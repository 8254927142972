import React, { useCallback, useEffect, useState } from 'react';

import { Button } from '@blueprintjs/core';
import classnames from 'classnames';
import { observer } from 'mobx-react-lite';

import { Direction } from '~/domain/common';
import { FilterDirection, Filters } from '~/domain/filtering';
import { Flow, Verdict } from '~/domain/flows';
import { HubblePolicy, IPProtocol, PodSelector, TCPFlagName, Workload } from '~/domain/hubble';
import { KV, Labels } from '~/domain/labels';

import {
  AuthTypeEntry,
  DnsBodyItem,
  IdentityEntry,
  IPEntry,
  LabelsEntry,
  PodEntry,
  PoliciesList,
  PortEntry,
  ProtocolEntry,
  TCPFlagsEntry,
  VerdictEntry,
  WorkloadsEntry,
} from './SidebarComponents';
import css from './styles.scss';

export interface Props {
  flow: Flow;
  filters: Filters;

  policies?: HubblePolicy[] | null;
  selectedPolicy?: HubblePolicy | null;
  policiesListTitle?: string;
  policiesYamlTitle?: string;

  showReviewPolicyButton: boolean;

  onSelectPolicy?: (policy: HubblePolicy | null) => void;
  onClose?: () => void;
  onVerdictClick?: (verdict: Verdict) => void;
  onTcpFlagClick?: (flag?: TCPFlagName, direction?: FilterDirection) => void;
  onLabelClick?: (label?: KV, direction?: FilterDirection) => void;
  onPodClick?: (podSelector?: PodSelector, direction?: FilterDirection) => void;
  onIdentityClick?: (identity?: string, direction?: FilterDirection) => void;
  onIpClick?: (ip?: string, direction?: FilterDirection) => void;
  onDnsClick?: (dns?: string) => void;
  onWorkloadClick?: (workload?: Workload, direction?: FilterDirection) => void;
  onPortClick?: (port?: number, direction?: FilterDirection) => void;
  onProtocolClick?: (protocol?: string, direction?: FilterDirection) => void;
  onReviewInPolicyEditor?: () => void;
  onOpenProcessTreeForFlow?: (f: Flow, d: Direction) => void;
}

export const FlowsTableSidebarContent = observer(function FlowsTableSidebarContent(props: Props) {
  const { flow } = props;

  const tcpFilterDirection = FilterDirection.From;
  const isVerdictSelected = !!props.filters.verdicts?.has(flow.verdict);

  const onVerdictClick = useCallback(() => {
    props.onVerdictClick?.(flow.verdict);
  }, [props.flow, isVerdictSelected, props.onVerdictClick]);

  const [labelsSelection, setLabelSelection] = useState<Set<string>>(new Set());
  const [podSelection, setPodSelection] = useState<Set<string>>(new Set());
  const [identitySelection, setIdSelection] = useState<Set<number>>(new Set());
  const [ipSelection, setIpSelection] = useState<Set<string>>(new Set());
  const [workloadSelection] = useState<Set<string>>(new Set());
  const [portSelection] = useState<Set<string>>(new Set());
  const [protocolSelection] = useState<Set<string>>(new Set());
  const [tcpFlagSelection] = useState<Set<TCPFlagName>>(new Set());

  const [isDnsSelected] = useState(false);

  useEffect(() => {
    const lblSelection: Set<string> = new Set();
    const podsSelection: Set<string> = new Set();
    const idtySelection: Set<number> = new Set();
    const ipsSelection: Set<string> = new Set();

    setLabelSelection(lblSelection);
    setPodSelection(podsSelection);
    setIdSelection(idtySelection);
    setIpSelection(ipsSelection);
  }, [props.flow]);

  const onTcpFlagClick = useCallback(
    (flag: TCPFlagName) => {
      const isSelected = tcpFlagSelection.has(flag);
      if (isSelected) {
        return props.onTcpFlagClick?.();
      }

      props.onTcpFlagClick?.(flag, tcpFilterDirection);
    },
    [props.onTcpFlagClick, tcpFlagSelection],
  );

  const onSourceLabelClick = useCallback(
    (label: KV) => {
      const labelStr = Labels.concatKV(label);
      const isSelected = labelsSelection.has(labelStr);

      if (isSelected) {
        return props.onLabelClick?.();
      }

      props.onLabelClick?.(label, FilterDirection.From);
    },
    [props.onLabelClick, labelsSelection],
  );

  const onDestLabelClick = useCallback(
    (label: KV) => {
      const labelStr = Labels.concatKV(label);
      const isSelected = labelsSelection.has(labelStr);

      if (isSelected) {
        return props.onLabelClick?.();
      }

      props.onLabelClick?.(label, FilterDirection.To);
    },
    [props.onLabelClick, labelsSelection],
  );

  const onSourcePodNameClick = useCallback(
    (podSelector: PodSelector) => {
      const isSelected = podSelection.has(podSelector.pod);

      if (isSelected) {
        return props.onPodClick?.();
      }

      props.onPodClick?.(podSelector, FilterDirection.From);
    },
    [podSelection, props.flow, props.onPodClick],
  );

  const onDestPodNameClick = useCallback(
    (podSelector: PodSelector) => {
      const isSelected = podSelection.has(podSelector.pod);

      if (isSelected) {
        return props.onPodClick?.();
      }

      props.onPodClick?.(podSelector, FilterDirection.To);
    },
    [props.onPodClick, podSelection],
  );

  const onSourceIdentityClick = useCallback(() => {
    const identity = props.flow.sourceIdentity;
    const isSelected = identitySelection.has(identity!);

    if (identity == null || isSelected) {
      return props.onIdentityClick?.();
    }

    props.onIdentityClick?.(`${identity}`, FilterDirection.From);
  }, [props.onIdentityClick, props.flow, identitySelection]);

  const onDestIdentityClick = useCallback(() => {
    const identity = props.flow.destinationIdentity;
    const isSelected = identitySelection.has(identity!);

    if (identity == null || isSelected) {
      return props.onIdentityClick?.();
    }

    props.onIdentityClick?.(`${identity}`, FilterDirection.To);
  }, [props.onIdentityClick, props.flow, identitySelection]);

  const onSourceIpClick = useCallback(() => {
    const ip = props.flow.sourceIp;
    const isSelected = ipSelection.has(ip!);

    if (ip == null || isSelected) {
      return props.onIpClick?.();
    }

    props.onIpClick?.(ip, FilterDirection.From);
  }, [props.onIpClick, props.flow, ipSelection]);

  const onDestIpClick = useCallback(() => {
    const ip = props.flow.destinationIp;
    const isSelected = ipSelection.has(ip!);

    if (ip == null || isSelected) {
      return props.onIpClick?.();
    }

    props.onIpClick?.(ip, FilterDirection.To);
  }, [props.onIpClick, props.flow, ipSelection]);

  const onSourceWorkloadClick = useCallback(
    (workload: Workload) => {
      const isSelected = workloadSelection.has(workload?.name ?? '');

      if (workload == null || isSelected) {
        return props.onWorkloadClick?.();
      }

      props.onWorkloadClick?.(workload, FilterDirection.From);
    },
    [props.onWorkloadClick, props.flow, workloadSelection],
  );

  const onDestWorkloadClick = useCallback(
    (workload: Workload) => {
      const isSelected = workloadSelection.has(workload?.name ?? '');

      if (workload == null || isSelected) {
        return props.onWorkloadClick?.();
      }

      props.onWorkloadClick?.(workload, FilterDirection.To);
    },
    [props.onWorkloadClick, props.flow, workloadSelection],
  );

  const onSourcePortClick = useCallback(() => {
    const port = props.flow.sourcePort;
    const isSelected = portSelection.has(port?.toString?.() ?? '');

    if (port == null || isSelected) {
      return props.onPortClick?.();
    }

    props.onPortClick?.(port, FilterDirection.From);
  }, [props.onPortClick, props.flow, portSelection]);

  const onDestPortClick = useCallback(() => {
    const port = props.flow.destinationPort;
    const isSelected = portSelection.has(port?.toString?.() ?? '');

    if (port == null || isSelected) {
      return props.onPortClick?.();
    }

    props.onPortClick?.(port, FilterDirection.To);
  }, [props.onPortClick, props.flow, portSelection]);

  const onSourceProtocolClick = useCallback(() => {
    const protocol = props.flow.protocolStr ?? '';
    const isSelected = protocolSelection.has(protocol);

    if (protocol == null || isSelected) {
      return props.onProtocolClick?.();
    }

    props.onProtocolClick?.(protocol, FilterDirection.From);
  }, [props.onProtocolClick, props.flow, protocolSelection]);

  const onDestProtocolClick = useCallback(() => {
    const protocol = props.flow.protocolStr ?? '';
    const isSelected = protocolSelection.has(protocol);

    if (protocol == null || isSelected) {
      return props.onProtocolClick?.();
    }

    props.onProtocolClick?.(protocol, FilterDirection.To);
  }, [props.onProtocolClick, props.flow, protocolSelection]);

  const onDnsClick = useCallback(() => {
    if (isDnsSelected || props.flow.destinationDns == null) {
      return props.onDnsClick?.();
    }

    props.onDnsClick?.(props.flow.destinationDns);
  }, [props.flow, props.onDnsClick, isDnsSelected]);

  const isICMPProtocol =
    props.flow.protocol === IPProtocol.ICMPv4 || props.flow.protocol === IPProtocol.ICMPv6;

  return (
    <>
      <section className={css.block}>
        <span className={css.title}>Timestamp</span>
        <div className={css.body}>{flow.isoTimestamp}</div>
      </section>
      <section className={css.block}>
        <span className={css.title}>Verdict</span>
        <div className={css.body}>
          <VerdictEntry
            verdict={flow.verdict}
            isSelected={isVerdictSelected}
            onClick={onVerdictClick}
          />
        </div>
      </section>
      {/* `!!flow.authType` check works as `AuthType.Disabled` has value = 0 */}
      {/* And we don't want to show it in sidebar */}
      {!!flow.authType && (
        <section className={css.block}>
          <span className={css.title}>Auth Type</span>
          <div className={css.body}>
            <AuthTypeEntry authType={flow.authType} />
          </div>
        </section>
      )}
      {flow.verdict === Verdict.Dropped && (
        <section className={css.block}>
          <span className={css.title}>Drop reason</span>
          <div className={css.body}>
            <div>{flow.dropReason}</div>
            {props.showReviewPolicyButton && (
              <Button small onClick={props.onReviewInPolicyEditor}>
                Review
              </Button>
            )}
          </div>
        </section>
      )}
      <section className={css.block}>
        <span className={css.title}>Traffic direction</span>
        <div className={css.body}>{flow.trafficDirectionLabel}</div>
      </section>
      {flow.ciliumEventSubTypeLabel && (
        <section className={css.block}>
          <span className={css.title}>Cilium event type</span>
          <div className={css.body}>{flow.ciliumEventSubTypeLabel}</div>
        </section>
      )}
      {flow.enabledTcpFlags.length > 0 && (
        <section className={css.block}>
          <span className={css.title}>TCP flags</span>
          <div className={css.body}>
            <TCPFlagsEntry
              flags={flow.enabledTcpFlags}
              selected={tcpFlagSelection}
              filterDirection={tcpFilterDirection}
              onClick={onTcpFlagClick}
            />
          </div>
        </section>
      )}
      {flow.egressAllowedByPolicies && flow.egressAllowedByPolicies.length > 0 && (
        <section className={css.block}>
          <span className={css.title}>Egress allowed by policies</span>
          <div className={css.body}>
            <PoliciesList policies={flow.egressAllowedByPolicies} />
          </div>
        </section>
      )}
      {flow.ingressAllowedByPolicies && flow.ingressAllowedByPolicies.length > 0 && (
        <section className={css.block}>
          <span className={css.title}>Ingress allowed by policies</span>
          <div className={css.body}>
            <PoliciesList policies={flow.ingressAllowedByPolicies} />
          </div>
        </section>
      )}
      <div className={css.blockSeparator} />
      {flow.hasSource && flow.sourceCluster && (
        <section className={css.block}>
          <span className={css.title}>Source cluster</span>
          <div className={css.body}>{flow.sourceCluster}</div>
        </section>
      )}
      {flow.hasSource && flow.sourcePodName && (
        <section className={css.block}>
          <span className={css.title}>Source pod</span>
          <div className={css.body}>
            <PodEntry
              podSelector={flow.sourcePodSelector}
              isSelected={podSelection.has(flow.sourcePodName)}
              onClick={onSourcePodNameClick}
            />
          </div>
        </section>
      )}
      {flow.hasSource && flow.sourceWorkloads.length > 0 && (
        <section className={css.block}>
          <span className={css.title}>Source Workloads</span>
          <div className={css.body}>
            <WorkloadsEntry
              workloads={flow.sourceWorkloads}
              selected={labelsSelection}
              onClick={onSourceWorkloadClick}
            />
          </div>
        </section>
      )}
      {flow.hasSource && typeof flow.sourceIdentity === 'number' && (
        <section className={css.block}>
          <span className={css.title}>Source identity</span>
          <div className={css.body}>
            <IdentityEntry
              identity={flow.sourceIdentity}
              isSelected={identitySelection.has(flow.sourceIdentity)}
              onClick={onSourceIdentityClick}
            />
          </div>
        </section>
      )}
      {flow.hasSource && flow.sourceLabels.length > 0 && (
        <section className={css.block}>
          <span className={css.title}>Source labels</span>
          <div className={css.body}>
            <LabelsEntry
              labels={flow.sourceLabels}
              selected={labelsSelection}
              onClick={onSourceLabelClick}
            />
          </div>
        </section>
      )}
      {flow.hasSource && flow.sourceIp && (
        <section className={css.block}>
          <span className={css.title}>Source IP</span>
          <div className={css.body}>
            <IPEntry
              ip={flow.sourceIp}
              isSelected={ipSelection.has(flow.sourceIp)}
              onClick={onSourceIpClick}
            />
          </div>
        </section>
      )}
      {flow.hasSource && flow.protocol && (
        <section className={css.block}>
          <span className={css.title}>Source {!isICMPProtocol && 'port • '}protocol</span>
          <div className={css.body}>
            {flow.sourcePort && (
              <PortEntry
                port={flow.sourcePort}
                isSelected={portSelection.has(flow.sourcePort.toString())}
                onClick={onSourcePortClick}
              />
            )}
            {flow.sourcePort && ` • `}
            <ProtocolEntry
              protocol={flow.protocolStr}
              isSelected={protocolSelection.has(flow.protocolStr ?? '')}
              onClick={onSourceProtocolClick}
            />
          </div>
        </section>
      )}
      {flow.hasSource && flow.sourcePodName && (
        <section className={css.block}>
          <Button
            outlined
            onClick={() => props.onOpenProcessTreeForFlow?.(flow, Direction.Source)}
            text="Show pod processes →"
          />
        </section>
      )}
      <div className={css.blockSeparator} />
      {flow.hasSource && flow.destinationCluster && (
        <section className={css.block}>
          <span className={css.title}>Destination cluster</span>
          <div className={css.body}>{flow.destinationCluster}</div>
        </section>
      )}
      {flow.hasDestination && flow.destinationPodName && (
        <section className={css.block}>
          <span className={css.title}>Destination pod</span>
          <div className={css.body}>
            <PodEntry
              podSelector={flow.destinationPodSelector}
              isSelected={podSelection.has(flow.destinationPodName)}
              onClick={onDestPodNameClick}
            />
          </div>
        </section>
      )}
      {flow.hasDestination && flow.destinationWorkloads.length > 0 && (
        <section className={css.block}>
          <span className={css.title}>Destination Workloads</span>
          <div className={css.body}>
            <WorkloadsEntry
              workloads={flow.destinationWorkloads}
              selected={labelsSelection}
              onClick={onDestWorkloadClick}
            />
          </div>
        </section>
      )}
      {flow.hasDestination && typeof flow.destinationIdentity === 'number' && (
        <section className={css.block}>
          <span className={css.title}>Destination identity</span>
          <div className={css.body}>
            <IdentityEntry
              identity={flow.destinationIdentity}
              isSelected={identitySelection.has(flow.destinationIdentity)}
              onClick={onDestIdentityClick}
            />
          </div>
        </section>
      )}
      {flow.hasDestination && flow.destinationLabels.length > 0 && (
        <section className={css.block}>
          <span className={css.title}>Destination labels</span>
          <div className={css.body}>
            <LabelsEntry
              labels={flow.destinationLabels}
              selected={labelsSelection}
              onClick={onDestLabelClick}
            />
          </div>
        </section>
      )}
      {flow.hasDestination && flow.destinationIp && (
        <section className={css.block}>
          <span className={css.title}>Destination IP</span>
          <div className={css.body}>
            <IPEntry
              ip={flow.destinationIp}
              isSelected={ipSelection.has(flow.destinationIp)}
              onClick={onDestIpClick}
            />
          </div>
        </section>
      )}
      {flow.hasDestination && flow.destinationDns && (
        <section className={css.block}>
          <span className={css.title}>Destination DNS</span>
          <div className={css.body}>
            <DnsBodyItem
              dns={flow.destinationDns}
              isSelected={isDnsSelected}
              onClick={onDnsClick}
            />
          </div>
        </section>
      )}
      {flow.hasDestination && flow.protocol && (
        <section className={css.block}>
          <span className={css.title}>Destination {!isICMPProtocol && 'port • '}protocol</span>
          <div className={css.body}>
            {flow.destinationPort && (
              <PortEntry
                port={flow.destinationPort}
                isSelected={portSelection.has(flow.destinationPort.toString())}
                onClick={onDestPortClick}
              />
            )}
            {flow.destinationPort && ` • `}
            <ProtocolEntry
              protocol={flow.protocolStr}
              isSelected={protocolSelection.has(flow.protocolStr ?? '')}
              onClick={onDestProtocolClick}
            />
          </div>
        </section>
      )}
      {flow.hasDestination && flow.destinationPodName && (
        <section className={css.block}>
          <Button
            outlined
            onClick={() => props.onOpenProcessTreeForFlow?.(flow, Direction.Destination)}
            text="Show pod processes →"
          />
        </section>
      )}
      {flow.http != null && (
        <>
          <hr />
          {block('HTTP Request Method', flow.http.method)}
          {block('HTTP Request Protocol', flow.http.protocol)}
          {block('HTTP Request URL', flow.http.url)}

          {!!flow.http.headersList?.length &&
            block(
              'HTTP Request Headers',
              <div className={css.headerList}>
                {flow.http.headersList.map(h => {
                  const lbl = `${h.key}: ${h.value}`;
                  return (
                    <span key={lbl} title={lbl}>
                      <strong>{h.key}</strong>: {h.value}
                    </span>
                  );
                })}
              </div>,
            )}
        </>
      )}
    </>
  );
});

const block = (
  caption: string,
  content: React.ReactNode,
  cls: string | null = null,
): React.ReactNode => {
  return (
    <section className={classnames(css.block, cls)}>
      <span className={css.title}>{caption}</span>
      <div className={css.body}>{content}</div>
    </section>
  );
};
