import { EventParams, EventParamsSet } from '~/api/general/event-stream';
import { CiliumEventTypes } from '~/domain/cilium';
import { CountStats, Order } from '~/domain/common';
import { FilterGroup, FilterKind, Filters } from '~/domain/filtering';
import { Flow } from '~/domain/flows';
import * as helpers from '~/domain/helpers';
import { ReservedLabel, SpecialLabel } from '~/domain/labels';
import { Link } from '~/domain/link';
import { ServiceMap } from '~/domain/service-map';
import { TimeRange } from '~/domain/time';
import { PodInfo, TimescapeData, TimescapeDataFilter } from '~/domain/timescape';
import * as flowpb from '~backend/proto/flow/flow_pb';
import * as k8seventpb from '~backend/proto/k8sevent/v1/event_pb';
import * as obpb from '~backend/proto/observer/observer_pb';
import * as tscpb from '~backend/proto/timescape/v1/container_pb';
import * as tsk8seventspb from '~backend/proto/timescape/v1/k8s_events_pb';
import * as tstfpb from '~backend/proto/timescape/v1/time_filter_pb';
import * as uipepb from '~backend/proto/ui/process-events_pb';
import * as uipb from '~backend/proto/ui/ui_pb';

export class ProtoFactory {
  public static getEventsRequestFromFilters(
    namespace: string | null,
    f: Filters,
    ep: EventParams = EventParamsSet.EventStream,
  ): uipb.GetEventsRequest {
    const req = uipb.GetEventsRequest.create({
      event_types: [],
    });

    if (ep.flows) {
      req.event_types.push(uipb.EventType.FLOWS);
    }

    if (ep.flow) {
      req.event_types.push(uipb.EventType.FLOW);
    }

    if (ep.namespaces) {
      req.event_types.push(uipb.EventType.K8S_NAMESPACE_STATE);
    }

    if (ep.services) {
      req.event_types.push(uipb.EventType.SERVICE_STATE);
    }

    if (ep.serviceLinks) {
      req.event_types.push(uipb.EventType.SERVICE_LINK_STATE);
    }

    if (ep.policies) {
      req.event_types.push(uipb.EventType.POLICY_STATE);
    }

    if (ep.status) {
      req.event_types.push(uipb.EventType.STATUS);
    }

    if (f.aggregation != null) {
      const agg = helpers.aggregation.domainToPb(f.aggregation);
      req.aggregation = agg;
    }

    const [wlFlowFilters, blFlowFilters] = ProtoFactory.flowFiltersFromFilters(f);

    const policyFilters = ProtoFactory.policyFiltersFromFilters(namespace);

    const wlFilters = wlFlowFilters
      .map(ProtoFactory.eventFilterFromFlowFilter)
      .concat(policyFilters.map(ProtoFactory.eventFilterFromPolicyFilter));

    const blFilters = blFlowFilters.map(ProtoFactory.eventFilterFromFlowFilter);

    if (wlFilters.length) {
      req.whitelist = wlFilters;
    }

    if (blFilters.length) {
      req.blacklist = blFilters;
    }

    return req;
  }

  public static flowFiltersFromFilters(
    filters: Filters,
  ): [flowpb.FlowFilter[], flowpb.FlowFilter[]] {
    const wlFilters: flowpb.FlowFilter[] = [];
    const blFilters = ProtoFactory.blacklistFlowFilters(filters);

    const positiveGroups = filters.filterGroups?.filter(
      group => !group.entries.every(e => e.negative),
    );

    const pushBaseWlFilters = () => {
      wlFilters.push(ProtoFactory.baseWhitelistFilter(filters));
    };

    if (!positiveGroups?.length) {
      if (filters.filterGroups?.length === 0) {
        pushBaseWlFilters();
      }

      return [wlFilters, blFilters];
    }

    positiveGroups.forEach(group => {
      wlFilters.push(...ProtoFactory.filterEntryWhitelistFilters(filters, group));
    });

    return [wlFilters, blFilters];
  }

  // blacklist: [labels=[unknown OR host]] OR [dns=[*.cluster.local*]] OR [labels=[hubble-ui]]
  // blacklist: [labels=[unknown OR host OR hubble-ui]]

  // CURRENTLY whitelist: [labels=[hubble-ui]] OR [dns=[google.com]]
  // -> ?filter-labels=hubble-ui&filter-dns=google.com

  // NEXT whitelist: [labels=[hubble-ui] AND dns=[hubble-ui.isovalent.com]] OR [dns=[google.com]]
  // -> ?filter-labels=hubble-ui&filter-dns=google.com

  public static blacklistFlowFilters(filters?: Filters): flowpb.FlowFilter[] {
    const blFilters: flowpb.FlowFilter[] = [];

    const blSrcLabelsFilter = flowpb.FlowFilter.create();
    const blDstLabelsFilter = flowpb.FlowFilter.create();
    blFilters.push(blSrcLabelsFilter, blDstLabelsFilter);

    blSrcLabelsFilter.source_label.push(ReservedLabel.Unknown);
    blDstLabelsFilter.destination_label.push(ReservedLabel.Unknown);

    if (filters?.skipHost) {
      blSrcLabelsFilter.source_label.push(ReservedLabel.Host);
      blDstLabelsFilter.destination_label.push(ReservedLabel.Host);
    }

    if (filters?.skipKubeDns) {
      blSrcLabelsFilter.source_label.push(SpecialLabel.KubeDNS);

      const blDstKubeDns = flowpb.FlowFilter.create();
      // TODO: consider this line to remove, as if service is Unmanaged, flows to
      // TOOD: 53 port will be allowed; don't forget to fix `filter-flow.ts` too
      blDstKubeDns.destination_label.push(SpecialLabel.KubeDNS);
      blDstKubeDns.destination_port.push('53');
      blFilters.push(blDstKubeDns);
    }

    if (filters?.skipRemoteNode) {
      blSrcLabelsFilter.source_label.push(ReservedLabel.RemoteNode);
      blDstLabelsFilter.destination_label.push(ReservedLabel.RemoteNode);
    }

    if (filters?.skipPrometheusApp) {
      blSrcLabelsFilter.source_label.push(SpecialLabel.PrometheusApp);
      blDstLabelsFilter.destination_label.push(SpecialLabel.PrometheusApp);
    }

    // filter out intermediate dns requests
    const blSrcLocalDnsFilter = flowpb.FlowFilter.create();
    const blDstLocalDnsFilter = flowpb.FlowFilter.create();
    blSrcLocalDnsFilter.source_fqdn.push('*.cluster.local*');
    blDstLocalDnsFilter.destination_fqdn.push('*.cluster.local*');
    blFilters.push(blSrcLocalDnsFilter, blDstLocalDnsFilter);

    const filteredFilterGroups = filters?.filterGroups?.filter(group =>
      group.entries.some(e => e.negative),
    );

    filteredFilterGroups?.forEach(group => {
      const hubbleFlowToFilter = flowpb.FlowFilter.create();
      const hubbleFlowFromFilter = flowpb.FlowFilter.create();

      const { entries } = group;

      const podEntry = entries.find(e => e.kind === FilterKind.Pod);
      const namespaceEntry = entries.find(e => e.kind === FilterKind.Namespace);

      if (!podEntry && namespaceEntry) {
        const value = `${namespaceEntry.query}/`;
        namespaceEntry.fromRequired && hubbleFlowFromFilter.source_pod.push(value);
        namespaceEntry.toRequired && hubbleFlowToFilter.destination_pod.push(value);
      }

      entries.forEach(entry => {
        switch (entry.kind) {
          case FilterKind.Cluster: {
            entry.fromRequired && hubbleFlowFromFilter.node_name.push(entry.query);
            entry.toRequired && hubbleFlowToFilter.node_name.push(entry.query);
            break;
          }
          case FilterKind.Label: {
            entry.fromRequired && hubbleFlowFromFilter.source_label.push(entry.query);
            entry.toRequired && hubbleFlowToFilter.destination_label.push(entry.query);
            break;
          }
          case FilterKind.Ip: {
            entry.fromRequired && hubbleFlowFromFilter.source_ip.push(entry.query);
            entry.toRequired && hubbleFlowToFilter.destination_ip.push(entry.query);
            break;
          }
          case FilterKind.Dns: {
            entry.fromRequired && hubbleFlowFromFilter.source_fqdn.push(entry.query);
            entry.toRequired && hubbleFlowToFilter.destination_fqdn.push(entry.query);
            break;
          }
          case FilterKind.Identity: {
            entry.fromRequired && hubbleFlowFromFilter.source_identity.push(+entry.query);
            entry.toRequired && hubbleFlowToFilter.destination_identity.push(+entry.query);
            break;
          }
          case FilterKind.Pod: {
            const namespace = namespaceEntry?.query;
            if (!namespace) break;
            const podValue = `${namespace}/${entry.query}`;
            entry.fromRequired && hubbleFlowFromFilter.source_pod.push(podValue);
            entry.toRequired && hubbleFlowToFilter.destination_pod.push(podValue);
            break;
          }
          case FilterKind.Workload: {
            entry.fromRequired &&
              hubbleFlowFromFilter.source_workload.push({
                name: entry.query,
                kind: entry.meta ?? 'Deployment',
              });
            entry.toRequired &&
              hubbleFlowToFilter.destination_workload.push({
                name: entry.query,
                kind: entry.meta ?? 'Deployment',
              });
            break;
          }
        }
      });

      if (entries.some(e => e.fromRequired)) {
        blFilters.push(hubbleFlowFromFilter);
      }

      if (entries.some(e => e.toRequired)) {
        blFilters.push(hubbleFlowToFilter);
      }
    });

    return blFilters;
  }

  public static baseWhitelistFilter(filters?: Filters): flowpb.FlowFilter {
    const wlFilter = flowpb.FlowFilter.create();

    const eventTypes: CiliumEventTypes[] = [];
    if (filters?.httpStatus) {
      // Filter by http status code allows only l7 event type
      eventTypes.push(CiliumEventTypes.L7);
      wlFilter.http_status_code.push(filters.httpStatus);
    }

    eventTypes.forEach(eventTypeNumber => {
      wlFilter.event_type.push(flowpb.EventTypeFilter.create({ type: eventTypeNumber }));
    });

    filters?.verdicts?.forEach(verdict => {
      wlFilter.verdict.push(helpers.verdict.verdictToPb(verdict));
    });

    // TODO: code for handling tcp flags should be here

    wlFilter.reply.push(false);

    return wlFilter;
  }

  public static filterEntryWhitelistFilters(
    filters: Filters,
    filterGroup: FilterGroup,
  ): flowpb.FlowFilter[] {
    const wlFilters: flowpb.FlowFilter[] = [];

    const fromInside = ProtoFactory.baseWhitelistFilter(filters);
    const toInside = ProtoFactory.baseWhitelistFilter(filters);

    const podEntry = filterGroup.entries.find(e => e.kind === FilterKind.Pod);
    const namespaceEntry = filterGroup.entries.find(e => e.kind === FilterKind.Namespace);

    if (!podEntry && namespaceEntry) {
      const value = `${namespaceEntry.query}/`;
      namespaceEntry.fromRequired && fromInside.source_pod.push(value);
      namespaceEntry.toRequired && toInside.destination_pod.push(value);
    }

    filterGroup.entries.forEach(entry => {
      // NOTE: ...this filter fixes this last case

      if (entry.fromRequired) {
        // NOTE: this makes possible to catch flows [outside of ns] -> [ns]
        // NOTE: but flows [ns] -> [outside of ns] are lost...
        // podsInNamespace && fromInside.sourcePod.push(podsInNamespace);

        switch (entry.kind) {
          case FilterKind.Cluster: {
            fromInside.node_name.push(`${entry.query}/`);
            break;
          }
          case FilterKind.Label: {
            fromInside.source_label.push(entry.query);
            break;
          }
          case FilterKind.Ip: {
            fromInside.source_ip.push(entry.query);
            break;
          }
          case FilterKind.Dns: {
            fromInside.source_fqdn.push(entry.query);
            break;
          }
          case FilterKind.Identity: {
            fromInside.source_identity.push(+entry.query);
            break;
          }
          case FilterKind.Pod: {
            const namespace = namespaceEntry?.query;
            if (!namespace) break;
            const podValue = `${namespace}/${entry.query}`;
            fromInside.source_pod.push(podValue);
            break;
          }
          case FilterKind.Workload: {
            const workload = ProtoFactory.workloadFromFilterGroup(filterGroup);
            if (workload == null) break;

            fromInside.source_workload.push(workload);
            break;
          }
          case FilterKind.Port: {
            fromInside.source_port.push(entry.query);
            break;
          }
          case FilterKind.Protocol: {
            fromInside.protocol.push(entry.query);
            break;
          }
        }
      }

      if (entry.toRequired) {
        // NOTE: this makes possible to catch flows [ns] -> [outside of ns]
        // NOTE: but flows [outside of ns] -> [ns] are lost...

        switch (entry.kind) {
          case FilterKind.Cluster: {
            toInside.node_name.push(`${entry.query}/`);
            break;
          }
          case FilterKind.Label: {
            toInside.destination_label.push(entry.query);
            break;
          }
          case FilterKind.Ip: {
            toInside.destination_ip.push(entry.query);
            break;
          }
          case FilterKind.Dns: {
            toInside.destination_fqdn.push(entry.query);
            break;
          }
          case FilterKind.Identity: {
            toInside.destination_identity.push(+entry.query);
            break;
          }
          case FilterKind.Pod: {
            const namespace = namespaceEntry?.query;
            if (!namespace) break;
            const podValue = `${namespace}/${entry.query}`;
            toInside.destination_pod.push(podValue);
            break;
          }
          case FilterKind.Workload: {
            const workload = ProtoFactory.workloadFromFilterGroup(filterGroup);
            if (workload == null) break;
            toInside.destination_workload.push(workload);
            break;
          }
          case FilterKind.Port: {
            toInside.destination_port.push(entry.query);
            break;
          }
          case FilterKind.Protocol: {
            toInside.protocol.push(entry.query);
            break;
          }
        }
      }
    });

    if (filterGroup.entries.some(e => e.toRequired)) {
      wlFilters.push(toInside);
    }

    if (filterGroup.entries.some(e => e.fromRequired)) {
      wlFilters.push(fromInside);
    }

    return wlFilters;
  }

  public static policyFiltersFromFilters(namespace: string | null): uipb.PolicySpecFilter[] {
    const filter = uipb.PolicySpecFilter.create({
      policyNamespace: [],
    });

    if (namespace) filter.policyNamespace.push(namespace);

    return [filter];
  }

  public static workloadFromFilterGroup(group: FilterGroup): flowpb.Workload | null {
    const entry = group.entries.find(fe => {
      if (!fe.isWorkload || !fe.query || !fe.meta) return false;

      return !!fe.asWorkload();
    });
    if (!entry) return null;

    return entry.asWorkload() ?? null;
  }

  public static eventFilterFromFlowFilter(ff: flowpb.FlowFilter): uipb.EventFilter {
    const filter = uipb.EventFilter.create({
      filter: {
        oneofKind: 'flow_filter',
        flow_filter: ff,
      },
    });

    return filter;
  }

  public static eventFilterFromPolicyFilter(psf: uipb.PolicySpecFilter): uipb.EventFilter {
    const filter = uipb.EventFilter.create({
      filter: {
        oneofKind: 'policy_spec_filter',
        policy_spec_filter: psf,
      },
    });

    return filter;
  }

  public static timescapeDataRequestFromDataFilters(
    dataFilters: TimescapeDataFilter,
  ): uipb.GetTimescapeDataRequest {
    const request = uipb.GetTimescapeDataRequest.create();
    const flowsRequest = obpb.GetFlowsRequest.create();

    const filters = dataFilters.filters ?? Filters.default();

    const [wlFlowFilters, blFlowFilters] = ProtoFactory.flowFiltersFromFilters(filters);

    flowsRequest.whitelist = wlFlowFilters;
    flowsRequest.blacklist = blFlowFilters;
    // Don't place "number" field to flows request
    // This will break data set returned from timescape
    // flowsRequest.number = dataFilters.limit;

    if (filters.timeRange?.startDate != null) {
      const pbTimestamp = helpers.time.dateToPBTimestamp(filters.timeRange.startDate);
      flowsRequest.since = pbTimestamp;
    }

    if (filters.timeRange?.endDate != null) {
      const pbTimestamp = helpers.time.dateToPBTimestamp(filters.timeRange.endDate);
      flowsRequest.until = pbTimestamp;
    }

    // TODO: does that make any sense in context of hubble-timescape?
    if (filters.aggregation != null) {
      const agg = helpers.aggregation.domainToPb(filters.aggregation);
      flowsRequest.aggregation = agg;
    }

    if (dataFilters.lastSeen?.timestamp != null) {
      const lastSeenData = uipb.GetTimescapeDataRequest_LastDatumSeen.create();

      lastSeenData.id = dataFilters.lastSeen.id;
      lastSeenData.timestamp = helpers.time.timeToPBTimestamp(dataFilters.lastSeen.timestamp);

      request.last_seen = lastSeenData;
      request.last_flow_seen_filled = true;
    }

    request.flows_request = flowsRequest;
    request.limit = dataFilters.limit;

    return request;
  }

  public static timescapeDataFromDataResponse(
    resp?: uipb.GetTimescapeDataResponse | null,
  ): TimescapeData | null {
    if (resp == null) return null;

    const flows = resp.flows.map(pbFlow => {
      const hubbleFlow = helpers.flows.hubbleFlowFromPb(pbFlow);
      const flow = new Flow(hubbleFlow, undefined, pbFlow);

      return flow;
    });

    const links = resp.service_link.map(pbLink => {
      const link = helpers.relayServiceLinkFromPb(pbLink);

      return Link.fromHubbleLink(link);
    });

    const services = resp.service.map(pbService => {
      const service = helpers.relayServiceFromPb(pbService);

      return service;
    });

    const countStats: CountStats[] = [];
    resp.count_stats.forEach(cs => {
      const parsedCountStats = helpers.countStatsFromPb(cs);
      if (parsedCountStats == null) return;

      countStats.push(parsedCountStats);
    });

    const flowSummaries = helpers.timescape.flowSummariesFromProto(resp.flow_summaries);
    const namespaces = helpers.timescape.namespaceDescriptorsFromProto(resp.namespaces);

    return { flows, links, services, countStats, flowSummaries, namespaces };
  }

  public static fullFlowRequest(fsId: string): uipb.GetFullFlowRequest {
    const req = uipb.GetFullFlowRequest.create({
      flow_summary_id: fsId,
    });

    return req;
  }

  public static flowFromFullFlowResponse(
    flowId: string,
    resp?: uipb.GetFullFlowResponse | null,
  ): Flow | null {
    if (!resp?.found) return null;

    const pbFlow = resp.flow;
    if (pbFlow == null) return null;

    const hubbleFlow = helpers.flows.hubbleFlowFromPb(pbFlow);
    if (hubbleFlow == null) return null;

    return new Flow(hubbleFlow, flowId, pbFlow);
  }

  public static timescapeK8SPolicyEventsRequest(
    cluster: string | null | undefined,
    namespace: string | null | undefined,
    timeRange: TimeRange | null | undefined,
    resourceUuid: string | null | undefined,
  ): tsk8seventspb.GetK8sEventsRequest {
    const req = tsk8seventspb.GetK8sEventsRequest.create();

    if (timeRange) {
      req.time_filter = tstfpb.TimeFilter.create();
      const { start, end } = timeRange.plain;
      req.time_filter.since = helpers.time.dateToPBTimestamp(start);
      req.time_filter.until = helpers.time.dateToPBTimestamp(end);
    }

    const kinds: k8seventpb.Kind[] = [];
    if (namespace) {
      kinds.push(k8seventpb.Kind.CILIUM_NETWORK_POLICY);
      kinds.push(k8seventpb.Kind.KUBERNETES_NETWORK_POLICY);
    } else {
      kinds.push(k8seventpb.Kind.CILIUM_CLUSTERWIDE_NETWORK_POLICY);
    }

    const eventFilter = tsk8seventspb.K8sEventFilter.create({
      kind: kinds,
      event_type: [
        k8seventpb.EventType.CREATED,
        k8seventpb.EventType.DELETED,
        k8seventpb.EventType.UPDATED,
      ],
      ...(resourceUuid ? { resourceUuid: [resourceUuid] } : {}),
    });
    // TODO: return cluster to filter back
    // if (cluster) {
    //   eventFilter.cluster = [cluster];
    // }
    if (namespace) {
      eventFilter.namespace = [namespace];
    }
    req.include = [eventFilter];

    return req;
  }

  public static timescapePodsRequestFromDataFilter(
    namespace: string | null,
    filter: TimescapeDataFilter,
  ): uipb.GetTimescapePodsRequest | null {
    if (!namespace) return null;

    const request = uipb.GetTimescapePodsRequest.create();
    const innerRequest = tscpb.GetContainersFromEventsRequest.create();

    innerRequest.results_limit = filter.limit;

    const timeFilter = tstfpb.TimeFilter.create();
    const { start, end } = filter.filters?.timeRange?.plain ?? TimeRange.lastHour().plain;

    timeFilter.since = helpers.time.dateToPBTimestamp(start);
    timeFilter.until = helpers.time.dateToPBTimestamp(end);
    innerRequest.time_filter = timeFilter;

    const order =
      filter.order === Order.Ascending
        ? tscpb.GetContainersFromEventsRequest_ResultsOrder.CONTAINER_START_TIME_ASCENDING
        : tscpb.GetContainersFromEventsRequest_ResultsOrder.CONTAINER_START_TIME_DESCENDING;

    innerRequest.results_order = order;

    const nsFilter = tscpb.EventsContainersFilter.create();
    nsFilter.namespaces = [namespace];
    innerRequest.allow_list = [nsFilter];

    request.request = innerRequest;
    return request;
  }

  public static podInfoFromTimescapePodsResponse(
    resp: uipb.GetTimescapePodsResponse | null,
  ): PodInfo[] | null {
    if (resp == null) return null;

    return helpers.timescape.podInfosFromProto(resp.containers);
  }

  public static processEventsRequestFromPodInfo(
    pod: PodInfo,
  ): uipepb.GetTimescapeProcessEventsRequest {
    const req = uipepb.GetTimescapeProcessEventsRequest.create();

    const eventsPodInfo = helpers.timescape.podInfoToProto(pod);
    req.container = eventsPodInfo;

    return req;
  }

  public static serviceMapFromLogsRequest(
    logs: ArrayBuffer,
    filters: Filters,
  ): uipb.GetServiceMapFromLogsRequest {
    const req = uipb.GetServiceMapFromLogsRequest.create();
    req.logs = new Uint8Array(logs);

    const [wlFilters, blFilters] = ProtoFactory.eventsFiltersFromFilters(filters);

    req.whitelist = wlFilters;
    req.blacklist = blFilters;

    return req;
  }

  public static eventsFiltersFromFilters(
    filters: Filters,
  ): [uipb.EventFilter[], uipb.EventFilter[]] {
    const [wlFlowFilters, blFlowFilters] = ProtoFactory.flowFiltersFromFilters(filters);

    const wlFilters = wlFlowFilters.map(ProtoFactory.eventFilterFromFlowFilter);
    const blFilters = blFlowFilters.map(ProtoFactory.eventFilterFromFlowFilter);

    return [wlFilters, blFilters];
  }

  public static eventFiltersFromFlowFilter(flowFilter: flowpb.FlowFilter): uipb.EventFilter {
    const filter = uipb.EventFilter.create();
    filter.filter = {
      oneofKind: 'flow_filter',
      flow_filter: flowFilter,
    };

    return filter;
  }

  public static serviceMapFromLogsResponse(res: uipb.GetServiceMapFromLogsResponse): ServiceMap {
    const services = res.services.map(svc => {
      return helpers.relayServiceFromPb(svc);
    });

    const links = res.links.map(link => {
      return helpers.relayServiceLinkFromPb(link);
    });

    return ServiceMap.fromHubbleParts(services, links);
  }
}
