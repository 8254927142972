import _ from 'lodash';

import {
  CiliumEventType,
  DNS,
  Endpoint,
  Ethernet,
  Service as FlowService,
  FlowType,
  HTTP,
  HubbleFlow,
  HubblePolicy,
  ICMPv4,
  ICMPv6,
  IP,
  IPVersion,
  L7FlowType,
  Layer4,
  Layer7,
  TCP,
  TCPFlags,
  Time,
  TrafficDirection,
  UDP,
} from '~/domain/hubble';
import { KV, Labels } from '~/domain/labels';
import * as misc from '~/domain/misc';
import * as flowpb from '~backend/proto/flow/flow_pb';

import { authTypeFromPb, authTypeFromStr } from './auth-type';
import * as verdictHelpers from './verdict';

export const hubbleFlowFromObj = (obj: any): HubbleFlow | null => {
  obj = obj.flow != null ? obj.flow : obj;

  // NOTE: sanity check
  if (obj.verdict == null && obj.nodeName == null) return null;

  const time = isoTimeToHubbleTime(obj.time) ?? void 0;
  const verdict = verdictHelpers.parse(obj.verdict);
  if (verdict == null) return null;

  const ethernet =
    obj.ethernet != null
      ? {
          source: obj.ethernet.source,
          destination: obj.ethernet.destination,
        }
      : void 0;

  const ip = ipFromObj(obj.ip) ?? void 0;
  const source = endpointFromObj(obj.source) ?? void 0;
  const destination = endpointFromObj(obj.destination) ?? void 0;
  const l4 = l4FromObj(obj.l4) ?? void 0;
  const type = flowTypeFromStr(obj.type);
  const l7 = l7FromObj(obj.l7) ?? void 0;
  const eventType: CiliumEventType | undefined =
    obj.eventType != null
      ? {
          type: obj.eventType.type,
          subType: obj.eventType.subType,
        }
      : void 0;

  const sourceService = flowServiceFromObj(obj.sourceService) ?? void 0;
  const destinationService = flowServiceFromObj(obj.destinationService) ?? void 0;
  const trafficDirection = trafficDirectionFromStr(obj.trafficDirection);
  const egressAllowedByPolicies = hubblePoliciesFromArr(
    obj.egressAllowedByPolicies || obj.egressAllowedBy || [],
  );
  const ingressAllowedByPolicies = hubblePoliciesFromArr(
    obj.ingressAllowedByPolicies || obj.egressAllowedBy || [],
  );
  const authType = authTypeFromStr(obj.authType);

  return {
    time,
    verdict,
    dropReason: obj.dropReasonDesc || obj.dropReason,
    ethernet,
    ip,
    l4,
    source,
    destination,
    type,
    nodeName: obj.nodeName,
    sourceNamesList: obj.sourceNamesList ?? obj.sourceNames ?? [],
    destinationNamesList: obj.destinationNamesList ?? obj.destinationNames ?? [],
    l7,
    reply: obj.reply != null ? !!obj.reply : void 0,
    eventType,
    sourceService,
    destinationService,
    summary: obj.summary,
    trafficDirection,
    egressAllowedByPolicies,
    ingressAllowedByPolicies,
    authType,
  };
};

export const isoTimeToHubbleTime = (t: string | null): Time | null => {
  if (!t) return null;

  const d = new Date(t);
  if (!misc.isValidDate(d)) return null;

  const ms = +d;
  const seconds = (ms / 1000) | 0;
  // WARN: precision lost accumulates here
  const nanos = (ms / 1000 - seconds) * 1e9;

  return { seconds, nanos };
};

export const hubbleFlowFromPb = (flow: flowpb.Flow): HubbleFlow => {
  let time: any = void 0;

  if (flow.time != null) {
    const timeObj = flow.time;

    time = {
      seconds: timeObj.seconds,
      nanos: timeObj.nanos,
    };
  }

  const verdict = verdictHelpers.verdictFromPb(flow.verdict);
  const ethernet = ethernetFromPb(flow.ethernet);
  const ip = ipFromPb(flow.IP);
  const l4 = l4FromPb(flow.l4);
  const source = endpointFromPb(flow.source);
  const destination = endpointFromPb(flow.destination);
  const type = flowTypeFromPb(flow.Type);
  const l7 = l7FromPb(flow.l7);
  const eventType = ciliumEventTypeFromPb(flow.event_type);
  const sourceService = flowServiceFromPb(flow.source_service);
  const destinationService = flowServiceFromPb(flow.destination_service);
  const trafficDirection = trafficDirectionFromPb(flow.traffic_direction);
  const egressAllowedByPolicies = flow.egress_allowed_by.map(policy => hubblePolicyFromPb(policy));
  const ingressAllowedByPolicies = flow.ingress_allowed_by.map(policy =>
    hubblePolicyFromPb(policy),
  );
  const authType = authTypeFromPb(flow.auth_type);

  return {
    time,
    verdict,
    dropReason: flow.drop_reason_desc || flow.drop_reason,
    ethernet,
    ip,
    l4,
    source,
    destination,
    type,
    nodeName: flow.node_name,
    sourceNamesList: flow.source_names,
    destinationNamesList: flow.destination_names,
    l7,
    reply: flow.is_reply?.value,
    eventType,
    sourceService,
    destinationService,
    summary: flow.Summary,
    trafficDirection,
    egressAllowedByPolicies,
    ingressAllowedByPolicies,
    authType,
  };
};

export const flowServiceFromPb = (svc: flowpb.Service | undefined): FlowService | undefined => {
  return svc == null ? undefined : svc;
};

export const flowServiceFromObj = (obj: any): FlowService | null => {
  if (obj == null) return null;

  return {
    name: obj.name,
    namespace: obj.namespace,
  };
};

export const ciliumEventTypeFromPb = (
  cet: flowpb.CiliumEventType | undefined,
): CiliumEventType | undefined => {
  return cet == null
    ? undefined
    : {
        ...cet,
        subType: cet.sub_type,
      };
};

export const l7FromPb = (l7: flowpb.Layer7 | undefined): Layer7 | undefined => {
  if (l7 == null) return undefined;

  const obj = {
    type: l7FlowTypeFromPb(l7.type),
    latencyNs: l7.latency_ns,
    dns:
      l7.record.oneofKind === 'dns'
        ? { ...l7.record.dns, observationSource: l7.record.dns.observation_source }
        : void 0,
    http: l7.record.oneofKind === 'http' ? l7httpFromObj(l7.record.http) || void 0 : void 0,
    kafka:
      l7.record.oneofKind === 'kafka'
        ? {
            ...l7.record.kafka,
            errorCode: l7.record.kafka.error_code,
            apiVersion: l7.record.kafka.api_version,
            apiKey: l7.record.kafka.api_key,
            correlationId: l7.record.kafka.correlation_id,
          }
        : void 0,
  };

  return obj;
};

export const l7FromObj = (l7: any): Layer7 | null => {
  if (l7 == null) return null;

  return {
    type: l7FlowTypeFromStr(l7.type),
    latencyNs: l7.latencyNs,
    http: l7httpFromObj(l7.http) ?? void 0,
    dns: l7dnsFromObj(l7.dns) ?? void 0,
    kafka: l7.kafka,
  };
};

export const l7httpFromObj = (obj: any): HTTP | null => {
  if (obj == null) return null;

  return {
    code: obj.code,
    method: obj.method,
    url: obj.url,
    protocol: obj.protocol,
    headersList: obj.headersList ?? obj.headers,
  };
};

export const l7dnsFromObj = (dns: any): DNS | null => {
  if (!dns) return null;

  return {
    query: dns.query,
    ips: dns.ips ?? dns.ipsList,
    ttl: dns.ttl ? parseInt(dns.ttl, 10) : 0,
    cnames: dns.cnames ?? dns.cnamesList,
    observationSource: dns.observationSource,
    qtypes: dns.qtypes ?? dns.qtypesList,
    rrtypes: dns.rrtypes ?? dns.rrtypesList,
    rcode: dns.rcode ?? -1,
  };
};

export const l7FlowTypeFromPb = (pb: flowpb.L7FlowType): L7FlowType => {
  let ft = L7FlowType.Unknown;

  if (pb === flowpb.L7FlowType.REQUEST) {
    ft = L7FlowType.Request;
  } else if (pb === flowpb.L7FlowType.RESPONSE) {
    ft = L7FlowType.Response;
  } else if (pb === flowpb.L7FlowType.SAMPLE) {
    ft = L7FlowType.Sample;
  }

  return ft;
};

export const l7FlowTypeFromStr = (str: string): L7FlowType => {
  let ft = L7FlowType.Unknown;
  if (!str) return ft;

  str = str.toLowerCase();

  if (str.startsWith('request')) {
    ft = L7FlowType.Request;
  } else if (str.startsWith('response')) {
    ft = L7FlowType.Response;
  } else if (str.startsWith('sample')) {
    ft = L7FlowType.Sample;
  }

  return ft;
};

export const trafficDirectionFromPb = (pb: flowpb.TrafficDirection): TrafficDirection => {
  let dir = TrafficDirection.Unknown;

  if (pb === flowpb.TrafficDirection.INGRESS) {
    dir = TrafficDirection.Ingress;
  } else if (pb === flowpb.TrafficDirection.EGRESS) {
    dir = TrafficDirection.Egress;
  }

  return dir;
};

export const trafficDirectionFromStr = (str: string): TrafficDirection => {
  let dir = TrafficDirection.Unknown;
  if (!str) return dir;
  str = str.toLowerCase();

  if (str.startsWith('ingress')) {
    dir = TrafficDirection.Ingress;
  } else if (str.startsWith('egress')) {
    dir = TrafficDirection.Egress;
  }

  return dir;
};

export const flowTypeFromPb = (ft: flowpb.FlowType): FlowType => {
  let t = FlowType.Unknown;

  if (ft === flowpb.FlowType.L3_L4) {
    t = FlowType.L34;
  } else if (ft === flowpb.FlowType.L7) {
    t = FlowType.L7;
  }

  return t;
};

export const flowTypeFromStr = (str: string): FlowType => {
  let t = FlowType.Unknown;

  if (!str) return t;
  str = str.toLowerCase();

  if (str.startsWith('l3_l4')) t = FlowType.L34;
  if (str.startsWith('l7')) t = FlowType.L7;

  return t;
};

export const endpointFromPb = (ep: flowpb.Endpoint | undefined): Endpoint | undefined => {
  if (ep == null) return void 0;

  return { ...ep, podName: ep.pod_name, id: ep.ID };
};

export const endpointFromObj = (obj: any): Endpoint | null => {
  if (obj == null) return null;

  return {
    id: obj.id,
    identity: obj.identity,
    namespace: obj.namespace,
    labels: (obj.labels || obj.labelsList || []).slice(),
    workloads: [],
    podName: obj.podName,
  };
};

export const ethernetFromPb = (e: flowpb.Ethernet | undefined): Ethernet | undefined => {
  return e ?? void 0;
};

export const ipFromPb = (ip: flowpb.IP | undefined): IP | undefined => {
  if (ip == null) return undefined;

  let ipVersion = IPVersion.NotUsed;
  const fipVersion = ip.ipVersion;

  if (fipVersion === flowpb.IPVersion.IPv4) {
    ipVersion = IPVersion.V4;
  } else if (fipVersion == flowpb.IPVersion.IPv6) {
    ipVersion = IPVersion.V6;
  }

  return { ...ip, ipVersion };
};

export const ipFromObj = (ip: any | null): IP | null => {
  if (ip == null) return null;

  const ipVersion = ipVersionFromStr(ip.ipVersion);

  return {
    source: ip.source,
    destination: ip.destination,
    ipVersion,
    encrypted: !!ip.encrypted,
  };
};

export const ipVersionFromStr = (ipv: string): IPVersion => {
  if (!ipv) return IPVersion.NotUsed;
  ipv = ipv.toLowerCase();

  if (ipv.startsWith('ipv4')) return IPVersion.V4;
  if (ipv.startsWith('ipv6')) return IPVersion.V6;

  return IPVersion.NotUsed;
};

export const l4FromPb = (l4: flowpb.Layer4 | undefined): Layer4 | undefined => {
  if (l4 == null) return undefined;

  let tcp: TCP | undefined = undefined;
  let udp: UDP | undefined = undefined;
  let icmpv4: ICMPv4 | undefined = undefined;
  let icmpv6: ICMPv6 | undefined = undefined;

  if (l4.protocol.oneofKind === 'TCP') {
    tcp = tcpFromPb(l4.protocol.TCP);
  }

  if (l4.protocol.oneofKind === 'UDP') {
    udp = udpFromPb(l4.protocol.UDP);
  }

  if (l4.protocol.oneofKind === 'ICMPv4') {
    icmpv4 = icmpv4FromPb(l4.protocol.ICMPv4);
  }

  if (l4.protocol.oneofKind === 'ICMPv6') {
    icmpv6 = icmpv6FromPb(l4.protocol.ICMPv6);
  }

  return { tcp, udp, icmpv4, icmpv6 };
};

export const l4FromObj = (l4: any): Layer4 | null => {
  if (l4 == null) return null;
  const parsed: Layer4 = {};

  const icmpv4 = l4.icmPv4 ?? l4.ICMPv4 ?? l4.icmpv4 ?? l4.icmpV4;
  const icmpv6 = l4.icmPv6 ?? l4.ICMPv6 ?? l4.icmpv6 ?? l4.icmpV6;

  if (l4.tcp != null && l4.tcp.flags != null) {
    parsed.tcp = {
      sourcePort: l4.tcp.sourcePort,
      destinationPort: l4.tcp.destinationPort,
      flags: tcpFlagsFromObject(l4.tcp.flags)!,
    };
  }

  if (l4.udp != null) {
    parsed.udp = {
      sourcePort: l4.udp.sourcePort,
      destinationPort: l4.udp.destinationPort,
    };
  }

  if (icmpv4 != null) {
    parsed.icmpv4 = {
      type: icmpv4.type,
      code: icmpv4.code,
    };
  }

  if (icmpv6 != null) {
    parsed.icmpv6 = {
      type: icmpv6.type,
      code: icmpv6.code,
    };
  }

  return parsed;
};

export const tcpFlagsFromObject = (obj: any | flowpb.TCPFlags): TCPFlags | null => {
  if (obj == null) return null;

  if (obj.toObject != null) obj = obj.toObject();

  return {
    fin: !!obj.fin || !!obj['FIN'] || !!obj.fIN,
    syn: !!obj.syn || !!obj['SYN'] || !!obj.sYN,
    rst: !!obj.rst || !!obj['RST'] || !!obj.rST,
    psh: !!obj.psh || !!obj['PSH'] || !!obj.pSH,
    ack: !!obj.ack || !!obj['ACK'] || !!obj.aCK,
    urg: !!obj.urg || !!obj['URG'] || !!obj.uRG,
    ece: !!obj.ece || !!obj['ECE'] || !!obj.eCE,
    cwr: !!obj.cwr || !!obj['CWR'] || !!obj.cWR,
    ns: !!obj.ns || !!obj['NS'] || !!obj.nS,
  };
};

export const tcpFromPb = (tcp: flowpb.TCP): TCP => {
  return {
    sourcePort: tcp.source_port,
    destinationPort: tcp.destination_port,
    flags: tcp.flags ? tcpFlagsFromPb(tcp.flags) : void 0,
  };
};

export const tcpFlagsFromPb = (flags: flowpb.TCPFlags): TCPFlags => {
  return tcpFlagsFromObject(flags)!;
};

export const udpFromPb = (udp: flowpb.UDP): UDP => {
  return {
    ...udp,
    destinationPort: udp.destination_port,
    sourcePort: udp.source_port,
  };
};

export const icmpv4FromPb = (icmp: flowpb.ICMPv4): ICMPv4 => {
  return icmp;
};

export const icmpv6FromPb = (icmp: flowpb.ICMPv6): ICMPv6 => {
  return icmpv4FromPb(icmp);
};

export const hubblePolicyFromPb = (policy: flowpb.Policy): HubblePolicy => {
  const [labels, uuid] = parsePolicyLabels(policy.labels);

  return {
    name: policy.name,
    namespace: policy.namespace,
    labels,
    uuid: uuid || void 0,
    revision: policy.revision,
  };
};

export const hubblePoliciesFromArr = (arr: any[]): HubblePolicy[] => {
  if (arr == null) return [];
  const result: HubblePolicy[] = [];

  arr?.forEach(hp => {
    const parsed = hubblePolicyFromObj(hp);
    if (parsed == null) return;

    result.push(parsed);
  });

  return result;
};

export const hubblePolicyFromObj = (obj: any): HubblePolicy | null => {
  if (obj == null) return null;

  const [labels, uuid] = parsePolicyLabels(obj.labels);

  return {
    name: obj.name,
    namespace: obj.namespace,
    labels,
    uuid: uuid || void 0,
    revision: obj.revision,
  };
};

const parsePolicyLabels = (lbls: any): [KV[], string | null] => {
  if (lbls == null || !Array.isArray(lbls)) return [[], null];
  let uuid: string | null = null;

  const labels: KV[] = new Array(lbls.length);

  lbls.forEach((lbl, idx) => {
    if (!_.isString(lbl)) return;

    const kv = Labels.toKV(lbl, true);
    labels[idx] = kv;

    if (!uuid) {
      uuid = Labels.getPolicyUUID(kv, false);
    }
  });

  return [labels, uuid];
};
