import { DataLayer } from '~/data-layer';
import { Application } from '~/domain/common';
import { Router } from '~/router';
import { Store } from '~/store';

import { Options } from './common';

export class Cimulator {
  private readonly store: Store;
  private readonly router: Router;
  private readonly dataLayer: DataLayer;

  constructor(opts: Options) {
    this.store = opts.store;
    this.router = opts.router;
    this.dataLayer = opts.dataLayer;
  }

  public async appToggled(next: Application, isChanged: boolean) {
    if (!isChanged) return;

    switch (next) {
      case Application.Cimulator: {
        break;
      }
    }
  }
}
