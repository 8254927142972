import React from 'react';

import { Spinner } from '@blueprintjs/core';
import classnames from 'classnames';
import * as mobx from 'mobx';
import { observer } from 'mobx-react';

import { HubbleNode, HubbleNodeStateMap, HubbleServerStatus } from '~/domain/hubble';
import { Status } from '~/domain/status';

import css from './index.scss';
import { SortableTH } from '../Table/SortableTH';
import { Direction, useSortableTableColumn } from '../Table/useSortableColumn';

export enum Columns {
  Name = 'name',
  Status = 'status',
  StartTime = 'start-time',
  FlowsPerSec = 'flows-per-sec',
  CurMaxFlows = 'cur-max-flows',
  Version = 'version',
  Address = 'address',
  TLS = 'tls',
}

export type Props = {
  status: Status | null;
};

export const HubbleNodes = observer(function HubbleNodes(props: Props) {
  const sorter = useSortableTableColumn<Columns>(Columns.Name);

  const nodes = mobx
    .computed(() => {
      return sortNodes(props.status?.nodes || [], sorter.column, sorter.direction);
    })
    .get();

  return (
    <div className={css.wrapper}>
      <table cellPadding={0} cellSpacing={0}>
        <caption>
          <div className={css.captionInner}>
            <div className={css.leftArea}>
              Hubble Nodes
              {props.status == null && (
                <span className={css.loadingArea}>
                  <Spinner size={16} /> <div>Updating...</div>
                </span>
              )}
              {props.status && <NodesMiniInfo server={props.status.status} />}
            </div>
            {props.status && (
              <div>
                <FlowsMiniInfo server={props.status.status} />
              </div>
            )}
          </div>
        </caption>
        {nodes.length > 0 && (
          <thead>
            <tr>
              <SortableTH column={Columns.Name} sorter={sorter}>
                Name
              </SortableTH>
              <SortableTH column={Columns.Status} sorter={sorter}>
                Status
              </SortableTH>
              <SortableTH column={Columns.StartTime} sorter={sorter}>
                Start&nbsp;Time
              </SortableTH>
              <SortableTH
                column={Columns.FlowsPerSec}
                sorter={sorter}
                childrenClassName={css.alignedRight}
              >
                Flows/s
              </SortableTH>
              <SortableTH
                column={Columns.CurMaxFlows}
                sorter={sorter}
                childrenClassName={css.alignedRight}
              >
                Cur/Max&nbsp;Flows
              </SortableTH>
              <SortableTH column={Columns.Version} sorter={sorter}>
                Version
              </SortableTH>
              <SortableTH column={Columns.Address} sorter={sorter}>
                Address
              </SortableTH>
              <SortableTH column={Columns.TLS} sorter={sorter}>
                TLS
              </SortableTH>
            </tr>
          </thead>
        )}
        {nodes.length > 0 && (
          <tbody>
            {nodes.map(node => (
              <HubbleNodeRow key={node.hash} node={node} />
            ))}
          </tbody>
        )}
      </table>
    </div>
  );
});

function HubbleNodeRow({ node }: { node: HubbleNode }): JSX.Element {
  const stateClassName = classnames({
    [css.enabled]: node.state === HubbleNodeStateMap.NodeConnected,
    [css.muted]:
      node.state === HubbleNodeStateMap.NodeGone ||
      node.state === HubbleNodeStateMap.UnknownNodeState,
    [css.disabled]:
      node.state === HubbleNodeStateMap.NodeError ||
      node.state === HubbleNodeStateMap.NodeUnavailable,
  });

  const tlsClassName = classnames({
    [css.enabled]: node.tls,
    [css.disabled]: !node.tls,
  });

  return (
    <tr>
      <td>{node.name}</td>
      <td className={stateClassName}>{node.humanState}</td>
      <td className={css.noTextWrap}>{node.humanStartTime}</td>
      <td className={css.alignedRight}>{node.flowsPerSecond.toFixed(1)}</td>
      <td className={css.alignedRight}>
        {node.numFlows}/{node.maxFlows}
      </td>
      <td className={css.noTextWrap}>{node.version}</td>
      <td>{node.address}</td>
      <td className={tlsClassName}>{node.tls ? 'Enabled' : 'Disabled'}</td>
    </tr>
  );
}

function NodesMiniInfo({ server }: { server: HubbleServerStatus }): JSX.Element | null {
  if (server.totalNodes === null) return null;
  if (server.hasUnavailableNodes === null) return null;

  const numConnNodesClassName = classnames({
    [css.disabled]: server.hasUnavailableNodes,
  });

  return (
    <span className={css.hubbleNodesMiniInfo}>
      (<span className={numConnNodesClassName}>{server.numConnectedNodes}</span>/{server.totalNodes}
      )
    </span>
  );
}

function FlowsMiniInfo({ server }: { server: HubbleServerStatus }): JSX.Element | null {
  const flowsPerSec = HubbleNode.calcFlowsPerSecond(server);

  return (
    <span className={css.hubbleFlowsMiniInfo}>
      Cur/Max Flows:{' '}
      <b>
        {server.numFlows}/{server.maxFlows}
      </b>{' '}
      | Flows/s: <b>{flowsPerSec.toFixed(1)}</b>
    </span>
  );
}

function sortNodes(nodes: HubbleNode[], column: Columns, direction: Direction): HubbleNode[] {
  const swapper = (a: HubbleNode, b: HubbleNode): [HubbleNode, HubbleNode] => {
    return direction === Direction.ASC ? [a, b] : [b, a];
  };

  const sort = (comparator: (a: HubbleNode, b: HubbleNode) => number) => {
    return [...nodes].sort((a, b) => comparator(...swapper(a, b)));
  };

  switch (column) {
    case Columns.Name:
      return sort((a, b) => a.name.localeCompare(b.name));
    case Columns.Status:
      return sort((a, b) => a.state - b.state);
    case Columns.StartTime:
      return sort((a, b) => (a.startTime > b.startTime ? 1 : -1));
    case Columns.FlowsPerSec:
      return sort((a, b) => a.flowsPerSecond - b.flowsPerSecond);
    case Columns.CurMaxFlows:
      return sort((a, b) => a.maxFlows - a.numFlows - (b.maxFlows - b.numFlows));
    case Columns.Version:
      return sort((a, b) => a.version.localeCompare(b.version));
    case Columns.Address:
      return sort((a, b) => a.address.localeCompare(b.address));
    case Columns.TLS:
      return sort((a, b) => (a.tls ? 1 : 0) - (b.tls ? 1 : 0));
  }
}
