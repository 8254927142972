import _ from 'lodash';
import { makeAutoObservable } from 'mobx';

import { K8SEvent, TimescapeK8SEventKind } from '~/domain/timescape/k8s-events';

export default class K8SEventsStore {
  private events: K8SEvent[];

  constructor() {
    this.events = [];
    makeAutoObservable(this, void 0, {
      autoBind: true,
    });
  }

  public clone(deep = false): K8SEventsStore {
    const store = new K8SEventsStore();
    store.events = deep ? _.cloneDeep(this.events) : this.events.slice();
    return store;
  }

  public clear() {
    this.events = [];
  }

  public get policyEventsList() {
    return this.events.filter(event => {
      return (
        event.raw.kind === TimescapeK8SEventKind.KUBERNETES_NETWORK_POLICY ||
        event.raw.kind === TimescapeK8SEventKind.CILIUM_NETWORK_POLICY ||
        event.raw.kind === TimescapeK8SEventKind.CILIUM_CLUSTERWIDE_NETWORK_POLICY
      );
    });
  }

  public replacePolicyEvents(events: K8SEvent[]) {
    this.events = this.events
      .filter(event => {
        return (
          event.raw.kind !== TimescapeK8SEventKind.KUBERNETES_NETWORK_POLICY &&
          event.raw.kind !== TimescapeK8SEventKind.CILIUM_NETWORK_POLICY &&
          event.raw.kind !== TimescapeK8SEventKind.CILIUM_CLUSTERWIDE_NETWORK_POLICY
        );
      })
      .concat(events);
  }
}
