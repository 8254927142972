import React, { useEffect, useMemo } from 'react';

import { Button, Intent, Switch } from '@blueprintjs/core';
import { observer } from 'mobx-react';

import { PolicyCard } from '~/domain/cimulator/cards';
import { PolicyEndpoint } from '~/domain/cimulator/endpoint';
import { PolicyKind } from '~/domain/cimulator/types';
import { useStore } from '~/store/stores/cimulator';

import css from './CrudActions.scss';
import { RuleKind } from './general';
import { RuleKindSelector } from './RuleKindSelector';

export interface Props {
  card: PolicyCard;
  endpoint?: PolicyEndpoint | null;
  ruleKind: RuleKind | null;
  canSubmit: boolean;
  onSubmit?: () => void;
  onDeny?: () => void;
  onDelete?: () => void;
  onChangeEndpoint?: (endpoint?: PolicyEndpoint) => void;
  onCanSubmitChange?: (state: boolean) => void;
  onRuleKindChange?: (kind: RuleKind) => void;
}

export const CrudActions = observer(function CrudActions(props: Props) {
  const store = useStore();

  const actions: JSX.Element[] = [];

  const isEndpointAllowed = useMemo(() => {
    if (!props.endpoint) return false;
    return store.policy.contextual.isAllowedEndpoint(props.card.fullEndpointId(props.endpoint.id));
  }, [props.card, props.endpoint, store.policy.contextual.allowedEndpointsSet]);

  const dir = props.card.isIngress ? 'from' : 'to';

  const isEditableEndpoint = useMemo(() => {
    return Boolean(
      props.endpoint && !props.endpoint.isAllWithoutPorts && !props.endpoint.isKubeDns,
    );
  }, [props.endpoint]);

  const submitAction = useMemo(() => {
    if (props.endpoint && !isEndpointAllowed) {
      return 'allow';
    }
    return props.endpoint ? 'edit' : 'add';
  }, [props.card, props.endpoint, isEndpointAllowed]);

  const showDenyButton = useMemo(() => {
    return (
      props.endpoint && store.policy.isAllowedEndpoint(props.card.fullEndpointId(props.endpoint.id))
    );
  }, [props.card, props.endpoint]);

  const showDeleteButton = useMemo(() => {
    if (showDenyButton) return false;

    return props.endpoint && !props.endpoint.isAllWithoutPorts && !props.endpoint.isKubeDns;
  }, [props.endpoint, showDenyButton]);

  const [submitIntent, submitText] = useMemo((): [Intent, string] => {
    switch (submitAction) {
      case 'allow': {
        if (!props.endpoint?.isAll) return ['success', 'Allow rule'];

        return [
          'none',
          props.card.isOutsideCluster
            ? `Allow ${dir} any endpoint`
            : props.card.isAll
              ? `Allow ${dir} all`
              : `Allow ${dir} any pod`,
        ];
      }
      case 'add':
        return ['success', 'Add rule'];
      case 'edit':
        return ['success', 'Save'];
    }
  }, [submitAction, props.card, props.endpoint, dir]);

  const disableSubmitButton = useMemo(() => {
    return Boolean(!props.canSubmit);
  }, [props.canSubmit]);

  const showSubmitButton = useMemo(() => {
    return props.ruleKind !== null && (isEditableEndpoint || !showDenyButton);
  }, [props.ruleKind, props.card, isEditableEndpoint]);

  useEffect(() => {
    props.onCanSubmitChange?.(showSubmitButton);
  });

  const showDNSProxyCheckbox = useMemo(() => {
    return (
      props.endpoint &&
      props.endpoint.isKubeDns &&
      store.controls.policyKind === PolicyKind.CNP &&
      isEndpointAllowed
    );
  }, [
    props.card,
    props.endpoint,
    props.endpoint?.isKubeDns,
    isEndpointAllowed,
    store.controls.policyKind,
  ]);

  const showAllEndpointActions = useMemo(() => {
    return props.endpoint && !isEndpointAllowed && props.ruleKind === RuleKind.All;
  }, [props.endpoint, isEndpointAllowed, props.ruleKind]);

  const showAuthenticationCheckbox = useMemo(() => {
    return props.endpoint && store.controls.policyKind === PolicyKind.CNP && isEndpointAllowed;
  }, [props.card, props.endpoint, isEndpointAllowed, store.controls.policyKind]);

  showAuthenticationCheckbox &&
    actions.push(
      <Switch
        key="authentication"
        className={css.action}
        style={{ marginRight: '20px' }}
        checked={Boolean(props.endpoint?.isAuthenticationEnabled)}
        onChange={props.endpoint?.toggleAuthentication}
        label="Mutual Auth"
      />,
    );

  showDNSProxyCheckbox &&
    actions.push(
      <Switch
        key="dns-proxy"
        className={css.action}
        style={{ marginRight: '20px' }}
        checked={Boolean(props.endpoint?.isDNSProxyEnabled)}
        onChange={props.endpoint?.toggleDNSProxy}
        label="DNS proxy"
      />,
    );

  showDenyButton &&
    actions.push(
      <Button
        small
        key="deny"
        intent="danger"
        className={css.action}
        onClick={props.onDeny}
        tabIndex={-1}
      >
        Delete
      </Button>,
    );

  showDeleteButton &&
    actions.push(
      <Button
        key="delete"
        small
        intent="danger"
        className={css.action}
        onClick={props.onDelete}
        tabIndex={-1}
      >
        Delete
      </Button>,
    );

  showSubmitButton &&
    actions.push(
      <Button
        small
        fill={actions.length > 2}
        key="submit"
        className={css.action}
        type="submit"
        intent={submitIntent}
        disabled={disableSubmitButton}
      >
        {submitText}
      </Button>,
    );

  if (showAllEndpointActions) {
    if (props.card.isOutsideCluster) {
      if (props.card.isEgress || props.card.isIngress) {
        actions.length = 0;
        actions.push(
          <RuleKindSelector
            key="rule-kind-selector"
            card={props.card}
            onChange={kind => {
              if (!kind) return;
              props.onChangeEndpoint?.(undefined);
              props.onRuleKindChange?.(kind);
            }}
          />,
        );
      }
    } else if (props.card.isAll) {
      actions.push(
        <Button
          key="allow all"
          small
          intent="success"
          className={css.action}
          tabIndex={-1}
          onClick={(e: React.MouseEvent) => {
            e.stopPropagation();
            props.onChangeEndpoint?.(undefined);
            props.onRuleKindChange?.(RuleKind.All);
          }}
        >
          Allow {dir} ports
        </Button>,
      );
    } else {
      actions.push(
        <Button
          key="allow pod selector"
          small
          intent="success"
          className={css.action}
          tabIndex={-1}
          onClick={(e: React.MouseEvent) => {
            e.stopPropagation();
            props.onChangeEndpoint?.(undefined);
            props.onRuleKindChange?.(RuleKind.PodSelector);
          }}
        >
          Allow {dir} pod selector
        </Button>,
      );
    }
  }

  return actions.length ? <div className={css.wrapper}>{actions}</div> : null;
});
