import { DataLayer } from '~/data-layer';
import { Router } from '~/router';
import { Store } from '~/store';

import { UILayer } from './ui-layer';

export class UILayerBuilder {
  private dataLayer?: DataLayer;
  private store?: Store;
  private router?: Router;

  private isCSSVarsInjectionEnabled?: boolean;

  public static new() {
    return new UILayerBuilder();
  }

  public withDataLayer(dl?: DataLayer): this {
    this.dataLayer = dl;
    return this;
  }

  public withStore(s?: Store): this {
    this.store = s;
    return this;
  }

  public withRouter(r?: Router): this {
    this.router = r;
    return this;
  }

  public withCSSVarsInjection(enabled?: boolean): this {
    this.isCSSVarsInjectionEnabled = !!enabled;
    return this;
  }

  public build() {
    if (this.dataLayer == null) throw this.err('DataLayer');
    if (this.router == null) throw this.err('Router');
    if (this.store == null) throw this.err('Store');

    return new UILayer(this.router, this.store, this.dataLayer, !!this.isCSSVarsInjectionEnabled);
  }

  private err(comp: string): Error {
    return new Error(`Failed to build UILayer: ${comp} is not set`);
  }
}
