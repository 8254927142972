import * as React from 'react';

import { Card, H5, Tag } from '@blueprintjs/core';
import { observer } from 'mobx-react';

import { AssistantEmitterActions, emitter } from '~/store/stores/assistant/emitter';
import { useStore } from '~/store/stores/cimulator';

import { Vulnerability, VulnerabilitySeverity } from './types';
import css from './Vulnerabilities.scss';

export const Vulnerabilities = observer(function Vulnerabilities() {
  const store = useStore();

  const vulnerabilities: Vulnerability[] = [
    {
      id: '1',
      message: 'Allowed egress access to non-approved FQDN',
      description:
        'Policy egress rule allows access to fqdn domain that is not in the list approved by security team',
      severity: VulnerabilitySeverity.High,
      ruleRef:
        'egress/world -> fqdn/d031a68dbe212840a0b099bbbe111934e4682a50:109085beaaa80ac89858b283a64f7c75d7e5bb12',
    },
    {
      id: '2',
      message: 'No default deny on ingress',
      description: 'No default deny on ingress',
      severity: VulnerabilitySeverity.High,
    },
  ];

  const [currentRuleRef, setCurrentRuleRef] = React.useState<{
    clicked: boolean;
    ref: string;
  } | null>(null);

  React.useEffect(() => {
    if (!currentRuleRef) {
      emitter.emit(AssistantEmitterActions.CloseCardEndpointPopup);
      return;
    }

    store.policy.cardsList?.forEach(card => {
      card.endpointsList.forEach(endpoint => {
        const fullEndpointId = card.fullEndpointId(endpoint.id);
        if (!store.policy.isAllowedEndpoint(fullEndpointId)) return;
        if (fullEndpointId === currentRuleRef.ref) {
          if (currentRuleRef.clicked) {
            store.controls.selectElement(card, endpoint);
          }
          emitter.emit(AssistantEmitterActions.OpenCardEndpointPopup, card, endpoint);
        }
      });
    });
  }, [currentRuleRef, store.policy.allowedEndpointsSet]);

  return (
    <div className={css.wrapper}>
      {vulnerabilities.map(vuln => {
        const onClick = (event: React.MouseEvent) => {
          event.stopPropagation();
          event.preventDefault();
          if (!vuln.ruleRef) return;
          setCurrentRuleRef({ clicked: true, ref: vuln.ruleRef });
        };
        const onMouseEnter = () => {
          if (!vuln.ruleRef) return;
          setCurrentRuleRef({ clicked: false, ref: vuln.ruleRef });
        };
        const onMouseLeave = () => {
          if (!vuln.ruleRef || currentRuleRef?.clicked) return;
          setCurrentRuleRef(null);
        };

        return (
          <Card
            key={vuln.id}
            className={css.item}
            elevation={currentRuleRef?.clicked && vuln.ruleRef === currentRuleRef?.ref ? 3 : 0}
            interactive={Boolean(vuln.ruleRef)}
            onClick={onClick}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          >
            <H5>{vuln.message}</H5>
            <p>{vuln.description}</p>
            <Tag intent="danger">{cap(vuln.severity)} severity</Tag>
          </Card>
        );
      })}
    </div>
  );
});

function cap(s: string) {
  return `${s[0].toUpperCase()}${s.slice(1)}`;
}
