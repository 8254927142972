// S I Z E S

// this sizes map uses for both js calculations and css styles
// all sizes in this map should be considered in pixels
export const sizes = {
  endpointWidth: 560,
  defaultCardW: 560,
  defaultCardH: 0,
  endpointShadowSize: 24,
  endpointIconWidth: 80,
  endpointHeaderheight: 115,
  endpointTitleHeight: 29,
  endpointSubtitleHeight: 12,
  endpointHPadding: 300,
  endpointVPadding: 200,
  searchBarHeight: 50,
  detailPanelResizeBarHeight: 30,
  sidebarWidth: 240,
  endpointFunctionHeight: 16,
  distanceBetweenConnectors: 50,
  connectorCardStartGap: 40,
  connectorCardEndGap: 120,
  minArrowLength: 50,
  arrowStartTopOffset: 50,
  arrowHandleWidth: 20,
  arrowRadius: 5,
  arrowOverlapGap: 20,
  aroundCardPadX: 40,
  aroundCardPadY: 40,
  endpointProtocolHeight: 16,
  egressConnectorWidth: 38,
  ingressConnectorPadding: 24,
  linkWidth: 2,
  linkHighlightedWidth: 3,
  linkCurveRadius: 8,
  connectorWidth: 3,
  connectorHighlightedWidth: 4,
  flowsTableRowHeight: 20,
  flowsTableHeadHeight: 24,
  feetInnerWidth: 2,
  feetInnerWidthThick: 5,
  feetInnerHighlightedWidth: 3,
  feetInnerHighlightedWidthThick: 6,
  topBarHeight: 50,
  arrowHandleHWRatio: 10.87 / 11.61,
  processEventRowHeight: 25,
  throughputIndicatorWidth: 2,
  throughputIndicatorDotRadius: 2,
  namespaceBackplatePadding: 200,
  filterGroupTopBarHeight: 50,
};

// C O L O R S
export const colors = {
  startPlateStroke: '#C7D0DA',
  startPlateFill: '#C7D0DA',
  arrowStroke: '#C7D0DA',
  arrowStrokeHighlighted: '#8F9DAE',
  arrowStrokeGreen: '#7ED268',
  arrowStrokeRed: '#C50000',
  arrowHandle: '#C2C8D0',
  arrowHandleHighlighted: '#758394',
  connectorFill: '#C7D0DA',
  connectorHighlightedFill: '#AEB7C0',
  connectorStroke: '#B4BCC6',
  connectorHighlightedStroke: '#58626D',
  connectorFillGreen: '#7ED268',
  connectorStrokeGreen: '#7ED268',
  connectorStrokeRed: '#C50000',
  connectorFillRed: '#C50000',
  feetOuterStroke: '#EBEEF0',
  feetNeutralStroke: '#C1C8D0',
  feetRedStroke: '#BC7677',
  feetHighlightedRedStroke: '#C50001',
  throughputIndicatorStroke: '#A7B9C3',
  throughputIndicatorFill: '#FFFFFF',
  throughputIndicatorDot: '#728998',
  hoveredArrow: '#01A4FF',
};

export const zIndex = {
  bottomPanel: 20,
  slackButton: 21,
  loadingOverlay: 30,
  alertsPortal: 40,
  popovers: 41,
  tooltips: 42,
  smallScreenOverlay: 50,
};
