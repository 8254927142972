import React from 'react';

import css from './FilterDeleteIcon.scss';

export const FilterDeleteIcon = function FilterDeleteIcon() {
  const filterDeleteIconSrc = `/icons/misc/cross-icon.svg`;

  return (
    <div className={css.icon}>
      <img src={filterDeleteIconSrc} />
    </div>
  );
};
