import React from 'react';

import { ButtonGroupFilter } from '../ButtonGroupFilter/ButtonGroupFilter';
import { CheckboxFilter } from '../CheckboxFilter/CheckboxFilter';
import { InputFilter } from '../InputFilter/InputFilter';
import { SelectFilter } from '../SelectFilter/SelectFilter';
import { FlowFilterGroupFormBaseComponentProps, FlowFilterGroupFormItemProps } from '../types';

const defaultComponents: {
  [key: string]: React.FC<FlowFilterGroupFormBaseComponentProps>;
} = {
  input: InputFilter,
  select: SelectFilter,
  checkbox: CheckboxFilter,
  buttongroup: ButtonGroupFilter,
};

export const FlowFilterGroupFormItem = function FlowFilterGroupFormItem(
  props: FlowFilterGroupFormItemProps,
) {
  const { config, value, onChange } = props;
  const { component } = config;

  if (typeof component === 'string') {
    const Component = defaultComponents[
      component
    ] as React.FC<FlowFilterGroupFormBaseComponentProps>;

    if (!Component) {
      throw new Error(
        `${component} is unsupported. Please provide one of the following: ${Object.keys(
          defaultComponents,
        ).join(', ')}`,
      );
    }

    return <Component config={config} filterState={value} onFilterChange={onChange} />;
  }

  return component;
};
