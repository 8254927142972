import * as mobx from 'mobx';

import {
  BackendAPI,
  ServiceMapFromLogsOneshot,
  ServiceMapStream,
  TimescapeServiceMapOneshot,
} from '~/api/customprotocol';
import { TimescapeK8SEventsParams } from '~/api/customprotocol/timescape-k8s-events-oneshot';
import { CustomError } from '~/api/customprotocol-core/errors';
import { EventParams } from '~/api/general/event-stream';
import { Paginated } from '~/api/general/pagination';
import { CountStats } from '~/domain/common';
import { FilterGroup, FiltersDiff } from '~/domain/filtering';
import { Flow } from '~/domain/flows';
import { FlowDigest } from '~/domain/flows/common';
import { DataMode, TransferState } from '~/domain/interactions';
import { StreamKind } from '~/domain/interactions/reconnect-state';
import { ServiceMap as DomainServiceMap } from '~/domain/service-map';
import { TimescapeData } from '~/domain/timescape';
import { K8SEvent, TimescapeK8SEvent } from '~/domain/timescape/k8s-events';
import { Store, StoreFrame } from '~/store';
import { APIStatus } from '~/utils/api';
import { EventEmitter } from '~/utils/emitter';
import { logger } from '~/utils/logger';
import { Retries } from '~/utils/retry';
import * as tsmappb from '~backend/proto/timescape/map/v1/map_pb';

import { Options } from './common';
import { ConnectEvent } from './connect-event';
import { Controls } from './controls';

export enum Event {
  FlowsDiff = 'flows-diff-count',
  FlowFiltersShouldBeChanged = 'filter-entries-should-be-changed',
  ConnectEvent = 'connect-event',

  TimescapeFlowStatsLoadingStarted = 'timescape-flow-stats-loading-started',
  TimescapeFlowStatsLoadingFinished = 'timescape-flow-stats-loading-finished',
  TimescapeFlowStatsLoadingFailed = 'timescape-flow-stats-loading-failed',

  TimescapeK8SPolicyEventsLoadingStarted = 'timescape-k8s-policy-events-loading-started',
  TimescapeK8SPolicyEventsLoadingFinished = 'timescape-k8s-policy-events-loading-finished',
  TimescapeK8SPolicyEventsLoadingFailed = 'timescape-k8s-policy-events-loading-failed',

  ConnectionsMapLoadingStarted = 'connections-map-loading-started',
  ConnectionsMapLoadingFinished = 'connections-map-loading-finished',
  ConnectionsMapLoadingFailed = 'connections-map-loading-failed',

  TimescapeFlowsPageLoadingStarted = 'timescape-flows-page-loading-started',
  TimescapeFlowsPageLoadingFinished = 'timescape-flows-page-loading-finished',
  TimescapeFlowsPageLoadingFailed = 'timescape-flows-page-loading-failed',

  FullFlowLoadingStarted = 'full-flow-loading-started',
  FullFlowLoadingFinished = 'full-flow-loading-finished',
  FullFlowLoadingFailed = 'full-flow-loading-failed',

  ServiceMapLogsUploadingStarted = 'service-map-logs-uploading-started',
  ServiceMapLogsUploadingFailed = 'service-map-logs-uploading-failed',
  ServiceMapLogsUploadingFinished = 'service-map-logs-uploading-finished',
}

export type Handlers = {
  [Event.FlowsDiff]: (dc: number, f: StoreFrame) => void;
  [Event.FlowFiltersShouldBeChanged]: (group: FilterGroup[]) => void;
  [Event.ConnectEvent]: (rs: ConnectEvent) => void;

  [Event.TimescapeFlowStatsLoadingStarted]: () => void;
  [Event.TimescapeFlowStatsLoadingFinished]: () => void;
  [Event.TimescapeFlowStatsLoadingFailed]: (err: CustomError) => void;

  [Event.TimescapeK8SPolicyEventsLoadingStarted]: () => void;
  [Event.TimescapeK8SPolicyEventsLoadingFinished]: () => void;
  [Event.TimescapeK8SPolicyEventsLoadingFailed]: (err: CustomError) => void;

  [Event.ConnectionsMapLoadingStarted]: () => void;
  [Event.ConnectionsMapLoadingFinished]: () => void;
  [Event.ConnectionsMapLoadingFailed]: (err: CustomError) => void;

  [Event.TimescapeFlowsPageLoadingStarted]: () => void;
  [Event.TimescapeFlowsPageLoadingFinished]: () => void;
  [Event.TimescapeFlowsPageLoadingFailed]: (err: CustomError) => void;

  [Event.FullFlowLoadingStarted]: () => void;
  [Event.FullFlowLoadingFinished]: () => void;
  [Event.FullFlowLoadingFailed]: (err: CustomError) => void;

  [Event.ServiceMapLogsUploadingStarted]: () => void;
  [Event.ServiceMapLogsUploadingFinished]: () => void;
  [Event.ServiceMapLogsUploadingFailed]: (err: CustomError) => void;
};

export class ServiceMap extends EventEmitter<Handlers> {
  private backendAPI: BackendAPI;
  private store: Store;
  private transferState: TransferState;
  private dataLayerControls: Controls;

  private streamFlags?: Partial<EventParams>;
  private stream: ServiceMapStream | null = null;
  private streamRetries: Retries = Retries.newExponential();

  private flowsPager: Paginated<TimescapeData> | null = null;
  private statsOneshot: TimescapeServiceMapOneshot | null = null;

  private smFromLogsOneshot: ServiceMapFromLogsOneshot | null = null;

  constructor(opts: Options) {
    super(true);

    this.store = opts.store;
    this.backendAPI = opts.backendAPI;
    this.transferState = opts.transferState;
    this.dataLayerControls = opts.dataLayerControls;
  }

  public get isAppActive(): boolean {
    return this.flowsPager != null || this.stream != null;
  }

  public onFlowsDiffCount(fn: Handlers[Event.FlowsDiff]): this {
    this.on(Event.FlowsDiff, fn);
    return this;
  }

  public onFlowFiltersShouldBeChanged(fn: Handlers[Event.FlowFiltersShouldBeChanged]): this {
    this.on(Event.FlowFiltersShouldBeChanged, fn);
    return this;
  }

  public onConnectEvent(fn: Handlers[Event.ConnectEvent]): this {
    this.on(Event.ConnectEvent, fn);
    return this;
  }

  public onTimescapeFlowsPageLoadingStarted(
    fn: Handlers[Event.TimescapeFlowsPageLoadingStarted],
  ): this {
    this.on(Event.TimescapeFlowsPageLoadingStarted, fn);
    return this;
  }

  public onTimescapeFlowsPageLoadingFailed(
    fn: Handlers[Event.TimescapeFlowsPageLoadingFailed],
  ): this {
    this.on(Event.TimescapeFlowsPageLoadingFailed, fn);
    return this;
  }

  public onTimescapeFlowsPageLoadingFinished(
    fn: Handlers[Event.TimescapeFlowsPageLoadingFinished],
  ): this {
    this.on(Event.TimescapeFlowsPageLoadingFinished, fn);
    return this;
  }

  public onTimescapeFlowStatsLoadingStarted(
    fn: Handlers[Event.TimescapeFlowStatsLoadingStarted],
  ): this {
    this.on(Event.TimescapeFlowStatsLoadingStarted, fn);
    return this;
  }

  public onTimescapeFlowStatsLoadingFailed(
    fn: Handlers[Event.TimescapeFlowStatsLoadingFailed],
  ): this {
    this.on(Event.TimescapeFlowStatsLoadingFailed, fn);
    return this;
  }

  public onTimescapeFlowStatsLoadingFinished(
    fn: Handlers[Event.TimescapeFlowStatsLoadingFinished],
  ): this {
    this.on(Event.TimescapeFlowStatsLoadingFinished, fn);
    return this;
  }

  public onTimescapeK8SPolicyEventsLoadingStarted(
    fn: Handlers[Event.TimescapeK8SPolicyEventsLoadingStarted],
  ): this {
    this.on(Event.TimescapeK8SPolicyEventsLoadingStarted, fn);
    return this;
  }

  public onTimescapeK8SPolicyEventsLoadingFailed(
    fn: Handlers[Event.TimescapeK8SPolicyEventsLoadingFailed],
  ): this {
    this.on(Event.TimescapeK8SPolicyEventsLoadingFailed, fn);
    return this;
  }

  public onTimescapeK8SPolicyEventsLoadingFinished(
    fn: Handlers[Event.TimescapeK8SPolicyEventsLoadingFinished],
  ): this {
    this.on(Event.TimescapeK8SPolicyEventsLoadingFinished, fn);
    return this;
  }

  public onConnectionsMapLoadingStarted(fn: Handlers[Event.ConnectionsMapLoadingStarted]): this {
    this.on(Event.ConnectionsMapLoadingStarted, fn);
    return this;
  }

  public onConnectionsMapLoadingFailed(fn: Handlers[Event.ConnectionsMapLoadingFailed]): this {
    this.on(Event.ConnectionsMapLoadingFailed, fn);
    return this;
  }

  public onConnectionsMapLoadingFinished(fn: Handlers[Event.ConnectionsMapLoadingFinished]): this {
    this.on(Event.ConnectionsMapLoadingFinished, fn);
    return this;
  }

  public onFullFlowLoadingStarted(fn: Handlers[Event.FullFlowLoadingStarted]): this {
    this.on(Event.FullFlowLoadingStarted, fn);
    return this;
  }

  public onFullFlowLoadingFailed(fn: Handlers[Event.FullFlowLoadingFailed]): this {
    this.on(Event.FullFlowLoadingFailed, fn);
    return this;
  }

  public onFullFlowLoadingFinished(fn: Handlers[Event.FullFlowLoadingFinished]): this {
    this.on(Event.FullFlowLoadingFinished, fn);
    return this;
  }

  public onServiceMapLogsUploadingStarted(
    fn: Handlers[Event.ServiceMapLogsUploadingStarted],
  ): this {
    this.on(Event.ServiceMapLogsUploadingStarted, fn);
    return this;
  }

  public onServiceMapLogsUploadingFailed(fn: Handlers[Event.ServiceMapLogsUploadingFailed]): this {
    this.on(Event.ServiceMapLogsUploadingFailed, fn);
    return this;
  }

  public onServiceMapLogsUploadingFinished(
    fn: Handlers[Event.ServiceMapLogsUploadingFinished],
  ): this {
    this.on(Event.ServiceMapLogsUploadingFinished, fn);
    return this;
  }

  public async switchToDataMode(dm: DataMode) {
    if (this.store.controls.app.isProcessTree) return;

    await this.resetDataFetch(dm);
  }

  public async resetDataFetch(dm?: DataMode) {
    const dataMode = dm || this.transferState.dataMode;

    await this.dropDataFetch();
    await this.ensureDataFetch(dataMode);
  }

  public async ensureDataFetch(forDataMode?: DataMode) {
    const dm = forDataMode || this.transferState.dataMode;
    logger.log(`service map: ensuring data fetch for mode: ${dm}`);

    if (dm === DataMode.CiliumStreaming) {
      return this.ensureLiveStream();
    } else if (dm == DataMode.WatchingHistory) {
      return this.ensureHistoricalData();
    }
  }

  public async appOpened() {
    await this.dropDataFetch();
    const dataMode = this.transferState.isDisabled
      ? this.store.uiSettings.isTimescapeEnabled
        ? DataMode.WatchingHistory
        : DataMode.CiliumStreaming
      : this.transferState.dataMode;
    await this.ensureDataFetch(dataMode);
  }

  public async dropDataFetch() {
    if (this.stream != null) {
      this.stream.offAllEvents();
      await this.stream.stop();

      this.stream = null;
    }

    if (this.flowsPager != null) {
      await this.flowsPager.stop();
      this.flowsPager = null;
    }

    if (this.statsOneshot != null) {
      await this.statsOneshot.stop();
      this.statsOneshot = null;
    }

    this.transferState.dropReconnectState(StreamKind.Event);
    this.streamRetries.reset();
  }

  public async ensureHistoricalData() {
    if (this.flowsPager != null) return;

    const fetchTimescapeData = () => {
      const promises: Promise<unknown>[] = [
        this.fetchTimescapeFlowsNextPage(),
        this.fetchTimescapeFlowsCountStat(),
        this.fetchTimescapeK8SPolicyEvents(null).then(events =>
          this.saveTimescapeK8SPolicyEvents(events),
        ),
      ];

      return promises;
    };

    const fetchConnectionsMap = () => {
      this.fetchConnectionsMap(this.store.clusterNamespaces.currCluster)
        .then(map => {
          mobx.runInAction(() => {
            this.store.uiSettings.connectionsMapApiState = APIStatus.Available;
            if (this.store.clusterNamespaces.currNamespace) {
              this.store.connectionsMap.replaceWith(tsmappb.ConnectionKind.CLUSTERS, null);
            } else {
              const kind =
                this.store.clusterNamespaces.currCluster === null
                  ? tsmappb.ConnectionKind.CLUSTERS
                  : tsmappb.ConnectionKind.NAMESPACES;
              this.store.connectionsMap.replaceWith(kind, map);
            }
          });
        })
        .catch(error => {
          if (error instanceof Error && error.message.includes('unknown service')) {
            mobx.runInAction(() => {
              this.store.uiSettings.connectionsMapApiState = APIStatus.Unavailable;
            });
          } else {
            throw error;
          }
        })
        .finally(() => {
          if (
            this.store.uiSettings.connectionsMapApiState === APIStatus.Available ||
            this.store.clusterNamespaces.currNamespace
          ) {
            return Promise.all(fetchTimescapeData());
          }
          return Promise.resolve([]);
        });
    };

    if (this.store.uiSettings.connectionsMapApiState === APIStatus.Pending) {
      return fetchConnectionsMap();
    } else if (this.store.clusterNamespaces.currNamespace) {
      this.store.connectionsMap.replaceWith(tsmappb.ConnectionKind.CLUSTERS, null);
      return Promise.all(fetchTimescapeData());
    } else if (this.store.uiSettings.connectionsMapApiState === APIStatus.Available) {
      return fetchConnectionsMap();
    }
  }

  public async fetchTimescapeFlowsNextPage() {
    if (this.flowsPager == null) {
      this.flowsPager = this.backendAPI
        .getTimescapeFlowsPager(this.dataLayerControls.timescapeFilters)
        .onFetchStarted(() => this.emit(Event.TimescapeFlowsPageLoadingStarted))
        .onFetchFailed(err => this.emit(Event.TimescapeFlowsPageLoadingFailed, err))
        .onFetchFinished(() => this.emit(Event.TimescapeFlowsPageLoadingFinished))
        .onPage(timescapeData => {
          if (timescapeData == null) return;
          this.saveTimescapeDataPage(timescapeData);
        });
    }

    await this.flowsPager.fetchNextPage();
  }

  public async fetchTimescapeFlowsCountStat() {
    const filters = this.dataLayerControls.timescapeFilters;
    const oneshot = this.backendAPI.getTimescapeFlowStats(filters);
    this.statsOneshot = oneshot;

    this.emit(Event.TimescapeFlowStatsLoadingStarted);

    const p = new Promise<CountStats[]>((resolve, reject) => {
      oneshot
        .onTimescapeData(d => resolve(d.countStats ?? []))
        .onTerminated(msg => {
          if (msg.errors.length > 0) return reject(msg.errors[0]);

          resolve([]);
        })
        .run();
    });

    return p
      .then(countStats => {
        this.saveTimescapeFlowsCountStat(countStats);
        this.statsOneshot = null;
      })
      .catch(err => {
        this.emit(Event.TimescapeFlowStatsLoadingFailed, err);
        throw err;
      })
      .finally(() => {
        this.emit(Event.TimescapeFlowStatsLoadingFinished);
      });
  }

  public async fetchTimescapeK8SPolicyEvents(resourceUuid: string | null | undefined) {
    const cluster = this.store.clusterNamespaces.currCluster;
    const namespace = this.store.clusterNamespaces.currNamespace;

    const opts: TimescapeK8SEventsParams = resourceUuid
      ? { resourceUuid }
      : { timeRange: this.dataLayerControls.timescapeFilters.filters?.timeRange };

    if (cluster) opts.cluster = cluster;
    if (namespace) opts.namespace = namespace;

    const oneshot = this.backendAPI.getTimescapeK8SPolicyEvents(opts);

    this.emit(Event.TimescapeK8SPolicyEventsLoadingStarted);

    return new Promise<TimescapeK8SEvent[]>((resolve, reject) => {
      oneshot
        .onPolicyEvents(events => resolve(events ?? []))
        .onErrors(err => reject(err[0]))
        .onTerminated(msg => {
          if (msg.errors.length > 0) return reject(msg.errors[0]);

          resolve([]);
        })
        .run();
    })
      .then(events => {
        return events.map(event => new K8SEvent(event));
      })
      .catch(err => {
        this.emit(Event.TimescapeK8SPolicyEventsLoadingFailed, err);
        throw err;
      })
      .finally(() => {
        this.emit(Event.TimescapeK8SPolicyEventsLoadingFinished);
      });
  }

  public async fetchConnectionsMap(cluster: string | null) {
    const oneshot = this.backendAPI.getConnectionsMap({
      cluster,
      dataFilters: this.dataLayerControls.timescapeFilters,
    });
    this.emit(Event.ConnectionsMapLoadingStarted);
    return new Promise<tsmappb.Connections | null>((resolve, reject) => {
      oneshot
        .onConnectionsMap(resolve)
        .onErrors(err => reject(err[0]))
        .onTerminated(msg => {
          if (msg.errors.length > 0) return reject(msg.errors[0]);
          resolve(null);
        })
        .run();
    })
      .catch(err => {
        this.emit(Event.ConnectionsMapLoadingFailed, err);
        throw err;
      })
      .finally(() => {
        this.emit(Event.ConnectionsMapLoadingFinished);
      });
  }

  public async uploadServiceMapLogs(logs: ArrayBuffer) {
    const filters = this.dataLayerControls.timescapeFilters.filters ?? undefined;
    const oneshot = this.backendAPI.getServiceMapFromLogs(logs, filters);
    this.smFromLogsOneshot = oneshot;

    this.emit(Event.ServiceMapLogsUploadingStarted);

    const p = new Promise<DomainServiceMap>((resolve, reject) => {
      oneshot
        .onServiceMap(sm => resolve(sm))
        .onErrors(err => reject(err[0]))
        .run();
    });

    await p
      .then(sm => this.store.applyServiceMapFromLogFile(sm))
      .catch(err => this.emit(Event.ServiceMapLogsUploadingFailed, err))
      .finally(() => {
        this.smFromLogsOneshot = null;
        this.emit(Event.ServiceMapLogsUploadingFinished);
      });
  }

  public ensureLiveStream(): ServiceMapStream | null {
    logger.log('ensuring service map data stream');

    if (!this.store.clusterNamespaces.currNamespace) return null;

    if (this.stream != null) return this.stream;

    this.stream = this.backendAPI
      .serviceMapStream(
        this.store.clusterNamespaces.currNamespace,
        this.dataLayerControls.relayFilters,
        this.streamFlags,
      )
      .onServices(svcs => this.store.currentFrame.applyServiceChanges(svcs))
      .onServiceLinks(links => this.store.currentFrame.applyServiceLinkChanges(links))
      .onFlows(flows => this.handleFlows(this.store.currentFrame, flows))
      .onPolicies(p => this.store.cimulator.policy.applyPolicyChanges(p))
      .onReconnectAttemptFailed((att, err) => this.handleReconnectFail(att, err))
      .onReconnectAttempt((att, d) => this.handleReconnectDelay(att, d))
      .onReconnected(attempt => this.handleReconnected(attempt))
      .onTerminated((_, isStopped) => this.handleTerminated(isStopped))
      .run();

    return this.stream;
  }

  public handleReconnectFail(attempt: number, err: any) {
    this.transferState.updateReconnectState(StreamKind.Event, old => ({
      ...old,
      attempt,
      lastError: err,
    }));

    this.emit(Event.ConnectEvent, ConnectEvent.newFailed().setAttempt(attempt).setError(err));
  }

  public handleReconnectDelay(attempt: number, delay: number) {
    this.transferState.updateReconnectState(StreamKind.Event, old => ({
      ...old,
      attempt,
      delay,
    }));

    this.emit(
      Event.ConnectEvent,
      ConnectEvent.newAttemptDelay().setAttempt(attempt).setDelay(delay),
    );
  }

  public handleReconnected(att: number) {
    const states = this.transferState.reconnectStates;
    const isAllReconnected = states.size === 1 && states.has(StreamKind.Event);

    this.transferState.dropReconnectState(StreamKind.Event);
    this.streamRetries.reset();

    this.emit(
      Event.ConnectEvent,
      ConnectEvent.newSuccess().setAttempt(att).setAllReconnected(isAllReconnected),
    );
  }

  public async handleTerminated(isStopped: boolean) {
    // NOTE: isStopped set to true can be only in case when entire stream was
    // forced to stop, for example when filters are changed..
    if (isStopped) {
      this.transferState.dropReconnectState(StreamKind.Event);
      this.streamRetries.reset();
      return;
    }

    if (this.stream == null) {
      logger.error('unreachable: stream just terminated, but it is null');
      return;
    }

    // NOTE: When isStopped set to false, it means that stream was terminated
    // by backend and we probably need to try to recreate it...
    this.emit(Event.ConnectEvent, ConnectEvent.newDisconnected());

    // NOTE: Stream was terminated, so we don't need to call .stop() on it
    this.stream.terminate().offAllEvents();
    this.stream = null;

    // NOTE: Recreate stream with exact last params used (including possible
    // policies enabled)
    const nextDelay = this.streamRetries.nextDelay();
    const state = this.transferState.updateReconnectState(StreamKind.Event, old => ({
      ...old,
      attempt: (old?.attempt || 0) + 1,
      delay: nextDelay,
    }));

    this.emit(
      Event.ConnectEvent,
      ConnectEvent.newAttemptDelay().setAttempt(state.attempt).setDelay(nextDelay),
    );

    await this.streamRetries.wait();
    if (this.transferState.reconnectStates.get(StreamKind.Event) == null) {
      // NOTE: We are here if `dropDataFetch` was called during the wait
      this.streamRetries.reset();
      return;
    }

    return this.ensureLiveStream();
  }

  public async enablePoliciesFetch() {
    this.streamFlags = Object.assign({}, this.streamFlags, { policies: true });

    if (this.stream != null) {
      // NOTE: Means that we already have stream setup and thus we are not
      // in timescape only mode
      await this.stream.updateEventFlags(this.streamFlags);
    } else {
      await this.ensureDataFetch();
    }
  }

  public async filtersChanged(f: FiltersDiff) {
    // NOTE: The idea is to react on filters change if only app is active
    // in the background/foreground.
    if (!this.isAppActive) return;

    // NOTE: Stream is supposed to be null if ServiceMap wasn't ever opened
    await this.dropDataFetch();

    if (
      f.podFiltersChanged ||
      f.aggregationChanged ||
      f.timeRangeChanged ||
      f.filterGroups.changed
    ) {
      this.store.flush({
        globalFrame: true,
        policies: f.filterGroups.changed,
      });
    }

    // NOTE: This call will take from global frame only that data that matches
    // current set of filters
    this.store.resetCurrentFrame(this.dataLayerControls.modalFilters, {
      preserveActiveCards: true,
    });

    await this.ensureDataFetch();
  }

  public toggleActiveCardFilterGroup(filterGroups: FilterGroup[]) {
    this.emit(Event.FlowFiltersShouldBeChanged, filterGroups);
  }

  public async loadFullFlow(fd: FlowDigest): Promise<Flow | null> {
    this.emit(Event.FullFlowLoadingStarted);

    const p = new Promise<Flow | null>((resolve, reject) => {
      this.emit(Event.FullFlowLoadingStarted);

      this.backendAPI
        .getFullFlow(fd.id)
        .onFullFlow(ff => resolve(ff))
        .onErrors(errs => reject(errs[0]))
        .onTerminated(msg => {
          if (msg.errors.length > 0) {
            reject(msg.errors[0]);
          } else {
            resolve(null);
          }
        })
        .run();
    });

    return p
      .finally(() => this.emit(Event.FullFlowLoadingFinished))
      .catch(err => {
        this.emit(Event.FullFlowLoadingFailed, err);
        throw err;
      });
  }

  private handleFlows(frame: StoreFrame, flows: Flow[]) {
    const { flowsDiffCount } = frame.addFlows(flows);

    this.emit(Event.FlowsDiff, flowsDiffCount, frame);
  }

  private saveTimescapeDataPage(td: TimescapeData) {
    const frame = this.store.currentFrame;
    logger.log('saving timescape data page', td);

    if (td.flowSummaries.length > 0) {
      frame.addFlowSummaries(td.flowSummaries);
    } else if (td.flows.length > 0) {
      frame.addFlows(td.flows);
    }

    const { links, services } = td;
    if (links.length > 0 || services.length > 0) {
      const serviceMap = new DomainServiceMap(services, links);
      frame.extendServiceMap(serviceMap);
    }
  }

  private saveTimescapeFlowsCountStat(cs: CountStats[]) {
    if (cs.length === 0) return;
    this.store.currentFrame.replaceFlowStats(cs);
  }

  private saveTimescapeK8SPolicyEvents(events: K8SEvent[]) {
    this.store.currentFrame.replaceK8SPolicyEvents(events);
  }
}
