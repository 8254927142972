import _ from 'lodash';
import { makeAutoObservable } from 'mobx';

import { DashboardVariant } from '~/components/Dashboard/types';
import {
  Aggregation,
  StateChange as AggregationStateChange,
  AggregatorType,
} from '~/domain/aggregation';
import { Application, DetailsPanelMode } from '~/domain/common';
import { FilterGroup, Filters } from '~/domain/filtering';
import { Flow } from '~/domain/flows';
import { Verdict } from '~/domain/hubble';
import { DateOrShortcut, DateShortcut, TimeRange } from '~/domain/time';

// This store maintains data that is configured by control interfaces
export default class ControlStore {
  public currApp: Application;
  public selectedTableFlow: Flow | null = null;
  public showCrossNamespaceActivity = true;

  public verdicts = new Set<Verdict>();
  public httpStatus: string | null = null;
  public flowFilterGroups: FilterGroup[] = [];
  public showHost = false;
  public showKubeDns = false;
  public showRemoteNode = false;
  public showPrometheusApp = false;
  public aggregation: Aggregation | null = Aggregation.default();
  public detailsPanelMode: DetailsPanelMode = DetailsPanelMode.Flows;

  public currentDashboardVariant: DashboardVariant | null = null;

  public isRbacNoRoles = false;

  public showCimulator = false;

  public timeRange: TimeRange = ControlStore.getDefaultTimeRange();

  static getDefaultTimeRangeStart(): DateOrShortcut {
    return DateShortcut.Hour1;
  }

  static getDefaultTimeRangeEnd(): DateOrShortcut {
    return DateShortcut.Now;
  }

  static getDefaultTimeRange(): TimeRange {
    return new TimeRange(
      ControlStore.getDefaultTimeRangeStart(),
      ControlStore.getDefaultTimeRangeEnd(),
    );
  }

  constructor(currApp = Application.Dashboard) {
    this.currApp = currApp;
    makeAutoObservable(this, void 0, {
      autoBind: true,
    });
  }

  clone(deep = false): ControlStore {
    const store = new ControlStore(this.currApp);

    const selFlow = this.selectedTableFlow;
    const flowFilterGroups = this.flowFilterGroups;
    const agg = this.aggregation;

    store.selectedTableFlow = selFlow ? selFlow.clone() : null;
    store.showCrossNamespaceActivity = this.showCrossNamespaceActivity;
    store.verdicts = deep ? _.cloneDeep(this.verdicts) : this.verdicts;
    store.httpStatus = this.httpStatus;
    store.flowFilterGroups = deep
      ? flowFilterGroups.map(group => group.clone())
      : flowFilterGroups.slice();
    store.aggregation = agg == null ? null : agg.clone(deep);
    store.currApp = this.currApp;

    return store;
  }

  resetUserSelected() {
    this.selectedTableFlow = null;
    this.verdicts = new Set();
    this.httpStatus = null;
  }

  setCurrentApp(nextApp: Application): [Application, boolean] {
    const prevApp = this.currApp;
    const isChanged = prevApp !== nextApp;
    if (isChanged) this.currApp = nextApp;
    return [prevApp, isChanged];
  }

  setCurrentDashboardVariant(variant: DashboardVariant | null) {
    this.currentDashboardVariant = variant;
  }

  setIsRbacNoRoles(state: boolean) {
    this.isRbacNoRoles = state;
  }

  selectTableFlow(flow: Flow | null) {
    this.selectedTableFlow = flow;
  }

  setCrossNamespaceActivity(v: boolean) {
    this.showCrossNamespaceActivity = v;
  }

  toggleVerdict(verdict: Verdict): Set<Verdict> {
    const nextVerdicts = new Set(this.verdicts);

    if (nextVerdicts.has(verdict)) {
      nextVerdicts.delete(verdict);
    } else {
      nextVerdicts.add(verdict);
    }

    this.verdicts = nextVerdicts;
    return this.verdicts;
  }

  setVerdicts(verdicts: Set<Verdict>) {
    this.verdicts = verdicts;
  }

  setHttpStatus(st: string | null): string | null {
    const prev = this.httpStatus;
    this.httpStatus = st;

    return prev;
  }

  setFlowFilterGroups(groups: FilterGroup[]): FilterGroup[] {
    const prev = this.flowFilterGroups.slice();
    this.flowFilterGroups = groups;

    return prev;
  }

  setShowHost(val: boolean): boolean {
    this.showHost = val;

    return val;
  }

  toggleShowHost(): boolean {
    return this.setShowHost(!this.showHost);
  }

  setShowKubeDns(val: boolean): boolean {
    this.showKubeDns = val;

    return val;
  }

  toggleShowKubeDns(): boolean {
    return this.setShowKubeDns(!this.showKubeDns);
  }

  setShowRemoteNode(val: boolean): boolean {
    this.showRemoteNode = val;

    return val;
  }

  toggleShowRemoteNode(): boolean {
    return this.setShowRemoteNode(!this.showRemoteNode);
  }

  setShowPrometheusApp(val: boolean): boolean {
    this.showPrometheusApp = val;

    return val;
  }

  toggleShowPrometheusApp(): boolean {
    return this.setShowPrometheusApp(!this.showPrometheusApp);
  }

  setAggreatorTypes(types: AggregatorType[]) {
    const agg = this.aggregation || Aggregation.default();
    this.aggregation = agg.setAggreatorTypes(types);
  }

  setAggregationStateChange(stateChange: AggregationStateChange) {
    const agg = this.aggregation || Aggregation.default();
    this.aggregation = agg.setStateChange(stateChange);
  }

  setAggregation(aggregation: Aggregation | null) {
    this.aggregation = aggregation;
  }

  toggleAggregation(toggledTo: boolean): Aggregation | null {
    const nextAggregation = toggledTo ? Aggregation.default() : null;
    this.setAggregation(nextAggregation);

    return nextAggregation;
  }

  setFilters(f: Filters) {
    this.verdicts = f.verdicts ?? new Set();
    this.httpStatus = f.httpStatus ?? null;
    this.flowFilterGroups = f.filterGroups || [];
    this.showHost = !f.skipHost;
    this.showKubeDns = !f.skipKubeDns;
    this.showRemoteNode = !f.skipRemoteNode;
    this.showPrometheusApp = !f.skipPrometheusApp;
    this.timeRange = f.timeRange ?? ControlStore.getDefaultTimeRange();
  }

  public setTimeRange(timeRange: TimeRange): TimeRange | null {
    const prev = this.timeRange;
    this.timeRange = timeRange;

    return prev;
  }

  public setDetailsPanelMode(mode: DetailsPanelMode) {
    this.detailsPanelMode = mode;
  }

  get app() {
    return {
      isConnectionsMap: this.currApp === Application.ConnectionsMap,
      isProcessTree: this.currApp === Application.ProcessTree,
      isCimulator: this.currApp === Application.Cimulator,
      isDashboard: this.currApp === Application.Dashboard,
    };
  }
}
