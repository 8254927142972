import { makeAutoObservable } from 'mobx';
import * as YAML from 'yaml';

import {
  CIDRRule,
  EgressRule,
  EndpointSelector,
  FQDNSelector,
  IngressRule,
  PortProtocol,
  PortRule,
  Service,
} from '~/domain/cilium/cnp/types.generated';
import { NetworkPolicyEgressRule, NetworkPolicyIngressRule } from '~/domain/k8s/knp/types';
import { Labels as DomainLabels, KV, Labels, NamespaceLabelKey } from '~/domain/labels';
import { createHash } from '~/domain/misc';
import { logger } from '~/utils/logger';

import { ENDPOINT_NAME_LABELS } from './labels';
import { EndpointRequirement } from './requirement';
import {
  CardGeneralInfo,
  CardKind,
  EndpointAllKind,
  EndpointCidrKind,
  EndpointKind,
  EndpointMatchExpression,
  EndpointRequirementKind,
  EndpointRequirementOperator,
  PolicyKind,
} from './types';

export class PolicyEndpoint {
  private _kind: EndpointKind;
  private _fqdn: FQDNSelector | null = null;
  private _cidr: CIDRRule | null = null;
  private _ports: PortRule[] | null = null;
  private _requirements: EndpointRequirement[] | null = null;
  private _service: Service | null = null;
  private _authentication: string | null = null;

  private _origYamlRange: YAML.Range | null | undefined;

  private _allKind: EndpointAllKind | null = null;
  private _cidrKind: EndpointCidrKind | null = null;
  private _originRule: {
    rule: IngressRule | EgressRule | NetworkPolicyEgressRule | NetworkPolicyIngressRule;
    policyKind: PolicyKind;
  } | null = null;

  static fromFQDN = (fqdn: FQDNSelector): PolicyEndpoint => {
    const endpoint = new PolicyEndpoint(EndpointKind.Fqdn, null);
    endpoint.setFqdn(fqdn);
    return endpoint;
  };

  static fromFQDNString = (fqdnPattern: string): PolicyEndpoint => {
    let fqdn: FQDNSelector = { matchName: fqdnPattern };

    if (fqdnPattern.indexOf('*') !== -1) {
      fqdn = { matchPattern: fqdnPattern };
    }

    return PolicyEndpoint.fromFQDN(fqdn);
  };

  static fromCIDR = (cidr: CIDRRule): PolicyEndpoint => {
    const endpoint = new PolicyEndpoint(EndpointKind.Cidr, null);
    endpoint.setCidr(cidr);
    return endpoint;
  };

  static fromCIDRString = (cidrStr: string): PolicyEndpoint => {
    const endpoint = new PolicyEndpoint(EndpointKind.Cidr, null);
    endpoint.setCidrFromStr(cidrStr);
    return endpoint;
  };

  static selectorToRequirements = (selector: EndpointSelector): EndpointRequirement[] => {
    const requirements: EndpointRequirement[] = [];

    Object.keys(selector.matchLabels ?? {}).forEach(matchLabelKey => {
      const matchLabelValue = selector.matchLabels?.[matchLabelKey];
      requirements.push(
        new EndpointRequirement(
          EndpointRequirementKind.MatchLabels,
          matchLabelKey,
          EndpointRequirementOperator.In,
          matchLabelValue ? [matchLabelValue] : undefined,
        ),
      );
    });

    selector.matchExpressions?.forEach(matchExpression => {
      requirements.push(
        new EndpointRequirement(
          EndpointRequirementKind.MatchExpression,
          matchExpression.key,
          matchExpression.operator as EndpointRequirementOperator,
          matchExpression.values,
        ),
      );
    });
    return requirements;
  };

  static fromSelector = (selector: EndpointSelector): PolicyEndpoint => {
    const endpoint = new PolicyEndpoint(
      EndpointKind.LabelsSelector,
      PolicyEndpoint.selectorToRequirements(selector),
    );
    if (endpoint.namespaceSelector && !endpoint.podSelector) {
      endpoint.setKind(EndpointKind.NamespaceSelector);
    }
    return endpoint;
  };

  static fromService = (service: Service): PolicyEndpoint => {
    const endpoint = new PolicyEndpoint(EndpointKind.Service, null);
    endpoint.setService(service);
    return endpoint;
  };

  static fromKind = (kind: EndpointKind): PolicyEndpoint => {
    return new PolicyEndpoint(kind, []);
  };

  static newAll = (allKind?: EndpointAllKind): PolicyEndpoint => {
    const endpoint = new PolicyEndpoint(EndpointKind.All, null);
    if (allKind) endpoint.setAllKind(allKind);
    return endpoint;
  };

  static newFQDN = (): PolicyEndpoint => {
    return new PolicyEndpoint(EndpointKind.Fqdn, null);
  };

  static newCIDR = (): PolicyEndpoint => {
    return new PolicyEndpoint(EndpointKind.Cidr, null);
  };

  static newKubeDns = (enableL7 = true): PolicyEndpoint => {
    const selector = {
      matchLabels: {
        [NamespaceLabelKey]: 'kube-system',
        'k8s-app': 'kube-dns',
      },
    };

    const endpoint = PolicyEndpoint.fromSelector(selector)
      .setKind(EndpointKind.KubeDns)
      .addPorts([{ ports: [{ port: '53', protocol: 'UDP' }] }]);

    if (enableL7) endpoint.enableDNSProxy();

    return endpoint;
  };

  static newNone = (): PolicyEndpoint => {
    return new PolicyEndpoint(EndpointKind.None, null);
  };

  static newLabelsSelector = (): PolicyEndpoint => {
    return new PolicyEndpoint(EndpointKind.LabelsSelector, []);
  };

  static newService = (): PolicyEndpoint => {
    return new PolicyEndpoint(EndpointKind.Service, null);
  };

  static newNamespaceSelector = (): PolicyEndpoint => {
    return new PolicyEndpoint(EndpointKind.NamespaceSelector, []);
  };

  static extractFQDNMatch = (fqdn: FQDNSelector): string => {
    return (fqdn.matchName || fqdn.matchPattern) as string;
  };

  static checkCNPRuleIsKubeDns = (rule: EgressRule) => {
    if (Object.keys(rule).length !== 2) return false;
    if (rule.toEndpoints?.length !== 1) return false;
    if (rule.toPorts?.length !== 1 && rule.toPorts?.length !== 2) return false;

    const matchLabels = rule.toEndpoints?.[0].matchLabels;
    if (!matchLabels) return false;

    const labels: KV[] = Object.keys(matchLabels).map(key => ({
      key,
      value: matchLabels[key] ?? undefined,
    }));

    const appName = DomainLabels.findAppNameInLabels(labels);
    const namespace = DomainLabels.findNamespaceInLabels(labels);

    const checkAppName = appName && ['kube-dns', 'coredns', 'opendns'].includes(appName);

    const checkLabels =
      (labels.length === 1 && (checkAppName || namespace)) ||
      (labels.length === 2 && checkAppName && namespace);

    const portsCheck = rule.toPorts.every(portRule => {
      if (!portRule.ports) return false;
      if (portRule.ports.length < 1 || portRule.ports.length > 2) return false;
      const protocols = new Set<string>();
      const check = portRule.ports.every(p => {
        const protocol = p.protocol?.toLowerCase();
        if (protocol !== 'tcp' && protocol !== 'udp' && protocol !== 'any') {
          return false;
        }
        protocols.add(protocol);
        return String(p.port) === '53';
      });
      if (!check || (!protocols.has('udp') && !protocols.has('any'))) {
        return false;
      }
      return true;
    });

    return checkLabels && portsCheck;
  };

  static checkKNPRuleIsKubeDns = (rule: NetworkPolicyEgressRule) => {
    if (Object.keys(rule).length !== 2) return false;
    if (rule.to?.length !== 1) return false;
    if (rule.to[0].ipBlock) return false;
    if (!rule.ports || (rule.ports.length !== 1 && rule.ports.length !== 2)) {
      return false;
    }

    const peer = rule.to[0];

    const namespaceSelector = peer.namespaceSelector;
    if (!namespaceSelector || Object.keys(namespaceSelector).length !== 0) {
      return false;
    }

    const matchLabels = peer.podSelector?.matchLabels;
    const labels: KV[] = matchLabels
      ? Object.keys(matchLabels).map(key => ({
          key,
          value: matchLabels[key] ?? undefined,
        }))
      : [];

    const appName = DomainLabels.findAppNameInLabels(labels);

    const checkAppName = appName && ['kube-dns', 'coredns', 'opendns'].includes(appName);

    const checkLabels =
      !matchLabels || labels.length === 0 || (labels.length === 1 && checkAppName);

    const protocols = new Set<string>();
    const portsCheck = rule.ports.every(p => {
      if (p.port !== 53) return false;
      const protocol = p.protocol?.toLowerCase();
      if (protocol !== 'tcp' && protocol !== 'udp' && protocol !== 'any') {
        return false;
      }
      protocols.add(protocol);
      return true;
    });
    if (!protocols.has('udp') && !protocols.has('any')) return false;

    return checkLabels && portsCheck;
  };

  static isValidPortsArray = (ports: string[]) => {
    return ports
      .map(p => p.trim())
      .filter(p => p.length > 0)
      .every(p => {
        const [val] = p.split('|');
        return /^\d+$/.test(val) && +val >= 0 && +val <= 65535;
      });
    // TODO: support for port-range below
    // .every(p => {
    //   return p
    //     .split('-')
    //     .map(p => p.trim())
    //     .every(p => /^\d+$/.test(p) && +p >= 0 && +p <= 65535);
    // });
  };

  get id(): string {
    const ports = createHash(this.ports);
    if (this.isCIDR) return `cidr/${createHash(this._cidr)}:${ports}`;
    else if (this.isFQDN) return `fqdn/${createHash(this._fqdn)}:${ports}`;
    else if (this.isKubeDns) return 'kube-dns';
    else if (this.isAll) return `all:${ports}`;
    else if (this.isHost) return `host:${ports}`;
    else if (this.isKubeApi) return 'kube-apiserver';
    else if (this.isRemoteNode) return `remote-node:${ports}`;
    else if (this.isNamespaceSelector) {
      return `namespace-selector/${createHash(this._requirements)}:${ports}`;
    } else if (this.isLabelsSelector) {
      return `labels-selector/${createHash(this._requirements)}:${ports}`;
    } else if (this.isService) {
      return `service/${createHash(this._service)}:${ports}`;
    }
    return this.hash;
  }

  get hash() {
    return createHash([
      this._kind,
      this._requirements,
      this._service,
      this._ports,
      this._fqdn,
      this._cidr,
    ]);
  }

  get kind() {
    return this._kind;
  }

  get origYamlRange() {
    return this._origYamlRange;
  }

  setOrigYamlRange(range: YAML.Range | null | undefined) {
    this._origYamlRange = range;
  }

  get requirements() {
    if (this.isService) {
      const requirements: EndpointRequirement[] = [];
      Object.entries(this.service?.k8sServiceSelector?.selector?.matchLabels || []).forEach(
        ([key, value]) => {
          requirements.push(
            new EndpointRequirement(
              EndpointRequirementKind.MatchLabels,
              key,
              EndpointRequirementOperator.In,
              [value],
            ),
          );
        },
      );
      this.service?.k8sServiceSelector?.selector?.matchExpressions?.forEach(expr => {
        requirements.push(
          new EndpointRequirement(
            EndpointRequirementKind.MatchExpression,
            expr.key,
            expr.operator as EndpointRequirementOperator,
            expr.values,
          ),
        );
      });
      return requirements;
    }
    return this._requirements;
  }

  get originRule() {
    return this._originRule;
  }

  get allKind() {
    return this._allKind;
  }

  get selectsAllNamespaces() {
    return this._allKind === EndpointAllKind.AllNamespacesSelector;
  }

  get hasCNPAllNamespacesSelector() {
    return this.labelsSelector?.matchExpressions?.some(expr => {
      const operatorCheck = expr.operator.toLowerCase() === 'exists';
      const keyCheck = Boolean(Labels.findNamespaceInLabels([{ key: expr.key, value: 'stub' }]));
      return operatorCheck && keyCheck;
    });
  }

  get service(): Service | null {
    if (!this.isService) {
      return null;
    }
    return this._service;
  }

  get cidrKind(): EndpointCidrKind | null {
    if (!this.isCIDR) return null;
    return this._cidrKind;
  }

  get ports(): PortRule[] | null {
    return this._ports?.length ? this._ports.slice() : null;
  }

  get fqdn(): FQDNSelector | null {
    return this._fqdn ? { ...this._fqdn } : null;
  }

  get fqdnMatch(): string | null {
    return this.fqdn ? PolicyEndpoint.extractFQDNMatch(this.fqdn) : null;
  }

  get cidr(): CIDRRule | null {
    return this._cidr ? { ...this._cidr } : null;
  }

  get cidrStr(): string | null {
    return this.cidr?.cidr || null;
  }

  get exceptCidrsArray() {
    return this.cidr?.except || null;
  }

  get exceptCidrsStr(): string | null {
    return this.exceptCidrsArray ? this.exceptCidrsArray.join(', ') : null;
  }

  get labelsSelector(): EndpointSelector | null {
    if (!this.requirements) return null;

    const selector: EndpointSelector = {};

    this.requirements.forEach(requirement => {
      const reqSelector = requirement.toEndpointSelector();

      if (reqSelector.matchLabels != null) {
        selector.matchLabels = {
          ...selector.matchLabels,
          ...reqSelector.matchLabels,
        };
      }

      if (reqSelector.matchExpressions?.length) {
        selector.matchExpressions = [
          ...(selector.matchExpressions || []),
          ...reqSelector.matchExpressions,
        ];
      }
    });

    return selector;
  }

  get matchLabelsArray(): string[] | null {
    if (!this.matchLabelsStr) return null;
    return this.matchLabelsStr.split(',').map(v => v.trim());
  }

  get matchExpressions(): EndpointMatchExpression[] | null {
    if (!this.requirements) return null;
    return this.requirements
      .filter(req => req.isMatchExpression)
      .map(req => ({
        key: req.key,
        operator: req.operator,
        values: req.values,
      }));
  }

  get podSelector(): EndpointSelector | null {
    if (!this.labelsSelector) return null;
    const selector = { ...this.labelsSelector };
    if (selector.matchLabels) {
      const matchLabels = { ...selector.matchLabels };
      Object.keys(matchLabels).forEach(key => {
        if (key.includes(NamespaceLabelKey)) {
          delete matchLabels?.[key];
        }
      });
      if (Object.keys(matchLabels).length) selector.matchLabels = matchLabels;
      else delete selector.matchLabels;
    }
    if (selector.matchExpressions) {
      const matchExpressions = selector.matchExpressions.filter(
        expr => !expr.key.includes(NamespaceLabelKey),
      );
      if (matchExpressions.length) selector.matchExpressions = matchExpressions;
      else delete selector.matchExpressions;
    }
    if (!Object.keys(selector.matchLabels ?? {}).length && !selector.matchExpressions?.length) {
      return null;
    }
    return selector;
  }

  get namespaceSelector(): EndpointSelector | null {
    if (!this.labelsSelector) return null;
    const selector = { ...this.labelsSelector };

    const normkey = (key: string) => {
      const startIdx = key.indexOf(NamespaceLabelKey);
      if (startIdx === -1) return key;
      const endIdx = startIdx + NamespaceLabelKey.length;
      const reskey = key.slice(endIdx);
      if (reskey.length === 0) return 'namespace';
      if (reskey.startsWith('.labels.')) return reskey.slice('.labels.'.length);
      return reskey;
    };

    if (selector.matchLabels) {
      const matchLabels = { ...selector.matchLabels };
      Object.keys(matchLabels).forEach(key => {
        const value = matchLabels[key];
        delete matchLabels[key];
        if (!key.includes(NamespaceLabelKey)) return;
        matchLabels[normkey(key)] = value;
      });
      if (Object.keys(matchLabels).length) selector.matchLabels = matchLabels;
      else delete selector.matchLabels;
    }
    if (selector.matchExpressions) {
      const matchExpressions = selector.matchExpressions
        .filter(expr => expr.key.includes(NamespaceLabelKey))
        .map(expr => {
          const cloned = { ...expr };
          cloned.key = normkey(cloned.key);
          return cloned;
        });
      if (matchExpressions.length) selector.matchExpressions = matchExpressions;
      else delete selector.matchExpressions;
    }
    if (!Object.keys(selector.matchLabels ?? {}).length && !selector.matchExpressions?.length) {
      return null;
    }
    return selector;
  }

  get matchLabelsStr(): string | null {
    if (!this.labelsSelector) return null;
    const labels: string[] = [];
    Object.keys(this.labelsSelector.matchLabels || {}).forEach(key => {
      if (this.labelsSelector?.matchLabels == null) return;
      const value = this.labelsSelector.matchLabels[key];
      labels.push(`${key}=${value}`);
    });
    return labels.join(', ');
  }

  get podMatchLabelsStr(): string | null {
    if (!this.podMatchLabelsArray) return null;
    return this.podMatchLabelsArray.join(', ');
  }

  get podMatchLabelsArray(): string[] | null {
    if (!this.matchLabelsStr) return null;
    return this.matchLabelsStr
      .split(',')
      .map(v => v.trim())
      .filter(v => !v.includes(`${NamespaceLabelKey}`));
  }

  get podMatchExpressions(): EndpointMatchExpression[] | null {
    if (!this.requirements) return null;
    return this.requirements
      .filter(v => v.isMatchExpression && !v.key.includes(`${NamespaceLabelKey}`))
      .map(req => ({
        key: req.key,
        operator: req.operator,
        values: req.values,
      }));
  }

  get namespaceMatchLabelsStr(): string | null {
    if (!this.namespaceMatchLabelsArray) return null;
    return this.namespaceMatchLabelsArray.join(', ');
  }

  get namespaceMatchLabelsArray(): string[] | null {
    if (!this.matchLabelsStr) return null;
    return this.matchLabelsStr
      .split(',')
      .map(v => v.trim())
      .filter(v => v.includes(`${NamespaceLabelKey}`));
  }

  get namespaceMatchExpressions(): EndpointMatchExpression[] | null {
    if (!this.requirements) return null;
    return this.requirements
      .filter(v => v.isMatchExpression && v.key.includes(`${NamespaceLabelKey}`))
      .map(req => ({
        key: req.key,
        operator: req.operator,
        values: req.values,
      }));
  }

  get isAll(): boolean {
    return this._kind === EndpointKind.All;
  }

  get isAllWithPorts(): boolean {
    return this.isAll && this.portsStr.length > 0;
  }

  get isAllWithoutPorts(): boolean {
    return this.isAll && this.portsStr.length === 0;
  }

  get isKubeDns(): boolean {
    return this._kind === EndpointKind.KubeDns;
  }

  get isHost(): boolean {
    return this._kind === EndpointKind.Host;
  }

  get isRemoteNode(): boolean {
    return this._kind === EndpointKind.RemoteNode;
  }

  get isKubeApi(): boolean {
    return this._kind === EndpointKind.KubeApiserver;
  }

  get isLabelsSelector(): boolean {
    return this._kind === EndpointKind.LabelsSelector;
  }

  get isNamespaceSelector(): boolean {
    return this._kind === EndpointKind.NamespaceSelector;
  }

  get isSelector(): boolean {
    return this.isLabelsSelector || this.isNamespaceSelector;
  }

  get isService(): boolean {
    return this._kind === EndpointKind.Service;
  }

  get isFQDN(): boolean {
    return this._kind === EndpointKind.Fqdn;
  }

  get isNone(): boolean {
    return this._kind === EndpointKind.None;
  }

  get isCIDR(): boolean {
    return this._kind === EndpointKind.Cidr;
  }

  get isAWS(): boolean {
    return false;
  }

  get namespace(): string | null {
    if (this.kind === EndpointKind.Service) {
      return (
        this._service?.k8sService?.namespace ?? this._service?.k8sServiceSelector?.namespace ?? null
      );
    }
    if (!this._requirements) return null;

    const namespaceRequirement = this._requirements.find(r => {
      return r.key === NamespaceLabelKey;
    });

    if (namespaceRequirement) {
      return namespaceRequirement.values?.length ? namespaceRequirement.values[0] : null;
    }

    return null;
  }

  get firstNamespaceLabel(): string | null {
    if (!this._requirements) return null;

    const namespaceRequirement = this._requirements.find(r => {
      return r.key.includes(NamespaceLabelKey);
    });

    if (namespaceRequirement) {
      const key = Labels.normalizeNamespaceLabelKey(namespaceRequirement.key);
      return namespaceRequirement.values?.length
        ? `${key}=${namespaceRequirement.values[0]}`
        : null;
    }

    return null;
  }

  get isDNSProxyEnabled() {
    if (!this.isKubeDns) return false;

    const rules = this.ports?.[0].rules;

    return Boolean(
      rules && rules.dns && rules.dns.length === 1 && rules.dns[0].matchPattern === '*',
    );
  }

  get authentication() {
    return this._authentication;
  }

  get isAuthenticationEnabled() {
    return !!this.authentication;
  }

  get portsStringArray(): Array<string> {
    if (!this._ports) return [];

    const ports: Array<string> = [];
    this._ports.forEach(p => {
      if (p.ports == null) return;

      p.ports.forEach(pp => {
        if (pp.port == null) return;

        let value = String(pp.port);
        if (pp.protocol) value += '|' + pp.protocol;
        ports.push(value);
      });
    });

    return ports;
  }

  get portsStr(): string {
    return this.portsStringArray.join(', ');
  }

  get portsStrWithColons(): string {
    return this.portsStr
      .split(', ')
      .map(p => `:${p}`)
      .join(', ');
  }

  enableDNSProxy = () => {
    if (!this.isKubeDns || this.isDNSProxyEnabled) return this;
    (this._ports as PortRule[])[0].rules = {
      dns: [{ matchPattern: '*' }],
    };
    this._ports = (this._ports as PortRule[]).slice();
    return this;
  };

  disableDNSProxy = () => {
    if (!this.isKubeDns || !this.isDNSProxyEnabled) return this;
    delete (this._ports as PortRule[])[0].rules;
    this._ports = (this._ports as PortRule[]).slice();
    return this;
  };

  toggleDNSProxy = () => {
    if (!this.isKubeDns) return this;
    this.isDNSProxyEnabled ? this.disableDNSProxy() : this.enableDNSProxy();
    return this;
  };

  setAuthentication = (value: string | null) => {
    this._authentication = value;
  };

  enableAuthentication = () => {
    this.setAuthentication('required');
  };

  disableAuthentication = () => {
    this.setAuthentication(null);
  };

  toggleAuthentication = () => {
    this._authentication ? this.disableAuthentication() : this.enableAuthentication();
  };

  setKind = (kind: EndpointKind) => {
    this._kind = kind;
    return this;
  };

  setAllKind = (allKind: EndpointAllKind | null) => {
    this._allKind = allKind;
    return this;
  };

  setOriginRule = ({
    rule,
    policyKind,
  }: {
    rule: IngressRule | EgressRule | NetworkPolicyEgressRule | NetworkPolicyIngressRule | null;
    policyKind: PolicyKind;
  }) => {
    this._originRule = rule ? { rule, policyKind } : null;
    return this;
  };

  addPorts = (ports?: PortRule[] | null) => {
    if (!ports) return this;
    if (!this._ports) this._ports = [];
    ports.forEach(rule => {
      if (!rule.ports?.length) return;
      if (rule.ports?.some(p => this.hasPort(p.port, p.protocol))) {
        return;
      }
      this._ports?.push({
        ...rule,
        ports: rule.ports?.map(pp => ({
          ...pp,
          port: `${pp.port}`,
        })),
      });
    });

    return this;
  };

  addPortsFromStringArray = (ports: string[]) => {
    this.addPorts(PolicyEndpoint.normPortsArray(ports));
    return this;
  };

  setPortsFromStringArray = (ports: string[]) => {
    this._ports = PolicyEndpoint.normPortsArray(ports);
  };

  setFqdn = (fqdn: FQDNSelector): PolicyEndpoint => {
    this._fqdn = fqdn;
    this._kind = EndpointKind.Fqdn;
    return this;
  };

  setFqdnFromMatch = (match: string): PolicyEndpoint => {
    let fqdn: FQDNSelector = {};
    if (match.indexOf('*') !== -1) {
      fqdn = {
        matchPattern: match,
      };
    } else {
      fqdn = {
        matchName: match,
      };
    }
    this.setFqdn(fqdn);
    return this;
  };

  setSelector = (selector: EndpointSelector): PolicyEndpoint => {
    const requirements = PolicyEndpoint.selectorToRequirements(selector);
    this._requirements = requirements;
    return this;
  };

  setCidr = (cidr: CIDRRule): PolicyEndpoint => {
    this._cidr = cidr;
    this._kind = EndpointKind.Cidr;
    return this;
  };

  setService = (service: Service): PolicyEndpoint => {
    this._service = service;
    this._kind = EndpointKind.Service;
    return this;
  };

  setCidrFromStr = (cidrStr: string, except?: string[]): PolicyEndpoint => {
    const cidr: CIDRRule = {
      cidr: cidrStr,
    };
    if (except?.length) {
      cidr.except = except.filter(s => s.length);
    }
    this.setCidr(cidr);
    return this;
  };

  setCidrKind = (kind: EndpointCidrKind | null): PolicyEndpoint => {
    this._cidrKind = kind;
    return this;
  };

  getCaption = ({
    cardInfo = {},
  }: {
    cardInfo?: Partial<CardGeneralInfo>;
  } = {}): string => {
    if (this.isAll) {
      switch (cardInfo.kind) {
        case CardKind.All:
          if (this.ports?.length) {
            return 'Everything on ports';
          } else {
            return 'Everything';
          }
        case CardKind.OutsideCluster:
          return 'Any endpoint';
        case CardKind.InNamespace:
          return 'Any pod';
        case CardKind.InCluster:
          return 'Everything in the cluster';
      }
      return 'Any endpoint';
    }
    if (this.kind === EndpointKind.KubeDns) {
      return 'Kubernetes DNS';
    }
    if (this.kind === EndpointKind.Host) {
      return 'host';
    }
    if (this.kind === EndpointKind.KubeApiserver) {
      return 'kube-apiserver';
    }
    if (this.kind === EndpointKind.RemoteNode) {
      return 'remote-node';
    }

    const matchExpressionToCaption = (expr: EndpointMatchExpression) => {
      const operator =
        expr.operator === EndpointRequirementOperator.In
          ? 'IN'
          : expr.operator === EndpointRequirementOperator.NotIn
            ? '!IN'
            : expr.operator === EndpointRequirementOperator.Exists
              ? 'EXIST'
              : '!EXIST';
      const postfix = expr.values?.length ? ` ${expr.values.join(', ')}` : '';
      return `${Labels.normalizeKey(expr.key)} ${operator}${postfix}`;
    };

    const requirementsToCaption = (namespaceCaption?: string | null) => {
      namespaceCaption = namespaceCaption ? Labels.normalizeKey(namespaceCaption) : null;

      if (!this.requirements) return null;

      const requirementsMap = new Map(this.requirements.map(req => [req.key, req]));

      const nonNamespace = this.requirements.filter(r => {
        return !r.key.includes(NamespaceLabelKey);
      });

      for (const appKey of ENDPOINT_NAME_LABELS) {
        const req = requirementsMap.get(appKey);
        if (!req) continue;
        if (!req.in || !req.values?.length) continue;

        let appCaption = `${Labels.normalizeKey(appKey)}=${req.values[0]}`;
        if (nonNamespace.length > 1) appCaption = `${appCaption},…`;

        if (namespaceCaption) return `${namespaceCaption}/${appCaption}`;
        else return appCaption;
      }

      if (!nonNamespace.length) return namespaceCaption || 'Untitled';

      const firstKey = nonNamespace[0].key;
      const firstVal = nonNamespace[0].values?.[0] ?? '';

      let postfixCaption = `${firstKey}`;
      if (firstVal) postfixCaption = `${postfixCaption}=${firstVal}`;
      if (nonNamespace.length > 1) postfixCaption = `${postfixCaption},…`;

      if (namespaceCaption) return `${namespaceCaption}/${postfixCaption}`;
      else return postfixCaption;
    };

    if (this.isSelector && this._requirements) {
      if (this.matchLabelsArray?.length) {
        let namespaceCaption = '';
        if (this.namespaceMatchLabelsArray?.length) {
          if (this.namespace) {
            namespaceCaption = `namespace=${this.namespace}`;
          } else if (this.firstNamespaceLabel) {
            namespaceCaption = this.firstNamespaceLabel;
          }
          if (namespaceCaption && this.namespaceMatchLabelsArray.length > 1) {
            namespaceCaption = `${namespaceCaption},…`;
          }
        }
        const caption = requirementsToCaption(namespaceCaption);
        if (caption) return caption;
      } else if (this.matchExpressions?.length) {
        const expr = this.matchExpressions[0];
        let matchExpressionCaption = matchExpressionToCaption(expr);
        if (this.matchExpressions.length > 1) matchExpressionCaption += '…';
        return matchExpressionCaption;
      } else {
        return 'Untitled';
      }
    }

    if (this.isService && this.service) {
      const namespaceCaption = this.namespace ? `namespace=${this.namespace}` : '';

      if (this.service.k8sService?.serviceName) {
        if (namespaceCaption) {
          return `${namespaceCaption}/${this.service.k8sService.serviceName}`;
        } else {
          return this.service.k8sService.serviceName;
        }
      } else if (this.service.k8sServiceSelector?.selector) {
        if (this.matchLabelsArray?.length) {
          const caption = requirementsToCaption(namespaceCaption);
          if (caption) return caption;
        } else if (this.matchExpressions?.length) {
          const expr = this.matchExpressions[0];
          let matchExpressionCaption = matchExpressionToCaption(expr);
          if (this.matchExpressions.length > 1) matchExpressionCaption += '…';
          return matchExpressionCaption;
        }
      }
    }

    if (this.isFQDN && this.fqdnMatch) return this.fqdnMatch;

    if (this.isCIDR && this.cidrStr) return this.cidrStr;

    return 'Untitled';
  };

  isEqual = (endpoint: PolicyEndpoint) => {
    return !(
      this.id !== endpoint.id ||
      this._kind !== endpoint.kind ||
      this.portsStr !== endpoint.portsStr ||
      this.cidrStr !== endpoint.cidrStr ||
      this.fqdnMatch !== endpoint.fqdnMatch ||
      this.authentication !== endpoint.authentication
    );
  };

  hasPort = (port: string | number, protocol?: string): boolean => {
    return !!this._ports?.some(rule => {
      return rule.ports?.some(
        portRule => String(portRule.port) === String(port) && portRule.protocol === protocol,
      );
    });
  };

  clone = (): PolicyEndpoint => {
    const ep = new PolicyEndpoint(
      this.kind,
      this._requirements ? this._requirements.map(req => req.clone()) : this._requirements,
    );

    if (this.isFQDN && this.fqdnMatch) {
      ep.setFqdnFromMatch(this.fqdnMatch.slice());
    }

    if (this.isCIDR && this._cidr) {
      ep.setCidr(this._cidr);
    }

    if (this.isService && this._service) {
      ep.setService(this._service);
    }

    if (this.ports) {
      ep.addPorts(this.ports);
    }

    ep.setAuthentication(this.authentication);

    return ep;
  };

  private constructor(kind: EndpointKind, reqs: EndpointRequirement[] | null) {
    makeAutoObservable(this, {
      getCaption: false,
      isEqual: false,
      clone: false,
      hasPort: false,
    });
    this._kind = kind;
    this._requirements = reqs;
  }

  private static normPortsArray = (ports: string[]) => {
    if (!PolicyEndpoint.isValidPortsArray(ports)) {
      logger.warn(`Can't parse invalid port string: ${ports}`);
      return [];
    }

    const mem = new Set<string>();
    const rules: PortRule[] = [];

    ports
      .map(s => s.trim())
      .filter(s => s.length > 0)
      .forEach(s => {
        if (mem.has(s)) return;
        mem.add(s);
        const [val, protocol] = s.split('|');
        const portProtocol: PortProtocol = { port: val };
        if (protocol?.trim()) portProtocol.protocol = protocol?.trim();
        rules.push({ ports: [portProtocol] });
      });

    return rules;
  };
}
