import React from 'react';

import css from './styles.scss';

interface Props {
  prompt: string;
  height: number;
}

export default function EmptyMapPlaceholder(props: Props) {
  return (
    <div className={css.notSupported} style={{ height: `${props.height}px` }}>
      <div>
        <img src="/icons/misc/no-data.svg" alt="No data icon" />
        <div className={css.mainPrompt}>{props.prompt}</div>
      </div>
    </div>
  );
}
