import React from 'react';

import { utils as gutils, Line2, Vec2 } from '~/domain/geometry';

export interface Props {
  points: [Vec2, Vec2] | null;

  radius?: number;
  fill?: string;
  className?: string;
}

export const ArrowTriangle = function ArrowTriangle(props: Props) {
  if (props.points?.length !== 2) return <path d="" />;

  const [start, end] = props.points!;
  const width = start.distance(end);

  const line = Line2.throughPoints(start, end);
  const side = line.normal.mul(width / 2);

  const baseA = start.add(side);
  const baseB = start.sub(side);

  const sweep = gutils.pointSideOfLine(start, end, baseA) > 0 ? 0 : 1;

  const r = props.radius ?? 2;
  const [ar1, ar2] = gutils.roundCorner(r, [start, baseA, end]);
  const [br1, br2] = gutils.roundCorner(r, [start, baseB, end]);
  const [er1, er2] = gutils.roundCorner(r, [baseA, end, baseB]);

  const d = `
    M ${start.x} ${start.y}
    L ${ar1.x} ${ar1.y}
    A ${r} ${r} 0 0 ${sweep} ${ar2.x} ${ar2.y}
    L ${er1.x} ${er1.y}
    A ${r} ${r} 0 0 ${sweep} ${er2.x} ${er2.y}
    L ${br2.x} ${br2.y}
    A ${r} ${r} 0 0 ${sweep} ${br1.x} ${br1.y}
    Z
  `;

  return <path d={d} className={props.className} fill={props.fill} />;
};
