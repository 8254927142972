import React from 'react';

import classNames from 'classnames';

import { applyPrefix } from '~/utils';

import { IconKind } from './common';
import { byDomainName, byLabels } from './detect-icon';
import css from './styles.scss';

export interface Props {
  className?: string;
  iconsPrefix?: string;
  default?: string;

  labels?: string[];
  dns?: string;
}

export const ServiceIcon = function ServiceIcon(props: Props) {
  let iconPath = props.default ?? '/icons/logos/world.png';

  if (props.labels?.length) {
    const iconKind = byLabels(props.labels);
    if (iconKind != null) {
      iconPath = iconKind;
    }
  }

  if (iconPath == null && !!props.dns) {
    const iconKind = byDomainName(props.dns);
    if (iconKind != null) {
      iconPath = iconKind;
    }
  }

  iconPath = applyPrefix(iconPath, props.iconsPrefix);

  const classes = classNames(css.serviceIcon, props.className, {
    [css.kafka]: iconPath.endsWith(IconKind.Kafka),
  });

  return (
    <div className={classes}>
      <img src={iconPath} />
    </div>
  );
};
