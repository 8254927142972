import React, { SyntheticEvent } from 'react';

import css from './AddFilterButton.scss';
import { AddFilterIcon } from '../AddFilterIcon/AddFilterIcon';

export const AddFilterButton = function AddFilterButton({
  handleClick,
}: {
  handleClick: (e: SyntheticEvent) => void;
}) {
  return (
    <button className={css.btn} onClick={handleClick}>
      Add filter
      <AddFilterIcon />
    </button>
  );
};
