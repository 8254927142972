import { toJS } from 'mobx';
import * as YAML from 'yaml';

import {
  CiliumNetworkPolicy,
  EgressRule,
  IngressRule,
  Rule,
} from '~/domain/cilium/cnp/types.generated';
import {
  CardKind,
  CardSide,
  CardsMap,
  DefaultDenyKind,
  EndpointAllKind,
  EndpointCidrKind,
  EndpointKind,
  PolicyBuilder,
  PolicyKind,
  PolicyParseResult,
} from '~/domain/cimulator/types';
import { NamespaceLabelKey } from '~/domain/labels';
import { logger } from '~/utils/logger';

import { PolicyCard } from './cards';
import { PolicyEndpoint } from './endpoint';
import { YAML_POLICY_STRINGIFY_OPTS } from '../misc';

const SUPPORTED_EGRESS_FIELDS = new Set([
  'toEndpoints',
  'toPorts',
  'toCIDR',
  'toCIDRSet',
  'toEntities',
  'toServices',
  'toFQDNs',
  'authentication',
]);

const SUPPORTED_INGRESS_FIELDS = new Set([
  'fromEndpoints',
  'toPorts',
  'fromEntities',
  'fromCIDR',
  'fromCIDRSet',
  'authentication',
]);

export type IPolicyBuilder = PolicyBuilder<Rule, IngressRule, EgressRule>;

export const CiliumNetworkPolicyBuilder: IPolicyBuilder = {
  parsePolicy(json) {
    const np = json as CiliumNetworkPolicy;
    const policyName = (np.metadata?.name ?? null) as string | null;
    const policyNamespace = (np.metadata?.namespace ?? null) as string | null;
    const isClusterwide = np.kind === 'CiliumClusterwideNetworkPolicy';

    if (!np.specs && !np.spec) {
      return {
        policyName,
        policyNamespace,
        isSingleSpec: true,
        results: [
          {
            cards: new Map(),
            defaultDenyEgress: null,
            defaultDenyIngress: null,
            unspprtdEgress: [],
            unspprtdIngress: [],
          },
        ],
      };
    }

    const isSingleSpec = Boolean(np.spec);

    const specs = [...(np.specs ? np.specs : []), ...(np.spec ? [np.spec] : [])];

    const results: PolicyParseResult[] = [];

    specs.forEach((spec, specIdx) => {
      const cards = new Map<string, PolicyCard>();

      const unspprtdIngress: IngressRule[] = [];
      const unspprtdEgress: EgressRule[] = [];

      let defaultDenyIngress: DefaultDenyKind | null = null;
      let defaultDenyEgress: DefaultDenyKind | null = null;

      if (spec.endpointSelector) {
        const card = new PolicyCard(CardSide.Selector, CardKind.InNamespace).setPodSelector(
          spec.endpointSelector,
        );
        cards.set(card.id, card);
      }

      if (spec.ingress?.length) {
        defaultDenyIngress = DefaultDenyKind.CnpEmptyObjectRule;
      }

      if (spec.egress?.length) {
        defaultDenyEgress = DefaultDenyKind.CnpEmptyObjectRule;
      }

      const addCardEndpoint = (
        cardsToAdd: Map<string, PolicyCard>,
        cardKind: CardKind,
        cardSide: CardSide,
        endpoint: PolicyEndpoint,
      ) => {
        const cardId = PolicyCard.buildId(cardSide, cardKind);
        const card = cardsToAdd.get(cardId) ?? new PolicyCard(cardSide, cardKind);
        card.addEndpoints(endpoint);
        cardsToAdd.set(card.id, card);
        return card;
      };

      const mergeCards = (cardsToAdd: Map<string, PolicyCard>) => {
        cardsToAdd.forEach((card, cardId) => {
          const curCard = cards.get(cardId);
          if (!curCard) return cards.set(cardId, card);
          return curCard.addEndpoints(...card.endpointsList);
        });
      };

      let egressIdx = 0;
      const egressNodes = (isSingleSpec ? np.spec?.egress : np.specs?.[specIdx].egress) ?? [];

      egressLoop: for (const egress of spec.egress ?? []) {
        const cardsToAdd = new Map<string, PolicyCard>();
        const range = (egressNodes[egressIdx++] as YAML.Node).range;

        const addEgressCardEndpoint = (cardKind: CardKind, endpoint: PolicyEndpoint) => {
          endpoint.setOrigYamlRange(range);
          endpoint.setAuthentication(egress.authentication?.mode ?? null);
          return addCardEndpoint(cardsToAdd, cardKind, CardSide.Egress, endpoint);
        };

        const originRule = { rule: egress, policyKind: PolicyKind.CNP };
        for (const field of Object.keys(egress)) {
          if (!SUPPORTED_EGRESS_FIELDS.has(field)) {
            unspprtdEgress.push(egress);
            continue egressLoop;
          }
        }

        if (PolicyEndpoint.checkCNPRuleIsKubeDns(egress)) {
          addEgressCardEndpoint(
            CardKind.InCluster,
            PolicyEndpoint.newKubeDns().setOriginRule(originRule),
          );
          mergeCards(cardsToAdd);
          continue egressLoop;
        }

        for (const entity of egress.toEntities ?? []) {
          entitySwitch: switch (entity) {
            case EndpointKind.RemoteNode:
            case EndpointKind.Host:
            case EndpointKind.KubeApiserver:
              addEgressCardEndpoint(
                CardKind.InCluster,
                PolicyEndpoint.fromKind(entity).addPorts(egress.toPorts),
              );
              break entitySwitch;
            case CardKind.OutsideCluster:
            case CardKind.InCluster:
            case CardKind.All: {
              const cardKind = entity;
              addEgressCardEndpoint(cardKind, PolicyEndpoint.newAll().addPorts(egress.toPorts));
              break entitySwitch;
            }
            default: {
              unspprtdEgress.push(egress);
              continue egressLoop;
            }
          }
        }

        for (const selector of egress.toEndpoints ?? []) {
          const endpoint =
            Object.keys(selector).length === 0 &&
            Object.keys(selector.matchLabels ?? {}).length === 0 &&
            (selector.matchExpressions ?? []).length === 0
              ? PolicyEndpoint.newAll()
              : PolicyEndpoint.fromSelector(selector);

          endpoint.addPorts(egress.toPorts);

          if (endpoint.hasCNPAllNamespacesSelector) {
            endpoint.setAllKind(EndpointAllKind.AllNamespacesSelector);
          }

          const cardKind =
            endpoint.namespaceSelector || isClusterwide ? CardKind.InCluster : CardKind.InNamespace;

          const card = addEgressCardEndpoint(cardKind, endpoint);

          if (endpoint.namespace) card.setNamespace(endpoint.namespace);
        }

        for (const service of egress.toServices ?? []) {
          const endpoint = PolicyEndpoint.fromService(service);
          endpoint.addPorts(egress.toPorts);

          const cardKind =
            endpoint.namespace || isClusterwide ? CardKind.InCluster : CardKind.InNamespace;

          const card = addEgressCardEndpoint(cardKind, endpoint);

          if (endpoint.namespace) card.setNamespace(endpoint.namespace);
        }

        for (const fqdn of egress.toFQDNs ?? []) {
          addEgressCardEndpoint(
            CardKind.OutsideCluster,
            PolicyEndpoint.fromFQDN(fqdn).addPorts(egress.toPorts),
          );
        }

        for (const cidr of egress.toCIDR ?? []) {
          addEgressCardEndpoint(
            CardKind.OutsideCluster,
            PolicyEndpoint.fromCIDRString(cidr)
              .setCidrKind(EndpointCidrKind.String)
              .addPorts(egress.toPorts),
          );
        }

        for (const cidr of egress.toCIDRSet ?? []) {
          addEgressCardEndpoint(
            CardKind.OutsideCluster,
            PolicyEndpoint.fromCIDR(cidr).addPorts(egress.toPorts),
          );
        }

        if (cardsToAdd.size === 0 && egress.toPorts) {
          addEgressCardEndpoint(CardKind.All, PolicyEndpoint.newAll().addPorts(egress.toPorts));
        }

        if (cardsToAdd.size > 0) {
          mergeCards(cardsToAdd);
        } else if (Object.keys(egress).length > 0) {
          unspprtdEgress.push(egress);
        }
      }

      let ingressIdx = 0;
      const ingressNodes = (isSingleSpec ? np.spec?.ingress : np.specs?.[specIdx].ingress) ?? [];

      ingressLoop: for (const ingress of spec.ingress ?? []) {
        for (const field of Object.keys(ingress)) {
          if (!SUPPORTED_INGRESS_FIELDS.has(field)) {
            unspprtdIngress.push(ingress);
            continue ingressLoop;
          }
        }

        const cardsToAdd = new Map<string, PolicyCard>();
        const range = (ingressNodes[ingressIdx++] as YAML.Node).range;

        const addIngressCardEndpoint = (cardKind: CardKind, endpoint: PolicyEndpoint) => {
          endpoint.setOrigYamlRange(range);
          endpoint.setAuthentication(ingress.authentication?.mode ?? null);
          return addCardEndpoint(cardsToAdd, cardKind, CardSide.Ingress, endpoint);
        };

        for (const entity of ingress.fromEntities ?? []) {
          entitySwitch: switch (entity) {
            case EndpointKind.RemoteNode:
            case EndpointKind.Host:
            case EndpointKind.KubeApiserver:
              addIngressCardEndpoint(
                CardKind.InCluster,
                PolicyEndpoint.fromKind(entity).addPorts(ingress.toPorts),
              );
              break entitySwitch;
            case CardKind.OutsideCluster:
            case CardKind.InCluster:
            case CardKind.All: {
              const cardKind = entity;
              addIngressCardEndpoint(cardKind, PolicyEndpoint.newAll().addPorts(ingress.toPorts));
              break entitySwitch;
            }
            default: {
              unspprtdIngress.push(ingress);
              continue ingressLoop;
            }
          }
        }

        for (const selector of ingress.fromEndpoints ?? []) {
          const endpoint =
            Object.keys(selector).length === 0 &&
            Object.keys(selector.matchLabels ?? {}).length === 0 &&
            (selector.matchExpressions ?? []).length === 0
              ? PolicyEndpoint.newAll()
              : PolicyEndpoint.fromSelector(selector);

          if (endpoint.hasCNPAllNamespacesSelector) {
            endpoint.setAllKind(EndpointAllKind.AllNamespacesSelector);
          }

          endpoint.addPorts(ingress.toPorts);

          const cardKind =
            endpoint.namespaceSelector || isClusterwide ? CardKind.InCluster : CardKind.InNamespace;

          const card = addIngressCardEndpoint(cardKind, endpoint);

          if (endpoint.namespace) card.setNamespace(endpoint.namespace);
        }

        for (const cidr of ingress.fromCIDR ?? []) {
          addIngressCardEndpoint(
            CardKind.OutsideCluster,
            PolicyEndpoint.fromCIDRString(cidr)
              .setCidrKind(EndpointCidrKind.String)
              .addPorts(ingress.toPorts),
          );
        }

        for (const cidr of ingress.fromCIDRSet ?? []) {
          addIngressCardEndpoint(
            CardKind.OutsideCluster,
            PolicyEndpoint.fromCIDR(cidr).addPorts(ingress.toPorts),
          );
        }

        if (cardsToAdd.size === 0 && ingress.toPorts) {
          addIngressCardEndpoint(CardKind.All, PolicyEndpoint.newAll().addPorts(ingress.toPorts));
        }

        if (cardsToAdd.size > 0) {
          mergeCards(cardsToAdd);
        } else if (Object.keys(ingress).length > 0) {
          unspprtdIngress.push(ingress);
        }
      }

      results.push({
        cards,
        defaultDenyEgress,
        defaultDenyIngress,
        unspprtdIngress,
        unspprtdEgress,
      });
    });

    return {
      policyName,
      policyNamespace,
      isSingleSpec,
      results,
    };
  },

  generateYaml(policyName, policyNamespace, specs, isSingleSpec, isClusterwide) {
    const np: CiliumNetworkPolicy = this.generatePolicyHeader(
      policyName,
      policyNamespace,
      isClusterwide,
    );

    if (specs.length === 0) {
      return YAML.stringify(np, YAML_POLICY_STRINGIFY_OPTS);
    }

    const gen = ({ spec, unspprtdEgress, unspprtdIngress }: (typeof specs)[number]) => {
      const rule: Rule = {};

      if (spec.endpointSelector) {
        Object.assign(rule, { endpointSelector: spec.endpointSelector });
      }

      if (spec.ingress?.length) {
        const ingress = spec.ingress.slice();
        if (unspprtdIngress.length > 0) {
          (ingress as any).push('__unsupported_ingress__');
          ingress.push(...unspprtdIngress);
        }
        Object.assign(rule, { ingress });
      }

      if (spec.egress?.length) {
        const egress = spec.egress.slice();
        if (unspprtdEgress.length > 0) {
          (egress as any).push('__unsupported_egress__');
          egress.push(...unspprtdEgress);
        }
        Object.assign(rule, { egress });
      }

      return rule;
    };

    if (isSingleSpec) np.spec = gen(specs[0]);
    else np.specs = specs.map(gen);

    let yaml = YAML.stringify(np, YAML_POLICY_STRINGIFY_OPTS);
    ['ingress', 'egress'].forEach(dir => {
      yaml = yaml.replace(
        new RegExp(`- __unsupported_${dir}__`, 'g'),
        `# editor doesn't yet support ${dir} rules below`,
      );
    });

    return yaml;
  },

  generateYamlForCardEndpoint(card, endpoint, onlyRule) {
    const rule = this.generateSpecForCardEndpoint(card, endpoint, onlyRule);
    if (!rule) return null;
    return YAML.stringify(rule, YAML_POLICY_STRINGIFY_OPTS);
  },

  generatePolicyHeader(name, namespace, isClusterwide) {
    return {
      apiVersion: 'cilium.io/v2',
      kind: isClusterwide ? 'CiliumClusterwideNetworkPolicy' : 'CiliumNetworkPolicy',
      metadata: {
        name: name || 'untitled-policy',
        namespace: namespace || undefined,
      },
    };
  },

  generateSpecForCardEndpoint(card, endpoint, onlyRule) {
    const ingresses: CardsMap = new Map();
    const egresses: CardsMap = new Map();

    const cloned = card.clone().flushEndpoints();
    if (endpoint) cloned.addEndpoints(endpoint);

    if (card.isIngress) {
      ingresses.set(card.id, cloned);
    } else if (card.isEgress) {
      egresses.set(card.id, cloned);
    }

    return this.generateSpec(card.podSelector, ingresses, egresses, null, null, null, onlyRule);
  },

  generateSpec(
    podSelector,
    ingresses,
    egresses,
    defaultDenyIngress,
    defaultDenyEgress,
    namespace,
    onlyRule = false,
  ) {
    const ingress: IngressRule[] = [];
    const egress: EgressRule[] = [];

    const spec: Rule = {
      endpointSelector: toJS(onlyRule ? undefined : (podSelector ?? {})),
    };

    ingresses.forEach(card => {
      card.endpointsMap.forEach(endpoint => {
        const rule: IngressRule = {
          fromEntities: [],
          fromEndpoints: [],
          fromCIDRSet: [],
          fromCIDR: [],
        };
        if (endpoint.authentication) {
          rule.authentication = { mode: 'required' };
        }
        switch (endpoint.kind) {
          case EndpointKind.All:
            switch (card.kind) {
              case CardKind.All:
                if (!endpoint.ports) rule.fromEntities?.push('all');
                break;
              case CardKind.OutsideCluster:
                rule.fromEntities?.push('world');
                break;
              case CardKind.InCluster:
                rule.fromEntities?.push('cluster');
                break;
              case CardKind.InNamespace:
                if (namespace && card.namespace !== namespace) {
                  rule.fromEndpoints?.unshift({
                    matchLabels: { [NamespaceLabelKey]: namespace },
                  });
                } else {
                  rule.fromEndpoints?.unshift({});
                }
                break;
              default:
                logger.log('Card kind is not supported for egress policy generation', card.kind);
            }
            break;
          case EndpointKind.Host:
          case EndpointKind.KubeApiserver:
          case EndpointKind.RemoteNode:
            if (card.isInCluster) {
              rule.fromEntities?.push(endpoint.kind);
              break;
            }
            logger.log('Endpoint kind is not supported for policy generation', card.kind);
            break;
          case EndpointKind.Cidr:
            if (!endpoint.cidr) break;
            if (endpoint.cidrKind === EndpointCidrKind.String) {
              rule.fromCIDR?.push(endpoint.cidr.cidr);
            } else {
              rule.fromCIDRSet?.push(endpoint.cidr);
            }
            break;
          case EndpointKind.LabelsSelector: {
            let selector = endpoint.labelsSelector;
            if (endpoint.selectsAllNamespaces && !endpoint.hasCNPAllNamespacesSelector) {
              const matchExpressions = [...(selector?.matchExpressions ?? [])];
              matchExpressions.push({
                key: NamespaceLabelKey,
                operator: 'Exists',
              });
              selector = { ...selector, matchExpressions };
            }
            if (selector) rule.fromEndpoints?.push(selector);
            break;
          }
          case EndpointKind.NamespaceSelector:
            if (namespace && endpoint.namespace === namespace) {
              rule.fromEndpoints?.push({});
            } else if (endpoint.labelsSelector) {
              rule.fromEndpoints?.push(endpoint.labelsSelector);
            }
            break;
          case EndpointKind.None:
            // do nothing
            break;
          default:
            logger.log(
              'Endpoint kind is not supported for egress policy generation',
              endpoint.kind,
            );
        }
        if (endpoint.ports) {
          rule.toPorts = endpoint.ports;
        }
        // cleanup rule from empty structures
        rule.fromEndpoints?.length === 0 && delete rule.fromEndpoints;
        rule.fromEntities?.length === 0 && delete rule.fromEntities;
        rule.fromCIDRSet?.length === 0 && delete rule.fromCIDRSet;
        rule.fromCIDR?.length === 0 && delete rule.fromCIDR;
        rule.toPorts?.length === 0 && delete rule.toPorts;
        ingress.push(rule);
      });
    });

    egresses.forEach(card => {
      card.endpointsList.forEach(endpoint => {
        if (endpoint.isKubeDns && endpoint.originRule?.policyKind === PolicyKind.CNP) {
          const originRule = { ...endpoint.originRule.rule } as EgressRule;
          if (!endpoint.isDNSProxyEnabled) {
            // drop dns proxy from rule if disabled
            originRule.toPorts = originRule.toPorts?.map(port => {
              const updport = { ...port };
              if (!updport.rules) return updport;
              const { dns, ...rules } = updport.rules;
              if (Object.keys(rules).length === 0) {
                return { ...updport, rules: undefined };
              }
              return { ...updport, rules };
            });
          }
          egress.push(originRule);
          return;
        }
        const rule: EgressRule = {
          toEntities: [],
          toEndpoints: [],
          toServices: [],
          toFQDNs: [],
          toCIDRSet: [],
          toCIDR: [],
        };
        if (endpoint.authentication) {
          rule.authentication = { mode: 'required' };
        }
        switch (endpoint.kind) {
          case EndpointKind.All:
            switch (card.kind) {
              case CardKind.All:
                if (!endpoint.ports) rule.toEntities?.push('all');
                break;
              case CardKind.OutsideCluster:
                rule.toEntities?.push('world');
                break;
              case CardKind.InCluster:
                rule.toEntities?.push('cluster');
                break;
              case CardKind.InNamespace:
                if (namespace && card.namespace !== namespace) {
                  rule.toEndpoints?.unshift({
                    matchLabels: { [NamespaceLabelKey]: namespace },
                  });
                } else {
                  rule.toEndpoints?.unshift({});
                }
                break;
              default:
                logger.log('Card kind is not supported for egress policy generation', card.kind);
            }
            break;
          case EndpointKind.Service:
            endpoint.service && rule.toServices?.push(endpoint.service);
            break;
          case EndpointKind.Host:
          case EndpointKind.KubeApiserver:
          case EndpointKind.RemoteNode:
            if (card.isInCluster) {
              rule.toEntities?.push(endpoint.kind);
              break;
            }
            logger.log('Endpoint kind is not supported for policy generation', card.kind);
            break;
          case EndpointKind.Fqdn:
            endpoint.fqdn && rule.toFQDNs?.push(endpoint.fqdn);
            break;
          case EndpointKind.Cidr:
            if (!endpoint.cidr) break;
            if (endpoint.cidrKind === EndpointCidrKind.String) {
              rule.toCIDR?.push(endpoint.cidr.cidr);
            } else {
              rule.toCIDRSet?.push(endpoint.cidr);
            }
            break;
          case EndpointKind.KubeDns:
          case EndpointKind.LabelsSelector: {
            let selector = endpoint.labelsSelector;
            if (endpoint.selectsAllNamespaces && !endpoint.hasCNPAllNamespacesSelector) {
              const matchExpressions = [...(selector?.matchExpressions ?? [])];
              matchExpressions.push({
                key: NamespaceLabelKey,
                operator: 'Exists',
              });
              selector = { ...selector, matchExpressions };
            }
            if (selector) rule.toEndpoints?.push(selector);
            break;
          }
          case EndpointKind.NamespaceSelector:
            if (namespace && endpoint.namespace === namespace) {
              rule.toEndpoints?.push({});
            } else if (endpoint.labelsSelector) {
              rule.toEndpoints?.push(endpoint.labelsSelector);
            }
            break;
          case EndpointKind.None:
            // do nothing
            break;
          default:
            logger.log(
              'Endpoint kind is not supported for egress policy generation',
              endpoint.kind,
            );
        }
        if (endpoint.ports) {
          rule.toPorts = endpoint.ports;
        }
        // cleanup rule from empty structures
        rule.toEndpoints?.length === 0 && delete rule.toEndpoints;
        rule.toFQDNs?.length === 0 && delete rule.toFQDNs;
        rule.toEntities?.length === 0 && delete rule.toEntities;
        rule.toServices?.length === 0 && delete rule.toServices;
        rule.toCIDRSet?.length === 0 && delete rule.toCIDRSet;
        rule.toCIDR?.length === 0 && delete rule.toCIDR;
        rule.toPorts?.length === 0 && delete rule.toPorts;
        egress.push(rule);
      });
    });

    if (defaultDenyIngress && ingress.length === 0) ingress.push({});
    if (defaultDenyEgress && egress.length === 0) egress.push({});

    if (ingress.length) spec.ingress = ingress;
    if (egress.length) spec.egress = egress;

    return spec;
  },
};
