import React from 'react';

export enum Direction {
  ASC = 'asc',
  DESC = 'desc',
}

export function useSortableTableColumn<Columns>(
  initialColumn: Columns,
  initialDirecton: Direction = Direction.ASC,
) {
  const [column, setColumn] = React.useState<Columns>(initialColumn);
  const [direction, setDirection] = React.useState<Direction>(initialDirecton);

  const onChange = React.useCallback(
    (nextColumn: Columns) => {
      if (nextColumn !== column) {
        setColumn(nextColumn);
      } else {
        setDirection(direction === Direction.ASC ? Direction.DESC : Direction.ASC);
      }
    },
    [column, direction],
  );

  return { column, direction, onChange };
}

export type SortableColumnProps<Columns> = ReturnType<typeof useSortableTableColumn<Columns>>;
