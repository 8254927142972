import React, { createContext, memo, PropsWithChildren, useContext } from 'react';

import { configure } from 'mobx';

import { CimulatorArrowStrategy, CimulatorPlacementStrategy } from '~/domain/layout/cimulator';
import type { Store as MainStore } from '~/store';

import { PolicyAssistantStore } from './assistant';
import ControlStore from './cimulator-controls';
import { PolicyStore } from './policy';
import { PolicySuggestionsStore } from './policy-suggestions';
import UserStore from './user';

configure({ enforceActions: 'observed' });

class Store {
  main?: MainStore;
  controls: ControlStore;
  policy: PolicyStore;
  assistant: PolicyAssistantStore;
  suggestions: PolicySuggestionsStore;
  placement: CimulatorPlacementStrategy;
  arrows: CimulatorArrowStrategy;
  user: UserStore;

  constructor() {
    this.controls = new ControlStore();
    this.policy = new PolicyStore(this.controls);
    this.suggestions = new PolicySuggestionsStore(this.policy);
    this.user = new UserStore();
    this.assistant = new PolicyAssistantStore(this.controls);
    this.placement = new CimulatorPlacementStrategy(this.policy);
    this.arrows = new CimulatorArrowStrategy(this.placement, this.policy);
  }

  setMainStore(store: MainStore) {
    this.main = store;
  }
}

const StoreContext = createContext<Store | null>(null);

export type Props = PropsWithChildren<{
  store: Store;
}>;

const StoreProvider = memo<Props>(function StoreProvider(props: Props) {
  return <StoreContext.Provider value={props.store}>{props.children}</StoreContext.Provider>;
});

const useStore = () => {
  const store = useContext(StoreContext);
  if (!store) {
    throw new Error('useStore must be used within a StoreProvider.');
  }

  return store;
};

const store = new Store();

export { useStore, StoreProvider, Store, store };
