import React from 'react';

import classNames from 'classnames';
import * as mobx from 'mobx';
import { observer } from 'mobx-react';

import { useRefsCollector } from '~/components/ProcessTree/RefsCollectorContext';
import { XY } from '~/domain/geometry';
import { IPEndpoint } from '~/domain/interactions/endpoints';

import { Endpoint } from './Endpoint';
import css from './styles.scss';

export interface Props {
  group: IPEndpoint[];
  groupName: string;
  isExpanded?: boolean;
  isIpVisible?: boolean;

  onPortConnectorCoords?: (port: number, xy: XY, ip?: string) => void;
  onClick?: (port: number, ip?: string) => void;
  onGroupClick?: () => void;
  onExpandToggle?: () => void;
}

export const EndpointGroup = observer(function EndpointGroup(props: Props) {
  const ictx = useRefsCollector();

  const ports = mobx
    .computed(() => {
      const ports: Set<number> = new Set();

      props.group.forEach(ipEndpoint => {
        ipEndpoint.ports.forEach(port => ports.add(port));
      });

      return [...ports].sort();
    })
    .get();

  const isExpanded = mobx
    .computed(() => {
      return props.isExpanded || ports.length <= 1;
    })
    .get();

  if (ports.length === 0) return null;
  const classes = classNames(css.endpointGroup);

  return (
    <div className={classes}>
      {ports.length > 1 && (
        <div className={css.handle} ref={ictx.destinationGroupConnectorRef(props.groupName)}>
          <div className={css.collapsedLine} onClick={props.onGroupClick}>
            {ports.slice(0, 3).map(port => {
              return (
                <span className={css.port} key={port}>
                  {port || 'n/a'}
                </span>
              );
            })}
            {ports.length > 3 && (
              <span className={css.portsEllipsis} title={`${ports.length} ports total`}>
                ...
              </span>
            )}
          </div>

          <div className={css.expandControls}>
            <div className={css.toggleButton} onClick={props.onExpandToggle}>
              {props.isExpanded ? 'Hide' : 'Expand'}
            </div>
          </div>
        </div>
      )}

      {isExpanded && (
        <div className={css.endpoints}>
          {!props.isIpVisible &&
            ports.map(port => {
              return (
                <Endpoint
                  key={port}
                  port={port}
                  onClick={() => props.onClick?.(port)}
                  connectorRef={ictx.destinationPortConnectorRef(props.groupName, port)}
                />
              );
            })}

          {!!props.isIpVisible &&
            props.group.map(ipEndpoint => {
              return (
                <React.Fragment key={ipEndpoint.ip}>
                  {ipEndpoint.portsArray.map(port => {
                    return (
                      <Endpoint
                        key={`${ipEndpoint.ip}:${port}`}
                        port={port}
                        ipAddress={ipEndpoint.ip}
                        onClick={() => props.onClick?.(port, ipEndpoint.ip)}
                        connectorRef={ictx.destinationPortConnectorRef(
                          props.groupName,
                          port,
                          ipEndpoint.ip,
                        )}
                      />
                    );
                  })}
                </React.Fragment>
              );
            })}
        </div>
      )}
    </div>
  );
});
