/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter add_pb_suffix,eslint_disable,ts_nocheck,generate_dependencies,long_type_number,use_proto_field_name
// @generated from protobuf file "ui/feature-flags.proto" (package "ui", syntax proto3)
// tslint:disable
// @ts-nocheck
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message ui.GetFeatureFlagsRequest
 */
export interface GetFeatureFlagsRequest {
}
/**
 * @generated from protobuf message ui.GetFeatureFlagsResponse
 */
export interface GetFeatureFlagsResponse {
    /**
     * @generated from protobuf field: ui.TimescapeFeatureFlag timescape = 1;
     */
    timescape?: TimescapeFeatureFlag;
    /**
     * @generated from protobuf field: ui.TimescapeOnlyFeatureFlag timescape_only = 2;
     */
    timescape_only?: TimescapeOnlyFeatureFlag;
    /**
     * @generated from protobuf field: ui.GenericFeatureFlag service_map_file_upload = 3;
     */
    service_map_file_upload?: GenericFeatureFlag;
    /**
     * @generated from protobuf field: ui.GrafanaFeatureFlag grafana = 4;
     */
    grafana?: GrafanaFeatureFlag;
    /**
     * @generated from protobuf field: ui.TetragonFeatureFlag tetragon = 5;
     */
    tetragon?: TetragonFeatureFlag;
}
/**
 * @generated from protobuf message ui.TimescapeFeatureFlag
 */
export interface TimescapeFeatureFlag {
    /**
     * @generated from protobuf field: bool enabled = 1;
     */
    enabled: boolean;
}
/**
 * @generated from protobuf message ui.TimescapeOnlyFeatureFlag
 */
export interface TimescapeOnlyFeatureFlag {
    /**
     * @generated from protobuf field: bool enabled = 1;
     */
    enabled: boolean;
}
/**
 * @generated from protobuf message ui.GenericFeatureFlag
 */
export interface GenericFeatureFlag {
    /**
     * @generated from protobuf field: bool enabled = 1;
     */
    enabled: boolean;
}
/**
 * @generated from protobuf message ui.GrafanaFeatureFlag
 */
export interface GrafanaFeatureFlag {
    /**
     * @generated from protobuf field: bool enabled = 1;
     */
    enabled: boolean;
    /**
     * @generated from protobuf field: string dashboard_json = 2;
     */
    dashboard_json: string;
    /**
     * @generated from protobuf field: bool enabled_nodes_graph = 3;
     */
    enabled_nodes_graph: boolean;
}
/**
 * @generated from protobuf message ui.TetragonFeatureFlag
 */
export interface TetragonFeatureFlag {
    /**
     * @generated from protobuf field: bool only = 1;
     */
    only: boolean;
}
// @generated message type with reflection information, may provide speed optimized methods
class GetFeatureFlagsRequest$Type extends MessageType<GetFeatureFlagsRequest> {
    constructor() {
        super("ui.GetFeatureFlagsRequest", []);
    }
    create(value?: PartialMessage<GetFeatureFlagsRequest>): GetFeatureFlagsRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<GetFeatureFlagsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetFeatureFlagsRequest): GetFeatureFlagsRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: GetFeatureFlagsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ui.GetFeatureFlagsRequest
 */
export const GetFeatureFlagsRequest = new GetFeatureFlagsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetFeatureFlagsResponse$Type extends MessageType<GetFeatureFlagsResponse> {
    constructor() {
        super("ui.GetFeatureFlagsResponse", [
            { no: 1, name: "timescape", kind: "message", T: () => TimescapeFeatureFlag },
            { no: 2, name: "timescape_only", kind: "message", localName: "timescape_only", T: () => TimescapeOnlyFeatureFlag },
            { no: 3, name: "service_map_file_upload", kind: "message", localName: "service_map_file_upload", T: () => GenericFeatureFlag },
            { no: 4, name: "grafana", kind: "message", T: () => GrafanaFeatureFlag },
            { no: 5, name: "tetragon", kind: "message", T: () => TetragonFeatureFlag }
        ]);
    }
    create(value?: PartialMessage<GetFeatureFlagsResponse>): GetFeatureFlagsResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<GetFeatureFlagsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetFeatureFlagsResponse): GetFeatureFlagsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* ui.TimescapeFeatureFlag timescape */ 1:
                    message.timescape = TimescapeFeatureFlag.internalBinaryRead(reader, reader.uint32(), options, message.timescape);
                    break;
                case /* ui.TimescapeOnlyFeatureFlag timescape_only */ 2:
                    message.timescape_only = TimescapeOnlyFeatureFlag.internalBinaryRead(reader, reader.uint32(), options, message.timescape_only);
                    break;
                case /* ui.GenericFeatureFlag service_map_file_upload */ 3:
                    message.service_map_file_upload = GenericFeatureFlag.internalBinaryRead(reader, reader.uint32(), options, message.service_map_file_upload);
                    break;
                case /* ui.GrafanaFeatureFlag grafana */ 4:
                    message.grafana = GrafanaFeatureFlag.internalBinaryRead(reader, reader.uint32(), options, message.grafana);
                    break;
                case /* ui.TetragonFeatureFlag tetragon */ 5:
                    message.tetragon = TetragonFeatureFlag.internalBinaryRead(reader, reader.uint32(), options, message.tetragon);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetFeatureFlagsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* ui.TimescapeFeatureFlag timescape = 1; */
        if (message.timescape)
            TimescapeFeatureFlag.internalBinaryWrite(message.timescape, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* ui.TimescapeOnlyFeatureFlag timescape_only = 2; */
        if (message.timescape_only)
            TimescapeOnlyFeatureFlag.internalBinaryWrite(message.timescape_only, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* ui.GenericFeatureFlag service_map_file_upload = 3; */
        if (message.service_map_file_upload)
            GenericFeatureFlag.internalBinaryWrite(message.service_map_file_upload, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* ui.GrafanaFeatureFlag grafana = 4; */
        if (message.grafana)
            GrafanaFeatureFlag.internalBinaryWrite(message.grafana, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* ui.TetragonFeatureFlag tetragon = 5; */
        if (message.tetragon)
            TetragonFeatureFlag.internalBinaryWrite(message.tetragon, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ui.GetFeatureFlagsResponse
 */
export const GetFeatureFlagsResponse = new GetFeatureFlagsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TimescapeFeatureFlag$Type extends MessageType<TimescapeFeatureFlag> {
    constructor() {
        super("ui.TimescapeFeatureFlag", [
            { no: 1, name: "enabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<TimescapeFeatureFlag>): TimescapeFeatureFlag {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.enabled = false;
        if (value !== undefined)
            reflectionMergePartial<TimescapeFeatureFlag>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TimescapeFeatureFlag): TimescapeFeatureFlag {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool enabled */ 1:
                    message.enabled = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TimescapeFeatureFlag, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool enabled = 1; */
        if (message.enabled !== false)
            writer.tag(1, WireType.Varint).bool(message.enabled);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ui.TimescapeFeatureFlag
 */
export const TimescapeFeatureFlag = new TimescapeFeatureFlag$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TimescapeOnlyFeatureFlag$Type extends MessageType<TimescapeOnlyFeatureFlag> {
    constructor() {
        super("ui.TimescapeOnlyFeatureFlag", [
            { no: 1, name: "enabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<TimescapeOnlyFeatureFlag>): TimescapeOnlyFeatureFlag {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.enabled = false;
        if (value !== undefined)
            reflectionMergePartial<TimescapeOnlyFeatureFlag>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TimescapeOnlyFeatureFlag): TimescapeOnlyFeatureFlag {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool enabled */ 1:
                    message.enabled = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TimescapeOnlyFeatureFlag, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool enabled = 1; */
        if (message.enabled !== false)
            writer.tag(1, WireType.Varint).bool(message.enabled);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ui.TimescapeOnlyFeatureFlag
 */
export const TimescapeOnlyFeatureFlag = new TimescapeOnlyFeatureFlag$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GenericFeatureFlag$Type extends MessageType<GenericFeatureFlag> {
    constructor() {
        super("ui.GenericFeatureFlag", [
            { no: 1, name: "enabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<GenericFeatureFlag>): GenericFeatureFlag {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.enabled = false;
        if (value !== undefined)
            reflectionMergePartial<GenericFeatureFlag>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GenericFeatureFlag): GenericFeatureFlag {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool enabled */ 1:
                    message.enabled = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GenericFeatureFlag, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool enabled = 1; */
        if (message.enabled !== false)
            writer.tag(1, WireType.Varint).bool(message.enabled);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ui.GenericFeatureFlag
 */
export const GenericFeatureFlag = new GenericFeatureFlag$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GrafanaFeatureFlag$Type extends MessageType<GrafanaFeatureFlag> {
    constructor() {
        super("ui.GrafanaFeatureFlag", [
            { no: 1, name: "enabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "dashboard_json", kind: "scalar", localName: "dashboard_json", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "enabled_nodes_graph", kind: "scalar", localName: "enabled_nodes_graph", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<GrafanaFeatureFlag>): GrafanaFeatureFlag {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.enabled = false;
        message.dashboard_json = "";
        message.enabled_nodes_graph = false;
        if (value !== undefined)
            reflectionMergePartial<GrafanaFeatureFlag>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GrafanaFeatureFlag): GrafanaFeatureFlag {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool enabled */ 1:
                    message.enabled = reader.bool();
                    break;
                case /* string dashboard_json */ 2:
                    message.dashboard_json = reader.string();
                    break;
                case /* bool enabled_nodes_graph */ 3:
                    message.enabled_nodes_graph = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GrafanaFeatureFlag, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool enabled = 1; */
        if (message.enabled !== false)
            writer.tag(1, WireType.Varint).bool(message.enabled);
        /* string dashboard_json = 2; */
        if (message.dashboard_json !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.dashboard_json);
        /* bool enabled_nodes_graph = 3; */
        if (message.enabled_nodes_graph !== false)
            writer.tag(3, WireType.Varint).bool(message.enabled_nodes_graph);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ui.GrafanaFeatureFlag
 */
export const GrafanaFeatureFlag = new GrafanaFeatureFlag$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TetragonFeatureFlag$Type extends MessageType<TetragonFeatureFlag> {
    constructor() {
        super("ui.TetragonFeatureFlag", [
            { no: 1, name: "only", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<TetragonFeatureFlag>): TetragonFeatureFlag {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.only = false;
        if (value !== undefined)
            reflectionMergePartial<TetragonFeatureFlag>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TetragonFeatureFlag): TetragonFeatureFlag {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool only */ 1:
                    message.only = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TetragonFeatureFlag, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool only = 1; */
        if (message.only !== false)
            writer.tag(1, WireType.Varint).bool(message.only);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ui.TetragonFeatureFlag
 */
export const TetragonFeatureFlag = new TetragonFeatureFlag$Type();
