import { EndpointSelector } from '~/domain/cilium/cnp/types.generated';

import {
  EndpointMatchExpression,
  EndpointRequirementKind,
  EndpointRequirementOperator,
} from './types';

export class EndpointRequirement implements EndpointMatchExpression {
  public kind: EndpointRequirementKind;
  public key: string;
  public operator: EndpointRequirementOperator;
  public values?: string[] | null;

  constructor(
    kind: EndpointRequirementKind,
    key: string,
    op: EndpointRequirementOperator,
    values?: string[] | null,
  ) {
    this.kind = kind;
    this.key = key;
    this.operator = op;
    this.values =
      op === (EndpointRequirementOperator.In || op === EndpointRequirementOperator.NotIn)
        ? values
        : undefined;
  }

  public clone(): EndpointRequirement {
    return new EndpointRequirement(this.kind, this.key, this.operator, this.values?.slice());
  }

  public toEndpointSelector(): EndpointSelector {
    const { key, operator, values } = this;
    if (this.kind === EndpointRequirementKind.MatchLabels) {
      if (!values?.length) return { matchLabels: {} };
      return { matchLabels: { [key]: values[0] } };
    }
    return { matchExpressions: [{ key, operator, values }] };
  }

  get isMatchLabels() {
    return this.kind === EndpointRequirementKind.MatchLabels;
  }

  get isMatchExpression() {
    return this.kind === EndpointRequirementKind.MatchExpression;
  }

  get in(): boolean {
    return this.operator === EndpointRequirementOperator.In;
  }

  get notIn(): boolean {
    return this.operator === EndpointRequirementOperator.NotIn;
  }

  get exists(): boolean {
    return this.operator === EndpointRequirementOperator.Exists;
  }

  get doesNotExist(): boolean {
    return this.operator === EndpointRequirementOperator.DoesNotExist;
  }
}
