import React from 'react';

import { computed } from 'mobx';
import { observer } from 'mobx-react-lite';

import { XY } from '~/domain/geometry';
import * as timeHelpers from '~/domain/helpers/time';
import { LinkThroughput } from '~/domain/hubble';
import { toHumanString, Units } from '~/utils/numbers';

import css from './Content.scss';

export type Props = {
  coords: XY;
  linkThroughput: LinkThroughput;
};

export const FlowsInfoContent = observer(function FlowsInfoContent(props: Props) {
  const throughputLabel = computed(() => {
    const { bytesTransfered } = props.linkThroughput;
    const bytesStr = toHumanString(bytesTransfered, Units.BytesIEC, 2);

    return bytesTransfered === 0 ? null : `Throughput: ${bytesStr}`;
  }).get();

  const connectionsLabel = computed(() => {
    const { flowAmount } = props.linkThroughput;
    const connsStr = toHumanString(flowAmount, Units.Metric, 2);

    return `Connections: ${connsStr}`;
  }).get();

  const latencyLbl = computed(() => {
    const {
      latency: { avg },
    } = props.linkThroughput;

    if (timeHelpers.isZero(avg)) return null;

    return `Latency: ${timeHelpers.toLatencyString(avg, 2)}`;
  }).get();

  return (
    <div className={css.content}>
      {throughputLabel != null && <div>{throughputLabel}</div>}
      {connectionsLabel != null && <div>{connectionsLabel}</div>}
      {latencyLbl != null && <div>{latencyLbl}</div>}
    </div>
  );
});
