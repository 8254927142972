import { action, computed, makeObservable, observable, reaction } from 'mobx';

import { PolicyCard } from '~/domain/cimulator/cards';
import { PolicyEndpoint } from '~/domain/cimulator/endpoint';
import { PolicyKind } from '~/domain/cimulator/types';

import { SpecStore } from './policy-spec';
import { RuleStatusInfo, RuleStatusKind } from './types';
import { createInitialCards } from './utils';

import type { PolicyStore, PolicyStruct } from '.';

export class MultiPolicyStore {
  @observable
  private _policy: PolicyStruct;

  constructor(store: PolicyStore) {
    makeObservable(this);
    this._policy = this.buildPolicy(store);
    this.setupReactions(store);
  }

  @computed
  get policy() {
    return this._policy;
  }

  @computed
  get spec() {
    return this.policy.specs[0];
  }

  @computed
  get cardsMap() {
    return this.spec.cardsMap;
  }

  @computed
  get visibleCardsList() {
    return this.spec.visibleCardsList;
  }

  @computed
  get allowedEndpointsSet(): Set<string> | null {
    return this.spec.allowedEndpointsSet;
  }

  @computed
  get defaultDenyIngress() {
    return this.spec.defaultDenyIngress;
  }

  @computed
  get defaultDenyEgress() {
    return this.spec.defaultDenyEgress;
  }

  @computed
  get hasSomeIngress() {
    return this.spec.hasSomeIngress;
  }

  @computed
  get hasSomeEgress() {
    return this.spec.hasSomeEgress;
  }

  @computed
  get hasEgressRules() {
    return this.spec.hasEgressRules;
  }

  @computed
  get hasIngressRules() {
    return this.spec.hasIngressRules;
  }

  @computed
  get isKubeDnsAllowed(): boolean {
    return this.spec.isKubeDnsAllowed;
  }

  @computed
  get isDNSProxyEnabled(): boolean {
    return this.spec.isDNSProxyEnabled;
  }

  @computed
  get hasEgressFqdnOutsideCluster() {
    return this.spec.hasEgressFqdnOutsideCluster;
  }

  getRuleStatusInfo = (card: PolicyCard, endpoint: PolicyEndpoint): RuleStatusInfo | null => {
    return this.spec.getRuleStatusInfo(card, endpoint);
  };

  isAllowedEndpoint = (endpointId: string): boolean => {
    return this.spec.isAllowedEndpoint(endpointId);
  };

  isVisibleCard = (card: PolicyCard): boolean => {
    return this.spec.isVisibleCard(card);
  };

  @action
  private setupReactions(store: PolicyStore) {
    reaction(
      () => [
        store.policiesList,
        store.allPoliciesAllowedEndpointsSet,
        store.main?.controls.flowFilterGroups,
      ],
      () => {
        this._policy = this.buildPolicy(store);
      },
    );
  }

  @action
  private buildPolicy = (store: PolicyStore) => {
    const resSpec = new SpecStore({
      controls: store.controls,
      originPolicyKind: PolicyKind.CNP,
      defaultDenyEgress: null,
      defaultDenyIngress: null,
      unspprtdEgress: [],
      unspprtdIngress: [],
      cards: createInitialCards(false),
      autoStartCardReactions: false,
      isClusterwide: false,
    });

    const res: PolicyStruct = {
      id: `@@@MULTI_POLICY@@@`,
      name: '@@@MULTI_POLICY@@@',
      isSingleSpec: true,
      isClusterwide: false,
      specs: [resSpec],
      currentSpecIdx: 0,
      isPolicyVersion: false,
    };

    store.filteredPoliciesList.forEach(policy => {
      policy.specs.forEach(spec => {
        if (!spec.hasSomeRules) return;
        if (spec.defaultDenyEgress) resSpec.enableDefaultDenyEgress();
        if (spec.defaultDenyIngress) resSpec.enableDefaultDenyIngress();

        spec.cardsList.forEach(card => {
          card.endpointsList.forEach(endpoint => {
            const fullEndpointId = card.fullEndpointId(endpoint.id);
            const isAllowed =
              spec.isAllowedEndpoint(fullEndpointId) ||
              [
                RuleStatusKind.AllowedByDefaultAllow,
                RuleStatusKind.AllowedByExplicitRule,
                RuleStatusKind.AllowedByOtherRule,
              ].includes(spec.getRuleStatusInfo(card, endpoint).kind);
            if (!isAllowed) return;
            const resCard = resSpec.getCardBy(card.side, card.kind);
            if (!resCard) return;
            resCard.addEndpoints(endpoint);
            resSpec.setAllowedEndpoint(fullEndpointId, true);
          });
        });
      });
    });

    return res;
  };
}
