import { makeAutoObservable } from 'mobx';

import { Authorization } from '~/authorization';
import { DataLayer } from '~/data-layer';
import { NamespaceChange } from '~/domain/events';
import { FilterGroup } from '~/domain/filtering';
import { Verdict } from '~/domain/hubble';
import { DataMode } from '~/domain/interactions';
import { StateChange } from '~/domain/misc';
import { NamespaceDescriptor } from '~/domain/namespaces';
import { Notification } from '~/domain/notifications';
import { TimeRange } from '~/domain/time';
import { Router } from '~/router';
import * as storage from '~/storage/local';
import { Store } from '~/store';

import { Options } from './common';
import { StatusCenter } from './status-center';

export class Controls {
  private store: Store;
  private router: Router;
  private dataLayer: DataLayer;
  private statusCenter: StatusCenter;

  constructor(opts: Options) {
    this.store = opts.store;
    this.router = opts.router;
    this.dataLayer = opts.dataLayer;
    this.statusCenter = opts.statusCenter;

    this.setupEventHandlers();

    makeAutoObservable(this, undefined, { autoBind: true });
  }

  public get isConnectionsMapAvailable() {
    return !this.store.uiSettings.isTetragonOnlyEnabled;
  }

  public get isHostShown() {
    return this.store.controls.showHost;
  }

  public clusterChanged(cluster: string | null) {
    this.clusterNamespaceChanged(cluster, null);
  }

  public clusterNamespaceChanged(cluster: string | null, namespace: string | null) {
    this.dataLayer.controls.clusterNamespaceChanged(cluster, namespace);
  }

  public aggregationChanged(enabled: boolean) {
    this.dataLayer.controls.setAggregationEnabled(enabled);
    this.router.commit();
  }

  public toggleVerdict(verdict: Verdict) {
    this.dataLayer.controls.toggleVerdict(verdict);
    this.router.commit();
  }

  public toggleShowHost() {
    this.dataLayer.controls.toggleShowHost();
  }

  public toggleShowKubeDNS() {
    this.dataLayer.controls.toggleShowKubeDNS();
    this.router.commit();
  }

  public toggleShowRemoteNode() {
    this.dataLayer.controls.toggleShowRemoteNode();
  }

  public toggleShowPrometheusApp() {
    this.dataLayer.controls.toggleShowPrometheusApp();
  }

  public setFlowFilterGroups(
    groups: FilterGroup[] | null,
    opts: { commitRouter?: boolean; resetClusterNamespaceOnEmptyFilters?: boolean } = {},
  ) {
    const { commitRouter = true, resetClusterNamespaceOnEmptyFilters = false } = opts;
    if (!groups?.length) {
      if (resetClusterNamespaceOnEmptyFilters) {
        this.clusterNamespaceChanged(null, null);
      } else {
        this.dataLayer.controls.setFlowFilterGroups([]);
      }
    } else {
      this.dataLayer.controls.setFlowFilterGroups(groups);
    }
    if (commitRouter) {
      this.router.commit();
    }
  }

  public timeRangeChanged(tr: TimeRange) {
    this.dataLayer.controls.setTimeRange(tr);
    this.router.commit();
  }

  public setupControlStream() {
    this.dataLayer.controls
      .ensureControlStream()
      .onNotification(notif => {
        this.checkAuthorization(notif);
        this.statusCenter.push(notif);
      })
      .onNamespaceChanges(changes => this.handleNamespaceChanges(changes))
      .onMessage(msg => {
        if (!msg.isUnauthorized) return;

        Authorization.goToSignin();
      })
      .onErrors(errs => {
        this.statusCenter.pushControlStreamErrors(errs);

        const isRbacNoRoles = errs.some(err => err.isRbacNoRoles);
        if (isRbacNoRoles) this.store.controls.setIsRbacNoRoles(true);
      })
      .run();
  }

  public openPolicy(
    cluster: string | null,
    namespace: string | null,
    policyUuid: string | null,
    policyVersionShortId: string | null,
  ) {
    this.store.cimulator.controls.setPolicyUuid(policyUuid);
    this.store.cimulator.controls.setPolicyVersionShortId(policyVersionShortId);

    this.dataLayer.controls.setClusterNamespace(cluster, namespace);

    // NOTE: Replace option is set, because updating namespace via dataLayer caused router push
    this.router.openPolicy(policyUuid).commit();
  }

  public async adjustTransferState(ns?: NamespaceDescriptor | null) {
    const tstate = this.dataLayer.transferState;
    const uiSettings = this.store.uiSettings;

    let targetMode = tstate.dataMode;
    if (uiSettings.isTetragonOnlyEnabled || uiSettings.isTimescapeOnlyEnabled) {
      targetMode = DataMode.WatchingHistory;
    } else if (ns != null) {
      if (targetMode === DataMode.Disabled || tstate.isWatchingHistory) {
        targetMode = DataMode.WatchingHistory;
      } else {
        targetMode = DataMode.CiliumStreaming;
      }
    }

    await this.dataLayer.switchToDataMode(targetMode);
  }

  private checkAuthorization(notif: Notification) {
    if (!notif.authState?.invalid) return;

    Authorization.goToSignin();
  }

  private setupEventHandlers() {}

  private async handleNamespaceChanges(changes: NamespaceChange[]) {
    const currentNs = this.store.clusterNamespaces.currNamespace;
    if (currentNs == null) return;

    const currentNamespaceChange = changes.find(ch => {
      return ch.change === StateChange.Added && ch.namespace.namespace === currentNs;
    });

    if (currentNamespaceChange == null) return;

    // TODO: Smth is wrong with all this logic
    const nsDesc = this.store.clusterNamespaces.currDescriptor;
    if (nsDesc == null) {
      this.statusCenter.pushNamespaceIsNotObserved(currentNs);
      storage.deleteLastNamespace();
    } else {
      this.statusCenter.pushNamespaceIsObserved(nsDesc);
      await this.adjustTransferState(nsDesc);
    }
  }
}
