import React from 'react';

import { observer } from 'mobx-react';

import { Arrow } from '~/ui-layer/service-map/coordinates/types';

import { ServiceMapArrowBody } from './ServiceMapArrowBody';

export interface ArrowRendererProps {
  overlay?: React.MutableRefObject<Element | null>;
  arrows: Arrow[];
}

export const ServiceMapArrowsRenderer = observer(({ arrows, overlay }: ArrowRendererProps) => (
  <g className="arrows">
    <g className="bodies">
      {arrows.map(arrow => {
        return (
          <ServiceMapArrowBody
            key={arrow.id}
            arrow={arrow}
            overlay={overlay}
            isFlowStatsHidden={true}
          />
        );
      })}
    </g>
  </g>
));
