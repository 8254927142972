import React, { useCallback } from 'react';

import { Button } from '@blueprintjs/core';
import { DateRange, DateRangePicker, TimePrecision } from '@blueprintjs/datetime';
import { subDays } from 'date-fns';

import { TimeRange } from '~/domain/time';

import css from './TimeRangePicker.scss';
import { TimeRangeShortcuts } from './TimeRangeShortcuts';

export interface Props {
  innerRef?: React.RefObject<DateRangePicker>;
  timeRange?: TimeRange | null;
  onTimeRangeChange?: (timeRange: TimeRange) => void;
}

export const TimeRangePicker = (props: Props) => {
  const [applyDisabled, setApplyDisabled] = React.useState(true);

  const [rangeStart, setRangeStart] = React.useState<Date | null>(
    props.timeRange?.startDate ?? subDays(new Date(), 1),
  );

  const [rangeEnd, setRangeEnd] = React.useState<Date | null>(
    props.timeRange?.endDate ?? new Date(),
  );

  const onRangeChange = useCallback((range: DateRange) => {
    const [start, end] = range;
    setRangeStart(start);
    setRangeEnd(end);
    setApplyDisabled(false);
  }, []);

  const onFinish = useCallback(() => {
    if (rangeStart && rangeEnd && props.onTimeRangeChange) {
      props.onTimeRangeChange(TimeRange.new(rangeStart, rangeEnd));
    }
  }, [rangeStart, rangeEnd, props.onTimeRangeChange]);

  return (
    <div className={css.wrapper}>
      <div className={css.row}>
        <div className={css.shortcuts}>
          <TimeRangeShortcuts
            timeRange={props.timeRange}
            onTimeRangeChange={props.onTimeRangeChange}
          />
        </div>
        <DateRangePicker
          ref={props.innerRef}
          shortcuts={false}
          contiguousCalendarMonths={false}
          allowSingleDayRange
          defaultValue={[rangeStart, rangeEnd]}
          onChange={onRangeChange}
          timePrecision={TimePrecision.MINUTE}
          maxDate={new Date()}
        />
      </div>
      <div className={css.applyButton}>
        <Button disabled={applyDisabled} fill onClick={onFinish}>
          Apply
        </Button>
      </div>
    </div>
  );
};
