/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter add_pb_suffix,eslint_disable,ts_nocheck,generate_dependencies,long_type_number,use_proto_field_name
// @generated from protobuf file "tetragon/fgs.proto" (package "tetragon", syntax proto3)
// tslint:disable
// @ts-nocheck
//
// Copyright 2020 Authors of Hubble
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
//
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Timestamp } from "../google/protobuf/timestamp_pb";
import { Namespace } from "./tetragon_pb";
import { Duration } from "../google/protobuf/duration_pb";
import { UInt32Value } from "../google/protobuf/wrappers_pb";
import { Process } from "./tetragon_pb";
import { Pod } from "./tetragon_pb";
/**
 * @generated from protobuf message tetragon.HistogramBucket
 */
export interface HistogramBucket {
    /**
     * @generated from protobuf field: uint32 percentile = 1;
     */
    percentile: number;
    /**
     * @generated from protobuf field: uint32 size = 2;
     */
    size: number;
    /**
     * @generated from protobuf field: uint64 count = 3;
     */
    count: number;
}
/**
 * @generated from protobuf message tetragon.Histogram
 */
export interface Histogram {
    /**
     * @generated from protobuf field: repeated tetragon.HistogramBucket buckets = 1;
     */
    buckets: HistogramBucket[];
    /**
     * @generated from protobuf field: uint64 sum = 2;
     */
    sum: number;
}
/**
 * @generated from protobuf message tetragon.InterfaceStats
 */
export interface InterfaceStats {
    /**
     * @generated from protobuf field: string interface_name = 1;
     */
    interface_name: string;
    /**
     * @generated from protobuf field: uint32 interface_ifindex = 2;
     */
    interface_ifindex: number;
    /**
     * @generated from protobuf field: uint64 bytes_sent = 3;
     */
    bytes_sent: number;
    /**
     * @generated from protobuf field: uint64 bytes_received = 4;
     */
    bytes_received: number;
    /**
     * @generated from protobuf field: uint64 packets_sent = 5;
     */
    packets_sent: number;
    /**
     * @generated from protobuf field: uint64 packets_received = 6;
     */
    packets_received: number;
    /**
     * @generated from protobuf field: uint64 tx_errors = 7;
     */
    tx_errors: number;
    /**
     * @generated from protobuf field: uint64 rx_errors = 8;
     */
    rx_errors: number;
    /**
     * @generated from protobuf field: uint64 tx_drops = 9;
     */
    tx_drops: number;
    /**
     * @generated from protobuf field: uint64 rx_drops = 10;
     */
    rx_drops: number;
    /**
     * @generated from protobuf field: tetragon.Pod pod = 11;
     */
    pod?: Pod;
    /**
     * @generated from protobuf field: string netns = 12;
     */
    netns: string;
    /**
     * @generated from protobuf field: string container_name = 13;
     */
    container_name: string;
    /**
     * @generated from protobuf field: tetragon.Histogram qlen = 14;
     */
    qlen?: Histogram;
}
/**
 * @generated from protobuf message tetragon.SocketStats
 */
export interface SocketStats {
    /**
     * @generated from protobuf field: uint64 bytes_sent = 1;
     */
    bytes_sent: number;
    /**
     * @generated from protobuf field: uint64 bytes_received = 2;
     */
    bytes_received: number;
    /**
     * @generated from protobuf field: uint32 segs_in = 3;
     */
    segs_in: number;
    /**
     * @generated from protobuf field: uint32 segs_out = 4;
     */
    segs_out: number;
    /**
     * TCP specific:
     *
     * @generated from protobuf field: uint32 srtt = 5;
     */
    srtt: number;
    /**
     * @generated from protobuf field: uint64 retransmits_bytes = 6;
     */
    retransmits_bytes: number;
    /**
     * @generated from protobuf field: uint32 retransmits_segs = 7;
     */
    retransmits_segs: number;
    /**
     * @generated from protobuf field: uint32 to_zero_window = 8;
     */
    to_zero_window: number;
    /**
     * @generated from protobuf field: uint32 sk_drop = 9;
     */
    sk_drop: number;
    /**
     * UDP specific:
     *
     * @generated from protobuf field: uint64 bytes_consumed = 10;
     */
    bytes_consumed: number;
    /**
     * @generated from protobuf field: uint64 bytes_submitted = 11;
     */
    bytes_submitted: number;
    /**
     * @generated from protobuf field: uint32 segs_consumed = 12;
     */
    segs_consumed: number;
    /**
     * @generated from protobuf field: uint32 segs_submitted = 13;
     */
    segs_submitted: number;
    /**
     * @generated from protobuf field: uint32 skb_consume_misses = 14;
     */
    skb_consume_misses: number;
    /**
     * TCP RTT Histogram:
     *
     * @generated from protobuf field: tetragon.Histogram rtt = 15;
     */
    rtt?: Histogram;
    /**
     * TCP/UDP Latency Histogram:
     *
     * @generated from protobuf field: tetragon.Histogram latency = 16;
     */
    latency?: Histogram;
}
/**
 * @generated from protobuf message tetragon.ProcessConnect
 */
export interface ProcessConnect {
    /**
     * @generated from protobuf field: tetragon.Process process = 1;
     */
    process?: Process;
    /**
     * @generated from protobuf field: tetragon.Process parent = 2;
     */
    parent?: Process;
    /**
     * @generated from protobuf field: string source_ip = 3;
     */
    source_ip: string;
    /**
     * @generated from protobuf field: google.protobuf.UInt32Value source_port = 4;
     */
    source_port?: UInt32Value;
    /**
     * @generated from protobuf field: string destination_ip = 5;
     */
    destination_ip: string;
    /**
     * @generated from protobuf field: google.protobuf.UInt32Value destination_port = 6;
     */
    destination_port?: UInt32Value;
    /**
     * @generated from protobuf field: repeated string destination_names = 7;
     */
    destination_names: string[];
    /**
     * @generated from protobuf field: uint64 sock_cookie = 8;
     */
    sock_cookie: number;
    /**
     * @generated from protobuf field: tetragon.Pod destination_pod = 9;
     */
    destination_pod?: Pod;
    /**
     * @generated from protobuf field: tetragon.SocketProtocol protocol = 10;
     */
    protocol: SocketProtocol;
}
/**
 * @generated from protobuf message tetragon.ProcessClose
 */
export interface ProcessClose {
    /**
     * @generated from protobuf field: tetragon.Process process = 1;
     */
    process?: Process;
    /**
     * @generated from protobuf field: tetragon.Process parent = 2;
     */
    parent?: Process;
    /**
     * @generated from protobuf field: string source_ip = 3;
     */
    source_ip: string;
    /**
     * @generated from protobuf field: google.protobuf.UInt32Value source_port = 4;
     */
    source_port?: UInt32Value;
    /**
     * @generated from protobuf field: string destination_ip = 5;
     */
    destination_ip: string;
    /**
     * @generated from protobuf field: google.protobuf.UInt32Value destination_port = 6;
     */
    destination_port?: UInt32Value;
    /**
     * @generated from protobuf field: repeated string destination_names = 7;
     */
    destination_names: string[];
    /**
     * @generated from protobuf field: uint64 sock_cookie = 8;
     */
    sock_cookie: number;
    /**
     * @generated from protobuf field: tetragon.SocketStats stats = 9;
     */
    stats?: SocketStats;
    /**
     * @generated from protobuf field: tetragon.Pod destination_pod = 10;
     */
    destination_pod?: Pod;
    /**
     * @generated from protobuf field: tetragon.SocketProtocol protocol = 11;
     */
    protocol: SocketProtocol;
    /**
     * @generated from protobuf field: string socket_type = 12;
     */
    socket_type: string;
    /**
     * @generated from protobuf field: google.protobuf.Duration duration = 13;
     */
    duration?: Duration;
}
/**
 * @generated from protobuf message tetragon.ProcessListen
 */
export interface ProcessListen {
    /**
     * @generated from protobuf field: tetragon.Process process = 1;
     */
    process?: Process;
    /**
     * @generated from protobuf field: tetragon.Process parent = 2;
     */
    parent?: Process;
    /**
     * @generated from protobuf field: string ip = 3;
     */
    ip: string;
    /**
     * @generated from protobuf field: google.protobuf.UInt32Value port = 4;
     */
    port?: UInt32Value;
    /**
     * @generated from protobuf field: uint64 sock_cookie = 5;
     */
    sock_cookie: number;
    /**
     * @generated from protobuf field: tetragon.SocketProtocol protocol = 6;
     */
    protocol: SocketProtocol;
}
/**
 * @generated from protobuf message tetragon.ProcessAccept
 */
export interface ProcessAccept {
    /**
     * @generated from protobuf field: tetragon.Process process = 1;
     */
    process?: Process;
    /**
     * @generated from protobuf field: tetragon.Process parent = 2;
     */
    parent?: Process;
    /**
     * @generated from protobuf field: string source_ip = 3;
     */
    source_ip: string;
    /**
     * @generated from protobuf field: google.protobuf.UInt32Value source_port = 4;
     */
    source_port?: UInt32Value;
    /**
     * @generated from protobuf field: string destination_ip = 5;
     */
    destination_ip: string;
    /**
     * @generated from protobuf field: google.protobuf.UInt32Value destination_port = 6;
     */
    destination_port?: UInt32Value;
    /**
     * @generated from protobuf field: repeated string destination_names = 7;
     */
    destination_names: string[];
    /**
     * @generated from protobuf field: uint64 sock_cookie = 8;
     */
    sock_cookie: number;
    /**
     * @generated from protobuf field: tetragon.Pod destination_pod = 9;
     */
    destination_pod?: Pod;
    /**
     * @generated from protobuf field: tetragon.SocketProtocol protocol = 10;
     */
    protocol: SocketProtocol;
}
/**
 * @generated from protobuf message tetragon.ProcessRawsockCreate
 */
export interface ProcessRawsockCreate {
    /**
     * @generated from protobuf field: tetragon.Process process = 1;
     */
    process?: Process;
    /**
     * @generated from protobuf field: tetragon.Process parent = 2;
     */
    parent?: Process;
    /**
     * @generated from protobuf field: uint64 sock_cookie = 3;
     */
    sock_cookie: number;
}
/**
 * @generated from protobuf message tetragon.ProcessRawsockClose
 */
export interface ProcessRawsockClose {
    /**
     * @generated from protobuf field: tetragon.Process process = 1;
     */
    process?: Process;
    /**
     * @generated from protobuf field: tetragon.Process parent = 2;
     */
    parent?: Process;
    /**
     * @generated from protobuf field: uint64 sock_cookie = 3;
     */
    sock_cookie: number;
    /**
     * @generated from protobuf field: google.protobuf.Duration duration = 4;
     */
    duration?: Duration;
}
/**
 * @generated from protobuf message tetragon.ProcessIcmp
 */
export interface ProcessIcmp {
    /**
     * @generated from protobuf field: tetragon.Process process = 1;
     */
    process?: Process;
    /**
     * @generated from protobuf field: tetragon.Process parent = 2;
     */
    parent?: Process;
    /**
     * @generated from protobuf field: string source_ip = 3;
     */
    source_ip: string;
    /**
     * @generated from protobuf field: string destination_ip = 4;
     */
    destination_ip: string;
    /**
     * @generated from protobuf field: repeated string destination_names = 5;
     */
    destination_names: string[];
    /**
     * @generated from protobuf field: uint64 sock_cookie = 6;
     */
    sock_cookie: number;
    /**
     * @generated from protobuf field: tetragon.Pod destination_pod = 7;
     */
    destination_pod?: Pod;
    /**
     * @generated from protobuf field: tetragon.SocketProtocol protocol = 8;
     */
    protocol: SocketProtocol;
    /**
     * @generated from protobuf field: string icmp_type = 9;
     */
    icmp_type: string;
    /**
     * @generated from protobuf field: string icmp_code = 10;
     */
    icmp_code: string;
    /**
     * @generated from protobuf field: uint32 icmp_type_value = 11;
     */
    icmp_type_value: number;
    /**
     * @generated from protobuf field: uint32 icmp_code_value = 12;
     */
    icmp_code_value: number;
    /**
     * @generated from protobuf field: uint32 identifier = 13;
     */
    identifier: number;
    /**
     * @generated from protobuf field: uint32 sequence_number = 14;
     */
    sequence_number: number;
    /**
     * @generated from protobuf field: uint32 icmp_data_len = 15;
     */
    icmp_data_len: number;
    /**
     * @generated from protobuf field: string direction = 16;
     */
    direction: string;
    /**
     * @generated from protobuf field: tetragon.SocketProtocol icmp_ip_protocol = 17;
     */
    icmp_ip_protocol: SocketProtocol;
    /**
     * @generated from protobuf field: uint32 icmp_ip_port = 18;
     */
    icmp_ip_port: number;
    /**
     * @generated from protobuf field: uint32 icmp_ip_ttl = 19;
     */
    icmp_ip_ttl: number;
    /**
     * @generated from protobuf field: uint32 icmp_ip_pointer = 20;
     */
    icmp_ip_pointer: number;
    /**
     * @generated from protobuf field: string icmp_ip_gateway = 21;
     */
    icmp_ip_gateway: string;
}
/**
 * @generated from protobuf message tetragon.ProcessIpError
 */
export interface ProcessIpError {
    /**
     * @generated from protobuf field: tetragon.Process process = 1;
     */
    process?: Process;
    /**
     * @generated from protobuf field: tetragon.Process parent = 2;
     */
    parent?: Process;
    /**
     * @generated from protobuf field: string source_ip = 3;
     */
    source_ip: string;
    /**
     * @generated from protobuf field: string destination_ip = 4;
     */
    destination_ip: string;
    /**
     * @generated from protobuf field: string version = 5;
     */
    version: string;
    /**
     * @generated from protobuf field: uint64 sock_cookie = 6;
     */
    sock_cookie: number;
    /**
     * @generated from protobuf field: tetragon.Pod destination_pod = 7;
     */
    destination_pod?: Pod;
    /**
     * @generated from protobuf field: string details = 8;
     */
    details: string;
    /**
     * @generated from protobuf field: string send = 9;
     */
    send: string;
    /**
     * @generated from protobuf field: uint64 version_byte = 10;
     */
    version_byte: number;
    /**
     * @generated from protobuf field: uint64 data = 11;
     */
    data: number;
}
/**
 * @generated from protobuf message tetragon.FileSystem
 */
export interface FileSystem {
    /**
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * @generated from protobuf field: string dev = 2;
     */
    dev: string;
    /**
     * @generated from protobuf field: string id = 3;
     */
    id: string;
    /**
     * @generated from protobuf field: string uuid = 4;
     */
    uuid: string;
}
/**
 * @generated from protobuf message tetragon.Inode
 */
export interface Inode {
    /**
     * @generated from protobuf field: uint64 number = 1;
     */
    number: number;
    /**
     * @generated from protobuf field: tetragon.FileSystem fs = 2;
     */
    fs?: FileSystem;
}
/**
 * @generated from protobuf message tetragon.FileLocation
 */
export interface FileLocation {
    /**
     * @generated from protobuf field: tetragon.FileScope type = 1;
     */
    type: FileScope;
    /**
     * @generated from protobuf field: string container_id = 2;
     */
    container_id: string; // only valid if type == CONTAINER_FILE_{LOCAL, REMOTE}
    /**
     * @generated from protobuf field: tetragon.Pod pod = 3;
     */
    pod?: Pod; // only valid if type == CONTAINER_FILE_REMOTE
}
/**
 * @generated from protobuf message tetragon.FileDetails
 */
export interface FileDetails {
    /**
     * @generated from protobuf oneof: filename
     */
    filename: {
        oneofKind: "str";
        /**
         * @generated from protobuf field: string str = 1;
         */
        str: string;
    } | {
        oneofKind: undefined;
    };
    /**
     * @generated from protobuf field: tetragon.Inode inode = 2;
     */
    inode?: Inode;
    /**
     * @generated from protobuf field: tetragon.Inode parent_inode = 3;
     */
    parent_inode?: Inode;
    /**
     * @generated from protobuf field: tetragon.FileLocation location = 4;
     */
    location?: FileLocation;
}
/**
 * @generated from protobuf message tetragon.FileIO
 */
export interface FileIO {
    /**
     * @generated from protobuf field: string offset = 1;
     */
    offset: string;
    /**
     * @generated from protobuf field: string size = 2;
     */
    size: string;
}
/**
 * @generated from protobuf message tetragon.FileDigest
 */
export interface FileDigest {
    /**
     * @generated from protobuf field: tetragon.DigestAlgo algo = 1;
     */
    algo: DigestAlgo;
    /**
     * @generated from protobuf field: string hash = 2;
     */
    hash: string;
    /**
     * @generated from protobuf field: int64 error = 3;
     */
    error: number;
}
/**
 * @generated from protobuf message tetragon.GenericFileArg
 */
export interface GenericFileArg {
    /**
     * @generated from protobuf field: tetragon.FileDetails file = 1;
     */
    file?: FileDetails;
    /**
     * @deprecated
     * @generated from protobuf field: tetragon.FileIO io = 2 [deprecated = true];
     */
    io?: FileIO;
    /**
     * @generated from protobuf field: tetragon.Namespace mnt_ns = 3;
     */
    mnt_ns?: Namespace;
    /**
     * @generated from protobuf field: tetragon.FileDigest digest = 4;
     */
    digest?: FileDigest;
}
/**
 * @generated from protobuf message tetragon.RenameFileArg
 */
export interface RenameFileArg {
    /**
     * @generated from protobuf field: tetragon.FileDetails src = 1;
     */
    src?: FileDetails;
    /**
     * @generated from protobuf field: tetragon.FileDetails dst = 2;
     */
    dst?: FileDetails;
    /**
     * @generated from protobuf field: tetragon.Namespace mnt_ns = 3;
     */
    mnt_ns?: Namespace;
    /**
     * @generated from protobuf field: repeated string flags = 4;
     */
    flags: string[];
}
/**
 * @generated from protobuf message tetragon.ReadDirArg
 */
export interface ReadDirArg {
    /**
     * @generated from protobuf field: tetragon.FileDetails file = 1;
     */
    file?: FileDetails;
    /**
     * @generated from protobuf field: tetragon.Namespace mnt_ns = 2;
     */
    mnt_ns?: Namespace;
}
/**
 * @generated from protobuf message tetragon.AttrChange
 */
export interface AttrChange {
    /**
     * @generated from protobuf field: string new = 1;
     */
    new: string;
    /**
     * @generated from protobuf field: string old = 2;
     */
    old: string;
}
/**
 * @generated from protobuf message tetragon.FileAttr
 */
export interface FileAttr {
    /**
     * @generated from protobuf field: tetragon.AttrChange permissions = 1;
     */
    permissions?: AttrChange;
    /**
     * @generated from protobuf field: tetragon.AttrChange uid = 2;
     */
    uid?: AttrChange;
    /**
     * @generated from protobuf field: tetragon.AttrChange gid = 3;
     */
    gid?: AttrChange;
}
/**
 * @generated from protobuf message tetragon.AttrArg
 */
export interface AttrArg {
    /**
     * @generated from protobuf field: tetragon.FileDetails file = 1;
     */
    file?: FileDetails;
    /**
     * @generated from protobuf field: tetragon.FileAttr attr = 2;
     */
    attr?: FileAttr;
    /**
     * @generated from protobuf field: tetragon.Namespace mnt_ns = 3;
     */
    mnt_ns?: Namespace;
}
/**
 * @generated from protobuf message tetragon.FileArgument
 */
export interface FileArgument {
    /**
     * @generated from protobuf oneof: arg
     */
    arg: {
        oneofKind: "generic_arg";
        /**
         * @generated from protobuf field: tetragon.GenericFileArg generic_arg = 1;
         */
        generic_arg: GenericFileArg;
    } | {
        oneofKind: "rename_arg";
        /**
         * @generated from protobuf field: tetragon.RenameFileArg rename_arg = 2;
         */
        rename_arg: RenameFileArg;
    } | {
        oneofKind: "readdir_arg";
        /**
         * @generated from protobuf field: tetragon.ReadDirArg readdir_arg = 3;
         */
        readdir_arg: ReadDirArg;
    } | {
        oneofKind: "attr_arg";
        /**
         * @generated from protobuf field: tetragon.AttrArg attr_arg = 4;
         */
        attr_arg: AttrArg;
    } | {
        oneofKind: undefined;
    };
}
/**
 * @generated from protobuf message tetragon.ProcessFile
 */
export interface ProcessFile {
    /**
     * @generated from protobuf field: tetragon.Process process = 1;
     */
    process?: Process;
    /**
     * @generated from protobuf field: tetragon.Process parent = 2;
     */
    parent?: Process;
    /**
     * @generated from protobuf field: tetragon.FileAction action = 3;
     */
    action: FileAction;
    /**
     * @generated from protobuf field: tetragon.FileArgument args = 4;
     */
    args?: FileArgument;
    /**
     * @generated from protobuf field: string permissions = 5;
     */
    permissions: string;
    /**
     * @generated from protobuf field: string uid = 6;
     */
    uid: string;
    /**
     * @generated from protobuf field: string gid = 7;
     */
    gid: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp time = 8;
     */
    time?: Timestamp;
    /**
     * @generated from protobuf field: string hook = 9;
     */
    hook: string;
    /**
     * @generated from protobuf field: repeated tetragon.FileOperation operation = 10;
     */
    operation: FileOperation[];
}
/**
 * ProcessFileExec events provide (additional to ProcessExec) information about files being executed.
 * They are configured in the "exec:" section of the TracingPolicy.
 *
 * @generated from protobuf message tetragon.ProcessFileExec
 */
export interface ProcessFileExec {
    /**
     * @generated from protobuf field: tetragon.Process process = 1;
     */
    process?: Process;
    /**
     * @generated from protobuf field: tetragon.Process parent = 2;
     */
    parent?: Process;
    /**
     * @generated from protobuf field: tetragon.FileDetails file = 3;
     */
    file?: FileDetails;
    /**
     * @generated from protobuf field: tetragon.FileDigest digest = 4;
     */
    digest?: FileDigest;
    /**
     * @generated from protobuf field: repeated tetragon.FileOperation operations = 5;
     */
    operations: FileOperation[];
}
/**
 * @generated from protobuf message tetragon.SockInfo
 */
export interface SockInfo {
    /**
     * @generated from protobuf field: string source_ip = 1;
     */
    source_ip: string;
    /**
     * @generated from protobuf field: google.protobuf.UInt32Value source_port = 2;
     */
    source_port?: UInt32Value;
    /**
     * @generated from protobuf field: string destination_ip = 3;
     */
    destination_ip: string;
    /**
     * @generated from protobuf field: google.protobuf.UInt32Value destination_port = 4;
     */
    destination_port?: UInt32Value;
    /**
     * @generated from protobuf field: uint64 sock_cookie = 5;
     */
    sock_cookie: number;
    /**
     * @generated from protobuf field: tetragon.SocketProtocol protocol = 6;
     */
    protocol: SocketProtocol;
    /**
     * @generated from protobuf field: repeated string destination_names = 7;
     */
    destination_names: string[];
    /**
     * @generated from protobuf field: tetragon.Pod destination_pod = 8;
     */
    destination_pod?: Pod;
}
/**
 * @generated from protobuf message tetragon.ProcessSockStats
 */
export interface ProcessSockStats {
    /**
     * @generated from protobuf field: tetragon.Process process = 1;
     */
    process?: Process;
    /**
     * @generated from protobuf field: tetragon.Process parent = 2;
     */
    parent?: Process;
    /**
     * @generated from protobuf field: tetragon.SockInfo socket = 3;
     */
    socket?: SockInfo;
    /**
     * @generated from protobuf field: tetragon.SocketStats stats = 4;
     */
    stats?: SocketStats;
}
/**
 * @generated from protobuf message tetragon.Tls
 */
export interface Tls {
    /**
     * @generated from protobuf field: tetragon.Process process = 1;
     */
    process?: Process;
    /**
     * @generated from protobuf field: string source_ip = 2;
     */
    source_ip: string;
    /**
     * @generated from protobuf field: google.protobuf.UInt32Value source_port = 3;
     */
    source_port?: UInt32Value;
    /**
     * @generated from protobuf field: string destination_ip = 4;
     */
    destination_ip: string;
    /**
     * @generated from protobuf field: google.protobuf.UInt32Value destination_port = 5;
     */
    destination_port?: UInt32Value;
    /**
     * @generated from protobuf field: string negotiated_version = 6;
     */
    negotiated_version: string;
    /**
     * @generated from protobuf field: string supported_versions = 7;
     */
    supported_versions: string;
    /**
     * @generated from protobuf field: string sni_type = 8;
     */
    sni_type: string;
    /**
     * @generated from protobuf field: string sni_name = 9;
     */
    sni_name: string;
    /**
     * @generated from protobuf field: string cipher = 10;
     */
    cipher: string;
    /**
     * @generated from protobuf field: string client_flags = 11;
     */
    client_flags: string;
    /**
     * @generated from protobuf field: string server_flags = 12;
     */
    server_flags: string;
    /**
     * @generated from protobuf field: string client_version = 13;
     */
    client_version: string;
    /**
     * @generated from protobuf field: string server_version = 14;
     */
    server_version: string;
    /**
     * @generated from protobuf field: string client_alert = 15;
     */
    client_alert: string;
    /**
     * @generated from protobuf field: string server_alert = 16;
     */
    server_alert: string;
    /**
     * @generated from protobuf field: string client_session = 17;
     */
    client_session: string;
    /**
     * @generated from protobuf field: string server_session = 18;
     */
    server_session: string;
    /**
     * @generated from protobuf field: repeated string certificates = 19;
     */
    certificates: string[];
    /**
     * @generated from protobuf field: tetragon.TlsCertificateError certificate_error = 20;
     */
    certificate_error: TlsCertificateError;
    /**
     * @deprecated
     * @generated from protobuf field: uint32 parser_state_next = 21 [deprecated = true];
     */
    parser_state_next: number;
    /**
     * @deprecated
     * @generated from protobuf field: uint32 parser_state_needed = 22 [deprecated = true];
     */
    parser_state_needed: number;
    /**
     * @deprecated
     * @generated from protobuf field: uint32 parser_state_csize = 23 [deprecated = true];
     */
    parser_state_csize: number;
    /**
     * @deprecated
     * @generated from protobuf field: uint32 parser_state_skblen = 24 [deprecated = true];
     */
    parser_state_skblen: number;
    /**
     * @generated from protobuf field: string parser_internal_state = 25;
     */
    parser_internal_state: string;
    /**
     * @generated from protobuf field: tetragon.Process parent = 26;
     */
    parent?: Process;
}
/**
 *
 * HTTP PARSER
 *
 * @generated from protobuf message tetragon.HttpHeader
 */
export interface HttpHeader {
    /**
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * @generated from protobuf field: string value = 2;
     */
    value: string;
}
/**
 * @generated from protobuf message tetragon.HttpRequest
 */
export interface HttpRequest {
    /**
     * @generated from protobuf field: google.protobuf.Timestamp timestamp = 1;
     */
    timestamp?: Timestamp;
    /**
     * @generated from protobuf field: string method = 2;
     */
    method: string;
    /**
     * @generated from protobuf field: string uri = 3;
     */
    uri: string;
    /**
     * @generated from protobuf field: string version = 4;
     */
    version: string;
    /**
     * @generated from protobuf field: string host = 5;
     */
    host: string;
    /**
     * @generated from protobuf field: string agent = 6;
     */
    agent: string;
    /**
     * @generated from protobuf field: google.protobuf.UInt32Value content_length = 7;
     */
    content_length?: UInt32Value;
    /**
     * @generated from protobuf field: repeated tetragon.HttpHeader headers = 8;
     */
    headers: HttpHeader[];
    /**
     * @generated from protobuf field: string flags = 9;
     */
    flags: string;
    /**
     * @generated from protobuf field: string transfer_encoding = 10;
     */
    transfer_encoding: string;
}
/**
 * @generated from protobuf message tetragon.HttpResponse
 */
export interface HttpResponse {
    /**
     * @generated from protobuf field: google.protobuf.Timestamp timestamp = 1;
     */
    timestamp?: Timestamp;
    /**
     * @generated from protobuf field: string version = 2;
     */
    version: string;
    /**
     * @generated from protobuf field: uint32 code = 3;
     */
    code: number;
    /**
     * @generated from protobuf field: string reason = 4;
     */
    reason: string;
    /**
     * @generated from protobuf field: google.protobuf.UInt32Value content_length = 5;
     */
    content_length?: UInt32Value;
    /**
     * @generated from protobuf field: repeated tetragon.HttpHeader headers = 6;
     */
    headers: HttpHeader[];
    /**
     * @generated from protobuf field: string flags = 7;
     */
    flags: string;
    /**
     * @generated from protobuf field: string transfer_encoding = 8;
     */
    transfer_encoding: string;
}
/**
 * @generated from protobuf message tetragon.HttpInfo
 */
export interface HttpInfo {
    /**
     * @generated from protobuf field: tetragon.HttpRequest request = 1;
     */
    request?: HttpRequest;
    /**
     * @generated from protobuf field: tetragon.HttpResponse response = 2;
     */
    response?: HttpResponse;
    /**
     * @generated from protobuf field: google.protobuf.Duration latency = 3;
     */
    latency?: Duration;
}
/**
 * @generated from protobuf message tetragon.ProcessHttp
 */
export interface ProcessHttp {
    /**
     * @generated from protobuf field: tetragon.Process process = 1;
     */
    process?: Process;
    /**
     * @generated from protobuf field: tetragon.SockInfo socket = 2;
     */
    socket?: SockInfo;
    /**
     * @generated from protobuf field: tetragon.HttpInfo http = 3;
     */
    http?: HttpInfo;
    /**
     * @deprecated
     * @generated from protobuf field: repeated string destination_names = 4 [deprecated = true];
     */
    destination_names: string[]; // deprecated in favor of socket.destination_names.
    /**
     * @deprecated
     * @generated from protobuf field: tetragon.Pod destination_pod = 5 [deprecated = true];
     */
    destination_pod?: Pod; // deprecated in favor of socket.destination_pod
    /**
     * @generated from protobuf field: tetragon.Process parent = 6;
     */
    parent?: Process;
}
/**
 * @generated from protobuf message tetragon.ProcessNetworkBurst
 */
export interface ProcessNetworkBurst {
    /**
     * @generated from protobuf field: tetragon.Process process = 1;
     */
    process?: Process;
    /**
     * @generated from protobuf field: tetragon.Process parent = 2;
     */
    parent?: Process;
    /**
     * @generated from protobuf field: string protocol = 3;
     */
    protocol: string;
    /**
     * @generated from protobuf field: string direction = 4;
     */
    direction: string;
    /**
     * @generated from protobuf field: string burst_state = 5;
     */
    burst_state: string;
    /**
     * @generated from protobuf field: uint64 window_size = 6;
     */
    window_size: number;
    /**
     * @generated from protobuf field: uint64 hist_avg = 7;
     */
    hist_avg: number;
    /**
     * @generated from protobuf field: uint64 hist_trigger = 8;
     */
    hist_trigger: number;
    /**
     * @generated from protobuf field: uint64 window_avg = 9;
     */
    window_avg: number;
}
/**
 * @generated from protobuf message tetragon.ProcessNetworkWatermark
 */
export interface ProcessNetworkWatermark {
    /**
     * @generated from protobuf field: tetragon.Process process = 1;
     */
    process?: Process;
    /**
     * @generated from protobuf field: tetragon.Process parent = 2;
     */
    parent?: Process;
    /**
     * @generated from protobuf field: string protocol = 3;
     */
    protocol: string;
    /**
     * @generated from protobuf field: string direction = 4;
     */
    direction: string;
    /**
     * @generated from protobuf field: string watermarks_state = 5;
     */
    watermarks_state: string;
    /**
     * @generated from protobuf field: string watermarks_type = 6;
     */
    watermarks_type: string;
    /**
     * @generated from protobuf field: uint64 window_size = 7;
     */
    window_size: number;
    /**
     * @generated from protobuf field: uint64 hist_avg = 8;
     */
    hist_avg: number;
    /**
     * @generated from protobuf field: uint64 hist_burst_trigger = 9;
     */
    hist_burst_trigger: number;
    /**
     * @generated from protobuf field: uint64 hist_dip_trigger = 10;
     */
    hist_dip_trigger: number;
    /**
     * @generated from protobuf field: uint64 window_avg = 11;
     */
    window_avg: number;
}
/**
 * @generated from protobuf message tetragon.ProcessUdpSeqCheckError
 */
export interface ProcessUdpSeqCheckError {
    /**
     * @generated from protobuf field: tetragon.Process process = 1;
     */
    process?: Process;
    /**
     * @generated from protobuf field: tetragon.Process parent = 2;
     */
    parent?: Process;
    /**
     * @generated from protobuf field: tetragon.SockInfo socket = 3;
     */
    socket?: SockInfo;
    /**
     * @generated from protobuf field: uint64 application_id = 4;
     */
    application_id: number;
    /**
     * @generated from protobuf field: uint64 app_specific_id = 5;
     */
    app_specific_id: number;
    /**
     * @generated from protobuf field: uint64 seq_num_expected = 6;
     */
    seq_num_expected: number;
    /**
     * @generated from protobuf field: uint64 seq_num_received = 7;
     */
    seq_num_received: number;
}
/**
 * @generated from protobuf enum tetragon.SocketProtocol
 */
export enum SocketProtocol {
    /**
     * @generated from protobuf enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,
    /**
     * @generated from protobuf enum value: ICMP = 1;
     */
    ICMP = 1,
    /**
     * @generated from protobuf enum value: TCP = 6;
     */
    TCP = 6,
    /**
     * @generated from protobuf enum value: UDP = 17;
     */
    UDP = 17,
    /**
     * @generated from protobuf enum value: ICMPV6 = 58;
     */
    ICMPV6 = 58
}
/**
 * @generated from protobuf enum tetragon.FileAction
 */
export enum FileAction {
    /**
     * @generated from protobuf enum value: FILE_INVALID = 0;
     */
    FILE_INVALID = 0,
    /**
     * @generated from protobuf enum value: FILE_WRITE = 1;
     */
    FILE_WRITE = 1,
    /**
     * @generated from protobuf enum value: FILE_READ = 2;
     */
    FILE_READ = 2,
    /**
     * @generated from protobuf enum value: FILE_DELETE = 3;
     */
    FILE_DELETE = 3,
    /**
     * @generated from protobuf enum value: FILE_CREATE = 4;
     */
    FILE_CREATE = 4,
    /**
     * @generated from protobuf enum value: FILE_RMDIR = 5;
     */
    FILE_RMDIR = 5,
    /**
     * @generated from protobuf enum value: FILE_MKDIR = 6;
     */
    FILE_MKDIR = 6,
    /**
     * @generated from protobuf enum value: FILE_RENAME = 7;
     */
    FILE_RENAME = 7,
    /**
     * @generated from protobuf enum value: FILE_READDIR = 8;
     */
    FILE_READDIR = 8,
    /**
     * @generated from protobuf enum value: FILE_CHATTR = 9;
     */
    FILE_CHATTR = 9,
    /**
     * @generated from protobuf enum value: FILE_EXEC = 10;
     */
    FILE_EXEC = 10
}
/**
 * @generated from protobuf enum tetragon.FileScope
 */
export enum FileScope {
    /**
     * @generated from protobuf enum value: UNKNOWN_FILE = 0;
     */
    UNKNOWN_FILE = 0,
    /**
     * HOST_FILE means that the file is monitored as a host file, and the access happened from a container or the host
     *
     * @generated from protobuf enum value: HOST_FILE = 1;
     */
    HOST_FILE = 1,
    /**
     * CONTAINER_FILE_LOCAL means that the file is monitored as a container file, and the access happened from the same container
     *
     * @generated from protobuf enum value: CONTAINER_FILE_LOCAL = 2;
     */
    CONTAINER_FILE_LOCAL = 2,
    /**
     * CONTAINER_FILE_REMOTE means that the file is monitored as a container file, and the access happened from a different container or the host
     *
     * @generated from protobuf enum value: CONTAINER_FILE_REMOTE = 3;
     */
    CONTAINER_FILE_REMOTE = 3
}
/**
 * from https://elixir.bootlin.com/linux/v6.2.16/source/include/uapi/linux/hash_info.h
 *
 * @generated from protobuf enum tetragon.DigestAlgo
 */
export enum DigestAlgo {
    /**
     * @generated from protobuf enum value: HASH_ALGO_MD4 = 0;
     */
    HASH_ALGO_MD4 = 0,
    /**
     * @generated from protobuf enum value: HASH_ALGO_MD5 = 1;
     */
    HASH_ALGO_MD5 = 1,
    /**
     * @generated from protobuf enum value: HASH_ALGO_SHA1 = 2;
     */
    HASH_ALGO_SHA1 = 2,
    /**
     * @generated from protobuf enum value: HASH_ALGO_RIPE_MD_160 = 3;
     */
    HASH_ALGO_RIPE_MD_160 = 3,
    /**
     * @generated from protobuf enum value: HASH_ALGO_SHA256 = 4;
     */
    HASH_ALGO_SHA256 = 4,
    /**
     * @generated from protobuf enum value: HASH_ALGO_SHA384 = 5;
     */
    HASH_ALGO_SHA384 = 5,
    /**
     * @generated from protobuf enum value: HASH_ALGO_SHA512 = 6;
     */
    HASH_ALGO_SHA512 = 6,
    /**
     * @generated from protobuf enum value: HASH_ALGO_SHA224 = 7;
     */
    HASH_ALGO_SHA224 = 7,
    /**
     * @generated from protobuf enum value: HASH_ALGO_RIPE_MD_128 = 8;
     */
    HASH_ALGO_RIPE_MD_128 = 8,
    /**
     * @generated from protobuf enum value: HASH_ALGO_RIPE_MD_256 = 9;
     */
    HASH_ALGO_RIPE_MD_256 = 9,
    /**
     * @generated from protobuf enum value: HASH_ALGO_RIPE_MD_320 = 10;
     */
    HASH_ALGO_RIPE_MD_320 = 10,
    /**
     * @generated from protobuf enum value: HASH_ALGO_WP_256 = 11;
     */
    HASH_ALGO_WP_256 = 11,
    /**
     * @generated from protobuf enum value: HASH_ALGO_WP_384 = 12;
     */
    HASH_ALGO_WP_384 = 12,
    /**
     * @generated from protobuf enum value: HASH_ALGO_WP_512 = 13;
     */
    HASH_ALGO_WP_512 = 13,
    /**
     * @generated from protobuf enum value: HASH_ALGO_TGR_128 = 14;
     */
    HASH_ALGO_TGR_128 = 14,
    /**
     * @generated from protobuf enum value: HASH_ALGO_TGR_160 = 15;
     */
    HASH_ALGO_TGR_160 = 15,
    /**
     * @generated from protobuf enum value: HASH_ALGO_TGR_192 = 16;
     */
    HASH_ALGO_TGR_192 = 16,
    /**
     * @generated from protobuf enum value: HASH_ALGO_SM3_256 = 17;
     */
    HASH_ALGO_SM3_256 = 17,
    /**
     * @generated from protobuf enum value: HASH_ALGO_STREEBOG_256 = 18;
     */
    HASH_ALGO_STREEBOG_256 = 18,
    /**
     * @generated from protobuf enum value: HASH_ALGO_STREEBOG_512 = 19;
     */
    HASH_ALGO_STREEBOG_512 = 19,
    /**
     * @generated from protobuf enum value: HASH_ALGO__LAST = 20;
     */
    HASH_ALGO__LAST = 20
}
/**
 * @generated from protobuf enum tetragon.FileOperation
 */
export enum FileOperation {
    /**
     * @generated from protobuf enum value: FILE_OP_UNKNOWN = 0;
     */
    FILE_OP_UNKNOWN = 0,
    /**
     * @generated from protobuf enum value: FILE_OP_POST = 1;
     */
    FILE_OP_POST = 1,
    /**
     * @generated from protobuf enum value: FILE_OP_BLOCK = 2;
     */
    FILE_OP_BLOCK = 2
}
/**
 * @generated from protobuf enum tetragon.TlsCertificateError
 */
export enum TlsCertificateError {
    /**
     * @generated from protobuf enum value: TLS_CERT_ERROR_UNDEF = 0;
     */
    TLS_CERT_ERROR_UNDEF = 0,
    /**
     * @generated from protobuf enum value: TLS_CERT_ERROR_UNKNOWN = 1;
     */
    TLS_CERT_ERROR_UNKNOWN = 1,
    /**
     * @deprecated
     * @generated from protobuf enum value: TLS_CERT_ERROR_TOO_LARGE = 2 [deprecated = true];
     */
    TLS_CERT_ERROR_TOO_LARGE = 2,
    /**
     * @deprecated
     * @generated from protobuf enum value: TLS_CERT_ERROR_GET_DATA_HDR = 3 [deprecated = true];
     */
    TLS_CERT_ERROR_GET_DATA_HDR = 3,
    /**
     * @deprecated
     * @generated from protobuf enum value: TLS_CERT_ERROR_NO_BUFFER = 4 [deprecated = true];
     */
    TLS_CERT_ERROR_NO_BUFFER = 4,
    /**
     * @deprecated
     * @generated from protobuf enum value: TLS_CERT_ERROR_COPY = 5 [deprecated = true];
     */
    TLS_CERT_ERROR_COPY = 5,
    /**
     * @generated from protobuf enum value: TLS_CERT_ERROR_LENGTH_READ = 6;
     */
    TLS_CERT_ERROR_LENGTH_READ = 6,
    /**
     * @generated from protobuf enum value: TLS_CERT_ERROR_CERT_READ = 7;
     */
    TLS_CERT_ERROR_CERT_READ = 7,
    /**
     * @generated from protobuf enum value: TLS_CERT_ERROR_CERT_PARTIAL = 8;
     */
    TLS_CERT_ERROR_CERT_PARTIAL = 8,
    /**
     * @generated from protobuf enum value: TLS_CERT_ERROR_PARSE_X509 = 9;
     */
    TLS_CERT_ERROR_PARSE_X509 = 9,
    /**
     * @deprecated
     * @generated from protobuf enum value: TLS_CERT_ERROR_MISSING_CODE = 10 [deprecated = true];
     */
    TLS_CERT_ERROR_MISSING_CODE = 10,
    /**
     * @deprecated
     * @generated from protobuf enum value: TLS_CERT_ERROR_GET_DATA_CERT = 11 [deprecated = true];
     */
    TLS_CERT_ERROR_GET_DATA_CERT = 11,
    /**
     * @deprecated
     * @generated from protobuf enum value: TLS_CERT_ERROR_GET_DATA_MORECERT = 12 [deprecated = true];
     */
    TLS_CERT_ERROR_GET_DATA_MORECERT = 12,
    /**
     * @deprecated
     * @generated from protobuf enum value: TLS_CERT_ERROR_COPY_CERT = 13 [deprecated = true];
     */
    TLS_CERT_ERROR_COPY_CERT = 13,
    /**
     * @deprecated
     * @generated from protobuf enum value: TLS_CERT_ERROR_COPY_MORE_CERT = 14 [deprecated = true];
     */
    TLS_CERT_ERROR_COPY_MORE_CERT = 14,
    /**
     * @generated from protobuf enum value: TLS_CERT_ERROR_BAD_HEADER = 15;
     */
    TLS_CERT_ERROR_BAD_HEADER = 15,
    /**
     * @generated from protobuf enum value: TLS_CERT_ERROR_MISSING_ERROR = 16;
     */
    TLS_CERT_ERROR_MISSING_ERROR = 16,
    /**
     * @generated from protobuf enum value: TLS_CERT_ERROR_SPURIOUS_CERTS = 17;
     */
    TLS_CERT_ERROR_SPURIOUS_CERTS = 17
}
// @generated message type with reflection information, may provide speed optimized methods
class HistogramBucket$Type extends MessageType<HistogramBucket> {
    constructor() {
        super("tetragon.HistogramBucket", [
            { no: 1, name: "percentile", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 2, name: "size", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 3, name: "count", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<HistogramBucket>): HistogramBucket {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.percentile = 0;
        message.size = 0;
        message.count = 0;
        if (value !== undefined)
            reflectionMergePartial<HistogramBucket>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: HistogramBucket): HistogramBucket {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint32 percentile */ 1:
                    message.percentile = reader.uint32();
                    break;
                case /* uint32 size */ 2:
                    message.size = reader.uint32();
                    break;
                case /* uint64 count */ 3:
                    message.count = reader.uint64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: HistogramBucket, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint32 percentile = 1; */
        if (message.percentile !== 0)
            writer.tag(1, WireType.Varint).uint32(message.percentile);
        /* uint32 size = 2; */
        if (message.size !== 0)
            writer.tag(2, WireType.Varint).uint32(message.size);
        /* uint64 count = 3; */
        if (message.count !== 0)
            writer.tag(3, WireType.Varint).uint64(message.count);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tetragon.HistogramBucket
 */
export const HistogramBucket = new HistogramBucket$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Histogram$Type extends MessageType<Histogram> {
    constructor() {
        super("tetragon.Histogram", [
            { no: 1, name: "buckets", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => HistogramBucket },
            { no: 2, name: "sum", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<Histogram>): Histogram {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.buckets = [];
        message.sum = 0;
        if (value !== undefined)
            reflectionMergePartial<Histogram>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Histogram): Histogram {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated tetragon.HistogramBucket buckets */ 1:
                    message.buckets.push(HistogramBucket.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* uint64 sum */ 2:
                    message.sum = reader.uint64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Histogram, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated tetragon.HistogramBucket buckets = 1; */
        for (let i = 0; i < message.buckets.length; i++)
            HistogramBucket.internalBinaryWrite(message.buckets[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* uint64 sum = 2; */
        if (message.sum !== 0)
            writer.tag(2, WireType.Varint).uint64(message.sum);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tetragon.Histogram
 */
export const Histogram = new Histogram$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InterfaceStats$Type extends MessageType<InterfaceStats> {
    constructor() {
        super("tetragon.InterfaceStats", [
            { no: 1, name: "interface_name", kind: "scalar", localName: "interface_name", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "interface_ifindex", kind: "scalar", localName: "interface_ifindex", T: 13 /*ScalarType.UINT32*/ },
            { no: 3, name: "bytes_sent", kind: "scalar", localName: "bytes_sent", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "bytes_received", kind: "scalar", localName: "bytes_received", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 5, name: "packets_sent", kind: "scalar", localName: "packets_sent", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 6, name: "packets_received", kind: "scalar", localName: "packets_received", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 7, name: "tx_errors", kind: "scalar", localName: "tx_errors", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 8, name: "rx_errors", kind: "scalar", localName: "rx_errors", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 9, name: "tx_drops", kind: "scalar", localName: "tx_drops", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 10, name: "rx_drops", kind: "scalar", localName: "rx_drops", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 11, name: "pod", kind: "message", T: () => Pod },
            { no: 12, name: "netns", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "container_name", kind: "scalar", localName: "container_name", T: 9 /*ScalarType.STRING*/ },
            { no: 14, name: "qlen", kind: "message", T: () => Histogram }
        ]);
    }
    create(value?: PartialMessage<InterfaceStats>): InterfaceStats {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.interface_name = "";
        message.interface_ifindex = 0;
        message.bytes_sent = 0;
        message.bytes_received = 0;
        message.packets_sent = 0;
        message.packets_received = 0;
        message.tx_errors = 0;
        message.rx_errors = 0;
        message.tx_drops = 0;
        message.rx_drops = 0;
        message.netns = "";
        message.container_name = "";
        if (value !== undefined)
            reflectionMergePartial<InterfaceStats>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: InterfaceStats): InterfaceStats {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string interface_name */ 1:
                    message.interface_name = reader.string();
                    break;
                case /* uint32 interface_ifindex */ 2:
                    message.interface_ifindex = reader.uint32();
                    break;
                case /* uint64 bytes_sent */ 3:
                    message.bytes_sent = reader.uint64().toNumber();
                    break;
                case /* uint64 bytes_received */ 4:
                    message.bytes_received = reader.uint64().toNumber();
                    break;
                case /* uint64 packets_sent */ 5:
                    message.packets_sent = reader.uint64().toNumber();
                    break;
                case /* uint64 packets_received */ 6:
                    message.packets_received = reader.uint64().toNumber();
                    break;
                case /* uint64 tx_errors */ 7:
                    message.tx_errors = reader.uint64().toNumber();
                    break;
                case /* uint64 rx_errors */ 8:
                    message.rx_errors = reader.uint64().toNumber();
                    break;
                case /* uint64 tx_drops */ 9:
                    message.tx_drops = reader.uint64().toNumber();
                    break;
                case /* uint64 rx_drops */ 10:
                    message.rx_drops = reader.uint64().toNumber();
                    break;
                case /* tetragon.Pod pod */ 11:
                    message.pod = Pod.internalBinaryRead(reader, reader.uint32(), options, message.pod);
                    break;
                case /* string netns */ 12:
                    message.netns = reader.string();
                    break;
                case /* string container_name */ 13:
                    message.container_name = reader.string();
                    break;
                case /* tetragon.Histogram qlen */ 14:
                    message.qlen = Histogram.internalBinaryRead(reader, reader.uint32(), options, message.qlen);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: InterfaceStats, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string interface_name = 1; */
        if (message.interface_name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.interface_name);
        /* uint32 interface_ifindex = 2; */
        if (message.interface_ifindex !== 0)
            writer.tag(2, WireType.Varint).uint32(message.interface_ifindex);
        /* uint64 bytes_sent = 3; */
        if (message.bytes_sent !== 0)
            writer.tag(3, WireType.Varint).uint64(message.bytes_sent);
        /* uint64 bytes_received = 4; */
        if (message.bytes_received !== 0)
            writer.tag(4, WireType.Varint).uint64(message.bytes_received);
        /* uint64 packets_sent = 5; */
        if (message.packets_sent !== 0)
            writer.tag(5, WireType.Varint).uint64(message.packets_sent);
        /* uint64 packets_received = 6; */
        if (message.packets_received !== 0)
            writer.tag(6, WireType.Varint).uint64(message.packets_received);
        /* uint64 tx_errors = 7; */
        if (message.tx_errors !== 0)
            writer.tag(7, WireType.Varint).uint64(message.tx_errors);
        /* uint64 rx_errors = 8; */
        if (message.rx_errors !== 0)
            writer.tag(8, WireType.Varint).uint64(message.rx_errors);
        /* uint64 tx_drops = 9; */
        if (message.tx_drops !== 0)
            writer.tag(9, WireType.Varint).uint64(message.tx_drops);
        /* uint64 rx_drops = 10; */
        if (message.rx_drops !== 0)
            writer.tag(10, WireType.Varint).uint64(message.rx_drops);
        /* tetragon.Pod pod = 11; */
        if (message.pod)
            Pod.internalBinaryWrite(message.pod, writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        /* string netns = 12; */
        if (message.netns !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.netns);
        /* string container_name = 13; */
        if (message.container_name !== "")
            writer.tag(13, WireType.LengthDelimited).string(message.container_name);
        /* tetragon.Histogram qlen = 14; */
        if (message.qlen)
            Histogram.internalBinaryWrite(message.qlen, writer.tag(14, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tetragon.InterfaceStats
 */
export const InterfaceStats = new InterfaceStats$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SocketStats$Type extends MessageType<SocketStats> {
    constructor() {
        super("tetragon.SocketStats", [
            { no: 1, name: "bytes_sent", kind: "scalar", localName: "bytes_sent", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "bytes_received", kind: "scalar", localName: "bytes_received", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "segs_in", kind: "scalar", localName: "segs_in", T: 13 /*ScalarType.UINT32*/ },
            { no: 4, name: "segs_out", kind: "scalar", localName: "segs_out", T: 13 /*ScalarType.UINT32*/ },
            { no: 5, name: "srtt", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 6, name: "retransmits_bytes", kind: "scalar", localName: "retransmits_bytes", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 7, name: "retransmits_segs", kind: "scalar", localName: "retransmits_segs", T: 13 /*ScalarType.UINT32*/ },
            { no: 8, name: "to_zero_window", kind: "scalar", localName: "to_zero_window", T: 13 /*ScalarType.UINT32*/ },
            { no: 9, name: "sk_drop", kind: "scalar", localName: "sk_drop", T: 13 /*ScalarType.UINT32*/ },
            { no: 10, name: "bytes_consumed", kind: "scalar", localName: "bytes_consumed", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 11, name: "bytes_submitted", kind: "scalar", localName: "bytes_submitted", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 12, name: "segs_consumed", kind: "scalar", localName: "segs_consumed", T: 13 /*ScalarType.UINT32*/ },
            { no: 13, name: "segs_submitted", kind: "scalar", localName: "segs_submitted", T: 13 /*ScalarType.UINT32*/ },
            { no: 14, name: "skb_consume_misses", kind: "scalar", localName: "skb_consume_misses", T: 13 /*ScalarType.UINT32*/ },
            { no: 15, name: "rtt", kind: "message", T: () => Histogram },
            { no: 16, name: "latency", kind: "message", T: () => Histogram }
        ]);
    }
    create(value?: PartialMessage<SocketStats>): SocketStats {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.bytes_sent = 0;
        message.bytes_received = 0;
        message.segs_in = 0;
        message.segs_out = 0;
        message.srtt = 0;
        message.retransmits_bytes = 0;
        message.retransmits_segs = 0;
        message.to_zero_window = 0;
        message.sk_drop = 0;
        message.bytes_consumed = 0;
        message.bytes_submitted = 0;
        message.segs_consumed = 0;
        message.segs_submitted = 0;
        message.skb_consume_misses = 0;
        if (value !== undefined)
            reflectionMergePartial<SocketStats>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SocketStats): SocketStats {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint64 bytes_sent */ 1:
                    message.bytes_sent = reader.uint64().toNumber();
                    break;
                case /* uint64 bytes_received */ 2:
                    message.bytes_received = reader.uint64().toNumber();
                    break;
                case /* uint32 segs_in */ 3:
                    message.segs_in = reader.uint32();
                    break;
                case /* uint32 segs_out */ 4:
                    message.segs_out = reader.uint32();
                    break;
                case /* uint32 srtt */ 5:
                    message.srtt = reader.uint32();
                    break;
                case /* uint64 retransmits_bytes */ 6:
                    message.retransmits_bytes = reader.uint64().toNumber();
                    break;
                case /* uint32 retransmits_segs */ 7:
                    message.retransmits_segs = reader.uint32();
                    break;
                case /* uint32 to_zero_window */ 8:
                    message.to_zero_window = reader.uint32();
                    break;
                case /* uint32 sk_drop */ 9:
                    message.sk_drop = reader.uint32();
                    break;
                case /* uint64 bytes_consumed */ 10:
                    message.bytes_consumed = reader.uint64().toNumber();
                    break;
                case /* uint64 bytes_submitted */ 11:
                    message.bytes_submitted = reader.uint64().toNumber();
                    break;
                case /* uint32 segs_consumed */ 12:
                    message.segs_consumed = reader.uint32();
                    break;
                case /* uint32 segs_submitted */ 13:
                    message.segs_submitted = reader.uint32();
                    break;
                case /* uint32 skb_consume_misses */ 14:
                    message.skb_consume_misses = reader.uint32();
                    break;
                case /* tetragon.Histogram rtt */ 15:
                    message.rtt = Histogram.internalBinaryRead(reader, reader.uint32(), options, message.rtt);
                    break;
                case /* tetragon.Histogram latency */ 16:
                    message.latency = Histogram.internalBinaryRead(reader, reader.uint32(), options, message.latency);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SocketStats, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint64 bytes_sent = 1; */
        if (message.bytes_sent !== 0)
            writer.tag(1, WireType.Varint).uint64(message.bytes_sent);
        /* uint64 bytes_received = 2; */
        if (message.bytes_received !== 0)
            writer.tag(2, WireType.Varint).uint64(message.bytes_received);
        /* uint32 segs_in = 3; */
        if (message.segs_in !== 0)
            writer.tag(3, WireType.Varint).uint32(message.segs_in);
        /* uint32 segs_out = 4; */
        if (message.segs_out !== 0)
            writer.tag(4, WireType.Varint).uint32(message.segs_out);
        /* uint32 srtt = 5; */
        if (message.srtt !== 0)
            writer.tag(5, WireType.Varint).uint32(message.srtt);
        /* uint64 retransmits_bytes = 6; */
        if (message.retransmits_bytes !== 0)
            writer.tag(6, WireType.Varint).uint64(message.retransmits_bytes);
        /* uint32 retransmits_segs = 7; */
        if (message.retransmits_segs !== 0)
            writer.tag(7, WireType.Varint).uint32(message.retransmits_segs);
        /* uint32 to_zero_window = 8; */
        if (message.to_zero_window !== 0)
            writer.tag(8, WireType.Varint).uint32(message.to_zero_window);
        /* uint32 sk_drop = 9; */
        if (message.sk_drop !== 0)
            writer.tag(9, WireType.Varint).uint32(message.sk_drop);
        /* uint64 bytes_consumed = 10; */
        if (message.bytes_consumed !== 0)
            writer.tag(10, WireType.Varint).uint64(message.bytes_consumed);
        /* uint64 bytes_submitted = 11; */
        if (message.bytes_submitted !== 0)
            writer.tag(11, WireType.Varint).uint64(message.bytes_submitted);
        /* uint32 segs_consumed = 12; */
        if (message.segs_consumed !== 0)
            writer.tag(12, WireType.Varint).uint32(message.segs_consumed);
        /* uint32 segs_submitted = 13; */
        if (message.segs_submitted !== 0)
            writer.tag(13, WireType.Varint).uint32(message.segs_submitted);
        /* uint32 skb_consume_misses = 14; */
        if (message.skb_consume_misses !== 0)
            writer.tag(14, WireType.Varint).uint32(message.skb_consume_misses);
        /* tetragon.Histogram rtt = 15; */
        if (message.rtt)
            Histogram.internalBinaryWrite(message.rtt, writer.tag(15, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.Histogram latency = 16; */
        if (message.latency)
            Histogram.internalBinaryWrite(message.latency, writer.tag(16, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tetragon.SocketStats
 */
export const SocketStats = new SocketStats$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ProcessConnect$Type extends MessageType<ProcessConnect> {
    constructor() {
        super("tetragon.ProcessConnect", [
            { no: 1, name: "process", kind: "message", T: () => Process },
            { no: 2, name: "parent", kind: "message", T: () => Process },
            { no: 3, name: "source_ip", kind: "scalar", localName: "source_ip", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "source_port", kind: "message", localName: "source_port", T: () => UInt32Value },
            { no: 5, name: "destination_ip", kind: "scalar", localName: "destination_ip", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "destination_port", kind: "message", localName: "destination_port", T: () => UInt32Value },
            { no: 7, name: "destination_names", kind: "scalar", localName: "destination_names", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "sock_cookie", kind: "scalar", localName: "sock_cookie", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 9, name: "destination_pod", kind: "message", localName: "destination_pod", T: () => Pod },
            { no: 10, name: "protocol", kind: "enum", T: () => ["tetragon.SocketProtocol", SocketProtocol] }
        ]);
    }
    create(value?: PartialMessage<ProcessConnect>): ProcessConnect {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.source_ip = "";
        message.destination_ip = "";
        message.destination_names = [];
        message.sock_cookie = 0;
        message.protocol = 0;
        if (value !== undefined)
            reflectionMergePartial<ProcessConnect>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ProcessConnect): ProcessConnect {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* tetragon.Process process */ 1:
                    message.process = Process.internalBinaryRead(reader, reader.uint32(), options, message.process);
                    break;
                case /* tetragon.Process parent */ 2:
                    message.parent = Process.internalBinaryRead(reader, reader.uint32(), options, message.parent);
                    break;
                case /* string source_ip */ 3:
                    message.source_ip = reader.string();
                    break;
                case /* google.protobuf.UInt32Value source_port */ 4:
                    message.source_port = UInt32Value.internalBinaryRead(reader, reader.uint32(), options, message.source_port);
                    break;
                case /* string destination_ip */ 5:
                    message.destination_ip = reader.string();
                    break;
                case /* google.protobuf.UInt32Value destination_port */ 6:
                    message.destination_port = UInt32Value.internalBinaryRead(reader, reader.uint32(), options, message.destination_port);
                    break;
                case /* repeated string destination_names */ 7:
                    message.destination_names.push(reader.string());
                    break;
                case /* uint64 sock_cookie */ 8:
                    message.sock_cookie = reader.uint64().toNumber();
                    break;
                case /* tetragon.Pod destination_pod */ 9:
                    message.destination_pod = Pod.internalBinaryRead(reader, reader.uint32(), options, message.destination_pod);
                    break;
                case /* tetragon.SocketProtocol protocol */ 10:
                    message.protocol = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ProcessConnect, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* tetragon.Process process = 1; */
        if (message.process)
            Process.internalBinaryWrite(message.process, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.Process parent = 2; */
        if (message.parent)
            Process.internalBinaryWrite(message.parent, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* string source_ip = 3; */
        if (message.source_ip !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.source_ip);
        /* google.protobuf.UInt32Value source_port = 4; */
        if (message.source_port)
            UInt32Value.internalBinaryWrite(message.source_port, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* string destination_ip = 5; */
        if (message.destination_ip !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.destination_ip);
        /* google.protobuf.UInt32Value destination_port = 6; */
        if (message.destination_port)
            UInt32Value.internalBinaryWrite(message.destination_port, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* repeated string destination_names = 7; */
        for (let i = 0; i < message.destination_names.length; i++)
            writer.tag(7, WireType.LengthDelimited).string(message.destination_names[i]);
        /* uint64 sock_cookie = 8; */
        if (message.sock_cookie !== 0)
            writer.tag(8, WireType.Varint).uint64(message.sock_cookie);
        /* tetragon.Pod destination_pod = 9; */
        if (message.destination_pod)
            Pod.internalBinaryWrite(message.destination_pod, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.SocketProtocol protocol = 10; */
        if (message.protocol !== 0)
            writer.tag(10, WireType.Varint).int32(message.protocol);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tetragon.ProcessConnect
 */
export const ProcessConnect = new ProcessConnect$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ProcessClose$Type extends MessageType<ProcessClose> {
    constructor() {
        super("tetragon.ProcessClose", [
            { no: 1, name: "process", kind: "message", T: () => Process },
            { no: 2, name: "parent", kind: "message", T: () => Process },
            { no: 3, name: "source_ip", kind: "scalar", localName: "source_ip", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "source_port", kind: "message", localName: "source_port", T: () => UInt32Value },
            { no: 5, name: "destination_ip", kind: "scalar", localName: "destination_ip", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "destination_port", kind: "message", localName: "destination_port", T: () => UInt32Value },
            { no: 7, name: "destination_names", kind: "scalar", localName: "destination_names", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "sock_cookie", kind: "scalar", localName: "sock_cookie", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 9, name: "stats", kind: "message", T: () => SocketStats },
            { no: 10, name: "destination_pod", kind: "message", localName: "destination_pod", T: () => Pod },
            { no: 11, name: "protocol", kind: "enum", T: () => ["tetragon.SocketProtocol", SocketProtocol] },
            { no: 12, name: "socket_type", kind: "scalar", localName: "socket_type", T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "duration", kind: "message", T: () => Duration }
        ]);
    }
    create(value?: PartialMessage<ProcessClose>): ProcessClose {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.source_ip = "";
        message.destination_ip = "";
        message.destination_names = [];
        message.sock_cookie = 0;
        message.protocol = 0;
        message.socket_type = "";
        if (value !== undefined)
            reflectionMergePartial<ProcessClose>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ProcessClose): ProcessClose {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* tetragon.Process process */ 1:
                    message.process = Process.internalBinaryRead(reader, reader.uint32(), options, message.process);
                    break;
                case /* tetragon.Process parent */ 2:
                    message.parent = Process.internalBinaryRead(reader, reader.uint32(), options, message.parent);
                    break;
                case /* string source_ip */ 3:
                    message.source_ip = reader.string();
                    break;
                case /* google.protobuf.UInt32Value source_port */ 4:
                    message.source_port = UInt32Value.internalBinaryRead(reader, reader.uint32(), options, message.source_port);
                    break;
                case /* string destination_ip */ 5:
                    message.destination_ip = reader.string();
                    break;
                case /* google.protobuf.UInt32Value destination_port */ 6:
                    message.destination_port = UInt32Value.internalBinaryRead(reader, reader.uint32(), options, message.destination_port);
                    break;
                case /* repeated string destination_names */ 7:
                    message.destination_names.push(reader.string());
                    break;
                case /* uint64 sock_cookie */ 8:
                    message.sock_cookie = reader.uint64().toNumber();
                    break;
                case /* tetragon.SocketStats stats */ 9:
                    message.stats = SocketStats.internalBinaryRead(reader, reader.uint32(), options, message.stats);
                    break;
                case /* tetragon.Pod destination_pod */ 10:
                    message.destination_pod = Pod.internalBinaryRead(reader, reader.uint32(), options, message.destination_pod);
                    break;
                case /* tetragon.SocketProtocol protocol */ 11:
                    message.protocol = reader.int32();
                    break;
                case /* string socket_type */ 12:
                    message.socket_type = reader.string();
                    break;
                case /* google.protobuf.Duration duration */ 13:
                    message.duration = Duration.internalBinaryRead(reader, reader.uint32(), options, message.duration);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ProcessClose, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* tetragon.Process process = 1; */
        if (message.process)
            Process.internalBinaryWrite(message.process, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.Process parent = 2; */
        if (message.parent)
            Process.internalBinaryWrite(message.parent, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* string source_ip = 3; */
        if (message.source_ip !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.source_ip);
        /* google.protobuf.UInt32Value source_port = 4; */
        if (message.source_port)
            UInt32Value.internalBinaryWrite(message.source_port, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* string destination_ip = 5; */
        if (message.destination_ip !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.destination_ip);
        /* google.protobuf.UInt32Value destination_port = 6; */
        if (message.destination_port)
            UInt32Value.internalBinaryWrite(message.destination_port, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* repeated string destination_names = 7; */
        for (let i = 0; i < message.destination_names.length; i++)
            writer.tag(7, WireType.LengthDelimited).string(message.destination_names[i]);
        /* uint64 sock_cookie = 8; */
        if (message.sock_cookie !== 0)
            writer.tag(8, WireType.Varint).uint64(message.sock_cookie);
        /* tetragon.SocketStats stats = 9; */
        if (message.stats)
            SocketStats.internalBinaryWrite(message.stats, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.Pod destination_pod = 10; */
        if (message.destination_pod)
            Pod.internalBinaryWrite(message.destination_pod, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.SocketProtocol protocol = 11; */
        if (message.protocol !== 0)
            writer.tag(11, WireType.Varint).int32(message.protocol);
        /* string socket_type = 12; */
        if (message.socket_type !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.socket_type);
        /* google.protobuf.Duration duration = 13; */
        if (message.duration)
            Duration.internalBinaryWrite(message.duration, writer.tag(13, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tetragon.ProcessClose
 */
export const ProcessClose = new ProcessClose$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ProcessListen$Type extends MessageType<ProcessListen> {
    constructor() {
        super("tetragon.ProcessListen", [
            { no: 1, name: "process", kind: "message", T: () => Process },
            { no: 2, name: "parent", kind: "message", T: () => Process },
            { no: 3, name: "ip", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "port", kind: "message", T: () => UInt32Value },
            { no: 5, name: "sock_cookie", kind: "scalar", localName: "sock_cookie", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 6, name: "protocol", kind: "enum", T: () => ["tetragon.SocketProtocol", SocketProtocol] }
        ]);
    }
    create(value?: PartialMessage<ProcessListen>): ProcessListen {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.ip = "";
        message.sock_cookie = 0;
        message.protocol = 0;
        if (value !== undefined)
            reflectionMergePartial<ProcessListen>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ProcessListen): ProcessListen {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* tetragon.Process process */ 1:
                    message.process = Process.internalBinaryRead(reader, reader.uint32(), options, message.process);
                    break;
                case /* tetragon.Process parent */ 2:
                    message.parent = Process.internalBinaryRead(reader, reader.uint32(), options, message.parent);
                    break;
                case /* string ip */ 3:
                    message.ip = reader.string();
                    break;
                case /* google.protobuf.UInt32Value port */ 4:
                    message.port = UInt32Value.internalBinaryRead(reader, reader.uint32(), options, message.port);
                    break;
                case /* uint64 sock_cookie */ 5:
                    message.sock_cookie = reader.uint64().toNumber();
                    break;
                case /* tetragon.SocketProtocol protocol */ 6:
                    message.protocol = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ProcessListen, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* tetragon.Process process = 1; */
        if (message.process)
            Process.internalBinaryWrite(message.process, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.Process parent = 2; */
        if (message.parent)
            Process.internalBinaryWrite(message.parent, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* string ip = 3; */
        if (message.ip !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.ip);
        /* google.protobuf.UInt32Value port = 4; */
        if (message.port)
            UInt32Value.internalBinaryWrite(message.port, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* uint64 sock_cookie = 5; */
        if (message.sock_cookie !== 0)
            writer.tag(5, WireType.Varint).uint64(message.sock_cookie);
        /* tetragon.SocketProtocol protocol = 6; */
        if (message.protocol !== 0)
            writer.tag(6, WireType.Varint).int32(message.protocol);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tetragon.ProcessListen
 */
export const ProcessListen = new ProcessListen$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ProcessAccept$Type extends MessageType<ProcessAccept> {
    constructor() {
        super("tetragon.ProcessAccept", [
            { no: 1, name: "process", kind: "message", T: () => Process },
            { no: 2, name: "parent", kind: "message", T: () => Process },
            { no: 3, name: "source_ip", kind: "scalar", localName: "source_ip", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "source_port", kind: "message", localName: "source_port", T: () => UInt32Value },
            { no: 5, name: "destination_ip", kind: "scalar", localName: "destination_ip", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "destination_port", kind: "message", localName: "destination_port", T: () => UInt32Value },
            { no: 7, name: "destination_names", kind: "scalar", localName: "destination_names", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "sock_cookie", kind: "scalar", localName: "sock_cookie", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 9, name: "destination_pod", kind: "message", localName: "destination_pod", T: () => Pod },
            { no: 10, name: "protocol", kind: "enum", T: () => ["tetragon.SocketProtocol", SocketProtocol] }
        ]);
    }
    create(value?: PartialMessage<ProcessAccept>): ProcessAccept {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.source_ip = "";
        message.destination_ip = "";
        message.destination_names = [];
        message.sock_cookie = 0;
        message.protocol = 0;
        if (value !== undefined)
            reflectionMergePartial<ProcessAccept>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ProcessAccept): ProcessAccept {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* tetragon.Process process */ 1:
                    message.process = Process.internalBinaryRead(reader, reader.uint32(), options, message.process);
                    break;
                case /* tetragon.Process parent */ 2:
                    message.parent = Process.internalBinaryRead(reader, reader.uint32(), options, message.parent);
                    break;
                case /* string source_ip */ 3:
                    message.source_ip = reader.string();
                    break;
                case /* google.protobuf.UInt32Value source_port */ 4:
                    message.source_port = UInt32Value.internalBinaryRead(reader, reader.uint32(), options, message.source_port);
                    break;
                case /* string destination_ip */ 5:
                    message.destination_ip = reader.string();
                    break;
                case /* google.protobuf.UInt32Value destination_port */ 6:
                    message.destination_port = UInt32Value.internalBinaryRead(reader, reader.uint32(), options, message.destination_port);
                    break;
                case /* repeated string destination_names */ 7:
                    message.destination_names.push(reader.string());
                    break;
                case /* uint64 sock_cookie */ 8:
                    message.sock_cookie = reader.uint64().toNumber();
                    break;
                case /* tetragon.Pod destination_pod */ 9:
                    message.destination_pod = Pod.internalBinaryRead(reader, reader.uint32(), options, message.destination_pod);
                    break;
                case /* tetragon.SocketProtocol protocol */ 10:
                    message.protocol = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ProcessAccept, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* tetragon.Process process = 1; */
        if (message.process)
            Process.internalBinaryWrite(message.process, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.Process parent = 2; */
        if (message.parent)
            Process.internalBinaryWrite(message.parent, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* string source_ip = 3; */
        if (message.source_ip !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.source_ip);
        /* google.protobuf.UInt32Value source_port = 4; */
        if (message.source_port)
            UInt32Value.internalBinaryWrite(message.source_port, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* string destination_ip = 5; */
        if (message.destination_ip !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.destination_ip);
        /* google.protobuf.UInt32Value destination_port = 6; */
        if (message.destination_port)
            UInt32Value.internalBinaryWrite(message.destination_port, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* repeated string destination_names = 7; */
        for (let i = 0; i < message.destination_names.length; i++)
            writer.tag(7, WireType.LengthDelimited).string(message.destination_names[i]);
        /* uint64 sock_cookie = 8; */
        if (message.sock_cookie !== 0)
            writer.tag(8, WireType.Varint).uint64(message.sock_cookie);
        /* tetragon.Pod destination_pod = 9; */
        if (message.destination_pod)
            Pod.internalBinaryWrite(message.destination_pod, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.SocketProtocol protocol = 10; */
        if (message.protocol !== 0)
            writer.tag(10, WireType.Varint).int32(message.protocol);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tetragon.ProcessAccept
 */
export const ProcessAccept = new ProcessAccept$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ProcessRawsockCreate$Type extends MessageType<ProcessRawsockCreate> {
    constructor() {
        super("tetragon.ProcessRawsockCreate", [
            { no: 1, name: "process", kind: "message", T: () => Process },
            { no: 2, name: "parent", kind: "message", T: () => Process },
            { no: 3, name: "sock_cookie", kind: "scalar", localName: "sock_cookie", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<ProcessRawsockCreate>): ProcessRawsockCreate {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.sock_cookie = 0;
        if (value !== undefined)
            reflectionMergePartial<ProcessRawsockCreate>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ProcessRawsockCreate): ProcessRawsockCreate {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* tetragon.Process process */ 1:
                    message.process = Process.internalBinaryRead(reader, reader.uint32(), options, message.process);
                    break;
                case /* tetragon.Process parent */ 2:
                    message.parent = Process.internalBinaryRead(reader, reader.uint32(), options, message.parent);
                    break;
                case /* uint64 sock_cookie */ 3:
                    message.sock_cookie = reader.uint64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ProcessRawsockCreate, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* tetragon.Process process = 1; */
        if (message.process)
            Process.internalBinaryWrite(message.process, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.Process parent = 2; */
        if (message.parent)
            Process.internalBinaryWrite(message.parent, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* uint64 sock_cookie = 3; */
        if (message.sock_cookie !== 0)
            writer.tag(3, WireType.Varint).uint64(message.sock_cookie);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tetragon.ProcessRawsockCreate
 */
export const ProcessRawsockCreate = new ProcessRawsockCreate$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ProcessRawsockClose$Type extends MessageType<ProcessRawsockClose> {
    constructor() {
        super("tetragon.ProcessRawsockClose", [
            { no: 1, name: "process", kind: "message", T: () => Process },
            { no: 2, name: "parent", kind: "message", T: () => Process },
            { no: 3, name: "sock_cookie", kind: "scalar", localName: "sock_cookie", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "duration", kind: "message", T: () => Duration }
        ]);
    }
    create(value?: PartialMessage<ProcessRawsockClose>): ProcessRawsockClose {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.sock_cookie = 0;
        if (value !== undefined)
            reflectionMergePartial<ProcessRawsockClose>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ProcessRawsockClose): ProcessRawsockClose {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* tetragon.Process process */ 1:
                    message.process = Process.internalBinaryRead(reader, reader.uint32(), options, message.process);
                    break;
                case /* tetragon.Process parent */ 2:
                    message.parent = Process.internalBinaryRead(reader, reader.uint32(), options, message.parent);
                    break;
                case /* uint64 sock_cookie */ 3:
                    message.sock_cookie = reader.uint64().toNumber();
                    break;
                case /* google.protobuf.Duration duration */ 4:
                    message.duration = Duration.internalBinaryRead(reader, reader.uint32(), options, message.duration);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ProcessRawsockClose, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* tetragon.Process process = 1; */
        if (message.process)
            Process.internalBinaryWrite(message.process, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.Process parent = 2; */
        if (message.parent)
            Process.internalBinaryWrite(message.parent, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* uint64 sock_cookie = 3; */
        if (message.sock_cookie !== 0)
            writer.tag(3, WireType.Varint).uint64(message.sock_cookie);
        /* google.protobuf.Duration duration = 4; */
        if (message.duration)
            Duration.internalBinaryWrite(message.duration, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tetragon.ProcessRawsockClose
 */
export const ProcessRawsockClose = new ProcessRawsockClose$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ProcessIcmp$Type extends MessageType<ProcessIcmp> {
    constructor() {
        super("tetragon.ProcessIcmp", [
            { no: 1, name: "process", kind: "message", T: () => Process },
            { no: 2, name: "parent", kind: "message", T: () => Process },
            { no: 3, name: "source_ip", kind: "scalar", localName: "source_ip", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "destination_ip", kind: "scalar", localName: "destination_ip", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "destination_names", kind: "scalar", localName: "destination_names", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "sock_cookie", kind: "scalar", localName: "sock_cookie", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 7, name: "destination_pod", kind: "message", localName: "destination_pod", T: () => Pod },
            { no: 8, name: "protocol", kind: "enum", T: () => ["tetragon.SocketProtocol", SocketProtocol] },
            { no: 9, name: "icmp_type", kind: "scalar", localName: "icmp_type", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "icmp_code", kind: "scalar", localName: "icmp_code", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "icmp_type_value", kind: "scalar", localName: "icmp_type_value", T: 13 /*ScalarType.UINT32*/ },
            { no: 12, name: "icmp_code_value", kind: "scalar", localName: "icmp_code_value", T: 13 /*ScalarType.UINT32*/ },
            { no: 13, name: "identifier", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 14, name: "sequence_number", kind: "scalar", localName: "sequence_number", T: 13 /*ScalarType.UINT32*/ },
            { no: 15, name: "icmp_data_len", kind: "scalar", localName: "icmp_data_len", T: 13 /*ScalarType.UINT32*/ },
            { no: 16, name: "direction", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 17, name: "icmp_ip_protocol", kind: "enum", localName: "icmp_ip_protocol", T: () => ["tetragon.SocketProtocol", SocketProtocol] },
            { no: 18, name: "icmp_ip_port", kind: "scalar", localName: "icmp_ip_port", T: 13 /*ScalarType.UINT32*/ },
            { no: 19, name: "icmp_ip_ttl", kind: "scalar", localName: "icmp_ip_ttl", T: 13 /*ScalarType.UINT32*/ },
            { no: 20, name: "icmp_ip_pointer", kind: "scalar", localName: "icmp_ip_pointer", T: 13 /*ScalarType.UINT32*/ },
            { no: 21, name: "icmp_ip_gateway", kind: "scalar", localName: "icmp_ip_gateway", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ProcessIcmp>): ProcessIcmp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.source_ip = "";
        message.destination_ip = "";
        message.destination_names = [];
        message.sock_cookie = 0;
        message.protocol = 0;
        message.icmp_type = "";
        message.icmp_code = "";
        message.icmp_type_value = 0;
        message.icmp_code_value = 0;
        message.identifier = 0;
        message.sequence_number = 0;
        message.icmp_data_len = 0;
        message.direction = "";
        message.icmp_ip_protocol = 0;
        message.icmp_ip_port = 0;
        message.icmp_ip_ttl = 0;
        message.icmp_ip_pointer = 0;
        message.icmp_ip_gateway = "";
        if (value !== undefined)
            reflectionMergePartial<ProcessIcmp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ProcessIcmp): ProcessIcmp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* tetragon.Process process */ 1:
                    message.process = Process.internalBinaryRead(reader, reader.uint32(), options, message.process);
                    break;
                case /* tetragon.Process parent */ 2:
                    message.parent = Process.internalBinaryRead(reader, reader.uint32(), options, message.parent);
                    break;
                case /* string source_ip */ 3:
                    message.source_ip = reader.string();
                    break;
                case /* string destination_ip */ 4:
                    message.destination_ip = reader.string();
                    break;
                case /* repeated string destination_names */ 5:
                    message.destination_names.push(reader.string());
                    break;
                case /* uint64 sock_cookie */ 6:
                    message.sock_cookie = reader.uint64().toNumber();
                    break;
                case /* tetragon.Pod destination_pod */ 7:
                    message.destination_pod = Pod.internalBinaryRead(reader, reader.uint32(), options, message.destination_pod);
                    break;
                case /* tetragon.SocketProtocol protocol */ 8:
                    message.protocol = reader.int32();
                    break;
                case /* string icmp_type */ 9:
                    message.icmp_type = reader.string();
                    break;
                case /* string icmp_code */ 10:
                    message.icmp_code = reader.string();
                    break;
                case /* uint32 icmp_type_value */ 11:
                    message.icmp_type_value = reader.uint32();
                    break;
                case /* uint32 icmp_code_value */ 12:
                    message.icmp_code_value = reader.uint32();
                    break;
                case /* uint32 identifier */ 13:
                    message.identifier = reader.uint32();
                    break;
                case /* uint32 sequence_number */ 14:
                    message.sequence_number = reader.uint32();
                    break;
                case /* uint32 icmp_data_len */ 15:
                    message.icmp_data_len = reader.uint32();
                    break;
                case /* string direction */ 16:
                    message.direction = reader.string();
                    break;
                case /* tetragon.SocketProtocol icmp_ip_protocol */ 17:
                    message.icmp_ip_protocol = reader.int32();
                    break;
                case /* uint32 icmp_ip_port */ 18:
                    message.icmp_ip_port = reader.uint32();
                    break;
                case /* uint32 icmp_ip_ttl */ 19:
                    message.icmp_ip_ttl = reader.uint32();
                    break;
                case /* uint32 icmp_ip_pointer */ 20:
                    message.icmp_ip_pointer = reader.uint32();
                    break;
                case /* string icmp_ip_gateway */ 21:
                    message.icmp_ip_gateway = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ProcessIcmp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* tetragon.Process process = 1; */
        if (message.process)
            Process.internalBinaryWrite(message.process, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.Process parent = 2; */
        if (message.parent)
            Process.internalBinaryWrite(message.parent, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* string source_ip = 3; */
        if (message.source_ip !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.source_ip);
        /* string destination_ip = 4; */
        if (message.destination_ip !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.destination_ip);
        /* repeated string destination_names = 5; */
        for (let i = 0; i < message.destination_names.length; i++)
            writer.tag(5, WireType.LengthDelimited).string(message.destination_names[i]);
        /* uint64 sock_cookie = 6; */
        if (message.sock_cookie !== 0)
            writer.tag(6, WireType.Varint).uint64(message.sock_cookie);
        /* tetragon.Pod destination_pod = 7; */
        if (message.destination_pod)
            Pod.internalBinaryWrite(message.destination_pod, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.SocketProtocol protocol = 8; */
        if (message.protocol !== 0)
            writer.tag(8, WireType.Varint).int32(message.protocol);
        /* string icmp_type = 9; */
        if (message.icmp_type !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.icmp_type);
        /* string icmp_code = 10; */
        if (message.icmp_code !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.icmp_code);
        /* uint32 icmp_type_value = 11; */
        if (message.icmp_type_value !== 0)
            writer.tag(11, WireType.Varint).uint32(message.icmp_type_value);
        /* uint32 icmp_code_value = 12; */
        if (message.icmp_code_value !== 0)
            writer.tag(12, WireType.Varint).uint32(message.icmp_code_value);
        /* uint32 identifier = 13; */
        if (message.identifier !== 0)
            writer.tag(13, WireType.Varint).uint32(message.identifier);
        /* uint32 sequence_number = 14; */
        if (message.sequence_number !== 0)
            writer.tag(14, WireType.Varint).uint32(message.sequence_number);
        /* uint32 icmp_data_len = 15; */
        if (message.icmp_data_len !== 0)
            writer.tag(15, WireType.Varint).uint32(message.icmp_data_len);
        /* string direction = 16; */
        if (message.direction !== "")
            writer.tag(16, WireType.LengthDelimited).string(message.direction);
        /* tetragon.SocketProtocol icmp_ip_protocol = 17; */
        if (message.icmp_ip_protocol !== 0)
            writer.tag(17, WireType.Varint).int32(message.icmp_ip_protocol);
        /* uint32 icmp_ip_port = 18; */
        if (message.icmp_ip_port !== 0)
            writer.tag(18, WireType.Varint).uint32(message.icmp_ip_port);
        /* uint32 icmp_ip_ttl = 19; */
        if (message.icmp_ip_ttl !== 0)
            writer.tag(19, WireType.Varint).uint32(message.icmp_ip_ttl);
        /* uint32 icmp_ip_pointer = 20; */
        if (message.icmp_ip_pointer !== 0)
            writer.tag(20, WireType.Varint).uint32(message.icmp_ip_pointer);
        /* string icmp_ip_gateway = 21; */
        if (message.icmp_ip_gateway !== "")
            writer.tag(21, WireType.LengthDelimited).string(message.icmp_ip_gateway);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tetragon.ProcessIcmp
 */
export const ProcessIcmp = new ProcessIcmp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ProcessIpError$Type extends MessageType<ProcessIpError> {
    constructor() {
        super("tetragon.ProcessIpError", [
            { no: 1, name: "process", kind: "message", T: () => Process },
            { no: 2, name: "parent", kind: "message", T: () => Process },
            { no: 3, name: "source_ip", kind: "scalar", localName: "source_ip", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "destination_ip", kind: "scalar", localName: "destination_ip", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "version", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "sock_cookie", kind: "scalar", localName: "sock_cookie", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 7, name: "destination_pod", kind: "message", localName: "destination_pod", T: () => Pod },
            { no: 8, name: "details", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "send", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "version_byte", kind: "scalar", localName: "version_byte", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 11, name: "data", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<ProcessIpError>): ProcessIpError {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.source_ip = "";
        message.destination_ip = "";
        message.version = "";
        message.sock_cookie = 0;
        message.details = "";
        message.send = "";
        message.version_byte = 0;
        message.data = 0;
        if (value !== undefined)
            reflectionMergePartial<ProcessIpError>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ProcessIpError): ProcessIpError {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* tetragon.Process process */ 1:
                    message.process = Process.internalBinaryRead(reader, reader.uint32(), options, message.process);
                    break;
                case /* tetragon.Process parent */ 2:
                    message.parent = Process.internalBinaryRead(reader, reader.uint32(), options, message.parent);
                    break;
                case /* string source_ip */ 3:
                    message.source_ip = reader.string();
                    break;
                case /* string destination_ip */ 4:
                    message.destination_ip = reader.string();
                    break;
                case /* string version */ 5:
                    message.version = reader.string();
                    break;
                case /* uint64 sock_cookie */ 6:
                    message.sock_cookie = reader.uint64().toNumber();
                    break;
                case /* tetragon.Pod destination_pod */ 7:
                    message.destination_pod = Pod.internalBinaryRead(reader, reader.uint32(), options, message.destination_pod);
                    break;
                case /* string details */ 8:
                    message.details = reader.string();
                    break;
                case /* string send */ 9:
                    message.send = reader.string();
                    break;
                case /* uint64 version_byte */ 10:
                    message.version_byte = reader.uint64().toNumber();
                    break;
                case /* uint64 data */ 11:
                    message.data = reader.uint64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ProcessIpError, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* tetragon.Process process = 1; */
        if (message.process)
            Process.internalBinaryWrite(message.process, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.Process parent = 2; */
        if (message.parent)
            Process.internalBinaryWrite(message.parent, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* string source_ip = 3; */
        if (message.source_ip !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.source_ip);
        /* string destination_ip = 4; */
        if (message.destination_ip !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.destination_ip);
        /* string version = 5; */
        if (message.version !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.version);
        /* uint64 sock_cookie = 6; */
        if (message.sock_cookie !== 0)
            writer.tag(6, WireType.Varint).uint64(message.sock_cookie);
        /* tetragon.Pod destination_pod = 7; */
        if (message.destination_pod)
            Pod.internalBinaryWrite(message.destination_pod, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* string details = 8; */
        if (message.details !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.details);
        /* string send = 9; */
        if (message.send !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.send);
        /* uint64 version_byte = 10; */
        if (message.version_byte !== 0)
            writer.tag(10, WireType.Varint).uint64(message.version_byte);
        /* uint64 data = 11; */
        if (message.data !== 0)
            writer.tag(11, WireType.Varint).uint64(message.data);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tetragon.ProcessIpError
 */
export const ProcessIpError = new ProcessIpError$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FileSystem$Type extends MessageType<FileSystem> {
    constructor() {
        super("tetragon.FileSystem", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "dev", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "uuid", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<FileSystem>): FileSystem {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.name = "";
        message.dev = "";
        message.id = "";
        message.uuid = "";
        if (value !== undefined)
            reflectionMergePartial<FileSystem>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FileSystem): FileSystem {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* string dev */ 2:
                    message.dev = reader.string();
                    break;
                case /* string id */ 3:
                    message.id = reader.string();
                    break;
                case /* string uuid */ 4:
                    message.uuid = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FileSystem, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* string dev = 2; */
        if (message.dev !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.dev);
        /* string id = 3; */
        if (message.id !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.id);
        /* string uuid = 4; */
        if (message.uuid !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.uuid);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tetragon.FileSystem
 */
export const FileSystem = new FileSystem$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Inode$Type extends MessageType<Inode> {
    constructor() {
        super("tetragon.Inode", [
            { no: 1, name: "number", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "fs", kind: "message", T: () => FileSystem }
        ]);
    }
    create(value?: PartialMessage<Inode>): Inode {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.number = 0;
        if (value !== undefined)
            reflectionMergePartial<Inode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Inode): Inode {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint64 number */ 1:
                    message.number = reader.uint64().toNumber();
                    break;
                case /* tetragon.FileSystem fs */ 2:
                    message.fs = FileSystem.internalBinaryRead(reader, reader.uint32(), options, message.fs);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Inode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint64 number = 1; */
        if (message.number !== 0)
            writer.tag(1, WireType.Varint).uint64(message.number);
        /* tetragon.FileSystem fs = 2; */
        if (message.fs)
            FileSystem.internalBinaryWrite(message.fs, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tetragon.Inode
 */
export const Inode = new Inode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FileLocation$Type extends MessageType<FileLocation> {
    constructor() {
        super("tetragon.FileLocation", [
            { no: 1, name: "type", kind: "enum", T: () => ["tetragon.FileScope", FileScope] },
            { no: 2, name: "container_id", kind: "scalar", localName: "container_id", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "pod", kind: "message", T: () => Pod }
        ]);
    }
    create(value?: PartialMessage<FileLocation>): FileLocation {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.type = 0;
        message.container_id = "";
        if (value !== undefined)
            reflectionMergePartial<FileLocation>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FileLocation): FileLocation {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* tetragon.FileScope type */ 1:
                    message.type = reader.int32();
                    break;
                case /* string container_id */ 2:
                    message.container_id = reader.string();
                    break;
                case /* tetragon.Pod pod */ 3:
                    message.pod = Pod.internalBinaryRead(reader, reader.uint32(), options, message.pod);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FileLocation, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* tetragon.FileScope type = 1; */
        if (message.type !== 0)
            writer.tag(1, WireType.Varint).int32(message.type);
        /* string container_id = 2; */
        if (message.container_id !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.container_id);
        /* tetragon.Pod pod = 3; */
        if (message.pod)
            Pod.internalBinaryWrite(message.pod, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tetragon.FileLocation
 */
export const FileLocation = new FileLocation$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FileDetails$Type extends MessageType<FileDetails> {
    constructor() {
        super("tetragon.FileDetails", [
            { no: 1, name: "str", kind: "scalar", oneof: "filename", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "inode", kind: "message", T: () => Inode },
            { no: 3, name: "parent_inode", kind: "message", localName: "parent_inode", T: () => Inode },
            { no: 4, name: "location", kind: "message", T: () => FileLocation }
        ]);
    }
    create(value?: PartialMessage<FileDetails>): FileDetails {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.filename = { oneofKind: undefined };
        if (value !== undefined)
            reflectionMergePartial<FileDetails>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FileDetails): FileDetails {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string str */ 1:
                    message.filename = {
                        oneofKind: "str",
                        str: reader.string()
                    };
                    break;
                case /* tetragon.Inode inode */ 2:
                    message.inode = Inode.internalBinaryRead(reader, reader.uint32(), options, message.inode);
                    break;
                case /* tetragon.Inode parent_inode */ 3:
                    message.parent_inode = Inode.internalBinaryRead(reader, reader.uint32(), options, message.parent_inode);
                    break;
                case /* tetragon.FileLocation location */ 4:
                    message.location = FileLocation.internalBinaryRead(reader, reader.uint32(), options, message.location);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FileDetails, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string str = 1; */
        if (message.filename.oneofKind === "str")
            writer.tag(1, WireType.LengthDelimited).string(message.filename.str);
        /* tetragon.Inode inode = 2; */
        if (message.inode)
            Inode.internalBinaryWrite(message.inode, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.Inode parent_inode = 3; */
        if (message.parent_inode)
            Inode.internalBinaryWrite(message.parent_inode, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.FileLocation location = 4; */
        if (message.location)
            FileLocation.internalBinaryWrite(message.location, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tetragon.FileDetails
 */
export const FileDetails = new FileDetails$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FileIO$Type extends MessageType<FileIO> {
    constructor() {
        super("tetragon.FileIO", [
            { no: 1, name: "offset", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "size", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<FileIO>): FileIO {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.offset = "";
        message.size = "";
        if (value !== undefined)
            reflectionMergePartial<FileIO>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FileIO): FileIO {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string offset */ 1:
                    message.offset = reader.string();
                    break;
                case /* string size */ 2:
                    message.size = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FileIO, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string offset = 1; */
        if (message.offset !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.offset);
        /* string size = 2; */
        if (message.size !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.size);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tetragon.FileIO
 */
export const FileIO = new FileIO$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FileDigest$Type extends MessageType<FileDigest> {
    constructor() {
        super("tetragon.FileDigest", [
            { no: 1, name: "algo", kind: "enum", T: () => ["tetragon.DigestAlgo", DigestAlgo] },
            { no: 2, name: "hash", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "error", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<FileDigest>): FileDigest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.algo = 0;
        message.hash = "";
        message.error = 0;
        if (value !== undefined)
            reflectionMergePartial<FileDigest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FileDigest): FileDigest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* tetragon.DigestAlgo algo */ 1:
                    message.algo = reader.int32();
                    break;
                case /* string hash */ 2:
                    message.hash = reader.string();
                    break;
                case /* int64 error */ 3:
                    message.error = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FileDigest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* tetragon.DigestAlgo algo = 1; */
        if (message.algo !== 0)
            writer.tag(1, WireType.Varint).int32(message.algo);
        /* string hash = 2; */
        if (message.hash !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.hash);
        /* int64 error = 3; */
        if (message.error !== 0)
            writer.tag(3, WireType.Varint).int64(message.error);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tetragon.FileDigest
 */
export const FileDigest = new FileDigest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GenericFileArg$Type extends MessageType<GenericFileArg> {
    constructor() {
        super("tetragon.GenericFileArg", [
            { no: 1, name: "file", kind: "message", T: () => FileDetails },
            { no: 2, name: "io", kind: "message", T: () => FileIO },
            { no: 3, name: "mnt_ns", kind: "message", localName: "mnt_ns", T: () => Namespace },
            { no: 4, name: "digest", kind: "message", T: () => FileDigest }
        ]);
    }
    create(value?: PartialMessage<GenericFileArg>): GenericFileArg {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<GenericFileArg>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GenericFileArg): GenericFileArg {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* tetragon.FileDetails file */ 1:
                    message.file = FileDetails.internalBinaryRead(reader, reader.uint32(), options, message.file);
                    break;
                case /* tetragon.FileIO io = 2 [deprecated = true];*/ 2:
                    message.io = FileIO.internalBinaryRead(reader, reader.uint32(), options, message.io);
                    break;
                case /* tetragon.Namespace mnt_ns */ 3:
                    message.mnt_ns = Namespace.internalBinaryRead(reader, reader.uint32(), options, message.mnt_ns);
                    break;
                case /* tetragon.FileDigest digest */ 4:
                    message.digest = FileDigest.internalBinaryRead(reader, reader.uint32(), options, message.digest);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GenericFileArg, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* tetragon.FileDetails file = 1; */
        if (message.file)
            FileDetails.internalBinaryWrite(message.file, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.FileIO io = 2 [deprecated = true]; */
        if (message.io)
            FileIO.internalBinaryWrite(message.io, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.Namespace mnt_ns = 3; */
        if (message.mnt_ns)
            Namespace.internalBinaryWrite(message.mnt_ns, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.FileDigest digest = 4; */
        if (message.digest)
            FileDigest.internalBinaryWrite(message.digest, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tetragon.GenericFileArg
 */
export const GenericFileArg = new GenericFileArg$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RenameFileArg$Type extends MessageType<RenameFileArg> {
    constructor() {
        super("tetragon.RenameFileArg", [
            { no: 1, name: "src", kind: "message", T: () => FileDetails },
            { no: 2, name: "dst", kind: "message", T: () => FileDetails },
            { no: 3, name: "mnt_ns", kind: "message", localName: "mnt_ns", T: () => Namespace },
            { no: 4, name: "flags", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<RenameFileArg>): RenameFileArg {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.flags = [];
        if (value !== undefined)
            reflectionMergePartial<RenameFileArg>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RenameFileArg): RenameFileArg {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* tetragon.FileDetails src */ 1:
                    message.src = FileDetails.internalBinaryRead(reader, reader.uint32(), options, message.src);
                    break;
                case /* tetragon.FileDetails dst */ 2:
                    message.dst = FileDetails.internalBinaryRead(reader, reader.uint32(), options, message.dst);
                    break;
                case /* tetragon.Namespace mnt_ns */ 3:
                    message.mnt_ns = Namespace.internalBinaryRead(reader, reader.uint32(), options, message.mnt_ns);
                    break;
                case /* repeated string flags */ 4:
                    message.flags.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RenameFileArg, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* tetragon.FileDetails src = 1; */
        if (message.src)
            FileDetails.internalBinaryWrite(message.src, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.FileDetails dst = 2; */
        if (message.dst)
            FileDetails.internalBinaryWrite(message.dst, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.Namespace mnt_ns = 3; */
        if (message.mnt_ns)
            Namespace.internalBinaryWrite(message.mnt_ns, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* repeated string flags = 4; */
        for (let i = 0; i < message.flags.length; i++)
            writer.tag(4, WireType.LengthDelimited).string(message.flags[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tetragon.RenameFileArg
 */
export const RenameFileArg = new RenameFileArg$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReadDirArg$Type extends MessageType<ReadDirArg> {
    constructor() {
        super("tetragon.ReadDirArg", [
            { no: 1, name: "file", kind: "message", T: () => FileDetails },
            { no: 2, name: "mnt_ns", kind: "message", localName: "mnt_ns", T: () => Namespace }
        ]);
    }
    create(value?: PartialMessage<ReadDirArg>): ReadDirArg {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<ReadDirArg>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ReadDirArg): ReadDirArg {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* tetragon.FileDetails file */ 1:
                    message.file = FileDetails.internalBinaryRead(reader, reader.uint32(), options, message.file);
                    break;
                case /* tetragon.Namespace mnt_ns */ 2:
                    message.mnt_ns = Namespace.internalBinaryRead(reader, reader.uint32(), options, message.mnt_ns);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ReadDirArg, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* tetragon.FileDetails file = 1; */
        if (message.file)
            FileDetails.internalBinaryWrite(message.file, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.Namespace mnt_ns = 2; */
        if (message.mnt_ns)
            Namespace.internalBinaryWrite(message.mnt_ns, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tetragon.ReadDirArg
 */
export const ReadDirArg = new ReadDirArg$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AttrChange$Type extends MessageType<AttrChange> {
    constructor() {
        super("tetragon.AttrChange", [
            { no: 1, name: "new", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "old", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<AttrChange>): AttrChange {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.new = "";
        message.old = "";
        if (value !== undefined)
            reflectionMergePartial<AttrChange>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AttrChange): AttrChange {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string new */ 1:
                    message.new = reader.string();
                    break;
                case /* string old */ 2:
                    message.old = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AttrChange, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string new = 1; */
        if (message.new !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.new);
        /* string old = 2; */
        if (message.old !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.old);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tetragon.AttrChange
 */
export const AttrChange = new AttrChange$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FileAttr$Type extends MessageType<FileAttr> {
    constructor() {
        super("tetragon.FileAttr", [
            { no: 1, name: "permissions", kind: "message", T: () => AttrChange },
            { no: 2, name: "uid", kind: "message", T: () => AttrChange },
            { no: 3, name: "gid", kind: "message", T: () => AttrChange }
        ]);
    }
    create(value?: PartialMessage<FileAttr>): FileAttr {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<FileAttr>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FileAttr): FileAttr {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* tetragon.AttrChange permissions */ 1:
                    message.permissions = AttrChange.internalBinaryRead(reader, reader.uint32(), options, message.permissions);
                    break;
                case /* tetragon.AttrChange uid */ 2:
                    message.uid = AttrChange.internalBinaryRead(reader, reader.uint32(), options, message.uid);
                    break;
                case /* tetragon.AttrChange gid */ 3:
                    message.gid = AttrChange.internalBinaryRead(reader, reader.uint32(), options, message.gid);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FileAttr, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* tetragon.AttrChange permissions = 1; */
        if (message.permissions)
            AttrChange.internalBinaryWrite(message.permissions, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.AttrChange uid = 2; */
        if (message.uid)
            AttrChange.internalBinaryWrite(message.uid, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.AttrChange gid = 3; */
        if (message.gid)
            AttrChange.internalBinaryWrite(message.gid, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tetragon.FileAttr
 */
export const FileAttr = new FileAttr$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AttrArg$Type extends MessageType<AttrArg> {
    constructor() {
        super("tetragon.AttrArg", [
            { no: 1, name: "file", kind: "message", T: () => FileDetails },
            { no: 2, name: "attr", kind: "message", T: () => FileAttr },
            { no: 3, name: "mnt_ns", kind: "message", localName: "mnt_ns", T: () => Namespace }
        ]);
    }
    create(value?: PartialMessage<AttrArg>): AttrArg {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<AttrArg>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AttrArg): AttrArg {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* tetragon.FileDetails file */ 1:
                    message.file = FileDetails.internalBinaryRead(reader, reader.uint32(), options, message.file);
                    break;
                case /* tetragon.FileAttr attr */ 2:
                    message.attr = FileAttr.internalBinaryRead(reader, reader.uint32(), options, message.attr);
                    break;
                case /* tetragon.Namespace mnt_ns */ 3:
                    message.mnt_ns = Namespace.internalBinaryRead(reader, reader.uint32(), options, message.mnt_ns);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AttrArg, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* tetragon.FileDetails file = 1; */
        if (message.file)
            FileDetails.internalBinaryWrite(message.file, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.FileAttr attr = 2; */
        if (message.attr)
            FileAttr.internalBinaryWrite(message.attr, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.Namespace mnt_ns = 3; */
        if (message.mnt_ns)
            Namespace.internalBinaryWrite(message.mnt_ns, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tetragon.AttrArg
 */
export const AttrArg = new AttrArg$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FileArgument$Type extends MessageType<FileArgument> {
    constructor() {
        super("tetragon.FileArgument", [
            { no: 1, name: "generic_arg", kind: "message", localName: "generic_arg", oneof: "arg", T: () => GenericFileArg },
            { no: 2, name: "rename_arg", kind: "message", localName: "rename_arg", oneof: "arg", T: () => RenameFileArg },
            { no: 3, name: "readdir_arg", kind: "message", localName: "readdir_arg", oneof: "arg", T: () => ReadDirArg },
            { no: 4, name: "attr_arg", kind: "message", localName: "attr_arg", oneof: "arg", T: () => AttrArg }
        ]);
    }
    create(value?: PartialMessage<FileArgument>): FileArgument {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.arg = { oneofKind: undefined };
        if (value !== undefined)
            reflectionMergePartial<FileArgument>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FileArgument): FileArgument {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* tetragon.GenericFileArg generic_arg */ 1:
                    message.arg = {
                        oneofKind: "generic_arg",
                        generic_arg: GenericFileArg.internalBinaryRead(reader, reader.uint32(), options, (message.arg as any).generic_arg)
                    };
                    break;
                case /* tetragon.RenameFileArg rename_arg */ 2:
                    message.arg = {
                        oneofKind: "rename_arg",
                        rename_arg: RenameFileArg.internalBinaryRead(reader, reader.uint32(), options, (message.arg as any).rename_arg)
                    };
                    break;
                case /* tetragon.ReadDirArg readdir_arg */ 3:
                    message.arg = {
                        oneofKind: "readdir_arg",
                        readdir_arg: ReadDirArg.internalBinaryRead(reader, reader.uint32(), options, (message.arg as any).readdir_arg)
                    };
                    break;
                case /* tetragon.AttrArg attr_arg */ 4:
                    message.arg = {
                        oneofKind: "attr_arg",
                        attr_arg: AttrArg.internalBinaryRead(reader, reader.uint32(), options, (message.arg as any).attr_arg)
                    };
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FileArgument, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* tetragon.GenericFileArg generic_arg = 1; */
        if (message.arg.oneofKind === "generic_arg")
            GenericFileArg.internalBinaryWrite(message.arg.generic_arg, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.RenameFileArg rename_arg = 2; */
        if (message.arg.oneofKind === "rename_arg")
            RenameFileArg.internalBinaryWrite(message.arg.rename_arg, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.ReadDirArg readdir_arg = 3; */
        if (message.arg.oneofKind === "readdir_arg")
            ReadDirArg.internalBinaryWrite(message.arg.readdir_arg, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.AttrArg attr_arg = 4; */
        if (message.arg.oneofKind === "attr_arg")
            AttrArg.internalBinaryWrite(message.arg.attr_arg, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tetragon.FileArgument
 */
export const FileArgument = new FileArgument$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ProcessFile$Type extends MessageType<ProcessFile> {
    constructor() {
        super("tetragon.ProcessFile", [
            { no: 1, name: "process", kind: "message", T: () => Process },
            { no: 2, name: "parent", kind: "message", T: () => Process },
            { no: 3, name: "action", kind: "enum", T: () => ["tetragon.FileAction", FileAction] },
            { no: 4, name: "args", kind: "message", T: () => FileArgument },
            { no: 5, name: "permissions", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "uid", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "gid", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "time", kind: "message", T: () => Timestamp },
            { no: 9, name: "hook", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "operation", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["tetragon.FileOperation", FileOperation] }
        ]);
    }
    create(value?: PartialMessage<ProcessFile>): ProcessFile {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.action = 0;
        message.permissions = "";
        message.uid = "";
        message.gid = "";
        message.hook = "";
        message.operation = [];
        if (value !== undefined)
            reflectionMergePartial<ProcessFile>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ProcessFile): ProcessFile {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* tetragon.Process process */ 1:
                    message.process = Process.internalBinaryRead(reader, reader.uint32(), options, message.process);
                    break;
                case /* tetragon.Process parent */ 2:
                    message.parent = Process.internalBinaryRead(reader, reader.uint32(), options, message.parent);
                    break;
                case /* tetragon.FileAction action */ 3:
                    message.action = reader.int32();
                    break;
                case /* tetragon.FileArgument args */ 4:
                    message.args = FileArgument.internalBinaryRead(reader, reader.uint32(), options, message.args);
                    break;
                case /* string permissions */ 5:
                    message.permissions = reader.string();
                    break;
                case /* string uid */ 6:
                    message.uid = reader.string();
                    break;
                case /* string gid */ 7:
                    message.gid = reader.string();
                    break;
                case /* google.protobuf.Timestamp time */ 8:
                    message.time = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.time);
                    break;
                case /* string hook */ 9:
                    message.hook = reader.string();
                    break;
                case /* repeated tetragon.FileOperation operation */ 10:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.operation.push(reader.int32());
                    else
                        message.operation.push(reader.int32());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ProcessFile, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* tetragon.Process process = 1; */
        if (message.process)
            Process.internalBinaryWrite(message.process, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.Process parent = 2; */
        if (message.parent)
            Process.internalBinaryWrite(message.parent, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.FileAction action = 3; */
        if (message.action !== 0)
            writer.tag(3, WireType.Varint).int32(message.action);
        /* tetragon.FileArgument args = 4; */
        if (message.args)
            FileArgument.internalBinaryWrite(message.args, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* string permissions = 5; */
        if (message.permissions !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.permissions);
        /* string uid = 6; */
        if (message.uid !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.uid);
        /* string gid = 7; */
        if (message.gid !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.gid);
        /* google.protobuf.Timestamp time = 8; */
        if (message.time)
            Timestamp.internalBinaryWrite(message.time, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* string hook = 9; */
        if (message.hook !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.hook);
        /* repeated tetragon.FileOperation operation = 10; */
        if (message.operation.length) {
            writer.tag(10, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.operation.length; i++)
                writer.int32(message.operation[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tetragon.ProcessFile
 */
export const ProcessFile = new ProcessFile$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ProcessFileExec$Type extends MessageType<ProcessFileExec> {
    constructor() {
        super("tetragon.ProcessFileExec", [
            { no: 1, name: "process", kind: "message", T: () => Process },
            { no: 2, name: "parent", kind: "message", T: () => Process },
            { no: 3, name: "file", kind: "message", T: () => FileDetails },
            { no: 4, name: "digest", kind: "message", T: () => FileDigest },
            { no: 5, name: "operations", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["tetragon.FileOperation", FileOperation] }
        ]);
    }
    create(value?: PartialMessage<ProcessFileExec>): ProcessFileExec {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.operations = [];
        if (value !== undefined)
            reflectionMergePartial<ProcessFileExec>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ProcessFileExec): ProcessFileExec {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* tetragon.Process process */ 1:
                    message.process = Process.internalBinaryRead(reader, reader.uint32(), options, message.process);
                    break;
                case /* tetragon.Process parent */ 2:
                    message.parent = Process.internalBinaryRead(reader, reader.uint32(), options, message.parent);
                    break;
                case /* tetragon.FileDetails file */ 3:
                    message.file = FileDetails.internalBinaryRead(reader, reader.uint32(), options, message.file);
                    break;
                case /* tetragon.FileDigest digest */ 4:
                    message.digest = FileDigest.internalBinaryRead(reader, reader.uint32(), options, message.digest);
                    break;
                case /* repeated tetragon.FileOperation operations */ 5:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.operations.push(reader.int32());
                    else
                        message.operations.push(reader.int32());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ProcessFileExec, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* tetragon.Process process = 1; */
        if (message.process)
            Process.internalBinaryWrite(message.process, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.Process parent = 2; */
        if (message.parent)
            Process.internalBinaryWrite(message.parent, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.FileDetails file = 3; */
        if (message.file)
            FileDetails.internalBinaryWrite(message.file, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.FileDigest digest = 4; */
        if (message.digest)
            FileDigest.internalBinaryWrite(message.digest, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* repeated tetragon.FileOperation operations = 5; */
        if (message.operations.length) {
            writer.tag(5, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.operations.length; i++)
                writer.int32(message.operations[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tetragon.ProcessFileExec
 */
export const ProcessFileExec = new ProcessFileExec$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SockInfo$Type extends MessageType<SockInfo> {
    constructor() {
        super("tetragon.SockInfo", [
            { no: 1, name: "source_ip", kind: "scalar", localName: "source_ip", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "source_port", kind: "message", localName: "source_port", T: () => UInt32Value },
            { no: 3, name: "destination_ip", kind: "scalar", localName: "destination_ip", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "destination_port", kind: "message", localName: "destination_port", T: () => UInt32Value },
            { no: 5, name: "sock_cookie", kind: "scalar", localName: "sock_cookie", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 6, name: "protocol", kind: "enum", T: () => ["tetragon.SocketProtocol", SocketProtocol] },
            { no: 7, name: "destination_names", kind: "scalar", localName: "destination_names", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "destination_pod", kind: "message", localName: "destination_pod", T: () => Pod }
        ]);
    }
    create(value?: PartialMessage<SockInfo>): SockInfo {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.source_ip = "";
        message.destination_ip = "";
        message.sock_cookie = 0;
        message.protocol = 0;
        message.destination_names = [];
        if (value !== undefined)
            reflectionMergePartial<SockInfo>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SockInfo): SockInfo {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string source_ip */ 1:
                    message.source_ip = reader.string();
                    break;
                case /* google.protobuf.UInt32Value source_port */ 2:
                    message.source_port = UInt32Value.internalBinaryRead(reader, reader.uint32(), options, message.source_port);
                    break;
                case /* string destination_ip */ 3:
                    message.destination_ip = reader.string();
                    break;
                case /* google.protobuf.UInt32Value destination_port */ 4:
                    message.destination_port = UInt32Value.internalBinaryRead(reader, reader.uint32(), options, message.destination_port);
                    break;
                case /* uint64 sock_cookie */ 5:
                    message.sock_cookie = reader.uint64().toNumber();
                    break;
                case /* tetragon.SocketProtocol protocol */ 6:
                    message.protocol = reader.int32();
                    break;
                case /* repeated string destination_names */ 7:
                    message.destination_names.push(reader.string());
                    break;
                case /* tetragon.Pod destination_pod */ 8:
                    message.destination_pod = Pod.internalBinaryRead(reader, reader.uint32(), options, message.destination_pod);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SockInfo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string source_ip = 1; */
        if (message.source_ip !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.source_ip);
        /* google.protobuf.UInt32Value source_port = 2; */
        if (message.source_port)
            UInt32Value.internalBinaryWrite(message.source_port, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* string destination_ip = 3; */
        if (message.destination_ip !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.destination_ip);
        /* google.protobuf.UInt32Value destination_port = 4; */
        if (message.destination_port)
            UInt32Value.internalBinaryWrite(message.destination_port, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* uint64 sock_cookie = 5; */
        if (message.sock_cookie !== 0)
            writer.tag(5, WireType.Varint).uint64(message.sock_cookie);
        /* tetragon.SocketProtocol protocol = 6; */
        if (message.protocol !== 0)
            writer.tag(6, WireType.Varint).int32(message.protocol);
        /* repeated string destination_names = 7; */
        for (let i = 0; i < message.destination_names.length; i++)
            writer.tag(7, WireType.LengthDelimited).string(message.destination_names[i]);
        /* tetragon.Pod destination_pod = 8; */
        if (message.destination_pod)
            Pod.internalBinaryWrite(message.destination_pod, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tetragon.SockInfo
 */
export const SockInfo = new SockInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ProcessSockStats$Type extends MessageType<ProcessSockStats> {
    constructor() {
        super("tetragon.ProcessSockStats", [
            { no: 1, name: "process", kind: "message", T: () => Process },
            { no: 2, name: "parent", kind: "message", T: () => Process },
            { no: 3, name: "socket", kind: "message", T: () => SockInfo },
            { no: 4, name: "stats", kind: "message", T: () => SocketStats }
        ]);
    }
    create(value?: PartialMessage<ProcessSockStats>): ProcessSockStats {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<ProcessSockStats>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ProcessSockStats): ProcessSockStats {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* tetragon.Process process */ 1:
                    message.process = Process.internalBinaryRead(reader, reader.uint32(), options, message.process);
                    break;
                case /* tetragon.Process parent */ 2:
                    message.parent = Process.internalBinaryRead(reader, reader.uint32(), options, message.parent);
                    break;
                case /* tetragon.SockInfo socket */ 3:
                    message.socket = SockInfo.internalBinaryRead(reader, reader.uint32(), options, message.socket);
                    break;
                case /* tetragon.SocketStats stats */ 4:
                    message.stats = SocketStats.internalBinaryRead(reader, reader.uint32(), options, message.stats);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ProcessSockStats, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* tetragon.Process process = 1; */
        if (message.process)
            Process.internalBinaryWrite(message.process, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.Process parent = 2; */
        if (message.parent)
            Process.internalBinaryWrite(message.parent, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.SockInfo socket = 3; */
        if (message.socket)
            SockInfo.internalBinaryWrite(message.socket, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.SocketStats stats = 4; */
        if (message.stats)
            SocketStats.internalBinaryWrite(message.stats, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tetragon.ProcessSockStats
 */
export const ProcessSockStats = new ProcessSockStats$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Tls$Type extends MessageType<Tls> {
    constructor() {
        super("tetragon.Tls", [
            { no: 1, name: "process", kind: "message", T: () => Process },
            { no: 2, name: "source_ip", kind: "scalar", localName: "source_ip", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "source_port", kind: "message", localName: "source_port", T: () => UInt32Value },
            { no: 4, name: "destination_ip", kind: "scalar", localName: "destination_ip", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "destination_port", kind: "message", localName: "destination_port", T: () => UInt32Value },
            { no: 6, name: "negotiated_version", kind: "scalar", localName: "negotiated_version", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "supported_versions", kind: "scalar", localName: "supported_versions", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "sni_type", kind: "scalar", localName: "sni_type", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "sni_name", kind: "scalar", localName: "sni_name", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "cipher", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "client_flags", kind: "scalar", localName: "client_flags", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "server_flags", kind: "scalar", localName: "server_flags", T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "client_version", kind: "scalar", localName: "client_version", T: 9 /*ScalarType.STRING*/ },
            { no: 14, name: "server_version", kind: "scalar", localName: "server_version", T: 9 /*ScalarType.STRING*/ },
            { no: 15, name: "client_alert", kind: "scalar", localName: "client_alert", T: 9 /*ScalarType.STRING*/ },
            { no: 16, name: "server_alert", kind: "scalar", localName: "server_alert", T: 9 /*ScalarType.STRING*/ },
            { no: 17, name: "client_session", kind: "scalar", localName: "client_session", T: 9 /*ScalarType.STRING*/ },
            { no: 18, name: "server_session", kind: "scalar", localName: "server_session", T: 9 /*ScalarType.STRING*/ },
            { no: 19, name: "certificates", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 20, name: "certificate_error", kind: "enum", localName: "certificate_error", T: () => ["tetragon.TlsCertificateError", TlsCertificateError] },
            { no: 21, name: "parser_state_next", kind: "scalar", localName: "parser_state_next", T: 13 /*ScalarType.UINT32*/ },
            { no: 22, name: "parser_state_needed", kind: "scalar", localName: "parser_state_needed", T: 13 /*ScalarType.UINT32*/ },
            { no: 23, name: "parser_state_csize", kind: "scalar", localName: "parser_state_csize", T: 13 /*ScalarType.UINT32*/ },
            { no: 24, name: "parser_state_skblen", kind: "scalar", localName: "parser_state_skblen", T: 13 /*ScalarType.UINT32*/ },
            { no: 25, name: "parser_internal_state", kind: "scalar", localName: "parser_internal_state", T: 9 /*ScalarType.STRING*/ },
            { no: 26, name: "parent", kind: "message", T: () => Process }
        ]);
    }
    create(value?: PartialMessage<Tls>): Tls {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.source_ip = "";
        message.destination_ip = "";
        message.negotiated_version = "";
        message.supported_versions = "";
        message.sni_type = "";
        message.sni_name = "";
        message.cipher = "";
        message.client_flags = "";
        message.server_flags = "";
        message.client_version = "";
        message.server_version = "";
        message.client_alert = "";
        message.server_alert = "";
        message.client_session = "";
        message.server_session = "";
        message.certificates = [];
        message.certificate_error = 0;
        message.parser_state_next = 0;
        message.parser_state_needed = 0;
        message.parser_state_csize = 0;
        message.parser_state_skblen = 0;
        message.parser_internal_state = "";
        if (value !== undefined)
            reflectionMergePartial<Tls>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Tls): Tls {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* tetragon.Process process */ 1:
                    message.process = Process.internalBinaryRead(reader, reader.uint32(), options, message.process);
                    break;
                case /* string source_ip */ 2:
                    message.source_ip = reader.string();
                    break;
                case /* google.protobuf.UInt32Value source_port */ 3:
                    message.source_port = UInt32Value.internalBinaryRead(reader, reader.uint32(), options, message.source_port);
                    break;
                case /* string destination_ip */ 4:
                    message.destination_ip = reader.string();
                    break;
                case /* google.protobuf.UInt32Value destination_port */ 5:
                    message.destination_port = UInt32Value.internalBinaryRead(reader, reader.uint32(), options, message.destination_port);
                    break;
                case /* string negotiated_version */ 6:
                    message.negotiated_version = reader.string();
                    break;
                case /* string supported_versions */ 7:
                    message.supported_versions = reader.string();
                    break;
                case /* string sni_type */ 8:
                    message.sni_type = reader.string();
                    break;
                case /* string sni_name */ 9:
                    message.sni_name = reader.string();
                    break;
                case /* string cipher */ 10:
                    message.cipher = reader.string();
                    break;
                case /* string client_flags */ 11:
                    message.client_flags = reader.string();
                    break;
                case /* string server_flags */ 12:
                    message.server_flags = reader.string();
                    break;
                case /* string client_version */ 13:
                    message.client_version = reader.string();
                    break;
                case /* string server_version */ 14:
                    message.server_version = reader.string();
                    break;
                case /* string client_alert */ 15:
                    message.client_alert = reader.string();
                    break;
                case /* string server_alert */ 16:
                    message.server_alert = reader.string();
                    break;
                case /* string client_session */ 17:
                    message.client_session = reader.string();
                    break;
                case /* string server_session */ 18:
                    message.server_session = reader.string();
                    break;
                case /* repeated string certificates */ 19:
                    message.certificates.push(reader.string());
                    break;
                case /* tetragon.TlsCertificateError certificate_error */ 20:
                    message.certificate_error = reader.int32();
                    break;
                case /* uint32 parser_state_next = 21 [deprecated = true];*/ 21:
                    message.parser_state_next = reader.uint32();
                    break;
                case /* uint32 parser_state_needed = 22 [deprecated = true];*/ 22:
                    message.parser_state_needed = reader.uint32();
                    break;
                case /* uint32 parser_state_csize = 23 [deprecated = true];*/ 23:
                    message.parser_state_csize = reader.uint32();
                    break;
                case /* uint32 parser_state_skblen = 24 [deprecated = true];*/ 24:
                    message.parser_state_skblen = reader.uint32();
                    break;
                case /* string parser_internal_state */ 25:
                    message.parser_internal_state = reader.string();
                    break;
                case /* tetragon.Process parent */ 26:
                    message.parent = Process.internalBinaryRead(reader, reader.uint32(), options, message.parent);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Tls, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* tetragon.Process process = 1; */
        if (message.process)
            Process.internalBinaryWrite(message.process, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string source_ip = 2; */
        if (message.source_ip !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.source_ip);
        /* google.protobuf.UInt32Value source_port = 3; */
        if (message.source_port)
            UInt32Value.internalBinaryWrite(message.source_port, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* string destination_ip = 4; */
        if (message.destination_ip !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.destination_ip);
        /* google.protobuf.UInt32Value destination_port = 5; */
        if (message.destination_port)
            UInt32Value.internalBinaryWrite(message.destination_port, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* string negotiated_version = 6; */
        if (message.negotiated_version !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.negotiated_version);
        /* string supported_versions = 7; */
        if (message.supported_versions !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.supported_versions);
        /* string sni_type = 8; */
        if (message.sni_type !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.sni_type);
        /* string sni_name = 9; */
        if (message.sni_name !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.sni_name);
        /* string cipher = 10; */
        if (message.cipher !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.cipher);
        /* string client_flags = 11; */
        if (message.client_flags !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.client_flags);
        /* string server_flags = 12; */
        if (message.server_flags !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.server_flags);
        /* string client_version = 13; */
        if (message.client_version !== "")
            writer.tag(13, WireType.LengthDelimited).string(message.client_version);
        /* string server_version = 14; */
        if (message.server_version !== "")
            writer.tag(14, WireType.LengthDelimited).string(message.server_version);
        /* string client_alert = 15; */
        if (message.client_alert !== "")
            writer.tag(15, WireType.LengthDelimited).string(message.client_alert);
        /* string server_alert = 16; */
        if (message.server_alert !== "")
            writer.tag(16, WireType.LengthDelimited).string(message.server_alert);
        /* string client_session = 17; */
        if (message.client_session !== "")
            writer.tag(17, WireType.LengthDelimited).string(message.client_session);
        /* string server_session = 18; */
        if (message.server_session !== "")
            writer.tag(18, WireType.LengthDelimited).string(message.server_session);
        /* repeated string certificates = 19; */
        for (let i = 0; i < message.certificates.length; i++)
            writer.tag(19, WireType.LengthDelimited).string(message.certificates[i]);
        /* tetragon.TlsCertificateError certificate_error = 20; */
        if (message.certificate_error !== 0)
            writer.tag(20, WireType.Varint).int32(message.certificate_error);
        /* uint32 parser_state_next = 21 [deprecated = true]; */
        if (message.parser_state_next !== 0)
            writer.tag(21, WireType.Varint).uint32(message.parser_state_next);
        /* uint32 parser_state_needed = 22 [deprecated = true]; */
        if (message.parser_state_needed !== 0)
            writer.tag(22, WireType.Varint).uint32(message.parser_state_needed);
        /* uint32 parser_state_csize = 23 [deprecated = true]; */
        if (message.parser_state_csize !== 0)
            writer.tag(23, WireType.Varint).uint32(message.parser_state_csize);
        /* uint32 parser_state_skblen = 24 [deprecated = true]; */
        if (message.parser_state_skblen !== 0)
            writer.tag(24, WireType.Varint).uint32(message.parser_state_skblen);
        /* string parser_internal_state = 25; */
        if (message.parser_internal_state !== "")
            writer.tag(25, WireType.LengthDelimited).string(message.parser_internal_state);
        /* tetragon.Process parent = 26; */
        if (message.parent)
            Process.internalBinaryWrite(message.parent, writer.tag(26, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tetragon.Tls
 */
export const Tls = new Tls$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HttpHeader$Type extends MessageType<HttpHeader> {
    constructor() {
        super("tetragon.HttpHeader", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "value", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<HttpHeader>): HttpHeader {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.name = "";
        message.value = "";
        if (value !== undefined)
            reflectionMergePartial<HttpHeader>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: HttpHeader): HttpHeader {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* string value */ 2:
                    message.value = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: HttpHeader, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* string value = 2; */
        if (message.value !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.value);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tetragon.HttpHeader
 */
export const HttpHeader = new HttpHeader$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HttpRequest$Type extends MessageType<HttpRequest> {
    constructor() {
        super("tetragon.HttpRequest", [
            { no: 1, name: "timestamp", kind: "message", T: () => Timestamp },
            { no: 2, name: "method", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "uri", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "version", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "host", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "agent", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "content_length", kind: "message", localName: "content_length", T: () => UInt32Value },
            { no: 8, name: "headers", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => HttpHeader },
            { no: 9, name: "flags", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "transfer_encoding", kind: "scalar", localName: "transfer_encoding", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<HttpRequest>): HttpRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.method = "";
        message.uri = "";
        message.version = "";
        message.host = "";
        message.agent = "";
        message.headers = [];
        message.flags = "";
        message.transfer_encoding = "";
        if (value !== undefined)
            reflectionMergePartial<HttpRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: HttpRequest): HttpRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* google.protobuf.Timestamp timestamp */ 1:
                    message.timestamp = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.timestamp);
                    break;
                case /* string method */ 2:
                    message.method = reader.string();
                    break;
                case /* string uri */ 3:
                    message.uri = reader.string();
                    break;
                case /* string version */ 4:
                    message.version = reader.string();
                    break;
                case /* string host */ 5:
                    message.host = reader.string();
                    break;
                case /* string agent */ 6:
                    message.agent = reader.string();
                    break;
                case /* google.protobuf.UInt32Value content_length */ 7:
                    message.content_length = UInt32Value.internalBinaryRead(reader, reader.uint32(), options, message.content_length);
                    break;
                case /* repeated tetragon.HttpHeader headers */ 8:
                    message.headers.push(HttpHeader.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string flags */ 9:
                    message.flags = reader.string();
                    break;
                case /* string transfer_encoding */ 10:
                    message.transfer_encoding = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: HttpRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* google.protobuf.Timestamp timestamp = 1; */
        if (message.timestamp)
            Timestamp.internalBinaryWrite(message.timestamp, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string method = 2; */
        if (message.method !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.method);
        /* string uri = 3; */
        if (message.uri !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.uri);
        /* string version = 4; */
        if (message.version !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.version);
        /* string host = 5; */
        if (message.host !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.host);
        /* string agent = 6; */
        if (message.agent !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.agent);
        /* google.protobuf.UInt32Value content_length = 7; */
        if (message.content_length)
            UInt32Value.internalBinaryWrite(message.content_length, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* repeated tetragon.HttpHeader headers = 8; */
        for (let i = 0; i < message.headers.length; i++)
            HttpHeader.internalBinaryWrite(message.headers[i], writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* string flags = 9; */
        if (message.flags !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.flags);
        /* string transfer_encoding = 10; */
        if (message.transfer_encoding !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.transfer_encoding);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tetragon.HttpRequest
 */
export const HttpRequest = new HttpRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HttpResponse$Type extends MessageType<HttpResponse> {
    constructor() {
        super("tetragon.HttpResponse", [
            { no: 1, name: "timestamp", kind: "message", T: () => Timestamp },
            { no: 2, name: "version", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "code", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 4, name: "reason", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "content_length", kind: "message", localName: "content_length", T: () => UInt32Value },
            { no: 6, name: "headers", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => HttpHeader },
            { no: 7, name: "flags", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "transfer_encoding", kind: "scalar", localName: "transfer_encoding", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<HttpResponse>): HttpResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.version = "";
        message.code = 0;
        message.reason = "";
        message.headers = [];
        message.flags = "";
        message.transfer_encoding = "";
        if (value !== undefined)
            reflectionMergePartial<HttpResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: HttpResponse): HttpResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* google.protobuf.Timestamp timestamp */ 1:
                    message.timestamp = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.timestamp);
                    break;
                case /* string version */ 2:
                    message.version = reader.string();
                    break;
                case /* uint32 code */ 3:
                    message.code = reader.uint32();
                    break;
                case /* string reason */ 4:
                    message.reason = reader.string();
                    break;
                case /* google.protobuf.UInt32Value content_length */ 5:
                    message.content_length = UInt32Value.internalBinaryRead(reader, reader.uint32(), options, message.content_length);
                    break;
                case /* repeated tetragon.HttpHeader headers */ 6:
                    message.headers.push(HttpHeader.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string flags */ 7:
                    message.flags = reader.string();
                    break;
                case /* string transfer_encoding */ 8:
                    message.transfer_encoding = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: HttpResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* google.protobuf.Timestamp timestamp = 1; */
        if (message.timestamp)
            Timestamp.internalBinaryWrite(message.timestamp, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string version = 2; */
        if (message.version !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.version);
        /* uint32 code = 3; */
        if (message.code !== 0)
            writer.tag(3, WireType.Varint).uint32(message.code);
        /* string reason = 4; */
        if (message.reason !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.reason);
        /* google.protobuf.UInt32Value content_length = 5; */
        if (message.content_length)
            UInt32Value.internalBinaryWrite(message.content_length, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* repeated tetragon.HttpHeader headers = 6; */
        for (let i = 0; i < message.headers.length; i++)
            HttpHeader.internalBinaryWrite(message.headers[i], writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* string flags = 7; */
        if (message.flags !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.flags);
        /* string transfer_encoding = 8; */
        if (message.transfer_encoding !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.transfer_encoding);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tetragon.HttpResponse
 */
export const HttpResponse = new HttpResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HttpInfo$Type extends MessageType<HttpInfo> {
    constructor() {
        super("tetragon.HttpInfo", [
            { no: 1, name: "request", kind: "message", T: () => HttpRequest },
            { no: 2, name: "response", kind: "message", T: () => HttpResponse },
            { no: 3, name: "latency", kind: "message", T: () => Duration }
        ]);
    }
    create(value?: PartialMessage<HttpInfo>): HttpInfo {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<HttpInfo>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: HttpInfo): HttpInfo {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* tetragon.HttpRequest request */ 1:
                    message.request = HttpRequest.internalBinaryRead(reader, reader.uint32(), options, message.request);
                    break;
                case /* tetragon.HttpResponse response */ 2:
                    message.response = HttpResponse.internalBinaryRead(reader, reader.uint32(), options, message.response);
                    break;
                case /* google.protobuf.Duration latency */ 3:
                    message.latency = Duration.internalBinaryRead(reader, reader.uint32(), options, message.latency);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: HttpInfo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* tetragon.HttpRequest request = 1; */
        if (message.request)
            HttpRequest.internalBinaryWrite(message.request, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.HttpResponse response = 2; */
        if (message.response)
            HttpResponse.internalBinaryWrite(message.response, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Duration latency = 3; */
        if (message.latency)
            Duration.internalBinaryWrite(message.latency, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tetragon.HttpInfo
 */
export const HttpInfo = new HttpInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ProcessHttp$Type extends MessageType<ProcessHttp> {
    constructor() {
        super("tetragon.ProcessHttp", [
            { no: 1, name: "process", kind: "message", T: () => Process },
            { no: 2, name: "socket", kind: "message", T: () => SockInfo },
            { no: 3, name: "http", kind: "message", T: () => HttpInfo },
            { no: 4, name: "destination_names", kind: "scalar", localName: "destination_names", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "destination_pod", kind: "message", localName: "destination_pod", T: () => Pod },
            { no: 6, name: "parent", kind: "message", T: () => Process }
        ]);
    }
    create(value?: PartialMessage<ProcessHttp>): ProcessHttp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.destination_names = [];
        if (value !== undefined)
            reflectionMergePartial<ProcessHttp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ProcessHttp): ProcessHttp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* tetragon.Process process */ 1:
                    message.process = Process.internalBinaryRead(reader, reader.uint32(), options, message.process);
                    break;
                case /* tetragon.SockInfo socket */ 2:
                    message.socket = SockInfo.internalBinaryRead(reader, reader.uint32(), options, message.socket);
                    break;
                case /* tetragon.HttpInfo http */ 3:
                    message.http = HttpInfo.internalBinaryRead(reader, reader.uint32(), options, message.http);
                    break;
                case /* repeated string destination_names = 4 [deprecated = true];*/ 4:
                    message.destination_names.push(reader.string());
                    break;
                case /* tetragon.Pod destination_pod = 5 [deprecated = true];*/ 5:
                    message.destination_pod = Pod.internalBinaryRead(reader, reader.uint32(), options, message.destination_pod);
                    break;
                case /* tetragon.Process parent */ 6:
                    message.parent = Process.internalBinaryRead(reader, reader.uint32(), options, message.parent);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ProcessHttp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* tetragon.Process process = 1; */
        if (message.process)
            Process.internalBinaryWrite(message.process, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.SockInfo socket = 2; */
        if (message.socket)
            SockInfo.internalBinaryWrite(message.socket, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.HttpInfo http = 3; */
        if (message.http)
            HttpInfo.internalBinaryWrite(message.http, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* repeated string destination_names = 4 [deprecated = true]; */
        for (let i = 0; i < message.destination_names.length; i++)
            writer.tag(4, WireType.LengthDelimited).string(message.destination_names[i]);
        /* tetragon.Pod destination_pod = 5 [deprecated = true]; */
        if (message.destination_pod)
            Pod.internalBinaryWrite(message.destination_pod, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.Process parent = 6; */
        if (message.parent)
            Process.internalBinaryWrite(message.parent, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tetragon.ProcessHttp
 */
export const ProcessHttp = new ProcessHttp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ProcessNetworkBurst$Type extends MessageType<ProcessNetworkBurst> {
    constructor() {
        super("tetragon.ProcessNetworkBurst", [
            { no: 1, name: "process", kind: "message", T: () => Process },
            { no: 2, name: "parent", kind: "message", T: () => Process },
            { no: 3, name: "protocol", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "direction", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "burst_state", kind: "scalar", localName: "burst_state", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "window_size", kind: "scalar", localName: "window_size", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 7, name: "hist_avg", kind: "scalar", localName: "hist_avg", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 8, name: "hist_trigger", kind: "scalar", localName: "hist_trigger", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 9, name: "window_avg", kind: "scalar", localName: "window_avg", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<ProcessNetworkBurst>): ProcessNetworkBurst {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.protocol = "";
        message.direction = "";
        message.burst_state = "";
        message.window_size = 0;
        message.hist_avg = 0;
        message.hist_trigger = 0;
        message.window_avg = 0;
        if (value !== undefined)
            reflectionMergePartial<ProcessNetworkBurst>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ProcessNetworkBurst): ProcessNetworkBurst {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* tetragon.Process process */ 1:
                    message.process = Process.internalBinaryRead(reader, reader.uint32(), options, message.process);
                    break;
                case /* tetragon.Process parent */ 2:
                    message.parent = Process.internalBinaryRead(reader, reader.uint32(), options, message.parent);
                    break;
                case /* string protocol */ 3:
                    message.protocol = reader.string();
                    break;
                case /* string direction */ 4:
                    message.direction = reader.string();
                    break;
                case /* string burst_state */ 5:
                    message.burst_state = reader.string();
                    break;
                case /* uint64 window_size */ 6:
                    message.window_size = reader.uint64().toNumber();
                    break;
                case /* uint64 hist_avg */ 7:
                    message.hist_avg = reader.uint64().toNumber();
                    break;
                case /* uint64 hist_trigger */ 8:
                    message.hist_trigger = reader.uint64().toNumber();
                    break;
                case /* uint64 window_avg */ 9:
                    message.window_avg = reader.uint64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ProcessNetworkBurst, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* tetragon.Process process = 1; */
        if (message.process)
            Process.internalBinaryWrite(message.process, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.Process parent = 2; */
        if (message.parent)
            Process.internalBinaryWrite(message.parent, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* string protocol = 3; */
        if (message.protocol !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.protocol);
        /* string direction = 4; */
        if (message.direction !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.direction);
        /* string burst_state = 5; */
        if (message.burst_state !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.burst_state);
        /* uint64 window_size = 6; */
        if (message.window_size !== 0)
            writer.tag(6, WireType.Varint).uint64(message.window_size);
        /* uint64 hist_avg = 7; */
        if (message.hist_avg !== 0)
            writer.tag(7, WireType.Varint).uint64(message.hist_avg);
        /* uint64 hist_trigger = 8; */
        if (message.hist_trigger !== 0)
            writer.tag(8, WireType.Varint).uint64(message.hist_trigger);
        /* uint64 window_avg = 9; */
        if (message.window_avg !== 0)
            writer.tag(9, WireType.Varint).uint64(message.window_avg);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tetragon.ProcessNetworkBurst
 */
export const ProcessNetworkBurst = new ProcessNetworkBurst$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ProcessNetworkWatermark$Type extends MessageType<ProcessNetworkWatermark> {
    constructor() {
        super("tetragon.ProcessNetworkWatermark", [
            { no: 1, name: "process", kind: "message", T: () => Process },
            { no: 2, name: "parent", kind: "message", T: () => Process },
            { no: 3, name: "protocol", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "direction", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "watermarks_state", kind: "scalar", localName: "watermarks_state", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "watermarks_type", kind: "scalar", localName: "watermarks_type", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "window_size", kind: "scalar", localName: "window_size", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 8, name: "hist_avg", kind: "scalar", localName: "hist_avg", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 9, name: "hist_burst_trigger", kind: "scalar", localName: "hist_burst_trigger", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 10, name: "hist_dip_trigger", kind: "scalar", localName: "hist_dip_trigger", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 11, name: "window_avg", kind: "scalar", localName: "window_avg", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<ProcessNetworkWatermark>): ProcessNetworkWatermark {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.protocol = "";
        message.direction = "";
        message.watermarks_state = "";
        message.watermarks_type = "";
        message.window_size = 0;
        message.hist_avg = 0;
        message.hist_burst_trigger = 0;
        message.hist_dip_trigger = 0;
        message.window_avg = 0;
        if (value !== undefined)
            reflectionMergePartial<ProcessNetworkWatermark>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ProcessNetworkWatermark): ProcessNetworkWatermark {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* tetragon.Process process */ 1:
                    message.process = Process.internalBinaryRead(reader, reader.uint32(), options, message.process);
                    break;
                case /* tetragon.Process parent */ 2:
                    message.parent = Process.internalBinaryRead(reader, reader.uint32(), options, message.parent);
                    break;
                case /* string protocol */ 3:
                    message.protocol = reader.string();
                    break;
                case /* string direction */ 4:
                    message.direction = reader.string();
                    break;
                case /* string watermarks_state */ 5:
                    message.watermarks_state = reader.string();
                    break;
                case /* string watermarks_type */ 6:
                    message.watermarks_type = reader.string();
                    break;
                case /* uint64 window_size */ 7:
                    message.window_size = reader.uint64().toNumber();
                    break;
                case /* uint64 hist_avg */ 8:
                    message.hist_avg = reader.uint64().toNumber();
                    break;
                case /* uint64 hist_burst_trigger */ 9:
                    message.hist_burst_trigger = reader.uint64().toNumber();
                    break;
                case /* uint64 hist_dip_trigger */ 10:
                    message.hist_dip_trigger = reader.uint64().toNumber();
                    break;
                case /* uint64 window_avg */ 11:
                    message.window_avg = reader.uint64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ProcessNetworkWatermark, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* tetragon.Process process = 1; */
        if (message.process)
            Process.internalBinaryWrite(message.process, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.Process parent = 2; */
        if (message.parent)
            Process.internalBinaryWrite(message.parent, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* string protocol = 3; */
        if (message.protocol !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.protocol);
        /* string direction = 4; */
        if (message.direction !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.direction);
        /* string watermarks_state = 5; */
        if (message.watermarks_state !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.watermarks_state);
        /* string watermarks_type = 6; */
        if (message.watermarks_type !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.watermarks_type);
        /* uint64 window_size = 7; */
        if (message.window_size !== 0)
            writer.tag(7, WireType.Varint).uint64(message.window_size);
        /* uint64 hist_avg = 8; */
        if (message.hist_avg !== 0)
            writer.tag(8, WireType.Varint).uint64(message.hist_avg);
        /* uint64 hist_burst_trigger = 9; */
        if (message.hist_burst_trigger !== 0)
            writer.tag(9, WireType.Varint).uint64(message.hist_burst_trigger);
        /* uint64 hist_dip_trigger = 10; */
        if (message.hist_dip_trigger !== 0)
            writer.tag(10, WireType.Varint).uint64(message.hist_dip_trigger);
        /* uint64 window_avg = 11; */
        if (message.window_avg !== 0)
            writer.tag(11, WireType.Varint).uint64(message.window_avg);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tetragon.ProcessNetworkWatermark
 */
export const ProcessNetworkWatermark = new ProcessNetworkWatermark$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ProcessUdpSeqCheckError$Type extends MessageType<ProcessUdpSeqCheckError> {
    constructor() {
        super("tetragon.ProcessUdpSeqCheckError", [
            { no: 1, name: "process", kind: "message", T: () => Process },
            { no: 2, name: "parent", kind: "message", T: () => Process },
            { no: 3, name: "socket", kind: "message", T: () => SockInfo },
            { no: 4, name: "application_id", kind: "scalar", localName: "application_id", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 5, name: "app_specific_id", kind: "scalar", localName: "app_specific_id", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 6, name: "seq_num_expected", kind: "scalar", localName: "seq_num_expected", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 7, name: "seq_num_received", kind: "scalar", localName: "seq_num_received", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<ProcessUdpSeqCheckError>): ProcessUdpSeqCheckError {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.application_id = 0;
        message.app_specific_id = 0;
        message.seq_num_expected = 0;
        message.seq_num_received = 0;
        if (value !== undefined)
            reflectionMergePartial<ProcessUdpSeqCheckError>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ProcessUdpSeqCheckError): ProcessUdpSeqCheckError {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* tetragon.Process process */ 1:
                    message.process = Process.internalBinaryRead(reader, reader.uint32(), options, message.process);
                    break;
                case /* tetragon.Process parent */ 2:
                    message.parent = Process.internalBinaryRead(reader, reader.uint32(), options, message.parent);
                    break;
                case /* tetragon.SockInfo socket */ 3:
                    message.socket = SockInfo.internalBinaryRead(reader, reader.uint32(), options, message.socket);
                    break;
                case /* uint64 application_id */ 4:
                    message.application_id = reader.uint64().toNumber();
                    break;
                case /* uint64 app_specific_id */ 5:
                    message.app_specific_id = reader.uint64().toNumber();
                    break;
                case /* uint64 seq_num_expected */ 6:
                    message.seq_num_expected = reader.uint64().toNumber();
                    break;
                case /* uint64 seq_num_received */ 7:
                    message.seq_num_received = reader.uint64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ProcessUdpSeqCheckError, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* tetragon.Process process = 1; */
        if (message.process)
            Process.internalBinaryWrite(message.process, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.Process parent = 2; */
        if (message.parent)
            Process.internalBinaryWrite(message.parent, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.SockInfo socket = 3; */
        if (message.socket)
            SockInfo.internalBinaryWrite(message.socket, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* uint64 application_id = 4; */
        if (message.application_id !== 0)
            writer.tag(4, WireType.Varint).uint64(message.application_id);
        /* uint64 app_specific_id = 5; */
        if (message.app_specific_id !== 0)
            writer.tag(5, WireType.Varint).uint64(message.app_specific_id);
        /* uint64 seq_num_expected = 6; */
        if (message.seq_num_expected !== 0)
            writer.tag(6, WireType.Varint).uint64(message.seq_num_expected);
        /* uint64 seq_num_received = 7; */
        if (message.seq_num_received !== 0)
            writer.tag(7, WireType.Varint).uint64(message.seq_num_received);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tetragon.ProcessUdpSeqCheckError
 */
export const ProcessUdpSeqCheckError = new ProcessUdpSeqCheckError$Type();
