import * as mobx from 'mobx';

import { DashboardVariant } from '~/components/Dashboard/types';

export class FeatureFlags {
  public timescape: GenericFeature = {
    enabled: false,
  };

  public timescapeOnly: GenericFeature = {
    enabled: false,
  };

  public serviceMapFileUpload: GenericFeature = { enabled: false };
  public grafana: Grafana = { enabled: false };
  public tetragon: TetragonFeature = { only: false };

  public static default(): FeatureFlags {
    return new FeatureFlags();
  }

  constructor() {
    mobx.makeAutoObservable(this);
  }

  public setTimescapeEnabled(enabled: boolean): this {
    this.timescape.enabled = enabled;
    return this;
  }

  public setTimescapeOnlyEnabled(enabled: boolean): this {
    this.timescapeOnly.enabled = enabled;
    return this;
  }

  public setServiceMapUploadEnabled(enabled: boolean): this {
    this.serviceMapFileUpload.enabled = enabled;
    return this;
  }

  public setGrafana(
    enabled: boolean,
    enabledNodesGraph: boolean,
    dashboards: GrafanaDashboards | null,
  ): this {
    this.grafana.enabled = enabled;
    if (this.grafana.enabled) {
      if (!dashboards) {
        throw new Error('Grafana dashboard should be provided');
      }
      this.grafana.enabledNodesGraph = enabledNodesGraph;
      this.grafana.dashboards = dashboards;
    }

    return this;
  }

  public setTetragon(only: boolean): this {
    this.tetragon.only = only;

    return this;
  }
}

// NOTE: A simple feature that has no other options except `enabled` flag
export type GenericFeature = {
  enabled: boolean;
};

export type TetragonFeature = {
  only: boolean;
};

export type Grafana =
  | { enabled: false }
  | {
      enabled: true;
      enabledNodesGraph: boolean;
      dashboards: GrafanaDashboards;
    };

export enum GrafanaDashboardKind {
  Cluster = 'cluster',
  Namespace = 'namespace',
  Service = 'service',
}

export interface GrafanaDashboards {
  [DashboardVariant.Overview]: GrafanaDashboardDescription;
  [DashboardVariant.Network]: GrafanaDashboardDescription;
  [DashboardVariant.NetworkServiceMapWorkload]: GrafanaDashboardDescription;
  [DashboardVariant.NetworServiceMapNodeGraph]: GrafanaDashboardDescription;
  [DashboardVariant.Runtime]: GrafanaDashboardDescription;
}

export interface GrafanaDashboardDescription {
  dashboards: GrafanaDashboardsEntry[];
}

export interface GrafanaDashboardsEntry {
  title: string;
  dashboard: GrafanaDashboardEntry;
}

export interface GrafanaDashboardEntry {
  uid: string;
}
