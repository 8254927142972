import React from 'react';

import { Icon } from '@blueprintjs/core';
import classnames from 'classnames';
import { motion } from 'framer-motion';
import { observer } from 'mobx-react';

import { Card, Props as CardProps } from '~/components/Card';
import { ClusterSelectorDropdown } from '~/components/NavigationSidebar/ClusterSelectorDropdown';
import { NamespaceSelectorDropdown } from '~/components/NavigationSidebar/NamespaceSelectorDropdown';

import css from './styles.scss';
import * as helpers from '../ServiceMapArrowsRenderer/helpers';

export enum E2E {
  containerCard = `container-card`,
  breadcrumbItem = `breadcrumbs-item`,
}

interface Props extends CardProps {
  selectedCluster: string;
  loading?: boolean;
  onReturnClusterMapClick: () => void;
  onReturnNamespaceMapClick: () => void;
  onReturnServiceMapClick: () => void;
  onClusterChange: (clusterId: string | null) => void;
  onNamespaceChange: (namespaceId: string | null) => void;
  onServiceChange: (serviceId: string) => void;
  selectedNamespace?: string | null;
  selectedService?: string | null;
  clustersList?: string[];
  namespacesList?: string[];
  servicesList?: { cardId: string; serviceName: string }[];
  empty?: boolean;
}

export const ContainerHeader = observer(function ContainerHeader({
  loading,
  selectedCluster,
  onReturnClusterMapClick,
  onReturnNamespaceMapClick,
  selectedNamespace,
  selectedService,
  onReturnServiceMapClick,
  onClusterChange,
  empty,
  ...props
}: Props) {
  const attrs = { ...props, coords: { ...(props.coords || { x: 0, y: 0, w: 0, h: 0 }), h: 100 } };
  const clusterElement = selectedNamespace ? (
    <a
      className={css.link}
      onClick={e => {
        e.preventDefault();
        onReturnNamespaceMapClick();
      }}
      data-testid={E2E.breadcrumbItem}
    >
      {selectedCluster}
    </a>
  ) : (
    <ClusterSelectorDropdown
      clusters={props.clustersList ?? []}
      cluster={selectedCluster ?? null}
      onChange={onClusterChange}
      placeholderText={'Choose cluster'}
      large={true}
    />
  );

  const namespaceElement = selectedService ? (
    <a
      className={css.link}
      onClick={e => {
        e.preventDefault();
        onReturnServiceMapClick();
      }}
      data-testid={E2E.breadcrumbItem}
    >
      {selectedNamespace}
    </a>
  ) : (
    <NamespaceSelectorDropdown
      namespacesList={props.namespacesList ?? []}
      currNamespace={selectedNamespace ?? null}
      onChange={props.onNamespaceChange}
      placeholderText={'Choose namespace'}
      large={true}
    />
  );

  const content = (
    <motion.div
      initial={{ opacity: 0, scale: 1 }}
      animate={{ opacity: 1, scale: [0.5, 1] }}
      transition={{
        delay: helpers.animationDelay.cardDelay + 0.1,
        duration: helpers.animationDelay.cardAnimation + 0.1,
      }}
      exit={{ opacity: 0, scale: 0.5 }}
      className={classnames(css.breadcrumbs, css.transparent)}
      data-testid={E2E.containerCard}
    >
      <a
        className={css.link}
        onClick={e => {
          e.preventDefault();
          onReturnClusterMapClick();
        }}
        data-testid={E2E.breadcrumbItem}
      >
        Cluster map
      </a>
      <Icon icon="caret-right" size={20} />
      <div className={css.cluster}>
        <img src="/icons/misc/cluster_icon.svg" className={css.icon} />
        {clusterElement}
      </div>
      {selectedNamespace && (
        <>
          <Icon icon="caret-right" size={20} />
          <div className={css.cluster}>
            <img src="/icons/misc/map-namespace-icon.svg" className={css.icon} />
            {namespaceElement}
          </div>
        </>
      )}
      {selectedService && (
        <>
          <Icon icon="caret-right" size={20} />
          <div className={css.cluster}>
            <img src="/icons/misc/map-service-icon.svg" className={css.icon} />
            <NamespaceSelectorDropdown
              namespacesList={props.servicesList?.map(service => service.serviceName) ?? []}
              currNamespace={selectedService ?? null}
              onChange={(serviceName: string | null) => {
                if (serviceName) {
                  const { cardId } = props.servicesList?.find(
                    service => service.serviceName === serviceName,
                  ) ?? { cardId: null };
                  if (cardId) {
                    props.onServiceChange(cardId);
                  }
                }
              }}
              placeholderText={'Choose service'}
              large={true}
            />
          </div>
        </>
      )}
    </motion.div>
  );
  return (
    <Card {...attrs} isUnsizedMode={true} className={css.cardHeader}>
      {content}
    </Card>
  );
});
