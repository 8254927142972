import { makeAutoObservable } from 'mobx';

import { Flow } from '~/domain/flows';
import { Kind, PodWithoutContainer, ProcessEvent } from '~/domain/process-events';

import { Processes } from './processes';
import { IPEndpoint, PartialConnections } from '../interactions/new-connections';

export class PodNode {
  public pod: PodWithoutContainer;
  public processes: Processes;

  public flows: Flow[] = [];

  public static new(pod: PodWithoutContainer) {
    return new PodNode(pod);
  }

  constructor(pod: PodWithoutContainer) {
    this.pod = pod;
    this.processes = Processes.empty();

    makeAutoObservable(this);
  }

  public saveEvent(evt: ProcessEvent): boolean {
    return this.processes.saveEvent(evt);
  }

  public saveFlow(flow: Flow): boolean {
    this.flows.push(flow);

    return true;
  }

  public get name(): string {
    return this.pod.name;
  }

  public get namespace(): string {
    return this.pod.namespace;
  }

  public get hasNoProcessEvents(): boolean {
    return this.processes.events.size === 0;
  }

  // NOTE: { senderExecId -> { ipAddr -> IPEndpoint }}
  public get connections(): PartialConnections<IPEndpoint> {
    const conns = new PartialConnections<IPEndpoint>();

    this.processes.events.forEach((evts, execId) => {
      const pidConnectEvents = evts.get(Kind.Connect);
      if (pidConnectEvents == null) return;

      pidConnectEvents.forEach(evt => {
        const connectEvt = evt.process_connect;
        if (connectEvt == null) return;

        const { destinationIp, destinationPort } = connectEvt;

        if (!conns.has(execId, destinationIp)) {
          conns.upsert(execId, destinationIp, new IPEndpoint(destinationIp));
        }

        const ipEndpoint = conns.get(execId)?.get(destinationIp);
        ipEndpoint?.addPort(destinationPort);
      });
    });

    return conns;
  }

  // NOTE: { destinationIpAddress -> Flow[] }
  public get flowsToIp(): Map<string, Flow[]> {
    const m: Map<string, Flow[]> = new Map();

    this.flows.forEach(f => {
      const { destinationIp } = f;
      if (!destinationIp) return;

      if (!m.has(destinationIp)) m.set(destinationIp, []);

      m.get(destinationIp)?.push(f);
    });

    return m;
  }
}
