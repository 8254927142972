import React from 'react';

import classNames from 'classnames';
import _ from 'lodash';
import { observer } from 'mobx-react';

import { ProcessEvent } from '~/domain/process-events';

import css from './styles.scss';

export interface Props {
  processEvent?: ProcessEvent;
  isScrolling?: boolean;
  style?: React.CSSProperties;
  isHeader?: boolean;
  className?: string;

  onClick?: () => void;
}

export const ProcessEventRow = observer(function ProcessEventRow(props: Props) {
  const d = (() => {
    if (props.isHeader) {
      return {
        node_name: 'Node',
        time: 'Time',
        eventKind: 'Event',
        pid: 'PID',
        binary: 'Binary name',
        arguments: 'Arguments',
        sourceIp: 'Source IP',
        destinationIp: 'Destination IP',
        destinationPort: 'Destination Port',
        destinationNames: 'Destination Names',
      } as const;
    } else if (props.processEvent != null) {
      if (props.processEvent?.base == null) return null;

      const { process: ps } = props.processEvent.base;
      const { node_name, process_connect: connect } = props.processEvent;

      return {
        node_name,
        time: props.processEvent.date.toISOString(),
        eventKind: _.capitalize(props.processEvent.kind),
        pid: ps.pid,
        binary: ps.binary.slice(ps.binary.lastIndexOf('/') + 1),
        fullBinary: ps.binary,
        arguments: ps.arguments,
        sourceIp: connect?.sourceIp ?? '-',
        destinationIp: connect?.destinationIp ?? '-',
        destinationPort: connect?.destinationPort ?? '-',
        destinationNames: connect?.destinationNames.join(', ') ?? '-',
      } as const;
    }
    return null;
  })();

  const classes = classNames(css.processEventRow, props.className, {
    [css.header]: !!props.isHeader,
  });

  if (!d) return null;

  return (
    <div className={classes} style={props.style} onClick={props.onClick}>
      <div className={css.nodeName}>
        <span title={d.node_name}>{d.node_name}</span>
      </div>
      <div className={css.time}>
        <span title={d.time}>{d.time}</span>
      </div>
      <div className={css.kind}>
        <span title={d.eventKind}>{d.eventKind}</span>
      </div>
      <div className={css.pid}>
        <span title={String(d.pid)}>{d.pid}</span>
      </div>
      <div className={css.binary}>
        <span title={d.fullBinary}>{d.binary}</span>
      </div>
      <div className={css.args}>
        <span title={d.arguments}>{d.arguments}</span>
      </div>
      <div className={css.sourceIp}>
        <span title={d.sourceIp}>{d.sourceIp}</span>
      </div>
      <div className={css.destinationIp}>
        <span title={d.destinationIp}>{d.destinationIp}</span>
      </div>
      <div className={css.destinationPort}>
        <span title={String(d.destinationPort)}>{d.destinationPort}</span>
      </div>
    </div>
  );
});
