import React, { memo, useCallback } from 'react';

import classnames from 'classnames';
import { areEqual, ListChildComponentProps } from 'react-window';

import { Flow } from '~/domain/flows';
import { AsFlowDigest, FlowDigest } from '~/domain/flows/common';

import { Cell } from './Cell';
import { Column, CommonProps } from './general';
import css from './styles.scss';

export interface RowProps extends CommonProps {
  flow: FlowDigest;
  isSelected: boolean;
  onSelect?: (flow: FlowDigest | null) => void;
  style?: React.CSSProperties;
  columnWidths?: { [key in Column]?: number | undefined };
}

export type RowContentProps = Omit<RowProps, 'style'>;

export interface RowRendererData {
  flows: AsFlowDigest[];
  selectedFlow: Flow | null;
  visibleColumns: CommonProps['visibleColumns'];
  onSelectFlow?: RowProps['onSelect'];
  columnWidths: RowProps['columnWidths'];
}

export function RowRenderer({ index, style, data }: ListChildComponentProps) {
  const props = data as RowRendererData;
  const flow = props.flows[index].asFlowDigest();

  return (
    <Row
      key={flow.id}
      style={style}
      flow={flow}
      columnWidths={props.columnWidths}
      visibleColumns={props.visibleColumns}
      isSelected={props.selectedFlow?.id === flow.id}
      onSelect={props.onSelectFlow}
    />
  );
}

export const Row = memo<RowProps>(function FlowsTableRow(props) {
  return (
    <div style={props.style} data-testid={props.flow.id}>
      <RowContent
        flow={props.flow}
        onSelect={props.onSelect}
        isSelected={props.isSelected}
        visibleColumns={props.visibleColumns}
        columnWidths={props.columnWidths}
      />
    </div>
  );
}, areEqual);

export const RowContent = memo<RowContentProps>(function FlowsTableRowContent(props) {
  const onClick = useCallback(
    () => props.onSelect?.(props.isSelected ? null : props.flow),
    [props.onSelect, props.isSelected, props.flow],
  );

  const className = classnames(css.row, {
    [css.selected]: props.isSelected,
  });

  return (
    <div className={className} onClick={onClick}>
      {props.visibleColumns.has(Column.SrcCluster) && (
        <Cell
          flow={props.flow}
          width={props.columnWidths?.[Column.SrcCluster]}
          kind={Column.SrcCluster}
        />
      )}
      {props.visibleColumns.has(Column.SrcNamespace) && (
        <Cell
          flow={props.flow}
          width={props.columnWidths?.[Column.SrcNamespace]}
          kind={Column.SrcNamespace}
        />
      )}
      {props.visibleColumns.has(Column.SrcPod) && (
        <Cell flow={props.flow} width={props.columnWidths?.[Column.SrcPod]} kind={Column.SrcPod} />
      )}
      {props.visibleColumns.has(Column.SrcIp) && (
        <Cell flow={props.flow} width={props.columnWidths?.[Column.SrcIp]} kind={Column.SrcIp} />
      )}
      {props.visibleColumns.has(Column.SrcService) && (
        <Cell
          flow={props.flow}
          width={props.columnWidths?.[Column.SrcService]}
          kind={Column.SrcService}
        />
      )}
      {props.visibleColumns.has(Column.DstCluster) && (
        <Cell
          flow={props.flow}
          width={props.columnWidths?.[Column.DstCluster]}
          kind={Column.DstCluster}
        />
      )}
      {props.visibleColumns.has(Column.DstNamespace) && (
        <Cell
          flow={props.flow}
          width={props.columnWidths?.[Column.DstNamespace]}
          kind={Column.DstNamespace}
        />
      )}
      {props.visibleColumns.has(Column.DstPod) && (
        <Cell flow={props.flow} width={props.columnWidths?.[Column.DstPod]} kind={Column.DstPod} />
      )}
      {props.visibleColumns.has(Column.DstIp) && (
        <Cell flow={props.flow} width={props.columnWidths?.[Column.DstIp]} kind={Column.DstIp} />
      )}
      {props.visibleColumns.has(Column.DstService) && (
        <Cell
          flow={props.flow}
          width={props.columnWidths?.[Column.DstService]}
          kind={Column.DstService}
        />
      )}
      {props.visibleColumns.has(Column.DstPort) && (
        <Cell
          flow={props.flow}
          width={props.columnWidths?.[Column.DstPort]}
          kind={Column.DstPort}
        />
      )}
      {props.visibleColumns.has(Column.L7Info) && (
        <Cell flow={props.flow} width={props.columnWidths?.[Column.L7Info]} kind={Column.L7Info} />
      )}
      {props.visibleColumns.has(Column.TrafficDirection) && (
        <Cell
          flow={props.flow}
          width={props.columnWidths?.[Column.TrafficDirection]}
          kind={Column.TrafficDirection}
        />
      )}
      {props.visibleColumns.has(Column.Verdict) && (
        <Cell
          flow={props.flow}
          width={props.columnWidths?.[Column.Verdict]}
          kind={Column.Verdict}
        />
      )}
      {props.visibleColumns.has(Column.TcpFlags) && (
        <Cell
          flow={props.flow}
          width={props.columnWidths?.[Column.TcpFlags]}
          kind={Column.TcpFlags}
        />
      )}
      {props.visibleColumns.has(Column.AuthType) && (
        <Cell
          flow={props.flow}
          kind={Column.AuthType}
          width={props.columnWidths?.[Column.AuthType]}
        />
      )}
      {props.visibleColumns.has(Column.Timestamp) && (
        <Cell
          flow={props.flow}
          width={props.columnWidths?.[Column.Timestamp]}
          kind={Column.Timestamp}
        />
      )}
    </div>
  );
});
