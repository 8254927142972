import React, { memo, useCallback } from 'react';

import { Button, InputGroup, Menu, MenuItem, Popover, TagInput } from '@blueprintjs/core';
import { observer } from 'mobx-react';

import { EndpointMatchExpression, EndpointRequirementOperator } from '~/domain/cimulator/types';
import { usePopover } from '~/ui/hooks/usePopover';

import { tagInputTagCommonProps } from './general';
import { useStringInputState, useTagsState, useTypedState } from './hooks';
import css from './MatchExpressionsInput.scss';

export interface Props {
  expr: EndpointMatchExpression;
  index: number;
  onChange?: (index: number, expr: EndpointMatchExpression) => void;
  onDelete?: (index: number) => void;
}

export const MatchExpressionsInput = memo(function MatchExpressionsInput(props: Props) {
  const popover = usePopover({
    preventDefault: true,
    stopPropagation: true,
  });

  const key = useStringInputState(props.expr.key);
  const operator = useTypedState<EndpointRequirementOperator>(props.expr.operator);
  const values = useTagsState(props.expr.values ?? []);

  const preventEnter = useCallback((event: React.KeyboardEvent) => {
    if (event.key === 'Enter') event.preventDefault();
  }, []);

  const onDelete = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();
      props.onDelete?.(props.index);
    },
    [props.onDelete, props.index],
  );

  const change = useCallback(
    (key: string, operator: EndpointRequirementOperator, values: string[]) => {
      props.onChange?.(props.index, {
        key,
        operator,
        values,
      });
    },
    [props.onChange, props.index],
  );

  const onKeyChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      key.onChange(event);
      change(event.target.value, operator.value, values.value);
    },
    [change, key.onChange, operator.value, values.value],
  );

  const onOperatorChange = useCallback(
    (nextOperator: EndpointRequirementOperator) => {
      popover.close();
      operator.onChange(nextOperator);
      change(key.value, nextOperator, values.value);
    },
    [change, operator.onChange, key.value, values.value],
  );

  const onValuesChange = useCallback(
    (nextValues: React.ReactNode[]) => {
      values.onChange(nextValues);
      change(key.value, operator.value, nextValues as string[]);
    },
    [change, values.onChange, key.value, operator.value],
  );

  return (
    <div className={css.wrapper}>
      <div onClick={onDelete} className={css.deleteButton}>
        ×
      </div>
      <label className={css.row}>
        <div className={`${css.label} ${css.required}`}>Key</div>
        <div className={css.inputWrapper}>
          <InputGroup fill value={key.value} onChange={onKeyChange} placeholder="environment" />
        </div>
      </label>
      <Popover
        {...popover.props}
        minimal
        content={
          <OperatorsMenu currentOperator={operator.value} onOperatorChange={onOperatorChange} />
        }
        targetTagName="div"
        position="bottom"
        fill
      >
        <div className={css.row}>
          <div className={`${css.label} ${css.required}`}>Operator</div>
          <div className={css.inputWrapper}>
            <Button
              fill
              text={operator.value}
              alignText="left"
              rightIcon={'double-caret-vertical'}
              onClick={popover.toggle}
            />
          </div>
        </div>
      </Popover>
      {(operator.value === EndpointRequirementOperator.In ||
        operator.value === EndpointRequirementOperator.NotIn) && (
        <label className={css.row}>
          <div className={`${css.label} ${css.required}`}>Values</div>
          <div className={css.inputWrapper}>
            <TagInput
              fill
              addOnBlur
              addOnPaste
              values={values.value}
              onChange={onValuesChange}
              placeholder="staging, prod"
              onKeyDown={preventEnter}
              tagProps={tagInputTagCommonProps}
              inputProps={{
                id: `match-expression-values-${props.index}`,
              }}
            />
          </div>
        </label>
      )}
    </div>
  );
});

interface OperatorsMenuProps {
  currentOperator: EndpointRequirementOperator;
  onOperatorChange?: (operator: EndpointRequirementOperator) => void;
}

const OperatorsMenu = observer(function OperatorSelector(props: OperatorsMenuProps) {
  return (
    <Menu>
      {[
        EndpointRequirementOperator.In,
        EndpointRequirementOperator.NotIn,
        EndpointRequirementOperator.Exists,
        EndpointRequirementOperator.DoesNotExist,
      ].map(operator => {
        return (
          <OperatorItem
            key={operator}
            operator={operator}
            isActive={props.currentOperator === operator}
            onSelect={props.onOperatorChange}
          />
        );
      })}
    </Menu>
  );
});

interface OperatorItemProps {
  operator: EndpointRequirementOperator;
  isActive: boolean;
  onSelect?: (operator: EndpointRequirementOperator) => void;
}

const OperatorItem = observer(function OperatorItem(props: OperatorItemProps) {
  const onSelect = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      event.stopPropagation();
      event.preventDefault();
      props.onSelect?.(props.operator);
    },
    [props.onSelect, props.operator],
  );

  return (
    <MenuItem
      active={props.isActive}
      text={props.operator}
      onClick={onSelect}
      shouldDismissPopover={true}
    ></MenuItem>
  );
});
