import React, { useCallback } from 'react';

import classnames from 'classnames';
import { observer } from 'mobx-react';

import { FilterDirection } from '~/domain/filtering';
import * as helpers from '~/domain/helpers';
import {
  AuthType,
  HubblePolicy,
  PodSelector,
  TCPFlagName,
  Verdict,
  Workload,
} from '~/domain/hubble';
import { Labels } from '~/domain/labels';
import { KV } from '~/domain/misc';

import css from './styles.scss';

export interface DirectionProps {
  filterDirection?: FilterDirection;
}

export interface LabelsEntryProps extends DirectionProps {
  labels: KV[];
  selected: Set<string>;
  onClick: (label: KV) => void;
}

export const LabelsEntry = observer(function FlowsTableSidebarLabelsEntry(props: LabelsEntryProps) {
  return (
    <div className={css.labels}>
      {props.labels.map(label => {
        const isSelected = props.selected.has(Labels.concatKV(label));

        return (
          <LabelsEntryItem
            key={Labels.concatKV(label)}
            label={label}
            onClick={props.onClick}
            isSelected={isSelected}
          />
        );
      })}
    </div>
  );
});

export interface LabelsEntryItemProps extends DirectionProps {
  label: KV;
  isSelected: boolean;
  onClick: (label: KV) => void;
}

export const LabelsEntryItem = observer(function FlowsTableSidebarLabelsEntryItem(
  props: LabelsEntryItemProps,
) {
  const onClick = useCallback(() => {
    props.onClick?.(props.label);
  }, [props.label, props.onClick]);

  const className = classnames(css.label, {
    [css.clickable]: !!props.onClick,
    [css.selected]: props.isSelected,
  });

  const title = Labels.concatKV(props.label, true);

  return (
    <span className={className} onClick={onClick}>
      {title}
    </span>
  );
});

export interface WorkloadEntryProps {
  workloads: Workload[];
  selected: Set<string>;
  onClick: (workload: Workload) => void;
}

export const WorkloadsEntry = observer(function FlowsTableSidebarWorkloadsEntry(
  props: WorkloadEntryProps,
) {
  return (
    <div className={css.workloads}>
      {props.workloads.map(workload => {
        const isSelected = props.selected.has(workload.name);

        return (
          <WorkloadsEntryItem
            key={workload.name}
            workload={workload}
            onClick={props.onClick}
            isSelected={isSelected}
          />
        );
      })}
    </div>
  );
});

export interface WorkloadsEntryItemProps extends DirectionProps {
  workload: Workload;
  isSelected: boolean;
  onClick: (workload: Workload) => void;
}

export const WorkloadsEntryItem = observer(function FlowsTableSidebarWorkloadsEntryItem(
  props: WorkloadsEntryItemProps,
) {
  const onClick = useCallback(() => {
    props.onClick?.(props.workload);
  }, [props.workload, props.onClick]);

  const className = classnames(css.workload, {
    [css.clickable]: !!props.onClick,
    [css.selected]: props.isSelected,
  });

  const title = props.workload.name;

  return (
    <span className={className} onClick={onClick}>
      {title}
    </span>
  );
});

export interface TCPFlagsEntryProps {
  flags: Array<TCPFlagName>;
  filterDirection?: FilterDirection;
  selected?: Set<TCPFlagName>;
  onClick?: (flag: TCPFlagName) => void;
}

export const TCPFlagsEntry = observer(function FlowsTableSidebarTCPFlagsEntry(
  props: TCPFlagsEntryProps,
) {
  return (
    <div className={css.tcpFlags}>
      {props.flags.map(flag => {
        const isSelected = props.selected ? props.selected.has(flag) : false;
        const onClick = () => {
          props.onClick?.(flag);
        };

        return (
          <TCPFlagsEntryItem
            key={flag}
            flag={flag}
            isSelected={isSelected}
            filterDirection={props.filterDirection}
            onClick={onClick}
          />
        );
      })}
    </div>
  );
});

export interface TCPFlagsItemProps extends DirectionProps {
  flag: TCPFlagName;
  filterDirection?: FilterDirection;
  isSelected: boolean;
  onClick?: () => void;
}

export const TCPFlagsEntryItem = observer(function FlowsTableSidebarTCPFlagsEntryItem(
  props: TCPFlagsItemProps,
) {
  const className = classnames(css.tcpFlag, {
    [css.clickable]: !!props.onClick,
    [css.selected]: props.isSelected,
  });

  return (
    <span className={className} onClick={props.onClick}>
      {props.flag.toLocaleUpperCase()}
    </span>
  );
});

export interface PortEntryProps {
  port: number;
  isSelected: boolean;
  onClick?: () => void;
}

export const PortEntry = observer(function FlowsTableSidebarPortEntryProps(props: PortEntryProps) {
  const className = classnames(css.port, {
    [css.clickable]: !!props.onClick,
    [css.selected]: props.isSelected,
  });

  return (
    <span className={className} onClick={props.onClick}>
      {props.port.toString()}
    </span>
  );
});

export interface ProtocolEntryProps {
  protocol: any;
  isSelected: boolean;
  onClick?: () => void;
}

export const ProtocolEntry = observer(function FlowsTableSidebarProtocolEntryProps(
  props: ProtocolEntryProps,
) {
  const className = classnames(css.protocol, {
    [css.clickable]: !!props.onClick,
    [css.selected]: props.isSelected,
  });

  return (
    <span className={className} onClick={props.onClick}>
      {`${props.protocol}`}
    </span>
  );
});

export interface VerdictEntryProps {
  verdict: Verdict;
  isSelected: boolean;
  onClick?: () => void;
}

export const VerdictEntry = observer(function FlowsTableSidebarVerdictEntry(
  props: VerdictEntryProps,
) {
  const className = classnames(css.verdict, {
    [css.clickable]: !!props.onClick,
    [css.selected]: props.isSelected,
    [css.forwardedVerdict]: props.verdict === Verdict.Forwarded,
    [css.droppedVerdict]: props.verdict === Verdict.Dropped,
    [css.auditVerdict]: props.verdict === Verdict.Audit,
  });

  return (
    <span className={className} onClick={props.onClick}>
      {helpers.verdict.toString(props.verdict)}
    </span>
  );
});

export interface IPItemProps {
  ip: string;
  isSelected: boolean;
  onClick?: () => void;
}

export const IPEntry = observer(function FlowsTableSidebarIPEntry(props: IPItemProps) {
  const className = classnames(css.ip, {
    [css.clickable]: !!props.onClick,
    [css.selected]: props.isSelected,
  });

  return (
    <span className={className} onClick={props.onClick}>
      {props.ip}
    </span>
  );
});

export interface WorkloadItemProps {
  workload: Workload;
  isSelected: boolean;
  onClick?: () => void;
}

export const WorkloadEntry = observer(function FlowsTableSidebarWorkloadEntry(
  props: WorkloadItemProps,
) {
  const className = classnames(css.workload, {
    [css.clickable]: !!props.onClick,
    [css.selected]: props.isSelected,
  });

  return (
    <span className={className} onClick={props.onClick}>
      {props.workload.name}
    </span>
  );
});

export interface DnsItemProps {
  dns: string;
  isSelected: boolean;
  onClick?: () => void;
}

export const DnsBodyItem = observer(function FlowsTableSidebarDnsBodyItem(props: DnsItemProps) {
  const className = classnames(css.dns, {
    [css.clickable]: !!props.onClick,
    [css.selected]: props.isSelected,
  });

  return (
    <span className={className} onClick={props.onClick}>
      {props.dns}
    </span>
  );
});

export interface IdentityItemProps {
  identity: number;
  isSelected: boolean;
  onClick?: () => void;
}

export const IdentityEntry = observer(function FlowsTableSidebarIdentityBodyItem(
  props: IdentityItemProps,
) {
  const className = classnames(css.identity, {
    [css.clickable]: !!props.onClick,
    [css.selected]: props.isSelected,
  });

  return (
    <span className={className} onClick={props.onClick}>
      {props.identity}
    </span>
  );
});

export interface PodItemProps {
  podSelector: PodSelector;
  isSelected: boolean;
  onClick?: (_: PodSelector) => void;
}

export const PodEntry = observer(function FlowsTableSidebarPodEntry(props: PodItemProps) {
  const onClick = useCallback(() => {
    props.onClick?.(props.podSelector);
  }, [props.podSelector, props.onClick]);

  const className = classnames(css.podd, {
    [css.clickable]: !!props.onClick,
    [css.selected]: props.isSelected,
  });

  return (
    <span className={className} onClick={onClick}>
      {props.podSelector.pod}
    </span>
  );
});

export interface PoliciesListProps {
  policies: HubblePolicy[];
  onClick?: (policy: HubblePolicy) => void;
}

export const PoliciesList = observer(function FlowsTableSidebarPoliciesEntry(
  props: PoliciesListProps,
) {
  return (
    <div className={css.policies}>
      {props.policies.map(policy => {
        return (
          <PoliciesListItem
            key={policy.name + policy.namespace}
            policy={policy}
            onClick={props.onClick}
          />
        );
      })}
    </div>
  );
});

export interface PoliciesListItemProps {
  policy: HubblePolicy;
  onClick?: (policy: HubblePolicy) => void;
}

export const PoliciesListItem = observer(function FlowsTableSidebarPolicyEntry(
  props: PoliciesListItemProps,
) {
  const onClick = useCallback(() => {
    props.onClick?.(props.policy);
  }, [props.policy, props.onClick]);

  const className = classnames(css.policy, {
    [css.clickable]: !!props.onClick,
  });

  return (
    <div className={className} onClick={onClick}>
      {props.policy.namespace}/{props.policy.name}
    </div>
  );
});

export interface AuthTypeEntryProps {
  authType: AuthType;
}

export const AuthTypeEntry = observer(function FlowsTableSidebarAuthTypeEntry(
  props: AuthTypeEntryProps,
) {
  const className = classnames(css.authType, {
    [css.authTypeFailed]: props.authType === AuthType.TestAlwaysFail,
  });

  return <span className={className}>{helpers.authType.toString(props.authType)}</span>;
});
