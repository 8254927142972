import React, { useLayoutEffect, useRef } from 'react';

import * as mobx from 'mobx';
import { observer } from 'mobx-react';

import { useApplication } from '~/application';
import * as helpers from '~/domain/helpers';
import * as l7helpers from '~/domain/helpers/l7';
import { AuthType, IPProtocol, L7Kind, Verdict } from '~/domain/hubble';
import * as trafficIcons from '~/ui/icons/traffic';

import { AuthTypeEntry } from './AuthTypeEntry';
import css from './styles.scss';

export interface Props {
  port: number;
  l4Protocol: IPProtocol;
  l7Protocol?: L7Kind;
  verdicts?: Set<Verdict>;
  authTypes?: Set<AuthType>;
  connectorRef?: React.MutableRefObject<HTMLDivElement | null>;
  id: string;
  cardId: string;
}

export enum E2E {
  accessPointPortTestId = 'port',
  accessPointL4ProtoTestId = 'proto-l4',
  accessPointL7ProtoTestId = 'proto-l7',
  accessPoint = 'access-point',
}

export const AccessPoint = observer(function AccessPoint(props: Props) {
  const { store } = useApplication();
  const themeStore = store.themes;
  const isDarkTheme = themeStore.isDarkTheme;

  const connectorRef = useRef<HTMLDivElement>(null);
  const showPort = props.l4Protocol !== IPProtocol.ICMPv4 && props.l4Protocol !== IPProtocol.ICMPv6;
  const showL7Protocol = props.l7Protocol != null && props.l7Protocol !== L7Kind.Unknown;

  const successIcon = mobx
    .computed(() => {
      return trafficIcons.iconByVerdicts(props.verdicts, isDarkTheme);
    })
    .get();

  useLayoutEffect(() => {
    if (props.connectorRef == null || connectorRef.current == null) return;
    props.connectorRef.current = connectorRef.current;
  }, [props.connectorRef]);

  return (
    <div
      className={css.accessPoint}
      data-testid={`${E2E.accessPoint}-${helpers.protocol.toString(props.l4Protocol)}-${props.port}`}
    >
      <div className={css.icons}>
        <div className={css.connectorMock} ref={connectorRef} />
        <div className={css.connectorIcon}>
          <img src={successIcon} />
        </div>
      </div>

      <div className={css.data}>
        {props.authTypes && (
          <>
            <div className={css.authType}>{<AuthTypeEntry authTypes={props.authTypes} />}</div>
            <div className={css.dot} />
          </>
        )}
        {showPort && (
          <>
            <div className={css.port} data-testid={E2E.accessPointPortTestId}>
              {props.port}
            </div>
            <div className={css.dot} />
          </>
        )}
        <div className={css.protocol} data-testid={E2E.accessPointL4ProtoTestId}>
          {IPProtocol[props.l4Protocol]}
        </div>

        {props.l7Protocol && showL7Protocol && (
          <>
            <div className={css.dot} />
            <div className={css.protocol} data-testid={E2E.accessPointL7ProtoTestId}>
              {l7helpers.l7KindToString(props.l7Protocol)}
            </div>
          </>
        )}
      </div>
    </div>
  );
});
