import React from 'react';

import css from './NamespaceSelectIcon.scss';

export const NamespaceSelectIcon = function NamespaceSelectIcon() {
  const namespaceSelectIconSrc = `/icons/misc/double-caret-vertical-icon.svg`;

  return (
    <span className={css.icon}>
      <img src={namespaceSelectIconSrc} />
    </span>
  );
};
