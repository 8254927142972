import React from 'react';

import classNames from 'classnames';

import css from './AspectRatioBox.scss';

export type Props = {
  ratio: string;

  className?: string;
  children?: React.ReactNode;
};

export const AspectRatioBox = function AspectRatioBox(props: Props) {
  const [w, h] = parseRatio(props.ratio);
  const classes = classNames(css.aspectRatioBox, props.className);

  const styles: React.CSSProperties = {
    paddingTop: `${((h / w) * 100).toFixed(4)}%`,
  };

  return (
    <div className={classes} style={styles}>
      <div className={css.content}>{props.children}</div>
    </div>
  );
};

const parseRatio = (ratioStr: string): [number, number] => {
  const parts = ratioStr.trim().split('x');
  if (parts.length === 2) return parseParts(parts);

  const slashParts = ratioStr.trim().split('/');
  if (slashParts.length === 2) return parseParts(slashParts);

  return [1, 1];
};

const parseParts = (parts: string[]): [number, number] => {
  const w = parseFloat(parts[0]);
  const h = parseFloat(parts[1]);

  if (Number.isNaN(w) || Number.isNaN(h)) return [1, 1];
  return [w, h];
};
