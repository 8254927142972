import { CountStats } from '~/domain/common';
import { Flow } from '~/domain/flows';
import { NamespaceDescriptor } from '~/domain/namespaces';
import { Link, Service } from '~/domain/service-map';

import { FlowSummary, FlowSummaryPlain } from './flow-summary';
export { FlowSummaryPlain, FlowSummary };

export { TimescapeDataFilter } from './data-filter';
export { PodInfo } from './pod-info';

export interface TimescapeData {
  flows: Flow[];
  links: Link[];
  services: Service[];
  countStats: CountStats[];
  flowSummaries: FlowSummary[];
  namespaces: NamespaceDescriptor[];
}

export const emptyTimescapeData = (): TimescapeData => {
  return {
    flows: [],
    links: [],
    services: [],
    countStats: [],
    flowSummaries: [],
    namespaces: [],
  };
};
