import { Message, Oneshot, OneshotOptions } from '~/api/customprotocol-core';
import { Flow } from '~/domain/flows';
import { ProtoFactory } from '~/factories/proto';
import * as uipb from '~backend/proto/ui/ui_pb';

export type Options = OneshotOptions & {
  flowSummaryId: string;
};

export enum Event {
  FullFlow = 'full-flow',
}

export type Handlers = {
  [Event.FullFlow]: (ff: Flow) => void;
};

export class FullFlowOneshot extends Oneshot<Handlers> {
  private flowSummaryId: string;

  constructor(opts: Options) {
    super(opts);

    this.flowSummaryId = opts.flowSummaryId;

    this.setupEventHandlers();
  }

  public onFullFlow(fn: Handlers[Event.FullFlow]): this {
    this.on(Event.FullFlow, fn);
    return this;
  }

  private setupEventHandlers() {
    this.onMessage(msg => {
      const resp = uipb.GetFullFlowResponse.fromBinary(msg.body);
      const fullFlow = ProtoFactory.flowFromFullFlowResponse(this.flowSummaryId, resp);
      if (fullFlow == null) return;

      this.emit(Event.FullFlow, fullFlow);
    });
  }

  protected messageBuilder(msg: Message, isFirst: boolean): Message {
    if (!isFirst) return msg;

    const request = ProtoFactory.fullFlowRequest(this.flowSummaryId);
    msg.setBodyBytes(uipb.GetFullFlowRequest.toBinary(request));

    return msg;
  }
}
