import React, { useMemo, useRef } from 'react';

import { observer } from 'mobx-react';

import { useApplication } from '~/application';
import { ClusterMapCard } from '~/components/ClusterMap/ClusterMapCard';
import { ClusterMapStore } from '~/components/ClusterMap/ClusterMapStore';
import { ContainerCard } from '~/components/ClusterMap/ContainerCard';
import { ContainerHeader } from '~/components/ClusterMap/ContainerHeader';
import {
  AccessPointArrow,
  Arrow,
  CardInfo,
  CombinedAccessPointArrow,
  ContainerCardInfo,
} from '~/components/ClusterMap/types';
import EmptyMapPlaceholder from '~/components/EmptyMapPlaceholder';
import { LoadingOverlay } from '~/components/Misc/LoadingOverlay';
import * as helpers from '~/components/ServiceMapArrowsRenderer/helpers';
import { ServiceMapArrowBody } from '~/components/ServiceMapArrowsRenderer/ServiceMapArrowBody';
import { ServiceMapArrowDuckFeet } from '~/components/ServiceMapArrowsRenderer/ServiceMapArrowDuckFeet';
import { ServiceMapCard } from '~/components/ServiceMapCard';
import { useMapZoom } from '~/ui/hooks/useMapZoom';
import { useMutationObserver } from '~/ui/hooks/useMutationObserver';
import { useVisibleHeight } from '~/ui/hooks/useVisibleHeight';
import { RefsCollector } from '~/ui/service-map/collector';
import { colors, sizes } from '~/ui/vars';
import { XYWH } from '~/ui-layer/service-map/coordinates/types';

import css from './styles.scss';

export enum E2E {
  visibleContainer = 'visible-container',
  arrowsForeground = 'arrows-foreground',
}

export const ClusterMap = observer(function ClusterMap({
  mapVisibleHeight,
}: {
  mapVisibleHeight: number;
}) {
  const { ui, store } = useApplication();
  const cardsRef = useRef<SVGGElement | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const visibleHeight = useVisibleHeight(containerRef);

  const vm = useMemo(
    () =>
      new ClusterMapStore(
        store.clusterNamespaces,
        store.connectionsMap,
        ui.serviceMap,
        ui.controls,
        store.themes.isDarkTheme,
      ),
    [store.clusterNamespaces, store.connectionsMap, ui.serviceMap],
  );

  useMutationObserver({ ref: cardsRef, options: { all: true } }, () => {
    setTimeout(() => {
      ui.serviceMap.cardsMutationsObserved();
    }, 20);
  });

  return (
    <div ref={containerRef}>
      {vm.hasNoData && (
        <EmptyMapPlaceholder
          height={mapVisibleHeight}
          prompt="Filters returned no data to visualize"
        />
      )}
      {vm.loading && <LoadingOverlay height={'100%'} text={'Loading...'} />}
      {!vm.hasNoData && (
        <MapClusterMap
          container={vm.containerCard}
          cards={vm.cards}
          arrows={vm.arrows}
          zoomBBox={vm.zoomBBox}
          cardsRef={cardsRef}
          collector={ui.serviceMap.collector}
          onReturnClusterMapClick={() => {
            vm.onReturnClusterMapClick();
          }}
          onReturnNamespaceMapClick={() => {
            vm.onReturnNamespaceMapClick();
          }}
          onClusterChange={(clusterId: string | null) => {
            vm.onClusterChange(clusterId);
          }}
          clustersList={store.clusterNamespaces.clustersList}
          namespacesList={store.clusterNamespaces.currClusterNamespacesList}
          onNamespaceChange={(namespaceId: string | null) => {
            vm.onNamespaceChange(namespaceId);
          }}
          visibleHeight={visibleHeight}
          combinedAccessPointArrows={vm.combinedAccessPointArrows}
          exiting={vm.exiting}
          selectedService={vm.selectedService}
          servicesList={vm.servicesList}
          onReturnServiceMapClick={() => {
            vm.onReturnServiceMapClick();
          }}
          onServiceChange={(serviceId: string) => vm.onServiceChange(serviceId)}
        />
      )}
    </div>
  );
});

const MapClusterMap = observer(
  ({
    cards,
    arrows,
    zoomBBox,
    container,
    onReturnClusterMapClick,
    onReturnNamespaceMapClick,
    visibleHeight,
    cardsRef,
    collector,
    combinedAccessPointArrows,
    onClusterChange,
    clustersList,
    namespacesList,
    onNamespaceChange,
    exiting,
    selectedService,
    servicesList,
    onReturnServiceMapClick,
    onServiceChange,
  }: {
    container?: ContainerCardInfo | null;
    onReturnClusterMapClick: () => void;
    onReturnNamespaceMapClick: () => void;
    onReturnServiceMapClick: () => void;
    onClusterChange: (cluster: string | null) => void;
    onNamespaceChange: (namespace: string | null) => void;
    cards: CardInfo[];
    arrows: Arrow[];
    zoomBBox: XYWH | null;
    visibleHeight: number;
    cardsRef: React.MutableRefObject<SVGGElement | null>;
    collector: RefsCollector;
    combinedAccessPointArrows: CombinedAccessPointArrow[];
    clustersList?: string[];
    namespacesList?: string[];
    exiting?: boolean;
    selectedService?: string;
    servicesList?: { cardId: string; serviceName: string }[];
    onServiceChange: (serviceId: string) => void;
  }) => {
    const { store, ui } = useApplication();
    const arrowsForegroundRef = useRef<SVGGElement | null>(null);
    const zoom = useMapZoom({
      mapBBox: zoomBBox,
      visibleHeight,
      wasDragged: false,
    });
    const l7endpoints = store.currentFrame.interactions.l7endpoints;

    return (
      <svg
        ref={zoom.ref}
        className={`${css.wrapper}${exiting ? ' ' + css.exit : ''}`}
        id="service-map"
      >
        <g transform={zoom.transformStr}>
          {container && <ContainerCard {...container} coords={container} />}
          <g className="arrows">
            <g className="bodies">
              {arrows.map(arrow => {
                return (
                  <ServiceMapArrowBody key={arrow.id} arrow={arrow} isFlowStatsHidden={true} />
                );
              })}
            </g>
            <g className="highlight">
              {arrows
                .filter(a => a.highlighted)
                .map(arrow => {
                  return (
                    <ServiceMapArrowBody
                      key={`${arrow.id}-highlighted`}
                      arrow={arrow}
                      isFlowStatsHidden={true}
                      strokeWidth={sizes.linkHighlightedWidth}
                      strokeColor={colors.arrowStrokeHighlighted}
                      decorationColor={colors.arrowHandleHighlighted}
                      arrowDecorationDuration={helpers.animationDelay.arrowHighlightedDuration}
                      arrowDelay={0}
                      arrowDuration={helpers.animationDelay.arrowHighlightedDuration}
                      className={css.highlight}
                    />
                  );
                })}
            </g>
          </g>
          <g ref={cardsRef} className="visible-cards" data-testid={E2E.visibleContainer}>
            {cards.map((card, i) => {
              if (card.type === 'service') {
                return (
                  <ServiceMapCard
                    card={card.card}
                    key={card.id}
                    className={`map-card ${card.outside ? css.outsideCard : css.insideCard}`}
                    active={ui.serviceMap.isCardActive(card.card as any)}
                    collector={collector}
                    l7endpoints={l7endpoints.forReceiver(card.card.id)}
                    maxHttpEndpointsVisible={5}
                    isClusterMeshed={store.currentFrame.services.isClusterMeshed}
                    onHeaderClick={svcCard => {
                      card.onCardClick && card.onCardClick(svcCard);
                    }}
                    onMouseEnter={card.onMouseEnter}
                    onMouseLeave={card.onMouseLeave}
                    coords={card}
                    onGotoProcessTree={card => ui.serviceMap.openProcessTreeForCard(card)}
                    showAdditionalInfo={false}
                  />
                );
              } else {
                return (
                  <ClusterMapCard
                    type={card.type}
                    onClick={card.onCardClick}
                    verdictIcon={card.verdictIcon}
                    coords={card}
                    loading={card.loading}
                    key={i}
                    outside={card.outside}
                    name={card.name}
                    onMouseEnter={card.onMouseEnter}
                    onMouseLeave={card.onMouseLeave}
                  />
                );
              }
            })}
          </g>
          <g
            className={css.arrowsForeground}
            ref={arrowsForegroundRef}
            data-testid={E2E.arrowsForeground}
          >
            <g className="bodies">
              {combinedAccessPointArrows.map(combinedArrows => {
                return (
                  <ServiceMapArrowDuckFeet
                    key={combinedArrows.id}
                    connectorId={combinedArrows.id}
                    arrows={combinedArrows.combinedArrows}
                  />
                );
              })}
            </g>
            <g className="highlight">
              {combinedAccessPointArrows
                .filter(a => a.highlighted)
                .map(combinedArrows => {
                  return (
                    <ServiceMapArrowDuckFeet
                      key={combinedArrows.id}
                      connectorId={combinedArrows.id}
                      arrows={combinedArrows.combinedArrows}
                      strokeWidth={(a: AccessPointArrow) =>
                        helpers.innerArrows.strokeWidth(a, true)
                      }
                      strokeColor={(a: AccessPointArrow) =>
                        helpers.innerArrows.strokeColor(a, true)
                      }
                      animationDelay={helpers.animationDelay.arrowHighlightedDuration}
                      animationDuration={0.2}
                      decorationStrokeColor={colors.connectorHighlightedStroke}
                      decorationColor={colors.connectorHighlightedFill}
                      animationDecorationDuration={
                        helpers.animationDelay.duckFeetDecorationDuration
                      }
                      className={css.highlight}
                    />
                  );
                })}
            </g>
          </g>
          {container && (
            <ContainerHeader
              onReturnClusterMapClick={onReturnClusterMapClick}
              onReturnNamespaceMapClick={onReturnNamespaceMapClick}
              onClusterChange={onClusterChange}
              {...container}
              clustersList={clustersList}
              namespacesList={namespacesList}
              onNamespaceChange={onNamespaceChange}
              coords={container}
              onReturnServiceMapClick={onReturnServiceMapClick}
              onServiceChange={onServiceChange}
              selectedService={selectedService}
              servicesList={servicesList}
            />
          )}
        </g>
      </svg>
    );
  },
);
