import { makeAutoObservable } from 'mobx';

import { Flow } from '~/domain/flows';
import { ProcessEvent } from '~/domain/process-events';

import { NamespaceNode } from './namespace-node';

export class ClusterNode {
  public name: string;
  public namespaces: Map<string, NamespaceNode> = new Map();

  public static new(nodeName: string) {
    return new ClusterNode(nodeName);
  }

  constructor(nodeName: string) {
    this.name = nodeName;

    makeAutoObservable(this);
  }

  public saveEvent(evt: ProcessEvent): boolean {
    const ns = evt.namespace;
    if (ns == null) return false;

    const nsNode = this.ensureNode(ns);
    return nsNode.saveEvent(evt);
  }

  public saveFlow(flow: Flow): boolean {
    const ns = flow.sourceNamespace;
    if (ns == null) return false;

    const nsNode = this.ensureNode(ns);
    return nsNode.saveFlow(flow);
  }

  private ensureNode(ns: string): NamespaceNode {
    if (this.namespaces.has(ns)) return this.namespaces.get(ns)!;

    const node = NamespaceNode.new(ns);
    this.namespaces.set(ns, node);

    return node;
  }
}
