import { action, computed, makeObservable, observable, ObservableMap } from 'mobx';

import { EndpointSelector } from '~/domain/cilium/cnp/types.generated';
import { PolicyEndpoint } from '~/domain/cimulator/endpoint';
import {
  CardGeneralInfo,
  CardKind,
  CardSide,
  PolicyKind,
  UnsupportedReasonInfo,
  UnsupportedReasonKind,
} from '~/domain/cimulator/types';
import { NamespaceLabelKey } from '~/domain/labels';
import { createHash } from '~/domain/misc';

abstract class AbstractAccessPoint {
  public abstract get id(): string;
}

export abstract class AbstractCard {
  public abstract createAccessPoints(): Map<string, AbstractAccessPoint>;
  public abstract get id(): string;
}

export class PolicyCard extends AbstractCard {
  @observable private _side: CardSide;
  @observable private _kind: CardKind;
  @observable private _namespace: string | null | undefined = null;
  @observable private _endpoints: ObservableMap<string, PolicyEndpoint>;
  @observable private _podSelector: EndpointSelector | null = null;

  public static readonly NoName = 'No Name';

  static buildId = (side: CardSide, kind: CardKind) => {
    return `${side}/${kind}`;
  };

  static buildFullEndpointId = (side: CardSide, kind: CardKind, endpointId: string) => {
    return `${PolicyCard.buildId(side, kind)} -> ${endpointId}`;
  };

  static parseFullCardEndpointId = (id: string): [string, string] => {
    const [cardId, endpointId] = id.split(' -> ');
    return [cardId, endpointId];
  };

  constructor(side: CardSide, kind: CardKind) {
    super();
    makeObservable(this);
    this._side = side;
    this._kind = kind;
    this._endpoints = observable.map({});
  }

  @computed get hash() {
    return createHash({
      side: this.side,
      kind: this._kind,
      namespace: this._namespace,
      endpoints: this._endpoints,
      podSelector: this._podSelector,
    });
  }

  @computed get id(): string {
    return PolicyCard.buildId(this._side, this._kind);
  }

  @computed get podSelector() {
    return this._podSelector;
  }

  @computed get side() {
    return this._side;
  }

  @computed get kind() {
    return this._kind;
  }

  @computed get namespace(): string | null | undefined {
    return this._namespace;
  }

  @computed get generalInfo(): CardGeneralInfo {
    return {
      kind: this._kind,
      side: this._side,
      namespace: this._namespace,
    };
  }

  @computed get caption(): string {
    switch (this.kind) {
      case CardKind.All:
        return 'All';
      case CardKind.OutsideCluster:
        return 'Outside Cluster';
      case CardKind.InCluster:
        return 'In Cluster';
      case CardKind.InNamespace:
        return 'In Namespace';
      default:
        return this.kind;
    }
  }

  @computed get subcaption(): string | null {
    switch (this.kind) {
      case CardKind.InNamespace:
        return this.namespace ?? null;
      default:
        return null;
    }
  }

  @computed get isIngress(): boolean {
    return this._side === CardSide.Ingress;
  }

  @computed get isEgress(): boolean {
    return this._side === CardSide.Egress;
  }

  @computed get isSelector(): boolean {
    return this._side === CardSide.Selector;
  }

  @computed get endpointsMap(): Map<string, PolicyEndpoint> {
    return this._endpoints;
  }

  @computed get endpointsList(): PolicyEndpoint[] {
    const map = new Map(this.endpointsMap);

    const sort = (a: PolicyEndpoint, b: PolicyEndpoint): number => {
      const comp = a.getCaption().localeCompare(b.getCaption());
      if (comp === 0) {
        return a.portsStr.localeCompare(b.portsStr);
      }
      return comp;
    };

    // Sorting:
    // 1. all endpoints without ports
    // 2. all endpoints with ports
    // 3. kube-dns
    // 4. entities
    // 5. other

    const a: PolicyEndpoint[] = [];
    map.forEach((endpoint, key) => {
      if (endpoint.isAllWithoutPorts) {
        a.push(endpoint);
        map.delete(key);
      }
    });
    a.sort(sort);

    const b: PolicyEndpoint[] = [];
    map.forEach((endpoint, key) => {
      if (endpoint.isAllWithPorts) {
        b.push(endpoint);
        map.delete(key);
      }
    });
    b.sort(sort);

    const c: PolicyEndpoint[] = [];
    map.forEach((endpoint, key) => {
      if (endpoint.isKubeDns) {
        c.push(endpoint);
        map.delete(key);
      }
    });
    c.sort(sort);

    const d: PolicyEndpoint[] = [];
    map.forEach((endpoint, key) => {
      if (endpoint.isHost || endpoint.isRemoteNode) {
        d.push(endpoint);
        map.delete(key);
      }
    });
    d.sort(sort);

    const e = Array.from(map.values()).sort(sort);

    return [...a, ...b, ...c, ...d, ...e];
  }

  @computed get partialEndpointsList(): PolicyEndpoint[] {
    const endpoints = this.endpointsList;
    const len = endpoints.length;
    const result: PolicyEndpoint[] = [];
    for (let i = 0; i < Math.min(len, 10); i++) {
      result.push(endpoints[i]);
    }
    return result;
  }

  @computed get isAll(): boolean {
    return this._kind === CardKind.All;
  }

  @computed get isOutsideCluster(): boolean {
    return this._kind === CardKind.OutsideCluster;
  }

  @computed get isInNamespace(): boolean {
    return this._kind === CardKind.InNamespace;
  }

  @computed get isInCluster(): boolean {
    return this._kind === CardKind.InCluster;
  }

  @computed get allEndpoint() {
    return this._endpoints.get(PolicyEndpoint.newAll().id);
  }

  @computed get firstEndpoint(): PolicyEndpoint | null {
    return this.endpointsList[0] ?? null;
  }

  @action setPodSelector = (selector: EndpointSelector) => {
    this._podSelector = selector;
    return this;
  };

  @action addEndpoints = (...endpoints: PolicyEndpoint[]) => {
    endpoints.forEach(ep => {
      if (this._endpoints.has(ep.id)) return;
      this._endpoints.set(ep.id, ep);
    });
    return this;
  };

  @action replaceEndpoints = (...endpoints: PolicyEndpoint[]) => {
    this._endpoints.clear();
    endpoints.forEach(endpoint => {
      this._endpoints.set(endpoint.id, endpoint);
    });
    return this;
  };

  @action removeEndpoint = (endpoint: PolicyEndpoint) => {
    this._endpoints.delete(endpoint.id);
  };

  @action updateEndpoint = (updated: PolicyEndpoint, oldId: string) => {
    this._endpoints.delete(oldId);
    this._endpoints.set(updated.id, updated);
  };

  @action setSide = (side: CardSide) => {
    this._side = side;
  };

  @action setKind = (kind: CardKind) => {
    this._kind = kind;
  };

  @action setNamespace = (namespace: string | null | undefined) => {
    this._namespace = namespace;
  };

  @action flushEndpoints = (): this => {
    this._endpoints.clear();
    return this;
  };

  hasEndpoint = (endpoint: PolicyEndpoint): boolean => {
    return this.hasEndpointId(endpoint.id);
  };

  hasEndpointId = (id: string): boolean => {
    return this._endpoints.has(id);
  };

  fullEndpointId = (endpointId: string): string => {
    return PolicyCard.buildFullEndpointId(this.side, this.kind, endpointId);
  };

  createAccessPoints = (): any => {
    return new Map();
  };

  getUnsupportedReasonInfo = (
    policyKind: PolicyKind,
    endpoint: PolicyEndpoint,
  ): UnsupportedReasonInfo | null => {
    if (endpoint.isKubeDns) {
      return null;
    }
    if (policyKind === PolicyKind.KNP) {
      if (endpoint.namespace) {
        return {
          kind: UnsupportedReasonKind.CNPNamespaceKey,
          namespaceLabel: `${NamespaceLabelKey}=${endpoint.namespace}`,
        };
      } else if (endpoint.fqdn) {
        return {
          kind: UnsupportedReasonKind.CNPfqdn,
          fqdn: endpoint.fqdnMatch,
        };
      } else if (endpoint.isHost || endpoint.isRemoteNode) {
        return {
          kind: UnsupportedReasonKind.CNPEntity,
          entity: endpoint.kind,
        };
      }
    }
    return null;
  };

  clone = (): PolicyCard => {
    const clonedCard = new PolicyCard(this._side, this._kind);

    if (this._namespace) {
      clonedCard.setNamespace(this._namespace);
    }

    if (this._podSelector) {
      clonedCard.setPodSelector(this._podSelector);
    }

    this._endpoints.forEach(endpoint => {
      clonedCard.addEndpoints(endpoint.clone());
    });

    return clonedCard;
  };
}
