import React from 'react';

import { Icon, Switch } from '@blueprintjs/core';
import { observer } from 'mobx-react-lite';

import { useStore } from '~/store';

import css from './ThemeSwitcher.scss';

export const ThemeSwitcher = observer(function ThemeSwitcher() {
  const store = useStore();
  const themeStore = store.themes;
  const isDarkThemeActive = themeStore.isDarkTheme;

  const themeIcon = isDarkThemeActive ? 'moon' : 'flash';

  const handleThemeChange = () => {
    themeStore.applyTheme(isDarkThemeActive ? 'light' : 'dark');
  };

  return (
    <div className={css.container}>
      <Switch checked={themeStore.isDarkTheme} onChange={handleThemeChange} />
      <Icon icon={themeIcon} />
    </div>
  );
});
