import { action, computed, makeObservable, observable, reaction, runInAction } from 'mobx';

import { XY, XYWH } from '~/domain/geometry';
import { Method as HttpMethod } from '~/domain/http';
import { PlacementStrategy } from '~/domain/layout/abstract';
import { ServiceCard } from '~/domain/service-map';
import { StoreFrame } from '~/store/frame';
import { ClusterNamespaceStore } from '~/store/stores/cluster-namespace';
import ControlStore from '~/store/stores/controls';
import InteractionStore from '~/store/stores/interaction';
import ServiceStore from '~/store/stores/service';
import { getBoundingBox } from '~/ui-layer/service-map/coordinates/get-bounding-box';
import {
  getColumnBasedPlacement,
  PlacementEntry,
  PlacementKind,
  PlacementMeta,
} from '~/ui-layer/service-map/coordinates/get-placement';

type CardsPlacement = Map<string, PlacementEntry<ServiceCard>>;
export type CardsColumns = Map<string, PlacementMeta<ServiceCard>[][]>;

// TODO: move it away from domain
export class ServiceMapPlacementStrategy extends PlacementStrategy {
  @observable
  private controls: ControlStore;

  @observable
  private interactions: InteractionStore;

  @observable
  private services: ServiceStore;

  @observable
  private clusterNamespace: ClusterNamespaceStore;

  // NOTE: { receiverId -> { urlPath -> { HttpMethod -> XY }}}
  @observable
  protected _httpEndpointCoords: Map<string, Map<string, Map<HttpMethod, XY>>>;

  constructor(frame: StoreFrame) {
    super();
    makeObservable(this);
    this.controls = frame.controls;
    this.interactions = frame.interactions;
    this.services = frame.services;
    this.clusterNamespace = frame.clusterNamespace;
    this._httpEndpointCoords = new Map();

    reaction(
      () => this.cardsPlacement,
      () => this.rebuild(),
    );
  }

  public reset() {
    runInAction(() => {
      super.reset();
      this._httpEndpointCoords.clear();
    });
  }

  @action.bound
  public setHttpEndpointCoords(svcId: string, urlPath: string, method: HttpMethod, coords: XY) {
    if (!this._httpEndpointCoords.has(svcId)) {
      this._httpEndpointCoords.set(svcId, new Map());
    }

    const svcUrlPaths = this._httpEndpointCoords.get(svcId)!;
    if (!svcUrlPaths.has(urlPath)) {
      svcUrlPaths.set(urlPath, new Map());
    }

    svcUrlPaths.get(urlPath)?.set(method, coords);
  }

  @action.bound
  public setHttpEndpointsCoords(
    coords: {
      cardId: string;
      urlPath: string;
      method: HttpMethod;
      bbox: XYWH;
    }[],
  ) {
    coords.forEach(c => {
      this.setHttpEndpointCoords(c.cardId, c.urlPath, c.method, c.bbox.center);
    });
  }

  @computed
  public get httpEndpointCoords() {
    return this._httpEndpointCoords;
  }

  @computed
  public get namespaceBBox(): XYWH | null {
    const raw = getBoundingBox(
      [...this.cardsPlacement.values()]
        .filter(
          e =>
            e.kind === PlacementKind.InsideWithConnections ||
            e.kind === PlacementKind.InsideWithoutConnections,
        )
        .map(e => e.geometry),
    );

    return raw && new XYWH(raw.x, raw.y, raw.w, raw.h);
  }

  @action.bound
  public rebuild() {
    this.cardsPlacement.forEach((plcEntry: PlacementEntry<ServiceCard>, cardId: string) => {
      this.cardsXYs.set(cardId, plcEntry.geometry);
    });
  }

  @computed
  get cardsPlacement(): CardsPlacement {
    const groups = this.placementGroups;
    const skipAnotherNs = !this.controls.showCrossNamespaceActivity;

    return getColumnBasedPlacement({
      groups,
      skipAnotherNs,
      cardsDimensions: this.cardsDimensions,
    });
  }

  @computed
  private get placementGroups() {
    const index: Map<PlacementKind, PlacementMeta<ServiceCard>[]> = new Map();
    const currentNs = this.clusterNamespace.currNamespace;

    this.services.cardsList.forEach((card: ServiceCard) => {
      const meta = this.getCardPlacementMeta(card, currentNs || undefined);
      const kindSet = index.get(meta.kind) ?? [];
      const cards = [...kindSet, meta];

      index.set(meta.kind, cards);
    });

    return index;
  }

  private getCardPlacementMeta(card: ServiceCard, ns?: string): PlacementMeta<ServiceCard> {
    const senders = this.connections.incomings.get(card.id);
    const receivers = this.connections.outgoings.get(card.id);

    const incomingsCount = senders?.size || 0;
    const outgoingsCount = receivers?.size || 0;

    // TODO: cache this ?
    const props = this.findSpecialInteractions(card);

    let kind = PlacementKind.InsideWithoutConnections;
    if (card.isHost || card.isRemoteNode) {
      kind = PlacementKind.FromWorld;
    } else if (card.isWorld) {
      kind = PlacementKind.FromWorld;
      kind = incomingsCount > 0 ? PlacementKind.ToWorld : kind;
    } else if (card.namespace !== ns) {
      kind = PlacementKind.AnotherNamespace;
    } else if (incomingsCount > 0 || outgoingsCount > 0) {
      kind = PlacementKind.InsideWithConnections;
    }

    // Weight determines how many connections the card has.
    // If card has special interactions, it gains more weight.
    let weight = -incomingsCount + outgoingsCount;

    weight += props.hasWorldAsSender || props.hasHostAsSender ? 1000 : 0;
    weight += props.hasWorldAsReceiver ? 500 : 0;

    return {
      kind,
      card,
      weight,
    };
  }

  private findSpecialInteractions(card: ServiceCard) {
    const senders = this.connections.incomings.get(card.id);
    const receivers = this.connections.outgoings.get(card.id);

    let hasWorldAsSender = false;
    let hasHostAsSender = false;
    let hasWorldAsReceiver = false;

    // prettier-ignore
    senders != null && senders.forEach((_, senderId) => {
      const sender = this.services.cardsMap.get(senderId);
      if (sender == null) return;

      hasWorldAsSender = hasWorldAsSender || sender.isWorld;
      hasHostAsSender = hasHostAsSender || sender.isHost;
    });

    // prettier-ignore
    receivers != null && receivers.forEach((_, receiverId) => {
      const receiver = this.services.cardsMap.get(receiverId);
      if (receiver == null) return;

      hasWorldAsReceiver = hasWorldAsReceiver || receiver.isWorld;
    });

    return {
      hasWorldAsReceiver,
      hasWorldAsSender,
      hasHostAsSender,
    };
  }

  @computed
  get connections() {
    return this.interactions.connections;
  }
}
