import { makeAutoObservable, reaction } from 'mobx';

import * as storage from '../../storage/local';

// TODO: Redo

export default class ThemeStore {
  private theme = 'light';

  // constructor() {
  //   makeAutoObservable(this);
  //   const restored = this.restoreInitialTheme();
  //   reaction(
  //     () => this.theme,
  //     nextTheme => this.applyTheme(nextTheme),
  //   );
  //   if (!restored) this.syncWithSystemTheme();
  // }

  constructor() {
    makeAutoObservable(this);
    this.restoreInitialTheme();
    reaction(
      () => this.theme,
      nextTheme => this.applyTheme(nextTheme),
    );
    // this.syncWithSystemTheme();
  }

  get currentTheme() {
    return this.theme;
  }

  get isDarkTheme() {
    return this.theme === 'dark';
  }

  applyTheme(nextTheme: string, persist = true) {
    this.theme = nextTheme;

    const html = document.documentElement;
    const body = document.querySelector('body');
    if (!body) return;

    const oppositeTheme = nextTheme === 'light' ? 'dark' : 'light';

    if (nextTheme === 'dark') {
      html.classList.remove(`${oppositeTheme}`);
      html.classList.add(`${nextTheme}`);
      body.classList.add(`bp5-${nextTheme}`);
    } else {
      html.classList.remove(`${oppositeTheme}`);
      body.classList.remove(`bp5-${oppositeTheme}`);
      html.classList.add(`${nextTheme}`);
    }

    if (persist) storage.saveThemePreference(nextTheme);
  }

  private restoreInitialTheme() {
    const storedTheme = storage.getThemePreference();
    if (storedTheme) this.theme = storedTheme;
    this.applyTheme(this.theme, false);

    return Boolean(storedTheme);
  }

  /* Create a new media query. Add on change event listener to listen for system theme changes to sync */
  private syncWithSystemTheme = () => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const apply = (check: boolean) => this.applyTheme(check ? 'dark' : 'light');
    apply(mediaQuery.matches);
    mediaQuery.addEventListener('change', event => apply(event.matches));
  };
}
