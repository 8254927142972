/* eslint-disable no-console */
export enum LogLevel {
  None,
  Error,
  Warn,
  Info,
  Debug,
  Trace,
}

type ConsoleMethod = (...args: Parameters<typeof console.log>) => void;

function print(methodLevel: LogLevel, consoleMethod: ConsoleMethod) {
  return (...args: Parameters<typeof console.log>) => {
    if (methodLevel <= logger.level) {
      consoleMethod(...args);
    }
  };
}

export const logger = {
  level: (process.env.NODE_ENV || 'dev').startsWith('dev') ? LogLevel.Debug : LogLevel.None,

  info: print(LogLevel.Info, console.info),

  warn: print(LogLevel.Warn, console.warn),

  error: print(LogLevel.Error, console.error),

  log: print(LogLevel.Debug, console.log),

  debug: print(LogLevel.Debug, console.debug),

  trace: print(LogLevel.Trace, console.trace),

  table: print(LogLevel.Trace, console.table),
};

(window as any).logger = logger;
