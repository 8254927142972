import { Authorization } from '~/authorization';
import { DataLayer, Event as DLEvent } from '~/data-layer';
import { ConnectEvent } from '~/data-layer/connect-event';
import { Application } from '~/domain/common';
import { FeatureFlags } from '~/domain/features';
import { Flow } from '~/domain/flows';
import { FlowDigest } from '~/domain/flows/common';
import { DataMode, TransferState } from '~/domain/interactions';
import { ServiceCard } from '~/domain/service-map';
import { TimeRange } from '~/domain/time';
import { Event as REvent, Router } from '~/router';
import { Store } from '~/store';
import * as ui from '~/ui';
import { SSRError } from '~/ui/ssr';
import { logger } from '~/utils/logger';

import { Cimulator } from './cimulator';
import { CommonUtils, Options } from './common';
import { Controls } from './controls';
import { ErrorPage } from './error-page';
import { ProcessTree } from './process-tree';
import { ServiceMap } from './service-map';
import { StatusCenter } from './status-center';

export class UILayer {
  public readonly statusCenter: StatusCenter;
  public readonly controls: Controls;
  public readonly serviceMap: ServiceMap;
  public readonly processTree: ProcessTree;
  public readonly cimulator: Cimulator;
  public readonly errorPage: ErrorPage;

  private readonly transferState: TransferState;

  constructor(
    public router: Router,
    private store: Store,
    private dataLayer: DataLayer,
    private isCSSVarsInjectionEnabled: boolean,
  ) {
    this.transferState = dataLayer.transferState;
    this.statusCenter = new StatusCenter({
      maxNotifications: 100,
    });

    this.controls = new Controls(this.commonOpts);
    this.serviceMap = new ServiceMap(this.commonOpts);
    this.processTree = new ProcessTree(this.commonOpts);
    this.cimulator = new Cimulator(this.commonOpts);
    this.errorPage = new ErrorPage(this.commonOpts);

    this.setupEventHandlers();
  }

  public get commonOpts(): Options {
    return {
      statusCenter: this.statusCenter,
      router: this.router,
      store: this.store,
      dataLayer: this.dataLayer,
      utils: this.utils,
    };
  }

  private get utils(): CommonUtils {
    return {};
  }

  public onMounted() {
    this.extractInjectedData();
  }

  public onBeforeMount() {
    this.injectCSSVars();
  }

  public async flowDigestSelected(fd: FlowDigest | null) {
    logger.log('about to select flow digest: ', fd);
    if (fd == null) {
      this.store.controls.selectTableFlow(null);
      return;
    }

    const flow = this.store.currentFrame.interactions.flowsMap.get(fd.id);
    if (flow != null) {
      this.store.controls.selectTableFlow(flow);
      return;
    }

    if (this.transferState.isCiliumStreaming) {
      logger.warn('No full flow found in streaming mode, flowId: ', fd.id);
      return;
    }

    const fullFlow = await this.dataLayer.serviceMap.loadFullFlow(fd);
    this.store.controls.selectTableFlow(fullFlow);
  }

  public async reviewFlowInPolicyEditor(f: Flow | null) {
    if (f == null) return;

    const policy = f.getDropReasonPolicy();
    if (policy == null || !policy.uuid) {
      this.router.openPolicy(null).commit();
    } else {
      this.router.openPolicy(policy.uuid).commit();
    }
  }

  public getActiveServices(): ServiceCard[] {
    return this.store.currentFrame.services.cardsList.filter(card => {
      return this.dataLayer.controls.areSomeFilterGroupsEnabled(card.filterGroups);
    });
  }

  public async applicationChanged(app: Application) {
    const { uiSettings } = this.store;

    if (uiSettings.isTetragonOnlyEnabled && app === Application.ConnectionsMap) {
      this.applicationChanged(Application.Dashboard);
      return;
    }

    // NOTE: Do not call `store.controls.setCurrentApp` here, it would disallow
    // UILayer to detect application change.
    this.router.openApplication(app);

    this.router.commit();
  }

  public async advanceTimescapeFlowsPager() {
    await this.serviceMap.advanceTimescapeFlowsPager();
  }

  public async toggleDataMode() {
    const currCluster = this.store.clusterNamespaces.currCluster;
    const currNamespace = this.store.clusterNamespaces.currNamespace;
    const clustersList = this.store.clusterNamespaces.clustersList;

    if (!currCluster && currNamespace && this.transferState.isCiliumStreaming) {
      if (clustersList.length > 1) {
        if (!(await this.serviceMap.askUserFiltersDrop())) {
          return;
        }
        this.controls.clusterNamespaceChanged(null, null);
        this.router.dropSearchParams();
      }
      this.router.commit();
    }
    await this.toggleDataModeOnNamespace();
  }

  public async toggleDataModeOnNamespace() {
    this.serviceMap.clearCoordinates();

    if (this.transferState.isCiliumStreaming) {
      await this.switchToTimescapeMode();
    } else {
      await this.switchToLiveMode();
    }
  }

  public async switchToTimescapeMode() {
    this.store.clusterNamespaces.setDataMode(DataMode.WatchingHistory);
    this.store.flush({ preserveActiveCards: true, globalFrame: true, policies: true });
    await this.dataLayer.switchToDataMode(DataMode.WatchingHistory);
  }

  public async switchToLiveMode() {
    this.store.clusterNamespaces.setDataMode(DataMode.CiliumStreaming);
    this.store.flush({ preserveActiveCards: true, globalFrame: true, policies: true });
    await this.dataLayer.switchToDataMode(DataMode.CiliumStreaming);
  }

  private injectCSSVars() {
    if (this.isCSSVarsInjectionEnabled) {
      ui.setCSSVars(ui.sizes);
      ui.setCSSVarsZIndex(ui.zIndex);
    }
  }

  private extractInjectedData() {
    this.dataLayer
      .injectionReader()
      .onSSRErrorsReadError(err => {
        logger.error(`ssr errors read error: `, err);
        this.statusCenter.pushSSRErrorReadError(err);
      })
      .onFeatureFlagsError(err => {
        logger.error('feature flags error: ', err);
        this.statusCenter.pushFetchUISettingsError(err);
      })
      .onAuthorizationError(err => {
        logger.error('authz error: ', err);
        this.statusCenter.pushFetchAuthorizationError(err);
      })
      .getSSRErrors()
      .getFeatureFlags()
      .getAuthorization();
  }

  private setupEventHandlers() {
    this.dataLayer.onUnauthorized(() => {
      Authorization.goToSignin();
    });

    this.dataLayer.once(DLEvent.SSRErrorSet, ssrError => {
      logger.log(`ssr error set: `, ssrError);
      if (ssrError == null) return;

      this.router.openSSRErrorPage().commit();
    });

    this.dataLayer.once(DLEvent.FeatureFlagsSet, ff => {
      logger.log(`feature flags set: `, ff);
      this.trySetupEverything();
    });

    this.router.once(REvent.Initialized, () => {
      logger.log(`router is initialized`);
      this.trySetupEverything();
    });

    this.dataLayer.serviceMap.onConnectEvent(ce => {
      this.handleConnectEvent(ce);
    });
  }

  private handleConnectEvent(ce: ConnectEvent) {
    if (ce.isSuccess && ce.isAllReconnected) {
      this.statusCenter.pushStreamsReconnected();
    } else if (ce.isAttemptDelay && ce.delay) {
      this.statusCenter.pushStreamsReconnectingDelay(ce.delay);
    } else if (ce.isFailed) {
      if (ce.attempt === 1) this.statusCenter.pushStreamsReconnecting();
      if (ce.error != null) {
        this.statusCenter.pushStreamsReconnectFailed(ce.error);
      }
    } else if (ce.isDisconnected) {
      this.statusCenter.pushStreamsReconnecting();
    }
  }

  private trySetupEverything() {
    const { uiSettings } = this.store;
    if (!uiSettings.isFeaturesSet || !this.router.isInitialized || !uiSettings.isSSRErrorSet) {
      logger.log(`trySetupEverything is prevented`);
      return;
    }

    this.setupEverything(this.store.uiSettings.featureFlags, this.store.uiSettings.ssrError);
  }

  private setupEverything(ff: FeatureFlags, ssrError: SSRError | null) {
    if (ssrError != null) return;

    this.transferState.setDataModeModifiers({
      timescapeOnly: !!ff.timescapeOnly.enabled,
      tetragonOnly: !!ff.tetragon.only,
    });

    // NOTE: The only thing `applyLocalParameters` doesnt do is setting current
    // application inside control store. This allows to decide whether we need
    // to switch there or not here, on higher level of responsibility.
    this.controls.setupControlStream();

    this.router.onLocationUpdated(async evt => {
      const currApp = this.router.getCurrentApplication();
      const [, isChanged] = this.store.controls.setCurrentApp(currApp);

      // NOTE: Location is "detached" if it was changed not by application controls
      // i e for example by pressing "back" button in the browser
      if (evt.isDetached) {
        // NOTE: We reapply all the application parameters here and the main
        // complexity is to notify all other parts of UI of that, not triggering
        // recursive emits...
        await this.reapplyApplicationState();
      }

      await this.appToggled(currApp, isChanged);
      this.controls.adjustTransferState(this.store.clusterNamespaces.currDescriptor);
    });

    // NOTE: FiltersChanged event is not triggered on first application open
    this.dataLayer.controls.onFiltersChanged(async f => {
      await this.dataLayer.filtersChanged(f);
    });
  }

  private async reapplyApplicationState() {
    const filtersDiff = this.dataLayer.controls.filtersDiff;
    this.applyLocalParameters();
    filtersDiff.step(this.dataLayer.controls.modalFilters);

    if (filtersDiff.changed) {
      logger.log(`filters are changed after applyLocalParameters`, filtersDiff);
      await this.dataLayer.filtersChanged(filtersDiff);
    }

    this.serviceMap.toggleDetached();
  }

  private async appToggled(nextApp: Application, isChanged: boolean) {
    await this.serviceMap.appToggled(nextApp, isChanged);
    await this.processTree.appToggled(nextApp, isChanged);
    await this.cimulator.appToggled(nextApp, isChanged);
  }

  private applyLocalParameters(): void {
    const routeParams = this.router.getRouteParams();
    const storageParams = this.dataLayer.readLocalStorageParams();

    logger.log(`applying local params: `, routeParams, storageParams);

    // NOTE: All these setters are used directly without DataLayer not to trigger
    // for example router to reemit events of params update. This is the initialization
    // of the app.
    this.store.controls.setShowHost(storageParams.isHostShown);
    this.store.controls.setShowKubeDns(storageParams.isKubeDNSShown);
    this.store.controls.setHttpStatus(routeParams.httpStatus);
    this.store.controls.setFlowFilterGroups(routeParams.flowFilterGroups);
    this.store.controls.setVerdicts(routeParams.verdicts);

    if (routeParams.timeRangeFrom != null && routeParams.timeRangeTo != null) {
      const tr = TimeRange.parseFromTo(routeParams.timeRangeFrom, routeParams.timeRangeTo);

      logger.log('parsed TimeRange: ', tr);
      if (tr != null) this.store.controls.setTimeRange(tr);
    }

    const aggOffByRoute = routeParams.aggregation === false;
    const aggOffByStorage = storageParams.isAggregationOff === true;
    const aggDisabled = aggOffByRoute || aggOffByStorage;
    this.store.controls.toggleAggregation(!aggDisabled);

    this.store.clusterNamespaces.setClusterNamespace(routeParams.cluster, routeParams.namespace);

    this.transferState.setDataMode(
      this.store.uiSettings.isTimescapeEnabled
        ? DataMode.WatchingHistory
        : DataMode.CiliumStreaming,
    );

    this.router.commit();
  }
}
