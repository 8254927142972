import React from 'react';

import { observer } from 'mobx-react';

import { useApplication } from '~/application';
import { PublicLinks } from '~/branding';

import css from './styles.scss';

export const SSRErrorPage = observer(function SSRErrorPage() {
  const { store } = useApplication();
  const ssrError = store.uiSettings.ssrError;
  const docsLink = PublicLinks.isovalentEnterpriseDocs;

  // NOTE: Control logic of what page is rendered should be located inside
  // the UI Layer
  if (ssrError == null) return;

  return (
    <div className={css.ssrErrorPage}>
      <div className={css.content}>
        <div className={css.caption}>Error {ssrError.httpStatus || ''}</div>
        <div className={css.subcaption}>
          Hubble UI backend failed to render the page.
          {!!ssrError.component && (
            <span>
              {` `}It happened on interaction with <b>{ssrError.component}</b> service.
            </span>
          )}
          {` `}You can find the details in the Hubble UI pod logs using following commands:
        </div>

        <div className={css.infoFrame}>
          {/*prettier-ignore*/}
          <pre>{`HUBBLE_UI_NAMESPACE=hubble-ui
HUBBLE_UI_POD=$(kubectl get pod -n $HUBBLE_UI_NAMESPACE \\
  -l k8s-app=hubble-ui \\
  -o jsonpath="{.items[0].metadata.name}" \\
)

kubectl logs -n $HUBBLE_UI_NAMESPACE pod/$HUBBLE_UI_POD --all-containers`}
          </pre>
        </div>

        <div className={css.subcaption}>
          Please refer to{` `}
          <a href={ssrError.docsLink || docsLink} target="_blank" rel="noreferrer">
            Isovalent documentation
          </a>{' '}
          to fix possible misconfiguration issue.
        </div>
      </div>
    </div>
  );
});
