import { Aggregation } from '~/domain/aggregation';
import { Diffable } from '~/domain/diff';
import { Verdict } from '~/domain/flows';

import { FilterGroup } from './filter-entry';
import { FiltersDiff } from './filters-diff';
import { TimeRange } from '../time';

const assignFilterProps = (to: FiltersObject, from: FiltersObject) => {
  Object.assign(to, {
    verdicts: from.verdicts,
    httpStatus: from.httpStatus,
    filterGroups: from.filterGroups,
    skipHost: from.skipHost,
    skipKubeDns: from.skipKubeDns,
    skipRemoteNode: from.skipRemoteNode,
    skipPrometheusApp: from.skipPrometheusApp,
    aggregation: from.aggregation,
    timeRange: from.timeRange,
  });

  return to;
};

export interface FiltersObject {
  verdicts?: Set<Verdict>;
  httpStatus?: string | null;
  filterGroups?: FilterGroup[];
  skipHost?: boolean;
  skipKubeDns?: boolean;
  skipRemoteNode?: boolean;
  skipPrometheusApp?: boolean;

  aggregation?: Aggregation | null;
  timeRange?: TimeRange | null;
}

export type FiltersKey = keyof FiltersObject;

export class Filters implements FiltersObject, Diffable<Filters, FiltersDiff> {
  public verdicts?: Set<Verdict>;
  public httpStatus?: string | null;
  public filterGroups?: FilterGroup[];
  public skipHost?: boolean;
  public skipKubeDns?: boolean;
  public skipRemoteNode?: boolean;
  public skipPrometheusApp?: boolean;
  public aggregation?: Aggregation;
  public timeRange?: TimeRange;

  public static fromObject(obj: FiltersObject): Filters {
    return new Filters(obj);
  }

  public static defaultObject(): FiltersObject {
    return {
      verdicts: new Set(),
      httpStatus: null,
      filterGroups: [],
      skipHost: false,
      skipKubeDns: false,
      skipRemoteNode: false,
      skipPrometheusApp: false,
      aggregation: null,
      timeRange: null,
    };
  }

  public static default(): Filters {
    return new Filters(Filters.defaultObject());
  }

  constructor(obj: FiltersObject) {
    assignFilterProps(this, obj);
  }

  public setTimeRange(timeRange: TimeRange | null) {
    this.timeRange = timeRange ?? undefined;

    return this;
  }

  public addFilterEntryGroup(group: FilterGroup) {
    if (this.filterGroups == null) {
      this.filterGroups = [];
    }

    this.filterGroups.push(group);
  }

  public toPlainObject(): FiltersObject {
    return assignFilterProps({} as FiltersObject, this);
  }

  // TODO: write tests for a new behavior regarding since/until fields
  public equals(rhs: FiltersObject): boolean {
    if (
      this.httpStatus != rhs.httpStatus ||
      this.skipHost != rhs.skipHost ||
      this.skipKubeDns != rhs.skipKubeDns ||
      this.skipRemoteNode != rhs.skipRemoteNode ||
      this.skipPrometheusApp != rhs.skipPrometheusApp
    ) {
      return false;
    }

    if (!TimeRange.checkEquality(this.timeRange, rhs.timeRange)) return false;

    if (!!this.aggregation !== !!rhs.aggregation) return false;
    if (this.aggregation && !this.aggregation.equals(rhs.aggregation!)) {
      return false;
    }

    const aVerdictsEntries = this.verdicts ?? new Set();
    const bVerdictsEntries = rhs.verdicts ?? new Set();
    if (aVerdictsEntries.size !== bVerdictsEntries.size) return false;
    for (const verdict of aVerdictsEntries) if (!bVerdictsEntries.has(verdict)) return false;

    const aFilterGroups = (this.filterGroups || []).reduce((acc, f) => {
      acc.add(f.toString());
      return acc;
    }, new Set<string>());

    const bFilterGroups = (rhs.filterGroups || []).reduce((acc, f) => {
      acc.add(f.toString());
      return acc;
    }, new Set<string>());

    if (aFilterGroups.size !== bFilterGroups.size) return false;

    for (const f of aFilterGroups) {
      if (!bFilterGroups.has(f)) return false;
    }

    return true;
  }

  public clone(deep = false): Filters {
    const shallowObj: FiltersObject = this.toPlainObject();

    if (deep) {
      shallowObj.filterGroups = (shallowObj.filterGroups || []).map(group => group.clone());
    }

    if (this.aggregation != null) {
      shallowObj.aggregation = this.aggregation.clone(deep);
    }

    if (deep && shallowObj.timeRange != null) {
      shallowObj.timeRange = shallowObj.timeRange.clone();
    }

    return Filters.fromObject(shallowObj);
  }

  public diff(rhs?: Filters | null): FiltersDiff {
    if (rhs == null) return FiltersDiff.fromFilters(this).invert();

    return FiltersDiff.new(this, rhs);
  }
}
