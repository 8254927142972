import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Button, ButtonGroup, Icon, NonIdealState, Tooltip } from '@blueprintjs/core';
import classnames from 'classnames';
import { observer } from 'mobx-react';

import { YAMLEditor } from '~/components/YAMLEditor';
import { K8SEvent } from '~/domain/timescape/k8s-events';
import { useStore } from '~/store/stores/cimulator';
import { AnalyticsTrackKind, track } from '~/utils/analytics';

import { PolicyKindSelector } from './PolicyKindSelector';
import { PolicyVersionSelector } from './PolicyVersionSelector';
import { PolicyWarning } from './PolicyWarning';
import css from './YamlPanel.scss';

export enum E2E {
  yamlEditor = 'yaml-editor',
}

export interface Props {
  policyYaml: string | null;
  isDarkThemeEnabled: boolean;
  selectedCardId?: string | null;
  selectedEndpointId?: string | null;
  policyVersions?: K8SEvent[];
  selectedPolicyVersion?: K8SEvent | null | undefined;
  onDownloadYaml?: () => void;
  onUploadPolicy?: (event: React.SyntheticEvent) => void;
  onCreateNewPolicy?: () => void;
  onSelectPolicyVersion?: (event: K8SEvent, restoreOrigin: boolean) => void;
}

export const YamlPanel = observer(function YamlPanel(props: Props) {
  const store = useStore();

  const selectedCard = useMemo(() => {
    return store.policy.cardsList?.find(c => c.id === props.selectedCardId);
  }, [store.policy.cardsList, props.selectedCardId]);

  const [comparePolicy, setComparePolicy] = useState<boolean>(false);
  useEffect(() => setComparePolicy(false), [props.selectedPolicyVersion?.isUpdated]);
  const onComparePolicy = useCallback(() => setComparePolicy(prev => !prev), []);

  const selectedEndpoint = useMemo(() => {
    return selectedCard?.partialEndpointsList?.find(e => e.id === props.selectedEndpointId);
  }, [selectedCard?.partialEndpointsList, props.selectedEndpointId]);

  const isSelectedEndpointAllowed = useMemo(() => {
    if (!selectedCard || !selectedEndpoint) return false;
    return store.policy.isAllowedEndpoint(selectedCard.fullEndpointId(selectedEndpoint.id));
  }, [selectedCard, selectedEndpoint, store.policy.allowedEndpointsSet]);

  const onClickCreateNewPolicy = useCallback(() => {
    props.onCreateNewPolicy?.();
    track(AnalyticsTrackKind.CreatePolicy, {
      source: 'yaml-panel',
    });
  }, [props.onCreateNewPolicy]);

  const onClickUploadPolicy = useCallback(
    (event: React.SyntheticEvent) => {
      track(AnalyticsTrackKind.UploadPolicy, {
        source: 'yaml-panel',
      });
      props.onUploadPolicy?.(event);
    },
    [props.onUploadPolicy],
  );

  const onPrevSpec = useCallback(() => {
    store.policy.goToPrevSpec();
  }, []);

  const onNextSpec = useCallback(() => {
    store.policy.goToNextSpec();
  }, []);

  const cardEndpointYaml = useMemo(() => {
    if (!selectedCard) return null;
    return store.policy.curPolicyKindBuilder.generateYamlForCardEndpoint(
      selectedCard,
      (isSelectedEndpointAllowed ? selectedEndpoint : null) ?? null,
      selectedCard.isSelector ? false : true,
    );
  }, [
    selectedCard?.hash,
    selectedEndpoint?.hash,
    isSelectedEndpointAllowed,
    store.policy.curPolicyKindBuilder,
  ]);

  const editorClassName = classnames(css.editor, {
    [css.unsupportedWarningOpened]: store.policy.hasUnsupportedRules,
  });

  return (
    <div className={css.wrapper}>
      <div className={css.header}>
        <div className={css.left}>
          <div className={css.leftActions}>
            <ButtonGroup>
              <Tooltip
                minimal
                interactionKind="hover-target"
                content="Create new policy"
                position="top"
              >
                <Button
                  icon={<Icon size={13} icon="plus" />}
                  onClick={onClickCreateNewPolicy}
                  className={css.newYamlButton}
                >
                  New
                </Button>
              </Tooltip>
              <Tooltip
                minimal
                interactionKind="hover-target"
                content="Upload policy YAML"
                position="top"
              >
                <Button
                  icon={<Icon size={13} icon="upload" />}
                  onClick={onClickUploadPolicy}
                  className={css.uploadYamlButton}
                />
              </Tooltip>
              <Tooltip
                minimal
                interactionKind="hover-target"
                content="Download policy YAML"
                position="top"
              >
                <Button
                  intent="primary"
                  icon={<Icon size={13} icon="download" />}
                  className={css.downloadYamlButton}
                  onClick={props.onDownloadYaml}
                ></Button>
              </Tooltip>
            </ButtonGroup>
          </div>
          {Boolean(props.policyVersions?.length) && (
            <PolicyVersionSelector
              versions={props.policyVersions!}
              currentVersion={props.selectedPolicyVersion}
              comparing={comparePolicy}
              onChange={props.onSelectPolicyVersion}
              onCompare={onComparePolicy}
            />
          )}
        </div>
        <div className={css.center}>
          {(store.policy.currentSpecsCount ?? 0) > 1 && (
            <ButtonGroup className={css.specsSelector}>
              <Tooltip minimal content="Go to prev spec">
                <Button minimal onClick={onPrevSpec} icon="arrow-left"></Button>
              </Tooltip>
              <Tooltip minimal content="Go to next spec">
                <Button minimal onClick={onNextSpec} icon="arrow-right"></Button>
              </Tooltip>
            </ButtonGroup>
          )}
          {store.policy.policyKind && (
            <PolicyKindSelector
              curPolicyKind={store.policy.policyKind}
              onChange={store.controls.setPolicyKind}
            />
          )}
        </div>
        <div className={css.right}></div>
      </div>
      {store.policy.hasUnsupportedRules && <PolicyWarning />}
      <div className={editorClassName} data-testid={E2E.yamlEditor}>
        {!props.policyYaml && (
          <NonIdealState
            title="No policy to show"
            icon="document"
            description={
              <Button onClick={store.policy.createNew} className={css.newEmptyPolicyButton}>
                Create empty policy
              </Button>
            }
          />
        )}
        {props.policyYaml && (
          <YAMLEditor
            yaml={props.policyYaml}
            isDarkThemeEnabled={props.isDarkThemeEnabled}
            policyVersion={props.selectedPolicyVersion}
            compare={comparePolicy}
            yamlToHighlight={cardEndpointYaml ?? null}
          />
        )}
      </div>
    </div>
  );
});
