import React from 'react';

import { observer } from 'mobx-react';

import { Card, Props as CardProps } from '~/components/Card';
import { LoadingOverlay } from '~/components/Misc/LoadingOverlay';

import css from './styles.scss';

interface Props extends CardProps {
  loading?: boolean;
  selectedNamespace?: string | null;
  empty?: boolean;
}

export const ContainerCard = observer(function ContainerCard({
  loading,
  selectedNamespace,
  empty,
  ...props
}: Props) {
  return (
    <Card {...props} isUnsizedMode={false} className={css.outsideCard}>
      {loading && <LoadingOverlay height={'100%'} text={'Loading...'} />}
      {empty && (
        <div className={css.empty}>
          <div className={css.icon} />
          <div>
            No connections to visualize in selected {selectedNamespace ? 'namespace ' : 'cluster '}
            and time range
          </div>
        </div>
      )}
    </Card>
  );
});
