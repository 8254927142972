import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Button, Checkbox, Dialog, DialogBody, DialogFooter } from '@blueprintjs/core';
import classnames from 'classnames';
import _ from 'lodash';
import * as mobx from 'mobx';
import { observer } from 'mobx-react';

import { useApplication } from '~/application';
import { Authorization } from '~/authorization';
import { ClusterMap } from '~/components/ClusterMap';
import { DetailsPanel, ResizeProps as DetailsResizeProps } from '~/components/DetailsPanel';
import EmptyMapPlaceholder from '~/components/EmptyMapPlaceholder';
import { defaultVisibleColumns } from '~/components/FlowsTable';
import { getDefaultWidths, useFlowsTableColumns } from '~/components/hooks/useFlowTableColumns';
import { LiveMap } from '~/components/LiveMap';
import { NavigationSidebar } from '~/components/NavigationSidebar/NavigationSidebar';
import { filtersConfig } from '~/components/TopBar/filter-config';
import { FlowFiltersInputNew } from '~/components/TopBar/FlowFiltersInputNew/FlowFiltersInputNew';
import { FilterDirection, FilterEntry, FilterGroup } from '~/domain/filtering';
import { PodSelector, TCPFlagName, Verdict, Workload } from '~/domain/hubble';
import { DataMode } from '~/domain/interactions';
import { KV, Labels } from '~/domain/labels';
import { getFlowsTableVisibleColumns } from '~/storage/local';
import { sizes } from '~/ui/vars';
import { APIStatus } from '~/utils/api';

import css from './styles.scss';

const flowTableUniqueId = 'service-map';

export const ServiceMapApp = observer(function ServiceMapApp() {
  const { store, ui, dataLayer } = useApplication();

  const [mapVisibleHeight, setMapVisibleHeight] = useState<number>(0);
  const onFlowsDiffCount = useRef<(diff: number) => void>();
  const [selectedTab, setSelectedTab] = useState<'flows' | 'policies' | 'node-connectivity'>(
    'flows',
  );
  const isFiltersInputVisible = mobx
    .computed(() => store.clusterNamespaces.currNamespace !== null)
    .get();

  useEffect(() => {
    setFlowsWaitTimeout(false);
    if (!store.clusterNamespaces.currDescriptor) return;

    const stop = setTimeout(() => {
      setFlowsWaitTimeout(true);
    }, 5000);

    return () => clearTimeout(stop);
  }, [store.clusterNamespaces.currDescriptor]);

  useEffect(() => {
    return dataLayer.serviceMap
      .onFlowsDiffCount((diff, frame) => {
        if (store.currentFrame !== frame) return;

        onFlowsDiffCount.current?.(diff);
      })
      .disposer()
      .asFunction();
  }, [store.currentFrame]);

  const isRealtime = mobx
    .computed(() => dataLayer.transferState.dataMode === DataMode.CiliumStreaming)
    .get();

  const onAggregationChange = useCallback((toggledTo: boolean) => {
    ui.controls.aggregationChanged(toggledTo);
  }, []);

  const onLoginAgainClick = useCallback(() => {
    Authorization.goToSignin();
  }, []);

  const flowsTableColumns = useFlowsTableColumns(
    flowTableUniqueId,
    getFlowsTableVisibleColumns(flowTableUniqueId) ?? getDefaultWidths(defaultVisibleColumns),
  );
  const [flowsWaitTimeout, setFlowsWaitTimeout] = useState(false);

  const onCloseFlowsTableSidebar = useCallback(() => {
    store.controls.selectTableFlow(null);
  }, []);

  const onPanelResize = useCallback((resizeProps: DetailsResizeProps) => {
    const vh = resizeProps.panelTopInPixels - sizes.topBarHeight;
    setMapVisibleHeight(vh);
  }, []);

  const onSidebarVerdictClick = useCallback((verdict: Verdict) => {
    ui.controls.toggleVerdict(verdict);
  }, []);

  const onSidebarTCPFlagClick = useCallback((flag?: TCPFlagName, dir?: FilterDirection) => {
    if (!flag || !dir) return ui.controls.setFlowFilterGroups([]);

    ui.controls.setFlowFilterGroups([
      new FilterGroup([FilterEntry.newTCPFlag(flag).setDirection(dir)]),
    ]);
  }, []);

  const onSidebarLabelClick = useCallback((label?: KV, dir?: FilterDirection) => {
    if (!label || !dir) return ui.controls.setFlowFilterGroups([]);
    const labelStr = Labels.concatKV(label);

    ui.controls.setFlowFilterGroups([
      new FilterGroup([FilterEntry.newLabel(labelStr).setDirection(dir)]),
    ]);
  }, []);

  const onSidebarPodClick = useCallback((podSelector?: PodSelector, dir?: FilterDirection) => {
    if (!podSelector || !dir) return ui.controls.setFlowFilterGroups([]);

    ui.controls.setFlowFilterGroups([
      new FilterGroup([FilterEntry.newPodSelector(podSelector).setDirection(dir)]),
    ]);
  }, []);

  const onSidebarIdentityClick = useCallback((identity?: string, dir?: FilterDirection) => {
    if (identity == null || !dir) return ui.controls.setFlowFilterGroups([]);

    ui.controls.setFlowFilterGroups([
      new FilterGroup([FilterEntry.newIdentity(identity).setDirection(dir)]),
    ]);
  }, []);

  const onSidebarIpClick = useCallback((ip?: string, dir?: FilterDirection) => {
    if (ip == null || !dir) return ui.controls.setFlowFilterGroups([]);

    ui.controls.setFlowFilterGroups([new FilterGroup([FilterEntry.newIP(ip).setDirection(dir)])]);
  }, []);

  const onSidebarDnsClick = useCallback((dns?: string) => {
    if (dns == null) return ui.controls.setFlowFilterGroups([]);

    ui.controls.setFlowFilterGroups([
      new FilterGroup([FilterEntry.newDNS(dns).setDirection(FilterDirection.Either)]),
    ]);
  }, []);

  const onSidebarWorkloadClick = useCallback(
    (workload?: Workload, direction = FilterDirection.Either) => {
      if (workload == null) return ui.controls.setFlowFilterGroups([]);

      ui.controls.setFlowFilterGroups([
        new FilterGroup([FilterEntry.newWorkload(workload).setDirection(direction)]),
      ]);
    },
    [],
  );

  const onSidebarPortClick = useCallback((port?: number, direction = FilterDirection.Either) => {
    if (port == null) return ui.controls.setFlowFilterGroups([]);

    ui.controls.setFlowFilterGroups([
      new FilterGroup([FilterEntry.newPort(port).setDirection(direction)]),
    ]);
  }, []);

  const onSidebarProtocolClick = useCallback(
    (protocol?: string, direction = FilterDirection.Either) => {
      if (protocol == null) return ui.controls.setFlowFilterGroups([]);

      ui.controls.setFlowFilterGroups([
        new FilterGroup([FilterEntry.newProtocol(protocol).setDirection(direction)]),
      ]);
    },
    [],
  );

  const onFlowsScrolledToBottom = useCallback(
    _.debounce(() => {
      if (!dataLayer.transferState.isWatchingHistory || ui.serviceMap.isTimescapeFlowsPageLoading) {
        return;
      }

      ui.advanceTimescapeFlowsPager();
    }, 50),
    [dataLayer, ui],
  );

  const memoFiltersConfig = React.useMemo(() => {
    const isTimescapeMode = dataLayer.transferState.dataMode !== DataMode.CiliumStreaming;
    return filtersConfig({
      cluster: {
        enabled: isTimescapeMode && store.clusterNamespaces.hasClusters,
        list: store.clusterNamespaces.clustersList,
        component: 'select',
      },
      namespace: {
        enabled: true,
        list: store.clusterNamespaces.allClustersNamespacesList,
        component: 'select',
      },
    });
  }, [
    dataLayer.transferState.dataMode,
    store.clusterNamespaces.clustersList,
    store.clusterNamespaces.allClustersNamespacesList,
  ]);

  const filtersInput = (
    <div
      className={classnames(css.searchInputWrapper, {
        [css.backdrop]: store.clusterNamespaces.currNamespace,
        [css.hidden]: !isFiltersInputVisible,
      })}
    >
      <FlowFiltersInputNew
        filtersConfig={memoFiltersConfig}
        groups={store.controls.flowFilterGroups}
        onChange={filterGroups =>
          ui.controls.setFlowFilterGroups(filterGroups, {
            resetClusterNamespaceOnEmptyFilters: true,
          })
        }
      />
    </div>
  );

  const sidebar = (
    <NavigationSidebar
      clustersList={store.clusterNamespaces.clustersList}
      currCluster={store.clusterNamespaces.currCluster}
      transferState={dataLayer.transferState}
      namespacesList={store.clusterNamespaces.currClusterNamespacesList}
      currNamespace={store.clusterNamespaces.currNamespace}
      onClusterNamespaceChange={ui.controls.clusterNamespaceChanged}
      selectedVerdicts={store.controls.verdicts}
      onVerdictToggle={verdict => ui.controls.toggleVerdict(verdict)}
      onAppSelect={app => ui.applicationChanged(app)}
      aggregation={store.controls.aggregation || undefined}
      onAggregationChange={onAggregationChange}
      timeRange={store.controls.timeRange}
      onTimeRangeChange={tr => ui.controls.timeRangeChanged(tr)}
      isFlowsPageLoading={ui.serviceMap.isTimescapeFlowsPageLoading}
      showHost={ui.controls.isHostShown}
      onShowHostToggle={() => ui.controls.toggleShowHost()}
      showKubeDns={store.controls.showKubeDns}
      onShowKubeDnsToggle={() => ui.controls.toggleShowKubeDNS()}
      showRemoteNode={store.controls.showRemoteNode}
      onShowRemoteNodeToggle={() => ui.controls.toggleShowRemoteNode()}
      showPrometheusApp={store.controls.showPrometheusApp}
      onShowPrometheusAppToggle={() => ui.controls.toggleShowPrometheusApp()}
      onServiceMapLogs={logs => ui.serviceMap.uploadServiceMapLogs(logs)}
      isServiceMapLogsUploading={ui.serviceMap.isServiceMapLogsUploading}
      onLoginAgainClick={onLoginAgainClick}
    />
  );

  const detailsPanel = (
    <DetailsPanel
      namespace={store.clusterNamespaces.currNamespace ?? null}
      tab={selectedTab}
      transferState={dataLayer.transferState}
      filters={dataLayer.controls.modalFilters}
      flows={dataLayer.flowDigests}
      selectedFlow={store.controls.selectedTableFlow}
      selectedFlowIsLoading={ui.serviceMap.isFullFlowLoading}
      historyModeAvailable={store.uiSettings.isTimescapeEnabled}
      flowsWaitTimeout={flowsWaitTimeout}
      flowsTableVisibleColumns={flowsTableColumns.visible}
      flowsTableColumnWidths={flowsTableColumns.widths}
      timeRange={store.controls.timeRange}
      isFlowsPageLoading={ui.serviceMap.isTimescapeFlowsPageLoading}
      isFlowsStatsLoading={ui.serviceMap.isTimescapeFlowStatsLoading}
      isResizable={true}
      onSelectTab={setSelectedTab}
      onFlowsModeToggle={() => ui.toggleDataMode()}
      onSelectFlow={fd => ui.flowDigestSelected(fd)}
      onCloseSidebar={onCloseFlowsTableSidebar}
      onSidebarVerdictClick={onSidebarVerdictClick}
      onSidebarTCPFlagClick={onSidebarTCPFlagClick}
      onSidebarLabelClick={onSidebarLabelClick}
      onSidebarPodClick={onSidebarPodClick}
      onSidebarIdentityClick={onSidebarIdentityClick}
      onSidebarIpClick={onSidebarIpClick}
      onSidebarDnsClick={onSidebarDnsClick}
      onSidebarWorkloadClick={onSidebarWorkloadClick}
      onSidebarPortClick={onSidebarPortClick}
      onSidebarProtocolClick={onSidebarProtocolClick}
      onSidebarOpenProcessTreeForFlowClick={(f, d) => ui.serviceMap.openProcessTreeForFlow(f, d)}
      onPanelResize={onPanelResize}
      onFlowsDiffCount={onFlowsDiffCount}
      onFlowsTableColumnToggle={flowsTableColumns.toggle}
      onTimeRangeChange={tr => ui.controls.timeRangeChanged(tr)}
      onReviewFlowInPolicyEditor={f => ui.reviewFlowInPolicyEditor(f)}
      onFlowsScrolledToBottom={onFlowsScrolledToBottom}
      onFlowsTableColumnResize={flowsTableColumns.setWidths}
      isFiltersInputVisible={isFiltersInputVisible}
    />
  );

  const showLiveMap =
    isRealtime ||
    store.uiSettings.connectionsMapApiState === APIStatus.Unavailable ||
    !store.clusterNamespaces.hasClusters;

  const showClusterMap =
    !isRealtime &&
    store.uiSettings.connectionsMapApiState === APIStatus.Available &&
    store.clusterNamespaces.hasClusters;

  const isVizSupported = mobx.computed(() => dataLayer.controls.isVizSupporsFlowFilterGroups).get();

  return (
    <div className={css.app}>
      <Dialog
        title="Filters set are not supported in Connections"
        icon="warning-sign"
        isOpen={ui.serviceMap.showFilterDropWarning}
        onClose={() => ui.serviceMap.cancelFilterDrop()}
        isCloseButtonShown={false}
      >
        <DialogBody>
          <small>
            The filters set on Flows table page are not supported on Connections page and will
            therefore be reset to default values
          </small>
        </DialogBody>
        <DialogFooter
          className={css.dialogFooter}
          actions={
            <>
              <Checkbox
                checked={ui.serviceMap.shouldHideDropFIltersMessagePermanently}
                labelElement={<small>Don&apos;t show this message again</small>}
                onChange={() => {
                  ui.serviceMap.toggleHideDropFiltersMessage();
                }}
              />
              <Button
                intent="none"
                text="Cancel"
                onClick={() => ui.serviceMap.cancelFilterDrop()}
              />
              <Button
                intent="danger"
                text="Use default filters"
                onClick={() => ui.serviceMap.confirmFilterDrop()}
              />
            </>
          }
        />
      </Dialog>
      <div className={css.sidebar}>{sidebar}</div>
      <div className={css.workspace}>
        {filtersInput}
        {showLiveMap && isVizSupported ? (
          store.clusterNamespaces.currNamespace ? (
            <LiveMap mapVisibleHeight={mapVisibleHeight} />
          ) : (
            <EmptyMapPlaceholder
              prompt={
                isRealtime
                  ? 'Select a namespace in sidebar to view Service Map'
                  : 'Select cluster and namespace in sidebar to view Service Map'
              }
              height={mapVisibleHeight}
            />
          )
        ) : null}
        {showClusterMap && isVizSupported && <ClusterMap mapVisibleHeight={mapVisibleHeight} />}
        {!isVizSupported && (
          <EmptyMapPlaceholder
            prompt={
              showLiveMap && store.clusterNamespaces.currNamespace === null
                ? 'Select a namespace in sidebar to view Service Map'
                : 'Visualization is not supported for specified flow filters'
            }
            height={mapVisibleHeight}
          />
        )}
        {detailsPanel}
      </div>
    </div>
  );
});
