/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter add_pb_suffix,eslint_disable,ts_nocheck,generate_dependencies,long_type_number,use_proto_field_name
// @generated from protobuf file "timescape/v1/time_filter.proto" (package "timescape.v1", syntax proto3)
// tslint:disable
// @ts-nocheck
//
// Copyright (C) Isovalent, Inc. - All Rights Reserved.
//
// NOTICE: All information contained herein is, and remains the property of
// Isovalent Inc and its suppliers, if any. The intellectual and technical
// concepts contained herein are proprietary to Isovalent Inc and its suppliers
// and may be covered by U.S. and Foreign Patents, patents in process, and are
// protected by trade secret or copyright law.  Dissemination of this
// information or reproduction of this material is strictly forbidden unless
// prior written permission is obtained from Isovalent Inc.
//
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Timestamp } from "../../google/protobuf/timestamp_pb";
/**
 * TimeFilter is a filter that allows to specify a starting time, ending time
 * or time range.
 *
 * @generated from protobuf message timescape.v1.TimeFilter
 */
export interface TimeFilter {
    /**
     * since defines the starting time for the filter (inclusive).
     *
     * @generated from protobuf field: google.protobuf.Timestamp since = 1;
     */
    since?: Timestamp;
    /**
     * until defines the ending time for the filter (inclusive).
     *
     * @generated from protobuf field: google.protobuf.Timestamp until = 2;
     */
    until?: Timestamp;
}
// @generated message type with reflection information, may provide speed optimized methods
class TimeFilter$Type extends MessageType<TimeFilter> {
    constructor() {
        super("timescape.v1.TimeFilter", [
            { no: 1, name: "since", kind: "message", T: () => Timestamp },
            { no: 2, name: "until", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value?: PartialMessage<TimeFilter>): TimeFilter {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<TimeFilter>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TimeFilter): TimeFilter {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* google.protobuf.Timestamp since */ 1:
                    message.since = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.since);
                    break;
                case /* google.protobuf.Timestamp until */ 2:
                    message.until = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.until);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TimeFilter, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* google.protobuf.Timestamp since = 1; */
        if (message.since)
            Timestamp.internalBinaryWrite(message.since, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp until = 2; */
        if (message.until)
            Timestamp.internalBinaryWrite(message.until, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message timescape.v1.TimeFilter
 */
export const TimeFilter = new TimeFilter$Type();
