import { makeAutoObservable } from 'mobx';

import { FilterEntry, FilterGroup, Filters } from '~/domain/filtering';
import * as helpers from '~/domain/helpers';
import { LogEntries } from '~/domain/helpers/process-events';
import { NamespaceDescriptor } from '~/domain/namespaces';
import { ProcessEvent } from '~/domain/process-events';
import { Tree as TreeData } from '~/domain/process-tree';
import { PodInfo } from '~/domain/timescape';
import { logger } from '~/utils/logger';

import { ClusterNamespaceStore } from './cluster-namespace';
import SettingsStore from './ui-settings';

export class ProcessTreeStore {
  // NOTE: Map { namespace -> { podName -> PodInfo }}
  private _timescapePods: Map<string, Map<string, PodInfo>>;

  // NOTE: Map { namespace -> { podName -> ProcessEvent[] }}
  private _podProcessEvents: Map<string, Map<string, ProcessEvent[]>>;

  private uiSettings: SettingsStore;
  private clusterNamespace: ClusterNamespaceStore;

  private uploadedFileSelectedNamespace: string | null = null;
  public isUsingUploadedFile = false;
  private _currentPodName: string | null = null;

  private _podPrefilter: string | null | undefined = null;

  public tree: TreeData = new TreeData();

  // TODO: Remove that FeaturesStore and NamespaceStore deps from this store.
  // They should be used in appropriate ui-layer or smth like that.
  constructor(uiSettings: SettingsStore, namespace: ClusterNamespaceStore) {
    this._timescapePods = new Map();
    this._podProcessEvents = new Map();

    this.uiSettings = uiSettings;
    this.clusterNamespace = namespace;

    makeAutoObservable(this, void 0, {
      autoBind: true,
    });
  }

  public set podPrefilter(filter: string | null | undefined) {
    this._podPrefilter = filter;
  }

  public get podPrefilter(): string | null | undefined {
    return this._podPrefilter;
  }

  public get eventsFilters(): Filters {
    const f = Filters.default();

    const ns = this.currentNamespace;
    const podName = this.currentPodName;
    if (ns == null || podName == null) return f;

    f.addFilterEntryGroup(
      new FilterGroup([FilterEntry.newNamespace(ns), FilterEntry.newPod(podName)]),
    );

    return f;
  }

  public get currentEvents(): ProcessEvent[] {
    const ns = this.currentNamespace;
    const podName = this.currentPodName;
    if (ns == null || podName == null) return [];

    const podEventsMap = this.podEvents.get(ns)?.get(podName);
    return podEventsMap == null ? [] : [...podEventsMap.values()];
  }

  public get currentPodName(): string | undefined {
    return this._currentPodName ?? void 0;
  }

  public get timescapePods() {
    return (ns?: string | null): PodInfo[] => {
      if (!ns) return [];

      const pods = this._timescapePods.get(ns)?.values();
      if (!pods) return [];

      return [...pods];
    };
  }

  public get timescapePodsMap(): ProcessTreeStore['_timescapePods'] {
    return new Map(this._timescapePods);
  }

  public get timescapePodNames(): Map<string, string[]> {
    const m = new Map();

    this._timescapePods.forEach((nsPods, ns) => {
      const podNames = nsPods.keys();

      m.set(ns, [...podNames]);
    });

    return m;
  }

  public get podEvents() {
    return new Map(this._podProcessEvents);
  }

  public get namespaces(): NamespaceDescriptor[] {
    if (!this.isLocalMode) {
      return this.clusterNamespace.currClusterNamespaceDescriptors;
    }

    return [...this._podProcessEvents.keys()].map(ns => ({
      // TODO: put currect cluster here
      cluster: '',
      timescape: true,
      relay: false,
      namespace: ns,
    }));
  }

  public get currentNamespace(): string | null {
    if (!this.isLocalMode) {
      return this.clusterNamespace.currNamespace;
    }

    return this.uploadedFileSelectedNamespace ? this.uploadedFileSelectedNamespace : null;
  }

  public get availablePods(): string[] {
    const ns = this.isLocalMode
      ? this.uploadedFileSelectedNamespace
      : this.clusterNamespace.currNamespace;

    const podsFromEvents = this._podProcessEvents.get(ns ?? '');
    const timescapePods = this._timescapePods.get(ns ?? '');
    const set = new Set<string>();

    podsFromEvents?.forEach((_, podName) => {
      set.add(podName);
    });

    timescapePods?.forEach((_, podName) => {
      set.add(podName);
    });

    return [...set].sort((a, b) => a.localeCompare(b));
  }

  public get isLocalMode(): boolean {
    return this.isUsingUploadedFile || !this.timescapeEnabled;
  }

  private get timescapeEnabled(): boolean {
    return this.uiSettings.isTimescapeEnabled;
  }

  public clear() {
    this.uploadedFileSelectedNamespace = null;
    this.isUsingUploadedFile = false;

    this.clearTimeRangeDependentData();
  }

  public clearTimeRangeDependentData() {
    this._currentPodName = null;
    this._timescapePods.clear();
    this._podProcessEvents.clear();
  }

  public clone(): ProcessTreeStore {
    const s = new ProcessTreeStore(this.uiSettings, this.clusterNamespace);

    this._timescapePods.forEach((nsPods, ns) => {
      s.upsertTimescapePods(ns, [...nsPods.values()]);
    });

    s.isUsingUploadedFile = this.isUsingUploadedFile;
    s.uploadedFileSelectedNamespace = this.uploadedFileSelectedNamespace;

    return s;
  }

  public selectPod(podName?: string | null): boolean {
    const isChanged = this._currentPodName !== podName;

    this._currentPodName = podName ?? null;

    if (!this.isLocalMode) {
      if (isChanged) {
        this._podProcessEvents.clear();
      }
    }

    return isChanged;
  }

  public selectNamespace(ns?: string | null): boolean {
    if (!this.isLocalMode) {
      this._timescapePods.clear();
      this._podProcessEvents.clear();
    }

    // NOTE: This case is handled by `onNamespaceChanged` in the app
    if (!this.isUsingUploadedFile) return this.isLocalMode;

    this.uploadedFileSelectedNamespace = ns ?? null;
    this._currentPodName = null;

    return this.isLocalMode;
  }

  public upsertTimescapePods(ns: string, pods: PodInfo[]) {
    if (!this._timescapePods.has(ns)) {
      this._timescapePods.set(ns, new Map());
    }

    const nsPods = this._timescapePods.get(ns);
    pods.forEach(pod => {
      nsPods?.set(pod.name, pod);
    });
  }

  public replaceTimescapePods(ns: string, pods: PodInfo[]) {
    this._timescapePods.get(ns)?.clear();
    this.upsertTimescapePods(ns, pods);
  }

  public upsertEvents(events: ProcessEvent[], ns?: string, podName?: string) {
    events.forEach(evt => {
      const pod = podName ?? evt.pod?.name;
      if (pod == null) {
        return;
      }

      const namespace = ns ?? evt.pod?.namespace;
      if (namespace == null) {
        return;
      }

      if (!this._podProcessEvents.has(namespace)) {
        this._podProcessEvents.set(namespace, new Map());
      }
      const nsEvents = this._podProcessEvents.get(namespace);

      if (!nsEvents?.has(pod)) {
        nsEvents?.set(pod, []);
      }

      const podEvents = nsEvents?.get(pod);
      podEvents?.push(evt);
    });
  }

  public replaceEvents(events: ProcessEvent[], ns?: string, podName?: string) {
    // this.clear();
    this.upsertEvents(events, ns, podName);
    this.tree.replaceEvents(this.currentEvents);
    logger.log('replacing events: ', events, this.podEvents);
  }

  public useUploadedParsedEvents(evts: ProcessEvent[]) {
    this.replaceEvents(evts);
    logger.log('_podProcessEvents: ', this._podProcessEvents);

    this.isUsingUploadedFile = true;
    this._currentPodName = null;
    this.uploadedFileSelectedNamespace = null;
  }

  public useUploadedEvents(eventsString: string): LogEntries {
    const logEntries = helpers.processEvents.parse(eventsString, {
      wrapFlows: true,
    });

    this.useUploadedParsedEvents(logEntries.processEvents);
    return logEntries;
  }

  public dropUploadedFile() {
    this.isUsingUploadedFile = false;
    this.clear();
  }
}

export default ProcessTreeStore;
