import * as ffpb from '~backend/proto/ui/feature-flags_pb';

import { FeatureFlags, GrafanaDashboards } from '../features';

export const fromPb = (response?: ffpb.GetFeatureFlagsResponse | null): FeatureFlags | null => {
  if (response == null) return null;

  let grafanaDashboard: GrafanaDashboards | null = null;
  if (response.grafana?.dashboard_json) {
    try {
      grafanaDashboard = JSON.parse(response.grafana?.dashboard_json) as GrafanaDashboards;
    } catch (e) {
      // Nothing
    }
  }

  return FeatureFlags.default()
    .setTimescapeEnabled(!!response.timescape?.enabled)
    .setTimescapeOnlyEnabled(!!response.timescape_only?.enabled)
    .setServiceMapUploadEnabled(!!response.service_map_file_upload?.enabled)
    .setGrafana(
      !!response.grafana?.enabled,
      !!response.grafana?.enabled_nodes_graph,
      grafanaDashboard,
    )
    .setTetragon(!!response.tetragon?.only);
};
