import React from 'react';

import classNames from 'classnames';

import css from './ButtonGroupFilter.scss';
import { FlowFilterGroupFormBaseComponentProps } from '../types';

export const ButtonGroupFilter = function ButtonGroupFilter(
  props: FlowFilterGroupFormBaseComponentProps,
) {
  const { config, filterState, onFilterChange } = props;
  const { label } = config;

  const makeButtonClassName = (direction: string) =>
    classNames([
      css.directionBtn,
      {
        [css.selected]: filterState === direction,
      },
    ]);

  return (
    <div className={css.section}>
      <div className={css.label}>{label}</div>
      <div className={css.btnGroup}>
        <button
          className={makeButtonClassName('either')}
          type="button"
          name="either"
          onClick={e => {
            onFilterChange(e.currentTarget.name);
          }}
        >
          Either
        </button>
        <button
          className={makeButtonClassName('from')}
          type="button"
          name="from"
          onClick={e => {
            onFilterChange(e.currentTarget.name);
          }}
        >
          From
        </button>
        <button
          className={makeButtonClassName('to')}
          type="button"
          name="to"
          onClick={e => {
            onFilterChange(e.currentTarget.name);
          }}
        >
          To
        </button>
      </div>
    </div>
  );
};
