import { makeAutoObservable } from 'mobx';

import { WH, XY } from '~/domain/geometry';
import { PodHandleID } from '~/domain/process-tree';
import { ProcessTreeConnectorCoords } from '~/ui/process-tree/collector';
import { logger } from '~/utils/logger';

type ExecID = string;

export class TreeLayout {
  // TODO: consider to squash this maps into one structure
  public podHandleDimensions: Map<PodHandleID, WH>;
  public podHandleConnectorCoords: Map<PodHandleID, Map<ExecID, XY>>;
  public containerConnectorCoords: Map<PodHandleID, Map<ExecID, XY>>;
  public egressConnectorCoords: Map<PodHandleID, Map<ExecID, XY>>;

  // NOTE: { groupName -> { port -> { ip -> XY }}}
  public destinationConnectorCoords: Map<string, Map<number, Map<string, XY>>>;
  // NOTE: { groupName -> XY of the only grouped (collapsed) endpoints }
  public groupedDestinationCoords: Map<string, XY>;
  public nodeConnectorCoords: Map<PodHandleID, XY>;
  public childGroupStates: Map<PodHandleID, Map<string, boolean>>;
  public childGroupConnectorCoords: Map<PodHandleID, Map<string, XY>>;
  public destinationGroupStates: Map<string, boolean>;

  public _svgDimensions: WH;

  public static defaults = {
    gapBeforeDestinations: 100,
  };

  public static new(): TreeLayout {
    return new TreeLayout();
  }

  constructor() {
    this.podHandleDimensions = new Map();
    this.podHandleConnectorCoords = new Map();
    this.nodeConnectorCoords = new Map();
    this.containerConnectorCoords = new Map();
    this.egressConnectorCoords = new Map();
    this.destinationConnectorCoords = new Map();
    this.childGroupConnectorCoords = new Map();
    this.childGroupStates = new Map();
    this.groupedDestinationCoords = new Map();
    this.destinationGroupStates = new Map();

    // NOTE: Keep this dimensions non-zero, otherwise Firefox will not give
    // a real bboxes for inner elements as they are not visible..
    this._svgDimensions = { w: 1, h: 1 };

    makeAutoObservable(this, void 0, {
      autoBind: true,
    });
  }

  // NOTE: very important to keep this method up-to-date
  public reset() {
    logger.log('in TreeLayout.reset()');
    this.podHandleDimensions.clear();
    this.podHandleConnectorCoords.clear();
    this.nodeConnectorCoords.clear();
    this.containerConnectorCoords.clear();
    this.egressConnectorCoords.clear();
    this.destinationConnectorCoords.clear();
    this.childGroupConnectorCoords.clear();
    this.childGroupStates.clear();
    this.destinationGroupStates.clear();
    this.groupedDestinationCoords.clear();

    this._svgDimensions.w = 1;
    this._svgDimensions.h = 1;
  }

  public setCollectedCoords(coords: ProcessTreeConnectorCoords) {
    const svgOrigin = coords.enclosingBBox;
    logger.log(`svgOrigin`, svgOrigin);
    this.setSVGDimensions(svgOrigin);

    coords.podConnectorBBoxes.forEach(({ bbox, podHandleId }) => {
      this.setNodeConnectorCoords(podHandleId, bbox.centerRelativeTo(svgOrigin));
    });

    coords.containerConnectorBBoxes.forEach(({ podHandleId, execId, bbox }) => {
      this.setContainerConnectorCoords(podHandleId, execId, bbox.centerRelativeTo(svgOrigin));
    });

    coords.processLineConnectorBBoxes.forEach(({ podHandleId, execId, bbox }) => {
      this.setPodHandleConnectorCoords(podHandleId, execId, bbox.centerRelativeTo(svgOrigin));
    });

    coords.egressConnectorBBoxes.forEach(({ podHandleId, execId, bbox }) => {
      this.setEgressConnectorCoords(podHandleId, execId, bbox.centerRelativeTo(svgOrigin));
    });

    coords.similarChildsConnectorBBoxes.forEach(({ podHandleId, groupKey, bbox }) => {
      this.setChildGroupConnectorCoords(podHandleId, groupKey, bbox.centerRelativeTo(svgOrigin));
    });

    // TODO: Currently these coords are not in use, but they should probably be.
    coords.similarChildsEgressConnectorBBoxes;

    coords.destinationGroupConnectorBBoxes.forEach(({ bbox, groupName }) => {
      this.setGroupedDestinationConnectorCoords(groupName, {
        x: bbox.x - svgOrigin.x - 10,
        y: bbox.center.y - svgOrigin.y,
      });
    });

    coords.destinationPortConnectorBBoxes.forEach(({ bbox, groupName, port, ip }) => {
      const xy = {
        x: bbox.x - svgOrigin.x - 10,
        y: bbox.center.y - svgOrigin.y,
      };
      this.setDestinationConnectorCoords(groupName, +port, xy, ip);
    });
  }

  public get svgDimensions(): WH {
    return { w: this._svgDimensions.w, h: this._svgDimensions.h };
  }

  public egressConnectorEnd(egressConnector: XY, destinationConnector: XY): XY {
    const gap = TreeLayout.defaults.gapBeforeDestinations;
    return {
      x: destinationConnector.x - gap,
      y: egressConnector.y,
    };
  }

  public shiftEndpointCoords(xy: XY): XY {
    return {
      x: xy.x - 20,
      y: xy.y,
    };
  }

  public toggleDestinationGroup(groupName: string) {
    const opened = !!this.destinationGroupStates.get(groupName);
    this.destinationGroupStates.set(groupName, !opened);
  }

  public setGroupedDestinationConnectorCoords(groupName: string, xy: XY) {
    this.groupedDestinationCoords.set(groupName, xy);
  }

  public setDestinationConnectorCoords(
    groupName: string,
    port: number,
    xy: XY,
    ipAddress?: string,
  ) {
    if (!this.destinationConnectorCoords.has(groupName)) {
      this.destinationConnectorCoords.set(groupName, new Map());
    }

    const groupCoords = this.destinationConnectorCoords.get(groupName)!;
    if (!groupCoords.has(port)) {
      groupCoords.set(port, new Map());
    }

    const portGroup = groupCoords.get(port)!;
    portGroup.set(ipAddress ?? '', xy);
  }

  public setEgressConnectorCoords(handleId: string, execId: string, xy: XY) {
    if (!this.egressConnectorCoords.has(handleId)) {
      this.egressConnectorCoords.set(handleId, new Map());
    }

    this.egressConnectorCoords.get(handleId)?.set(execId, xy);
  }

  public setNodeConnectorCoords(handleId: PodHandleID, xy: XY) {
    this.nodeConnectorCoords.set(handleId, xy);
  }

  public setPodHandleConnectorCoords(handleId: PodHandleID, execId: string, pos: XY) {
    if (!this.podHandleConnectorCoords.has(handleId)) {
      this.podHandleConnectorCoords.set(handleId, new Map());
    }

    const podHandleCoords = this.podHandleConnectorCoords.get(handleId)!;
    podHandleCoords.set(execId, pos);
  }

  public setContainerConnectorCoords(handleId: PodHandleID, execId: string, pos: XY) {
    if (!this.containerConnectorCoords.has(handleId)) {
      this.containerConnectorCoords.set(handleId, new Map());
    }

    const podHandleCoords = this.containerConnectorCoords.get(handleId)!;
    podHandleCoords.set(execId, pos);
  }

  public setChildGroupConnectorCoords(handleId: PodHandleID, groupKey: string, pos: XY) {
    if (!this.childGroupConnectorCoords.has(handleId)) {
      this.childGroupConnectorCoords.set(handleId, new Map());
    }

    const podHandleCoords = this.childGroupConnectorCoords.get(handleId)!;
    podHandleCoords.set(groupKey, pos);
  }

  public toggleChildGroupState(handleId: PodHandleID, groupKey: string) {
    if (!this.childGroupStates.has(handleId)) {
      this.childGroupStates.set(handleId, new Map());
    }

    const childGroup = this.childGroupStates.get(handleId)!;
    const currentValue = !!childGroup.get(groupKey);
    childGroup.set(groupKey, !currentValue);
  }

  public setSVGDimensions({ w, h }: WH) {
    this._svgDimensions.w = w;
    this._svgDimensions.h = h;
  }

  public isHierarchyArrowsUpdateNeeded(bboxes: ProcessTreeConnectorCoords) {
    return (
      bboxes.containerConnectorBBoxes.length > 0 ||
      bboxes.processLineConnectorBBoxes.length > 0 ||
      bboxes.podConnectorBBoxes.length > 0 ||
      bboxes.similarChildsConnectorBBoxes.length > 0 ||
      bboxes.similarChildsEgressConnectorBBoxes.length > 0
    );
  }
}
