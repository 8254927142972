import React, { useLayoutEffect, useRef } from 'react';

import classnames from 'classnames';
import { motion } from 'framer-motion';
import { observer } from 'mobx-react';

import { sizes } from '~/ui';

import css from './styles.scss';
import * as helpers from '../ServiceMapArrowsRenderer/helpers';

type DivRef = React.MutableRefObject<HTMLDivElement | null>;

export enum E2E {
  cardRootTestId = 'card-div-root',
}

interface XYWH {
  w: number;
  h: number;
  x: number;
  y: number;
}

export interface Props {
  coords?: XYWH;
  children?: React.ReactNode;
  className?: string;
  isUnsizedMode?: boolean;
  divRef?: DivRef;
  onClick?: (() => void) | null;
  onMouseEnter?: (() => void) | null;
  onMouseLeave?: (() => void) | null;
}

export const Card = observer(function Card(props: Props) {
  const divRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = React.useState<number>(0);

  const classes = classnames(css.baseCard, props.onClick ? 'clickable' : '', props.className);

  const shadowSize = sizes.endpointShadowSize;
  const { x, y, w, h } = props.coords || { x: 0, y: 0, w: 0, h: 0 };

  useLayoutEffect(() => {
    if (props.divRef == null) return;
    props.divRef.current = divRef.current;
    setHeight(divRef.current?.getBoundingClientRect().height || 0);
  }, [props.divRef]);

  const viewX = x - shadowSize;
  const viewY = y - shadowSize;
  const viewW = w + 2 * shadowSize;
  const viewH = (h || height) + 2 * shadowSize;

  const styles = {
    height: props.isUnsizedMode ? 'auto' : `${h}px`,
  };

  return (
    <g transform={`translate(${viewX}, ${viewY})`}>
      <foreignObject width={viewW} height={viewH}>
        <motion.div
          initial={{ opacity: 0, scale: 1 }}
          exit={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: [0.5, 1] }}
          transition={{
            delay: helpers.animationDelay.cardDelay,
            duration: helpers.animationDelay.cardAnimation,
          }}
          className={classes}
          ref={divRef}
          style={styles}
          data-testid={E2E.cardRootTestId}
          onClick={() => props.onClick?.()}
          onMouseEnter={() => props.onMouseEnter?.()}
          onMouseLeave={() => props.onMouseLeave?.()}
        >
          {props.children}
        </motion.div>
      </foreignObject>
    </g>
  );
});
