import React, { useEffect } from 'react';

import { LoadingState, PanelData } from '@grafana/data';
import classnames from 'classnames';
import * as mobx from 'mobx';
import { observer } from 'mobx-react';

import { Flow } from '~/domain/flows';
import { DestinationGroup, Tree as ProcessTree } from '~/domain/process-tree';
import { ProcessTreeArrowsLayout } from '~/ui/process-tree/arrows';
import { RefsCollector } from '~/ui/process-tree/collector';
import { TreeLayout } from '~/ui/process-tree/layout';

import { EgressGroup } from './EgressGroup';
import {
  parseProcessEventsFromPanelData,
  parseProcessEventsFromSplunkResult,
  SplunkResultEntry,
} from './helpers';
import { PodHandle } from './PodHandle';
import { ProcessTreeArrows } from './ProcessTreeArrows';
import { RefsCollectorContextProvider } from './RefsCollectorContext';
import css from './styles.scss';
export { parseProcessEventsFromPanelData };

export enum E2E {
  svgRoot = `svg-root`,
}

export function useProps(data: PanelData | null): {
  tree: ProcessTree;
  collector: RefsCollector;
  layout: TreeLayout;
  arrows: ProcessTreeArrowsLayout;
} {
  const { tree, collector, layout, arrows } = React.useMemo(() => {
    const tree = new ProcessTree();
    const layout = new TreeLayout();
    const collector = new RefsCollector();
    collector.onCoordsUpdated(bboxes => {
      layout.setCollectedCoords(bboxes);
      const onlyDestinationArrows = !layout.isHierarchyArrowsUpdateNeeded(bboxes);
      arrows.release({ onlyDestinationArrows });
    });
    const arrows = new ProcessTreeArrowsLayout(collector, layout, tree);
    return { tree, collector, layout, arrows };
  }, []);

  React.useEffect(() => {
    if (data?.state === LoadingState.Done) {
      layout.reset();
      collector.reset();
      arrows.reset();
      tree.replaceEvents(parseProcessEventsFromPanelData(data));
    }
  }, [data]);

  return { tree, collector, layout, arrows };
}

export function useSplunkProps(data: SplunkResultEntry[]): {
  tree: ProcessTree;
  collector: RefsCollector;
  layout: TreeLayout;
  arrows: ProcessTreeArrowsLayout;
} {
  const { tree, collector, layout, arrows } = React.useMemo(() => {
    const tree = new ProcessTree();
    const layout = new TreeLayout();
    const collector = new RefsCollector();
    collector.onCoordsUpdated(bboxes => {
      layout.setCollectedCoords(bboxes);
      const onlyDestinationArrows = !layout.isHierarchyArrowsUpdateNeeded(bboxes);
      arrows.release({ onlyDestinationArrows });
    });
    const arrows = new ProcessTreeArrowsLayout(collector, layout, tree);
    return { tree, collector, layout, arrows };
  }, []);

  React.useEffect(() => {
    layout.reset();
    collector.reset();
    arrows.reset();
    tree.replaceEvents(parseProcessEventsFromSplunkResult(data));
  }, [data]);

  return { tree, collector, layout, arrows };
}

export interface Props {
  tree: ProcessTree;
  collector: RefsCollector;
  layout: TreeLayout;
  arrows: ProcessTreeArrowsLayout;

  flows?: Flow[];

  className?: string;

  // NOTE: This prefix is used by grafana panel plugin, which takes static files
  // NOTE: using different base path (from: /public/plugins/<plugin-id-here>/static)
  iconsPrefix?: string;
  isEmbedded?: boolean;
  isDarkThemeEnabled: boolean;
  hideIpsInKnownGroups?: boolean;
  hideIpsInUnknownGroup?: boolean;

  onDestinationClick?: (g: DestinationGroup, port?: number, ip?: string) => void;
}

export const Tree = observer(function Tree(props: Props) {
  const { collector, layout, tree, arrows } = props;
  const divRef = React.createRef<HTMLDivElement>();

  useEmbedded(divRef, !!props.isEmbedded, props.isDarkThemeEnabled);

  // TODO: This is probably not good to have such a flows replacement from here
  useEffect(() => {
    // NOTE: new flows cannot change tree layout
    tree.replaceFlows(props.flows ?? []);
  }, [props.flows, tree]);

  const onChildGroupStateToggle = mobx.action((handleId: string, groupKey: string) => {
    layout.toggleChildGroupState(handleId, groupKey);
  });

  const podHandles = mobx
    .computed(() => {
      const handles: JSX.Element[] = [];

      tree.forEachPodNode((cn, nsn, podNode, handleId) => {
        if (podNode.hasNoProcessEvents) return;

        handles.push(
          <PodHandle
            key={handleId}
            nodeName={cn.name}
            namespaceName={nsn.name}
            podName={podNode.name}
            podHandleId={handleId}
            processes={podNode.processes}
            iconsPrefix={props.iconsPrefix}
            isDarkThemeEnabled={props.isDarkThemeEnabled}
            childGroupStates={layout.childGroupStates.get(handleId)}
            onChildGroupStateToggle={groupKey => {
              onChildGroupStateToggle(handleId, groupKey);
            }}
          />,
        );
      });

      return handles;
    })
    .get();

  const destinations = mobx
    .computed(() => {
      const dests: JSX.Element[] = [];

      tree.destinationGroups.forEach((group, groupName) => {
        dests.push(
          <EgressGroup
            key={groupName}
            name={groupName}
            group={group}
            iconsPrefix={props.iconsPrefix}
            isIpVisible={group.isUnknown && !props.hideIpsInUnknownGroup}
            isExpanded={!!layout.destinationGroupStates.get(groupName)}
            isDarkThemeEnabled={props.isDarkThemeEnabled}
            onDestinationClick={(port, ip) => {
              props.onDestinationClick?.(group, port, ip);
            }}
            onExpandToggle={() => layout.toggleDestinationGroup(groupName)}
          />,
        );
      });

      return dests;
    })
    .get();

  const { w, h } = layout.svgDimensions;

  return (
    <RefsCollectorContextProvider context={collector}>
      <div ref={divRef} className={classnames(css.tree, props.className)}>
        <svg
          data-testid={E2E.svgRoot}
          ref={collector.processTreeSvgRoot()}
          width={w}
          height={h}
          viewBox={`0 0 ${w} ${h}`}
        >
          {/* Both sides: arrows */}
          <g className={css.processTreeArrows}>
            <ProcessTreeArrows
              arrowsLayout={arrows}
              processTree={tree}
              isDarkThemeEnabled={props.isDarkThemeEnabled}
            />
          </g>

          <foreignObject width={w} height={h}>
            <div className={css.sourcesAndDestinations}>
              {/* Left side: process tree */}
              <div className={css.podHandles}>{podHandles}</div>

              {/* Right side: egress destinations */}
              <div className={css.egressGroups}>{destinations}</div>
            </div>
          </foreignObject>
        </svg>
      </div>
    </RefsCollectorContextProvider>
  );
});

export function useEmbedded(
  ref: React.RefObject<HTMLDivElement>,
  isEmbedded: boolean,
  isDarkThemeEnabled: boolean,
) {
  React.useEffect(() => {
    if (!ref?.current || !isEmbedded) return;
    const div = ref.current;
    if (isDarkThemeEnabled) {
      div.classList.add(`embedded-dark`);
      div.classList.add(`bp5-dark`);
      div.classList.remove(`embedded-light`);
      div.classList.remove(`bp5-light`);
    } else {
      div.classList.add(`embedded-light`);
      div.classList.add(`bp5-light`);
      div.classList.remove(`embedded-dark`);
      div.classList.remove(`bp5-dark`);
    }
  }, [isEmbedded, isDarkThemeEnabled]);
}
