/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter add_pb_suffix,eslint_disable,ts_nocheck,generate_dependencies,long_type_number,use_proto_field_name
// @generated from protobuf file "timescape/map/v1/map.proto" (package "timescape.map.v1", syntax proto3)
// tslint:disable
// @ts-nocheck
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Layer7 } from "../../../flow/flow_pb";
import { Layer4 } from "../../../flow/flow_pb";
import { AuthType } from "../../../flow/flow_pb";
import { Verdict } from "../../../flow/flow_pb";
import { Workload } from "../../../flow/flow_pb";
import { TimeFilter } from "../../v1/time_filter_pb";
import { FlowFilter } from "../../../flow/flow_pb";
/**
 * TODO: comment
 *
 * @generated from protobuf message timescape.map.v1.GetConnectionsRequest
 */
export interface GetConnectionsRequest {
    /**
     * TODO: comment
     *
     * @generated from protobuf field: string cluster = 1;
     */
    cluster: string;
    /**
     * TODO: comment
     *
     * @generated from protobuf field: string namespace = 2;
     */
    namespace: string;
    /**
     * Include are filters that flows must match.
     *
     * @generated from protobuf field: repeated flow.FlowFilter include = 3;
     */
    include: FlowFilter[];
    /**
     * Exclude are filters that flows must not match.
     *
     * @generated from protobuf field: repeated flow.FlowFilter exclude = 4;
     */
    exclude: FlowFilter[];
    /**
     * NOTE: Following two fields control level of details for connections
     * "inner" - connection which destination matches filters
     * "outer" - connection which destination is "outside" of specified filters
     *  ┌───────────────────────────┐
     *  │Cluster A & Namespace 1    │
     *  │                           │
     *  │ ┌─────────┐               │
     *  │ │         │               │
     *  │ │ Srv_1   │     ┌───────┐ │
     *  │ └─────────┴─────► Srv_2 | |         ┌───────────────────┐
     *  │       Inner Conn│ HTTP  │ │         │                   │
     *  │                 └───────┤ │Outer Conn                   │
     *  │                         ├─┼─────────►    Cluster B      │
     *  └─────────────────────────┴─┘         │                   │
     *                                        │                   │
     *                                        └───────────────────┘
     * Map above represents:
     * { inner_connections_level = L7, outer_connections_level = CLUSTERS }
     * TODO: comment
     *
     * @generated from protobuf field: timescape.map.v1.ConnectionKind inner_connections_level = 5;
     */
    inner_connections_level: ConnectionKind; // CLUSTERS by default
    /**
     * TODO: comment
     *
     * @generated from protobuf field: timescape.map.v1.ConnectionKind outer_connections_level = 6;
     */
    outer_connections_level: ConnectionKind; // CLUSTERS by default
    /**
     * TimeFilter is a filter that allows to specify a time interval.
     *
     * @generated from protobuf field: timescape.v1.TimeFilter time_filter = 7;
     */
    time_filter?: TimeFilter;
}
/**
 * TODO: comment
 *
 * @generated from protobuf message timescape.map.v1.GetConnectionsResponse
 */
export interface GetConnectionsResponse {
    /**
     * TODO: comment
     *
     * @generated from protobuf field: timescape.map.v1.Connections connections_map = 1;
     */
    connections_map?: Connections;
}
/**
 * TODO: comment
 *
 * @generated from protobuf message timescape.map.v1.Connections
 */
export interface Connections {
    /**
     * TODO: comment
     *
     * @generated from protobuf field: map<string, timescape.map.v1.Cluster> clusters = 1;
     */
    clusters: {
        [key: string]: Cluster;
    };
    /**
     * TODO: comment
     *
     * @generated from protobuf field: repeated timescape.map.v1.Connection connections = 2;
     */
    connections: Connection[];
}
/**
 * TODO: comment
 *
 * @generated from protobuf message timescape.map.v1.Cluster
 */
export interface Cluster {
    /**
     * TODO: comment
     *
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * TODO: comment
     *
     * @generated from protobuf field: map<string, timescape.map.v1.Namespace> namespaces = 2;
     */
    namespaces: {
        [key: string]: Namespace;
    };
}
/**
 * TODO: comment
 *
 * @generated from protobuf message timescape.map.v1.Namespace
 */
export interface Namespace {
    /**
     * TODO: comment
     *
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * TODO: comment
     *
     * @generated from protobuf field: repeated timescape.map.v1.Service services = 2;
     */
    services: Service[];
}
/**
 * NOTE: Discuss "caption" notion.
 * There is long time requested feature from users/customers
 * to support better configurable "caption" extraction for services
 * Ref: https://github.com/cilium/hubble-ui/issues/242
 * TODO: comment
 *
 * @generated from protobuf message timescape.map.v1.Service
 */
export interface Service {
    /**
     * Global Service ID derived from available data
     * For example Tetragon emited flows won't contain
     * same data as Hubble emited flow.
     *
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * TODO: comment
     *
     * @generated from protobuf field: string cluster = 2;
     */
    cluster: string;
    /**
     * TODO: comment
     *
     * @generated from protobuf field: string node = 3;
     */
    node: string;
    /**
     * TODO: comment
     *
     * @generated from protobuf field: string namespace = 4;
     */
    namespace: string;
    /**
     * NOTE: cilium identity number
     * TODO: comment
     *
     * @generated from protobuf field: string identity = 5;
     */
    identity: string;
    /**
     * TODO: comment
     *
     * @generated from protobuf field: repeated string dns_names = 6;
     */
    dns_names: string[];
    /**
     * TODO: comment
     *
     * @generated from protobuf field: repeated string ip_addresses = 7;
     */
    ip_addresses: string[];
    /**
     * TODO: comment
     *
     * @generated from protobuf field: repeated flow.Workload workloads = 8;
     */
    workloads: Workload[];
    /**
     * NOTE: can service have different labels set over time for the same identity? Probably not?
     * TODO: comment
     *
     * @generated from protobuf field: repeated string labels = 9;
     */
    labels: string[];
}
/**
 * TODO: comment
 *
 * @generated from protobuf message timescape.map.v1.Connection
 */
export interface Connection {
    /**
     * TODO: comment
     *
     * @generated from protobuf field: timescape.map.v1.ConnectionKind kind = 1;
     */
    kind: ConnectionKind;
    /**
     * TODO: comment
     *
     * @generated from protobuf field: string src_cluster = 2;
     */
    src_cluster: string;
    /**
     * TODO: comment
     *
     * @generated from protobuf field: string src_namespace = 3;
     */
    src_namespace: string;
    /**
     * TODO: comment
     *
     * @generated from protobuf field: string src_service_id = 4;
     */
    src_service_id: string;
    /**
     * TODO: comment
     *
     * @generated from protobuf field: string dst_cluster = 5;
     */
    dst_cluster: string;
    /**
     * TODO: comment
     *
     * @generated from protobuf field: string dst_namespace = 6;
     */
    dst_namespace: string;
    /**
     * TODO: comment
     *
     * @generated from protobuf field: string dst_service_id = 7;
     */
    dst_service_id: string;
    /**
     * TODO: comment
     *
     * @generated from protobuf field: repeated flow.Verdict verdicts = 8;
     */
    verdicts: Verdict[];
    /**
     * TODO: comment
     *
     * @generated from protobuf field: repeated flow.AuthType auth_types = 9;
     */
    auth_types: AuthType[];
    /**
     * TODO: comment
     *
     * @generated from protobuf field: uint64 flows_count = 10;
     */
    flows_count: number;
    /**
     * Layer 4 information defined in the same way as in OSS flow.proto
     *
     * @generated from protobuf field: flow.Layer4 l4 = 31;
     */
    l4?: Layer4;
    /**
     * Layer 7 information defined in the same way as in OSS flow.proto
     *
     * @generated from protobuf field: flow.Layer7 l7 = 32;
     */
    l7?: Layer7;
}
/**
 * TODO: comment
 *
 * @generated from protobuf enum timescape.map.v1.ConnectionKind
 */
export enum ConnectionKind {
    /**
     * TODO: figure out how to make buf breaking allow_alias so that we can have CONNECTION_KIND_CLUSTERS=0?
     * option allow_alias = true;
     * TODO: comment
     *
     * @generated from protobuf enum value: CONNECTION_KIND_UNSPECIFIED = 0;
     */
    UNSPECIFIED = 0,
    /**
     * TODO: comment
     *
     * @generated from protobuf enum value: CONNECTION_KIND_CLUSTERS = 1;
     */
    CLUSTERS = 1,
    /**
     * TODO: comment
     *
     * @generated from protobuf enum value: CONNECTION_KIND_NAMESPACES = 2;
     */
    NAMESPACES = 2,
    /**
     * TODO: comment
     *
     * @generated from protobuf enum value: CONNECTION_KIND_SERVICES = 3;
     */
    SERVICES = 3,
    /**
     * TODO: comment
     *
     * @generated from protobuf enum value: CONNECTION_KIND_L3 = 4;
     */
    L3 = 4,
    /**
     * TODO: comment
     *
     * @generated from protobuf enum value: CONNECTION_KIND_L4 = 5;
     */
    L4 = 5,
    /**
     * TODO: comment
     *
     * @generated from protobuf enum value: CONNECTION_KIND_L7 = 6;
     */
    L7 = 6,
    /**
     * Either reserved:host or reserved:remote-node
     *
     * @generated from protobuf enum value: CONNECTION_KIND_NODE = 7;
     */
    NODE = 7,
    /**
     * TODO: comment
     *
     * @generated from protobuf enum value: CONNECTION_KIND_KUBEAPI = 8;
     */
    KUBEAPI = 8,
    /**
     * TODO: comment
     *
     * @generated from protobuf enum value: CONNECTION_KIND_WORLD = 9;
     */
    WORLD = 9
}
// @generated message type with reflection information, may provide speed optimized methods
class GetConnectionsRequest$Type extends MessageType<GetConnectionsRequest> {
    constructor() {
        super("timescape.map.v1.GetConnectionsRequest", [
            { no: 1, name: "cluster", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "namespace", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "include", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => FlowFilter },
            { no: 4, name: "exclude", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => FlowFilter },
            { no: 5, name: "inner_connections_level", kind: "enum", localName: "inner_connections_level", T: () => ["timescape.map.v1.ConnectionKind", ConnectionKind, "CONNECTION_KIND_"] },
            { no: 6, name: "outer_connections_level", kind: "enum", localName: "outer_connections_level", T: () => ["timescape.map.v1.ConnectionKind", ConnectionKind, "CONNECTION_KIND_"] },
            { no: 7, name: "time_filter", kind: "message", localName: "time_filter", T: () => TimeFilter }
        ]);
    }
    create(value?: PartialMessage<GetConnectionsRequest>): GetConnectionsRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.cluster = "";
        message.namespace = "";
        message.include = [];
        message.exclude = [];
        message.inner_connections_level = 0;
        message.outer_connections_level = 0;
        if (value !== undefined)
            reflectionMergePartial<GetConnectionsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetConnectionsRequest): GetConnectionsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string cluster */ 1:
                    message.cluster = reader.string();
                    break;
                case /* string namespace */ 2:
                    message.namespace = reader.string();
                    break;
                case /* repeated flow.FlowFilter include */ 3:
                    message.include.push(FlowFilter.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated flow.FlowFilter exclude */ 4:
                    message.exclude.push(FlowFilter.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* timescape.map.v1.ConnectionKind inner_connections_level */ 5:
                    message.inner_connections_level = reader.int32();
                    break;
                case /* timescape.map.v1.ConnectionKind outer_connections_level */ 6:
                    message.outer_connections_level = reader.int32();
                    break;
                case /* timescape.v1.TimeFilter time_filter */ 7:
                    message.time_filter = TimeFilter.internalBinaryRead(reader, reader.uint32(), options, message.time_filter);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetConnectionsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string cluster = 1; */
        if (message.cluster !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.cluster);
        /* string namespace = 2; */
        if (message.namespace !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.namespace);
        /* repeated flow.FlowFilter include = 3; */
        for (let i = 0; i < message.include.length; i++)
            FlowFilter.internalBinaryWrite(message.include[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* repeated flow.FlowFilter exclude = 4; */
        for (let i = 0; i < message.exclude.length; i++)
            FlowFilter.internalBinaryWrite(message.exclude[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* timescape.map.v1.ConnectionKind inner_connections_level = 5; */
        if (message.inner_connections_level !== 0)
            writer.tag(5, WireType.Varint).int32(message.inner_connections_level);
        /* timescape.map.v1.ConnectionKind outer_connections_level = 6; */
        if (message.outer_connections_level !== 0)
            writer.tag(6, WireType.Varint).int32(message.outer_connections_level);
        /* timescape.v1.TimeFilter time_filter = 7; */
        if (message.time_filter)
            TimeFilter.internalBinaryWrite(message.time_filter, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message timescape.map.v1.GetConnectionsRequest
 */
export const GetConnectionsRequest = new GetConnectionsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetConnectionsResponse$Type extends MessageType<GetConnectionsResponse> {
    constructor() {
        super("timescape.map.v1.GetConnectionsResponse", [
            { no: 1, name: "connections_map", kind: "message", localName: "connections_map", T: () => Connections }
        ]);
    }
    create(value?: PartialMessage<GetConnectionsResponse>): GetConnectionsResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<GetConnectionsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetConnectionsResponse): GetConnectionsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* timescape.map.v1.Connections connections_map */ 1:
                    message.connections_map = Connections.internalBinaryRead(reader, reader.uint32(), options, message.connections_map);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetConnectionsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* timescape.map.v1.Connections connections_map = 1; */
        if (message.connections_map)
            Connections.internalBinaryWrite(message.connections_map, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message timescape.map.v1.GetConnectionsResponse
 */
export const GetConnectionsResponse = new GetConnectionsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Connections$Type extends MessageType<Connections> {
    constructor() {
        super("timescape.map.v1.Connections", [
            { no: 1, name: "clusters", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Cluster } },
            { no: 2, name: "connections", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Connection }
        ]);
    }
    create(value?: PartialMessage<Connections>): Connections {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.clusters = {};
        message.connections = [];
        if (value !== undefined)
            reflectionMergePartial<Connections>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Connections): Connections {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* map<string, timescape.map.v1.Cluster> clusters */ 1:
                    this.binaryReadMap1(message.clusters, reader, options);
                    break;
                case /* repeated timescape.map.v1.Connection connections */ 2:
                    message.connections.push(Connection.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap1(map: Connections["clusters"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof Connections["clusters"] | undefined, val: Connections["clusters"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Cluster.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field timescape.map.v1.Connections.clusters");
            }
        }
        map[key ?? ""] = val ?? Cluster.create();
    }
    internalBinaryWrite(message: Connections, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* map<string, timescape.map.v1.Cluster> clusters = 1; */
        for (let k of globalThis.Object.keys(message.clusters)) {
            writer.tag(1, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Cluster.internalBinaryWrite(message.clusters[k], writer, options);
            writer.join().join();
        }
        /* repeated timescape.map.v1.Connection connections = 2; */
        for (let i = 0; i < message.connections.length; i++)
            Connection.internalBinaryWrite(message.connections[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message timescape.map.v1.Connections
 */
export const Connections = new Connections$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Cluster$Type extends MessageType<Cluster> {
    constructor() {
        super("timescape.map.v1.Cluster", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "namespaces", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Namespace } }
        ]);
    }
    create(value?: PartialMessage<Cluster>): Cluster {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.name = "";
        message.namespaces = {};
        if (value !== undefined)
            reflectionMergePartial<Cluster>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Cluster): Cluster {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* map<string, timescape.map.v1.Namespace> namespaces */ 2:
                    this.binaryReadMap2(message.namespaces, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap2(map: Cluster["namespaces"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof Cluster["namespaces"] | undefined, val: Cluster["namespaces"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Namespace.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field timescape.map.v1.Cluster.namespaces");
            }
        }
        map[key ?? ""] = val ?? Namespace.create();
    }
    internalBinaryWrite(message: Cluster, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* map<string, timescape.map.v1.Namespace> namespaces = 2; */
        for (let k of globalThis.Object.keys(message.namespaces)) {
            writer.tag(2, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Namespace.internalBinaryWrite(message.namespaces[k], writer, options);
            writer.join().join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message timescape.map.v1.Cluster
 */
export const Cluster = new Cluster$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Namespace$Type extends MessageType<Namespace> {
    constructor() {
        super("timescape.map.v1.Namespace", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "services", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Service }
        ]);
    }
    create(value?: PartialMessage<Namespace>): Namespace {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.name = "";
        message.services = [];
        if (value !== undefined)
            reflectionMergePartial<Namespace>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Namespace): Namespace {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* repeated timescape.map.v1.Service services */ 2:
                    message.services.push(Service.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Namespace, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* repeated timescape.map.v1.Service services = 2; */
        for (let i = 0; i < message.services.length; i++)
            Service.internalBinaryWrite(message.services[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message timescape.map.v1.Namespace
 */
export const Namespace = new Namespace$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Service$Type extends MessageType<Service> {
    constructor() {
        super("timescape.map.v1.Service", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "cluster", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "node", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "namespace", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "identity", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "dns_names", kind: "scalar", localName: "dns_names", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "ip_addresses", kind: "scalar", localName: "ip_addresses", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "workloads", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Workload },
            { no: 9, name: "labels", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Service>): Service {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.cluster = "";
        message.node = "";
        message.namespace = "";
        message.identity = "";
        message.dns_names = [];
        message.ip_addresses = [];
        message.workloads = [];
        message.labels = [];
        if (value !== undefined)
            reflectionMergePartial<Service>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Service): Service {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string cluster */ 2:
                    message.cluster = reader.string();
                    break;
                case /* string node */ 3:
                    message.node = reader.string();
                    break;
                case /* string namespace */ 4:
                    message.namespace = reader.string();
                    break;
                case /* string identity */ 5:
                    message.identity = reader.string();
                    break;
                case /* repeated string dns_names */ 6:
                    message.dns_names.push(reader.string());
                    break;
                case /* repeated string ip_addresses */ 7:
                    message.ip_addresses.push(reader.string());
                    break;
                case /* repeated flow.Workload workloads */ 8:
                    message.workloads.push(Workload.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated string labels */ 9:
                    message.labels.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Service, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string cluster = 2; */
        if (message.cluster !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.cluster);
        /* string node = 3; */
        if (message.node !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.node);
        /* string namespace = 4; */
        if (message.namespace !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.namespace);
        /* string identity = 5; */
        if (message.identity !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.identity);
        /* repeated string dns_names = 6; */
        for (let i = 0; i < message.dns_names.length; i++)
            writer.tag(6, WireType.LengthDelimited).string(message.dns_names[i]);
        /* repeated string ip_addresses = 7; */
        for (let i = 0; i < message.ip_addresses.length; i++)
            writer.tag(7, WireType.LengthDelimited).string(message.ip_addresses[i]);
        /* repeated flow.Workload workloads = 8; */
        for (let i = 0; i < message.workloads.length; i++)
            Workload.internalBinaryWrite(message.workloads[i], writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* repeated string labels = 9; */
        for (let i = 0; i < message.labels.length; i++)
            writer.tag(9, WireType.LengthDelimited).string(message.labels[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message timescape.map.v1.Service
 */
export const Service = new Service$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Connection$Type extends MessageType<Connection> {
    constructor() {
        super("timescape.map.v1.Connection", [
            { no: 1, name: "kind", kind: "enum", T: () => ["timescape.map.v1.ConnectionKind", ConnectionKind, "CONNECTION_KIND_"] },
            { no: 2, name: "src_cluster", kind: "scalar", localName: "src_cluster", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "src_namespace", kind: "scalar", localName: "src_namespace", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "src_service_id", kind: "scalar", localName: "src_service_id", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "dst_cluster", kind: "scalar", localName: "dst_cluster", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "dst_namespace", kind: "scalar", localName: "dst_namespace", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "dst_service_id", kind: "scalar", localName: "dst_service_id", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "verdicts", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["flow.Verdict", Verdict] },
            { no: 9, name: "auth_types", kind: "enum", localName: "auth_types", repeat: 1 /*RepeatType.PACKED*/, T: () => ["flow.AuthType", AuthType] },
            { no: 10, name: "flows_count", kind: "scalar", localName: "flows_count", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 31, name: "l4", kind: "message", T: () => Layer4 },
            { no: 32, name: "l7", kind: "message", T: () => Layer7 }
        ]);
    }
    create(value?: PartialMessage<Connection>): Connection {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.kind = 0;
        message.src_cluster = "";
        message.src_namespace = "";
        message.src_service_id = "";
        message.dst_cluster = "";
        message.dst_namespace = "";
        message.dst_service_id = "";
        message.verdicts = [];
        message.auth_types = [];
        message.flows_count = 0;
        if (value !== undefined)
            reflectionMergePartial<Connection>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Connection): Connection {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* timescape.map.v1.ConnectionKind kind */ 1:
                    message.kind = reader.int32();
                    break;
                case /* string src_cluster */ 2:
                    message.src_cluster = reader.string();
                    break;
                case /* string src_namespace */ 3:
                    message.src_namespace = reader.string();
                    break;
                case /* string src_service_id */ 4:
                    message.src_service_id = reader.string();
                    break;
                case /* string dst_cluster */ 5:
                    message.dst_cluster = reader.string();
                    break;
                case /* string dst_namespace */ 6:
                    message.dst_namespace = reader.string();
                    break;
                case /* string dst_service_id */ 7:
                    message.dst_service_id = reader.string();
                    break;
                case /* repeated flow.Verdict verdicts */ 8:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.verdicts.push(reader.int32());
                    else
                        message.verdicts.push(reader.int32());
                    break;
                case /* repeated flow.AuthType auth_types */ 9:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.auth_types.push(reader.int32());
                    else
                        message.auth_types.push(reader.int32());
                    break;
                case /* uint64 flows_count */ 10:
                    message.flows_count = reader.uint64().toNumber();
                    break;
                case /* flow.Layer4 l4 */ 31:
                    message.l4 = Layer4.internalBinaryRead(reader, reader.uint32(), options, message.l4);
                    break;
                case /* flow.Layer7 l7 */ 32:
                    message.l7 = Layer7.internalBinaryRead(reader, reader.uint32(), options, message.l7);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Connection, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* timescape.map.v1.ConnectionKind kind = 1; */
        if (message.kind !== 0)
            writer.tag(1, WireType.Varint).int32(message.kind);
        /* string src_cluster = 2; */
        if (message.src_cluster !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.src_cluster);
        /* string src_namespace = 3; */
        if (message.src_namespace !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.src_namespace);
        /* string src_service_id = 4; */
        if (message.src_service_id !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.src_service_id);
        /* string dst_cluster = 5; */
        if (message.dst_cluster !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.dst_cluster);
        /* string dst_namespace = 6; */
        if (message.dst_namespace !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.dst_namespace);
        /* string dst_service_id = 7; */
        if (message.dst_service_id !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.dst_service_id);
        /* repeated flow.Verdict verdicts = 8; */
        if (message.verdicts.length) {
            writer.tag(8, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.verdicts.length; i++)
                writer.int32(message.verdicts[i]);
            writer.join();
        }
        /* repeated flow.AuthType auth_types = 9; */
        if (message.auth_types.length) {
            writer.tag(9, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.auth_types.length; i++)
                writer.int32(message.auth_types[i]);
            writer.join();
        }
        /* uint64 flows_count = 10; */
        if (message.flows_count !== 0)
            writer.tag(10, WireType.Varint).uint64(message.flows_count);
        /* flow.Layer4 l4 = 31; */
        if (message.l4)
            Layer4.internalBinaryWrite(message.l4, writer.tag(31, WireType.LengthDelimited).fork(), options).join();
        /* flow.Layer7 l7 = 32; */
        if (message.l7)
            Layer7.internalBinaryWrite(message.l7, writer.tag(32, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message timescape.map.v1.Connection
 */
export const Connection = new Connection$Type();
/**
 * @generated ServiceType for protobuf service timescape.map.v1.MapService
 */
export const MapService = new ServiceType("timescape.map.v1.MapService", [
    { name: "GetConnections", options: {}, I: GetConnectionsRequest, O: GetConnectionsResponse }
]);
