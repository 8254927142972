import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Button, Icon, Intent, NonIdealState, Spinner, Tooltip } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import _ from 'lodash';
import * as mobx from 'mobx';
import { observer } from 'mobx-react';

import { CustomError } from '~/api/customprotocol-core/errors';
import { useApplication } from '~/application';
import { Authorization } from '~/authorization';
import { defaultVisibleColumns, FlowsTable } from '~/components/FlowsTable';
import { FlowsTableSidebar } from '~/components/FlowsTable/Sidebar';
import { getDefaultWidths, useFlowsTableColumns } from '~/components/hooks/useFlowTableColumns';
import { LoadingDots } from '~/components/LoadingDots';
import { NavigationSidebar } from '~/components/NavigationSidebar/NavigationSidebar';
import { ProcessEventsTable } from '~/components/ProcessEventsTable';
import { ProcessEventTableSidebar } from '~/components/ProcessEventTableSidebar';
import { Tree as ProcessTree } from '~/components/ProcessTree';
import { ResizeHandle } from '~/components/ResizeHandle';
import { Teleport } from '~/components/Teleport';
import { ButtonGroup } from '~/components/widgets/ButtonGroup';
import { filterFlow } from '~/domain/filtering';
import { Flow } from '~/domain/flows';
import { FlowDigest } from '~/domain/flows/common';
import { ProcessEvent } from '~/domain/process-events';
import { DestinationGroup } from '~/domain/process-tree';
import { useNotifier } from '~/notifier';
import { Disposer } from '~/utils/disposer';
import { logger } from '~/utils/logger';

import css from './styles.scss';

enum TableKind {
  ProcessEvents = 'process-events',
  Flows = 'flows',
}

const flowTableUniqueId = 'process-tree';

export const ProcessTreeApp = observer(function ProcessTreeApp() {
  const [flows, setFlows] = useState<Flow[]>([]);
  const [selectedFlows, setSelectedFlows] = useState<Flow[] | null>(null);
  const [selectedFlowDigest, setSelectedFlowDigest] = useState<FlowDigest | null>(null);
  // const [flowsTabSelected, selectFlowsTab] = useState(false);
  const [tableTab, setTableTab] = useState<TableKind>(TableKind.ProcessEvents);
  const [isUploading, setIsUploading] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<ProcessEvent | null>(null);
  const [podListFetchError, setPodListFetchError] = useState<CustomError | null>(null);
  const [eventsFetchError, setEventsFetchError] = useState<CustomError | null>(null);
  const sidebarSlotRef = useRef<HTMLDivElement | null>(null);

  const flowsTableColumns = useFlowsTableColumns(
    flowTableUniqueId,
    getDefaultWidths(defaultVisibleColumns),
  );

  const { ui, store, dataLayer } = useApplication();
  const notifier = useNotifier();
  const transferState = dataLayer.transferState;
  const isFlowsTabAvailable = !transferState.isTetragonOnlyMode;

  const selectedFlowsIndex: Map<string, Flow> = useMemo(() => {
    const index = new Map();

    selectedFlows?.forEach(f => {
      index.set(f.id, f);
    });

    return index;
  }, [selectedFlowDigest]);

  const arePodsLoading = mobx
    .computed(() => {
      return ui.processTree.isTimescapePodsLoading;
    })
    .get();

  const arePodEventsLoading = mobx
    .computed(() => {
      return ui.processTree.isPodEventsLoading;
    })
    .get();

  const selectedFlow = useMemo(() => {
    if (selectedFlowDigest == null) return null;

    return selectedFlowsIndex.get(selectedFlowDigest.id);
  }, [selectedFlowDigest, selectedFlowsIndex]);

  const filteredFlows = useMemo(() => {
    return flows.filter(flow => {
      return filterFlow(flow, store.processTree.eventsFilters);
    });
  }, [store.processTree.eventsFilters, flows]);

  const tableHolderDiv = useRef<HTMLDivElement | null>(null);
  const [tableListHeight, setTableListHeight] = useState(0);

  const setListHeight = useCallback(
    _.debounce(() => {
      if (tableHolderDiv.current == null) return;

      const bbox = tableHolderDiv.current.getBoundingClientRect();

      setTableListHeight(bbox.height);
    }, 100),
    [tableHolderDiv.current],
  );

  React.useLayoutEffect(() => {
    setListHeight();
  }, [setListHeight, tableHolderDiv.current]);

  const eventsTableDiv = useRef<HTMLDivElement | null>(null);
  const [eventsTableHeight, setEventsTableHeight] = useState(-1);

  const onResize = useCallback(
    (dy: number) => {
      if (eventsTableDiv.current == null) return;

      if (eventsTableHeight === -1) {
        const bbox = eventsTableDiv.current.getBoundingClientRect();

        setEventsTableHeight(bbox.height + dy);
      } else {
        setEventsTableHeight(currentHeight => currentHeight + dy);
      }

      setListHeight();
    },
    [eventsTableHeight, eventsTableDiv.current],
  );

  const onLogsUploaded = useCallback(
    (uploadedLogs: string) => {
      logger.log('onLogsUploaded: ', uploadedLogs);
      const logEntries = store.processTree.useUploadedEvents(uploadedLogs);

      setIsUploading(false);

      const { processEvents, flows } = logEntries;
      const noEvents = processEvents.length === 0 && flows.length === 0;
      const onlyFlows = processEvents.length === 0 && flows.length > 0;

      if (noEvents && uploadedLogs.length > 0) {
        notifier.showError(`
          Only raw export-stdout log files are accepted. This file contains wrong
          data or formatted jsons (e. g. output of jq util).
        `);

        return;
      }

      if (onlyFlows) {
        notifier.showWarning(`
          Uploaded file contains only flow events. Process events are required
          to render process tree.
        `);

        return;
      }

      // TODO: moves management of associated flows inside ProcessTree store
      setFlows(flows);
    },
    [notifier],
  );

  const onLogsUploadError = useCallback(
    (err: DOMException | null) => {
      logger.error('failed to read data file: ', err);
      notifier.showError('Failed to read data file: ' + err);
      setIsUploading(false);
    },
    [notifier],
  );

  const onTableTabClick = useCallback(
    (tab: TableKind) => {
      if (tableTab !== tab) {
        setSelectedFlowDigest(null);
        setSelectedEvent(null);
      }

      setTableTab(tab);
    },
    [tableTab],
  );

  const onDestinationClick = useCallback(
    (g: DestinationGroup, port?: number, ip?: string) => {
      if (!isFlowsTabAvailable) {
        onTableTabClick(TableKind.ProcessEvents);
        return;
      } else {
        onTableTabClick(TableKind.Flows);
      }

      setSelectedFlowDigest(null);

      if (port == null) {
        setSelectedFlows(g.flows);
        return;
      }

      // TODO: it should be FilterEntry with to/from/port
      const flows = g.flows.filter(f => {
        if (f.destinationPort !== port) return false;
        if (ip != null && f.destinationIp !== ip) return false;

        return true;
      });

      setSelectedFlows(flows);
    },
    [onTableTabClick, isFlowsTabAvailable],
  );

  const onFlowsTabClose = useCallback((e: React.MouseEvent<any>) => {
    e.preventDefault();
    e.stopPropagation();

    setSelectedFlows(null);
    setSelectedFlowDigest(null);

    setTableTab(TableKind.ProcessEvents);
  }, []);

  const tableIsProcessEvents = tableTab === TableKind.ProcessEvents;

  const onLoginAgainClick = useCallback(() => {
    Authorization.goToSignin();
  }, []);

  const onNamespaceChanged = mobx.action(
    async (cluster: string | null, namespace: string | null) => {
      if (namespace !== null) {
        const isLocalMode = await ui.processTree.namespaceChanged(namespace);
        if (isLocalMode) return;
      }
      ui.controls.clusterNamespaceChanged(cluster, namespace);
    },
  );

  useEffect(() => {
    return Disposer.new()
      .chain(
        dataLayer.processTree
          .onTimescapePodsLoadingSuccess(() => {
            setPodListFetchError(null);
          })
          .disposer(),
      )
      .chain(
        dataLayer.processTree
          .onTimescapePodsLoadingFailed(err => {
            setPodListFetchError(err);
          })
          .disposer(),
      )
      .chain(
        dataLayer.processTree
          .onTimescapePodEventsLoadingFailed(err => {
            setEventsFetchError(err);
          })
          .disposer(),
      )
      .chain(
        dataLayer.processTree
          .onTimescapePodEventsLoadingSuccess(() => {
            setEventsFetchError(null);
          })
          .disposer(),
      )
      .asFunction();
  }, [dataLayer]);

  const events = mobx.computed(() => store.processTree.currentEvents).get();

  return (
    <div className={css.app}>
      <div className={css.sidebar}>
        <NavigationSidebar
          clustersList={store.clusterNamespaces.clustersList}
          currCluster={store.clusterNamespaces.currCluster}
          transferState={dataLayer.transferState}
          onAppSelect={app => ui.applicationChanged(app)}
          namespacesList={store.clusterNamespaces.allClustersNamespacesList}
          currNamespace={store.clusterNamespaces.currNamespace}
          timeRange={store.controls.timeRange}
          processTreeAvailablePods={store.processTree.availablePods}
          processTreeSelectedPod={store.processTree.currentPodName}
          processTreeSlotRef={sidebarSlotRef}
          initialPodQuery={ui.processTree.getInitialPodQuery()}
          podSelectionControl={ui.processTree.podSelectionControl}
          isProcessTreeLogsUploading={isUploading}
          isPodsSpinnerVisible={arePodsLoading || arePodEventsLoading}
          onProcessTreeLogsUploaded={onLogsUploaded}
          onProcessTreeLogsUploadError={onLogsUploadError}
          onProcessTreeReadingStart={() => setIsUploading(true)}
          onProcessTreePodSelect={pod => ui.processTree.podSelected(pod)}
          onClusterNamespaceChange={onNamespaceChanged}
          onTimeRangeChange={tr => ui.controls.timeRangeChanged(tr)}
          onLoginAgainClick={onLoginAgainClick}
        />

        <Teleport to={sidebarSlotRef}>
          {ui.processTree.isPodEventsLoadingTakesTooLong && (
            <div className={css.longLoadingHelper}>
              <div className={css.statusLine}>
                <LoadingDots
                  caption={`Fetching ${ui.processTree.amountOfPodEventsLoaded} events`}
                />
              </div>

              <div className={css.actions}>
                <div className={css.cancelBtn} onClick={() => ui.processTree.dropLongLoading()}>
                  Stop
                </div>
              </div>
            </div>
          )}
        </Teleport>
      </div>
      <div className={css.workspace}>
        <div className={css.processTree}>
          <div className={css.caption}>Process Tree</div>

          {store.processTree.currentPodName == null && (
            <div className={css.empty}>Select a pod in the left panel</div>
          )}

          {podListFetchError != null && (
            <NonIdealState
              icon={
                <div className={css.podEventsStateIcon}>
                  {!arePodsLoading ? <Icon icon={'error'} iconSize={48} /> : <Spinner size={48} />}
                </div>
              }
              description={
                `Failed to fetch pod list for namespace ` +
                `"${store.processTree.currentNamespace}": ` +
                podListFetchError.message
              }
              action={
                <Button intent={Intent.DANGER} onClick={() => ui.processTree.refetchPodList()}>
                  Retry
                </Button>
              }
            />
          )}

          {store.processTree.currentPodName != null &&
            (eventsFetchError == null ? (
              <ProcessTree
                className={css.svgTree}
                layout={ui.processTree.layout}
                collector={ui.processTree.collector}
                arrows={ui.processTree.arrows}
                tree={store.processTree.tree}
                flows={filteredFlows}
                hideIpsInKnownGroups={true}
                hideIpsInUnknownGroup={false}
                isDarkThemeEnabled={store.themes.isDarkTheme}
                onDestinationClick={onDestinationClick}
              />
            ) : (
              <NonIdealState
                icon={
                  <div className={css.podEventsStateIcon}>
                    {!arePodEventsLoading ? (
                      <Icon icon={'error'} iconSize={48} />
                    ) : (
                      <Spinner size={48} />
                    )}
                  </div>
                }
                description={
                  `Failed to fetch process events for pod ` +
                  `"${store.processTree.currentPodName}": ` +
                  eventsFetchError.message
                }
                action={
                  <Button
                    intent={Intent.DANGER}
                    onClick={() => ui.processTree.refetchProcessEvents()}
                  >
                    Retry
                  </Button>
                }
              />
            ))}
        </div>

        <div
          className={css.eventsTable}
          style={{ height: `${eventsTableHeight}px` }}
          ref={eventsTableDiv}
        >
          <ResizeHandle
            horizontal={true}
            stickToTop={true}
            onResize={onResize}
            resizeThrottling={25}
            className={css.resizeHandle}
          />

          <div className={css.tableHolder} ref={tableHolderDiv}>
            {events.length === 0 && <div className={css.empty}>Table of events</div>}

            {events.length > 0 && selectedFlows != null && (
              <div className={css.tabs}>
                <ButtonGroup className={css.tabButtons}>
                  <Button
                    active={tableTab === TableKind.ProcessEvents}
                    onClick={() => onTableTabClick(TableKind.ProcessEvents)}
                  >
                    Process events
                  </Button>
                  {isFlowsTabAvailable && (
                    <Button
                      className={css.flows}
                      active={tableTab === TableKind.Flows}
                      onClick={() => onTableTabClick(TableKind.Flows)}
                    >
                      <span>Flows</span>
                      <Icon
                        className={css.closeFlows}
                        intent={Intent.PRIMARY}
                        iconSize={14}
                        icon={IconNames.CROSS}
                        color="#8aa2b4"
                        onClick={onFlowsTabClose}
                      />
                    </Button>
                  )}
                </ButtonGroup>

                <Tooltip
                  content={
                    <div className={css.flowsTooltip}>
                      Flows tab contains flows to selected destination
                    </div>
                  }
                  placement="right"
                  usePortal={false}
                >
                  <Icon
                    className={css.help}
                    intent={Intent.PRIMARY}
                    iconSize={14}
                    icon={IconNames.HELP}
                    color="#d1deea"
                  />
                </Tooltip>
              </div>
            )}

            {events.length !== 0 && tableIsProcessEvents && (
              <ProcessEventsTable
                className={css.table}
                processEvents={events}
                onRowClick={evt => setSelectedEvent(evt)}
                listHeight={tableListHeight}
              />
            )}
            {tableTab === TableKind.Flows && (
              <FlowsTable
                flows={selectedFlows ?? []}
                isLoading={false}
                visibleColumns={flowsTableColumns.visible}
                selectedFlow={selectedFlow ?? null}
                onSelectFlow={setSelectedFlowDigest}
              />
            )}
          </div>

          <ProcessEventTableSidebar
            event={selectedEvent}
            isVisible={selectedEvent != null}
            onClose={() => setSelectedEvent(null)}
          />

          {selectedFlow != null && (
            <FlowsTableSidebar
              showReviewPolicyButton={true}
              flow={selectedFlow ?? null}
              filters={store.processTree.eventsFilters}
              onClose={() => setSelectedFlowDigest(null)}
            />
          )}
        </div>
      </div>
    </div>
  );
});
