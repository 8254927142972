import { makeAutoObservable } from 'mobx';

import { Ancestor, Kind, ProcessEvent } from '~/domain/process-events';
import { ChildrenGroups, ProcessChildGroup } from '~/domain/process-tree/common';

type PID = number;
type ExecID = string;

// NOTE: in fact, it's not really processes. This entity treats process event
// NOTE: with unique execId as separate "process".
export class Processes {
  // NOTE: events associated with evt.process, i. e. evt.process.execId -> evt[]
  public events: Map<ExecID, Map<Kind, ProcessEvent[]>>;

  // NOTE: all processes (evt.process, evt.parent?, evt.ancestors?)
  // NOTE: Ancestor type is used because pod field is not guaranteed to be there
  public processes: Map<ExecID, Ancestor>;

  // NOTE: map execid -> parentExecId between any ExecIDs
  public parents: Map<ExecID, ExecID>;

  // NOTE: map execId -> namespacePid (pid inside container)
  public namespacePids: Map<ExecID, PID>;

  public static empty(): Processes {
    return new Processes();
  }

  constructor() {
    this.parents = new Map();
    this.processes = new Map();
    this.events = new Map();
    this.namespacePids = new Map();
    // this.orphanedExecIds = new Set();

    makeAutoObservable(this);
  }

  public saveEvent(evt: ProcessEvent): boolean {
    const base = evt.base;
    if (base == null) return false;
    const { process: ps, parent: pps, ancestors, kind } = base;

    // NOTE: Step 1 - just save source event and it's evt.process
    this.upsertProcess(ps);
    if (!this.events.has(ps.exec_id)) {
      this.events.set(ps.exec_id, new Map());
    }

    const pidEvents = this.events.get(ps.exec_id)!;
    if (!pidEvents.has(kind)) {
      pidEvents.set(kind, []);
    }

    const kindEvents = pidEvents.get(kind)!;
    kindEvents.push(evt);

    // NOTE: Step 2 - Save parent process
    if (pps != null) {
      this.upsertProcess(pps);
      this.parents.set(ps.exec_id, pps.exec_id);
    }

    // NOTE: Step 3 - Save ancestors (parents of pps)
    if (ancestors != null && ancestors.length > 0) {
      const closest = this.upsertAncestors(ancestors)!;

      // NOTE: first ancestor in ancestors array is a parent of pps
      if (pps != null) {
        this.parents.set(pps.exec_id, closest.exec_id);
      }
    }

    // this.tryFulfillOrphans();
    return true;
  }

  public hasEventsOfKind(execId: string, kind: Kind): boolean {
    return !!this.events?.get(execId)?.get(kind)?.length;
  }

  public childGroupHasEventsOfKind(group: ProcessChildGroup, kind: Kind): boolean {
    for (const execId of group.execIds) {
      if (this.hasEventsOfKind(execId, kind)) return true;
    }

    return false;
  }

  // NOTE: returns { execId -> max number of all descendants (depth) } and
  // NOTE: execId with largest descendats chain
  public get rootExecIds(): [Map<string, number>, string | null] {
    const roots: Map<string, number> = new Map();
    let maxDepth = 0;
    let maxDepthExecId: string | null = null;

    this.processes.forEach((_, execId) => {
      const [rootExecId, depth] = this.getRootOfProcess(execId, true);
      const newDepth = Math.max(roots.get(rootExecId) ?? 0, depth);
      roots.set(rootExecId, newDepth);

      if (newDepth > maxDepth) {
        maxDepth = newDepth;
        maxDepthExecId = rootExecId;
      }
    });

    return [roots, maxDepthExecId];
  }

  public getRootOfProcess(execId: string, onlyExistingProcesses = false): [string, number] {
    const seen = new Set();
    seen.add(execId);

    let depth = 0;
    for (let parentExecId; (parentExecId = this.parents.get(execId)); ) {
      if (seen.has(parentExecId)) break;

      if (onlyExistingProcesses) {
        const ps = this.processes.get(parentExecId);
        if (ps == null) break;
      }

      depth += 1;
      execId = parentExecId;
      seen.add(execId);
    }

    return [execId, depth];
  }

  public get rootExecId(): string | null {
    const [, maxDepthExecId] = this.rootExecIds;

    return maxDepthExecId;
  }

  // NOTE: reversed version of this.parents
  public get children(): Map<ExecID, Set<ExecID>> {
    const m = new Map();

    this.parents.forEach((parentExecId, childExecId) => {
      if (!m.has(parentExecId)) {
        m.set(parentExecId, new Set());
      }

      m.get(parentExecId)?.add(childExecId);
    });

    return m;
  }

  // NOTE: used to detect if several processes are doing the same
  public get childrenGroups(): ChildrenGroups {
    const m: ChildrenGroups = new Map();

    this.children.forEach((childExecIds, parentExecId) => {
      const execIdGroups = m.get(parentExecId) || new Map();
      if (!m.has(parentExecId)) {
        m.set(parentExecId, execIdGroups);
      }

      childExecIds.forEach(childExecId => {
        if (childExecId === parentExecId) return;

        const ps = this.processes.get(childExecId);
        if (ps == null) return;

        const binary = ps.binary;
        const args = ps.arguments;

        // NOTE: this key should be unique in pod
        const key = `${parentExecId} ${binary} ${args}`;

        if (!execIdGroups.has(key)) {
          execIdGroups.set(key, {
            execIds: new Set(),
            key,
            binary,
            args,
          });
        }

        const childGroup = execIdGroups.get(key)!;
        childGroup.execIds.add(childExecId);
      });
    });

    return m;
  }

  private upsertProcess(ps: Ancestor) {
    // TODO: if this.processes already has one, update some empty fields
    const { exec_id } = ps;
    if (!this.processes.has(exec_id)) {
      this.processes.set(exec_id, ps);

      if (ps.pod?.container?.pid != null) {
        this.namespacePids.set(exec_id, ps.pod.container.pid);
      }

      return;
    }

    let updated = this.processes.get(exec_id)!;
    if (ps.pod != null && updated.pod == null) {
      updated = { ...updated, pod: ps.pod };
    }

    if (ps.pod?.container?.pid != null) {
      this.namespacePids.set(exec_id, ps.pod.container.pid);
    }

    this.processes.set(exec_id, updated);
  }

  private upsertAncestors(ancestors: Ancestor[]): Ancestor | null {
    const n = ancestors.length;
    if (n === 0) return null;

    for (let i = n - 1; i >= 0; --i) {
      const ancestor = ancestors[i];
      this.upsertProcess(ancestor);

      // NOTE: if ancestor has parentExecId set, we are done here
      if (ancestor.parent_exec_id != null) {
        this.parents.set(ancestor.exec_id, ancestor.parent_exec_id);
        continue;
      }

      // NOTE: trying to check if we alreay know parent of this ancestor
      if (this.parents.has(ancestor.exec_id)) continue;

      // NOTE: fallback, if there is next element in array, then it is parent
      if (i + 1 < n) {
        this.parents.set(ancestor.exec_id, ancestors[i + 1].exec_id);
        continue;
      }

      // this.orphanedExecIds.add(ancestor.execId);
    }

    return ancestors[0];
  }
}
