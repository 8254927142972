import { Paginated } from '~/api/general/pagination';
import { LastSeen } from '~/domain/common';
import { EventEmitter } from '~/utils/emitter';

export enum Event {
  FetchStarted = 'fetch-started',
  FetchFinished = 'fetch-finished',
  FetchFailed = 'fetch-failed',
  Page = 'page',
  Exhausted = 'exhausted',
  Stop = 'stop',
}

export type Handlers<T> = {
  [Event.FetchStarted]: () => void;
  [Event.FetchFinished]: () => void;
  [Event.FetchFailed]: (err: any) => void;
  [Event.Page]: (p: T, ls?: LastSeen) => void;
  [Event.Exhausted]: () => void;
  [Event.Stop]: () => void;
};

export abstract class Pager<T> extends EventEmitter<Handlers<T>> implements Paginated<T> {
  public static readonly defaultLimit = 100;

  private defaultLimit: number = Pager.defaultLimit;
  protected limit: number = Pager.defaultLimit;
  protected lastSeen: LastSeen | null = null;

  private _isExhausted = false;

  constructor(isCached?: boolean) {
    super(!!isCached);
  }

  protected abstract checkIfExhausted(page: T): boolean;
  protected abstract getLastSeenFromPage(page: T): LastSeen | null;
  protected abstract fetchPage(lastSeen?: LastSeen): Promise<T>;

  public async stop(): Promise<void> {
    this.emit(Event.Stop);
  }

  public get isExhausted(): boolean {
    return this._isExhausted;
  }

  public async fetchNextPage(): Promise<void> {
    return this.fetchAfter(this.lastSeen ?? void 0);
  }

  public async fetchAfter(lastSeen?: LastSeen): Promise<void> {
    let nextLastSeen: LastSeen | null = null;

    this.emit(Event.FetchStarted);

    const page = await this.fetchPage(lastSeen)
      .catch(err => {
        this.emit(Event.FetchFailed, err);
        throw err;
      })
      .finally(() => this.emit(Event.FetchFinished));

    this._isExhausted = this.checkIfExhausted(page);

    if (!this._isExhausted) {
      nextLastSeen = this.getLastSeenFromPage(page);
    } else {
      this.emit(Event.Exhausted);
    }

    this.setLastSeen(nextLastSeen);
    this.emit(Event.Page, page, lastSeen);
  }

  public onFetchStarted(fn: Handlers<T>[Event.FetchStarted]): this {
    this.on(Event.FetchStarted, fn);
    return this;
  }

  public onFetchFailed(fn: Handlers<T>[Event.FetchFailed]): this {
    this.on(Event.FetchFailed, fn);
    return this;
  }

  public onFetchFinished(fn: Handlers<T>[Event.FetchFinished]): this {
    this.on(Event.FetchFinished, fn);
    return this;
  }

  public onPage(fn: Handlers<T>[Event.Page]): this {
    this.on(Event.Page, fn);
    return this;
  }

  public onExhausted(fn: Handlers<T>[Event.Exhausted]): this {
    this.on(Event.Exhausted, fn);
    return this;
  }

  public onStopped(fn: Handlers<T>[Event.Stop]): this {
    this.on(Event.Stop, fn);
    return this;
  }

  public setLastSeen(lastSeen?: LastSeen | null): this {
    this.lastSeen = lastSeen ?? null;
    return this;
  }

  public setLimit(limit?: number | null): this {
    this.limit = this.ensureLimit(limit);
    return this;
  }

  public setDefaultLimit(limit: number): this {
    this.defaultLimit = limit;
    return this;
  }

  private ensureLimit(limit?: number | null): number {
    return Math.max(1, limit || this.defaultLimit);
  }
}
