import React, { useCallback, useEffect, useRef } from 'react';

import _ from 'lodash';

export type HoverWrapperProps = {
  isSVGContext: boolean;
  children?: React.ReactNode;
  parentRef?: React.MutableRefObject<Element | null>;
  forwardRef?: React.MutableRefObject<Element | null>;
  isHovered?: boolean;

  onElementHover?: (e: Element) => void;
};

export const HoverWrapper = function HoverWrapper(props: HoverWrapperProps) {
  const svgGroupElem = useRef<SVGGElement | null>(null);
  const divElem = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const ref = props.parentRef;
    if (ref == null) return;

    if (!!props.isSVGContext && svgGroupElem.current != null) {
      ref.current = svgGroupElem.current.parentElement;
    }

    if (!props.isSVGContext && divElem.current != null) {
      ref.current = divElem.current.parentElement;
    }
  }, [props.isSVGContext, props.parentRef]);

  useEffect(() => {
    const ref = props.forwardRef;
    if (ref == null) return;

    if (!!props.isSVGContext && svgGroupElem.current != null) {
      ref.current = svgGroupElem.current;
    }

    if (!props.isSVGContext && divElem.current != null) {
      ref.current = divElem.current;
    }
  }, [props.isSVGContext, props.forwardRef]);

  const mouseHandler = useCallback(
    (e: MouseEvent) => {
      props.onElementHover?.(e.target as Element);
    },
    [props.onElementHover],
  );

  useEffect(() => {
    const mouseOverHandler = _.throttle(mouseHandler, 50);

    document.addEventListener('mouseover', mouseOverHandler);

    return () => {
      document.removeEventListener('mouseover', mouseOverHandler);
    };
  }, [mouseHandler]);

  if (props.isSVGContext) {
    return <g ref={svgGroupElem}>{props.children}</g>;
  }

  return <div ref={divElem}>{props.children}</div>;
};
