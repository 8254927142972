import React, { useCallback, useMemo, useRef, useState } from 'react';

import { ElemCoords, getElemCoords } from '~/ui/hooks/useElemCoords';

import { RenderableElement } from './common';
import { HoverWrapper } from './HoverWrapper';
import { TooltipWindow } from './TooltipWindow';

export type Props = {
  target: RenderableElement;
  content?: RenderableElement;

  isSVGContext?: boolean;
  usePortal?: boolean;
  portalContainer?: Element | React.MutableRefObject<Element | null>;
  children?: React.ReactNode;
};

// NOTE: For now, this is very restricted component only supporting top
// NOTE: placed tooltip window and non-updating window position (no resize, etc
// NOTE: event handlers).
// TODO: For impl simplicity, it's assumed that usePortal is always true
export const Tooltip = function Tooltip(props: Props) {
  const [handleCoords, setHandleCoords] = useState<ElemCoords | null>(null);
  const [isHovered, setHovered] = useState(false);

  // NOTE: HoverWraper will set parentRef element, it's that element where
  // NOTE: tooltip window should be rendered when no props.portalContainer is
  // NOTE: provided.
  const parentRef = useRef<Element | null>(null);
  const targetRef = useRef<Element | null>(null);
  const windowRef = useRef<HTMLDivElement | null>(null);

  // NOTE: The user of Tooltip components is responsible for wrapping the target
  // NOTE: element in case if we are in svg context
  const target = useMemo(() => {
    if (props.target instanceof Function) return props.target();

    return props.target;
  }, [props.target]);

  const portalContainer = useMemo(() => {
    if (props.portalContainer != null) return props.portalContainer;

    if (props.isSVGContext && parentRef.current != null) {
      return parentRef;
    }

    return document.body;
  }, [props.isSVGContext, props.portalContainer]);

  // NOTE: Here is where we control if window tooltip should be visible
  const onElementHover = useCallback(
    (overElem: Element) => {
      const isInsideTarget = !!targetRef.current?.contains(overElem);
      if (isInsideTarget) {
        if (isHovered) return;

        setHandleCoords(getElemCoords(overElem));
        setHovered(true);
        return;
      }

      const isInsideWindow = !!windowRef.current?.contains(overElem);
      if (isInsideWindow) {
        if (isHovered) return;

        setHovered(true);
        return;
      }

      setHovered(false);
    },
    [isHovered],
  );

  return (
    <React.Fragment>
      <HoverWrapper
        parentRef={parentRef}
        forwardRef={targetRef}
        isSVGContext={!!props.isSVGContext}
        isHovered={isHovered}
        onElementHover={onElementHover}
      >
        {target}
      </HoverWrapper>

      <TooltipWindow
        forwardRef={windowRef}
        isSVGContext={!!props.isSVGContext}
        isVisible={isHovered}
        handleCoords={handleCoords}
        portalContainer={portalContainer}
        content={props.content}
      />
    </React.Fragment>
  );
};
