import React, { memo, useCallback, useMemo } from 'react';

import { Button, ButtonGroup, Icon, MenuItem, Tooltip } from '@blueprintjs/core';
import { ItemPredicate, ItemRenderer, Select } from '@blueprintjs/select';
import moment from 'moment';

import { K8SEvent } from '~/domain/timescape/k8s-events';

export enum E2E {
  policyVersionSelectorList = 'policy-version-selector-list',
  policyVersionSelectorButton = 'policy-version-selector-button',
  policyVersionDiffButton = 'policy-version-diff-button',
  policyVersionSelector = 'policy-version-selector-item',
}

type Item = { origin: boolean; event: K8SEvent };

const renderItem: ItemRenderer<Item> = (item, itemProps) => {
  const { handleClick, modifiers } = itemProps;

  if (!modifiers.matchesPredicate) {
    return null;
  }

  return (
    <MenuItem
      key={item.origin ? 'origin' : item.event.timestamp?.valueOf()}
      onClick={handleClick}
      text={<StyledItem item={item} />}
    />
  );
};

const filterItem: ItemPredicate<Item> = (query, item, _idx, exactMatch) => {
  const value = `${item.event.eventTypeStr} ${moment(item.event.timestamp).format('lll')}`;
  const normalizedTitle = value.toLowerCase();
  const normalizedQuery = query.toLowerCase();

  if (exactMatch) {
    return normalizedTitle === normalizedQuery;
  } else {
    return value.indexOf(normalizedQuery) >= 0;
  }
};

export interface Props {
  versions: K8SEvent[];
  currentVersion?: K8SEvent | null | undefined;
  comparing?: boolean;
  disabled?: boolean;
  onChange?: (event: K8SEvent, restoreOrigin: boolean) => void;
  onCompare?: () => void;
}

export const PolicyVersionSelector = memo<Props>(function PolicyVersionSelector(props) {
  const onChange = useCallback(
    (item: Item) => !props.disabled && props.onChange?.(item.event, item.origin),
    [props.onChange, props.disabled],
  );

  const onCompare = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      props.onCompare?.();
    },
    [props.onCompare],
  );

  const dropdownText = useMemo(() => {
    if (!props.currentVersion) return 'Select policy version…';
    return <StyledItem item={{ origin: false, event: props.currentVersion }} />;
  }, [props.currentVersion]);

  const items = useMemo(() => {
    if (props.versions.length === 0) return [];
    return [{ origin: true, event: props.versions[0] }].concat(
      props.versions.map(event => ({ origin: false, event })),
    );
  }, [props.versions]);

  return (
    <Select<Item>
      resetOnQuery
      resetOnClose
      resetOnSelect
      itemPredicate={filterItem}
      itemRenderer={renderItem}
      items={items}
      noResults={<MenuItem disabled={true} text="No matches" />}
      onItemSelect={onChange}
      inputProps={{ placeholder: 'Filter policy versions…' }}
      menuProps={{ 'data-testid': E2E.policyVersionSelectorList } as any}
    >
      <ButtonGroup>
        <Button
          data-testid={E2E.policyVersionSelectorButton}
          outlined
          rightIcon="caret-down"
          text={dropdownText}
          disabled={props.disabled}
          alignText="left"
        />
        {props.currentVersion?.isUpdated && (
          <Tooltip
            minimal
            interactionKind="hover-target"
            content="Show diff with the previous version"
            position="top"
          >
            <Button
              data-testid={E2E.policyVersionDiffButton}
              outlined
              icon={<Icon size={13} icon="comparison" />}
              active={props.comparing}
              alignText="left"
              onClick={onCompare}
            />
          </Tooltip>
        )}
      </ButtonGroup>
    </Select>
  );
});

const StyledItem = (props: { item: Item }) => {
  if (props.item.origin) return 'Restore current version';

  return (
    <span data-testid={E2E.policyVersionSelector}>
      {props.item.event.eventTypeStr}{' '}
      <small>{moment(props.item.event.timestamp).format('lll')}</small>
    </span>
  );
};
