/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter add_pb_suffix,eslint_disable,ts_nocheck,generate_dependencies,long_type_number,use_proto_field_name
// @generated from protobuf file "ui/authorization.proto" (package "ui", syntax proto3)
// tslint:disable
// @ts-nocheck
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message ui.GetAuthzRequest
 */
export interface GetAuthzRequest {
}
/**
 * @generated from protobuf message ui.GetAuthzResponse
 */
export interface GetAuthzResponse {
    /**
     * @generated from protobuf field: bool is_authorized = 1;
     */
    is_authorized: boolean;
    /**
     * @generated from protobuf field: bool is_required = 2;
     */
    is_required: boolean;
    /**
     * @generated from protobuf field: ui.User user = 3;
     */
    user?: User;
}
/**
 * @generated from protobuf message ui.User
 */
export interface User {
    /**
     * @generated from protobuf field: string email = 1;
     */
    email: string;
    /**
     * @generated from protobuf field: string token = 2;
     */
    token: string;
    /**
     * @generated from protobuf field: bool is_relay_admin = 3;
     */
    is_relay_admin: boolean;
    /**
     * @generated from protobuf field: bool is_timescape_admin = 4;
     */
    is_timescape_admin: boolean; // TODO: scopes and other authz-related things are here...
}
// @generated message type with reflection information, may provide speed optimized methods
class GetAuthzRequest$Type extends MessageType<GetAuthzRequest> {
    constructor() {
        super("ui.GetAuthzRequest", []);
    }
    create(value?: PartialMessage<GetAuthzRequest>): GetAuthzRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<GetAuthzRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAuthzRequest): GetAuthzRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: GetAuthzRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ui.GetAuthzRequest
 */
export const GetAuthzRequest = new GetAuthzRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAuthzResponse$Type extends MessageType<GetAuthzResponse> {
    constructor() {
        super("ui.GetAuthzResponse", [
            { no: 1, name: "is_authorized", kind: "scalar", localName: "is_authorized", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "is_required", kind: "scalar", localName: "is_required", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "user", kind: "message", T: () => User }
        ]);
    }
    create(value?: PartialMessage<GetAuthzResponse>): GetAuthzResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.is_authorized = false;
        message.is_required = false;
        if (value !== undefined)
            reflectionMergePartial<GetAuthzResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAuthzResponse): GetAuthzResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool is_authorized */ 1:
                    message.is_authorized = reader.bool();
                    break;
                case /* bool is_required */ 2:
                    message.is_required = reader.bool();
                    break;
                case /* ui.User user */ 3:
                    message.user = User.internalBinaryRead(reader, reader.uint32(), options, message.user);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetAuthzResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool is_authorized = 1; */
        if (message.is_authorized !== false)
            writer.tag(1, WireType.Varint).bool(message.is_authorized);
        /* bool is_required = 2; */
        if (message.is_required !== false)
            writer.tag(2, WireType.Varint).bool(message.is_required);
        /* ui.User user = 3; */
        if (message.user)
            User.internalBinaryWrite(message.user, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ui.GetAuthzResponse
 */
export const GetAuthzResponse = new GetAuthzResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class User$Type extends MessageType<User> {
    constructor() {
        super("ui.User", [
            { no: 1, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "token", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "is_relay_admin", kind: "scalar", localName: "is_relay_admin", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "is_timescape_admin", kind: "scalar", localName: "is_timescape_admin", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<User>): User {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.email = "";
        message.token = "";
        message.is_relay_admin = false;
        message.is_timescape_admin = false;
        if (value !== undefined)
            reflectionMergePartial<User>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: User): User {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string email */ 1:
                    message.email = reader.string();
                    break;
                case /* string token */ 2:
                    message.token = reader.string();
                    break;
                case /* bool is_relay_admin */ 3:
                    message.is_relay_admin = reader.bool();
                    break;
                case /* bool is_timescape_admin */ 4:
                    message.is_timescape_admin = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: User, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string email = 1; */
        if (message.email !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.email);
        /* string token = 2; */
        if (message.token !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.token);
        /* bool is_relay_admin = 3; */
        if (message.is_relay_admin !== false)
            writer.tag(3, WireType.Varint).bool(message.is_relay_admin);
        /* bool is_timescape_admin = 4; */
        if (message.is_timescape_admin !== false)
            writer.tag(4, WireType.Varint).bool(message.is_timescape_admin);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ui.User
 */
export const User = new User$Type();
