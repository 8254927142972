import * as uipb from '~backend/proto/ui/ui_pb';

import { NamespaceDescriptor } from '../namespaces';

export const fromPb = (pb?: uipb.NamespaceDescriptor | null): NamespaceDescriptor | null => {
  if (pb == null) return null;
  return {
    cluster: pb.cluster,
    namespace: pb.name,
  };
};
