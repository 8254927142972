import * as React from 'react';

import { Button, Classes, Spinner, Text } from '@blueprintjs/core';
import classnames from 'classnames';
import { observer } from 'mobx-react';
import moment from 'moment';
import {
  Area,
  ComposedChart,
  ReferenceArea,
  ResponsiveContainer,
  Scatter,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';
import { CategoricalChartState } from 'recharts/types/chart/types';

import { useApplication } from '~/application';
import { TimeRange } from '~/domain/time';
import { K8SEvent } from '~/domain/timescape/k8s-events';
import { useStore } from '~/store';
import { numberWithCommas } from '~/utils/numbers';

import css from './FlowsChart.scss';
import {
  FlowsChartItem,
  useChartWithMouse,
  useFlowsStatsData,
  useK8sPolicyEventsData,
  useTickDurationMs,
  useTooltip,
} from './hooks';
import { AxisDomain } from './utils';

export interface Props {
  timeRange: TimeRange;
  isLoading: boolean;
  onTimeRangeChange?: (timeRange: TimeRange) => void;
}

export enum E2E {
  root = 'flow-counts-chart',
  popover = 'flow-counts-chart-event-popover',
  policyEvent = 'flow-counts-chart-policy-event',
  policyButton = 'flow-counts-chart-go-to-policy-button',
}

export const FlowsChart = observer(function FlowsChart(props: Props) {
  const store = useStore();
  const themeStore = store.themes;
  const isDarkThemeEnabled = themeStore.isDarkTheme;

  const { wrapperRef, isTooltipActive, showTooltip, hideTooltip } = useTooltip();
  const [flipTooltip, setFlipTooltip] = React.useState<boolean>(false);

  const tickDurationMs = useTickDurationMs(props.timeRange.duration);

  const flowsStatData = useFlowsStatsData(
    tickDurationMs,
    store.currentFrame.interactions.flowStats,
  );

  const k8sPolicyEventsData = useK8sPolicyEventsData(
    flowsStatData.arr,
    store.currentFrame.k8sEvents.policyEventsList,
  );

  const chartData = React.useMemo((): FlowsChartItem[] => {
    return flowsStatData.arr.map(item => {
      const k8sEvents = k8sPolicyEventsData[+item.time];
      return {
        ...item,
        y: k8sEvents?.length ? flowsStatData.maxY * 1.25 : undefined,
        k8sPolicyEvents: k8sEvents,
      };
    });
  }, [flowsStatData, k8sPolicyEventsData]);

  const {
    xDomain,
    xTicks,
    yLabel,
    xDateFormat,
    origTimeRange,
    resetTimeRange,
    onMouseDown,
    onMouseMove,
    onMouseUp,
    selectionState,
  } = useChartWithMouse(props.timeRange, tickDurationMs, props.onTimeRangeChange);

  const onMouseMoveWrapper = React.useCallback(
    (evt: CategoricalChartState) => {
      onMouseMove(evt);
      showTooltip();
      if (!evt.activeCoordinate || !wrapperRef.current) return;
      setFlipTooltip(evt.activeCoordinate.x > wrapperRef.current.getBoundingClientRect().width / 2);
    },
    [onMouseMove, showTooltip, wrapperRef.current],
  );

  if (props.isLoading && flowsStatData.arr.length === 0) {
    return (
      <div className={css.wrapper}>
        <Spinner size={60} />
      </div>
    );
  }

  return (
    <div data-testid={E2E.root} className={css.wrapper} ref={wrapperRef}>
      <span className={css.yAxisLabel}>
        {yLabel}
        {origTimeRange && (
          <>
            {' | '}
            <a href="#" onClick={resetTimeRange}>
              Reset selection
            </a>
          </>
        )}
      </span>
      <ResponsiveContainer>
        <ComposedChart
          onMouseDown={onMouseDown}
          onMouseMove={onMouseMoveWrapper}
          onMouseUp={onMouseUp}
          onMouseEnter={showTooltip}
          onMouseLeave={hideTooltip}
          data={chartData}
          margin={{ left: 0, right: 0 }}
        >
          <XAxis
            allowDataOverflow={true}
            dataKey="time"
            type="number"
            interval="preserveStartEnd"
            domain={xDomain as [AxisDomain, AxisDomain]}
            name="Time"
            padding={{ left: 30 }}
            height={20}
            tickMargin={0}
            tickLine={false}
            tick={{ fontSize: 11 }}
            ticks={xTicks}
            stroke={isDarkThemeEnabled ? '#78868E' : '#999'}
            axisLine={false}
            tickFormatter={tick => moment(tick).format(xDateFormat)}
          />
          <YAxis
            allowDataOverflow={true}
            interval="preserveStartEnd"
            name="Count"
            tickMargin={0}
            tickLine={false}
            padding={{ top: 30, bottom: 10 }}
            mirror={true}
            tick={{ fontSize: 11 }}
            stroke={isDarkThemeEnabled ? '#78868E' : '#999'}
            axisLine={false}
            tickFormatter={tick => numberWithCommas(tick)}
          />
          <Area
            dataKey="count"
            fill={isDarkThemeEnabled ? '#4D99CA' : '#F5F7F8'}
            stroke="#4D99CA"
            strokeWidth={2}
            animationDuration={300}
            dot={false}
            cursor="crosshair"
          />
          <Scatter name="k8s-policy-event" dataKey="y" fill="#F6894D" isAnimationActive={false} />
          {selectionState.refAreaLeft && selectionState.refAreaRight && (
            <ReferenceArea
              x1={selectionState.refAreaLeft.getTime()}
              x2={selectionState.refAreaRight.getTime()}
              strokeOpacity={1.0}
              fill={isDarkThemeEnabled ? '#B2CAE0' : '#CCCCCC'}
              fillOpacity="0.5"
            />
          )}
          <Tooltip
            active={isTooltipActive}
            content={PointTooltip}
            isAnimationActive={false}
            position={{ y: -60 }}
            offset={0}
            wrapperStyle={{ zIndex: 1000, marginLeft: flipTooltip ? '0' : undefined }}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
});

const PointTooltip = (props: TooltipProps<number, string>) => {
  return <PointTooltipInner {...props} />;
};

const PointTooltipInner = observer((props: TooltipProps<number, string>) => {
  const { ui } = useApplication();

  const onPolicyOpen = React.useCallback(
    (event: K8SEvent) => {
      ui.controls.openPolicy(event.raw.cluster, event.raw.namespace, event.uuid, event.shortId);
    },
    [props.payload],
  );

  const addedItems = new Set<string>();

  return (
    <div data-testid={E2E.popover} className={classnames(Classes.ELEVATION_2, css.tooltip)}>
      {props.payload?.reduce<React.ReactNode[]>((acc, item) => {
        const payload = item?.payload as FlowsChartItem | null;

        if (!payload?.time) {
          return acc;
        }

        const keyPrefix = payload.time;

        if (typeof payload.count === 'number') {
          const flowCountKey = `${keyPrefix}-flow-count-${payload.count}`;
          if (!addedItems.has(flowCountKey)) {
            acc.push(
              <React.Fragment key={flowCountKey}>
                <Text tagName="b">
                  {numberWithCommas(payload.count)} {payload.count === 1 ? 'flow' : 'flows'}
                </Text>
                <Text className={Classes.TEXT_MUTED}>{moment(props.label).format('lll')}</Text>
              </React.Fragment>,
            );
            addedItems.add(flowCountKey);
          }
        }

        payload.k8sPolicyEvents?.forEach(k8sEvent => {
          const k8sEventKey = `${keyPrefix}-k8s-policy-event-${k8sEvent.uuid}`;
          if (addedItems.has(k8sEventKey)) return;
          acc.push(
            <div data-testid={E2E.policyEvent} key={k8sEventKey} className={css.k8seventItem}>
              <Text className={Classes.TEXT_MUTED}>{k8sEvent.kindStr}</Text>
              <Text tagName="b">{k8sEvent.name}</Text>
              <Text tagName="i">{k8sEvent.eventTypeStr}</Text>
              {k8sEvent.timestamp && (
                <Text className={Classes.TEXT_MUTED}>
                  {moment(k8sEvent.timestamp).format('lll')}
                </Text>
              )}
              <Button
                data-testid={E2E.policyButton}
                small
                onClick={() => onPolicyOpen(k8sEvent)}
                style={{ marginTop: '7px' }}
              >
                View policy
              </Button>
            </div>,
          );
          addedItems.add(k8sEventKey);
        });
        return acc;
      }, [])}
    </div>
  );
});
