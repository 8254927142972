import React from 'react';

import * as d3 from 'd3';
import * as mobx from 'mobx';
import { observer } from 'mobx-react';

import { Intent } from '~/components/widgets/common';
import { XY } from '~/domain/geometry';

export interface Props {
  points: XY[];

  intent?: Intent;
  isDarkThemeEnabled: boolean;
}

export const CubicArrow = observer(function CubicArrow(props: Props) {
  const intent = props.intent ?? Intent.None;

  let arrowColor = props.isDarkThemeEnabled ? '#4f5a63' : '#b8b8b8';

  if (intent === Intent.Primary) {
    arrowColor = props.isDarkThemeEnabled ? '#4F5A63' : '#B8B8B8';
  } else if (intent === Intent.Danger) {
    arrowColor = props.isDarkThemeEnabled ? '#be8e2f' : '#CF9500';
  }

  const pathD = mobx
    .computed(() => {
      const lineGenerator = d3.line().curve(d3.curveStepBefore);

      return lineGenerator(props.points.map(p => [p.x, p.y]));
    })
    .get();

  return <path d={pathD || ''} fill="none" stroke={arrowColor} strokeWidth={2} />;
});
