import React from 'react';

import css from './AddFilterIcon.scss';

export const AddFilterIcon = function AddFilterIcon() {
  const plusIconSrc = `/icons/misc/plus.svg`;

  return (
    <span className={css.icon}>
      <img src={plusIconSrc} />
    </span>
  );
};
