import { NamespaceDescriptor } from '~/domain/namespaces';
import { FlowSummary, FlowSummaryPlain, PodInfo } from '~/domain/timescape';
import { logger } from '~/utils/logger';
import * as tspb from '~backend/proto/timescape/v1/container_pb';
import * as uipb from '~backend/proto/ui/ui_pb';

import { authTypeFromPb } from './auth-type';
import { tcpFlagsFromObject } from './flows';
import * as time from './time';
import { verdictFromPb } from './verdict';

export const namespaceDescriptorsFromProto = (
  nsDescriptors: uipb.NamespaceDescriptor[],
): NamespaceDescriptor[] => {
  const parsed: NamespaceDescriptor[] = [];

  nsDescriptors.forEach(nsDesc => {
    const desc = namespaceDescriptorFromProto(nsDesc);
    if (desc == null) return;

    parsed.push(desc);
  });

  return parsed;
};

export const namespaceDescriptorFromProto = (
  nsDesc: uipb.NamespaceDescriptor,
): NamespaceDescriptor | null => {
  if (nsDesc == null) return null;

  const cluster = nsDesc.cluster;
  const namespace = nsDesc.name;

  return { cluster, namespace };
};

export const flowSummariesFromProto = (proto: uipb.FlowSummary[]): FlowSummary[] => {
  const flowSummaries: FlowSummary[] = [];

  proto.forEach(pbFlowSummary => {
    const flowSummary = flowSummaryFromProto(pbFlowSummary);
    if (flowSummary == null) return;

    flowSummaries.push(flowSummary);
  });

  return flowSummaries;
};

export const flowSummaryFromProto = (proto: uipb.FlowSummary): FlowSummary | null => {
  if (proto == null) return null;

  const time = proto.time;
  if (time == null) {
    logger.warn('uipb.FlowSummary contains empty time: ', proto);
    return null;
  }

  const plain: FlowSummaryPlain = {
    id: proto.id,
    time: time,
    sourcePod: proto.source_pod,
    sourceLabels: proto.source_labels,
    sourceIp: proto.source_ip,
    destinationPod: proto.destination_pod,
    destinationLabels: proto.destination_labels,
    destinationNames: proto.destination_names,
    destinationIp: proto.destination_ip,
    verdict: verdictFromPb(proto.verdict),
    sourceWorkloads: proto.source_workloads ?? [],
    destinationWorkloads: proto.destination_workloads ?? [],
    authType: authTypeFromPb(proto.auth_type),
  };

  const has = proto.has;

  const tcpFlags = proto.tcp_flags;
  if (tcpFlags) {
    plain.tcpFlags = tcpFlagsFromObject(tcpFlags) ?? void 0;
  }

  if (has?.destination_port) {
    plain.destinationPort = proto.destination_port ?? void 0;
  }

  return new FlowSummary(plain);
};

export const podInfosFromProto = (protos?: tspb.EventsContainerInfo[] | null): PodInfo[] => {
  return (protos ?? []).reduce((acc, proto) => {
    const parsed = podInfoFromProto(proto);
    if (parsed == null) return acc;

    return acc.concat(parsed);
  }, [] as PodInfo[]);
};

export const podInfoFromProto = (proto: tspb.EventsContainerInfo): PodInfo | null => {
  if (proto == null) return null;

  const cntStartTime = time.parseTimeFromObject(proto.start_time);
  if (cntStartTime == null) return null;

  return {
    name: proto.pod_name,
    nodeName: proto.node_name,
    namespace: proto.namespace,
    containerImageId: proto.image_id,
    containerStartTime: time.timeToDate(cntStartTime),
  };
};

export const podInfoToProto = (pi: PodInfo): tspb.EventsContainerInfo => {
  const eventsPodInfo = tspb.EventsContainerInfo.create({
    pod_name: pi.name,
    namespace: pi.namespace,
    node_name: pi.nodeName,
    image_id: pi.containerImageId,
    start_time: time.dateToPBTimestamp(pi.containerStartTime),
  });

  return eventsPodInfo;
};
