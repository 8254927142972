import * as React from 'react';

import { CategoricalChartState } from 'recharts/types/chart/types';

import { CountStats } from '~/domain/common';
import { TimeDuration, TimeRange } from '~/domain/time';
import { K8SEvent } from '~/domain/timescape/k8s-events';

import css from './FlowsChart.scss';
import { AxisDomain, getChartPivotTime, getXAxisDomain, getXAxisTicks, mapDuration } from './utils';

export type FlowsChartItem = {
  count?: number | null;
  y?: number | null;
  time: Date;
  k8sPolicyEvents?: K8SEvent[];
};

export function useTooltip() {
  const [isActive, setIsActive] = React.useState<boolean>(true);
  const [tooltipWrapper, setTooltipWrapper] = React.useState<HTMLDivElement | null>(null);
  const wrapperRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const div = wrapperRef.current;
    if (!div) return;
    const disposers = ['mouseenter', 'mouseleave', 'mousemove', 'mouseout', 'mouseover'].map(
      eventName => {
        const listener = () => {
          const wrapper = document.querySelector<HTMLDivElement>(`.${css.tooltip}`);
          if (wrapper !== tooltipWrapper) {
            setTooltipWrapper(wrapper);
          }
        };
        div.addEventListener(eventName, listener, false);
        return () => div.removeEventListener(eventName, listener);
      },
    );
    return () => disposers.forEach(disposer => disposer());
  }, [wrapperRef.current, tooltipWrapper]);

  // Hack to make tooltip hoverable and clickable
  React.useEffect(() => {
    if (!tooltipWrapper) return;

    tooltipWrapper.style.pointerEvents = 'auto';
    tooltipWrapper.style.cursor = 'auto';

    const disposers = ['mouseenter', 'mouseleave', 'mousemove', 'mouseout', 'mouseover'].map(
      eventName => {
        const listener = (evt: Event) => {
          evt.preventDefault();
          evt.stopPropagation();
          if (eventName === 'mouseleave') {
            hideTooltip();
          }
        };
        tooltipWrapper.addEventListener(eventName, listener, false);
        return () => tooltipWrapper.removeEventListener(eventName, listener);
      },
    );
    return () => disposers.forEach(disposer => disposer());
  }, [tooltipWrapper]);

  const showTooltip = React.useCallback(() => {
    setIsActive(true);
  }, []);

  const hideTooltip = React.useCallback(() => {
    setIsActive(false);
  }, []);

  return {
    wrapperRef,
    isTooltipActive: isActive,
    showTooltip,
    hideTooltip,
  };
}

export function useTickDurationMs(duration: TimeDuration): number {
  return React.useMemo(() => {
    const { milliseconds: tickDurationMs } = mapDuration(duration, key => {
      switch (key) {
        case 'ZeroToDay':
          return new TimeDuration(60 * 1000);
        case 'DayToWeek':
          return new TimeDuration(60 * 60 * 1000);
        case 'WeekToMonth':
          return new TimeDuration(60 * 60 * 6 * 1000);
        case 'MonthPlus':
          return new TimeDuration(60 * 60 * 24 * 1000);
      }
    });
    return tickDurationMs;
  }, [duration.milliseconds]);
}

export function useFlowsStatsData(tickDurationMs: number, flowStats: CountStats[]) {
  return React.useMemo(() => {
    const result: FlowsChartItem[] = [];

    let maxY = -1;
    for (
      let i = 0, curr = flowStats[0], prev = null as CountStats | null;
      i < flowStats.length;
      i = i + 1, curr = flowStats[i], prev = flowStats[i - 1]
    ) {
      if (prev) {
        const prevTickMs = prev.timestampAsDate.getTime();
        const currTickMs = curr.timestampAsDate.getTime();
        const tickDiffMs = new TimeDuration(currTickMs - prevTickMs);
        const missedTicksCount = tickDiffMs.milliseconds / tickDurationMs - 1;
        for (let tick = 1; tick <= missedTicksCount; tick++) {
          const missedTick = new Date(prevTickMs + tickDurationMs * tick);
          result.push({ count: null, time: missedTick });
        }
      }
      result.push({ count: curr.count, time: curr.timestampAsDate });
      if (curr.count > maxY) maxY = curr.count;
    }

    if (result.length > 0) {
      const firstTick = result[0];
      const lastTick = result[result.length - 1];
      const prefixTick = new Date(+firstTick.time - tickDurationMs);
      const suffixTick = new Date(+lastTick.time + tickDurationMs);
      result.unshift({ count: null, time: prefixTick });
      result.push({ count: null, time: suffixTick });
    }

    return { maxY, arr: result };
  }, [tickDurationMs, flowStats]);
}

export function useK8sPolicyEventsData(flowCounts: FlowsChartItem[], k8sEvents: K8SEvent[]) {
  return React.useMemo(() => {
    // Going to group events by nearest timestamp pivots
    const k8sEventsMap: { [timestamp: number]: K8SEvent[] } = {};

    const sortedFlowCounts = [...flowCounts].sort((a, b) => {
      return +a.time - +b.time;
    });
    const flowCountsCnt = sortedFlowCounts.length;

    if (flowCountsCnt === 0) {
      return k8sEventsMap;
    }

    const sortedK8sEvents = [...k8sEvents].sort((a, b) => {
      return +(a.timestamp ?? 0) - +(b.timestamp ?? 0);
    });
    const k8sEventsCnt = sortedK8sEvents.length;

    for (let k8sEventIdx = 0, flowCountIdx = 0; k8sEventIdx < k8sEventsCnt; k8sEventIdx++) {
      const k8sEvent = sortedK8sEvents[k8sEventIdx];

      if (!k8sEvent.timestamp) continue;

      let minTimeDiff = Number.MAX_SAFE_INTEGER;
      while (flowCountIdx < flowCountsCnt) {
        const timeDiff = Math.abs(+sortedFlowCounts[flowCountIdx].time - +k8sEvent.timestamp);
        if (timeDiff >= minTimeDiff) break;
        minTimeDiff = timeDiff;
        flowCountIdx++;
      }
      flowCountIdx = Math.max(0, flowCountIdx - 1);

      (k8sEventsMap[+sortedFlowCounts[flowCountIdx].time] ??= []).push(k8sEvent);
    }

    return k8sEventsMap;
  }, [flowCounts, k8sEvents]);
}

export function useChartWithMouse(
  timeRange: TimeRange,
  tickDurationMs: number,
  onTimeRangeChange?: (timeRange: TimeRange) => void,
) {
  const [origTimeRange, setOrigTimeRange] = React.useState<TimeRange | null>(null);

  const [selectionState, setSelectionState] = React.useState({
    refAreaLeft: null as Date | null,
    refAreaRight: null as Date | null,
  });

  const resetTimeRange = React.useCallback(
    (evt: React.MouseEvent<HTMLAnchorElement>) => {
      evt.preventDefault();
      if (origTimeRange === null) return;
      onTimeRangeChange?.(origTimeRange);
      setOrigTimeRange(null);
      setSelectionState(() => ({ refAreaLeft: null, refAreaRight: null }));
    },
    [selectionState, origTimeRange, onTimeRangeChange],
  );

  const { xDomain, xTicks, xDateFormat, yLabel } = React.useMemo(() => {
    const pivot = getChartPivotTime(timeRange.startISOString);

    const xDomain = pivot
      ? getXAxisDomain(tickDurationMs, pivot, timeRange.endISOString)
      : ['dataMin', 'dataMax'];

    const xTicks = pivot ? getXAxisTicks(xDomain as [AxisDomain, AxisDomain]) : undefined;

    const years = new Set(xTicks?.map(tick => new Date(tick).getFullYear()));
    const xDateFormat = years.size > 1 ? 'YYYY/MM/DD HH:mm' : 'MM/DD HH:mm';

    const yLabel = mapDuration(timeRange.duration, key => {
      switch (key) {
        case 'ZeroToDay':
          return 'flows / minute';
        case 'DayToWeek':
          return 'flows / hour';
        case 'WeekToMonth':
          return 'flows / 6 hours';
        case 'MonthPlus':
          return 'flows / day';
      }
    });

    return { xDomain, xTicks, xDateFormat, yLabel };
  }, [timeRange]);

  const onMouseUp = React.useCallback(() => {
    if (
      +(selectionState.refAreaLeft ?? 0) === +(selectionState.refAreaRight ?? 0) ||
      selectionState.refAreaLeft === null ||
      selectionState.refAreaRight === null
    ) {
      setSelectionState(() => ({ refAreaLeft: null, refAreaRight: null }));
      return;
    }

    if (selectionState.refAreaLeft > selectionState.refAreaRight) {
      [selectionState.refAreaLeft, selectionState.refAreaRight] = [
        selectionState.refAreaRight,
        selectionState.refAreaLeft,
      ];
    }

    onTimeRangeChange?.(new TimeRange(selectionState.refAreaLeft, selectionState.refAreaRight));

    if (origTimeRange === null) setOrigTimeRange(timeRange);

    setSelectionState(() => ({ refAreaLeft: null, refAreaRight: null }));
  }, [selectionState, origTimeRange, timeRange, onTimeRangeChange]);

  const onMouseDown = React.useCallback(
    (evt: CategoricalChartState) => {
      const activeLabel = evt.activeLabel as unknown as Date;
      if (!activeLabel) return;
      setSelectionState(cur => ({
        refAreaLeft: activeLabel,
        refAreaRight: cur.refAreaRight,
      }));
    },
    [selectionState],
  );

  const onMouseMove = React.useCallback(
    (evt: CategoricalChartState) => {
      const activeLabel = evt.activeLabel as unknown as Date;
      if (!activeLabel || !selectionState.refAreaLeft) return;
      setSelectionState(cur => ({
        refAreaRight: activeLabel,
        refAreaLeft: cur.refAreaLeft,
      }));
    },
    [selectionState],
  );

  return {
    onMouseUp,
    onMouseDown,
    onMouseMove,
    selectionState,
    xDomain,
    xTicks,
    xDateFormat,
    yLabel,
    resetTimeRange,
    origTimeRange,
  };
}
