import React, { useCallback } from 'react';

import { Button } from '@blueprintjs/core';
import { observer } from 'mobx-react';

import { PolicySuggestionAction, PolicySuggestionEntry } from '~/store/stores/policy-suggestions';

import css from './PolicySuggestionCard.scss';

export interface Props {
  suggestion: PolicySuggestionEntry;
}

export const PolicySuggestionCard = observer(function PolicySuggestionCard(props: Props) {
  return (
    <div className={css.wrapper}>
      <small className={`${css.kind} ${css[props.suggestion.kind]}`}>{props.suggestion.kind}</small>
      <h4 className={css.title}>{props.suggestion.title}</h4>
      <p className={css.description}>{props.suggestion.description}</p>
      <div className={css.actions}>
        {props.suggestion.actionsList.map(action => (
          <Action key={action.id} action={action} />
        ))}
      </div>
    </div>
  );
});

interface ActionProps {
  action: PolicySuggestionAction;
}

const Action = observer(function PolicyAssistantCardAction(props: ActionProps) {
  const onClick = useCallback(() => {
    props.action.activate();
  }, [props.action, props.action.activate]);

  return (
    <Button small outlined onClick={onClick} className={css.action} {...props.action.props}>
      {props.action.title}
    </Button>
  );
});
