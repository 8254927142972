import React from 'react';

import { Switch } from '@blueprintjs/core';
import * as mobx from 'mobx';
import { observer } from 'mobx-react';

import { useApplication } from '~/application';
import { DataMode } from '~/domain/interactions';

import css from './DataModeSwitcher.scss';

export enum E2E {
  root = 'mode-switcher',
}

export const DataModeSwitcher = observer(function DataModeSwitcher() {
  const { ui, dataLayer, store } = useApplication();
  const transferState = dataLayer.transferState;

  const { isRealtime, currentNamespace } = mobx
    .computed(() => {
      const isRealtime = transferState.dataMode === DataMode.CiliumStreaming;
      const currentNamespace = store.clusterNamespaces.currDescriptor;

      return {
        currentNamespace,
        isRealtime,
      };
    })
    .get();

  const title = isRealtime
    ? `${currentNamespace?.namespace} is only available in Live View`
    : `${currentNamespace?.namespace} is only available in Timescape View`;

  if (!store.uiSettings.isTimescapeEnabled) return null;
  if (store.uiSettings.isTimescapeOnlyEnabled) return null;

  // NOTE: Additional <span> is used here because original top level element
  // is hidden and thus Cypress tests are failing.
  return (
    <span>
      <Switch
        checked={isRealtime}
        onChange={() => ui.toggleDataMode()}
        label="Live View"
        className={css.wrapper}
        title={title}
        data-testid={E2E.root}
      />
    </span>
  );
});
