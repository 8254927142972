import { toJS } from 'mobx';
import * as YAML from 'yaml';

import { EndpointSelector, PortProtocol, PortRule } from '~/domain/cilium/cnp/types.generated';
import {
  LabelSelector,
  NetworkPolicy,
  NetworkPolicyEgressRule,
  NetworkPolicyIngressRule,
  NetworkPolicyPeer,
  NetworkPolicySpec,
} from '~/domain/k8s/knp/types';
import { NamespaceLabelKey } from '~/domain/labels';

import { PolicyCard } from './cards';
import { PolicyEndpoint } from './endpoint';
import {
  CardKind,
  CardSide,
  CardsMap,
  DefaultDenyKind,
  EndpointAllKind,
  EndpointKind,
  PolicyBuilder,
  PolicyKind,
} from './types';
import { YAML_POLICY_STRINGIFY_OPTS } from '../misc';

export type IPolicyBuilder = PolicyBuilder<
  NetworkPolicySpec,
  NetworkPolicyIngressRule,
  NetworkPolicyEgressRule
>;

export const KubernetesNetworkPolicyBuilder: IPolicyBuilder = {
  parsePolicy(json) {
    const np = json as NetworkPolicy;
    const policyName = (np.metadata.name ?? null) as string | null;
    const policyNamespace = (np.metadata.namespace ?? null) as string | null;

    const cards = new Map<string, PolicyCard>();

    const unspprtdIngress: NetworkPolicyIngressRule[] = [];
    const unspprtdEgress: NetworkPolicyEgressRule[] = [];

    let defaultDenyIngress: DefaultDenyKind | null = null;
    let defaultDenyEgress: DefaultDenyKind | null = null;

    const spec = np.spec;
    if (!spec) {
      return {
        policyName,
        policyNamespace,
        isSingleSpec: true,
        results: [
          {
            cards: new Map(),
            defaultDenyEgress: null,
            defaultDenyIngress: null,
            unspprtdEgress: [],
            unspprtdIngress: [],
          },
        ],
      };
    }

    const addCardEndpoint = (
      cardsToAdd: Map<string, PolicyCard>,
      cardKind: CardKind,
      cardSide: CardSide,
      endpoint: PolicyEndpoint,
    ) => {
      const cardId = PolicyCard.buildId(cardSide, cardKind);
      const card =
        cards.get(cardId)?.clone() ?? cardsToAdd.get(cardId) ?? new PolicyCard(cardSide, cardKind);
      card.addEndpoints(endpoint);
      cardsToAdd.set(card.id, card);
      return card;
    };

    const mergeCards = (cardsToAdd: Map<string, PolicyCard>) => {
      cardsToAdd.forEach((card, cardId) => {
        const curCard = cards.get(cardId);
        if (!curCard) return cards.set(cardId, card);
        return curCard.addEndpoints(...card.endpointsList);
      });
    };

    if (spec.policyTypes?.some(t => t.toLowerCase() === 'ingress')) {
      defaultDenyIngress = DefaultDenyKind.KnpPolicyTypeField;
    } else if (Array.isArray(spec.ingress)) {
      defaultDenyIngress = DefaultDenyKind.KnpEmptyRulesArray;
    }

    if (spec.policyTypes?.some(t => t.toLowerCase() === 'egress')) {
      defaultDenyEgress = DefaultDenyKind.KnpPolicyTypeField;
    } else if (Array.isArray(spec.egress)) {
      defaultDenyEgress = DefaultDenyKind.KnpEmptyRulesArray;
    }

    if (spec.podSelector) {
      const card = new PolicyCard(CardSide.Selector, CardKind.InNamespace).setPodSelector(
        spec.podSelector,
      );
      cards.set(card.id, card);
    }

    const entries = [
      { cardSide: CardSide.Egress, rules: spec.egress },
      { cardSide: CardSide.Ingress, rules: spec.ingress },
    ];

    for (const { cardSide, rules } of entries) {
      let ruleIdx = 0;
      const ruleNodes = np.spec?.[cardSide as 'ingress' | 'egress'] ?? [];

      rulesLoop: for (const rule of rules ?? []) {
        const originRule = { rule, policyKind: PolicyKind.KNP };

        const cardsToAdd = new Map<string, PolicyCard>();
        const range = (ruleNodes[ruleIdx++] as YAML.Node).range;

        const isEgress = cardSide === CardSide.Egress;
        const isIngress = cardSide === CardSide.Ingress;

        const isEmptyRuleObj = Object.keys(rule).length === 0;

        if (isEmptyRuleObj) {
          addCardEndpoint(
            cardsToAdd,
            CardKind.All,
            cardSide,
            PolicyEndpoint.newAll().setAllKind(EndpointAllKind.KNPEmptyObject),
          );
          mergeCards(cardsToAdd);
          continue rulesLoop;
        }

        const addSideCardEndpoint = (cardKind: CardKind, endpoint: PolicyEndpoint) => {
          endpoint.setOrigYamlRange(range);
          return addCardEndpoint(cardsToAdd, cardKind, cardSide, endpoint);
        };

        if (isEgress && PolicyEndpoint.checkKNPRuleIsKubeDns(rule)) {
          addSideCardEndpoint(
            CardKind.InCluster,
            PolicyEndpoint.newKubeDns().setOriginRule(originRule),
          );
          mergeCards(cardsToAdd);
          continue rulesLoop;
        }

        const peers =
          'to' in rule
            ? (rule as NetworkPolicyEgressRule).to
            : 'from' in rule
              ? (rule as NetworkPolicyIngressRule).from
              : null;

        const ports: PortRule[] = [];
        for (const port of rule.ports ?? []) {
          if (port.port) {
            const portProtocol: PortProtocol = { port: port.port as any };
            if (port.protocol) portProtocol.protocol = port.protocol;
            ports.push({ ports: [portProtocol] });
          }
        }

        if (!peers?.length && ports.length) {
          addSideCardEndpoint(CardKind.All, PolicyEndpoint.newAll().addPorts(ports));
          mergeCards(cardsToAdd);
          continue rulesLoop;
        }

        peersLoop: for (const peer of peers ?? []) {
          const { podSelector, namespaceSelector } = peer;

          if (peer.ipBlock) {
            if (
              peer.ipBlock.cidr === '0.0.0.0/0' &&
              !peer.podSelector &&
              !peer.namespaceSelector &&
              ports.length === 0
            ) {
              addSideCardEndpoint(
                CardKind.OutsideCluster,
                PolicyEndpoint.newAll(EndpointAllKind.KNPZeroCidr),
              );
              continue peersLoop;
            } else {
              addSideCardEndpoint(
                CardKind.OutsideCluster,
                PolicyEndpoint.fromCIDR(peer.ipBlock).addPorts(ports),
              );
              if (!podSelector && !namespaceSelector) continue peersLoop;
            }
          }

          if (!podSelector && !namespaceSelector) {
            if (isEgress) unspprtdEgress.push(rule);
            if (isIngress) unspprtdIngress.push(rule);
            continue rulesLoop;
          }

          const checkIsEmptySelector = (s?: LabelSelector | null) => {
            return (
              Object.keys(s?.matchLabels ?? {}).length === 0 &&
              (s?.matchExpressions ?? []).length === 0
            );
          };

          const isEmptySelector = [podSelector, namespaceSelector].every(checkIsEmptySelector);

          const endpointKind = podSelector
            ? EndpointKind.LabelsSelector
            : EndpointKind.NamespaceSelector;

          const endpoint = isEmptySelector
            ? PolicyEndpoint.newAll()
            : PolicyEndpoint.fromKind(endpointKind);

          if (namespaceSelector && checkIsEmptySelector(namespaceSelector)) {
            endpoint.setAllKind(EndpointAllKind.AllNamespacesSelector);
          }

          const selector: EndpointSelector = {};
          if (podSelector?.matchLabels) {
            selector.matchLabels = { ...podSelector.matchLabels };
          }
          if (podSelector?.matchExpressions) {
            selector.matchExpressions = podSelector.matchExpressions.slice();
          }

          const prependKey = (key: string) => {
            return `${NamespaceLabelKey}.labels.${key}`;
          };

          if (namespaceSelector?.matchLabels) {
            Object.entries(namespaceSelector.matchLabels).forEach(([key, value]) => {
              if (!selector.matchLabels) selector.matchLabels = {};
              selector.matchLabels[prependKey(key)] = value;
            });
          }
          if (namespaceSelector?.matchExpressions) {
            namespaceSelector.matchExpressions.forEach(expr => {
              if (!selector.matchExpressions) selector.matchExpressions = [];
              selector.matchExpressions.push({
                ...expr,
                key: prependKey(expr.key),
              });
            });
          }

          endpoint.setSelector(selector).addPorts(ports);

          const cardKind = namespaceSelector ? CardKind.InCluster : CardKind.InNamespace;

          const card = addSideCardEndpoint(cardKind, endpoint);

          if (endpoint.namespace) card.setNamespace(endpoint.namespace);
        }

        if (cardsToAdd.size > 0) {
          mergeCards(cardsToAdd);
        } else if (Object.keys(rule).length > 0) {
          (isEgress ? unspprtdEgress : unspprtdIngress).push(rule);
        }
      }
    }

    return {
      policyName,
      policyNamespace,
      isSingleSpec: true,
      results: [
        {
          cards,
          defaultDenyIngress,
          defaultDenyEgress,
          unspprtdEgress,
          unspprtdIngress,
        },
      ],
    };
  },

  generateYaml(policyName, policyNamespace, specs) {
    const np: NetworkPolicy = this.generatePolicyHeader(policyName, policyNamespace);

    if (specs.length === 0) {
      return YAML.stringify(np, YAML_POLICY_STRINGIFY_OPTS);
    }

    const { spec, unspprtdEgress, unspprtdIngress } = specs[0];

    np.spec = { podSelector: spec.podSelector };

    if (spec.policyTypes?.length) np.spec.policyTypes = spec.policyTypes;

    if (spec.ingress) {
      const ingress = spec.ingress.slice();
      if (unspprtdIngress.length > 0) {
        (ingress as any).push('__unsupported_ingress__');
        ingress.push(...unspprtdIngress);
      }
      Object.assign(np.spec, { ingress });
    } else if (np.spec.policyTypes?.some(t => t.toLowerCase() === 'ingress')) {
      Object.assign(np.spec, { ingress: [] });
    }

    if (spec.egress) {
      const egress = spec.egress.slice();
      if (unspprtdEgress.length > 0) {
        (egress as any).push('__unsupported_egress__');
        egress.push(...unspprtdEgress);
      }
      Object.assign(np.spec, { egress });
    } else if (np.spec.policyTypes?.some(t => t.toLowerCase() === 'egress')) {
      Object.assign(np.spec, { egress: [] });
    }

    let yaml = YAML.stringify(np, YAML_POLICY_STRINGIFY_OPTS);
    ['ingress', 'egress'].forEach(dir => {
      yaml = yaml.replace(
        new RegExp(`- __unsupported_${dir}__`, 'g'),
        `# editor doesn't yet support ${dir} rules below`,
      );
    });

    return yaml;
  },

  generateYamlForCardEndpoint(card, endpoint, onlyRule) {
    const rule = this.generateSpecForCardEndpoint(card, endpoint, onlyRule);

    if (!rule) return null;

    if (onlyRule) {
      const spec = rule.ingress
        ? { ingress: rule.ingress }
        : rule.egress
          ? { egress: rule.egress }
          : null;
      return spec ? YAML.stringify(spec, YAML_POLICY_STRINGIFY_OPTS) : null;
    }

    return YAML.stringify(rule, YAML_POLICY_STRINGIFY_OPTS);
  },

  generatePolicyHeader(name, namespace) {
    return {
      apiVersion: 'networking.k8s.io/v1',
      kind: 'NetworkPolicy',
      metadata: {
        name: name || 'untitled-policy',
        namespace: namespace || undefined,
      },
    };
  },

  generateSpecForCardEndpoint(card, endpoint, onlyRule) {
    const ingresses: CardsMap = new Map();
    const egresses: CardsMap = new Map();

    const cloned = card.clone().flushEndpoints();
    if (endpoint) cloned.addEndpoints(endpoint);

    if (card.isIngress) {
      ingresses.set(card.id, cloned);
    } else if (card.isEgress) {
      egresses.set(card.id, cloned);
    }

    return this.generateSpec(card.podSelector, ingresses, egresses, null, null, null, onlyRule);
  },

  generateSpec(podSelector, ingresses, egresses, defaultDenyIngress, defaultDenyEgress) {
    const ingress: NetworkPolicyIngressRule[] = [];
    const egress: NetworkPolicyEgressRule[] = [];

    const spec: NetworkPolicySpec = {
      podSelector: toJS(podSelector ?? {}),
    };

    function processCards(
      cards: CardsMap,
      dir: 'ingress' | 'egress',
      target: NetworkPolicyEgressRule[] | NetworkPolicyIngressRule[],
      defaultDeny: DefaultDenyKind | null,
    ) {
      cards.forEach(card => {
        card.endpointsMap.forEach(endpoint => {
          if (card.getUnsupportedReasonInfo(PolicyKind.KNP, endpoint)) return;

          if (endpoint.isKubeDns && endpoint.originRule?.policyKind === PolicyKind.KNP) {
            target.push(
              endpoint.originRule.rule as NetworkPolicyEgressRule | NetworkPolicyIngressRule,
            );
            return;
          }

          const peer: NetworkPolicyPeer | null = {};

          switch (endpoint.kind) {
            case EndpointKind.All:
              switch (card.kind) {
                case CardKind.OutsideCluster:
                  peer.ipBlock = { cidr: '0.0.0.0/0' };
                  break;
                case CardKind.InNamespace:
                  peer.podSelector = {};
                  break;
                case CardKind.InCluster:
                  peer.namespaceSelector = {};
                  break;
              }
              break;
            case EndpointKind.NamespaceSelector:
              peer.namespaceSelector = endpoint.namespaceSelector ?? {};
              break;
            case EndpointKind.KubeDns:
              peer.namespaceSelector = {};
              peer.podSelector = { matchLabels: { 'k8s-app': 'kube-dns' } };
              break;
            case EndpointKind.LabelsSelector:
              if (card.isInCluster) {
                // check if this contains all namespace selector from CNP
                if (endpoint.selectsAllNamespaces) {
                  peer.namespaceSelector = {};
                } else {
                  peer.namespaceSelector = endpoint.namespaceSelector ?? {};
                }
              }
              peer.podSelector = endpoint.podSelector ?? {};
              break;
            case EndpointKind.Cidr:
              endpoint.cidr && (peer.ipBlock = endpoint.cidr);
              break;
            default:
              return;
          }

          const rule: NetworkPolicyEgressRule | NetworkPolicyIngressRule = {};

          if (peer && Object.keys(peer).length > 0) {
            if (dir === 'egress') {
              (rule as NetworkPolicyEgressRule).to = [peer];
            } else {
              (rule as NetworkPolicyIngressRule).from = [peer];
            }
          }

          endpoint.ports?.forEach(portRule => {
            portRule.ports?.forEach(p => {
              if (!rule.ports) rule.ports = [];
              rule.ports.push({
                port: +p.port as number,
                protocol: p.protocol,
              });
            });
          });

          target.push(rule);
        });
      });

      if (target.length > 0) {
        spec[dir as 'ingress' | 'egress'] = target;
      } else if (defaultDeny === DefaultDenyKind.KnpEmptyRulesArray) {
        spec[dir as 'ingress' | 'egress'] = [];
      }

      if (
        defaultDeny === DefaultDenyKind.KnpPolicyTypeField ||
        defaultDeny === DefaultDenyKind.CnpEmptyObjectRule
      ) {
        if (!spec.policyTypes) spec.policyTypes = [];
        spec.policyTypes.push(dir === 'egress' ? 'Egress' : 'Ingress');
      }
    }

    processCards(ingresses, 'ingress', ingress, defaultDenyIngress);
    processCards(egresses, 'egress', egress, defaultDenyEgress);

    return spec;
  },
};
