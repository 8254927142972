import React from 'react';

import { createRoot } from 'react-dom/client';

import { ApplicationBuilder, ApplicationProvider } from '~/application';
import { DataLayerBuilder } from '~/data-layer';
import { Router, RouterBuilder, RouterKind, RouterProvider } from '~/router';
import { Store } from '~/store';
import { UILayerBuilder } from '~/ui-layer';

import { Environment } from './environment';

import './blueprint.scss';
import './index.scss';

declare global {
  interface Window {
    debugTools: any;
  }
}

const buildAPIUrl = (env: Environment): string => {
  if (!env.isDev) {
    return `${document.location.origin}/hubble-ui-api/`;
  }

  const schema = env.var('API_SCHEMA');
  const host = env.var('API_HOST');
  const port = env.var('API_PORT');
  const path = env.var('API_PATH');
  const slashedPath = path?.startsWith('/') ? path : `/${path}`;

  return host != null
    ? `${schema}://${host}:${port}${slashedPath}`
    : `${document.location.origin}/hubble-ui-api/`;
};

const run = async () => {
  ApplicationBuilder.new()
    .withStore(() => new Store(Router.getApplicationByPath(document.location.pathname)))
    .withDataLayer((env, store) =>
      DataLayerBuilder.new()
        .withStore(store)
        .withFeatureFlagsSelector('#hubble-ui/feature-flags')
        .withAuthorizationSelector('#hubble-ui/authorization')
        .withSSRErrorsSelector('#hubble-ui/ssr-errors')
        .withCustomProtocolBaseURL(() => buildAPIUrl(env))
        .withCustomProtocolRequestTimeout(3000)
        .withCustomProtocolMessagesInJSON(env.isDev)
        .withCustomProtocolCORSEnabled(true)
        .build(),
    )
    .withRouter((env, dataLayer) =>
      RouterBuilder.new()
        .withUnderlyingRouter(env.isTesting ? RouterKind.Memory : RouterKind.Browser)
        .withDataLayer(dataLayer)
        .build(),
    )
    .withUILayer((store, router, dataLayer) =>
      UILayerBuilder.new()
        .withStore(store)
        .withRouter(router)
        .withDataLayer(dataLayer)
        .withCSSVarsInjection(true)
        .build(),
    )
    .withRenderFunction((targetElem, app) => {
      const root = createRoot(targetElem);

      // NOTE: Use RouterProvider here not to create dependency cycle:
      // Application -> Router -> <Our app component> -> useApplication
      root.render(
        <ApplicationProvider app={app}>
          <RouterProvider router={app.router} />
        </ApplicationProvider>,
      );
    })
    .build()
    .onBeforeMount(app => {
      app.uiLayer.onBeforeMount();
    })
    .onMounted(app => app.uiLayer.onMounted())
    .mount('#app');
};

run();

if ((module as any).hot) {
  (module as any).hot.accept();
}
