import React from 'react';

import css from './FlowFiltersTag.scss';
import { FilterDeleteIcon } from '../FilterDeleteIcon/FilterDeleteIcon';
import { FlowFilterTagProps } from '../types';

export const FlowFiltersTag = function FlowFiltersTag(props: FlowFilterTagProps) {
  const { label, index, onFilterTagClick, onRemove, getSelectedItemProps } = props;

  return (
    <div className={css.container}>
      <button
        className={css.filterTagButton}
        {...getSelectedItemProps({ index, selectedItem: index.toString() })}
        onClick={e => {
          e.stopPropagation();
          onFilterTagClick();
        }}
      >
        {label}
      </button>

      <button className={css.removeFilterTagButton} onClick={onRemove}>
        <FilterDeleteIcon />
      </button>
    </div>
  );
};
