import React from 'react';

import classNames from 'classnames';
import { useSelect } from 'downshift';
import useDeepCompareEffect from 'use-deep-compare-effect';

import css from './SelectFilter.scss';
import { NamespaceSelectIcon } from '../NamespaceSelectIcon/NamespaceSelectIcon';
import { FilterOption, FlowFilterGroupFormBaseComponentProps } from '../types';

export const SelectFilter = function SelectFilter(props: FlowFilterGroupFormBaseComponentProps) {
  const { config, filterState, onFilterChange } = props;
  const {
    label,
    filterComponentProps,
    itemToString = (item: FilterOption | null) => item?.label ?? '',
  } = config;

  const items: FilterOption[] = filterComponentProps.options ?? [];

  const {
    isOpen,
    selectedItem,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
    selectItem,
  } = useSelect({
    items: items || [],
    itemToString,
    onSelectedItemChange(changes) {
      if (changes?.selectedItem) {
        onFilterChange(changes.selectedItem.value);
      }
    },
  });

  useDeepCompareEffect(() => {
    const value = items.find(val => val.value === filterState);
    if (value) {
      selectItem(value);
    }
  }, [filterState, items]);

  return (
    <div className={css.container}>
      <div className={css.section}>
        <div className={css.label} {...getLabelProps()}>
          {label}
        </div>
        <div className={css.selectNamespacesDropdownButton} {...getToggleButtonProps()}>
          <span className={css.selectOptionText}>
            {selectedItem ? selectedItem.label : filterComponentProps.placeholder}
          </span>
          <NamespaceSelectIcon />
        </div>
      </div>

      <ul className={classNames(css.menuList, `${!isOpen && 'hidden'}`)} {...getMenuProps()}>
        {isOpen &&
          items?.map((item, index) => (
            <li
              className={classNames(css.listItem, {
                [css.highlighted]: highlightedIndex === index,
                [css.selected]: selectedItem === item,
              })}
              {...getItemProps({ item, index })}
              key={item.id}
            >
              <span>{item.label}</span>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default SelectFilter;
