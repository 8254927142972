import * as mobx from 'mobx';

import { XY } from '~/domain/geometry';
import { DestinationGroup } from '~/domain/process-tree/destination-group';
import { TreeLayout } from '~/ui/process-tree/layout';

type ExecID = string;
export type ExecIDToExecIDArrows = Map<ExecID, Map<ExecID, XY[]>>;

export type ChildGroupArrows = Map<string, XY[]>;
export type PidToChildGroupArrows = Map<ExecID, ChildGroupArrows>;

export class ProcessArrows {
  public toProcessArrows: ExecIDToExecIDArrows;
  public toContainerArrows: ExecIDToExecIDArrows;
  public toChildGroupsArrows: PidToChildGroupArrows;

  constructor() {
    this.toProcessArrows = new Map();
    this.toContainerArrows = new Map();
    this.toChildGroupsArrows = new Map();

    mobx.makeAutoObservable(this);
  }

  public toChildGroup(parentExecId: string, groupkey: string, coords: [XY, XY]) {
    if (!this.toChildGroupsArrows.has(parentExecId)) {
      this.toChildGroupsArrows.set(parentExecId, new Map());
    }

    this.toChildGroupsArrows.get(parentExecId)?.set(groupkey, coords);
  }

  public toContainer(parentExecId: string, containerChildExecId: string, coords: [XY, XY]) {
    if (!this.toContainerArrows.has(parentExecId)) {
      this.toContainerArrows.set(parentExecId, new Map());
    }

    this.toContainerArrows.get(parentExecId)?.set(containerChildExecId, coords);
  }

  public toProcess(parentExecId: string, childExecId: string, coords: [XY, XY]) {
    if (!this.toProcessArrows.has(parentExecId)) {
      this.toProcessArrows.set(parentExecId, new Map());
    }

    this.toProcessArrows.get(parentExecId)?.set(childExecId, coords);
  }
}

// NOTE: first point is connector coords, second is the same point but shifted
// NOTE: right to the pod handle margin if possible, third point is
// NOTE: left-shifted coords of endpoint (to make arrow ending flat), fourth
// NOTE: point is endpoint connector coords
type FourPoints = [XY, XY, XY, XY];

// NOTE: { groupName -> { port -> XY[4] }}
type PortArrows = Map<string, Map<number, FourPoints>>;

// NOTE: { groupName -> { port -> { ip -> XY[4] }}}
type IPPortArrows = Map<string, Map<number, Map<string, FourPoints>>>;

export class DestinationArrows {
  public layout: TreeLayout;

  // { execId -> { groupName -> XY[4] }}
  public toGroupConnectors: Map<string, Map<string, FourPoints>> = new Map();

  // { execId -> { groupName -> { port -> XY[4] }}}
  public toPortConnectors: Map<string, PortArrows> = new Map();

  // { execId -> { groupName -> { port -> { ip -> XY[4] }}}}
  public toIpPortConnectors: Map<string, IPPortArrows> = new Map();

  constructor(layout: TreeLayout) {
    this.layout = layout;

    mobx.makeAutoObservable(this, void 0, {
      autoBind: true,
    });
  }

  // NOTE: though we require handleId as an argument here, we do not store
  // handleId as key in public maps above
  public arrow(
    fromHandleId: string,
    fromExecId: string,
    toGroup: DestinationGroup,
    toIp: string,
    toPort: number,
  ) {
    const egressConnectorCoords = this.layout.egressConnectorCoords
      .get(fromHandleId)
      ?.get(fromExecId);

    if (egressConnectorCoords == null) return;

    const separatedEndpoint = toGroup.separatedDestinations.get(toIp);
    const regularEndpoint = toGroup.destinations.get(toIp);
    const groupPorts = this.layout.destinationConnectorCoords.get(toGroup.name);

    let destinationConnectorCoords: XY | undefined = undefined;
    let isToGroupConnector = false;
    let isToIPConnector = false;

    // NOTE: if this is a suspicious traffic, we use separated connector...
    if (separatedEndpoint?.hasPort(toPort)) {
      const portAddresses = groupPorts?.get(toPort);

      // NOTE: if there is a coords for '' ip address then `isIpVisible` flag is
      // set to false
      if (portAddresses?.has('')) {
        destinationConnectorCoords = portAddresses.get('');
      } else {
        isToIPConnector = true;
        destinationConnectorCoords = portAddresses?.get(toIp);
      }
    } else if (regularEndpoint?.hasPort(toPort)) {
      // NOTE: ...otherwise, this destination port is inside of grouped endpoint
      // and we need to check if that group is expanded or if group contains
      // only one port, in this case the group is rendered as expanded one
      const isExpanded =
        !!this.layout.destinationGroupStates.get(toGroup.name) || groupPorts?.size === 1;

      if (!isExpanded) {
        destinationConnectorCoords = this.layout.groupedDestinationCoords.get(toGroup.name);

        isToGroupConnector = true;
      } else {
        const portAddresses = groupPorts?.get(toPort);

        // NOTE: see the same lines first outer if branch
        if (portAddresses?.has('')) {
          destinationConnectorCoords = portAddresses.get('');
        } else {
          isToIPConnector = true;
          destinationConnectorCoords = portAddresses?.get(toIp);
        }
      }
    }

    if (destinationConnectorCoords == null) return;

    const shiftedStart = this.layout.egressConnectorEnd(
      egressConnectorCoords,
      destinationConnectorCoords,
    );
    const fourPoints: FourPoints = [
      egressConnectorCoords,
      shiftedStart.x > egressConnectorCoords.x ? shiftedStart : egressConnectorCoords,
      this.layout.shiftEndpointCoords(destinationConnectorCoords),
      destinationConnectorCoords,
    ];

    if (isToGroupConnector) {
      this.arrowToGroup(fromExecId, toGroup.name, fourPoints);
    } else if (isToIPConnector) {
      this.arrowToIpPort(fromExecId, toGroup.name, toIp, toPort, fourPoints);
    } else {
      this.arrowToPort(fromExecId, toGroup.name, toPort, fourPoints);
    }
  }

  private arrowToGroup(fromExecId: string, toGroupName: string, arrowPoints: FourPoints) {
    if (!this.toGroupConnectors.has(fromExecId)) {
      this.toGroupConnectors.set(fromExecId, new Map());
    }

    const toGroups = this.toGroupConnectors.get(fromExecId)!;
    toGroups.set(toGroupName, arrowPoints);
  }

  private arrowToPort(
    fromExecId: string,
    toGroupName: string,
    toPort: number,
    arrowPoints: FourPoints,
  ) {
    if (!this.toPortConnectors.has(fromExecId)) {
      this.toPortConnectors.set(fromExecId, new Map());
    }

    const toGroups = this.toPortConnectors.get(fromExecId)!;
    if (!toGroups.has(toGroupName)) {
      toGroups.set(toGroupName, new Map());
    }

    const groupPorts = toGroups.get(toGroupName)!;
    groupPorts.set(toPort, arrowPoints);
  }

  private arrowToIpPort(
    fromExecId: string,
    toGroupName: string,
    toIp: string,
    toPort: number,
    arrowPoints: FourPoints,
  ) {
    if (!this.toIpPortConnectors.has(fromExecId)) {
      this.toIpPortConnectors.set(fromExecId, new Map());
    }

    const toGroups = this.toIpPortConnectors.get(fromExecId)!;
    if (!toGroups.has(toGroupName)) {
      toGroups.set(toGroupName, new Map());
    }

    const groupPorts = toGroups.get(toGroupName)!;
    if (!groupPorts.has(toPort)) {
      groupPorts.set(toPort, new Map());
    }

    const portAddresses = groupPorts.get(toPort)!;
    portAddresses.set(toIp, arrowPoints);
  }
}
