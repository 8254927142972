import { BackendAPI } from '~/api/customprotocol';
import { ProcessEventsStream } from '~/api/customprotocol/process-events-stream';
import { TimescapePodsOneshot } from '~/api/customprotocol/timescape-pods-oneshot';
import { CustomError } from '~/api/customprotocol-core/errors';
import { Direction } from '~/domain/common';
import { Diff } from '~/domain/diff';
import { FiltersDiff } from '~/domain/filtering';
import { Flow } from '~/domain/flows';
import { ProcessEvent } from '~/domain/process-events';
import { PodInfo } from '~/domain/timescape';
import { Store } from '~/store';
import { EventEmitter } from '~/utils/emitter';
import { logger } from '~/utils/logger';

import { Options } from './common';
import { Controls } from './controls';

export enum Event {
  TimescapePodsLoadingStarted = 'timescape-pods-loading-started',
  TimescapePodsLoadingSuccess = 'timescape-pods-loading-success',
  TimescapePodsLoadingFailed = 'timescape-pods-loading-failed',
  TimescapePodsLoadingFinished = 'timescape-pods-loading-finished',

  TimescapePodEventsLoadingStarted = 'timescape-pod-events-loading-started',
  TimescapePodEventsLoadingFinished = 'timescape-pod-events-loading-finished',
  TimescapePodEventsLoadingSuccess = 'timescape-pod-events-loading-success',
  TimescapePodEventsChunkFetched = 'timescape-pod-events-chunk-fetched',
  TimescapePodEventsLoadingFailed = 'timescape-pod-events-loading-failed',

  PodPrefilterChanged = 'pod-prefilter-changed',
}

export type Handlers = {
  [Event.TimescapePodsLoadingStarted]: () => void;
  [Event.TimescapePodsLoadingSuccess]: () => void;
  [Event.TimescapePodsLoadingFinished]: () => void;
  [Event.TimescapePodsLoadingFailed]: (err: CustomError) => void;

  [Event.TimescapePodEventsLoadingStarted]: () => void;
  [Event.TimescapePodEventsLoadingFinished]: () => void;
  [Event.TimescapePodEventsLoadingSuccess]: (pod: PodInfo) => void;
  [Event.TimescapePodEventsChunkFetched]: (evts: ProcessEvent[]) => void;
  [Event.TimescapePodEventsLoadingFailed]: (err: CustomError) => void;

  [Event.PodPrefilterChanged]: (d: Diff<string>) => void;
};

export class ProcessTree extends EventEmitter<Handlers> {
  private store: Store;
  private backendAPI: BackendAPI;
  private dataLayerControls: Controls;

  private podsOneshot: TimescapePodsOneshot | null = null;
  private processEventsStream: ProcessEventsStream | null = null;

  constructor(opts: Options) {
    super(true);

    this.store = opts.store;
    this.backendAPI = opts.backendAPI;
    this.dataLayerControls = opts.dataLayerControls;
  }

  public async appOpened() {
    await this.ensurePodsForNamespace();
  }

  public async filtersChanged(diff: FiltersDiff) {
    if (!this.store.controls.app.isProcessTree) return;

    const isFilterGroupsUpdated = diff.filterGroups.changed;
    const isTimeRangeChanged = diff.timeRange.changed;

    if (isFilterGroupsUpdated || isTimeRangeChanged) {
      await this.dropProcessEventsStream();
    }

    if (isFilterGroupsUpdated || diff.timeRange.changed) {
      await this.ensurePodsForNamespace();
    }

    if (diff.timeRange.changed) {
      // NOTE: Time range is changed while pod is selected, refreshing the data
      await this.tryRefreshCurrentPodData();
    }
  }

  public async namespaceChanged(ns: string): Promise<boolean> {
    const psTreeStore = this.store.processTree;
    const currentNamespace = psTreeStore.currentNamespace;

    const isChanged = currentNamespace !== ns;
    if (!isChanged) return psTreeStore.isLocalMode;

    await this.dropProcessEventsStream();

    const isLocalMode = this.store.processTree.selectNamespace(ns);
    return isLocalMode;
  }

  public async tryRefreshCurrentPodData() {
    const psTreeStore = this.store.processTree;
    const currentNs = psTreeStore.currentNamespace;
    const currentPodName = psTreeStore.currentPodName;

    if (currentPodName == null || currentNs == null || psTreeStore.isLocalMode) return;

    // NOTE: Refreshed pod list doesnt contain currently chosen pod
    if (!psTreeStore.availablePods.includes(currentPodName)) return;

    await this.recreateProcessEventsStream(currentPodName);
  }

  public async recreateProcessEventsStream(podName: string) {
    logger.log(`recreating process events stream for pod: ${podName}`);

    if (this.processEventsStream != null) {
      await this.dropProcessEventsStream();
    }

    await this.setupProcessEventsStream(podName);
  }

  public async ensureProcessEventsStream(podName: string) {
    if (this.processEventsStream != null) {
      if (this.processEventsStream.getPodName() === podName) return;

      await this.dropProcessEventsStream();
    }

    await this.setupProcessEventsStream(podName);
  }

  public async setupProcessEventsStream(podName: string): Promise<ProcessEventsStream | null> {
    const ns = this.store.clusterNamespaces.currNamespace ?? '';
    const pod = this.store.processTree.timescapePodsMap.get(ns)?.get(podName);
    if (pod == null) {
      logger.warn('cannot setup process events stream: pod info not found');
      return null;
    }

    logger.log(`creating pod events stream for "${pod.name}"`);
    this.emit(Event.TimescapePodEventsLoadingStarted);

    this.processEventsStream = this.backendAPI
      .processEventsStream(pod)
      .onProcessEvents(evts => this.emit(Event.TimescapePodEventsChunkFetched, evts))
      .onTerminated((msg, isDemanded) => {
        logger.log(`events stream onTerminated, `, msg, isDemanded);

        if (msg.errors.length === 0) {
          this.emit(Event.TimescapePodEventsLoadingSuccess, pod);
        } else {
          this.emit(Event.TimescapePodEventsLoadingFailed, msg.errors[0]);
        }

        this.emit(Event.TimescapePodEventsLoadingFinished);
      })
      .run();

    return this.processEventsStream;
  }

  public async ensurePodsForNamespace() {
    // NOTE: LocalMode is when the logs file was uploaded...
    if (this.store.processTree.isLocalMode) return;
    if (!this.store.uiSettings.isTimescapeEnabled) return;

    const ns = this.store.processTree.currentNamespace;
    if (ns == null) return;

    logger.log(`ensuring pods for namespace ${ns}`);

    const oneshot = this.backendAPI.getTimescapePods(
      ns ?? null,
      this.dataLayerControls.timescapeFilters,
    );
    this.podsOneshot = oneshot;

    const p = new Promise<PodInfo[]>((resolve, reject) => {
      this.emit(Event.TimescapePodsLoadingStarted);
      const pods: PodInfo[] = [];

      oneshot
        .onPods(d => pods.splice(0, 0, ...d))
        .onTerminated(msg => {
          if (msg.errors.length > 0) return reject(msg.errors[0]);

          resolve(pods);
        })
        .run();
    });

    await p
      .then(pods => {
        this.store.processTree.replaceTimescapePods(ns, pods);
        this.emit(Event.TimescapePodsLoadingSuccess);
      })
      .catch(err => {
        this.emit(Event.TimescapePodsLoadingFailed, err);
        throw err;
      })
      .finally(() => {
        this.emit(Event.TimescapePodsLoadingFinished);
      });

    // NOTE: Check if currently chosen pod is still available
    const selectedPodName = this.store.processTree.currentPodName;
    if (selectedPodName == null) return;

    const availablePods = this.store.processTree.timescapePodNames.get(ns);
    if (availablePods?.includes(selectedPodName)) return;

    this.store.processTree.selectPod(null);
  }

  public setPodPrefilterFromServiceCard(card: { appLabel?: string | null }) {
    const diff = Diff.new(this.store.processTree.podPrefilter).step(card.appLabel);
    if (!diff.changed) return;

    const podPrefilter = card.appLabel;

    this.store.processTree.podPrefilter = podPrefilter;
    logger.log(`pod prefilter set from service card: ${podPrefilter}`);

    this.emit(Event.PodPrefilterChanged, diff);
  }

  public setPodPrefilterFromFlow(f: Flow, dir: Direction) {
    const podName = dir === Direction.Source ? f.sourcePodName : f.destinationPodName;
    const diff = Diff.new(this.store.processTree.podPrefilter).step(podName);
    if (!diff.changed) return;

    this.store.processTree.podPrefilter = podName;
    logger.log(`pod prefilter set from flow`, podName);

    this.emit(Event.PodPrefilterChanged, diff);
  }

  public onPodPrefilterChanged(fn: Handlers[Event.PodPrefilterChanged]): this {
    this.on(Event.PodPrefilterChanged, fn);
    return this;
  }

  public onTimescapePodsLoadingStarted(fn: Handlers[Event.TimescapePodsLoadingStarted]): this {
    this.on(Event.TimescapePodsLoadingStarted, fn);
    return this;
  }

  public onTimescapePodsLoadingFinished(fn: Handlers[Event.TimescapePodsLoadingFinished]): this {
    this.on(Event.TimescapePodsLoadingFinished, fn);
    return this;
  }

  public onTimescapePodsLoadingFailed(fn: Handlers[Event.TimescapePodsLoadingFailed]): this {
    this.on(Event.TimescapePodsLoadingFailed, fn);
    return this;
  }

  public onTimescapePodsLoadingSuccess(fn: Handlers[Event.TimescapePodsLoadingSuccess]): this {
    this.on(Event.TimescapePodsLoadingSuccess, fn);
    return this;
  }

  public onTimescapePodEventsChunkFetched(
    fn: Handlers[Event.TimescapePodEventsChunkFetched],
  ): this {
    this.on(Event.TimescapePodEventsChunkFetched, fn);
    return this;
  }

  public onTimescapePodEventsLoadingStarted(
    fn: Handlers[Event.TimescapePodEventsLoadingStarted],
  ): this {
    this.on(Event.TimescapePodEventsLoadingStarted, fn);
    return this;
  }

  public onTimescapePodEventsLoadingFinished(
    fn: Handlers[Event.TimescapePodEventsLoadingFinished],
  ): this {
    this.on(Event.TimescapePodEventsLoadingFinished, fn);
    return this;
  }

  public onTimescapePodEventsLoadingFailed(
    fn: Handlers[Event.TimescapePodEventsLoadingFailed],
  ): this {
    this.on(Event.TimescapePodEventsLoadingFailed, fn);
    return this;
  }

  public onTimescapePodEventsLoadingSuccess(
    fn: Handlers[Event.TimescapePodEventsLoadingSuccess],
  ): this {
    this.on(Event.TimescapePodEventsLoadingSuccess, fn);
    return this;
  }

  public async dropProcessEventsStream() {
    if (this.processEventsStream == null) return;

    await this.processEventsStream.stop();
    this.processEventsStream = null;
  }
}
