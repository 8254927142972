import React from 'react';

import css from './InputFilter.scss';
import { FlowFilterGroupFormBaseComponentProps } from '../types';

export const InputFilter = function InputFilter(props: FlowFilterGroupFormBaseComponentProps) {
  const { config, filterState, onFilterChange } = props;
  const { label, filterComponentProps = {} } = config;

  return (
    <div className={css.section}>
      <div className={css.label}>{label}</div>
      <input
        type="text"
        className={css.filterTextInput}
        value={filterState as string}
        onChange={e => onFilterChange(e.target.value)}
        {...filterComponentProps}
      />
    </div>
  );
};
