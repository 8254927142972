import { action, computed, makeObservable, observable } from 'mobx';

import { WH, XY, XYWH } from '~/domain/geometry';
import { sizes } from '~/ui/vars';

export abstract class PlacementStrategy {
  @observable
  protected cardsWHs: Map<string, WH>;

  @observable
  protected cardsXYs: Map<string, XY>;

  @observable
  protected _accessPointCoords: Map<string, XY>;

  @computed get bbox(): XYWH {
    let [x, y, width, height] = [0, 0, 0, 0];

    this.cardsXYs.forEach((xy: XY, cardId: string) => {
      const wh = this.cardsWHs.get(cardId);
      if (wh == null) return;

      width = Math.max(width, xy.x + wh.w);
      height = Math.max(height, xy.y + wh.h);

      x = Math.min(x, xy.x);
      y = Math.min(y, xy.y);
    });

    return new XYWH(x, y, width, height);
  }

  constructor() {
    makeObservable(this);
    this.cardsWHs = new Map();
    this.cardsXYs = new Map();

    this._accessPointCoords = new Map();
  }

  @action.bound
  public reset() {
    this.cardsWHs.clear();
    this.cardsXYs.clear();
    this._accessPointCoords.clear();
  }

  @action.bound
  public getCardCoords(cardId: string): XY | null {
    return this.cardsXYs.get(cardId) || null;
  }

  @action.bound
  public getCardDimensions(cardId: string): WH | null {
    return this.cardsWHs.get(cardId) || null;
  }

  @action.bound
  public getCardXYWH(cardId: string): XYWH | null {
    const xy = this.getCardCoords(cardId);
    if (xy == null) return null;

    const wh = this.getCardDimensions(cardId);
    if (wh == null) return null;

    return XYWH.fromParts(xy, wh);
  }

  @action.bound
  public getCardXYWHOrDefault(cardId: string): XYWH {
    const real = this.getCardXYWH(cardId);
    if (real != null) return real;

    return this.defaultCardXYWH();
  }

  @action.bound
  public setCardWH(cardId: string, wh: WH) {
    this.cardsWHs.set(cardId, {
      w: wh.w,
      h: wh.h,
    });
  }

  @action.bound
  public defaultCardXYWH(): XYWH {
    return new XYWH(-100500, -100500, this.defaultCardW, this.defaultCardH);
  }

  @action.bound
  public setCardWidth(cardId: string, width: number) {
    const cardWH = this.cardsWHs.get(cardId);

    this.cardsWHs.set(cardId, {
      w: width,
      h: cardWH ? cardWH.h : this.defaultCardH,
    });
  }

  @action.bound
  public setCardHeight(cardId: string, height: number, eps?: number): boolean {
    const cardWH = this.cardsWHs.get(cardId);

    if (cardWH == null) {
      this.cardsWHs.set(cardId, {
        w: this.defaultCardW,
        h: height,
      });

      return true;
    } else {
      if (eps != null && Math.abs(cardWH.h - height) < eps) return false;

      this.cardsWHs.set(cardId, {
        w: cardWH.w,
        h: height,
      });

      return true;
    }
  }

  @action.bound
  public setCardHeights(coords: { id: string; bbox: XYWH }[], eps?: number): number {
    let nupdated = 0;

    coords.forEach(c => {
      if (c.bbox.h < 0) return;
      if (this.setCardHeight(c.id, c.bbox.h, eps)) nupdated++;
    });

    return nupdated;
  }

  @action.bound
  public setAccessPointCoords(apId: string, xy: XY) {
    this._accessPointCoords.set(apId, xy);
  }

  @computed
  get accessPointCoords(): Map<string, XY> {
    return new Map(this._accessPointCoords);
  }

  @computed
  get cardsDimensions(): Map<string, WH> {
    return new Map(this.cardsWHs);
  }

  @computed
  get cardsCoords(): Map<string, XY> {
    return new Map(this.cardsXYs);
  }

  @computed
  get cardsBBoxes(): Map<string, XYWH> {
    const bboxes = new Map();

    this.cardsWHs.forEach((dims: WH, cardId: string) => {
      const coords = this.cardsXYs.get(cardId);
      if (coords == null) return;

      bboxes.set(cardId, XYWH.fromParts(coords, dims));
    });

    return bboxes;
  }

  @computed
  public get defaultCardW(): number {
    return sizes.defaultCardW;
  }

  @computed
  public get defaultCardH(): number {
    return sizes.defaultCardH;
  }

  @computed
  public get numCards(): number {
    return Math.min(this.cardsXYs.size, this.cardsWHs.size);
  }

  @action initUninitializedCard = (cardId: string) => {
    // const defaults = PlacementStrategy.defaultCardXYWH();
    const defaults = this.defaultCardXYWH();

    if (!this.cardsXYs.has(cardId)) {
      this.cardsXYs.set(cardId, {
        x: defaults.x,
        y: defaults.y,
      });
    }

    if (!this.cardsWHs.has(cardId)) {
      this.cardsWHs.set(cardId, {
        w: defaults.w,
        h: defaults.h,
      });
    }
  };
}
