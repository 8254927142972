import * as React from 'react';

import classnames from 'classnames';
import { observer } from 'mobx-react';

import { Application } from '~/domain/common';

import css from './MainNavigationItem.scss';

export interface Props {
  app: Application;
  text: string | React.ReactElement;
  icon?: JSX.Element;
  isActive?: boolean;
  subitem?: boolean;
  onSelect?: () => void;
  children?: React.ReactNode;
}

export const MainNavigationItem = observer(function MainNavigationItem(props: Props) {
  const onClick = React.useCallback(
    (evt: React.MouseEvent<HTMLButtonElement>) => {
      evt.preventDefault();
      evt.stopPropagation();
      props.onSelect?.();
    },
    [props.onSelect],
  );

  const className = classnames(css.wrapper, {
    [css.active]: props.isActive,
    [css.subitem]: !!props.subitem,
  });

  return (
    <div className={className}>
      <button className={css.clickable} onClick={onClick}>
        {props.icon && <div className={css.icon}>{props.icon}</div>}
        <div className={css.text}>{props.text}</div>
      </button>
      {props.children && <div className={css.submenu}>{props.children}</div>}
    </div>
  );
});
