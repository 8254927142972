import { DataLayer } from '~/data-layer/data-layer';

import { Router, RouterKind } from './router';

export class RouterBuilder {
  private kind: RouterKind = RouterKind.Browser;
  private dataLayer?: DataLayer;

  public static new() {
    return new RouterBuilder();
  }

  public withUnderlyingRouter(kind: RouterKind): this {
    this.kind = kind;
    return this;
  }

  public withDataLayer(dl: DataLayer): this {
    this.dataLayer = dl;
    return this;
  }

  public build(): Router {
    if (this.dataLayer == null) throw this.err('DataLayer');

    return new Router(this.kind, this.dataLayer);
  }

  private err(what: string) {
    return new Error(`cannot build Router: ${what} is not set`);
  }
}
