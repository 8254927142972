export interface Vulnerability {
  id: string;
  message: string;
  description: string;
  severity: VulnerabilitySeverity;
  ruleRef?: string;
}

export enum VulnerabilitySeverity {
  High = 'high',
}
