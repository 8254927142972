import React, { useState } from 'react';

import useDeepCompareEffect from 'use-deep-compare-effect';

import { FilterDirection, FilterEntry, FilterGroup, FilterKind } from '~/domain/filtering';

import css from './FlowFilterGroupForm.scss';
import { FilterConfig } from '../filter-config';
import { FlowFilterGroupFormItem } from '../FlowFilterGroupFormItem/FlowFilterGroupFormItem';
import { FlowFilterGroupFormProps } from '../types';

const prepareInitialFormState = ({
  config,
  group,
}: {
  config: FilterConfig[];
  group?: FilterGroup;
}) => {
  const formState: { [key: string]: string | boolean } = {};

  config.forEach(configItem => {
    if (group) {
      formState[configItem.kind] =
        group.entries.find(entry => entry.kind === configItem.kind)?.query ?? '';
      formState[FilterKind.Direction] = group.entries.at(0)?.direction || 'either';
      formState[FilterKind.Negative] = group.entries.at(0)?.negative || false;
    } else {
      formState[configItem.kind] = '';
      formState[FilterKind.Direction] = 'either';
    }
  });

  return formState;
};

const createFormStateValue = ({
  isEditMode,
  group,
  config,
}: {
  isEditMode: boolean;
  group?: FilterGroup;
  config: FilterConfig[];
}) => {
  if (isEditMode && group) {
    return prepareInitialFormState({
      config,
      group,
    });
  }

  return prepareInitialFormState({ config });
};

export const FlowFilterGroupForm = function FlowFilterGroupForm(props: FlowFilterGroupFormProps) {
  const { formMode, group, config } = props;

  const isEditMode = formMode === 'Edit';

  const [formState, setFormState] = useState(
    createFormStateValue({
      isEditMode,
      group,
      config,
    }),
  );

  useDeepCompareEffect(() => {
    setFormState(
      createFormStateValue({
        isEditMode,
        group,
        config,
      }),
    );
  }, [config, isEditMode, group]);

  const prepareNewFilters = (formState: { [key: string]: string | boolean }) => {
    const { negative: negativeEntry, direction, ...formStatePositive } = formState;

    const entries = Object.keys(formStatePositive)
      .filter(kind => formStatePositive[kind])
      .map(kind => {
        const typedKind = kind as FilterKind;
        const formValue = formStatePositive[typedKind];

        return new FilterEntry({
          kind: typedKind,
          direction: direction as FilterDirection,
          query: formValue as string,
          meta: typedKind === FilterKind.Workload ? 'Deployment' : undefined,
          negative: negativeEntry as boolean,
        });
      });

    return new FilterGroup(entries);
  };

  const onAddFilters = () => {
    const filterGroup = prepareNewFilters(formState);
    props.onAddFilters(filterGroup);
  };

  const onFormFieldChange = (kind: string) => (newValue: string | boolean) => {
    setFormState(currentState => {
      return {
        ...currentState,
        [kind]: newValue,
      };
    });
  };

  const onUpdateFilters = () => {
    const filters = prepareNewFilters(formState);
    props.onUpdateFilters(filters);
  };

  return (
    <div className={css.wrapper}>
      <div className={css.inner}>
        {props.config.map(filter => {
          const value = formState[filter.kind];

          return (
            <FlowFilterGroupFormItem
              key={filter.kind}
              config={filter}
              value={value}
              onChange={onFormFieldChange(filter.kind)}
            />
          );
        })}
      </div>
      <div className={css.footer}>
        <button
          className={css.addFilterGroupButton}
          onClick={isEditMode ? onUpdateFilters : onAddFilters}
        >
          {isEditMode ? 'Update' : 'Add'} Filter Group
        </button>
      </div>
    </div>
  );
};
