import React from 'react';

import { DimensionalWrapper } from '~/components/DimensionalWrapper';
import { XY } from '~/domain/geometry';
import { ElemCoords } from '~/ui/hooks';

import { AbsoluteDiv } from './AbsoluteDiv';

type Ref<T> = React.MutableRefObject<T>;
export type Props = {
  coords: XY;
  isSVGContext?: boolean;
  className?: string;
  children: React.ReactNode;
  forwardRef?: Ref<HTMLDivElement | SVGForeignObjectElement | null>;

  onCoords?: (_: ElemCoords) => void;
};

// NOTE: Consider not doing any coords transformation here
export const AbsoluteElement = function AbsoluteElement(props: Props) {
  if (props.isSVGContext) {
    return (
      <DimensionalWrapper
        className={props.className}
        coords={props.coords}
        onCoords={props.onCoords}
        forwardRef={props.forwardRef as Ref<SVGForeignObjectElement | null>}
      >
        {props.children}
      </DimensionalWrapper>
    );
  }

  // NOTE: Be careful: html part of this component is not tested and may require
  // NOTE: some calibration
  return (
    <AbsoluteDiv
      className={props.className}
      coords={props.coords}
      onCoords={props.onCoords}
      forwardRef={props.forwardRef as Ref<HTMLDivElement | null>}
    >
      {props.children}
    </AbsoluteDiv>
  );
};
