import { makeAutoObservable } from 'mobx';

import { DataMode } from '~/domain/interactions';
import { NamespaceDescriptor } from '~/domain/namespaces';

// CLUSTER_NAME -> Namespaces
// Note: for hubble-relay cluster is always empty "" string
export type DescriptorsMap = Map<string, Set<string>>;

// TODO: After hubble-relay mode is gone from the EE UI
// TODO: this store should be refactored and DataMode should be dropped here
export class ClusterNamespaceStore {
  private _dataMode: DataMode;
  private _currCluster: string | null = null;
  private _currNamespace: string | null = null;
  private _descriptorsMap: DescriptorsMap;

  constructor(isTimescapeEnabled: boolean) {
    this._dataMode = this.resetDataMode(isTimescapeEnabled);
    this._descriptorsMap = new Map();

    makeAutoObservable(this, void 0, {
      autoBind: true,
    });
  }

  public get hasClusters(): boolean {
    return this.clustersList.length > 0;
  }

  public get currCluster(): string | null {
    return this._currCluster || null;
  }

  public get currNamespace(): string | null {
    return this._currNamespace || null;
  }

  public get currDescriptor(): NamespaceDescriptor | null {
    if (this.currCluster === null || this.currNamespace === null) return null;
    const namespaces = this._descriptorsMap.get(this.currCluster)?.has(this.currNamespace);
    if (!namespaces) return null;
    return { cluster: this.currCluster, namespace: this.currNamespace };
  }

  public get currClusterNamespacesList(): string[] {
    if (this._dataMode === DataMode.CiliumStreaming || !this.hasClusters) {
      return this.allClustersNamespacesList;
    }
    const cluster = this.currCluster;
    if (cluster === null) return [];
    const namespaces = this._descriptorsMap.get(cluster) ?? [];
    return [...namespaces].sort((nsA, nsB) => (nsA.toLowerCase() < nsB.toLowerCase() ? -1 : 1));
  }

  public get currClusterNamespaceDescriptors(): NamespaceDescriptor[] {
    const cluster = this.currCluster;
    if (cluster === null) return [];
    return this.currClusterNamespacesList.map(namespace => ({ cluster, namespace }));
  }

  public get allClustersNamespacesList(): string[] {
    const namespaces = new Set<string>();
    this._descriptorsMap.forEach(clusterNamespaces => {
      clusterNamespaces.forEach(namespace => namespaces.add(namespace));
    });
    return [...namespaces].sort((nsA, nsB) => (nsA.toLowerCase() < nsB.toLowerCase() ? -1 : 1));
  }

  public get clustersList(): string[] {
    return [...this._descriptorsMap.keys()]
      .filter(s => s.length)
      .sort((a, b) => (a.toLowerCase() < b.toLowerCase() ? -1 : 1));
  }

  public setDataMode(dataMode: DataMode) {
    this._dataMode = dataMode;
  }

  public resetDataMode(isTimescapeEnabled: boolean) {
    this._dataMode = isTimescapeEnabled ? DataMode.WatchingHistory : DataMode.CiliumStreaming;
    return this._dataMode;
  }

  public clear() {
    this._descriptorsMap.clear();
  }

  public clone(): ClusterNamespaceStore {
    const cloned = new ClusterNamespaceStore(false);

    cloned.setDataMode(this._dataMode);

    this._descriptorsMap.forEach((namespaces, cluster) => {
      namespaces.forEach(namespace => cloned.upsertDescriptor({ cluster, namespace }));
    });

    cloned.setClusterNamespace(this._currCluster, this._currNamespace);

    return cloned;
  }

  public setClusterNamespace(cluster: string | null, namespace: string | null) {
    this._currCluster = cluster || null;
    this._currNamespace = namespace || null;
  }

  public upsertDescriptor(descriptor: NamespaceDescriptor): void {
    const namespaces = this._descriptorsMap.get(descriptor.cluster) ?? new Set<string>();
    namespaces.add(descriptor.namespace);
    this._descriptorsMap.set(descriptor.cluster, namespaces);
  }
}

export default ClusterNamespaceStore;
