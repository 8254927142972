import React, { createRef, forwardRef, memo, useCallback, useMemo, useRef } from 'react';

import useResizeObserver from '@react-hook/resize-observer';
import classnames from 'classnames';

import { formatWidth } from '~/components/hooks/useFlowTableColumns';

import { Column, ColumnWidths, CommonProps, getColumnLabel } from './general';
import css from './styles.scss';

const extraCssClass = {
  [Column.DstPort]: css.dstPort,
  [Column.L7Info]: css.l7info,
  [Column.TrafficDirection]: css.trafficDirection,
  [Column.Verdict]: css.verdict,
  [Column.AuthType]: css.authType,
  [Column.TcpFlags]: css.tcpFlags,
  [Column.Timestamp]: css.timestamp,
};

interface HeaderCellProps {
  column: Column;
  onResize: () => void;
  width?: number;
}

export enum E2E {
  header = 'flow-column-header',
}

const HeaderCell = forwardRef<HTMLDivElement, HeaderCellProps>(function HeaderCell(
  { column, onResize, width },
  target,
) {
  useResizeObserver(target as React.RefObject<HTMLDivElement>, () => {
    if (onResize) {
      onResize();
    }
  });

  return (
    <div
      className={classnames(css.cell, extraCssClass[column as keyof typeof extraCssClass])}
      ref={target}
      style={{
        resize: 'horizontal',
        flexGrow: width != null ? 1 : 0,
        flexShrink: width != null ? 1 : 0,
        width: formatWidth(width),
      }}
    >
      <div className={classnames(css.label)}>{getColumnLabel(column)}</div>
    </div>
  );
});

interface HeaderProps extends CommonProps {
  columnWidths?: ColumnWidths;
  onResize?: (width: ColumnWidths) => void;
}

export const Header = memo<HeaderProps>(function FlowsTableHeader({
  visibleColumns,
  columnWidths,
  onResize,
}) {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const headers = useMemo(
    () => Object.fromEntries([...visibleColumns].map(col => [col, createRef<HTMLDivElement>()])),
    [visibleColumns],
  );

  const onHeaderResize = useCallback(() => {
    onResize &&
      onResize(
        Object.fromEntries(
          Object.entries(headers).map(([key, header]) => [
            key,
            wrapperRef.current &&
              header.current &&
              Math.round(
                (1000 * header.current.getBoundingClientRect().width) /
                  wrapperRef.current.getBoundingClientRect().width,
              ) / 1000,
          ]),
        ) as ColumnWidths,
      );
  }, [onResize, headers]);

  return (
    <div className={classnames(css.row, css.head)} data-testid={E2E.header} ref={wrapperRef}>
      {visibleColumns.has(Column.SrcCluster) && (
        <HeaderCell
          ref={headers[Column.SrcCluster]}
          column={Column.SrcCluster}
          onResize={onHeaderResize}
          width={columnWidths?.[Column.SrcCluster]}
        />
      )}
      {visibleColumns.has(Column.SrcNamespace) && (
        <HeaderCell
          ref={headers[Column.SrcNamespace]}
          column={Column.SrcNamespace}
          onResize={onHeaderResize}
          width={columnWidths?.[Column.SrcNamespace]}
        />
      )}
      {visibleColumns.has(Column.SrcPod) && (
        <HeaderCell
          ref={headers[Column.SrcPod]}
          column={Column.SrcPod}
          onResize={onHeaderResize}
          width={columnWidths?.[Column.SrcPod]}
        />
      )}
      {visibleColumns.has(Column.SrcIp) && (
        <HeaderCell
          ref={headers[Column.SrcIp]}
          column={Column.SrcIp}
          onResize={onHeaderResize}
          width={columnWidths?.[Column.SrcIp]}
        />
      )}
      {visibleColumns.has(Column.SrcService) && (
        <HeaderCell
          ref={headers[Column.SrcService]}
          column={Column.SrcService}
          onResize={onHeaderResize}
          width={columnWidths?.[Column.SrcService]}
        />
      )}
      {visibleColumns.has(Column.DstCluster) && (
        <HeaderCell
          ref={headers[Column.DstCluster]}
          column={Column.DstCluster}
          onResize={onHeaderResize}
          width={columnWidths?.[Column.DstCluster]}
        />
      )}
      {visibleColumns.has(Column.DstNamespace) && (
        <HeaderCell
          ref={headers[Column.DstNamespace]}
          column={Column.DstNamespace}
          onResize={onHeaderResize}
          width={columnWidths?.[Column.DstNamespace]}
        />
      )}
      {visibleColumns.has(Column.DstPod) && (
        <HeaderCell
          ref={headers[Column.DstPod]}
          column={Column.DstPod}
          onResize={onHeaderResize}
          width={columnWidths?.[Column.DstPod]}
        />
      )}
      {visibleColumns.has(Column.DstIp) && (
        <HeaderCell
          ref={headers[Column.DstIp]}
          column={Column.DstIp}
          onResize={onHeaderResize}
          width={columnWidths?.[Column.DstIp]}
        />
      )}
      {visibleColumns.has(Column.DstService) && (
        <HeaderCell
          ref={headers[Column.DstService]}
          column={Column.DstService}
          onResize={onHeaderResize}
          width={columnWidths?.[Column.DstService]}
        />
      )}
      {visibleColumns.has(Column.DstPort) && (
        <HeaderCell
          ref={headers[Column.DstPort]}
          column={Column.DstPort}
          onResize={onHeaderResize}
          width={columnWidths?.[Column.DstPort]}
        />
      )}
      {visibleColumns.has(Column.L7Info) && (
        <HeaderCell
          ref={headers[Column.L7Info]}
          column={Column.L7Info}
          onResize={onHeaderResize}
          width={columnWidths?.[Column.L7Info]}
        />
      )}
      {visibleColumns.has(Column.TrafficDirection) && (
        <HeaderCell
          ref={headers[Column.TrafficDirection]}
          column={Column.TrafficDirection}
          onResize={onHeaderResize}
          width={columnWidths?.[Column.TrafficDirection]}
        />
      )}
      {visibleColumns.has(Column.Verdict) && (
        <HeaderCell
          ref={headers[Column.Verdict]}
          column={Column.Verdict}
          onResize={onHeaderResize}
          width={columnWidths?.[Column.Verdict]}
        />
      )}
      {visibleColumns.has(Column.TcpFlags) && (
        <HeaderCell
          ref={headers[Column.TcpFlags]}
          column={Column.TcpFlags}
          onResize={onHeaderResize}
          width={columnWidths?.[Column.TcpFlags]}
        />
      )}
      {visibleColumns.has(Column.AuthType) && (
        <HeaderCell
          ref={headers[Column.AuthType]}
          column={Column.AuthType}
          onResize={onHeaderResize}
          width={columnWidths?.[Column.AuthType]}
        />
      )}
      {visibleColumns.has(Column.Timestamp) && (
        <HeaderCell
          ref={headers[Column.Timestamp]}
          column={Column.Timestamp}
          onResize={onHeaderResize}
          width={columnWidths?.[Column.Timestamp]}
        />
      )}
    </div>
  );
});
