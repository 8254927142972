import React, { useCallback, useEffect, useState } from 'react';

import { FormGroup, HTMLTable, InputGroup } from '@blueprintjs/core';

import { logger } from '~/utils/logger';

import css from './NodeConnectivity.scss';
import * as Types from './types';

export const NodeConnectivity = () => {
  const [srcQuery, setSrcQuery] = useState('');
  const [dstQuery, setDstQuery] = useState('');

  const [status, setStatus] = useState<Types.PodConnectivity[]>([]);
  const [connections, setConnections] = useState<Types.NodesConnectivity[]>([]);

  const onSrcQueryChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSrcQuery(event.target.value);
  }, []);

  const onDstQueryChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setDstQuery(event.target.value);
  }, []);

  useEffect(() => {
    const controller = new AbortController();
    fetch('/status/status.json', { signal: controller.signal })
      .then(response => response.json())
      .then(setStatus)
      .catch(error => logger.error(error));
    return () => controller.abort();
  }, []);

  useEffect(() => {
    const search = (query: string) => {
      return status.filter(entry => entry.labels.some(label => label.includes(query)));
    };

    const connect = (
      dir: 'ingress' | 'egress',
      a: Types.PodConnectivity[],
      b: Types.PodConnectivity[],
    ) => {
      const result: Types.NodesConnectivity[] = [];
      a.forEach(x => {
        const xNode = x.node;
        if (!xNode) return;
        b.forEach(y => {
          const yNode = y.node;
          if (!yNode) return;
          if (xNode.ip === yNode.ip) return;
          const connection = xNode.connections[yNode.ip];
          const nodeConnectivityStatus = connection ? connection.nodeConnectivityStatus : 'unknown';
          const endpointsConnectivityStatus = connection
            ? connection.endpointsConnectivityStatus
            : 'unknown';
          result.push({
            dir,
            srcLabels: x.labels,
            dstLabels: y.labels,
            srcIp: xNode.ip,
            dstIp: yNode.ip,
            srcNodeName: xNode.nodeName,
            dstNodeName: yNode.nodeName,
            nodeConnectivityStatus,
            endpointsConnectivityStatus,
          });
        });
      });
      return result;
    };

    const normSrcQuery = srcQuery.trim();
    const normDstQuery = dstQuery.trim();
    if (normSrcQuery.length === 0 || normDstQuery.length === 0) {
      return setConnections([]);
    }

    const srcs = search(normSrcQuery);
    const dsts = search(normDstQuery);
    setConnections([...connect('egress', srcs, dsts), ...connect('ingress', dsts, srcs)]);
  }, [status, srcQuery, dstQuery]);

  return (
    <div className={css.wrapper}>
      <h3>Node Connectivity Troubleshooting</h3>
      <div className={css.inputsArea}>
        <FormGroup label="From" labelInfo="(required)">
          <InputGroup
            fill
            value={srcQuery}
            onChange={onSrcQueryChange}
            placeholder="Service labels"
            required
          />
        </FormGroup>
        <FormGroup label="To" labelInfo="(required)">
          <InputGroup
            fill
            value={dstQuery}
            onChange={onDstQueryChange}
            placeholder="Service labels"
            required
          />
        </FormGroup>
      </div>
      <h4>Node Reachability</h4>
      {connections.length === 0 && 'No connections matched by filters'}
      {connections.length > 0 && (
        <HTMLTable style={{ width: '100%' }} compact striped>
          <thead>
            <tr>
              <th>Src node</th>
              <th>Direction</th>
              <th>Dst node</th>
              <th>Node conn</th>
              <th>Endpoints conn</th>
            </tr>
          </thead>
          <tbody>
            {connections.map((conn, idx) => {
              const nodecolor = getColor(conn.nodeConnectivityStatus);
              const endpointcolor = getColor(conn.endpointsConnectivityStatus);

              const isEgress = conn.dir === 'egress';

              const leftNodeName = isEgress ? conn.srcNodeName : conn.dstNodeName;
              const leftLabels = isEgress ? conn.srcLabels.join(', ') : conn.dstLabels.join(', ');
              const leftIp = isEgress ? conn.srcIp : conn.dstIp;

              const rightNodeName = isEgress ? conn.dstNodeName : conn.srcNodeName;
              const rightLabels = isEgress ? conn.dstLabels.join(', ') : conn.srcLabels.join(', ');
              const rightIp = isEgress ? conn.dstIp : conn.srcIp;

              return (
                <tr key={idx}>
                  <td>
                    <div>
                      <code>{leftNodeName}</code>
                    </div>
                    <div style={{ color: '#666' }}>
                      <small>{leftLabels}</small>
                    </div>
                    <div style={{ color: '#666' }}>
                      <small>{leftIp}</small>
                    </div>
                  </td>
                  <td
                    style={{
                      verticalAlign: 'middle',
                      fontSize: '20px',
                      textAlign: 'center',
                    }}
                  >
                    {conn.dir === 'egress' ? '→' : '←'}
                  </td>
                  <td>
                    <div>
                      <code>{rightNodeName}</code>
                    </div>
                    <div style={{ color: '#666' }}>
                      <small>{rightLabels}</small>
                    </div>
                    <div style={{ color: '#666' }}>
                      <small>{rightIp}</small>
                    </div>
                  </td>
                  <td style={{ color: nodecolor }}>{conn.nodeConnectivityStatus}</td>
                  <td style={{ color: endpointcolor }}>{conn.endpointsConnectivityStatus}</td>
                </tr>
              );
            })}
          </tbody>
        </HTMLTable>
      )}
    </div>
  );
};

function getColor(status: 'reachable' | 'unreachable' | 'unknown') {
  if (status === 'reachable') return 'green';
  if (status === 'unreachable') return 'red';
  return 'grey';
}
