import React, {
  memo,
  ReactNode,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';

import {
  Button,
  Checkbox,
  Icon,
  InputGroup,
  TagInput,
  TagInputProps,
  TagProps,
  Tooltip,
} from '@blueprintjs/core';
import { Address4 } from 'ip-address';
import { observer } from 'mobx-react';

import { EndpointSelector } from '~/domain/cilium/cnp/types.generated';
import { PolicyCard } from '~/domain/cimulator/cards';
import { PolicyEndpoint } from '~/domain/cimulator/endpoint';
import { PolicyKind } from '~/domain/cimulator/types';
import { NamespaceLabelKey } from '~/domain/labels';
import { useStore } from '~/store/stores/cimulator';
import { PolicyStruct } from '~/store/stores/policy';
import { SpecStore } from '~/store/stores/policy/policy-spec';
import { useTooltip } from '~/ui/hooks/useTooltip';
import { AnalyticsTrackKind, track } from '~/utils/analytics';

import { AdvancedSelectorInput } from './AdvancedSelectorInput';
import { CrudActions } from './CrudActions';
import css from './CrudPopover.scss';
import { RuleKind, tagInputTagCommonProps } from './general';
import { useMatchExpressionCrud, useRuleState, useStringInputState, useTagsState } from './hooks';
import { MatchLabelsInput } from './MatchLabelsInput';
import { RuleKindSelector } from './RuleKindSelector';
import { getCrudPopoverDescription } from './texts';

export interface Props {
  card: PolicyCard;
  endpoint?: PolicyEndpoint;
  initRuleKind?: RuleKind | null;
  onDone?: (card?: PolicyCard, endpoint?: PolicyEndpoint) => void;
  onInteraction?: () => void;
}

export const HelpIcon = memo<{ content: string | JSX.Element }>(function HelpIcon(props) {
  const tooltip = useTooltip({
    minimal: true,
    content: props.content,
    openOnTargetFocus: false,
  });

  return (
    <Tooltip {...tooltip.props}>
      <Icon icon="help" />
    </Tooltip>
  );
});

export const CrudPopover = observer(function CrudPopover(props: Props) {
  const store = useStore();

  const isKNP = store.controls.policyKind === PolicyKind.KNP;

  const [stateEndpoint, setStateEndpont] = useState(props.endpoint);
  const { rule, ruleEndpoint, validationError } = useRuleState(
    store.controls.policyKind,
    props.card,
    stateEndpoint,
    props.initRuleKind,
  );

  const [wasRender, setWasRender] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);

  const [showExceptCidrs, setShowExceptCidrs] = useState(
    Boolean(rule.kind.value === RuleKind.Cidr && stateEndpoint?.exceptCidrsStr),
  );

  const [ruleError, setRuleError] = useState<string | null>(null);
  const [showRuleError, setShowRuleError] = useState(false);

  useLayoutEffect(() => {
    setWasRender(true);
  }, []);

  useEffect(() => {
    wasRender && props.onInteraction?.();
  }, [rule.values, props.onInteraction]);

  useEffect(() => {
    setShowRuleError(false);
    setCanSubmit(true);
    setRuleError(validationError ?? null);
  }, [ruleEndpoint, validationError]);

  const showKindSelector = useMemo(() => {
    return !stateEndpoint;
  }, [stateEndpoint]);

  const showNamespaceSelector = useMemo(() => {
    return (
      (rule.kind.value === RuleKind.NamespaceSelector ||
        rule.kind.value === RuleKind.PodSelector ||
        rule.kind.value === RuleKind.Service) &&
      !props.card.isInNamespace
    );
  }, [rule.kind.value, props.card.isInNamespace]);

  const showAllNamespacesCheckbox = useMemo(() => {
    return props.card.isInCluster && rule.kind.value === RuleKind.PodSelector;
  }, [props.card.isInCluster, rule.kind.value]);

  const showPodsSelector = useMemo(() => {
    return rule.kind.value === RuleKind.PodSelector || rule.kind.value === RuleKind.Service;
  }, [rule.kind.value]);

  const showPortsInput = useMemo(() => {
    return (
      rule.kind.value !== null &&
      rule.kind.value !== RuleKind.Service &&
      rule.kind.value !== RuleKind.KubeDns &&
      rule.kind.value !== RuleKind.KubeApiserver &&
      !stateEndpoint?.isAllWithoutPorts
    );
  }, [rule, stateEndpoint]);

  const description = useMemo(() => {
    if (!ruleEndpoint) return [];
    return getCrudPopoverDescription(props.card, ruleEndpoint, store.policy);
  }, [props.card, ruleEndpoint, store.policy]);

  const cidrInputTooltip = useMemo(() => {
    try {
      const v4 = new Address4(rule.values.cidr);
      const start = v4.startAddress().addressMinusSuffix;
      const end = v4.endAddress().addressMinusSuffix;
      if (start === end) {
        return `Matches ${start}`;
      } else {
        return `Matches ${start} - ${end} range`;
      }
    } catch (error) {
      return '';
    }
  }, [rule]);

  const disableNamespaceInput = useMemo(() => {
    return props.card.isInNamespace;
  }, [props.card, stateEndpoint, store.policy.policyNamespace]);

  const toggleShowExceptCidrs = useCallback(() => {
    setShowExceptCidrs(!showExceptCidrs);
  }, [showExceptCidrs]);

  const preventEnter = useCallback((event: React.KeyboardEvent) => {
    if (event.key === 'Enter') event.preventDefault();
  }, []);

  const updatePolicy = useCallback(
    (callback: (p: PolicyStruct, s: SpecStore, c: PolicyCard, e: PolicyEndpoint) => void) => {
      store.policy.policiesList.forEach(p =>
        p.specs.forEach(s =>
          s.cardsList.forEach(c => c.partialEndpointsList.forEach(e => callback(p, s, c, e))),
        ),
      );
    },
    [store.policy.policiesList, store.policy.multi.allowedEndpointsSet],
  );

  const onSubmit = useCallback(
    (event?: React.FormEvent) => {
      event?.preventDefault();

      if (ruleError) {
        return setShowRuleError(true), setCanSubmit(false);
      } else if (!ruleEndpoint) {
        return setShowRuleError(true), setCanSubmit(false), setRuleError('Enter rule data');
      }

      const fullRuleEndpointId = props.card.fullEndpointId(ruleEndpoint.id);

      if (stateEndpoint && !stateEndpoint.isAllWithoutPorts) {
        updatePolicy((p, s, c, e) => {
          if (c.fullEndpointId(e.id) !== c.fullEndpointId(stateEndpoint.id)) {
            return;
          }
          c.updateEndpoint(ruleEndpoint, stateEndpoint.id);
        });
      } else {
        updatePolicy((_, s, c) => {
          if (
            store.policy.currentSpec !== s ||
            props.card.side !== c.side ||
            props.card.kind !== c.kind
          ) {
            return;
          }
          c.addEndpoints(ruleEndpoint);
        });
      }

      store.policy.setAllowedEndpoint(fullRuleEndpointId, true);

      track(AnalyticsTrackKind.PolicyRuleCRUD, {
        action: stateEndpoint ? 'update-endpoint' : 'create-endpoint',
        cardSide: props.card.side,
        cardKind: props.card.kind,
        endpointKind: ruleEndpoint.kind,
      });
      props.onDone?.(props.card, ruleEndpoint);
    },
    [
      props.card,
      stateEndpoint,
      updatePolicy,
      props.onDone,
      ruleEndpoint,
      store.policy.currentSpec,
      rule,
      ruleError,
    ],
  );

  const onDelete = useCallback(() => {
    if (!stateEndpoint) return;

    updatePolicy((p, s, c, e) => {
      const fullEndpointId = props.card.fullEndpointId(stateEndpoint.id);
      if (c.fullEndpointId(e.id) !== fullEndpointId) return;
      c.removeEndpoint(e);
      s.setAllowedEndpoint(fullEndpointId, false);
    });

    track(AnalyticsTrackKind.PolicyRuleCRUD, {
      action: 'delete-endpoint',
      cardSide: props.card.side,
      cardKind: props.card.kind,
      endpointKind: stateEndpoint.kind,
    });
    props.onDone?.();
  }, [props.card, stateEndpoint, props.onDone, updatePolicy]);

  const onDeny = useCallback(() => {
    if (!stateEndpoint) return;

    if (stateEndpoint?.isAllWithoutPorts || stateEndpoint?.isKubeDns) {
      store.policy.setAllowedEndpoint(props.card.fullEndpointId(stateEndpoint.id), false);
      return props.onDone?.();
    } else {
      return onDelete();
    }
  }, [props.card, stateEndpoint, props.onDone]);

  const tagInputTagProps = useCallback(
    (value: ReactNode) => {
      const unsupported =
        store.controls.policyKind === PolicyKind.KNP &&
        (rule.kind.value === RuleKind.NamespaceSelector ||
          rule.kind.value === RuleKind.PodSelector) &&
        (value as string).includes(`${NamespaceLabelKey}=`);

      return {
        ...tagInputTagCommonProps,
        intent: unsupported ? 'danger' : 'none',
      } as TagProps;
    },
    [props.card, rule.kind.value, store.controls.policyKind],
  );

  const podSelectorMatchExpressionCrud = useMatchExpressionCrud(
    rule.podSelector.matchExpressions.value,
    rule.podSelector.matchExpressions.onChange,
  );

  const namespaceSelectorMatchExpressionCrud = useMatchExpressionCrud(
    rule.namespaceSelector.matchExpressions.value,
    rule.namespaceSelector.matchExpressions.onChange,
  );

  const podSelectorMatchLabelsTagInputProps: TagInputProps = {
    values: rule.podSelector.matchLabels.value,
    onChange: rule.podSelector.matchLabels.onChange,
    placeholder:
      rule.kind.value === RuleKind.Service
        ? 'frontend OR app=frontend, version=2'
        : 'app=frontend, version=2',
    onKeyDown: preventEnter,
    tagProps: tagInputTagProps,
    inputProps: { autoFocus: !showNamespaceSelector },
  };

  const namespaceSelectorMatchLabelsTagInputProps: TagInputProps = {
    values: rule.namespaceSelector.matchLabels.value,
    onChange: rule.namespaceSelector.matchLabels.onChange,
    disabled:
      disableNamespaceInput ||
      (showAllNamespacesCheckbox && rule.namespaceSelector.selectAll.value),
    placeholder: isKNP
      ? 'team=one, env=prod'
      : rule.kind.value === RuleKind.Service
        ? 'namespace'
        : 'namespace, env=prod',
    onKeyDown: preventEnter,
    tagProps: tagInputTagProps,
    inputProps: { autoFocus: true },
  };

  if (props.card.isSelector) {
    return (
      <EndpointSelectorCrudPopover
        card={props.card}
        onDone={props.onDone}
        onInteraction={props.onInteraction}
      />
    );
  }

  return (
    <form className={css.wrapper} onSubmit={onSubmit} onMouseDown={props.onInteraction}>
      {description.length > 0 && (
        <div className={css.description}>
          {description.map(({ title, text }) => {
            return (
              <React.Fragment key={title}>
                {title != '' ? <div className={css.title}>{title}</div> : null}
                <div className={css.text}>{text}</div>
              </React.Fragment>
            );
          })}
        </div>
      )}
      {showKindSelector && (
        <div className={css.section}>
          <div className={css.label}>Rule type</div>
          <RuleKindSelector
            card={props.card}
            disabled={!!stateEndpoint}
            value={rule.kind.value}
            expanded={!rule.kind.value}
            onChange={rule.kind.onChange}
          />
        </div>
      )}
      {showNamespaceSelector && (
        <div className={css.section}>
          {rule.kind.value !== RuleKind.Service && (
            <Button
              rightIcon="plus"
              small
              outlined
              onClick={namespaceSelectorMatchExpressionCrud.add}
              className={css.addExpressionButton}
            >
              Expression
            </Button>
          )}
          <div className={`${css.label} ${css.required}`}>
            {rule.kind.value === RuleKind.Service ? 'Namespace' : 'Namespace selector'}{' '}
            {rule.kind.value !== RuleKind.Service && (
              <HelpIcon
                content={
                  <>
                    Selects particular namespaces for which the rule should be applied. <br />
                    Enter comma separated list of labels or enter a name of the namespace
                    <br />
                    (supported only by CiliumNetworkPolicy).
                  </>
                }
              />
            )}
          </div>
          {rule.namespaceSelector.matchExpressions.value.length &&
          !rule.namespaceSelector.selectAll.value ? (
            <AdvancedSelectorInput
              matchLabels={namespaceSelectorMatchLabelsTagInputProps}
              matchExpressions={rule.namespaceSelector.matchExpressions.value.map(
                (expr, index) => ({ expr, index }),
              )}
              onUpdateMatchExpression={namespaceSelectorMatchExpressionCrud.update}
              onDeleteMatchExpression={namespaceSelectorMatchExpressionCrud.remove}
            />
          ) : (
            <MatchLabelsInput {...namespaceSelectorMatchLabelsTagInputProps} />
          )}
          {showAllNamespacesCheckbox && (
            <Checkbox
              className={css.namespaceSelectorAllCheckbox}
              label="Any namespace"
              checked={rule.namespaceSelector.selectAll.value}
              onChange={rule.namespaceSelector.selectAll.onToggle}
            />
          )}
        </div>
      )}
      {showPodsSelector && (
        <div className={css.section}>
          <Button
            rightIcon="plus"
            small
            outlined
            onClick={podSelectorMatchExpressionCrud.add}
            className={css.addExpressionButton}
          >
            Expression
          </Button>
          <div className={`${css.label} ${css.required}`}>
            {rule.kind.value === RuleKind.Service ? 'Service name or labels' : 'Pod selector'}
          </div>
          {rule.podSelector.matchExpressions.value.length ? (
            <AdvancedSelectorInput
              matchLabels={podSelectorMatchLabelsTagInputProps}
              matchExpressions={rule.podSelector.matchExpressions.value.map((expr, index) => ({
                expr,
                index,
              }))}
              onUpdateMatchExpression={podSelectorMatchExpressionCrud.update}
              onDeleteMatchExpression={podSelectorMatchExpressionCrud.remove}
            />
          ) : (
            <MatchLabelsInput {...podSelectorMatchLabelsTagInputProps} />
          )}
        </div>
      )}
      {rule.kind.value === RuleKind.Fqdn && (
        <div className={css.section}>
          <div className={`${css.label} ${css.required}`}>FQDN</div>
          <InputGroup
            fill
            autoFocus
            value={rule.fqdn.value}
            onChange={rule.fqdn.onChange}
            placeholder="Ex.: *.cilium.io"
          />
        </div>
      )}
      {rule.kind.value === RuleKind.Cidr && (
        <div className={css.section}>
          <div className={`${css.label} ${css.required}`}>CIDR</div>
          <InputGroup
            fill
            autoFocus
            intent={rule.cidr.value && cidrInputTooltip ? 'success' : undefined}
            value={rule.cidr.value}
            onChange={rule.cidr.onChange}
            placeholder="Ex.: 10.2.1.0/28"
            rightElement={
              <Button
                minimal
                small
                rightIcon={showExceptCidrs ? 'caret-up' : 'caret-down'}
                onClick={toggleShowExceptCidrs}
                tabIndex={-1}
              >
                Except
              </Button>
            }
          />
          {cidrInputTooltip && <small className={css.labelNote}>{cidrInputTooltip}</small>}
        </div>
      )}
      {rule.kind.value === RuleKind.Cidr && showExceptCidrs && (
        <div className={`${css.section} ${css.fill}`}>
          <div className={css.label}>Except CIDRs</div>
          <TagInput
            fill
            addOnBlur
            addOnPaste
            values={rule.exceptCidrs.value}
            onChange={rule.exceptCidrs.onChange}
            placeholder="Ex.: 10.2.1.1/32, 10.2.1.2/32"
            onKeyDown={preventEnter}
            tagProps={tagInputTagProps}
          />
        </div>
      )}
      {showPortsInput && (
        <div className={css.section}>
          <div className={css.label}>
            {props.card.isIngress ? 'To ports' : 'Ports'}{' '}
            <HelpIcon
              content={
                <>
                  List of comma separated ports (both UDP and TCP) to allow traffic to.
                  <br />
                  Leave empty to allow all ports
                </>
              }
            />
          </div>
          <TagInput
            fill
            addOnBlur
            addOnPaste
            values={rule.ports.value}
            onChange={rule.ports.onChange}
            placeholder="443, 80"
            onKeyDown={preventEnter}
            tagProps={tagInputTagProps}
          />
        </div>
      )}
      <CrudActions
        card={props.card}
        endpoint={stateEndpoint}
        ruleKind={rule.kind.value}
        canSubmit={canSubmit}
        onSubmit={onSubmit}
        onDeny={onDeny}
        onDelete={onDelete}
        onChangeEndpoint={setStateEndpont}
        onCanSubmitChange={setCanSubmit}
        onRuleKindChange={rule.kind.onChange}
      />
      {showRuleError && <div className={css.error}>{ruleError}</div>}
    </form>
  );
});

export interface EndpointSelectorProps {
  card: PolicyCard;
  onDone?: (card: PolicyCard) => void;
  onInteraction?: () => void;
}

const EndpointSelectorCrudPopover = observer(function EndpointSelectorCrudPopover(
  props: EndpointSelectorProps,
) {
  const store = useStore();

  const podSelectorState = useTagsState(
    Object.keys(store.policy.specPodSelector?.matchLabels ?? {}).map(key => {
      const val = store.policy.specPodSelector?.matchLabels?.[key];
      return val ? `${key}=${val}` : key;
    }),
  );

  const policyName = useStringInputState(store.policy.policyName ?? '');
  const policyNamespace = useStringInputState(store.policy.policyNamespace ?? '');

  const onSubmit = useCallback(
    (event: React.FormEvent) => {
      event.preventDefault();

      const podSelector: EndpointSelector = podSelectorState.value.reduce<EndpointSelector>(
        (sel, s) => {
          if (s.trim().length === 0) return sel;
          const [key, value] = s.trim().split('=');
          if (!sel.matchLabels) sel.matchLabels = {};
          sel.matchLabels && (sel.matchLabels[key] = value ? value : '');
          return sel;
        },
        {},
      );

      store.policy.setSpecPodSelector(podSelector);
      store.policy.setCurrentPolicyName(policyName.value);
      store.policy.setCurrentPolicyNamespace(policyNamespace.value);

      props.onDone?.(props.card);
    },
    [props.card, props.onDone, podSelectorState.value, policyName.value, policyNamespace.value],
  );

  const preventEnter = useCallback((event: React.KeyboardEvent) => {
    if (event.key === 'Enter') event.preventDefault();
  }, []);

  return (
    <form className={css.wrapper} onSubmit={onSubmit} onMouseDown={props.onInteraction}>
      <div className={css.section}>
        <div className={css.label}>Policy name</div>
        <InputGroup
          fill
          placeholder="my-policy"
          value={policyName.value}
          onChange={policyName.onChange}
        />
      </div>
      {
        <div className={css.section}>
          <div className={css.label}>Policy namespace</div>
          <InputGroup
            fill
            placeholder="my-namespace"
            value={policyNamespace.value}
            onChange={policyNamespace.onChange}
          />
        </div>
      }
      <div className={css.section}>
        <div className={css.label}>{store.policy.isCNP ? 'Endpoint selector' : 'Pod selector'}</div>
        <TagInput
          fill
          addOnBlur
          addOnPaste
          values={podSelectorState.value}
          onChange={podSelectorState.onChange}
          placeholder="app=frontend, env=production"
          onKeyDown={preventEnter}
          tagProps={tagInputTagCommonProps}
          inputProps={{ autoFocus: true }}
        />
      </div>
      <div className={css.actions}>
        <Button fill type="submit" intent="success" className={css.action}>
          Save
        </Button>
      </div>
    </form>
  );
});
