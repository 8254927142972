import { ColumnWidths as FlowsTableColumn } from '~/components/FlowsTable/general';
import {
  Aggregation,
  StateChange as AggregationStateChange,
  AggregatorType,
} from '~/domain/aggregation';

const LAST_NAMESPACE_KEY = '@hubble-ui/namespace';
const LAST_IS_AGGREGATION_OFF = '@hubble-ui/is-aggregation-off';
const LAST_AGGREGATOR_TYPES_KEY = '@hubble-ui/aggregator-types';
const LAST_AGGREGATION_STATE_CHANGE = '@hubble-ui/aggregation-state-change';
const FLOWS_TABLE_COLUMNS_KEY = '@hubble-ui/flowstable-columns/v2';
const SHOW_HOST_KEY = '@hubble-ui/show-host';
const SHOW_KUBEDNS_KEY = '@hubble-ui/show-kube-dns';
const SHOW_REMOTE_NODE_KEY = '@hubble-ui/show-remote-node';
const SHOW_PROMETHEUS_SERVICE_KEY = '@hubble-ui/show-prometheus-app';
const PANEL_RESIZE_POSITION = '@hubble-ui/panel-resize-position';
const PANEL_RESIZE_EXPANDED = '@hubble-ui/panel-resize-expanded';
const PANEL_RESIZE_COLLAPSED = '@hubble-ui/panel-resize-collapsed';
const THEME_PREFERENCE_KEY = '@hubble-ui/theme-preference';
const HIDE_DROP_FILTERS_MESSAGE = '@hubble-ui/hide-drop-filters-message';
const VISITS_COUNTER = '@hubble-ui/visits-counters';
const POLICY_IGNORE_FLOW_LABELS = '@hubble-ui/policy-ignore-flow-labels';
const POLICY_ASSISTANT_IGNORED_IDS = '@hubble-ui/policy-assistant-ignored-ids';
const SKIP_ONBOARDING_DIALOG = '@hubble-ui/skip-onboarding-dialog';
const SKIP_DOWNLOAD_POLICY_DIALOG = '@hubble-ui/skip-download-policy-dialog';
const SKIP_SHARE_POLICY_DIALOG = '@hubble-ui/skip-share-policy-dialog';
const SKIP_ON_BEFORE_UNLOAD = '@hubble-ui/skip-on-before-unload';
const GITHUB_ACCESS_TOKEN = '@hubble-ui/github-access-token';
const DETAILS_PANEL_POS = '@hubble-ui/panel-position';

export function getLastNamespace(): string | null {
  return localStorage.getItem(LAST_NAMESPACE_KEY);
}

export function saveLastNamespace(ns: string) {
  localStorage.setItem(LAST_NAMESPACE_KEY, ns);
}

export function deleteLastNamespace() {
  localStorage.removeItem(LAST_NAMESPACE_KEY);
}

export function getIsAggregationOff(): boolean | null {
  const isOff = localStorage.getItem(LAST_IS_AGGREGATION_OFF);
  if (isOff == null) return null;

  return isOff === 'true';
}

export function saveIsAggregationOff(val: boolean) {
  localStorage.setItem(LAST_IS_AGGREGATION_OFF, String(val));
}

export function deleteLastIsAggregationOff() {
  localStorage.removeItem(LAST_IS_AGGREGATION_OFF);
}

export function getLastAggregatorTypes(): AggregatorType[] | null {
  const val = localStorage.getItem(LAST_AGGREGATOR_TYPES_KEY);
  if (val == null) return null;

  const types: AggregatorType[] = [];

  val.split(',').forEach(str => {
    const type = Aggregation.parseAggregatorType(str);
    if (type == null) return;

    types.push(type);
  }, [] as string[]);

  return types;
}

export function saveLastAggregatorTypes(types: AggregatorType[] | null) {
  if (types == null || types.length === 0) {
    deleteLastAggregatorTypes();
    return;
  }

  localStorage.setItem(LAST_AGGREGATOR_TYPES_KEY, types.join(','));
}

export function deleteLastAggregatorTypes() {
  localStorage.removeItem(LAST_AGGREGATOR_TYPES_KEY);
}

export function getLastAggregationStateChange(): AggregationStateChange | null {
  const val = localStorage.getItem(LAST_AGGREGATION_STATE_CHANGE);
  if (val == null || val.length === 0) return null;

  return Aggregation.parseStateChange(val);
}

export function saveLastAggregationStateChange(stateChange: AggregationStateChange | null) {
  if (stateChange == null) {
    deleteLastAggregationStateChange();
    return;
  }

  localStorage.setItem(LAST_AGGREGATION_STATE_CHANGE, String(stateChange));
}

export function deleteLastAggregationStateChange() {
  localStorage.removeItem(LAST_AGGREGATION_STATE_CHANGE);
}

export function getFlowsTableVisibleColumns(id: string): FlowsTableColumn | null {
  const val = localStorage.getItem(`${FLOWS_TABLE_COLUMNS_KEY}-${id}`);
  if (!val) return null;

  const columns = JSON.parse(val);

  return columns;
}

export function saveFlowsTableVisibleColumns(columns: FlowsTableColumn, id: string) {
  localStorage.setItem(`${FLOWS_TABLE_COLUMNS_KEY}-${id}`, JSON.stringify(columns));
}

export function getShowHost(): boolean {
  return localStorage.getItem(SHOW_HOST_KEY) === 'true';
}

export function saveShowHost(val: boolean) {
  localStorage.setItem(SHOW_HOST_KEY, val ? 'true' : 'false');
}

export function getShowKubeDns(): boolean {
  return localStorage.getItem(SHOW_KUBEDNS_KEY) === 'true';
}

export function saveShowKubeDns(val: boolean) {
  localStorage.setItem(SHOW_KUBEDNS_KEY, val ? 'true' : 'false');
}

export function getShowRemoteNode(): boolean {
  return localStorage.getItem(SHOW_REMOTE_NODE_KEY) === 'true';
}

export function saveShowRemoteNode(val: boolean) {
  localStorage.setItem(SHOW_REMOTE_NODE_KEY, val ? 'true' : 'false');
}

export function getShowPrometheusApp(): boolean {
  return localStorage.getItem(SHOW_PROMETHEUS_SERVICE_KEY) === 'true';
}

export function saveShowPrometheusApp(val: boolean) {
  localStorage.setItem(SHOW_PROMETHEUS_SERVICE_KEY, val ? 'true' : 'false');
}

export function getThemePreference() {
  return localStorage.getItem(THEME_PREFERENCE_KEY);
}

export function saveThemePreference(themeValue: string) {
  localStorage.setItem(THEME_PREFERENCE_KEY, themeValue);
}

export function saveHideDropFiltersMessage() {
  localStorage.setItem(HIDE_DROP_FILTERS_MESSAGE, 'true');
}

export function getHideDropFiltersMessage() {
  return localStorage.getItem(HIDE_DROP_FILTERS_MESSAGE);
}

export function getVisitsCounter(): number {
  const cnt = localStorage.getItem(VISITS_COUNTER);
  if (cnt && /^\d+$/gi.test(cnt)) return +cnt;
  return 0;
}

export function incrementVisitsCounter() {
  const cnt = getVisitsCounter() ?? 0;
  const nextCnt = cnt + 1;
  localStorage.setItem(VISITS_COUNTER, String(nextCnt));
  return nextCnt;
}

export function getGithubAccessToken(): string | null {
  return localStorage.getItem(GITHUB_ACCESS_TOKEN);
}

export function saveGithubAccessToken(access_token: string) {
  localStorage.setItem(GITHUB_ACCESS_TOKEN, access_token);
}

export function getSkipOnboardingDialog(): boolean {
  return localStorage.getItem(SKIP_ONBOARDING_DIALOG) === 'yes';
}

export function saveSkipOnboardingDialog(state: boolean) {
  state ? localStorage.setItem(SKIP_ONBOARDING_DIALOG, 'yes') : deleteSkipOnboardingDialog();
}

export function toggleSkipOnboardingDialog() {
  getSkipOnboardingDialog() ? deleteSkipOnboardingDialog() : saveSkipOnboardingDialog(true);
}

export function deleteSkipOnboardingDialog() {
  localStorage.removeItem(SKIP_ONBOARDING_DIALOG);
}

export function getSkipDownloadPolicyDialog(): boolean {
  return localStorage.getItem(SKIP_DOWNLOAD_POLICY_DIALOG) === 'yes';
}

export function saveSkipDownloadPolicyDialog(state: boolean) {
  state
    ? localStorage.setItem(SKIP_DOWNLOAD_POLICY_DIALOG, 'yes')
    : deleteSkipDownloadPolicyDialog();
}

export function toggleSkipDownloadPolicyDialog() {
  getSkipDownloadPolicyDialog()
    ? deleteSkipDownloadPolicyDialog()
    : saveSkipDownloadPolicyDialog(true);
}

export function deleteSkipDownloadPolicyDialog() {
  localStorage.removeItem(SKIP_DOWNLOAD_POLICY_DIALOG);
}

export function getSkipSharePolicyDialog(): boolean {
  return localStorage.getItem(SKIP_SHARE_POLICY_DIALOG) === 'yes';
}

export function saveSkipSharePolicyDialog(state: boolean) {
  state ? localStorage.setItem(SKIP_SHARE_POLICY_DIALOG, 'yes') : deleteSkipSharePolicyDialog();
}

export function toggleSkipSharePolicyDialog() {
  getSkipSharePolicyDialog() ? deleteSkipSharePolicyDialog() : saveSkipSharePolicyDialog(true);
}

export function deleteSkipSharePolicyDialog() {
  localStorage.removeItem(SKIP_SHARE_POLICY_DIALOG);
}

export function getSkipOnBeforeUnload(): boolean {
  return localStorage.getItem(SKIP_ON_BEFORE_UNLOAD) === 'yes';
}

export function saveSkipOnBeforeUnload(state: boolean) {
  state ? localStorage.setItem(SKIP_ON_BEFORE_UNLOAD, 'yes') : deleteSkipOnBeforeUnload();
}

export function toggleSkipOnBeforeUnload() {
  getSkipOnBeforeUnload() ? deleteSkipOnBeforeUnload() : saveSkipOnBeforeUnload(true);
}

export function deleteSkipOnBeforeUnload() {
  localStorage.removeItem(SKIP_ON_BEFORE_UNLOAD);
}

export function getIgnoreFlowLabels(defaults = new Set<string>()): Set<string> {
  const str = localStorage.getItem(POLICY_IGNORE_FLOW_LABELS);
  if (typeof str !== 'string') return defaults;
  const list = str
    .split(',')
    .map(s => s.trim())
    .filter(s => s.length > 0);
  return new Set(list);
}

export function saveIgnoreFlowLabels(set: Set<string>) {
  localStorage.setItem(POLICY_IGNORE_FLOW_LABELS, Array.from(set).join(','));
}

export function deleteIgnoreFlowLabels() {
  localStorage.removeItem(POLICY_IGNORE_FLOW_LABELS);
}

export function getIgnoredPolicyAssistantEntries(): string[] {
  const ids = localStorage.getItem(POLICY_ASSISTANT_IGNORED_IDS);
  if (!ids) return [];
  return ids
    .split(',')
    .map(id => id.trim())
    .filter(id => id.length > 0);
}

export function setIgnoredPolicyAssistantEntries(ids: string[]) {
  localStorage.setItem(POLICY_ASSISTANT_IGNORED_IDS, ids.join(','));
}

export function getPanelResizeCollapsed(panelName?: string) {
  return (
    localStorage.getItem(`${PANEL_RESIZE_COLLAPSED}${panelName ? '-' + panelName : ''}`) === 'true'
  );
}
export function getPanelResizeExpanded(panelName?: string) {
  return (
    localStorage.getItem(`${PANEL_RESIZE_EXPANDED}${panelName ? '-' + panelName : ''}`) === 'true'
  );
}
export function getPanelResizePosition(panelName?: string) {
  const top = parseInt(
    localStorage.getItem(`${PANEL_RESIZE_POSITION}${panelName ? '-' + panelName : ''}`) || '',
  );

  return isNaN(top) ? null : top;
}
export function setPanelResizeCollapsed(value: boolean, panelName?: string) {
  localStorage.setItem(
    `${PANEL_RESIZE_COLLAPSED}${panelName ? '-' + panelName : ''}`,
    value ? 'true' : 'false',
  );
}
export function setPanelResizeExpanded(value: boolean, panelName?: string) {
  localStorage.setItem(
    `${PANEL_RESIZE_EXPANDED}${panelName ? '-' + panelName : ''}`,
    value ? 'true' : 'false',
  );
}
export function setPanelTopPosition(value: number, panelName?: string) {
  localStorage.setItem(`${PANEL_RESIZE_POSITION}${panelName ? '-' + panelName : ''}`, `${value}`);
}

export function getDetailsPanelTop(panelName?: string): number | null {
  const num = localStorage.getItem(`${DETAILS_PANEL_POS}${panelName ? '-' + panelName : ''}`);
  if (!num) return null;

  return parseFloat(num);
}

export function setDetailsPanelTop(top: number, panelName?: string) {
  localStorage.setItem(`${DETAILS_PANEL_POS}${panelName ? '-' + panelName : ''}`, `${top}`);
}
