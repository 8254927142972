import { ServiceCard } from '~/components/ServiceMapCard/types';
import { XY } from '~/domain/geometry';
import { XYWH } from '~/ui-layer/service-map/coordinates/types';
import { Verdict } from '~backend/proto/flow/flow_pb';

export enum CardType {
  Cluster = 'cluster',
  Namespace = 'namespace',
  World = 'world',
  Host = 'host',
}
export interface CardInfoOther extends XYWH {
  name: string;
  id: string;
  verdictIcon?: string | null;
  loading?: boolean;
  type: CardType;
  outside: boolean;
  onCardClick?: () => void | null;
  onMouseEnter?: () => void | null;
  onMouseLeave?: () => void | null;
}

export type CardInfoService = XYWH & {
  type: 'service';
  id: string;
  outside: boolean;
  card: ServiceCard;
  onCardClick?: (svcCard: ServiceCard) => void | null;
  onMouseEnter?: () => void | null;
  onMouseLeave?: () => void | null;
};

export type CardInfo = CardInfoOther | CardInfoService;

export interface ContainerCardInfo extends XYWH {
  loading?: boolean;
  selectedCluster: string;
  selectedNamespace?: string;
}

export interface Arrow {
  id: string;
  points: XY[];
  highlighted?: boolean;
  verdicts?: Verdict[];
}

export interface BaseConnection {
  src_namespace?: string | null;
  dst_namespace?: string | null;
  src_cluster?: string | null;
  dst_cluster?: string | null;
  verdicts: Verdict[];
}

export interface AccessPointArrow {
  id: string;
  start?: XY;
  end?: XY;
  verdicts: Set<Verdict>;
}

export type CombinedAccessPointArrow = {
  id: string;
  highlighted?: boolean;
  combinedArrows: AccessPointArrow[];
};
