import * as React from 'react';

import { Menu, MenuItem } from '@blueprintjs/core';

import { DateShortcut, TimeRange } from '~/domain/time';

import css from './TimeRangeShortcuts.scss';

interface Props {
  readonly timeRange?: TimeRange | null;
  readonly onTimeRangeChange?: (timeRange: TimeRange) => void;
}

const SHORTCUTS_LIST: DateShortcut[] = [
  DateShortcut.Minutes5,
  DateShortcut.Minutes30,
  DateShortcut.Hour1,
  DateShortcut.Day1,
  DateShortcut.Week1,
  DateShortcut.Month1,
];

export const TimeRangeShortcuts = (props: Props) => {
  const checkIsActive = React.useCallback(
    (shortcut: DateShortcut) => {
      return props.timeRange?.start === shortcut;
    },
    [props.timeRange],
  );

  const onSelectShortcut = React.useCallback(
    (shortcut: DateShortcut) => {
      props.onTimeRangeChange?.(TimeRange.new(shortcut, DateShortcut.Now));
    },
    [props.onTimeRangeChange],
  );

  return (
    <Menu className={css.wrapper}>
      {SHORTCUTS_LIST.map(shortcut => {
        return (
          <MenuItem
            key={shortcut}
            active={checkIsActive(shortcut)}
            onClick={() => onSelectShortcut(shortcut)}
            text={'Last ' + TimeRange.shortcutToHumanString(shortcut)}
          />
        );
      })}
    </Menu>
  );
};
