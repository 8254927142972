import React, { useCallback } from 'react';

import { Button, Menu, MenuItem, Popover, Spinner } from '@blueprintjs/core';
import { observer } from 'mobx-react';

import { Authorization } from '~/authorization';
import { useStore } from '~/store';
import { usePopover } from '~/ui/hooks';

import css from './UserDropdown.scss';

export enum E2E {
  authHandle = 'auth-handle',
}
export interface Props {
  inlineEmail?: boolean;
}

export const UserDropdown = observer(function UserDropdown(props: Props) {
  const store = useStore();
  const popover = usePopover({
    popoverProps: {
      className: css.popoverWrapper,
    },
  });

  const onLogout = useCallback(() => {
    Authorization.goToSignin();
  }, []);

  if (store.authz.isUnauthorized) {
    return null;
  }

  const content = (
    <Menu>
      {store.authz.user?.email && <MenuItem text={store.authz.user?.email} disabled />}
      <MenuItem text="Logout" intent="danger" onClick={onLogout} />
    </Menu>
  );

  return (
    <Popover {...popover.props} content={content}>
      <Button
        data-testid={E2E.authHandle}
        minimal
        className={css.button}
        icon={store.authz.isAuthorized ? 'user' : <Spinner size={16} />}
        rightIcon={store.authz.isPending ? undefined : 'chevron-down'}
        onClick={popover.toggle}
        text={props.inlineEmail ? store.authz.user?.email : undefined}
      />
    </Popover>
  );
});
