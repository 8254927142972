import React from 'react';

import classNames from 'classnames';
import { observer } from 'mobx-react';

import { Intent, intentClasses, Size } from '~/components/widgets/common';

import css from './styles.scss';

export interface Props {
  className?: string;
  children?: React.ReactNode;
  active?: boolean;
  intent?: Intent;

  size?: Size;
  transparent?: boolean;
  disabled?: boolean;

  onClick?: () => void;
}

export const Button = observer(function Button(props: Props) {
  const intent = props.intent ?? Intent.None;

  const classes = classNames(css.button, props.className, {
    [css.transparent]: !!props.transparent,
    [css.active]: !!props.active,
    ...intentClasses(css, intent),
  });

  return (
    <div className={classes} onClick={props.onClick}>
      {props.children}
    </div>
  );
});
