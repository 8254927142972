// TODO: its probably not a good place for such helpers
// consider moving it outside of domain types

import { CountStats } from '~/domain/common';
import { HubbleLink, HubbleService, IPProtocol, Latency, Time } from '~/domain/hubble';
import { Labels } from '~/domain/labels';
import { StateChange } from '~/domain/misc';
import { PolicyData, PolicyDataType } from '~/domain/policy';
import * as uipb from '~backend/proto/ui/ui_pb';

import * as aggregation from './aggregation';
import * as authType from './auth-type';
import * as flows from './flows';
import * as notifications from './notifications';
import * as processEvents from './process-events';
import * as time from './time';
import * as verdict from './verdict';

export const stateChangeFromPb = (change: uipb.StateChange): StateChange => {
  switch (change) {
    case uipb.StateChange.ADDED:
      return StateChange.Added;
    case uipb.StateChange.MODIFIED:
      return StateChange.Modified;
    case uipb.StateChange.DELETED:
      return StateChange.Deleted;
    case uipb.StateChange.EXISTS:
      return StateChange.Exists;
  }

  return StateChange.Unknown;
};

export const relayServiceFromPb = (svc: uipb.Service): HubbleService => {
  return {
    id: svc.id,
    name: svc.name,
    namespace: svc.namespace,
    labels: Labels.labelsToKV(svc.labels, false),
    dnsNames: svc.dns_names,
    egressPolicyEnforced: svc.egress_policy_enforced,
    ingressPolicyEnforced: svc.ingress_policy_enforced,
    visibilityPolicyStatus: svc.visibility_policy_status,
    creationTimestamp: svc.creation_timestamp ?? msToPbTimestamp(Date.now()),
    workloads: svc.workloads,
    identity: svc.identity,
  };
};

export const relayServiceLinkFromPb = (link: uipb.ServiceLink): HubbleLink => {
  return {
    id: link.id,
    sourceId: link.source_id,
    destinationId: link.destination_id,
    destinationPort: link.destination_port,
    ipProtocol: ipProtocolFromPb(link.ip_protocol),
    verdict: verdict.verdictFromPb(link.verdict),
    flowAmount: link.flow_amount,
    bytesTransfered: link.bytes_transfered,
    latency: latencyFromPb(link.latency),
    authType: authType.authTypeFromPb(link.auth_type),
    isEncrypted: link.is_encrypted,
  };
};

export const latencyFromPb = (lat?: uipb.ServiceLink_Latency | null): Latency => {
  return {
    min: time.fromDuration(lat?.min) ?? time.zero(),
    max: time.fromDuration(lat?.max) ?? time.zero(),
    avg: time.fromDuration(lat?.avg) ?? time.zero(),
  };
};

export const ipProtocolFromPb = (ipp: uipb.IPProtocol): IPProtocol => {
  switch (ipp) {
    case uipb.IPProtocol.TCP:
      return IPProtocol.TCP;
    case uipb.IPProtocol.UDP:
      return IPProtocol.UDP;
    case uipb.IPProtocol.ICMP_V4:
      return IPProtocol.ICMPv4;
    case uipb.IPProtocol.ICMP_V6:
      return IPProtocol.ICMPv6;
  }

  return IPProtocol.Unknown;
};

export const policySpecFromPb = (policySpec: uipb.PolicySpec): PolicyData => {
  return {
    policyName: policySpec.policyName,
    policyNamespace: policySpec.policyNamespace,
    type: policyTypeFromPb(policySpec.type),
    yaml: policySpec.yaml,
    uid: policySpec.uid,
  };
};

export const policyTypeFromPb = (type: uipb.PolicySpecType): PolicyDataType => {
  switch (type) {
    case uipb.PolicySpecType.CILIUM_NETWORK_POLICY:
      return PolicyDataType.CiliumNetworkPolicy;
    case uipb.PolicySpecType.KUBERNETES_NETWORK_POLICY:
      return PolicyDataType.KubernetesNetworkPolicy;
    case uipb.PolicySpecType.CILIUM_CLUSTERWIDE_NETWORK_POLICY:
      return PolicyDataType.CiliumClusterwideNetworkPolicy;
  }
};

export const msToPbTimestamp = (ms: number): Time => {
  const seconds = (ms / 1000) | 0;
  const nanos = (ms - seconds * 1000) * 1e6;

  return { seconds, nanos };
};

export const countStatsFromPb = (cs: uipb.CountStats): CountStats | null => {
  const parsedTime = time.parseTimeFromObject(cs.timestamp);
  const count = cs.count;

  if (parsedTime == null || count == null) return null;

  return new CountStats(parsedTime, count);
};

export { authType };
export { verdict };
export { notifications };
export { aggregation };
export { processEvents };
export { flows };
export { time };

export * as authorization from './authorization';
export * as featureFlags from './feature-flags';
export * as timescape from './timescape';
export * as tcpFlags from './tcp-flags';
export * as protocol from './protocol';
export * as namespaces from './namespaces';
export * as link from './link';
export * as l7 from './l7';
export * as workload from './workload';
