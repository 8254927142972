import React from 'react';

import { Intent, Spinner } from '@blueprintjs/core';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import { Color, ColoredCircle } from '~/components/ColoredCircle';
import { StatusEntry } from '~/ui/status-center';

import css from './StatusCenterHandle.scss';

export type Props = {
  hasNotifications?: boolean;
  criticalPreview?: StatusEntry | null;

  onClick?: () => void;
};

export const StatusCenterHandle = observer(function StatusCenterHandle(props: Props) {
  const { criticalPreview: preview } = props;

  // NOTE: Presence of css.hasNotifications class controls if ColoredCircle is
  // NOTE: visible.
  const classes = classNames(css.statusCenterHandle, {
    [css.hasNotifications]: !!props.hasNotifications,
    [css.hasCriticalPreview]: !!props.criticalPreview,
  });

  return (
    <div className={classes} onClick={props.onClick}>
      {props.criticalPreview == null && (
        <div className={css.regularHandle}>
          <div className={css.icon}>
            <ColoredCircle color={Color.Red} className={css.notificationsCircle} />

            <img src="/icons/misc/recent-events-icon.svg" width="100%" />
          </div>

          <div className={css.caption}>Notifications</div>
          <div className={css.arrowIcon}>
            <img src="/icons/misc/arrow-right-rounded.svg" width="100%" />
          </div>
        </div>
      )}

      {preview != null && (
        <div className={css.criticalHandle}>
          {preview.isPending && (
            <div className={css.spinner}>
              <Spinner size={20} intent={Intent.DANGER} />
            </div>
          )}

          <div className={css.title} title={preview.title}>
            {preview.title}
          </div>
        </div>
      )}
    </div>
  );
});
