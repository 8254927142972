import React, { memo, useState } from 'react';

import { Icon, Intent, Spinner, Tab, Tabs } from '@blueprintjs/core';
import classnames from 'classnames';

import { FlowActionComponent } from '~/components/PoliciesPanel/FlowActionComponent';
import { Flow } from '~/domain/flows';

import { Props as ContentProps, FlowsTableSidebarContent } from './SidebarContent';
import css from './styles.scss';

export type Props = Omit<ContentProps, 'flow'> & {
  flow?: Flow | null;
  isLoading?: boolean;
  showAddToPolicyAction?: boolean;
};

export enum E2E {
  root = 'flow-table-detail-table',
}

export const FlowsTableSidebar = memo(function FlowsTableSidebar(props: Props) {
  const [currentTab, setCurrentTab] = useState<string>('details');
  const className = classnames(css.sidebar);
  const isLoading = !!props.isLoading;

  return (
    <div className={className}>
      <div className={css.main} data-testid={E2E.root}>
        <header className={css.header}>
          <Tabs
            defaultSelectedTabId="details"
            onChange={(newTabId: string) => setCurrentTab(newTabId)}
          >
            <Tab id="details" title="Flow Details"></Tab>
            <Tab id="raw" title="Raw flow"></Tab>
          </Tabs>
          <Tabs.Expander />
          <Icon className={css.close} icon="cross" onClick={props.onClose} />
        </header>
        {props.showAddToPolicyAction && props.flow && (
          <div className={css.action}>
            <FlowActionComponent flow={props.flow} />
          </div>
        )}
        {isLoading && (
          <div className={css.centeredOverlay}>
            <Spinner size={40} intent={Intent.PRIMARY} />
          </div>
        )}

        {!isLoading && props.flow == null && (
          <div className={css.centeredOverlay}>
            <span className={css.noData}>Details are not available</span>
          </div>
        )}
        {!isLoading && props.flow != null && currentTab == 'raw' && (
          <textarea className={css.rawInput} value={JSON.stringify(props.flow.raw, null, 2)} />
        )}

        {!isLoading && props.flow != null && currentTab == 'details' && (
          <FlowsTableSidebarContent
            flow={props.flow}
            filters={props.filters}
            policies={props.policies}
            showReviewPolicyButton={props.showReviewPolicyButton}
            selectedPolicy={props.selectedPolicy}
            policiesListTitle={props.policiesListTitle}
            policiesYamlTitle={props.policiesYamlTitle}
            onSelectPolicy={props.onSelectPolicy}
            onClose={props.onClose}
            onVerdictClick={props.onVerdictClick}
            onTcpFlagClick={props.onTcpFlagClick}
            onLabelClick={props.onLabelClick}
            onPodClick={props.onPodClick}
            onIdentityClick={props.onIdentityClick}
            onIpClick={props.onIpClick}
            onDnsClick={props.onDnsClick}
            onWorkloadClick={props.onWorkloadClick}
            onPortClick={props.onPortClick}
            onProtocolClick={props.onProtocolClick}
            onReviewInPolicyEditor={props.onReviewInPolicyEditor}
            onOpenProcessTreeForFlow={props.onOpenProcessTreeForFlow}
          />
        )}
      </div>
    </div>
  );
});
