import React from 'react';

import { animated } from '@react-spring/web';
import { useDrag } from '@use-gesture/react';
import classnames from 'classnames';
import { observer } from 'mobx-react';

import css from './styles.scss';

export interface Props {
  leftSide?: React.ReactNode;
  center?: React.ReactNode;
  rightSide?: React.ReactNode;
  isResizable?: boolean;

  onResize?: (movY: number, isLast: boolean, isActive: boolean) => void;
  children?: React.ReactNode;
}

export const DragPanel = observer(function DragPanel(props: Props) {
  const { isResizable = true } = props;

  // NOTE: What is wrong with this `e` param type?
  const bind = useDrag(
    e => {
      if (!props.onResize || !isResizable) return;

      // NOTE: By some reason e.delta[1] is not working in Chromium correctly :/
      props.onResize(e.xy[1], e.last, e.active);
    },
    { filterTaps: true },
  );

  return (
    <animated.div
      {...bind()}
      className={classnames(css.dragPanel, {
        [css.resizable]: isResizable,
      })}
    >
      <div className={css.left}>{props.leftSide}</div>
      <div className={css.center}>{props.center}</div>
      <div className={css.right}>{props.rightSide}</div>
    </animated.div>
  );
});
