import sub from 'date-fns/sub';
import _ from 'lodash';
import moment from 'moment';

import * as misc from '~/domain/misc';

export type Time = {
  seconds: number;
  nanos: number;
};

export enum DateShortcut {
  Now = 'now',
  Minutes5 = '5-mins',
  Minutes30 = '30-mins',
  Hour1 = '1-hour',
  Day1 = '1-day',
  Week1 = '1-week',
  Month1 = '1-month',
}

const SHORTCUTS_SET = new Set<string>(Object.values(DateShortcut));
const DATE_SHORTCUTS_HUMAN_STRINGS = {
  [DateShortcut.Now]: 'Now',
  [DateShortcut.Minutes5]: '5 mins',
  [DateShortcut.Minutes30]: '30 mins',
  [DateShortcut.Hour1]: '1 hour',
  [DateShortcut.Day1]: '1 day',
  [DateShortcut.Week1]: '1 week',
  [DateShortcut.Month1]: '1 month',
} as const;

export type DateOrShortcut = DateShortcut | Date;

export class TimeRange {
  private _start: DateOrShortcut;
  private _end: DateOrShortcut;

  public static new(start: DateOrShortcut, end: DateOrShortcut) {
    return new TimeRange(start, end);
  }

  public static parseFromTo(from: any, to: any): TimeRange | null {
    if (!from || !to) return null;

    const parsedFrom = TimeRange.parseDateOrShortcut(from);
    if (!parsedFrom) return null;

    const parsedTo = TimeRange.parseDateOrShortcut(to);
    if (!parsedTo) return null;

    return TimeRange.new(parsedFrom, parsedTo);
  }

  public static parseDateOrShortcut(v: any): DateOrShortcut | null {
    if (!v) return null;

    if (_.isDate(v)) {
      return misc.isValidDate(v) ? v : null;
    }

    if (_.isString(v)) {
      if (SHORTCUTS_SET.has(v)) return v as DateShortcut;

      const d = new Date(v);
      return _.isDate(d) && misc.isValidDate(d) ? d : null;
    }

    return null;
  }

  public static lastHour(): TimeRange {
    const now = new Date();
    const pastHour = sub(now, { hours: 1 });

    return TimeRange.new(pastHour, now);
  }

  public static lastDay(): TimeRange {
    const now = new Date();
    const pastDay = sub(now, { days: 1 });

    return TimeRange.new(pastDay, now)!;
  }

  public static checkEquality(
    a: TimeRange | null | undefined,
    b: TimeRange | null | undefined,
  ): boolean {
    if (typeof a !== typeof b) return false;
    if (!a || !b) return true;
    if (typeof a.start !== typeof b.start || typeof a.end !== typeof b.end) {
      return false;
    }
    const startIsEqual =
      typeof a.start === 'string' ? a.start === b.start : a.start.valueOf() === b.start.valueOf();
    const endIsEqual =
      typeof a.end === 'string' ? a.end === b.end : a.end.valueOf() === b.end.valueOf();
    return startIsEqual && endIsEqual;
  }

  public static shortcutToHumanString(shortcut: DateShortcut): string {
    return DATE_SHORTCUTS_HUMAN_STRINGS[shortcut];
  }

  public static dateOrShortcutToDate(value: DateOrShortcut): Date {
    const now = new Date();
    switch (value) {
      case DateShortcut.Now:
        return now;
      case DateShortcut.Minutes5:
        return sub(now, { minutes: 5 });
      case DateShortcut.Minutes30:
        return sub(now, { minutes: 30 });
      case DateShortcut.Hour1:
        return sub(now, { hours: 1 });
      case DateShortcut.Day1:
        return sub(now, { days: 1 });
      case DateShortcut.Week1:
        return sub(now, { weeks: 1 });
      case DateShortcut.Month1:
        return sub(now, { months: 1 });
      default:
        return new Date(value);
    }
  }

  constructor(start: DateOrShortcut, end: DateOrShortcut) {
    this._start = start;
    this._end = end;
  }

  public clone(): TimeRange {
    return new TimeRange(this._start, this._end);
  }

  public get start(): DateOrShortcut {
    return this._start;
  }

  public set start(s: DateOrShortcut) {
    this._start = s;
  }

  public get end(): DateOrShortcut {
    return this._end;
  }

  public set end(e: DateOrShortcut) {
    this._end = e;
  }

  public get startStr(): string {
    return typeof this.start === 'string' ? this.start : this.start.toISOString();
  }

  public get endStr(): string {
    return typeof this.end === 'string' ? this.end : this.end.toISOString();
  }

  public get startISOString(): string {
    return this.startDate.toISOString();
  }

  public get endISOString(): string {
    return this.endDate.toISOString();
  }

  public get startDate(): Date {
    return TimeRange.dateOrShortcutToDate(this.start);
  }

  public get endDate(): Date {
    return TimeRange.dateOrShortcutToDate(this.end);
  }

  public get startDateTimezoneOffset(): Date {
    return new Date(this.startDate.getTime() - new Date().getTimezoneOffset() * 60000);
  }

  public get endDateTimezoneOffset(): Date {
    return new Date(this.endDate.getTime() - new Date().getTimezoneOffset() * 60000);
  }

  public get duration(): TimeDuration {
    return new TimeDuration(this.endDate.getTime() - this.startDate.getTime());
  }

  public get plain(): { start: Date; end: Date } {
    return {
      start: this.startDate,
      end: this.endDate,
    };
  }

  public get startHumanString(): string {
    if (typeof this.start === 'string') {
      if (this.start === DateShortcut.Now) {
        return TimeRange.shortcutToHumanString(DateShortcut.Now);
      }
      return `${TimeRange.shortcutToHumanString(this.start)} ago`;
    }
    return moment(this.startDate).format('MMM DD, YYYY HH:mm');
  }

  public get endHumanString(): string {
    return typeof this.end === 'string'
      ? TimeRange.shortcutToHumanString(this.end)
      : moment(this.endDate).format('MMM DD, YYYY HH:mm');
  }

  toString(): string {
    return `${this.startStr} - ${this.endStr}`;
  }
}

export class TimeDuration {
  private _milliseconds: number;

  constructor(milliseconds: number) {
    this._milliseconds = milliseconds;
  }

  get milliseconds(): number {
    return this._milliseconds;
  }

  get seconds(): number {
    return this.milliseconds / 1000;
  }

  toString(): string {
    return `${this.milliseconds}ms`;
  }
}

export class TimeDurationRange {
  private _min: TimeDuration;
  private _max: TimeDuration;

  constructor(min: TimeDuration, max: TimeDuration) {
    this._min = min;
    this._max = max;
  }

  covered(duration: TimeDuration, { includeMin = true, includeMax = true } = {}): boolean {
    let left = false;
    let right = false;
    if (includeMin) {
      left = duration.milliseconds >= this._min.milliseconds;
    } else {
      left = duration.milliseconds > this._min.milliseconds;
    }
    if (includeMax) {
      right = duration.milliseconds <= this._max.milliseconds;
    } else {
      right = duration.milliseconds < this._max.milliseconds;
    }
    return left && right;
  }

  toString(): string {
    return `${this._min} - ${this._max}`;
  }
}
