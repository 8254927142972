import { XYWH } from '~/ui-layer/service-map/coordinates/types';

/**
 * This adds top, right, bottom and left margin to a box XYWH
 *
 * @param xywh the boxes to which it adds margin
 * @param t top
 * @param r right
 * @param b bottom
 * @param l left
 * @returns a new XYWH box
 */
export function addMargin(xywh: XYWH, t: number, r?: number, b?: number, l?: number): XYWH {
  let effR, effB, effL;

  if (r == null) {
    effL = t;
    effR = t;
    effB = t;
  } else if (b == null) {
    effB = t;
    effL = r;
    effR = r;
  } else if (l == null) {
    effB = b;
    effL = r;
    effR = r;
  } else {
    effB = b;
    effL = l;
    effR = r;
  }

  const x = xywh.x - effL;
  const y = xywh.y - t;
  const w = xywh.w + effL + effR;
  const h = xywh.h + t + effB;

  return { x, y, w, h };
}
