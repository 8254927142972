import { makeAutoObservable, reaction, runInAction } from 'mobx';

import {
  AccessPointArrow,
  Arrow,
  CardInfo,
  CardInfoOther,
  CardInfoService,
  CardType,
  CombinedAccessPointArrow,
  ContainerCardInfo,
} from '~/components/ClusterMap/types';
import { Verdict } from '~/domain/hubble';
import { ServiceCard } from '~/domain/service-map';
import { ClusterNamespaceStore } from '~/store/stores/cluster-namespace';
import { ConnectionsMapStore } from '~/store/stores/connections-map';
import * as trafficIcons from '~/ui/icons/traffic';
import { sizes } from '~/ui/vars';
import { Controls } from '~/ui-layer/controls';
import { ServiceMap } from '~/ui-layer/service-map';
import { addMargin } from '~/ui-layer/service-map/coordinates/add-margin';
import { ServiceMapArrow } from '~/ui-layer/service-map/coordinates/arrow';
import { buildArrowPointsFor } from '~/ui-layer/service-map/coordinates/build-arrow-points-for';
import { getBoundingBox } from '~/ui-layer/service-map/coordinates/get-bounding-box';
import {
  ForceBasedPlacement,
  getBottomPlacements,
  getColumnBasedPlacement,
  getLeftPlacements,
  getRightPlacements,
  getTopPlacements,
  PlacementKind,
  PlacementMeta,
} from '~/ui-layer/service-map/coordinates/get-placement';
import {
  ConnectorCoordsAccumulator,
  createCardOffsetAdvancers,
} from '~/ui-layer/service-map/coordinates/helpers';
import { XYWH } from '~/ui-layer/service-map/coordinates/types';
import { MapUtils } from '~/utils/iter-tools/map';
import { Connection } from '~backend/proto/timescape/map/v1/map_pb';

const cardSize = {
  w: 500,
  h: 80,
};
const selectedClusterCardSize = {
  w: 800,
  h: 400,
};
const emptyContainerCardSize = {
  w: 1320,
  h: 500,
};
const placementMargin = {
  l: cardSize.w + 50,
  b: 30,
  r: 50,
};

const containerMargin = {
  l: 100,
  t: 140,
  r: 100,
  b: 100,
};

const duckFeetMargin = 50;
const duckFeetSize = duckFeetMargin + 35;

enum Level {
  Cluster = 'cluster',
  Namespace = 'namespace',
  Service = 'service',
}
const exitingDuration = 200;
const serviceChangeExtraDuration = 1000;

let _computeClustersPositionAbortController: AbortController | null = null;
let _computeNamespacePositionAbortController: AbortController | null = null;

function getClusterType(name: string) {
  switch (name) {
    case 'reserved:world':
      return CardType.World;

    case 'reserved:host':
      return CardType.Host;
    default:
      return CardType.Cluster;
  }
}

function isClickable(cardType: CardType) {
  return cardType !== CardType.World && cardType !== CardType.Host;
}

type Maybe<D> = D | null | undefined;

function ensureUniqueArrows<D extends { id: string; points: any[] }>(arrows: Maybe<D>[]) {
  const uniqueArrows: { [key: string]: D } = {};
  arrows.forEach(a => {
    if (!a) return;

    if (a.points.length > 1 && !uniqueArrows[a.id]) {
      uniqueArrows[a.id] = a;
    }
  });

  return Object.values(uniqueArrows);
}

function createNamespaceArrows(
  link: Connection,
  insideCards: CardInfoOther[],
  outsideCards?: CardInfoOther[],
) {
  const src =
    insideCards.find(c => c.id === `${link.src_namespace}-${link.src_cluster}`) ||
    outsideCards?.find(c => c.id === link.src_cluster);
  const dst =
    insideCards.find(c => c.id === `${link.dst_namespace}-${link.dst_cluster}`) ||
    outsideCards?.find(c => c.id === link.dst_cluster);

  if (!src || !dst) return null;

  const points = buildArrowPointsFor(
    { x: src.x + src.w, y: src.y + src.h / 2 },
    { x: dst.x - duckFeetMargin, y: dst.y + dst.h / 2 },
    { ...src, x: src.x - duckFeetMargin, w: src.w + duckFeetMargin },
    { ...dst, x: dst.x - duckFeetMargin, w: dst.w + duckFeetMargin },
    createCardOffsetAdvancers(),
  );

  return {
    id: `namespace-${src.type}-${src.id}-${dst.type}-${dst.id}`,
    points,
    dst,
    src,
    verdicts: link.verdicts,
  };
}

export class ClusterMapStore {
  constructor(
    private _clusterNamespaces: ClusterNamespaceStore,
    private _connectionsMap: ConnectionsMapStore,
    private _serviceMap: ServiceMap,
    private _controls: Controls,
    private _isDarkTheme: boolean,
  ) {
    makeAutoObservable(this);
    reaction(
      () => this._serviceMap.isTimescapeFlowStatsLoading,
      isLoadingData => {
        if (isLoadingData) {
          runInAction(() => {
            this._isLoadingData = true;
          });
        } else {
          if (Object.keys(this._connectionsMap.map?.clusters ?? {}).length > 0) {
            runInAction(() => {
              this._isLoadingData = false;
            });
          } else {
            setTimeout(() => {
              runInAction(() => {
                this._isLoadingData = false;
              });
            }, 200);
          }
        }
      },
    );

    reaction(
      () => ({
        level: this._level,
        map: this._connectionsMap.map,
      }),
      () => {
        if (this._level === Level.Cluster) {
          this.computeClustersPosition();
        } else if (this._level === Level.Namespace) {
          this.computeNamespacesPosition();
        } else {
          this._initialComputationDone = true;
        }
      },
      { fireImmediately: true },
    );

    reaction(
      () => ({
        level: this._level,
        selectedNamespace: this.selectedNamespace,
      }),
      (args, prevArgs) => {
        if (this._level === Level.Service) {
          runInAction(() => {
            this._servicesPositionComputed = args.selectedNamespace === prevArgs?.selectedNamespace;
          });
          setTimeout(
            () =>
              runInAction(() => {
                this._servicesPositionComputed = true;
              }),
            500,
          );
        } else {
          runInAction(() => {
            this._servicesPositionComputed = false;
          });
        }
      },
      { fireImmediately: true },
    );

    reaction(
      () => ({
        selectedNamespace: this.selectedNamespace,
        services: this._serviceMap.placement.cardsPlacement,
      }),
      (args, prevArgs) => {
        // If namespace changed, reset services list:
        if (args.selectedNamespace !== prevArgs?.selectedNamespace) {
          this._servicesList = [];
          this._serviceCardsList = [];
        }
        this._computeServicesList();
      },
      { fireImmediately: true },
    );
  }

  get selectedCluster() {
    return this._clusterNamespaces?.currCluster;
  }

  get selectedNamespace() {
    return this._clusterNamespaces?.currNamespace;
  }

  get selectedService() {
    return [...this._serviceMap.placement.cardsPlacement.values()].find(a =>
      this._serviceMap.isCardActive(a.card),
    )?.card.service.name;
  }

  private _servicesList: { cardId: string; serviceName: string }[] = [];
  private _serviceCardsList: ServiceCard[] = [];

  /**
   * Whenever a namespace is selected, this function is called to capture
   * the services list. Note that actual service list when filters are applied
   * are not capturing all services, and therefore we only adds services in the list.
   */
  private _computeServicesList() {
    this._serviceMap.placement.cardsPlacement.forEach(card => {
      if (
        card.card.namespace === this.selectedNamespace &&
        !this._serviceMap.isCardActive(card.card) &&
        !this._servicesList.find(s => s.cardId === card.card.id)
      ) {
        this._servicesList.push({ serviceName: card.card.service.name, cardId: card.card.id });
        this._serviceCardsList.push(card.card);
      }
    });
  }

  get servicesList() {
    return this._servicesList;
  }

  private _clustersPositionComputed = false;
  private _isLoadingData = false;
  private _extraLoadingTime = false;
  private _namespacesPositionComputed = false;
  private _exiting = false;
  private highlightedCard: string | null = null;

  public get exiting() {
    return this._exiting;
  }

  private get _level() {
    if (
      this._clusterNamespaces?.currCluster !== null &&
      this._clusterNamespaces?.currNamespace !== null
    ) {
      return Level.Service;
    } else if (
      this._clusterNamespaces?.currCluster !== null &&
      this._clusterNamespaces?.currNamespace === null
    ) {
      return Level.Namespace;
    } else {
      return Level.Cluster;
    }
  }

  get hasNoData() {
    return this._clusterCards.length === 0 && this._level === Level.Cluster && !this.loading;
  }

  /**
   * This variable is set to true after any computation done,
   * weather it's namespaces, services or clusters cards computation done.
   */
  private _initialComputationDone = false;

  /**
   * This drives weather a global loading spinner should be shown or not.
   */
  get loading() {
    if (this._isLoadingData || this._extraLoadingTime) return true;

    return (
      !this._initialComputationDone ||
      (this._level === Level.Cluster && !this._clustersPositionComputed)
    );
  }

  /**
   * Cards to render. They represent either or a combination of clusters, namespaces, services,
   * world(s), host(s) cards.
   */
  get cards(): CardInfo[] {
    if (this._level === Level.Service) {
      return this._servicesCards;
    } else if (this._level === Level.Namespace && this._namespacesPositionComputed) {
      return [this._outsideNamespaceClustersCards, this._insideNamespacesCards].flat();
    } else {
      return this._clusterCards;
    }
  }

  /**
   * Arrows to render between cards.
   */
  get arrows(): Arrow[] {
    if (this._level === Level.Service) {
      return this._servicesArrows;
    } else if (this._level === Level.Namespace) {
      return this._namespacesArrows;
    } else {
      return this._clustersArrows;
    }
  }

  /**
   * Optional duck feet.
   */
  get combinedAccessPointArrows(): CombinedAccessPointArrow[] {
    if (this._level === Level.Service) {
      return this._servicesCombinedAccessPointArrows;
    } else if (this._level === Level.Namespace) {
      return this._namespacesCombinedAccessPointArrows;
    } else {
      return this._clusterCombinedAccessPointArrows;
    }
  }

  //----------------------
  // Clusters
  //----------------------

  /**
   * Store cluster placements to avoid to recompute.
   */
  private _clusterPlacements: ForceBasedPlacement[] = [];

  private get _clusterCards(): CardInfoOther[] {
    return (
      this._clusterPlacements
        .map(placement => {
          const selected = this.selectedCluster === placement.id;
          const deltaH = selected ? 0 : (selectedClusterCardSize.h - cardSize.h) / 2;
          const deltaW = selected ? 0 : (selectedClusterCardSize.w - cardSize.w) / 2;
          const cardType = getClusterType(placement.id);

          const incomingVerdicts: Verdict[] = this._connectionsMap.map.connections
            .filter(l => l.dst_cluster === placement.id)
            .map(l => l.verdicts)
            .flat() as any[]; // Force type Verdict from backend/proto/flow/flow_pb to be src/domain/hubble

          return {
            id: placement.id,
            x: placement.x - deltaW,
            y: placement.y - deltaH,
            ...(selected ? selectedClusterCardSize : cardSize),
            name: placement.id,
            loading: selected && !this._namespacesPositionComputed,
            onCardClick: isClickable(cardType)
              ? () => {
                  this._exiting = true;
                  setTimeout(() => {
                    this._extraLoadingTime = false;
                  }, exitingDuration + 1000);
                  setTimeout(() => {
                    this._exiting = false;
                    this._extraLoadingTime = true;
                    this._controls.clusterNamespaceChanged(placement.id, null);
                  }, exitingDuration);
                }
              : null,
            onMouseEnter: () => {
              runInAction(() => {
                this.highlightedCard = placement.id;
              });
            },
            onMouseLeave: () => {
              runInAction(() => {
                this.highlightedCard = null;
              });
            },
            outside: true,
            verdictIcon:
              incomingVerdicts.length > 0
                ? trafficIcons.iconByVerdicts(new Set(incomingVerdicts), this._isDarkTheme)
                : null,
            type: cardType,
          } as CardInfoOther;
        })
        .filter((card): card is CardInfoOther => card !== null)
        // sort cards so loading elements renders on top:
        .sort((a, b) => (b.loading ? -1 : a.loading ? 1 : 0))
    );
  }

  private get _clustersArrows() {
    return ensureUniqueArrows(
      this._connectionsMap.map.connections.map(conn => {
        const src = this._clusterCards.find(c => c.id === conn.src_cluster);
        const dst = this._clusterCards.find(c => c.id === conn.dst_cluster);

        if (!src || !dst) return;

        const points = buildArrowPointsFor(
          { x: src.x + src.w, y: src.y + src.h / 2 },
          { x: dst.x - duckFeetMargin, y: dst.y + dst.h / 2 },
          { ...src, x: src.x - duckFeetMargin, w: src.w + duckFeetMargin },
          { ...dst, x: dst.x - duckFeetMargin, w: dst.w + duckFeetMargin },
          createCardOffsetAdvancers(),
        );

        return {
          id: `cluster-${src.id}-${dst.id}${this.selectedCluster ? '-selected' : ''}`,
          highlighted: this.highlightedCard === src.id || this.highlightedCard === dst.id,
          points,
        };
      }),
    );
  }

  private get _clusterCombinedAccessPointArrows() {
    const arrows: CombinedAccessPointArrow[] = [];

    this._connectionsMap.map.connections.forEach(conn => {
      const src = this._clusterCards.find(c => c.id === conn.src_cluster);
      const dst = this._clusterCards.find(c => c.id === conn.dst_cluster);
      if (!src || !dst) return;

      const id = `cluster-duck-feet-${src.id}-${dst.id}`;
      if (arrows.find(a => a.id === id)) return;

      arrows.push({
        id,
        highlighted: this.highlightedCard === src.id || this.highlightedCard === dst.id,
        combinedArrows: [
          {
            id,
            start: { x: dst.x - duckFeetMargin, y: dst.y + dst.h / 2 },
            end: { x: dst.x + -duckFeetMargin + duckFeetSize, y: dst.y + dst.h / 2 },
            verdicts: new Set(conn.verdicts as any),
          } as AccessPointArrow,
        ],
      });
    });

    return arrows;
  }

  /**
   * Whenever clustersList or clusterLinks changes, this function
   * recomputes the clusters position.
   */
  async computeClustersPosition() {
    if (_computeClustersPositionAbortController) {
      _computeClustersPositionAbortController.abort();
    }

    _computeClustersPositionAbortController = new AbortController();
    const signal = _computeClustersPositionAbortController.signal;

    // Delay to accumulate all changes:
    await new Promise(resolve => setTimeout(resolve, 10));
    if (signal && signal.aborted) return;

    runInAction(() => {
      this._clustersPositionComputed = false;
    });

    const groups: Map<PlacementKind, PlacementMeta<{ id: string }>[]> = new Map();
    Object.keys(this._connectionsMap.map.clusters ?? {}).forEach(cluster => {
      const incomingsCount = this._connectionsMap.map.connections.filter(
        l => l.dst_cluster === cluster,
      ).length;

      const outgoingsCount = this._connectionsMap.map.connections.filter(
        l => l.src_cluster === cluster,
      ).length;

      const weight = -incomingsCount + outgoingsCount;

      const meta: PlacementMeta<{ id: string }> = {
        weight,
        card: { id: cluster },
        kind:
          incomingsCount + outgoingsCount > 0
            ? PlacementKind.InsideWithConnections
            : PlacementKind.InsideWithoutConnections,
      };
      const kindSet = groups.get(meta.kind) ?? [];
      const cards = [...kindSet, meta];
      groups.set(meta.kind, cards);
    });

    try {
      const placements = getColumnBasedPlacement({
        groups,
        cardsDimensions: new Map(
          Object.keys(this._connectionsMap.map.clusters ?? {}).map(c => [c, cardSize]),
        ),
      });
      if (signal && signal.aborted) return;
      runInAction(() => {
        this._clusterPlacements = Array.from(placements, ([name, value]) => ({
          id: name,
          ...value.geometry,
        }));
        this._clustersPositionComputed = true;
        this._initialComputationDone = true;
      });
    } catch (error) {
      if (signal && signal.aborted) return;
      runInAction(() => {
        this._clustersPositionComputed = false;
        // Handle error
      });
    }
  }

  //----------------------
  // Namespaces
  //----------------------

  private _insideNamespacePlacements: ForceBasedPlacement[] = [];
  private get _insideNamespaces() {
    return this.selectedCluster != null &&
      this._connectionsMap.map?.clusters?.[this.selectedCluster]
      ? Object.keys(this._connectionsMap.map.clusters[this.selectedCluster]?.namespaces)
      : [];
  }

  private get _namespacesInsideLinks() {
    return this._connectionsMap.map.connections.filter(
      conn =>
        conn.src_cluster === this.selectedCluster && conn.dst_cluster === this.selectedCluster,
    );
  }

  private get _namespacesOutsideLinks() {
    const selectedNamespacesLinks = this._connectionsMap.map.connections.filter(
      conn =>
        conn.src_cluster !== this.selectedCluster || conn.dst_cluster !== this.selectedCluster,
    );
    const namespacesOutsideLinks: Connection[] = [];

    selectedNamespacesLinks.forEach(l => {
      // Not external:
      if (this._namespacesInsideLinks.indexOf(l) > -1) return;

      namespacesOutsideLinks.push(l);
    });

    return namespacesOutsideLinks;
  }

  private get _outsideNamespaceClusters() {
    const allClusters = [
      ...new Set(
        this._namespacesOutsideLinks
          .map(n => n.dst_cluster)
          .concat(this._namespacesOutsideLinks.map(n => n.src_cluster)),
      ),
    ];

    return allClusters.filter(c => c !== this.selectedCluster);
  }

  private get _insideNamespacesCards() {
    return this._insideNamespacePlacements
      .map(placement => {
        const namespace = this._insideNamespaces.find(
          namespace => `${namespace}-${this.selectedCluster}` === placement.id,
        );
        if (!namespace) return null;

        const incomingVerdicts: Verdict[] = this._connectionsMap.map.connections
          .filter(l => `${l.dst_namespace}-${l.dst_cluster}` === placement.id)
          .map(l => l.verdicts)
          .flat() as any[]; // Force type Verdict from backend/proto/flow/flow_pb to be src/domain/hubble

        return {
          ...placement,
          ...cardSize,
          name: namespace,
          type: CardType.Namespace,
          outside: false,
          onCardClick: () => {
            this._exiting = true;
            setTimeout(() => {
              this._exiting = false;
              this._controls.clusterNamespaceChanged(this.selectedCluster, namespace);
            }, exitingDuration);
          },
          onMouseEnter: () => {
            runInAction(() => {
              this.highlightedCard = placement.id;
            });
          },
          onMouseLeave: () => {
            runInAction(() => {
              this.highlightedCard = null;
            });
          },
          verdictIcon:
            incomingVerdicts.length > 0
              ? trafficIcons.iconByVerdicts(new Set(incomingVerdicts), this._isDarkTheme)
              : null,
        } as CardInfoOther;
      })
      .filter((card): card is CardInfoOther => card !== null);
  }

  private get _namespaceContainerCardCoords(): XYWH | null {
    if (this._insideNamespacePlacements.length === 0 || !this._namespacesPositionComputed) {
      return { x: 0, y: 0, ...emptyContainerCardSize };
    }

    const bbox = getBoundingBox(
      this._insideNamespacePlacements
        .map(p => ({ x: p.x, y: p.y, ...cardSize }))
        .concat(
          this._insideArrows.map(a => a.points.map(p => ({ x: p.x, y: p.y, w: 5, h: 5 }))).flat(),
        ),
    );
    if (!bbox) return null;

    const { t, r, b, l } = containerMargin;
    return addMargin(bbox, t, r, b, l);
  }

  private get _outsideNamespacePlacements() {
    if (!this._namespaceContainerCardCoords) return [];

    const world = this._outsideNamespaceClusters.find(o => o === 'reserved:world');
    const host = this._outsideNamespaceClusters.find(o => o === 'reserved:host');

    const numberOfPlacements =
      this._outsideNamespaceClusters.length - (world ? 1 : 0) - (host ? 1 : 0);

    const nbPlacementRight = Math.floor((numberOfPlacements / 12) * 2);
    const nbPlacementBottom = Math.ceil((numberOfPlacements / 12) * 9);
    const nbPlacementLeft =
      Math.max(0, numberOfPlacements - nbPlacementRight - nbPlacementBottom) + (host ? 1 : 0);

    const rightPlacements = getRightPlacements(
      this._namespaceContainerCardCoords,
      nbPlacementRight,
      cardSize.h,
      placementMargin.r,
    );
    const leftPlacements = getLeftPlacements(
      this._namespaceContainerCardCoords,
      nbPlacementLeft,
      cardSize.h,
      placementMargin.l,
    );
    const bottomPlacements = getBottomPlacements(
      this._namespaceContainerCardCoords,
      nbPlacementBottom,
      cardSize.h,
      placementMargin.l,
    );

    return [...leftPlacements, ...rightPlacements, ...bottomPlacements];
  }

  private get _outsideNamespaceClustersCards() {
    if (!this._namespaceContainerCardCoords) return [];

    // This array of clusters has first the host, then the others:
    const outsideNamespaceClusters = this._outsideNamespaceClusters
      .filter(o => o !== 'reserved:world')
      .sort((a, b) => (a === 'reserved:host' ? -1 : b === 'reserved:host' ? 1 : 0));

    const world = this._outsideNamespaceClusters.find(o => o === 'reserved:world');

    const outsideClusters = this._outsideNamespacePlacements.map((placement, i) => {
      const incomingVerdicts: Verdict[] = this._namespacesOutsideLinks
        .filter(l => l.dst_cluster === outsideNamespaceClusters[i]!)
        .map(l => l.verdicts)
        .flat() as any; // Force type Verdict from backend/proto/flow/flow_pb to be src/domain/hubble

      const cardType = getClusterType(outsideNamespaceClusters[i]!);

      return {
        ...placement,
        ...cardSize,
        id: outsideNamespaceClusters[i]!,
        name: outsideNamespaceClusters[i]!,
        verdictIcon:
          incomingVerdicts.length > 0
            ? trafficIcons.iconByVerdicts(new Set(incomingVerdicts), this._isDarkTheme)
            : null,
        type: cardType,
        outside: true,
        onCardClick: isClickable(cardType)
          ? () => {
              this._exiting = true;
              setTimeout(() => {
                this._exiting = false;
                this._controls.clusterNamespaceChanged(outsideNamespaceClusters[i]!, null);
              }, exitingDuration);
            }
          : null,
        onMouseEnter: () => {
          runInAction(() => {
            this.highlightedCard = outsideNamespaceClusters[i]!;
          });
        },
        onMouseLeave: () => {
          runInAction(() => {
            this.highlightedCard = null;
          });
        },
      } as CardInfoOther;
    });

    if (world) {
      const worldPlacement = getTopPlacements(this._namespaceContainerCardCoords, 1, 0, 200)[0];

      const incomingVerdicts: Verdict[] = this._namespacesOutsideLinks
        .filter(l => l.dst_cluster === world!)
        .map(l => l.verdicts)
        .flat() as any;

      outsideClusters.push({
        ...worldPlacement,
        ...cardSize,
        id: world,
        name: world,
        outside: true,
        verdictIcon:
          incomingVerdicts.length > 0
            ? trafficIcons.iconByVerdicts(new Set(incomingVerdicts), this._isDarkTheme)
            : null,
        type: CardType.World,
        onMouseEnter: () => {
          runInAction(() => {
            this.highlightedCard = world;
          });
        },
        onMouseLeave: () => {
          runInAction(() => {
            this.highlightedCard = null;
          });
        },
      } as CardInfoOther);
    }

    return outsideClusters;
  }

  private async computeNamespacesPosition() {
    if (_computeNamespacePositionAbortController) {
      _computeNamespacePositionAbortController.abort();
    }

    _computeNamespacePositionAbortController = new AbortController();
    const signal = _computeNamespacePositionAbortController.signal;

    // Delay to accumulate all changes:
    await new Promise(resolve => setTimeout(resolve, 10));
    if (signal && signal.aborted) return;

    runInAction(() => {
      this._namespacesPositionComputed = false;
    });
    const groups: Map<PlacementKind, PlacementMeta<{ id: string }>[]> = new Map();
    this._insideNamespaces.forEach(namespace => {
      const incomingsCount = this._connectionsMap.map.connections.filter(
        c => c.dst_cluster === this.selectedCluster && c.dst_namespace === namespace,
      ).length;

      const outgoingsCount = this._connectionsMap.map.connections.filter(
        c => c.src_cluster === this.selectedCluster && c.src_namespace === namespace,
      ).length;

      const weight = -incomingsCount + outgoingsCount;

      const meta: PlacementMeta<{ id: string }> = {
        weight,
        card: { id: `${namespace}-${this.selectedCluster}` },
        kind:
          incomingsCount + outgoingsCount > 0
            ? PlacementKind.InsideWithConnections
            : PlacementKind.InsideWithoutConnections,
      };
      const kindSet = groups.get(meta.kind) ?? [];
      const cards = [...kindSet, meta];
      groups.set(meta.kind, cards);
    });
    try {
      const placements = getColumnBasedPlacement({
        groups,
        cardsDimensions: new Map(this._insideNamespaces.map(namespace => [namespace, cardSize])),
      });
      if (signal && signal.aborted) return;
      runInAction(() => {
        this._insideNamespacePlacements = Array.from(placements, ([name, value]) => ({
          id: name,
          ...value.geometry,
        }));
        this._namespacesPositionComputed = true;
        this._initialComputationDone = true;
      });
    } catch (error) {
      if (signal && signal.aborted) return;
      runInAction(() => {
        this._namespacesPositionComputed = false;
        // Handle error
      });
    }
  }

  private get _insideArrows() {
    return ensureUniqueArrows(
      this._namespacesInsideLinks.map(link =>
        createNamespaceArrows(link, this._insideNamespacesCards),
      ),
    );
  }

  private get _outsideArrows() {
    return ensureUniqueArrows(
      this._namespacesOutsideLinks.map(link =>
        createNamespaceArrows(
          link,
          this._insideNamespacesCards,
          this._outsideNamespaceClustersCards,
        ),
      ),
    );
  }

  private get _namespacesArrows() {
    return [this._outsideArrows, this._insideArrows].flat().map(a => ({
      ...a,
      highlighted: this.highlightedCard === a.src.id || this.highlightedCard === a.dst.id,
    }));
  }

  private get _namespacesCombinedAccessPointArrows(): CombinedAccessPointArrow[] {
    const combined: CombinedAccessPointArrow[] = [];

    this._namespacesArrows.forEach(arrow => {
      combined.push({
        id: `${arrow.id}-duck-feet`,
        highlighted: arrow.highlighted,
        combinedArrows: [
          {
            id: `${arrow.id}-duck-feet`,
            start: arrow.points[arrow.points.length - 1],
            end: {
              x: arrow.points[arrow.points.length - 1].x + duckFeetSize,
              y: arrow.points[arrow.points.length - 1].y,
            },
            verdicts: new Set(arrow.verdicts as any),
          },
        ],
      });
    });

    return combined;
  }

  //----------------------
  // Service
  //----------------------

  private _servicesPositionComputed = false;

  private get _servicesCards() {
    return [...this._serviceMap.placement.cardsPlacement.entries()]
      .map(([cardId, m]) => {
        const coords = m.geometry;
        if (!coords) return null;

        if (!m.card.isWorld) {
          coords.y += 200;
        }

        if (!m.card.isHost) {
          coords.x += 200;
        }

        return {
          type: 'service',
          outside:
            m.card.namespace !== this.selectedNamespace ||
            m.card.clusterName !== this.selectedCluster,
          id: cardId,
          onCardClick: () => {
            runInAction(() => {
              this._exiting = true;
            });
            setTimeout(() => {
              const nextCluster =
                m.card.cluster && m.card.cluster !== this._clusterNamespaces.currCluster
                  ? m.card.cluster
                  : this._clusterNamespaces.currCluster;
              const nextNamespace =
                m.card.namespace && m.card.namespace !== this._clusterNamespaces.currNamespace
                  ? m.card.namespace
                  : this._clusterNamespaces.currNamespace;
              this._controls.clusterNamespaceChanged(nextCluster, nextNamespace);
              this._serviceMap.onCardSelect(m.card);
            }, exitingDuration);
            setTimeout(() => {
              runInAction(() => {
                this._exiting = false;
              });
            }, exitingDuration + 1200);
          },
          onMouseEnter: () => {
            runInAction(() => {
              this.highlightedCard = cardId;
            });
          },
          onMouseLeave: () => {
            runInAction(() => {
              this.highlightedCard = null;
            });
          },
          card: m.card,
          ...coords,
        };
      })
      .filter(a => !!a) as CardInfoService[];
  }

  private get _servicesContainerCardCoords(): XYWH | null {
    if (this._servicesPositionComputed && this._serviceMap.placement.cardsPlacement.size === 0) {
      return { x: 0, y: 0, ...emptyContainerCardSize };
    }

    const bbox = getBoundingBox(
      this._servicesCards.filter(
        a => !a.card.isWorld && a.card.namespace === this.selectedNamespace,
      ),
    );
    if (!bbox) return null;

    const { t, r, b, l } = containerMargin;
    const containerCardCoords = addMargin(bbox, t, r, b, l);

    if (containerCardCoords.w < emptyContainerCardSize.w) {
      containerCardCoords.x -= (emptyContainerCardSize.w - containerCardCoords.w) / 2;
      containerCardCoords.w = emptyContainerCardSize.w;
    }

    return containerCardCoords;
  }

  private get _servicesMapArrows() {
    const arrows: Map<string, ServiceMapArrow> = new Map();

    // NOTE: Keep track of how many connectors a receiver has to properly
    // NOTE: compute vertical coordinate of next connector
    const cardConnectorCoords = new ConnectorCoordsAccumulator(
      this._serviceMap.arrows.connections,
      this._serviceMap.arrows.connectorMidPoints,
      this._serviceMap.placement,
    );

    this._serviceMap.arrows.connections.outgoings.forEach((receivers, senderId) => {
      const senderBBox = this._serviceMap.placement.cardsPlacement.get(senderId ?? '')?.geometry;
      if (senderBBox == null) return;

      receivers.forEach((receiverAccessPoints, receiverId) => {
        const receiverBBox = this._serviceMap.placement.cardsPlacement.get(
          receiverId ?? '',
        )?.geometry;
        if (receiverBBox == null) return;

        const receiverCard = this._serviceMap.arrows.services.byId(receiverId);
        if (receiverCard == null) return;
        // NOTE: Save sender/receiver bboxes to be able to construct path
        // NOTE: that walks around those bboxes later

        const arrow = ServiceMapArrow.new().from(senderId, senderBBox).to(receiverId, receiverBBox);

        arrows.set(arrow.id, arrow);

        const connector = cardConnectorCoords.accumulate(senderId, receiverId);
        if (connector == null) return;
        const { connectorId, connectorCoords } = connector;

        arrow
          .addPoint({
            x: senderBBox.x + senderBBox.w,
            y: senderBBox.y + sizes.arrowStartTopOffset,
          })
          .addPoint(connectorCoords);

        const receiverHttpEndpoints = this._serviceMap.placement.httpEndpointCoords.get(receiverId);
        const isReceiverActive = this.selectedService === receiverCard.service.name;

        const areHttpEndpointsHidden = receiverHttpEndpoints == null || !isReceiverActive;

        // NOTE: Here we build small arrows from card outer connector to
        // NOTE: endpoint connectors (points and http endpoints)
        receiverAccessPoints.forEach((link, apId) => {
          const coords = this._serviceMap.placement.accessPointCoords.get(apId);
          if (coords == null) return;

          arrow
            .addLinkThroughput(link.throughput)
            .addAccessPointArrow(connectorId, apId)
            .addVerdicts(link.verdicts)
            .addAuthTypes(link.authTypes)
            .setEncryption(link.isEncrypted)
            .addPoint(connectorCoords)
            .addPoint(coords);

          if (areHttpEndpointsHidden) return;

          receiverHttpEndpoints.forEach((methods, urlPath) => {
            methods.forEach((xy, method) => {
              const l7endpoint = this._serviceMap.arrows.interactions.getHttpEndpointByParts(
                receiverId,
                link.destinationPort,
                method,
                urlPath,
              );

              if (l7endpoint == null) {
                return;
              }

              arrow
                .addAccessPointArrow(connectorId, l7endpoint.id)
                .addVerdicts(l7endpoint.verdicts)
                .addPoint(connectorCoords)
                .addPoint(xy);
            });
          });
        });
      });
    });

    cardConnectorCoords.adjustVertically();
    const offsets = createCardOffsetAdvancers();

    arrows.forEach(arrow => {
      arrow.buildPointsAroundSenderAndReceiver(offsets);
      arrow.computeFlowsInfoIndicatorPosition();
    });
    return arrows;
  }

  private get _servicesCombinedAccessPointArrows(): CombinedAccessPointArrow[] {
    const combined: CombinedAccessPointArrow[] = [];

    const arrows = this._servicesMapArrows;

    if (!(MapUtils.pickFirst(arrows) instanceof ServiceMapArrow)) {
      return combined;
    }

    arrows.forEach(arrow => {
      arrow.accessPointArrows.forEach((apArrow, apArrowId) => {
        if (apArrow.connectorId == null) return;

        if (!combined.find(c => c.id === apArrow.connectorId)) {
          combined.push({
            id: apArrow.connectorId,
            highlighted:
              this.highlightedCard === arrow.senderId || this.highlightedCard === arrow.receiverId,
            combinedArrows: [],
          });
        }

        const connectorArrows = combined.find(c => c.id === apArrow.connectorId);
        if (connectorArrows == null) return;

        connectorArrows.highlighted =
          connectorArrows.highlighted ||
          this.highlightedCard === arrow.senderId ||
          this.highlightedCard === arrow.receiverId;

        // NOTE: apArrowId looks like `<connectorId> -> <apId>`
        const existing = connectorArrows.combinedArrows.find(ap => ap.id === apArrowId);
        if (existing == null) {
          connectorArrows.combinedArrows.push({
            id: apArrowId + arrow.id,
            start: apArrow.points[0],
            end: apArrow.points[1],
            verdicts: new Set(apArrow.verdicts as any),
          });
        }
      });
    });

    return combined;
  }

  private get _servicesArrows() {
    return ensureUniqueArrows(
      [...this._servicesMapArrows.values()].map(l => ({
        id: `service-service-${l.senderId}-service-${l.receiverId}`,
        highlighted: this.highlightedCard === l.senderId || this.highlightedCard === l.receiverId,
        points: l.points,
      })),
    );
  }

  //----------------------
  // Container card
  //----------------------

  get isContainerLoading() {
    if (this._level === Level.Service) {
      return !this._servicesPositionComputed;
    } else if (Level.Namespace === this._level) {
      return !this._namespacesPositionComputed;
    } else {
      return false;
    }
  }

  /**
   * Container card to render around services or namespaces.
   * When rendering clusters, the container is null.
   */
  get containerCard() {
    if (
      this._level === Level.Cluster ||
      !this._initialComputationDone ||
      (this._level === Level.Namespace && !this._namespaceContainerCardCoords) ||
      (this._level === Level.Service && !this._servicesContainerCardCoords)
    ) {
      return null;
    } else if (this._level === Level.Namespace) {
      return {
        ...this._namespaceContainerCardCoords,
        loading: this.isContainerLoading,
        selectedCluster: this.selectedCluster,
        empty: this._insideNamespaces.length === 0,
      } as ContainerCardInfo;
    } else {
      return {
        ...this._servicesContainerCardCoords,
        loading: this.isContainerLoading,
        selectedCluster: this.selectedCluster,
        selectedNamespace: this._clusterNamespaces.currNamespace,
        empty:
          this._level === Level.Service && this._serviceMap.placement.cardsPlacement.size === 0,
      } as ContainerCardInfo;
    }
  }

  //----------------------
  // Zoom bounding box
  //----------------------

  get zoomBBox(): XYWH | null {
    if (this._level === Level.Service) {
      if (this._servicesPositionComputed && this._serviceMap.placement.cardsPlacement.size === 0) {
        return this._servicesContainerCardCoords;
      }

      return getBoundingBox(
        this._servicesArrows.map(p => p.points.map(p => ({ ...p, h: 1, w: 1 }))).flat(),
      );
    } else if (this._level === Level.Namespace && this._namespaceContainerCardCoords) {
      return this._namespaceContainerCardCoords;
    } else {
      return getBoundingBox(this._clusterPlacements.map(p => ({ x: p.x, y: p.y, ...cardSize })));
    }
  }

  // Other

  onReturnClusterMapClick() {
    runInAction(() => {
      this._exiting = true;
    });
    setTimeout(() => {
      runInAction(() => {
        this._exiting = false;
      });
      this._controls.clusterNamespaceChanged(null, null);
    }, exitingDuration);
  }

  onReturnNamespaceMapClick() {
    runInAction(() => {
      this._exiting = true;
    });
    setTimeout(() => {
      runInAction(() => {
        this._exiting = false;
      });
      this._controls.clusterNamespaceChanged(this.selectedCluster, null);
    }, exitingDuration);
  }

  onReturnServiceMapClick() {
    runInAction(() => {
      this._exiting = true;
    });
    setTimeout(() => {
      this._controls.setFlowFilterGroups([]);
    }, exitingDuration);
    setTimeout(() => {
      runInAction(() => {
        this._exiting = false;
      });
    }, exitingDuration + serviceChangeExtraDuration); // add extra time to avoid flickering
  }

  onClusterChange(clusterId: string | null) {
    this._exiting = true;
    setTimeout(() => {
      this._extraLoadingTime = false;
    }, exitingDuration + 1200);
    setTimeout(() => {
      this._exiting = false;
      this._extraLoadingTime = true;
      this._controls.clusterNamespaceChanged(clusterId, null);
    }, exitingDuration);
  }

  onNamespaceChange(namespaceId: string | null) {
    runInAction(() => {
      this._exiting = true;
    });

    setTimeout(() => {
      this._controls.clusterNamespaceChanged(this.selectedCluster, namespaceId);
    }, exitingDuration);

    setTimeout(() => {
      runInAction(() => {
        this._exiting = false;
      });
    }, exitingDuration + 1200);
  }

  onServiceChange(cardId: string) {
    runInAction(() => {
      this._exiting = true;
    });
    setTimeout(() => {
      this._initialComputationDone = false;
      const card = this._serviceCardsList.find(c => c.id === cardId);
      if (card) {
        this._serviceMap.onCardSelect(card);
      }
    }, exitingDuration);
    setTimeout(() => {
      runInAction(() => {
        this._exiting = false;
        this._initialComputationDone = true;
      });
    }, exitingDuration + serviceChangeExtraDuration);
  }
}
