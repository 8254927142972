import React, { useCallback, useEffect, useRef } from 'react';

import * as d3 from 'd3';
import { observer } from 'mobx-react-lite';

import * as helpers from '~/components/ServiceMapArrowsRenderer/helpers';
import { XY } from '~/domain/geometry';
import { link } from '~/domain/helpers';
import { LinkThroughput } from '~/domain/hubble';
import { colors, sizes } from '~/ui/vars';

import css from './Handle.scss';

export type Props = {
  arrow: { id: string; flowsInfoIndicatorCoords?: XY | null };
  linkThroughput: LinkThroughput;
};

export const FlowsInfoHandle = observer(function FlowsInfoHandle(props: Props) {
  const flowsInfoHandleGroup = useRef<SVGGElement | null>(null);

  const renderHandle = useCallback((container: SVGGElement, coords: XY) => {
    const g = d3
      .select(container)
      .selectAll<SVGGElement, XY>('g')
      .data([props.linkThroughput], () => props.arrow.id);

    if (link.isDumbLinkThroughput(props.linkThroughput)) {
      g.remove();
      return;
    }

    g.selectAll<SVGCircleElement, LinkThroughput>('circle').call(self =>
      helpers.flowsInfoIndicator.setPosition(self, coords),
    );

    const enteredGroup = g.enter().append('g').attr('class', css.circles);
    enteredGroup
      .append('circle')
      .attr('class', css.outer)
      .attr('stroke', colors.throughputIndicatorStroke)
      .attr('stroke-width', sizes.throughputIndicatorWidth)
      .attr('fill', colors.throughputIndicatorFill)
      .attr('r', 10)
      .call(self => helpers.flowsInfoIndicator.setPosition(self, coords));

    enteredGroup
      .append('circle')
      .attr('class', css.dot)
      .attr('fill', colors.throughputIndicatorDot)
      .attr('r', sizes.throughputIndicatorDotRadius)
      .call(self => helpers.flowsInfoIndicator.setPosition(self, coords));

    g.exit().remove();
  }, []);

  useEffect(() => {
    if (flowsInfoHandleGroup.current == null) return;
    if (props.arrow.flowsInfoIndicatorCoords == null) return;

    renderHandle(flowsInfoHandleGroup.current, props.arrow.flowsInfoIndicatorCoords);
  }, [props.arrow.flowsInfoIndicatorCoords]);

  return <g className={css.flowsInfoIndicator} ref={flowsInfoHandleGroup}></g>;
});
