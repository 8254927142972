import { BackendAPI } from '~/api/customprotocol';
import { Store } from '~/store';

import { DataLayer } from './data-layer';

export class DataLayerBuilder {
  private ffTemplateSelector?: string;
  private authzTemplateSelector?: string;
  private ssrErrorsSelector?: string;
  private store?: Store;
  private cpBaseURL?: () => string;
  private cpReqTimeout?: number;
  private cpCORSEnabled?: boolean;
  private useJSON = false;

  public static new() {
    return new DataLayerBuilder();
  }

  public withFeatureFlagsSelector(sel?: string): this {
    this.ffTemplateSelector = sel;
    return this;
  }

  public withAuthorizationSelector(sel?: string): this {
    this.authzTemplateSelector = sel;
    return this;
  }

  public withSSRErrorsSelector(sel?: string): this {
    this.ssrErrorsSelector = sel;
    return this;
  }

  public withCustomProtocolBaseURL(fn?: () => string): this {
    this.cpBaseURL = fn;
    return this;
  }

  public withCustomProtocolRequestTimeout(t: number): this {
    this.cpReqTimeout = t;
    return this;
  }

  public withCustomProtocolMessagesInJSON(useJSON?: boolean): this {
    this.useJSON = !!useJSON;
    return this;
  }

  public withCustomProtocolCORSEnabled(enabled?: boolean): this {
    this.cpCORSEnabled = enabled;
    return this;
  }

  public withStore(s?: Store): this {
    this.store = s;
    return this;
  }

  public build(): DataLayer {
    if (this.store == null) throw this.err('Store');
    if (this.ffTemplateSelector == null) throw this.err('FeatureFlagsSelector');
    if (this.authzTemplateSelector == null) throw this.err('AuthozationSelector');
    if (this.ssrErrorsSelector == null) throw this.err('SSRErrorsSelector');
    if (!this.cpBaseURL) throw this.err('custom protocol baseURL');
    if (!this.cpReqTimeout) throw this.err('custom protocol request timeout');

    const backendAPI = new BackendAPI({
      baseURL: this.cpBaseURL(),
      requestTimeout: this.cpReqTimeout,
      cors: !!this.cpCORSEnabled,
      useJSON: !!this.useJSON,
    });

    return new DataLayer(
      this.store,
      this.ffTemplateSelector,
      this.authzTemplateSelector,
      this.ssrErrorsSelector,
      backendAPI,
    );
  }

  private err(comp: string): Error {
    return new Error(`Failed to build DataLayer: ${comp} is not set`);
  }
}
