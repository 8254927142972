/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter add_pb_suffix,eslint_disable,ts_nocheck,generate_dependencies,long_type_number,use_proto_field_name
// @generated from protobuf file "observer/observer.proto" (package "observer", syntax proto3)
// tslint:disable
// @ts-nocheck
//
// SPDX-License-Identifier: Apache-2.0
// Copyright Authors of Hubble
//
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { BoolValue } from "../google/protobuf/wrappers_pb";
import { Duration } from "../google/protobuf/duration_pb";
import { NodeState } from "../relay/relay_pb";
import { DebugEvent } from "../flow/flow_pb";
import { AgentEvent } from "../flow/flow_pb";
import { LostEvent } from "../flow/flow_pb";
import { NodeStatusEvent } from "../relay/relay_pb";
import { Flow } from "../flow/flow_pb";
import { FieldMask } from "../google/protobuf/field_mask_pb";
import { Any } from "../google/protobuf/any_pb";
import { Timestamp } from "../google/protobuf/timestamp_pb";
import { FlowFilter } from "../flow/flow_pb";
import { UInt32Value } from "../google/protobuf/wrappers_pb";
/**
 * @generated from protobuf message observer.ServerStatusRequest
 */
export interface ServerStatusRequest {
}
/**
 * @generated from protobuf message observer.ServerStatusResponse
 */
export interface ServerStatusResponse {
    /**
     * number of currently captured flows
     * In a multi-node context, this is the cumulative count of all captured
     * flows.
     *
     * @generated from protobuf field: uint64 num_flows = 1;
     */
    num_flows: number;
    /**
     * maximum capacity of the ring buffer
     * In a multi-node context, this is the aggregation of all ring buffers
     * capacities.
     *
     * @generated from protobuf field: uint64 max_flows = 2;
     */
    max_flows: number;
    /**
     * total amount of flows observed since the observer was started
     * In a multi-node context, this is the aggregation of all flows that have
     * been seen.
     *
     * @generated from protobuf field: uint64 seen_flows = 3;
     */
    seen_flows: number;
    /**
     * uptime of this observer instance in nanoseconds
     * In a multi-node context, this field corresponds to the uptime of the
     * longest living instance.
     *
     * @generated from protobuf field: uint64 uptime_ns = 4;
     */
    uptime_ns: number;
    /**
     * number of nodes for which a connection is established
     *
     * @generated from protobuf field: google.protobuf.UInt32Value num_connected_nodes = 5;
     */
    num_connected_nodes?: UInt32Value;
    /**
     * number of nodes for which a connection cannot be established
     *
     * @generated from protobuf field: google.protobuf.UInt32Value num_unavailable_nodes = 6;
     */
    num_unavailable_nodes?: UInt32Value;
    /**
     * list of nodes that are unavailable
     * This list may not be exhaustive.
     *
     * @generated from protobuf field: repeated string unavailable_nodes = 7;
     */
    unavailable_nodes: string[];
    /**
     * Version is the version of Cilium/Hubble.
     *
     * @generated from protobuf field: string version = 8;
     */
    version: string;
    /**
     * Approximate rate of flows seen by Hubble per second over the last minute.
     * In a multi-node context, this is the sum of all flows rates.
     *
     * @generated from protobuf field: double flows_rate = 9;
     */
    flows_rate: number;
}
/**
 * @generated from protobuf message observer.GetFlowsRequest
 */
export interface GetFlowsRequest {
    /**
     * Number of flows that should be returned. Incompatible with `since/until`.
     * Defaults to the most recent (last) `number` flows, unless `first` is
     * true, then it will return the earliest `number` flows.
     *
     * @generated from protobuf field: uint64 number = 1;
     */
    number: number;
    /**
     * first specifies if we should look at the first `number` flows or the
     * last `number` of flows. Incompatible with `follow`.
     *
     * @generated from protobuf field: bool first = 9;
     */
    first: boolean;
    /**
     * follow sets when the server should continue to stream flows after
     * printing the last N flows.
     *
     * @generated from protobuf field: bool follow = 3;
     */
    follow: boolean;
    /**
     * blacklist defines a list of filters which have to match for a flow to be
     * excluded from the result.
     * If multiple blacklist filters are specified, only one of them has to
     * match for a flow to be excluded.
     *
     * @generated from protobuf field: repeated flow.FlowFilter blacklist = 5;
     */
    blacklist: FlowFilter[];
    /**
     * whitelist defines a list of filters which have to match for a flow to be
     * included in the result.
     * If multiple whitelist filters are specified, only one of them has to
     * match for a flow to be included.
     * The whitelist and blacklist can both be specified. In such cases, the
     * set of the returned flows is the set difference `whitelist - blacklist`.
     * In other words, the result will contain all flows matched by the
     * whitelist that are not also simultaneously matched by the blacklist.
     *
     * @generated from protobuf field: repeated flow.FlowFilter whitelist = 6;
     */
    whitelist: FlowFilter[];
    /**
     * Since this time for returned flows. Incompatible with `number`.
     *
     * @generated from protobuf field: google.protobuf.Timestamp since = 7;
     */
    since?: Timestamp;
    /**
     * Until this time for returned flows. Incompatible with `number`.
     *
     * @generated from protobuf field: google.protobuf.Timestamp until = 8;
     */
    until?: Timestamp;
    /**
     * @generated from protobuf field: observer.GetFlowsRequest.Experimental experimental = 999;
     */
    experimental?: GetFlowsRequest_Experimental;
    /**
     * extensions can be used to add arbitrary additional metadata to GetFlowsRequest.
     * This can be used to extend functionality for other Hubble compatible
     * APIs, or experiment with new functionality without needing to change the public API.
     *
     * @generated from protobuf field: google.protobuf.Any extensions = 150000;
     */
    extensions?: Any;
    /**
     * Aggregation defines flow aggregation settings, determining how flows get
     * aggregated when queried.
     * Deprecated. Use the extensions field with the
     * isovalent.extensions.GetFlowsRequestExtension message type instead.
     *
     * @deprecated
     * @generated from protobuf field: observer.Aggregation aggregation = 10001 [deprecated = true];
     */
    aggregation?: Aggregation;
}
/**
 * Experimental contains fields that are not stable yet. Support for
 * experimental features is always optional and subject to change.
 *
 * @generated from protobuf message observer.GetFlowsRequest.Experimental
 */
export interface GetFlowsRequest_Experimental {
    /**
     * FieldMask allows clients to limit flow's fields that will be returned.
     * For example, {paths: ["source.id", "destination.id"]} will return flows
     * with only these two fields set.
     *
     * @generated from protobuf field: google.protobuf.FieldMask field_mask = 1;
     */
    field_mask?: FieldMask;
}
/**
 * GetFlowsResponse contains either a flow or a protocol message.
 *
 * @generated from protobuf message observer.GetFlowsResponse
 */
export interface GetFlowsResponse {
    /**
     * @generated from protobuf oneof: response_types
     */
    response_types: {
        oneofKind: "flow";
        /**
         * @generated from protobuf field: flow.Flow flow = 1;
         */
        flow: Flow;
    } | {
        oneofKind: "node_status";
        /**
         * node_status informs clients about the state of the nodes
         * participating in this particular GetFlows request.
         *
         * @generated from protobuf field: relay.NodeStatusEvent node_status = 2;
         */
        node_status: NodeStatusEvent;
    } | {
        oneofKind: "lost_events";
        /**
         * lost_events informs clients about events which got dropped due to
         * a Hubble component being unavailable
         *
         * @generated from protobuf field: flow.LostEvent lost_events = 3;
         */
        lost_events: LostEvent;
    } | {
        oneofKind: undefined;
    };
    /**
     * Name of the node where this event was observed.
     *
     * @generated from protobuf field: string node_name = 1000;
     */
    node_name: string;
    /**
     * Timestamp at which this event was observed.
     *
     * @generated from protobuf field: google.protobuf.Timestamp time = 1001;
     */
    time?: Timestamp;
}
/**
 * @generated from protobuf message observer.GetAgentEventsRequest
 */
export interface GetAgentEventsRequest {
    /**
     * Number of flows that should be returned. Incompatible with `since/until`.
     * Defaults to the most recent (last) `number` events, unless `first` is
     * true, then it will return the earliest `number` events.
     *
     * @generated from protobuf field: uint64 number = 1;
     */
    number: number;
    /**
     * first specifies if we should look at the first `number` events or the
     * last `number` of events. Incompatible with `follow`.
     *
     * @generated from protobuf field: bool first = 9;
     */
    first: boolean;
    /**
     * follow sets when the server should continue to stream agent events after
     * printing the last N agent events.
     *
     * @generated from protobuf field: bool follow = 2;
     */
    follow: boolean;
    // TODO: do we want to be able to specify blocklist/allowlist (previously
    // known as blacklist/whitelist)?

    /**
     * Since this time for returned agent events. Incompatible with `number`.
     *
     * @generated from protobuf field: google.protobuf.Timestamp since = 7;
     */
    since?: Timestamp;
    /**
     * Until this time for returned agent events. Incompatible with `number`.
     *
     * @generated from protobuf field: google.protobuf.Timestamp until = 8;
     */
    until?: Timestamp;
}
/**
 * GetAgentEventsResponse contains an event received from the Cilium agent.
 *
 * @generated from protobuf message observer.GetAgentEventsResponse
 */
export interface GetAgentEventsResponse {
    /**
     * @generated from protobuf field: flow.AgentEvent agent_event = 1;
     */
    agent_event?: AgentEvent;
    /**
     * Name of the node where this event was observed.
     *
     * @generated from protobuf field: string node_name = 1000;
     */
    node_name: string;
    /**
     * Timestamp at which this event was observed.
     *
     * @generated from protobuf field: google.protobuf.Timestamp time = 1001;
     */
    time?: Timestamp;
}
/**
 * @generated from protobuf message observer.GetDebugEventsRequest
 */
export interface GetDebugEventsRequest {
    /**
     * Number of events that should be returned. Incompatible with `since/until`.
     * Defaults to the most recent (last) `number` events, unless `first` is
     * true, then it will return the earliest `number` events.
     *
     * @generated from protobuf field: uint64 number = 1;
     */
    number: number;
    /**
     * first specifies if we should look at the first `number` events or the
     * last `number` of events. Incompatible with `follow`.
     *
     * @generated from protobuf field: bool first = 9;
     */
    first: boolean;
    /**
     * follow sets when the server should continue to stream debug events after
     * printing the last N debug events.
     *
     * @generated from protobuf field: bool follow = 2;
     */
    follow: boolean;
    // TODO: do we want to be able to specify blocklist/allowlist (previously
    // known as blacklist/whitelist)?

    /**
     * Since this time for returned debug events. Incompatible with `number`.
     *
     * @generated from protobuf field: google.protobuf.Timestamp since = 7;
     */
    since?: Timestamp;
    /**
     * Until this time for returned debug events. Incompatible with `number`.
     *
     * @generated from protobuf field: google.protobuf.Timestamp until = 8;
     */
    until?: Timestamp;
}
/**
 * GetDebugEventsResponse contains a Cilium datapath debug events.
 *
 * @generated from protobuf message observer.GetDebugEventsResponse
 */
export interface GetDebugEventsResponse {
    /**
     * @generated from protobuf field: flow.DebugEvent debug_event = 1;
     */
    debug_event?: DebugEvent;
    /**
     * Name of the node where this event was observed.
     *
     * @generated from protobuf field: string node_name = 1000;
     */
    node_name: string;
    /**
     * Timestamp at which this event was observed.
     *
     * @generated from protobuf field: google.protobuf.Timestamp time = 1001;
     */
    time?: Timestamp;
}
/**
 * @generated from protobuf message observer.GetNodesRequest
 */
export interface GetNodesRequest {
}
/**
 * GetNodesResponse contains the list of nodes.
 *
 * @generated from protobuf message observer.GetNodesResponse
 */
export interface GetNodesResponse {
    /**
     * Nodes is an exhaustive list of nodes.
     *
     * @generated from protobuf field: repeated observer.Node nodes = 1;
     */
    nodes: Node[];
}
/**
 * Node represents a cluster node.
 *
 * @generated from protobuf message observer.Node
 */
export interface Node {
    /**
     * Name is the name of the node.
     *
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * Version is the version of Cilium/Hubble as reported by the node.
     *
     * @generated from protobuf field: string version = 2;
     */
    version: string;
    /**
     * Address is the network address of the API endpoint.
     *
     * @generated from protobuf field: string address = 3;
     */
    address: string;
    /**
     * State represents the known state of the node.
     *
     * @generated from protobuf field: relay.NodeState state = 4;
     */
    state: NodeState;
    /**
     * TLS reports TLS related information.
     *
     * @generated from protobuf field: observer.TLS tls = 5;
     */
    tls?: TLS;
    /**
     * UptimeNS is the uptime of this instance in nanoseconds
     *
     * @generated from protobuf field: uint64 uptime_ns = 6;
     */
    uptime_ns: number;
    /**
     * number of currently captured flows
     *
     * @generated from protobuf field: uint64 num_flows = 7;
     */
    num_flows: number;
    /**
     * maximum capacity of the ring buffer
     *
     * @generated from protobuf field: uint64 max_flows = 8;
     */
    max_flows: number;
    /**
     * total amount of flows observed since the observer was started
     *
     * @generated from protobuf field: uint64 seen_flows = 9;
     */
    seen_flows: number;
}
/**
 * TLS represents TLS information.
 *
 * @generated from protobuf message observer.TLS
 */
export interface TLS {
    /**
     * Enabled reports whether TLS is enabled or not.
     *
     * @generated from protobuf field: bool enabled = 1;
     */
    enabled: boolean;
    /**
     * ServerName is the TLS server name that can be used as part of the TLS
     * cert validation process.
     *
     * @generated from protobuf field: string server_name = 2;
     */
    server_name: string;
}
/**
 * @generated from protobuf message observer.GetNamespacesRequest
 */
export interface GetNamespacesRequest {
}
/**
 * GetNamespacesResponse contains the list of namespaces.
 *
 * @generated from protobuf message observer.GetNamespacesResponse
 */
export interface GetNamespacesResponse {
    /**
     * Namespaces is a list of namespaces with flows
     *
     * @generated from protobuf field: repeated observer.Namespace namespaces = 1;
     */
    namespaces: Namespace[];
}
/**
 * @generated from protobuf message observer.Namespace
 */
export interface Namespace {
    /**
     * @generated from protobuf field: string cluster = 1;
     */
    cluster: string;
    /**
     * @generated from protobuf field: string namespace = 2;
     */
    namespace: string;
}
/**
 * ExportEvent contains an event to be exported. Not to be used outside of the
 * exporter feature.
 *
 * @generated from protobuf message observer.ExportEvent
 */
export interface ExportEvent {
    /**
     * @generated from protobuf oneof: response_types
     */
    response_types: {
        oneofKind: "flow";
        /**
         * @generated from protobuf field: flow.Flow flow = 1;
         */
        flow: Flow;
    } | {
        oneofKind: "node_status";
        /**
         * node_status informs clients about the state of the nodes
         * participating in this particular GetFlows request.
         *
         * @generated from protobuf field: relay.NodeStatusEvent node_status = 2;
         */
        node_status: NodeStatusEvent;
    } | {
        oneofKind: "lost_events";
        /**
         * lost_events informs clients about events which got dropped due to
         * a Hubble component being unavailable
         *
         * @generated from protobuf field: flow.LostEvent lost_events = 3;
         */
        lost_events: LostEvent;
    } | {
        oneofKind: "agent_event";
        /**
         * agent_event informs clients about an event received from the Cilium
         * agent.
         *
         * @generated from protobuf field: flow.AgentEvent agent_event = 4;
         */
        agent_event: AgentEvent;
    } | {
        oneofKind: "debug_event";
        /**
         * debug_event contains Cilium datapath debug events
         *
         * @generated from protobuf field: flow.DebugEvent debug_event = 5;
         */
        debug_event: DebugEvent;
    } | {
        oneofKind: undefined;
    };
    /**
     * Name of the node where this event was observed.
     *
     * @generated from protobuf field: string node_name = 1000;
     */
    node_name: string;
    /**
     * Timestamp at which this event was observed.
     *
     * @generated from protobuf field: google.protobuf.Timestamp time = 1001;
     */
    time?: Timestamp;
}
// Enterprise specific protobuf messages

/**
 * DirectionStatistics are flow statistics in a particular direction
 * Deprecated. Use isovalent.flow.aggregation.DirectionStatistics instead.
 *
 * @deprecated
 * @generated from protobuf message observer.DirectionStatistics
 */
export interface DirectionStatistics {
    /**
     * first_activity is the timestamp of first activity on the flow
     *
     * @generated from protobuf field: google.protobuf.Timestamp first_activity = 1;
     */
    first_activity?: Timestamp;
    /**
     * last_activity is the timestamp when activity was last observed
     *
     * @generated from protobuf field: google.protobuf.Timestamp last_activity = 2;
     */
    last_activity?: Timestamp;
    /**
     * num_flows is the number of flows aggregated together
     *
     * @generated from protobuf field: uint64 num_flows = 3;
     */
    num_flows: number;
    /**
     * bytes is the number of bytes observed on the flow
     *
     * @generated from protobuf field: uint64 bytes = 4;
     */
    bytes: number;
    /**
     * errors is the number of errors observed on the flow, e.g. RSTs or
     * HTTP 4xx 5xx status returns
     *
     * @generated from protobuf field: uint64 errors = 5;
     */
    errors: number;
    /**
     * ack_seen is true once a TCP ACK has been seen in this direction
     *
     * @generated from protobuf field: bool ack_seen = 6;
     */
    ack_seen: boolean;
    /**
     * connect_requests is the number of requests for new connections, i.e.
     * the number of SYNs seen
     *
     * @generated from protobuf field: uint64 connection_attempts = 7;
     */
    connection_attempts: number;
    /**
     * close_requests is the number of connection closure requests
     * received, i.e. the number of FINs seen
     *
     * @generated from protobuf field: uint64 close_requests = 8;
     */
    close_requests: number;
}
/**
 * FlowStatistics includes the statistics for a flow in both directions
 * Deprecated. Use isovalent.flow.aggregation.FlowStatistics instead.
 *
 * @deprecated
 * @generated from protobuf message observer.FlowStatistics
 */
export interface FlowStatistics {
    /**
     * forward represents flow statistics in the forward direction
     *
     * @generated from protobuf field: observer.DirectionStatistics forward = 1;
     */
    forward?: DirectionStatistics;
    /**
     * reply represents flow statistics in the reply direction
     *
     * @generated from protobuf field: observer.DirectionStatistics reply = 2;
     */
    reply?: DirectionStatistics;
    /**
     * established is set to true once the connection/flow is established
     *
     * @generated from protobuf field: bool established = 3;
     */
    established: boolean;
}
/**
 * Aggregator is an aggregator configuration
 * Deprecated. Use isovalent.flow.aggregation.Aggregator instead.
 *
 * @deprecated
 * @generated from protobuf message observer.Aggregator
 */
export interface Aggregator {
    /**
     * @generated from protobuf field: observer.AggregatorType type = 1;
     */
    type: AggregatorType;
    /**
     * Ignore source port during aggregation.
     *
     * @generated from protobuf field: bool ignore_source_port = 2;
     */
    ignore_source_port: boolean;
    /**
     * Specify the flow TTL for this aggregator. Defaults to 30 seconds.
     *
     * @generated from protobuf field: google.protobuf.Duration ttl = 3;
     */
    ttl?: Duration;
    /**
     * By default, the flow TTL gets renewed when there is an activity on a
     * given aggregation target (connection or identity). This means that flows
     * do not expire unless they remain inactive for the duration specified in
     * the ttl field. Set this flag to false to expire flows after their initial
     * TTLs regardless of whether there have been subsequent flows on their
     * aggregation targets.
     *
     * @generated from protobuf field: google.protobuf.BoolValue renew_ttl = 4;
     */
    renew_ttl?: BoolValue;
}
/**
 * Aggregation is a filter to define flow aggregation behavior
 * Deprecated. Use isovalent.flow.aggregation.Aggregation instead.
 *
 * @deprecated
 * @generated from protobuf message observer.Aggregation
 */
export interface Aggregation {
    /**
     * aggregators is a list of aggregators to apply on flows before
     * returning them. If multiple aggregator are defined, all of them are
     * applied in a row.
     *
     * @generated from protobuf field: repeated observer.Aggregator aggregators = 1;
     */
    aggregators: Aggregator[];
    /**
     * state_change_filter lists the state changes to consider when
     * determing to return an updated flow while aggregating
     *
     * @generated from protobuf field: observer.StateChange state_change_filter = 2;
     */
    state_change_filter: StateChange;
}
/**
 * Deprecated. Use isovalent.flow.aggregation.StateChange instead.
 *
 * @deprecated
 * @generated from protobuf enum observer.StateChange
 */
export enum StateChange {
    /**
     * unspec represents no change in state
     *
     * @generated from protobuf enum value: unspec = 0;
     */
    unspec = 0,
    /**
     * new indicates that the flow has been observed for the first time,
     * e.g. for connection aggregation, the first time a 5-tuple + verdict
     * + drop-reason has been observed.
     *
     * @generated from protobuf enum value: new = 1;
     */
    new = 1,
    /**
     * established indicates that the connection handshake has been
     * successful, i.e. for TCP this means that the 3-way handshake has
     * been successful. For any non-TCP protocol, the first flow in any
     * direction triggers established state.
     *
     * @generated from protobuf enum value: established = 2;
     */
    established = 2,
    /**
     * first_error indicates that an error has been observed on the flow
     * for the first time
     *
     * @generated from protobuf enum value: first_error = 4;
     */
    first_error = 4,
    /**
     * error indicates that the latest flow reported an error condition.
     * For TCP, this indicates that an RST has been observed.  For HTTP,
     * this indicates that a 4xx or 5xx status code has been observed.
     *
     * @generated from protobuf enum value: error = 8;
     */
    error = 8,
    /**
     * closed indicates closure of the connection, e.g. a TCP FIN has been
     * seen in both direction. For non-TCP, this state is never triggered.
     * This state is never reached for non-connection aggregation.
     *
     * @generated from protobuf enum value: closed = 16;
     */
    closed = 16,
    /**
     * first_reply indicates that a flow with is_reply set to true has been
     * observed on the flow for the first time.
     *
     * @generated from protobuf enum value: first_reply = 32;
     */
    first_reply = 32
}
/**
 * AggregatorType are all aggregator types
 * Deprecated. Use isovalent.flow.aggregation.AggregatorType instead.
 *
 * @deprecated
 * @generated from protobuf enum observer.AggregatorType
 */
export enum AggregatorType {
    /**
     * @generated from protobuf enum value: unknown = 0;
     */
    unknown = 0,
    /**
     * @generated from protobuf enum value: connection = 1;
     */
    connection = 1,
    /**
     * @generated from protobuf enum value: identity = 2;
     */
    identity = 2
}
// @generated message type with reflection information, may provide speed optimized methods
class ServerStatusRequest$Type extends MessageType<ServerStatusRequest> {
    constructor() {
        super("observer.ServerStatusRequest", []);
    }
    create(value?: PartialMessage<ServerStatusRequest>): ServerStatusRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<ServerStatusRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ServerStatusRequest): ServerStatusRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ServerStatusRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message observer.ServerStatusRequest
 */
export const ServerStatusRequest = new ServerStatusRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ServerStatusResponse$Type extends MessageType<ServerStatusResponse> {
    constructor() {
        super("observer.ServerStatusResponse", [
            { no: 1, name: "num_flows", kind: "scalar", localName: "num_flows", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "max_flows", kind: "scalar", localName: "max_flows", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "seen_flows", kind: "scalar", localName: "seen_flows", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "uptime_ns", kind: "scalar", localName: "uptime_ns", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 5, name: "num_connected_nodes", kind: "message", localName: "num_connected_nodes", T: () => UInt32Value },
            { no: 6, name: "num_unavailable_nodes", kind: "message", localName: "num_unavailable_nodes", T: () => UInt32Value },
            { no: 7, name: "unavailable_nodes", kind: "scalar", localName: "unavailable_nodes", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "version", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "flows_rate", kind: "scalar", localName: "flows_rate", T: 1 /*ScalarType.DOUBLE*/ }
        ]);
    }
    create(value?: PartialMessage<ServerStatusResponse>): ServerStatusResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.num_flows = 0;
        message.max_flows = 0;
        message.seen_flows = 0;
        message.uptime_ns = 0;
        message.unavailable_nodes = [];
        message.version = "";
        message.flows_rate = 0;
        if (value !== undefined)
            reflectionMergePartial<ServerStatusResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ServerStatusResponse): ServerStatusResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint64 num_flows */ 1:
                    message.num_flows = reader.uint64().toNumber();
                    break;
                case /* uint64 max_flows */ 2:
                    message.max_flows = reader.uint64().toNumber();
                    break;
                case /* uint64 seen_flows */ 3:
                    message.seen_flows = reader.uint64().toNumber();
                    break;
                case /* uint64 uptime_ns */ 4:
                    message.uptime_ns = reader.uint64().toNumber();
                    break;
                case /* google.protobuf.UInt32Value num_connected_nodes */ 5:
                    message.num_connected_nodes = UInt32Value.internalBinaryRead(reader, reader.uint32(), options, message.num_connected_nodes);
                    break;
                case /* google.protobuf.UInt32Value num_unavailable_nodes */ 6:
                    message.num_unavailable_nodes = UInt32Value.internalBinaryRead(reader, reader.uint32(), options, message.num_unavailable_nodes);
                    break;
                case /* repeated string unavailable_nodes */ 7:
                    message.unavailable_nodes.push(reader.string());
                    break;
                case /* string version */ 8:
                    message.version = reader.string();
                    break;
                case /* double flows_rate */ 9:
                    message.flows_rate = reader.double();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ServerStatusResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint64 num_flows = 1; */
        if (message.num_flows !== 0)
            writer.tag(1, WireType.Varint).uint64(message.num_flows);
        /* uint64 max_flows = 2; */
        if (message.max_flows !== 0)
            writer.tag(2, WireType.Varint).uint64(message.max_flows);
        /* uint64 seen_flows = 3; */
        if (message.seen_flows !== 0)
            writer.tag(3, WireType.Varint).uint64(message.seen_flows);
        /* uint64 uptime_ns = 4; */
        if (message.uptime_ns !== 0)
            writer.tag(4, WireType.Varint).uint64(message.uptime_ns);
        /* google.protobuf.UInt32Value num_connected_nodes = 5; */
        if (message.num_connected_nodes)
            UInt32Value.internalBinaryWrite(message.num_connected_nodes, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.UInt32Value num_unavailable_nodes = 6; */
        if (message.num_unavailable_nodes)
            UInt32Value.internalBinaryWrite(message.num_unavailable_nodes, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* repeated string unavailable_nodes = 7; */
        for (let i = 0; i < message.unavailable_nodes.length; i++)
            writer.tag(7, WireType.LengthDelimited).string(message.unavailable_nodes[i]);
        /* string version = 8; */
        if (message.version !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.version);
        /* double flows_rate = 9; */
        if (message.flows_rate !== 0)
            writer.tag(9, WireType.Bit64).double(message.flows_rate);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message observer.ServerStatusResponse
 */
export const ServerStatusResponse = new ServerStatusResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetFlowsRequest$Type extends MessageType<GetFlowsRequest> {
    constructor() {
        super("observer.GetFlowsRequest", [
            { no: 1, name: "number", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 9, name: "first", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "follow", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 5, name: "blacklist", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => FlowFilter },
            { no: 6, name: "whitelist", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => FlowFilter },
            { no: 7, name: "since", kind: "message", T: () => Timestamp },
            { no: 8, name: "until", kind: "message", T: () => Timestamp },
            { no: 999, name: "experimental", kind: "message", T: () => GetFlowsRequest_Experimental },
            { no: 150000, name: "extensions", kind: "message", T: () => Any },
            { no: 10001, name: "aggregation", kind: "message", T: () => Aggregation }
        ]);
    }
    create(value?: PartialMessage<GetFlowsRequest>): GetFlowsRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.number = 0;
        message.first = false;
        message.follow = false;
        message.blacklist = [];
        message.whitelist = [];
        if (value !== undefined)
            reflectionMergePartial<GetFlowsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetFlowsRequest): GetFlowsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint64 number */ 1:
                    message.number = reader.uint64().toNumber();
                    break;
                case /* bool first */ 9:
                    message.first = reader.bool();
                    break;
                case /* bool follow */ 3:
                    message.follow = reader.bool();
                    break;
                case /* repeated flow.FlowFilter blacklist */ 5:
                    message.blacklist.push(FlowFilter.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated flow.FlowFilter whitelist */ 6:
                    message.whitelist.push(FlowFilter.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* google.protobuf.Timestamp since */ 7:
                    message.since = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.since);
                    break;
                case /* google.protobuf.Timestamp until */ 8:
                    message.until = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.until);
                    break;
                case /* observer.GetFlowsRequest.Experimental experimental */ 999:
                    message.experimental = GetFlowsRequest_Experimental.internalBinaryRead(reader, reader.uint32(), options, message.experimental);
                    break;
                case /* google.protobuf.Any extensions */ 150000:
                    message.extensions = Any.internalBinaryRead(reader, reader.uint32(), options, message.extensions);
                    break;
                case /* observer.Aggregation aggregation = 10001 [deprecated = true];*/ 10001:
                    message.aggregation = Aggregation.internalBinaryRead(reader, reader.uint32(), options, message.aggregation);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetFlowsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint64 number = 1; */
        if (message.number !== 0)
            writer.tag(1, WireType.Varint).uint64(message.number);
        /* bool first = 9; */
        if (message.first !== false)
            writer.tag(9, WireType.Varint).bool(message.first);
        /* bool follow = 3; */
        if (message.follow !== false)
            writer.tag(3, WireType.Varint).bool(message.follow);
        /* repeated flow.FlowFilter blacklist = 5; */
        for (let i = 0; i < message.blacklist.length; i++)
            FlowFilter.internalBinaryWrite(message.blacklist[i], writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* repeated flow.FlowFilter whitelist = 6; */
        for (let i = 0; i < message.whitelist.length; i++)
            FlowFilter.internalBinaryWrite(message.whitelist[i], writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp since = 7; */
        if (message.since)
            Timestamp.internalBinaryWrite(message.since, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp until = 8; */
        if (message.until)
            Timestamp.internalBinaryWrite(message.until, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* observer.GetFlowsRequest.Experimental experimental = 999; */
        if (message.experimental)
            GetFlowsRequest_Experimental.internalBinaryWrite(message.experimental, writer.tag(999, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Any extensions = 150000; */
        if (message.extensions)
            Any.internalBinaryWrite(message.extensions, writer.tag(150000, WireType.LengthDelimited).fork(), options).join();
        /* observer.Aggregation aggregation = 10001 [deprecated = true]; */
        if (message.aggregation)
            Aggregation.internalBinaryWrite(message.aggregation, writer.tag(10001, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message observer.GetFlowsRequest
 */
export const GetFlowsRequest = new GetFlowsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetFlowsRequest_Experimental$Type extends MessageType<GetFlowsRequest_Experimental> {
    constructor() {
        super("observer.GetFlowsRequest.Experimental", [
            { no: 1, name: "field_mask", kind: "message", localName: "field_mask", T: () => FieldMask }
        ]);
    }
    create(value?: PartialMessage<GetFlowsRequest_Experimental>): GetFlowsRequest_Experimental {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<GetFlowsRequest_Experimental>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetFlowsRequest_Experimental): GetFlowsRequest_Experimental {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* google.protobuf.FieldMask field_mask */ 1:
                    message.field_mask = FieldMask.internalBinaryRead(reader, reader.uint32(), options, message.field_mask);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetFlowsRequest_Experimental, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* google.protobuf.FieldMask field_mask = 1; */
        if (message.field_mask)
            FieldMask.internalBinaryWrite(message.field_mask, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message observer.GetFlowsRequest.Experimental
 */
export const GetFlowsRequest_Experimental = new GetFlowsRequest_Experimental$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetFlowsResponse$Type extends MessageType<GetFlowsResponse> {
    constructor() {
        super("observer.GetFlowsResponse", [
            { no: 1, name: "flow", kind: "message", oneof: "response_types", T: () => Flow },
            { no: 2, name: "node_status", kind: "message", localName: "node_status", oneof: "response_types", T: () => NodeStatusEvent },
            { no: 3, name: "lost_events", kind: "message", localName: "lost_events", oneof: "response_types", T: () => LostEvent },
            { no: 1000, name: "node_name", kind: "scalar", localName: "node_name", T: 9 /*ScalarType.STRING*/ },
            { no: 1001, name: "time", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value?: PartialMessage<GetFlowsResponse>): GetFlowsResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.response_types = { oneofKind: undefined };
        message.node_name = "";
        if (value !== undefined)
            reflectionMergePartial<GetFlowsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetFlowsResponse): GetFlowsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* flow.Flow flow */ 1:
                    message.response_types = {
                        oneofKind: "flow",
                        flow: Flow.internalBinaryRead(reader, reader.uint32(), options, (message.response_types as any).flow)
                    };
                    break;
                case /* relay.NodeStatusEvent node_status */ 2:
                    message.response_types = {
                        oneofKind: "node_status",
                        node_status: NodeStatusEvent.internalBinaryRead(reader, reader.uint32(), options, (message.response_types as any).node_status)
                    };
                    break;
                case /* flow.LostEvent lost_events */ 3:
                    message.response_types = {
                        oneofKind: "lost_events",
                        lost_events: LostEvent.internalBinaryRead(reader, reader.uint32(), options, (message.response_types as any).lost_events)
                    };
                    break;
                case /* string node_name */ 1000:
                    message.node_name = reader.string();
                    break;
                case /* google.protobuf.Timestamp time */ 1001:
                    message.time = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.time);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetFlowsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* flow.Flow flow = 1; */
        if (message.response_types.oneofKind === "flow")
            Flow.internalBinaryWrite(message.response_types.flow, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* relay.NodeStatusEvent node_status = 2; */
        if (message.response_types.oneofKind === "node_status")
            NodeStatusEvent.internalBinaryWrite(message.response_types.node_status, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* flow.LostEvent lost_events = 3; */
        if (message.response_types.oneofKind === "lost_events")
            LostEvent.internalBinaryWrite(message.response_types.lost_events, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* string node_name = 1000; */
        if (message.node_name !== "")
            writer.tag(1000, WireType.LengthDelimited).string(message.node_name);
        /* google.protobuf.Timestamp time = 1001; */
        if (message.time)
            Timestamp.internalBinaryWrite(message.time, writer.tag(1001, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message observer.GetFlowsResponse
 */
export const GetFlowsResponse = new GetFlowsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAgentEventsRequest$Type extends MessageType<GetAgentEventsRequest> {
    constructor() {
        super("observer.GetAgentEventsRequest", [
            { no: 1, name: "number", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 9, name: "first", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "follow", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 7, name: "since", kind: "message", T: () => Timestamp },
            { no: 8, name: "until", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value?: PartialMessage<GetAgentEventsRequest>): GetAgentEventsRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.number = 0;
        message.first = false;
        message.follow = false;
        if (value !== undefined)
            reflectionMergePartial<GetAgentEventsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAgentEventsRequest): GetAgentEventsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint64 number */ 1:
                    message.number = reader.uint64().toNumber();
                    break;
                case /* bool first */ 9:
                    message.first = reader.bool();
                    break;
                case /* bool follow */ 2:
                    message.follow = reader.bool();
                    break;
                case /* google.protobuf.Timestamp since */ 7:
                    message.since = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.since);
                    break;
                case /* google.protobuf.Timestamp until */ 8:
                    message.until = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.until);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetAgentEventsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint64 number = 1; */
        if (message.number !== 0)
            writer.tag(1, WireType.Varint).uint64(message.number);
        /* bool first = 9; */
        if (message.first !== false)
            writer.tag(9, WireType.Varint).bool(message.first);
        /* bool follow = 2; */
        if (message.follow !== false)
            writer.tag(2, WireType.Varint).bool(message.follow);
        /* google.protobuf.Timestamp since = 7; */
        if (message.since)
            Timestamp.internalBinaryWrite(message.since, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp until = 8; */
        if (message.until)
            Timestamp.internalBinaryWrite(message.until, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message observer.GetAgentEventsRequest
 */
export const GetAgentEventsRequest = new GetAgentEventsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAgentEventsResponse$Type extends MessageType<GetAgentEventsResponse> {
    constructor() {
        super("observer.GetAgentEventsResponse", [
            { no: 1, name: "agent_event", kind: "message", localName: "agent_event", T: () => AgentEvent },
            { no: 1000, name: "node_name", kind: "scalar", localName: "node_name", T: 9 /*ScalarType.STRING*/ },
            { no: 1001, name: "time", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value?: PartialMessage<GetAgentEventsResponse>): GetAgentEventsResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.node_name = "";
        if (value !== undefined)
            reflectionMergePartial<GetAgentEventsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAgentEventsResponse): GetAgentEventsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* flow.AgentEvent agent_event */ 1:
                    message.agent_event = AgentEvent.internalBinaryRead(reader, reader.uint32(), options, message.agent_event);
                    break;
                case /* string node_name */ 1000:
                    message.node_name = reader.string();
                    break;
                case /* google.protobuf.Timestamp time */ 1001:
                    message.time = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.time);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetAgentEventsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* flow.AgentEvent agent_event = 1; */
        if (message.agent_event)
            AgentEvent.internalBinaryWrite(message.agent_event, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string node_name = 1000; */
        if (message.node_name !== "")
            writer.tag(1000, WireType.LengthDelimited).string(message.node_name);
        /* google.protobuf.Timestamp time = 1001; */
        if (message.time)
            Timestamp.internalBinaryWrite(message.time, writer.tag(1001, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message observer.GetAgentEventsResponse
 */
export const GetAgentEventsResponse = new GetAgentEventsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetDebugEventsRequest$Type extends MessageType<GetDebugEventsRequest> {
    constructor() {
        super("observer.GetDebugEventsRequest", [
            { no: 1, name: "number", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 9, name: "first", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "follow", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 7, name: "since", kind: "message", T: () => Timestamp },
            { no: 8, name: "until", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value?: PartialMessage<GetDebugEventsRequest>): GetDebugEventsRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.number = 0;
        message.first = false;
        message.follow = false;
        if (value !== undefined)
            reflectionMergePartial<GetDebugEventsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetDebugEventsRequest): GetDebugEventsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint64 number */ 1:
                    message.number = reader.uint64().toNumber();
                    break;
                case /* bool first */ 9:
                    message.first = reader.bool();
                    break;
                case /* bool follow */ 2:
                    message.follow = reader.bool();
                    break;
                case /* google.protobuf.Timestamp since */ 7:
                    message.since = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.since);
                    break;
                case /* google.protobuf.Timestamp until */ 8:
                    message.until = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.until);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetDebugEventsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint64 number = 1; */
        if (message.number !== 0)
            writer.tag(1, WireType.Varint).uint64(message.number);
        /* bool first = 9; */
        if (message.first !== false)
            writer.tag(9, WireType.Varint).bool(message.first);
        /* bool follow = 2; */
        if (message.follow !== false)
            writer.tag(2, WireType.Varint).bool(message.follow);
        /* google.protobuf.Timestamp since = 7; */
        if (message.since)
            Timestamp.internalBinaryWrite(message.since, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp until = 8; */
        if (message.until)
            Timestamp.internalBinaryWrite(message.until, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message observer.GetDebugEventsRequest
 */
export const GetDebugEventsRequest = new GetDebugEventsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetDebugEventsResponse$Type extends MessageType<GetDebugEventsResponse> {
    constructor() {
        super("observer.GetDebugEventsResponse", [
            { no: 1, name: "debug_event", kind: "message", localName: "debug_event", T: () => DebugEvent },
            { no: 1000, name: "node_name", kind: "scalar", localName: "node_name", T: 9 /*ScalarType.STRING*/ },
            { no: 1001, name: "time", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value?: PartialMessage<GetDebugEventsResponse>): GetDebugEventsResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.node_name = "";
        if (value !== undefined)
            reflectionMergePartial<GetDebugEventsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetDebugEventsResponse): GetDebugEventsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* flow.DebugEvent debug_event */ 1:
                    message.debug_event = DebugEvent.internalBinaryRead(reader, reader.uint32(), options, message.debug_event);
                    break;
                case /* string node_name */ 1000:
                    message.node_name = reader.string();
                    break;
                case /* google.protobuf.Timestamp time */ 1001:
                    message.time = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.time);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetDebugEventsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* flow.DebugEvent debug_event = 1; */
        if (message.debug_event)
            DebugEvent.internalBinaryWrite(message.debug_event, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string node_name = 1000; */
        if (message.node_name !== "")
            writer.tag(1000, WireType.LengthDelimited).string(message.node_name);
        /* google.protobuf.Timestamp time = 1001; */
        if (message.time)
            Timestamp.internalBinaryWrite(message.time, writer.tag(1001, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message observer.GetDebugEventsResponse
 */
export const GetDebugEventsResponse = new GetDebugEventsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetNodesRequest$Type extends MessageType<GetNodesRequest> {
    constructor() {
        super("observer.GetNodesRequest", []);
    }
    create(value?: PartialMessage<GetNodesRequest>): GetNodesRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<GetNodesRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetNodesRequest): GetNodesRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: GetNodesRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message observer.GetNodesRequest
 */
export const GetNodesRequest = new GetNodesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetNodesResponse$Type extends MessageType<GetNodesResponse> {
    constructor() {
        super("observer.GetNodesResponse", [
            { no: 1, name: "nodes", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Node }
        ]);
    }
    create(value?: PartialMessage<GetNodesResponse>): GetNodesResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.nodes = [];
        if (value !== undefined)
            reflectionMergePartial<GetNodesResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetNodesResponse): GetNodesResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated observer.Node nodes */ 1:
                    message.nodes.push(Node.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetNodesResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated observer.Node nodes = 1; */
        for (let i = 0; i < message.nodes.length; i++)
            Node.internalBinaryWrite(message.nodes[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message observer.GetNodesResponse
 */
export const GetNodesResponse = new GetNodesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Node$Type extends MessageType<Node> {
    constructor() {
        super("observer.Node", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "version", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "address", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "state", kind: "enum", T: () => ["relay.NodeState", NodeState] },
            { no: 5, name: "tls", kind: "message", T: () => TLS },
            { no: 6, name: "uptime_ns", kind: "scalar", localName: "uptime_ns", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 7, name: "num_flows", kind: "scalar", localName: "num_flows", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 8, name: "max_flows", kind: "scalar", localName: "max_flows", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 9, name: "seen_flows", kind: "scalar", localName: "seen_flows", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<Node>): Node {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.name = "";
        message.version = "";
        message.address = "";
        message.state = 0;
        message.uptime_ns = 0;
        message.num_flows = 0;
        message.max_flows = 0;
        message.seen_flows = 0;
        if (value !== undefined)
            reflectionMergePartial<Node>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Node): Node {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* string version */ 2:
                    message.version = reader.string();
                    break;
                case /* string address */ 3:
                    message.address = reader.string();
                    break;
                case /* relay.NodeState state */ 4:
                    message.state = reader.int32();
                    break;
                case /* observer.TLS tls */ 5:
                    message.tls = TLS.internalBinaryRead(reader, reader.uint32(), options, message.tls);
                    break;
                case /* uint64 uptime_ns */ 6:
                    message.uptime_ns = reader.uint64().toNumber();
                    break;
                case /* uint64 num_flows */ 7:
                    message.num_flows = reader.uint64().toNumber();
                    break;
                case /* uint64 max_flows */ 8:
                    message.max_flows = reader.uint64().toNumber();
                    break;
                case /* uint64 seen_flows */ 9:
                    message.seen_flows = reader.uint64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Node, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* string version = 2; */
        if (message.version !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.version);
        /* string address = 3; */
        if (message.address !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.address);
        /* relay.NodeState state = 4; */
        if (message.state !== 0)
            writer.tag(4, WireType.Varint).int32(message.state);
        /* observer.TLS tls = 5; */
        if (message.tls)
            TLS.internalBinaryWrite(message.tls, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* uint64 uptime_ns = 6; */
        if (message.uptime_ns !== 0)
            writer.tag(6, WireType.Varint).uint64(message.uptime_ns);
        /* uint64 num_flows = 7; */
        if (message.num_flows !== 0)
            writer.tag(7, WireType.Varint).uint64(message.num_flows);
        /* uint64 max_flows = 8; */
        if (message.max_flows !== 0)
            writer.tag(8, WireType.Varint).uint64(message.max_flows);
        /* uint64 seen_flows = 9; */
        if (message.seen_flows !== 0)
            writer.tag(9, WireType.Varint).uint64(message.seen_flows);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message observer.Node
 */
export const Node = new Node$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TLS$Type extends MessageType<TLS> {
    constructor() {
        super("observer.TLS", [
            { no: 1, name: "enabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "server_name", kind: "scalar", localName: "server_name", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<TLS>): TLS {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.enabled = false;
        message.server_name = "";
        if (value !== undefined)
            reflectionMergePartial<TLS>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TLS): TLS {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool enabled */ 1:
                    message.enabled = reader.bool();
                    break;
                case /* string server_name */ 2:
                    message.server_name = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TLS, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool enabled = 1; */
        if (message.enabled !== false)
            writer.tag(1, WireType.Varint).bool(message.enabled);
        /* string server_name = 2; */
        if (message.server_name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.server_name);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message observer.TLS
 */
export const TLS = new TLS$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetNamespacesRequest$Type extends MessageType<GetNamespacesRequest> {
    constructor() {
        super("observer.GetNamespacesRequest", []);
    }
    create(value?: PartialMessage<GetNamespacesRequest>): GetNamespacesRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<GetNamespacesRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetNamespacesRequest): GetNamespacesRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: GetNamespacesRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message observer.GetNamespacesRequest
 */
export const GetNamespacesRequest = new GetNamespacesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetNamespacesResponse$Type extends MessageType<GetNamespacesResponse> {
    constructor() {
        super("observer.GetNamespacesResponse", [
            { no: 1, name: "namespaces", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Namespace }
        ]);
    }
    create(value?: PartialMessage<GetNamespacesResponse>): GetNamespacesResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.namespaces = [];
        if (value !== undefined)
            reflectionMergePartial<GetNamespacesResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetNamespacesResponse): GetNamespacesResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated observer.Namespace namespaces */ 1:
                    message.namespaces.push(Namespace.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetNamespacesResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated observer.Namespace namespaces = 1; */
        for (let i = 0; i < message.namespaces.length; i++)
            Namespace.internalBinaryWrite(message.namespaces[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message observer.GetNamespacesResponse
 */
export const GetNamespacesResponse = new GetNamespacesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Namespace$Type extends MessageType<Namespace> {
    constructor() {
        super("observer.Namespace", [
            { no: 1, name: "cluster", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "namespace", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Namespace>): Namespace {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.cluster = "";
        message.namespace = "";
        if (value !== undefined)
            reflectionMergePartial<Namespace>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Namespace): Namespace {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string cluster */ 1:
                    message.cluster = reader.string();
                    break;
                case /* string namespace */ 2:
                    message.namespace = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Namespace, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string cluster = 1; */
        if (message.cluster !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.cluster);
        /* string namespace = 2; */
        if (message.namespace !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.namespace);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message observer.Namespace
 */
export const Namespace = new Namespace$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ExportEvent$Type extends MessageType<ExportEvent> {
    constructor() {
        super("observer.ExportEvent", [
            { no: 1, name: "flow", kind: "message", oneof: "response_types", T: () => Flow },
            { no: 2, name: "node_status", kind: "message", localName: "node_status", oneof: "response_types", T: () => NodeStatusEvent },
            { no: 3, name: "lost_events", kind: "message", localName: "lost_events", oneof: "response_types", T: () => LostEvent },
            { no: 4, name: "agent_event", kind: "message", localName: "agent_event", oneof: "response_types", T: () => AgentEvent },
            { no: 5, name: "debug_event", kind: "message", localName: "debug_event", oneof: "response_types", T: () => DebugEvent },
            { no: 1000, name: "node_name", kind: "scalar", localName: "node_name", T: 9 /*ScalarType.STRING*/ },
            { no: 1001, name: "time", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value?: PartialMessage<ExportEvent>): ExportEvent {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.response_types = { oneofKind: undefined };
        message.node_name = "";
        if (value !== undefined)
            reflectionMergePartial<ExportEvent>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ExportEvent): ExportEvent {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* flow.Flow flow */ 1:
                    message.response_types = {
                        oneofKind: "flow",
                        flow: Flow.internalBinaryRead(reader, reader.uint32(), options, (message.response_types as any).flow)
                    };
                    break;
                case /* relay.NodeStatusEvent node_status */ 2:
                    message.response_types = {
                        oneofKind: "node_status",
                        node_status: NodeStatusEvent.internalBinaryRead(reader, reader.uint32(), options, (message.response_types as any).node_status)
                    };
                    break;
                case /* flow.LostEvent lost_events */ 3:
                    message.response_types = {
                        oneofKind: "lost_events",
                        lost_events: LostEvent.internalBinaryRead(reader, reader.uint32(), options, (message.response_types as any).lost_events)
                    };
                    break;
                case /* flow.AgentEvent agent_event */ 4:
                    message.response_types = {
                        oneofKind: "agent_event",
                        agent_event: AgentEvent.internalBinaryRead(reader, reader.uint32(), options, (message.response_types as any).agent_event)
                    };
                    break;
                case /* flow.DebugEvent debug_event */ 5:
                    message.response_types = {
                        oneofKind: "debug_event",
                        debug_event: DebugEvent.internalBinaryRead(reader, reader.uint32(), options, (message.response_types as any).debug_event)
                    };
                    break;
                case /* string node_name */ 1000:
                    message.node_name = reader.string();
                    break;
                case /* google.protobuf.Timestamp time */ 1001:
                    message.time = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.time);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ExportEvent, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* flow.Flow flow = 1; */
        if (message.response_types.oneofKind === "flow")
            Flow.internalBinaryWrite(message.response_types.flow, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* relay.NodeStatusEvent node_status = 2; */
        if (message.response_types.oneofKind === "node_status")
            NodeStatusEvent.internalBinaryWrite(message.response_types.node_status, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* flow.LostEvent lost_events = 3; */
        if (message.response_types.oneofKind === "lost_events")
            LostEvent.internalBinaryWrite(message.response_types.lost_events, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* flow.AgentEvent agent_event = 4; */
        if (message.response_types.oneofKind === "agent_event")
            AgentEvent.internalBinaryWrite(message.response_types.agent_event, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* flow.DebugEvent debug_event = 5; */
        if (message.response_types.oneofKind === "debug_event")
            DebugEvent.internalBinaryWrite(message.response_types.debug_event, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* string node_name = 1000; */
        if (message.node_name !== "")
            writer.tag(1000, WireType.LengthDelimited).string(message.node_name);
        /* google.protobuf.Timestamp time = 1001; */
        if (message.time)
            Timestamp.internalBinaryWrite(message.time, writer.tag(1001, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message observer.ExportEvent
 */
export const ExportEvent = new ExportEvent$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DirectionStatistics$Type extends MessageType<DirectionStatistics> {
    constructor() {
        super("observer.DirectionStatistics", [
            { no: 1, name: "first_activity", kind: "message", localName: "first_activity", T: () => Timestamp },
            { no: 2, name: "last_activity", kind: "message", localName: "last_activity", T: () => Timestamp },
            { no: 3, name: "num_flows", kind: "scalar", localName: "num_flows", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "bytes", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 5, name: "errors", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 6, name: "ack_seen", kind: "scalar", localName: "ack_seen", T: 8 /*ScalarType.BOOL*/ },
            { no: 7, name: "connection_attempts", kind: "scalar", localName: "connection_attempts", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 8, name: "close_requests", kind: "scalar", localName: "close_requests", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<DirectionStatistics>): DirectionStatistics {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.num_flows = 0;
        message.bytes = 0;
        message.errors = 0;
        message.ack_seen = false;
        message.connection_attempts = 0;
        message.close_requests = 0;
        if (value !== undefined)
            reflectionMergePartial<DirectionStatistics>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DirectionStatistics): DirectionStatistics {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* google.protobuf.Timestamp first_activity */ 1:
                    message.first_activity = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.first_activity);
                    break;
                case /* google.protobuf.Timestamp last_activity */ 2:
                    message.last_activity = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.last_activity);
                    break;
                case /* uint64 num_flows */ 3:
                    message.num_flows = reader.uint64().toNumber();
                    break;
                case /* uint64 bytes */ 4:
                    message.bytes = reader.uint64().toNumber();
                    break;
                case /* uint64 errors */ 5:
                    message.errors = reader.uint64().toNumber();
                    break;
                case /* bool ack_seen */ 6:
                    message.ack_seen = reader.bool();
                    break;
                case /* uint64 connection_attempts */ 7:
                    message.connection_attempts = reader.uint64().toNumber();
                    break;
                case /* uint64 close_requests */ 8:
                    message.close_requests = reader.uint64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DirectionStatistics, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* google.protobuf.Timestamp first_activity = 1; */
        if (message.first_activity)
            Timestamp.internalBinaryWrite(message.first_activity, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp last_activity = 2; */
        if (message.last_activity)
            Timestamp.internalBinaryWrite(message.last_activity, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* uint64 num_flows = 3; */
        if (message.num_flows !== 0)
            writer.tag(3, WireType.Varint).uint64(message.num_flows);
        /* uint64 bytes = 4; */
        if (message.bytes !== 0)
            writer.tag(4, WireType.Varint).uint64(message.bytes);
        /* uint64 errors = 5; */
        if (message.errors !== 0)
            writer.tag(5, WireType.Varint).uint64(message.errors);
        /* bool ack_seen = 6; */
        if (message.ack_seen !== false)
            writer.tag(6, WireType.Varint).bool(message.ack_seen);
        /* uint64 connection_attempts = 7; */
        if (message.connection_attempts !== 0)
            writer.tag(7, WireType.Varint).uint64(message.connection_attempts);
        /* uint64 close_requests = 8; */
        if (message.close_requests !== 0)
            writer.tag(8, WireType.Varint).uint64(message.close_requests);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @deprecated
 * @generated MessageType for protobuf message observer.DirectionStatistics
 */
export const DirectionStatistics = new DirectionStatistics$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FlowStatistics$Type extends MessageType<FlowStatistics> {
    constructor() {
        super("observer.FlowStatistics", [
            { no: 1, name: "forward", kind: "message", T: () => DirectionStatistics },
            { no: 2, name: "reply", kind: "message", T: () => DirectionStatistics },
            { no: 3, name: "established", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<FlowStatistics>): FlowStatistics {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.established = false;
        if (value !== undefined)
            reflectionMergePartial<FlowStatistics>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FlowStatistics): FlowStatistics {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* observer.DirectionStatistics forward */ 1:
                    message.forward = DirectionStatistics.internalBinaryRead(reader, reader.uint32(), options, message.forward);
                    break;
                case /* observer.DirectionStatistics reply */ 2:
                    message.reply = DirectionStatistics.internalBinaryRead(reader, reader.uint32(), options, message.reply);
                    break;
                case /* bool established */ 3:
                    message.established = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FlowStatistics, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* observer.DirectionStatistics forward = 1; */
        if (message.forward)
            DirectionStatistics.internalBinaryWrite(message.forward, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* observer.DirectionStatistics reply = 2; */
        if (message.reply)
            DirectionStatistics.internalBinaryWrite(message.reply, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* bool established = 3; */
        if (message.established !== false)
            writer.tag(3, WireType.Varint).bool(message.established);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @deprecated
 * @generated MessageType for protobuf message observer.FlowStatistics
 */
export const FlowStatistics = new FlowStatistics$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Aggregator$Type extends MessageType<Aggregator> {
    constructor() {
        super("observer.Aggregator", [
            { no: 1, name: "type", kind: "enum", T: () => ["observer.AggregatorType", AggregatorType] },
            { no: 2, name: "ignore_source_port", kind: "scalar", localName: "ignore_source_port", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "ttl", kind: "message", T: () => Duration },
            { no: 4, name: "renew_ttl", kind: "message", localName: "renew_ttl", T: () => BoolValue }
        ]);
    }
    create(value?: PartialMessage<Aggregator>): Aggregator {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.type = 0;
        message.ignore_source_port = false;
        if (value !== undefined)
            reflectionMergePartial<Aggregator>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Aggregator): Aggregator {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* observer.AggregatorType type */ 1:
                    message.type = reader.int32();
                    break;
                case /* bool ignore_source_port */ 2:
                    message.ignore_source_port = reader.bool();
                    break;
                case /* google.protobuf.Duration ttl */ 3:
                    message.ttl = Duration.internalBinaryRead(reader, reader.uint32(), options, message.ttl);
                    break;
                case /* google.protobuf.BoolValue renew_ttl */ 4:
                    message.renew_ttl = BoolValue.internalBinaryRead(reader, reader.uint32(), options, message.renew_ttl);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Aggregator, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* observer.AggregatorType type = 1; */
        if (message.type !== 0)
            writer.tag(1, WireType.Varint).int32(message.type);
        /* bool ignore_source_port = 2; */
        if (message.ignore_source_port !== false)
            writer.tag(2, WireType.Varint).bool(message.ignore_source_port);
        /* google.protobuf.Duration ttl = 3; */
        if (message.ttl)
            Duration.internalBinaryWrite(message.ttl, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.BoolValue renew_ttl = 4; */
        if (message.renew_ttl)
            BoolValue.internalBinaryWrite(message.renew_ttl, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @deprecated
 * @generated MessageType for protobuf message observer.Aggregator
 */
export const Aggregator = new Aggregator$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Aggregation$Type extends MessageType<Aggregation> {
    constructor() {
        super("observer.Aggregation", [
            { no: 1, name: "aggregators", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Aggregator },
            { no: 2, name: "state_change_filter", kind: "enum", localName: "state_change_filter", T: () => ["observer.StateChange", StateChange] }
        ]);
    }
    create(value?: PartialMessage<Aggregation>): Aggregation {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.aggregators = [];
        message.state_change_filter = 0;
        if (value !== undefined)
            reflectionMergePartial<Aggregation>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Aggregation): Aggregation {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated observer.Aggregator aggregators */ 1:
                    message.aggregators.push(Aggregator.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* observer.StateChange state_change_filter */ 2:
                    message.state_change_filter = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Aggregation, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated observer.Aggregator aggregators = 1; */
        for (let i = 0; i < message.aggregators.length; i++)
            Aggregator.internalBinaryWrite(message.aggregators[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* observer.StateChange state_change_filter = 2; */
        if (message.state_change_filter !== 0)
            writer.tag(2, WireType.Varint).int32(message.state_change_filter);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @deprecated
 * @generated MessageType for protobuf message observer.Aggregation
 */
export const Aggregation = new Aggregation$Type();
/**
 * @generated ServiceType for protobuf service observer.Observer
 */
export const Observer = new ServiceType("observer.Observer", [
    { name: "GetFlows", serverStreaming: true, options: {}, I: GetFlowsRequest, O: GetFlowsResponse },
    { name: "GetAgentEvents", serverStreaming: true, options: {}, I: GetAgentEventsRequest, O: GetAgentEventsResponse },
    { name: "GetDebugEvents", serverStreaming: true, options: {}, I: GetDebugEventsRequest, O: GetDebugEventsResponse },
    { name: "GetNodes", options: {}, I: GetNodesRequest, O: GetNodesResponse },
    { name: "GetNamespaces", options: {}, I: GetNamespacesRequest, O: GetNamespacesResponse },
    { name: "ServerStatus", options: {}, I: ServerStatusRequest, O: ServerStatusResponse }
]);
