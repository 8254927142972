import React from 'react';

import { Button } from '@blueprintjs/core';
import classnames from 'classnames';
import { observer } from 'mobx-react';

import {
  Props as ColumnSelectorProps,
  FlowsTableColumnsSelector,
} from '~/components/FlowsTable/ColumnsSelector';

import css from './right-side.scss';
export interface Props {
  flowsTableVisibleColumns: ColumnSelectorProps['visibleColumns'];
  onFlowsTableColumnToggle?: ColumnSelectorProps['onToggleColumn'];
  onPanelFullscreenToggle?: () => void;
  isFullscreen: boolean;
  onPanelToggle?: () => void;
  isHidden: boolean;
}

export enum E2E {
  collapseButton = 'detail-panel-collapse-button',
}

export const RightSide = observer((props: Props) => {
  return (
    <div className={css.layout}>
      {!props.isHidden && (
        <FlowsTableColumnsSelector
          visibleColumns={props.flowsTableVisibleColumns}
          onToggleColumn={props.onFlowsTableColumnToggle}
          className={classnames(css.button, { [css.hidden]: props.isHidden })}
        />
      )}
      <Button
        className={`${css.button} ${css.icon_smaller}`}
        small
        minimal
        rightIcon={props.isFullscreen ? 'minimize' : 'fullscreen'}
        onClick={props.onPanelFullscreenToggle}
      />
      <Button
        className={`${css.button} ${css.icon_bigger}`}
        small
        minimal
        rightIcon={props.isHidden ? 'chevron-up' : 'chevron-down'}
        onClick={props.onPanelToggle}
        data-testid={E2E.collapseButton}
      />
    </div>
  );
});
