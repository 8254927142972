import AJV from 'ajv';
import addAJVFormats from 'ajv-formats';
import { autorun, makeAutoObservable, observable, ObservableMap } from 'mobx';
import * as YAML from 'yaml';

import { schema as cnpSchema } from '~/domain/cilium/cnp/schema';
import {
  EgressRule,
  EndpointSelector,
  IngressRule,
  Rule,
} from '~/domain/cilium/cnp/types.generated';
import { CiliumNetworkPolicyBuilder, KubernetesNetworkPolicyBuilder } from '~/domain/cimulator';
import { PolicyCard } from '~/domain/cimulator/cards';
import { PolicyEndpoint } from '~/domain/cimulator/endpoint';
import { CardKind, CardSide, EndpointKind, PolicyKind } from '~/domain/cimulator/types';
import { PolicySpecChange } from '~/domain/events';
import { FilterKind } from '~/domain/filtering';
import { Flow } from '~/domain/flows';
import { downloadTextAsFile } from '~/domain/helpers/files';
import { FlowType, TrafficDirection } from '~/domain/hubble';
import { schema as knpSchema } from '~/domain/k8s/knp/schema';
import { NetworkPolicySpec } from '~/domain/k8s/knp/types';
import { KV, Labels } from '~/domain/labels';
import { POLICY_PARSE_REVIEWER, StateChange } from '~/domain/misc';
import { PolicyData, PolicyDataType } from '~/domain/policy';
import { filterPolicySpecs } from '~/domain/policy/utils';
import { K8SEvent } from '~/domain/timescape/k8s-events';
import * as storage from '~/storage/local';
import type { Store as MainStore } from '~/store';
import ControlStore from '~/store/stores/cimulator-controls';
import { AnalyticsTrackKind, track } from '~/utils/analytics';
import { logger } from '~/utils/logger';
import { memoize } from '~/utils/memoize';
import { Kind } from '~backend/proto/k8sevent/v1/event_pb';

import { MultiPolicyStore } from './multi-policy';
import { SpecStore } from './policy-spec';
import { ParsePolicyYAMLResult, PolicyInfoSnapshot, RuleStatusInfo } from './types';
import { createInitialCards } from './utils';

export interface PolicyStruct {
  id: string;
  name?: string | null;
  namespace?: string | null;
  isSingleSpec: boolean;
  isClusterwide: boolean;
  isPolicyVersion: boolean;
  specs: SpecStore[];
  currentSpecIdx: number;
}

export class PolicyStore {
  private _main: MainStore | null = null;

  private _multi: MultiPolicyStore;

  private _controls: ControlStore;

  private _origins: ObservableMap<string, string>;
  private _policies: ObservableMap<string, PolicyStruct>;
  private _versions: ObservableMap<string, K8SEvent[]>;

  private _allowedFqdns: string[] = [];

  private _ignoreFlowLabels = storage.getIgnoreFlowLabels(
    new Set(['io.cilium.k8s.policy.cluster', 'io.cilium.k8s.policy.serviceaccount']),
  );

  static parseStr(str: string, kind?: Kind): ParsePolicyYAMLResult {
    return str.startsWith('{') ? PolicyStore.parseJsonStr(str, kind) : PolicyStore.parseYaml(str);
  }

  private static parseYaml(yaml: string): ParsePolicyYAMLResult {
    try {
      const doc = YAML.parseDocument(yaml);
      return PolicyStore.parseJsonMap(doc.toJS({ reviver: POLICY_PARSE_REVIEWER }));
    } catch (error) {
      return { ok: false, errors: [String(error)] };
    }
  }

  private static parseJsonMap(json: JSONMap, kind?: Kind): ParsePolicyYAMLResult {
    try {
      const rawPolicyKind = json.kind as string | null;
      let policyKind: PolicyKind | null = null;

      if (kind) {
        policyKind =
          kind === Kind.KUBERNETES_NETWORK_POLICY
            ? PolicyKind.KNP
            : kind === Kind.CILIUM_NETWORK_POLICY || kind === Kind.CILIUM_CLUSTERWIDE_NETWORK_POLICY
              ? PolicyKind.CNP
              : null;
      } else if (rawPolicyKind) {
        policyKind =
          rawPolicyKind === 'NetworkPolicy'
            ? PolicyKind.KNP
            : rawPolicyKind.startsWith('Cilium') && rawPolicyKind.endsWith('NetworkPolicy')
              ? PolicyKind.CNP
              : null;
      }

      if (!policyKind) {
        return { ok: false, errors: ['Invalid policy kind'] };
      }

      const { validate } =
        policyKind === PolicyKind.CNP
          ? PolicyStore.getCNPValidator()
          : PolicyStore.getKNPValidator();

      const valid = validate(json);
      if (!valid) {
        if (!validate.errors?.length) {
          return { ok: false, errors: ['Invalid policy'] };
        }
        const errors = validate.errors.map(error => {
          return `${error.instancePath}: ${error.message ?? 'invalid'}`;
        });
        return { ok: false, errors };
      }

      const policy =
        policyKind === PolicyKind.KNP
          ? KubernetesNetworkPolicyBuilder.parsePolicy(json)
          : CiliumNetworkPolicyBuilder.parsePolicy(json);

      if (policyKind === PolicyKind.CNP) {
        const isClusterwide =
          rawPolicyKind === 'CiliumClusterwideNetworkPolicy' ||
          kind === Kind.CILIUM_CLUSTERWIDE_NETWORK_POLICY;
        return { ok: true, policyKind, isClusterwide, ...policy };
      } else {
        return { ok: true, policyKind, ...policy };
      }
    } catch (error) {
      return { ok: false, errors: [String(error)] };
    }
  }

  private static parseJsonStr(json: string, kind?: Kind): ParsePolicyYAMLResult {
    try {
      const jsonObj = JSON.parse(json, POLICY_PARSE_REVIEWER) as JSONMap;
      return PolicyStore.parseJsonMap(jsonObj, kind);
    } catch (error) {
      return { ok: false, errors: [String(error)] };
    }
  }

  constructor(controls: ControlStore) {
    makeAutoObservable(this, {
      getCardBy: false,
      isVisibleCard: false,
      getRuleStatusInfo: false,
      flowToCards: false,
      flowsToCardEndpoints: false,
    });

    this._controls = controls;

    this._policies = observable.map({});
    this._origins = observable.map({});
    this._versions = observable.map({});

    this.initAllowedFqdns();
    this.setupReactions();

    this._multi = new MultiPolicyStore(this);
  }

  /* PUBLIC GETTERS */
  get controls() {
    return this._controls;
  }

  get main() {
    return this._main;
  }

  get multi() {
    return this._multi;
  }

  get single() {
    return this;
  }

  get contextual(): MultiPolicyStore | PolicyStore {
    return this._controls.showMultiPolicy ? this.multi : this.single;
  }

  get filteredPoliciesList(): PolicyStruct[] {
    const labelsFilter: KV[] = [];
    const identitiesFilter: string[] = [];
    const dnsFilter: string[] = [];
    const ipsFilter: string[] = [];
    this.main?.controls.flowFilterGroups.forEach(group => {
      for (const entry of group.entries) {
        switch (entry.kind) {
          case FilterKind.Label: {
            const [key, ...values] = entry.query.split('=');
            labelsFilter.push({ key, value: values.join('=') });
            break;
          }
          case FilterKind.Identity: {
            identitiesFilter.push(entry.query);
            break;
          }
          case FilterKind.Dns: {
            dnsFilter.push(entry.query);
            break;
          }
          case FilterKind.Ip: {
            ipsFilter.push(entry.query);
            break;
          }
        }
      }
    });

    return this.policiesList.filter(policy => {
      const checkLabels = (labels: KV[]): Boolean => {
        return Boolean(
          filterPolicySpecs(
            policy.specs.map(s => s.specPodSelector),
            labels,
          ).length,
        );
      };

      const checkDns = (dns?: string | null): Boolean => {
        if (!dns || !dnsFilter.length) return true;
        return dnsFilter.includes(dns);
      };

      const checkIp = (ip?: string | null): Boolean => {
        if (!ip || !ipsFilter.length) return true;
        return ipsFilter.includes(ip);
      };

      return policy.specs.some(spec => {
        if (spec.isAllPodSelector) return true;

        if (labelsFilter.length && !checkLabels(labelsFilter)) {
          return false;
        }

        if (identitiesFilter.length) {
          const checkers = identitiesFilter.some(identity => {
            const service = this.main?.currentFrame.getServiceById(identity);
            if (!service || !service.labels.length) return false;
            return checkLabels(service.labels);
          });
          if (!checkers) return false;
        }

        return spec.cardsList.some(card => {
          return card.endpointsList.some(endpoint => {
            const fqdnCheck = endpoint.fqdnMatch && checkDns(endpoint.fqdnMatch);
            if (dnsFilter.length && !fqdnCheck) return false;

            const ipCheck = endpoint.cidr?.cidr && checkIp(endpoint.cidr.cidr);
            if (ipsFilter.length && !ipCheck) return false;

            return true;
          });
        });
      });
    });
  }

  get filteredExistingPoliciesList(): PolicyStruct[] {
    return this.filteredPoliciesList.filter(policy => !policy.isPolicyVersion);
  }

  get filteredHistoricalPoliciesList(): PolicyStruct[] {
    return this.filteredPoliciesList.filter(policy => policy.isPolicyVersion);
  }

  get policiesList(): PolicyStruct[] {
    return Array.from(this._policies.values()).sort(
      (a, b) => a.name?.toLocaleLowerCase().localeCompare(b.name?.toLocaleLowerCase() ?? '') ?? 0,
    );
  }

  get policyName(): string | null | undefined {
    return this.currentPolicy?.name;
  }

  get policyNamespace(): string | null | undefined {
    return this.currentPolicy?.namespace ?? this.main?.clusterNamespaces.currNamespace;
  }

  get policyIsClusterwide(): boolean {
    return this.currentPolicy?.isClusterwide ?? false;
  }

  get policyKind() {
    if (!this.currentSpec) return null;
    return this.currentSpec.policyKind;
  }

  get currentSpecsCount() {
    return this.currentSpecs?.length ?? null;
  }

  get isCNP() {
    return this.policyKind === PolicyKind.CNP;
  }

  get isKNP() {
    return this.policyKind === PolicyKind.KNP;
  }

  get currentPolicy() {
    if (!this._controls.policyUuid) return null;
    return this._policies.get(this._controls.policyUuid) ?? null;
  }

  get currentSpecs() {
    return this.currentPolicy?.specs ?? null;
  }

  get currentSpec() {
    if (!this.currentPolicy || !this.currentSpecs) return null;
    return this.currentSpecs[this.currentPolicy.currentSpecIdx];
  }

  get curPolicyKindBuilder() {
    return this.isCNP ? CiliumNetworkPolicyBuilder : KubernetesNetworkPolicyBuilder;
  }

  get policyCnpSpec() {
    if (!this.currentSpec) return null;
    return PolicyStore.genPolicySpec(
      this.policyNamespace,
      this.currentSpec,
      CiliumNetworkPolicyBuilder,
    );
  }

  get policyKnpSpec() {
    if (!this.currentSpec) return null;
    return PolicyStore.genPolicySpec(
      this.policyNamespace,
      this.currentSpec,
      KubernetesNetworkPolicyBuilder,
    );
  }

  get policyCnpSpecs() {
    if (!this.currentSpecs) return null;
    return this.currentSpecs.map(spec => ({
      spec: PolicyStore.genPolicySpec(this.policyNamespace, spec, CiliumNetworkPolicyBuilder),
      unspprtdEgress: spec.originPolicyKind === PolicyKind.CNP ? spec.unspprtdEgress : [],
      unspprtdIngress: spec.originPolicyKind === PolicyKind.CNP ? spec.unspprtdIngress : [],
    }));
  }

  get policyKnpSpecs() {
    if (!this.currentSpecs) return null;
    return this.currentSpecs.map(spec => ({
      spec: PolicyStore.genPolicySpec(this.policyNamespace, spec, KubernetesNetworkPolicyBuilder),
      unspprtdEgress: spec.originPolicyKind === PolicyKind.CNP ? spec.unspprtdEgress : [],
      unspprtdIngress: spec.originPolicyKind === PolicyKind.CNP ? spec.unspprtdIngress : [],
    }));
  }

  get policyCurrentSpecYaml(): string | null {
    if (!this.currentSpec) return null;

    let yaml: string | null;
    if (this.isCNP) {
      yaml = CiliumNetworkPolicyBuilder.generateYaml(
        this.policyName,
        this.policyNamespace,
        [
          {
            spec: this.policyCnpSpec as Rule,
            unspprtdEgress:
              this.currentSpec.originPolicyKind === PolicyKind.CNP
                ? this.currentSpec.unspprtdEgress
                : [],
            unspprtdIngress:
              this.currentSpec.originPolicyKind === PolicyKind.CNP
                ? this.currentSpec.unspprtdIngress
                : [],
          },
        ],
        true,
        this.policyIsClusterwide,
      );
    } else {
      yaml = KubernetesNetworkPolicyBuilder.generateYaml(
        this.policyName,
        this.policyNamespace,
        [
          {
            spec: this.policyKnpSpec as NetworkPolicySpec,
            unspprtdEgress:
              this.currentSpec.originPolicyKind === PolicyKind.KNP
                ? this.currentSpec.unspprtdEgress
                : [],
            unspprtdIngress:
              this.currentSpec.originPolicyKind === PolicyKind.KNP
                ? this.currentSpec.unspprtdIngress
                : [],
          },
        ],
        true,
      );
    }

    return yaml;
  }

  get policySpecsYaml(): string | null {
    return this.isCNP ? this.policyCnpSpecsYaml : this.policyKnpSpecsYaml;
  }

  get policyCnpSpecsYaml(): string | null {
    if (!this.currentPolicy) return null;

    return CiliumNetworkPolicyBuilder.generateYaml(
      this.policyName,
      this.policyNamespace,
      this.policyCnpSpecs as Array<{
        spec: Rule;
        unspprtdEgress: EgressRule[];
        unspprtdIngress: IngressRule[];
      }>,
      this.currentPolicy.isSingleSpec,
      this.currentPolicy.isClusterwide,
    );
  }

  get policyKnpSpecsYaml(): string | null {
    if (!this.currentSpec || !this.currentPolicy) return null;

    return KubernetesNetworkPolicyBuilder.generateYaml(
      this.policyName,
      this.policyNamespace,
      [
        {
          spec: this.policyKnpSpec as NetworkPolicySpec,
          unspprtdEgress:
            this.currentSpec.originPolicyKind === PolicyKind.KNP
              ? this.currentSpec.unspprtdEgress
              : [],
          unspprtdIngress:
            this.currentSpec.originPolicyKind === PolicyKind.KNP
              ? this.currentSpec.unspprtdIngress
              : [],
        },
      ],
      this.currentPolicy.isSingleSpec,
    );
  }
  get allowedFqdnsSet() {
    return new Set(this._allowedFqdns);
  }

  get allowedFqdnsMap() {
    const map = new Map<string, RegExp>();
    this._allowedFqdns.forEach(fqdn => {
      map.set(fqdn, PolicyStore.createFqdnRegexp(fqdn));
    });
    return map;
  }

  get forbiddenFqdns() {
    if (this.allowedFqdnsMap.size === 0) return [];
    const forbidden = new Set<string>();
    this.currentSpec?.cardsMap.egress.forEach(card => {
      if (!card.isOutsideCluster) return;
      card.endpointsList.forEach(endpoint => {
        const { fqdnMatch } = endpoint;
        if (!endpoint.isFQDN || !fqdnMatch) return;
        const isAllowed = PolicyStore.includesFqdn(this.allowedFqdnsMap, fqdnMatch);
        if (isAllowed) return;
        forbidden.add(fqdnMatch);
      });
    });
    return Array.from(forbidden);
  }

  get forbiddenFqdnsMap() {
    const map = new Map<string, RegExp>();
    this.forbiddenFqdns.forEach(fqdn => {
      map.set(fqdn, PolicyStore.createFqdnRegexp(fqdn));
    });
    return map;
  }

  get ignoreFlowLabelsList() {
    return Array.from(this._ignoreFlowLabels);
  }

  get ratingStates() {
    if (!this.currentSpec) return null;
    return this.currentSpec.ratingStates;
  }

  get actualPoints() {
    if (!this.currentSpec) return null;
    return this.currentSpec.actualPoints;
  }

  get policyAvgRating() {
    if (!this.currentSpec) return null;
    return this.currentSpec.policyAvgRating;
  }

  get roundedRating() {
    if (!this.currentSpec) return null;
    return this.currentSpec.roundedRating;
  }

  /* PROXY TO SPEC */
  get specPodSelector() {
    if (!this.currentSpec) return null;
    return this.currentSpec.specPodSelector;
  }

  get defaultDenyIngress() {
    if (!this.currentSpec) return null;
    return this.currentSpec.defaultDenyIngress;
  }

  get defaultDenyEgress() {
    if (!this.currentSpec) return null;
    return this.currentSpec.defaultDenyEgress;
  }

  get hasUnsupportedOriginRules() {
    if (!this.currentSpec) return false;
    return this.currentSpec.hasUnsupportedOriginRules;
  }

  get hasUnsupportedEndpoints() {
    if (!this.currentSpec) return false;
    return this.currentSpec.hasUnsupportedEndpoints;
  }

  get hasUnsupportedRules() {
    if (!this.currentSpec) return false;
    return this.currentSpec.hasUnsupportedRules;
  }

  get hasIngressRules() {
    if (!this.currentSpec) return false;
    return this.currentSpec.hasIngressRules;
  }

  get hasEgressCidrOutsideCluster() {
    if (!this.currentSpec) return false;
    return this.currentSpec.hasEgressCidrOutsideCluster;
  }

  get hasEgressFqdnOutsideCluster() {
    if (!this.currentSpec) return false;
    return this.currentSpec.hasEgressFqdnOutsideCluster;
  }

  get selectorCard() {
    if (!this.currentSpec) return null;
    return this.currentSpec.selectorCard;
  }

  get egressOutsideClusterCard() {
    if (!this.currentSpec) return null;
    return this.currentSpec.egressOutsideClusterCard;
  }

  get egressInNamespaceCard() {
    if (!this.currentSpec) return null;
    return this.currentSpec.egressInNamespaceCard;
  }

  get egressInClusterCard() {
    if (!this.currentSpec) return null;
    return this.currentSpec.egressInClusterCard;
  }

  get ingressOutsideClusterCard() {
    if (!this.currentSpec) return null;
    return this.currentSpec.ingressOutsideClusterCard;
  }

  get ingressInNamespaceCard() {
    if (!this.currentSpec) return null;
    return this.currentSpec.ingressInNamespaceCard;
  }

  get ingressInClusterCard() {
    if (!this.currentSpec) return null;
    return this.currentSpec.ingressInClusterCard;
  }

  get allEndpointsList() {
    return this.currentSpec?.allEndpointsList ?? null;
  }

  get hasSomeRules() {
    if (!this.currentSpec) return false;
    return this.currentSpec.hasSomeRules;
  }

  get hasSomeIngress() {
    if (!this.currentSpec) return false;
    return this.currentSpec.hasSomeIngress;
  }

  get hasSomeEgress() {
    if (!this.currentSpec) return false;
    return this.currentSpec.hasSomeEgress;
  }

  get hasFullEgressOutsideCluster() {
    if (!this.currentSpec) return false;
    return this.currentSpec.hasFullEgressOutsideCluster;
  }

  get hasEgressOutsideClusterToSpecificPorts() {
    if (!this.currentSpec) return false;
    return this.currentSpec.hasEgressOutsideClusterToSpecificPorts;
  }

  get hasEgressOutsideCluster() {
    if (!this.currentSpec) return false;
    return this.currentSpec.hasEgressOutsideCluster;
  }

  get hasFullEgressInCluster() {
    if (!this.currentSpec) return false;
    return this.currentSpec.hasFullEgressInCluster;
  }

  get hasFullEgressInNamespace() {
    if (!this.currentSpec) return false;
    return this.currentSpec.hasFullEgressInNamespace;
  }

  get hasSomeEgressInNamespace() {
    if (!this.currentSpec) return false;
    return this.currentSpec.hasSomeEgressInNamespace;
  }

  get hasSomeEgressSelectorsInNamespace() {
    if (!this.currentSpec) return false;
    return this.currentSpec.hasSomeEgressSelectorsInNamespace;
  }

  get hasSomeEgressInCluster() {
    if (!this.currentSpec) return false;
    return this.currentSpec.hasSomeEgressInCluster;
  }

  get hasSomeEgressSelectorsInCluster() {
    if (!this.currentSpec) return false;
    return this.currentSpec.hasSomeEgressSelectorsInCluster;
  }

  get hasIngressFromOutside() {
    if (!this.currentSpec) return false;
    return this.currentSpec.hasIngressFromOutside;
  }

  get hasIngressFromOutsideToSpecificPorts() {
    if (!this.currentSpec) return false;
    return this.currentSpec.hasIngressFromOutsideToSpecificPorts;
  }

  get hasFullIngressInCluster() {
    if (!this.currentSpec) return false;
    return this.currentSpec.hasFullIngressInCluster;
  }

  get hasFullIngressInNamespace() {
    if (!this.currentSpec) return false;
    return this.currentSpec.hasFullIngressInNamespace;
  }

  get hasSomeIngressInNamespace() {
    if (!this.currentSpec) return false;
    return this.currentSpec.hasSomeIngressInNamespace;
  }

  get hasSomeIngressSelectorsInNamespace() {
    if (!this.currentSpec) return false;
    return this.currentSpec.hasSomeIngressSelectorsInNamespace;
  }

  get hasSomeIngressInCluster() {
    if (!this.currentSpec) return false;
    return this.currentSpec.hasSomeIngressInCluster;
  }

  get hasSomeIngressSelectorsInCluster() {
    if (!this.currentSpec) return false;
    return this.currentSpec.hasSomeIngressSelectorsInCluster;
  }

  get hasEgressRules() {
    if (!this.currentSpec) return false;
    return this.currentSpec.hasEgressRules;
  }

  get isKubeDnsAllowed(): boolean {
    if (!this.currentSpec) return false;
    return this.currentSpec.isKubeDnsAllowed;
  }

  get kubeDnsEndpoint(): PolicyEndpoint | null {
    if (!this.currentSpec) return null;
    return this.currentSpec.kubeDnsEndpoint;
  }

  get isDNSProxyEnabled(): boolean {
    if (!this.currentSpec) return false;
    return this.currentSpec.isDNSProxyEnabled;
  }

  get cardsMap() {
    if (!this.currentSpec) return null;
    return this.currentSpec.cardsMap;
  }

  get cardsList() {
    if (!this.currentSpec) return null;
    return this.currentSpec.cardsList;
  }

  get visibleCardsList() {
    if (!this.currentSpec) return null;
    return this.currentSpec.visibleCardsList;
  }

  get allowedEndpointsSet(): Set<string> | null {
    if (!this.currentSpec) return null;
    return this.currentSpec.allowedEndpointsSet;
  }

  get allPoliciesAllowedEndpointsSet(): Set<string> {
    const set = new Set<string>();
    this.policiesList.forEach(policy => {
      policy.specs.forEach(spec => {
        spec.allowedEndpointsSet.forEach(id => {
          set.add(id);
        });
      });
    });
    return set;
  }

  /* PUBLIC ACTIONS */
  setMainStore = (store: MainStore) => {
    this._main = store;
  };

  getPolicyById = (id: string) => {
    return this._policies.get(id);
  };

  goToNextSpec = () => {
    if (!this.currentPolicy || !this.currentSpecs) return;
    let nextIdx = this.currentPolicy.currentSpecIdx + 1;
    if (nextIdx >= this.currentSpecs.length) nextIdx = 0;
    this.currentPolicy.currentSpecIdx = nextIdx;
    this._controls.selectElement(undefined, undefined);
  };

  goToPrevSpec = () => {
    if (!this.currentPolicy || !this.currentSpecs) return;
    let prevIdx = this.currentPolicy.currentSpecIdx - 1;
    if (prevIdx < 0) prevIdx = this.currentSpecs.length - 1;
    this.currentPolicy.currentSpecIdx = prevIdx;
    this._controls.selectElement(undefined, undefined);
  };

  clearAllPolicies = () => {
    this._policies.clear();
    this._origins.clear();
    this._versions.clear();
  };

  dropForbiddenFqdns = () => {
    this.currentSpec?.cardsMap.egress.forEach(card => {
      if (!card.isOutsideCluster) return;
      card.endpointsList.forEach(endpoint => {
        const { fqdnMatch } = endpoint;
        if (!endpoint.isFQDN || !fqdnMatch) return;
        const isForbidden = PolicyStore.includesFqdn(this.forbiddenFqdnsMap, fqdnMatch);
        if (!isForbidden) return;
        this.setAllowedEndpoint(card.fullEndpointId(endpoint.id), false);
        card.removeEndpoint(endpoint);
      });
    });
  };

  setIgnoreFlowLabels = (values: string[]) => {
    this._ignoreFlowLabels = new Set(values);
    storage.saveIgnoreFlowLabels(this._ignoreFlowLabels);
  };

  addToIgnoreFlowLabels = (val: string) => {
    if (!this._ignoreFlowLabels.has(val)) {
      this._ignoreFlowLabels.add(val);
      storage.saveIgnoreFlowLabels(this._ignoreFlowLabels);
    }
  };

  deleteFromIgnoreFlowLabels = (val: string) => {
    this._ignoreFlowLabels.delete(val);
    storage.saveIgnoreFlowLabels(this._ignoreFlowLabels);
  };

  applyPolicyChanges = (data: PolicySpecChange[]) => {
    data.forEach(p => this.applyPolicyChange(p.policySpec, p.change));
  };

  applyPolicyChange = (data: PolicyData, change: StateChange) => {
    const uid = data.uid || data.policyName + data.policyNamespace + data.type;

    if (change === StateChange.Deleted) {
      this._policies.delete(uid);
      return;
    }

    if (this._policies.has(uid) && !this._policies.get(uid)?.isPolicyVersion) return;

    const isCilium =
      data.type === PolicyDataType.CiliumNetworkPolicy ||
      data.type === PolicyDataType.CiliumClusterwideNetworkPolicy;

    const isClusterwide = data.type === PolicyDataType.CiliumClusterwideNetworkPolicy;

    const apiVersion = isCilium ? 'cilium.io/v2' : 'networking.k8s.io/v1';

    const kind = isClusterwide
      ? 'CiliumClusterwideNetworkPolicy'
      : isCilium
        ? 'CiliumNetworkPolicy'
        : 'NetworkPolicy';

    const yaml = `apiVersion: ${apiVersion}
kind: ${kind}
metadata:
  name: ${data.policyName}
${isClusterwide ? '' : `namespace: ${data.policyNamespace}`}
${data.yaml}`;

    this.loadAsNewPolicy(
      { uid: uid, policyObj: yaml },
      { autoselect: false, isHistoricalVersion: false },
    );
  };

  createPolicyBySnapshot = (
    snapshot: Partial<PolicyInfoSnapshot>,
  ):
    | { ok: true; parseInfo: ParsePolicyYAMLResult; policy: PolicyStruct }
    | { ok: false; errors: string[] } => {
    if (!snapshot.policyObj) {
      return { ok: false, errors: ['Invalid policy object'] };
    }

    const parseInfo = PolicyStore.parseStr(snapshot.policyObj, snapshot.k8sEventPolicyKind);
    if (!parseInfo.ok) {
      return { ok: false, errors: parseInfo.errors };
    }

    const isClusterwide = parseInfo.policyKind === PolicyKind.CNP ? parseInfo.isClusterwide : false;

    const newSpecs = parseInfo.results.map((result, specIdx) => {
      const spec = new SpecStore({
        controls: this._controls,
        originPolicyKind: snapshot.originPolicyKind ?? parseInfo.policyKind,
        defaultDenyEgress: snapshot.defaultDenyEgresses?.[specIdx] ?? result.defaultDenyEgress,
        defaultDenyIngress: snapshot.defaultDenyIngresses?.[specIdx] ?? result.defaultDenyIngress,
        unspprtdEgress: snapshot.unspprtdEgresses?.[specIdx] ?? result.unspprtdEgress,
        unspprtdIngress: snapshot.unspprtdIngresses?.[specIdx] ?? result.unspprtdIngress,
        cards: createInitialCards(isClusterwide),
        autoStartCardReactions: false,
        isClusterwide,
      });

      result.cards.forEach(card => {
        if (card.isIngress || card.isEgress) {
          spec.setCard(card);
          card.endpointsMap.forEach(endpoint => {
            spec.setAllowedEndpoint(card.fullEndpointId(endpoint.id), true);
          });
          if (!card.firstEndpoint?.isAllWithoutPorts) {
            card.addEndpoints(PolicyEndpoint.newAll());
          }
        } else if (card.isSelector && card.podSelector) {
          spec.selectorCard.setPodSelector(card.podSelector);
        }
      });

      if (!spec.kubeDnsEndpoint || !isClusterwide) {
        const kubedns = PolicyEndpoint.newKubeDns().enableDNSProxy();
        spec.egressInClusterCard.addEndpoints(kubedns);
      }

      return spec.startCardsReactions();
    });

    const policy: PolicyStruct = {
      id: snapshot.uid ?? ControlStore.policyIdGenerator(),
      name: parseInfo.policyName || 'untitled-policy',
      namespace: parseInfo.policyNamespace,
      isSingleSpec: parseInfo.isSingleSpec,
      isPolicyVersion: snapshot.isPolicyVersion ?? false,
      isClusterwide,
      specs: observable.array(newSpecs),
      currentSpecIdx: 0,
    };

    return { ok: true, parseInfo, policy };
  };

  loadAsNewPolicy = (
    snapshot: Partial<PolicyInfoSnapshot>,
    opts: { autoselect: boolean; isHistoricalVersion: boolean },
  ): { ok: true } | { ok: false; errors: string[] } => {
    if (!snapshot.policyObj) {
      return { ok: false, errors: ['Invalid policy YAML'] };
    }

    const parsed = this.createPolicyBySnapshot(snapshot);
    if (!parsed.ok || !parsed.parseInfo.ok) return parsed;

    this._policies.set(parsed.policy.id, parsed.policy);
    if (!opts.isHistoricalVersion) {
      this._origins.set(parsed.policy.id, snapshot.policyObj);
    }

    if (opts.autoselect) {
      this._controls.setPolicyUuid(parsed.policy.id);
      this._controls.setPolicyKind(snapshot.lastPolicyKind ?? parsed.parseInfo.policyKind);
    }

    return { ok: true };
  };

  restoreOrigin = (uid: string) => {
    return this.loadAsNewPolicy(
      { uid, policyObj: this._origins.get(uid) },
      { autoselect: true, isHistoricalVersion: false },
    );
  };

  downloadYaml = () => {
    if (!this.policyKind || !this.policySpecsYaml || !this.currentSpecs) return;
    track(AnalyticsTrackKind.DownloadPolicyYaml, {
      policyKind: this.policyKind,
      multiSpec: this.currentSpecs.length > 0,
      rulesCnt: this.currentSpecs.reduce((cnt, spec) => cnt + spec.allowedEndpointsSet.size, 0),
      specsStats: this.currentSpecs.map(spec => spec.ratingStates),
    });
    const filename = (this.policyName ?? 'policy') + '.yaml';
    const base64 = btoa(this.policySpecsYaml);
    downloadTextAsFile(filename, `base64,${base64}`, 'text/plain');
  };

  createNew = () => {
    const namespace = this.main?.clusterNamespaces.currNamespace;
    const writeNamespace = Boolean(namespace);
    const metadata = writeNamespace
      ? `metadata:
  namespace: ${namespace}`
      : 'metadata: {}';

    this.isKNP
      ? this.loadAsNewPolicy(
          {
            uid: ControlStore.policyIdGenerator(),
            policyObj: `apiVersion: networking.k8s.io/v1
kind: NetworkPolicy
${metadata}`,
          },
          { autoselect: true, isHistoricalVersion: false },
        )
      : this.loadAsNewPolicy(
          {
            uid: ControlStore.policyIdGenerator(),
            policyObj: `apiVersion: cilium.io/v2
kind: CiliumNetworkPolicy
${metadata}`,
          },
          { autoselect: true, isHistoricalVersion: false },
        );
  };

  setPolicyVersions(uuid: string, events: K8SEvent[]) {
    const sorted = [...events].sort(
      (a, b) => (b.timestamp?.valueOf() ?? 0) - (a.timestamp?.valueOf() ?? 0),
    );
    this._versions.set(uuid, sorted);
  }

  addPoliciesFromEvents(events: K8SEvent[]) {
    // NOTE: Effectively parse/filter/group received events
    // NOTE: Timescape will allow to offload this with additional API
    const policiesToAdd = events.reduce(
      (acc, event) => {
        if (+(acc[event.uuid]?.event.timestamp ?? 0) >= +(event.timestamp ?? 0)) return acc;
        if (this._policies.has(event.uuid)) return acc;

        const parsed = this.createPolicyBySnapshot({
          uid: event.uuid,
          policyObj: event.raw.object,
          k8sEventPolicyKind: event.kind,
          isPolicyVersion: true,
        });
        if (!parsed.ok) {
          logger.error(parsed.errors);
          return acc;
        }

        acc[parsed.policy.id] = {
          policy: parsed.policy,
          event,
        };

        return acc;
      },
      {} as { [key: string]: { policy: PolicyStruct; event: K8SEvent } },
    );

    this._policies.merge(
      Object.values(policiesToAdd).reduce(
        (acc, { policy }) => {
          acc[policy.id] = policy;
          return acc;
        },
        {} as { [key: string]: PolicyStruct },
      ),
    );
  }

  getPolicyVersions(policyUuid?: string | null | undefined): K8SEvent[] {
    if (!policyUuid) return [];
    return this._versions.get(policyUuid) ?? [];
  }

  getPolicyVersion(
    policyUuid?: string | null | undefined,
    policyVersionShortId?: string | null | undefined,
  ): K8SEvent | null {
    if (!policyUuid || !policyVersionShortId) return null;
    const versions = this._versions.get(policyUuid) ?? [];
    const version = versions.find(v => v.shortId === policyVersionShortId);
    return version ?? null;
  }

  // PROXY TO SPEC
  getCardBy = (cardSide: CardSide, cardKind: CardKind): PolicyCard | null => {
    if (!this.currentSpec) return null;
    return this.currentSpec.getCardBy(cardSide, cardKind);
  };

  setCard = (card: PolicyCard): void => {
    if (!this.currentSpec) return;
    return this.currentSpec.setCard(card);
  };

  setAllowedEndpoint = (endpointId: string, state: boolean): void => {
    if (!this.currentSpec) return;
    return this.currentSpec.setAllowedEndpoint(endpointId, state);
  };

  setSpecPodSelector = (selector: EndpointSelector): void => {
    if (!this.currentSpec) return;
    return this.currentSpec.setSpecPodSelector(selector);
  };

  setCurrentPolicyName = (name: string): void => {
    if (!this.currentPolicy) return;
    this.currentPolicy.name = name;
  };

  setCurrentPolicyNamespace = (namespace: string): void => {
    if (!this.currentPolicy) return;
    this.currentPolicy.namespace = namespace;
  };

  allowFullInNamespaceEgress = (): void => {
    if (!this.currentSpec) return;
    return this.currentSpec.allowFullInNamespaceEgress();
  };

  denyFullInNamespaceEgress = (): void => {
    if (!this.currentSpec) return;
    return this.currentSpec.denyFullInNamespaceEgress();
  };

  allowFullInClusterEgress = (): void => {
    if (!this.currentSpec) return;
    return this.currentSpec.allowFullInClusterEgress();
  };

  denyFullInClusterEgress = (): void => {
    if (!this.currentSpec) return;
    return this.currentSpec.denyFullInClusterEgress();
  };

  allowFullInNamespaceIngress = (): void => {
    if (!this.currentSpec) return;
    return this.currentSpec.allowFullInNamespaceIngress();
  };

  denyFullInNamespaceIngress = (): void => {
    if (!this.currentSpec) return;
    return this.currentSpec.denyFullInNamespaceIngress();
  };

  allowFullInClusterIngress = (): void => {
    if (!this.currentSpec) return;
    return this.currentSpec.allowFullInClusterIngress();
  };

  denyFullInClusterIngress = (): void => {
    if (!this.currentSpec) return;
    return this.currentSpec.denyFullInClusterIngress();
  };

  cleanupEgressWorld = (): void => {
    if (!this.currentSpec) return;
    return this.currentSpec.cleanupEgressWorld();
  };

  toggleDefaultDenyIngress = (): void => {
    if (!this.currentSpec) return;
    return this.currentSpec.toggleDefaultDenyIngress();
  };

  enableDefaultDenyIngress = (): void => {
    if (!this.currentSpec) return;
    return this.currentSpec.enableDefaultDenyIngress();
  };

  disableDefaultDenyIngress = (): void => {
    if (!this.currentSpec) return;
    return this.currentSpec.disableDefaultDenyIngress();
  };

  allowKubeDns = (): void => {
    if (!this.currentSpec) return;
    return this.currentSpec.allowKubeDns();
  };

  denyKubeDns = (): void => {
    if (!this.currentSpec) return;
    return this.currentSpec.denyKubeDns();
  };

  enableDNSProxy = (): void => {
    if (!this.currentSpec) return;
    return this.currentSpec.enableDNSProxy();
  };

  disableDNSProxy = (): void => {
    if (!this.currentSpec) return;
    return this.currentSpec.disableDNSProxy();
  };

  toggleDefaultDenyEgress = (): void => {
    if (!this.currentSpec) return;
    return this.currentSpec.toggleDefaultDenyEgress();
  };

  disableDefaultDenyEgress = (): void => {
    if (!this.currentSpec) return;
    return this.currentSpec.disableDefaultDenyEgress();
  };

  enableDefaultDenyEgress = (): void => {
    if (!this.currentSpec) return;
    return this.currentSpec.enableDefaultDenyEgress();
  };

  toggleAllowedEnpoint = (endpointId: string): boolean => {
    if (!this.currentSpec) return false;
    return this.currentSpec.toggleAllowedEnpoint(endpointId);
  };

  isAllowedEndpoint = (endpointId: string): boolean => {
    if (!this.currentSpec) return false;
    return this.currentSpec.isAllowedEndpoint(endpointId);
  };

  isVisibleCard = (card: PolicyCard): boolean => {
    if (!this.currentSpec) return false;
    return this.currentSpec.isVisibleCard(card);
  };

  getRuleStatusInfo = (card: PolicyCard, endpoint: PolicyEndpoint): RuleStatusInfo | null => {
    if (!this.currentSpec) return null;
    return this.currentSpec.getRuleStatusInfo(card, endpoint);
  };

  flowsToCardEndpoints = (
    flows: Flow[],
  ): {
    cardSide: CardSide;
    cardKind: CardKind;
    endpoints: PolicyEndpoint[];
  }[] => {
    const r: {
      cardSide: CardSide;
      cardKind: CardKind;
      endpoints: PolicyEndpoint[];
    }[] = [];
    flows.forEach(flow => r.push(...this.flowToCards(flow)));
    return r;
  };

  flowToCards = (
    flow: Flow,
  ): {
    cardSide: CardSide;
    cardKind: CardKind;
    endpoints: PolicyEndpoint[];
  }[] => {
    if (flow.isReply) return [];
    if (flow.type !== FlowType.L34 && flow.type !== FlowType.L7) return [];
    if (flow.trafficDirection === TrafficDirection.Unknown) return [];
    if (!flow.destinationPort) return [];
    if (
      flow.sourceNamespace !== this.policyNamespace &&
      flow.destinationNamespace !== this.policyNamespace
    ) {
      return [];
    }
    if (flow.isKubeDnsFlow) return [];
    const dstPort = String(flow.destinationPort);

    const ignore = Array.from(this._ignoreFlowLabels);
    const filterLabel = (label: string) => {
      return ignore.every(key => !label.includes(key));
    };

    const flowToConvert = new Flow({
      ...flow.ref,
      ...(flow.ref.source
        ? {
            source: {
              ...flow.ref.source,
              labels: flow.ref.source.labels.filter(filterLabel),
            },
          }
        : {}),
      ...(flow.ref.destination
        ? {
            destination: {
              ...flow.ref.destination,
              labels: flow.ref.destination.labels.filter(filterLabel),
            },
          }
        : {}),
    });

    if (flowToConvert.trafficDirection === TrafficDirection.Egress) {
      if (flowToConvert.isKubeDnsFlow) {
        return [
          {
            cardSide: CardSide.Egress,
            cardKind: CardKind.OutsideCluster,
            endpoints: [PolicyEndpoint.newKubeDns()],
          },
        ];
      }

      if (flowToConvert.isFqdnFlow) {
        const endpoints: PolicyEndpoint[] = [];
        flowToConvert.destinationNamesList.forEach(fqdn => {
          const endpoint = PolicyEndpoint.fromFQDNString(fqdn);
          endpoint.addPortsFromStringArray([dstPort]);
          endpoints.push(endpoint);
        });
        return [
          {
            cardSide: CardSide.Egress,
            cardKind: CardKind.OutsideCluster,
            endpoints,
          },
        ];
      }

      if (flowToConvert.isIpFlow) {
        const endpoint = PolicyEndpoint.newAll().addPortsFromStringArray([dstPort]);
        return [
          {
            cardSide: CardSide.Egress,
            cardKind: CardKind.OutsideCluster,
            endpoints: [endpoint],
          },
        ];
      }

      if (
        flowToConvert.destinationLabels.length > 0 &&
        flowToConvert.sourceNamespace &&
        flowToConvert.sourceNamespace === this.policyNamespace
      ) {
        return this.labelsFlowToEndpoints({
          cardSide: CardSide.Egress,
          labels: flowToConvert.destinationLabels,
          port: dstPort,
        });
      }
    }

    if (flowToConvert.trafficDirection === TrafficDirection.Ingress) {
      if (flowToConvert.isIpFlow) {
        const endpoint = PolicyEndpoint.newAll().addPortsFromStringArray([dstPort]);
        return [
          {
            cardSide: CardSide.Ingress,
            cardKind: CardKind.OutsideCluster,
            endpoints: [endpoint],
          },
        ];
      }

      if (
        flowToConvert.sourceLabels.length > 0 &&
        flowToConvert.destinationNamespace &&
        flowToConvert.destinationNamespace === this.policyNamespace
      ) {
        return this.labelsFlowToEndpoints({
          cardSide: CardSide.Ingress,
          labels: flowToConvert.sourceLabels,
          port: dstPort,
        });
      }
    }

    return [];
  };

  /* PRIVATE ACTIONS */
  private setupReactions = () => {
    autorun(() => {
      this.currentSpecs?.forEach(spec => {
        spec.cardsList.forEach(card => {
          if (card.isInNamespace || card.isSelector) {
            card.setNamespace(this.policyNamespace);
          }
        });
      });
    });
  };

  private labelsFlowToEndpoints = ({
    cardSide,
    labels,
    port,
  }: {
    cardSide: CardSide;
    labels: KV[];
    port: string;
  }): {
    cardSide: CardSide;
    cardKind: CardKind;
    endpoints: PolicyEndpoint[];
  }[] => {
    if (Labels.haveReserved(labels)) return [];

    const namespace = Labels.findNamespaceInLabels(labels);
    const isOnlyNamespaceLabel = namespace && labels.length === 1;

    const matchLabels = labels.reduce<{
      [key: string]: string;
    }>((obj, { key, value }) => ({ ...obj, [key]: value }), {});

    const endpointKind = isOnlyNamespaceLabel
      ? EndpointKind.NamespaceSelector
      : EndpointKind.LabelsSelector;

    const endpoint = PolicyEndpoint.fromKind(endpointKind)
      .setSelector({ matchLabels })
      .addPortsFromStringArray([port]);

    return [
      {
        cardSide: cardSide,
        cardKind: CardKind.InCluster,
        endpoints: [endpoint],
      },
    ];
  };

  private initAllowedFqdns = () => {
    const hash = window?.location.hash.substring(1);
    const params: { [key: string]: string } = {};
    hash?.split('&').map(hk => {
      const temp = hk.split('=');
      params[temp[0]] = temp[1];
    });
    const fromurl = params['allow-only-fqdns'];
    if (!fromurl) return (this._allowedFqdns = []);
    if (Array.isArray(fromurl)) return (this._allowedFqdns = fromurl);
    return (this._allowedFqdns = [fromurl]);
  };

  /* PRIVATE STATIC */
  private static includesFqdn = (map: Map<string, RegExp>, testFqdn: string) => {
    if (map.has(testFqdn)) return true;
    for (const [fqdn, regexp] of map) {
      if (!fqdn.includes('*')) continue;
      if (regexp.test(testFqdn)) return true;
    }
    return false;
  };

  private static createFqdnRegexp(fqdn: string) {
    return new RegExp(fqdn.replace('*', '(?:.+)'));
  }

  @memoize
  private static getCNPValidator() {
    const ajv = new AJV({ allErrors: true, allowUnionTypes: true });
    addAJVFormats(ajv);
    return { validate: ajv.compile(cnpSchema.schema), ajv };
  }

  @memoize
  private static getKNPValidator() {
    const ajv = new AJV({ allErrors: true });
    addAJVFormats(ajv);
    return { validate: ajv.compile(knpSchema.schema), ajv };
  }

  private static genPolicySpec(
    policyNamespace: string | null | undefined,
    spec: SpecStore,
    builder: typeof CiliumNetworkPolicyBuilder | typeof KubernetesNetworkPolicyBuilder,
  ) {
    const srcs = new Map<string, PolicyCard>();
    const dsts = new Map<string, PolicyCard>();

    spec.allowedEndpointsSet.forEach((id: string) => {
      const [cardId, endpointId] = PolicyCard.parseFullCardEndpointId(id);

      const src = spec.cardsMap.ingress.get(cardId);
      if (src) {
        const endpoint = src.endpointsMap.get(endpointId);
        if (!endpoint) return;
        const card = srcs.get(cardId) ?? src.clone().flushEndpoints();
        card.addEndpoints(endpoint);
        srcs.set(cardId, card);
        return;
      }

      const dst = spec.cardsMap.egress.get(cardId);
      if (dst) {
        const endpoint = dst.endpointsMap.get(endpointId);
        if (!endpoint) return;
        const card = dsts.get(cardId) ?? dst.clone().flushEndpoints();
        card.addEndpoints(endpoint);
        dsts.set(cardId, card);
        return;
      }
    });

    return builder.generateSpec(
      spec.selectorCard.podSelector,
      srcs,
      dsts,
      spec.defaultDenyIngress,
      spec.defaultDenyEgress,
      policyNamespace,
    );
  }
}
