import React, { Suspense } from 'react';

import { NonIdealState, Spinner, Text } from '@blueprintjs/core';
import { observer } from 'mobx-react';
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer';

import { K8SEvent } from '~/domain/timescape/k8s-events';
import { jsonToYaml } from '~/utils/converters';
import { logger } from '~/utils/logger';

import css from './styles.scss';
import type { Props } from './YAMLEditor';

export const Editor = React.lazy(() => import('./YAMLEditor'));

export type OuterProps = Props & { compare?: boolean; policyVersion?: K8SEvent | null | undefined };

export const YAMLEditor = observer((props: OuterProps) => {
  if (props.compare) {
    return (
      <DiffViewer
        policyVersion={props.policyVersion}
        isDarkThemeEnabled={props.isDarkThemeEnabled}
      />
    );
  }

  return (
    <ErrorBoundary>
      <Suspense
        fallback={<NonIdealState icon={<Spinner size={100} />} title="Loading editor..." />}
      >
        <Editor {...props} />
      </Suspense>
    </ErrorBoundary>
  );
});

class ErrorBoundary extends React.Component<React.PropsWithChildren<{}>> {
  state = { hasError: false };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: unknown, errorInfo: unknown) {
    logger.error("Can't load YAML editor", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <NonIdealState icon="warning-sign" title="Can't load policy YAML" />;
    }
    return this.props.children;
  }
}

interface DiffViewerProps {
  policyVersion?: K8SEvent | null | undefined;
  isDarkThemeEnabled: boolean;
}

const DiffViewer = observer((props: DiffViewerProps) => {
  const oldValue = React.useMemo(() => {
    if (!props.policyVersion?.raw.old_object) return undefined;
    return jsonToYaml(props.policyVersion.raw.old_object);
  }, [props.policyVersion?.raw.old_object]);

  const newValue = React.useMemo(() => {
    if (!props.policyVersion?.raw.object) return undefined;
    return jsonToYaml(props.policyVersion.raw.object);
  }, [props.policyVersion?.raw.object]);

  if (!props.policyVersion) {
    return <Text>Nothing to compare</Text>;
  }
  return (
    <div className={css.diffWrapper}>
      <ReactDiffViewer
        oldValue={oldValue}
        newValue={newValue}
        splitView={false}
        compareMethod={DiffMethod.WORDS_WITH_SPACE}
        useDarkTheme={props.isDarkThemeEnabled}
        styles={{}}
      />
    </div>
  );
});
