import { Store } from '~/store';
import { EventEmitter } from '~/utils/emitter';

import { Options } from './common';

export enum Event {}
export type Handlers = {};

export class Cimulator extends EventEmitter<Handlers> {
  private store: Store;

  constructor(opts: Options) {
    super(true);

    this.store = opts.store;
  }
}
