import React from 'react';

import { createBrowserRouter, createMemoryRouter, RouterProvider } from 'react-router-dom';

import { CimulatorApp } from '~/components/CimulatorApp';
import { Dashboard } from '~/components/Dashboard';
import { ProcessTreeApp } from '~/components/ProcessTreeApp';
import { ServiceMapApp } from '~/components/ServiceMapApp';
import { SSRErrorPage } from '~/components/SSRErrorPage';

import { ApplicationPath, Router } from './router';

export type Props = {
  router: Router;
  RootComponent: JSX.Element;
};

export const Routes = function Routes(props: Props) {
  const createRouter = props.router.isInMemory ? createMemoryRouter : createBrowserRouter;

  const router = createRouter([
    {
      path: '/',
      element: props.RootComponent,
      children: [
        {
          path: '', //ApplicationPath.Dashboard,
          element: <Dashboard />,
        },
        {
          path: ApplicationPath.ErrorPage,
          element: <SSRErrorPage />,
        },
        {
          path: ApplicationPath.Cimulator,
          element: <CimulatorApp />,
        },
        {
          path: ApplicationPath.ProcessTree,
          element: <ProcessTreeApp />,
        },
        {
          path: ApplicationPath.ConnectionsMap,
          element: <ServiceMapApp />,
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
};
