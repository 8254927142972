import React, { FunctionComponent, useEffect, useRef } from 'react';

import * as d3 from 'd3';
import { observer } from 'mobx-react';

import { utils as gutils, Line2, Vec2 } from '~/domain/geometry';
import {
  ArrowColor,
  ArrowPath,
  ArrowPathsMap,
  EndingFigure,
} from '~/domain/layout/abstract/arrows';
import { colors, sizes } from '~/ui/vars';
import { chunks } from '~/utils/iter-tools';

export interface Props {
  arrows: ArrowPathsMap;
}

interface FigureData {
  id: string;
  arrow: ArrowPath;
  isStart: boolean;
  figure: EndingFigure;
  color: ArrowColor;
  coords: Vec2;
  direction: Vec2;
}

interface ArrowData {
  color: ArrowColor;
  points: Array<Vec2>;
  handles: [Vec2, Vec2][];
}

type RenderingArrow = [string, ArrowData];

// NOTE: returns stroke and fill colors
const figureColorProps = (fd: FigureData): [string, string] => {
  if (fd.figure === EndingFigure.Plate) {
    switch (fd.color) {
      case ArrowColor.Neutral:
        return [colors.startPlateStroke, colors.startPlateFill];
      case ArrowColor.Red:
        return [colors.connectorStrokeRed, colors.connectorFillRed];
      case ArrowColor.Green:
        return [colors.connectorStrokeGreen, colors.connectorFillGreen];
    }
  } else if (fd.figure === EndingFigure.Arrow) {
    switch (fd.color) {
      case ArrowColor.Neutral:
        return [colors.connectorStroke, colors.connectorStroke];
      case ArrowColor.Red:
        return [colors.connectorStrokeRed, colors.connectorStrokeRed];
      case ArrowColor.Green:
        return [colors.connectorStrokeGreen, colors.connectorStrokeGreen];
    }
  }

  return [colors.arrowStroke, colors.arrowStroke];
};

const arrowLine = (points: Vec2[]): string => {
  if (points.length < 2) return '';

  if (points.length === 2) {
    const [a, b] = points;
    return `M ${a.x} ${a.y} L${b.x} ${b.y}`;
  }

  const first = points[0];
  const last = points[points.length - 1];
  const r = sizes.arrowRadius;

  let line = `M ${first.x} ${first.y}`;

  chunks(points, 3, 2).forEach((chunk: Vec2[]) => {
    const [a, b, c] = chunk;
    let [d, e, angle] = gutils.roundCorner(r, [a, b, c]);

    // This case occurs much more rarely than others, so using roundCorner
    // one more time is ok since angle computaion is part of entire function
    if (angle < Math.PI / 4) {
      [d, e, angle] = gutils.roundCorner(r * Math.sin(angle), [a, b, c]);
    }

    const ab = Vec2.from(b.x - a.x, b.y - a.y);
    const bc = Vec2.from(c.x - b.x, c.y - b.y);
    const sweep = ab.isClockwise(bc) ? 0 : 1;

    line += `
      L ${d.x} ${d.y}
      A ${r} ${r} 0 0 ${sweep} ${e.x} ${e.y}
    `;
  });

  line += `L ${last.x} ${last.y}`;
  return line;
};

const startPlatePath = (d: any) => {
  const { x, y } = d.coords;

  // prettier-ignore
  const r = 3, w = 5, h = 20;
  const tr = `a ${r} ${r} 0 0 1 ${r} ${r}`;
  const br = `a ${r} ${r} 0 0 1 -${r} ${r}`;

  return `
    M ${x - 1} ${y - h / 2}
    h ${w - r}
    ${tr}
    v ${h - 2 * r}
    ${br}
    h -${w - r}
    z
  `;
};

const generalExit = (exit: any) => {
  exit.remove();
};

const figureFillColor = (fd: FigureData): string => {
  const [, fill] = figureColorProps(fd);
  return fill;
};

const figureStrokeColor = (fd: FigureData): string => {
  const [stroke] = figureColorProps(fd);
  return stroke;
};

const startPlatesEnter = (enter: any) => {
  return enter
    .append('path')
    .attr('fill', colors.connectorFill)
    .attr('stroke', figureStrokeColor)
    .attr('stroke-width', sizes.linkWidth)
    .attr('d', startPlatePath);
};

const startPlatesUpdate = (update: any) => {
  // XXX: why d3.select('g path').attr(...) is not working?
  return update.each((_: any, i: any, e: any) => {
    d3.select(e[i])
      .select('path')
      .attr('d', startPlatePath)
      .attr('stroke', figureStrokeColor as any);
  });
};

const arrowTriangle = (handle: [Vec2, Vec2] | null): string => {
  if (handle == null) return '';

  const [start, end] = handle;
  const width = start.distance(end);

  const line = Line2.throughPoints(start, end);
  const side = line.normal.mul(width / 2);

  const baseA = start.add(side);
  const baseB = start.sub(side);

  const criteria = (baseA.x - baseB.x) * (end.y - start.y);
  const sweep = criteria <= 0 ? 0 : 1;

  const r = 2;
  const [ar1, ar2] = gutils.roundCorner(r, [start, baseA, end]);
  const [br1, br2] = gutils.roundCorner(r, [start, baseB, end]);
  const [er1, er2] = gutils.roundCorner(r, [baseA, end, baseB]);

  return `
    M ${start.x} ${start.y}
    L ${ar1.x} ${ar1.y}
    A ${r} ${r} 0 0 ${sweep} ${ar2.x} ${ar2.y}
    L ${er1.x} ${er1.y}
    A ${r} ${r} 0 0 ${sweep} ${er2.x} ${er2.y}
    L ${br2.x} ${br2.y}
    A ${r} ${r} 0 0 ${sweep} ${br1.x} ${br1.y}
    Z
  `;
};

const arrowStrokeColor = (ad: RenderingArrow) => {
  switch (ad[1].color) {
    case ArrowColor.Neutral:
      return colors.arrowStroke;
    case ArrowColor.Red:
      return colors.arrowStrokeRed;
    case ArrowColor.Green:
      return colors.arrowStrokeGreen;
  }
};

const arrowsEnter = (enter: any) => {
  const arrowGroup = enter.append('g').attr('class', (d: RenderingArrow) => d[0]);

  arrowGroup
    .append('path')
    .attr('class', 'line')
    .attr('stroke', arrowStrokeColor)
    .attr('stroke-width', sizes.linkWidth)
    .attr('fill', 'none')
    .attr('d', (d: RenderingArrow) => arrowLine(d[1].points));

  return arrowGroup;
};

const arrowsUpdate = (update: any) => {
  update
    .select('path.line')
    .attr('d', (d: RenderingArrow) => arrowLine(d[1].points))
    .attr('stroke', arrowStrokeColor);

  return update;
};

const trianglePropsSet = (path: any) => {
  const triangleW = sizes.arrowHandleWidth;

  return path
    .attr('fill', figureFillColor)
    .attr('stroke', figureStrokeColor)
    .attr('class', 'triangle-path')
    .attr('d', (fd: FigureData) => {
      const triangleStart = fd.coords.sub(fd.direction.mul(triangleW));
      const triangleEnd = fd.coords;

      return arrowTriangle([triangleStart, triangleEnd]);
    });
};

const statusIconPropsSet = (image: any) => {
  return image
    .attr('class', 'status-icon')
    .attr('width', 14)
    .attr('height', 14)
    .attr('x', (fd: FigureData) => {
      if (fd.id.startsWith('egress')) {
        return fd.arrow.end.coords.x - 45;
      }
      return fd.arrow.start.coords.x + 20;
    })
    .attr('y', (fd: FigureData) => {
      if (fd.id.startsWith('egress')) {
        return fd.arrow.end.coords.y - 7;
      }
      return fd.arrow.start.coords.y - 7;
    })
    .attr('xlink:href', (fd: FigureData) => {
      if (fd.color === ArrowColor.Red) {
        return 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAJZSURBVHgB5VdNbtNAFP6atmsiIXUdFt0hUZZIUNwbhBPYOQHcAI4AJ3B6AnIDpxCJZROxowvcJVmgsmBR8eO+L/G0z+NxZ+x2UamfZM145pv55ue952fgvmGjDfkzEEkxlEEvC2AgZZ/tUj+T+lzKhbxOXgBT31xBwp+ARIhv5RmE8GUBuXDfPQcO0UU4E6Ft4KNU99ABXMBf4OBASruv1zToCxBvAcddRYmN9cKP5cSGjr46SOytd3pr2JTreqaOvrbjbG00qd3+IIoQChf3H/A+UzZSE5bjzYy1GuymKR5nGXbiGD6QQy7HWOhvN50irXcmNqGfH2laaHyL48LmmId9Ghzr4NVXL376XZO+RlHhgkvcFjXgHJpHDWptKtFIjviNXsh5nuP89BQPh1Wj5Dv7fi8Wl8e7Ox7XNnIyGuHnZFJp4zWOgKMt1VYzeWJZTmjfmRZqEl062o3WpTvNVga9ColO7CSJy2Cc8IgSc23V1wYKTsQJb0GUGGjhvo/tEw8UXWn10BZF0a3PghY+85GbrNeAfSFBhlpaOMcNRFuKXxmXHNIRWoryTl137hNnwqD9mJ7+uo2oNqQmP18eOnOBalQJDplJUg+Z0tYmZFas+j/wQb//mk5rK25yGZercSzn0GBKVJZVzBxZh7mzED81EY6iJ1LXYCokieAjp3CZZ1G8ElD4cbdX3wQXl5mo5F9PTf7VlPokPUcWchPINb7aV0bljFxCGJOIgKDiQ7GeY7RvWbI3vS1ToQG6Yf5HNuBKb+9mQm9D/cI8kcn3rF+YvIx+Qb8wF7apobD6EbtQAAAAAElFTkSuQmCC';
      }
      return 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAO5SURBVHgBpVZNTFNBEJ7dVgLRSEEiAZQUiXqQUprQm4gkhItEIF6IHEAPSr34c1TAij9Hr+1J8GAiB0M5cCEkSupNksrfQdRYDWAgBlpi0gboW2e2f++9/uOXtN03u93vzex8s8MgDzh9g5cPOHRzzlpBKGYAZorOiIAA9lkIWDBy8Dgt7g+59mLZJkcWbt8Fxu7h0Az5wQ+cOUcbXK+hEEL0yKwY2SQI0QSHg59HoM1pc/v1E1xvGFlw9CsG4fsPMoKZ9hhadHTrJzQe0gIOYlK/qJgXg6XcDrVH66GypAZKj5RJe3B/BzZD67C6uwxLO/OQFoINjFqTIU4QyjCSZ4mEiOLc8Qtw5VQvFBtKIBuCe9vg3ZpJQywCPMJs8fAa42bFAO/1ZO3VXWA/0QL5oLSoHDrxxUz4692cUc0wk8wHABs9yTMcXhwcAF0mtlR25E2mxsWTHdBedVVrxHwYWXb0Jwgxro/V85ayZvnHQkAhpQ/BXnEJj6JBu0ARTsn1CEVtkOFM4s75hzJEhZC9+eGS4746h/xvOBIC15cX8jcOzqCNG7jQpC55dxiy4N6O/Lz7OS5JKMkspmbN2gMFq5VgzKo2poQiC0gSr769lEQJW3gD5v945bj2WL1mPUqu1chI4Cwpx8riGs2i2Y0pCCshmYFqkPam196iN2GNvZHOv7Ijtle19g0ZmI1IppFCaVFU1BQW2nB1dyVqP1IuM5ewiFqjOT1oXp1sqUfDTEbIgOm1iQQZ4SOKms6FXoTGucgywYgaCai9pPMgL0lLdEZUvuKY/T2VdpNMBWIztKGzYNXBk/RrFoXX5S+F45r5Rs6S1nm6N2OBCO5va+nw7uSgwJza+Ovv98SYDj2lasRABb3vjCMl9dWgxNIQ4kXNIwrzqI1LgXmNWC1l9kSyqMmuIxndHplA+tQXcuoK+HMbtgUiGdZodk5oFlIy2CuiYaOr6ebZB/KaygavPrGQg1oQWUsFgyfqOQrFp5h442iv6pJViMKYqxLRbZF6TTGn/I4/Yv/iw2xt0numD2cu0IumZDN6N2p119EwoUOusB6FC59aIqQ3yrQWJM7llb5QJMlQCgpriz9qWoxh3+AAM8BYug0pnFRniThesgKo0S3ZYqzA192llDJHUID1PGt0edISEoZ8t6j/HNOXvIKBngmF3X9qc4+rzZnbRI53JMu7H9WRocAV0ZOuTczaCFPrwQR2A/kSS3lhI2wtsBHWQ3YF2OqjfKyx6ywabqrDIEnmIgp4pKZz4B+UeZB5G1o+UQAAAABJRU5ErkJggg==';
    });
};

const triangleEndingEnter = (enter: any) => {
  enter.append('path').call(trianglePropsSet);
  enter.append('image').call(statusIconPropsSet);
};

const triangleEndingUpdate = (update: any) => {
  update.select('path.triangle-path').call(trianglePropsSet);
  update.select('image.status-icon').call(statusIconPropsSet);
};

const figuresEnter = (enter: any) => {
  return enter
    .append('g')
    .attr('class', (d: FigureData) => d.id)
    .each((d: FigureData, i: number, group: any) => {
      const figureGroup = d3.select(group[i]);

      if (d.figure === EndingFigure.Plate) {
        figureGroup.call(startPlatesEnter);
      } else if (d.figure === EndingFigure.Arrow) {
        figureGroup.call(triangleEndingEnter);
      } else {
        throw new Error(`enter: rendering of ${d.figure} ending figure is not implemented`);
      }
    });
};

const figuresUpdate = (update: any) => {
  return update.each((d: FigureData, i: number, group: any) => {
    const figureGroup = d3.select(group[i]);

    if (d.figure === EndingFigure.Plate) {
      figureGroup.call(startPlatesUpdate);
    } else if (d.figure === EndingFigure.Arrow) {
      figureGroup.call(triangleEndingUpdate);
    } else {
      throw new Error(`update: rendering of ${d.figure} ending figure is not implemented`);
    }
  });
};

// Handle is created for each segment of arrow whose length >= minArrowLength
const arrowHandlesFromPoints = (points: Vec2[]): [Vec2, Vec2][] => {
  if (points.length < 2) return [];

  const handles: [Vec2, Vec2][] = [];
  chunks(points, 2, 1).forEach(([start, end], i: number, n: number) => {
    if (i === n - 1) return;
    if (start.distance(end) < sizes.minArrowLength) return;

    const mid = start.linterp(end, 0.5);
    const direction = end.sub(start).normalize();

    if (direction.isZero()) return;

    const handleLength = sizes.arrowHandleWidth;
    const handleFrom = mid.sub(direction.mul(handleLength / 2));
    const handleTo = mid.add(direction.mul(handleLength / 2));

    handles.push([handleFrom, handleTo]);
  });

  return handles;
};

// NOTE: returns directions of first two points and last two points
const calcDirections = (points: Vec2[]): [Vec2, Vec2] => {
  if (points.length < 2) return [Vec2.zero(), Vec2.zero()];

  const [first, second] = points.slice(0, 2);
  // NOTE: reversed, cz direction computed TO start point
  const startDir = first.sub(second).normalize();

  if (points.length === 2) return [startDir, startDir.clone()];

  const [prev, last] = points.slice(-2, points.length);
  const endDir = last.sub(prev).normalize();

  return [startDir, endDir];
};

const manageArrows = (arrows: ArrowPathsMap, g: SVGGElement) => {
  const rootGroup = d3.select(g);
  const startFiguresGroup = rootGroup.select('.start-figures');
  const endFiguresGroup = rootGroup.select('.end-figures');
  const arrowsGroup = rootGroup.select('.arrows');

  const startFiguresMap: Map<string, FigureData> = new Map();
  const endFiguresMap: Map<string, FigureData> = new Map();

  const arrowsToRender: Array<RenderingArrow> = [];

  arrows.forEach(arrow => {
    const startId = arrow.start.endingId;
    const endId = `${startId} -> ${arrow.end.endingId}`;
    const allPoints = [arrow.start.coords].concat(arrow.points);
    const [startDirection, endDirection] = calcDirections(allPoints);

    if (!startFiguresMap.has(startId)) {
      // TODO: what if there are two arrows with different colors ?
      startFiguresMap.set(startId, {
        id: startId,
        arrow,
        isStart: true,
        figure: arrow.start.figure,
        color: arrow.color,
        coords: arrow.start.coords,
        direction: startDirection,
      });
    }

    if (!endFiguresMap.has(endId)) {
      endFiguresMap.set(endId, {
        id: arrow.end.endingId,
        arrow,
        isStart: false,
        figure: arrow.end.figure,
        color: arrow.color,
        coords: arrow.end.coords,
        direction: endDirection,
      });
    }

    const arrowHandles = arrow.noHandles ? [] : arrowHandlesFromPoints(allPoints);

    arrowsToRender.push([
      arrow.arrowId,
      {
        points: allPoints,
        handles: arrowHandles,
        color: arrow.color,
      },
    ]);
  });

  const fns = {
    arrows: {
      enter: arrowsEnter,
      update: arrowsUpdate,
    },
    endingFigures: {
      enter: figuresEnter,
      update: figuresUpdate,
    },
    common: {
      exit: generalExit,
    },
  };

  arrowsGroup
    .selectAll('g')
    .data(arrowsToRender, (d: any) => d[0])
    .join(fns.arrows.enter, fns.arrows.update, fns.common.exit);

  startFiguresGroup
    .selectAll('g')
    .data([...startFiguresMap.values()], (d: any) => d.id)
    .join(fns.endingFigures.enter, fns.endingFigures.update, fns.common.exit);

  endFiguresGroup
    .selectAll('g')
    .data([...endFiguresMap.values()], (d: any) => d.id)
    .join(fns.endingFigures.enter, fns.endingFigures.update, fns.common.exit);
};

// This component manages multiple arrows to be able to draw them
// properly using d3
export const Component: FunctionComponent<Props> = observer(function ArrowsRenderer(props: Props) {
  const rootRef = useRef<SVGGElement>(null as any);

  useEffect(() => {
    if (rootRef == null || rootRef.current == null) return;

    manageArrows(props.arrows, rootRef.current);
  }, [props.arrows, rootRef]);

  return (
    <g ref={rootRef} className="arrows">
      <g className="arrows" />
      <g className="feets" />
      <g className="start-figures" />
      <g className="end-figures" />
    </g>
  );
});

export const ArrowsRenderer = React.memo(Component);
