import * as React from 'react';
import { useEffect, useState } from 'react';

import { Spinner, Switch } from '@blueprintjs/core';
import classNames from 'classnames';
import { observer } from 'mobx-react';

import LockIcon from '~/assets/icons/lock-icon.svg';
import { useStore } from '~/store';
import { PolicyStruct } from '~/store/stores/policy';

import css from './PoliciesSidebar.scss';

export enum E2E {
  policyList = 'policies-list',
}

export interface Props {
  currNamespace: string | null;
  onOpenPolicy?: (id: string) => void;
}

export const PoliciesList = observer(function PoliciesList(props: Props) {
  const store = useStore();
  const [status, setStatus] = useState<'loading' | 'ready'>('loading');

  useEffect(() => {
    if (store.cimulator.policy.policiesList.length !== 0) return;
    setStatus('loading');
  }, [props.currNamespace]);

  useEffect(() => {
    if (status !== 'loading') return;
    const timeout = setTimeout(() => setStatus('ready'), 2000);
    return () => clearTimeout(timeout);
  }, [status]);

  useEffect(() => {
    if (store.cimulator.policy.filteredPoliciesList.length === 0) {
      return;
    }
    setStatus('ready');
  }, [store.cimulator.policy.filteredPoliciesList]);

  const innerClassName = classNames(css.inner, {
    [css.active]: store.cimulator.controls.showMultiPolicy,
  });

  return (
    <div className={css.outer}>
      <div className={innerClassName}>
        <div className={css.title}>Network policies</div>
        {store.controls.flowFilterGroups.length > 0 && (
          <div className={css.filtersBanner}>
            <span className={css.filtersBannerLabel}>Filters applied</span>
          </div>
        )}
        <Switch
          className={css.switcher}
          checked={store.cimulator.controls.showMultiPolicy}
          onChange={store.cimulator.controls.toggleShowMultiPolicy}
        >
          Visualize all
        </Switch>
        <div className={css.switcherNote}>
          {store.cimulator.controls.showMultiPolicy
            ? 'All policies visualized on map'
            : 'Selected policy visualized on map'}
        </div>
        {status === 'loading' && (
          <div className={css.messageWrapper} title="Loading policies...">
            <Spinner size={60} />
            <div className={css.messageText}>Loading policies...</div>
          </div>
        )}
        {store.cimulator.policy.filteredExistingPoliciesList.length > 0 && (
          <div className={css.list} data-testid={E2E.policyList}>
            {store.cimulator.policy.filteredExistingPoliciesList.map(policy => {
              return (
                <PolicyListItem
                  key={policy.id}
                  policy={policy}
                  currentPolicyUuid={store.cimulator.controls.policyUuid}
                  onSelect={props.onOpenPolicy}
                />
              );
            })}
          </div>
        )}
        {store.cimulator.policy.filteredHistoricalPoliciesList.length > 0 && (
          <div className={css.historicalTitle}>Historical policies</div>
        )}
        {store.cimulator.policy.filteredHistoricalPoliciesList.length > 0 && (
          <div className={css.list} data-testid={E2E.policyList}>
            {store.cimulator.policy.filteredHistoricalPoliciesList.map(policy => {
              return (
                <PolicyListItem
                  key={policy.id}
                  policy={policy}
                  currentPolicyUuid={store.cimulator.controls.policyUuid}
                  onSelect={props.onOpenPolicy}
                />
              );
            })}
          </div>
        )}
        {store.controls.flowFilterGroups.length > 0 &&
          store.cimulator.policy.filteredPoliciesList.length === 0 && (
            <div className={css.messageWrapper}>
              <div className={css.messageText}>No matched policies</div>
            </div>
          )}
      </div>
    </div>
  );
});

interface PolicyListItemProps {
  policy: PolicyStruct;
  currentPolicyUuid: string | null;
  onSelect?: (id: string) => void;
}

function PolicyListItem(props: PolicyListItemProps) {
  const className = classNames(css.item, {
    [css.active]: props.policy.id === props.currentPolicyUuid,
  });

  const onSelect = React.useCallback(() => {
    props.onSelect?.(props.policy.id);
  }, [props.policy.id, props.onSelect]);

  return (
    <div className={className} key={props.policy.id} onClick={onSelect}>
      <LockIcon />{' '}
      <div className={css.label} data-testid={props.policy.name}>
        {props.policy.name}
      </div>
    </div>
  );
}
