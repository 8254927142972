import { autorun, makeAutoObservable } from 'mobx';

import * as storage from '~/storage/local';

export interface ResizeProps {
  panelTop: number;
  panelTopInPixels: number;
}

const DEFAULT_PANEL_POSITION_COEFFICIENT = 0.6;

export class PanelResizeStore {
  get panelTopStyle() {
    return { top: `${this.panelTop}%` };
  }

  get panelTop() {
    return (this.panelTopInPixels / this._windowHeight) * 100;
  }

  get panelTopInPixels() {
    if (this._collapsed) {
      return this._windowHeight - this._marginBottom;
    }

    if (this._expanded) {
      return this._marginTop;
    }

    return this._currentPanelTopInPixel;
  }

  private _panelName: string | undefined = undefined;
  private _marginTop = 0;
  private _marginBottom = 0;
  private _windowHeight = window.innerHeight;
  private _collapsed = false;
  private _expanded = false;
  private _currentPanelTopInPixel = 0;
  private _onResizeInternal: ((props: ResizeProps) => void) | undefined;

  constructor(
    private opts?: {
      panelName?: string;
      onResize?: (props: ResizeProps) => void;
      marginTop?: number;
      marginBottom?: number;
    },
  ) {
    makeAutoObservable(this);

    if (this.opts) {
      if (this.opts.marginBottom) {
        this._marginBottom = this.opts.marginBottom;
      }

      if (this.opts.marginTop) {
        this._marginTop = this.opts.marginTop;
      }

      if (this.opts.panelName) {
        this._panelName = this.opts.panelName;
      }

      if (this.opts.onResize) {
        this._onResizeInternal = this.opts.onResize;
      }
    }

    autorun(() => {
      const { panelTop, panelTopInPixels } = this;
      this._onResizeInternal?.({ panelTop, panelTopInPixels });
    });

    window.addEventListener('resize', this.onWindowResize);

    // Initialization
    this._collapsed = !!storage.getPanelResizeCollapsed(this._panelName);
    this._expanded = !!storage.getPanelResizeExpanded(this._panelName);
    const storedPosition = storage.getPanelResizePosition(this._panelName);

    // Cleanup?
    if (this._collapsed && this._expanded) {
      this._collapsed = false;
      this._expanded = false;
    }

    if (storedPosition) {
      this._currentPanelTopInPixel = this.clampedValue(storedPosition);
    } else {
      this._currentPanelTopInPixel = this._windowHeight * DEFAULT_PANEL_POSITION_COEFFICIENT;
    }
  }

  private onWindowResize = () => {
    const currentValueInPercent = this._currentPanelTopInPixel / this._windowHeight;
    this._windowHeight = window.innerHeight;
    this._currentPanelTopInPixel = this.clampedValue(currentValueInPercent * this._windowHeight);
  };

  private clampedValue(panelTop: number) {
    if (panelTop < this._marginTop) {
      return this._marginTop;
    }

    if (panelTop > this._windowHeight - this._marginBottom) {
      return this._windowHeight - this._marginBottom;
    }

    return panelTop;
  }

  set collapsed(collapsed: boolean) {
    this._collapsed = collapsed;
    this._expanded = false;
    storage.setPanelResizeCollapsed(collapsed, this._panelName);
  }
  get collapsed() {
    return this._collapsed;
  }

  set expanded(expanded: boolean) {
    this._expanded = expanded;
    this._collapsed = false;
    storage.setPanelResizeExpanded(expanded, this._panelName);
  }
  get expanded() {
    return this._expanded;
  }

  onResize(movY: number) {
    const panelTop = this.clampedValue(movY);

    this._currentPanelTopInPixel = panelTop;
    storage.setPanelTopPosition(panelTop);
  }
}
