import React, { memo } from 'react';

import { Icon } from '@blueprintjs/core';
import classNames from 'classnames';

import { FilterDirection } from '~/domain/filtering';

import css from './TagDirection.scss';
import { TagDirectionProps } from '../types';

// TODO: Replace Blueprint Icon with custom component once available

export const TagDirection = memo<TagDirectionProps>(function TagDirection(props) {
  const dir = props.direction;
  const fromIsShown = [FilterDirection.From, FilterDirection.Either].includes(dir);
  const toIsShown = [FilterDirection.To, FilterDirection.Either].includes(dir);

  return (
    <span
      className={classNames(css.direction, {
        [css.from]: dir === FilterDirection.From,
        [css.to]: dir === FilterDirection.To,
        [css.either]: dir === FilterDirection.Either,
      })}
    >
      {fromIsShown && <span className={classNames(css.label, css.from)}>from</span>}

      <span className={css.icon}>
        <Icon
          icon={dir === FilterDirection.Either ? 'arrows-horizontal' : 'arrow-right'}
          iconSize={9}
        />
      </span>

      {toIsShown && <span className={classNames(css.label, css.to)}>to</span>}
    </span>
  );
});
