import { makeAutoObservable } from 'mobx';

import { PolicyAssistantTutorial } from './general';
import ControlStore from '../cimulator-controls';

export class PolicyAssistantStore {
  fetchingTutorials = false;

  private _controls: ControlStore;
  private _tutorials: PolicyAssistantTutorial[];

  constructor(controls: ControlStore) {
    makeAutoObservable(this);

    this._controls = controls;
    this._tutorials = [];
  }

  get tutorials() {
    return this._tutorials.slice();
  }

  get currentTutorial() {
    return this._tutorials.find(t => t.id === this._controls.tutorial) ?? null;
  }

  startTutorialsFetch = () => {
    this.fetchingTutorials = true;
  };

  stopTutorialsFetch = () => {
    this.fetchingTutorials = false;
  };

  fetchTutorialsList = async () => {
    this.startTutorialsFetch();
    this.stopTutorialsFetch();
  };
}
