import React, { useCallback, useEffect, useState } from 'react';

import classNames from 'classnames';
import { observer } from 'mobx-react';

import { Props as SidebarProps } from '~/components/FlowsTable/Sidebar';
import { PolicyAssistant } from '~/components/PolicyAssistant';
import { ResizablePanel, ResizeProps } from '~/components/ResizablePanel';
import { Filters } from '~/domain/filtering';
import { Flow } from '~/domain/flows';
import { AsFlowDigest, FlowDigest } from '~/domain/flows/common';
import { NamespaceDescriptor } from '~/domain/namespaces';
import { TimeRange } from '~/domain/time';
import { K8SEvent } from '~/domain/timescape/k8s-events';
import { AssistantEmitterActions, emitter } from '~/store/stores/assistant/emitter';

import { HubblePanel, PanelKind } from './HubblePanel';
import css from './styles.scss';
import { YamlPanel } from './YamlPanel';
import { Vulnerabilities } from '../Vulnerabilities/Vulnerabilities';

export interface Props {
  currentNamespace: NamespaceDescriptor | null;
  policyYaml: string | null;
  flows: AsFlowDigest[];
  filters: Filters;
  selectedFlow: Flow | null;
  selectedFlowIsLoading: boolean;
  flowsPageIsLoading: boolean;
  isDarkThemeEnabled: boolean;
  selectedCardId?: string | null;
  selectedEndpointId?: string | null;
  timeRange: TimeRange;
  isFlowsTableAvailable?: boolean;

  policyVersions?: K8SEvent[];
  selectedPolicyVersion?: K8SEvent | null | undefined;
  onSelectPolicyVersion?: (event: K8SEvent, restoreOrigin: boolean) => void;

  onTimeRangeChange?: (timeRange: TimeRange) => void;
  onSidebarVerdictClick?: SidebarProps['onVerdictClick'];
  onSidebarTCPFlagClick?: SidebarProps['onTcpFlagClick'];
  onSidebarLabelClick?: SidebarProps['onLabelClick'];
  onSidebarPodClick?: SidebarProps['onPodClick'];
  onSidebarIdentityClick?: SidebarProps['onIdentityClick'];
  onSidebarIpClick?: SidebarProps['onIpClick'];
  onSidebarDnsClick?: SidebarProps['onDnsClick'];
  onSidebarWorkloadClick?: SidebarProps['onWorkloadClick'];
  onSidebarPortClick?: SidebarProps['onPortClick'];
  onSidebarProtocolClick?: SidebarProps['onProtocolClick'];
  onSidebarOpenProcessTreeForFlowClick?: SidebarProps['onOpenProcessTreeForFlow'];

  onFlowsScrolledToBottom?: () => void;
  onDownloadYaml?: () => void;
  onPanelResize?: (resizeProps: ResizeProps) => void;
  onSelectFlow?: (flow: FlowDigest | null) => void;
  onCloseFlowsTableSidebar?: () => void;
  onUploadPolicy?: (event: React.SyntheticEvent) => void;
  onCreateNewPolicy?: () => void;
  onUploadFlows?: (
    event: React.SyntheticEvent,
    onSuccess?: (flows: Flow[]) => void,
    onError?: (error: Error) => void,
  ) => void;
  onOpenPolicy?: (id: string) => void;
}

enum RightPanelKind {
  Hubble = 'hubble',
  Vulnerabilities = 'vulnerabilities',
  Tutorial = 'tutorial',
}

export const PoliciesPanel = observer(function PoliciesPanel(props: Props) {
  const [rightPanelKind, setRightPanelKind] = useState<RightPanelKind>(RightPanelKind.Hubble);

  const [hubblePanelKind, setHubblePanelKind] = useState<PanelKind>(PanelKind.Table);

  useEffect(() => {
    return emitter.on(AssistantEmitterActions.OpenHubblePanel, () => {
      setRightPanelKind(RightPanelKind.Hubble);
    });
  }, [emitter]);

  const onHubblePanelSetKind = useCallback((kind: PanelKind) => {
    setHubblePanelKind(kind);
  }, []);

  const flowsPanelAvailable = !!props.isFlowsTableAvailable;
  const isRightPanelInvisible = rightPanelKind === RightPanelKind.Hubble && !flowsPanelAvailable;

  const content = (
    <div className={css.wrapper}>
      <div
        className={classNames(css.centerPanel, {
          [css.wide]: isRightPanelInvisible,
        })}
      >
        <YamlPanel
          policyYaml={props.policyYaml}
          isDarkThemeEnabled={props.isDarkThemeEnabled}
          selectedCardId={props.selectedCardId}
          selectedEndpointId={props.selectedEndpointId}
          policyVersions={props.policyVersions}
          selectedPolicyVersion={props.selectedPolicyVersion}
          onSelectPolicyVersion={props.onSelectPolicyVersion}
          onDownloadYaml={props.onDownloadYaml}
          onUploadPolicy={props.onUploadPolicy}
          onCreateNewPolicy={props.onCreateNewPolicy}
        />
      </div>
      {!isRightPanelInvisible && (
        <div className={css.rightPanel}>
          <div className={css.rightPanelContent}>
            {rightPanelKind === RightPanelKind.Tutorial && <PolicyAssistant />}
            {rightPanelKind === RightPanelKind.Vulnerabilities && <Vulnerabilities />}
            {rightPanelKind === RightPanelKind.Hubble && flowsPanelAvailable && (
              <HubblePanel
                flows={props.flows}
                filters={props.filters}
                kind={hubblePanelKind}
                selectedFlow={props.selectedFlow}
                selectedFlowIsLoading={props.selectedFlowIsLoading}
                flowsPageIsLoading={props.flowsPageIsLoading}
                timeRange={props.timeRange}
                onSidebarVerdictClick={props.onSidebarVerdictClick}
                onSidebarTCPFlagClick={props.onSidebarTCPFlagClick}
                onSidebarLabelClick={props.onSidebarLabelClick}
                onSidebarPodClick={props.onSidebarPodClick}
                onSidebarIdentityClick={props.onSidebarIdentityClick}
                onSidebarIpClick={props.onSidebarIpClick}
                onSidebarDnsClick={props.onSidebarDnsClick}
                onSidebarWorkloadClick={props.onSidebarWorkloadClick}
                onSidebarPortClick={props.onSidebarPortClick}
                onSidebarProtocolClick={props.onSidebarProtocolClick}
                onTimeRangeChange={props.onTimeRangeChange}
                onFlowsScrolledToBottom={props.onFlowsScrolledToBottom}
                onSelectFlow={props.onSelectFlow}
                onUploadFlows={props.onUploadFlows}
                onCloseFlowsTableSidebar={props.onCloseFlowsTableSidebar}
                onSetPanelKind={onHubblePanelSetKind}
                onSidebarOpenProcessTreeForFlowClick={props.onSidebarOpenProcessTreeForFlowClick}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );

  return (
    <ResizablePanel key="cimulator-app" panelId="cimulator" onPanelResize={props.onPanelResize}>
      {{ content }}
    </ResizablePanel>
  );
});

export { ResizeProps };
