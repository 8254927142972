import { Flow } from '~/domain/flows';
import { hubbleFlowFromObj } from '~/domain/helpers/flows';
import { parseTimeFromObject, timeToDate } from '~/domain/helpers/time';
import { HubbleFlow } from '~/domain/hubble';
import * as misc from '~/domain/misc';
import {
  AcceptEvent,
  Ancestor,
  CloseEvent,
  ConnectEvent,
  Container,
  ExecEvent,
  ExitEvent,
  Image,
  Kind,
  ListenEvent,
  Pod,
  Process,
  ProcessEvent,
  ProcessEventBase,
} from '~/domain/process-events';

export interface ParsedEntry {
  flow?: HubbleFlow;
  processEvent?: ProcessEvent;
}

export interface LogEntries {
  hubbleFlows: HubbleFlow[];
  flows: Flow[];
  processEvents: ProcessEvent[];
}

export type ParseOptions = {
  wrapFlows?: boolean;
};

export const parseProcessEvent = (rawEvent: string | object): ProcessEvent | null => {
  let obj: any = rawEvent;

  if (typeof rawEvent === 'string') {
    try {
      obj = JSON.parse(rawEvent);
    } catch (e) {
      return null;
    }
  }

  return parseObject(obj);
};

export const parseObject = (obj: any): ProcessEvent | null => {
  const processEvent = new ProcessEvent();

  if (obj.node_name == null || obj.time == null) return null;

  const time = parseTimeFromObject(obj.time);
  if (time == null) return null;

  processEvent.time = time;
  processEvent.node_name = obj.node_name;

  const [kind, concreteEvent] = checkProcessEventKind(obj);
  if (kind == null || concreteEvent == null) return null;

  switch (kind) {
    case Kind.Connect:
      processEvent.process_connect = parseConnectEventFromObject(concreteEvent) || void 0;
      break;
    case Kind.Accept:
      processEvent.process_accept = parseAcceptEventFromObject(concreteEvent) || void 0;
      break;
    case Kind.Listen:
      processEvent.process_listen = parseListenEventFromObject(concreteEvent) || void 0;
      break;
    case Kind.Exec:
      processEvent.process_exec = parseExecEventFromObject(concreteEvent) || void 0;
      break;
    case Kind.Exit:
      processEvent.process_exit = parseExitEventFromObject(concreteEvent) || void 0;
      break;
    case Kind.Close:
      processEvent.process_close = parseCloseEventFromObject(concreteEvent) || void 0;
      break;
    default:
      return null;
  }

  return processEvent;
};

export const checkProcessEventKind = (obj: any): [Kind | null, any] => {
  if (obj == null) return [null, null];

  if (obj.event != null) {
    const kind = obj.event.oneofKind || obj.event.oneOfKind;

    switch (kind) {
      case 'process_connect':
        return [Kind.Connect, obj.event.process_connect];
      case 'process_accept':
        return [Kind.Accept, obj.event.process_accept];
      case 'process_listen':
        return [Kind.Listen, obj.event.process_listen];
      case 'process_exec':
        return [Kind.Exec, obj.event.process_exec];
      case 'process_exit':
        return [Kind.Exit, obj.event.process_exit];
      case 'process_close':
        return [Kind.Close, obj.event.process_close];
    }
  }

  if (obj.process_connect != null) {
    return [Kind.Connect, obj.process_connect];
  } else if (obj.process_accept != null) {
    return [Kind.Accept, obj.process_accept];
  } else if (obj.process_listen != null) {
    return [Kind.Listen, obj.process_listen];
  } else if (obj.process_exec != null) {
    return [Kind.Exec, obj.process_exec];
  } else if (obj.process_exit != null) {
    return [Kind.Exit, obj.process_exit];
  } else if (obj.process_close != null) {
    return [Kind.Close, obj.process_close];
  }

  return [null, null];
};

export const parseEntry = (entry: string | object): ParsedEntry | null => {
  let obj: any = entry;

  if (typeof entry === 'string') {
    try {
      obj = JSON.parse(entry);
    } catch (e) {
      return null;
    }
  }

  if (obj.flow != null) {
    const hubbleFlow = hubbleFlowFromObj(misc.camelCasify(obj));
    if (hubbleFlow == null) return null;

    return { flow: hubbleFlow };
  }

  const processEvent = parseProcessEvent(obj);
  if (processEvent == null) {
    return null;
  }

  return { processEvent };
};

export const parse = (entries: string, opts?: ParseOptions): LogEntries => {
  const logEntries: LogEntries = {
    hubbleFlows: [],
    processEvents: [],
    flows: [],
  };

  entries.split('\n').forEach(entry => {
    const parsed = parseEntry(entry);
    if (parsed == null) {
      return;
    }

    if (parsed.flow != null) {
      if (opts?.wrapFlows) {
        logEntries.flows.push(new Flow(parsed.flow));
      } else {
        logEntries.hubbleFlows.push(parsed.flow);
      }
    } else if (parsed.processEvent != null) {
      logEntries.processEvents.push(parsed.processEvent);
    }
  });

  return logEntries;
};

const parseConnectEventFromObject = (obj: any): ConnectEvent | null => {
  const base = parseProcessEventBaseFromObject(obj);
  if (base == null || base.parent == null) return null;

  return {
    process: base.process,
    parent: base.parent,

    sourceIp: obj.source_ip,
    sourcePort: parseUnsignedInt(obj.source_port)!,

    destinationIp: obj.destination_ip,
    destinationPort: parseUnsignedInt(obj.destination_port)!,
    destinationNames: obj.destination_names || obj.destination_names_list || [],
    destinationPod: parsePodFromObject(obj.destination_pod),
  };
};

const parseAcceptEventFromObject = (obj: any): AcceptEvent | null => {
  return parseConnectEventFromObject(obj) as AcceptEvent | null;
};

const parseListenEventFromObject = (obj: any): ListenEvent | null => {
  const base = parseProcessEventBaseFromObject(obj);
  if (base == null || base.parent == null) return null;

  return {
    process: base.process,
    parent: base.parent,

    ip: obj.ip,
    port: parseUnsignedInt(obj.port)!,
  };
};

const parseExecEventFromObject = (obj: any): ExecEvent | null => {
  const base = parseProcessEventBaseFromObject(obj);
  if (base == null) return null;

  const ancestors = parseAncestorsFromArray(obj.ancestors);

  return {
    process: base.process,
    parent: base.parent,
    ancestors: ancestors?.length ? ancestors : base.ancestors || [],
  };
};

const parseExitEventFromObject = (obj: any): ExitEvent | null => {
  return parseProcessEventBaseFromObject(obj) as ExitEvent | null;
};

const parseCloseEventFromObject = (obj: any): CloseEvent | null => {
  return parseConnectEventFromObject(obj) as CloseEvent | null;
};

const parseProcessEventBaseFromObject = (obj: any): ProcessEventBase | null => {
  if (obj == null) return null;

  // TODO: check all required fields for null
  const process = parseProcessFromObject(obj.process);
  if (process == null) return null;

  const parent = parseAncestorFromObject(obj.parent) ?? void 0;
  const ancestors = parseAncestorsFromArray(obj.ancestors) ?? void 0;

  return {
    kind: ProcessEvent.getKind(obj),
    process,
    parent,
    ancestors,
  };
};

// NOTE: previously this methods returned Process[] for some reason (?)
const parseAncestorsFromArray = (ancestors: any[] | null): Ancestor[] | null => {
  if (ancestors == null) return null;

  const ancs: Ancestor[] = [];
  for (let anc of ancestors) {
    anc = parseAncestorFromObject(anc);

    if (anc == null) return null;
    ancs.push(anc);
  }

  return ancs;
};

const parseProcessFromObject = (obj: any): Process | null => {
  const anc = parseAncestorFromObject(obj);
  if (anc == null || anc.pod == null) return null;

  return anc as Process;
};

const parseAncestorFromObject = (obj: any): Ancestor | null => {
  if (obj == null || obj.exec_id == null || obj.pid == null) return null;

  // TODO: check all required fields for null
  const pod = parsePodFromObject(obj.pod) ?? void 0;
  const start_time = parseTimeFromObject(obj.start_time);
  if (start_time == null) return null;

  // TODO: refcnt, cap fields are missing here, is this important?
  return {
    exec_id: obj.exec_id,
    pid: parseUnsignedInt(obj.pid)!,
    uid: parseUnsignedInt(obj.uid)!,
    cwd: obj.cwd,
    binary: obj.binary,
    arguments: obj.arguments,
    flags: obj.flags,
    start_time: timeToDate(start_time),
    auid: parseUnsignedInt(obj.auid)!,
    pod,
    docker: obj.docker,
    parent_exec_id: obj.parent_exec_id || undefined,
  };
};

const parsePodFromObject = (obj: any): Pod | null => {
  if (obj == null || obj.namespace == null || obj.name == null) return null;

  const container = parseContainerFromObject(obj.container);
  if (container == null) return null;

  return {
    namespace: obj.namespace,
    name: obj.name,
    labels: obj.labelsList || obj.labels || [],
    container,
  };
};

const parseContainerFromObject = (obj: any): Container | null => {
  if (obj == null || obj.id == null || obj.name == null) {
    return null;
  }

  const image = parseImageFromObject(obj.image);
  if (image == null) return null;

  const startTime = parseTimeFromObject(obj.start_time);
  if (startTime == null) return null;

  return {
    id: obj.id,
    name: obj.name,
    image,
    start_time: timeToDate(startTime),
    pid: obj.pid == null ? void 0 : parseUnsignedInt(obj.pid)!,
    maybe_exec_probe: !!obj.maybe_exec_probe,
  };
};

const parseImageFromObject = (obj: any): Image | null => {
  if (obj.name == null || obj.id == null) return null;

  return {
    id: obj.id,
    name: obj.name,
  };
};

const parseUnsignedInt = (obj: any): number | null => {
  if (obj == null) return null;

  if (Number.isInteger(obj)) return obj;
  if (obj.value != null && !Number.isNaN(obj.value)) return obj.value;

  return null;
};
