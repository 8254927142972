import React, { useCallback, useMemo } from 'react';

import classnames from 'classnames';
import { observer } from 'mobx-react';

import { DragPanel } from '~/components/DragPanel';
import {
  Column,
  FlowsTable,
  Props as FlowsTableProps,
  OnFlowsDiffCount,
} from '~/components/FlowsTable';
import { ColumnWidths } from '~/components/FlowsTable/general';
import { FlowsTableSidebar, Props as SidebarProps } from '~/components/FlowsTable/Sidebar';
import * as FlowsTableControl from '~/components/FlowsTableControl';
import {
  getProps as getLoadingOverlayProps,
  LoadingOverlay,
} from '~/components/Misc/LoadingOverlay';
import { NodeConnectivity } from '~/components/NodeConnectivity/NodeConnectivity';
import { DetailsPanelMode } from '~/domain/common';
import { GrafanaDashboardKind } from '~/domain/features';
import { Flow } from '~/domain/flows';
import { DataMode, TransferState } from '~/domain/interactions';
import { useStore } from '~/store';
import { sizes } from '~/ui';

import { PanelResizeStore, ResizeProps } from './PanelResizeStore';
import css from './styles.scss';
import { DashboardVariant } from '../Dashboard/types';
import { FlowsChart } from '../FlowsChart/FlowsChart';
import { useAutoDashboardKind } from '../GrafanaDashboard/misc';
import { DashboardPage } from '../ServiceMapApp/DashboardPage';

export { OnFlowsDiffCount };

interface PanelProps {
  namespace: string | null;
  transferState: TransferState;
  flowsWaitTimeout: boolean;
  isFlowsPageLoading: boolean;
  isFlowsStatsLoading: boolean;
  selectedFlowIsLoading: boolean;
  tab: 'flows' | 'policies' | 'node-connectivity';
  flowsTableVisibleColumns: Set<Column>;
  historyModeAvailable?: boolean;
  isResizable?: boolean;
  onPanelResize?: (resizeProps: ResizeProps) => void;
  onStreamStop?: () => void;
  onSelectTab?: (tab: 'flows' | 'policies' | 'node-connectivity') => void;
  onFlowsTableColumnToggle?: (_: Column) => void;
}

type TableProps = Omit<FlowsTableProps, 'visibleColumns' | 'isLoading'>;

export type Props = PanelProps &
  TableProps & {
    onCloseSidebar?: SidebarProps['onClose'];
    onSidebarVerdictClick?: SidebarProps['onVerdictClick'];
    onSidebarTCPFlagClick?: SidebarProps['onTcpFlagClick'];
    onSidebarLabelClick?: SidebarProps['onLabelClick'];
    onSidebarPodClick?: SidebarProps['onPodClick'];
    onSidebarIdentityClick?: SidebarProps['onIdentityClick'];
    onSidebarIpClick?: SidebarProps['onIpClick'];
    onSidebarDnsClick?: SidebarProps['onDnsClick'];
    onSidebarWorkloadClick?: SidebarProps['onWorkloadClick'];
    onSidebarPortClick?: SidebarProps['onPortClick'];
    onSidebarProtocolClick?: SidebarProps['onProtocolClick'];
    onSidebarOpenProcessTreeForFlowClick?: SidebarProps['onOpenProcessTreeForFlow'];
    filters: SidebarProps['filters'];
    timeRange: FlowsTableControl.CenterProps['timeRange'];

    onReviewFlowInPolicyEditor?: (flow: Flow | null) => void;
    onTimeRangeChange?: FlowsTableControl.CenterProps['onTimeRangeChange'];
    onFlowsModeToggle?: FlowsTableControl.LeftSideProps['onModeToggle'];
    onFlowsScrolledToBottom?: FlowsTableProps['onScrolledToBottom'];
    flowsTableColumnWidths?: ColumnWidths;
    onFlowsTableColumnResize?: (widths: ColumnWidths) => void;
    isFiltersInputVisible: boolean;
  };

export const DetailsPanel = observer((props: Props) => {
  const store = useStore();
  const panelResize = useMemo(
    () =>
      new PanelResizeStore({
        onResize: props.onPanelResize,
        marginBottom: sizes.detailPanelResizeBarHeight,
        marginTop: props.isFiltersInputVisible ? sizes.searchBarHeight : 0,
      }),
    [props.isFiltersInputVisible, props.onPanelResize],
  );

  const dataMode = props.transferState.dataMode;

  const [isDragging, setIsDragging] = React.useState(false);

  const baseGrafanaDashboardKind = useAutoDashboardKind();
  const grafanaDashboardKind = React.useMemo(() => {
    switch (store.controls.detailsPanelMode) {
      case DetailsPanelMode.Dashboards:
        return baseGrafanaDashboardKind;
      default:
        return GrafanaDashboardKind.Namespace;
    }
  }, [store.controls.detailsPanelMode, baseGrafanaDashboardKind]);

  const grafanaDashboardVariant = React.useMemo(() => {
    switch (store.controls.detailsPanelMode) {
      case DetailsPanelMode.NodeGraph:
        return DashboardVariant.NetworServiceMapNodeGraph;
      default:
        if (baseGrafanaDashboardKind === GrafanaDashboardKind.Service) {
          return DashboardVariant.NetworkServiceMapWorkload;
        }
        return DashboardVariant.Network;
    }
  }, [store.controls.detailsPanelMode, baseGrafanaDashboardKind]);

  const onReviewFlowInPolicyEditor = useCallback(() => {
    props.onReviewFlowInPolicyEditor?.(props.selectedFlow ?? null);
  }, [props.selectedFlow, props.onReviewFlowInPolicyEditor]);

  const loadingOverlay = getLoadingOverlayProps(props.flowsWaitTimeout, dataMode, props.namespace);

  const showFlowSidebar = !!(props.selectedFlow || props.selectedFlowIsLoading);

  return (
    <div
      className={classnames(css.panel, {
        [css.resizable]: props.isResizable,
        [css.isDragging]: isDragging,
      })}
      style={props.isResizable ? panelResize.panelTopStyle : { top: `${sizes.searchBarHeight}px` }}
    >
      <div className={css.dragPanel}>
        <DragPanel
          isResizable={props.isResizable && !panelResize.expanded && !panelResize.collapsed}
          onResize={(movY: number, _isLast: boolean, isActive: boolean) => {
            if (panelResize.collapsed || panelResize.expanded) return;

            panelResize.onResize(movY);
            setIsDragging(isActive);
          }}
          rightSide={
            props.tab === 'flows' && (
              <FlowsTableControl.RightSide
                flowsTableVisibleColumns={props.flowsTableVisibleColumns}
                onFlowsTableColumnToggle={props.onFlowsTableColumnToggle}
                onPanelFullscreenToggle={() => {
                  panelResize.expanded = !panelResize.expanded;
                }}
                onPanelToggle={() => {
                  panelResize.collapsed = !panelResize.collapsed;
                }}
                isFullscreen={panelResize.expanded}
                isHidden={panelResize.collapsed}
              />
            )
          }
          center={
            props.tab === 'flows' && (
              <FlowsTableControl.Center
                showPanelModeSwitcher={store.uiSettings.grafana.enabled}
                showGrafanaNodesGraph={
                  store.uiSettings.grafana.enabled && store.uiSettings.grafana.enabledNodesGraph
                }
                panelMode={store.controls.detailsPanelMode}
                dataMode={dataMode}
                timeRange={props.timeRange}
                isLoading={props.isFlowsStatsLoading || props.isFlowsPageLoading}
                onTimeRangeChange={props.onTimeRangeChange}
                onPanelModeChange={store.controls.setDetailsPanelMode}
                isHidden={panelResize.collapsed}
              />
            )
          }
        />
      </div>
      {props.tab === 'flows' && (
        <>
          {dataMode === DataMode.WatchingHistory && (
            <FlowsChart
              timeRange={props.timeRange}
              onTimeRangeChange={props.onTimeRangeChange}
              isLoading={props.isFlowsStatsLoading}
            />
          )}
          {store.controls.detailsPanelMode === DetailsPanelMode.Flows && (
            <div className={css.tableWrapper}>
              <FlowsTable
                flows={props.flows}
                isLoading={props.isFlowsPageLoading}
                visibleColumns={props.flowsTableVisibleColumns}
                columnWidths={props.flowsTableColumnWidths}
                selectedFlow={props.selectedFlow}
                onSelectFlow={props.onSelectFlow}
                onFlowsDiffCount={props.onFlowsDiffCount}
                onScrolledToBottom={props.onFlowsScrolledToBottom}
                onHeaderResize={props.onFlowsTableColumnResize}
              />
              <LoadingOverlay
                text={loadingOverlay.text.flowsTable}
                isSpinnerHidden={loadingOverlay.isSpinnerHidden}
                spinnerIntent={loadingOverlay.spinnerIntent}
              />
              {showFlowSidebar && (
                <FlowsTableSidebar
                  flow={props.selectedFlow}
                  isLoading={props.selectedFlowIsLoading}
                  filters={props.filters}
                  showReviewPolicyButton={true}
                  onClose={props.onCloseSidebar}
                  onVerdictClick={props.onSidebarVerdictClick}
                  onTcpFlagClick={props.onSidebarTCPFlagClick}
                  onLabelClick={props.onSidebarLabelClick}
                  onPodClick={props.onSidebarPodClick}
                  onIdentityClick={props.onSidebarIdentityClick}
                  onIpClick={props.onSidebarIpClick}
                  onDnsClick={props.onSidebarDnsClick}
                  onWorkloadClick={props.onSidebarWorkloadClick}
                  onPortClick={props.onSidebarPortClick}
                  onProtocolClick={props.onSidebarProtocolClick}
                  onReviewInPolicyEditor={onReviewFlowInPolicyEditor}
                  onOpenProcessTreeForFlow={props.onSidebarOpenProcessTreeForFlowClick}
                />
              )}
            </div>
          )}
          {(store.controls.detailsPanelMode === DetailsPanelMode.Dashboards ||
            store.controls.detailsPanelMode === DetailsPanelMode.NodeGraph) && (
            <div className={css.dashboardWrapper}>
              <DashboardPage
                dashboardKind={grafanaDashboardKind}
                dashboardVariant={grafanaDashboardVariant}
              />
            </div>
          )}
        </>
      )}
      {props.tab === 'node-connectivity' && (
        <div className={css.tableWrapper}>
          <NodeConnectivity />
        </div>
      )}
    </div>
  );
});

export { ResizeProps };
