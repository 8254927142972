import { Aggregation, Aggregator, AggregatorType, StateChange } from '~/domain/aggregation';
import * as obpb from '~backend/proto/observer/observer_pb';

export const domainStateChangeToPb = (ch: StateChange): obpb.StateChange => {
  switch (ch) {
    case StateChange.Closed:
      return obpb.StateChange.closed;
    case StateChange.Error:
      return obpb.StateChange.error;
    case StateChange.Established:
      return obpb.StateChange.established;
    case StateChange.FirstError:
      return obpb.StateChange.first_error;
    case StateChange.New:
      return obpb.StateChange.new;
  }

  return obpb.StateChange.unspec;
};

export const domainAggregatorTypeToPb = (type: AggregatorType): obpb.AggregatorType => {
  switch (type) {
    case AggregatorType.Connection:
      return obpb.AggregatorType.connection;
    case AggregatorType.Identity:
      return obpb.AggregatorType.identity;
  }

  return obpb.AggregatorType.unknown;
};

export const domainAggregatorToPb = (aggregator: Aggregator): obpb.Aggregator => {
  const agg = obpb.Aggregator.create();
  const pbType = domainAggregatorTypeToPb(aggregator.type);

  agg.type = pbType;
  return agg;
};

export const domainToPb = (aggregation: Aggregation): obpb.Aggregation => {
  const pbAgg = obpb.Aggregation.create();

  const pbStateChange = domainStateChangeToPb(aggregation.stateChange);
  pbAgg.state_change_filter = pbStateChange;

  const pbAggregators = aggregation.aggregators.map(agg => {
    return domainAggregatorToPb(agg);
  });

  pbAgg.aggregators = pbAggregators;
  return pbAgg;
};
