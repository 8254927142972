import { Message, Oneshot, OneshotOptions } from '~/api/customprotocol-core';
import { TimescapeData, TimescapeDataFilter } from '~/domain/timescape';
import { ProtoFactory } from '~/factories/proto';
import * as uipb from '~backend/proto/ui/ui_pb';

export type Options = OneshotOptions & {
  dataFilters: TimescapeDataFilter;
};

export enum Event {
  TimescapeData = 'timescape-data',
}

export type Handlers = {
  [Event.TimescapeData]: (d: TimescapeData) => void;
};

export class TimescapeServiceMapOneshot extends Oneshot<Handlers> {
  private dataFilters: TimescapeDataFilter;

  constructor(opts: Options) {
    super(opts);

    this.dataFilters = opts.dataFilters;

    this.setupEventHandlers();
  }

  public setupEventHandlers() {
    this.onMessage(msg => {
      const resp = uipb.GetTimescapeDataResponse.fromBinary(msg.body);
      const timescapeData = ProtoFactory.timescapeDataFromDataResponse(resp);
      if (timescapeData == null) return;

      this.emit(Event.TimescapeData, timescapeData);
    });
  }

  public onTimescapeData(fn: Handlers[Event.TimescapeData]): this {
    this.on(Event.TimescapeData, fn);
    return this;
  }

  protected messageBuilder(msg: Message, isFirst: boolean): Message {
    if (!isFirst) return msg;

    const request = ProtoFactory.timescapeDataRequestFromDataFilters(this.dataFilters);

    msg.setBodyBytes(uipb.GetTimescapeDataRequest.toBinary(request));
    return msg;
  }
}
