import React, { ReactNode } from 'react';

import { FilterGroup } from '~/domain/filtering';

import { FilterTagEntry } from './FilterTagEntry';
import { AndOperator } from '../AndOperator/AndOperator';
import { FlowFiltersTag } from '../FlowFiltersTag/FlowFiltersTag';
import { DownshiftGetSelectedItemPropsOptions } from '../types';

export const FilterGroupTag = function FilterGroupTag({
  currentGroupItem,
  groupId,
  currentGroupIndex,
  onFilterTagClick,
  onRemoveFilter,
  getSelectedItemProps,
}: {
  currentGroupItem: FilterGroup;
  groupId: string;
  currentGroupIndex: number;
  onFilterTagClick: (groupId: string) => void;
  onRemoveFilter: (groupId: string) => void;
  getSelectedItemProps: DownshiftGetSelectedItemPropsOptions;
}) {
  const filterTagEntries = currentGroupItem.entries.reduce((acc: ReactNode, item, index) => {
    const filterTagEntrySeparator = <AndOperator key={`and-${index}`} />;
    const filterTagEntry = <FilterTagEntry key={`${index}_entry`} item={item} />;

    if (index === 0) {
      return [filterTagEntry];
    }

    return [acc, filterTagEntrySeparator, filterTagEntry];
  }, null);

  return (
    <>
      <FlowFiltersTag
        key={currentGroupIndex}
        index={currentGroupIndex}
        label={filterTagEntries}
        onFilterTagClick={() => onFilterTagClick(groupId)}
        onRemove={() => onRemoveFilter(groupId)}
        getSelectedItemProps={getSelectedItemProps}
      />
    </>
  );
};
