import React, { useEffect } from 'react';

import * as mobx from 'mobx';
import { observer } from 'mobx-react';

import { ProcessesSubTree, Props as SubTreeProps } from '~/components/ProcessTree/ProcessesSubTree';
import { useRefsCollector } from '~/components/ProcessTree/RefsCollectorContext';
import { Processes } from '~/domain/process-tree/processes';
import { applyPrefix } from '~/utils';
import { logger } from '~/utils/logger';

import css from './styles.scss';

export interface Props {
  nodeName: string;
  namespaceName: string;
  podName: string;
  podHandleId: string;
  processes: Processes;
  isDarkThemeEnabled: boolean;

  iconsPrefix?: string;
  childGroupStates?: Map<string, boolean>;

  onChildGroupStateToggle?: SubTreeProps['onChildGroupStateToggle'];
}

const Separator = (props: { iconsPrefix?: string }) => {
  const src = applyPrefix('/icons/processes/chevron-right.png', props.iconsPrefix);

  return <img className={css.separator} src={src} />;
};

export const PodHandle = observer(function PodHandle(props: Props) {
  const themePrefix = props.isDarkThemeEnabled ? 'dark-' : '';

  const collector = useRefsCollector();

  useEffect(() => {
    logger.log(`roots of ${props.podName}: `, props.processes.rootExecIds);
    logger.log(`children: `, props.processes.children);
    logger.log(`parents: `, props.processes.parents);
  }, [props.processes, props.podName]);

  const subTrees = mobx
    .computed(() => {
      const elems: JSX.Element[] = [];
      const [roots, maxDepthExecId] = props.processes.rootExecIds;
      if (maxDepthExecId == null) return elems;

      roots.forEach((_, rootExecId) => {
        const subTree = (
          <ProcessesSubTree
            key={rootExecId}
            rootExecId={rootExecId}
            podHandleId={props.podHandleId}
            processes={props.processes}
            iconsPrefix={props.iconsPrefix}
            childGroupStates={props.childGroupStates}
            onChildGroupStateToggle={props.onChildGroupStateToggle}
            isDarkThemeEnabled={props.isDarkThemeEnabled}
          />
        );

        // NOTE: render the broadest tree on top of entire list
        if (rootExecId === maxDepthExecId) {
          elems.unshift(subTree);
        } else {
          elems.push(subTree);
        }
      });

      return elems;
    })
    .get();

  return (
    <div className={css.podHandle}>
      <div className={css.headline}>
        <div className={css.nodeName}>
          <div
            className={css.podConnectorIcon}
            ref={collector.podHandleConnectorRef(props.podHandleId)}
          >
            <img
              src={applyPrefix(`/icons/processes/${themePrefix}one-cube.png`, props.iconsPrefix)}
            />
          </div>
          <span>{props.nodeName}</span>
        </div>

        <Separator iconsPrefix={props.iconsPrefix} />

        <div className={css.namespaceName}>
          <img src={applyPrefix(`/icons/processes/brackets.png`, props.iconsPrefix)} />
          <span>{props.namespaceName}</span>
        </div>

        <Separator iconsPrefix={props.iconsPrefix} />

        <div className={css.podName}>
          <img
            src={applyPrefix(`/icons/processes/${themePrefix}three-cubes.png`, props.iconsPrefix)}
          />
          <span>{props.podName}</span>
        </div>
      </div>

      <div className={css.processes}>{subTrees}</div>
    </div>
  );
});
