export function buildBackendUrl() {
  const schema = process.env.API_SCHEMA;
  const host = process.env.API_HOST;
  const port = process.env.API_PORT;
  const path = process.env.API_PATH ?? '';
  let addr = `${schema}://${host}:${port}${path}`;
  if (process.env.ENV_TYPE === 'cluster') {
    addr = `${document.location.origin}${path}`;
  }
  return addr;
}
