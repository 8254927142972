import React, { SVGProps, useId } from 'react';

import classnames from 'classnames';
import { motion, MotionProps } from 'framer-motion';
import { observer } from 'mobx-react';

import { AccessPointArrow } from '~/components/ClusterMap/types';
import { sizes } from '~/ui';
import { colors } from '~/ui/vars';

import * as helpers from './helpers';

function ifDefined(obj: any) {
  return Object.fromEntries(Object.entries(obj).filter(([, v]) => v != null));
}

export type Props = {
  arrows: AccessPointArrow[];
  connectorId: string;
  strokeWidth?: (ar: AccessPointArrow) => number | null;
  strokeColor?: (ar: AccessPointArrow) => string | null;
  decorationColor?: string | null;
  decorationStrokeColor?: string | null;
  animationDelay?: number | null;
  animationDuration?: number | null;
  animationDecorationDuration?: number | null;
  className?: string | null;
};

// Motion can't animate SVG line's strokeDasharray, so we need to use a mask to animate it:
const AnimatedLine = (
  props: MotionProps &
    SVGProps<SVGLineElement> & {
      x1: number;
      x2: number;
      y1: number;
      y2: number;
      strokeWidth: number;
      strokeDasharray: string | null | undefined;
    },
) => {
  const id = useId();
  const { x1, x2, y1, y2, strokeDasharray, strokeWidth } = props;
  const rectx = Math.min(x1, x2) - (strokeWidth || 0);
  const recty = Math.min(y1, y2) - (strokeWidth || 0);
  const rectWidth = Math.max(x1, x2) - rectx + (strokeWidth || 0) * 2;
  const rectHeight = Math.max(y1, y2) - recty + (strokeWidth || 0) * 2;

  return (
    <>
      <mask
        id={`mask-${id}`}
        maskUnits="userSpaceOnUse"
        x={rectx}
        y={recty}
        width={rectWidth}
        height={rectHeight}
      >
        {strokeDasharray ? (
          <>
            <motion.line {...ifDefined(props)} className="" stroke="white" />
            <motion.rect
              initial={{ opacity: 1 }}
              animate={{ opacity: 0 }}
              transition={props.transition}
              x={rectx}
              y={recty}
              width={rectWidth}
              height={rectHeight}
              fill="black"
            />
          </>
        ) : (
          <rect x={rectx} y={recty} width={rectWidth} height={rectHeight} fill="white" />
        )}
      </mask>
      {strokeDasharray ? (
        <line
          mask={`url(#mask-${id})`}
          {...ifDefined({ ...props, initial: null, animate: null, transition: null })}
        />
      ) : (
        <motion.line {...ifDefined(props)} mask={`url(#mask-${id})`} />
      )}
    </>
  );
};

export const ServiceMapArrowDuckFeet = observer(function ServiceMapArrowDuckFeet(props: Props) {
  if (props.arrows.length === 0) {
    return null;
  }

  const classes = classnames('arrow-duckfeet', props.connectorId);

  return (
    <g className={classes} data-testid={props.connectorId}>
      <g className="arrows-to-access-points">
        {props.arrows.map(ar => {
          const x2 = ar.end?.x || 0;
          const y2 = ar.end?.y || 0;
          const x1 = ar.start?.x || 0;
          const y1 = ar.start?.y || 0;
          const arrowId = ar.id
            .replaceAll(' ', '-')
            .replaceAll(' ', '-')
            .replaceAll('(', '')
            .replaceAll('>', '')
            .replaceAll('/', '')
            .replaceAll(')', '')
            .replaceAll('----', '-');
          const id = `${arrowId}-${Array.from(ar.verdicts).join('-')}`;
          const sharedLineProps = {
            x1,
            x2,
            y1,
            y2,
            strokeDasharray: helpers.innerArrows.strokeStyle(ar) as any,
            initial: { pathLength: 0 },
            animate: { pathLength: 1 },
            strokeLinecap: 'round' as any,
          };

          return (
            <g key={id}>
              <AnimatedLine
                key={id}
                {...sharedLineProps}
                strokeWidth={props.strokeWidth?.(ar) || helpers.innerArrows.strokeWidth(ar) || 0}
                stroke={props.strokeColor?.(ar) || helpers.innerArrows.strokeColor(ar)}
                transition={{
                  delay: props.animationDelay || helpers.animationDelay.duckFeetDelay,
                  duration: props.animationDuration || helpers.animationDelay.duckFeetDuration,
                }}
                className={`inner${props.className ? ' ' + props.className : ''}`}
              />
            </g>
          );
        })}
      </g>
      <g className="connector-cap">
        <motion.circle
          id={`${props.connectorId}-ending-connector`}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            delay: props.animationDelay || helpers.animationDelay.duckFeetDelay,
            duration:
              props.animationDecorationDuration ||
              helpers.animationDelay.duckFeetDecorationDuration,
          }}
          className={'ending-connector'}
          stroke={props.decorationStrokeColor || colors.connectorStroke}
          strokeWidth={sizes.connectorWidth}
          fill={props.decorationColor || colors.connectorFill}
          r={7.5}
          cx={(props.arrows[0]?.start?.x || 0) - 8}
          cy={props.arrows[0]?.start?.y || 0}
        />
      </g>
    </g>
  );
});
