export enum Kind {
  Exec = 'exec',
  Listen = 'listen',
  Connect = 'connect',
  Accept = 'accept',
  Exit = 'exit',
  Close = 'close',
  Kprobe = 'kprobe',
}

export interface Process {
  exec_id: string;
  pid: number;
  uid: number;
  cwd: string;
  binary: string;
  arguments: string;
  flags: string;
  start_time: Date;
  auid: number;
  pod: Pod;
  docker: string;
  parent_exec_id?: string;
}

export interface Pod {
  namespace: string;
  name: string;
  labels: string[];
  container: Container;
}

export type PodWithoutContainer = Omit<Pod, 'container'>;

// NOTE: pid can be undefined on entries fetched from log files
export interface Container {
  id: string;
  name: string;
  image: Image;
  start_time: Date;
  pid?: number;
  maybe_exec_probe: boolean;
}

export interface Image {
  id: string;
  name: string;
}

export type Ancestor = Omit<Process, 'pod'> & {
  pod?: Pod;
};
