import * as React from 'react';

import { Checkbox } from '@blueprintjs/core';
import { observer } from 'mobx-react';

import css from './AggregationFilter.scss';

export interface Props {
  enabled?: boolean;
  onAggregationChange?: (_: boolean) => void;
}

export const AggregationFilter = observer(function AggregationFilter(props: Props) {
  return (
    <div className={css.wrapper}>
      <Checkbox
        checked={!!props.enabled}
        label="Aggregate flows"
        onChange={() => props.onAggregationChange?.(!props.enabled)}
      />
    </div>
  );
});
