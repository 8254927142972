import { RefObject, useEffect, useRef, useState } from 'react';

import * as d3 from 'd3';

import { XYWH } from '~/domain/geometry';
import { sizes } from '~/ui/vars';

export interface MapZoom {
  ref: RefObject<SVGSVGElement>;
  transform: string;
}

export interface Args {
  wasDragged?: boolean;
  onMapDrag?: (val: boolean) => void;
  mapBBox: XYWH;
  visibleHeight: number;
}

export function useMapZoom(args: Args): MapZoom {
  const ref = useRef<SVGSVGElement>(null);
  const [transform, setTransform] = useState<d3.ZoomTransform>(createTransformToCenter(args));
  const doTransform = useRef<((trans: d3.ZoomTransform) => void) | null>(null);
  const movesCount = useRef<number>(0);

  useEffect(() => {
    if (args.wasDragged === false) {
      movesCount.current = 0;
    }
  }, [args.wasDragged]);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    const initialTransform = createTransformToCenter(args);

    const zoom = d3
      .zoom()
      .filter(function (event) {
        return (!event.ctrlKey || event.type === 'wheel') && !event.button;
      })
      .scaleExtent([0.1, 1.5])
      .on('zoom', event => {
        setTransform(event.transform);
        if (event.transform.programmatic) return;

        args.onMapDrag?.(movesCount.current++ > 1);
      });

    const zoomable = d3
      .select(ref.current)
      .call(zoom as any)
      .on('dblclick.zoom', null);

    doTransform.current = transform => {
      (transform as any).programmatic = true;
      zoomable.call(zoom.transform as any, transform);
    };

    // Dirty hack for tests: jsdom doesn't have full svg support
    // https://github.com/jsdom/jsdom/issues/2531
    if (process.env.NODE_ENV !== 'test') {
      doTransform.current(initialTransform);
    }
  }, []);

  // auto center map on updates until user makes a move
  useEffect(() => {
    if (process.env.NODE_ENV !== 'test') {
      if (!doTransform.current || movesCount.current > 1) return;
      doTransform.current(createTransformToCenter(args));
    }
  }, [args.mapBBox]);

  return { transform: transform.toString(), ref };
}

function createTransformToCenter(args: Args) {
  const containerWidth = window.innerWidth - sizes.sidebarWidth;
  const containerHeight = Math.round(Math.max(window.innerHeight / 2, args.visibleHeight));

  const maxMapWidth = Math.min(1280, containerWidth - 40);
  const maxMapHeight = containerHeight - 40;

  const mapWidth = args.mapBBox.w || maxMapWidth;
  const mapHeight = args.mapBBox.h || maxMapHeight;

  const scale = Math.min(maxMapWidth / mapWidth, maxMapHeight / mapHeight);

  const scaledMapWidth = mapWidth * scale;
  const scaledMapHeight = mapHeight * scale;
  const scaledXOffset = -args.mapBBox.x * scale;
  const scaledYOffset = -args.mapBBox.y * scale;

  const x = scaledXOffset + (containerWidth - scaledMapWidth) / 2;
  const y = scaledYOffset + (containerHeight - scaledMapHeight) / 2;

  return d3.zoomIdentity.translate(x, y).scale(scale);
}
