import { Authorization, Status, User } from '~/domain/authorization';
import * as authpb from '~backend/proto/ui/authorization_pb';

export const fromPb = (response?: authpb.GetAuthzResponse | null): Authorization | null => {
  if (response == null) return null;
  const user = userFromResponsePb(response) || void 0;

  return {
    user,
    isRequired: !!response.is_required,
    isAuthorized: !!response.is_authorized,
  };
};

export const userFromResponsePb = (response: authpb.GetAuthzResponse): User | null => {
  const pbUser = response.user;
  if (pbUser == null) return null;

  const user = new User(
    pbUser.token,
    pbUser.email,
    pbUser.is_relay_admin,
    pbUser.is_timescape_admin,
  );
  user.status = response.is_authorized ? Status.Authorized : Status.Unauthorized;

  return user;
};
