import React from 'react';

import classNames from 'classnames';
import { observer } from 'mobx-react';

import css from './styles.scss';

export interface Props {
  port: number;
  ipAddress?: string;
  protocol?: string;

  connectorRef?: React.MutableRefObject<HTMLDivElement | null>;
  onClick?: () => void;
}

export const Endpoint = observer(function Endpoint(props: Props) {
  const classes = classNames(css.endpoint, {
    [css.clickable]: props.onClick != null,
  });

  return (
    <div className={classes} ref={props.connectorRef} onClick={props.onClick}>
      <div className={css.port}>{props.port || 'n/a'}</div>
      {props.protocol != null && <div className={css.protocol}>{props.protocol}</div>}
      {props.ipAddress != null && <div className={css.ipAddress}>{props.ipAddress}</div>}
    </div>
  );
});
