import React, { MutableRefObject, useCallback, useEffect, useRef } from 'react';

import { ListOnScrollProps } from 'react-window';

import { sizes } from '~/ui';

export type OnFlowsDiffCount = MutableRefObject<((diff: number) => void) | undefined>;

export interface UseScroll {
  outerRef: React.Ref<any>;
  onScroll: (p: ListOnScrollProps) => void;
}

export function useScroll(
  onFlowsDiffCount?: OnFlowsDiffCount,
  onScroll?: (p: ListOnScrollProps, isDiffScrolling: boolean, rootElem: HTMLElement) => void,
): UseScroll {
  const outerRef = useRef<HTMLElement>(null);

  // NOTE: this flag allows to detect cases when we do programmable scrolling
  // NOTE: via element.scrollTo. Introduced because,
  // NOTE: ListOnScrollProps.scrollUpdateWasRequested is not working :/
  const isDiffScrolling = useRef<boolean>(false);

  useEffect(() => {
    if (!onFlowsDiffCount) return;

    onFlowsDiffCount.current = diff => {
      if (!outerRef.current) return;

      isDiffScrolling.current = true;
      scroll({
        element: outerRef.current,
        offset: diff * sizes.flowsTableRowHeight,
      });

      setTimeout(() => {
        isDiffScrolling.current = false;
      }, 25);
    };

    return () => {
      onFlowsDiffCount.current = () => void 0;
    };
  }, [onFlowsDiffCount]);

  const innerOnScroll = useCallback(
    (p: ListOnScrollProps) => {
      if (!outerRef.current || !onScroll) return;

      onScroll(p, isDiffScrolling.current, outerRef.current);
    },
    [onScroll],
  );

  return { outerRef, onScroll: innerOnScroll };
}

function scroll({ element, offset }: { element: Element | undefined | null; offset: number }) {
  if (!element || offset === 0 || element.scrollTop === 0) return;

  element.scrollTo({
    top: element.scrollTop + offset,
    left: element.scrollLeft,
  });
}
