import React from 'react';

import { Callout } from '@blueprintjs/core';

import { PolicyCard } from '~/domain/cimulator/cards';
import { PolicyEndpoint } from '~/domain/cimulator/endpoint';
import {
  CardSide,
  IneffectiveRuleKind,
  PolicyKind,
  UnsupportedReasonKind,
} from '~/domain/cimulator/types';
import { NamespaceLabelKey } from '~/domain/labels';
import { PolicyStore } from '~/store/stores/policy';
import { RuleStatusKind } from '~/store/stores/policy/types';

import { normNamespaceMatchLabels } from './general';
import {
  AllowedTooltip,
  CiliumNetworkPoliciesTooltip,
  DroppedTooltip,
  EgressTooltip,
  IngressTooltip,
} from './Tooltips';

export function getCrudPopoverDescription(
  card: PolicyCard,
  endpoint: PolicyEndpoint,
  policy: PolicyStore,
): { title: string; text: React.ReactNode }[] {
  // const a = policy.egressOutsideClusterCard.endpointsList.some(e => !e.isAll)
  // policy.isKubeDnsAllowed
  // policy.isCNP : policy.isKNP

  const result: { title: string; text: React.ReactNode }[] = [];

  const status = policy.getRuleStatusInfo(card, endpoint);
  if (!status) return result;

  const isAllowed = [
    RuleStatusKind.AllowedByDefaultAllow,
    RuleStatusKind.AllowedByExplicitRule,
    RuleStatusKind.AllowedByOtherRule,
  ].includes(status.kind);

  const isAllowedByOtherRule = status.kind === RuleStatusKind.AllowedByOtherRule;

  const isDenied = status.kind === RuleStatusKind.DeniedByDefaultDeny;

  const isIngress = card.side === CardSide.Ingress;
  const isEgress = card.side === CardSide.Egress;

  if (!policy.selectorCard || !policy.policyKind) return result;

  const portsLabel = endpoint.ports?.length
    ? endpoint.ports.length > 1
      ? `ports ${endpoint.portsStr}`
      : `port ${endpoint.portsStr}`
    : '';

  if (status.kind === RuleStatusKind.UnsupportedByReason) {
    switch (status.info.kind) {
      case UnsupportedReasonKind.CNPNamespaceKey:
        result.push({
          title: 'Rule is unsupported',
          text: (
            <>
              Cilium Network Policy allows you to select namespace by name (with special{' '}
              <code>{NamespaceLabelKey}</code> label). Kubernetes Network Policy doesn&apos;t
              support it.
            </>
          ),
        });
        break;
      case UnsupportedReasonKind.CNPfqdn:
        result.push({
          title: 'Rule is unsupported',
          text: (
            <>
              Cilium Network Policy allows you to select endpoints by FQDN. Kubernetes Network
              Policy doesn&apos;t support it.
            </>
          ),
        });
        break;
      case UnsupportedReasonKind.CNPEntity:
        result.push({
          title: 'Rule is unsupported',
          text: (
            <>
              Cilium Network Policy allows you to select endpoints by entity kind. Kubernetes
              Network Policy doesn&apos;t support it.
            </>
          ),
        });
        break;
    }
  }

  if (status.kind === RuleStatusKind.Ineffective) {
    switch (status.info.kind) {
      case IneffectiveRuleKind.DisabledDNSProxy:
        result.push({
          title: 'Rule is ineffective',
          text: <>DNS Proxy is not enabled, so FQDN rules won&apos;t work</>,
        });
        break;
    }
  }

  if (card.isAll && isIngress && endpoint.isAll && isDenied) {
    result.push({
      title: '',
      text: (
        <>
          <IngressTooltip capitalize /> {portsLabel ? `from ${portsLabel}` : ''} from everything
          cluster will be <DroppedTooltip />.
        </>
      ),
    });
  }

  if (card.isAll && isIngress && endpoint.isAll && isAllowed) {
    result.push({
      title: '',
      text: (
        <>
          <IngressTooltip capitalize /> {portsLabel ? `from ${portsLabel}` : ''} from everything
          will be <AllowedTooltip byOtherRule={isAllowedByOtherRule} />.
        </>
      ),
    });
  }

  if (card.isAll && isEgress && endpoint.isAll && isDenied) {
    result.push({
      title: '',
      text: (
        <>
          <EgressTooltip capitalize /> {portsLabel ? `to ${portsLabel}` : ''} to everything will be{' '}
          <DroppedTooltip />.
        </>
      ),
    });
  }

  if (card.isAll && isEgress && endpoint.isAll && isAllowed) {
    result.push({
      title: '',
      text: (
        <>
          <EgressTooltip capitalize /> {portsLabel ? `to ${portsLabel}` : ''} to everything will be{' '}
          <AllowedTooltip byOtherRule={isAllowedByOtherRule} />.
        </>
      ),
    });
  }

  if (card.isOutsideCluster && isIngress && endpoint.isAll && isDenied) {
    result.push({
      title: '',
      text: (
        <>
          <IngressTooltip capitalize /> from any endpoint outside cluster will be <DroppedTooltip />
          .
        </>
      ),
    });
    result.push({
      title: 'Advice',
      text: (
        <>
          If pods in the namespace {policy.selectorCard.namespace} need to receive ingress traffic,
          consider one of the following options:
          <br />- allow ingress from any endpoint only to specific pods
          <br />- allow ingress from any endpoint only to any pods but only specific port(s) (e.g to
          port 80)
          <br />- allow ingress from any endpoint only to any pod in the{' '}
          {policy.selectorCard.namespace} namespace
        </>
      ),
    });
  }

  if (card.isOutsideCluster && isIngress && endpoint.isAll && isAllowed) {
    result.push({
      title: '',
      text: (
        <>
          <IngressTooltip capitalize /> from any endpoint outside cluster will be{' '}
          <AllowedTooltip byOtherRule={isAllowedByOtherRule} />.
        </>
      ),
    });
    result.push({
      title: 'Advice',
      text: (
        <>
          To achieve least-privilege security, consider allowing <IngressTooltip /> to one of the
          following options instead:
          <br />- allow ingress from any endpoint only to specific pods
          <br />- allow ingress from any endpoint only to any pods but only specific port(s) (e.g to
          port 80)
        </>
      ),
    });
  }

  if (card.isOutsideCluster && isEgress && endpoint.isAll && isDenied) {
    result.push({
      title: '',
      text: (
        <>
          <EgressTooltip capitalize /> to {portsLabel ? portsLabel + ' of ' : ''}any endpoint
          outside of the cluster will be <DroppedTooltip />.
        </>
      ),
    });
    result.push({
      title: 'Advice',
      text: (
        <>
          If pods in the namespace {policy.selectorCard.namespace} communicate with endpoints
          outside of the cluster, consider one of the following options:
          <br />- allow egress to specific CIDRs
          <br />- allow egress to specific FQDNs<sup>1</sup>
          <br />- allow egress to any endpoint outside of the cluster
          <Callout>
            [1] toFQDN policy rules are only supported by <CiliumNetworkPoliciesTooltip /> and will
            require Cilium as a CNI in your Kubernetes cluster.{' '}
          </Callout>
        </>
      ),
    });
  }

  if (card.isOutsideCluster && isEgress && endpoint.isAll && isAllowed) {
    result.push({
      title: '',
      text: (
        <>
          <EgressTooltip capitalize /> to {portsLabel ? portsLabel + ' of ' : ''}any endpoint
          outside of the cluster will be <AllowedTooltip byOtherRule={isAllowedByOtherRule} />.
        </>
      ),
    });
    result.push({
      title: 'Advice',
      text: (
        <>
          To achieve least-privilege security, consider one of the following options instead:
          <br />- allow egress to specific CIDRs
          <br />- allow egress to specific FQDNs<sup>1</sup>
          <br />- allow egress to any endpoint outside of the cluster
          <Callout>
            [1] toFQDN policy rules are only supported by <CiliumNetworkPoliciesTooltip /> and will
            require Cilium as a CNI and enable DNS Proxy.{' '}
          </Callout>
        </>
      ),
    });
  }

  const namespace = (card.namespace ?? 'current' + ' ') + ' ';
  if (card.isInNamespace && isIngress && endpoint.isAll && isDenied) {
    result.push({
      title: 'Status',
      text: (
        <>
          <IngressTooltip capitalize /> to any pod in {namespace}
          namespace will be <DroppedTooltip />
        </>
      ),
    });
    result.push({
      title: 'Advice',
      text: (
        <>
          Use specific pod selectors to achieve least-privilege security or allow all ingress
          traffic within namespace to get started with simpler policy.
        </>
      ),
    });
  }

  if (card.isInNamespace && isIngress && endpoint.isAll && isAllowed) {
    result.push({
      title: 'Status',
      text: (
        <>
          <IngressTooltip capitalize /> from any pod in {namespace}
          namespace will be <AllowedTooltip byOtherRule={isAllowedByOtherRule} />
        </>
      ),
    });
  }

  if (card.isInNamespace && isIngress && endpoint.isSelector && isAllowed) {
    result.push({
      title: '',
      text: (
        <>
          <IngressTooltip capitalize /> {portsLabel ? `to ${portsLabel}` : ''} from pods with labels{' '}
          {endpoint.podMatchLabelsStr} in the same namespace will be{' '}
          <AllowedTooltip byOtherRule={isAllowedByOtherRule} />
        </>
      ),
    });
  }

  if (card.isInNamespace && isEgress && endpoint.isAll && isDenied) {
    result.push({
      title: 'Status',
      text: (
        <>
          <EgressTooltip capitalize /> to any pod in {namespace}
          namespace will be <DroppedTooltip />
        </>
      ),
    });
    result.push({
      title: 'Advice',
      text: (
        <>
          Use specific pod selectors to achieve least-privilege security or allow all ingress
          traffic within namespace to get started with simpler policy.
        </>
      ),
    });
  }

  if (card.isInNamespace && isEgress && endpoint.isAll && isAllowed) {
    result.push({
      title: 'Status',
      text: (
        <>
          <EgressTooltip capitalize /> to any pod in {namespace}
          namespace will be <AllowedTooltip byOtherRule={isAllowedByOtherRule} />
        </>
      ),
    });
  }

  if (card.isInNamespace && isEgress && endpoint.isSelector && isAllowed) {
    result.push({
      title: '',
      text: (
        <>
          <EgressTooltip capitalize /> {portsLabel ? `to ${portsLabel}` : ''} to pods with labels{' '}
          {endpoint.podMatchLabelsStr} in the same namespace will be{' '}
          <AllowedTooltip byOtherRule={isAllowedByOtherRule} />
        </>
      ),
    });
  }

  if (card.isInCluster && isIngress && endpoint.isAll && isDenied) {
    result.push({
      title: 'Status',
      text: (
        <>
          <IngressTooltip capitalize /> from any pod or namespace in the cluster will be{' '}
          <DroppedTooltip />
        </>
      ),
    });
    result.push({
      title: 'Advice',
      text: (
        <>
          If pods in the namespace {policy.selectorCard.namespace} need to receive ingress traffic
          from other pods in the cluster, consider one of the following options:
          <br />- allow ingress only from selected namespaces
          <br />- allow ingress only from pods that match selected labels
          <br />- allow all ingress traffic from any pod in the cluster
        </>
      ),
    });
  }

  if (card.isInCluster && isIngress && endpoint.isAll && isAllowed) {
    result.push(
      {
        title: 'Status',
        text: (
          <>
            <IngressTooltip capitalize /> from any pod in the cluster will be{' '}
            <AllowedTooltip byOtherRule={isAllowedByOtherRule} />
          </>
        ),
      },
      {
        title: 'Advice',
        text: (
          <>
            To achieve least-privilege security, consider one of the following options instead:
            <br />- allow ingress only from selected namespaces
            <br />- allow ingress only from pods that match selected labels
          </>
        ),
      },
    );
  }

  if (card.isInCluster && isIngress && endpoint.isSelector && isAllowed) {
    const namespaceLabel = endpoint.namespace
      ? `in ${endpoint.namespace} namespace`
      : endpoint.namespaceMatchLabelsArray?.length
        ? `in namespaces with labels ${normNamespaceMatchLabels(
            policy.policyKind,
            endpoint.namespaceMatchLabelsArray,
          )}`
        : 'in any namespace';
    const podLabels = endpoint.podMatchLabelsStr
      ? `pods with labels ${endpoint.podMatchLabelsStr}`
      : 'any pods';
    result.push({
      title: '',
      text: (
        <>
          <IngressTooltip capitalize /> {portsLabel ? `to ${portsLabel}` : ''} from {podLabels}{' '}
          {namespaceLabel} will be <AllowedTooltip byOtherRule={isAllowedByOtherRule} />
        </>
      ),
    });
  }

  if (card.isInCluster && isEgress && endpoint.isAll && isDenied) {
    result.push({
      title: 'Status',
      text: (
        <>
          <EgressTooltip capitalize /> to any pod in any namespace in the cluster will be{' '}
          <DroppedTooltip />
        </>
      ),
    });
    result.push({
      title: 'Advice',
      text: (
        <>
          If pods in the namespace {policy.selectorCard.namespace} need to send egress to other pods
          in other namespaces, consider one of the following options:
          <br />- allow egress only to selected namespaces
          <br />- allow egress only to pods that match selected labels
        </>
      ),
    });
  }

  if (card.isInCluster && isEgress && endpoint.isAll && isAllowed) {
    result.push({
      title: '',
      text: (
        <>
          <EgressTooltip capitalize /> to any pod in {namespace}
          namespace will be <AllowedTooltip byOtherRule={isAllowedByOtherRule} />
        </>
      ),
    });
  }

  if (card.isInCluster && isEgress && endpoint.isSelector && isAllowed) {
    const namespaceLabel = endpoint.namespace
      ? `in ${endpoint.namespace} namespace`
      : endpoint.namespaceMatchLabelsArray?.length
        ? `in namespaces with labels ${normNamespaceMatchLabels(
            policy.policyKind,
            endpoint.namespaceMatchLabelsArray,
          )}`
        : 'in any namespace';
    const podLabels = endpoint.podMatchLabelsStr
      ? `pods with labels ${endpoint.podMatchLabelsStr}`
      : 'any pods';
    result.push({
      title: '',
      text: (
        <>
          <EgressTooltip capitalize /> {portsLabel ? `to ${portsLabel}` : ''} to {podLabels}{' '}
          {namespaceLabel} will be <AllowedTooltip byOtherRule={isAllowedByOtherRule} />
        </>
      ),
    });
  }

  if (endpoint.isKubeDns && isDenied) {
    result.push({
      title: 'Status',
      text: (
        <>
          Current policy does not explicitly allow traffic to Kubernetes DNS. As a result DNS
          queries from pods in {policy.selectorCard.namespace} will be <DroppedTooltip />
        </>
      ),
    });
    result.push({
      title: 'Advice',
      text: <>Create allow egress rule to Kubernetes DNS.</>,
    });
  }

  if (endpoint.isKubeDns && isAllowed) {
    result.push({
      title: 'Status',
      text: (
        <>
          Egress flows to Kubernetes DNS will be{' '}
          <AllowedTooltip byOtherRule={isAllowedByOtherRule} />.
          {/* how to mention if DNS Proxy is enabled or not? */}
        </>
      ),
    });
    // we want to advice enable DNS Proxy and also use Cilium Network Policy as a result
    // {
    //   title: 'Advice',
    //   text: (
    //     <>

    //     </>
    //   ),
    // },
  }

  if (endpoint.isKubeDns && policy.isCNP) {
    const isCNP = policy.policyKind === PolicyKind.CNP;
    const enabled = isCNP && policy.isDNSProxyEnabled;

    const description = isCNP
      ? enabled
        ? 'on'
        : 'off'
      : 'is not supported in Kubernetes Network Policy';

    result.push({
      title: `DNS proxy`,
      text: <>DNS proxy {description}</>,
    });
  }

  if (endpoint.isRemoteNode) {
    const isCNP = policy.policyKind === PolicyKind.CNP;

    const description = isCNP ? (
      <>
        Selects any node in any of the connected clusters other than the local host. Also selects
        all containers running in host-networking mode on remote nodes. (Requires the option{' '}
        <a
          href="https://docs.cilium.io/en/v1.9/operations/upgrade/#id13"
          target="_blank"
          rel="noreferrer"
        >
          <code>enable-remote-node-identity</code>
        </a>{' '}
        to be enabled).
      </>
    ) : (
      'Selector by entity type is not supported in Kubernetes Network Policy'
    );

    result.push({
      title: `Entity selector: remote-node`,
      text: <>{description}</>,
    });
  }

  if (endpoint.isHost) {
    const isCNP = policy.policyKind === PolicyKind.CNP;

    const description = isCNP
      ? 'Selects the local host. Also selects all containers running in host networking mode on the local host.'
      : 'Selector by entity type is not supported in Kubernetes Network Policy.';

    result.push({
      title: `Entity selector: host`,
      text: <>{description}</>,
    });
  }

  if (result.length === 0) {
    if (isAllowed) {
      result.push({
        title: '',
        text: isIngress ? (
          <>
            <IngressTooltip capitalize /> {portsLabel ? 'to ' + portsLabel : ''} from{' '}
            {endpoint.getCaption()} will be <AllowedTooltip byOtherRule={isAllowedByOtherRule} />.
          </>
        ) : (
          <>
            <EgressTooltip capitalize /> to {portsLabel ? portsLabel + ' of ' : ''}{' '}
            {endpoint.getCaption()} will be <AllowedTooltip byOtherRule={isAllowedByOtherRule} />.
          </>
        ),
      });
    } else {
      result.push({
        title: '',
        text: isIngress ? (
          <>
            <IngressTooltip capitalize /> from {endpoint.getCaption()}{' '}
            {portsLabel ? 'to ' + portsLabel : ''} will be <DroppedTooltip />.
          </>
        ) : (
          <>
            <EgressTooltip capitalize /> to {portsLabel ? portsLabel + ' of ' : ''}{' '}
            {endpoint.getCaption()} will be <DroppedTooltip />.
          </>
        ),
      });
    }
  }

  return result;
}
