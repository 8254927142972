import * as d3 from 'd3';

import { WH, XY } from '~/domain/geometry';
import { sizes } from '~/ui/vars';
import { XYWH } from '~/ui-layer/service-map/coordinates/types';

export enum PlacementKind {
  FromWorld = 'FromWorld',
  ToWorld = 'ToWorld',
  AnotherNamespace = 'AnotherNamespace',
  InsideWithConnections = 'InsideWithConnections',
  InsideWithoutConnections = 'InsideWithoutConnections',
  OutsideManaged = 'OutsideManaged',
}

type CardToBePlaced<C> = C & {
  id: string;
};

export type PlacementMeta<C> = {
  kind: PlacementKind;
  card: CardToBePlaced<C>;
  weight: number;
};

export interface PlacementEntry<C> {
  kind: PlacementKind;
  card: CardToBePlaced<C>;
  geometry: XYWH;
}

export interface EntriesGroup<C> {
  entries: PlacementEntry<C>[];
  bbox: XYWH;
}

export type Placement<C> = Array<PlacementEntry<C>>;
export type CardsPlacement<C> = Map<string, PlacementEntry<C>>;
export type CardsColumns<C> = Map<string, PlacementMeta<C>[][]>;

interface Props<C> {
  groups: Map<PlacementKind, PlacementMeta<C>[]>;
  skipAnotherNs?: boolean;
  cardsDimensions: Map<string, WH>;
}

/**
 * This function returns placements of cards (represented by w,h values)
 * to a predicted grid based layout.
 *
 * @returns cardId -> PlacementEntry
 */
export function getColumnBasedPlacement<C>({ skipAnotherNs, groups, cardsDimensions }: Props<C>) {
  const columns: Map<string, PlacementMeta<C>[][]> = new Map();

  groups.forEach((placements, kind) => {
    if (skipAnotherNs && kind === PlacementKind.AnotherNamespace) return;

    const kindColumns = buildPlacementColumns(placements);
    columns.set(kind, kindColumns);
  });

  const placement = assignCoordinates(columns, cardsDimensions);
  return placement;
}

function buildPlacementColumns<C>(plcs: PlacementMeta<C>[]): PlacementMeta<C>[][] {
  // Heaviest cards go first
  const sorted = [...plcs].sort((a, b) => b.weight - a.weight);

  // Make columns to be more like square
  const maxCardsInColumn = Math.ceil(Math.sqrt(plcs.length));

  const columns: PlacementMeta<C>[][] = [];
  let curColumn: PlacementMeta<C>[] = [];
  let curWeight: number | null = null;

  const flushColumn = () => {
    columns.push(curColumn);
    curColumn = [];
  };

  sorted.forEach((plc: PlacementMeta<C>, i: number) => {
    const maxCardsReached = curColumn.length >= maxCardsInColumn;
    const weightChanged = curWeight != null && plc.weight !== curWeight;

    if (maxCardsReached || weightChanged) flushColumn();

    curWeight = plc.weight;
    curColumn.push(plc);

    if (i === sorted.length - 1) flushColumn();
  });

  return columns;
}

function assignCoordinates<C>(
  columns: CardsColumns<C>,
  cardsDimensions: Map<string, WH>,
): CardsPlacement<C> {
  const placement: CardsPlacement<C> = new Map();

  const top = alignColumns(columns, cardsDimensions, [PlacementKind.ToWorld]);

  const middle = alignColumns(columns, cardsDimensions, [
    PlacementKind.FromWorld,
    PlacementKind.InsideWithConnections,
    PlacementKind.InsideWithoutConnections,
  ]);

  const bottom = alignColumns(columns, cardsDimensions, [PlacementKind.AnotherNamespace]);

  const toOutside = top.get(PlacementKind.ToWorld);
  const fromOutside = middle.get(PlacementKind.FromWorld);
  const insideWithConns = middle.get(PlacementKind.InsideWithConnections);
  const insideNoConns = middle.get(PlacementKind.InsideWithoutConnections);
  const anotherNs = bottom.get(PlacementKind.AnotherNamespace);

  const shiftEntries = (shift: XY, entries: PlacementEntry<C>[]) => {
    entries.forEach((entry: PlacementEntry<C>) => {
      entry.geometry.x += shift.x;
      entry.geometry.y += shift.y;
    });
  };

  const shiftToInsideCenter = (bbox: XYWH): XY => {
    const insideBBox = insideWithConns!.bbox;

    const relativeOffset = insideBBox.x - bbox.x;
    const bboxDiff = (insideBBox.w - bbox.w) / 2;
    const x = relativeOffset + bboxDiff;

    return { x, y: 0 };
  };

  // ToWorld cards aligned to middle of InsideWithConnections cards
  if (insideWithConns != null && toOutside != null) {
    shiftEntries(shiftToInsideCenter(toOutside.bbox), toOutside.entries);
  }

  if (toOutside != null) {
    // Shift all other cards below ToWorld cards
    const middleShift = {
      x: 0,
      y: toOutside.bbox.h + 2 * sizes.namespaceBackplatePadding,
    };

    fromOutside && shiftEntries(middleShift, fromOutside.entries);
    insideWithConns && shiftEntries(middleShift, insideWithConns.entries);
    insideNoConns && shiftEntries(middleShift, insideNoConns.entries);
  }

  const buildShiftForBottom = (): XY => {
    const shift = { x: 0, y: 0 };

    if (toOutside != null) {
      shift.y += toOutside.bbox.h + 2 * sizes.namespaceBackplatePadding;
    }

    let middleHeight = 0;
    if (fromOutside != null) {
      middleHeight = Math.max(middleHeight, fromOutside.bbox.h);
    }

    if (insideWithConns != null) {
      middleHeight = Math.max(middleHeight, insideWithConns.bbox.h);
    }

    if (insideNoConns != null) {
      middleHeight = Math.max(middleHeight, insideNoConns.bbox.h);
    }

    if (middleHeight > Number.EPSILON) {
      shift.y += middleHeight + 2 * sizes.namespaceBackplatePadding;
    }

    if (insideWithConns != null) {
      const insideShift = shiftToInsideCenter(anotherNs!.bbox);
      shift.x = insideShift.x;
    }

    return shift;
  };

  if (anotherNs != null) {
    // Shift card from another namespace below others
    const shift = buildShiftForBottom();
    shiftEntries(shift, anotherNs.entries);
  }

  const copyEntries = (entries: PlacementEntry<C>[]) => {
    entries.forEach(entry => {
      placement.set(entry.card.id, entry);
    });
  };

  toOutside && copyEntries(toOutside.entries);
  fromOutside && copyEntries(fromOutside.entries);
  insideWithConns && copyEntries(insideWithConns.entries);
  insideNoConns && copyEntries(insideNoConns.entries);
  anotherNs && copyEntries(anotherNs.entries);

  return placement;
}

function alignColumns<C>(
  cardsColumns: CardsColumns<C>,
  cardsDimensions: Map<string, WH>,
  kinds: PlacementKind[],
): Map<PlacementKind, EntriesGroup<C>> {
  const alignment = new Map();

  const columnHeights: Map<PlacementEntry<C>[], number> = new Map();
  const offset = { x: 0, y: 0 };
  let entireHeight = 0;

  kinds.forEach(kind => {
    const columns = cardsColumns.get(kind);
    if (columns == null) return;

    const entries: PlacementEntry<C>[] = [];
    const bbox = { x: offset.x, y: offset.y, w: 0, h: 0 };

    columns.forEach((column: PlacementMeta<C>[], ci: number) => {
      const columnEntries: PlacementEntry<C>[] = [];

      let columnWidth = 0;
      let columnHeight = 0;
      offset.y = 0;

      column.forEach((meta: PlacementMeta<C>, ri: number) => {
        let cardWH = cardsDimensions.get(meta.card.id);
        if (cardWH == null) {
          cardWH = {
            w: sizes.defaultCardW,
            h: sizes.defaultCardH,
          };
        }

        const geometry = { x: offset.x, y: offset.y, w: cardWH.w, h: cardWH.h };

        const entry = {
          card: meta.card,
          kind: meta.kind,
          geometry: geometry,
        };

        entries.push(entry);
        columnEntries.push(entry);

        columnWidth = Math.max(columnWidth, cardWH.w);
        columnHeight += cardWH.h + (ri === 0 ? 0 : sizes.endpointVPadding);

        offset.y += cardWH.h + sizes.endpointVPadding;
      });

      columnHeights.set(columnEntries, columnHeight);

      offset.x += columnWidth + sizes.endpointHPadding;

      bbox.w += columnWidth + (ci === 0 ? 0 : sizes.endpointHPadding);
      bbox.h = Math.max(bbox.h, offset.y - sizes.endpointVPadding);

      entireHeight = Math.max(entireHeight, bbox.h);
    });

    alignment.set(kind, { entries, bbox });
  });

  columnHeights.forEach((columnHeight: number, entries: PlacementEntry<C>[]) => {
    if (entries.length === 0) return;

    const verticalOffset = (entireHeight - columnHeight) / 2;

    entries.forEach(entry => {
      entry.geometry.y += verticalOffset;
    });
  });

  return alignment;
}

export type ForceBasedPlacement = { id: string } & XY; // id is needed for linking
interface ForceBasedPlacementsProps {
  nodes: { id: string; x?: number; y?: number }[];
  links: { src: string; dst: string }[];
}

/**
 * This function computes placements of boxes (aka nodes) based
 * on the d3js force layout. This requires iterations, so the function
 * returns a promise.
 */
export function getForceBasedPlacements({
  nodes,
  links,
}: ForceBasedPlacementsProps): Promise<ForceBasedPlacement[]> {
  const { promise, resolve } = Promise.withResolvers<ForceBasedPlacement[]>();

  d3.forceSimulation(nodes)
    .force('charge', d3.forceManyBody().strength(100))
    .force(
      'collision',
      d3.forceCollide().radius(() => 400),
    )
    .force(
      'link',
      d3.forceLink().links(
        links.map(l => ({
          source: nodes.findIndex(v => v.id === l.src),
          target: nodes.findIndex(v => v.id === l.dst),
        })),
      ),
    )
    .on('end', () => {
      resolve(nodes as ForceBasedPlacement[]);
    });

  return promise;
}

function getPlacementsAlongGen(
  midPoint: XY,
  axis: 'x' | 'y',
  numberOfPlacements: number,
  space: number,
  extraRandomMargin?: number,
): XY[] {
  const placements: XY[] = [];

  for (let index = 0; index < numberOfPlacements; index++) {
    // Place points on both side of midPoint alternatively.
    // If odd number, we shift it by half a space.
    const oddNumber = index % 2 === 1;
    // side is either 1 or -1
    const side = Math.pow(-1, index % 2);
    const shiftByNb = Math.floor(index / 2) + (oddNumber ? 0.5 : 0);
    const placement = side * shiftByNb * space;

    if (axis === 'x') {
      placements.push({
        x: midPoint.x + placement,
        y: midPoint.y + Math.random() * (extraRandomMargin ?? 0),
      });
    } else {
      placements.push({
        x: midPoint.x + Math.random() * (extraRandomMargin ?? 0),
        y: midPoint.y + placement,
      });
    }
  }

  return placements;
}

export function getTopPlacements(
  bbox: XYWH,
  numberOfPlacements: number,
  minimalSpaceW: number,
  marginT?: number,
): XY[] {
  const midPoint = { x: bbox.x + bbox.w / 2, y: bbox.y - (marginT ?? 0) };
  const spaceW =
    bbox.w / numberOfPlacements > minimalSpaceW ? bbox.w / numberOfPlacements : minimalSpaceW;

  return getPlacementsAlongGen(midPoint, 'x', numberOfPlacements, spaceW);
}

export function getLeftPlacements(
  bbox: XYWH,
  numberOfPlacements: number,
  minimalSpaceH: number,
  marginL?: number,
): XY[] {
  const midPoint = { x: bbox.x - (marginL ?? 0), y: bbox.y + bbox.h / 2 };
  const spaceH =
    bbox.h / numberOfPlacements > minimalSpaceH ? bbox.h / numberOfPlacements : minimalSpaceH;

  return getPlacementsAlongGen(midPoint, 'y', numberOfPlacements, spaceH, -(marginL ?? 0));
}

export function getBottomPlacements(
  bbox: XYWH,
  numberOfPlacements: number,
  minimalSpaceW: number,
  marginB?: number,
): XY[] {
  const midPoint = { x: bbox.x + bbox.w / 2, y: bbox.y + bbox.h + (marginB ?? 0) };
  const spaceW =
    bbox.w / numberOfPlacements > minimalSpaceW ? bbox.w / numberOfPlacements : minimalSpaceW;

  return getPlacementsAlongGen(midPoint, 'x', numberOfPlacements, spaceW, marginB);
}

export function getRightPlacements(
  bbox: XYWH,
  numberOfPlacements: number,
  minimalSpaceH: number,
  marginR?: number,
): XY[] {
  const midPoint = { x: bbox.x + bbox.w + (marginR ?? 0), y: bbox.y + bbox.h / 2 };
  const spaceH =
    bbox.h / numberOfPlacements > minimalSpaceH ? bbox.h / numberOfPlacements : minimalSpaceH;

  return getPlacementsAlongGen(midPoint, 'y', numberOfPlacements, spaceH, marginR ?? 0);
}
