import React from 'react';

import classnames from 'classnames';
import { observer } from 'mobx-react';

import { CubicArrow } from '~/components/ProcessTree/CubicArrow';
import { DestinationArrow } from '~/components/ProcessTree/DestinationArrow';
import { Intent } from '~/components/widgets/common';
import { Tree as PSTree } from '~/domain/process-tree';
import { ProcessTreeArrowsLayout } from '~/ui/process-tree/arrows';
import { MapUtils } from '~/utils/iter-tools/map';

import css from './styles.scss';

export interface Props {
  arrowsLayout: ProcessTreeArrowsLayout;
  processTree: PSTree;
  isDarkThemeEnabled: boolean;
}

export const ProcessTreeArrows = observer(function ProcessTreeArrows(props: Props) {
  const { processTree, arrowsLayout: arrows } = props;

  const getOutgoingIntent = (handleId: string, execId: string): Intent => {
    const isSuspicious = processTree.isSuspiciousExecId(handleId, execId);
    const isInitProcess = processTree.isInitExecId(handleId, execId);

    return isSuspicious ? Intent.Danger : isInitProcess ? Intent.Primary : Intent.None;
  };

  return (
    <g className={css.processTreeArrows}>
      {/* Render arrows from pod handle headline to root processes */}
      {[...arrows.calculated.node.entries()].map(([handleId, rootProcessCoords]) => {
        const cls = classnames(css.arrowFromNodeToRootProcess, handleId);

        return (
          <g className={cls} key={handleId}>
            {[...rootProcessCoords.entries()].map(([rootExecId, points]) => {
              return (
                <CubicArrow
                  key={rootExecId}
                  points={points}
                  isDarkThemeEnabled={props.isDarkThemeEnabled}
                />
              );
            })}
          </g>
        );
      })}

      {/* Render arrows between process subtrees */}
      {[...arrows.calculated.ps.entries()].map(([handleId, podArrows]) => {
        const cls = classnames(css.processesArrows, handleId);

        return (
          <g className={cls} key={handleId}>
            {[...podArrows.entries()].map(([rootExecId, arrows]) => {
              return (
                <React.Fragment key={rootExecId}>
                  {[...arrows.entries()].map(([execId, points]) => {
                    let intent = Intent.None;
                    const isSuspicious = processTree.isSuspiciousExecId(handleId, execId);
                    const isInitProcess = processTree.isInitExecId(handleId, execId);

                    if (isSuspicious) {
                      intent = Intent.Danger;
                    } else if (isInitProcess) {
                      intent = Intent.Primary;
                    }

                    return (
                      <g className={`${rootExecId} -> ${execId}`} key={execId}>
                        <CubicArrow
                          intent={intent}
                          points={points}
                          isDarkThemeEnabled={props.isDarkThemeEnabled}
                        />
                      </g>
                    );
                  })}
                </React.Fragment>
              );
            })}
          </g>
        );
      })}

      {/* Render arrows to container connectors */}
      {[...arrows.calculated.container.entries()].map(([handleId, podArrows]) => {
        const cls = classnames(css.toContainerArrows, handleId);

        return (
          <g className={cls} key={handleId}>
            {[...podArrows.entries()].map(([rootExecId, arrows]) => {
              return (
                <React.Fragment key={rootExecId}>
                  {[...arrows.entries()].map(([execId, points]) => {
                    return (
                      <g className={`${rootExecId} -> ${execId}`} key={execId}>
                        <CubicArrow points={points} isDarkThemeEnabled={props.isDarkThemeEnabled} />
                      </g>
                    );
                  })}
                </React.Fragment>
              );
            })}
          </g>
        );
      })}

      {/* Render arrows to child group connector */}
      {[...arrows.calculated.childGroups.entries()].map(([handleId, podArrows]) => {
        const cls = classnames(css.toChildGroupsArrows, handleId);

        return (
          <g className={cls} key={handleId}>
            {[...podArrows.entries()].map(([rootPid, arrows]) => {
              return (
                <React.Fragment key={rootPid}>
                  {[...arrows.entries()].map(([groupKey, points]) => {
                    return (
                      <g className={`${rootPid} -> ${groupKey}`} key={groupKey}>
                        <CubicArrow points={points} isDarkThemeEnabled={props.isDarkThemeEnabled} />
                      </g>
                    );
                  })}
                </React.Fragment>
              );
            })}
          </g>
        );
      })}

      {/* Render arrows to destinations */}
      {MapUtils.new(arrows.calculated.destination).map((handleId, podArrows) => {
        const cls = classnames(css.toDestinationArrows, handleId);
        const toGroupConnectors = MapUtils.new(podArrows.toGroupConnectors);
        const toPortConnectors = MapUtils.new(podArrows.toPortConnectors);
        const toIpPortConnectors = MapUtils.new(podArrows.toIpPortConnectors);

        return (
          <g className={cls} key={handleId}>
            {toGroupConnectors.map((execId, arrows) => {
              const intent = getOutgoingIntent(handleId, execId);

              return (
                <React.Fragment key={`${execId} to groups`}>
                  {MapUtils.new(arrows).map((groupName, points) => {
                    const key = `${execId} -> ${groupName}`;

                    return (
                      <g key={key} className={key}>
                        <DestinationArrow
                          start={points[0]}
                          shiftedStart={points[1]}
                          shiftedEnd={points[2]}
                          end={points[3]}
                          maxArrowLength={10}
                          intent={intent}
                          isDarkThemeEnabled={props.isDarkThemeEnabled}
                        />
                      </g>
                    );
                  })}
                </React.Fragment>
              );
            })}

            {toPortConnectors.map((execId, arrows) => {
              const intent = getOutgoingIntent(handleId, execId);

              return (
                <React.Fragment key={`${execId} to ports`}>
                  {MapUtils.new(arrows).map((groupName, ports) => {
                    return MapUtils.new(ports).map((port, points) => {
                      const key = `${execId} -> ${groupName}:${port}`;

                      return (
                        <g key={key} className={key}>
                          <DestinationArrow
                            start={points[0]}
                            shiftedStart={points[1]}
                            shiftedEnd={points[2]}
                            end={points[3]}
                            maxArrowLength={10}
                            intent={intent}
                            isDarkThemeEnabled={props.isDarkThemeEnabled}
                          />
                        </g>
                      );
                    });
                  })}
                </React.Fragment>
              );
            })}

            {toIpPortConnectors.map((execId, arrows) => {
              const intent = getOutgoingIntent(handleId, execId);

              return (
                <React.Fragment key={`${execId} to ip:ports`}>
                  {MapUtils.new(arrows).map((groupName, ports) => {
                    return MapUtils.new(ports).map((port, ipAddresses) => {
                      return MapUtils.new(ipAddresses).map((ip, points) => {
                        const key = `${execId} -> ${groupName} -> ${ip}:${port}`;

                        return (
                          <g key={key} className={key}>
                            <DestinationArrow
                              start={points[0]}
                              shiftedStart={points[1]}
                              shiftedEnd={points[2]}
                              end={points[3]}
                              maxArrowLength={10}
                              intent={intent}
                              isDarkThemeEnabled={props.isDarkThemeEnabled}
                            />
                          </g>
                        );
                      });
                    });
                  })}
                </React.Fragment>
              );
            })}
          </g>
        );
      })}
    </g>
  );
});
