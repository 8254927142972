import React, { useCallback, useRef, useState } from 'react';

import { Classes, Icon, Intent, Placement, Popover, Spinner } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import classNames from 'classnames';

import UploadIcon from '~/assets/icons/arrow-up.svg';
import { Button } from '~/components/widgets/Button';

import css from './styles.scss';

export type FileData = string | ArrayBuffer | null;

export interface Props {
  className?: string;
  isUploading?: boolean;
  dataKind?: ReadKind;
  encoding?: string;
  popoverPlacement?: Placement;
  isUploadHintShown?: boolean;

  caption?: string;
  readingCaption?: string;
  uploadingCaption?: string;

  onData?: (_: FileData) => void;
  onText?: (_: string) => void;
  onArrayBuffer?: (_: ArrayBuffer) => void;
  onDataUrl?: (_: string) => void;

  onReadStart?: () => void;
  onError?: (err: DOMException | null) => void;
}

export enum ReadKind {
  Text = 'text',
  ArrayBuffer = 'array-buffer',
  DataURL = 'data-url',
}

export const FileUploader = function FileUploader(props: Props) {
  const fileInput = useRef<HTMLInputElement>(null);
  const [isReading, setIsReading] = useState(false);
  const dataKind = props.dataKind ?? ReadKind.ArrayBuffer;
  const popoverPlacement = props.popoverPlacement ?? 'right-start';
  const caption = props.caption ?? 'Upload';
  const readingCaption = props.readingCaption ?? 'Reading...';
  const uploadingCaption = props.uploadingCaption ?? 'Uploading...';

  const onFileChange = useCallback(() => {
    const input = fileInput.current;
    if (input?.files == null) return;

    const file = input.files[0];
    if (file == null) return;

    const reader = new FileReader();
    reader.onload = () => {
      setIsReading(false);

      if (dataKind === ReadKind.Text) {
        props.onText?.(reader.result as string);
      } else if (dataKind === ReadKind.ArrayBuffer) {
        props.onArrayBuffer?.(reader.result as ArrayBuffer);
      } else if (dataKind === ReadKind.DataURL) {
        props.onDataUrl?.(reader.result as string);
      }
    };

    reader.onerror = () => {
      setIsReading(false);
      props.onError?.(reader.error);
      input.value = '';
    };

    setIsReading(true);
    props.onReadStart?.();

    if (dataKind === ReadKind.Text) {
      reader.readAsText(file, props.encoding);
    } else if (dataKind === ReadKind.ArrayBuffer) {
      reader.readAsArrayBuffer(file);
    } else if (dataKind === ReadKind.DataURL) {
      reader.readAsDataURL(file);
    } else {
      reader.readAsArrayBuffer(file);
    }

    input.value = '';
  }, [
    fileInput.current,
    props.encoding,
    props.onError,
    props.onText,
    props.onDataUrl,
    props.onArrayBuffer,
    props.onReadStart,
  ]);

  const helpPopoverContent = (
    <div className={css.helpContent}>
      <div>
        Export process events directly from hubble-enterprise running the following command:
      </div>

      <div className={css.cmd}>
        kubectl logs -n kube-system ds/hubble-enterprise -c export-stdout --since=1h &gt;
        ~/export.log
      </div>
    </div>
  );

  const uploadHint = (
    <div className={css.uploadHelp}>
      <Popover
        interactionKind="click"
        canEscapeKeyClose={true}
        inheritDarkTheme={true}
        modifiers={{
          arrow: { enabled: true },
          flip: { enabled: true },
        }}
        placement={popoverPlacement}
        popoverClassName={Classes.POPOVER_CONTENT_SIZING}
        content={helpPopoverContent}
        usePortal={true}
      >
        <div className={css.handle}>
          <Icon
            className={css.icon}
            intent={Intent.PRIMARY}
            icon={IconNames.HELP}
            color="#7f7b7c"
          />

          <span>How can I get logs?</span>
        </div>
      </Popover>
    </div>
  );

  return (
    <div className={classNames(css.fileUploader, props.className)}>
      {!!props.isUploadHintShown && uploadHint}

      <div className={css.fileUpload}>
        <Button className={css.uploadButton} onClick={() => fileInput.current?.click()}>
          {!!props.isUploading && (
            <>
              <span>{uploadingCaption}</span>
              <Spinner size={18} intent={Intent.PRIMARY} />
            </>
          )}
          {!props.isUploading && !!isReading && (
            <>
              <span>{readingCaption}</span>
              <Spinner size={18} intent={Intent.PRIMARY} />
            </>
          )}
          {!props.isUploading && !isReading && (
            <>
              <span>{caption}</span>
              <UploadIcon />
            </>
          )}
        </Button>

        <input type="file" id="ps-file-upload" ref={fileInput} onChange={onFileChange}></input>
      </div>
    </div>
  );
};
