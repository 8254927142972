import { Authorization } from '~/domain/authorization';
import { FeatureFlags } from '~/domain/features';
import { authorization as authHelpers } from '~/domain/helpers';
import * as ffHelpers from '~/domain/helpers/feature-flags';
import type { SSRError } from '~/ui/ssr';
import { EventEmitter } from '~/utils/emitter';
import * as authpb from '~backend/proto/ui/authorization_pb';
import * as ffpb from '~backend/proto/ui/feature-flags_pb';

export enum Event {
  FeatureFlagsError = 'feature-flags-error',
  FeatureFlags = 'feature-flags',

  AuthorizationError = 'authz-error',
  Authorization = 'authz',

  SSRErrorsData = 'ssr-errors',
  SSRErrorsReadError = 'ssr-errors-error',
}

export type Handlers = {
  [Event.FeatureFlagsError]: (err: Error) => void;
  [Event.FeatureFlags]: (ff: FeatureFlags) => void;

  [Event.AuthorizationError]: (err: Error) => void;
  [Event.Authorization]: (authz: Authorization | null) => void;

  [Event.SSRErrorsReadError]: (err: Error) => void;
  [Event.SSRErrorsData]: (d: SSRError | null) => void;
};

export class InjectionReader extends EventEmitter<Handlers> {
  constructor(
    private ffSelector: string,
    private authzSelector: string,
    private ssrErrorsSelector: string,
  ) {
    super(true);
  }

  public getSSRErrors(): this {
    try {
      const errs = this.getSSRErrorsSync();
      this.emit(Event.SSRErrorsData, errs);
    } catch (err: any) {
      this.emit(Event.SSRErrorsReadError, err);
    }

    return this;
  }

  public getFeatureFlags(): this {
    try {
      const ff = this.getFeatureFlagsSync();
      if (ff == null) {
        throw new Error('No Feature Flags found');
      }

      this.emit(Event.FeatureFlags, ff);
    } catch (err: any) {
      this.emit(Event.FeatureFlagsError, err);
    }

    return this;
  }

  public getAuthorization(): this {
    try {
      const authz = this.getAuthorizationSync();
      this.emit(Event.Authorization, authz);
    } catch (err: any) {
      this.emit(Event.AuthorizationError, err);
    }

    return this;
  }

  public getSSRErrorsSync(): SSRError | null {
    return this.readTemplate(this.ssrErrorsSelector, (text, ds) => {
      const httpStatus = ds.httpStatus == null ? null : parseInt(ds.httpStatus);
      const component = ds.authErrComponent;
      const docsLink = ds.docsLink;

      return {
        error: text,
        httpStatus,
        component,
        docsLink,
      };
    });
  }

  public getFeatureFlagsSync(): FeatureFlags | null {
    return this.readBytesFromTemplate(this.ffSelector, bytes => {
      const ffProto = ffpb.GetFeatureFlagsResponse.fromBinary(bytes);
      return ffHelpers.fromPb(ffProto);
    });
  }

  public getAuthorizationSync(): Authorization | null {
    return this.readBytesFromTemplate(this.authzSelector, bytes => {
      const authzProto = authpb.GetAuthzResponse.fromBinary(bytes);
      return authHelpers.fromPb(authzProto);
    });
  }

  public onSSRErrors(fn: Handlers[Event.SSRErrorsData]): this {
    this.on(Event.SSRErrorsData, fn);
    return this;
  }

  public onSSRErrorsReadError(fn: Handlers[Event.SSRErrorsReadError]): this {
    this.on(Event.SSRErrorsReadError, fn);
    return this;
  }

  public onFeatureFlagsError(fn: Handlers[Event.FeatureFlagsError]): this {
    this.on(Event.FeatureFlagsError, fn);
    return this;
  }

  public onFeatureFlags(fn: Handlers[Event.FeatureFlags]): this {
    this.on(Event.FeatureFlags, fn);
    return this;
  }

  public onAuthorizationError(fn: Handlers[Event.AuthorizationError]): this {
    this.on(Event.AuthorizationError, fn);
    return this;
  }

  public onAuthorization(fn: Handlers[Event.Authorization]): this {
    this.on(Event.Authorization, fn);
    return this;
  }

  private readTemplate<T>(sel: string, fn: (b: string, ds: DOMStringMap) => T): T | null {
    const elem = this.querySelector(sel);
    if (elem == null) return null;

    return fn(elem.innerText, elem.dataset);
  }

  private readBytesFromTemplate<T>(
    sel: string,
    fn: (b: Uint8Array, ds: DOMStringMap) => T,
  ): T | null {
    return this.readTemplate(sel, (text, ds) => {
      const nums = this.extractNums(text);
      const bytes = new Uint8Array(nums);

      return fn(bytes, ds);
    });
  }

  private querySelector(sel: string): HTMLElement | null {
    return sel.startsWith('#')
      ? document.getElementById(sel.slice(1))
      : document.querySelector(sel);
  }

  // NOTE: This function is written to avoid using `eval`
  private extractNums(injectedNums: string): number[] {
    if (injectedNums.startsWith('[')) {
      injectedNums = injectedNums.slice(1);
    }

    if (injectedNums.endsWith(']')) {
      injectedNums = injectedNums.slice(0, -1);
    }

    // NOTE: Do not pass parseInt like that: `.map(parseInt)`
    return injectedNums.split(',').map(numStr => parseInt(numStr));
  }
}
