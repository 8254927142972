import React, { memo } from 'react';

import { Alert } from '@blueprintjs/core';

export interface Props {
  isOpen: boolean;
  text: React.ReactNode;
  buttonText: string;
  containerRef?: HTMLElement;
  onConfirm?: () => void;
  onCancel?: () => void;
}

export const ReplaceWithNewPolicyDialog = memo<Props>(function ReplaceWithNewPolicyDialog(props) {
  return (
    <Alert
      canEscapeKeyCancel={true}
      canOutsideClickCancel={true}
      isOpen={props.isOpen}
      confirmButtonText={props.buttonText}
      cancelButtonText="Cancel"
      intent="primary"
      icon="info-sign"
      onCancel={props.onCancel}
      onConfirm={props.onConfirm}
      portalContainer={props.containerRef}
    >
      {props.text}
    </Alert>
  );
});
