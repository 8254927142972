import React, { useEffect } from 'react';

import { observer } from 'mobx-react';

import { useApplication } from '~/application';
import { DashboardVariant } from '~/components/Dashboard/types';
import { GrafanaChart } from '~/components/GrafanaDashboard/GrafanaChart';
import { GrafanaChartSelector } from '~/components/GrafanaDashboard/GrafanaChartSelector';
import { GrafanaDashboardContext, useGrafanaDashboard } from '~/components/GrafanaDashboard/misc';
import { HubbleNodes } from '~/components/HubbleStatus';
import { GrafanaDashboardKind } from '~/domain/features';

import css from './DashboardPage.scss';

export enum E2E {
  namespaceList = 'namespace-list',
}

export interface Props {
  showNodesStatus?: boolean;
  dashboardVariant?: DashboardVariant;
  dashboardKind?: GrafanaDashboardKind;
}

export const DashboardPage = observer(function DashboardPage(props: Props) {
  const { store, dataLayer } = useApplication();

  const dashboard = useGrafanaDashboard(
    props.dashboardVariant ?? DashboardVariant.Overview,
    props.dashboardKind ?? GrafanaDashboardKind.Namespace,
    store.controls.timeRange,
  );

  useEffect(() => {
    if (props.dashboardVariant) {
      store.controls.setCurrentDashboardVariant(props.dashboardVariant);
    }
    return () => store.controls.setCurrentDashboardVariant(null);
  }, [props.dashboardVariant]);

  return (
    <div className={css.wrapper}>
      {props.showNodesStatus &&
        !store.uiSettings.isTimescapeOnlyEnabled &&
        !store.uiSettings.isTetragonOnlyEnabled && (
          <HubbleNodes status={dataLayer.transferState.deploymentStatus} />
        )}
      {props.dashboardVariant && props.dashboardKind && dashboard.context?.current && (
        <div className={css.grafanaWrapper}>
          {dashboard.context.entry.dashboards.length > 1 && (
            <div className={css.grafanaDashboardSelector}>
              <GrafanaChartSelector
                current={dashboard.context.current}
                entries={dashboard.context.entry.dashboards}
                onSelect={dashboard.context.select}
              />
            </div>
          )}
          <div className={css.grafanaInnerWrapper}>
            <GrafanaChart
              key={dashboard.context.current.dashboard.uid + '/' + dashboard.context.isDarkTheme}
              context={dashboard.context as GrafanaDashboardContext}
              currentNamespace={store.clusterNamespaces.currNamespace ?? null}
            />
          </div>
        </div>
      )}
    </div>
  );
});
