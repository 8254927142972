import React, { memo, useCallback, useMemo, useRef } from 'react';

import { Spinner } from '@blueprintjs/core';
import useResizeObserver from '@react-hook/resize-observer';
import classnames from 'classnames';
import { FixedSizeList, ListOnScrollProps } from 'react-window';

import { Flow } from '~/domain/flows';
import { AsFlowDigest, FlowDigest } from '~/domain/flows/common';
import { sizes } from '~/ui';

import { Column, ColumnWidths, CommonProps } from './general';
import { Header } from './Header';
import { OnFlowsDiffCount, useScroll } from './hooks/useScroll';
import { RowRenderer, RowRendererData } from './Row';
import css from './styles.scss';

export { defaultVisibleColumns } from './general';
export { OnFlowsDiffCount };
export { Column };
export interface Props extends CommonProps {
  flows: AsFlowDigest[];
  selectedFlow: Flow | null;
  isLoading: boolean;
  onScrolledToBottom?: () => void;
  onSelectFlow?: (flow: FlowDigest | null) => void;
  onFlowsDiffCount?: OnFlowsDiffCount;
  columnWidths?: ColumnWidths;
  onHeaderResize?: (columWidths: ColumnWidths) => void;
}

export const FlowsTable = memo<Props>(function FlowsTable(props: Props) {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const wrapperSize = useSize(wrapperRef);
  const itemData = useMemo((): RowRendererData => {
    return {
      flows: props.flows,
      visibleColumns: props.visibleColumns,
      selectedFlow: props.selectedFlow,
      onSelectFlow: props.onSelectFlow,
      columnWidths: props.columnWidths,
    };
  }, [
    props.flows,
    props.visibleColumns,
    props.selectedFlow,
    props.onSelectFlow,
    props.columnWidths,
  ]);

  const onScroll = useCallback(
    (scrollData: ListOnScrollProps, isDiffScrolling: boolean, rootElem: HTMLElement) => {
      if (
        scrollData.scrollDirection !== 'forward' ||
        !props.onScrolledToBottom ||
        isDiffScrolling
      ) {
        return;
      }

      const innerHeight = rootElem.clientHeight;
      const entireHeight = rootElem.scrollHeight;

      // NOTE: we are not at the bottom if no scrolling was performed.
      // NOTE: this also fixes the case when small amount of data (or no data)
      // NOTE: was rendered so that scrollbar doesn't become visible
      if (rootElem.scrollTop < Number.EPSILON) return;

      // NOTE: +px added to here to detect cases when technically we are not
      // NOTE: at the bottom of table, but visually - we are
      if (innerHeight + scrollData.scrollOffset + 1 >= entireHeight) {
        props.onScrolledToBottom();
      }
    },
    [props.onScrolledToBottom],
  );

  const scroll = useScroll(props.onFlowsDiffCount, onScroll);

  if (props.isLoading) {
    return (
      <div
        className={css.wrapper}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Spinner size={60} />
      </div>
    );
  }

  const width = wrapperSize?.width ?? 0;
  const height = wrapperSize ? wrapperSize.height - sizes.flowsTableHeadHeight - 2 : 0;

  return (
    <div className={css.wrapper} ref={wrapperRef} id="flows-table">
      <div className={classnames(css.row, css.head)}>
        <Header
          columnWidths={props.columnWidths}
          onResize={props.onHeaderResize}
          visibleColumns={props.visibleColumns}
        />
      </div>
      <FixedSizeList
        {...scroll}
        className={css.table}
        width={width}
        height={height}
        itemSize={sizes.flowsTableRowHeight}
        itemCount={props.flows.length}
        itemKey={itemKey}
        itemData={itemData}
        overscanCount={5}
      >
        {RowRenderer}
      </FixedSizeList>
    </div>
  );
});

function itemKey(index: number, data: RowRendererData) {
  return data.flows[index].asFlowDigest().id;
}

const useSize = (target: React.RefObject<HTMLElement>) => {
  const [size, setSize] = React.useState<DOMRect | null>(null);
  React.useLayoutEffect(() => {
    setSize(target.current?.getBoundingClientRect() ?? null);
  }, [target]);
  useResizeObserver(target, entry => setSize(entry.contentRect));
  return size;
};
