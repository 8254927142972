import { BackendAPI, ControlStream } from '~/api/customprotocol';
import { Aggregation } from '~/domain/aggregation';
import { Diff } from '~/domain/diff';
import {
  FilterDirection,
  FilterEntry,
  FilterGroup,
  Filters,
  FiltersDiff,
} from '~/domain/filtering';
import { Kind } from '~/domain/filtering/filter-entry';
import { Verdict } from '~/domain/hubble';
import { DataMode, TransferState } from '~/domain/interactions';
import { NamespaceDescriptor } from '~/domain/namespaces';
import { TimeRange } from '~/domain/time';
import { TimescapeDataFilter } from '~/domain/timescape';
import * as storage from '~/storage/local';
import { Store } from '~/store';
import ControlStore from '~/store/stores/controls';
import { EventEmitter } from '~/utils/emitter';

import { Options } from './common';

export enum Event {
  CurrentClusterNamespaceChanged = 'current-cluster-namespace-changed',
  AggregationChanged = 'aggregation-changed',
  VerdictsChanged = 'verdicts-changed',
  FiltersChanged = 'filters-changed',
  ShowHostChanged = 'show-host-changed',
  ShowKubeDNSChanged = 'show-kube-dns-changed',
  ShowRemoteNodeChanged = 'show-remote-node-changed',
  ShowPrometheusAppChanged = 'show-prometheus-app-changed',
  HTTPStatusChanged = 'http-status-changed',
  FlowFilterGroupsChanged = 'flow-filter-groups-changed',
  TimeRangeChanged = 'time-range-changed',
}

export type Handlers = {
  [Event.CurrentClusterNamespaceChanged]: (
    _cluster: Diff<string>,
    _namespace: Diff<string>,
  ) => void;
  [Event.AggregationChanged]: (_: Diff<Aggregation>) => void;
  [Event.FiltersChanged]: (f: FiltersDiff) => void;
  [Event.VerdictsChanged]: (v: Diff<Set<Verdict>>) => void;
  [Event.ShowHostChanged]: (e: Diff<boolean>) => void;
  [Event.ShowKubeDNSChanged]: (e: Diff<boolean>) => void;
  [Event.ShowRemoteNodeChanged]: (e: Diff<boolean>) => void;
  [Event.ShowPrometheusAppChanged]: (e: Diff<boolean>) => void;
  [Event.HTTPStatusChanged]: (st: Diff<string>) => void;
  [Event.FlowFilterGroupsChanged]: (ff: Diff<FilterGroup[]>) => void;
  [Event.TimeRangeChanged]: (d: Diff<TimeRange>) => void;
};

export class Controls extends EventEmitter<Handlers> {
  private store: Store;
  private backendAPI: BackendAPI;
  private transferState: TransferState;

  private controlStream?: ControlStream;

  constructor(opts: Options) {
    super(true);

    this.store = opts.store;
    this.backendAPI = opts.backendAPI;
    this.transferState = opts.transferState;

    this.setupEventHandlers();
  }

  public areSomeFilterGroupsEnabled(filterGroups: FilterGroup[]): boolean {
    const normalizedFilterGroups = filterGroups.map(group => {
      return new FilterGroup(
        group.entries.filter(e => (this.transferState.isCiliumStreaming ? !e.isCluster : true)),
      ).toString();
    });

    const currentKeys = new Set(
      this.store.controls.flowFilterGroups.map(group => group.toString()),
    );

    for (const group of normalizedFilterGroups) {
      const key = group.toString();

      if (currentKeys.has(key)) return true;
    }

    return false;
  }

  public get defaultFlowFilterGroups() {
    const initialFlowFilterEntries: FilterEntry[] = [];
    if (
      this.store.clusterNamespaces.currCluster &&
      this.transferState.dataMode !== DataMode.CiliumStreaming
    ) {
      const clusterFlowFilterEntry = new FilterEntry({
        kind: Kind.Cluster,
        direction: FilterDirection.Either,
        query: this.store.clusterNamespaces.currCluster,
      });
      initialFlowFilterEntries.push(clusterFlowFilterEntry);
    }
    if (this.store.clusterNamespaces.currNamespace) {
      const namespaceFlowFilterEntry = new FilterEntry({
        kind: Kind.Namespace,
        direction: FilterDirection.Either,
        query: this.store.clusterNamespaces.currNamespace,
      });
      initialFlowFilterEntries.push(namespaceFlowFilterEntry);
    }

    const nextFlowFilterGroups: FilterGroup[] = [];
    if (initialFlowFilterEntries.length) {
      nextFlowFilterGroups.unshift(new FilterGroup(initialFlowFilterEntries));
    }

    return nextFlowFilterGroups;
  }

  public get isVizSupporsFlowFilterGroups(): boolean {
    const { isWatchingHistory } = this.transferState;

    const { clusters, namespaces } = this.uniqClustersNamespaces;

    return clusters.size <= 1 && (isWatchingHistory ? namespaces.size <= 1 : namespaces.size === 1);
  }

  public get uniqClustersNamespaces() {
    const clusters = new Set<string>();
    const namespaces = new Set<string>();
    this.store.controls.flowFilterGroups.forEach(group => {
      group.entries.forEach(entry => {
        if (entry.isCluster) clusters.add(entry.query);
        if (entry.isNamespace) namespaces.add(entry.query);
      });
    });
    return { clusters, namespaces };
  }

  public get modalFilters(): Filters {
    return this.transferState.dataMode === DataMode.CiliumStreaming
      ? this.relayFilters
      : this.timescapeFilters.filters;
  }

  public get relayFilters(): Filters {
    return Filters.fromObject({
      verdicts: this.store.controls.verdicts,
      httpStatus: this.store.controls.httpStatus,
      filterGroups: this.store.controls.flowFilterGroups,
      skipHost: !this.store.controls.showHost,
      skipKubeDns: !this.store.controls.showKubeDns,
      skipRemoteNode: !this.store.controls.showRemoteNode,
      skipPrometheusApp: !this.store.controls.showPrometheusApp,
      aggregation: this.store.controls.aggregation,
      // Always set default time window
      timeRange: ControlStore.getDefaultTimeRange(),
    });
  }

  public get timescapeFilters(): TimescapeDataFilter {
    return new TimescapeDataFilter(
      Filters.fromObject({
        verdicts: this.store.controls.verdicts,
        httpStatus: this.store.controls.httpStatus,
        filterGroups: this.store.controls.flowFilterGroups,
        skipHost: !this.store.controls.showHost,
        skipKubeDns: !this.store.controls.showKubeDns,
        skipRemoteNode: !this.store.controls.showRemoteNode,
        skipPrometheusApp: !this.store.controls.showPrometheusApp,
        aggregation: this.store.controls.aggregation,
        timeRange: this.store.controls.timeRange,
      }),
    );
  }

  public get filtersDiff(): FiltersDiff {
    return FiltersDiff.fromFilters(this.modalFilters).setUnchanged();
  }

  private _upsertNamespaceDescriptor(namespaceDescriptor: NamespaceDescriptor) {
    this.store.clusterNamespaces.upsertDescriptor(namespaceDescriptor);
    this._tryAutoSelectCluster();
  }

  private _upsertNamespaceDescriptorDebouncer: NodeJS.Timeout | number = 0;
  public _tryAutoSelectCluster() {
    clearTimeout(this._upsertNamespaceDescriptorDebouncer);
    this._upsertNamespaceDescriptorDebouncer = setTimeout(() => {
      if (this.store.controls.flowFilterGroups.length !== 0) return;
      if (this.store.clusterNamespaces.currCluster) return;
      if (this.store.clusterNamespaces.clustersList.length === 0) return;
      if (this.store.clusterNamespaces.clustersList.length !== 1) return;
      this.clusterNamespaceChanged(
        this.store.clusterNamespaces.clustersList[0],
        this.store.clusterNamespaces.currNamespace,
      );
    }, 250);
  }

  public clusterNamespaceChanged(cluster: string | null, namespace: string | null) {
    this.setClusterNamespace(cluster, namespace);
    this.setFlowFilterGroups([]);
  }

  public ensureControlStream() {
    if (this.controlStream != null) return this.controlStream;

    this.controlStream = this.backendAPI
      .controlStream()
      .onNamespaceChanges(nsChanges => {
        nsChanges.forEach(nsChange => {
          const { namespace: nsDescriptor } = nsChange;
          this._upsertNamespaceDescriptor(nsDescriptor);
        });
      })
      .onNotification(notif => {
        // NOTE: This thing updates flow rate and more...
        if (notif.status != null) {
          this.transferState.setDeploymentStatus(notif.status);
        }
      });

    return this.controlStream;
  }

  public async stopFetches() {
    this.controlStream?.offAllEvents();
    await this.controlStream?.stop();
  }

  public setClusterNamespace(cluster: string | null, namespace: string | null): this {
    const prevCluster = this.store.clusterNamespaces.currCluster;
    const prevNamespace = this.store.clusterNamespaces.currNamespace;

    if (prevCluster === cluster && prevNamespace === namespace) return this;
    this.store.clusterNamespaces.setClusterNamespace(cluster, namespace);

    this.store.controls.resetUserSelected();
    this.store.flush({ globalFrame: true, policies: true });

    this.emit(
      Event.CurrentClusterNamespaceChanged,
      Diff.new(prevCluster).step(cluster),
      Diff.new(prevNamespace).step(namespace),
    );

    return this;
  }

  public setAggregationEnabled(e: boolean): this {
    const diff = Diff.new(this.store.controls.aggregation);
    // NOTE: For now we set aggregation to null if it is disabled
    const isEnabled = !!this.store.controls.aggregation;
    if (isEnabled === e) return this;

    const agg = this.store.controls.toggleAggregation(e);
    if (agg == null) {
      storage.saveIsAggregationOff(true);
    } else {
      storage.saveLastAggregationStateChange(agg.stateChange);
      storage.saveLastAggregatorTypes(agg.aggregatorTypes);
      storage.saveIsAggregationOff(false);
    }

    this.emit(Event.AggregationChanged, diff.step(agg));
    return this;
  }

  public toggleVerdict(v: Verdict): this {
    const prevVerdicts = new Set(this.store.controls.verdicts);
    const nextVerdicts = this.store.controls.toggleVerdict(v);

    const diff = Diff.new(prevVerdicts).setComparator(FiltersDiff.verdictsEqual).step(nextVerdicts);
    if (!diff.changed) return this;

    this.emit(Event.VerdictsChanged, diff);
    return this;
  }

  public toggleShowHost() {
    const isActive = this.store.controls.toggleShowHost();
    storage.saveShowHost(isActive);

    this.emit(Event.ShowHostChanged, Diff.new(!isActive).step(isActive));
  }

  public toggleShowKubeDNS() {
    const isActive = this.store.controls.toggleShowKubeDns();
    storage.saveShowKubeDns(isActive);

    this.emit(Event.ShowKubeDNSChanged, Diff.new(!isActive).step(isActive));
  }

  public toggleShowRemoteNode() {
    const isActive = this.store.controls.toggleShowRemoteNode();
    storage.saveShowRemoteNode(isActive);

    this.emit(Event.ShowRemoteNodeChanged, Diff.new(!isActive).step(isActive));
  }

  public toggleShowPrometheusApp() {
    const isActive = this.store.controls.toggleShowPrometheusApp();
    storage.saveShowPrometheusApp(isActive);

    this.emit(Event.ShowPrometheusAppChanged, Diff.new(!isActive).step(isActive));
  }

  public setHTTPStatus(st: string | null) {
    const prev = this.store.controls.setHttpStatus(st);
    if (prev === st) return;

    this.emit(Event.HTTPStatusChanged, Diff.new(prev).step(st));
  }

  // NOTE: This should be the only method who is responsible for changing flow filters

  public setFlowFilterGroups(groups: FilterGroup[] | null) {
    const normalized = this.normalizeFilterGroups(groups || []);
    const unique = FilterGroup.unique(normalized);

    const prev = this.store.controls.setFlowFilterGroups(unique);
    const isChanged = !FiltersDiff.filterGroupsEqual(prev, unique);

    if (!isChanged) return;

    const diff = Diff.new(prev).setComparator(FiltersDiff.filterGroupsEqual).step(unique);

    this.emit(Event.FlowFilterGroupsChanged, diff);
  }

  public setTimeRange(tr: TimeRange) {
    const prev = this.store.controls.setTimeRange(tr);
    const isChanged = !TimeRange.checkEquality(prev, tr);
    if (!isChanged) return;

    this.emit(
      Event.TimeRangeChanged,
      Diff.new(prev).setComparator(TimeRange.checkEquality).step(tr),
    );
  }

  public onCurrentClusterNamespaceChanged(
    fn: Handlers[Event.CurrentClusterNamespaceChanged],
  ): this {
    this.on(Event.CurrentClusterNamespaceChanged, fn);
    return this;
  }

  public onFiltersChanged(fn: Handlers[Event.FiltersChanged]): this {
    this.on(Event.FiltersChanged, fn);
    return this;
  }

  public onAggregationChanged(fn: Handlers[Event.AggregationChanged]): this {
    this.on(Event.AggregationChanged, fn);
    return this;
  }

  public onVerdictsChanged(fn: Handlers[Event.VerdictsChanged]): this {
    this.on(Event.VerdictsChanged, fn);
    return this;
  }

  public onShowHostChanged(fn: Handlers[Event.ShowHostChanged]): this {
    this.on(Event.ShowHostChanged, fn);
    return this;
  }

  public onShowKubeDNSChanged(fn: Handlers[Event.ShowKubeDNSChanged]): this {
    this.on(Event.ShowKubeDNSChanged, fn);
    return this;
  }

  public onShowPrometheusAppChanged(fn: Handlers[Event.ShowPrometheusAppChanged]): this {
    this.on(Event.ShowPrometheusAppChanged, fn);
    return this;
  }

  public onHTTPStatusChanged(fn: Handlers[Event.HTTPStatusChanged]): this {
    this.on(Event.HTTPStatusChanged, fn);
    return this;
  }

  public onFlowFilterGroupsChanged(fn: Handlers[Event.FlowFilterGroupsChanged]): this {
    this.on(Event.FlowFilterGroupsChanged, fn);
    return this;
  }

  public onTimeRangeChanged(fn: Handlers[Event.TimeRangeChanged]): this {
    this.on(Event.TimeRangeChanged, fn);
    return this;
  }

  private setupEventHandlers() {
    this.onAggregationChanged(diff => {
      const fd = this.filtersDiff.tap(d => d.aggregation.replace(diff));
      this.emit(Event.FiltersChanged, fd);
    });

    this.onVerdictsChanged(diff => {
      const fd = this.filtersDiff.tap(d => d.verdicts.replace(diff));
      this.emit(Event.FiltersChanged, fd);
    });

    this.onShowHostChanged(diff => {
      const fd = this.filtersDiff.tap(d => {
        return d.skipHost.replace(diff).invert();
      });

      this.emit(Event.FiltersChanged, fd);
    });

    this.onShowKubeDNSChanged(diff => {
      const fd = this.filtersDiff.tap(d => {
        return d.skipKubeDns.replace(diff).invert();
      });

      this.emit(Event.FiltersChanged, fd);
    });

    this.onShowPrometheusAppChanged(diff => {
      const fd = this.filtersDiff.tap(d => {
        return d.skipPrometheusApp.replace(diff).invert();
      });

      this.emit(Event.FiltersChanged, fd);
    });

    this.onHTTPStatusChanged(diff => {
      const fd = this.filtersDiff.tap(d => d.httpStatus.replace(diff));

      this.emit(Event.FiltersChanged, fd);
    });

    this.onFlowFilterGroupsChanged(diff => {
      const fd = this.filtersDiff.tap(d => d.filterGroups.replace(diff));
      this.emit(Event.FiltersChanged, fd);
    });

    this.onTimeRangeChanged(diff => {
      const fd = this.filtersDiff.tap(d => d.timeRange.replace(diff));
      this.emit(Event.FiltersChanged, fd);
    });
  }

  private normalizeFilterGroups(groups: Iterable<FilterGroup>): FilterGroup[] | null {
    if (groups == null) return null;

    const normalized: FilterGroup[] = [];
    const services = this.store.currentFrame.services;

    for (let group of groups) {
      // NOTE: Filtering by TCP flags is not supported?
      // if (filter.isTCPFlag) continue;

      const card = services.byFilterGroup(group);
      if (card != null) {
        group = group.clone();
        group.entries = group.entries.map(entry =>
          entry.setMeta(entry.meta || card.getFilterEntryMeta(entry) || ''),
        );
      }

      normalized.push(group);
    }

    return normalized;
  }
}
