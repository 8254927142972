import { Message, Oneshot, OneshotOptions } from '~/api/customprotocol-core';
import { TimescapeDataFilter } from '~/domain/timescape';
import { ProtoFactory } from '~/factories/proto';
import * as tsmappb from '~backend/proto/timescape/map/v1/map_pb';

export type ConnectionsMapParams = {
  cluster: string | null;
  dataFilters: TimescapeDataFilter | null;
};

export type Options = OneshotOptions & ConnectionsMapParams;

export enum EventKind {
  ConnectionsMap = 'connections-map',
}

export type Handlers = {
  [EventKind.ConnectionsMap]: (connections: tsmappb.Connections) => void;
};

export class ConnectionsMapOneshot extends Oneshot<Handlers> {
  private params: ConnectionsMapParams;

  constructor(opts: Options) {
    super(opts);
    this.params = { cluster: opts.cluster, dataFilters: opts.dataFilters };
    this.setupEventHandlers();
  }

  public onConnectionsMap(fn: Handlers[EventKind.ConnectionsMap]): this {
    this.on(EventKind.ConnectionsMap, fn);
    return this;
  }

  private setupEventHandlers() {
    this.onMessage(msg => {
      const resp = tsmappb.GetConnectionsResponse.fromBinary(msg.body);
      if (resp.connections_map == null) return;
      this.emit(EventKind.ConnectionsMap, resp.connections_map);
    });
  }

  protected messageBuilder(msg: Message, isFirst: boolean): Message {
    if (!isFirst) return msg;
    const req = tsmappb.GetConnectionsRequest.create();
    if (this.params.cluster) {
      req.cluster = this.params.cluster;
      req.inner_connections_level = tsmappb.ConnectionKind.NAMESPACES;
      req.outer_connections_level = tsmappb.ConnectionKind.CLUSTERS;
    } else {
      req.inner_connections_level = tsmappb.ConnectionKind.CLUSTERS;
      req.outer_connections_level = tsmappb.ConnectionKind.CLUSTERS;
    }
    if (this.params.dataFilters) {
      const { flows_request } = ProtoFactory.timescapeDataRequestFromDataFilters(
        this.params.dataFilters,
      );
      if (flows_request) {
        req.include = flows_request.whitelist;
        req.exclude = flows_request.blacklist;
        if (flows_request.since || flows_request.until) {
          req.time_filter = {};
          if (flows_request.since) req.time_filter.since = flows_request.since;
          if (flows_request.until) req.time_filter.until = flows_request.until;
        }
      }
    }
    msg.setBodyBytes(tsmappb.GetConnectionsRequest.toBinary(req));
    return msg;
  }
}
