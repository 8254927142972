import { action, computed, makeObservable, observable, reaction } from 'mobx';

import { PolicyCard } from '~/domain/cimulator/cards';
import { XY, XYWH } from '~/domain/geometry';
import { PlacementStrategy } from '~/domain/layout/abstract';
import { PolicyStore } from '~/store/stores/policy';

export enum PlacementKind {
  Left = 'Left',
  Right = 'Right',
  Center = 'Center',
}

interface Column {
  bbox: XYWH;
  cards: Map<string, XYWH>;
}

export class CimulatorPlacementStrategy extends PlacementStrategy {
  @observable private policy: PolicyStore;

  public static readonly columnPadding = 188;
  public static readonly rowPadding = 46;

  private static extractCoords(col: Column, plc: Map<string, XY>) {
    col.cards.forEach((cardBBox, cardId) => {
      plc.set(cardId, cardBBox.xy);
    });
  }

  constructor(policy: PolicyStore) {
    super();
    makeObservable(this);
    this.policy = policy;
    reaction(
      () => [this.cardsDimensions, this.policy.contextual.visibleCardsList],
      this.buildPlacement,
    );
  }

  get defaultCardW(): number {
    return 288;
  }

  @computed private get cardsPlacement(): Map<string, XY> {
    const placement: Map<string, XY> = new Map();

    if (!this.policy.contextual.visibleCardsList) return placement;

    const selectors = this.policy.contextual.visibleCardsList.filter(card => card.isSelector);
    const columnPadding = CimulatorPlacementStrategy.columnPadding;

    const leftOffset = 0;
    const left = this.buildColumn(
      this.policy.contextual.visibleCardsList.filter(card => card.isIngress),
      leftOffset,
    );

    const centerOffset = left.bbox.x + left.bbox.w + columnPadding;
    const center = this.buildColumn(selectors, centerOffset);

    const rightOffset = center.bbox.x + center.bbox.w + columnPadding;
    const right = this.buildColumn(
      this.policy.contextual.visibleCardsList.filter(card => card.isEgress),
      rightOffset,
    );

    this.centerColumns(left, center, right);

    CimulatorPlacementStrategy.extractCoords(left, placement);
    CimulatorPlacementStrategy.extractCoords(center, placement);
    CimulatorPlacementStrategy.extractCoords(right, placement);

    return placement;
  }

  @action private buildPlacement = () => {
    this.cardsPlacement.forEach((coords: XY, cardId: string) => {
      this.cardsXYs.set(cardId, coords);
    });
  };

  @action private centerColumns = (left: Column, center: Column, right: Column) => {
    const maxHeight = Math.max(left.bbox.h, center.bbox.h, right.bbox.h);

    const leftOffset = (maxHeight - left.bbox.h) / 2;
    const centerOffset = (maxHeight - center.bbox.h) / 2;
    const rightOffset = (maxHeight - right.bbox.h) / 2;

    const applyOffset = (col: Column, offset: number) => {
      col.bbox.y += offset;
      col.cards.forEach(bbox => {
        bbox.y += offset;
      });
    };

    applyOffset(left, leftOffset);
    applyOffset(center, centerOffset);
    applyOffset(right, rightOffset);
  };

  @action private buildColumn = (cards: PolicyCard[], xOffset: number): Column => {
    const rowPadding = CimulatorPlacementStrategy.rowPadding;

    const placedCards: Map<string, XYWH> = new Map();
    const bbox = XYWH.empty();
    bbox.x = xOffset;

    cards.forEach(card => {
      const wh = this.cardsDimensions.get(card.id);
      if (wh == null) return;

      const xy = { x: bbox.x, y: bbox.y + bbox.h };
      placedCards.set(card.id, XYWH.fromParts(xy, wh));

      bbox.h += wh.h + rowPadding;
      bbox.w = Math.max(bbox.w, wh.w);
    });

    if (cards.length > 0) {
      bbox.h -= rowPadding;
    }

    return { bbox, cards: placedCards };
  };
}
