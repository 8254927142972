/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter add_pb_suffix,eslint_disable,ts_nocheck,generate_dependencies,long_type_number,use_proto_field_name
// @generated from protobuf file "k8sevent/v1/event.proto" (package "k8sevent.v1", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Timestamp } from "../../google/protobuf/timestamp_pb";
/**
 * ExportEvent represents an exported event
 *
 * #### Experimental
 *
 * Notice: This type is EXPERIMENTAL and may be changed or removed in a
 * later release.
 *
 * @generated from protobuf message k8sevent.v1.ExportEvent
 */
export interface ExportEvent {
    /**
     * time is the time when the event happened.
     *
     * @generated from protobuf field: google.protobuf.Timestamp time = 1;
     */
    time?: Timestamp;
    /**
     * event is the underlying event.
     *
     * @generated from protobuf field: k8sevent.v1.Event k8s_event = 2 [json_name = "k8sEvent"];
     */
    k8s_event?: Event;
}
/**
 * Event represents an event occurring on a Kubernetes resource.
 *
 * #### Experimental
 *
 * Notice: This type is EXPERIMENTAL and may be changed or removed in a
 * later release.
 *
 * @generated from protobuf message k8sevent.v1.Event
 */
export interface Event {
    /**
     * Time is the time when the event happened.
     *
     * @generated from protobuf field: google.protobuf.Timestamp time = 1;
     */
    time?: Timestamp;
    /**
     * event_type is the event type.
     *
     * @generated from protobuf field: k8sevent.v1.EventType event_type = 2;
     */
    event_type: EventType;
    /**
     * resource_version is the K8s resource version.
     *
     * @generated from protobuf field: string resource_version = 3;
     */
    resource_version: string;
    /**
     * resource_uuid is the k8s resource uuid.
     *
     * @generated from protobuf field: string resource_uuid = 4;
     */
    resource_uuid: string;
    /**
     * api_version is the k8s apiVersion of the resource.
     *
     * @generated from protobuf field: string api_version = 5;
     */
    api_version: string;
    /**
     * kind is the k8s kind of the resource.
     *
     * @generated from protobuf field: k8sevent.v1.Kind kind = 6;
     */
    kind: Kind;
    /**
     * name is the name of the Kubernetes resource resource the event was about.
     *
     * @generated from protobuf field: string name = 7;
     */
    name: string;
    /**
     * namespace is the namespace of the Kubernetes resource resource the event was about.
     *
     * @generated from protobuf field: string namespace = 8;
     */
    namespace: string;
    /**
     * labels are the labels on the resource when the event happened.
     *
     * @generated from protobuf field: map<string, string> labels = 9;
     */
    labels: {
        [key: string]: string;
    };
    /**
     * object is the full object stored as JSON.
     *
     * @generated from protobuf field: string object = 10;
     */
    object: string;
    /**
     * old_object is the instance of the object before the update, stored as JSON.
     *
     * @generated from protobuf field: string old_object = 11;
     */
    old_object: string;
    /**
     * cluster is the name of the cluster this event was recorded on.
     *
     * @generated from protobuf field: string cluster = 12;
     */
    cluster: string;
}
/**
 * Kind represents the Kubernetes resource Kind
 *
 * #### Experimental
 *
 * Notice: This type is EXPERIMENTAL and may be changed or removed in a
 * later release.
 *
 * @generated from protobuf enum k8sevent.v1.Kind
 */
export enum Kind {
    /**
     * The kind of the object was unspecified. This is an error.
     *
     * @generated from protobuf enum value: KIND_UNSPECIFIED = 0;
     */
    UNSPECIFIED = 0,
    /**
     * The kind of the object was CiliumNetworkPolicy
     *
     * @generated from protobuf enum value: KIND_CILIUM_NETWORK_POLICY = 1;
     */
    CILIUM_NETWORK_POLICY = 1,
    /**
     * The kind of the object was CiliumClusterwideNetworkPolicy
     *
     * @generated from protobuf enum value: KIND_CILIUM_CLUSTERWIDE_NETWORK_POLICY = 2;
     */
    CILIUM_CLUSTERWIDE_NETWORK_POLICY = 2,
    /**
     * The kind of the object was the upstream Kubernetes NetworkPolicy
     *
     * @generated from protobuf enum value: KIND_KUBERNETES_NETWORK_POLICY = 3;
     */
    KUBERNETES_NETWORK_POLICY = 3,
    /**
     * The kind of the object was the upstream Kubernetes Endpoint
     *
     * @generated from protobuf enum value: KIND_KUBERNETES_ENDPOINT = 4;
     */
    KUBERNETES_ENDPOINT = 4
}
/**
 * EventType represents the type of event that occurred.
 *
 * #### Experimental
 *
 * Notice: This type is EXPERIMENTAL and may be changed or removed in a
 * later release.
 *
 * @generated from protobuf enum k8sevent.v1.EventType
 */
export enum EventType {
    /**
     * The event type wasn't specified. This is an error.
     *
     * @generated from protobuf enum value: EVENT_TYPE_UNSPECIFIED = 0;
     */
    UNSPECIFIED = 0,
    /**
     * The resource was created.
     * The event.object contains the object created.
     *
     * @generated from protobuf enum value: EVENT_TYPE_CREATED = 1;
     */
    CREATED = 1,
    /**
     * The resource was updated.
     * The event.object contains the new version and event.old_object contains the version before it was updated.
     *
     * @generated from protobuf enum value: EVENT_TYPE_UPDATED = 2;
     */
    UPDATED = 2,
    /**
     * The resource was deleted.
     * The event.old_object contains the object that was deleted.
     *
     * @generated from protobuf enum value: EVENT_TYPE_DELETED = 3;
     */
    DELETED = 3
}
// @generated message type with reflection information, may provide speed optimized methods
class ExportEvent$Type extends MessageType<ExportEvent> {
    constructor() {
        super("k8sevent.v1.ExportEvent", [
            { no: 1, name: "time", kind: "message", T: () => Timestamp },
            { no: 2, name: "k8s_event", kind: "message", localName: "k8s_event", jsonName: "k8sEvent", T: () => Event }
        ]);
    }
    create(value?: PartialMessage<ExportEvent>): ExportEvent {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<ExportEvent>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ExportEvent): ExportEvent {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* google.protobuf.Timestamp time */ 1:
                    message.time = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.time);
                    break;
                case /* k8sevent.v1.Event k8s_event = 2 [json_name = "k8sEvent"];*/ 2:
                    message.k8s_event = Event.internalBinaryRead(reader, reader.uint32(), options, message.k8s_event);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ExportEvent, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* google.protobuf.Timestamp time = 1; */
        if (message.time)
            Timestamp.internalBinaryWrite(message.time, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* k8sevent.v1.Event k8s_event = 2 [json_name = "k8sEvent"]; */
        if (message.k8s_event)
            Event.internalBinaryWrite(message.k8s_event, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message k8sevent.v1.ExportEvent
 */
export const ExportEvent = new ExportEvent$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Event$Type extends MessageType<Event> {
    constructor() {
        super("k8sevent.v1.Event", [
            { no: 1, name: "time", kind: "message", T: () => Timestamp },
            { no: 2, name: "event_type", kind: "enum", localName: "event_type", T: () => ["k8sevent.v1.EventType", EventType, "EVENT_TYPE_"] },
            { no: 3, name: "resource_version", kind: "scalar", localName: "resource_version", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "resource_uuid", kind: "scalar", localName: "resource_uuid", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "api_version", kind: "scalar", localName: "api_version", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "kind", kind: "enum", T: () => ["k8sevent.v1.Kind", Kind, "KIND_"] },
            { no: 7, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "namespace", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "labels", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } },
            { no: 10, name: "object", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "old_object", kind: "scalar", localName: "old_object", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "cluster", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Event>): Event {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.event_type = 0;
        message.resource_version = "";
        message.resource_uuid = "";
        message.api_version = "";
        message.kind = 0;
        message.name = "";
        message.namespace = "";
        message.labels = {};
        message.object = "";
        message.old_object = "";
        message.cluster = "";
        if (value !== undefined)
            reflectionMergePartial<Event>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Event): Event {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* google.protobuf.Timestamp time */ 1:
                    message.time = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.time);
                    break;
                case /* k8sevent.v1.EventType event_type */ 2:
                    message.event_type = reader.int32();
                    break;
                case /* string resource_version */ 3:
                    message.resource_version = reader.string();
                    break;
                case /* string resource_uuid */ 4:
                    message.resource_uuid = reader.string();
                    break;
                case /* string api_version */ 5:
                    message.api_version = reader.string();
                    break;
                case /* k8sevent.v1.Kind kind */ 6:
                    message.kind = reader.int32();
                    break;
                case /* string name */ 7:
                    message.name = reader.string();
                    break;
                case /* string namespace */ 8:
                    message.namespace = reader.string();
                    break;
                case /* map<string, string> labels */ 9:
                    this.binaryReadMap9(message.labels, reader, options);
                    break;
                case /* string object */ 10:
                    message.object = reader.string();
                    break;
                case /* string old_object */ 11:
                    message.old_object = reader.string();
                    break;
                case /* string cluster */ 12:
                    message.cluster = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap9(map: Event["labels"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof Event["labels"] | undefined, val: Event["labels"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.string();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field k8sevent.v1.Event.labels");
            }
        }
        map[key ?? ""] = val ?? "";
    }
    internalBinaryWrite(message: Event, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* google.protobuf.Timestamp time = 1; */
        if (message.time)
            Timestamp.internalBinaryWrite(message.time, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* k8sevent.v1.EventType event_type = 2; */
        if (message.event_type !== 0)
            writer.tag(2, WireType.Varint).int32(message.event_type);
        /* string resource_version = 3; */
        if (message.resource_version !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.resource_version);
        /* string resource_uuid = 4; */
        if (message.resource_uuid !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.resource_uuid);
        /* string api_version = 5; */
        if (message.api_version !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.api_version);
        /* k8sevent.v1.Kind kind = 6; */
        if (message.kind !== 0)
            writer.tag(6, WireType.Varint).int32(message.kind);
        /* string name = 7; */
        if (message.name !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.name);
        /* string namespace = 8; */
        if (message.namespace !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.namespace);
        /* map<string, string> labels = 9; */
        for (let k of globalThis.Object.keys(message.labels))
            writer.tag(9, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.LengthDelimited).string(message.labels[k]).join();
        /* string object = 10; */
        if (message.object !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.object);
        /* string old_object = 11; */
        if (message.old_object !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.old_object);
        /* string cluster = 12; */
        if (message.cluster !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.cluster);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message k8sevent.v1.Event
 */
export const Event = new Event$Type();
