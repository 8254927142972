import React from 'react';

import classNames from 'classnames';
import * as mobx from 'mobx';
import { observer } from 'mobx-react';

import { useRefsCollector } from '~/components/ProcessTree/RefsCollectorContext';
import { ServiceIcon } from '~/components/ServiceIcon';
import { XY } from '~/domain/geometry';
import { IPEndpoint } from '~/domain/interactions/endpoints';
import { DestinationGroup } from '~/domain/process-tree';
import { logger } from '~/utils/logger';

import { EndpointEntry } from './common';
import { Endpoint } from './Endpoint';
import { EndpointGroup } from './EndpointGroup';
import css from './styles.scss';

export interface Props {
  name: string;
  group: DestinationGroup;

  // NOTE: this flag controls whether ip:port or just port will be rendered
  isIpVisible?: boolean;

  // NOTE: By default non-separated endpoints are grouped into single entry.
  // NOTE: This flag controls if that single entry is expanded, i e whether
  // NOTE: every nested endpoint is visible. Note also that separated endpoints
  // NOTE: are always expanded.
  isExpanded?: boolean;
  iconsPrefix?: string;
  isDarkThemeEnabled: boolean;

  onConnectorCoords?: (port: number, xy: XY, ipAddress?: string) => void;
  onDestinationClick?: (port?: number, ipAddress?: string) => void;
  onExpandToggle?: () => void;
}

// NOTE: it assumes that DestinationGroup guarantees that a port can only be
// NOTE: either in grouped endoints or in separated, but not in both of them
export const EgressGroup = observer(function EgressGroup(props: Props) {
  const themePrefix = props.isDarkThemeEnabled ? 'dark-' : '';
  const ictx = useRefsCollector();

  // NOTE: we show expandable entries on top and then suspicious (plain) entries
  const sortedEndpoints = mobx
    .computed(() => {
      let endpoints: EndpointEntry[] = [];

      const group: IPEndpoint[] = [];
      props.group.destinations.forEach(ipEndpoint => {
        group.push(ipEndpoint);
      });

      if (group.length > 0) {
        group.sort(endpointSortFn);
        endpoints.push(EndpointEntry.newGroup(group));
      }

      const separated: IPEndpoint[] = [];
      props.group.separatedDestinations.forEach(endpoint => {
        separated.push(endpoint);
      });

      separated.sort(endpointSortFn);
      endpoints = endpoints.concat(separated.map(EndpointEntry.newStandalone));

      return endpoints;
    })
    .get();

  const labels = mobx
    .computed(() => {
      return [...props.group.labels].join(', ');
    })
    .get();

  const classes = classNames(css.egressGroup, {
    [css.clickable]: props.onDestinationClick != null,
  });

  return (
    <div className={classes}>
      <div
        className={css.headline}
        onClick={() => {
          props.onDestinationClick?.();
        }}
      >
        <ServiceIcon
          className={css.icon}
          labels={[...props.group.labels]}
          dns={props.group.name}
          default={`/icons/processes/${themePrefix}planet-icon.png`}
          iconsPrefix={props.iconsPrefix}
        />

        <div className={css.captions}>
          <div className={css.caption}>{props.name}</div>
          {labels.length > 0 && (
            <div className={css.labels} title={labels}>
              {labels}
            </div>
          )}
        </div>
      </div>

      <div className={css.endpoints}>
        {sortedEndpoints.map(endpointEntry => {
          if (endpointEntry.isGroup) {
            return (
              <EndpointGroup
                key={endpointEntry.key}
                group={endpointEntry.group || []}
                groupName={props.group.name}
                isExpanded={props.isExpanded}
                isIpVisible={!!props.isIpVisible}
                onExpandToggle={props.onExpandToggle}
                onClick={props.onDestinationClick}
                onGroupClick={() => {
                  logger.warn('collapsed group click handling is not implemented');
                }}
                onPortConnectorCoords={props.onConnectorCoords}
              />
            );
          }

          if (endpointEntry.endpoint == null) return null;
          const ip = endpointEntry.endpoint.ip;
          const ports = endpointEntry.endpoint.ports;

          return [...ports].map(port => {
            return (
              <Endpoint
                key={`${ip}:${port}`}
                ipAddress={ip}
                port={port}
                connectorRef={ictx.destinationPortConnectorRef(
                  props.group.name,
                  port,
                  props.isIpVisible ? ip : void 0,
                )}
                onClick={() => props.onDestinationClick?.(port, ip)}
              />
            );
          });
        })}
      </div>
    </div>
  );
});

const endpointSortFn = (a: IPEndpoint, b: IPEndpoint): number => {
  if (a.ip === b.ip) return a.ports.size - b.ports.size;

  return a.ipWeight - b.ipWeight;
};
