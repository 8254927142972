import React from 'react';

import { observer } from 'mobx-react';

import { ServiceCard } from '~/components/ServiceMapCard/types';
import { useStore } from '~/store';

import { extractLogo } from './helpers';
import * as logos from './logos';
import css from './styles.scss';

export interface Props {
  card: ServiceCard;
}

export const EndpointLogo = observer(function EndpointLogo({ card }: Props) {
  const store = useStore();
  const themeStore = store.themes;
  const isDarkThemeActive = themeStore.isDarkTheme;

  const { id } = extractLogo(card);
  const url = (logos as any)[id] as string;

  const themePrefix = isDarkThemeActive ? 'dark' : '';

  const getLogoUrl = () => {
    if (url.includes('kafka') && isDarkThemeActive) {
      return url.replace('/kafka', `/${themePrefix}-kafka`);
    }
    return url;
  };

  return <div className={css.logo} style={{ backgroundImage: `url(${getLogoUrl()})` }} />;
});

export default EndpointLogo;
