import React, { useCallback, useMemo } from 'react';

import { Button, Intent } from '@blueprintjs/core';
import { observer } from 'mobx-react';

import { PolicyCard } from '~/domain/cimulator/cards';
import { Flow } from '~/domain/flows';
import { useStore } from '~/store/stores/cimulator';
import { AnalyticsTrackKind, track } from '~/utils/analytics';

export interface Props {
  flow: Flow;
}

export const FlowActionComponent = observer(function FlowActionComponent(props: Props) {
  const store = useStore();

  const entries = useMemo(() => {
    return store.policy.flowToCards(props.flow);
  }, [props.flow, store.policy.cardsMap]);

  const action = useMemo(() => {
    if (entries.length === 0) return 'unavailable';
    const someAllowed = entries.some(entry => {
      return entry.endpoints.some(endpoint => {
        const fullEndpointId = PolicyCard.buildFullEndpointId(
          entry.cardSide,
          entry.cardKind,
          endpoint.id,
        );
        return store.policy.isAllowedEndpoint(fullEndpointId);
      });
    });

    return someAllowed ? 'deny' : 'allow';
  }, [props.flow, entries, store.policy.allowedEndpointsSet]);

  const label = useMemo(() => {
    switch (action) {
      case 'deny':
        return 'Remove rule from policy';
      case 'allow':
        return 'Add rule to policy';
      case 'unavailable':
        return "Can't create policy rule";
    }
  }, [action]);

  const onClick = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      if (action === 'unavailable') return;
      track(AnalyticsTrackKind.ToggleRuleFromFlow);
      entries.forEach(entry => {
        const card = store.policy.getCardBy(entry.cardSide, entry.cardKind);
        if (!card) return;
        entry.endpoints.forEach(endpoint => {
          const allowState = action === 'allow' ? true : false;
          if (allowState) card.addEndpoints(endpoint);
          const fullEndpointId = card.fullEndpointId(endpoint.id);
          store.policy.setAllowedEndpoint(fullEndpointId, allowState);
          store.controls.selectCardId(allowState ? card.id : null);
          store.controls.selectEndpointId(allowState ? endpoint.id : null);
        });
      });
    },
    [entries, action],
  );

  let intent: Intent = Intent.NONE;
  if (action === 'allow') intent = Intent.SUCCESS;
  if (action === 'deny') intent = Intent.DANGER;
  if (action === 'unavailable') intent = Intent.WARNING;

  return (
    <Button outlined onClick={onClick} intent={intent} disabled={action === 'unavailable'}>
      {label}
    </Button>
  );
});
