import { Message, Oneshot, OneshotOptions } from '~/api/customprotocol-core';
import { Filters } from '~/domain/filtering';
import { ServiceMap } from '~/domain/service-map';
import { ProtoFactory } from '~/factories/proto';
import * as uipb from '~backend/proto/ui/ui_pb';

export type Options = OneshotOptions & {
  logs: ArrayBuffer;
  filters?: Filters;
};

export enum Event {
  ServiceMap = 'service-map',
}

export type Handlers = {
  [Event.ServiceMap]: (sm: ServiceMap) => void;
};

export class ServiceMapFromLogsOneshot extends Oneshot<Handlers> {
  private logs: ArrayBuffer;
  private filters: Filters;

  constructor(opts: Options) {
    super(opts);

    this.logs = opts.logs;
    this.filters = opts.filters ?? Filters.default();

    this.setupEventHandlers();
  }

  public onServiceMap(fn: Handlers[Event.ServiceMap]): this {
    this.on(Event.ServiceMap, fn);
    return this;
  }

  protected messageBuilder(msg: Message, isFirst: boolean): Message {
    if (!isFirst) return msg;

    const req = ProtoFactory.serviceMapFromLogsRequest(this.logs, this.filters);
    msg.setBodyBytes(uipb.GetServiceMapFromLogsRequest.toBinary(req));

    return msg;
  }

  private setupEventHandlers() {
    this.onMessage(msg => {
      const resp = uipb.GetServiceMapFromLogsResponse.fromBinary(msg.body);

      const serviceMap = ProtoFactory.serviceMapFromLogsResponse(resp);
      this.emit(Event.ServiceMap, serviceMap);
    });
  }
}
