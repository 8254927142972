import React, { useRef } from 'react';

import classNames from 'classnames';

import { DragOptions, useMouseDrag } from '~/ui/hooks';

import css from './styles.scss';

export interface Props {
  className?: string;
  horizontal?: boolean;
  vertical?: boolean;

  stickToTop?: boolean;
  stickToBottom?: boolean;
  stickToLeft?: boolean;
  stickToRight?: boolean;

  resizeThrottling?: number;
  onResize?: (yDiff: number) => void;
}

const nelems = (n: number): number[] => {
  return Array(n).fill(0);
};

// NOTE: 8 squares in a row
export const nSquares = 8;

export const ResizeHandle = function ResizeHandle(props: Props) {
  const divRef = useRef<HTMLDivElement | null>(null);

  const dragOpts: DragOptions = {
    throttling: props.resizeThrottling,
  };

  useMouseDrag(divRef, dragOpts, (coords, flags) => {
    if (!flags.isMoving) return;
    props.onResize?.(coords.diff.y);
  });

  const horizontal = props.horizontal ?? true;
  const classes = classNames(css.resizeHandle, props.className, {
    [css.horizontal]: horizontal,
    [css.vertical]: !!props.vertical,
    [css.stickToTop]: !!props.stickToTop,
    [css.stickToBottom]: !!props.stickToBottom,
    [css.stickToLeft]: !!props.stickToLeft,
    [css.stickToRight]: !!props.stickToRight,
  });

  return (
    <div className={classes} ref={divRef}>
      <div className={css.dots}>
        <div className={css.topLine}>
          {nelems(nSquares).map((_, idx) => {
            return <span key={idx} className={css.dot} />;
          })}
        </div>

        <div className={css.bottomLine}>
          {nelems(nSquares).map((_, idx) => {
            return <span key={idx} className={css.dot} />;
          })}
        </div>
      </div>
    </div>
  );
};
