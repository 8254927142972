import { FilterDirection, FilterKind } from '~/domain/filtering';

import { FilterOption } from './types';

export interface ConfigComponents {
  cluster?: {
    enabled: boolean;
    list?: string[];
    component: FilterConfig['component'];
  };
  namespace?: {
    enabled: boolean;
    list?: string[];
    component: FilterConfig['component'];
  };
}

export const filtersConfig = (components: ConfigComponents): FilterConfig[] => [
  {
    kind: FilterKind.Negative,
    label: 'Negative',
    direction: FilterDirection.Either,
    component: 'checkbox',
    filterComponentProps: {},
  },
  {
    kind: FilterKind.Direction,
    label: 'Direction',
    direction: FilterDirection.Either,
    component: 'buttongroup',
    filterComponentProps: {},
  },
  ...(components?.cluster?.enabled
    ? [
        {
          kind: FilterKind.Cluster,
          label: 'Cluster',
          direction: FilterDirection.Either,
          component: components.cluster.component,
          filterComponentProps: {
            placeholder: 'Choose a cluster',
            options: components.cluster.list?.map(n => ({ id: n, label: n, value: n })),
          },
        } as FilterConfig,
      ]
    : []),
  ...(components?.namespace?.enabled
    ? [
        {
          kind: FilterKind.Namespace,
          label: 'Namespace',
          direction: FilterDirection.Either,
          component: components.namespace.component,
          filterComponentProps: {
            placeholder: 'Choose a namespace',
            options: components.namespace.list?.map(n => ({ id: n, label: n, value: n })),
          },
        } as FilterConfig,
      ]
    : []),
  {
    kind: FilterKind.Label,
    label: 'Label',
    direction: FilterDirection.Either,
    component: 'input',
    filterComponentProps: {},
  },
  {
    id: 'ip',
    kind: FilterKind.Ip,
    label: 'IP',
    direction: FilterDirection.Either,
    component: 'input',
    filterComponentProps: {
      type: 'text',
    },
  },
  {
    kind: FilterKind.Dns,
    label: 'DNS',
    direction: FilterDirection.Either,
    component: 'input',
    filterComponentProps: {
      type: 'text',
    },
  },
  {
    kind: FilterKind.Identity,
    label: 'Identity',
    direction: FilterDirection.Either,
    component: 'input',
    filterComponentProps: {
      type: 'text',
    },
  },
  {
    kind: FilterKind.TCPFlag,
    label: 'TCP Flag',
    direction: FilterDirection.Either,
    component: 'input',
    filterComponentProps: {
      type: 'text',
    },
  },
  {
    kind: FilterKind.Pod,
    label: 'Pod',
    direction: FilterDirection.Either,
    component: 'input',
    filterComponentProps: {
      type: 'text',
    },
  },
  {
    kind: FilterKind.Workload,
    label: 'Workload',
    direction: FilterDirection.Either,
    component: 'input',
    filterComponentProps: {
      type: 'text',
    },
  },
  {
    kind: FilterKind.Port,
    label: 'Port',
    direction: FilterDirection.Either,
    component: 'input',
    filterComponentProps: {
      type: 'text',
    },
  },
  {
    kind: FilterKind.Protocol,
    label: 'Protocol',
    direction: FilterDirection.Either,
    component: 'input',
    filterComponentProps: {
      type: 'text',
    },
  },
];

export type FilterConfig = {
  id?: string;
  kind: FilterKind;
  label: string;
  direction: FilterDirection;
  component: 'select' | 'input' | 'checkbox' | 'buttongroup';
  filterComponentProps: React.InputHTMLAttributes<HTMLInputElement> & { options?: FilterOption[] };
  fromFilterTagValueToComponentValue?: (value: string) => any;
  itemToString?: (ite: any) => any;
};
