/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter add_pb_suffix,eslint_disable,ts_nocheck,generate_dependencies,long_type_number,use_proto_field_name
// @generated from protobuf file "ui/ui.proto" (package "ui", syntax proto3)
// tslint:disable
// @ts-nocheck
//
// Copyright 2020 Authors of Cilium
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
//
import { GetTimescapeProcessEventsResponse } from "./process-events_pb";
import { GetTimescapeProcessEventsRequest } from "./process-events_pb";
import { GetK8sEventsRequest } from "../timescape/v0/k8s_events_pb";
import { GetFeatureFlagsResponse } from "./feature-flags_pb";
import { GetFeatureFlagsRequest } from "./feature-flags_pb";
import { GetAuthzResponse } from "./authorization_pb";
import { GetAuthzRequest } from "./authorization_pb";
import { GetNodesResponse } from "../observer/observer_pb";
import { GetNodesRequest } from "../observer/observer_pb";
import { ServerStatusResponse } from "../observer/observer_pb";
import { ServerStatusRequest } from "../observer/observer_pb";
import { GetStatusResponse } from "./status_pb";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Event as Event$ } from "../k8sevent/v1/event_pb";
import { EventsContainerInfo } from "../timescape/v1/container_pb";
import { GetContainersFromEventsRequest } from "../timescape/v1/container_pb";
import { TCPFlags } from "../flow/flow_pb";
import { GetFlowsRequest } from "../observer/observer_pb";
import { Duration } from "../google/protobuf/duration_pb";
import { AuthType } from "../flow/flow_pb";
import { Verdict } from "../flow/flow_pb";
import { Workload } from "../flow/flow_pb";
import { FlowFilter } from "../flow/flow_pb";
import { Notification } from "./notifications_pb";
import { Flow } from "../flow/flow_pb";
import { Aggregation } from "../aggregation/aggregation_pb";
import { GetStatusRequest } from "./status_pb";
import { Timestamp } from "../google/protobuf/timestamp_pb";
/**
 * Here I didn't include "follow", "until", and "number". This request assumes
 * follow, and lets the client decide when to end the request, whether it's
 * based on timestamp or the number of responses received.
 *
 * @generated from protobuf message ui.GetEventsRequest
 */
export interface GetEventsRequest {
    /**
     * EventType specifies which types of events to subscribe to. If unspecified,
     * the request is subscribed to all the event types.
     *
     * @generated from protobuf field: repeated ui.EventType event_types = 1;
     */
    event_types: EventType[];
    /**
     * @generated from protobuf field: repeated ui.EventFilter blacklist = 2;
     */
    blacklist: EventFilter[];
    /**
     * @generated from protobuf field: repeated ui.EventFilter whitelist = 3;
     */
    whitelist: EventFilter[];
    /**
     * @generated from protobuf field: google.protobuf.Timestamp since = 4;
     */
    since?: Timestamp;
    /**
     * @generated from protobuf field: ui.GetStatusRequest status_request = 5;
     */
    status_request?: GetStatusRequest;
    /**
     * @generated from protobuf field: isovalent.flow.aggregation.Aggregation aggregation = 10001;
     */
    aggregation?: Aggregation;
}
/**
 * @generated from protobuf message ui.GetEventsResponse
 */
export interface GetEventsResponse {
    /**
     * @generated from protobuf field: string node = 1;
     */
    node: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp timestamp = 2;
     */
    timestamp?: Timestamp;
    /**
     * @generated from protobuf field: repeated ui.Event events = 3;
     */
    events: Event[];
}
/**
 * @generated from protobuf message ui.Event
 */
export interface Event {
    /**
     * @generated from protobuf oneof: event
     */
    event: {
        oneofKind: "flow";
        /**
         * @generated from protobuf field: flow.Flow flow = 3;
         */
        flow: Flow;
    } | {
        oneofKind: "namespace_state";
        /**
         * @generated from protobuf field: ui.NamespaceState namespace_state = 4;
         */
        namespace_state: NamespaceState;
    } | {
        oneofKind: "service_state";
        /**
         * @generated from protobuf field: ui.ServiceState service_state = 5;
         */
        service_state: ServiceState;
    } | {
        oneofKind: "service_link_state";
        /**
         * It's unclear how we can define what it means for service links to get
         * added/deleted. For now I'm deferring the decision for whether a
         * particular link should be included in the service map to the client.
         * Maybe the server can periodically send service_link events for active
         * links to keep-alive, and the client can expire older links based on their
         * timestamp.
         *
         * @generated from protobuf field: ui.ServiceLinkState service_link_state = 6;
         */
        service_link_state: ServiceLinkState;
    } | {
        oneofKind: "policies";
        /**
         * @generated from protobuf field: ui.Policies policies = 7;
         */
        policies: Policies;
    } | {
        oneofKind: "flows";
        /**
         * @generated from protobuf field: ui.Flows flows = 8;
         */
        flows: Flows;
    } | {
        oneofKind: "notification";
        /**
         * @generated from protobuf field: ui.Notification notification = 9;
         */
        notification: Notification;
    } | {
        oneofKind: undefined;
    };
}
/**
 * @generated from protobuf message ui.Flows
 */
export interface Flows {
    /**
     * @generated from protobuf field: repeated flow.Flow flows = 1;
     */
    flows: Flow[];
}
/**
 * @generated from protobuf message ui.Policies
 */
export interface Policies {
    /**
     * @generated from protobuf field: repeated ui.PolicySpecState policies = 1;
     */
    policies: PolicySpecState[];
}
/**
 * Here I added different filter types for different events. Another option is
 * to reuse and extend FlowFilter to filter all the events, but I worry that it
 * might become too confusing in terms of figuring out which filter fields apply
 * to which events.
 *
 * @generated from protobuf message ui.EventFilter
 */
export interface EventFilter {
    /**
     * @generated from protobuf oneof: filter
     */
    filter: {
        oneofKind: "flow_filter";
        /**
         * @generated from protobuf field: flow.FlowFilter flow_filter = 2;
         */
        flow_filter: FlowFilter;
    } | {
        oneofKind: "service_filter";
        /**
         * @generated from protobuf field: ui.ServiceFilter service_filter = 3;
         */
        service_filter: ServiceFilter;
    } | {
        oneofKind: "service_link_filter";
        /**
         * @generated from protobuf field: ui.ServiceLinkFilter service_link_filter = 4;
         */
        service_link_filter: ServiceLinkFilter;
    } | {
        oneofKind: "policy_spec_filter";
        /**
         * @generated from protobuf field: ui.PolicySpecFilter policy_spec_filter = 5;
         */
        policy_spec_filter: PolicySpecFilter;
    } | {
        oneofKind: undefined;
    };
}
/**
 * @generated from protobuf message ui.NamespaceDescriptor
 */
export interface NamespaceDescriptor {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp creation_timestamp = 3;
     */
    creation_timestamp?: Timestamp;
    /**
     * @generated from protobuf field: string cluster = 5;
     */
    cluster: string;
}
/**
 * @generated from protobuf message ui.NamespaceState
 */
export interface NamespaceState {
    /**
     * @generated from protobuf field: ui.NamespaceDescriptor namespace = 1;
     */
    namespace?: NamespaceDescriptor;
    /**
     * @generated from protobuf field: ui.StateChange type = 2;
     */
    type: StateChange;
}
/**
 * Come up with a better name for Service.
 *
 * @generated from protobuf message ui.Service
 */
export interface Service {
    /**
     * An opaque ID that uniquely identifies the service.
     *
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: string namespace = 3;
     */
    namespace: string;
    /**
     * @generated from protobuf field: repeated string labels = 4;
     */
    labels: string[];
    /**
     * @generated from protobuf field: repeated string dns_names = 5;
     */
    dns_names: string[];
    /**
     * @generated from protobuf field: bool egress_policy_enforced = 6;
     */
    egress_policy_enforced: boolean;
    /**
     * @generated from protobuf field: bool ingress_policy_enforced = 7;
     */
    ingress_policy_enforced: boolean;
    /**
     * This is a string instead of boolean because Cilium reports status message.
     * If it's successfully enabled, this string is set to "OK" I think.
     *
     * @generated from protobuf field: string visibility_policy_status = 8;
     */
    visibility_policy_status: string;
    /**
     * We probably can't reliably set creation timestamp.
     *
     * @generated from protobuf field: google.protobuf.Timestamp creation_timestamp = 9;
     */
    creation_timestamp?: Timestamp;
    /**
     * @generated from protobuf field: repeated flow.Workload workloads = 10;
     */
    workloads: Workload[];
    /**
     * @generated from protobuf field: uint32 identity = 12;
     */
    identity: number;
}
/**
 * @generated from protobuf message ui.ServiceState
 */
export interface ServiceState {
    /**
     * @generated from protobuf field: ui.Service service = 1;
     */
    service?: Service;
    /**
     * @generated from protobuf field: ui.StateChange type = 2;
     */
    type: StateChange;
}
/**
 * @generated from protobuf message ui.ServiceFilter
 */
export interface ServiceFilter {
    /**
     * @generated from protobuf field: repeated string namespace = 1;
     */
    namespace: string[];
}
/**
 * @generated from protobuf message ui.ServiceLink
 */
export interface ServiceLink {
    /**
     * An opaque ID that uniquely identifies this link.
     *
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * source Service id
     *
     * @generated from protobuf field: string source_id = 2;
     */
    source_id: string;
    /**
     * destination Service id
     *
     * @generated from protobuf field: string destination_id = 3;
     */
    destination_id: string;
    /**
     * @generated from protobuf field: uint32 destination_port = 4;
     */
    destination_port: number;
    /**
     * @generated from protobuf field: ui.IPProtocol ip_protocol = 5;
     */
    ip_protocol: IPProtocol;
    /**
     * @generated from protobuf field: flow.Verdict verdict = 6;
     */
    verdict: Verdict;
    /**
     * @generated from protobuf field: uint64 flow_amount = 7;
     */
    flow_amount: number;
    /**
     * @generated from protobuf field: ui.ServiceLink.Latency latency = 8;
     */
    latency?: ServiceLink_Latency;
    /**
     * @generated from protobuf field: uint64 bytes_transfered = 9;
     */
    bytes_transfered: number;
    /**
     * @generated from protobuf field: flow.AuthType auth_type = 10;
     */
    auth_type: AuthType;
    /**
     * @generated from protobuf field: bool is_encrypted = 11;
     */
    is_encrypted: boolean;
}
/**
 * @generated from protobuf message ui.ServiceLink.Latency
 */
export interface ServiceLink_Latency {
    /**
     * @generated from protobuf field: google.protobuf.Duration min = 1;
     */
    min?: Duration;
    /**
     * @generated from protobuf field: google.protobuf.Duration max = 2;
     */
    max?: Duration;
    /**
     * @generated from protobuf field: google.protobuf.Duration avg = 3;
     */
    avg?: Duration;
}
/**
 * @generated from protobuf message ui.ServiceLinkState
 */
export interface ServiceLinkState {
    /**
     * @generated from protobuf field: ui.ServiceLink service_link = 1;
     */
    service_link?: ServiceLink;
    /**
     * @generated from protobuf field: ui.StateChange type = 2;
     */
    type: StateChange;
}
/**
 * @generated from protobuf message ui.ServiceLinkFilter
 */
export interface ServiceLinkFilter {
    /**
     * @generated from protobuf field: repeated ui.ServiceFilter source = 1;
     */
    source: ServiceFilter[];
    /**
     * @generated from protobuf field: repeated ui.ServiceFilter destination = 2;
     */
    destination: ServiceFilter[];
    /**
     * Use string here to support things like port ranges (e.g. 100-200).
     *
     * @generated from protobuf field: repeated string destination_port = 3;
     */
    destination_port: string[];
    /**
     * @generated from protobuf field: repeated flow.Verdict verdict = 4;
     */
    verdict: Verdict[];
}
/**
 * @generated from protobuf message ui.PolicySpec
 */
export interface PolicySpec {
    /**
     * @generated from protobuf field: string policyNamespace = 1;
     */
    policyNamespace: string;
    /**
     * @generated from protobuf field: string policyName = 2;
     */
    policyName: string;
    /**
     * @generated from protobuf field: ui.PolicySpecType type = 3;
     */
    type: PolicySpecType;
    /**
     * @generated from protobuf field: string yaml = 4;
     */
    yaml: string;
    /**
     * @generated from protobuf field: string uid = 5;
     */
    uid: string;
}
/**
 * @generated from protobuf message ui.PolicySpecState
 */
export interface PolicySpecState {
    /**
     * @generated from protobuf field: ui.PolicySpec policy_spec = 1;
     */
    policy_spec?: PolicySpec;
    /**
     * @generated from protobuf field: ui.StateChange type = 2;
     */
    type: StateChange;
}
/**
 * @generated from protobuf message ui.PolicySpecFilter
 */
export interface PolicySpecFilter {
    /**
     * @generated from protobuf field: repeated string policyNamespace = 1;
     */
    policyNamespace: string[];
    /**
     * @generated from protobuf field: repeated string policyName = 2;
     */
    policyName: string[];
    /**
     * @generated from protobuf field: repeated ui.PolicySpecType type = 3;
     */
    type: PolicySpecType[];
}
/**
 * @generated from protobuf message ui.GetTimescapeDataRequest
 */
export interface GetTimescapeDataRequest {
    /**
     * @generated from protobuf field: observer.GetFlowsRequest flows_request = 1;
     */
    flows_request?: GetFlowsRequest;
    /**
     * @generated from protobuf field: ui.GetTimescapeDataRequest.LastDatumSeen last_seen = 2;
     */
    last_seen?: GetTimescapeDataRequest_LastDatumSeen;
    /**
     * @generated from protobuf field: bool last_flow_seen_filled = 3;
     */
    last_flow_seen_filled: boolean;
    /**
     * @generated from protobuf field: int32 limit = 4;
     */
    limit: number;
}
/**
 * @generated from protobuf message ui.GetTimescapeDataRequest.LastDatumSeen
 */
export interface GetTimescapeDataRequest_LastDatumSeen {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp timestamp = 2;
     */
    timestamp?: Timestamp;
}
/**
 * @generated from protobuf message ui.GetTimescapeDataResponse
 */
export interface GetTimescapeDataResponse {
    /**
     * @generated from protobuf field: repeated flow.Flow flows = 1;
     */
    flows: Flow[];
    /**
     * NOTE: this fields seem to be redundant as services and links should be
     * NOTE: streamed. So, consider it to remove.
     *
     * @generated from protobuf field: repeated ui.Service service = 2;
     */
    service: Service[];
    /**
     * @generated from protobuf field: repeated ui.ServiceLink service_link = 3;
     */
    service_link: ServiceLink[];
    /**
     * @generated from protobuf field: repeated ui.NamespaceDescriptor namespaces = 4;
     */
    namespaces: NamespaceDescriptor[];
    /**
     * @generated from protobuf field: repeated ui.CountStats count_stats = 5;
     */
    count_stats: CountStats[];
    /**
     * @generated from protobuf field: repeated ui.FlowSummary flow_summaries = 6;
     */
    flow_summaries: FlowSummary[];
}
/**
 * @generated from protobuf message ui.GetFullFlowRequest
 */
export interface GetFullFlowRequest {
    /**
     * @generated from protobuf field: string flow_summary_id = 1;
     */
    flow_summary_id: string;
}
/**
 * @generated from protobuf message ui.GetFullFlowResponse
 */
export interface GetFullFlowResponse {
    /**
     * @generated from protobuf field: flow.Flow flow = 1;
     */
    flow?: Flow;
    /**
     * @generated from protobuf field: bool found = 2;
     */
    found: boolean;
}
/**
 * @generated from protobuf message ui.CountStats
 */
export interface CountStats {
    /**
     * @generated from protobuf field: google.protobuf.Timestamp timestamp = 1;
     */
    timestamp?: Timestamp;
    /**
     * @generated from protobuf field: int32 count = 2;
     */
    count: number;
}
/**
 * @generated from protobuf message ui.FlowSummary
 */
export interface FlowSummary {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp time = 2;
     */
    time?: Timestamp;
    /**
     * @generated from protobuf field: string source_pod = 3;
     */
    source_pod: string;
    /**
     * @generated from protobuf field: repeated string source_labels = 4;
     */
    source_labels: string[];
    /**
     * @generated from protobuf field: string source_ip = 5;
     */
    source_ip: string;
    /**
     * @generated from protobuf field: string destination_pod = 6;
     */
    destination_pod: string;
    /**
     * @generated from protobuf field: repeated string destination_labels = 7;
     */
    destination_labels: string[];
    /**
     * @generated from protobuf field: string destination_ip = 8;
     */
    destination_ip: string;
    /**
     * @generated from protobuf field: uint32 destination_port = 9;
     */
    destination_port: number;
    /**
     * @generated from protobuf field: flow.Verdict verdict = 10;
     */
    verdict: Verdict;
    /**
     * @generated from protobuf field: flow.TCPFlags tcp_flags = 11;
     */
    tcp_flags?: TCPFlags;
    /**
     * @generated from protobuf field: ui.FlowSummary.HasProps has = 12;
     */
    has?: FlowSummary_HasProps;
    /**
     * @generated from protobuf field: repeated string destination_names = 14;
     */
    destination_names: string[];
    /**
     * @generated from protobuf field: repeated flow.Workload source_workloads = 15;
     */
    source_workloads: Workload[];
    /**
     * @generated from protobuf field: repeated flow.Workload destination_workloads = 16;
     */
    destination_workloads: Workload[];
    /**
     * @generated from protobuf field: flow.AuthType auth_type = 17;
     */
    auth_type: AuthType;
}
/**
 * @generated from protobuf message ui.FlowSummary.HasProps
 */
export interface FlowSummary_HasProps {
    /**
     * @generated from protobuf field: bool destination_port = 1;
     */
    destination_port: boolean;
    /**
     * @generated from protobuf field: bool tcp_flags = 2;
     */
    tcp_flags: boolean;
}
/**
 * @generated from protobuf message ui.GetControlStreamRequest
 */
export interface GetControlStreamRequest {
}
/**
 * @generated from protobuf message ui.GetControlStreamResponse
 */
export interface GetControlStreamResponse {
    /**
     * @generated from protobuf oneof: event
     */
    event: {
        oneofKind: "namespaces";
        /**
         * @generated from protobuf field: ui.GetControlStreamResponse.NamespaceStates namespaces = 1;
         */
        namespaces: GetControlStreamResponse_NamespaceStates;
    } | {
        oneofKind: "notification";
        /**
         * @generated from protobuf field: ui.Notification notification = 2;
         */
        notification: Notification;
    } | {
        oneofKind: undefined;
    };
}
/**
 * @generated from protobuf message ui.GetControlStreamResponse.NamespaceStates
 */
export interface GetControlStreamResponse_NamespaceStates {
    /**
     * @generated from protobuf field: repeated ui.NamespaceState namespaces = 1;
     */
    namespaces: NamespaceState[];
}
/**
 * @generated from protobuf message ui.GetTimescapePodsRequest
 */
export interface GetTimescapePodsRequest {
    /**
     * @generated from protobuf field: timescape.v1.GetContainersFromEventsRequest request = 1;
     */
    request?: GetContainersFromEventsRequest;
}
/**
 * @generated from protobuf message ui.GetTimescapePodsResponse
 */
export interface GetTimescapePodsResponse {
    /**
     * @generated from protobuf field: repeated timescape.v1.EventsContainerInfo containers = 1;
     */
    containers: EventsContainerInfo[];
}
/**
 * @generated from protobuf message ui.GetServiceMapFromLogsRequest
 */
export interface GetServiceMapFromLogsRequest {
    /**
     * @generated from protobuf field: bytes logs = 1;
     */
    logs: Uint8Array;
    /**
     * @generated from protobuf field: repeated ui.EventFilter blacklist = 2;
     */
    blacklist: EventFilter[];
    /**
     * @generated from protobuf field: repeated ui.EventFilter whitelist = 3;
     */
    whitelist: EventFilter[];
}
/**
 * @generated from protobuf message ui.GetServiceMapFromLogsResponse
 */
export interface GetServiceMapFromLogsResponse {
    /**
     * @generated from protobuf field: repeated ui.Service services = 1;
     */
    services: Service[];
    /**
     * @generated from protobuf field: repeated ui.ServiceLink links = 2;
     */
    links: ServiceLink[];
}
/**
 * @generated from protobuf message ui.GetTimescapeK8SEventsResponse
 */
export interface GetTimescapeK8SEventsResponse {
    /**
     * @generated from protobuf field: repeated k8sevent.v1.Event events = 1;
     */
    events: Event$[];
}
/**
 * @generated from protobuf enum ui.EventType
 */
export enum EventType {
    /**
     * @generated from protobuf enum value: UNKNOWN_EVENT = 0;
     */
    UNKNOWN_EVENT = 0,
    /**
     * @generated from protobuf enum value: FLOW = 1;
     */
    FLOW = 1,
    /**
     * @generated from protobuf enum value: K8S_NAMESPACE_STATE = 2;
     */
    K8S_NAMESPACE_STATE = 2,
    /**
     * @generated from protobuf enum value: SERVICE_STATE = 3;
     */
    SERVICE_STATE = 3,
    /**
     * @generated from protobuf enum value: SERVICE_LINK_STATE = 4;
     */
    SERVICE_LINK_STATE = 4,
    /**
     * @generated from protobuf enum value: POLICY_STATE = 5;
     */
    POLICY_STATE = 5,
    /**
     * @generated from protobuf enum value: FLOWS = 6;
     */
    FLOWS = 6,
    /**
     * @generated from protobuf enum value: STATUS = 7;
     */
    STATUS = 7
}
/**
 * IP protocols. The values of enums do not correspond to actual IP protocol
 * numbers.
 *
 * @generated from protobuf enum ui.IPProtocol
 */
export enum IPProtocol {
    /**
     * @generated from protobuf enum value: UNKNOWN_IP_PROTOCOL = 0;
     */
    UNKNOWN_IP_PROTOCOL = 0,
    /**
     * @generated from protobuf enum value: TCP = 1;
     */
    TCP = 1,
    /**
     * @generated from protobuf enum value: UDP = 2;
     */
    UDP = 2,
    /**
     * @generated from protobuf enum value: ICMP_V4 = 3;
     */
    ICMP_V4 = 3,
    /**
     * @generated from protobuf enum value: ICMP_V6 = 4;
     */
    ICMP_V6 = 4
}
/**
 * @generated from protobuf enum ui.PolicySpecType
 */
export enum PolicySpecType {
    /**
     * @generated from protobuf enum value: CILIUM_NETWORK_POLICY = 0;
     */
    CILIUM_NETWORK_POLICY = 0,
    /**
     * @generated from protobuf enum value: KUBERNETES_NETWORK_POLICY = 1;
     */
    KUBERNETES_NETWORK_POLICY = 1,
    /**
     * @generated from protobuf enum value: CILIUM_CLUSTERWIDE_NETWORK_POLICY = 2;
     */
    CILIUM_CLUSTERWIDE_NETWORK_POLICY = 2
}
/**
 * @generated from protobuf enum ui.StateChange
 */
export enum StateChange {
    /**
     * @generated from protobuf enum value: UNKNOWN_STATE_CHANGE = 0;
     */
    UNKNOWN_STATE_CHANGE = 0,
    /**
     * @generated from protobuf enum value: ADDED = 1;
     */
    ADDED = 1,
    /**
     * @generated from protobuf enum value: MODIFIED = 2;
     */
    MODIFIED = 2,
    /**
     * @generated from protobuf enum value: DELETED = 3;
     */
    DELETED = 3,
    /**
     * This is needed in case the relay server knows that the service exists,
     * but it doesn't know when it got created.
     *
     * @generated from protobuf enum value: EXISTS = 4;
     */
    EXISTS = 4
}
// @generated message type with reflection information, may provide speed optimized methods
class GetEventsRequest$Type extends MessageType<GetEventsRequest> {
    constructor() {
        super("ui.GetEventsRequest", [
            { no: 1, name: "event_types", kind: "enum", localName: "event_types", repeat: 1 /*RepeatType.PACKED*/, T: () => ["ui.EventType", EventType] },
            { no: 2, name: "blacklist", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => EventFilter },
            { no: 3, name: "whitelist", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => EventFilter },
            { no: 4, name: "since", kind: "message", T: () => Timestamp },
            { no: 5, name: "status_request", kind: "message", localName: "status_request", T: () => GetStatusRequest },
            { no: 10001, name: "aggregation", kind: "message", T: () => Aggregation }
        ]);
    }
    create(value?: PartialMessage<GetEventsRequest>): GetEventsRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.event_types = [];
        message.blacklist = [];
        message.whitelist = [];
        if (value !== undefined)
            reflectionMergePartial<GetEventsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetEventsRequest): GetEventsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated ui.EventType event_types */ 1:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.event_types.push(reader.int32());
                    else
                        message.event_types.push(reader.int32());
                    break;
                case /* repeated ui.EventFilter blacklist */ 2:
                    message.blacklist.push(EventFilter.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated ui.EventFilter whitelist */ 3:
                    message.whitelist.push(EventFilter.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* google.protobuf.Timestamp since */ 4:
                    message.since = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.since);
                    break;
                case /* ui.GetStatusRequest status_request */ 5:
                    message.status_request = GetStatusRequest.internalBinaryRead(reader, reader.uint32(), options, message.status_request);
                    break;
                case /* isovalent.flow.aggregation.Aggregation aggregation */ 10001:
                    message.aggregation = Aggregation.internalBinaryRead(reader, reader.uint32(), options, message.aggregation);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetEventsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated ui.EventType event_types = 1; */
        if (message.event_types.length) {
            writer.tag(1, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.event_types.length; i++)
                writer.int32(message.event_types[i]);
            writer.join();
        }
        /* repeated ui.EventFilter blacklist = 2; */
        for (let i = 0; i < message.blacklist.length; i++)
            EventFilter.internalBinaryWrite(message.blacklist[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* repeated ui.EventFilter whitelist = 3; */
        for (let i = 0; i < message.whitelist.length; i++)
            EventFilter.internalBinaryWrite(message.whitelist[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp since = 4; */
        if (message.since)
            Timestamp.internalBinaryWrite(message.since, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* ui.GetStatusRequest status_request = 5; */
        if (message.status_request)
            GetStatusRequest.internalBinaryWrite(message.status_request, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* isovalent.flow.aggregation.Aggregation aggregation = 10001; */
        if (message.aggregation)
            Aggregation.internalBinaryWrite(message.aggregation, writer.tag(10001, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ui.GetEventsRequest
 */
export const GetEventsRequest = new GetEventsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetEventsResponse$Type extends MessageType<GetEventsResponse> {
    constructor() {
        super("ui.GetEventsResponse", [
            { no: 1, name: "node", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "timestamp", kind: "message", T: () => Timestamp },
            { no: 3, name: "events", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Event }
        ]);
    }
    create(value?: PartialMessage<GetEventsResponse>): GetEventsResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.node = "";
        message.events = [];
        if (value !== undefined)
            reflectionMergePartial<GetEventsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetEventsResponse): GetEventsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string node */ 1:
                    message.node = reader.string();
                    break;
                case /* google.protobuf.Timestamp timestamp */ 2:
                    message.timestamp = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.timestamp);
                    break;
                case /* repeated ui.Event events */ 3:
                    message.events.push(Event.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetEventsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string node = 1; */
        if (message.node !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.node);
        /* google.protobuf.Timestamp timestamp = 2; */
        if (message.timestamp)
            Timestamp.internalBinaryWrite(message.timestamp, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* repeated ui.Event events = 3; */
        for (let i = 0; i < message.events.length; i++)
            Event.internalBinaryWrite(message.events[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ui.GetEventsResponse
 */
export const GetEventsResponse = new GetEventsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Event$Type extends MessageType<Event> {
    constructor() {
        super("ui.Event", [
            { no: 3, name: "flow", kind: "message", oneof: "event", T: () => Flow },
            { no: 4, name: "namespace_state", kind: "message", localName: "namespace_state", oneof: "event", T: () => NamespaceState },
            { no: 5, name: "service_state", kind: "message", localName: "service_state", oneof: "event", T: () => ServiceState },
            { no: 6, name: "service_link_state", kind: "message", localName: "service_link_state", oneof: "event", T: () => ServiceLinkState },
            { no: 7, name: "policies", kind: "message", oneof: "event", T: () => Policies },
            { no: 8, name: "flows", kind: "message", oneof: "event", T: () => Flows },
            { no: 9, name: "notification", kind: "message", oneof: "event", T: () => Notification }
        ]);
    }
    create(value?: PartialMessage<Event>): Event {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.event = { oneofKind: undefined };
        if (value !== undefined)
            reflectionMergePartial<Event>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Event): Event {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* flow.Flow flow */ 3:
                    message.event = {
                        oneofKind: "flow",
                        flow: Flow.internalBinaryRead(reader, reader.uint32(), options, (message.event as any).flow)
                    };
                    break;
                case /* ui.NamespaceState namespace_state */ 4:
                    message.event = {
                        oneofKind: "namespace_state",
                        namespace_state: NamespaceState.internalBinaryRead(reader, reader.uint32(), options, (message.event as any).namespace_state)
                    };
                    break;
                case /* ui.ServiceState service_state */ 5:
                    message.event = {
                        oneofKind: "service_state",
                        service_state: ServiceState.internalBinaryRead(reader, reader.uint32(), options, (message.event as any).service_state)
                    };
                    break;
                case /* ui.ServiceLinkState service_link_state */ 6:
                    message.event = {
                        oneofKind: "service_link_state",
                        service_link_state: ServiceLinkState.internalBinaryRead(reader, reader.uint32(), options, (message.event as any).service_link_state)
                    };
                    break;
                case /* ui.Policies policies */ 7:
                    message.event = {
                        oneofKind: "policies",
                        policies: Policies.internalBinaryRead(reader, reader.uint32(), options, (message.event as any).policies)
                    };
                    break;
                case /* ui.Flows flows */ 8:
                    message.event = {
                        oneofKind: "flows",
                        flows: Flows.internalBinaryRead(reader, reader.uint32(), options, (message.event as any).flows)
                    };
                    break;
                case /* ui.Notification notification */ 9:
                    message.event = {
                        oneofKind: "notification",
                        notification: Notification.internalBinaryRead(reader, reader.uint32(), options, (message.event as any).notification)
                    };
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Event, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* flow.Flow flow = 3; */
        if (message.event.oneofKind === "flow")
            Flow.internalBinaryWrite(message.event.flow, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* ui.NamespaceState namespace_state = 4; */
        if (message.event.oneofKind === "namespace_state")
            NamespaceState.internalBinaryWrite(message.event.namespace_state, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* ui.ServiceState service_state = 5; */
        if (message.event.oneofKind === "service_state")
            ServiceState.internalBinaryWrite(message.event.service_state, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* ui.ServiceLinkState service_link_state = 6; */
        if (message.event.oneofKind === "service_link_state")
            ServiceLinkState.internalBinaryWrite(message.event.service_link_state, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* ui.Policies policies = 7; */
        if (message.event.oneofKind === "policies")
            Policies.internalBinaryWrite(message.event.policies, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* ui.Flows flows = 8; */
        if (message.event.oneofKind === "flows")
            Flows.internalBinaryWrite(message.event.flows, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* ui.Notification notification = 9; */
        if (message.event.oneofKind === "notification")
            Notification.internalBinaryWrite(message.event.notification, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ui.Event
 */
export const Event = new Event$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Flows$Type extends MessageType<Flows> {
    constructor() {
        super("ui.Flows", [
            { no: 1, name: "flows", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Flow }
        ]);
    }
    create(value?: PartialMessage<Flows>): Flows {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.flows = [];
        if (value !== undefined)
            reflectionMergePartial<Flows>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Flows): Flows {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated flow.Flow flows */ 1:
                    message.flows.push(Flow.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Flows, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated flow.Flow flows = 1; */
        for (let i = 0; i < message.flows.length; i++)
            Flow.internalBinaryWrite(message.flows[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ui.Flows
 */
export const Flows = new Flows$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Policies$Type extends MessageType<Policies> {
    constructor() {
        super("ui.Policies", [
            { no: 1, name: "policies", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PolicySpecState }
        ]);
    }
    create(value?: PartialMessage<Policies>): Policies {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.policies = [];
        if (value !== undefined)
            reflectionMergePartial<Policies>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Policies): Policies {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated ui.PolicySpecState policies */ 1:
                    message.policies.push(PolicySpecState.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Policies, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated ui.PolicySpecState policies = 1; */
        for (let i = 0; i < message.policies.length; i++)
            PolicySpecState.internalBinaryWrite(message.policies[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ui.Policies
 */
export const Policies = new Policies$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EventFilter$Type extends MessageType<EventFilter> {
    constructor() {
        super("ui.EventFilter", [
            { no: 2, name: "flow_filter", kind: "message", localName: "flow_filter", oneof: "filter", T: () => FlowFilter },
            { no: 3, name: "service_filter", kind: "message", localName: "service_filter", oneof: "filter", T: () => ServiceFilter },
            { no: 4, name: "service_link_filter", kind: "message", localName: "service_link_filter", oneof: "filter", T: () => ServiceLinkFilter },
            { no: 5, name: "policy_spec_filter", kind: "message", localName: "policy_spec_filter", oneof: "filter", T: () => PolicySpecFilter }
        ]);
    }
    create(value?: PartialMessage<EventFilter>): EventFilter {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.filter = { oneofKind: undefined };
        if (value !== undefined)
            reflectionMergePartial<EventFilter>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EventFilter): EventFilter {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* flow.FlowFilter flow_filter */ 2:
                    message.filter = {
                        oneofKind: "flow_filter",
                        flow_filter: FlowFilter.internalBinaryRead(reader, reader.uint32(), options, (message.filter as any).flow_filter)
                    };
                    break;
                case /* ui.ServiceFilter service_filter */ 3:
                    message.filter = {
                        oneofKind: "service_filter",
                        service_filter: ServiceFilter.internalBinaryRead(reader, reader.uint32(), options, (message.filter as any).service_filter)
                    };
                    break;
                case /* ui.ServiceLinkFilter service_link_filter */ 4:
                    message.filter = {
                        oneofKind: "service_link_filter",
                        service_link_filter: ServiceLinkFilter.internalBinaryRead(reader, reader.uint32(), options, (message.filter as any).service_link_filter)
                    };
                    break;
                case /* ui.PolicySpecFilter policy_spec_filter */ 5:
                    message.filter = {
                        oneofKind: "policy_spec_filter",
                        policy_spec_filter: PolicySpecFilter.internalBinaryRead(reader, reader.uint32(), options, (message.filter as any).policy_spec_filter)
                    };
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EventFilter, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* flow.FlowFilter flow_filter = 2; */
        if (message.filter.oneofKind === "flow_filter")
            FlowFilter.internalBinaryWrite(message.filter.flow_filter, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* ui.ServiceFilter service_filter = 3; */
        if (message.filter.oneofKind === "service_filter")
            ServiceFilter.internalBinaryWrite(message.filter.service_filter, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* ui.ServiceLinkFilter service_link_filter = 4; */
        if (message.filter.oneofKind === "service_link_filter")
            ServiceLinkFilter.internalBinaryWrite(message.filter.service_link_filter, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* ui.PolicySpecFilter policy_spec_filter = 5; */
        if (message.filter.oneofKind === "policy_spec_filter")
            PolicySpecFilter.internalBinaryWrite(message.filter.policy_spec_filter, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ui.EventFilter
 */
export const EventFilter = new EventFilter$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NamespaceDescriptor$Type extends MessageType<NamespaceDescriptor> {
    constructor() {
        super("ui.NamespaceDescriptor", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "creation_timestamp", kind: "message", localName: "creation_timestamp", T: () => Timestamp },
            { no: 5, name: "cluster", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<NamespaceDescriptor>): NamespaceDescriptor {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.name = "";
        message.cluster = "";
        if (value !== undefined)
            reflectionMergePartial<NamespaceDescriptor>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: NamespaceDescriptor): NamespaceDescriptor {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* google.protobuf.Timestamp creation_timestamp */ 3:
                    message.creation_timestamp = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.creation_timestamp);
                    break;
                case /* string cluster */ 5:
                    message.cluster = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: NamespaceDescriptor, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* google.protobuf.Timestamp creation_timestamp = 3; */
        if (message.creation_timestamp)
            Timestamp.internalBinaryWrite(message.creation_timestamp, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* string cluster = 5; */
        if (message.cluster !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.cluster);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ui.NamespaceDescriptor
 */
export const NamespaceDescriptor = new NamespaceDescriptor$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NamespaceState$Type extends MessageType<NamespaceState> {
    constructor() {
        super("ui.NamespaceState", [
            { no: 1, name: "namespace", kind: "message", T: () => NamespaceDescriptor },
            { no: 2, name: "type", kind: "enum", T: () => ["ui.StateChange", StateChange] }
        ]);
    }
    create(value?: PartialMessage<NamespaceState>): NamespaceState {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.type = 0;
        if (value !== undefined)
            reflectionMergePartial<NamespaceState>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: NamespaceState): NamespaceState {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* ui.NamespaceDescriptor namespace */ 1:
                    message.namespace = NamespaceDescriptor.internalBinaryRead(reader, reader.uint32(), options, message.namespace);
                    break;
                case /* ui.StateChange type */ 2:
                    message.type = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: NamespaceState, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* ui.NamespaceDescriptor namespace = 1; */
        if (message.namespace)
            NamespaceDescriptor.internalBinaryWrite(message.namespace, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* ui.StateChange type = 2; */
        if (message.type !== 0)
            writer.tag(2, WireType.Varint).int32(message.type);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ui.NamespaceState
 */
export const NamespaceState = new NamespaceState$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Service$Type extends MessageType<Service> {
    constructor() {
        super("ui.Service", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "namespace", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "labels", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "dns_names", kind: "scalar", localName: "dns_names", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "egress_policy_enforced", kind: "scalar", localName: "egress_policy_enforced", T: 8 /*ScalarType.BOOL*/ },
            { no: 7, name: "ingress_policy_enforced", kind: "scalar", localName: "ingress_policy_enforced", T: 8 /*ScalarType.BOOL*/ },
            { no: 8, name: "visibility_policy_status", kind: "scalar", localName: "visibility_policy_status", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "creation_timestamp", kind: "message", localName: "creation_timestamp", T: () => Timestamp },
            { no: 10, name: "workloads", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Workload },
            { no: 12, name: "identity", kind: "scalar", T: 13 /*ScalarType.UINT32*/ }
        ]);
    }
    create(value?: PartialMessage<Service>): Service {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.name = "";
        message.namespace = "";
        message.labels = [];
        message.dns_names = [];
        message.egress_policy_enforced = false;
        message.ingress_policy_enforced = false;
        message.visibility_policy_status = "";
        message.workloads = [];
        message.identity = 0;
        if (value !== undefined)
            reflectionMergePartial<Service>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Service): Service {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string namespace */ 3:
                    message.namespace = reader.string();
                    break;
                case /* repeated string labels */ 4:
                    message.labels.push(reader.string());
                    break;
                case /* repeated string dns_names */ 5:
                    message.dns_names.push(reader.string());
                    break;
                case /* bool egress_policy_enforced */ 6:
                    message.egress_policy_enforced = reader.bool();
                    break;
                case /* bool ingress_policy_enforced */ 7:
                    message.ingress_policy_enforced = reader.bool();
                    break;
                case /* string visibility_policy_status */ 8:
                    message.visibility_policy_status = reader.string();
                    break;
                case /* google.protobuf.Timestamp creation_timestamp */ 9:
                    message.creation_timestamp = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.creation_timestamp);
                    break;
                case /* repeated flow.Workload workloads */ 10:
                    message.workloads.push(Workload.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* uint32 identity */ 12:
                    message.identity = reader.uint32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Service, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string namespace = 3; */
        if (message.namespace !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.namespace);
        /* repeated string labels = 4; */
        for (let i = 0; i < message.labels.length; i++)
            writer.tag(4, WireType.LengthDelimited).string(message.labels[i]);
        /* repeated string dns_names = 5; */
        for (let i = 0; i < message.dns_names.length; i++)
            writer.tag(5, WireType.LengthDelimited).string(message.dns_names[i]);
        /* bool egress_policy_enforced = 6; */
        if (message.egress_policy_enforced !== false)
            writer.tag(6, WireType.Varint).bool(message.egress_policy_enforced);
        /* bool ingress_policy_enforced = 7; */
        if (message.ingress_policy_enforced !== false)
            writer.tag(7, WireType.Varint).bool(message.ingress_policy_enforced);
        /* string visibility_policy_status = 8; */
        if (message.visibility_policy_status !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.visibility_policy_status);
        /* google.protobuf.Timestamp creation_timestamp = 9; */
        if (message.creation_timestamp)
            Timestamp.internalBinaryWrite(message.creation_timestamp, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* repeated flow.Workload workloads = 10; */
        for (let i = 0; i < message.workloads.length; i++)
            Workload.internalBinaryWrite(message.workloads[i], writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* uint32 identity = 12; */
        if (message.identity !== 0)
            writer.tag(12, WireType.Varint).uint32(message.identity);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ui.Service
 */
export const Service = new Service$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ServiceState$Type extends MessageType<ServiceState> {
    constructor() {
        super("ui.ServiceState", [
            { no: 1, name: "service", kind: "message", T: () => Service },
            { no: 2, name: "type", kind: "enum", T: () => ["ui.StateChange", StateChange] }
        ]);
    }
    create(value?: PartialMessage<ServiceState>): ServiceState {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.type = 0;
        if (value !== undefined)
            reflectionMergePartial<ServiceState>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ServiceState): ServiceState {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* ui.Service service */ 1:
                    message.service = Service.internalBinaryRead(reader, reader.uint32(), options, message.service);
                    break;
                case /* ui.StateChange type */ 2:
                    message.type = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ServiceState, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* ui.Service service = 1; */
        if (message.service)
            Service.internalBinaryWrite(message.service, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* ui.StateChange type = 2; */
        if (message.type !== 0)
            writer.tag(2, WireType.Varint).int32(message.type);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ui.ServiceState
 */
export const ServiceState = new ServiceState$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ServiceFilter$Type extends MessageType<ServiceFilter> {
    constructor() {
        super("ui.ServiceFilter", [
            { no: 1, name: "namespace", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ServiceFilter>): ServiceFilter {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.namespace = [];
        if (value !== undefined)
            reflectionMergePartial<ServiceFilter>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ServiceFilter): ServiceFilter {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string namespace */ 1:
                    message.namespace.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ServiceFilter, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string namespace = 1; */
        for (let i = 0; i < message.namespace.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.namespace[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ui.ServiceFilter
 */
export const ServiceFilter = new ServiceFilter$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ServiceLink$Type extends MessageType<ServiceLink> {
    constructor() {
        super("ui.ServiceLink", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "source_id", kind: "scalar", localName: "source_id", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "destination_id", kind: "scalar", localName: "destination_id", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "destination_port", kind: "scalar", localName: "destination_port", T: 13 /*ScalarType.UINT32*/ },
            { no: 5, name: "ip_protocol", kind: "enum", localName: "ip_protocol", T: () => ["ui.IPProtocol", IPProtocol] },
            { no: 6, name: "verdict", kind: "enum", T: () => ["flow.Verdict", Verdict] },
            { no: 7, name: "flow_amount", kind: "scalar", localName: "flow_amount", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 8, name: "latency", kind: "message", T: () => ServiceLink_Latency },
            { no: 9, name: "bytes_transfered", kind: "scalar", localName: "bytes_transfered", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 10, name: "auth_type", kind: "enum", localName: "auth_type", T: () => ["flow.AuthType", AuthType] },
            { no: 11, name: "is_encrypted", kind: "scalar", localName: "is_encrypted", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<ServiceLink>): ServiceLink {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.source_id = "";
        message.destination_id = "";
        message.destination_port = 0;
        message.ip_protocol = 0;
        message.verdict = 0;
        message.flow_amount = 0;
        message.bytes_transfered = 0;
        message.auth_type = 0;
        message.is_encrypted = false;
        if (value !== undefined)
            reflectionMergePartial<ServiceLink>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ServiceLink): ServiceLink {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string source_id */ 2:
                    message.source_id = reader.string();
                    break;
                case /* string destination_id */ 3:
                    message.destination_id = reader.string();
                    break;
                case /* uint32 destination_port */ 4:
                    message.destination_port = reader.uint32();
                    break;
                case /* ui.IPProtocol ip_protocol */ 5:
                    message.ip_protocol = reader.int32();
                    break;
                case /* flow.Verdict verdict */ 6:
                    message.verdict = reader.int32();
                    break;
                case /* uint64 flow_amount */ 7:
                    message.flow_amount = reader.uint64().toNumber();
                    break;
                case /* ui.ServiceLink.Latency latency */ 8:
                    message.latency = ServiceLink_Latency.internalBinaryRead(reader, reader.uint32(), options, message.latency);
                    break;
                case /* uint64 bytes_transfered */ 9:
                    message.bytes_transfered = reader.uint64().toNumber();
                    break;
                case /* flow.AuthType auth_type */ 10:
                    message.auth_type = reader.int32();
                    break;
                case /* bool is_encrypted */ 11:
                    message.is_encrypted = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ServiceLink, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string source_id = 2; */
        if (message.source_id !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.source_id);
        /* string destination_id = 3; */
        if (message.destination_id !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.destination_id);
        /* uint32 destination_port = 4; */
        if (message.destination_port !== 0)
            writer.tag(4, WireType.Varint).uint32(message.destination_port);
        /* ui.IPProtocol ip_protocol = 5; */
        if (message.ip_protocol !== 0)
            writer.tag(5, WireType.Varint).int32(message.ip_protocol);
        /* flow.Verdict verdict = 6; */
        if (message.verdict !== 0)
            writer.tag(6, WireType.Varint).int32(message.verdict);
        /* uint64 flow_amount = 7; */
        if (message.flow_amount !== 0)
            writer.tag(7, WireType.Varint).uint64(message.flow_amount);
        /* ui.ServiceLink.Latency latency = 8; */
        if (message.latency)
            ServiceLink_Latency.internalBinaryWrite(message.latency, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* uint64 bytes_transfered = 9; */
        if (message.bytes_transfered !== 0)
            writer.tag(9, WireType.Varint).uint64(message.bytes_transfered);
        /* flow.AuthType auth_type = 10; */
        if (message.auth_type !== 0)
            writer.tag(10, WireType.Varint).int32(message.auth_type);
        /* bool is_encrypted = 11; */
        if (message.is_encrypted !== false)
            writer.tag(11, WireType.Varint).bool(message.is_encrypted);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ui.ServiceLink
 */
export const ServiceLink = new ServiceLink$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ServiceLink_Latency$Type extends MessageType<ServiceLink_Latency> {
    constructor() {
        super("ui.ServiceLink.Latency", [
            { no: 1, name: "min", kind: "message", T: () => Duration },
            { no: 2, name: "max", kind: "message", T: () => Duration },
            { no: 3, name: "avg", kind: "message", T: () => Duration }
        ]);
    }
    create(value?: PartialMessage<ServiceLink_Latency>): ServiceLink_Latency {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<ServiceLink_Latency>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ServiceLink_Latency): ServiceLink_Latency {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* google.protobuf.Duration min */ 1:
                    message.min = Duration.internalBinaryRead(reader, reader.uint32(), options, message.min);
                    break;
                case /* google.protobuf.Duration max */ 2:
                    message.max = Duration.internalBinaryRead(reader, reader.uint32(), options, message.max);
                    break;
                case /* google.protobuf.Duration avg */ 3:
                    message.avg = Duration.internalBinaryRead(reader, reader.uint32(), options, message.avg);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ServiceLink_Latency, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* google.protobuf.Duration min = 1; */
        if (message.min)
            Duration.internalBinaryWrite(message.min, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Duration max = 2; */
        if (message.max)
            Duration.internalBinaryWrite(message.max, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Duration avg = 3; */
        if (message.avg)
            Duration.internalBinaryWrite(message.avg, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ui.ServiceLink.Latency
 */
export const ServiceLink_Latency = new ServiceLink_Latency$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ServiceLinkState$Type extends MessageType<ServiceLinkState> {
    constructor() {
        super("ui.ServiceLinkState", [
            { no: 1, name: "service_link", kind: "message", localName: "service_link", T: () => ServiceLink },
            { no: 2, name: "type", kind: "enum", T: () => ["ui.StateChange", StateChange] }
        ]);
    }
    create(value?: PartialMessage<ServiceLinkState>): ServiceLinkState {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.type = 0;
        if (value !== undefined)
            reflectionMergePartial<ServiceLinkState>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ServiceLinkState): ServiceLinkState {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* ui.ServiceLink service_link */ 1:
                    message.service_link = ServiceLink.internalBinaryRead(reader, reader.uint32(), options, message.service_link);
                    break;
                case /* ui.StateChange type */ 2:
                    message.type = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ServiceLinkState, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* ui.ServiceLink service_link = 1; */
        if (message.service_link)
            ServiceLink.internalBinaryWrite(message.service_link, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* ui.StateChange type = 2; */
        if (message.type !== 0)
            writer.tag(2, WireType.Varint).int32(message.type);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ui.ServiceLinkState
 */
export const ServiceLinkState = new ServiceLinkState$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ServiceLinkFilter$Type extends MessageType<ServiceLinkFilter> {
    constructor() {
        super("ui.ServiceLinkFilter", [
            { no: 1, name: "source", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ServiceFilter },
            { no: 2, name: "destination", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ServiceFilter },
            { no: 3, name: "destination_port", kind: "scalar", localName: "destination_port", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "verdict", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["flow.Verdict", Verdict] }
        ]);
    }
    create(value?: PartialMessage<ServiceLinkFilter>): ServiceLinkFilter {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.source = [];
        message.destination = [];
        message.destination_port = [];
        message.verdict = [];
        if (value !== undefined)
            reflectionMergePartial<ServiceLinkFilter>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ServiceLinkFilter): ServiceLinkFilter {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated ui.ServiceFilter source */ 1:
                    message.source.push(ServiceFilter.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated ui.ServiceFilter destination */ 2:
                    message.destination.push(ServiceFilter.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated string destination_port */ 3:
                    message.destination_port.push(reader.string());
                    break;
                case /* repeated flow.Verdict verdict */ 4:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.verdict.push(reader.int32());
                    else
                        message.verdict.push(reader.int32());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ServiceLinkFilter, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated ui.ServiceFilter source = 1; */
        for (let i = 0; i < message.source.length; i++)
            ServiceFilter.internalBinaryWrite(message.source[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* repeated ui.ServiceFilter destination = 2; */
        for (let i = 0; i < message.destination.length; i++)
            ServiceFilter.internalBinaryWrite(message.destination[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* repeated string destination_port = 3; */
        for (let i = 0; i < message.destination_port.length; i++)
            writer.tag(3, WireType.LengthDelimited).string(message.destination_port[i]);
        /* repeated flow.Verdict verdict = 4; */
        if (message.verdict.length) {
            writer.tag(4, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.verdict.length; i++)
                writer.int32(message.verdict[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ui.ServiceLinkFilter
 */
export const ServiceLinkFilter = new ServiceLinkFilter$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PolicySpec$Type extends MessageType<PolicySpec> {
    constructor() {
        super("ui.PolicySpec", [
            { no: 1, name: "policyNamespace", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "policyName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "type", kind: "enum", T: () => ["ui.PolicySpecType", PolicySpecType] },
            { no: 4, name: "yaml", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "uid", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<PolicySpec>): PolicySpec {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.policyNamespace = "";
        message.policyName = "";
        message.type = 0;
        message.yaml = "";
        message.uid = "";
        if (value !== undefined)
            reflectionMergePartial<PolicySpec>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PolicySpec): PolicySpec {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string policyNamespace */ 1:
                    message.policyNamespace = reader.string();
                    break;
                case /* string policyName */ 2:
                    message.policyName = reader.string();
                    break;
                case /* ui.PolicySpecType type */ 3:
                    message.type = reader.int32();
                    break;
                case /* string yaml */ 4:
                    message.yaml = reader.string();
                    break;
                case /* string uid */ 5:
                    message.uid = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PolicySpec, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string policyNamespace = 1; */
        if (message.policyNamespace !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.policyNamespace);
        /* string policyName = 2; */
        if (message.policyName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.policyName);
        /* ui.PolicySpecType type = 3; */
        if (message.type !== 0)
            writer.tag(3, WireType.Varint).int32(message.type);
        /* string yaml = 4; */
        if (message.yaml !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.yaml);
        /* string uid = 5; */
        if (message.uid !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.uid);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ui.PolicySpec
 */
export const PolicySpec = new PolicySpec$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PolicySpecState$Type extends MessageType<PolicySpecState> {
    constructor() {
        super("ui.PolicySpecState", [
            { no: 1, name: "policy_spec", kind: "message", localName: "policy_spec", T: () => PolicySpec },
            { no: 2, name: "type", kind: "enum", T: () => ["ui.StateChange", StateChange] }
        ]);
    }
    create(value?: PartialMessage<PolicySpecState>): PolicySpecState {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.type = 0;
        if (value !== undefined)
            reflectionMergePartial<PolicySpecState>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PolicySpecState): PolicySpecState {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* ui.PolicySpec policy_spec */ 1:
                    message.policy_spec = PolicySpec.internalBinaryRead(reader, reader.uint32(), options, message.policy_spec);
                    break;
                case /* ui.StateChange type */ 2:
                    message.type = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PolicySpecState, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* ui.PolicySpec policy_spec = 1; */
        if (message.policy_spec)
            PolicySpec.internalBinaryWrite(message.policy_spec, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* ui.StateChange type = 2; */
        if (message.type !== 0)
            writer.tag(2, WireType.Varint).int32(message.type);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ui.PolicySpecState
 */
export const PolicySpecState = new PolicySpecState$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PolicySpecFilter$Type extends MessageType<PolicySpecFilter> {
    constructor() {
        super("ui.PolicySpecFilter", [
            { no: 1, name: "policyNamespace", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "policyName", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "type", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["ui.PolicySpecType", PolicySpecType] }
        ]);
    }
    create(value?: PartialMessage<PolicySpecFilter>): PolicySpecFilter {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.policyNamespace = [];
        message.policyName = [];
        message.type = [];
        if (value !== undefined)
            reflectionMergePartial<PolicySpecFilter>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PolicySpecFilter): PolicySpecFilter {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string policyNamespace */ 1:
                    message.policyNamespace.push(reader.string());
                    break;
                case /* repeated string policyName */ 2:
                    message.policyName.push(reader.string());
                    break;
                case /* repeated ui.PolicySpecType type */ 3:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.type.push(reader.int32());
                    else
                        message.type.push(reader.int32());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PolicySpecFilter, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string policyNamespace = 1; */
        for (let i = 0; i < message.policyNamespace.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.policyNamespace[i]);
        /* repeated string policyName = 2; */
        for (let i = 0; i < message.policyName.length; i++)
            writer.tag(2, WireType.LengthDelimited).string(message.policyName[i]);
        /* repeated ui.PolicySpecType type = 3; */
        if (message.type.length) {
            writer.tag(3, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.type.length; i++)
                writer.int32(message.type[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ui.PolicySpecFilter
 */
export const PolicySpecFilter = new PolicySpecFilter$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetTimescapeDataRequest$Type extends MessageType<GetTimescapeDataRequest> {
    constructor() {
        super("ui.GetTimescapeDataRequest", [
            { no: 1, name: "flows_request", kind: "message", localName: "flows_request", T: () => GetFlowsRequest },
            { no: 2, name: "last_seen", kind: "message", localName: "last_seen", T: () => GetTimescapeDataRequest_LastDatumSeen },
            { no: 3, name: "last_flow_seen_filled", kind: "scalar", localName: "last_flow_seen_filled", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "limit", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<GetTimescapeDataRequest>): GetTimescapeDataRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.last_flow_seen_filled = false;
        message.limit = 0;
        if (value !== undefined)
            reflectionMergePartial<GetTimescapeDataRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetTimescapeDataRequest): GetTimescapeDataRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* observer.GetFlowsRequest flows_request */ 1:
                    message.flows_request = GetFlowsRequest.internalBinaryRead(reader, reader.uint32(), options, message.flows_request);
                    break;
                case /* ui.GetTimescapeDataRequest.LastDatumSeen last_seen */ 2:
                    message.last_seen = GetTimescapeDataRequest_LastDatumSeen.internalBinaryRead(reader, reader.uint32(), options, message.last_seen);
                    break;
                case /* bool last_flow_seen_filled */ 3:
                    message.last_flow_seen_filled = reader.bool();
                    break;
                case /* int32 limit */ 4:
                    message.limit = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetTimescapeDataRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* observer.GetFlowsRequest flows_request = 1; */
        if (message.flows_request)
            GetFlowsRequest.internalBinaryWrite(message.flows_request, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* ui.GetTimescapeDataRequest.LastDatumSeen last_seen = 2; */
        if (message.last_seen)
            GetTimescapeDataRequest_LastDatumSeen.internalBinaryWrite(message.last_seen, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* bool last_flow_seen_filled = 3; */
        if (message.last_flow_seen_filled !== false)
            writer.tag(3, WireType.Varint).bool(message.last_flow_seen_filled);
        /* int32 limit = 4; */
        if (message.limit !== 0)
            writer.tag(4, WireType.Varint).int32(message.limit);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ui.GetTimescapeDataRequest
 */
export const GetTimescapeDataRequest = new GetTimescapeDataRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetTimescapeDataRequest_LastDatumSeen$Type extends MessageType<GetTimescapeDataRequest_LastDatumSeen> {
    constructor() {
        super("ui.GetTimescapeDataRequest.LastDatumSeen", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "timestamp", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value?: PartialMessage<GetTimescapeDataRequest_LastDatumSeen>): GetTimescapeDataRequest_LastDatumSeen {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        if (value !== undefined)
            reflectionMergePartial<GetTimescapeDataRequest_LastDatumSeen>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetTimescapeDataRequest_LastDatumSeen): GetTimescapeDataRequest_LastDatumSeen {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* google.protobuf.Timestamp timestamp */ 2:
                    message.timestamp = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.timestamp);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetTimescapeDataRequest_LastDatumSeen, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* google.protobuf.Timestamp timestamp = 2; */
        if (message.timestamp)
            Timestamp.internalBinaryWrite(message.timestamp, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ui.GetTimescapeDataRequest.LastDatumSeen
 */
export const GetTimescapeDataRequest_LastDatumSeen = new GetTimescapeDataRequest_LastDatumSeen$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetTimescapeDataResponse$Type extends MessageType<GetTimescapeDataResponse> {
    constructor() {
        super("ui.GetTimescapeDataResponse", [
            { no: 1, name: "flows", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Flow },
            { no: 2, name: "service", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Service },
            { no: 3, name: "service_link", kind: "message", localName: "service_link", repeat: 1 /*RepeatType.PACKED*/, T: () => ServiceLink },
            { no: 4, name: "namespaces", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => NamespaceDescriptor },
            { no: 5, name: "count_stats", kind: "message", localName: "count_stats", repeat: 1 /*RepeatType.PACKED*/, T: () => CountStats },
            { no: 6, name: "flow_summaries", kind: "message", localName: "flow_summaries", repeat: 1 /*RepeatType.PACKED*/, T: () => FlowSummary }
        ]);
    }
    create(value?: PartialMessage<GetTimescapeDataResponse>): GetTimescapeDataResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.flows = [];
        message.service = [];
        message.service_link = [];
        message.namespaces = [];
        message.count_stats = [];
        message.flow_summaries = [];
        if (value !== undefined)
            reflectionMergePartial<GetTimescapeDataResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetTimescapeDataResponse): GetTimescapeDataResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated flow.Flow flows */ 1:
                    message.flows.push(Flow.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated ui.Service service */ 2:
                    message.service.push(Service.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated ui.ServiceLink service_link */ 3:
                    message.service_link.push(ServiceLink.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated ui.NamespaceDescriptor namespaces */ 4:
                    message.namespaces.push(NamespaceDescriptor.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated ui.CountStats count_stats */ 5:
                    message.count_stats.push(CountStats.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated ui.FlowSummary flow_summaries */ 6:
                    message.flow_summaries.push(FlowSummary.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetTimescapeDataResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated flow.Flow flows = 1; */
        for (let i = 0; i < message.flows.length; i++)
            Flow.internalBinaryWrite(message.flows[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* repeated ui.Service service = 2; */
        for (let i = 0; i < message.service.length; i++)
            Service.internalBinaryWrite(message.service[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* repeated ui.ServiceLink service_link = 3; */
        for (let i = 0; i < message.service_link.length; i++)
            ServiceLink.internalBinaryWrite(message.service_link[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* repeated ui.NamespaceDescriptor namespaces = 4; */
        for (let i = 0; i < message.namespaces.length; i++)
            NamespaceDescriptor.internalBinaryWrite(message.namespaces[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* repeated ui.CountStats count_stats = 5; */
        for (let i = 0; i < message.count_stats.length; i++)
            CountStats.internalBinaryWrite(message.count_stats[i], writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* repeated ui.FlowSummary flow_summaries = 6; */
        for (let i = 0; i < message.flow_summaries.length; i++)
            FlowSummary.internalBinaryWrite(message.flow_summaries[i], writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ui.GetTimescapeDataResponse
 */
export const GetTimescapeDataResponse = new GetTimescapeDataResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetFullFlowRequest$Type extends MessageType<GetFullFlowRequest> {
    constructor() {
        super("ui.GetFullFlowRequest", [
            { no: 1, name: "flow_summary_id", kind: "scalar", localName: "flow_summary_id", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetFullFlowRequest>): GetFullFlowRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.flow_summary_id = "";
        if (value !== undefined)
            reflectionMergePartial<GetFullFlowRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetFullFlowRequest): GetFullFlowRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string flow_summary_id */ 1:
                    message.flow_summary_id = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetFullFlowRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string flow_summary_id = 1; */
        if (message.flow_summary_id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.flow_summary_id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ui.GetFullFlowRequest
 */
export const GetFullFlowRequest = new GetFullFlowRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetFullFlowResponse$Type extends MessageType<GetFullFlowResponse> {
    constructor() {
        super("ui.GetFullFlowResponse", [
            { no: 1, name: "flow", kind: "message", T: () => Flow },
            { no: 2, name: "found", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<GetFullFlowResponse>): GetFullFlowResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.found = false;
        if (value !== undefined)
            reflectionMergePartial<GetFullFlowResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetFullFlowResponse): GetFullFlowResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* flow.Flow flow */ 1:
                    message.flow = Flow.internalBinaryRead(reader, reader.uint32(), options, message.flow);
                    break;
                case /* bool found */ 2:
                    message.found = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetFullFlowResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* flow.Flow flow = 1; */
        if (message.flow)
            Flow.internalBinaryWrite(message.flow, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* bool found = 2; */
        if (message.found !== false)
            writer.tag(2, WireType.Varint).bool(message.found);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ui.GetFullFlowResponse
 */
export const GetFullFlowResponse = new GetFullFlowResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CountStats$Type extends MessageType<CountStats> {
    constructor() {
        super("ui.CountStats", [
            { no: 1, name: "timestamp", kind: "message", T: () => Timestamp },
            { no: 2, name: "count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<CountStats>): CountStats {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.count = 0;
        if (value !== undefined)
            reflectionMergePartial<CountStats>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CountStats): CountStats {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* google.protobuf.Timestamp timestamp */ 1:
                    message.timestamp = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.timestamp);
                    break;
                case /* int32 count */ 2:
                    message.count = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CountStats, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* google.protobuf.Timestamp timestamp = 1; */
        if (message.timestamp)
            Timestamp.internalBinaryWrite(message.timestamp, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 count = 2; */
        if (message.count !== 0)
            writer.tag(2, WireType.Varint).int32(message.count);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ui.CountStats
 */
export const CountStats = new CountStats$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FlowSummary$Type extends MessageType<FlowSummary> {
    constructor() {
        super("ui.FlowSummary", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "time", kind: "message", T: () => Timestamp },
            { no: 3, name: "source_pod", kind: "scalar", localName: "source_pod", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "source_labels", kind: "scalar", localName: "source_labels", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "source_ip", kind: "scalar", localName: "source_ip", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "destination_pod", kind: "scalar", localName: "destination_pod", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "destination_labels", kind: "scalar", localName: "destination_labels", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "destination_ip", kind: "scalar", localName: "destination_ip", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "destination_port", kind: "scalar", localName: "destination_port", T: 13 /*ScalarType.UINT32*/ },
            { no: 10, name: "verdict", kind: "enum", T: () => ["flow.Verdict", Verdict] },
            { no: 11, name: "tcp_flags", kind: "message", localName: "tcp_flags", T: () => TCPFlags },
            { no: 12, name: "has", kind: "message", T: () => FlowSummary_HasProps },
            { no: 14, name: "destination_names", kind: "scalar", localName: "destination_names", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 15, name: "source_workloads", kind: "message", localName: "source_workloads", repeat: 1 /*RepeatType.PACKED*/, T: () => Workload },
            { no: 16, name: "destination_workloads", kind: "message", localName: "destination_workloads", repeat: 1 /*RepeatType.PACKED*/, T: () => Workload },
            { no: 17, name: "auth_type", kind: "enum", localName: "auth_type", T: () => ["flow.AuthType", AuthType] }
        ]);
    }
    create(value?: PartialMessage<FlowSummary>): FlowSummary {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.source_pod = "";
        message.source_labels = [];
        message.source_ip = "";
        message.destination_pod = "";
        message.destination_labels = [];
        message.destination_ip = "";
        message.destination_port = 0;
        message.verdict = 0;
        message.destination_names = [];
        message.source_workloads = [];
        message.destination_workloads = [];
        message.auth_type = 0;
        if (value !== undefined)
            reflectionMergePartial<FlowSummary>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FlowSummary): FlowSummary {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* google.protobuf.Timestamp time */ 2:
                    message.time = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.time);
                    break;
                case /* string source_pod */ 3:
                    message.source_pod = reader.string();
                    break;
                case /* repeated string source_labels */ 4:
                    message.source_labels.push(reader.string());
                    break;
                case /* string source_ip */ 5:
                    message.source_ip = reader.string();
                    break;
                case /* string destination_pod */ 6:
                    message.destination_pod = reader.string();
                    break;
                case /* repeated string destination_labels */ 7:
                    message.destination_labels.push(reader.string());
                    break;
                case /* string destination_ip */ 8:
                    message.destination_ip = reader.string();
                    break;
                case /* uint32 destination_port */ 9:
                    message.destination_port = reader.uint32();
                    break;
                case /* flow.Verdict verdict */ 10:
                    message.verdict = reader.int32();
                    break;
                case /* flow.TCPFlags tcp_flags */ 11:
                    message.tcp_flags = TCPFlags.internalBinaryRead(reader, reader.uint32(), options, message.tcp_flags);
                    break;
                case /* ui.FlowSummary.HasProps has */ 12:
                    message.has = FlowSummary_HasProps.internalBinaryRead(reader, reader.uint32(), options, message.has);
                    break;
                case /* repeated string destination_names */ 14:
                    message.destination_names.push(reader.string());
                    break;
                case /* repeated flow.Workload source_workloads */ 15:
                    message.source_workloads.push(Workload.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated flow.Workload destination_workloads */ 16:
                    message.destination_workloads.push(Workload.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* flow.AuthType auth_type */ 17:
                    message.auth_type = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FlowSummary, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* google.protobuf.Timestamp time = 2; */
        if (message.time)
            Timestamp.internalBinaryWrite(message.time, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* string source_pod = 3; */
        if (message.source_pod !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.source_pod);
        /* repeated string source_labels = 4; */
        for (let i = 0; i < message.source_labels.length; i++)
            writer.tag(4, WireType.LengthDelimited).string(message.source_labels[i]);
        /* string source_ip = 5; */
        if (message.source_ip !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.source_ip);
        /* string destination_pod = 6; */
        if (message.destination_pod !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.destination_pod);
        /* repeated string destination_labels = 7; */
        for (let i = 0; i < message.destination_labels.length; i++)
            writer.tag(7, WireType.LengthDelimited).string(message.destination_labels[i]);
        /* string destination_ip = 8; */
        if (message.destination_ip !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.destination_ip);
        /* uint32 destination_port = 9; */
        if (message.destination_port !== 0)
            writer.tag(9, WireType.Varint).uint32(message.destination_port);
        /* flow.Verdict verdict = 10; */
        if (message.verdict !== 0)
            writer.tag(10, WireType.Varint).int32(message.verdict);
        /* flow.TCPFlags tcp_flags = 11; */
        if (message.tcp_flags)
            TCPFlags.internalBinaryWrite(message.tcp_flags, writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        /* ui.FlowSummary.HasProps has = 12; */
        if (message.has)
            FlowSummary_HasProps.internalBinaryWrite(message.has, writer.tag(12, WireType.LengthDelimited).fork(), options).join();
        /* repeated string destination_names = 14; */
        for (let i = 0; i < message.destination_names.length; i++)
            writer.tag(14, WireType.LengthDelimited).string(message.destination_names[i]);
        /* repeated flow.Workload source_workloads = 15; */
        for (let i = 0; i < message.source_workloads.length; i++)
            Workload.internalBinaryWrite(message.source_workloads[i], writer.tag(15, WireType.LengthDelimited).fork(), options).join();
        /* repeated flow.Workload destination_workloads = 16; */
        for (let i = 0; i < message.destination_workloads.length; i++)
            Workload.internalBinaryWrite(message.destination_workloads[i], writer.tag(16, WireType.LengthDelimited).fork(), options).join();
        /* flow.AuthType auth_type = 17; */
        if (message.auth_type !== 0)
            writer.tag(17, WireType.Varint).int32(message.auth_type);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ui.FlowSummary
 */
export const FlowSummary = new FlowSummary$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FlowSummary_HasProps$Type extends MessageType<FlowSummary_HasProps> {
    constructor() {
        super("ui.FlowSummary.HasProps", [
            { no: 1, name: "destination_port", kind: "scalar", localName: "destination_port", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "tcp_flags", kind: "scalar", localName: "tcp_flags", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<FlowSummary_HasProps>): FlowSummary_HasProps {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.destination_port = false;
        message.tcp_flags = false;
        if (value !== undefined)
            reflectionMergePartial<FlowSummary_HasProps>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FlowSummary_HasProps): FlowSummary_HasProps {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool destination_port */ 1:
                    message.destination_port = reader.bool();
                    break;
                case /* bool tcp_flags */ 2:
                    message.tcp_flags = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FlowSummary_HasProps, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool destination_port = 1; */
        if (message.destination_port !== false)
            writer.tag(1, WireType.Varint).bool(message.destination_port);
        /* bool tcp_flags = 2; */
        if (message.tcp_flags !== false)
            writer.tag(2, WireType.Varint).bool(message.tcp_flags);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ui.FlowSummary.HasProps
 */
export const FlowSummary_HasProps = new FlowSummary_HasProps$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetControlStreamRequest$Type extends MessageType<GetControlStreamRequest> {
    constructor() {
        super("ui.GetControlStreamRequest", []);
    }
    create(value?: PartialMessage<GetControlStreamRequest>): GetControlStreamRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<GetControlStreamRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetControlStreamRequest): GetControlStreamRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: GetControlStreamRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ui.GetControlStreamRequest
 */
export const GetControlStreamRequest = new GetControlStreamRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetControlStreamResponse$Type extends MessageType<GetControlStreamResponse> {
    constructor() {
        super("ui.GetControlStreamResponse", [
            { no: 1, name: "namespaces", kind: "message", oneof: "event", T: () => GetControlStreamResponse_NamespaceStates },
            { no: 2, name: "notification", kind: "message", oneof: "event", T: () => Notification }
        ]);
    }
    create(value?: PartialMessage<GetControlStreamResponse>): GetControlStreamResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.event = { oneofKind: undefined };
        if (value !== undefined)
            reflectionMergePartial<GetControlStreamResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetControlStreamResponse): GetControlStreamResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* ui.GetControlStreamResponse.NamespaceStates namespaces */ 1:
                    message.event = {
                        oneofKind: "namespaces",
                        namespaces: GetControlStreamResponse_NamespaceStates.internalBinaryRead(reader, reader.uint32(), options, (message.event as any).namespaces)
                    };
                    break;
                case /* ui.Notification notification */ 2:
                    message.event = {
                        oneofKind: "notification",
                        notification: Notification.internalBinaryRead(reader, reader.uint32(), options, (message.event as any).notification)
                    };
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetControlStreamResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* ui.GetControlStreamResponse.NamespaceStates namespaces = 1; */
        if (message.event.oneofKind === "namespaces")
            GetControlStreamResponse_NamespaceStates.internalBinaryWrite(message.event.namespaces, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* ui.Notification notification = 2; */
        if (message.event.oneofKind === "notification")
            Notification.internalBinaryWrite(message.event.notification, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ui.GetControlStreamResponse
 */
export const GetControlStreamResponse = new GetControlStreamResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetControlStreamResponse_NamespaceStates$Type extends MessageType<GetControlStreamResponse_NamespaceStates> {
    constructor() {
        super("ui.GetControlStreamResponse.NamespaceStates", [
            { no: 1, name: "namespaces", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => NamespaceState }
        ]);
    }
    create(value?: PartialMessage<GetControlStreamResponse_NamespaceStates>): GetControlStreamResponse_NamespaceStates {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.namespaces = [];
        if (value !== undefined)
            reflectionMergePartial<GetControlStreamResponse_NamespaceStates>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetControlStreamResponse_NamespaceStates): GetControlStreamResponse_NamespaceStates {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated ui.NamespaceState namespaces */ 1:
                    message.namespaces.push(NamespaceState.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetControlStreamResponse_NamespaceStates, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated ui.NamespaceState namespaces = 1; */
        for (let i = 0; i < message.namespaces.length; i++)
            NamespaceState.internalBinaryWrite(message.namespaces[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ui.GetControlStreamResponse.NamespaceStates
 */
export const GetControlStreamResponse_NamespaceStates = new GetControlStreamResponse_NamespaceStates$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetTimescapePodsRequest$Type extends MessageType<GetTimescapePodsRequest> {
    constructor() {
        super("ui.GetTimescapePodsRequest", [
            { no: 1, name: "request", kind: "message", T: () => GetContainersFromEventsRequest }
        ]);
    }
    create(value?: PartialMessage<GetTimescapePodsRequest>): GetTimescapePodsRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<GetTimescapePodsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetTimescapePodsRequest): GetTimescapePodsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* timescape.v1.GetContainersFromEventsRequest request */ 1:
                    message.request = GetContainersFromEventsRequest.internalBinaryRead(reader, reader.uint32(), options, message.request);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetTimescapePodsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* timescape.v1.GetContainersFromEventsRequest request = 1; */
        if (message.request)
            GetContainersFromEventsRequest.internalBinaryWrite(message.request, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ui.GetTimescapePodsRequest
 */
export const GetTimescapePodsRequest = new GetTimescapePodsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetTimescapePodsResponse$Type extends MessageType<GetTimescapePodsResponse> {
    constructor() {
        super("ui.GetTimescapePodsResponse", [
            { no: 1, name: "containers", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => EventsContainerInfo }
        ]);
    }
    create(value?: PartialMessage<GetTimescapePodsResponse>): GetTimescapePodsResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.containers = [];
        if (value !== undefined)
            reflectionMergePartial<GetTimescapePodsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetTimescapePodsResponse): GetTimescapePodsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated timescape.v1.EventsContainerInfo containers */ 1:
                    message.containers.push(EventsContainerInfo.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetTimescapePodsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated timescape.v1.EventsContainerInfo containers = 1; */
        for (let i = 0; i < message.containers.length; i++)
            EventsContainerInfo.internalBinaryWrite(message.containers[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ui.GetTimescapePodsResponse
 */
export const GetTimescapePodsResponse = new GetTimescapePodsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetServiceMapFromLogsRequest$Type extends MessageType<GetServiceMapFromLogsRequest> {
    constructor() {
        super("ui.GetServiceMapFromLogsRequest", [
            { no: 1, name: "logs", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 2, name: "blacklist", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => EventFilter },
            { no: 3, name: "whitelist", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => EventFilter }
        ]);
    }
    create(value?: PartialMessage<GetServiceMapFromLogsRequest>): GetServiceMapFromLogsRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.logs = new Uint8Array(0);
        message.blacklist = [];
        message.whitelist = [];
        if (value !== undefined)
            reflectionMergePartial<GetServiceMapFromLogsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetServiceMapFromLogsRequest): GetServiceMapFromLogsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes logs */ 1:
                    message.logs = reader.bytes();
                    break;
                case /* repeated ui.EventFilter blacklist */ 2:
                    message.blacklist.push(EventFilter.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated ui.EventFilter whitelist */ 3:
                    message.whitelist.push(EventFilter.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetServiceMapFromLogsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes logs = 1; */
        if (message.logs.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.logs);
        /* repeated ui.EventFilter blacklist = 2; */
        for (let i = 0; i < message.blacklist.length; i++)
            EventFilter.internalBinaryWrite(message.blacklist[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* repeated ui.EventFilter whitelist = 3; */
        for (let i = 0; i < message.whitelist.length; i++)
            EventFilter.internalBinaryWrite(message.whitelist[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ui.GetServiceMapFromLogsRequest
 */
export const GetServiceMapFromLogsRequest = new GetServiceMapFromLogsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetServiceMapFromLogsResponse$Type extends MessageType<GetServiceMapFromLogsResponse> {
    constructor() {
        super("ui.GetServiceMapFromLogsResponse", [
            { no: 1, name: "services", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Service },
            { no: 2, name: "links", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ServiceLink }
        ]);
    }
    create(value?: PartialMessage<GetServiceMapFromLogsResponse>): GetServiceMapFromLogsResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.services = [];
        message.links = [];
        if (value !== undefined)
            reflectionMergePartial<GetServiceMapFromLogsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetServiceMapFromLogsResponse): GetServiceMapFromLogsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated ui.Service services */ 1:
                    message.services.push(Service.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated ui.ServiceLink links */ 2:
                    message.links.push(ServiceLink.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetServiceMapFromLogsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated ui.Service services = 1; */
        for (let i = 0; i < message.services.length; i++)
            Service.internalBinaryWrite(message.services[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* repeated ui.ServiceLink links = 2; */
        for (let i = 0; i < message.links.length; i++)
            ServiceLink.internalBinaryWrite(message.links[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ui.GetServiceMapFromLogsResponse
 */
export const GetServiceMapFromLogsResponse = new GetServiceMapFromLogsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetTimescapeK8SEventsResponse$Type extends MessageType<GetTimescapeK8SEventsResponse> {
    constructor() {
        super("ui.GetTimescapeK8SEventsResponse", [
            { no: 1, name: "events", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Event$ }
        ]);
    }
    create(value?: PartialMessage<GetTimescapeK8SEventsResponse>): GetTimescapeK8SEventsResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.events = [];
        if (value !== undefined)
            reflectionMergePartial<GetTimescapeK8SEventsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetTimescapeK8SEventsResponse): GetTimescapeK8SEventsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated k8sevent.v1.Event events */ 1:
                    message.events.push(Event$.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetTimescapeK8SEventsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated k8sevent.v1.Event events = 1; */
        for (let i = 0; i < message.events.length; i++)
            Event$.internalBinaryWrite(message.events[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ui.GetTimescapeK8SEventsResponse
 */
export const GetTimescapeK8SEventsResponse = new GetTimescapeK8SEventsResponse$Type();
/**
 * @generated ServiceType for protobuf service ui.UI
 */
export const UI = new ServiceType("ui.UI", [
    { name: "GetEvents", serverStreaming: true, options: {}, I: GetEventsRequest, O: GetEventsResponse },
    { name: "GetStatus", options: {}, I: GetStatusRequest, O: GetStatusResponse },
    { name: "GetHubbleServerStatus", options: {}, I: ServerStatusRequest, O: ServerStatusResponse },
    { name: "GetHubbleNodes", options: {}, I: GetNodesRequest, O: GetNodesResponse },
    { name: "GetAuthorization", options: {}, I: GetAuthzRequest, O: GetAuthzResponse },
    { name: "GetFeatureFlags", options: {}, I: GetFeatureFlagsRequest, O: GetFeatureFlagsResponse },
    { name: "GetTimescapeFlows", options: {}, I: GetTimescapeDataRequest, O: GetTimescapeDataResponse },
    { name: "GetTimescapeK8SEvents", options: {}, I: GetK8sEventsRequest, O: GetTimescapeK8SEventsResponse },
    { name: "GetTimescapeFlowStats", options: {}, I: GetTimescapeDataRequest, O: GetTimescapeDataResponse },
    { name: "GetFullFlow", options: {}, I: GetFullFlowRequest, O: GetFullFlowResponse },
    { name: "GetControlStream", serverStreaming: true, options: {}, I: GetControlStreamRequest, O: GetControlStreamResponse },
    { name: "GetTimescapePods", options: {}, I: GetTimescapePodsRequest, O: GetTimescapePodsResponse },
    { name: "GetTimescapeProcessEvents", serverStreaming: true, options: {}, I: GetTimescapeProcessEventsRequest, O: GetTimescapeProcessEventsResponse },
    { name: "GetServiceMapFromLogs", options: {}, I: GetServiceMapFromLogsRequest, O: GetServiceMapFromLogsResponse }
]);
