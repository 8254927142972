import { ButtonProps, CheckboxProps } from '@blueprintjs/core';
import { IObservableValue, makeAutoObservable, observable, reaction } from 'mobx';

export const TUTORIALS_REPO = `cilium/networkpolicy`;
export const TUTORIALS_REPO_PATH_PREFIX = 'tutorials/';
export const TUTORIALS_REPO_BRANCH = `main`;

export enum PolicyAssistantActionKind {
  Button = 'button',
  Checkbox = 'checkbox',
}

export interface PolicyAssistantTutorial {
  id: string;
  title: string;
  entries: PolicyAssistantEntry[];
  yaml?: string | null;
}

export class PolicyAssistantEntry implements PolicyAssistantEntry {
  id: string;
  title: React.ReactNode | null;
  description: IObservableValue<React.ReactNode | null>;

  private _actions: Set<PolicyAssistantAction>;

  constructor({
    id,
    title,
    description,
  }: {
    id: string;
    title: React.ReactNode | null;
    description: React.ReactNode | null;
  }) {
    makeAutoObservable(this);

    this.id = id;
    this.title = title;
    this.description = observable.box(description, { deep: false });

    this._actions = new Set();
  }

  get actions() {
    return Array.from(this._actions);
  }

  addAction = (action: PolicyAssistantAction) => {
    this._actions.add(action);
    return this;
  };

  deleteAction = (action: PolicyAssistantAction) => {
    this._actions.delete(action);
    return this;
  };
}

export class PolicyAssistantAction {
  id: string;
  kind: PolicyAssistantActionKind;
  title: JSX.Element | string | null;
  description: IObservableValue<JSX.Element | string | null>;
  buttonText: string | null;
  rating: number | null;
  active: IObservableValue<boolean | null>;
  disabled: IObservableValue<boolean | null>;
  props?: ButtonProps | CheckboxProps;
  private _onChange?: (action: PolicyAssistantAction) => void;

  constructor({
    id,
    title,
    kind,
    description,
    buttonText,
    rating,
    active,
    disabled,
    onChange,
    props,
  }: {
    id: string;
    kind: PolicyAssistantActionKind;
    title: JSX.Element | string | null;
    description: JSX.Element | string | null;
    buttonText: string | null;
    rating: number | null;
    active: IObservableValue<boolean | null>;
    disabled: IObservableValue<boolean | null>;
    onChange?: (action: PolicyAssistantAction) => void;
    props?: ButtonProps;
  }) {
    makeAutoObservable(this);
    this.id = id;
    this.title = title;
    this.kind = kind;
    this.description = observable.box(description, { deep: false });
    this.buttonText = buttonText;
    this.active = active;
    this.disabled = disabled;
    this.rating = rating;
    this._onChange = onChange;
    this.props = props;
  }

  onChange = () => this._onChange?.(this);
}

export function createReactiveValue<T>(getter: () => T) {
  const box = observable.box(getter());
  reaction(getter, val => box.set(val));
  return box;
}
