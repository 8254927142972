import React, { ReactNode, ThHTMLAttributes } from 'react';

import classnames from 'classnames';

import css from './SortableTH.scss';
import { Direction, SortableColumnProps } from './useSortableColumn';

export type Props<Columns> = ThHTMLAttributes<HTMLTableCellElement> & {
  column: Columns;
  sorter: SortableColumnProps<Columns>;
  children: ReactNode;
  childrenClassName?: string;
};

export function SortableTH<Columns>(props: Props<Columns>) {
  const { children, childrenClassName: _, ...restProps } = props;

  const onChildClick = React.useCallback(() => {
    props.sorter.onChange(props.column);
  }, [props.column, props.sorter.onChange]);

  const isActive = React.useMemo(() => {
    return props.sorter.column === props.column;
  }, [props.sorter.column, props.column]);

  const childDirectionIcon = React.useMemo(() => {
    if (!isActive) return '◦';
    return props.sorter.direction === Direction.ASC ? '↑' : '↓';
  }, [props.sorter.direction, isActive]);

  const childrenClassName = React.useMemo(() => {
    return classnames(css.inner, props.childrenClassName, {
      [css.active]: isActive,
    });
  }, [props.childrenClassName, isActive]);

  const child = (
    <div className={childrenClassName} onClick={onChildClick}>
      <div>{children}</div>
      <div className={css.directionIcon}>{childDirectionIcon}</div>
    </div>
  );

  return React.createElement('th', restProps, child);
}
