import { makeAutoObservable } from 'mobx';

import { LastSeen } from '~/domain/common';
import { FlowDigest } from '~/domain/flows/common';
import * as timeHelpers from '~/domain/helpers/time';
import { AuthType, TCPFlags, Time, Verdict, Workload } from '~/domain/hubble';
import { Labels } from '~/domain/labels';

export interface FlowSummaryPlain {
  id: string;
  time: Time;
  sourcePod: string;
  sourceLabels: string[];
  sourceIp: string;
  sourceWorkloads: Workload[];
  destinationPod: string;
  destinationLabels: string[];
  destinationNames: string[];
  destinationIp: string;
  destinationPort?: number;
  verdict: Verdict;
  tcpFlags?: TCPFlags;
  destinationWorkloads: Workload[];
  authType: AuthType;
}

export class FlowSummary {
  private plain: FlowSummaryPlain;

  constructor(plain: FlowSummaryPlain) {
    this.plain = plain;

    makeAutoObservable(this, void 0, {
      autoBind: true,
    });
  }

  public asFlowDigest(): FlowDigest {
    const sourceLabelsKV = Labels.labelsToKV(this.plain.sourceLabels);
    const destLabelsKV = Labels.labelsToKV(this.plain.destinationLabels);

    const sourceNamespace = Labels.findNamespaceInLabels(sourceLabelsKV);
    const destNamespace = Labels.findNamespaceInLabels(destLabelsKV);

    const sourceService =
      this.plain.sourceWorkloads.find(w => w.name.length)?.name ||
      Labels.findAppNameInLabels(sourceLabelsKV);
    const destService =
      this.plain.destinationWorkloads.find(w => w.name.length)?.name ||
      Labels.findAppNameInLabels(destLabelsKV);

    return {
      id: this.plain.id,

      sourcePodName: this.plain.sourcePod,
      sourceIp: this.plain.sourceIp,
      sourceService: sourceService ?? void 0,
      sourceNamespace: sourceNamespace ?? void 0,
      sourceLabels: this.plain.sourceLabels,
      sourceWorkloads: this.plain.sourceWorkloads,

      destinationPodName: this.plain.destinationPod,
      destinationIp: this.plain.destinationIp,
      destinationDns: this.plain.destinationNames?.[0] ?? void 0,
      destinationService: destService ?? void 0,
      destinationNamespace: destNamespace ?? void 0,
      destinationLabels: this.plain.destinationLabels,
      destinationPort: this.plain.destinationPort,
      destinationWorkloads: this.plain.destinationWorkloads,

      verdict: this.plain.verdict,
      tcpFlags: this.plain.tcpFlags,
      time: this.plain.time,

      authType: this.plain.authType,
    };
  }

  public compare(rhs: FlowSummary): number {
    return timeHelpers.compareTimes(this.plain.time, rhs.plain.time);
  }

  public asPlain(): FlowSummaryPlain {
    return this.plain;
  }

  public asLastSeen(): LastSeen {
    return {
      id: this.id,
      timestamp: this.plain.time,
    };
  }

  public get id(): string {
    return this.plain.id;
  }

  public get date(): Date {
    const { seconds: s, nanos: n } = this.plain.time;

    return new Date(s * 1000 + n / 1e6);
  }
}
