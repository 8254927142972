import { HubbleNode, HubbleServerStatus } from '~/domain/hubble';
import { AuthState, NoPermission, Notification } from '~/domain/notifications';
import { FlowStats, Status } from '~/domain/status';
import * as notifpb from '~backend/proto/ui/notifications_pb';
import * as statuspb from '~backend/proto/ui/status_pb';

export const fromPb = (notif: notifpb.Notification): Notification | null => {
  const parsed = new Notification();

  switch (notif.notification.oneofKind) {
    case 'conn_state':
      parsed.connState = {
        ...notif.notification.conn_state,
        relayConnected: notif.notification.conn_state.relay_connected,
        relayReconnecting: notif.notification.conn_state.relay_reconnecting,
        timescapeConnected: notif.notification.conn_state.timescape_connected,
        timescapeReconnecting: notif.notification.conn_state.timescape_reconnecting,
        k8sUnavailable: notif.notification.conn_state.k8s_unavailable,
        k8sConnected: notif.notification.conn_state.k8s_connected,
      };
      break;
    case 'data_state':
      parsed.dataState = {
        ...notif.notification.data_state,
        noActivity: notif.notification.data_state.no_activity,
      };
      break;
    case 'status':
      parsed.status = statusFromPb(notif.notification.status) ?? void 0;
      break;
    case 'no_permission':
      parsed.noPermission = noPermissionFromPb(notif.notification.no_permission);
      break;
    case 'auth_state':
      parsed.authState = authStateFromPb(notif.notification.auth_state);
      break;
    case undefined:
    default:
      return null;
  }

  return parsed;
};

export const authStateFromPb = (state: notifpb.AuthorizationState): AuthState => {
  return {
    invalid: !!state.invalid,
  };
};

export const statusFromPb = (status: statuspb.GetStatusResponse): Status | null => {
  if (!status.flows) return null;
  const flowStats = status.flows;

  const serverStatus = status.server_status;
  if (serverStatus == null) return null;

  const flows: FlowStats = {
    perSecond: flowStats.per_second,
  };

  const nodes =
    status.nodes?.nodes?.map(node => {
      return HubbleNode.fromPb(node);
    }) || [];

  return {
    nodes,
    status: HubbleServerStatus.fromPb(serverStatus),
    flows,
    versions: [],
  };
};

export const noPermissionFromPb = (noPerms: notifpb.NoPermission): NoPermission => {
  return {
    resource: noPerms.resource,
    error: noPerms.error,
  };
};
