import React from 'react';

import classNames from 'classnames';

import { AspectRatioBox } from '~/components/AspectRatioBox';

import css from './ColoredCircle.scss';

export enum Color {
  Red = 'red',
  Green = 'green',
}

export type Props = {
  color?: Color;
  className?: string;
};

export const ColoredCircle = function ColoredCircle(props: Props) {
  const color = props.color ?? Color.Green;

  const classes = classNames(css.coloredCircle, props.className, {
    [css.red]: color === Color.Red,
    [css.green]: color === Color.Green,
  });

  return (
    <div className={classes}>
      <AspectRatioBox ratio="1x1">
        <div className={css.circle} />
      </AspectRatioBox>
    </div>
  );
};
