import _ from 'lodash';
import * as mobx from 'mobx';

import { Flow } from '~/domain/flows';
import { IPEndpoint } from '~/domain/interactions/endpoints';

export class DestinationGroup {
  // NOTE: name is a group id
  public name: string;

  // NOTE: { ipAddress -> IPEndpoint }
  public destinations: Map<string, IPEndpoint>;
  public separatedDestinations: Map<string, IPEndpoint>;
  public flows: Flow[];

  public static readonly ipAddressesName: string = 'IP Addresses';

  public static isIpAddressesName(groupName: string): boolean {
    return groupName === DestinationGroup.ipAddressesName;
  }

  public static getName(dnsNames: string[]): string {
    if (!dnsNames || dnsNames.length === 0) return DestinationGroup.ipAddressesName;

    return dnsNames[0];
  }

  public static getNameFromRelated(dnsNames: string[], flows?: Flow[]): string {
    // NOTE: priorities for destination names:
    // 1. destinationNames[0]
    // 2. appropriate flow.destinationDns
    // 3. appropriate flow.destinationAppName
    // 4. Unknown

    if (dnsNames.length > 0) return dnsNames[0];
    if (flows == null) return DestinationGroup.ipAddressesName;

    let flowWithDns: Flow | null = null;
    let flowWithAppName: Flow | null = null;

    for (const flow of flows) {
      if (!!flow.destinationDns && flowWithDns == null) {
        flowWithDns = flow;
      }

      if (!!flow.destinationIdentityName && flowWithAppName == null) {
        flowWithAppName = flow;
      }

      if (flowWithDns != null && flowWithAppName != null) break;
    }

    if (flowWithDns != null) return flowWithDns.destinationDns!;
    if (flowWithAppName != null) return _.capitalize(flowWithAppName.destinationIdentityName!);

    return DestinationGroup.ipAddressesName;
  }

  public static new(name: string) {
    return new DestinationGroup(name);
  }

  constructor(name: string) {
    this.name = name;
    this.destinations = new Map();
    this.separatedDestinations = new Map();
    this.flows = [];

    mobx.makeAutoObservable(this);
  }

  public get isUnknown(): boolean {
    return DestinationGroup.isIpAddressesName(this.name);
  }

  public get labels(): Set<string> {
    const labels: Set<string> = new Set();

    this.flows?.forEach(flow => {
      flow.destinationLabels.forEach(({ key, value }) => {
        const lbl = `${key}${value ? '=' + value : ''}`;
        labels.add(lbl);
      });
    });

    return labels;
  }

  public get flowIds(): Set<string> {
    return new Set(this.flows.map(f => f.id));
  }

  public get id(): string {
    return this.name;
  }

  public upsertPair(ipAddress: string, port: number, isSeparated?: boolean): boolean {
    const destinations = isSeparated ? this.separatedDestinations : this.destinations;

    // NOTE: if we already have a regular destination - remove it
    if (!!isSeparated && this.destinations.has(ipAddress)) {
      const regularDestination = this.destinations.get(ipAddress);

      regularDestination?.removePort(port);

      if (!regularDestination?.ports.size) {
        this.destinations.delete(ipAddress);
      }
    }

    if (!destinations.has(ipAddress)) {
      destinations.set(ipAddress, new IPEndpoint(ipAddress));
    }

    return !!destinations.get(ipAddress)?.addPort(port);
  }

  public upsertPairs(ipAddress: string, ports: Set<number> | number[], isSeparated?: boolean) {
    ports.forEach((port: number) => {
      this.upsertPair(ipAddress, port, isSeparated);
    });
  }

  public addFlows(flows?: Flow[]) {
    if (flows == null) return;

    // NOTE: do not do this.flows.push(...flows) as it blows up stack size
    // this.flows.push(...flows);
    this.flows = this.flows.concat(flows);
  }
}

// NOTE: { groupName -> DestinationGroup }
export type DestinationGroups = Map<string, DestinationGroup>;
