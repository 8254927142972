import { PolicyCard } from '~/domain/cimulator/cards';
import { PolicyEndpoint } from '~/domain/cimulator/endpoint';
import { CardKind, CardSide } from '~/domain/cimulator/types';

export function createInitialCards(isClusterwide: boolean): Map<string, PolicyCard> {
  const map = new Map<string, PolicyCard>();
  const add = (card: PolicyCard) => map.set(card.id, card);
  add(new PolicyCard(CardSide.Selector, CardKind.InNamespace));
  [CardSide.Ingress, CardSide.Egress].forEach(cardSide => {
    [
      CardKind.All,
      CardKind.OutsideCluster,
      isClusterwide ? null : CardKind.InNamespace,
      CardKind.InCluster,
    ]
      .filter(Boolean)
      .forEach(cardKind => {
        const card = new PolicyCard(cardSide, cardKind as CardKind);
        const allEndpoint = PolicyEndpoint.newAll();
        if (card.isInCluster && !isClusterwide) {
          if (card.isEgress) {
            const kubeDns = PolicyEndpoint.newKubeDns();
            card.addEndpoints(kubeDns);
          }
        }
        card.addEndpoints(allEndpoint);
        add(card);
      });
  });
  return map;
}
