import React, { createContext, PropsWithChildren, useContext } from 'react';

import { NotifierProvider } from '~/notifier/hooks';
import { StoreProvider } from '~/store';
import {
  store as cimulatorStore,
  StoreProvider as CimulatorStoreProvider,
} from '~/store/stores/cimulator';

import { Application } from './application';

export const ApplicationContext = createContext<Application | null>(null);

export type Props = PropsWithChildren<{
  app: Application;
}>;

export const ApplicationProvider = (props: Props) => {
  return (
    <ApplicationContext.Provider value={props.app}>
      <StoreProvider store={props.app.store}>
        <NotifierProvider>
          <CimulatorStoreProvider store={cimulatorStore}>{props.children}</CimulatorStoreProvider>
        </NotifierProvider>
      </StoreProvider>
    </ApplicationContext.Provider>
  );
};

export const useApplication = () => {
  const app = useContext(ApplicationContext);
  if (app != null) return app;

  throw new Error('useApplication must be used within ApplicationContext');
};
