import React from 'react';

import { Button, Tooltip } from '@blueprintjs/core';
import classnames from 'classnames';
import * as mobx from 'mobx';
import { observer } from 'mobx-react';

import { AccessPoint } from '~/components/AccessPoint';
import { Card, Props as CardProps } from '~/components/Card';
import { EndpointCardHeader } from '~/components/EndpointCardHeader';
import { ServiceCard, ServiceEndpoint } from '~/components/ServiceMapCard/types';
import { Teleport } from '~/components/Teleport';
import { L7Endpoint } from '~/domain/interactions/endpoints';
import { Connections } from '~/domain/interactions/new-connections';
import { RefsCollector } from '~/ui/service-map/collector';
import * as lang from '~/utils/lang';

import { EndpointCardLabels } from './EndpointCardLabels';
import { HTTPEndpointGroup } from './http-groups';
import { HttpEndpoint } from './HttpEndpoint';
import css from './styles.scss';

type SVGGElementRef = React.MutableRefObject<SVGGElement | null>;

type Props = CardProps & {
  card: ServiceCard;
  collector?: RefsCollector;
  l7endpoints?: Connections<L7Endpoint>;
  isClusterMeshed?: boolean;
  maxHttpEndpointsVisible?: number;
  active?: boolean;
  showAdditionalInfo?: boolean;
  onGotoProcessTree?: (card: ServiceCard) => void;
  underlayRef?: SVGGElementRef;
  backgroundsRef?: SVGGElementRef;
  overlayRef?: SVGGElementRef;
  onHeaderClick?: (c: ServiceCard) => void;
};

export const ServiceMapCard = observer(function ServiceMapCard(props: Props) {
  const maxHttpEndpoints = props.maxHttpEndpointsVisible ?? Infinity;

  const accessPoints = mobx
    .computed(() => {
      const aps = props.card.accessPoints;

      return aps.map((ap: ServiceEndpoint) => {
        const groups = HTTPEndpointGroup.createSorted(props.l7endpoints?.get(`${ap.port}`));

        const endpointsWord = lang.pluralize('endpoint', groups.length - maxHttpEndpoints);
        return (
          <div className={css.accessPointGroup} key={ap.id}>
            <AccessPoint
              key={ap.id}
              id={ap.id}
              cardId={props.card.id}
              port={ap.port}
              l4Protocol={ap.l4Protocol}
              l7Protocol={ap.l7Protocol ?? void 0}
              verdicts={ap.verdicts}
              authTypes={ap.authTypes}
              connectorRef={props.collector?.accessPointConnector(ap.id)}
            />

            {groups.length > 0 && props.active && (
              <>
                <div className={css.l7groups}>
                  {groups.slice(0, maxHttpEndpoints).map(group => {
                    return (
                      <HttpEndpoint key={group.key} group={group} collector={props.collector} />
                    );
                  })}
                </div>
                {groups.length > maxHttpEndpoints && (
                  <div className={css.endpointsLimited}>
                    {endpointsWord.num} {endpointsWord.plural} {endpointsWord.be} hidden
                  </div>
                )}
              </>
            )}
          </div>
        );
      });
    })
    .get();

  const showGoToProcessTree = props.active && props.card.hasClearAppName;
  const backplateClasses = classnames({
    [css.serviceMapCardBackplate]: true,
    [css.active]: !!props.active,
  });

  const backgroundClasses = classnames(css.serviceMapCardBackground);
  const foregroundClasses = classnames(css.serviceMapCardForeground, props.className);

  return (
    <>
      {/* Render backplate only when card sizes are known */}
      {!props.isUnsizedMode && (
        <>
          <Teleport to={props.underlayRef}>
            <Card coords={props.coords} className={backplateClasses} />
          </Teleport>

          <Teleport to={props.backgroundsRef}>
            <Card coords={props.coords} className={backgroundClasses} />
          </Teleport>
        </>
      )}

      <Card
        {...props}
        isUnsizedMode={!props.isUnsizedMode}
        className={foregroundClasses}
        divRef={props.collector?.cardRoot(props.card.id)}
      >
        <EndpointCardHeader
          card={props.card}
          showAdditionalInfo={props.showAdditionalInfo}
          onHeadlineClick={() => props.onHeaderClick?.(props.card)}
        />

        {accessPoints.length > 0 && <div className={css.accessPoints}>{accessPoints}</div>}

        {props.isClusterMeshed && props.card.clusterName && (
          <div className={css.clusterNameLabel}>
            <Tooltip content={`Cluster name: ${props.card.clusterName}`}>
              {props.card.clusterName}
            </Tooltip>
          </div>
        )}

        {props.active && props.card.labels && <EndpointCardLabels labels={props.card.labels} />}

        {showGoToProcessTree && (
          <Button
            outlined
            onClick={() => props.onGotoProcessTree?.(props.card)}
            text="Show processes →"
          />
        )}
      </Card>
    </>
  );
});
