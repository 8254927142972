import React from 'react';

import { observer } from 'mobx-react';

import { useStore } from '~/store/stores/cimulator';

import css from './YamlPanel.scss';

export const PolicyWarning = observer(function PolicyWarning() {
  const store = useStore();

  if (!store.policy.hasUnsupportedRules) {
    return null;
  }

  if (store.policy.hasUnsupportedEndpoints && store.policy.hasUnsupportedOriginRules) {
    return (
      <div className={css.unsupportedWarning}>
        The policy contains rules that only supported by Cilium Network Policy, and some uploaded
        rules are not supported by the editor yet
      </div>
    );
  } else if (store.policy.hasUnsupportedEndpoints) {
    return (
      <div className={css.unsupportedWarning}>
        The policy contains rules that only supported by Cilium Network Policy specification
      </div>
    );
  } else {
    return (
      <div className={css.unsupportedWarning}>
        The uploaded policy contains rules not supported by the editor yet
      </div>
    );
  }
});
