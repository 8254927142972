import React from 'react';

import { Icon } from '@blueprintjs/core';
import { observer } from 'mobx-react-lite';

import { StatusCenter } from '~/ui-layer/status-center';

import { Props as EntryProps, LogEntry } from './LogEntry';
import css from './StatusCenterWindow.scss';

export type Props = {
  statusCenter: StatusCenter;
  onClose?: () => void;

  detailsTarget: EntryProps['detailsTarget'];
};

export const StatusCenterWindow = observer(function StatusCenterWindow(props: Props) {
  const { statusCenter } = props;
  const totalEntries = statusCenter.notifications.length;

  return (
    <div className={css.statusCenterWindow}>
      <div className={css.header}>
        <div className={css.title}>Event log</div>

        {/* TODO: Replace this with our custom CloseButton component */}
        <div className={css.closeButton}>
          <Icon icon="cross" size={18} color={'#A4B6C1'} onClick={props.onClose} />
        </div>
      </div>

      <div className={css.entries}>
        {totalEntries === 0 && <div className={css.noEntriesPlaceholder}>No events</div>}

        {statusCenter.pendingEntriesList.map(entry => {
          return (
            <LogEntry
              key={entry.hash}
              entry={entry}
              className={css.entry}
              detailsTarget={props.detailsTarget}
            />
          );
        })}

        {statusCenter.nonPendingEntriesList.map(entry => {
          return (
            <LogEntry
              key={entry.hash}
              entry={entry}
              className={css.entry}
              detailsTarget={props.detailsTarget}
            />
          );
        })}
      </div>
    </div>
  );
});
