/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter add_pb_suffix,eslint_disable,ts_nocheck,generate_dependencies,long_type_number,use_proto_field_name
// @generated from protobuf file "tetragon/sandbox.proto" (package "tetragon", syntax proto3)
// tslint:disable
// @ts-nocheck
//
// Copyright 2020 Authors of Hubble
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
//
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Process } from "./tetragon_pb";
/**
 * @generated from protobuf message tetragon.ProcessSandboxSyscall
 */
export interface ProcessSandboxSyscall {
    /**
     * @generated from protobuf field: tetragon.Process process = 1;
     */
    process?: Process;
    /**
     * @generated from protobuf field: tetragon.Process parent = 2;
     */
    parent?: Process;
    /**
     * syscall name
     *
     * @generated from protobuf field: string name = 3;
     */
    name: string;
    /**
     * policy name
     *
     * @generated from protobuf field: string policy = 4;
     */
    policy: string;
}
// @generated message type with reflection information, may provide speed optimized methods
class ProcessSandboxSyscall$Type extends MessageType<ProcessSandboxSyscall> {
    constructor() {
        super("tetragon.ProcessSandboxSyscall", [
            { no: 1, name: "process", kind: "message", T: () => Process },
            { no: 2, name: "parent", kind: "message", T: () => Process },
            { no: 3, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "policy", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ProcessSandboxSyscall>): ProcessSandboxSyscall {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.name = "";
        message.policy = "";
        if (value !== undefined)
            reflectionMergePartial<ProcessSandboxSyscall>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ProcessSandboxSyscall): ProcessSandboxSyscall {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* tetragon.Process process */ 1:
                    message.process = Process.internalBinaryRead(reader, reader.uint32(), options, message.process);
                    break;
                case /* tetragon.Process parent */ 2:
                    message.parent = Process.internalBinaryRead(reader, reader.uint32(), options, message.parent);
                    break;
                case /* string name */ 3:
                    message.name = reader.string();
                    break;
                case /* string policy */ 4:
                    message.policy = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ProcessSandboxSyscall, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* tetragon.Process process = 1; */
        if (message.process)
            Process.internalBinaryWrite(message.process, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.Process parent = 2; */
        if (message.parent)
            Process.internalBinaryWrite(message.parent, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* string name = 3; */
        if (message.name !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.name);
        /* string policy = 4; */
        if (message.policy !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.policy);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tetragon.ProcessSandboxSyscall
 */
export const ProcessSandboxSyscall = new ProcessSandboxSyscall$Type();
