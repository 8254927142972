import React, { useCallback } from 'react';

import { observer } from 'mobx-react';

import { useApplication } from '~/application';
import { Authorization } from '~/authorization';
import { NavigationSidebar } from '~/components/NavigationSidebar/NavigationSidebar';
import { DashboardPage } from '~/components/ServiceMapApp/DashboardPage';
import { GrafanaDashboardKind } from '~/domain/features';

import css from './styles.scss';
import { DashboardVariant } from './types';

export const Dashboard = observer(() => {
  const { ui, store, dataLayer } = useApplication();

  const onClusterNamespaceChange = useCallback(
    (_cluster: string | null, namespace: string | null) => {
      ui.controls.clusterNamespaceChanged(store.clusterNamespaces.currCluster, namespace);
    },
    [store.clusterNamespaces.currCluster],
  );

  const onLoginAgainClick = useCallback(() => {
    Authorization.goToSignin();
  }, []);

  const dashboardVariant = store.controls.currentDashboardVariant ?? DashboardVariant.Overview;

  const dashboardKind = React.useMemo(() => {
    switch (dashboardVariant) {
      case DashboardVariant.Overview:
        return GrafanaDashboardKind.Cluster;
      default:
        return GrafanaDashboardKind.Namespace;
    }
  }, [dashboardVariant]);

  return (
    <div className={css.app}>
      <div className={css.sidebar}>
        <NavigationSidebar
          transferState={dataLayer.transferState}
          timeRange={store.controls.timeRange}
          onTimeRangeChange={store.controls.setTimeRange}
          hideNamespaceSelector={dashboardKind === GrafanaDashboardKind.Cluster}
          namespacesList={store.clusterNamespaces.currClusterNamespacesList}
          currNamespace={store.clusterNamespaces.currNamespace}
          onClusterNamespaceChange={onClusterNamespaceChange}
          onAppSelect={app => ui.applicationChanged(app)}
          onLoginAgainClick={onLoginAgainClick}
        />
      </div>

      <div className={css.workspace}>
        <DashboardPage
          showNodesStatus={dashboardVariant === DashboardVariant.Overview}
          dashboardKind={dashboardKind}
          dashboardVariant={dashboardVariant}
        />
      </div>
    </div>
  );
});
