import React, { createContext, PropsWithChildren, useContext } from 'react';

import { OverlayToaster } from '@blueprintjs/core';

import { setupDebugProp } from '~/domain/misc';

import { Notifier, Props as NotifierProps } from './notifier';

type Props = PropsWithChildren<NotifierProps>;

const NotifierContext = createContext<Notifier | null>(null);

export const NotifierProvider = (props: Props) => {
  const notifier = new Notifier();
  setupDebugProp({ notifier });

  const toasterProps = Notifier.prepareToasterProps(props);

  return (
    <NotifierContext.Provider value={notifier}>
      <OverlayToaster
        {...toasterProps}
        ref={ref => {
          if (!ref) return;

          notifier.setBackend(ref!);
        }}
      />

      {props.children}
    </NotifierContext.Provider>
  );
};

export const useNotifier = () => {
  const notifier = useContext(NotifierContext);
  if (!notifier) {
    throw new Error('useNotifier must be used within a NotifierProvider.');
  }

  return notifier;
};
