import React from 'react';

import { observer } from 'mobx-react';

import { Intent } from '~/components/widgets/common';
import { applyPrefix } from '~/utils';

export interface Props {
  className?: string;
  intent?: Intent;
  iconsPrefix?: string;
  containerRef?: React.MutableRefObject<HTMLDivElement | null>;
  isDarkThemeEnabled: boolean;
}

export const TrafficIcon = observer(function TrafficIcon(props: Props) {
  const intent = props.intent ?? Intent.None;
  const themePrefix = props.isDarkThemeEnabled ? 'dark-' : '';

  const iconSrc =
    intent === Intent.Primary
      ? '/icons/processes/svg/connect-emit-main.svg'
      : intent === Intent.Danger
        ? `/icons/processes/svg/${themePrefix}connect-emit-danger.svg`
        : `/icons/processes/svg/${themePrefix}connect-emit-regular.svg`;

  return (
    <div className={props.className} ref={props.containerRef}>
      <img src={applyPrefix(iconSrc, props.iconsPrefix)} />
    </div>
  );
});
