import { useLayoutEffect, useState } from 'react';

import useResizeObserver from '@react-hook/resize-observer';

export const useVisibleHeight = (target: React.RefObject<any>) => {
  const [visibleHeight, setVisibleHeight] = useState<number>(0);
  useLayoutEffect(() => {
    setVisibleHeight(target.current?.getBoundingClientRect().height || 0);
  }, [target]);

  // Where the magic happens
  useResizeObserver(target, entry => setVisibleHeight(entry.contentRect.height));

  return visibleHeight;
};
