import React from 'react';

import { Icon } from '@blueprintjs/core';
import classNames from 'classnames';
import _ from 'lodash';

import { Kind as EventKind, ProcessEvent } from '~/domain/process-events';

import css from './styles.scss';

export interface Props {
  event: ProcessEvent | null;

  isVisible?: boolean;

  onClose?: () => void;
}

export const ProcessEventTableSidebar = function ProcessEventTableSidebar(props: Props) {
  const { event: evt, isVisible } = props;
  if (evt == null || evt.base == null || !isVisible) return null;

  const { process: ps, kind } = evt.base;

  return (
    <div className={css.processEventTableSidebar}>
      <div className={css.header}>
        <span>Process event details</span>

        <Icon className={css.closeButton} icon="cross" onClick={props.onClose} />
      </div>

      {section(css.general, 'General', [
        descriptor('Cluster', evt.node_name),
        descriptor('Event kind', _.capitalize(kind)),
        descriptor('Time', evt.date.toISOString()),
      ])}

      {section(css.process, 'Process', [
        descriptor('PID', ps.pid),
        descriptor('UID', ps.uid),
        descriptor('CWD', ps.cwd),
        descriptor('Binary', ps.binary),
        descriptor('Arguments', ps.arguments || '-'),
        descriptor('Flags', ps.flags || '-'),
        descriptor('Start time', ps.start_time.toISOString()),
        ps.docker && descriptor('Docker', ps.docker),
        descriptor('AUID', ps.auid),
      ])}

      {ps.pod &&
        section(css.pod, 'Pod', [
          descriptor('Namespace', ps.pod.namespace),
          descriptor('Name', ps.pod.name),
          ps.pod.labels && descriptor('Labels', ps.pod.labels.join('\n')),
        ])}

      {ps.pod?.container &&
        section(css.container, 'Container', [
          descriptor('ID', ps.pod.container.id),
          descriptor('Name', ps.pod.container.name),
          ps.pod.container.image && [
            descriptor('Image ID', ps.pod.container.image.id),
            descriptor('Image name', ps.pod.container.image.name),
          ],
          descriptor('Start time', ps.pod.container.start_time.toISOString()),
          ps.pod.container.pid != null && descriptor('PID inside', ps.pod.container.pid),
        ])}

      {kind === EventKind.Connect &&
        section(null, 'Connect event data', [
          descriptor('Source IP', evt.process_connect?.sourceIp),
          descriptor('Source port', evt.process_connect?.sourcePort),
          descriptor('Destination IP', evt.process_connect?.destinationIp),
          descriptor('Destination port', evt.process_connect?.destinationPort),
          descriptor('Destination names', evt.process_connect?.destinationNames.join('\n') || '-'),
        ])}
    </div>
  );
};

const section = (
  cls: string | null,
  caption: string,
  labels: React.ReactNode[],
): React.ReactNode => {
  return (
    <div className={classNames(css.section, cls)}>
      <span className={css.caption} title={caption}>
        {caption}
      </span>

      <div className={css.labels}>{labels.flat()}</div>
    </div>
  );
};

const descriptor = (caption: string, content: React.ReactNode, cls?: string): React.ReactNode => {
  return (
    <div className={classNames(css.descriptor, cls)} key={caption}>
      <span className={css.caption} title={caption}>
        {caption}
      </span>

      <span className={css.content} title={content?.toString()}>
        {content}
      </span>
    </div>
  );
};
