import { timeToDate } from '~/domain/helpers/time';
import { Time } from '~/domain/time';

import { AcceptEvent } from './accept-event';
import { CloseEvent } from './close-event';
import { ConnectEvent } from './connect-event';
import { ExecEvent } from './exec-event';
import { ExitEvent } from './exit-event';
import { ListenEvent } from './listen-event';
import { Ancestor, Kind, Pod, Process } from './types';

export interface ProcessEventPlain {
  node_name: string;
  time: Time;

  process_exec?: ExecEvent;
  process_connect?: ConnectEvent;
  process_listen?: ListenEvent;
  process_accept?: AcceptEvent;
  process_close?: CloseEvent;
  process_exit?: ExitEvent;
}

export interface ProcessEventBase {
  kind: Kind;
  process: Process;
  parent?: Ancestor;
  ancestors?: Ancestor[];
}

type ProcessEventBody = Omit<ProcessEventPlain, 'node_name' | 'time'>;

export class ProcessEvent implements ProcessEventPlain {
  // TODO: some meta fields were omited, see fgs.proto for full description
  public node_name!: string;
  public time!: Time;

  public process_exec?: ExecEvent;
  public process_connect?: ConnectEvent;
  public process_listen?: ListenEvent;
  public process_accept?: AcceptEvent;
  public process_close?: CloseEvent;
  public process_exit?: ExitEvent;

  public static getKind(obj: ProcessEventBody): Kind {
    if (obj.process_connect != null) {
      return Kind.Connect;
    } else if (obj.process_exec != null) {
      return Kind.Exec;
    } else if (obj.process_close != null) {
      return Kind.Close;
    } else if (obj.process_accept != null) {
      return Kind.Accept;
    } else if (obj.process_exit != null) {
      return Kind.Exit;
    } else if (obj.process_listen != null) {
      return Kind.Listen;
    }

    return Kind.Kprobe;
  }

  public get id(): string {
    const node = this.node_name;
    const kind = this.kind;
    const { seconds: ss, nanos: nss } = this.time;
    const name = this.pod?.name ?? '<no_pod>';
    const ns = this.pod?.namespace ?? '<no_namespace>';
    const execId = this.base?.process.exec_id ?? '<no_exec_id>';
    const pExecId = this.base?.process.parent_exec_id ?? '<no_parent_exec_id>';

    return `${node}-${ns}-${name}-${kind}-${execId}-${pExecId}-${ss}.${nss}`;
  }

  public get date(): Date {
    return timeToDate(this.time);
  }

  public get kind(): Kind {
    return ProcessEvent.getKind(this);
  }

  public get pod(): Pod | null {
    const base = this.base;

    return (
      base?.process.pod ??
      base?.parent?.pod ??
      base?.ancestors?.find(anc => anc.pod != null)?.pod ??
      null
    );
  }

  public get base(): ProcessEventBase | null {
    const concreteEvent =
      this.process_exec ||
      this.process_connect ||
      this.process_close ||
      this.process_accept ||
      this.process_exit ||
      this.process_listen;

    if (concreteEvent == null) return null;

    return {
      process: concreteEvent.process,
      parent: concreteEvent.parent ?? void 0,
      ancestors: (concreteEvent as any).ancestors ?? void 0,
      kind: this.kind,
    };
  }

  public get exec_id(): string | null {
    const base = this.base;
    if (base == null) return null;

    return base.process.exec_id;
  }

  public get namespace(): string | null {
    const base = this.base;

    return base?.process.pod?.namespace ?? base?.parent?.pod?.namespace ?? null;
  }

  public get isConnect() {
    return this.kind === Kind.Connect;
  }

  public get isExec() {
    return this.kind === Kind.Exec;
  }

  public get isListen() {
    return this.kind === Kind.Listen;
  }

  public get isAccept() {
    return this.kind === Kind.Accept;
  }

  public get isExit() {
    return this.kind === Kind.Exit;
  }

  public get isKprobe() {
    return this.kind === Kind.Kprobe;
  }

  public get isClose() {
    return this.kind === Kind.Close;
  }
}
