import React from 'react';

import { ButtonProps } from '@blueprintjs/core';
import { IObservableValue, makeAutoObservable, observable, reaction, runInAction } from 'mobx';

import { PolicyStore } from './policy';

export enum PolicySuggestionEntryKind {
  Recommendation = 'recommendation',
  Violation = 'violation',
}

export enum PolicySuggestionEntryId {
  ForbiddenFqdns = 'forbidden-fqdns',
}

export class PolicySuggestionsStore {
  private policy: PolicyStore;
  private entries: Set<PolicySuggestionEntry> = new Set();

  constructor(policy: PolicyStore) {
    makeAutoObservable(this);

    this.policy = policy;

    this.init();
  }

  get actualSuggestions() {
    return Array.from(this.entries)
      .filter(entry => entry.isActual.get())
      .sort((a, b) => a.rank - b.rank);
  }

  addEntry = (entry: PolicySuggestionEntry) => {
    this.entries.add(entry);
  };

  init = () => {
    this.addEntry(this.createForbiddenFqdns());
  };

  private createForbiddenFqdns = () => {
    const isActual = this.createReactiveValue(() => {
      return this.policy.forbiddenFqdns.length > 0;
    });

    const buildDescription = (forbiddenFqdns: string[]) => {
      const joined = forbiddenFqdns.map((fqdn, idx) => {
        const notLast = idx < forbiddenFqdns.length - 1;
        return (
          <React.Fragment key={fqdn}>
            <b>{fqdn}</b>
            {notLast && ', '}
          </React.Fragment>
        );
      });
      return <>{joined} is not in the approved list of destinations outside of the cluster</>;
    };

    const entry = new PolicySuggestionEntry({
      id: PolicySuggestionEntryId.ForbiddenFqdns,
      title: 'Fix Violation: Not Approved FQDN(s)',
      kind: PolicySuggestionEntryKind.Violation,
      description: buildDescription(this.policy.forbiddenFqdns),
      rank: 0,
      ignorable: false,
      isActual,
    });

    reaction(
      () => this.policy.forbiddenFqdns,
      forbiddenFqdns => {
        runInAction(() => {
          entry.description = buildDescription(forbiddenFqdns);
        });
      },
    );

    entry.addAction(
      new PolicySuggestionAction({
        id: 'cleanup',
        title: 'Remove',
        props: { intent: 'warning' },
        onActivate: this.policy.dropForbiddenFqdns,
      }),
    );

    entry.addAction(this.createRequestReviewAction(entry));

    return entry;
  };

  private createReactiveValue = <T,>(getter: () => T) => {
    const box = observable.box(getter());
    reaction(getter, val => box.set(val));
    return box;
  };

  private createRequestReviewAction = (entry: PolicySuggestionEntry) => {
    return new PolicySuggestionAction({
      id: `request-review-${entry.id}`,
      title: 'Request Approval',
      props: { intent: 'primary' },
      onActivate: action => {
        runInAction(() => {
          action.title = 'Request for approval is sent';
          action.props = { ...action.props, disabled: true };
          entry.rank = 9999;
          entry.ignorable = true;
        });
      },
    });
  };
}

export class PolicySuggestionAction {
  id: string;
  title: string;
  props?: ButtonProps;
  private onActivate: (action: PolicySuggestionAction) => void;

  constructor({
    id,
    title,
    onActivate,
    props,
  }: {
    id: string;
    title: string;
    onActivate: (action: PolicySuggestionAction) => void;
    props?: ButtonProps;
  }) {
    makeAutoObservable(this);
    this.id = id;
    this.title = title;
    this.onActivate = onActivate;
    this.props = props;
  }

  activate = () => this.onActivate(this);
}

export class PolicySuggestionEntry {
  id: PolicySuggestionEntryId;
  kind: PolicySuggestionEntryKind;
  title: React.ReactNode;
  description: React.ReactNode;
  actions: Set<PolicySuggestionAction>;
  rank: number;
  ignorable: boolean;
  isActual: IObservableValue<boolean>;

  constructor({
    id,
    kind,
    title,
    description,
    rank,
    ignorable,
    isActual,
  }: {
    id: PolicySuggestionEntryId;
    kind: PolicySuggestionEntryKind;
    title: React.ReactNode;
    description: React.ReactNode;
    rank: number;
    ignorable: boolean;
    isActual: IObservableValue<boolean>;
  }) {
    makeAutoObservable(this);

    this.id = id;
    this.title = title;
    this.kind = kind;
    this.description = description;
    this.actions = new Set();
    this.rank = rank;
    this.ignorable = ignorable;
    this.isActual = isActual;
  }

  get actionsList() {
    return Array.from(this.actions);
  }

  addAction = (action: PolicySuggestionAction) => {
    this.actions.add(action);
    return this;
  };

  deleteAction = (action: PolicySuggestionAction) => {
    this.actions.delete(action);
    return this;
  };
}
