import React, { SyntheticEvent, useCallback, useMemo, useState } from 'react';

import { Overlay2, OverlaysProvider } from '@blueprintjs/core';
import { useMultipleSelection } from 'downshift';
import useDeepCompareEffect from 'use-deep-compare-effect';

import { FilterGroup } from '~/domain/filtering';

import css from './FlowFiltersInputNew.scss';
import { AddFilterButton } from '../AddFilterButton/AddFilterButton';
import { FilterGroupTags } from '../FilterGroupTags/FilterGroupTags';
import { FlowFilterGroupForm } from '../FlowFilterGroupForm/FlowFilterGroupForm';
import { FlowFiltersInputNewProps } from '../types';

export const FlowFiltersInputNew = function FlowFiltersInputNew(props: FlowFiltersInputNewProps) {
  const { groups, filtersConfig } = props;

  const normalizedGroupItems: {
    [key: string]: FilterGroup;
  } = useMemo(() => {
    return groups.reduce((acc, group) => {
      return {
        ...acc,
        [group.id()]: group,
      };
    }, {});
  }, [groups]);

  const [groupItems, setGroupItems] = useState<{
    [key: string]: FilterGroup;
  }>(normalizedGroupItems || {});

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const toggleOverlay = useCallback(() => setIsDropdownOpen(open => !open), [setIsDropdownOpen]);
  const [formMode, setFormMode] = useState('Create');
  const [currentGroup, setCurrentGroup] = useState<null | string>(null);

  useDeepCompareEffect(() => {
    if (Array.isArray(groups)) {
      setGroupItems(normalizedGroupItems);
      setIsDropdownOpen(false);
    }
  }, [normalizedGroupItems, groups]);

  const onAddFilters = (filtersToAdd: FilterGroup) => {
    if (filtersToAdd.entries.length) {
      const newItems = {
        [filtersToAdd.id()]: filtersToAdd,
      };

      const newGroupItems = {
        ...groupItems,
        ...newItems,
      };

      setGroupItems(newGroupItems);
      props.onChange(Object.values(newGroupItems));
    }
    setIsDropdownOpen(false);
  };

  const onRemoveFilter = (groupId: string) => {
    const newSelectedGroupItems = { ...groupItems };
    delete newSelectedGroupItems[groupId];

    props.onChange(Object.values(newSelectedGroupItems));
    setGroupItems(newSelectedGroupItems);
  };

  const onFilterTagClick = (groupId: string) => {
    setCurrentGroup(groupId);
    setFormMode('Edit');
    setIsDropdownOpen(value => !value);
  };

  const onUpdateFilters = (filters: FilterGroup) => {
    if (!currentGroup) return;

    if (filters.entries.length) {
      const newItems = {
        [currentGroup]: filters,
      };

      const updatedGroupItens = {
        ...groupItems,
        ...newItems,
      };

      setGroupItems(updatedGroupItens);
      props.onChange(Object.values(updatedGroupItens));
    } else {
      const groupItemCopy = { ...groupItems };
      delete groupItemCopy[currentGroup];

      setGroupItems(groupItemCopy);
      props.onChange(Object.values(groupItemCopy));
    }

    setFormMode('Create');
    setIsDropdownOpen(false);
  };

  const { getSelectedItemProps, getDropdownProps } = useMultipleSelection({
    selectedItems: Object.keys(groupItems),
    onStateChange({ type, selectedItems: newSelectedItems }) {
      switch (type) {
        case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownBackspace:
        case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownDelete:
        case useMultipleSelection.stateChangeTypes.DropdownKeyDownBackspace:
        case useMultipleSelection.stateChangeTypes.FunctionRemoveSelectedItem:
          if (Array.isArray(newSelectedItems)) {
            const newSelectedGroupItems: {
              [key: string]: FilterGroup;
            } = {};
            newSelectedItems.forEach(groupId => {
              newSelectedGroupItems[groupId] = groupItems[groupId];
            });
            setGroupItems(newSelectedGroupItems);
          }
          break;
        default:
          break;
      }
    },
  });

  const handleAddFilterClick = useCallback((e: SyntheticEvent) => {
    e.stopPropagation();
    setIsDropdownOpen(value => !value);
    setCurrentGroup(null);
    setFormMode('Create');
  }, []);

  const hasGroups = Object.keys(groupItems).length > 0;

  return (
    <OverlaysProvider>
      <div className={css.wrapper}>
        <div
          className={css.filtersBarContainer}
          onClick={() => {
            setIsDropdownOpen(false);
          }}
        >
          <div className={css.content}>
            <div className={css.filterTagsContainer} {...getDropdownProps()}>
              <FilterGroupTags
                groupItems={groupItems}
                onFilterTagClick={onFilterTagClick}
                onRemoveFilter={onRemoveFilter}
                getSelectedItemProps={getSelectedItemProps}
              />
              <AddFilterButton handleClick={handleAddFilterClick} />
            </div>

            {hasGroups && (
              <button
                className={css.clearFiltersButton}
                type="button"
                onClick={() => {
                  setGroupItems({});
                  setCurrentGroup(null);
                  setFormMode('Create');
                  setIsDropdownOpen(false);
                  props.onChange([]);
                }}
              >
                Clear filters
              </button>
            )}
          </div>
        </div>
        <Overlay2 backdropClassName={css.backdrop} isOpen={isDropdownOpen} onClose={toggleOverlay}>
          <FlowFilterGroupForm
            config={filtersConfig}
            group={currentGroup ? groupItems[currentGroup] : undefined}
            onAddFilters={onAddFilters}
            onUpdateFilters={onUpdateFilters}
            formMode={formMode}
          />
        </Overlay2>
      </div>
    </OverlaysProvider>
  );
};
