import React from 'react';

import { observer } from 'mobx-react';

import { Card, Props as CardProps } from '~/components/Card';
import { CardType } from '~/components/ClusterMap/types';
import { LoadingOverlay } from '~/components/Misc/LoadingOverlay';

import css from './styles.scss';

interface Props extends CardProps {
  name: string;
  type?: CardType;
  loading?: boolean;
  verdictIcon?: string | null;
  outside?: boolean;
}

function getIcon(type?: CardType | null): string {
  switch (type) {
    case CardType.Cluster:
      return '/icons/misc/cluster_icon.svg';

    case CardType.World:
      return '/icons/logos/world-logo.svg';

    case CardType.Host:
      return '/icons/misc/cluster_icon.svg';

    default:
      return '/icons/misc/map-namespace-icon.svg';
  }
}

function getText(text: string, type?: CardType | null): string {
  switch (type) {
    case CardType.Cluster:
      return text || 'Unknown';

    case CardType.World:
      return 'World';

    case CardType.Host:
      return 'Host';

    default:
      return text || 'Unknown';
  }
}

export const ClusterMapCard = observer(function ClusterMapCard({
  name,
  loading,
  type,
  verdictIcon,
  outside,
  ...props
}: Props) {
  const content = (
    <>
      {verdictIcon && <img src={verdictIcon} className={css.connector} />}
      <img src={getIcon(type)} className={css.icon} />
      <span style={{ fontWeight: 'bold' }}>{getText(name, type)}</span>
    </>
  );

  return (
    <Card
      {...props}
      isUnsizedMode={false}
      className={outside ? css.outsideCardBackground : css.insideCardBackground}
    >
      {loading ? <LoadingOverlay height={'100%'} text={'Loading...'} /> : content}
    </Card>
  );
});
