import React, { useCallback, useRef } from 'react';

import { Classes, Icon, Intent, Popover, Spinner } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import classNames from 'classnames';

import UploadIcon from '~/assets/icons/arrow-up.svg';
import { Button } from '~/components/widgets/Button';
import { logger } from '~/utils/logger';

import css from './styles.scss';

export interface Props {
  className?: string;
  isUploading?: boolean;

  onLogs?: (logs: string) => void;
  onLogsError?: (err: DOMException | null) => void;
  onReadingStart?: () => void;
}

export const LogsUpload = function LogsUpload(props: Props) {
  const fileInput = useRef<HTMLInputElement>(null);

  const onFileChange = useCallback(() => {
    logger.log('file: ', fileInput.current);
    if (fileInput.current?.files == null) return;

    const file = fileInput.current.files[0];
    logger.log('file: ', file);
    if (file == null) return;

    const reader = new FileReader();
    reader.onload = () => {
      logger.log('onload: ', reader.result);
      props.onLogs?.(reader.result as string);
    };

    reader.onerror = () => {
      props.onLogsError?.(reader.error);
    };

    props.onReadingStart?.();
    reader.readAsText(file);
  }, [fileInput.current, props.onLogsError, props.onLogs, props.onReadingStart]);

  const helpPopoverContent = (
    <div className={css.helpContent}>
      <div>
        Export process events directly from hubble-enterprise running the following command:
      </div>

      <div className={css.cmd}>
        kubectl logs -n kube-system ds/hubble-enterprise -c export-stdout --since=1h &gt;
        ~/export.log
      </div>
    </div>
  );

  return (
    <div className={classNames(css.logsUpload, props.className)}>
      <div className={css.uploadHelp}>
        <Popover
          interactionKind="click"
          canEscapeKeyClose={true}
          inheritDarkTheme={true}
          modifiers={{
            arrow: { enabled: true },
            flip: { enabled: true },
          }}
          placement="right-start"
          popoverClassName={Classes.POPOVER_CONTENT_SIZING}
          content={helpPopoverContent}
          usePortal={true}
        >
          <div className={css.handle}>
            <Icon className={css.icon} intent={Intent.PRIMARY} icon={IconNames.HELP} />

            <span>How can I get logs?</span>
          </div>
        </Popover>
      </div>

      <div className={css.fileUpload}>
        <Button className={css.uploadButton} onClick={() => fileInput.current?.click()}>
          {!props.isUploading && (
            <>
              <span>Upload</span>
              <UploadIcon />
            </>
          )}

          {!!props.isUploading && (
            <>
              <span>Uploading</span>
              <Spinner size={18} intent={Intent.PRIMARY} />
            </>
          )}
        </Button>

        <input type="file" id="ps-file-upload" ref={fileInput} onChange={onFileChange}></input>
      </div>
    </div>
  );
};
