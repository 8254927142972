import { configure, makeAutoObservable, toJS } from 'mobx';

import { Application } from '~/domain/common';
import { FeatureFlags } from '~/domain/features';
import { Filters } from '~/domain/filtering';
import { HubbleFlow, HubbleLink, HubbleService } from '~/domain/hubble';
import { setupDebugProp, StateChange } from '~/domain/misc';
import { ServiceMap } from '~/domain/service-map';
import { TimescapeData } from '~/domain/timescape';
import { EventKind as FrameEvent, StoreFrame } from '~/store/frame';
import { Store as CimulatorStore, store as cimulatorStore } from '~/store/stores/cimulator';
import { logger } from '~/utils/logger';

import { AuthorizationStore } from './authz';
import { ClusterNamespaceStore } from './cluster-namespace';
import { ConnectionsMapStore } from './connections-map';
import ControlStore from './controls';
import { ProcessTreeStore } from './process-tree';
import ThemeStore from './theme';
import SettingsStore from './ui-settings';

configure({ enforceActions: 'observed' });

export type FlushOptions = {
  namespaces?: boolean;
  policies?: boolean;
  globalFrame?: boolean;
  preserveActiveCards?: boolean;
};

export type Props = {};

export class Store {
  public controls: ControlStore;

  public globalFrame: StoreFrame;

  public currentFrame: StoreFrame;

  public cimulator: CimulatorStore;

  public authz: AuthorizationStore;

  public uiSettings: SettingsStore;

  public clusterNamespaces: ClusterNamespaceStore;

  public processTree: ProcessTreeStore;

  public themes: ThemeStore;

  public connectionsMap: ConnectionsMapStore;

  constructor(currApp = Application.Dashboard) {
    makeAutoObservable(this, void 0, { autoBind: true });

    this.authz = new AuthorizationStore();
    this.uiSettings = new SettingsStore();
    this.controls = new ControlStore(currApp);
    this.clusterNamespaces = new ClusterNamespaceStore(false);
    this.themes = new ThemeStore();
    this.connectionsMap = new ConnectionsMapStore(this.controls);

    this.globalFrame = StoreFrame.emptyWithShared(this.controls, this.clusterNamespaces);
    this.currentFrame = StoreFrame.emptyWithShared(this.controls, this.clusterNamespaces);

    this.processTree = new ProcessTreeStore(this.uiSettings, this.clusterNamespaces);

    this.setupEventHandlers();
    this.setupDebugTools();

    this.cimulator = cimulatorStore;
    this.cimulator.setMainStore(this);
    this.cimulator.policy.setMainStore(this);
  }

  public setup({
    services,
    flows,
    links,
  }: {
    services: HubbleService[];
    flows: HubbleFlow[];
    links: HubbleLink[];
  }) {
    this.currentFrame.services.set(services);
    this.currentFrame.services.extractAccessPoints(links);
    this.currentFrame.interactions.addHubbleLinks(links);
    this.currentFrame.interactions.setHubbleFlows(flows, { sort: true });
  }

  public resetCurrentFrame(filters: Filters, flushOpts?: FlushOptions) {
    this.currentFrame.flush(flushOpts);
    this.currentFrame.applyFrame(this.globalFrame, filters);
  }

  public setFeatures(features: FeatureFlags) {
    this.uiSettings.setFeatures(features);
    this.clusterNamespaces.resetDataMode(features.timescape.enabled);
  }

  public applyServiceMapFromLogFile(serviceMap: ServiceMap) {
    if (!this.controls.app.isConnectionsMap) {
      logger.warn('service map extension is not implemented for non-ServiceMap mode');

      return;
    }

    this.extendServiceMap(serviceMap);
  }

  public extendServiceMap(serviceMap: ServiceMap) {
    return this.currentFrame.extendServiceMap(serviceMap);
  }

  public flush(opts?: FlushOptions) {
    if (opts?.globalFrame) logger.log(`flushing global frame`);
    this.controls.selectTableFlow(null);

    if (opts?.globalFrame) {
      this.globalFrame.flush(opts);
    }

    this.currentFrame.flush(opts);

    if (opts?.policies) this.cimulator.policy.clearAllPolicies();
    this.processTree.clear();

    this.connectionsMap.clear();
  }

  public upsertTimescapeData(data: TimescapeData) {
    this.currentFrame.addFlows(data.flows, false);
    this.currentFrame.addFlowSummaries(data.flowSummaries, false);
    this.currentFrame.saveFlowStats(data.countStats);
  }

  // D E B U G
  public setupDebugTools() {
    setupDebugProp({
      printMapData: () => {
        this.printMapData();
      },
      getFlowsFromTable: () => {
        return this.getFlowsFromTable();
      },
      dropMapData: () => {
        this.currentFrame.flush({ namespaces: false });
      },
      mobxToJs: (obj: any) => toJS(obj),
    });
  }

  private setupEventHandlers() {
    const wrongChanges = [StateChange.Unknown, StateChange.Deleted];

    this.currentFrame.on(FrameEvent.ServicesSet, svcs => {
      this.globalFrame.setServices(svcs);
    });

    this.currentFrame.on(FrameEvent.FlowsAdded, flows => {
      this.globalFrame.addFlows(flows);
    });

    this.currentFrame.on(FrameEvent.FlowSummariesAdded, fss => {
      this.globalFrame.addFlowSummaries(fss);
    });

    this.currentFrame.on(FrameEvent.LinksChanged, _links => {
      const links = _links.filter(l => !wrongChanges.includes(l.change));
      if (links.length === 0) return;

      this.globalFrame.applyServiceLinkChanges(links);
    });

    this.currentFrame.on(FrameEvent.ServiceChange, svcChanges => {
      const changes = svcChanges.filter(ch => !wrongChanges.includes(ch.change));
      if (changes.length === 0) return;

      this.globalFrame.applyServiceChanges(changes);
    });

    this.currentFrame.on(FrameEvent.PolicyChanged, (spec, change) => {
      if (wrongChanges.includes(change)) return;

      this.cimulator.policy.applyPolicyChange(spec, change);
    });
  }

  private printMapData() {
    const data = {
      services: this.currentFrame.services.cardsList.map(c => c.service),
      links: this.currentFrame.interactions.links.map(l => l.hubbleLink),
    };

    logger.log(JSON.stringify(data, null, 2));
  }

  private getFlowsFromTable() {
    return this.currentFrame.interactions.flows.map(f => toJS(f.parsableRef));
  }
}
