import React from 'react';

import classNames from 'classnames';

import { FilterEntry } from '~/domain/filtering';

import css from './FilterTagEntry.scss';
import { NotOperator } from '../NotOperator/NotOperator';
import { TagDirection } from '../TagDirection/TagDirection';

export const FilterTagEntry = function FilterTagEntry({ item }: { item: FilterEntry }) {
  const negativeOperator = item.negative ? <NotOperator /> : '';
  const tagDirection = item.direction ? <TagDirection direction={item.direction} /> : null;

  return (
    <div className="filter-group-tag-entry-container">
      {negativeOperator}
      {tagDirection}
      <span className={classNames(css.kind, item.kind)}>{item.kind}</span>
      <span className={css.separator}>=</span>
      <span className={classNames(css.query, item.query)}>{item.query}</span>
    </div>
  );
};
