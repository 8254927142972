/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter add_pb_suffix,eslint_disable,ts_nocheck,generate_dependencies,long_type_number,use_proto_field_name
// @generated from protobuf file "tetragon/dns.proto" (package "tetragon", syntax proto3)
// tslint:disable
// @ts-nocheck
//
// Copyright 2020 Authors of Hubble
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
//
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Pod } from "./tetragon_pb";
import { SockInfo } from "./fgs_pb";
import { Process } from "./tetragon_pb";
import { Int32Value } from "../google/protobuf/wrappers_pb";
/**
 * @generated from protobuf message tetragon.DnsInfo
 */
export interface DnsInfo {
    /**
     * @deprecated
     * @generated from protobuf field: repeated uint32 question_types = 1 [deprecated = true];
     */
    question_types: number[]; // deprecated in favor of query_types
    /**
     * @deprecated
     * @generated from protobuf field: repeated uint32 answer_types = 2 [deprecated = true];
     */
    answer_types: number[]; // deprecated in favor of response_types
    /**
     * @deprecated
     * @generated from protobuf field: int32 rcode = 3 [deprecated = true];
     */
    rcode: number; // deprecated in favor of return_code
    /**
     * @generated from protobuf field: repeated string names = 4;
     */
    names: string[];
    /**
     * @generated from protobuf field: repeated string ips = 5;
     */
    ips: string[];
    /**
     * @deprecated
     * @generated from protobuf field: string query = 6 [deprecated = true];
     */
    query: string; // unused field, deprecated in favor of names and query_types
    /**
     * @generated from protobuf field: bool response = 7;
     */
    response: boolean;
    /**
     * @generated from protobuf field: google.protobuf.Int32Value return_code = 8;
     */
    return_code?: Int32Value;
    /**
     * @generated from protobuf field: repeated tetragon.DnsType query_types = 9;
     */
    query_types: DnsType[];
    /**
     * @generated from protobuf field: repeated tetragon.DnsType response_types = 10;
     */
    response_types: DnsType[];
}
/**
 * @generated from protobuf message tetragon.ProcessDns
 */
export interface ProcessDns {
    /**
     * @generated from protobuf field: tetragon.Process process = 1;
     */
    process?: Process;
    /**
     * @generated from protobuf field: tetragon.SockInfo socket = 2;
     */
    socket?: SockInfo;
    /**
     * @generated from protobuf field: tetragon.DnsInfo dns = 3;
     */
    dns?: DnsInfo;
    /**
     * @deprecated
     * @generated from protobuf field: repeated string destination_names = 4 [deprecated = true];
     */
    destination_names: string[]; // deprecated in favor of socket.destination_names.
    /**
     * @deprecated
     * @generated from protobuf field: tetragon.Pod destination_pod = 5 [deprecated = true];
     */
    destination_pod?: Pod; // deprecated in favor of socket.destination_pod
    /**
     * @generated from protobuf field: tetragon.Process parent = 6;
     */
    parent?: Process;
}
/**
 * @generated from protobuf enum tetragon.DnsType
 */
export enum DnsType {
    /**
     * @generated from protobuf enum value: DNS_TYPE_UNDEF = 0;
     */
    DNS_TYPE_UNDEF = 0,
    /**
     * @generated from protobuf enum value: A = 1;
     */
    A = 1,
    /**
     * @generated from protobuf enum value: NS = 2;
     */
    NS = 2,
    /**
     * @generated from protobuf enum value: CNAME = 5;
     */
    CNAME = 5,
    /**
     * @generated from protobuf enum value: SOA = 6;
     */
    SOA = 6,
    /**
     * @generated from protobuf enum value: PTR = 12;
     */
    PTR = 12,
    /**
     * @generated from protobuf enum value: MX = 15;
     */
    MX = 15,
    /**
     * @generated from protobuf enum value: TXT = 16;
     */
    TXT = 16,
    /**
     * @generated from protobuf enum value: AAAA = 28;
     */
    AAAA = 28,
    /**
     * @generated from protobuf enum value: SRV = 33;
     */
    SRV = 33,
    /**
     * @generated from protobuf enum value: OPT = 41;
     */
    OPT = 41,
    /**
     * @generated from protobuf enum value: WKS = 11;
     */
    WKS = 11,
    /**
     * @generated from protobuf enum value: HINFO = 13;
     */
    HINFO = 13,
    /**
     * @generated from protobuf enum value: MINFO = 14;
     */
    MINFO = 14,
    /**
     * @generated from protobuf enum value: AXFR = 252;
     */
    AXFR = 252,
    /**
     * @generated from protobuf enum value: ALL = 255;
     */
    ALL = 255
}
// @generated message type with reflection information, may provide speed optimized methods
class DnsInfo$Type extends MessageType<DnsInfo> {
    constructor() {
        super("tetragon.DnsInfo", [
            { no: 1, name: "question_types", kind: "scalar", localName: "question_types", repeat: 1 /*RepeatType.PACKED*/, T: 13 /*ScalarType.UINT32*/ },
            { no: 2, name: "answer_types", kind: "scalar", localName: "answer_types", repeat: 1 /*RepeatType.PACKED*/, T: 13 /*ScalarType.UINT32*/ },
            { no: 3, name: "rcode", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "names", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "ips", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "query", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "response", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 8, name: "return_code", kind: "message", localName: "return_code", T: () => Int32Value },
            { no: 9, name: "query_types", kind: "enum", localName: "query_types", repeat: 1 /*RepeatType.PACKED*/, T: () => ["tetragon.DnsType", DnsType] },
            { no: 10, name: "response_types", kind: "enum", localName: "response_types", repeat: 1 /*RepeatType.PACKED*/, T: () => ["tetragon.DnsType", DnsType] }
        ]);
    }
    create(value?: PartialMessage<DnsInfo>): DnsInfo {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.question_types = [];
        message.answer_types = [];
        message.rcode = 0;
        message.names = [];
        message.ips = [];
        message.query = "";
        message.response = false;
        message.query_types = [];
        message.response_types = [];
        if (value !== undefined)
            reflectionMergePartial<DnsInfo>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DnsInfo): DnsInfo {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated uint32 question_types = 1 [deprecated = true];*/ 1:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.question_types.push(reader.uint32());
                    else
                        message.question_types.push(reader.uint32());
                    break;
                case /* repeated uint32 answer_types = 2 [deprecated = true];*/ 2:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.answer_types.push(reader.uint32());
                    else
                        message.answer_types.push(reader.uint32());
                    break;
                case /* int32 rcode = 3 [deprecated = true];*/ 3:
                    message.rcode = reader.int32();
                    break;
                case /* repeated string names */ 4:
                    message.names.push(reader.string());
                    break;
                case /* repeated string ips */ 5:
                    message.ips.push(reader.string());
                    break;
                case /* string query = 6 [deprecated = true];*/ 6:
                    message.query = reader.string();
                    break;
                case /* bool response */ 7:
                    message.response = reader.bool();
                    break;
                case /* google.protobuf.Int32Value return_code */ 8:
                    message.return_code = Int32Value.internalBinaryRead(reader, reader.uint32(), options, message.return_code);
                    break;
                case /* repeated tetragon.DnsType query_types */ 9:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.query_types.push(reader.int32());
                    else
                        message.query_types.push(reader.int32());
                    break;
                case /* repeated tetragon.DnsType response_types */ 10:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.response_types.push(reader.int32());
                    else
                        message.response_types.push(reader.int32());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DnsInfo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated uint32 question_types = 1 [deprecated = true]; */
        if (message.question_types.length) {
            writer.tag(1, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.question_types.length; i++)
                writer.uint32(message.question_types[i]);
            writer.join();
        }
        /* repeated uint32 answer_types = 2 [deprecated = true]; */
        if (message.answer_types.length) {
            writer.tag(2, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.answer_types.length; i++)
                writer.uint32(message.answer_types[i]);
            writer.join();
        }
        /* int32 rcode = 3 [deprecated = true]; */
        if (message.rcode !== 0)
            writer.tag(3, WireType.Varint).int32(message.rcode);
        /* repeated string names = 4; */
        for (let i = 0; i < message.names.length; i++)
            writer.tag(4, WireType.LengthDelimited).string(message.names[i]);
        /* repeated string ips = 5; */
        for (let i = 0; i < message.ips.length; i++)
            writer.tag(5, WireType.LengthDelimited).string(message.ips[i]);
        /* string query = 6 [deprecated = true]; */
        if (message.query !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.query);
        /* bool response = 7; */
        if (message.response !== false)
            writer.tag(7, WireType.Varint).bool(message.response);
        /* google.protobuf.Int32Value return_code = 8; */
        if (message.return_code)
            Int32Value.internalBinaryWrite(message.return_code, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* repeated tetragon.DnsType query_types = 9; */
        if (message.query_types.length) {
            writer.tag(9, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.query_types.length; i++)
                writer.int32(message.query_types[i]);
            writer.join();
        }
        /* repeated tetragon.DnsType response_types = 10; */
        if (message.response_types.length) {
            writer.tag(10, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.response_types.length; i++)
                writer.int32(message.response_types[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tetragon.DnsInfo
 */
export const DnsInfo = new DnsInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ProcessDns$Type extends MessageType<ProcessDns> {
    constructor() {
        super("tetragon.ProcessDns", [
            { no: 1, name: "process", kind: "message", T: () => Process },
            { no: 2, name: "socket", kind: "message", T: () => SockInfo },
            { no: 3, name: "dns", kind: "message", T: () => DnsInfo },
            { no: 4, name: "destination_names", kind: "scalar", localName: "destination_names", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "destination_pod", kind: "message", localName: "destination_pod", T: () => Pod },
            { no: 6, name: "parent", kind: "message", T: () => Process }
        ]);
    }
    create(value?: PartialMessage<ProcessDns>): ProcessDns {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.destination_names = [];
        if (value !== undefined)
            reflectionMergePartial<ProcessDns>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ProcessDns): ProcessDns {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* tetragon.Process process */ 1:
                    message.process = Process.internalBinaryRead(reader, reader.uint32(), options, message.process);
                    break;
                case /* tetragon.SockInfo socket */ 2:
                    message.socket = SockInfo.internalBinaryRead(reader, reader.uint32(), options, message.socket);
                    break;
                case /* tetragon.DnsInfo dns */ 3:
                    message.dns = DnsInfo.internalBinaryRead(reader, reader.uint32(), options, message.dns);
                    break;
                case /* repeated string destination_names = 4 [deprecated = true];*/ 4:
                    message.destination_names.push(reader.string());
                    break;
                case /* tetragon.Pod destination_pod = 5 [deprecated = true];*/ 5:
                    message.destination_pod = Pod.internalBinaryRead(reader, reader.uint32(), options, message.destination_pod);
                    break;
                case /* tetragon.Process parent */ 6:
                    message.parent = Process.internalBinaryRead(reader, reader.uint32(), options, message.parent);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ProcessDns, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* tetragon.Process process = 1; */
        if (message.process)
            Process.internalBinaryWrite(message.process, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.SockInfo socket = 2; */
        if (message.socket)
            SockInfo.internalBinaryWrite(message.socket, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.DnsInfo dns = 3; */
        if (message.dns)
            DnsInfo.internalBinaryWrite(message.dns, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* repeated string destination_names = 4 [deprecated = true]; */
        for (let i = 0; i < message.destination_names.length; i++)
            writer.tag(4, WireType.LengthDelimited).string(message.destination_names[i]);
        /* tetragon.Pod destination_pod = 5 [deprecated = true]; */
        if (message.destination_pod)
            Pod.internalBinaryWrite(message.destination_pod, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.Process parent = 6; */
        if (message.parent)
            Process.internalBinaryWrite(message.parent, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tetragon.ProcessDns
 */
export const ProcessDns = new ProcessDns$Type();
