import React, { ReactNode } from 'react';

import { FilterGroup } from '~/domain/filtering';

import { FilterGroupTag } from '../FilterGroupTag/FilterGroupTag';
import { OrOperator } from '../OrOperator/OrOperator';
import { DownshiftGetSelectedItemPropsOptions } from '../types';

export const FilterGroupTags = function FilterGroupTags({
  groupItems,
  onFilterTagClick,
  onRemoveFilter,
  getSelectedItemProps,
}: {
  groupItems: { [key: string]: FilterGroup };
  onFilterTagClick: (groupId: string) => void;
  onRemoveFilter: (groupId: string) => void;
  getSelectedItemProps: DownshiftGetSelectedItemPropsOptions;
}) {
  return Object.keys(groupItems)
    .map((groupId, currentGroupIndex) => (
      <FilterGroupTag
        key={groupId}
        groupId={groupId}
        currentGroupIndex={currentGroupIndex}
        onFilterTagClick={onFilterTagClick}
        onRemoveFilter={onRemoveFilter}
        currentGroupItem={groupItems[groupId]}
        getSelectedItemProps={getSelectedItemProps}
      />
    ))
    .reduce((prev: Array<ReactNode>, curr, index) => {
      if (index === 0) {
        return [curr];
      }

      return [prev, <OrOperator key={`or-${index}`} />, curr];
    }, []);
};
