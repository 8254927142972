import React from 'react';

import { observer } from 'mobx-react';

import { useStore } from '~/store/stores/cimulator';

import { PolicySuggestionCard } from './PolicySuggestionCard';
import css from './PolicySuggestions.scss';

export interface Props {
  height: number | string;
}

export const PolicySuggestions = observer(function PolicySuggestions(props: Props) {
  const store = useStore();

  const suggestions = store.suggestions.actualSuggestions;
  if (suggestions.length === 0) return null;

  return (
    <div className={css.wrapper} style={{ maxHeight: props.height }}>
      {suggestions.map(suggestion => (
        <PolicySuggestionCard key={suggestion.id} suggestion={suggestion} />
      ))}
    </div>
  );
});
