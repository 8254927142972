/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter add_pb_suffix,eslint_disable,ts_nocheck,generate_dependencies,long_type_number,use_proto_field_name
// @generated from protobuf file "tetragon/events.proto" (package "tetragon", syntax proto3)
// tslint:disable
// @ts-nocheck
//
// Copyright 2020 Authors of Hubble
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
//
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Timestamp } from "../google/protobuf/timestamp_pb";
import { Test } from "./tetragon_pb";
import { ProcessSandboxSyscall } from "./sandbox_pb";
import { ProcessRawsockClose } from "./fgs_pb";
import { ProcessRawsockCreate } from "./fgs_pb";
import { ProcessIcmp } from "./fgs_pb";
import { ProcessFileExec } from "./fgs_pb";
import { ProcessUdpSeqCheckError } from "./fgs_pb";
import { ProcessUprobe } from "./tetragon_pb";
import { ProcessNetworkWatermark } from "./fgs_pb";
import { ProcessLoader } from "./tetragon_pb";
import { ProcessIpError } from "./fgs_pb";
import { ProcessFile } from "./fgs_pb";
import { ProcessNetworkBurst } from "./fgs_pb";
import { ProcessDns } from "./dns_pb";
import { InterfaceStats } from "./fgs_pb";
import { ProcessHttp } from "./fgs_pb";
import { ProcessSockStats } from "./fgs_pb";
import { ProcessTracepoint } from "./tetragon_pb";
import { ProcessKprobe } from "./tetragon_pb";
import { ProcessAccept } from "./fgs_pb";
import { ProcessClose } from "./fgs_pb";
import { ProcessExit } from "./tetragon_pb";
import { Tls } from "./fgs_pb";
import { ProcessListen } from "./fgs_pb";
import { ProcessConnect } from "./fgs_pb";
import { ProcessExec } from "./tetragon_pb";
import { Duration } from "../google/protobuf/duration_pb";
import { FieldMask } from "../google/protobuf/field_mask_pb";
import { CapabilitiesType } from "./capabilities_pb";
import { SocketProtocol } from "./fgs_pb";
import { BoolValue } from "../google/protobuf/wrappers_pb";
/**
 * @generated from protobuf message tetragon.Filter
 */
export interface Filter {
    /**
     * @generated from protobuf field: repeated string binary_regex = 1;
     */
    binary_regex: string[];
    /**
     * @generated from protobuf field: repeated string namespace = 2;
     */
    namespace: string[];
    /**
     * @generated from protobuf field: google.protobuf.BoolValue health_check = 3;
     */
    health_check?: BoolValue;
    /**
     * @generated from protobuf field: repeated uint32 pid = 4;
     */
    pid: number[];
    /**
     * @generated from protobuf field: repeated uint32 pid_set = 5;
     */
    pid_set: number[];
    /**
     * @generated from protobuf field: repeated tetragon.EventType event_set = 6;
     */
    event_set: EventType[];
    /**
     * A series of regexes for filtering over pod name
     *
     * @generated from protobuf field: repeated string pod_regex = 7;
     */
    pod_regex: string[];
    /**
     * Filter by process.arguments field using RE2 regular expression syntax:
     * https://github.com/google/re2/wiki/Syntax
     *
     * @generated from protobuf field: repeated string arguments_regex = 8;
     */
    arguments_regex: string[];
    /**
     * Filter events by pod labels using Kubernetes label selector syntax:
     * https://kubernetes.io/docs/concepts/overview/working-with-objects/labels/#label-selectors
     * Note that this filter never matches events without the pod field (i.e.
     * host process events).
     *
     * @generated from protobuf field: repeated string labels = 9;
     */
    labels: string[];
    /**
     * Filter events by tracing policy names
     *
     * @generated from protobuf field: repeated string policy_names = 10;
     */
    policy_names: string[];
    /**
     * Filter events by Linux process capability
     *
     * @generated from protobuf field: tetragon.CapFilter capabilities = 11;
     */
    capabilities?: CapFilter;
    /**
     * Filter parent process' binary using RE2 regular expression syntax.
     *
     * @generated from protobuf field: repeated string parent_binary_regex = 12;
     */
    parent_binary_regex: string[];
    /**
     * Filter by source_ip field using an address range specified using CIDR notation.
     *
     * Example: {"event_set": ["PROCESS_ACCEPT"], "source_ip_cidr": ["127.0.0.0/16"]}
     *
     * @generated from protobuf field: repeated string source_ip_cidr = 1000;
     */
    source_ip_cidr: string[];
    /**
     * Filter by destination_ip field using an address range specified using CIDR notation.
     *
     * Example: {"event_set": ["PROCESS_CLOSE"], "destination_ip_cidr": ["8.8.0.0/16"]}
     *
     * @generated from protobuf field: repeated string destination_ip_cidr = 1001;
     */
    destination_ip_cidr: string[];
    /**
     * Filter by IP field using an address range specified using CIDR notation.
     * This includes source_ip, destination_ip, and ProcessListen's ip field.
     *
     * Example 1: {"event_set": ["PROCESS_ACCEPT"], ip_cidr": ["127.0.0.1", "8.8.0.0/16"]}
     * Example 2: {"event_set": ["PROCESS_LISTEN"], ip_cidr": ["127.0.0.0/16"]}
     *
     * @generated from protobuf field: repeated string ip_cidr = 1002;
     */
    ip_cidr: string[];
    /**
     * Filter by http.request.uri field using RE2 regular expression syntax:
     * https://github.com/google/re2/wiki/Syntax
     *
     * @generated from protobuf field: repeated string uri_regex = 1003;
     */
    uri_regex: string[];
    /**
     * Filter by tls.sni_name field using RE2 regular expression syntax:
     * https://github.com/google/re2/wiki/Syntax
     *
     * @generated from protobuf field: repeated string sni_regex = 1004;
     */
    sni_regex: string[];
    /**
     * Filter by destination_names field using RE2 regular expression syntax:
     * https://github.com/google/re2/wiki/Syntax
     *
     * @generated from protobuf field: repeated string destination_names_regex = 1005;
     */
    destination_names_regex: string[];
    /**
     * Filter by destination_pod.name field using RE2 regular expression syntax:
     * https://github.com/google/re2/wiki/Syntax
     *
     * @generated from protobuf field: repeated string destination_pod_regex = 1006;
     */
    destination_pod_regex: string[];
    /**
     * Filter by process_dns.dns.names field using RE2 regular expression syntax:
     * https://github.com/google/re2/wiki/Syntax
     *
     * @generated from protobuf field: repeated string dns_names_regex = 1007;
     */
    dns_names_regex: string[];
    /**
     * Filter by process_http.http.request.host field using RE2 regular expression syntax:
     * https://github.com/google/re2/wiki/Syntax
     *
     * @generated from protobuf field: repeated string host_regex = 1008;
     */
    host_regex: string[];
    /**
     * Filter by socket protocol. An event matches if its socket protocol
     * matches any of the protocols listed here. Note that events without a
     * protocol field will never match.
     *
     * @generated from protobuf field: repeated tetragon.SocketProtocol protocol = 1009;
     */
    protocol: SocketProtocol[];
}
/**
 * Filter over a set of Linux process capabilities. See `message Capabilities`
 * for more info.  WARNING: Multiple sets are ANDed. For example, if the
 * permitted filter matches, but the effective filter does not, the filter will
 * NOT match.
 *
 * @generated from protobuf message tetragon.CapFilter
 */
export interface CapFilter {
    /**
     * Filter over the set of permitted capabilities.
     *
     * @generated from protobuf field: tetragon.CapFilterSet permitted = 1;
     */
    permitted?: CapFilterSet;
    /**
     * Filter over the set of effective capabilities.
     *
     * @generated from protobuf field: tetragon.CapFilterSet effective = 2;
     */
    effective?: CapFilterSet;
    /**
     * Filter over the set of inheritable capabilities.
     *
     * @generated from protobuf field: tetragon.CapFilterSet inheritable = 3;
     */
    inheritable?: CapFilterSet;
}
/**
 * Capability set to filter over. NOTE: you may specify only ONE set here.
 *
 * @generated from protobuf message tetragon.CapFilterSet
 */
export interface CapFilterSet {
    /**
     * Match if the capability set contains any of the capabilities defined in this filter.
     *
     * @generated from protobuf field: repeated tetragon.CapabilitiesType any = 1;
     */
    any: CapabilitiesType[];
    /**
     * Match if the capability set contains all of the capabilities defined in this filter.
     *
     * @generated from protobuf field: repeated tetragon.CapabilitiesType all = 2;
     */
    all: CapabilitiesType[];
    /**
     * Match if the capability set exactly matches all of the capabilities defined in this filter.
     *
     * @generated from protobuf field: repeated tetragon.CapabilitiesType exactly = 3;
     */
    exactly: CapabilitiesType[];
    /**
     * Match if the capability set contains none of the capabilities defined in this filter.
     *
     * @generated from protobuf field: repeated tetragon.CapabilitiesType none = 4;
     */
    none: CapabilitiesType[];
}
/**
 * @generated from protobuf message tetragon.RedactionFilter
 */
export interface RedactionFilter {
    /**
     * Deprecated, do not use.
     *
     * @deprecated
     * @generated from protobuf field: repeated tetragon.Filter match = 1 [deprecated = true];
     */
    match: Filter[];
    /**
     * Regular expressions to use for redaction. Strings inside capture groups are redacted.
     *
     * @generated from protobuf field: repeated string redact = 2;
     */
    redact: string[];
    /**
     * Regular expression to match binary name. If supplied, redactions will only be applied to matching processes.
     *
     * @generated from protobuf field: repeated string binary_regex = 3;
     */
    binary_regex: string[];
}
/**
 * @generated from protobuf message tetragon.RateLimitInfo
 */
export interface RateLimitInfo {
    /**
     * @generated from protobuf field: uint64 number_of_dropped_process_events = 1;
     */
    number_of_dropped_process_events: number;
}
/**
 * @generated from protobuf message tetragon.FieldFilter
 */
export interface FieldFilter {
    /**
     * Event types to filter or undefined to filter over all event types.
     *
     * @generated from protobuf field: repeated tetragon.EventType event_set = 1;
     */
    event_set: EventType[];
    /**
     * Fields to include or exclude.
     *
     * @generated from protobuf field: google.protobuf.FieldMask fields = 2;
     */
    fields?: FieldMask;
    /**
     * Whether to include or exclude fields.
     *
     * @generated from protobuf field: tetragon.FieldFilterAction action = 3;
     */
    action: FieldFilterAction;
    /**
     * Whether or not the event set filter should be inverted.
     *
     * @generated from protobuf field: google.protobuf.BoolValue invert_event_set = 4;
     */
    invert_event_set?: BoolValue;
}
/**
 * AggregationOptions defines configuration options for aggregating events.
 *
 * @generated from protobuf message tetragon.AggregationOptions
 */
export interface AggregationOptions {
    /**
     * Aggregation window size. Defaults to 15 seconds if this field is not set.
     *
     * @generated from protobuf field: google.protobuf.Duration window_size = 1;
     */
    window_size?: Duration;
    /**
     * Size of the buffer for the aggregator to receive incoming events. If the
     * buffer becomes full, the aggregator will log a warning and start dropping
     * incoming events.
     *
     * @generated from protobuf field: uint64 channel_buffer_size = 2;
     */
    channel_buffer_size: number;
}
/**
 * @generated from protobuf message tetragon.GetEventsRequest
 */
export interface GetEventsRequest {
    /**
     * allow_list specifies a list of filters to apply to only return certain
     * events. If multiple filters are specified, at least one of them has to
     * match for an event to be included in the results.
     *
     * @generated from protobuf field: repeated tetragon.Filter allow_list = 1;
     */
    allow_list: Filter[];
    /**
     * deny_list specifies a list of filters to apply to exclude certain events
     * from the results. If multiple filters are specified, at least one of
     * them has to match for an event to be excluded.
     *
     * If both allow_list and deny_list are specified, the results contain the
     * set difference allow_list - deny_list.
     *
     * @generated from protobuf field: repeated tetragon.Filter deny_list = 2;
     */
    deny_list: Filter[];
    /**
     * aggregation_options configures aggregation options for this request.
     * If this field is not set, responses will not be aggregated.
     *
     * Note that currently only process_accept and process_connect events are
     * aggregated. Other events remain unaggregated.
     *
     * @generated from protobuf field: tetragon.AggregationOptions aggregation_options = 3;
     */
    aggregation_options?: AggregationOptions;
    /**
     * Fields to include or exclude for events in the GetEventsResponse. Omitting this
     * field implies that all fields will be included. Exclusion always takes precedence
     * over inclusion in the case of conflicts.
     *
     * @generated from protobuf field: repeated tetragon.FieldFilter field_filters = 4;
     */
    field_filters: FieldFilter[];
}
/**
 * AggregationInfo contains information about aggregation results.
 *
 * @generated from protobuf message tetragon.AggregationInfo
 */
export interface AggregationInfo {
    /**
     * Total count of events in this aggregation time window.
     *
     * @generated from protobuf field: uint64 count = 1;
     */
    count: number;
}
/**
 * @generated from protobuf message tetragon.GetEventsResponse
 */
export interface GetEventsResponse {
    /**
     * @generated from protobuf oneof: event
     */
    event: {
        oneofKind: "process_exec";
        /**
         * @generated from protobuf field: tetragon.ProcessExec process_exec = 1;
         */
        process_exec: ProcessExec;
    } | {
        oneofKind: "process_connect";
        /**
         * @generated from protobuf field: tetragon.ProcessConnect process_connect = 2;
         */
        process_connect: ProcessConnect;
    } | {
        oneofKind: "process_listen";
        /**
         * @generated from protobuf field: tetragon.ProcessListen process_listen = 3;
         */
        process_listen: ProcessListen;
    } | {
        oneofKind: "tls";
        /**
         * @generated from protobuf field: tetragon.Tls tls = 4;
         */
        tls: Tls;
    } | {
        oneofKind: "process_exit";
        /**
         * @generated from protobuf field: tetragon.ProcessExit process_exit = 5;
         */
        process_exit: ProcessExit;
    } | {
        oneofKind: "process_close";
        /**
         * @generated from protobuf field: tetragon.ProcessClose process_close = 6;
         */
        process_close: ProcessClose;
    } | {
        oneofKind: "process_accept";
        /**
         * @generated from protobuf field: tetragon.ProcessAccept process_accept = 7;
         */
        process_accept: ProcessAccept;
    } | {
        oneofKind: "process_kprobe";
        /**
         * @generated from protobuf field: tetragon.ProcessKprobe process_kprobe = 9;
         */
        process_kprobe: ProcessKprobe;
    } | {
        oneofKind: "process_tracepoint";
        /**
         * @generated from protobuf field: tetragon.ProcessTracepoint process_tracepoint = 10;
         */
        process_tracepoint: ProcessTracepoint;
    } | {
        oneofKind: "process_sock_stats";
        /**
         * @generated from protobuf field: tetragon.ProcessSockStats process_sock_stats = 11;
         */
        process_sock_stats: ProcessSockStats;
    } | {
        oneofKind: "process_http";
        /**
         * @generated from protobuf field: tetragon.ProcessHttp process_http = 12;
         */
        process_http: ProcessHttp;
    } | {
        oneofKind: "interface_stats";
        /**
         * @generated from protobuf field: tetragon.InterfaceStats interface_stats = 13;
         */
        interface_stats: InterfaceStats;
    } | {
        oneofKind: "process_dns";
        /**
         * @generated from protobuf field: tetragon.ProcessDns process_dns = 14;
         */
        process_dns: ProcessDns;
    } | {
        oneofKind: "process_network_burst";
        /**
         * @generated from protobuf field: tetragon.ProcessNetworkBurst process_network_burst = 15;
         */
        process_network_burst: ProcessNetworkBurst;
    } | {
        oneofKind: "process_file";
        /**
         * @generated from protobuf field: tetragon.ProcessFile process_file = 16;
         */
        process_file: ProcessFile;
    } | {
        oneofKind: "process_ip_error";
        /**
         * @generated from protobuf field: tetragon.ProcessIpError process_ip_error = 17;
         */
        process_ip_error: ProcessIpError;
    } | {
        oneofKind: "process_loader";
        /**
         * @generated from protobuf field: tetragon.ProcessLoader process_loader = 18;
         */
        process_loader: ProcessLoader;
    } | {
        oneofKind: "process_network_watermark";
        /**
         * @generated from protobuf field: tetragon.ProcessNetworkWatermark process_network_watermark = 19;
         */
        process_network_watermark: ProcessNetworkWatermark;
    } | {
        oneofKind: "process_uprobe";
        /**
         * @generated from protobuf field: tetragon.ProcessUprobe process_uprobe = 20;
         */
        process_uprobe: ProcessUprobe;
    } | {
        oneofKind: "process_udp_seq_check_error";
        /**
         * @generated from protobuf field: tetragon.ProcessUdpSeqCheckError process_udp_seq_check_error = 21;
         */
        process_udp_seq_check_error: ProcessUdpSeqCheckError;
    } | {
        oneofKind: "process_file_exec";
        /**
         * @generated from protobuf field: tetragon.ProcessFileExec process_file_exec = 22;
         */
        process_file_exec: ProcessFileExec;
    } | {
        oneofKind: "process_icmp";
        /**
         * @generated from protobuf field: tetragon.ProcessIcmp process_icmp = 23;
         */
        process_icmp: ProcessIcmp;
    } | {
        oneofKind: "process_rawsock_create";
        /**
         * @generated from protobuf field: tetragon.ProcessRawsockCreate process_rawsock_create = 24;
         */
        process_rawsock_create: ProcessRawsockCreate;
    } | {
        oneofKind: "process_rawsock_close";
        /**
         * @generated from protobuf field: tetragon.ProcessRawsockClose process_rawsock_close = 25;
         */
        process_rawsock_close: ProcessRawsockClose;
    } | {
        oneofKind: "process_sandbox_syscall";
        /**
         * @generated from protobuf field: tetragon.ProcessSandboxSyscall process_sandbox_syscall = 26;
         */
        process_sandbox_syscall: ProcessSandboxSyscall;
    } | {
        oneofKind: "test";
        /**
         * @generated from protobuf field: tetragon.Test test = 40000;
         */
        test: Test;
    } | {
        oneofKind: "rate_limit_info";
        /**
         * @generated from protobuf field: tetragon.RateLimitInfo rate_limit_info = 40001;
         */
        rate_limit_info: RateLimitInfo;
    } | {
        oneofKind: undefined;
    };
    /**
     * Name of the node where this event was observed.
     *
     * @generated from protobuf field: string node_name = 1000;
     */
    node_name: string;
    /**
     * Timestamp at which this event was observed.
     *
     * For an aggregated response, this field to set to the timestamp at which
     * the event was observed for the first time in a given aggregation time window.
     *
     * @generated from protobuf field: google.protobuf.Timestamp time = 1001;
     */
    time?: Timestamp;
    /**
     * aggregation_info contains information about aggregation results. This field
     * is set only for aggregated responses.
     *
     * @generated from protobuf field: tetragon.AggregationInfo aggregation_info = 1002;
     */
    aggregation_info?: AggregationInfo;
}
/**
 * Represents the type of a Tetragon event.
 *
 * NOTE: EventType constants must be in sync with the numbers used in the
 * GetEventsResponse event oneof.
 *
 * @generated from protobuf enum tetragon.EventType
 */
export enum EventType {
    /**
     * @generated from protobuf enum value: UNDEF = 0;
     */
    UNDEF = 0,
    /**
     * @generated from protobuf enum value: PROCESS_EXEC = 1;
     */
    PROCESS_EXEC = 1,
    /**
     * @generated from protobuf enum value: PROCESS_CONNECT = 2;
     */
    PROCESS_CONNECT = 2,
    /**
     * @generated from protobuf enum value: PROCESS_LISTEN = 3;
     */
    PROCESS_LISTEN = 3,
    /**
     * @generated from protobuf enum value: PROCESS_TLS = 4;
     */
    PROCESS_TLS = 4,
    /**
     * @generated from protobuf enum value: PROCESS_TLS = 4;
     */
    TLS = 4,
    /**
     * @generated from protobuf enum value: PROCESS_EXIT = 5;
     */
    PROCESS_EXIT = 5,
    /**
     * @generated from protobuf enum value: PROCESS_CLOSE = 6;
     */
    PROCESS_CLOSE = 6,
    /**
     * @generated from protobuf enum value: PROCESS_ACCEPT = 7;
     */
    PROCESS_ACCEPT = 7,
    /**
     * @generated from protobuf enum value: PROCESS_KPROBE = 9;
     */
    PROCESS_KPROBE = 9,
    /**
     * @generated from protobuf enum value: PROCESS_TRACEPOINT = 10;
     */
    PROCESS_TRACEPOINT = 10,
    /**
     * @generated from protobuf enum value: PROCESS_SOCKSTATS = 11;
     */
    PROCESS_SOCKSTATS = 11,
    /**
     * @generated from protobuf enum value: PROCESS_SOCKSTATS = 11;
     */
    PROCESS_SOCK_STATS = 11,
    /**
     * @generated from protobuf enum value: PROCESS_HTTP = 12;
     */
    PROCESS_HTTP = 12,
    /**
     * @generated from protobuf enum value: INTERFACE_STATS = 13;
     */
    INTERFACE_STATS = 13,
    /**
     * @generated from protobuf enum value: PROCESS_DNS = 14;
     */
    PROCESS_DNS = 14,
    /**
     * @generated from protobuf enum value: PROCESS_NETWORK_BURST = 15;
     */
    PROCESS_NETWORK_BURST = 15,
    /**
     * @generated from protobuf enum value: PROCESS_FILE = 16;
     */
    PROCESS_FILE = 16,
    /**
     * @generated from protobuf enum value: PROCESS_IP_ERROR = 17;
     */
    PROCESS_IP_ERROR = 17,
    /**
     * @generated from protobuf enum value: PROCESS_LOADER = 18;
     */
    PROCESS_LOADER = 18,
    /**
     * @generated from protobuf enum value: PROCESS_NETWORK_WATERMARK = 19;
     */
    PROCESS_NETWORK_WATERMARK = 19,
    /**
     * @generated from protobuf enum value: PROCESS_UPROBE = 20;
     */
    PROCESS_UPROBE = 20,
    /**
     * @generated from protobuf enum value: PROCESS_UDP_SEQ_CHECK_ERROR = 21;
     */
    PROCESS_UDP_SEQ_CHECK_ERROR = 21,
    /**
     * @generated from protobuf enum value: PROCESS_FILE_EXEC = 22;
     */
    PROCESS_FILE_EXEC = 22,
    /**
     * @generated from protobuf enum value: PROCESS_ICMP = 23;
     */
    PROCESS_ICMP = 23,
    /**
     * @generated from protobuf enum value: PROCESS_RAWSOCK_CREATE = 24;
     */
    PROCESS_RAWSOCK_CREATE = 24,
    /**
     * @generated from protobuf enum value: PROCESS_RAWSOCK_CLOSE = 25;
     */
    PROCESS_RAWSOCK_CLOSE = 25,
    /**
     * @generated from protobuf enum value: PROCESS_SANDBOX_SYSCALL = 26;
     */
    PROCESS_SANDBOX_SYSCALL = 26,
    /**
     * @generated from protobuf enum value: TEST = 40000;
     */
    TEST = 40000,
    /**
     * @generated from protobuf enum value: RATE_LIMIT_INFO = 40001;
     */
    RATE_LIMIT_INFO = 40001
}
/**
 * Determins the behaviour of a field filter
 *
 * @generated from protobuf enum tetragon.FieldFilterAction
 */
export enum FieldFilterAction {
    /**
     * @generated from protobuf enum value: INCLUDE = 0;
     */
    INCLUDE = 0,
    /**
     * @generated from protobuf enum value: EXCLUDE = 1;
     */
    EXCLUDE = 1
}
// @generated message type with reflection information, may provide speed optimized methods
class Filter$Type extends MessageType<Filter> {
    constructor() {
        super("tetragon.Filter", [
            { no: 1, name: "binary_regex", kind: "scalar", localName: "binary_regex", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "namespace", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "health_check", kind: "message", localName: "health_check", T: () => BoolValue },
            { no: 4, name: "pid", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 13 /*ScalarType.UINT32*/ },
            { no: 5, name: "pid_set", kind: "scalar", localName: "pid_set", repeat: 1 /*RepeatType.PACKED*/, T: 13 /*ScalarType.UINT32*/ },
            { no: 6, name: "event_set", kind: "enum", localName: "event_set", repeat: 1 /*RepeatType.PACKED*/, T: () => ["tetragon.EventType", EventType] },
            { no: 7, name: "pod_regex", kind: "scalar", localName: "pod_regex", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "arguments_regex", kind: "scalar", localName: "arguments_regex", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "labels", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "policy_names", kind: "scalar", localName: "policy_names", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "capabilities", kind: "message", T: () => CapFilter },
            { no: 12, name: "parent_binary_regex", kind: "scalar", localName: "parent_binary_regex", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 1000, name: "source_ip_cidr", kind: "scalar", localName: "source_ip_cidr", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 1001, name: "destination_ip_cidr", kind: "scalar", localName: "destination_ip_cidr", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 1002, name: "ip_cidr", kind: "scalar", localName: "ip_cidr", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 1003, name: "uri_regex", kind: "scalar", localName: "uri_regex", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 1004, name: "sni_regex", kind: "scalar", localName: "sni_regex", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 1005, name: "destination_names_regex", kind: "scalar", localName: "destination_names_regex", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 1006, name: "destination_pod_regex", kind: "scalar", localName: "destination_pod_regex", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 1007, name: "dns_names_regex", kind: "scalar", localName: "dns_names_regex", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 1008, name: "host_regex", kind: "scalar", localName: "host_regex", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 1009, name: "protocol", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["tetragon.SocketProtocol", SocketProtocol] }
        ]);
    }
    create(value?: PartialMessage<Filter>): Filter {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.binary_regex = [];
        message.namespace = [];
        message.pid = [];
        message.pid_set = [];
        message.event_set = [];
        message.pod_regex = [];
        message.arguments_regex = [];
        message.labels = [];
        message.policy_names = [];
        message.parent_binary_regex = [];
        message.source_ip_cidr = [];
        message.destination_ip_cidr = [];
        message.ip_cidr = [];
        message.uri_regex = [];
        message.sni_regex = [];
        message.destination_names_regex = [];
        message.destination_pod_regex = [];
        message.dns_names_regex = [];
        message.host_regex = [];
        message.protocol = [];
        if (value !== undefined)
            reflectionMergePartial<Filter>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Filter): Filter {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string binary_regex */ 1:
                    message.binary_regex.push(reader.string());
                    break;
                case /* repeated string namespace */ 2:
                    message.namespace.push(reader.string());
                    break;
                case /* google.protobuf.BoolValue health_check */ 3:
                    message.health_check = BoolValue.internalBinaryRead(reader, reader.uint32(), options, message.health_check);
                    break;
                case /* repeated uint32 pid */ 4:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.pid.push(reader.uint32());
                    else
                        message.pid.push(reader.uint32());
                    break;
                case /* repeated uint32 pid_set */ 5:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.pid_set.push(reader.uint32());
                    else
                        message.pid_set.push(reader.uint32());
                    break;
                case /* repeated tetragon.EventType event_set */ 6:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.event_set.push(reader.int32());
                    else
                        message.event_set.push(reader.int32());
                    break;
                case /* repeated string pod_regex */ 7:
                    message.pod_regex.push(reader.string());
                    break;
                case /* repeated string arguments_regex */ 8:
                    message.arguments_regex.push(reader.string());
                    break;
                case /* repeated string labels */ 9:
                    message.labels.push(reader.string());
                    break;
                case /* repeated string policy_names */ 10:
                    message.policy_names.push(reader.string());
                    break;
                case /* tetragon.CapFilter capabilities */ 11:
                    message.capabilities = CapFilter.internalBinaryRead(reader, reader.uint32(), options, message.capabilities);
                    break;
                case /* repeated string parent_binary_regex */ 12:
                    message.parent_binary_regex.push(reader.string());
                    break;
                case /* repeated string source_ip_cidr */ 1000:
                    message.source_ip_cidr.push(reader.string());
                    break;
                case /* repeated string destination_ip_cidr */ 1001:
                    message.destination_ip_cidr.push(reader.string());
                    break;
                case /* repeated string ip_cidr */ 1002:
                    message.ip_cidr.push(reader.string());
                    break;
                case /* repeated string uri_regex */ 1003:
                    message.uri_regex.push(reader.string());
                    break;
                case /* repeated string sni_regex */ 1004:
                    message.sni_regex.push(reader.string());
                    break;
                case /* repeated string destination_names_regex */ 1005:
                    message.destination_names_regex.push(reader.string());
                    break;
                case /* repeated string destination_pod_regex */ 1006:
                    message.destination_pod_regex.push(reader.string());
                    break;
                case /* repeated string dns_names_regex */ 1007:
                    message.dns_names_regex.push(reader.string());
                    break;
                case /* repeated string host_regex */ 1008:
                    message.host_regex.push(reader.string());
                    break;
                case /* repeated tetragon.SocketProtocol protocol */ 1009:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.protocol.push(reader.int32());
                    else
                        message.protocol.push(reader.int32());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Filter, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string binary_regex = 1; */
        for (let i = 0; i < message.binary_regex.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.binary_regex[i]);
        /* repeated string namespace = 2; */
        for (let i = 0; i < message.namespace.length; i++)
            writer.tag(2, WireType.LengthDelimited).string(message.namespace[i]);
        /* google.protobuf.BoolValue health_check = 3; */
        if (message.health_check)
            BoolValue.internalBinaryWrite(message.health_check, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* repeated uint32 pid = 4; */
        if (message.pid.length) {
            writer.tag(4, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.pid.length; i++)
                writer.uint32(message.pid[i]);
            writer.join();
        }
        /* repeated uint32 pid_set = 5; */
        if (message.pid_set.length) {
            writer.tag(5, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.pid_set.length; i++)
                writer.uint32(message.pid_set[i]);
            writer.join();
        }
        /* repeated tetragon.EventType event_set = 6; */
        if (message.event_set.length) {
            writer.tag(6, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.event_set.length; i++)
                writer.int32(message.event_set[i]);
            writer.join();
        }
        /* repeated string pod_regex = 7; */
        for (let i = 0; i < message.pod_regex.length; i++)
            writer.tag(7, WireType.LengthDelimited).string(message.pod_regex[i]);
        /* repeated string arguments_regex = 8; */
        for (let i = 0; i < message.arguments_regex.length; i++)
            writer.tag(8, WireType.LengthDelimited).string(message.arguments_regex[i]);
        /* repeated string labels = 9; */
        for (let i = 0; i < message.labels.length; i++)
            writer.tag(9, WireType.LengthDelimited).string(message.labels[i]);
        /* repeated string policy_names = 10; */
        for (let i = 0; i < message.policy_names.length; i++)
            writer.tag(10, WireType.LengthDelimited).string(message.policy_names[i]);
        /* tetragon.CapFilter capabilities = 11; */
        if (message.capabilities)
            CapFilter.internalBinaryWrite(message.capabilities, writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        /* repeated string parent_binary_regex = 12; */
        for (let i = 0; i < message.parent_binary_regex.length; i++)
            writer.tag(12, WireType.LengthDelimited).string(message.parent_binary_regex[i]);
        /* repeated string source_ip_cidr = 1000; */
        for (let i = 0; i < message.source_ip_cidr.length; i++)
            writer.tag(1000, WireType.LengthDelimited).string(message.source_ip_cidr[i]);
        /* repeated string destination_ip_cidr = 1001; */
        for (let i = 0; i < message.destination_ip_cidr.length; i++)
            writer.tag(1001, WireType.LengthDelimited).string(message.destination_ip_cidr[i]);
        /* repeated string ip_cidr = 1002; */
        for (let i = 0; i < message.ip_cidr.length; i++)
            writer.tag(1002, WireType.LengthDelimited).string(message.ip_cidr[i]);
        /* repeated string uri_regex = 1003; */
        for (let i = 0; i < message.uri_regex.length; i++)
            writer.tag(1003, WireType.LengthDelimited).string(message.uri_regex[i]);
        /* repeated string sni_regex = 1004; */
        for (let i = 0; i < message.sni_regex.length; i++)
            writer.tag(1004, WireType.LengthDelimited).string(message.sni_regex[i]);
        /* repeated string destination_names_regex = 1005; */
        for (let i = 0; i < message.destination_names_regex.length; i++)
            writer.tag(1005, WireType.LengthDelimited).string(message.destination_names_regex[i]);
        /* repeated string destination_pod_regex = 1006; */
        for (let i = 0; i < message.destination_pod_regex.length; i++)
            writer.tag(1006, WireType.LengthDelimited).string(message.destination_pod_regex[i]);
        /* repeated string dns_names_regex = 1007; */
        for (let i = 0; i < message.dns_names_regex.length; i++)
            writer.tag(1007, WireType.LengthDelimited).string(message.dns_names_regex[i]);
        /* repeated string host_regex = 1008; */
        for (let i = 0; i < message.host_regex.length; i++)
            writer.tag(1008, WireType.LengthDelimited).string(message.host_regex[i]);
        /* repeated tetragon.SocketProtocol protocol = 1009; */
        if (message.protocol.length) {
            writer.tag(1009, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.protocol.length; i++)
                writer.int32(message.protocol[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tetragon.Filter
 */
export const Filter = new Filter$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CapFilter$Type extends MessageType<CapFilter> {
    constructor() {
        super("tetragon.CapFilter", [
            { no: 1, name: "permitted", kind: "message", T: () => CapFilterSet },
            { no: 2, name: "effective", kind: "message", T: () => CapFilterSet },
            { no: 3, name: "inheritable", kind: "message", T: () => CapFilterSet }
        ]);
    }
    create(value?: PartialMessage<CapFilter>): CapFilter {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<CapFilter>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CapFilter): CapFilter {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* tetragon.CapFilterSet permitted */ 1:
                    message.permitted = CapFilterSet.internalBinaryRead(reader, reader.uint32(), options, message.permitted);
                    break;
                case /* tetragon.CapFilterSet effective */ 2:
                    message.effective = CapFilterSet.internalBinaryRead(reader, reader.uint32(), options, message.effective);
                    break;
                case /* tetragon.CapFilterSet inheritable */ 3:
                    message.inheritable = CapFilterSet.internalBinaryRead(reader, reader.uint32(), options, message.inheritable);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CapFilter, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* tetragon.CapFilterSet permitted = 1; */
        if (message.permitted)
            CapFilterSet.internalBinaryWrite(message.permitted, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.CapFilterSet effective = 2; */
        if (message.effective)
            CapFilterSet.internalBinaryWrite(message.effective, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.CapFilterSet inheritable = 3; */
        if (message.inheritable)
            CapFilterSet.internalBinaryWrite(message.inheritable, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tetragon.CapFilter
 */
export const CapFilter = new CapFilter$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CapFilterSet$Type extends MessageType<CapFilterSet> {
    constructor() {
        super("tetragon.CapFilterSet", [
            { no: 1, name: "any", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["tetragon.CapabilitiesType", CapabilitiesType] },
            { no: 2, name: "all", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["tetragon.CapabilitiesType", CapabilitiesType] },
            { no: 3, name: "exactly", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["tetragon.CapabilitiesType", CapabilitiesType] },
            { no: 4, name: "none", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["tetragon.CapabilitiesType", CapabilitiesType] }
        ]);
    }
    create(value?: PartialMessage<CapFilterSet>): CapFilterSet {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.any = [];
        message.all = [];
        message.exactly = [];
        message.none = [];
        if (value !== undefined)
            reflectionMergePartial<CapFilterSet>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CapFilterSet): CapFilterSet {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated tetragon.CapabilitiesType any */ 1:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.any.push(reader.int32());
                    else
                        message.any.push(reader.int32());
                    break;
                case /* repeated tetragon.CapabilitiesType all */ 2:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.all.push(reader.int32());
                    else
                        message.all.push(reader.int32());
                    break;
                case /* repeated tetragon.CapabilitiesType exactly */ 3:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.exactly.push(reader.int32());
                    else
                        message.exactly.push(reader.int32());
                    break;
                case /* repeated tetragon.CapabilitiesType none */ 4:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.none.push(reader.int32());
                    else
                        message.none.push(reader.int32());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CapFilterSet, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated tetragon.CapabilitiesType any = 1; */
        if (message.any.length) {
            writer.tag(1, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.any.length; i++)
                writer.int32(message.any[i]);
            writer.join();
        }
        /* repeated tetragon.CapabilitiesType all = 2; */
        if (message.all.length) {
            writer.tag(2, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.all.length; i++)
                writer.int32(message.all[i]);
            writer.join();
        }
        /* repeated tetragon.CapabilitiesType exactly = 3; */
        if (message.exactly.length) {
            writer.tag(3, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.exactly.length; i++)
                writer.int32(message.exactly[i]);
            writer.join();
        }
        /* repeated tetragon.CapabilitiesType none = 4; */
        if (message.none.length) {
            writer.tag(4, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.none.length; i++)
                writer.int32(message.none[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tetragon.CapFilterSet
 */
export const CapFilterSet = new CapFilterSet$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RedactionFilter$Type extends MessageType<RedactionFilter> {
    constructor() {
        super("tetragon.RedactionFilter", [
            { no: 1, name: "match", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Filter },
            { no: 2, name: "redact", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "binary_regex", kind: "scalar", localName: "binary_regex", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<RedactionFilter>): RedactionFilter {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.match = [];
        message.redact = [];
        message.binary_regex = [];
        if (value !== undefined)
            reflectionMergePartial<RedactionFilter>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RedactionFilter): RedactionFilter {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated tetragon.Filter match = 1 [deprecated = true];*/ 1:
                    message.match.push(Filter.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated string redact */ 2:
                    message.redact.push(reader.string());
                    break;
                case /* repeated string binary_regex */ 3:
                    message.binary_regex.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RedactionFilter, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated tetragon.Filter match = 1 [deprecated = true]; */
        for (let i = 0; i < message.match.length; i++)
            Filter.internalBinaryWrite(message.match[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* repeated string redact = 2; */
        for (let i = 0; i < message.redact.length; i++)
            writer.tag(2, WireType.LengthDelimited).string(message.redact[i]);
        /* repeated string binary_regex = 3; */
        for (let i = 0; i < message.binary_regex.length; i++)
            writer.tag(3, WireType.LengthDelimited).string(message.binary_regex[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tetragon.RedactionFilter
 */
export const RedactionFilter = new RedactionFilter$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RateLimitInfo$Type extends MessageType<RateLimitInfo> {
    constructor() {
        super("tetragon.RateLimitInfo", [
            { no: 1, name: "number_of_dropped_process_events", kind: "scalar", localName: "number_of_dropped_process_events", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<RateLimitInfo>): RateLimitInfo {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.number_of_dropped_process_events = 0;
        if (value !== undefined)
            reflectionMergePartial<RateLimitInfo>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RateLimitInfo): RateLimitInfo {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint64 number_of_dropped_process_events */ 1:
                    message.number_of_dropped_process_events = reader.uint64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RateLimitInfo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint64 number_of_dropped_process_events = 1; */
        if (message.number_of_dropped_process_events !== 0)
            writer.tag(1, WireType.Varint).uint64(message.number_of_dropped_process_events);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tetragon.RateLimitInfo
 */
export const RateLimitInfo = new RateLimitInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FieldFilter$Type extends MessageType<FieldFilter> {
    constructor() {
        super("tetragon.FieldFilter", [
            { no: 1, name: "event_set", kind: "enum", localName: "event_set", repeat: 1 /*RepeatType.PACKED*/, T: () => ["tetragon.EventType", EventType] },
            { no: 2, name: "fields", kind: "message", T: () => FieldMask },
            { no: 3, name: "action", kind: "enum", T: () => ["tetragon.FieldFilterAction", FieldFilterAction] },
            { no: 4, name: "invert_event_set", kind: "message", localName: "invert_event_set", T: () => BoolValue }
        ]);
    }
    create(value?: PartialMessage<FieldFilter>): FieldFilter {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.event_set = [];
        message.action = 0;
        if (value !== undefined)
            reflectionMergePartial<FieldFilter>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FieldFilter): FieldFilter {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated tetragon.EventType event_set */ 1:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.event_set.push(reader.int32());
                    else
                        message.event_set.push(reader.int32());
                    break;
                case /* google.protobuf.FieldMask fields */ 2:
                    message.fields = FieldMask.internalBinaryRead(reader, reader.uint32(), options, message.fields);
                    break;
                case /* tetragon.FieldFilterAction action */ 3:
                    message.action = reader.int32();
                    break;
                case /* google.protobuf.BoolValue invert_event_set */ 4:
                    message.invert_event_set = BoolValue.internalBinaryRead(reader, reader.uint32(), options, message.invert_event_set);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FieldFilter, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated tetragon.EventType event_set = 1; */
        if (message.event_set.length) {
            writer.tag(1, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.event_set.length; i++)
                writer.int32(message.event_set[i]);
            writer.join();
        }
        /* google.protobuf.FieldMask fields = 2; */
        if (message.fields)
            FieldMask.internalBinaryWrite(message.fields, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.FieldFilterAction action = 3; */
        if (message.action !== 0)
            writer.tag(3, WireType.Varint).int32(message.action);
        /* google.protobuf.BoolValue invert_event_set = 4; */
        if (message.invert_event_set)
            BoolValue.internalBinaryWrite(message.invert_event_set, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tetragon.FieldFilter
 */
export const FieldFilter = new FieldFilter$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AggregationOptions$Type extends MessageType<AggregationOptions> {
    constructor() {
        super("tetragon.AggregationOptions", [
            { no: 1, name: "window_size", kind: "message", localName: "window_size", T: () => Duration },
            { no: 2, name: "channel_buffer_size", kind: "scalar", localName: "channel_buffer_size", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<AggregationOptions>): AggregationOptions {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.channel_buffer_size = 0;
        if (value !== undefined)
            reflectionMergePartial<AggregationOptions>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AggregationOptions): AggregationOptions {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* google.protobuf.Duration window_size */ 1:
                    message.window_size = Duration.internalBinaryRead(reader, reader.uint32(), options, message.window_size);
                    break;
                case /* uint64 channel_buffer_size */ 2:
                    message.channel_buffer_size = reader.uint64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AggregationOptions, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* google.protobuf.Duration window_size = 1; */
        if (message.window_size)
            Duration.internalBinaryWrite(message.window_size, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* uint64 channel_buffer_size = 2; */
        if (message.channel_buffer_size !== 0)
            writer.tag(2, WireType.Varint).uint64(message.channel_buffer_size);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tetragon.AggregationOptions
 */
export const AggregationOptions = new AggregationOptions$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetEventsRequest$Type extends MessageType<GetEventsRequest> {
    constructor() {
        super("tetragon.GetEventsRequest", [
            { no: 1, name: "allow_list", kind: "message", localName: "allow_list", repeat: 1 /*RepeatType.PACKED*/, T: () => Filter },
            { no: 2, name: "deny_list", kind: "message", localName: "deny_list", repeat: 1 /*RepeatType.PACKED*/, T: () => Filter },
            { no: 3, name: "aggregation_options", kind: "message", localName: "aggregation_options", T: () => AggregationOptions },
            { no: 4, name: "field_filters", kind: "message", localName: "field_filters", repeat: 1 /*RepeatType.PACKED*/, T: () => FieldFilter }
        ]);
    }
    create(value?: PartialMessage<GetEventsRequest>): GetEventsRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.allow_list = [];
        message.deny_list = [];
        message.field_filters = [];
        if (value !== undefined)
            reflectionMergePartial<GetEventsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetEventsRequest): GetEventsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated tetragon.Filter allow_list */ 1:
                    message.allow_list.push(Filter.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated tetragon.Filter deny_list */ 2:
                    message.deny_list.push(Filter.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* tetragon.AggregationOptions aggregation_options */ 3:
                    message.aggregation_options = AggregationOptions.internalBinaryRead(reader, reader.uint32(), options, message.aggregation_options);
                    break;
                case /* repeated tetragon.FieldFilter field_filters */ 4:
                    message.field_filters.push(FieldFilter.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetEventsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated tetragon.Filter allow_list = 1; */
        for (let i = 0; i < message.allow_list.length; i++)
            Filter.internalBinaryWrite(message.allow_list[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* repeated tetragon.Filter deny_list = 2; */
        for (let i = 0; i < message.deny_list.length; i++)
            Filter.internalBinaryWrite(message.deny_list[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.AggregationOptions aggregation_options = 3; */
        if (message.aggregation_options)
            AggregationOptions.internalBinaryWrite(message.aggregation_options, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* repeated tetragon.FieldFilter field_filters = 4; */
        for (let i = 0; i < message.field_filters.length; i++)
            FieldFilter.internalBinaryWrite(message.field_filters[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tetragon.GetEventsRequest
 */
export const GetEventsRequest = new GetEventsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AggregationInfo$Type extends MessageType<AggregationInfo> {
    constructor() {
        super("tetragon.AggregationInfo", [
            { no: 1, name: "count", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<AggregationInfo>): AggregationInfo {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.count = 0;
        if (value !== undefined)
            reflectionMergePartial<AggregationInfo>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AggregationInfo): AggregationInfo {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint64 count */ 1:
                    message.count = reader.uint64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AggregationInfo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint64 count = 1; */
        if (message.count !== 0)
            writer.tag(1, WireType.Varint).uint64(message.count);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tetragon.AggregationInfo
 */
export const AggregationInfo = new AggregationInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetEventsResponse$Type extends MessageType<GetEventsResponse> {
    constructor() {
        super("tetragon.GetEventsResponse", [
            { no: 1, name: "process_exec", kind: "message", localName: "process_exec", oneof: "event", T: () => ProcessExec },
            { no: 2, name: "process_connect", kind: "message", localName: "process_connect", oneof: "event", T: () => ProcessConnect },
            { no: 3, name: "process_listen", kind: "message", localName: "process_listen", oneof: "event", T: () => ProcessListen },
            { no: 4, name: "tls", kind: "message", oneof: "event", T: () => Tls },
            { no: 5, name: "process_exit", kind: "message", localName: "process_exit", oneof: "event", T: () => ProcessExit },
            { no: 6, name: "process_close", kind: "message", localName: "process_close", oneof: "event", T: () => ProcessClose },
            { no: 7, name: "process_accept", kind: "message", localName: "process_accept", oneof: "event", T: () => ProcessAccept },
            { no: 9, name: "process_kprobe", kind: "message", localName: "process_kprobe", oneof: "event", T: () => ProcessKprobe },
            { no: 10, name: "process_tracepoint", kind: "message", localName: "process_tracepoint", oneof: "event", T: () => ProcessTracepoint },
            { no: 11, name: "process_sock_stats", kind: "message", localName: "process_sock_stats", oneof: "event", T: () => ProcessSockStats },
            { no: 12, name: "process_http", kind: "message", localName: "process_http", oneof: "event", T: () => ProcessHttp },
            { no: 13, name: "interface_stats", kind: "message", localName: "interface_stats", oneof: "event", T: () => InterfaceStats },
            { no: 14, name: "process_dns", kind: "message", localName: "process_dns", oneof: "event", T: () => ProcessDns },
            { no: 15, name: "process_network_burst", kind: "message", localName: "process_network_burst", oneof: "event", T: () => ProcessNetworkBurst },
            { no: 16, name: "process_file", kind: "message", localName: "process_file", oneof: "event", T: () => ProcessFile },
            { no: 17, name: "process_ip_error", kind: "message", localName: "process_ip_error", oneof: "event", T: () => ProcessIpError },
            { no: 18, name: "process_loader", kind: "message", localName: "process_loader", oneof: "event", T: () => ProcessLoader },
            { no: 19, name: "process_network_watermark", kind: "message", localName: "process_network_watermark", oneof: "event", T: () => ProcessNetworkWatermark },
            { no: 20, name: "process_uprobe", kind: "message", localName: "process_uprobe", oneof: "event", T: () => ProcessUprobe },
            { no: 21, name: "process_udp_seq_check_error", kind: "message", localName: "process_udp_seq_check_error", oneof: "event", T: () => ProcessUdpSeqCheckError },
            { no: 22, name: "process_file_exec", kind: "message", localName: "process_file_exec", oneof: "event", T: () => ProcessFileExec },
            { no: 23, name: "process_icmp", kind: "message", localName: "process_icmp", oneof: "event", T: () => ProcessIcmp },
            { no: 24, name: "process_rawsock_create", kind: "message", localName: "process_rawsock_create", oneof: "event", T: () => ProcessRawsockCreate },
            { no: 25, name: "process_rawsock_close", kind: "message", localName: "process_rawsock_close", oneof: "event", T: () => ProcessRawsockClose },
            { no: 26, name: "process_sandbox_syscall", kind: "message", localName: "process_sandbox_syscall", oneof: "event", T: () => ProcessSandboxSyscall },
            { no: 40000, name: "test", kind: "message", oneof: "event", T: () => Test },
            { no: 40001, name: "rate_limit_info", kind: "message", localName: "rate_limit_info", oneof: "event", T: () => RateLimitInfo },
            { no: 1000, name: "node_name", kind: "scalar", localName: "node_name", T: 9 /*ScalarType.STRING*/ },
            { no: 1001, name: "time", kind: "message", T: () => Timestamp },
            { no: 1002, name: "aggregation_info", kind: "message", localName: "aggregation_info", T: () => AggregationInfo }
        ]);
    }
    create(value?: PartialMessage<GetEventsResponse>): GetEventsResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.event = { oneofKind: undefined };
        message.node_name = "";
        if (value !== undefined)
            reflectionMergePartial<GetEventsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetEventsResponse): GetEventsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* tetragon.ProcessExec process_exec */ 1:
                    message.event = {
                        oneofKind: "process_exec",
                        process_exec: ProcessExec.internalBinaryRead(reader, reader.uint32(), options, (message.event as any).process_exec)
                    };
                    break;
                case /* tetragon.ProcessConnect process_connect */ 2:
                    message.event = {
                        oneofKind: "process_connect",
                        process_connect: ProcessConnect.internalBinaryRead(reader, reader.uint32(), options, (message.event as any).process_connect)
                    };
                    break;
                case /* tetragon.ProcessListen process_listen */ 3:
                    message.event = {
                        oneofKind: "process_listen",
                        process_listen: ProcessListen.internalBinaryRead(reader, reader.uint32(), options, (message.event as any).process_listen)
                    };
                    break;
                case /* tetragon.Tls tls */ 4:
                    message.event = {
                        oneofKind: "tls",
                        tls: Tls.internalBinaryRead(reader, reader.uint32(), options, (message.event as any).tls)
                    };
                    break;
                case /* tetragon.ProcessExit process_exit */ 5:
                    message.event = {
                        oneofKind: "process_exit",
                        process_exit: ProcessExit.internalBinaryRead(reader, reader.uint32(), options, (message.event as any).process_exit)
                    };
                    break;
                case /* tetragon.ProcessClose process_close */ 6:
                    message.event = {
                        oneofKind: "process_close",
                        process_close: ProcessClose.internalBinaryRead(reader, reader.uint32(), options, (message.event as any).process_close)
                    };
                    break;
                case /* tetragon.ProcessAccept process_accept */ 7:
                    message.event = {
                        oneofKind: "process_accept",
                        process_accept: ProcessAccept.internalBinaryRead(reader, reader.uint32(), options, (message.event as any).process_accept)
                    };
                    break;
                case /* tetragon.ProcessKprobe process_kprobe */ 9:
                    message.event = {
                        oneofKind: "process_kprobe",
                        process_kprobe: ProcessKprobe.internalBinaryRead(reader, reader.uint32(), options, (message.event as any).process_kprobe)
                    };
                    break;
                case /* tetragon.ProcessTracepoint process_tracepoint */ 10:
                    message.event = {
                        oneofKind: "process_tracepoint",
                        process_tracepoint: ProcessTracepoint.internalBinaryRead(reader, reader.uint32(), options, (message.event as any).process_tracepoint)
                    };
                    break;
                case /* tetragon.ProcessSockStats process_sock_stats */ 11:
                    message.event = {
                        oneofKind: "process_sock_stats",
                        process_sock_stats: ProcessSockStats.internalBinaryRead(reader, reader.uint32(), options, (message.event as any).process_sock_stats)
                    };
                    break;
                case /* tetragon.ProcessHttp process_http */ 12:
                    message.event = {
                        oneofKind: "process_http",
                        process_http: ProcessHttp.internalBinaryRead(reader, reader.uint32(), options, (message.event as any).process_http)
                    };
                    break;
                case /* tetragon.InterfaceStats interface_stats */ 13:
                    message.event = {
                        oneofKind: "interface_stats",
                        interface_stats: InterfaceStats.internalBinaryRead(reader, reader.uint32(), options, (message.event as any).interface_stats)
                    };
                    break;
                case /* tetragon.ProcessDns process_dns */ 14:
                    message.event = {
                        oneofKind: "process_dns",
                        process_dns: ProcessDns.internalBinaryRead(reader, reader.uint32(), options, (message.event as any).process_dns)
                    };
                    break;
                case /* tetragon.ProcessNetworkBurst process_network_burst */ 15:
                    message.event = {
                        oneofKind: "process_network_burst",
                        process_network_burst: ProcessNetworkBurst.internalBinaryRead(reader, reader.uint32(), options, (message.event as any).process_network_burst)
                    };
                    break;
                case /* tetragon.ProcessFile process_file */ 16:
                    message.event = {
                        oneofKind: "process_file",
                        process_file: ProcessFile.internalBinaryRead(reader, reader.uint32(), options, (message.event as any).process_file)
                    };
                    break;
                case /* tetragon.ProcessIpError process_ip_error */ 17:
                    message.event = {
                        oneofKind: "process_ip_error",
                        process_ip_error: ProcessIpError.internalBinaryRead(reader, reader.uint32(), options, (message.event as any).process_ip_error)
                    };
                    break;
                case /* tetragon.ProcessLoader process_loader */ 18:
                    message.event = {
                        oneofKind: "process_loader",
                        process_loader: ProcessLoader.internalBinaryRead(reader, reader.uint32(), options, (message.event as any).process_loader)
                    };
                    break;
                case /* tetragon.ProcessNetworkWatermark process_network_watermark */ 19:
                    message.event = {
                        oneofKind: "process_network_watermark",
                        process_network_watermark: ProcessNetworkWatermark.internalBinaryRead(reader, reader.uint32(), options, (message.event as any).process_network_watermark)
                    };
                    break;
                case /* tetragon.ProcessUprobe process_uprobe */ 20:
                    message.event = {
                        oneofKind: "process_uprobe",
                        process_uprobe: ProcessUprobe.internalBinaryRead(reader, reader.uint32(), options, (message.event as any).process_uprobe)
                    };
                    break;
                case /* tetragon.ProcessUdpSeqCheckError process_udp_seq_check_error */ 21:
                    message.event = {
                        oneofKind: "process_udp_seq_check_error",
                        process_udp_seq_check_error: ProcessUdpSeqCheckError.internalBinaryRead(reader, reader.uint32(), options, (message.event as any).process_udp_seq_check_error)
                    };
                    break;
                case /* tetragon.ProcessFileExec process_file_exec */ 22:
                    message.event = {
                        oneofKind: "process_file_exec",
                        process_file_exec: ProcessFileExec.internalBinaryRead(reader, reader.uint32(), options, (message.event as any).process_file_exec)
                    };
                    break;
                case /* tetragon.ProcessIcmp process_icmp */ 23:
                    message.event = {
                        oneofKind: "process_icmp",
                        process_icmp: ProcessIcmp.internalBinaryRead(reader, reader.uint32(), options, (message.event as any).process_icmp)
                    };
                    break;
                case /* tetragon.ProcessRawsockCreate process_rawsock_create */ 24:
                    message.event = {
                        oneofKind: "process_rawsock_create",
                        process_rawsock_create: ProcessRawsockCreate.internalBinaryRead(reader, reader.uint32(), options, (message.event as any).process_rawsock_create)
                    };
                    break;
                case /* tetragon.ProcessRawsockClose process_rawsock_close */ 25:
                    message.event = {
                        oneofKind: "process_rawsock_close",
                        process_rawsock_close: ProcessRawsockClose.internalBinaryRead(reader, reader.uint32(), options, (message.event as any).process_rawsock_close)
                    };
                    break;
                case /* tetragon.ProcessSandboxSyscall process_sandbox_syscall */ 26:
                    message.event = {
                        oneofKind: "process_sandbox_syscall",
                        process_sandbox_syscall: ProcessSandboxSyscall.internalBinaryRead(reader, reader.uint32(), options, (message.event as any).process_sandbox_syscall)
                    };
                    break;
                case /* tetragon.Test test */ 40000:
                    message.event = {
                        oneofKind: "test",
                        test: Test.internalBinaryRead(reader, reader.uint32(), options, (message.event as any).test)
                    };
                    break;
                case /* tetragon.RateLimitInfo rate_limit_info */ 40001:
                    message.event = {
                        oneofKind: "rate_limit_info",
                        rate_limit_info: RateLimitInfo.internalBinaryRead(reader, reader.uint32(), options, (message.event as any).rate_limit_info)
                    };
                    break;
                case /* string node_name */ 1000:
                    message.node_name = reader.string();
                    break;
                case /* google.protobuf.Timestamp time */ 1001:
                    message.time = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.time);
                    break;
                case /* tetragon.AggregationInfo aggregation_info */ 1002:
                    message.aggregation_info = AggregationInfo.internalBinaryRead(reader, reader.uint32(), options, message.aggregation_info);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetEventsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* tetragon.ProcessExec process_exec = 1; */
        if (message.event.oneofKind === "process_exec")
            ProcessExec.internalBinaryWrite(message.event.process_exec, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.ProcessConnect process_connect = 2; */
        if (message.event.oneofKind === "process_connect")
            ProcessConnect.internalBinaryWrite(message.event.process_connect, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.ProcessListen process_listen = 3; */
        if (message.event.oneofKind === "process_listen")
            ProcessListen.internalBinaryWrite(message.event.process_listen, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.Tls tls = 4; */
        if (message.event.oneofKind === "tls")
            Tls.internalBinaryWrite(message.event.tls, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.ProcessExit process_exit = 5; */
        if (message.event.oneofKind === "process_exit")
            ProcessExit.internalBinaryWrite(message.event.process_exit, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.ProcessClose process_close = 6; */
        if (message.event.oneofKind === "process_close")
            ProcessClose.internalBinaryWrite(message.event.process_close, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.ProcessAccept process_accept = 7; */
        if (message.event.oneofKind === "process_accept")
            ProcessAccept.internalBinaryWrite(message.event.process_accept, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.ProcessKprobe process_kprobe = 9; */
        if (message.event.oneofKind === "process_kprobe")
            ProcessKprobe.internalBinaryWrite(message.event.process_kprobe, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.ProcessTracepoint process_tracepoint = 10; */
        if (message.event.oneofKind === "process_tracepoint")
            ProcessTracepoint.internalBinaryWrite(message.event.process_tracepoint, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.ProcessSockStats process_sock_stats = 11; */
        if (message.event.oneofKind === "process_sock_stats")
            ProcessSockStats.internalBinaryWrite(message.event.process_sock_stats, writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.ProcessHttp process_http = 12; */
        if (message.event.oneofKind === "process_http")
            ProcessHttp.internalBinaryWrite(message.event.process_http, writer.tag(12, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.InterfaceStats interface_stats = 13; */
        if (message.event.oneofKind === "interface_stats")
            InterfaceStats.internalBinaryWrite(message.event.interface_stats, writer.tag(13, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.ProcessDns process_dns = 14; */
        if (message.event.oneofKind === "process_dns")
            ProcessDns.internalBinaryWrite(message.event.process_dns, writer.tag(14, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.ProcessNetworkBurst process_network_burst = 15; */
        if (message.event.oneofKind === "process_network_burst")
            ProcessNetworkBurst.internalBinaryWrite(message.event.process_network_burst, writer.tag(15, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.ProcessFile process_file = 16; */
        if (message.event.oneofKind === "process_file")
            ProcessFile.internalBinaryWrite(message.event.process_file, writer.tag(16, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.ProcessIpError process_ip_error = 17; */
        if (message.event.oneofKind === "process_ip_error")
            ProcessIpError.internalBinaryWrite(message.event.process_ip_error, writer.tag(17, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.ProcessLoader process_loader = 18; */
        if (message.event.oneofKind === "process_loader")
            ProcessLoader.internalBinaryWrite(message.event.process_loader, writer.tag(18, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.ProcessNetworkWatermark process_network_watermark = 19; */
        if (message.event.oneofKind === "process_network_watermark")
            ProcessNetworkWatermark.internalBinaryWrite(message.event.process_network_watermark, writer.tag(19, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.ProcessUprobe process_uprobe = 20; */
        if (message.event.oneofKind === "process_uprobe")
            ProcessUprobe.internalBinaryWrite(message.event.process_uprobe, writer.tag(20, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.ProcessUdpSeqCheckError process_udp_seq_check_error = 21; */
        if (message.event.oneofKind === "process_udp_seq_check_error")
            ProcessUdpSeqCheckError.internalBinaryWrite(message.event.process_udp_seq_check_error, writer.tag(21, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.ProcessFileExec process_file_exec = 22; */
        if (message.event.oneofKind === "process_file_exec")
            ProcessFileExec.internalBinaryWrite(message.event.process_file_exec, writer.tag(22, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.ProcessIcmp process_icmp = 23; */
        if (message.event.oneofKind === "process_icmp")
            ProcessIcmp.internalBinaryWrite(message.event.process_icmp, writer.tag(23, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.ProcessRawsockCreate process_rawsock_create = 24; */
        if (message.event.oneofKind === "process_rawsock_create")
            ProcessRawsockCreate.internalBinaryWrite(message.event.process_rawsock_create, writer.tag(24, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.ProcessRawsockClose process_rawsock_close = 25; */
        if (message.event.oneofKind === "process_rawsock_close")
            ProcessRawsockClose.internalBinaryWrite(message.event.process_rawsock_close, writer.tag(25, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.ProcessSandboxSyscall process_sandbox_syscall = 26; */
        if (message.event.oneofKind === "process_sandbox_syscall")
            ProcessSandboxSyscall.internalBinaryWrite(message.event.process_sandbox_syscall, writer.tag(26, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.Test test = 40000; */
        if (message.event.oneofKind === "test")
            Test.internalBinaryWrite(message.event.test, writer.tag(40000, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.RateLimitInfo rate_limit_info = 40001; */
        if (message.event.oneofKind === "rate_limit_info")
            RateLimitInfo.internalBinaryWrite(message.event.rate_limit_info, writer.tag(40001, WireType.LengthDelimited).fork(), options).join();
        /* string node_name = 1000; */
        if (message.node_name !== "")
            writer.tag(1000, WireType.LengthDelimited).string(message.node_name);
        /* google.protobuf.Timestamp time = 1001; */
        if (message.time)
            Timestamp.internalBinaryWrite(message.time, writer.tag(1001, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.AggregationInfo aggregation_info = 1002; */
        if (message.aggregation_info)
            AggregationInfo.internalBinaryWrite(message.aggregation_info, writer.tag(1002, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tetragon.GetEventsResponse
 */
export const GetEventsResponse = new GetEventsResponse$Type();
