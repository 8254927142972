import React, { memo } from 'react';

import classnames from 'classnames';

import { ServiceCard } from '~/components/ServiceMapCard/types';

import { EndpointLogo } from './EndpointLogo';
import css from './styles.scss';

export interface Props {
  card: ServiceCard;
  showAdditionalInfo?: boolean;
  onHeadlineClick?: () => void;
}

export enum E2E {
  cardId = 'card-id',
}

export const EndpointCardHeader = memo(function EndpointCardHeader(props: Props) {
  const { card } = props;
  const showAdditionalInfo = !!props?.showAdditionalInfo;
  const subtitle = React.useMemo(() => {
    if (card.cluster && card.namespace) {
      return `${card.cluster} | ${card.namespace}`;
    } else if (card.cluster) {
      return card.cluster;
    } else if (card.namespace) {
      return card.namespace;
    }
    return null;
  }, [card.cluster, card.namespace]);

  const additionalInfo = React.useMemo(() => {
    if (card.networks.length === 0) return null;

    return (
      <div className={css.additionalInfoNetworks}>
        {card.networks.map(network => (
          <span key={network} className={css.additionalInfoNetwork}>
            {network}
          </span>
        ))}
      </div>
    );
  }, [card.networks]);

  const titleClassName = classnames(css.title, {
    [css.single]: !subtitle && !showAdditionalInfo,
  });

  return (
    <div className={css.wrapper} data-test-value={card.id} data-testid={E2E.cardId}>
      <div className={css.headline} onClick={props.onHeadlineClick}>
        <EndpointLogo card={card} />

        <div className={css.headings}>
          <div className={titleClassName}>{card.caption}</div>
          {subtitle && <div className={css.subtitle}>{subtitle}</div>}
          {showAdditionalInfo && <div className={css.subtitle}>{additionalInfo}</div>}
        </div>
      </div>
      {/* <PolicyInfo
        ingress={card.service.ingressPolicyEnforced}
        egress={card.service.egressPolicyEnforced}
      /> */}
    </div>
  );
});
