import React from 'react';

import css from './CheckboxFilter.scss';
import { FlowFilterGroupFormBaseComponentProps } from '../types';

export const CheckboxFilter = function CheckboxFilter(
  props: FlowFilterGroupFormBaseComponentProps,
) {
  const { config, filterState, onFilterChange } = props;
  const { label, filterComponentProps = {} } = config;

  return (
    <div className={css.section}>
      <span className={css.label}>{label}</span>
      <input
        type="checkbox"
        className={css.checkbox}
        checked={filterState as boolean}
        onChange={event => onFilterChange(event.target.checked)}
        {...filterComponentProps}
      />
      <span className={css.checkmark}></span>
    </div>
  );
};
