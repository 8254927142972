import { action, makeObservable, observable } from 'mobx';

import { getGithubAccessToken, saveGithubAccessToken } from '~/storage/local';

export default class UserStore {
  @observable private _githubAccessToken: string | null;

  constructor() {
    makeObservable(this);

    this._githubAccessToken = getGithubAccessToken();
  }

  set githubAccessToken(token: string | null) {
    this._githubAccessToken = token;
  }

  get githubAccessToken() {
    return this._githubAccessToken;
  }

  @action authGithub = (onPopupBlocked?: () => void) => {
    if (this.githubAccessToken) {
      return Promise.resolve(this.githubAccessToken);
    }

    let authwindow = window.open(`${process.env.EDITOR_API_URL}/auth/github/waiting`);
    if (!authwindow) onPopupBlocked?.();

    return new Promise<string>((resolve, reject) => {
      const eventSource = new EventSource(`${process.env.EDITOR_API_URL}/auth/github/init`);

      const closeStream = () => {
        if (eventSource.readyState !== EventSource.CLOSED) {
          eventSource.close();
        }
        authwindow?.close();
      };

      eventSource.addEventListener('accept', (event: any) => {
        const { auth_url } = JSON.parse(event.data);
        if (!authwindow) {
          authwindow = window.open(auth_url);
        } else {
          authwindow.location.href = auth_url;
        }
      });

      eventSource.addEventListener('failure', (event: any) => {
        authwindow?.close();
        reject(JSON.parse(event.data));
      });

      eventSource.addEventListener('success', async (event: any) => {
        const json = JSON.parse(event.data);
        authwindow?.close();
        saveGithubAccessToken(json.payload.access_token);
        resolve(json.payload.access_token);
      });

      eventSource.addEventListener('stop', () => {
        closeStream();
      });
    });
  };
}
