/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter add_pb_suffix,eslint_disable,ts_nocheck,generate_dependencies,long_type_number,use_proto_field_name
// @generated from protobuf file "aggregation/aggregation.proto" (package "isovalent.flow.aggregation", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { BoolValue } from "../google/protobuf/wrappers_pb";
import { Duration } from "../google/protobuf/duration_pb";
import { Timestamp } from "../google/protobuf/timestamp_pb";
/**
 * DirectionStatistics are flow statistics in a particular direction
 *
 * @generated from protobuf message isovalent.flow.aggregation.DirectionStatistics
 */
export interface DirectionStatistics {
    /**
     * first_activity is the timestamp of first activity on the flow
     *
     * @generated from protobuf field: google.protobuf.Timestamp first_activity = 1;
     */
    first_activity?: Timestamp;
    /**
     * last_activity is the timestamp when activity was last observed
     *
     * @generated from protobuf field: google.protobuf.Timestamp last_activity = 2;
     */
    last_activity?: Timestamp;
    /**
     * num_flows is the number of flows aggregated together
     *
     * @generated from protobuf field: uint64 num_flows = 3;
     */
    num_flows: number;
    /**
     * bytes is the number of bytes observed on the flow
     *
     * @generated from protobuf field: uint64 bytes = 4;
     */
    bytes: number;
    /**
     * errors is the number of errors observed on the flow, e.g. RSTs or
     * HTTP 4xx 5xx status returns
     *
     * @generated from protobuf field: uint64 errors = 5;
     */
    errors: number;
    /**
     * ack_seen is true once a TCP ACK has been seen in this direction
     *
     * @generated from protobuf field: bool ack_seen = 6;
     */
    ack_seen: boolean;
    /**
     * connect_requests is the number of requests for new connections, i.e.
     * the number of SYNs seen
     *
     * @generated from protobuf field: uint64 connection_attempts = 7;
     */
    connection_attempts: number;
    /**
     * close_requests is the number of connection closure requests
     * received, i.e. the number of FINs seen
     *
     * @generated from protobuf field: uint64 close_requests = 8;
     */
    close_requests: number;
}
/**
 * FlowStatistics includes the statistics for a flow in both directions
 *
 * @generated from protobuf message isovalent.flow.aggregation.FlowStatistics
 */
export interface FlowStatistics {
    /**
     * forward represents flow statistics in the forward direction
     *
     * @generated from protobuf field: isovalent.flow.aggregation.DirectionStatistics forward = 1;
     */
    forward?: DirectionStatistics;
    /**
     * reply represents flow statistics in the reply direction
     *
     * @generated from protobuf field: isovalent.flow.aggregation.DirectionStatistics reply = 2;
     */
    reply?: DirectionStatistics;
    /**
     * established is set to true once the connection/flow is established
     *
     * @generated from protobuf field: bool established = 3;
     */
    established: boolean;
}
/**
 * Aggregator is an aggregator configuration
 *
 * @generated from protobuf message isovalent.flow.aggregation.Aggregator
 */
export interface Aggregator {
    /**
     * @generated from protobuf field: isovalent.flow.aggregation.AggregatorType type = 1;
     */
    type: AggregatorType;
    /**
     * Ignore source port during aggregation.
     *
     * @generated from protobuf field: bool ignore_source_port = 2;
     */
    ignore_source_port: boolean;
    /**
     * Specify the flow TTL for this aggregator. Defaults to 30 seconds.
     *
     * @generated from protobuf field: google.protobuf.Duration ttl = 3;
     */
    ttl?: Duration;
    /**
     * By default, the flow TTL gets renewed when there is an activity on a
     * given aggregation target (connection or identity). This means that flows
     * do not expire unless they remain inactive for the duration specified in
     * the ttl field. Set this flag to false to expire flows after their initial
     * TTLs regardless of whether there have been subsequent flows on their
     * aggregation targets.
     *
     * @generated from protobuf field: google.protobuf.BoolValue renew_ttl = 4;
     */
    renew_ttl?: BoolValue;
}
/**
 * Aggregation is a filter to define flow aggregation behavior
 *
 * @generated from protobuf message isovalent.flow.aggregation.Aggregation
 */
export interface Aggregation {
    /**
     * aggregators is a list of aggregators to apply on flows before
     * returning them. If multiple aggregator are defined, all of them are
     * applied in a row.
     *
     * @generated from protobuf field: repeated isovalent.flow.aggregation.Aggregator aggregators = 1;
     */
    aggregators: Aggregator[];
    /**
     * state_change_filter lists the state changes to consider when
     * determing to return an updated flow while aggregating
     *
     * @generated from protobuf field: isovalent.flow.aggregation.StateChange state_change_filter = 2;
     */
    state_change_filter: StateChange;
}
/**
 * @generated from protobuf enum isovalent.flow.aggregation.StateChange
 */
export enum StateChange {
    /**
     * unspec represents no change in state
     *
     * @generated from protobuf enum value: unspec = 0;
     */
    unspec = 0,
    /**
     * new indicates that the flow has been observed for the first time,
     * e.g. for connection aggregation, the first time a 5-tuple + verdict
     * + drop-reason has been observed.
     *
     * @generated from protobuf enum value: new = 1;
     */
    new = 1,
    /**
     * established indicates that the connection handshake has been
     * successful, i.e. for TCP this means that the 3-way handshake has
     * been successful. For any non-TCP protocol, the first flow in any
     * direction triggers established state.
     *
     * @generated from protobuf enum value: established = 2;
     */
    established = 2,
    /**
     * first_error indicates that an error has been observed on the flow
     * for the first time
     *
     * @generated from protobuf enum value: first_error = 4;
     */
    first_error = 4,
    /**
     * error indicates that the latest flow reported an error condition.
     * For TCP, this indicates that an RST has been observed.  For HTTP,
     * this indicates that a 4xx or 5xx status code has been observed.
     *
     * @generated from protobuf enum value: error = 8;
     */
    error = 8,
    /**
     * closed indicates closure of the connection, e.g. a TCP FIN has been
     * seen in both direction. For non-TCP, this state is never triggered.
     * This state is never reached for non-connection aggregation.
     *
     * @generated from protobuf enum value: closed = 16;
     */
    closed = 16,
    /**
     * first_reply indicates that a flow with is_reply set to true has been
     * observed on the flow for the first time.
     *
     * @generated from protobuf enum value: first_reply = 32;
     */
    first_reply = 32
}
/**
 * AggregatorType are all aggregator types
 *
 * @generated from protobuf enum isovalent.flow.aggregation.AggregatorType
 */
export enum AggregatorType {
    /**
     * @generated from protobuf enum value: unknown = 0;
     */
    unknown = 0,
    /**
     * @generated from protobuf enum value: connection = 1;
     */
    connection = 1,
    /**
     * @generated from protobuf enum value: identity = 2;
     */
    identity = 2
}
// @generated message type with reflection information, may provide speed optimized methods
class DirectionStatistics$Type extends MessageType<DirectionStatistics> {
    constructor() {
        super("isovalent.flow.aggregation.DirectionStatistics", [
            { no: 1, name: "first_activity", kind: "message", localName: "first_activity", T: () => Timestamp },
            { no: 2, name: "last_activity", kind: "message", localName: "last_activity", T: () => Timestamp },
            { no: 3, name: "num_flows", kind: "scalar", localName: "num_flows", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "bytes", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 5, name: "errors", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 6, name: "ack_seen", kind: "scalar", localName: "ack_seen", T: 8 /*ScalarType.BOOL*/ },
            { no: 7, name: "connection_attempts", kind: "scalar", localName: "connection_attempts", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 8, name: "close_requests", kind: "scalar", localName: "close_requests", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<DirectionStatistics>): DirectionStatistics {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.num_flows = 0;
        message.bytes = 0;
        message.errors = 0;
        message.ack_seen = false;
        message.connection_attempts = 0;
        message.close_requests = 0;
        if (value !== undefined)
            reflectionMergePartial<DirectionStatistics>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DirectionStatistics): DirectionStatistics {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* google.protobuf.Timestamp first_activity */ 1:
                    message.first_activity = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.first_activity);
                    break;
                case /* google.protobuf.Timestamp last_activity */ 2:
                    message.last_activity = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.last_activity);
                    break;
                case /* uint64 num_flows */ 3:
                    message.num_flows = reader.uint64().toNumber();
                    break;
                case /* uint64 bytes */ 4:
                    message.bytes = reader.uint64().toNumber();
                    break;
                case /* uint64 errors */ 5:
                    message.errors = reader.uint64().toNumber();
                    break;
                case /* bool ack_seen */ 6:
                    message.ack_seen = reader.bool();
                    break;
                case /* uint64 connection_attempts */ 7:
                    message.connection_attempts = reader.uint64().toNumber();
                    break;
                case /* uint64 close_requests */ 8:
                    message.close_requests = reader.uint64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DirectionStatistics, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* google.protobuf.Timestamp first_activity = 1; */
        if (message.first_activity)
            Timestamp.internalBinaryWrite(message.first_activity, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp last_activity = 2; */
        if (message.last_activity)
            Timestamp.internalBinaryWrite(message.last_activity, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* uint64 num_flows = 3; */
        if (message.num_flows !== 0)
            writer.tag(3, WireType.Varint).uint64(message.num_flows);
        /* uint64 bytes = 4; */
        if (message.bytes !== 0)
            writer.tag(4, WireType.Varint).uint64(message.bytes);
        /* uint64 errors = 5; */
        if (message.errors !== 0)
            writer.tag(5, WireType.Varint).uint64(message.errors);
        /* bool ack_seen = 6; */
        if (message.ack_seen !== false)
            writer.tag(6, WireType.Varint).bool(message.ack_seen);
        /* uint64 connection_attempts = 7; */
        if (message.connection_attempts !== 0)
            writer.tag(7, WireType.Varint).uint64(message.connection_attempts);
        /* uint64 close_requests = 8; */
        if (message.close_requests !== 0)
            writer.tag(8, WireType.Varint).uint64(message.close_requests);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message isovalent.flow.aggregation.DirectionStatistics
 */
export const DirectionStatistics = new DirectionStatistics$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FlowStatistics$Type extends MessageType<FlowStatistics> {
    constructor() {
        super("isovalent.flow.aggregation.FlowStatistics", [
            { no: 1, name: "forward", kind: "message", T: () => DirectionStatistics },
            { no: 2, name: "reply", kind: "message", T: () => DirectionStatistics },
            { no: 3, name: "established", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<FlowStatistics>): FlowStatistics {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.established = false;
        if (value !== undefined)
            reflectionMergePartial<FlowStatistics>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FlowStatistics): FlowStatistics {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* isovalent.flow.aggregation.DirectionStatistics forward */ 1:
                    message.forward = DirectionStatistics.internalBinaryRead(reader, reader.uint32(), options, message.forward);
                    break;
                case /* isovalent.flow.aggregation.DirectionStatistics reply */ 2:
                    message.reply = DirectionStatistics.internalBinaryRead(reader, reader.uint32(), options, message.reply);
                    break;
                case /* bool established */ 3:
                    message.established = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FlowStatistics, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* isovalent.flow.aggregation.DirectionStatistics forward = 1; */
        if (message.forward)
            DirectionStatistics.internalBinaryWrite(message.forward, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* isovalent.flow.aggregation.DirectionStatistics reply = 2; */
        if (message.reply)
            DirectionStatistics.internalBinaryWrite(message.reply, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* bool established = 3; */
        if (message.established !== false)
            writer.tag(3, WireType.Varint).bool(message.established);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message isovalent.flow.aggregation.FlowStatistics
 */
export const FlowStatistics = new FlowStatistics$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Aggregator$Type extends MessageType<Aggregator> {
    constructor() {
        super("isovalent.flow.aggregation.Aggregator", [
            { no: 1, name: "type", kind: "enum", T: () => ["isovalent.flow.aggregation.AggregatorType", AggregatorType] },
            { no: 2, name: "ignore_source_port", kind: "scalar", localName: "ignore_source_port", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "ttl", kind: "message", T: () => Duration },
            { no: 4, name: "renew_ttl", kind: "message", localName: "renew_ttl", T: () => BoolValue }
        ]);
    }
    create(value?: PartialMessage<Aggregator>): Aggregator {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.type = 0;
        message.ignore_source_port = false;
        if (value !== undefined)
            reflectionMergePartial<Aggregator>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Aggregator): Aggregator {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* isovalent.flow.aggregation.AggregatorType type */ 1:
                    message.type = reader.int32();
                    break;
                case /* bool ignore_source_port */ 2:
                    message.ignore_source_port = reader.bool();
                    break;
                case /* google.protobuf.Duration ttl */ 3:
                    message.ttl = Duration.internalBinaryRead(reader, reader.uint32(), options, message.ttl);
                    break;
                case /* google.protobuf.BoolValue renew_ttl */ 4:
                    message.renew_ttl = BoolValue.internalBinaryRead(reader, reader.uint32(), options, message.renew_ttl);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Aggregator, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* isovalent.flow.aggregation.AggregatorType type = 1; */
        if (message.type !== 0)
            writer.tag(1, WireType.Varint).int32(message.type);
        /* bool ignore_source_port = 2; */
        if (message.ignore_source_port !== false)
            writer.tag(2, WireType.Varint).bool(message.ignore_source_port);
        /* google.protobuf.Duration ttl = 3; */
        if (message.ttl)
            Duration.internalBinaryWrite(message.ttl, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.BoolValue renew_ttl = 4; */
        if (message.renew_ttl)
            BoolValue.internalBinaryWrite(message.renew_ttl, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message isovalent.flow.aggregation.Aggregator
 */
export const Aggregator = new Aggregator$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Aggregation$Type extends MessageType<Aggregation> {
    constructor() {
        super("isovalent.flow.aggregation.Aggregation", [
            { no: 1, name: "aggregators", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Aggregator },
            { no: 2, name: "state_change_filter", kind: "enum", localName: "state_change_filter", T: () => ["isovalent.flow.aggregation.StateChange", StateChange] }
        ]);
    }
    create(value?: PartialMessage<Aggregation>): Aggregation {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.aggregators = [];
        message.state_change_filter = 0;
        if (value !== undefined)
            reflectionMergePartial<Aggregation>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Aggregation): Aggregation {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated isovalent.flow.aggregation.Aggregator aggregators */ 1:
                    message.aggregators.push(Aggregator.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* isovalent.flow.aggregation.StateChange state_change_filter */ 2:
                    message.state_change_filter = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Aggregation, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated isovalent.flow.aggregation.Aggregator aggregators = 1; */
        for (let i = 0; i < message.aggregators.length; i++)
            Aggregator.internalBinaryWrite(message.aggregators[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* isovalent.flow.aggregation.StateChange state_change_filter = 2; */
        if (message.state_change_filter !== 0)
            writer.tag(2, WireType.Varint).int32(message.state_change_filter);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message isovalent.flow.aggregation.Aggregation
 */
export const Aggregation = new Aggregation$Type();
