import { IPEndpoint } from '~/domain/interactions/endpoints';

export class EndpointEntry {
  public endpoint?: IPEndpoint;
  public group?: IPEndpoint[];

  public static newGroup(endpoints: IPEndpoint[]): EndpointEntry {
    const e = new EndpointEntry();
    e.group = endpoints;

    return e;
  }

  public static newStandalone(endpoint: IPEndpoint): EndpointEntry {
    const e = new EndpointEntry();
    e.endpoint = endpoint;

    return e;
  }

  public get isGroup(): boolean {
    return this.group != null;
  }

  public get isStandalone(): boolean {
    return this.endpoint != null;
  }

  public get key(): string {
    return this.endpoint?.id ?? this.group?.map(ep => ep.id)?.join(',') ?? '';
  }
}
