// NOTE: some icons are not listed here because they are too large
// TODO: to support large icons introduce `large` boolean prop
export enum IconKind {
  AWS = '/icons/logos/aws-logo.svg',
  Cassandra = '/icons/logos/cassandra-logo.svg',
  CouchDB = '/icons/logos/couchdb-logo.svg',
  ElasticSearch = '/icons/logos/elasticsearch-logo.svg',
  Etcd = '/icons/logos/etcd-logo.svg',
  GRPC = '/icons/logos/grpc-logo.svg',
  GraphQL = '/icons/logos/graphql-logo.svg',
  Kafka = '/icons/logos/kafka-logo.svg',
  Kubernetes = '/icons/logos/kubernetes-logo.svg',
  Memcached = '/icons/logos/memcached-logo.svg',
  MongoDB = '/icons/logos/mongodb-logo.svg',
  PostgreSQL = '/icons/logos/postgresql-logo.svg',
  Prometeus = '/icons/logos/prometheus-logo.svg',
  RabbitMQ = '/icons/logos/rabbitmq-logo.svg',
  Redis = '/icons/logos/redis-logo.svg',
  Twitter = '/icons/logos/twitter-logo.svg',
  Zookeeper = '/icons/logos/zookeeper-logo.png',
}
