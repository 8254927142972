import { Aggregation } from '~/domain/aggregation';
import { Diff, IDiff } from '~/domain/diff';
import { Verdict } from '~/domain/flows';

import { FilterGroup } from './filter-entry';
import { Filters } from './filters';
import { TimeRange } from '../time';

export class FiltersDiff implements IDiff {
  public static fromFilters(f?: Filters | null): FiltersDiff {
    return new FiltersDiff(
      Diff.new(f?.verdicts).setComparator(FiltersDiff.verdictsEqual),
      Diff.new(f?.httpStatus),
      Diff.new(f?.filterGroups).setComparator(FiltersDiff.filterGroupsEqual),
      Diff.new(f?.skipHost),
      Diff.new(f?.skipKubeDns),
      Diff.new(f?.skipRemoteNode),
      Diff.new(f?.skipPrometheusApp),
      Diff.new(f?.aggregation),
      Diff.new(f?.timeRange).setComparator(TimeRange.checkEquality),
    );
  }

  public static new(lhs?: Filters | null, rhs?: Filters | null): FiltersDiff {
    return FiltersDiff.fromFilters(lhs).step(rhs);
  }

  public static newUnchanged(): FiltersDiff {
    return FiltersDiff.new(null, null);
  }

  public static filterGroupsEqual(lhs?: FilterGroup[] | null, rhs?: FilterGroup[] | null): boolean {
    // NOTE: if both of them are empty or null
    if (!lhs?.length && !rhs?.length) return true;

    // NOTE: if one of them are empty or null
    if (!lhs?.length || !rhs?.length) return false;

    const lhsFingerprints: Set<string> = new Set();
    const rhsFingerprints: Set<string> = new Set();

    lhs.forEach(fg => {
      lhsFingerprints.add(fg.toString());
    });

    rhs.forEach(fg => {
      rhsFingerprints.add(fg.toString());
    });

    if (lhsFingerprints.size !== rhsFingerprints.size) return false;

    for (const key of lhsFingerprints) {
      if (!rhsFingerprints.has(key)) return false;
    }

    return true;
  }

  public static verdictsEqual(lhs?: Set<Verdict> | null, rhs?: Set<Verdict> | null): boolean {
    if (!lhs?.size && !rhs?.size) return true;
    if (!lhs?.size || !rhs?.size) return false;
    if (lhs?.size !== rhs?.size) return false;
    for (const key of lhs) if (!rhs.has(key)) return false;
    return true;
  }

  constructor(
    public verdicts: Diff<Set<Verdict>>,
    public httpStatus: Diff<string>,
    public filterGroups: Diff<FilterGroup[]>,
    public skipHost: Diff<boolean>,
    public skipKubeDns: Diff<boolean>,
    public skipRemoteNode: Diff<boolean>,
    public skipPrometheusApp: Diff<boolean>,
    public aggregation: Diff<Aggregation>,
    public timeRange: Diff<TimeRange>,
  ) {}

  public tap(fn: (self: FiltersDiff) => void): this {
    fn(this);
    return this;
  }

  public step(rhs?: Filters | null): this {
    this.verdicts.step(rhs?.verdicts);
    this.httpStatus.step(rhs?.httpStatus);
    this.filterGroups.step(rhs?.filterGroups);
    this.skipHost.step(rhs?.skipHost);
    this.skipKubeDns.step(rhs?.skipKubeDns);
    this.skipRemoteNode.step(rhs?.skipRemoteNode);
    this.skipPrometheusApp.step(rhs?.skipPrometheusApp);
    this.aggregation.step(rhs?.aggregation);
    this.timeRange.step(rhs?.timeRange);

    return this;
  }

  public invert(): this {
    this.verdicts.invert();
    this.httpStatus.invert();
    this.filterGroups.invert();
    this.skipHost.invert();
    this.skipKubeDns.invert();
    this.skipRemoteNode.invert();
    this.skipPrometheusApp.invert();
    this.aggregation.invert();
    this.timeRange.invert();

    return this;
  }

  public setUnchanged(): this {
    this.verdicts.setUnchanged();
    this.httpStatus.setUnchanged();
    this.filterGroups.setUnchanged();
    this.skipHost.setUnchanged();
    this.skipKubeDns.setUnchanged();
    this.skipRemoteNode.setUnchanged();
    this.skipPrometheusApp.setUnchanged();
    this.aggregation.setUnchanged();
    this.timeRange.setUnchanged();

    return this;
  }

  public get changed() {
    return (
      this.verdicts.changed ||
      this.httpStatus.changed ||
      this.filterGroups.changed ||
      this.skipHost.changed ||
      this.skipKubeDns.changed ||
      this.skipRemoteNode.changed ||
      this.skipPrometheusApp.changed ||
      this.aggregation.changed ||
      this.timeRange.changed
    );
  }

  public get podFiltersChanged(): boolean {
    const before = this.filterGroups.before?.filter(group =>
      group.entries.some(entry => entry.isPod),
    );
    const after = this.filterGroups.after?.filter(group =>
      group.entries.some(entry => entry.isPod),
    );

    return !FiltersDiff.filterGroupsEqual(before, after);
  }

  public get timeRangeChanged(): boolean {
    return this.timeRange.changed;
  }

  public get aggregationChanged(): boolean {
    return this.aggregation.changed;
  }

  public get nothingChanged(): boolean {
    return !this.changed;
  }
}
