import { makeAutoObservable } from 'mobx';

import { Status, User } from '~/domain/authorization';

export class AuthorizationStore {
  public user: User | null = null;
  public _status: Status = Status.Pending;

  constructor() {
    makeAutoObservable(this);
  }

  public setUser(user: User) {
    this.user = user;
    this._status = user.status;
  }

  public setStatus(st: Status) {
    this._status = st;
  }

  get status(): Status {
    return this._status;
  }

  get email(): string | null {
    return this.user?.email ?? null;
  }

  get isUserIsRelayAdmin(): boolean {
    return !!this.user?.isRelayAdmin;
  }

  get isUserIsTimescapeAdmin(): boolean {
    return !!this.user?.isTimescapeAdmin;
  }

  get isPending() {
    return this.status === Status.Pending;
  }

  get isAuthorized() {
    return this.status === Status.Authorized;
  }

  get isUnauthorized() {
    return this.status === Status.Unauthorized;
  }
}
