import { makeAutoObservable } from 'mobx';

export enum Status {
  Pending = 'pending',
  Authorized = 'authorized',
  Unauthorized = 'unauthorized',
}

export class User {
  public token: string;
  public email: string;
  public isRelayAdmin: boolean;
  public isTimescapeAdmin: boolean;
  public status: Status;

  constructor(
    token: string,
    email: string,
    isRelayAdmin: boolean,
    isTimescapeAdmin: boolean,
    status: Status = Status.Authorized,
  ) {
    this.token = token;
    this.email = email;
    this.isRelayAdmin = isRelayAdmin;
    this.isTimescapeAdmin = isTimescapeAdmin;
    this.status = status;

    makeAutoObservable(this);
  }

  get isAuthorized(): boolean {
    return this.status === Status.Authorized;
  }
}

export type Authorization = {
  isAuthorized: boolean;
  isRequired: boolean;
  user?: User;
};
