import React, { createContext, useContext } from 'react';

import { RefsCollector } from '~/ui/process-tree/collector';

export { RefsCollector };

export const RefsCollectorContext = createContext<RefsCollector | null>(null);

export type ProviderProps = {
  context?: RefsCollector;
  children?: React.ReactNode;
};

export const RefsCollectorContextProvider = (props: ProviderProps) => {
  const ctx = props.context || new RefsCollector();

  return (
    <RefsCollectorContext.Provider value={ctx}>{props.children}</RefsCollectorContext.Provider>
  );
};

export const useRefsCollector = () => {
  const impContext = useContext(RefsCollectorContext);
  if (!impContext) {
    throw new Error('useRefsCollector must be used within a ImperativeContextProvider');
  }

  return impContext;
};
