import { action, computed, makeObservable, observable } from 'mobx';

import { Vec2, XY } from '~/domain/geometry';
import { AuthType, LinkThroughput, Verdict } from '~/domain/hubble';
import { XYWH } from '~/ui-layer/service-map/coordinates/types';

import { buildArrowPointsFor } from './build-arrow-points-for';
import { CardOffsets } from './helpers/card-offsets';

export class AccessPointArrow {
  public connectorId: string | null = null;
  public accessPointId: string | null = null;

  public verdicts: Set<Verdict> = new Set();
  public authTypes: Set<AuthType> = new Set();
  public isEncrypted = false;

  public static new(): AccessPointArrow {
    return new AccessPointArrow();
  }

  @observable
  public _points: XY[] = [];

  @action
  public addPoint(p: XY): this {
    this._points.push(p);
    return this;
  }

  @computed
  public get points(): XY[] {
    return this._points.slice();
  }

  @computed
  public get start(): XY | undefined {
    return this.points.at(0);
  }

  @computed
  public get end(): XY | undefined {
    return this.points.at(-1);
  }

  constructor() {
    makeObservable(this);
  }

  @action
  public fromConnector(connectorId: string): this {
    this.connectorId = connectorId;
    return this;
  }

  @action
  public toAccessPoint(accessPointId: string): this {
    this.accessPointId = accessPointId;
    return this;
  }

  @action
  public addVerdicts(verdicts: Set<Verdict>): this {
    verdicts.forEach(v => {
      this.verdicts.add(v);
    });

    return this;
  }

  @action
  public addAuthTypes(authTypes: Set<AuthType>): this {
    authTypes.forEach(at => {
      this.authTypes.add(at);
    });

    return this;
  }

  @action
  public setEncryption(encryption: boolean): this {
    if (this.isEncrypted) return this;

    this.isEncrypted = encryption;
    return this;
  }

  @computed
  public get id(): string {
    return `${this.connectorId ?? ''} -> ${this.accessPointId ?? ''}`;
  }

  @computed
  public get hasAbnormalVerdict(): boolean {
    return this.verdicts.has(Verdict.Dropped) || this.verdicts.has(Verdict.Error);
  }

  @computed
  public get hasAuth(): boolean {
    return this.authTypes.has(AuthType.Spire);
  }
}

export class ServiceMapArrow {
  @observable
  public senderId: string | null = null;
  public senderBBox: XYWH | null = null;

  @observable
  public receiverId: string | null = null;
  public receiverBBox: XYWH | null = null;

  @observable
  private _flowsInfoIndicatorCoords: XY | null = null;

  @observable
  private _linkThroughputs: LinkThroughput[] = [];

  @observable
  public accessPointArrows: Map<string, AccessPointArrow> = new Map();

  // NOTE: An offset in pixels from shifted connector coords
  public static readonly flowsIndicatorOffset = 20;

  public static new(): ServiceMapArrow {
    return new ServiceMapArrow();
  }

  @observable
  public _points: XY[] = [];

  @action
  public addPoint(p: XY): this {
    this._points.push(p);
    return this;
  }

  @computed
  public get points(): XY[] {
    return this._points.slice();
  }

  @computed
  public get start(): XY | undefined {
    return this.points.at(0);
  }

  @computed
  public get end(): XY | undefined {
    return this.points.at(-1);
  }

  constructor() {
    makeObservable(this);
  }

  @action
  public from(senderId: string, bbox?: XYWH): this {
    this.senderId = senderId;
    this.senderBBox = bbox || null;
    return this;
  }

  @action
  public to(receiverId: string, bbox?: XYWH): this {
    this.receiverId = receiverId;
    this.receiverBBox = bbox || null;
    return this;
  }

  @action
  public addAccessPointArrow(connectorId: string, apId: string): AccessPointArrow {
    const apArrow = AccessPointArrow.new().fromConnector(connectorId).toAccessPoint(apId);

    this.accessPointArrows.set(apArrow.id, apArrow);
    return apArrow;
  }

  @action
  public addLinkThroughput(lt: LinkThroughput): this {
    this._linkThroughputs.push(lt);
    return this;
  }

  @action
  public buildPointsAroundSenderAndReceiver(offsets: CardOffsets): this {
    if (
      this.senderBBox == null ||
      this.receiverBBox == null ||
      this.start == null ||
      this.end == null
    )
      return this;

    this._points = buildArrowPointsFor(
      this.start,
      this.end,
      this.senderBBox,
      this.receiverBBox,
      offsets,
    );

    return this;
  }

  @action.bound
  public computeFlowsInfoIndicatorPosition() {
    const shiftedEnd = this._points.at(-2);
    const beforeShiftedEnd = this._points.at(-3);

    if (shiftedEnd == null || beforeShiftedEnd == null) return;

    const indicatorCoords = Vec2.fromXY(beforeShiftedEnd).sub(shiftedEnd).normalize();

    this._flowsInfoIndicatorCoords = Vec2.fromXY(shiftedEnd)
      .addInPlace(indicatorCoords.mul(ServiceMapArrow.flowsIndicatorOffset))
      .xy();
  }

  @computed
  public get id(): string {
    return `${this.senderId ?? ''} -> ${this.receiverId ?? ''}`;
  }

  @computed
  public get linkThroughputs(): LinkThroughput[] {
    return this._linkThroughputs.slice();
  }

  @computed
  public get flowsInfoIndicatorCoords(): XY | null {
    if (this._flowsInfoIndicatorCoords == null) return null;

    return {
      x: this._flowsInfoIndicatorCoords.x,
      y: this._flowsInfoIndicatorCoords.y,
    };
  }
}
