import { TimescapeServiceMapOneshot } from '~/api/customprotocol/timescape-service-map';
import { Pager } from '~/api/general/pager';
import { LastSeen } from '~/domain/common';
import { emptyTimescapeData, TimescapeData, TimescapeDataFilter } from '~/domain/timescape';

type OneshotFn = (df: TimescapeDataFilter) => TimescapeServiceMapOneshot;

export class TimescapeFlowsPager extends Pager<TimescapeData> {
  public static readonly flowsLimit = 100;

  private oneshotFn: OneshotFn | null = null;
  private oneshot: TimescapeServiceMapOneshot | null = null;
  private dataFilters: TimescapeDataFilter | null = null;

  public setDataFilters(df?: TimescapeDataFilter): this {
    this.dataFilters = df ?? null;
    return this;
  }

  public setOneshotConstructor(oneshotFn: OneshotFn): this {
    this.oneshotFn = oneshotFn;
    return this;
  }

  protected async fetchPage(lastSeen?: LastSeen): Promise<TimescapeData> {
    if (this.oneshotFn == null || this.dataFilters == null)
      throw new Error('TimescapeFlowsPager: cannot fetchPage: no oneshotFn or dataFilters set');

    const dataFilters = this.dataFilters
      .setLastSeen(lastSeen)
      .setLimit(TimescapeFlowsPager.flowsLimit);

    const oneshot = this.oneshotFn(dataFilters);
    this.oneshot = oneshot;

    const p = new Promise<TimescapeData>((resolve, reject) => {
      let resolved = false;
      oneshot
        .onTimescapeData(d => {
          resolve(this.sortFlows(d));
          resolved = true;
        })
        .onTerminated(() => {
          if (!resolved) {
            resolve(emptyTimescapeData());
            resolved = true;
          }
        })
        .onErrors(errs => reject(errs[0]))
        .run();
    });

    p.finally(() => (this.oneshot = null));
    return await p;
  }

  public async stop() {
    await this.oneshot?.stop();
    this.oneshot = null;

    super.stop();
  }

  protected checkIfExhausted(page: TimescapeData): boolean {
    return page.flows.length === 0 && page.flowSummaries.length === 0;
  }

  protected getLastSeenFromPage(page: TimescapeData): LastSeen | null {
    // NOTE: This code assumes that flows/flowSummaries are sorted
    const nFlowsSummaries = page.flowSummaries.length;
    if (nFlowsSummaries > 0) {
      return page.flowSummaries[nFlowsSummaries - 1].asLastSeen();
    }

    const nFlows = page.flows.length;
    if (nFlows > 0) {
      return page.flows[nFlows - 1].asLastSeen();
    }

    return null;
  }

  private sortFlows(td: TimescapeData): TimescapeData {
    td.flowSummaries.sort((a, b) => b.date.getTime() - a.date.getTime());
    td.flows.sort((a, b) => b.millisecondsTimestamp - a.millisecondsTimestamp);

    return td;
  }
}
