import React, { useCallback } from 'react';

import { Button, Icon, Intent, Popover, Spinner } from '@blueprintjs/core';

import { TimeRange } from '~/domain/time';
import { usePopover } from '~/ui/hooks';

import { TimeRangePicker } from './TimeRangePicker';
import css from './TimeRangePickerButton.scss';

export interface Props {
  className?: string;

  timeRange?: TimeRange;
  isLoading?: boolean;
  isDisabled?: boolean;
  isVertical?: boolean;
  isMinimal?: boolean;

  onTimeRangeChange?: (r: TimeRange) => void;
}

export const TimeRangePickerButton = (props: Props) => {
  const pickerPopover = usePopover({
    popoverProps: {
      rootBoundary: 'document',
      className: props.className,
      placement: props.isVertical ? 'right' : 'auto',
    },
  });

  const onToggle = useCallback(
    (event: React.SyntheticEvent) => {
      if (props.isDisabled) return;
      return pickerPopover.toggle(event);
    },
    [props.isDisabled, pickerPopover],
  );

  const onTimeRangeChange = useCallback(
    (timeRange: TimeRange) => {
      pickerPopover.close();
      props.onTimeRangeChange?.(timeRange);
    },
    [props.onTimeRangeChange, pickerPopover],
  );

  const rightIcon = props.isLoading ? <Spinner size={20} intent={Intent.PRIMARY} /> : void 0;

  return (
    <Popover
      {...pickerPopover.props}
      autoFocus={false}
      content={
        <div>
          <TimeRangePicker timeRange={props.timeRange} onTimeRangeChange={onTimeRangeChange} />
        </div>
      }
    >
      {props.isVertical ? (
        <Button
          className={css.verticalButton}
          minimal={!!props.isMinimal}
          onClick={onToggle}
          rightIcon={rightIcon}
        >
          <div className={css.buttonContent}>
            <div>
              <Icon icon="time" tagName="div" />
              <div className={css.arrowDown}>↓</div>
            </div>
            <div className={css.timeRangeRows}>
              <div className={css.timeRangeTop}>{props.timeRange?.startHumanString}</div>
              {props.timeRange?.endHumanString}
            </div>
          </div>
        </Button>
      ) : (
        <Button
          minimal={!!props.isMinimal}
          icon="time"
          disabled={props.isDisabled}
          text={`${props.timeRange?.startHumanString} → ${props.timeRange?.endHumanString}`}
          rightIcon={rightIcon}
          style={{ whiteSpace: 'pre' }}
          onClick={onToggle}
        />
      )}
    </Popover>
  );
};
