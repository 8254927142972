import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import _ from 'lodash';
import { observer } from 'mobx-react';

import { useApplication } from '~/application';
import { Authorization } from '~/authorization';
import { Map as MapComponent } from '~/components/CimulatorMap';
import { NavigationSidebar } from '~/components/NavigationSidebar/NavigationSidebar';
import { ResizeProps as DPResizeProps, PoliciesPanel } from '~/components/PoliciesPanel';
import { PolicyCard as CimulatorPolicyCard } from '~/components/PolicyCard';
import { filtersConfig } from '~/components/TopBar/filter-config';
import { FlowFiltersInputNew } from '~/components/TopBar/FlowFiltersInputNew/FlowFiltersInputNew';
import { PolicyCard } from '~/domain/cimulator/cards';
import { TrafficDirection } from '~/domain/cimulator/types';
import { FilterDirection, FilterEntry, FilterGroup } from '~/domain/filtering';
import { useFileUpload } from '~/domain/helpers/files';
import { PodSelector, TCPFlagName, Verdict, Workload } from '~/domain/hubble';
import { DataMode } from '~/domain/interactions';
import { KV, Labels } from '~/domain/labels';
import { CimulatorArrowStrategy, CimulatorPlacementStrategy } from '~/domain/layout/cimulator';
import { K8SEvent } from '~/domain/timescape/k8s-events';
import { useNotifier } from '~/notifier';
import {
  emitter as assistantEmitter,
  AssistantEmitterActions,
} from '~/store/stores/assistant/emitter';
import { logger } from '~/utils/logger';

import { DownloadDialog } from './DownloadDialog';
import { RemoveIngressEgressDialog } from './RemoveIngressEgressDialog';
import { ReplaceWithNewPolicyDialog } from './ReplaceWithNewPolicyDialog';
import css from './styles.scss';
import { PolicySuggestions } from '../PolicySuggestions/PolicySuggestions';

export const CimulatorApp = observer(function CimulatorApp() {
  // *** Globals ***
  const { store, ui, dataLayer } = useApplication();
  const notifier = useNotifier();
  const transferState = dataLayer.transferState;

  // *** /Globals ***

  // *** Refs ***
  const alertsPortal = useRef<HTMLDivElement>(null);
  // *** /Refs ***

  // *** Dialogs ***
  const [isDownloadDialogOpen, setIsDownloadDialogOpen] = useState<boolean>(false);
  const [ingressEgressWarningAlert, setIngressEgressWarningAlert] =
    useState<TrafficDirection | null>(null);
  const [replaceWithNewPolicyDialog, setReplaceWithNewPolicyDialog] = useState<
    { kind: 'upload' } | { kind: 'new' } | { kind: 'other'; id: string } | null
  >();

  const onDownloadDialogClose = useCallback(() => {
    setIsDownloadDialogOpen(false);
  }, []);

  const hideIngressEgressWarningAlert = useCallback(() => {
    setIngressEgressWarningAlert(null);
  }, []);

  const onConfirmReplaceWithNewPolicyDialog = useCallback(() => {
    switch (replaceWithNewPolicyDialog?.kind) {
      case 'new':
        store.cimulator.policy.createNew();
        break;
      case 'upload':
        uploadPolicyFile();
        break;
      case 'other':
        openPolicy(replaceWithNewPolicyDialog.id);
        break;
    }
    setReplaceWithNewPolicyDialog(null);
  }, [replaceWithNewPolicyDialog?.kind]);

  const onCancelReplaceWithNewPolicyDialog = useCallback(() => {
    setReplaceWithNewPolicyDialog(null);
  }, [replaceWithNewPolicyDialog?.kind]);
  // *** /Dialogs ***

  useEffect(() => {
    if (dataLayer.transferState.dataMode !== DataMode.WatchingHistory) return;
    const uuid = store.cimulator.controls.policyUuid;
    if (!uuid) return;
    dataLayer.serviceMap
      .fetchTimescapeK8SPolicyEvents(uuid)
      .then(events => store.cimulator.policy.setPolicyVersions(uuid, events))
      .catch(error => logger.log(error));
  }, [
    dataLayer.transferState.dataMode,
    store.clusterNamespaces.currCluster,
    store.clusterNamespaces.currNamespace,
    store.controls.timeRange,
    store.cimulator.controls.policyUuid,
  ]);

  useEffect(() => {
    if (dataLayer.transferState.dataMode !== DataMode.WatchingHistory) return;
    dataLayer.serviceMap
      .fetchTimescapeK8SPolicyEvents(null)
      .then(events => store.cimulator.policy.addPoliciesFromEvents(events))
      .catch(err => logger.error(err));
  }, [
    dataLayer.transferState.dataMode,
    store.clusterNamespaces.currCluster,
    store.clusterNamespaces.currNamespace,
    store.controls.timeRange,
  ]);

  const onSelectPolicyVersion = useCallback((event: K8SEvent, restoreOrigin: boolean) => {
    if (restoreOrigin) {
      ui.controls.openPolicy(
        store.clusterNamespaces.currCluster,
        store.clusterNamespaces.currNamespace,
        event.uuid,
        null,
      );
      return store.cimulator.policy.restoreOrigin(event.uuid);
    }
    ui.controls.openPolicy(
      store.clusterNamespaces.currCluster,
      store.clusterNamespaces.currNamespace,
      event.uuid,
      event.shortId,
    );
    const result = store.cimulator.policy.loadAsNewPolicy(
      { uid: event.raw.resource_uuid, policyObj: event.raw.object, k8sEventPolicyKind: event.kind },
      { autoselect: true, isHistoricalVersion: true },
    );
    if (!result.ok) logger.error(result.errors);
    return result;
  }, []);

  const onCreateNewPolicy = useCallback(() => {
    if (store.cimulator.policy.hasSomeRules) {
      setReplaceWithNewPolicyDialog({ kind: 'new' });
    } else {
      store.cimulator.policy.createNew();
    }
  }, [store.cimulator.policy.hasSomeRules]);

  // *** Download policy ***
  const initDownloadPolicy = useCallback(() => {
    if (!store.cimulator.controls.skipDownloadPolicyDialog) {
      setIsDownloadDialogOpen(true);
    }
    store.cimulator.policy.downloadYaml();
  }, [store.cimulator.controls.skipDownloadPolicyDialog]);

  const onDownloadPolicy = useCallback((event: React.MouseEvent) => {
    event.preventDefault();
    store.cimulator.policy.downloadYaml();
  }, []);
  // *** /Download policy ***

  // *** Default deny ***
  const toggleDefaultDenyIngress = useCallback(() => {
    if (store.cimulator.policy.hasIngressRules && store.cimulator.policy.hasSomeIngress) {
      setIngressEgressWarningAlert(TrafficDirection.Ingress);
    } else {
      store.cimulator.policy.toggleDefaultDenyIngress();
    }
  }, [
    store.cimulator.policy.hasIngressRules,
    store.cimulator.policy.hasSomeIngress,
    store.cimulator.policy.enableDefaultDenyIngress,
  ]);

  const toggleDefaultDenyEgress = useCallback(() => {
    if (store.cimulator.policy.hasEgressRules && store.cimulator.policy.hasSomeEgress) {
      setIngressEgressWarningAlert(TrafficDirection.Egress);
    } else {
      store.cimulator.policy.toggleDefaultDenyEgress();
    }
  }, [
    store.cimulator.policy.hasEgressRules,
    store.cimulator.policy.toggleDefaultDenyEgress,
    store.cimulator.policy.hasSomeEgress,
  ]);

  useEffect(() => {
    return assistantEmitter.on(
      AssistantEmitterActions.ToggleDefaultDenyIngress,
      toggleDefaultDenyIngress,
    );
  }, [assistantEmitter, toggleDefaultDenyIngress]);

  useEffect(() => {
    return assistantEmitter.on(
      AssistantEmitterActions.ToggleDefaultDenyEgress,
      toggleDefaultDenyEgress,
    );
  }, [assistantEmitter, toggleDefaultDenyEgress]);

  const disableDefaultDeny = useCallback(() => {
    if (ingressEgressWarningAlert === TrafficDirection.Ingress) {
      store.cimulator.policy.toggleDefaultDenyIngress();
    } else if (ingressEgressWarningAlert === TrafficDirection.Egress) {
      store.cimulator.policy.toggleDefaultDenyEgress();
    }
    hideIngressEgressWarningAlert();
  }, [
    ingressEgressWarningAlert,
    store.cimulator.policy.disableDefaultDenyIngress,
    store.cimulator.policy.disableDefaultDenyEgress,
  ]);
  // *** /Default deny ***

  const loadYaml = useCallback(({ uid, yaml }: { uid?: string; yaml: string }) => {
    const result = store.cimulator.policy.loadAsNewPolicy(
      { uid, policyObj: yaml },
      { autoselect: true, isHistoricalVersion: false },
    );
    if (!result.ok) {
      notifier
        .error(
          <div>
            <b>Invalid policy YAML</b>
            <ul>
              {result.errors.map((error, idx) => {
                return <li key={`${error}-${idx}`}>{error}</li>;
              })}
            </ul>
          </div>,
          {
            timeout: 20000,
          },
        )
        .show();
    }
  }, []);

  const safeOpenPolicy = useCallback((id: string) => {
    if (store.cimulator.policy.currentSpec?.wasEdit) {
      setReplaceWithNewPolicyDialog({ kind: 'other', id });
    } else {
      openPolicy(id);
    }
  }, []);

  const openPolicy = useCallback((id: string) => {
    ui.controls.openPolicy(
      store.clusterNamespaces.currCluster,
      store.clusterNamespaces.currNamespace,
      id,
      null,
    );
  }, []);

  const uploadPolicyFile = useFileUpload(['.yml', '.yaml'], yaml => loadYaml({ yaml }));

  const onSelectUploadPolicy = useCallback((event?: React.SyntheticEvent) => {
    if (store.cimulator.policy.hasSomeRules) {
      setReplaceWithNewPolicyDialog({ kind: 'upload' });
    } else {
      uploadPolicyFile(event);
    }
  }, []);
  // *** /Upload policy ***

  const onCloseFlowsTableSidebar = useCallback(() => {
    store.controls.selectTableFlow(null);
  }, []);

  // *** Filters Bar ***

  // *** Map ***
  const [mapVisibleHeight, setMapVisibleHeight] = useState<number | null>(null);
  const [mapWasDragged, setMapWasDragged] = useState<boolean>(false);

  const onPanelResize = useCallback((rp: DPResizeProps) => {
    const vh = rp.panelTopInPixels;
    setMapVisibleHeight(vh);
  }, []);

  const onMapDrag = useCallback((val: boolean) => setMapWasDragged(val), []);

  const onFlowsScrolledToBottom = useCallback(
    _.debounce(() => {
      if (!dataLayer.transferState.isWatchingHistory || ui.serviceMap.isTimescapeFlowsPageLoading)
        return;

      ui.advanceTimescapeFlowsPager();
    }, 50),
    [dataLayer, ui],
  );

  const onAggregationChange = useCallback((toggledTo: boolean) => {
    ui.controls.aggregationChanged(toggledTo);
  }, []);

  const onSidebarVerdictClick = useCallback((v: Verdict) => {
    store.controls.toggleVerdict(v);
  }, []);

  const onSidebarTCPFlagClick = useCallback((flag?: TCPFlagName, dir?: FilterDirection) => {
    if (!flag || !dir) return ui.controls.setFlowFilterGroups([]);

    ui.controls.setFlowFilterGroups([
      new FilterGroup([FilterEntry.newTCPFlag(flag).setDirection(dir)]),
    ]);
  }, []);

  const onSidebarLabelClick = useCallback((label?: KV, dir?: FilterDirection) => {
    if (!label || !dir) return ui.controls.setFlowFilterGroups([]);
    const labelStr = Labels.concatKV(label);

    ui.controls.setFlowFilterGroups([
      new FilterGroup([FilterEntry.newLabel(labelStr).setDirection(dir)]),
    ]);
  }, []);

  const onSidebarPodClick = useCallback((podSelector?: PodSelector, dir?: FilterDirection) => {
    if (!podSelector || !dir) return ui.controls.setFlowFilterGroups([]);

    ui.controls.setFlowFilterGroups([
      new FilterGroup([FilterEntry.newPodSelector(podSelector).setDirection(dir)]),
    ]);
  }, []);

  const onSidebarIdentityClick = useCallback((identity?: string, dir?: FilterDirection) => {
    if (identity == null || !dir) return ui.controls.setFlowFilterGroups([]);

    ui.controls.setFlowFilterGroups([
      new FilterGroup([FilterEntry.newIdentity(identity).setDirection(dir)]),
    ]);
  }, []);

  const onSidebarIpClick = useCallback((ip?: string, dir?: FilterDirection) => {
    if (ip == null || !dir) return ui.controls.setFlowFilterGroups([]);

    ui.controls.setFlowFilterGroups([new FilterGroup([FilterEntry.newIP(ip).setDirection(dir)])]);
  }, []);

  const onSidebarDnsClick = useCallback((dns?: string) => {
    if (dns == null) return ui.controls.setFlowFilterGroups([]);

    ui.controls.setFlowFilterGroups([
      new FilterGroup([FilterEntry.newDNS(dns).setDirection(FilterDirection.Either)]),
    ]);
  }, []);

  const onSidebarWorkloadClick = useCallback(
    (workload?: Workload, direction = FilterDirection.Either) => {
      if (workload == null) return ui.controls.setFlowFilterGroups([]);

      ui.controls.setFlowFilterGroups([
        new FilterGroup([FilterEntry.newWorkload(workload).setDirection(direction)]),
      ]);
    },
    [],
  );

  const onSidebarPortClick = useCallback((port?: number, direction = FilterDirection.Either) => {
    if (port == null) return ui.controls.setFlowFilterGroups([]);

    ui.controls.setFlowFilterGroups([
      new FilterGroup([FilterEntry.newPort(port).setDirection(direction)]),
    ]);
  }, []);

  const onSidebarProtocolClick = useCallback(
    (protocol?: string, direction = FilterDirection.Either) => {
      if (protocol == null) return ui.controls.setFlowFilterGroups([]);

      ui.controls.setFlowFilterGroups([
        new FilterGroup([FilterEntry.newProtocol(protocol).setDirection(direction)]),
      ]);
    },
    [],
  );

  const onLoginAgainClick = useCallback(() => {
    Authorization.goToSignin();
  }, []);

  const placementStrategy = useMemo(() => {
    return new CimulatorPlacementStrategy(store.cimulator.policy);
  }, []);

  const arrowStrategy = useMemo(() => {
    return new CimulatorArrowStrategy(placementStrategy, store.cimulator.policy);
  }, [placementStrategy]);

  const cardRenderer = useCallback(
    (card: PolicyCard) => {
      const coords = placementStrategy.cardsBBoxes.get(card.id);
      if (coords == null) return null;

      const onHeightChange = (h: number) => {
        return placementStrategy.setCardHeight(card.id, h);
      };

      return (
        <CimulatorPolicyCard
          key={card.id}
          card={card}
          selectedCardId={store.cimulator.controls.selectedCardId}
          selectedEndpointId={store.cimulator.controls.selectedEndpointId}
          coords={coords}
          defaultDenyIngress={Boolean(store.cimulator.policy.contextual.hasIngressRules)}
          defaultDenyEgress={Boolean(store.cimulator.policy.contextual.hasEgressRules)}
          onHeightChange={onHeightChange}
          onAccessPointCoords={placementStrategy.setAccessPointCoords}
          onToggleDefaultDenyIngress={toggleDefaultDenyIngress}
          onToggleDefaultDenyEgress={toggleDefaultDenyEgress}
        />
      );
    },
    [
      store.cimulator.controls.selectedCardId,
      store.cimulator.controls.selectedEndpointId,
      placementStrategy,
      placementStrategy.cardsBBoxes,
      store.cimulator.policy.contextual.visibleCardsList,
      store.cimulator.policy.contextual.hasIngressRules,
      store.cimulator.policy.contextual.hasEgressRules,
      toggleDefaultDenyIngress,
      toggleDefaultDenyEgress,
    ],
  );

  const memoFiltersConfig = React.useMemo(() => {
    return filtersConfig({
      cluster: {
        enabled:
          dataLayer.transferState.dataMode !== DataMode.CiliumStreaming &&
          store.clusterNamespaces.hasClusters,
        list: store.clusterNamespaces.clustersList,
        component: 'select',
      },
      namespace: {
        enabled: true,
        list: store.clusterNamespaces.currClusterNamespacesList,
        component: 'select',
      },
    });
  }, [
    dataLayer.transferState.dataMode,
    store.clusterNamespaces.clustersList,
    store.clusterNamespaces.currClusterNamespacesList,
  ]);
  // *** /Map ***

  return (
    <div className={css.app}>
      <div className={css.sidebar}>
        <NavigationSidebar
          clustersList={store.clusterNamespaces.clustersList}
          currCluster={store.clusterNamespaces.currCluster}
          transferState={dataLayer.transferState}
          namespacesList={store.clusterNamespaces.currClusterNamespacesList}
          currNamespace={store.clusterNamespaces.currNamespace}
          aggregation={store.controls.aggregation || undefined}
          onAggregationChange={onAggregationChange}
          timeRange={store.controls.timeRange}
          onTimeRangeChange={tr => ui.controls.timeRangeChanged(tr)}
          isFlowsPageLoading={ui.serviceMap.isTimescapeFlowsPageLoading}
          selectedVerdicts={store.controls.verdicts}
          onVerdictToggle={verdict => ui.controls.toggleVerdict(verdict)}
          onClusterNamespaceChange={ui.controls.clusterNamespaceChanged}
          onAppSelect={app => ui.applicationChanged(app)}
          onOpenPolicy={safeOpenPolicy}
          onLoginAgainClick={onLoginAgainClick}
        />
      </div>

      <div className={css.workspace}>
        <div className={css.searchInputWrapper}>
          <FlowFiltersInputNew
            filtersConfig={memoFiltersConfig}
            groups={store.controls.flowFilterGroups}
            onChange={filterGroups =>
              ui.controls.setFlowFilterGroups(filterGroups, {
                resetClusterNamespaceOnEmptyFilters: true,
              })
            }
          />
        </div>
        <div className={css.map}>
          <MapComponent
            placement={placementStrategy}
            cards={store.cimulator.policy.contextual.visibleCardsList ?? []}
            cardRenderer={cardRenderer}
            arrows={arrowStrategy}
            visibleHeight={mapVisibleHeight ?? 0}
            wasDragged={mapWasDragged}
            onCardHeightChange={placementStrategy.setCardHeight}
            onMapDrag={onMapDrag}
          />
        </div>

        <PoliciesPanel
          flows={dataLayer.flowDigests}
          filters={dataLayer.controls.modalFilters}
          currentNamespace={store.clusterNamespaces.currDescriptor}
          isDarkThemeEnabled={store.themes.isDarkTheme}
          policyYaml={store.cimulator.policy.policyCurrentSpecYaml ?? ''}
          policyVersions={store.cimulator.policy.getPolicyVersions(
            store.cimulator.controls.policyUuid,
          )}
          selectedPolicyVersion={store.cimulator.policy.getPolicyVersion(
            store.cimulator.controls.policyUuid,
            store.cimulator.controls.policyVersionShortId,
          )}
          onSelectPolicyVersion={onSelectPolicyVersion}
          onDownloadYaml={initDownloadPolicy}
          selectedCardId={store.cimulator.controls.selectedCardId}
          selectedEndpointId={store.cimulator.controls.selectedEndpointId}
          timeRange={store.controls.timeRange}
          isFlowsTableAvailable={!transferState.isTetragonOnlyMode}
          onUploadFlows={() => void 0}
          onPanelResize={onPanelResize}
          onTimeRangeChange={tr => ui.controls.timeRangeChanged(tr)}
          onFlowsScrolledToBottom={onFlowsScrolledToBottom}
          onSidebarVerdictClick={onSidebarVerdictClick}
          onSidebarTCPFlagClick={onSidebarTCPFlagClick}
          onSidebarLabelClick={onSidebarLabelClick}
          onSidebarPodClick={onSidebarPodClick}
          onSidebarIdentityClick={onSidebarIdentityClick}
          onSidebarIpClick={onSidebarIpClick}
          onSidebarDnsClick={onSidebarDnsClick}
          onSidebarWorkloadClick={onSidebarWorkloadClick}
          onSidebarPortClick={onSidebarPortClick}
          onSidebarProtocolClick={onSidebarProtocolClick}
          selectedFlow={store.controls.selectedTableFlow}
          selectedFlowIsLoading={ui.serviceMap.isFullFlowLoading}
          flowsPageIsLoading={ui.serviceMap.isTimescapeFlowsPageLoading}
          onSelectFlow={fd => ui.flowDigestSelected(fd)}
          onCloseFlowsTableSidebar={onCloseFlowsTableSidebar}
          onUploadPolicy={onSelectUploadPolicy}
          onCreateNewPolicy={onCreateNewPolicy}
          onOpenPolicy={safeOpenPolicy}
          onSidebarOpenProcessTreeForFlowClick={(f, d) =>
            ui.serviceMap.openProcessTreeForFlow(f, d)
          }
        />

        <PolicySuggestions height={mapVisibleHeight ? mapVisibleHeight : '100%'} />
      </div>

      <div ref={alertsPortal} className={css.alertsPortal} id="alerts-portal" />

      {alertsPortal.current && ingressEgressWarningAlert && (
        <RemoveIngressEgressDialog
          dir={ingressEgressWarningAlert}
          isOpen={Boolean(ingressEgressWarningAlert)}
          onCancel={hideIngressEgressWarningAlert}
          onConfirm={disableDefaultDeny}
          containerRef={alertsPortal.current}
        />
      )}

      {alertsPortal.current && (
        <ReplaceWithNewPolicyDialog
          isOpen={Boolean(replaceWithNewPolicyDialog?.kind)}
          text={
            replaceWithNewPolicyDialog?.kind === 'new' ? (
              <p>
                Unsaved network policy will be replaced with new empty policy.{' '}
                <a href="#" onClick={onDownloadPolicy}>
                  Download
                </a>{' '}
                current policy to save it.
              </p>
            ) : replaceWithNewPolicyDialog?.kind === 'upload' ? (
              <p>
                The editor will load policy from external URL. Unsaved network policy will be
                replaced with other policy.{' '}
                <a href="#" onClick={onDownloadPolicy}>
                  Download
                </a>{' '}
                current policy to save it.
              </p>
            ) : replaceWithNewPolicyDialog?.kind === 'other' ? (
              <p>
                You have unsaved changes that may be lost if you decide to continue.{' '}
                <a href="#" onClick={onDownloadPolicy}>
                  Download
                </a>{' '}
                the network policy to save the changes.
              </p>
            ) : null
          }
          buttonText={
            replaceWithNewPolicyDialog?.kind === 'new'
              ? 'Create new empty policy'
              : replaceWithNewPolicyDialog?.kind === 'upload'
                ? 'Upload other policy'
                : replaceWithNewPolicyDialog?.kind === 'other'
                  ? 'Continue'
                  : ''
          }
          onCancel={onCancelReplaceWithNewPolicyDialog}
          onConfirm={onConfirmReplaceWithNewPolicyDialog}
          containerRef={alertsPortal.current}
        />
      )}

      {alertsPortal.current && (
        <DownloadDialog
          isOpen={isDownloadDialogOpen}
          onClose={onDownloadDialogClose}
          containerRef={alertsPortal.current}
        />
      )}
    </div>
  );
});
