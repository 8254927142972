import { Flow } from '~/domain/flows';
import { LinkConnections } from '~/domain/interactions/connections';
import { Link, ServiceCard } from '~/domain/service-map';

import {
  Direction as FilterDirection,
  FilterEntry,
  FilterGroup,
  Kind as FilterKind,
} from './filter-entry';
import { filterFlow, filterFlowByGroup } from './filter-flow';
import { filterLink, filterLinkByGroup } from './filter-link';
import { filterService, filterServiceByGroup } from './filter-service';
import { Filters, FiltersKey, FiltersObject } from './filters';
import { FiltersDiff } from './filters-diff';

export { filterProcessEvent } from './process-events';
export { Filters, FiltersObject, FiltersKey };
export { FilterGroup, FilterEntry, FilterKind, FilterDirection };
export { filterFlow, filterFlowByGroup };
export { filterLink, filterLinkByGroup };
export { filterService, filterServiceByGroup };
export { FiltersDiff };

export type FilterResult = {
  flows: Flow[];
  links: Link[];
  services: ServiceCard[];
};

// some filtering cases explained:
// 1) given that we have filterGroup that keeps all flows from service A
// to service B. This means that we are not going to show response
// flows/links from service B to service A.
export const filter = (
  filters: Filters,
  flows: Flow[],
  cardsMap: Map<string, ServiceCard>,
  connections: LinkConnections,
): FilterResult => {
  const filteredFlows: Flow[] = [];
  const filteredLinks: Link[] = [];

  // flows have enough information to be filtered separately
  flows.forEach(f => {
    const passed = filterFlow(f, filters);
    if (!passed) return;

    filteredFlows.push(f);
  });

  const blacklistServices: Set<string> = new Set();
  const blacklistLinks: Set<string> = new Set();
  const newConnections: Map<string, Set<string>> = new Map();

  // Services do not have that information as we have "direction" filters
  // We do not know if card interact with another one
  cardsMap.forEach((card, id) => {
    // here we can only drop those cards, which surely not match
    const passed = filterService(card, filters);
    if (!passed) {
      blacklistServices.add(id);
      return;
    }

    // if there is no filterEntries then we should traverse all in/out
    // connections and use those senders/receivers, otherwise we should
    // use those services, who match filterEntries + related to them
    let checkOutgoings = !filters.filterGroups?.length;
    let checkIncomings = checkOutgoings;

    filters.filterGroups?.forEach(filterGroup => {
      if (!filterServiceByGroup(card.service, filterGroup)) return;

      checkOutgoings = checkOutgoings || filterGroup.entries.every(e => e.fromRequired);
      checkIncomings = checkIncomings || filterGroup.entries.every(e => e.toRequired);
    });

    // this means that every filterGroup skipped current card, but this
    // card can be saved by another card if it is presented in their
    // incomings/outgoings map
    if (!checkIncomings && !checkOutgoings) return;

    const incomings = connections.incomings.get(id);
    if (checkIncomings && incomings != null) {
      incomings.forEach((links, senderId) => {
        addSender(newConnections, id, senderId);
      });
    }

    const outgoings = connections.outgoings.get(id);
    if (checkOutgoings && outgoings != null) {
      outgoings.forEach((links, receiverId) => {
        addSender(newConnections, receiverId, id);
      });
    }
  });

  // we need this second cycle, cz in the first one we do not know if
  // there are other cards connecting to particular one (current)

  const clonedServices: Map<string, ServiceCard> = new Map();
  newConnections.forEach((senderIds, receiverId) => {
    if (blacklistServices.has(receiverId)) return;

    const incomings = connections.incomings.get(receiverId);
    if (incomings == null) return;

    senderIds.forEach(senderId => {
      if (blacklistServices.has(senderId)) return;

      const links = incomings.get(senderId);
      if (links == null) return;

      links.forEach(link => {
        if (blacklistLinks.has(link.id)) return;

        const passed = filterLink(link, filters);
        if (!passed) {
          blacklistLinks.add(link.id);
          return;
        }

        // if we got to this point, then sender and receiver must exist
        // and their degrees are definitely !== 0
        ensureService(clonedServices, cardsMap, senderId);
        const receiver = ensureService(clonedServices, cardsMap, receiverId);
        if (receiver == null) return;

        receiver.upsertAccessPointFromLink(link);
        filteredLinks.push(link);
      });
    });
  });

  return {
    flows: filteredFlows,
    links: filteredLinks,
    services: [...clonedServices.values()],
  };
};

// NOTE: connections is { receiverId -> Set(of all sender IDs)
const addSender = (connections: Map<string, Set<string>>, receiverId: string, senderId: string) => {
  if (!connections.has(receiverId)) {
    connections.set(receiverId, new Set());
  }

  const senders = connections.get(receiverId)!;
  senders.add(senderId);
};

const ensureService = (
  clonedServices: Map<string, ServiceCard>,
  originalServices: Map<string, ServiceCard>,
  serviceId: string,
): ServiceCard | null => {
  if (clonedServices.has(serviceId)) {
    return clonedServices.get(serviceId)!;
  }

  const original = originalServices.get(serviceId);
  if (original == null) return null;

  const cloned = original.clone().dropAccessPoints();
  clonedServices.set(serviceId, cloned);

  return cloned;
};
