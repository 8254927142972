import React from 'react';

import { Button, Checkbox, Menu, MenuItem, Popover } from '@blueprintjs/core';
import classnames from 'classnames';

import { toString as verdictToString, VerdictViewOptions } from '~/domain/helpers/verdict';
import { Verdict } from '~/domain/hubble';

import css from './VerdictsSelectorDropdown.scss';

export interface Props {
  selectedVerdicts: Set<Verdict>;
  onVerdictToggle?: (verdict: Verdict) => void;
}

const verdictsSet = Object.values(Verdict).reduce((acc, verdict) => {
  if (typeof verdict === 'number') acc.add(verdict as Verdict);
  return acc;
}, new Set<Verdict>());
const entries: VerdictEntry[] = prepareVerdicts(verdictsSet, { capitalizeFirstLetter: true });

export const VerdictsSelectorDropdown = React.memo(function VerdictsSelectorDropdown(props: Props) {
  const selectedTitle = useTitleComponent(props.selectedVerdicts);

  const list = React.useMemo(() => {
    return (
      <Menu className={css.list}>
        {entries.map(entry => {
          return (
            <VerdictItem
              key={entry.verdict}
              entry={entry}
              isActive={props.selectedVerdicts.has(entry.verdict)}
              onSelect={() => props.onVerdictToggle?.(entry.verdict)}
            />
          );
        })}
      </Menu>
    );
  }, [props.selectedVerdicts, props.onVerdictToggle]);

  return (
    <Popover minimal hasBackdrop content={list} targetTagName="div" position="right" fill>
      <Button
        fill={true}
        text={selectedTitle}
        className={css.titleWrapper}
        alignText="left"
        rightIcon={'double-caret-vertical'}
      />
    </Popover>
  );
});

interface VerdictItemProps {
  entry: VerdictEntry;
  isActive?: boolean;
  onSelect?: () => void;
}

const VerdictItem = React.memo(function VerdictItem(props: VerdictItemProps) {
  return (
    <MenuItem
      multiline
      className={css.item}
      text={
        <Checkbox checked={props.isActive} onChange={props.onSelect}>
          <span
            className={classnames({
              [css.dropped]: props.entry.verdict === Verdict.Dropped,
              [css.forwarded]: props.entry.verdict === Verdict.Forwarded,
            })}
          >
            {props.entry.title}
          </span>
        </Checkbox>
      }
      shouldDismissPopover={false}
    />
  );
});

function useTitleComponent(verdicts: Set<Verdict>) {
  return React.useMemo(() => {
    if (verdicts.size === 0) {
      return <div className={css.title}>Any verdict</div>;
    }

    const sorted = prepareVerdicts(verdicts, {
      shorten: verdicts.size > 2,
      capitalizeFirstLetter: true,
    }).map((entry, idx) => {
      const isNotLast = idx < verdicts.size - 1;
      return (
        <>
          <span
            key={entry.verdict}
            className={classnames({
              [css.dropped]: entry.verdict === Verdict.Dropped,
              [css.forwarded]: entry.verdict === Verdict.Forwarded,
            })}
          >
            {entry.title}
          </span>
          {isNotLast && ', '}
        </>
      );
    });

    return (
      <div className={css.title}>
        <div className={css.fluid}>
          <div className={css.fluidContent}>{sorted}</div>
        </div>
      </div>
    );
  }, [verdicts]);
}

interface VerdictEntry {
  verdict: Verdict;
  title: string;
}

function prepareVerdicts(origin: Set<Verdict>, opts?: VerdictViewOptions): VerdictEntry[] {
  const tmp = new Set(origin);
  tmp.delete(Verdict.Forwarded);
  tmp.delete(Verdict.Dropped);
  tmp.delete(Verdict.Unknown);

  function map(verdict: Verdict): VerdictEntry {
    return { verdict, title: verdictToString(verdict, opts) };
  }

  const sorted: VerdictEntry[] = Array.from(tmp)
    .map(map)
    .sort((a, b) => a.title.localeCompare(b.title));

  if (origin.has(Verdict.Dropped)) sorted.unshift(map(Verdict.Dropped));
  if (origin.has(Verdict.Forwarded)) sorted.unshift(map(Verdict.Forwarded));
  if (origin.has(Verdict.Unknown)) sorted.push(map(Verdict.Unknown));

  return sorted;
}
