/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter add_pb_suffix,eslint_disable,ts_nocheck,generate_dependencies,long_type_number,use_proto_field_name
// @generated from protobuf file "tetragon/tetragon.proto" (package "tetragon", syntax proto3)
// tslint:disable
// @ts-nocheck
//
// This file is copied from OSS, DO NOT EDIT
//
//
// SPDX-License-Identifier: Apache-2.0
// Copyright Authors of Hubble
//
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { BoolValue } from "../google/protobuf/wrappers_pb";
import { ProcessPrivilegesChanged } from "./capabilities_pb";
import { SecureBitsType } from "./capabilities_pb";
import { Int32Value } from "../google/protobuf/wrappers_pb";
import { CapabilitiesType } from "./capabilities_pb";
import { UInt32Value } from "../google/protobuf/wrappers_pb";
import { Timestamp } from "../google/protobuf/timestamp_pb";
/**
 * @generated from protobuf message tetragon.Image
 */
export interface Image {
    /**
     * Identifier of the container image composed of the registry path and the
     * sha256.
     *
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * Name of the container image composed of the registry path and the tag.
     *
     * @generated from protobuf field: string name = 2;
     */
    name: string;
}
/**
 * @generated from protobuf message tetragon.Container
 */
export interface Container {
    /**
     * Identifier of the container.
     *
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * Name of the container.
     *
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * Image of the container.
     *
     * @generated from protobuf field: tetragon.Image image = 3;
     */
    image?: Image;
    /**
     * Start time of the container.
     *
     * @generated from protobuf field: google.protobuf.Timestamp start_time = 4;
     */
    start_time?: Timestamp;
    /**
     * Process identifier in the container namespace.
     *
     * @generated from protobuf field: google.protobuf.UInt32Value pid = 5;
     */
    pid?: UInt32Value;
    /**
     * If this is set true, it means that the process might have been originated from
     * a Kubernetes exec probe. For this field to be true, the following must be true:
     * 1. The binary field matches the first element of the exec command list for either
     *    liveness or readiness probe excluding the basename. For example, "/bin/ls"
     *    and "ls" are considered a match.
     * 2. The arguments field exactly matches the rest of the exec command list.
     *
     * @generated from protobuf field: bool maybe_exec_probe = 13;
     */
    maybe_exec_probe: boolean;
}
/**
 * @generated from protobuf message tetragon.Pod
 */
export interface Pod {
    /**
     * Kubernetes namespace of the Pod.
     *
     * @generated from protobuf field: string namespace = 1;
     */
    namespace: string;
    /**
     * Name of the Pod.
     *
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * Container of the Pod from which the process that triggered the event
     * originates.
     *
     * @generated from protobuf field: tetragon.Container container = 4;
     */
    container?: Container;
    /**
     * Contains all the labels of the pod.
     *
     * @generated from protobuf field: map<string, string> pod_labels = 5;
     */
    pod_labels: {
        [key: string]: string;
    };
    /**
     * Kubernetes workload of the Pod.
     *
     * @generated from protobuf field: string workload = 6;
     */
    workload: string;
    /**
     * Kubernetes workload kind (e.g. "Deployment", "DaemonSet") of the Pod.
     *
     * @generated from protobuf field: string workload_kind = 7;
     */
    workload_kind: string;
}
/**
 * @generated from protobuf message tetragon.Capabilities
 */
export interface Capabilities {
    /**
     * Permitted set indicates what capabilities the process can use. This is a
     * limiting superset for the effective capabilities that the thread may
     * assume. It is also a limiting superset for the capabilities that may be
     * added to the inheritable set by a thread without the CAP_SETPCAP in its
     * effective set.
     *
     * @generated from protobuf field: repeated tetragon.CapabilitiesType permitted = 1;
     */
    permitted: CapabilitiesType[];
    /**
     * Effective set indicates what capabilities are active in a process. This
     * is the set used by the kernel to perform permission checks for the
     * thread.
     *
     * @generated from protobuf field: repeated tetragon.CapabilitiesType effective = 2;
     */
    effective: CapabilitiesType[];
    /**
     * Inheritable set indicates which capabilities will be inherited by the
     * current process when running as a root user.
     *
     * @generated from protobuf field: repeated tetragon.CapabilitiesType inheritable = 3;
     */
    inheritable: CapabilitiesType[];
}
/**
 * @generated from protobuf message tetragon.Namespace
 */
export interface Namespace {
    /**
     * Inode number of the namespace.
     *
     * @generated from protobuf field: uint32 inum = 1;
     */
    inum: number;
    /**
     * Indicates if namespace belongs to host.
     *
     * @generated from protobuf field: bool is_host = 2;
     */
    is_host: boolean;
}
/**
 * @generated from protobuf message tetragon.Namespaces
 */
export interface Namespaces {
    /**
     * Hostname and NIS domain name.
     *
     * @generated from protobuf field: tetragon.Namespace uts = 1;
     */
    uts?: Namespace;
    /**
     * System V IPC, POSIX message queues.
     *
     * @generated from protobuf field: tetragon.Namespace ipc = 2;
     */
    ipc?: Namespace;
    /**
     * Mount points.
     *
     * @generated from protobuf field: tetragon.Namespace mnt = 3;
     */
    mnt?: Namespace;
    /**
     * Process IDs.
     *
     * @generated from protobuf field: tetragon.Namespace pid = 4;
     */
    pid?: Namespace;
    /**
     * Process IDs for children processes.
     *
     * @generated from protobuf field: tetragon.Namespace pid_for_children = 5;
     */
    pid_for_children?: Namespace;
    /**
     * Network devices, stacks, ports, etc.
     *
     * @generated from protobuf field: tetragon.Namespace net = 6;
     */
    net?: Namespace;
    /**
     * Boot and monotonic clocks.
     *
     * @generated from protobuf field: tetragon.Namespace time = 7;
     */
    time?: Namespace;
    /**
     * Boot and monotonic clocks for children processes.
     *
     * @generated from protobuf field: tetragon.Namespace time_for_children = 8;
     */
    time_for_children?: Namespace;
    /**
     * Cgroup root directory.
     *
     * @generated from protobuf field: tetragon.Namespace cgroup = 9;
     */
    cgroup?: Namespace;
    /**
     * User and group IDs.
     *
     * @generated from protobuf field: tetragon.Namespace user = 10;
     */
    user?: Namespace;
}
/**
 * @generated from protobuf message tetragon.UserNamespace
 */
export interface UserNamespace {
    /**
     * Nested level of the user namespace. Init or host user namespace is at level 0.
     *
     * @generated from protobuf field: google.protobuf.Int32Value level = 1;
     */
    level?: Int32Value;
    /**
     * The owner user ID of the namespace
     *
     * @generated from protobuf field: google.protobuf.UInt32Value uid = 2;
     */
    uid?: UInt32Value;
    /**
     * The owner group ID of the namepace.
     *
     * @generated from protobuf field: google.protobuf.UInt32Value gid = 3;
     */
    gid?: UInt32Value;
    /**
     * The user namespace details that include the inode number of the namespace.
     *
     * @generated from protobuf field: tetragon.Namespace ns = 4;
     */
    ns?: Namespace;
}
/**
 * @generated from protobuf message tetragon.ProcessCredentials
 */
export interface ProcessCredentials {
    /**
     * The real user ID of the process' owner.
     *
     * @generated from protobuf field: google.protobuf.UInt32Value uid = 1;
     */
    uid?: UInt32Value;
    /**
     * The real group ID of the process' owner.
     *
     * @generated from protobuf field: google.protobuf.UInt32Value gid = 2;
     */
    gid?: UInt32Value;
    /**
     * The effective user ID used for permission checks.
     *
     * @generated from protobuf field: google.protobuf.UInt32Value euid = 3;
     */
    euid?: UInt32Value;
    /**
     * The effective group ID used for permission checks.
     *
     * @generated from protobuf field: google.protobuf.UInt32Value egid = 4;
     */
    egid?: UInt32Value;
    /**
     * The saved user ID.
     *
     * @generated from protobuf field: google.protobuf.UInt32Value suid = 5;
     */
    suid?: UInt32Value;
    /**
     * The saved group ID.
     *
     * @generated from protobuf field: google.protobuf.UInt32Value sgid = 6;
     */
    sgid?: UInt32Value;
    /**
     * the filesystem user ID used for filesystem access checks. Usually equals the euid.
     *
     * @generated from protobuf field: google.protobuf.UInt32Value fsuid = 7;
     */
    fsuid?: UInt32Value;
    /**
     * The filesystem group ID used for filesystem access checks. Usually equals the egid.
     *
     * @generated from protobuf field: google.protobuf.UInt32Value fsgid = 8;
     */
    fsgid?: UInt32Value;
    /**
     * Secure management flags
     *
     * @generated from protobuf field: repeated tetragon.SecureBitsType securebits = 9;
     */
    securebits: SecureBitsType[];
    /**
     * Set of capabilities that define the permissions the process can execute with.
     *
     * @generated from protobuf field: tetragon.Capabilities caps = 10;
     */
    caps?: Capabilities;
    /**
     * User namespace where the UIDs, GIDs and capabilities are relative to.
     *
     * @generated from protobuf field: tetragon.UserNamespace user_ns = 11;
     */
    user_ns?: UserNamespace;
}
/**
 * @generated from protobuf message tetragon.InodeProperties
 */
export interface InodeProperties {
    /**
     * The inode number
     *
     * @generated from protobuf field: uint64 number = 1;
     */
    number: number;
    /**
     * The inode links on the file system. If zero means the file is only in memory
     *
     * @generated from protobuf field: google.protobuf.UInt32Value links = 2;
     */
    links?: UInt32Value;
}
/**
 * @generated from protobuf message tetragon.FileProperties
 */
export interface FileProperties {
    /**
     * Inode of the file
     *
     * @generated from protobuf field: tetragon.InodeProperties inode = 1;
     */
    inode?: InodeProperties;
    /**
     * Path of the file
     *
     * @generated from protobuf field: string path = 2;
     */
    path: string;
}
/**
 * @generated from protobuf message tetragon.BinaryProperties
 */
export interface BinaryProperties {
    /**
     * If set then this is the set user ID used for execution
     *
     * @generated from protobuf field: google.protobuf.UInt32Value setuid = 1;
     */
    setuid?: UInt32Value;
    /**
     * If set then this is the set group ID used for execution
     *
     * @generated from protobuf field: google.protobuf.UInt32Value setgid = 2;
     */
    setgid?: UInt32Value;
    /**
     * The reasons why this binary execution changed privileges. Usually this happens when the process executes
     * a binary with the set-user-ID to root or file capability sets.
     * The final granted privileges can be listed inside the `process_credentials` or capabilities fields part of of the `process` object.
     *
     * @generated from protobuf field: repeated tetragon.ProcessPrivilegesChanged privileges_changed = 3;
     */
    privileges_changed: ProcessPrivilegesChanged[];
    /**
     * File properties in case the executed binary is:
     * 1. An anonymous shared memory file https://man7.org/linux/man-pages/man7/shm_overview.7.html.
     * 2. An anonymous file obtained with memfd API https://man7.org/linux/man-pages/man2/memfd_create.2.html.
     * 3. Or it was deleted from the file system.
     *
     * @generated from protobuf field: tetragon.FileProperties file = 4;
     */
    file?: FileProperties;
}
/**
 * User records
 *
 * @generated from protobuf message tetragon.UserRecord
 */
export interface UserRecord {
    /**
     * The UNIX username for this record. Corresponds to `pw_name` field of [struct passwd](https://man7.org/linux/man-pages/man3/getpwnam.3.html)
     * and the `sp_namp` field of [struct spwd](https://man7.org/linux/man-pages/man3/getspnam.3.html).
     *
     * @generated from protobuf field: string name = 1;
     */
    name: string;
}
/**
 * @generated from protobuf message tetragon.Process
 */
export interface Process {
    /**
     * Exec ID uniquely identifies the process over time across all the nodes in the cluster.
     *
     * @generated from protobuf field: string exec_id = 1;
     */
    exec_id: string;
    /**
     * Process identifier from host PID namespace.
     *
     * @generated from protobuf field: google.protobuf.UInt32Value pid = 2;
     */
    pid?: UInt32Value;
    /**
     * The effective User identifier used for permission checks. This field maps to the
     * 'ProcessCredentials.euid' field. Run with the `--enable-process-cred` flag to
     * enable 'ProcessCredentials' and get all the User and Group identifiers.
     *
     * @generated from protobuf field: google.protobuf.UInt32Value uid = 3;
     */
    uid?: UInt32Value;
    /**
     * Current working directory of the process.
     *
     * @generated from protobuf field: string cwd = 4;
     */
    cwd: string;
    /**
     * Absolute path of the executed binary.
     *
     * @generated from protobuf field: string binary = 5;
     */
    binary: string;
    /**
     * Arguments passed to the binary at execution.
     *
     * @generated from protobuf field: string arguments = 6;
     */
    arguments: string;
    /**
     * Flags are for debugging purposes only and should not be considered a
     * reliable source of information. They hold various information about
     * which syscalls generated events, use of internal Tetragon buffers,
     * errors and more.
     * - `execve` This event is generated by an execve syscall for a new
     * process. See procFs for the other option. A correctly formatted event
     * should either set execve or procFS (described next).
     * - `procFS` This event is generated from a proc interface. This happens
     * at Tetragon init when existing processes are being loaded into Tetragon
     * event buffer. All events should have either execve or procFS set.
     * - `truncFilename` Indicates a truncated processes filename because the
     * buffer size is too small to contain the process filename. Consider
     * increasing buffer size to avoid this.
     * - `truncArgs` Indicates truncated the processes arguments because the
     * buffer size was too small to contain all exec args. Consider increasing
     * buffer size to avoid this.
     * - `taskWalk` Primarily useful for debugging. Indicates a walked process
     * hierarchy to find a parent process in the Tetragon buffer. This may
     * happen when we did not receive an exec event for the immediate parent of
     * a process. Typically means we are looking at a fork that in turn did
     * another fork we don't currently track fork events exactly and instead
     * push an event with the original parent exec data. This flag can provide
     * this insight into the event if needed.
     * - `miss` An error flag indicating we could not find parent info in the
     * Tetragon event buffer. If this is set it should be reported to Tetragon
     * developers for debugging. Tetragon will do its best to recover
     * information about the process from available kernel data structures
     * instead of using cached info in this case. However, args will not be
     * available.
     * - `needsAUID` An internal flag for Tetragon to indicate the audit has
     * not yet been resolved. The BPF hooks look at this flag to determine if
     * probing the audit system is necessary.
     * - `errorFilename` An error flag indicating an error happened while
     * reading the filename. If this is set it should be reported to Tetragon
     * developers for debugging.
     * - `errorArgs` An error flag indicating an error happened while reading
     * the process args. If this is set it should be reported to Tetragon
     * developers for debugging
     * - `needsCWD` An internal flag for Tetragon to indicate the current
     * working directory has not yet been resolved. The Tetragon hooks look at
     * this flag to determine if probing the CWD is necessary.
     * - `noCWDSupport` Indicates that CWD is removed from the event because
     * the buffer size is too small. Consider increasing buffer size to avoid
     * this.
     * - `rootCWD` Indicates that CWD is the root directory. This is necessary
     * to inform readers the CWD is not in the event buffer and is '/' instead.
     * - `errorCWD` An error flag indicating an error occurred while reading
     * the CWD of a process. If this is set it should be reported to Tetragon
     * developers for debugging.
     * - `clone` Indicates the process issued a clone before exec*. This is the
     * general flow to exec* a new process, however its possible to replace the
     * current process with a new process by doing an exec* without a clone. In
     * this case the flag will be omitted and the same PID will be used by the
     * kernel for both the old process and the newly exec'd process.
     *
     * @generated from protobuf field: string flags = 7;
     */
    flags: string;
    /**
     * Start time of the execution.
     *
     * @generated from protobuf field: google.protobuf.Timestamp start_time = 8;
     */
    start_time?: Timestamp;
    /**
     * Audit user ID, this ID is assigned to a user upon login and is inherited
     * by every process even when the user's identity changes. For example, by
     * switching user accounts with su - john.
     *
     * @generated from protobuf field: google.protobuf.UInt32Value auid = 9;
     */
    auid?: UInt32Value;
    /**
     * Information about the the Kubernetes Pod where the event originated.
     *
     * @generated from protobuf field: tetragon.Pod pod = 10;
     */
    pod?: Pod;
    /**
     * The 15 first digits of the container ID.
     *
     * @generated from protobuf field: string docker = 11;
     */
    docker: string;
    /**
     * Exec ID of the parent process.
     *
     * @generated from protobuf field: string parent_exec_id = 12;
     */
    parent_exec_id: string;
    /**
     * Reference counter from the Tetragon process cache.
     *
     * @generated from protobuf field: uint32 refcnt = 13;
     */
    refcnt: number;
    /**
     * Set of capabilities that define the permissions the process can execute with.
     *
     * @generated from protobuf field: tetragon.Capabilities cap = 14;
     */
    cap?: Capabilities;
    /**
     * Linux namespaces of the process, disabled by default, can be enabled by
     * the `--enable-process-ns` flag.
     *
     * @generated from protobuf field: tetragon.Namespaces ns = 15;
     */
    ns?: Namespaces;
    /**
     * Thread ID, note that for the thread group leader, tid is equal to pid.
     *
     * @generated from protobuf field: google.protobuf.UInt32Value tid = 16;
     */
    tid?: UInt32Value;
    /**
     * Process credentials, disabled by default, can be enabled by the
     * `--enable-process-cred` flag.
     *
     * @generated from protobuf field: tetragon.ProcessCredentials process_credentials = 17;
     */
    process_credentials?: ProcessCredentials;
    /**
     * Executed binary properties. This field is only available on ProcessExec events.
     *
     * @generated from protobuf field: tetragon.BinaryProperties binary_properties = 18;
     */
    binary_properties?: BinaryProperties;
    /**
     * UserRecord contains user information about the event.
     *
     * UserRecord is only supported when i) Tetragon is running as a systemd service or directly on the host, and
     *  ii) when  `--username-metadata` is set to "unix". In this case, the information is retrieved from
     * the traditional user database `/etc/passwd` and no name services lookups are performed.
     * The resolution will only be attempted for processes in the host namespace.
     * Note that this resolution happens in user-space, which means that mapping might have changed
     * between the in-kernel BPF hook being executed and the username resolution.
     *
     * @generated from protobuf field: tetragon.UserRecord user = 19;
     */
    user?: UserRecord;
}
/**
 * @generated from protobuf message tetragon.ProcessExec
 */
export interface ProcessExec {
    /**
     * Process that triggered the exec.
     *
     * @generated from protobuf field: tetragon.Process process = 1;
     */
    process?: Process;
    /**
     * Immediate parent of the process.
     *
     * @generated from protobuf field: tetragon.Process parent = 2;
     */
    parent?: Process;
    /**
     * Ancestors of the process beyond the immediate parent.
     *
     * @generated from protobuf field: repeated tetragon.Process ancestors = 3;
     */
    ancestors: Process[];
}
/**
 * @generated from protobuf message tetragon.ProcessExit
 */
export interface ProcessExit {
    /**
     * Process that triggered the exit.
     *
     * @generated from protobuf field: tetragon.Process process = 1;
     */
    process?: Process;
    /**
     * Immediate parent of the process.
     *
     * @generated from protobuf field: tetragon.Process parent = 2;
     */
    parent?: Process;
    /**
     * Signal that the process received when it exited, for example SIGKILL or
     * SIGTERM (list all signal names with `kill -l`). If there is no signal
     * handler implemented for a specific process, we report the exit status
     * code that can be found in the status field.
     *
     * @generated from protobuf field: string signal = 3;
     */
    signal: string;
    /**
     * Status code on process exit. For example, the status code can indicate
     * if an error was encountered or the program exited successfully.
     *
     * @generated from protobuf field: uint32 status = 4;
     */
    status: number;
    /**
     * Date and time of the event.
     *
     * @generated from protobuf field: google.protobuf.Timestamp time = 5;
     */
    time?: Timestamp;
}
/**
 * @generated from protobuf message tetragon.KprobeSock
 */
export interface KprobeSock {
    /**
     * @generated from protobuf field: string family = 1;
     */
    family: string;
    /**
     * @generated from protobuf field: string type = 2;
     */
    type: string;
    /**
     * @generated from protobuf field: string protocol = 3;
     */
    protocol: string;
    /**
     * @generated from protobuf field: uint32 mark = 4;
     */
    mark: number;
    /**
     * @generated from protobuf field: uint32 priority = 5;
     */
    priority: number;
    /**
     * @generated from protobuf field: string saddr = 6;
     */
    saddr: string;
    /**
     * @generated from protobuf field: string daddr = 7;
     */
    daddr: string;
    /**
     * @generated from protobuf field: uint32 sport = 8;
     */
    sport: number;
    /**
     * @generated from protobuf field: uint32 dport = 9;
     */
    dport: number;
    /**
     * @generated from protobuf field: uint64 cookie = 10;
     */
    cookie: number;
    /**
     * @generated from protobuf field: string state = 11;
     */
    state: string;
}
/**
 * @generated from protobuf message tetragon.KprobeSkb
 */
export interface KprobeSkb {
    /**
     * @generated from protobuf field: uint32 hash = 1;
     */
    hash: number;
    /**
     * @generated from protobuf field: uint32 len = 2;
     */
    len: number;
    /**
     * @generated from protobuf field: uint32 priority = 3;
     */
    priority: number;
    /**
     * @generated from protobuf field: uint32 mark = 4;
     */
    mark: number;
    /**
     * @generated from protobuf field: string saddr = 5;
     */
    saddr: string;
    /**
     * @generated from protobuf field: string daddr = 6;
     */
    daddr: string;
    /**
     * @generated from protobuf field: uint32 sport = 7;
     */
    sport: number;
    /**
     * @generated from protobuf field: uint32 dport = 8;
     */
    dport: number;
    /**
     * @generated from protobuf field: uint32 proto = 9;
     */
    proto: number;
    /**
     * @generated from protobuf field: uint32 sec_path_len = 10;
     */
    sec_path_len: number;
    /**
     * @generated from protobuf field: uint32 sec_path_olen = 11;
     */
    sec_path_olen: number;
    /**
     * @generated from protobuf field: string protocol = 12;
     */
    protocol: string;
    /**
     * @generated from protobuf field: string family = 13;
     */
    family: string;
}
/**
 * @generated from protobuf message tetragon.KprobeNetDev
 */
export interface KprobeNetDev {
    /**
     * @generated from protobuf field: string name = 1;
     */
    name: string;
}
/**
 * @generated from protobuf message tetragon.KprobePath
 */
export interface KprobePath {
    /**
     * @generated from protobuf field: string mount = 1;
     */
    mount: string;
    /**
     * @generated from protobuf field: string path = 2;
     */
    path: string;
    /**
     * @generated from protobuf field: string flags = 3;
     */
    flags: string;
    /**
     * @generated from protobuf field: string permission = 4;
     */
    permission: string;
}
/**
 * @generated from protobuf message tetragon.KprobeFile
 */
export interface KprobeFile {
    /**
     * @generated from protobuf field: string mount = 1;
     */
    mount: string;
    /**
     * @generated from protobuf field: string path = 2;
     */
    path: string;
    /**
     * @generated from protobuf field: string flags = 3;
     */
    flags: string;
    /**
     * @generated from protobuf field: string permission = 4;
     */
    permission: string;
}
/**
 * @generated from protobuf message tetragon.KprobeTruncatedBytes
 */
export interface KprobeTruncatedBytes {
    /**
     * @generated from protobuf field: bytes bytes_arg = 1;
     */
    bytes_arg: Uint8Array;
    /**
     * @generated from protobuf field: uint64 orig_size = 2;
     */
    orig_size: number;
}
/**
 * @generated from protobuf message tetragon.KprobeCred
 */
export interface KprobeCred {
    /**
     * @generated from protobuf field: repeated tetragon.CapabilitiesType permitted = 1;
     */
    permitted: CapabilitiesType[];
    /**
     * @generated from protobuf field: repeated tetragon.CapabilitiesType effective = 2;
     */
    effective: CapabilitiesType[];
    /**
     * @generated from protobuf field: repeated tetragon.CapabilitiesType inheritable = 3;
     */
    inheritable: CapabilitiesType[];
}
/**
 * @generated from protobuf message tetragon.KprobeLinuxBinprm
 */
export interface KprobeLinuxBinprm {
    /**
     * @generated from protobuf field: string path = 1;
     */
    path: string;
    /**
     * @generated from protobuf field: string flags = 2;
     */
    flags: string;
    /**
     * @generated from protobuf field: string permission = 3;
     */
    permission: string;
}
/**
 * @generated from protobuf message tetragon.KprobeCapability
 */
export interface KprobeCapability {
    /**
     * @generated from protobuf field: google.protobuf.Int32Value value = 1;
     */
    value?: Int32Value;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
}
/**
 * @generated from protobuf message tetragon.KprobeUserNamespace
 */
export interface KprobeUserNamespace {
    /**
     * @generated from protobuf field: google.protobuf.Int32Value level = 1;
     */
    level?: Int32Value;
    /**
     * @generated from protobuf field: google.protobuf.UInt32Value owner = 2;
     */
    owner?: UInt32Value;
    /**
     * @generated from protobuf field: google.protobuf.UInt32Value group = 3;
     */
    group?: UInt32Value;
    /**
     * @generated from protobuf field: tetragon.Namespace ns = 4;
     */
    ns?: Namespace;
}
/**
 * @generated from protobuf message tetragon.KprobeBpfAttr
 */
export interface KprobeBpfAttr {
    /**
     * @generated from protobuf field: string ProgType = 1 [json_name = "ProgType"];
     */
    ProgType: string;
    /**
     * @generated from protobuf field: uint32 InsnCnt = 2 [json_name = "InsnCnt"];
     */
    InsnCnt: number;
    /**
     * @generated from protobuf field: string ProgName = 3 [json_name = "ProgName"];
     */
    ProgName: string;
}
/**
 * @generated from protobuf message tetragon.KprobePerfEvent
 */
export interface KprobePerfEvent {
    /**
     * @generated from protobuf field: string KprobeFunc = 1 [json_name = "KprobeFunc"];
     */
    KprobeFunc: string;
    /**
     * @generated from protobuf field: string Type = 2 [json_name = "Type"];
     */
    Type: string;
    /**
     * @generated from protobuf field: uint64 Config = 3 [json_name = "Config"];
     */
    Config: number;
    /**
     * @generated from protobuf field: uint64 ProbeOffset = 4 [json_name = "ProbeOffset"];
     */
    ProbeOffset: number;
}
/**
 * @generated from protobuf message tetragon.KprobeBpfMap
 */
export interface KprobeBpfMap {
    /**
     * @generated from protobuf field: string MapType = 1 [json_name = "MapType"];
     */
    MapType: string;
    /**
     * @generated from protobuf field: uint32 KeySize = 2 [json_name = "KeySize"];
     */
    KeySize: number;
    /**
     * @generated from protobuf field: uint32 ValueSize = 3 [json_name = "ValueSize"];
     */
    ValueSize: number;
    /**
     * @generated from protobuf field: uint32 MaxEntries = 4 [json_name = "MaxEntries"];
     */
    MaxEntries: number;
    /**
     * @generated from protobuf field: string MapName = 5 [json_name = "MapName"];
     */
    MapName: string;
}
/**
 * @generated from protobuf message tetragon.KprobeArgument
 */
export interface KprobeArgument {
    /**
     * @generated from protobuf oneof: arg
     */
    arg: {
        oneofKind: "string_arg";
        /**
         * @generated from protobuf field: string string_arg = 1;
         */
        string_arg: string;
    } | {
        oneofKind: "int_arg";
        /**
         * @generated from protobuf field: int32 int_arg = 2;
         */
        int_arg: number;
    } | {
        oneofKind: "skb_arg";
        /**
         * @generated from protobuf field: tetragon.KprobeSkb skb_arg = 3;
         */
        skb_arg: KprobeSkb;
    } | {
        oneofKind: "size_arg";
        /**
         * @generated from protobuf field: uint64 size_arg = 4;
         */
        size_arg: number;
    } | {
        oneofKind: "bytes_arg";
        /**
         * @generated from protobuf field: bytes bytes_arg = 5;
         */
        bytes_arg: Uint8Array;
    } | {
        oneofKind: "path_arg";
        /**
         * @generated from protobuf field: tetragon.KprobePath path_arg = 6;
         */
        path_arg: KprobePath;
    } | {
        oneofKind: "file_arg";
        /**
         * @generated from protobuf field: tetragon.KprobeFile file_arg = 7;
         */
        file_arg: KprobeFile;
    } | {
        oneofKind: "truncated_bytes_arg";
        /**
         * @generated from protobuf field: tetragon.KprobeTruncatedBytes truncated_bytes_arg = 8;
         */
        truncated_bytes_arg: KprobeTruncatedBytes;
    } | {
        oneofKind: "sock_arg";
        /**
         * @generated from protobuf field: tetragon.KprobeSock sock_arg = 9;
         */
        sock_arg: KprobeSock;
    } | {
        oneofKind: "cred_arg";
        /**
         * @generated from protobuf field: tetragon.KprobeCred cred_arg = 10;
         */
        cred_arg: KprobeCred;
    } | {
        oneofKind: "long_arg";
        /**
         * @generated from protobuf field: int64 long_arg = 11;
         */
        long_arg: number;
    } | {
        oneofKind: "bpf_attr_arg";
        /**
         * @generated from protobuf field: tetragon.KprobeBpfAttr bpf_attr_arg = 12;
         */
        bpf_attr_arg: KprobeBpfAttr;
    } | {
        oneofKind: "perf_event_arg";
        /**
         * @generated from protobuf field: tetragon.KprobePerfEvent perf_event_arg = 13;
         */
        perf_event_arg: KprobePerfEvent;
    } | {
        oneofKind: "bpf_map_arg";
        /**
         * @generated from protobuf field: tetragon.KprobeBpfMap bpf_map_arg = 14;
         */
        bpf_map_arg: KprobeBpfMap;
    } | {
        oneofKind: "uint_arg";
        /**
         * @generated from protobuf field: uint32 uint_arg = 15;
         */
        uint_arg: number;
    } | {
        oneofKind: "user_namespace_arg";
        /**
         * @deprecated
         * @generated from protobuf field: tetragon.KprobeUserNamespace user_namespace_arg = 16 [deprecated = true];
         */
        user_namespace_arg: KprobeUserNamespace;
    } | {
        oneofKind: "capability_arg";
        /**
         * @generated from protobuf field: tetragon.KprobeCapability capability_arg = 17;
         */
        capability_arg: KprobeCapability;
    } | {
        oneofKind: "process_credentials_arg";
        /**
         * @generated from protobuf field: tetragon.ProcessCredentials process_credentials_arg = 19;
         */
        process_credentials_arg: ProcessCredentials;
    } | {
        oneofKind: "user_ns_arg";
        /**
         * @generated from protobuf field: tetragon.UserNamespace user_ns_arg = 20;
         */
        user_ns_arg: UserNamespace;
    } | {
        oneofKind: "module_arg";
        /**
         * @generated from protobuf field: tetragon.KernelModule module_arg = 21;
         */
        module_arg: KernelModule;
    } | {
        oneofKind: "kernel_cap_t_arg";
        /**
         * @generated from protobuf field: string kernel_cap_t_arg = 22;
         */
        kernel_cap_t_arg: string; // Capabilities in hexadecimal format.
    } | {
        oneofKind: "cap_inheritable_arg";
        /**
         * @generated from protobuf field: string cap_inheritable_arg = 23;
         */
        cap_inheritable_arg: string; // Capabilities inherited by a forked process in hexadecimal format.
    } | {
        oneofKind: "cap_permitted_arg";
        /**
         * @generated from protobuf field: string cap_permitted_arg = 24;
         */
        cap_permitted_arg: string; // Capabilities that are currently permitted in hexadecimal format.
    } | {
        oneofKind: "cap_effective_arg";
        /**
         * @generated from protobuf field: string cap_effective_arg = 25;
         */
        cap_effective_arg: string; // Capabilities that are actually used in hexadecimal format.
    } | {
        oneofKind: "linux_binprm_arg";
        /**
         * @generated from protobuf field: tetragon.KprobeLinuxBinprm linux_binprm_arg = 26;
         */
        linux_binprm_arg: KprobeLinuxBinprm;
    } | {
        oneofKind: "net_dev_arg";
        /**
         * @generated from protobuf field: tetragon.KprobeNetDev net_dev_arg = 27;
         */
        net_dev_arg: KprobeNetDev;
    } | {
        oneofKind: undefined;
    };
    /**
     * @generated from protobuf field: string label = 18;
     */
    label: string;
}
/**
 * @generated from protobuf message tetragon.ProcessKprobe
 */
export interface ProcessKprobe {
    /**
     * Process that triggered the kprobe.
     *
     * @generated from protobuf field: tetragon.Process process = 1;
     */
    process?: Process;
    /**
     * Immediate parent of the process.
     *
     * @generated from protobuf field: tetragon.Process parent = 2;
     */
    parent?: Process;
    /**
     * Symbol on which the kprobe was attached.
     *
     * @generated from protobuf field: string function_name = 3;
     */
    function_name: string;
    /**
     * Arguments definition of the observed kprobe.
     *
     * @generated from protobuf field: repeated tetragon.KprobeArgument args = 4;
     */
    args: KprobeArgument[];
    /**
     * Return value definition of the observed kprobe.
     *
     * @generated from protobuf field: tetragon.KprobeArgument return = 5;
     */
    return?: KprobeArgument;
    /**
     * Action performed when the kprobe matched.
     *
     * @generated from protobuf field: tetragon.KprobeAction action = 6;
     */
    action: KprobeAction;
    /**
     * Kernel stack trace to the call.
     *
     * @generated from protobuf field: repeated tetragon.StackTraceEntry kernel_stack_trace = 7;
     */
    kernel_stack_trace: StackTraceEntry[];
    /**
     * Name of the Tracing Policy that created that kprobe.
     *
     * @generated from protobuf field: string policy_name = 8;
     */
    policy_name: string;
    /**
     * Action performed when the return kprobe executed.
     *
     * @generated from protobuf field: tetragon.KprobeAction return_action = 9;
     */
    return_action: KprobeAction;
    /**
     * Short message of the Tracing Policy to inform users what is going on.
     *
     * @generated from protobuf field: string message = 10;
     */
    message: string;
    /**
     * Tags of the Tracing Policy to categorize the event.
     *
     * @generated from protobuf field: repeated string tags = 11;
     */
    tags: string[];
    /**
     * User-mode stack trace to the call.
     *
     * @generated from protobuf field: repeated tetragon.StackTraceEntry user_stack_trace = 12;
     */
    user_stack_trace: StackTraceEntry[];
}
/**
 * @generated from protobuf message tetragon.ProcessTracepoint
 */
export interface ProcessTracepoint {
    /**
     * Process that triggered the tracepoint.
     *
     * @generated from protobuf field: tetragon.Process process = 1;
     */
    process?: Process;
    /**
     * Immediate parent of the process.
     *
     * @generated from protobuf field: tetragon.Process parent = 2;
     */
    parent?: Process;
    /**
     * Subsystem of the tracepoint.
     *
     * @generated from protobuf field: string subsys = 4;
     */
    subsys: string;
    /**
     * Event of the subsystem.
     *
     * @generated from protobuf field: string event = 5;
     */
    event: string;
    /**
     * Arguments definition of the observed tracepoint.
     * TODO: once we implement all we want, rename KprobeArgument to GenericArgument
     *
     * @generated from protobuf field: repeated tetragon.KprobeArgument args = 6;
     */
    args: KprobeArgument[];
    /**
     * Name of the policy that created that tracepoint.
     *
     * @generated from protobuf field: string policy_name = 7;
     */
    policy_name: string;
    /**
     * Action performed when the tracepoint matched.
     *
     * @generated from protobuf field: tetragon.KprobeAction action = 8;
     */
    action: KprobeAction;
    /**
     * Short message of the Tracing Policy to inform users what is going on.
     *
     * @generated from protobuf field: string message = 9;
     */
    message: string;
    /**
     * Tags of the Tracing Policy to categorize the event.
     *
     * @generated from protobuf field: repeated string tags = 10;
     */
    tags: string[];
}
/**
 * @generated from protobuf message tetragon.ProcessUprobe
 */
export interface ProcessUprobe {
    /**
     * @generated from protobuf field: tetragon.Process process = 1;
     */
    process?: Process;
    /**
     * @generated from protobuf field: tetragon.Process parent = 2;
     */
    parent?: Process;
    /**
     * @generated from protobuf field: string path = 3;
     */
    path: string;
    /**
     * @generated from protobuf field: string symbol = 4;
     */
    symbol: string;
    /**
     * Name of the policy that created that uprobe.
     *
     * @generated from protobuf field: string policy_name = 5;
     */
    policy_name: string;
    /**
     * Short message of the Tracing Policy to inform users what is going on.
     *
     * @generated from protobuf field: string message = 6;
     */
    message: string;
    /**
     * Arguments definition of the observed uprobe.
     *
     * @generated from protobuf field: repeated tetragon.KprobeArgument args = 7;
     */
    args: KprobeArgument[];
    /**
     * Tags of the Tracing Policy to categorize the event.
     *
     * @generated from protobuf field: repeated string tags = 8;
     */
    tags: string[];
}
/**
 * @generated from protobuf message tetragon.KernelModule
 */
export interface KernelModule {
    /**
     * Kernel module name
     *
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * If true the module signature was verified successfully. Depends on kernels compiled with
     * CONFIG_MODULE_SIG option, for details please read: https://www.kernel.org/doc/Documentation/admin-guide/module-signing.rst
     *
     * @generated from protobuf field: google.protobuf.BoolValue signature_ok = 2;
     */
    signature_ok?: BoolValue;
    /**
     * The module tainted flags that will be applied on the kernel. For further details please read: https://docs.kernel.org/admin-guide/tainted-kernels.html
     *
     * @generated from protobuf field: repeated tetragon.TaintedBitsType tainted = 3;
     */
    tainted: TaintedBitsType[];
}
/**
 * @generated from protobuf message tetragon.Test
 */
export interface Test {
    /**
     * @generated from protobuf field: uint64 arg0 = 1;
     */
    arg0: number;
    /**
     * @generated from protobuf field: uint64 arg1 = 2;
     */
    arg1: number;
    /**
     * @generated from protobuf field: uint64 arg2 = 3;
     */
    arg2: number;
    /**
     * @generated from protobuf field: uint64 arg3 = 4;
     */
    arg3: number;
}
/**
 * @generated from protobuf message tetragon.GetHealthStatusRequest
 */
export interface GetHealthStatusRequest {
    /**
     * @generated from protobuf field: repeated tetragon.HealthStatusType event_set = 1;
     */
    event_set: HealthStatusType[];
}
/**
 * @generated from protobuf message tetragon.HealthStatus
 */
export interface HealthStatus {
    /**
     * @generated from protobuf field: tetragon.HealthStatusType event = 1;
     */
    event: HealthStatusType;
    /**
     * @generated from protobuf field: tetragon.HealthStatusResult status = 2;
     */
    status: HealthStatusResult;
    /**
     * @generated from protobuf field: string details = 3;
     */
    details: string;
}
/**
 * @generated from protobuf message tetragon.GetHealthStatusResponse
 */
export interface GetHealthStatusResponse {
    /**
     * @generated from protobuf field: repeated tetragon.HealthStatus health_status = 1;
     */
    health_status: HealthStatus[];
}
/**
 * loader sensor event triggered for loaded binary/library
 *
 * @generated from protobuf message tetragon.ProcessLoader
 */
export interface ProcessLoader {
    /**
     * @generated from protobuf field: tetragon.Process process = 1;
     */
    process?: Process;
    /**
     * @generated from protobuf field: string path = 2;
     */
    path: string;
    /**
     * @generated from protobuf field: bytes buildid = 3;
     */
    buildid: Uint8Array;
}
/**
 * RuntimeHookRequest synchronously propagates information to the agent about run-time state.
 *
 * @generated from protobuf message tetragon.RuntimeHookRequest
 */
export interface RuntimeHookRequest {
    /**
     * @generated from protobuf oneof: event
     */
    event: {
        oneofKind: "createContainer";
        /**
         * @generated from protobuf field: tetragon.CreateContainer createContainer = 1;
         */
        createContainer: CreateContainer;
    } | {
        oneofKind: undefined;
    };
}
/**
 * @generated from protobuf message tetragon.RuntimeHookResponse
 */
export interface RuntimeHookResponse {
}
/**
 * CreateContainer informs the agent that a container was created
 * This is intented to be used by OCI hooks (but not limited to them) and corresponds to the
 * CreateContainer hook:
 * https://github.com/opencontainers/runtime-spec/blob/main/config.md#createcontainer-hooks.
 *
 * @generated from protobuf message tetragon.CreateContainer
 */
export interface CreateContainer {
    /**
     * cgroupsPath is the cgroups path for the container. The path is expected to be relative to the
     * cgroups mountpoint. See: https://github.com/opencontainers/runtime-spec/blob/58ec43f9fc39e0db229b653ae98295bfde74aeab/specs-go/config.go#L174
     *
     * @generated from protobuf field: string cgroupsPath = 1;
     */
    cgroupsPath: string;
    /**
     * rootDir is the absolute path of the root directory of the container.
     * See: https://github.com/opencontainers/runtime-spec/blob/main/specs-go/config.go#L174
     *
     * @generated from protobuf field: string rootDir = 2;
     */
    rootDir: string;
    /**
     * annotations are the run-time annotations for the container
     * see https://github.com/opencontainers/runtime-spec/blob/main/config.md#annotations
     *
     * @generated from protobuf field: map<string, string> annotations = 3;
     */
    annotations: {
        [key: string]: string;
    };
    /**
     * containerName is the name of the container
     *
     * @generated from protobuf field: string containerName = 4;
     */
    containerName: string;
}
/**
 * @generated from protobuf message tetragon.StackTraceEntry
 */
export interface StackTraceEntry {
    /**
     * linear address of the function in kernel or user space.
     *
     * @generated from protobuf field: uint64 address = 1;
     */
    address: number;
    /**
     * offset is the offset into the native instructions for the function.
     *
     * @generated from protobuf field: uint64 offset = 2;
     */
    offset: number;
    /**
     * symbol is the symbol name of the function.
     *
     * @generated from protobuf field: string symbol = 3;
     */
    symbol: string;
    /**
     * module path for user space addresses.
     *
     * @generated from protobuf field: string module = 4;
     */
    module: string;
}
/**
 * @generated from protobuf enum tetragon.KprobeAction
 */
export enum KprobeAction {
    /**
     * Unknown action
     *
     * @generated from protobuf enum value: KPROBE_ACTION_UNKNOWN = 0;
     */
    UNKNOWN = 0,
    /**
     * Post action creates an event (default action).
     *
     * @generated from protobuf enum value: KPROBE_ACTION_POST = 1;
     */
    POST = 1,
    /**
     * Post action creates a mapping between file descriptors and file names.
     *
     * @generated from protobuf enum value: KPROBE_ACTION_FOLLOWFD = 2;
     */
    FOLLOWFD = 2,
    /**
     * Sigkill action synchronously terminates the process.
     *
     * @generated from protobuf enum value: KPROBE_ACTION_SIGKILL = 3;
     */
    SIGKILL = 3,
    /**
     * Post action removes a mapping between file descriptors and file names.
     *
     * @generated from protobuf enum value: KPROBE_ACTION_UNFOLLOWFD = 4;
     */
    UNFOLLOWFD = 4,
    /**
     * Override action modifies the return value of the call.
     *
     * @generated from protobuf enum value: KPROBE_ACTION_OVERRIDE = 5;
     */
    OVERRIDE = 5,
    /**
     * Post action dupplicates a mapping between file descriptors and file
     * names.
     *
     * @generated from protobuf enum value: KPROBE_ACTION_COPYFD = 6;
     */
    COPYFD = 6,
    /**
     * GetURL action issue an HTTP Get request against an URL from userspace.
     *
     * @generated from protobuf enum value: KPROBE_ACTION_GETURL = 7;
     */
    GETURL = 7,
    /**
     * GetURL action issue a DNS lookup against an URL from userspace.
     *
     * @generated from protobuf enum value: KPROBE_ACTION_DNSLOOKUP = 8;
     */
    DNSLOOKUP = 8,
    /**
     * NoPost action suppresses the transmission of the event to userspace.
     *
     * @generated from protobuf enum value: KPROBE_ACTION_NOPOST = 9;
     */
    NOPOST = 9,
    /**
     * Signal action sends specified signal to the process.
     *
     * @generated from protobuf enum value: KPROBE_ACTION_SIGNAL = 10;
     */
    SIGNAL = 10,
    /**
     * TrackSock action tracks socket.
     *
     * @generated from protobuf enum value: KPROBE_ACTION_TRACKSOCK = 11;
     */
    TRACKSOCK = 11,
    /**
     * UntrackSock action un-tracks socket.
     *
     * @generated from protobuf enum value: KPROBE_ACTION_UNTRACKSOCK = 12;
     */
    UNTRACKSOCK = 12,
    /**
     * NotifyEnforcer action notifies killer sensor.
     *
     * @generated from protobuf enum value: KPROBE_ACTION_NOTIFYENFORCER = 13;
     */
    NOTIFYENFORCER = 13
}
/**
 * @generated from protobuf enum tetragon.HealthStatusType
 */
export enum HealthStatusType {
    /**
     * @generated from protobuf enum value: HEALTH_STATUS_TYPE_UNDEF = 0;
     */
    UNDEF = 0,
    /**
     * @generated from protobuf enum value: HEALTH_STATUS_TYPE_STATUS = 1;
     */
    STATUS = 1
}
/**
 * @generated from protobuf enum tetragon.HealthStatusResult
 */
export enum HealthStatusResult {
    /**
     * @generated from protobuf enum value: HEALTH_STATUS_UNDEF = 0;
     */
    HEALTH_STATUS_UNDEF = 0,
    /**
     * @generated from protobuf enum value: HEALTH_STATUS_RUNNING = 1;
     */
    HEALTH_STATUS_RUNNING = 1,
    /**
     * @generated from protobuf enum value: HEALTH_STATUS_STOPPED = 2;
     */
    HEALTH_STATUS_STOPPED = 2,
    /**
     * @generated from protobuf enum value: HEALTH_STATUS_ERROR = 3;
     */
    HEALTH_STATUS_ERROR = 3
}
/**
 * Tainted bits to indicate if the kernel was tainted. For further details: https://docs.kernel.org/admin-guide/tainted-kernels.html
 *
 * @generated from protobuf enum tetragon.TaintedBitsType
 */
export enum TaintedBitsType {
    /**
     * @generated from protobuf enum value: TAINT_UNSET = 0;
     */
    TAINT_UNSET = 0,
    /**
     * A proprietary module was loaded.
     *
     * @generated from protobuf enum value: TAINT_PROPRIETARY_MODULE = 1;
     */
    TAINT_PROPRIETARY_MODULE = 1,
    /**
     * A module was force loaded.
     *
     * @generated from protobuf enum value: TAINT_FORCED_MODULE = 2;
     */
    TAINT_FORCED_MODULE = 2,
    /**
     * A module was force unloaded.
     *
     * @generated from protobuf enum value: TAINT_FORCED_UNLOAD_MODULE = 4;
     */
    TAINT_FORCED_UNLOAD_MODULE = 4,
    /**
     * A staging driver was loaded.
     *
     * @generated from protobuf enum value: TAINT_STAGED_MODULE = 1024;
     */
    TAINT_STAGED_MODULE = 1024,
    /**
     * An out of tree module was loaded.
     *
     * @generated from protobuf enum value: TAINT_OUT_OF_TREE_MODULE = 4096;
     */
    TAINT_OUT_OF_TREE_MODULE = 4096,
    /**
     * An unsigned module was loaded. Supported only on kernels built with CONFIG_MODULE_SIG option.
     *
     * @generated from protobuf enum value: TAINT_UNSIGNED_MODULE = 8192;
     */
    TAINT_UNSIGNED_MODULE = 8192,
    /**
     * The kernel has been live patched.
     *
     * @generated from protobuf enum value: TAINT_KERNEL_LIVE_PATCH_MODULE = 32768;
     */
    TAINT_KERNEL_LIVE_PATCH_MODULE = 32768,
    /**
     * Loading a test module.
     *
     * @generated from protobuf enum value: TAINT_TEST_MODULE = 262144;
     */
    TAINT_TEST_MODULE = 262144
}
// @generated message type with reflection information, may provide speed optimized methods
class Image$Type extends MessageType<Image> {
    constructor() {
        super("tetragon.Image", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Image>): Image {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.name = "";
        if (value !== undefined)
            reflectionMergePartial<Image>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Image): Image {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Image, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tetragon.Image
 */
export const Image = new Image$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Container$Type extends MessageType<Container> {
    constructor() {
        super("tetragon.Container", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "image", kind: "message", T: () => Image },
            { no: 4, name: "start_time", kind: "message", localName: "start_time", T: () => Timestamp },
            { no: 5, name: "pid", kind: "message", T: () => UInt32Value },
            { no: 13, name: "maybe_exec_probe", kind: "scalar", localName: "maybe_exec_probe", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<Container>): Container {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.name = "";
        message.maybe_exec_probe = false;
        if (value !== undefined)
            reflectionMergePartial<Container>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Container): Container {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* tetragon.Image image */ 3:
                    message.image = Image.internalBinaryRead(reader, reader.uint32(), options, message.image);
                    break;
                case /* google.protobuf.Timestamp start_time */ 4:
                    message.start_time = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.start_time);
                    break;
                case /* google.protobuf.UInt32Value pid */ 5:
                    message.pid = UInt32Value.internalBinaryRead(reader, reader.uint32(), options, message.pid);
                    break;
                case /* bool maybe_exec_probe */ 13:
                    message.maybe_exec_probe = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Container, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* tetragon.Image image = 3; */
        if (message.image)
            Image.internalBinaryWrite(message.image, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp start_time = 4; */
        if (message.start_time)
            Timestamp.internalBinaryWrite(message.start_time, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.UInt32Value pid = 5; */
        if (message.pid)
            UInt32Value.internalBinaryWrite(message.pid, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* bool maybe_exec_probe = 13; */
        if (message.maybe_exec_probe !== false)
            writer.tag(13, WireType.Varint).bool(message.maybe_exec_probe);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tetragon.Container
 */
export const Container = new Container$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Pod$Type extends MessageType<Pod> {
    constructor() {
        super("tetragon.Pod", [
            { no: 1, name: "namespace", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "container", kind: "message", T: () => Container },
            { no: 5, name: "pod_labels", kind: "map", localName: "pod_labels", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } },
            { no: 6, name: "workload", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "workload_kind", kind: "scalar", localName: "workload_kind", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Pod>): Pod {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.namespace = "";
        message.name = "";
        message.pod_labels = {};
        message.workload = "";
        message.workload_kind = "";
        if (value !== undefined)
            reflectionMergePartial<Pod>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Pod): Pod {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string namespace */ 1:
                    message.namespace = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* tetragon.Container container */ 4:
                    message.container = Container.internalBinaryRead(reader, reader.uint32(), options, message.container);
                    break;
                case /* map<string, string> pod_labels */ 5:
                    this.binaryReadMap5(message.pod_labels, reader, options);
                    break;
                case /* string workload */ 6:
                    message.workload = reader.string();
                    break;
                case /* string workload_kind */ 7:
                    message.workload_kind = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap5(map: Pod["pod_labels"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof Pod["pod_labels"] | undefined, val: Pod["pod_labels"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.string();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field tetragon.Pod.pod_labels");
            }
        }
        map[key ?? ""] = val ?? "";
    }
    internalBinaryWrite(message: Pod, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string namespace = 1; */
        if (message.namespace !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.namespace);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* tetragon.Container container = 4; */
        if (message.container)
            Container.internalBinaryWrite(message.container, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* map<string, string> pod_labels = 5; */
        for (let k of globalThis.Object.keys(message.pod_labels))
            writer.tag(5, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.LengthDelimited).string(message.pod_labels[k]).join();
        /* string workload = 6; */
        if (message.workload !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.workload);
        /* string workload_kind = 7; */
        if (message.workload_kind !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.workload_kind);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tetragon.Pod
 */
export const Pod = new Pod$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Capabilities$Type extends MessageType<Capabilities> {
    constructor() {
        super("tetragon.Capabilities", [
            { no: 1, name: "permitted", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["tetragon.CapabilitiesType", CapabilitiesType] },
            { no: 2, name: "effective", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["tetragon.CapabilitiesType", CapabilitiesType] },
            { no: 3, name: "inheritable", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["tetragon.CapabilitiesType", CapabilitiesType] }
        ]);
    }
    create(value?: PartialMessage<Capabilities>): Capabilities {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.permitted = [];
        message.effective = [];
        message.inheritable = [];
        if (value !== undefined)
            reflectionMergePartial<Capabilities>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Capabilities): Capabilities {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated tetragon.CapabilitiesType permitted */ 1:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.permitted.push(reader.int32());
                    else
                        message.permitted.push(reader.int32());
                    break;
                case /* repeated tetragon.CapabilitiesType effective */ 2:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.effective.push(reader.int32());
                    else
                        message.effective.push(reader.int32());
                    break;
                case /* repeated tetragon.CapabilitiesType inheritable */ 3:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.inheritable.push(reader.int32());
                    else
                        message.inheritable.push(reader.int32());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Capabilities, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated tetragon.CapabilitiesType permitted = 1; */
        if (message.permitted.length) {
            writer.tag(1, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.permitted.length; i++)
                writer.int32(message.permitted[i]);
            writer.join();
        }
        /* repeated tetragon.CapabilitiesType effective = 2; */
        if (message.effective.length) {
            writer.tag(2, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.effective.length; i++)
                writer.int32(message.effective[i]);
            writer.join();
        }
        /* repeated tetragon.CapabilitiesType inheritable = 3; */
        if (message.inheritable.length) {
            writer.tag(3, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.inheritable.length; i++)
                writer.int32(message.inheritable[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tetragon.Capabilities
 */
export const Capabilities = new Capabilities$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Namespace$Type extends MessageType<Namespace> {
    constructor() {
        super("tetragon.Namespace", [
            { no: 1, name: "inum", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 2, name: "is_host", kind: "scalar", localName: "is_host", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<Namespace>): Namespace {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.inum = 0;
        message.is_host = false;
        if (value !== undefined)
            reflectionMergePartial<Namespace>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Namespace): Namespace {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint32 inum */ 1:
                    message.inum = reader.uint32();
                    break;
                case /* bool is_host */ 2:
                    message.is_host = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Namespace, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint32 inum = 1; */
        if (message.inum !== 0)
            writer.tag(1, WireType.Varint).uint32(message.inum);
        /* bool is_host = 2; */
        if (message.is_host !== false)
            writer.tag(2, WireType.Varint).bool(message.is_host);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tetragon.Namespace
 */
export const Namespace = new Namespace$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Namespaces$Type extends MessageType<Namespaces> {
    constructor() {
        super("tetragon.Namespaces", [
            { no: 1, name: "uts", kind: "message", T: () => Namespace },
            { no: 2, name: "ipc", kind: "message", T: () => Namespace },
            { no: 3, name: "mnt", kind: "message", T: () => Namespace },
            { no: 4, name: "pid", kind: "message", T: () => Namespace },
            { no: 5, name: "pid_for_children", kind: "message", localName: "pid_for_children", T: () => Namespace },
            { no: 6, name: "net", kind: "message", T: () => Namespace },
            { no: 7, name: "time", kind: "message", T: () => Namespace },
            { no: 8, name: "time_for_children", kind: "message", localName: "time_for_children", T: () => Namespace },
            { no: 9, name: "cgroup", kind: "message", T: () => Namespace },
            { no: 10, name: "user", kind: "message", T: () => Namespace }
        ]);
    }
    create(value?: PartialMessage<Namespaces>): Namespaces {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<Namespaces>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Namespaces): Namespaces {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* tetragon.Namespace uts */ 1:
                    message.uts = Namespace.internalBinaryRead(reader, reader.uint32(), options, message.uts);
                    break;
                case /* tetragon.Namespace ipc */ 2:
                    message.ipc = Namespace.internalBinaryRead(reader, reader.uint32(), options, message.ipc);
                    break;
                case /* tetragon.Namespace mnt */ 3:
                    message.mnt = Namespace.internalBinaryRead(reader, reader.uint32(), options, message.mnt);
                    break;
                case /* tetragon.Namespace pid */ 4:
                    message.pid = Namespace.internalBinaryRead(reader, reader.uint32(), options, message.pid);
                    break;
                case /* tetragon.Namespace pid_for_children */ 5:
                    message.pid_for_children = Namespace.internalBinaryRead(reader, reader.uint32(), options, message.pid_for_children);
                    break;
                case /* tetragon.Namespace net */ 6:
                    message.net = Namespace.internalBinaryRead(reader, reader.uint32(), options, message.net);
                    break;
                case /* tetragon.Namespace time */ 7:
                    message.time = Namespace.internalBinaryRead(reader, reader.uint32(), options, message.time);
                    break;
                case /* tetragon.Namespace time_for_children */ 8:
                    message.time_for_children = Namespace.internalBinaryRead(reader, reader.uint32(), options, message.time_for_children);
                    break;
                case /* tetragon.Namespace cgroup */ 9:
                    message.cgroup = Namespace.internalBinaryRead(reader, reader.uint32(), options, message.cgroup);
                    break;
                case /* tetragon.Namespace user */ 10:
                    message.user = Namespace.internalBinaryRead(reader, reader.uint32(), options, message.user);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Namespaces, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* tetragon.Namespace uts = 1; */
        if (message.uts)
            Namespace.internalBinaryWrite(message.uts, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.Namespace ipc = 2; */
        if (message.ipc)
            Namespace.internalBinaryWrite(message.ipc, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.Namespace mnt = 3; */
        if (message.mnt)
            Namespace.internalBinaryWrite(message.mnt, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.Namespace pid = 4; */
        if (message.pid)
            Namespace.internalBinaryWrite(message.pid, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.Namespace pid_for_children = 5; */
        if (message.pid_for_children)
            Namespace.internalBinaryWrite(message.pid_for_children, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.Namespace net = 6; */
        if (message.net)
            Namespace.internalBinaryWrite(message.net, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.Namespace time = 7; */
        if (message.time)
            Namespace.internalBinaryWrite(message.time, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.Namespace time_for_children = 8; */
        if (message.time_for_children)
            Namespace.internalBinaryWrite(message.time_for_children, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.Namespace cgroup = 9; */
        if (message.cgroup)
            Namespace.internalBinaryWrite(message.cgroup, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.Namespace user = 10; */
        if (message.user)
            Namespace.internalBinaryWrite(message.user, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tetragon.Namespaces
 */
export const Namespaces = new Namespaces$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserNamespace$Type extends MessageType<UserNamespace> {
    constructor() {
        super("tetragon.UserNamespace", [
            { no: 1, name: "level", kind: "message", T: () => Int32Value },
            { no: 2, name: "uid", kind: "message", T: () => UInt32Value },
            { no: 3, name: "gid", kind: "message", T: () => UInt32Value },
            { no: 4, name: "ns", kind: "message", T: () => Namespace }
        ]);
    }
    create(value?: PartialMessage<UserNamespace>): UserNamespace {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<UserNamespace>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UserNamespace): UserNamespace {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* google.protobuf.Int32Value level */ 1:
                    message.level = Int32Value.internalBinaryRead(reader, reader.uint32(), options, message.level);
                    break;
                case /* google.protobuf.UInt32Value uid */ 2:
                    message.uid = UInt32Value.internalBinaryRead(reader, reader.uint32(), options, message.uid);
                    break;
                case /* google.protobuf.UInt32Value gid */ 3:
                    message.gid = UInt32Value.internalBinaryRead(reader, reader.uint32(), options, message.gid);
                    break;
                case /* tetragon.Namespace ns */ 4:
                    message.ns = Namespace.internalBinaryRead(reader, reader.uint32(), options, message.ns);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UserNamespace, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* google.protobuf.Int32Value level = 1; */
        if (message.level)
            Int32Value.internalBinaryWrite(message.level, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.UInt32Value uid = 2; */
        if (message.uid)
            UInt32Value.internalBinaryWrite(message.uid, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.UInt32Value gid = 3; */
        if (message.gid)
            UInt32Value.internalBinaryWrite(message.gid, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.Namespace ns = 4; */
        if (message.ns)
            Namespace.internalBinaryWrite(message.ns, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tetragon.UserNamespace
 */
export const UserNamespace = new UserNamespace$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ProcessCredentials$Type extends MessageType<ProcessCredentials> {
    constructor() {
        super("tetragon.ProcessCredentials", [
            { no: 1, name: "uid", kind: "message", T: () => UInt32Value },
            { no: 2, name: "gid", kind: "message", T: () => UInt32Value },
            { no: 3, name: "euid", kind: "message", T: () => UInt32Value },
            { no: 4, name: "egid", kind: "message", T: () => UInt32Value },
            { no: 5, name: "suid", kind: "message", T: () => UInt32Value },
            { no: 6, name: "sgid", kind: "message", T: () => UInt32Value },
            { no: 7, name: "fsuid", kind: "message", T: () => UInt32Value },
            { no: 8, name: "fsgid", kind: "message", T: () => UInt32Value },
            { no: 9, name: "securebits", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["tetragon.SecureBitsType", SecureBitsType] },
            { no: 10, name: "caps", kind: "message", T: () => Capabilities },
            { no: 11, name: "user_ns", kind: "message", localName: "user_ns", T: () => UserNamespace }
        ]);
    }
    create(value?: PartialMessage<ProcessCredentials>): ProcessCredentials {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.securebits = [];
        if (value !== undefined)
            reflectionMergePartial<ProcessCredentials>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ProcessCredentials): ProcessCredentials {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* google.protobuf.UInt32Value uid */ 1:
                    message.uid = UInt32Value.internalBinaryRead(reader, reader.uint32(), options, message.uid);
                    break;
                case /* google.protobuf.UInt32Value gid */ 2:
                    message.gid = UInt32Value.internalBinaryRead(reader, reader.uint32(), options, message.gid);
                    break;
                case /* google.protobuf.UInt32Value euid */ 3:
                    message.euid = UInt32Value.internalBinaryRead(reader, reader.uint32(), options, message.euid);
                    break;
                case /* google.protobuf.UInt32Value egid */ 4:
                    message.egid = UInt32Value.internalBinaryRead(reader, reader.uint32(), options, message.egid);
                    break;
                case /* google.protobuf.UInt32Value suid */ 5:
                    message.suid = UInt32Value.internalBinaryRead(reader, reader.uint32(), options, message.suid);
                    break;
                case /* google.protobuf.UInt32Value sgid */ 6:
                    message.sgid = UInt32Value.internalBinaryRead(reader, reader.uint32(), options, message.sgid);
                    break;
                case /* google.protobuf.UInt32Value fsuid */ 7:
                    message.fsuid = UInt32Value.internalBinaryRead(reader, reader.uint32(), options, message.fsuid);
                    break;
                case /* google.protobuf.UInt32Value fsgid */ 8:
                    message.fsgid = UInt32Value.internalBinaryRead(reader, reader.uint32(), options, message.fsgid);
                    break;
                case /* repeated tetragon.SecureBitsType securebits */ 9:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.securebits.push(reader.int32());
                    else
                        message.securebits.push(reader.int32());
                    break;
                case /* tetragon.Capabilities caps */ 10:
                    message.caps = Capabilities.internalBinaryRead(reader, reader.uint32(), options, message.caps);
                    break;
                case /* tetragon.UserNamespace user_ns */ 11:
                    message.user_ns = UserNamespace.internalBinaryRead(reader, reader.uint32(), options, message.user_ns);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ProcessCredentials, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* google.protobuf.UInt32Value uid = 1; */
        if (message.uid)
            UInt32Value.internalBinaryWrite(message.uid, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.UInt32Value gid = 2; */
        if (message.gid)
            UInt32Value.internalBinaryWrite(message.gid, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.UInt32Value euid = 3; */
        if (message.euid)
            UInt32Value.internalBinaryWrite(message.euid, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.UInt32Value egid = 4; */
        if (message.egid)
            UInt32Value.internalBinaryWrite(message.egid, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.UInt32Value suid = 5; */
        if (message.suid)
            UInt32Value.internalBinaryWrite(message.suid, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.UInt32Value sgid = 6; */
        if (message.sgid)
            UInt32Value.internalBinaryWrite(message.sgid, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.UInt32Value fsuid = 7; */
        if (message.fsuid)
            UInt32Value.internalBinaryWrite(message.fsuid, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.UInt32Value fsgid = 8; */
        if (message.fsgid)
            UInt32Value.internalBinaryWrite(message.fsgid, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* repeated tetragon.SecureBitsType securebits = 9; */
        if (message.securebits.length) {
            writer.tag(9, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.securebits.length; i++)
                writer.int32(message.securebits[i]);
            writer.join();
        }
        /* tetragon.Capabilities caps = 10; */
        if (message.caps)
            Capabilities.internalBinaryWrite(message.caps, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.UserNamespace user_ns = 11; */
        if (message.user_ns)
            UserNamespace.internalBinaryWrite(message.user_ns, writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tetragon.ProcessCredentials
 */
export const ProcessCredentials = new ProcessCredentials$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InodeProperties$Type extends MessageType<InodeProperties> {
    constructor() {
        super("tetragon.InodeProperties", [
            { no: 1, name: "number", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "links", kind: "message", T: () => UInt32Value }
        ]);
    }
    create(value?: PartialMessage<InodeProperties>): InodeProperties {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.number = 0;
        if (value !== undefined)
            reflectionMergePartial<InodeProperties>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: InodeProperties): InodeProperties {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint64 number */ 1:
                    message.number = reader.uint64().toNumber();
                    break;
                case /* google.protobuf.UInt32Value links */ 2:
                    message.links = UInt32Value.internalBinaryRead(reader, reader.uint32(), options, message.links);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: InodeProperties, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint64 number = 1; */
        if (message.number !== 0)
            writer.tag(1, WireType.Varint).uint64(message.number);
        /* google.protobuf.UInt32Value links = 2; */
        if (message.links)
            UInt32Value.internalBinaryWrite(message.links, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tetragon.InodeProperties
 */
export const InodeProperties = new InodeProperties$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FileProperties$Type extends MessageType<FileProperties> {
    constructor() {
        super("tetragon.FileProperties", [
            { no: 1, name: "inode", kind: "message", T: () => InodeProperties },
            { no: 2, name: "path", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<FileProperties>): FileProperties {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.path = "";
        if (value !== undefined)
            reflectionMergePartial<FileProperties>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FileProperties): FileProperties {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* tetragon.InodeProperties inode */ 1:
                    message.inode = InodeProperties.internalBinaryRead(reader, reader.uint32(), options, message.inode);
                    break;
                case /* string path */ 2:
                    message.path = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FileProperties, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* tetragon.InodeProperties inode = 1; */
        if (message.inode)
            InodeProperties.internalBinaryWrite(message.inode, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string path = 2; */
        if (message.path !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.path);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tetragon.FileProperties
 */
export const FileProperties = new FileProperties$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BinaryProperties$Type extends MessageType<BinaryProperties> {
    constructor() {
        super("tetragon.BinaryProperties", [
            { no: 1, name: "setuid", kind: "message", T: () => UInt32Value },
            { no: 2, name: "setgid", kind: "message", T: () => UInt32Value },
            { no: 3, name: "privileges_changed", kind: "enum", localName: "privileges_changed", repeat: 1 /*RepeatType.PACKED*/, T: () => ["tetragon.ProcessPrivilegesChanged", ProcessPrivilegesChanged] },
            { no: 4, name: "file", kind: "message", T: () => FileProperties }
        ]);
    }
    create(value?: PartialMessage<BinaryProperties>): BinaryProperties {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.privileges_changed = [];
        if (value !== undefined)
            reflectionMergePartial<BinaryProperties>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BinaryProperties): BinaryProperties {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* google.protobuf.UInt32Value setuid */ 1:
                    message.setuid = UInt32Value.internalBinaryRead(reader, reader.uint32(), options, message.setuid);
                    break;
                case /* google.protobuf.UInt32Value setgid */ 2:
                    message.setgid = UInt32Value.internalBinaryRead(reader, reader.uint32(), options, message.setgid);
                    break;
                case /* repeated tetragon.ProcessPrivilegesChanged privileges_changed */ 3:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.privileges_changed.push(reader.int32());
                    else
                        message.privileges_changed.push(reader.int32());
                    break;
                case /* tetragon.FileProperties file */ 4:
                    message.file = FileProperties.internalBinaryRead(reader, reader.uint32(), options, message.file);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BinaryProperties, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* google.protobuf.UInt32Value setuid = 1; */
        if (message.setuid)
            UInt32Value.internalBinaryWrite(message.setuid, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.UInt32Value setgid = 2; */
        if (message.setgid)
            UInt32Value.internalBinaryWrite(message.setgid, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* repeated tetragon.ProcessPrivilegesChanged privileges_changed = 3; */
        if (message.privileges_changed.length) {
            writer.tag(3, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.privileges_changed.length; i++)
                writer.int32(message.privileges_changed[i]);
            writer.join();
        }
        /* tetragon.FileProperties file = 4; */
        if (message.file)
            FileProperties.internalBinaryWrite(message.file, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tetragon.BinaryProperties
 */
export const BinaryProperties = new BinaryProperties$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserRecord$Type extends MessageType<UserRecord> {
    constructor() {
        super("tetragon.UserRecord", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UserRecord>): UserRecord {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.name = "";
        if (value !== undefined)
            reflectionMergePartial<UserRecord>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UserRecord): UserRecord {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UserRecord, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tetragon.UserRecord
 */
export const UserRecord = new UserRecord$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Process$Type extends MessageType<Process> {
    constructor() {
        super("tetragon.Process", [
            { no: 1, name: "exec_id", kind: "scalar", localName: "exec_id", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "pid", kind: "message", T: () => UInt32Value },
            { no: 3, name: "uid", kind: "message", T: () => UInt32Value },
            { no: 4, name: "cwd", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "binary", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "arguments", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "flags", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "start_time", kind: "message", localName: "start_time", T: () => Timestamp },
            { no: 9, name: "auid", kind: "message", T: () => UInt32Value },
            { no: 10, name: "pod", kind: "message", T: () => Pod },
            { no: 11, name: "docker", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "parent_exec_id", kind: "scalar", localName: "parent_exec_id", T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "refcnt", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 14, name: "cap", kind: "message", T: () => Capabilities },
            { no: 15, name: "ns", kind: "message", T: () => Namespaces },
            { no: 16, name: "tid", kind: "message", T: () => UInt32Value },
            { no: 17, name: "process_credentials", kind: "message", localName: "process_credentials", T: () => ProcessCredentials },
            { no: 18, name: "binary_properties", kind: "message", localName: "binary_properties", T: () => BinaryProperties },
            { no: 19, name: "user", kind: "message", T: () => UserRecord }
        ]);
    }
    create(value?: PartialMessage<Process>): Process {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.exec_id = "";
        message.cwd = "";
        message.binary = "";
        message.arguments = "";
        message.flags = "";
        message.docker = "";
        message.parent_exec_id = "";
        message.refcnt = 0;
        if (value !== undefined)
            reflectionMergePartial<Process>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Process): Process {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string exec_id */ 1:
                    message.exec_id = reader.string();
                    break;
                case /* google.protobuf.UInt32Value pid */ 2:
                    message.pid = UInt32Value.internalBinaryRead(reader, reader.uint32(), options, message.pid);
                    break;
                case /* google.protobuf.UInt32Value uid */ 3:
                    message.uid = UInt32Value.internalBinaryRead(reader, reader.uint32(), options, message.uid);
                    break;
                case /* string cwd */ 4:
                    message.cwd = reader.string();
                    break;
                case /* string binary */ 5:
                    message.binary = reader.string();
                    break;
                case /* string arguments */ 6:
                    message.arguments = reader.string();
                    break;
                case /* string flags */ 7:
                    message.flags = reader.string();
                    break;
                case /* google.protobuf.Timestamp start_time */ 8:
                    message.start_time = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.start_time);
                    break;
                case /* google.protobuf.UInt32Value auid */ 9:
                    message.auid = UInt32Value.internalBinaryRead(reader, reader.uint32(), options, message.auid);
                    break;
                case /* tetragon.Pod pod */ 10:
                    message.pod = Pod.internalBinaryRead(reader, reader.uint32(), options, message.pod);
                    break;
                case /* string docker */ 11:
                    message.docker = reader.string();
                    break;
                case /* string parent_exec_id */ 12:
                    message.parent_exec_id = reader.string();
                    break;
                case /* uint32 refcnt */ 13:
                    message.refcnt = reader.uint32();
                    break;
                case /* tetragon.Capabilities cap */ 14:
                    message.cap = Capabilities.internalBinaryRead(reader, reader.uint32(), options, message.cap);
                    break;
                case /* tetragon.Namespaces ns */ 15:
                    message.ns = Namespaces.internalBinaryRead(reader, reader.uint32(), options, message.ns);
                    break;
                case /* google.protobuf.UInt32Value tid */ 16:
                    message.tid = UInt32Value.internalBinaryRead(reader, reader.uint32(), options, message.tid);
                    break;
                case /* tetragon.ProcessCredentials process_credentials */ 17:
                    message.process_credentials = ProcessCredentials.internalBinaryRead(reader, reader.uint32(), options, message.process_credentials);
                    break;
                case /* tetragon.BinaryProperties binary_properties */ 18:
                    message.binary_properties = BinaryProperties.internalBinaryRead(reader, reader.uint32(), options, message.binary_properties);
                    break;
                case /* tetragon.UserRecord user */ 19:
                    message.user = UserRecord.internalBinaryRead(reader, reader.uint32(), options, message.user);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Process, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string exec_id = 1; */
        if (message.exec_id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.exec_id);
        /* google.protobuf.UInt32Value pid = 2; */
        if (message.pid)
            UInt32Value.internalBinaryWrite(message.pid, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.UInt32Value uid = 3; */
        if (message.uid)
            UInt32Value.internalBinaryWrite(message.uid, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* string cwd = 4; */
        if (message.cwd !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.cwd);
        /* string binary = 5; */
        if (message.binary !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.binary);
        /* string arguments = 6; */
        if (message.arguments !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.arguments);
        /* string flags = 7; */
        if (message.flags !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.flags);
        /* google.protobuf.Timestamp start_time = 8; */
        if (message.start_time)
            Timestamp.internalBinaryWrite(message.start_time, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.UInt32Value auid = 9; */
        if (message.auid)
            UInt32Value.internalBinaryWrite(message.auid, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.Pod pod = 10; */
        if (message.pod)
            Pod.internalBinaryWrite(message.pod, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* string docker = 11; */
        if (message.docker !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.docker);
        /* string parent_exec_id = 12; */
        if (message.parent_exec_id !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.parent_exec_id);
        /* uint32 refcnt = 13; */
        if (message.refcnt !== 0)
            writer.tag(13, WireType.Varint).uint32(message.refcnt);
        /* tetragon.Capabilities cap = 14; */
        if (message.cap)
            Capabilities.internalBinaryWrite(message.cap, writer.tag(14, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.Namespaces ns = 15; */
        if (message.ns)
            Namespaces.internalBinaryWrite(message.ns, writer.tag(15, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.UInt32Value tid = 16; */
        if (message.tid)
            UInt32Value.internalBinaryWrite(message.tid, writer.tag(16, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.ProcessCredentials process_credentials = 17; */
        if (message.process_credentials)
            ProcessCredentials.internalBinaryWrite(message.process_credentials, writer.tag(17, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.BinaryProperties binary_properties = 18; */
        if (message.binary_properties)
            BinaryProperties.internalBinaryWrite(message.binary_properties, writer.tag(18, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.UserRecord user = 19; */
        if (message.user)
            UserRecord.internalBinaryWrite(message.user, writer.tag(19, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tetragon.Process
 */
export const Process = new Process$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ProcessExec$Type extends MessageType<ProcessExec> {
    constructor() {
        super("tetragon.ProcessExec", [
            { no: 1, name: "process", kind: "message", T: () => Process },
            { no: 2, name: "parent", kind: "message", T: () => Process },
            { no: 3, name: "ancestors", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Process }
        ]);
    }
    create(value?: PartialMessage<ProcessExec>): ProcessExec {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.ancestors = [];
        if (value !== undefined)
            reflectionMergePartial<ProcessExec>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ProcessExec): ProcessExec {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* tetragon.Process process */ 1:
                    message.process = Process.internalBinaryRead(reader, reader.uint32(), options, message.process);
                    break;
                case /* tetragon.Process parent */ 2:
                    message.parent = Process.internalBinaryRead(reader, reader.uint32(), options, message.parent);
                    break;
                case /* repeated tetragon.Process ancestors */ 3:
                    message.ancestors.push(Process.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ProcessExec, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* tetragon.Process process = 1; */
        if (message.process)
            Process.internalBinaryWrite(message.process, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.Process parent = 2; */
        if (message.parent)
            Process.internalBinaryWrite(message.parent, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* repeated tetragon.Process ancestors = 3; */
        for (let i = 0; i < message.ancestors.length; i++)
            Process.internalBinaryWrite(message.ancestors[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tetragon.ProcessExec
 */
export const ProcessExec = new ProcessExec$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ProcessExit$Type extends MessageType<ProcessExit> {
    constructor() {
        super("tetragon.ProcessExit", [
            { no: 1, name: "process", kind: "message", T: () => Process },
            { no: 2, name: "parent", kind: "message", T: () => Process },
            { no: 3, name: "signal", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "status", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 5, name: "time", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value?: PartialMessage<ProcessExit>): ProcessExit {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.signal = "";
        message.status = 0;
        if (value !== undefined)
            reflectionMergePartial<ProcessExit>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ProcessExit): ProcessExit {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* tetragon.Process process */ 1:
                    message.process = Process.internalBinaryRead(reader, reader.uint32(), options, message.process);
                    break;
                case /* tetragon.Process parent */ 2:
                    message.parent = Process.internalBinaryRead(reader, reader.uint32(), options, message.parent);
                    break;
                case /* string signal */ 3:
                    message.signal = reader.string();
                    break;
                case /* uint32 status */ 4:
                    message.status = reader.uint32();
                    break;
                case /* google.protobuf.Timestamp time */ 5:
                    message.time = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.time);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ProcessExit, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* tetragon.Process process = 1; */
        if (message.process)
            Process.internalBinaryWrite(message.process, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.Process parent = 2; */
        if (message.parent)
            Process.internalBinaryWrite(message.parent, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* string signal = 3; */
        if (message.signal !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.signal);
        /* uint32 status = 4; */
        if (message.status !== 0)
            writer.tag(4, WireType.Varint).uint32(message.status);
        /* google.protobuf.Timestamp time = 5; */
        if (message.time)
            Timestamp.internalBinaryWrite(message.time, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tetragon.ProcessExit
 */
export const ProcessExit = new ProcessExit$Type();
// @generated message type with reflection information, may provide speed optimized methods
class KprobeSock$Type extends MessageType<KprobeSock> {
    constructor() {
        super("tetragon.KprobeSock", [
            { no: 1, name: "family", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "protocol", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "mark", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 5, name: "priority", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 6, name: "saddr", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "daddr", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "sport", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 9, name: "dport", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 10, name: "cookie", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 11, name: "state", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<KprobeSock>): KprobeSock {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.family = "";
        message.type = "";
        message.protocol = "";
        message.mark = 0;
        message.priority = 0;
        message.saddr = "";
        message.daddr = "";
        message.sport = 0;
        message.dport = 0;
        message.cookie = 0;
        message.state = "";
        if (value !== undefined)
            reflectionMergePartial<KprobeSock>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: KprobeSock): KprobeSock {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string family */ 1:
                    message.family = reader.string();
                    break;
                case /* string type */ 2:
                    message.type = reader.string();
                    break;
                case /* string protocol */ 3:
                    message.protocol = reader.string();
                    break;
                case /* uint32 mark */ 4:
                    message.mark = reader.uint32();
                    break;
                case /* uint32 priority */ 5:
                    message.priority = reader.uint32();
                    break;
                case /* string saddr */ 6:
                    message.saddr = reader.string();
                    break;
                case /* string daddr */ 7:
                    message.daddr = reader.string();
                    break;
                case /* uint32 sport */ 8:
                    message.sport = reader.uint32();
                    break;
                case /* uint32 dport */ 9:
                    message.dport = reader.uint32();
                    break;
                case /* uint64 cookie */ 10:
                    message.cookie = reader.uint64().toNumber();
                    break;
                case /* string state */ 11:
                    message.state = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: KprobeSock, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string family = 1; */
        if (message.family !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.family);
        /* string type = 2; */
        if (message.type !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.type);
        /* string protocol = 3; */
        if (message.protocol !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.protocol);
        /* uint32 mark = 4; */
        if (message.mark !== 0)
            writer.tag(4, WireType.Varint).uint32(message.mark);
        /* uint32 priority = 5; */
        if (message.priority !== 0)
            writer.tag(5, WireType.Varint).uint32(message.priority);
        /* string saddr = 6; */
        if (message.saddr !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.saddr);
        /* string daddr = 7; */
        if (message.daddr !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.daddr);
        /* uint32 sport = 8; */
        if (message.sport !== 0)
            writer.tag(8, WireType.Varint).uint32(message.sport);
        /* uint32 dport = 9; */
        if (message.dport !== 0)
            writer.tag(9, WireType.Varint).uint32(message.dport);
        /* uint64 cookie = 10; */
        if (message.cookie !== 0)
            writer.tag(10, WireType.Varint).uint64(message.cookie);
        /* string state = 11; */
        if (message.state !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.state);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tetragon.KprobeSock
 */
export const KprobeSock = new KprobeSock$Type();
// @generated message type with reflection information, may provide speed optimized methods
class KprobeSkb$Type extends MessageType<KprobeSkb> {
    constructor() {
        super("tetragon.KprobeSkb", [
            { no: 1, name: "hash", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 2, name: "len", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 3, name: "priority", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 4, name: "mark", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 5, name: "saddr", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "daddr", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "sport", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 8, name: "dport", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 9, name: "proto", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 10, name: "sec_path_len", kind: "scalar", localName: "sec_path_len", T: 13 /*ScalarType.UINT32*/ },
            { no: 11, name: "sec_path_olen", kind: "scalar", localName: "sec_path_olen", T: 13 /*ScalarType.UINT32*/ },
            { no: 12, name: "protocol", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "family", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<KprobeSkb>): KprobeSkb {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.hash = 0;
        message.len = 0;
        message.priority = 0;
        message.mark = 0;
        message.saddr = "";
        message.daddr = "";
        message.sport = 0;
        message.dport = 0;
        message.proto = 0;
        message.sec_path_len = 0;
        message.sec_path_olen = 0;
        message.protocol = "";
        message.family = "";
        if (value !== undefined)
            reflectionMergePartial<KprobeSkb>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: KprobeSkb): KprobeSkb {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint32 hash */ 1:
                    message.hash = reader.uint32();
                    break;
                case /* uint32 len */ 2:
                    message.len = reader.uint32();
                    break;
                case /* uint32 priority */ 3:
                    message.priority = reader.uint32();
                    break;
                case /* uint32 mark */ 4:
                    message.mark = reader.uint32();
                    break;
                case /* string saddr */ 5:
                    message.saddr = reader.string();
                    break;
                case /* string daddr */ 6:
                    message.daddr = reader.string();
                    break;
                case /* uint32 sport */ 7:
                    message.sport = reader.uint32();
                    break;
                case /* uint32 dport */ 8:
                    message.dport = reader.uint32();
                    break;
                case /* uint32 proto */ 9:
                    message.proto = reader.uint32();
                    break;
                case /* uint32 sec_path_len */ 10:
                    message.sec_path_len = reader.uint32();
                    break;
                case /* uint32 sec_path_olen */ 11:
                    message.sec_path_olen = reader.uint32();
                    break;
                case /* string protocol */ 12:
                    message.protocol = reader.string();
                    break;
                case /* string family */ 13:
                    message.family = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: KprobeSkb, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint32 hash = 1; */
        if (message.hash !== 0)
            writer.tag(1, WireType.Varint).uint32(message.hash);
        /* uint32 len = 2; */
        if (message.len !== 0)
            writer.tag(2, WireType.Varint).uint32(message.len);
        /* uint32 priority = 3; */
        if (message.priority !== 0)
            writer.tag(3, WireType.Varint).uint32(message.priority);
        /* uint32 mark = 4; */
        if (message.mark !== 0)
            writer.tag(4, WireType.Varint).uint32(message.mark);
        /* string saddr = 5; */
        if (message.saddr !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.saddr);
        /* string daddr = 6; */
        if (message.daddr !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.daddr);
        /* uint32 sport = 7; */
        if (message.sport !== 0)
            writer.tag(7, WireType.Varint).uint32(message.sport);
        /* uint32 dport = 8; */
        if (message.dport !== 0)
            writer.tag(8, WireType.Varint).uint32(message.dport);
        /* uint32 proto = 9; */
        if (message.proto !== 0)
            writer.tag(9, WireType.Varint).uint32(message.proto);
        /* uint32 sec_path_len = 10; */
        if (message.sec_path_len !== 0)
            writer.tag(10, WireType.Varint).uint32(message.sec_path_len);
        /* uint32 sec_path_olen = 11; */
        if (message.sec_path_olen !== 0)
            writer.tag(11, WireType.Varint).uint32(message.sec_path_olen);
        /* string protocol = 12; */
        if (message.protocol !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.protocol);
        /* string family = 13; */
        if (message.family !== "")
            writer.tag(13, WireType.LengthDelimited).string(message.family);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tetragon.KprobeSkb
 */
export const KprobeSkb = new KprobeSkb$Type();
// @generated message type with reflection information, may provide speed optimized methods
class KprobeNetDev$Type extends MessageType<KprobeNetDev> {
    constructor() {
        super("tetragon.KprobeNetDev", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<KprobeNetDev>): KprobeNetDev {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.name = "";
        if (value !== undefined)
            reflectionMergePartial<KprobeNetDev>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: KprobeNetDev): KprobeNetDev {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: KprobeNetDev, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tetragon.KprobeNetDev
 */
export const KprobeNetDev = new KprobeNetDev$Type();
// @generated message type with reflection information, may provide speed optimized methods
class KprobePath$Type extends MessageType<KprobePath> {
    constructor() {
        super("tetragon.KprobePath", [
            { no: 1, name: "mount", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "path", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "flags", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "permission", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<KprobePath>): KprobePath {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.mount = "";
        message.path = "";
        message.flags = "";
        message.permission = "";
        if (value !== undefined)
            reflectionMergePartial<KprobePath>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: KprobePath): KprobePath {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string mount */ 1:
                    message.mount = reader.string();
                    break;
                case /* string path */ 2:
                    message.path = reader.string();
                    break;
                case /* string flags */ 3:
                    message.flags = reader.string();
                    break;
                case /* string permission */ 4:
                    message.permission = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: KprobePath, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string mount = 1; */
        if (message.mount !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.mount);
        /* string path = 2; */
        if (message.path !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.path);
        /* string flags = 3; */
        if (message.flags !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.flags);
        /* string permission = 4; */
        if (message.permission !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.permission);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tetragon.KprobePath
 */
export const KprobePath = new KprobePath$Type();
// @generated message type with reflection information, may provide speed optimized methods
class KprobeFile$Type extends MessageType<KprobeFile> {
    constructor() {
        super("tetragon.KprobeFile", [
            { no: 1, name: "mount", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "path", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "flags", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "permission", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<KprobeFile>): KprobeFile {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.mount = "";
        message.path = "";
        message.flags = "";
        message.permission = "";
        if (value !== undefined)
            reflectionMergePartial<KprobeFile>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: KprobeFile): KprobeFile {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string mount */ 1:
                    message.mount = reader.string();
                    break;
                case /* string path */ 2:
                    message.path = reader.string();
                    break;
                case /* string flags */ 3:
                    message.flags = reader.string();
                    break;
                case /* string permission */ 4:
                    message.permission = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: KprobeFile, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string mount = 1; */
        if (message.mount !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.mount);
        /* string path = 2; */
        if (message.path !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.path);
        /* string flags = 3; */
        if (message.flags !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.flags);
        /* string permission = 4; */
        if (message.permission !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.permission);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tetragon.KprobeFile
 */
export const KprobeFile = new KprobeFile$Type();
// @generated message type with reflection information, may provide speed optimized methods
class KprobeTruncatedBytes$Type extends MessageType<KprobeTruncatedBytes> {
    constructor() {
        super("tetragon.KprobeTruncatedBytes", [
            { no: 1, name: "bytes_arg", kind: "scalar", localName: "bytes_arg", T: 12 /*ScalarType.BYTES*/ },
            { no: 2, name: "orig_size", kind: "scalar", localName: "orig_size", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<KprobeTruncatedBytes>): KprobeTruncatedBytes {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.bytes_arg = new Uint8Array(0);
        message.orig_size = 0;
        if (value !== undefined)
            reflectionMergePartial<KprobeTruncatedBytes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: KprobeTruncatedBytes): KprobeTruncatedBytes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes bytes_arg */ 1:
                    message.bytes_arg = reader.bytes();
                    break;
                case /* uint64 orig_size */ 2:
                    message.orig_size = reader.uint64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: KprobeTruncatedBytes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes bytes_arg = 1; */
        if (message.bytes_arg.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.bytes_arg);
        /* uint64 orig_size = 2; */
        if (message.orig_size !== 0)
            writer.tag(2, WireType.Varint).uint64(message.orig_size);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tetragon.KprobeTruncatedBytes
 */
export const KprobeTruncatedBytes = new KprobeTruncatedBytes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class KprobeCred$Type extends MessageType<KprobeCred> {
    constructor() {
        super("tetragon.KprobeCred", [
            { no: 1, name: "permitted", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["tetragon.CapabilitiesType", CapabilitiesType] },
            { no: 2, name: "effective", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["tetragon.CapabilitiesType", CapabilitiesType] },
            { no: 3, name: "inheritable", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["tetragon.CapabilitiesType", CapabilitiesType] }
        ]);
    }
    create(value?: PartialMessage<KprobeCred>): KprobeCred {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.permitted = [];
        message.effective = [];
        message.inheritable = [];
        if (value !== undefined)
            reflectionMergePartial<KprobeCred>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: KprobeCred): KprobeCred {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated tetragon.CapabilitiesType permitted */ 1:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.permitted.push(reader.int32());
                    else
                        message.permitted.push(reader.int32());
                    break;
                case /* repeated tetragon.CapabilitiesType effective */ 2:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.effective.push(reader.int32());
                    else
                        message.effective.push(reader.int32());
                    break;
                case /* repeated tetragon.CapabilitiesType inheritable */ 3:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.inheritable.push(reader.int32());
                    else
                        message.inheritable.push(reader.int32());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: KprobeCred, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated tetragon.CapabilitiesType permitted = 1; */
        if (message.permitted.length) {
            writer.tag(1, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.permitted.length; i++)
                writer.int32(message.permitted[i]);
            writer.join();
        }
        /* repeated tetragon.CapabilitiesType effective = 2; */
        if (message.effective.length) {
            writer.tag(2, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.effective.length; i++)
                writer.int32(message.effective[i]);
            writer.join();
        }
        /* repeated tetragon.CapabilitiesType inheritable = 3; */
        if (message.inheritable.length) {
            writer.tag(3, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.inheritable.length; i++)
                writer.int32(message.inheritable[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tetragon.KprobeCred
 */
export const KprobeCred = new KprobeCred$Type();
// @generated message type with reflection information, may provide speed optimized methods
class KprobeLinuxBinprm$Type extends MessageType<KprobeLinuxBinprm> {
    constructor() {
        super("tetragon.KprobeLinuxBinprm", [
            { no: 1, name: "path", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "flags", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "permission", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<KprobeLinuxBinprm>): KprobeLinuxBinprm {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.path = "";
        message.flags = "";
        message.permission = "";
        if (value !== undefined)
            reflectionMergePartial<KprobeLinuxBinprm>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: KprobeLinuxBinprm): KprobeLinuxBinprm {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string path */ 1:
                    message.path = reader.string();
                    break;
                case /* string flags */ 2:
                    message.flags = reader.string();
                    break;
                case /* string permission */ 3:
                    message.permission = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: KprobeLinuxBinprm, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string path = 1; */
        if (message.path !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.path);
        /* string flags = 2; */
        if (message.flags !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.flags);
        /* string permission = 3; */
        if (message.permission !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.permission);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tetragon.KprobeLinuxBinprm
 */
export const KprobeLinuxBinprm = new KprobeLinuxBinprm$Type();
// @generated message type with reflection information, may provide speed optimized methods
class KprobeCapability$Type extends MessageType<KprobeCapability> {
    constructor() {
        super("tetragon.KprobeCapability", [
            { no: 1, name: "value", kind: "message", T: () => Int32Value },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<KprobeCapability>): KprobeCapability {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.name = "";
        if (value !== undefined)
            reflectionMergePartial<KprobeCapability>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: KprobeCapability): KprobeCapability {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* google.protobuf.Int32Value value */ 1:
                    message.value = Int32Value.internalBinaryRead(reader, reader.uint32(), options, message.value);
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: KprobeCapability, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* google.protobuf.Int32Value value = 1; */
        if (message.value)
            Int32Value.internalBinaryWrite(message.value, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tetragon.KprobeCapability
 */
export const KprobeCapability = new KprobeCapability$Type();
// @generated message type with reflection information, may provide speed optimized methods
class KprobeUserNamespace$Type extends MessageType<KprobeUserNamespace> {
    constructor() {
        super("tetragon.KprobeUserNamespace", [
            { no: 1, name: "level", kind: "message", T: () => Int32Value },
            { no: 2, name: "owner", kind: "message", T: () => UInt32Value },
            { no: 3, name: "group", kind: "message", T: () => UInt32Value },
            { no: 4, name: "ns", kind: "message", T: () => Namespace }
        ]);
    }
    create(value?: PartialMessage<KprobeUserNamespace>): KprobeUserNamespace {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<KprobeUserNamespace>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: KprobeUserNamespace): KprobeUserNamespace {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* google.protobuf.Int32Value level */ 1:
                    message.level = Int32Value.internalBinaryRead(reader, reader.uint32(), options, message.level);
                    break;
                case /* google.protobuf.UInt32Value owner */ 2:
                    message.owner = UInt32Value.internalBinaryRead(reader, reader.uint32(), options, message.owner);
                    break;
                case /* google.protobuf.UInt32Value group */ 3:
                    message.group = UInt32Value.internalBinaryRead(reader, reader.uint32(), options, message.group);
                    break;
                case /* tetragon.Namespace ns */ 4:
                    message.ns = Namespace.internalBinaryRead(reader, reader.uint32(), options, message.ns);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: KprobeUserNamespace, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* google.protobuf.Int32Value level = 1; */
        if (message.level)
            Int32Value.internalBinaryWrite(message.level, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.UInt32Value owner = 2; */
        if (message.owner)
            UInt32Value.internalBinaryWrite(message.owner, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.UInt32Value group = 3; */
        if (message.group)
            UInt32Value.internalBinaryWrite(message.group, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.Namespace ns = 4; */
        if (message.ns)
            Namespace.internalBinaryWrite(message.ns, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tetragon.KprobeUserNamespace
 */
export const KprobeUserNamespace = new KprobeUserNamespace$Type();
// @generated message type with reflection information, may provide speed optimized methods
class KprobeBpfAttr$Type extends MessageType<KprobeBpfAttr> {
    constructor() {
        super("tetragon.KprobeBpfAttr", [
            { no: 1, name: "ProgType", kind: "scalar", localName: "ProgType", jsonName: "ProgType", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "InsnCnt", kind: "scalar", localName: "InsnCnt", jsonName: "InsnCnt", T: 13 /*ScalarType.UINT32*/ },
            { no: 3, name: "ProgName", kind: "scalar", localName: "ProgName", jsonName: "ProgName", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<KprobeBpfAttr>): KprobeBpfAttr {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.ProgType = "";
        message.InsnCnt = 0;
        message.ProgName = "";
        if (value !== undefined)
            reflectionMergePartial<KprobeBpfAttr>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: KprobeBpfAttr): KprobeBpfAttr {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string ProgType = 1 [json_name = "ProgType"];*/ 1:
                    message.ProgType = reader.string();
                    break;
                case /* uint32 InsnCnt = 2 [json_name = "InsnCnt"];*/ 2:
                    message.InsnCnt = reader.uint32();
                    break;
                case /* string ProgName = 3 [json_name = "ProgName"];*/ 3:
                    message.ProgName = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: KprobeBpfAttr, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string ProgType = 1 [json_name = "ProgType"]; */
        if (message.ProgType !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.ProgType);
        /* uint32 InsnCnt = 2 [json_name = "InsnCnt"]; */
        if (message.InsnCnt !== 0)
            writer.tag(2, WireType.Varint).uint32(message.InsnCnt);
        /* string ProgName = 3 [json_name = "ProgName"]; */
        if (message.ProgName !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.ProgName);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tetragon.KprobeBpfAttr
 */
export const KprobeBpfAttr = new KprobeBpfAttr$Type();
// @generated message type with reflection information, may provide speed optimized methods
class KprobePerfEvent$Type extends MessageType<KprobePerfEvent> {
    constructor() {
        super("tetragon.KprobePerfEvent", [
            { no: 1, name: "KprobeFunc", kind: "scalar", localName: "KprobeFunc", jsonName: "KprobeFunc", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "Type", kind: "scalar", localName: "Type", jsonName: "Type", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "Config", kind: "scalar", localName: "Config", jsonName: "Config", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "ProbeOffset", kind: "scalar", localName: "ProbeOffset", jsonName: "ProbeOffset", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<KprobePerfEvent>): KprobePerfEvent {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.KprobeFunc = "";
        message.Type = "";
        message.Config = 0;
        message.ProbeOffset = 0;
        if (value !== undefined)
            reflectionMergePartial<KprobePerfEvent>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: KprobePerfEvent): KprobePerfEvent {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string KprobeFunc = 1 [json_name = "KprobeFunc"];*/ 1:
                    message.KprobeFunc = reader.string();
                    break;
                case /* string Type = 2 [json_name = "Type"];*/ 2:
                    message.Type = reader.string();
                    break;
                case /* uint64 Config = 3 [json_name = "Config"];*/ 3:
                    message.Config = reader.uint64().toNumber();
                    break;
                case /* uint64 ProbeOffset = 4 [json_name = "ProbeOffset"];*/ 4:
                    message.ProbeOffset = reader.uint64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: KprobePerfEvent, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string KprobeFunc = 1 [json_name = "KprobeFunc"]; */
        if (message.KprobeFunc !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.KprobeFunc);
        /* string Type = 2 [json_name = "Type"]; */
        if (message.Type !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.Type);
        /* uint64 Config = 3 [json_name = "Config"]; */
        if (message.Config !== 0)
            writer.tag(3, WireType.Varint).uint64(message.Config);
        /* uint64 ProbeOffset = 4 [json_name = "ProbeOffset"]; */
        if (message.ProbeOffset !== 0)
            writer.tag(4, WireType.Varint).uint64(message.ProbeOffset);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tetragon.KprobePerfEvent
 */
export const KprobePerfEvent = new KprobePerfEvent$Type();
// @generated message type with reflection information, may provide speed optimized methods
class KprobeBpfMap$Type extends MessageType<KprobeBpfMap> {
    constructor() {
        super("tetragon.KprobeBpfMap", [
            { no: 1, name: "MapType", kind: "scalar", localName: "MapType", jsonName: "MapType", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "KeySize", kind: "scalar", localName: "KeySize", jsonName: "KeySize", T: 13 /*ScalarType.UINT32*/ },
            { no: 3, name: "ValueSize", kind: "scalar", localName: "ValueSize", jsonName: "ValueSize", T: 13 /*ScalarType.UINT32*/ },
            { no: 4, name: "MaxEntries", kind: "scalar", localName: "MaxEntries", jsonName: "MaxEntries", T: 13 /*ScalarType.UINT32*/ },
            { no: 5, name: "MapName", kind: "scalar", localName: "MapName", jsonName: "MapName", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<KprobeBpfMap>): KprobeBpfMap {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.MapType = "";
        message.KeySize = 0;
        message.ValueSize = 0;
        message.MaxEntries = 0;
        message.MapName = "";
        if (value !== undefined)
            reflectionMergePartial<KprobeBpfMap>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: KprobeBpfMap): KprobeBpfMap {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string MapType = 1 [json_name = "MapType"];*/ 1:
                    message.MapType = reader.string();
                    break;
                case /* uint32 KeySize = 2 [json_name = "KeySize"];*/ 2:
                    message.KeySize = reader.uint32();
                    break;
                case /* uint32 ValueSize = 3 [json_name = "ValueSize"];*/ 3:
                    message.ValueSize = reader.uint32();
                    break;
                case /* uint32 MaxEntries = 4 [json_name = "MaxEntries"];*/ 4:
                    message.MaxEntries = reader.uint32();
                    break;
                case /* string MapName = 5 [json_name = "MapName"];*/ 5:
                    message.MapName = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: KprobeBpfMap, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string MapType = 1 [json_name = "MapType"]; */
        if (message.MapType !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.MapType);
        /* uint32 KeySize = 2 [json_name = "KeySize"]; */
        if (message.KeySize !== 0)
            writer.tag(2, WireType.Varint).uint32(message.KeySize);
        /* uint32 ValueSize = 3 [json_name = "ValueSize"]; */
        if (message.ValueSize !== 0)
            writer.tag(3, WireType.Varint).uint32(message.ValueSize);
        /* uint32 MaxEntries = 4 [json_name = "MaxEntries"]; */
        if (message.MaxEntries !== 0)
            writer.tag(4, WireType.Varint).uint32(message.MaxEntries);
        /* string MapName = 5 [json_name = "MapName"]; */
        if (message.MapName !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.MapName);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tetragon.KprobeBpfMap
 */
export const KprobeBpfMap = new KprobeBpfMap$Type();
// @generated message type with reflection information, may provide speed optimized methods
class KprobeArgument$Type extends MessageType<KprobeArgument> {
    constructor() {
        super("tetragon.KprobeArgument", [
            { no: 1, name: "string_arg", kind: "scalar", localName: "string_arg", oneof: "arg", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "int_arg", kind: "scalar", localName: "int_arg", oneof: "arg", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "skb_arg", kind: "message", localName: "skb_arg", oneof: "arg", T: () => KprobeSkb },
            { no: 4, name: "size_arg", kind: "scalar", localName: "size_arg", oneof: "arg", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 5, name: "bytes_arg", kind: "scalar", localName: "bytes_arg", oneof: "arg", T: 12 /*ScalarType.BYTES*/ },
            { no: 6, name: "path_arg", kind: "message", localName: "path_arg", oneof: "arg", T: () => KprobePath },
            { no: 7, name: "file_arg", kind: "message", localName: "file_arg", oneof: "arg", T: () => KprobeFile },
            { no: 8, name: "truncated_bytes_arg", kind: "message", localName: "truncated_bytes_arg", oneof: "arg", T: () => KprobeTruncatedBytes },
            { no: 9, name: "sock_arg", kind: "message", localName: "sock_arg", oneof: "arg", T: () => KprobeSock },
            { no: 10, name: "cred_arg", kind: "message", localName: "cred_arg", oneof: "arg", T: () => KprobeCred },
            { no: 11, name: "long_arg", kind: "scalar", localName: "long_arg", oneof: "arg", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 12, name: "bpf_attr_arg", kind: "message", localName: "bpf_attr_arg", oneof: "arg", T: () => KprobeBpfAttr },
            { no: 13, name: "perf_event_arg", kind: "message", localName: "perf_event_arg", oneof: "arg", T: () => KprobePerfEvent },
            { no: 14, name: "bpf_map_arg", kind: "message", localName: "bpf_map_arg", oneof: "arg", T: () => KprobeBpfMap },
            { no: 15, name: "uint_arg", kind: "scalar", localName: "uint_arg", oneof: "arg", T: 13 /*ScalarType.UINT32*/ },
            { no: 16, name: "user_namespace_arg", kind: "message", localName: "user_namespace_arg", oneof: "arg", T: () => KprobeUserNamespace },
            { no: 17, name: "capability_arg", kind: "message", localName: "capability_arg", oneof: "arg", T: () => KprobeCapability },
            { no: 19, name: "process_credentials_arg", kind: "message", localName: "process_credentials_arg", oneof: "arg", T: () => ProcessCredentials },
            { no: 20, name: "user_ns_arg", kind: "message", localName: "user_ns_arg", oneof: "arg", T: () => UserNamespace },
            { no: 21, name: "module_arg", kind: "message", localName: "module_arg", oneof: "arg", T: () => KernelModule },
            { no: 22, name: "kernel_cap_t_arg", kind: "scalar", localName: "kernel_cap_t_arg", oneof: "arg", T: 9 /*ScalarType.STRING*/ },
            { no: 23, name: "cap_inheritable_arg", kind: "scalar", localName: "cap_inheritable_arg", oneof: "arg", T: 9 /*ScalarType.STRING*/ },
            { no: 24, name: "cap_permitted_arg", kind: "scalar", localName: "cap_permitted_arg", oneof: "arg", T: 9 /*ScalarType.STRING*/ },
            { no: 25, name: "cap_effective_arg", kind: "scalar", localName: "cap_effective_arg", oneof: "arg", T: 9 /*ScalarType.STRING*/ },
            { no: 26, name: "linux_binprm_arg", kind: "message", localName: "linux_binprm_arg", oneof: "arg", T: () => KprobeLinuxBinprm },
            { no: 27, name: "net_dev_arg", kind: "message", localName: "net_dev_arg", oneof: "arg", T: () => KprobeNetDev },
            { no: 18, name: "label", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<KprobeArgument>): KprobeArgument {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.arg = { oneofKind: undefined };
        message.label = "";
        if (value !== undefined)
            reflectionMergePartial<KprobeArgument>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: KprobeArgument): KprobeArgument {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string string_arg */ 1:
                    message.arg = {
                        oneofKind: "string_arg",
                        string_arg: reader.string()
                    };
                    break;
                case /* int32 int_arg */ 2:
                    message.arg = {
                        oneofKind: "int_arg",
                        int_arg: reader.int32()
                    };
                    break;
                case /* tetragon.KprobeSkb skb_arg */ 3:
                    message.arg = {
                        oneofKind: "skb_arg",
                        skb_arg: KprobeSkb.internalBinaryRead(reader, reader.uint32(), options, (message.arg as any).skb_arg)
                    };
                    break;
                case /* uint64 size_arg */ 4:
                    message.arg = {
                        oneofKind: "size_arg",
                        size_arg: reader.uint64().toNumber()
                    };
                    break;
                case /* bytes bytes_arg */ 5:
                    message.arg = {
                        oneofKind: "bytes_arg",
                        bytes_arg: reader.bytes()
                    };
                    break;
                case /* tetragon.KprobePath path_arg */ 6:
                    message.arg = {
                        oneofKind: "path_arg",
                        path_arg: KprobePath.internalBinaryRead(reader, reader.uint32(), options, (message.arg as any).path_arg)
                    };
                    break;
                case /* tetragon.KprobeFile file_arg */ 7:
                    message.arg = {
                        oneofKind: "file_arg",
                        file_arg: KprobeFile.internalBinaryRead(reader, reader.uint32(), options, (message.arg as any).file_arg)
                    };
                    break;
                case /* tetragon.KprobeTruncatedBytes truncated_bytes_arg */ 8:
                    message.arg = {
                        oneofKind: "truncated_bytes_arg",
                        truncated_bytes_arg: KprobeTruncatedBytes.internalBinaryRead(reader, reader.uint32(), options, (message.arg as any).truncated_bytes_arg)
                    };
                    break;
                case /* tetragon.KprobeSock sock_arg */ 9:
                    message.arg = {
                        oneofKind: "sock_arg",
                        sock_arg: KprobeSock.internalBinaryRead(reader, reader.uint32(), options, (message.arg as any).sock_arg)
                    };
                    break;
                case /* tetragon.KprobeCred cred_arg */ 10:
                    message.arg = {
                        oneofKind: "cred_arg",
                        cred_arg: KprobeCred.internalBinaryRead(reader, reader.uint32(), options, (message.arg as any).cred_arg)
                    };
                    break;
                case /* int64 long_arg */ 11:
                    message.arg = {
                        oneofKind: "long_arg",
                        long_arg: reader.int64().toNumber()
                    };
                    break;
                case /* tetragon.KprobeBpfAttr bpf_attr_arg */ 12:
                    message.arg = {
                        oneofKind: "bpf_attr_arg",
                        bpf_attr_arg: KprobeBpfAttr.internalBinaryRead(reader, reader.uint32(), options, (message.arg as any).bpf_attr_arg)
                    };
                    break;
                case /* tetragon.KprobePerfEvent perf_event_arg */ 13:
                    message.arg = {
                        oneofKind: "perf_event_arg",
                        perf_event_arg: KprobePerfEvent.internalBinaryRead(reader, reader.uint32(), options, (message.arg as any).perf_event_arg)
                    };
                    break;
                case /* tetragon.KprobeBpfMap bpf_map_arg */ 14:
                    message.arg = {
                        oneofKind: "bpf_map_arg",
                        bpf_map_arg: KprobeBpfMap.internalBinaryRead(reader, reader.uint32(), options, (message.arg as any).bpf_map_arg)
                    };
                    break;
                case /* uint32 uint_arg */ 15:
                    message.arg = {
                        oneofKind: "uint_arg",
                        uint_arg: reader.uint32()
                    };
                    break;
                case /* tetragon.KprobeUserNamespace user_namespace_arg = 16 [deprecated = true];*/ 16:
                    message.arg = {
                        oneofKind: "user_namespace_arg",
                        user_namespace_arg: KprobeUserNamespace.internalBinaryRead(reader, reader.uint32(), options, (message.arg as any).user_namespace_arg)
                    };
                    break;
                case /* tetragon.KprobeCapability capability_arg */ 17:
                    message.arg = {
                        oneofKind: "capability_arg",
                        capability_arg: KprobeCapability.internalBinaryRead(reader, reader.uint32(), options, (message.arg as any).capability_arg)
                    };
                    break;
                case /* tetragon.ProcessCredentials process_credentials_arg */ 19:
                    message.arg = {
                        oneofKind: "process_credentials_arg",
                        process_credentials_arg: ProcessCredentials.internalBinaryRead(reader, reader.uint32(), options, (message.arg as any).process_credentials_arg)
                    };
                    break;
                case /* tetragon.UserNamespace user_ns_arg */ 20:
                    message.arg = {
                        oneofKind: "user_ns_arg",
                        user_ns_arg: UserNamespace.internalBinaryRead(reader, reader.uint32(), options, (message.arg as any).user_ns_arg)
                    };
                    break;
                case /* tetragon.KernelModule module_arg */ 21:
                    message.arg = {
                        oneofKind: "module_arg",
                        module_arg: KernelModule.internalBinaryRead(reader, reader.uint32(), options, (message.arg as any).module_arg)
                    };
                    break;
                case /* string kernel_cap_t_arg */ 22:
                    message.arg = {
                        oneofKind: "kernel_cap_t_arg",
                        kernel_cap_t_arg: reader.string()
                    };
                    break;
                case /* string cap_inheritable_arg */ 23:
                    message.arg = {
                        oneofKind: "cap_inheritable_arg",
                        cap_inheritable_arg: reader.string()
                    };
                    break;
                case /* string cap_permitted_arg */ 24:
                    message.arg = {
                        oneofKind: "cap_permitted_arg",
                        cap_permitted_arg: reader.string()
                    };
                    break;
                case /* string cap_effective_arg */ 25:
                    message.arg = {
                        oneofKind: "cap_effective_arg",
                        cap_effective_arg: reader.string()
                    };
                    break;
                case /* tetragon.KprobeLinuxBinprm linux_binprm_arg */ 26:
                    message.arg = {
                        oneofKind: "linux_binprm_arg",
                        linux_binprm_arg: KprobeLinuxBinprm.internalBinaryRead(reader, reader.uint32(), options, (message.arg as any).linux_binprm_arg)
                    };
                    break;
                case /* tetragon.KprobeNetDev net_dev_arg */ 27:
                    message.arg = {
                        oneofKind: "net_dev_arg",
                        net_dev_arg: KprobeNetDev.internalBinaryRead(reader, reader.uint32(), options, (message.arg as any).net_dev_arg)
                    };
                    break;
                case /* string label */ 18:
                    message.label = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: KprobeArgument, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string string_arg = 1; */
        if (message.arg.oneofKind === "string_arg")
            writer.tag(1, WireType.LengthDelimited).string(message.arg.string_arg);
        /* int32 int_arg = 2; */
        if (message.arg.oneofKind === "int_arg")
            writer.tag(2, WireType.Varint).int32(message.arg.int_arg);
        /* tetragon.KprobeSkb skb_arg = 3; */
        if (message.arg.oneofKind === "skb_arg")
            KprobeSkb.internalBinaryWrite(message.arg.skb_arg, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* uint64 size_arg = 4; */
        if (message.arg.oneofKind === "size_arg")
            writer.tag(4, WireType.Varint).uint64(message.arg.size_arg);
        /* bytes bytes_arg = 5; */
        if (message.arg.oneofKind === "bytes_arg")
            writer.tag(5, WireType.LengthDelimited).bytes(message.arg.bytes_arg);
        /* tetragon.KprobePath path_arg = 6; */
        if (message.arg.oneofKind === "path_arg")
            KprobePath.internalBinaryWrite(message.arg.path_arg, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.KprobeFile file_arg = 7; */
        if (message.arg.oneofKind === "file_arg")
            KprobeFile.internalBinaryWrite(message.arg.file_arg, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.KprobeTruncatedBytes truncated_bytes_arg = 8; */
        if (message.arg.oneofKind === "truncated_bytes_arg")
            KprobeTruncatedBytes.internalBinaryWrite(message.arg.truncated_bytes_arg, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.KprobeSock sock_arg = 9; */
        if (message.arg.oneofKind === "sock_arg")
            KprobeSock.internalBinaryWrite(message.arg.sock_arg, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.KprobeCred cred_arg = 10; */
        if (message.arg.oneofKind === "cred_arg")
            KprobeCred.internalBinaryWrite(message.arg.cred_arg, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* int64 long_arg = 11; */
        if (message.arg.oneofKind === "long_arg")
            writer.tag(11, WireType.Varint).int64(message.arg.long_arg);
        /* tetragon.KprobeBpfAttr bpf_attr_arg = 12; */
        if (message.arg.oneofKind === "bpf_attr_arg")
            KprobeBpfAttr.internalBinaryWrite(message.arg.bpf_attr_arg, writer.tag(12, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.KprobePerfEvent perf_event_arg = 13; */
        if (message.arg.oneofKind === "perf_event_arg")
            KprobePerfEvent.internalBinaryWrite(message.arg.perf_event_arg, writer.tag(13, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.KprobeBpfMap bpf_map_arg = 14; */
        if (message.arg.oneofKind === "bpf_map_arg")
            KprobeBpfMap.internalBinaryWrite(message.arg.bpf_map_arg, writer.tag(14, WireType.LengthDelimited).fork(), options).join();
        /* uint32 uint_arg = 15; */
        if (message.arg.oneofKind === "uint_arg")
            writer.tag(15, WireType.Varint).uint32(message.arg.uint_arg);
        /* tetragon.KprobeUserNamespace user_namespace_arg = 16 [deprecated = true]; */
        if (message.arg.oneofKind === "user_namespace_arg")
            KprobeUserNamespace.internalBinaryWrite(message.arg.user_namespace_arg, writer.tag(16, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.KprobeCapability capability_arg = 17; */
        if (message.arg.oneofKind === "capability_arg")
            KprobeCapability.internalBinaryWrite(message.arg.capability_arg, writer.tag(17, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.ProcessCredentials process_credentials_arg = 19; */
        if (message.arg.oneofKind === "process_credentials_arg")
            ProcessCredentials.internalBinaryWrite(message.arg.process_credentials_arg, writer.tag(19, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.UserNamespace user_ns_arg = 20; */
        if (message.arg.oneofKind === "user_ns_arg")
            UserNamespace.internalBinaryWrite(message.arg.user_ns_arg, writer.tag(20, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.KernelModule module_arg = 21; */
        if (message.arg.oneofKind === "module_arg")
            KernelModule.internalBinaryWrite(message.arg.module_arg, writer.tag(21, WireType.LengthDelimited).fork(), options).join();
        /* string kernel_cap_t_arg = 22; */
        if (message.arg.oneofKind === "kernel_cap_t_arg")
            writer.tag(22, WireType.LengthDelimited).string(message.arg.kernel_cap_t_arg);
        /* string cap_inheritable_arg = 23; */
        if (message.arg.oneofKind === "cap_inheritable_arg")
            writer.tag(23, WireType.LengthDelimited).string(message.arg.cap_inheritable_arg);
        /* string cap_permitted_arg = 24; */
        if (message.arg.oneofKind === "cap_permitted_arg")
            writer.tag(24, WireType.LengthDelimited).string(message.arg.cap_permitted_arg);
        /* string cap_effective_arg = 25; */
        if (message.arg.oneofKind === "cap_effective_arg")
            writer.tag(25, WireType.LengthDelimited).string(message.arg.cap_effective_arg);
        /* tetragon.KprobeLinuxBinprm linux_binprm_arg = 26; */
        if (message.arg.oneofKind === "linux_binprm_arg")
            KprobeLinuxBinprm.internalBinaryWrite(message.arg.linux_binprm_arg, writer.tag(26, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.KprobeNetDev net_dev_arg = 27; */
        if (message.arg.oneofKind === "net_dev_arg")
            KprobeNetDev.internalBinaryWrite(message.arg.net_dev_arg, writer.tag(27, WireType.LengthDelimited).fork(), options).join();
        /* string label = 18; */
        if (message.label !== "")
            writer.tag(18, WireType.LengthDelimited).string(message.label);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tetragon.KprobeArgument
 */
export const KprobeArgument = new KprobeArgument$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ProcessKprobe$Type extends MessageType<ProcessKprobe> {
    constructor() {
        super("tetragon.ProcessKprobe", [
            { no: 1, name: "process", kind: "message", T: () => Process },
            { no: 2, name: "parent", kind: "message", T: () => Process },
            { no: 3, name: "function_name", kind: "scalar", localName: "function_name", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "args", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => KprobeArgument },
            { no: 5, name: "return", kind: "message", T: () => KprobeArgument },
            { no: 6, name: "action", kind: "enum", T: () => ["tetragon.KprobeAction", KprobeAction, "KPROBE_ACTION_"] },
            { no: 7, name: "kernel_stack_trace", kind: "message", localName: "kernel_stack_trace", repeat: 1 /*RepeatType.PACKED*/, T: () => StackTraceEntry },
            { no: 8, name: "policy_name", kind: "scalar", localName: "policy_name", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "return_action", kind: "enum", localName: "return_action", T: () => ["tetragon.KprobeAction", KprobeAction, "KPROBE_ACTION_"] },
            { no: 10, name: "message", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "tags", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "user_stack_trace", kind: "message", localName: "user_stack_trace", repeat: 1 /*RepeatType.PACKED*/, T: () => StackTraceEntry }
        ]);
    }
    create(value?: PartialMessage<ProcessKprobe>): ProcessKprobe {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.function_name = "";
        message.args = [];
        message.action = 0;
        message.kernel_stack_trace = [];
        message.policy_name = "";
        message.return_action = 0;
        message.message = "";
        message.tags = [];
        message.user_stack_trace = [];
        if (value !== undefined)
            reflectionMergePartial<ProcessKprobe>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ProcessKprobe): ProcessKprobe {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* tetragon.Process process */ 1:
                    message.process = Process.internalBinaryRead(reader, reader.uint32(), options, message.process);
                    break;
                case /* tetragon.Process parent */ 2:
                    message.parent = Process.internalBinaryRead(reader, reader.uint32(), options, message.parent);
                    break;
                case /* string function_name */ 3:
                    message.function_name = reader.string();
                    break;
                case /* repeated tetragon.KprobeArgument args */ 4:
                    message.args.push(KprobeArgument.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* tetragon.KprobeArgument return */ 5:
                    message.return = KprobeArgument.internalBinaryRead(reader, reader.uint32(), options, message.return);
                    break;
                case /* tetragon.KprobeAction action */ 6:
                    message.action = reader.int32();
                    break;
                case /* repeated tetragon.StackTraceEntry kernel_stack_trace */ 7:
                    message.kernel_stack_trace.push(StackTraceEntry.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string policy_name */ 8:
                    message.policy_name = reader.string();
                    break;
                case /* tetragon.KprobeAction return_action */ 9:
                    message.return_action = reader.int32();
                    break;
                case /* string message */ 10:
                    message.message = reader.string();
                    break;
                case /* repeated string tags */ 11:
                    message.tags.push(reader.string());
                    break;
                case /* repeated tetragon.StackTraceEntry user_stack_trace */ 12:
                    message.user_stack_trace.push(StackTraceEntry.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ProcessKprobe, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* tetragon.Process process = 1; */
        if (message.process)
            Process.internalBinaryWrite(message.process, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.Process parent = 2; */
        if (message.parent)
            Process.internalBinaryWrite(message.parent, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* string function_name = 3; */
        if (message.function_name !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.function_name);
        /* repeated tetragon.KprobeArgument args = 4; */
        for (let i = 0; i < message.args.length; i++)
            KprobeArgument.internalBinaryWrite(message.args[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.KprobeArgument return = 5; */
        if (message.return)
            KprobeArgument.internalBinaryWrite(message.return, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.KprobeAction action = 6; */
        if (message.action !== 0)
            writer.tag(6, WireType.Varint).int32(message.action);
        /* repeated tetragon.StackTraceEntry kernel_stack_trace = 7; */
        for (let i = 0; i < message.kernel_stack_trace.length; i++)
            StackTraceEntry.internalBinaryWrite(message.kernel_stack_trace[i], writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* string policy_name = 8; */
        if (message.policy_name !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.policy_name);
        /* tetragon.KprobeAction return_action = 9; */
        if (message.return_action !== 0)
            writer.tag(9, WireType.Varint).int32(message.return_action);
        /* string message = 10; */
        if (message.message !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.message);
        /* repeated string tags = 11; */
        for (let i = 0; i < message.tags.length; i++)
            writer.tag(11, WireType.LengthDelimited).string(message.tags[i]);
        /* repeated tetragon.StackTraceEntry user_stack_trace = 12; */
        for (let i = 0; i < message.user_stack_trace.length; i++)
            StackTraceEntry.internalBinaryWrite(message.user_stack_trace[i], writer.tag(12, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tetragon.ProcessKprobe
 */
export const ProcessKprobe = new ProcessKprobe$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ProcessTracepoint$Type extends MessageType<ProcessTracepoint> {
    constructor() {
        super("tetragon.ProcessTracepoint", [
            { no: 1, name: "process", kind: "message", T: () => Process },
            { no: 2, name: "parent", kind: "message", T: () => Process },
            { no: 4, name: "subsys", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "event", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "args", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => KprobeArgument },
            { no: 7, name: "policy_name", kind: "scalar", localName: "policy_name", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "action", kind: "enum", T: () => ["tetragon.KprobeAction", KprobeAction, "KPROBE_ACTION_"] },
            { no: 9, name: "message", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "tags", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ProcessTracepoint>): ProcessTracepoint {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.subsys = "";
        message.event = "";
        message.args = [];
        message.policy_name = "";
        message.action = 0;
        message.message = "";
        message.tags = [];
        if (value !== undefined)
            reflectionMergePartial<ProcessTracepoint>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ProcessTracepoint): ProcessTracepoint {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* tetragon.Process process */ 1:
                    message.process = Process.internalBinaryRead(reader, reader.uint32(), options, message.process);
                    break;
                case /* tetragon.Process parent */ 2:
                    message.parent = Process.internalBinaryRead(reader, reader.uint32(), options, message.parent);
                    break;
                case /* string subsys */ 4:
                    message.subsys = reader.string();
                    break;
                case /* string event */ 5:
                    message.event = reader.string();
                    break;
                case /* repeated tetragon.KprobeArgument args */ 6:
                    message.args.push(KprobeArgument.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string policy_name */ 7:
                    message.policy_name = reader.string();
                    break;
                case /* tetragon.KprobeAction action */ 8:
                    message.action = reader.int32();
                    break;
                case /* string message */ 9:
                    message.message = reader.string();
                    break;
                case /* repeated string tags */ 10:
                    message.tags.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ProcessTracepoint, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* tetragon.Process process = 1; */
        if (message.process)
            Process.internalBinaryWrite(message.process, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.Process parent = 2; */
        if (message.parent)
            Process.internalBinaryWrite(message.parent, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* string subsys = 4; */
        if (message.subsys !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.subsys);
        /* string event = 5; */
        if (message.event !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.event);
        /* repeated tetragon.KprobeArgument args = 6; */
        for (let i = 0; i < message.args.length; i++)
            KprobeArgument.internalBinaryWrite(message.args[i], writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* string policy_name = 7; */
        if (message.policy_name !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.policy_name);
        /* tetragon.KprobeAction action = 8; */
        if (message.action !== 0)
            writer.tag(8, WireType.Varint).int32(message.action);
        /* string message = 9; */
        if (message.message !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.message);
        /* repeated string tags = 10; */
        for (let i = 0; i < message.tags.length; i++)
            writer.tag(10, WireType.LengthDelimited).string(message.tags[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tetragon.ProcessTracepoint
 */
export const ProcessTracepoint = new ProcessTracepoint$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ProcessUprobe$Type extends MessageType<ProcessUprobe> {
    constructor() {
        super("tetragon.ProcessUprobe", [
            { no: 1, name: "process", kind: "message", T: () => Process },
            { no: 2, name: "parent", kind: "message", T: () => Process },
            { no: 3, name: "path", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "symbol", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "policy_name", kind: "scalar", localName: "policy_name", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "message", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "args", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => KprobeArgument },
            { no: 8, name: "tags", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ProcessUprobe>): ProcessUprobe {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.path = "";
        message.symbol = "";
        message.policy_name = "";
        message.message = "";
        message.args = [];
        message.tags = [];
        if (value !== undefined)
            reflectionMergePartial<ProcessUprobe>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ProcessUprobe): ProcessUprobe {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* tetragon.Process process */ 1:
                    message.process = Process.internalBinaryRead(reader, reader.uint32(), options, message.process);
                    break;
                case /* tetragon.Process parent */ 2:
                    message.parent = Process.internalBinaryRead(reader, reader.uint32(), options, message.parent);
                    break;
                case /* string path */ 3:
                    message.path = reader.string();
                    break;
                case /* string symbol */ 4:
                    message.symbol = reader.string();
                    break;
                case /* string policy_name */ 5:
                    message.policy_name = reader.string();
                    break;
                case /* string message */ 6:
                    message.message = reader.string();
                    break;
                case /* repeated tetragon.KprobeArgument args */ 7:
                    message.args.push(KprobeArgument.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated string tags */ 8:
                    message.tags.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ProcessUprobe, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* tetragon.Process process = 1; */
        if (message.process)
            Process.internalBinaryWrite(message.process, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* tetragon.Process parent = 2; */
        if (message.parent)
            Process.internalBinaryWrite(message.parent, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* string path = 3; */
        if (message.path !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.path);
        /* string symbol = 4; */
        if (message.symbol !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.symbol);
        /* string policy_name = 5; */
        if (message.policy_name !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.policy_name);
        /* string message = 6; */
        if (message.message !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.message);
        /* repeated tetragon.KprobeArgument args = 7; */
        for (let i = 0; i < message.args.length; i++)
            KprobeArgument.internalBinaryWrite(message.args[i], writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* repeated string tags = 8; */
        for (let i = 0; i < message.tags.length; i++)
            writer.tag(8, WireType.LengthDelimited).string(message.tags[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tetragon.ProcessUprobe
 */
export const ProcessUprobe = new ProcessUprobe$Type();
// @generated message type with reflection information, may provide speed optimized methods
class KernelModule$Type extends MessageType<KernelModule> {
    constructor() {
        super("tetragon.KernelModule", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "signature_ok", kind: "message", localName: "signature_ok", T: () => BoolValue },
            { no: 3, name: "tainted", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["tetragon.TaintedBitsType", TaintedBitsType] }
        ]);
    }
    create(value?: PartialMessage<KernelModule>): KernelModule {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.name = "";
        message.tainted = [];
        if (value !== undefined)
            reflectionMergePartial<KernelModule>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: KernelModule): KernelModule {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* google.protobuf.BoolValue signature_ok */ 2:
                    message.signature_ok = BoolValue.internalBinaryRead(reader, reader.uint32(), options, message.signature_ok);
                    break;
                case /* repeated tetragon.TaintedBitsType tainted */ 3:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.tainted.push(reader.int32());
                    else
                        message.tainted.push(reader.int32());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: KernelModule, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* google.protobuf.BoolValue signature_ok = 2; */
        if (message.signature_ok)
            BoolValue.internalBinaryWrite(message.signature_ok, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* repeated tetragon.TaintedBitsType tainted = 3; */
        if (message.tainted.length) {
            writer.tag(3, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.tainted.length; i++)
                writer.int32(message.tainted[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tetragon.KernelModule
 */
export const KernelModule = new KernelModule$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Test$Type extends MessageType<Test> {
    constructor() {
        super("tetragon.Test", [
            { no: 1, name: "arg0", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "arg1", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "arg2", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "arg3", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<Test>): Test {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.arg0 = 0;
        message.arg1 = 0;
        message.arg2 = 0;
        message.arg3 = 0;
        if (value !== undefined)
            reflectionMergePartial<Test>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Test): Test {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint64 arg0 */ 1:
                    message.arg0 = reader.uint64().toNumber();
                    break;
                case /* uint64 arg1 */ 2:
                    message.arg1 = reader.uint64().toNumber();
                    break;
                case /* uint64 arg2 */ 3:
                    message.arg2 = reader.uint64().toNumber();
                    break;
                case /* uint64 arg3 */ 4:
                    message.arg3 = reader.uint64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Test, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint64 arg0 = 1; */
        if (message.arg0 !== 0)
            writer.tag(1, WireType.Varint).uint64(message.arg0);
        /* uint64 arg1 = 2; */
        if (message.arg1 !== 0)
            writer.tag(2, WireType.Varint).uint64(message.arg1);
        /* uint64 arg2 = 3; */
        if (message.arg2 !== 0)
            writer.tag(3, WireType.Varint).uint64(message.arg2);
        /* uint64 arg3 = 4; */
        if (message.arg3 !== 0)
            writer.tag(4, WireType.Varint).uint64(message.arg3);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tetragon.Test
 */
export const Test = new Test$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetHealthStatusRequest$Type extends MessageType<GetHealthStatusRequest> {
    constructor() {
        super("tetragon.GetHealthStatusRequest", [
            { no: 1, name: "event_set", kind: "enum", localName: "event_set", repeat: 1 /*RepeatType.PACKED*/, T: () => ["tetragon.HealthStatusType", HealthStatusType, "HEALTH_STATUS_TYPE_"] }
        ]);
    }
    create(value?: PartialMessage<GetHealthStatusRequest>): GetHealthStatusRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.event_set = [];
        if (value !== undefined)
            reflectionMergePartial<GetHealthStatusRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetHealthStatusRequest): GetHealthStatusRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated tetragon.HealthStatusType event_set */ 1:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.event_set.push(reader.int32());
                    else
                        message.event_set.push(reader.int32());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetHealthStatusRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated tetragon.HealthStatusType event_set = 1; */
        if (message.event_set.length) {
            writer.tag(1, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.event_set.length; i++)
                writer.int32(message.event_set[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tetragon.GetHealthStatusRequest
 */
export const GetHealthStatusRequest = new GetHealthStatusRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HealthStatus$Type extends MessageType<HealthStatus> {
    constructor() {
        super("tetragon.HealthStatus", [
            { no: 1, name: "event", kind: "enum", T: () => ["tetragon.HealthStatusType", HealthStatusType, "HEALTH_STATUS_TYPE_"] },
            { no: 2, name: "status", kind: "enum", T: () => ["tetragon.HealthStatusResult", HealthStatusResult] },
            { no: 3, name: "details", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<HealthStatus>): HealthStatus {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.event = 0;
        message.status = 0;
        message.details = "";
        if (value !== undefined)
            reflectionMergePartial<HealthStatus>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: HealthStatus): HealthStatus {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* tetragon.HealthStatusType event */ 1:
                    message.event = reader.int32();
                    break;
                case /* tetragon.HealthStatusResult status */ 2:
                    message.status = reader.int32();
                    break;
                case /* string details */ 3:
                    message.details = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: HealthStatus, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* tetragon.HealthStatusType event = 1; */
        if (message.event !== 0)
            writer.tag(1, WireType.Varint).int32(message.event);
        /* tetragon.HealthStatusResult status = 2; */
        if (message.status !== 0)
            writer.tag(2, WireType.Varint).int32(message.status);
        /* string details = 3; */
        if (message.details !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.details);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tetragon.HealthStatus
 */
export const HealthStatus = new HealthStatus$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetHealthStatusResponse$Type extends MessageType<GetHealthStatusResponse> {
    constructor() {
        super("tetragon.GetHealthStatusResponse", [
            { no: 1, name: "health_status", kind: "message", localName: "health_status", repeat: 1 /*RepeatType.PACKED*/, T: () => HealthStatus }
        ]);
    }
    create(value?: PartialMessage<GetHealthStatusResponse>): GetHealthStatusResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.health_status = [];
        if (value !== undefined)
            reflectionMergePartial<GetHealthStatusResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetHealthStatusResponse): GetHealthStatusResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated tetragon.HealthStatus health_status */ 1:
                    message.health_status.push(HealthStatus.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetHealthStatusResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated tetragon.HealthStatus health_status = 1; */
        for (let i = 0; i < message.health_status.length; i++)
            HealthStatus.internalBinaryWrite(message.health_status[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tetragon.GetHealthStatusResponse
 */
export const GetHealthStatusResponse = new GetHealthStatusResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ProcessLoader$Type extends MessageType<ProcessLoader> {
    constructor() {
        super("tetragon.ProcessLoader", [
            { no: 1, name: "process", kind: "message", T: () => Process },
            { no: 2, name: "path", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "buildid", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<ProcessLoader>): ProcessLoader {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.path = "";
        message.buildid = new Uint8Array(0);
        if (value !== undefined)
            reflectionMergePartial<ProcessLoader>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ProcessLoader): ProcessLoader {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* tetragon.Process process */ 1:
                    message.process = Process.internalBinaryRead(reader, reader.uint32(), options, message.process);
                    break;
                case /* string path */ 2:
                    message.path = reader.string();
                    break;
                case /* bytes buildid */ 3:
                    message.buildid = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ProcessLoader, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* tetragon.Process process = 1; */
        if (message.process)
            Process.internalBinaryWrite(message.process, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string path = 2; */
        if (message.path !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.path);
        /* bytes buildid = 3; */
        if (message.buildid.length)
            writer.tag(3, WireType.LengthDelimited).bytes(message.buildid);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tetragon.ProcessLoader
 */
export const ProcessLoader = new ProcessLoader$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RuntimeHookRequest$Type extends MessageType<RuntimeHookRequest> {
    constructor() {
        super("tetragon.RuntimeHookRequest", [
            { no: 1, name: "createContainer", kind: "message", oneof: "event", T: () => CreateContainer }
        ]);
    }
    create(value?: PartialMessage<RuntimeHookRequest>): RuntimeHookRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.event = { oneofKind: undefined };
        if (value !== undefined)
            reflectionMergePartial<RuntimeHookRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RuntimeHookRequest): RuntimeHookRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* tetragon.CreateContainer createContainer */ 1:
                    message.event = {
                        oneofKind: "createContainer",
                        createContainer: CreateContainer.internalBinaryRead(reader, reader.uint32(), options, (message.event as any).createContainer)
                    };
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RuntimeHookRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* tetragon.CreateContainer createContainer = 1; */
        if (message.event.oneofKind === "createContainer")
            CreateContainer.internalBinaryWrite(message.event.createContainer, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tetragon.RuntimeHookRequest
 */
export const RuntimeHookRequest = new RuntimeHookRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RuntimeHookResponse$Type extends MessageType<RuntimeHookResponse> {
    constructor() {
        super("tetragon.RuntimeHookResponse", []);
    }
    create(value?: PartialMessage<RuntimeHookResponse>): RuntimeHookResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<RuntimeHookResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RuntimeHookResponse): RuntimeHookResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: RuntimeHookResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tetragon.RuntimeHookResponse
 */
export const RuntimeHookResponse = new RuntimeHookResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateContainer$Type extends MessageType<CreateContainer> {
    constructor() {
        super("tetragon.CreateContainer", [
            { no: 1, name: "cgroupsPath", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "rootDir", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "annotations", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } },
            { no: 4, name: "containerName", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CreateContainer>): CreateContainer {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.cgroupsPath = "";
        message.rootDir = "";
        message.annotations = {};
        message.containerName = "";
        if (value !== undefined)
            reflectionMergePartial<CreateContainer>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateContainer): CreateContainer {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string cgroupsPath */ 1:
                    message.cgroupsPath = reader.string();
                    break;
                case /* string rootDir */ 2:
                    message.rootDir = reader.string();
                    break;
                case /* map<string, string> annotations */ 3:
                    this.binaryReadMap3(message.annotations, reader, options);
                    break;
                case /* string containerName */ 4:
                    message.containerName = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap3(map: CreateContainer["annotations"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof CreateContainer["annotations"] | undefined, val: CreateContainer["annotations"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.string();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field tetragon.CreateContainer.annotations");
            }
        }
        map[key ?? ""] = val ?? "";
    }
    internalBinaryWrite(message: CreateContainer, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string cgroupsPath = 1; */
        if (message.cgroupsPath !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.cgroupsPath);
        /* string rootDir = 2; */
        if (message.rootDir !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.rootDir);
        /* map<string, string> annotations = 3; */
        for (let k of globalThis.Object.keys(message.annotations))
            writer.tag(3, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.LengthDelimited).string(message.annotations[k]).join();
        /* string containerName = 4; */
        if (message.containerName !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.containerName);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tetragon.CreateContainer
 */
export const CreateContainer = new CreateContainer$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StackTraceEntry$Type extends MessageType<StackTraceEntry> {
    constructor() {
        super("tetragon.StackTraceEntry", [
            { no: 1, name: "address", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "offset", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "symbol", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "module", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<StackTraceEntry>): StackTraceEntry {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.address = 0;
        message.offset = 0;
        message.symbol = "";
        message.module = "";
        if (value !== undefined)
            reflectionMergePartial<StackTraceEntry>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: StackTraceEntry): StackTraceEntry {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint64 address */ 1:
                    message.address = reader.uint64().toNumber();
                    break;
                case /* uint64 offset */ 2:
                    message.offset = reader.uint64().toNumber();
                    break;
                case /* string symbol */ 3:
                    message.symbol = reader.string();
                    break;
                case /* string module */ 4:
                    message.module = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: StackTraceEntry, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint64 address = 1; */
        if (message.address !== 0)
            writer.tag(1, WireType.Varint).uint64(message.address);
        /* uint64 offset = 2; */
        if (message.offset !== 0)
            writer.tag(2, WireType.Varint).uint64(message.offset);
        /* string symbol = 3; */
        if (message.symbol !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.symbol);
        /* string module = 4; */
        if (message.module !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.module);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tetragon.StackTraceEntry
 */
export const StackTraceEntry = new StackTraceEntry$Type();
