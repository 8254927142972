import { Labels } from '~/domain/labels';

import { IconKind } from './common';

const labelMatchers = [
  { test: /elasticsearch/, icon: IconKind.ElasticSearch },
  { test: /(amazonaws|amazon)/, icon: IconKind.AWS },
  { test: /(cassandra|casandra)/, icon: IconKind.Cassandra },
  { test: /couchdb/, icon: IconKind.CouchDB },
  { test: /etcd/, icon: IconKind.Etcd },
  { test: /(graphql|gql)/, icon: IconKind.GraphQL },
  { test: /grpc/, icon: IconKind.GRPC },
  { test: /kafka/, icon: IconKind.Kafka },
  { test: /kubernetes/, icon: IconKind.Kubernetes },
  { test: /memcached/, icon: IconKind.Memcached },
  { test: /(mongodb|mongo)/, icon: IconKind.MongoDB },
  { test: /(postgres|psql)/, icon: IconKind.PostgreSQL },
  { test: /prometheus/, icon: IconKind.Prometeus },
  { test: /rabbitmq/, icon: IconKind.RabbitMQ },
  { test: /redis/, icon: IconKind.Redis },
  { test: /zookeeper/, icon: IconKind.Zookeeper },
];

const domainMatchers = [
  { test: /(twitter|t)\.com?/, icon: IconKind.Twitter },
  { test: /(amazon)(aws)?\.com/, icon: IconKind.AWS },
];

export const byLabels = (labels: string[]): IconKind | null => {
  if (!labels || labels.length === 0) return null;

  for (const label of labels) {
    let { value } = Labels.toKV(label);
    value = value.toLowerCase();

    for (const { test: matcher, icon } of Object.values(labelMatchers)) {
      if (matcher.test(value)) return icon;
    }
  }

  return null;
};

export const byDomainName = (domainName: string): IconKind | null => {
  domainName = domainName.replace(/^[a-z0-9_-]+:\/\//i, '');

  for (const { test: matcher, icon } of Object.values(domainMatchers)) {
    if (matcher.test(domainName)) return icon;
  }

  return null;
};
