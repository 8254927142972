import { Dictionary } from '~/domain/misc';

export enum Intent {
  None = 'none',
  Primary = 'primary',
  Success = 'success',
  Danger = 'danger',
  Warning = 'warning',
}

export enum Size {
  ExtraSmall = 'xs',
  Small = 'sm',
  Medium = 'md',
  Large = 'lg',
  ExtraLarge = 'xl',
}

export const intentClasses = (css: Dictionary<string>, i: Intent) => {
  return {
    [css.primary]: i === Intent.Primary,
    [css.success]: i === Intent.Success,
    [css.danger]: i === Intent.Danger,
    [css.warning]: i === Intent.Warning,
  };
};
