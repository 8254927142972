import { Filters } from '~/domain/filtering';
import { Link } from '~/domain/service-map';

import { FilterGroup, Kind as FilterKind } from './filter-entry';

export const filterLink = (link: Link, filters: Filters): boolean => {
  if ((filters.verdicts?.size ?? 0) > 0) {
    let hasVerdict = false;
    for (const verdict of filters.verdicts ?? new Set()) {
      if (link.verdicts.has(verdict)) {
        hasVerdict = true;
        break;
      }
    }
    if (!hasVerdict) return false;
  }

  if (link.isDNSRequest && filters.skipKubeDns) return false;

  if (!filters.filterGroups?.length) return true;

  for (const group of filters.filterGroups) {
    const groupResult = filterLinkByGroup(link, group);

    // TODO: fix negative for group
    if (group.entries.some(e => e.negative) && !groupResult) return false;
    if (!group.entries.some(e => e.negative) && groupResult) return true;
  }
  return false;
};

export const filterLinkByGroup = (l: Link, group: FilterGroup): boolean => {
  return group.entries.every(e => {
    const sourceIdentityMatch = l.sourceId === e.query;
    const destIdentityMatch = l.destinationId === e.query;

    let [fromOk, toOk] = [false, false];

    switch (e.kind) {
      case FilterKind.Identity: {
        // TODO: This is wrong, coz sourceId/destinationId is not an identity
        if (e.fromRequired) {
          if (!sourceIdentityMatch && e.negative) return true;
          fromOk ||= sourceIdentityMatch;
        }
        if (e.toRequired) {
          if (!destIdentityMatch && e.negative) return true;
          toOk ||= destIdentityMatch;
        }

        break;
      }
      case FilterKind.Port: {
        if (e.toRequired) {
          toOk ||= +e.query === l.destinationPort;
        }
        break;
      }
      default: {
        if (e.negative && !sourceIdentityMatch && !destIdentityMatch) return true;
        fromOk = true;
        toOk = true;
      }
    }

    return e.negative || fromOk || toOk;
  });
};
