import React, { useMemo } from 'react';

import * as d3 from 'd3';
import { observer } from 'mobx-react';

import { ArrowTriangle } from '~/components/ArrowTriangle';
import { Intent } from '~/components/widgets/common';
import { Vec2, XY } from '~/domain/geometry';

export interface Props {
  start: XY;
  end: XY;
  shiftedStart: XY;
  shiftedEnd: XY;

  isDarkThemeEnabled: boolean;

  intent?: Intent;
  maxArrowLength?: number;
}

export const DestinationArrow = observer(function DestinationArrow(props: Props) {
  const intent = props.intent ?? Intent.None;
  const pathD = useMemo(() => {
    // TODO: switch to curveBumpX when it is available
    const lineGenerator = d3.line().curve(d3.curveMonotoneX);

    return lineGenerator([
      [props.start.x, props.start.y],
      [props.shiftedStart.x, props.shiftedStart.y],
      [props.shiftedEnd.x, props.shiftedEnd.y],
      [props.end.x, props.end.y],
    ]);
  }, [props.start, props.shiftedStart, props.shiftedEnd, props.end]);

  let triangleStart = Vec2.fromXY(props.shiftedEnd);
  let triangleEnd = Vec2.fromXY(props.end);
  [triangleStart, triangleEnd] = Vec2.advance(triangleStart, triangleEnd, 2);

  const maxW = props.maxArrowLength;
  const w = triangleStart.distance(triangleEnd);

  if (maxW != null && w > maxW) {
    triangleStart = triangleEnd.linterp(triangleStart, maxW / w);
  }

  let arrowColor = '#7591aa';
  if (intent === Intent.Primary) {
    arrowColor = '#008bdb';
  } else if (intent === Intent.Danger) {
    arrowColor = props.isDarkThemeEnabled ? '#FFA901' : '#CF9500';
  }

  return (
    <>
      <ArrowTriangle fill={arrowColor} radius={1} points={[triangleStart, triangleEnd]} />
      <path fill="none" stroke={arrowColor} strokeWidth={2} d={pathD || ''} />;
    </>
  );
});
