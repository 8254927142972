import {
  differenceInDays,
  differenceInHours,
  differenceInMilliseconds,
  differenceInMinutes,
  differenceInSeconds,
  format,
} from 'date-fns';

import { UseIndicator, useIndicator } from '~/ui/hooks/useIndicator';

export const startTimeFmt = (d: Date | string): string => {
  // Mar 5, 2021, 10:52 AM
  return format(typeof d === 'string' ? new Date(d) : d, 'LLL d, yyyy, hh:mm aa');
};

export const startTimeDiff = (lhsArg: Date | string, rhsArg: Date | string): [string, boolean] => {
  const lhs = typeof lhsArg === 'string' ? new Date(lhsArg) : lhsArg;
  const rhs = typeof rhsArg === 'string' ? new Date(rhsArg) : rhsArg;
  const days = differenceInDays(lhs, rhs);
  if (days >= 1) return [`+${days} days`, true];

  const hours = differenceInHours(lhs, rhs);
  if (hours >= 1) return [`+${hours} hours`, true];

  const minutes = differenceInMinutes(lhs, rhs);
  if (minutes >= 1) return [`+${minutes} minutes`, minutes >= 3];

  const seconds = differenceInSeconds(lhs, rhs);
  if (seconds >= 1) return [`+${seconds} seconds`, false];

  const milliseconds = differenceInMilliseconds(lhs, rhs);
  return [`${Math.sign(milliseconds) > 0 ? '+' : ''}${milliseconds} ms`, false];
};

const indicatorFieldsObj = {
  connector: 1,
  nodeConnector: 1,
  egressConnector: 1,
  egressDestination: 1,
  podHandleDimensions: 1,
};

export type CoordsIndicator = UseIndicator<typeof indicatorFieldsObj, Set<string>>;

export const createCoordsIndicator = () => {
  return useIndicator(indicatorFieldsObj, new Set<string>());
};
