import _ from 'lodash';

import { Application } from '~/domain/common';
import { FilterGroup } from '~/domain/filtering';
import { Verdict } from '~/domain/hubble';

export enum RouteParam {
  Cluster = 'cluster',
  Namespace = 'namespace',
  Verdicts = 'verdicts',
  FlowFilterGroups = 'flow-filter-groups',
  HttpStatus = 'http-status',
  AggregatorTypes = 'aggregator-types',
  AggregationStateChange = 'aggregation-state-change',
  Aggregation = 'aggregation',
  TimeRangeFrom = 'time-from',
  TimeRangeTo = 'time-to',
  PolicyUrl = 'policy-url',
  PolicyTutorial = 'policy-tutorial',
  PolicyGist = 'gist',
  PolicyUuid = 'policy-uuid',
}

export const routeParamSet = new Set(Object.values(RouteParam));

export type RouteParamValue = string | number | null | undefined;
export type RouteParamPair = [string, RouteParamValue];
export type RouteParamPairs = RouteParamPair[];

export type RouteParams = {
  app: Application;
  cluster: string | null;
  namespace: string | null;
  verdicts: Set<Verdict>;
  aggregation: boolean | null;
  httpStatus: string | null;
  flowFilterGroups: FilterGroup[];
  timeRangeFrom: string | null;
  timeRangeTo: string | null;
};

export const parseParamName = (name?: any): RouteParam | null => {
  if (!name || !_.isString(name)) return null;

  return routeParamSet.has(name as any) ? (name as RouteParam) : null;
};

export class RouteParamAction {
  public static new(param: string, v?: RouteParamValue) {
    return new RouteParamAction(param, v);
  }

  public static fromPair(p: RouteParamPair): RouteParamAction {
    return RouteParamAction.new(p[0], p[1]);
  }

  public static fromSerializable(obj?: any): RouteParamAction | null {
    if (obj == null || !_.isObject(obj)) return null;

    const param = parseParamName((obj as any).param);
    if (param == null) return null;

    return RouteParamAction.new(param, (obj as any).value);
  }

  constructor(
    public readonly param: string,
    public readonly value?: RouteParamValue,
  ) {}

  public asSerializable(): any {
    return {
      param: this.param,
      value: this.value,
    };
  }
}
