import { Message, Oneshot, OneshotOptions } from '~/api/customprotocol-core';
import { PodInfo, TimescapeDataFilter } from '~/domain/timescape';
import { ProtoFactory } from '~/factories/proto';
import { logger } from '~/utils/logger';
import * as uipb from '~backend/proto/ui/ui_pb';

export type Options = OneshotOptions & {
  namespace: string | null;
  dataFilter: TimescapeDataFilter;
};

export enum Event {
  Pods = 'pods',
}

export type Handlers = {
  [Event.Pods]: (pods: PodInfo[]) => void;
};

export class TimescapePodsOneshot extends Oneshot<Handlers> {
  private namespace: string | null;
  private dataFilter: TimescapeDataFilter;

  constructor(opts: Options) {
    super(opts);

    this.namespace = opts.namespace;
    this.dataFilter = opts.dataFilter;

    this.setupEventHandlers();
  }

  public onPods(fn: Handlers[Event.Pods]): this {
    this.on(Event.Pods, fn);
    return this;
  }

  private setupEventHandlers() {
    this.onMessage(msg => {
      const resp = uipb.GetTimescapePodsResponse.fromBinary(msg.body);
      const pods = ProtoFactory.podInfoFromTimescapePodsResponse(resp);
      if (pods == null) return;

      this.emit(Event.Pods, pods);
    });
  }

  protected messageBuilder(msg: Message, isFirst: boolean): Message {
    if (!isFirst) return msg;

    const req = ProtoFactory.timescapePodsRequestFromDataFilter(this.namespace, this.dataFilter);
    if (req == null) {
      logger.log(`dataFilter: `, this.dataFilter);
      throw new Error('failed to build TimescapePodsRequest');
    }

    msg.setBodyBytes(uipb.GetTimescapePodsRequest.toBinary(req));
    return msg;
  }
}
