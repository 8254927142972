import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Button, Intent, MenuItem, Spinner } from '@blueprintjs/core';
import { ItemRenderer, Select } from '@blueprintjs/select';
import { observer } from 'mobx-react';

import { usePopover } from '~/ui/hooks/usePopover';
import { WidgetControl } from '~/ui/widgets/control';

import css from './styles.scss';

export enum E2E {
  currentPod = `current-pod`,
  podItem = `pod-item`,
}

export interface Props {
  pods: string[];
  selectedPod?: string;
  initialQuery?: string;
  disabled?: boolean;
  isSpinnerVisible?: boolean;
  widgetControl?: WidgetControl;
  onPodSelect?: (pod: string) => void;
}

export const PodSelection = observer(function PodSelection(props: Props) {
  const [podFilter, setPodFilter] = useState(props.initialQuery ?? '');

  const filteredPods = useMemo(() => {
    const query = podFilter.toLowerCase();

    return props.pods.filter(podName => {
      const pod = podName.toLowerCase();
      return pod.includes(query);
    });
  }, [props.pods, podFilter]);

  const popover = usePopover({
    popoverProps: {
      position: 'right',
      rootBoundary: 'document',
      className: css.popover,
      hasBackdrop: true,
    },
  });

  useEffect(() => {
    if (props.widgetControl == null) return;

    return props.widgetControl
      .onOpen(() => {
        popover.open();
      })
      .disposer()
      .asFunction();
  }, [props.widgetControl, popover]);

  const onItemSelect = useCallback(
    (podName: string) => {
      if (props.disabled) return;

      props.onPodSelect?.(podName);
    },
    [props.onPodSelect, props.disabled],
  );

  const rightIcon = !props.isSpinnerVisible ? (
    'caret-down'
  ) : (
    <Spinner size={20} intent={Intent.PRIMARY} />
  );

  return (
    <div className={css.podSelect}>
      <div className={css.header}>Pods</div>

      <div className={css.list}>
        <Select<string>
          itemRenderer={renderItem}
          items={filteredPods}
          query={podFilter}
          onQueryChange={setPodFilter}
          noResults={<MenuItem disabled={true} text="No matches" />}
          onItemSelect={onItemSelect}
          popoverProps={popover.props}
          inputProps={{ placeholder: 'Filter pods…' }}
        >
          <Button
            rightIcon={rightIcon}
            data-testid={E2E.currentPod}
            icon={null}
            text={
              props.disabled
                ? 'Not available'
                : (props.selectedPod ?? `Choose pod (${props.pods.length})`)
            }
            disabled={props.disabled}
            onClick={popover.toggle}
            className={css.wrapper}
            alignText="left"
          />
        </Select>
      </div>
    </div>
  );
});

const renderItem: ItemRenderer<string> = (podName, itemProps) => {
  const { handleClick, modifiers } = itemProps;

  if (!modifiers.matchesPredicate) {
    return null;
  }

  return (
    <MenuItem
      key={podName}
      data-testid={E2E.podItem}
      onClick={handleClick}
      text={podName}
      icon={null}
    />
  );
};
