import { Time } from '~/domain/time';

export enum Application {
  Dashboard = 'dashboard',
  ConnectionsMap = 'connections-map',
  ProcessTree = 'ps-tree',
  Cimulator = 'policies',
  ErrorPage = 'error',
}

export const APPLICATION_NAMES = new Set<string>(Object.values(Application));

export const getApplicationName = (app: Application): string => {
  return {
    [Application.Dashboard]: 'Dashboard',
    [Application.ConnectionsMap]: 'Connections Map',
    [Application.ProcessTree]: 'Process Tree',
    [Application.Cimulator]: 'Network Policies',
    [Application.ErrorPage]: 'Error Page',
  }[app];
};

export enum DetailsPanelMode {
  Flows = 'flows',
  Dashboards = 'dashboards',
  NodeGraph = 'nodegraph',
}

export enum ServiceMapMode {
  HubbleUI = 'hubble-ui',
  GrafanaNodeGraph = 'grafana-node-graph',
}

export class CountStats {
  public timestamp: Time;
  public count: number;

  public static fromNumbers(seconds: number, count: number) {
    return new CountStats({ seconds, nanos: 0 }, count);
  }

  constructor(ts: Time, count: number) {
    this.timestamp = ts;
    this.count = count;
  }

  public get timestampAsDate(): Date {
    return new Date(this.timestamp.seconds * 1000 + this.timestamp.nanos / 1e6);
  }

  public get seconds() {
    return this.timestamp.seconds;
  }

  public get nanos() {
    return this.timestamp.nanos;
  }
}

export interface LastSeen {
  id: string;
  timestamp: Time;
}

export enum Order {
  Ascending = 'ascending',
  Descending = 'descending',
}

export enum Direction {
  Source = 'source',
  Destination = 'destination',
}
