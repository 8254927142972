import { useCallback, useState } from 'react';

import { Column } from '~/components/FlowsTable';
import { ColumnWidths } from '~/components/FlowsTable/general';
import { saveFlowsTableVisibleColumns } from '~/storage/local';

export const getDefaultWidths = (columns: Set<Column>) =>
  Object.fromEntries([...columns].map(col => [col, undefined])) as ColumnWidths;

export const formatWidth = (width: number | null | undefined) =>
  width ? `${width * 100}%` : undefined;

/**
 * This hook allows a component to maintain a list
 * of changeable visible columns with their widths.
 * @param visibleColumns A set of visible columns
 * @returns the visible column as well that their width as a ratio of the window's size
 */
export const useFlowsTableColumns = (uniqueId: string, visibleColumns: ColumnWidths) => {
  const [visible, setVisible] = useState<Set<Column>>(
    new Set(Object.keys(visibleColumns) as Column[]),
  );
  const [widths, setVisibleColumns] = useState(visibleColumns || getDefaultWidths(visibleColumns));

  const toggle = useCallback(
    (column: Column) => {
      const nextVisible = new Set<Column>(visible);
      visible.has(column) ? nextVisible.delete(column) : nextVisible.add(column);

      setVisible(nextVisible);

      // Save visible column and width
      saveFlowsTableVisibleColumns(getDefaultWidths(nextVisible), uniqueId);

      // When toggling a column, reset all widths:
      setVisibleColumns(getDefaultWidths(nextVisible));
    },
    [visible],
  );

  const isVisible = useCallback((column: Column) => visible.has(column), [visible]);

  const setWidths = useCallback(
    (widths: ColumnWidths) => {
      saveFlowsTableVisibleColumns(widths, uniqueId);
      setVisibleColumns(widths);
    },
    [visible],
  );

  return { toggle, isVisible, visible, widths, setWidths, uniqueId };
};
