import { XYWH } from '~/ui-layer/service-map/coordinates/types';

function isFinite(placement: XYWH) {
  return !Object.values(placement).find(value => value === Infinity || value === -Infinity);
}

/**
 * This function takes some boxes (aka placements) represented by x,y,w,h and
 * computes the surrounding boxes, or null if data provided is
 * not good.
 */
export function getBoundingBox(placements: XYWH[]): XYWH | null {
  const bbox = { x: Infinity, y: Infinity, w: -Infinity, h: -Infinity };

  placements.forEach(({ x, y, w, h }) => {
    bbox.x = Math.min(bbox.x, x);
    bbox.y = Math.min(bbox.y, y);

    // Temporarily store here maxX, maxY for a while
    bbox.w = Math.max(bbox.w, x + w);
    bbox.h = Math.max(bbox.h, y + h);
  });

  if (!isFinite(bbox)) return null;

  bbox.w -= bbox.x;
  bbox.h -= bbox.y;

  return bbox;
}
