import { useEffect, useMemo, useRef, useState } from 'react';

import * as d3 from 'd3';

import { sizes } from '~/ui/vars';

export interface Args {
  wasDragged?: boolean;
  visibleHeight?: number;
  mapBBox?: { x: number; y: number; w: number; h: number } | null;
}

export function useMapZoom(args: Args) {
  const ref = useRef<SVGSVGElement>(null);
  const [transform, setTransform] = useState<d3.ZoomTransform>(
    createTransformToCenter({ ...args }),
  );

  const isManuallyMoved = useRef(false);
  const doTransform = useRef<((trans: d3.ZoomTransform) => void) | null>(null);

  useEffect(() => {
    if (args.wasDragged === false) {
      isManuallyMoved.current = false;
    }
  }, [args.wasDragged]);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    const initialTransform = createTransformToCenter({ ...args });

    // NOTE: Dumb values for `.extent` call prevents Cypress from failing ><
    const zoom = d3
      .zoom()
      .extent([
        [0, 0],
        [100, 100],
      ])
      .scaleExtent([0.1, 1.5])
      .on('zoom', event => {
        setTransform(() => {
          if (event.transform.programmatic) return event.transform;

          isManuallyMoved.current = true;

          return event.transform;
        });
      });

    const zoomable = d3
      .select(ref.current)
      .call(zoom as any)
      .on('dblclick.zoom', null);

    doTransform.current = newTransform => {
      (newTransform as any).programmatic = true;
      zoomable.call(zoom.transform as any, newTransform);
    };

    // Dirty hack for tests: jsdom doesn't have full svg support
    // https://github.com/jsdom/jsdom/issues/2531
    if (process.env.NODE_ENV !== 'test') {
      doTransform.current(initialTransform);
    }

    return () => {
      zoom.on('zoom', null);
    };
  }, []);

  const isAllowedToCenter = useMemo(() => {
    return !args.wasDragged && !isManuallyMoved.current;
  }, [args]);

  // auto center map on updates until user makes a move
  useEffect(() => {
    if (!isAllowedToCenter) return;

    const tr = createTransformToCenter({ ...args });
    doTransform.current?.(tr);
  }, [isAllowedToCenter, args.mapBBox]);

  return {
    ref,
    transform,
    transformStr: transform.toString(),
    setMapBBox: (mapBBox: { x: number; y: number; w: number; h: number }) =>
      doTransform.current?.(createTransformToCenter({ mapBBox })),
  };
}

function createTransformToCenter(args: Args) {
  const innerPadding = 100;

  const containerWidth = Math.max(window.innerWidth - sizes.sidebarWidth, 960);
  const containerHeight = Math.max(240, args.visibleHeight ?? 0);

  const mapWidth = (args.mapBBox?.w || containerWidth) + sizes.endpointHPadding + innerPadding * 2;
  const mapHeight =
    (args.mapBBox?.h || containerHeight) + sizes.endpointHPadding + innerPadding * 2;

  const scale = Math.min(containerWidth / mapWidth, containerHeight / mapHeight);

  const scaledMapWidth = mapWidth * scale;
  const scaledMapHeight = mapHeight * scale;

  const scaledXOffset =
    scale * (-(args.mapBBox?.x || 0) + sizes.endpointHPadding / 2 + innerPadding);
  const scaledYOffset =
    scale * (-(args.mapBBox?.y || 0) + sizes.endpointHPadding / 2 + innerPadding);

  const x = scaledXOffset + (containerWidth - scaledMapWidth) / 2;
  const y = sizes.topBarHeight + scaledYOffset + (containerHeight - scaledMapHeight) / 2;

  return d3.zoomIdentity.translate(x, y).scale(scale);
}
