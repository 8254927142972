import { makeAutoObservable } from 'mobx';

import { FeatureFlags } from '~/domain/features';
import { SSRError } from '~/ui/ssr';
import { APIStatus } from '~/utils/api';

export default class SettingsStore {
  private _flags: FeatureFlags;
  private _isFeatureFlagsSet = false;

  private _ssrError: SSRError | null = null;
  private _isSSRErrorSet = false;

  public connectionsMapApiState: APIStatus = APIStatus.Pending;

  constructor() {
    this._flags = FeatureFlags.default();

    makeAutoObservable(this, void 0, { autoBind: true });
  }

  public setFeatures(features: FeatureFlags) {
    this._flags = features;
    this._isFeatureFlagsSet = true;
  }

  public setSSRError(err: SSRError | null) {
    this._ssrError = err;
    this._isSSRErrorSet = true;
  }

  public get isTimescapeEnabled() {
    return !!this._flags?.timescape?.enabled;
  }

  public get isTimescapeOnlyEnabled() {
    return !!this._flags?.timescapeOnly?.enabled;
  }

  public get isServiceMapUploadEnabled() {
    return !!this._flags.serviceMapFileUpload?.enabled;
  }

  public get isTetragonOnlyEnabled() {
    return !!this._flags.tetragon?.only;
  }

  public get isFeaturesSet() {
    return this._isFeatureFlagsSet;
  }

  public get isSSRErrorSet() {
    return this._isSSRErrorSet;
  }

  public get grafana() {
    return this._flags.grafana;
  }

  public get featureFlags() {
    return this._flags;
  }

  public get ssrError() {
    return this._ssrError;
  }
}
