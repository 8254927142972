import { Event, EventType, Kind } from '~backend/proto/k8sevent/v1/event_pb';

export type TimescapeK8SEvent = Event;
export { EventType as TimescapeK8SEventType };
export { Kind as TimescapeK8SEventKind };

export class K8SEvent {
  constructor(public raw: TimescapeK8SEvent) {}

  get timestamp() {
    if (!this.raw.time) return null;
    return new Date(this.raw.time.seconds * 1000);
  }

  get uuid() {
    return this.raw.resource_uuid;
  }

  get name() {
    return this.raw.name;
  }

  get namespace() {
    return this.raw.namespace;
  }

  get fullId() {
    return `${this.uuid}_${this.shortId}`;
  }

  get kind() {
    return this.raw.kind;
  }

  static parseFullId(id: string): { uuid: string } & ReturnType<typeof this.parseShortId> {
    const [uuid, ...rest] = id.split('_');
    return { uuid, ...K8SEvent.parseShortId(rest.join('_')) };
  }

  get shortId() {
    let id = this.raw.resource_version;
    if (this.timestamp) id += `_${this.timestamp.valueOf()}`;
    return id;
  }

  static parseShortId(id: string): { resourceVersion: string; timestamp?: number } {
    const [resourceVersion, timestamp] = id.split('_');
    return { resourceVersion, ...(timestamp ? { timestamp: +timestamp } : {}) };
  }

  get kindStr() {
    switch (this.raw.kind) {
      case Kind.CILIUM_NETWORK_POLICY:
        return 'CiliumNetworkPolicy';
      case Kind.CILIUM_CLUSTERWIDE_NETWORK_POLICY:
        return 'CiliumClusterwideNetworkPolicy';
      case Kind.KUBERNETES_NETWORK_POLICY:
        return 'KubernetesNetworkPolicy';
      case Kind.KUBERNETES_ENDPOINT:
        return 'KubernetesEndpoint';
      case Kind.UNSPECIFIED:
        return 'Unspecified';
    }
  }

  get eventType() {
    return this.raw.event_type;
  }

  get eventTypeStr() {
    switch (this.eventType) {
      case EventType.CREATED:
        return 'created';
      case EventType.UPDATED:
        return 'updated';
      case EventType.DELETED:
        return 'deleted';
      case EventType.UNSPECIFIED:
        return 'unspecified';
    }
  }

  get isCreated() {
    return this.raw.event_type === EventType.CREATED;
  }

  get isUpdated() {
    return this.raw.event_type === EventType.UPDATED;
  }

  get isDeleted() {
    return this.raw.event_type === EventType.DELETED;
  }

  get isNetworkPolicyEvent() {
    return (
      this.raw.kind === Kind.CILIUM_NETWORK_POLICY ||
      this.raw.kind === Kind.CILIUM_CLUSTERWIDE_NETWORK_POLICY ||
      this.raw.kind === Kind.KUBERNETES_NETWORK_POLICY
    );
  }
}
