import { HubbleFlow } from '~/domain/flows';

export function getFlowId(flow: Pick<HubbleFlow, 'time' | 'nodeName'>): string {
  let timeStr = '';
  if (flow.time) {
    const { seconds: s, nanos: n } = flow.time;
    timeStr = `${Math.trunc(s)}.${Math.trunc(n)}`;
  } else {
    timeStr = `${Date.now()}`;
  }

  return `${timeStr}-${flow.nodeName}`;
}
