import { CiliumNetworkPolicyBuilder, KubernetesNetworkPolicyBuilder } from '~/domain/cimulator';
import { PolicyCard } from '~/domain/cimulator/cards';
import { PolicyEndpoint } from '~/domain/cimulator/endpoint';
import {
  CardSide,
  DefaultDenyKind,
  IneffectiveRuleInfo,
  PolicyKind,
  UnsupportedReasonInfo,
} from '~/domain/cimulator/types';
import { Kind } from '~backend/proto/k8sevent/v1/event_pb';

export interface CardsStore {
  list: PolicyCard[];
  has: (id: string) => boolean;
  get: (id: string) => PolicyCard | undefined;
  set: (card: PolicyCard) => void;
  forEach: (callback: (card: PolicyCard, index: number, array: PolicyCard[]) => void) => void;
  [Symbol.iterator]: () => {
    next: () => { done: false; value: PolicyCard } | { done: true };
  };
}

export type IngressCardsStore = CardsStore;

export type EgressCardsStore = CardsStore;

export type AnyCardsStore = IngressCardsStore | EgressCardsStore;

export interface CardsMaps {
  [CardSide.Ingress]: IngressCardsStore;
  [CardSide.Egress]: EgressCardsStore;
  [CardSide.Selector]: PolicyCard;
}

export enum RuleStatusKind {
  Unknown = 'unknown',
  UnsupportedByReason = 'unsupported-by-reason',
  Ineffective = 'ineffective',
  DeniedByDefaultDeny = 'denied-by-default-deny',
  AllowedByDefaultAllow = 'allowed-by-default-allow',
  AllowedByExplicitRule = 'allowed-by-explicit-rule',
  AllowedByOtherRule = 'allowed-by-other-rule',
}

export type RuleStatusInfo =
  | {
      kind: RuleStatusKind.Unknown;
    }
  | {
      kind: RuleStatusKind.UnsupportedByReason;
      info: UnsupportedReasonInfo;
    }
  | {
      kind: RuleStatusKind.Ineffective;
      info: IneffectiveRuleInfo;
    }
  | {
      kind: RuleStatusKind.DeniedByDefaultDeny;
    }
  | {
      kind: RuleStatusKind.AllowedByDefaultAllow;
    }
  | {
      kind: RuleStatusKind.AllowedByExplicitRule;
    }
  | {
      kind: RuleStatusKind.AllowedByOtherRule;
      card: PolicyCard;
      endpoint: PolicyEndpoint;
    };

export type ParsePolicyYAMLResult =
  | {
      ok: false;
      errors: string[];
    }
  | ({
      ok: true;
      policyKind: PolicyKind.CNP;
      isClusterwide: boolean;
    } & ReturnType<(typeof CiliumNetworkPolicyBuilder)['parsePolicy']>)
  | ({ ok: true; policyKind: PolicyKind.KNP } & ReturnType<
      (typeof KubernetesNetworkPolicyBuilder)['parsePolicy']
    >);

export enum RatingRuleKind {
  'Ingress default deny is enabled' = 'Ingress default deny is enabled',
  'Ingress is in default allow' = 'Ingress is in default allow',
  'Egress default deny is enabled' = 'Egress default deny is enabled',
  'Egress is in default allow' = 'Egress is in default allow',
  'Egress outside cluster' = 'Egress outside cluster',
  'Egress outside cluster is default allow' = 'Egress outside cluster is default allow',
  'Egress outside cluster to specific ports' = 'Egress outside cluster to specific ports',
  'Egress outside cluster to CIDR' = 'Egress outside cluster to CIDR',
  'Egress outside cluster to FQDN' = 'Egress outside cluster to FQDN',
  'Ingress from outside cluster' = 'Ingress outside cluster',
  'Ingress from outside cluster to specific ports' = 'Ingress outside cluster to specific ports',
  'Allow within namespace for ingress' = 'Allow within namespace for ingress',
  'Allow within namespace for egress' = 'Allow within namespace for egress',
  'Allow within namespace for pod selector and no rule to allow within namespace for ingress' = 'Allow within namespace for pod selector and no rule to allow within namespace for ingress',
  'Allow within namespace for pod selector and no rule to allow within namespace for egress' = 'Allow within namespace for pod selector and no rule to allow within namespace for egress',
  'Allow within cluster for ingress' = 'Allow within cluster for ingress',
  'Allow within cluster for egress' = 'Allow within cluster for egress',
}

export const RatingPoints = {
  [RatingRuleKind['Ingress default deny is enabled']]: 5,
  [RatingRuleKind['Ingress is in default allow']]: 0,
  [RatingRuleKind['Egress default deny is enabled']]: 5,
  [RatingRuleKind['Egress is in default allow']]: 0,
  [RatingRuleKind['Egress outside cluster is default allow']]: 0,
  [RatingRuleKind['Egress outside cluster']]: 2,
  [RatingRuleKind['Egress outside cluster to specific ports']]: 4,
  [RatingRuleKind['Egress outside cluster to CIDR']]: 5,
  [RatingRuleKind['Egress outside cluster to FQDN']]: 5,
  [RatingRuleKind['Allow within namespace for ingress']]: 3,
  [RatingRuleKind['Allow within namespace for egress']]: 3,
  [RatingRuleKind['Ingress from outside cluster']]: 3,
  [RatingRuleKind['Ingress from outside cluster to specific ports']]: 4,
  [RatingRuleKind[
    'Allow within namespace for pod selector and no rule to allow within namespace for ingress'
  ]]: 5,
  [RatingRuleKind[
    'Allow within namespace for pod selector and no rule to allow within namespace for egress'
  ]]: 5,
  [RatingRuleKind['Allow within cluster for ingress']]: 2,
  [RatingRuleKind['Allow within cluster for egress']]: 2,
};

export interface PolicyInfoSnapshot {
  uid: string;
  originPolicyKind: PolicyKind;
  k8sEventPolicyKind: Kind;
  lastPolicyKind: PolicyKind;
  policyObj: string;
  // default deny info by spec index
  defaultDenyIngresses: Array<DefaultDenyKind | null>;
  defaultDenyEgresses: Array<DefaultDenyKind | null>;
  // first dimension is spec index, second is rules
  unspprtdIngresses: any[][];
  unspprtdEgresses: any[][];
  updatedAt?: number;
  isPolicyVersion: boolean;
}
