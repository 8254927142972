import { utils as gutils, rounding, XY } from '~/domain/geometry';
import { Vec2 } from '~/domain/geometry/vec2';
import { sizes } from '~/ui/vars';
import { CardOffsets } from '~/ui-layer/service-map/coordinates/helpers/card-offsets';
import { XYWH } from '~/ui-layer/service-map/coordinates/types';

/**
 * Based on a starting point and an endpoint, this function
 * builds the various points needed to create a nice looking
 * arrow from start to end.
 *
 * @param start the starting position of the arrow
 * @param end the ending position of the arrow
 * @param sender the sender box to add points around
 * @param receiver the receiver box to add points around
 * @param offsets offsets to add points from sender and receivers
 * @returns an array of points XY
 */
export function buildArrowPointsFor(
  start: XY,
  end: XY,
  sender: XYWH,
  receiver: XYWH,
  offsets: CardOffsets,
): XY[] {
  const startPoint = Vec2.fromXY(start);
  const endPoint = Vec2.fromXY(end);

  const shiftedStart = startPoint.add({ x: sizes.connectorCardStartGap, y: 0 });
  const shiftedEnd = endPoint.sub({ x: sizes.connectorCardStartGap, y: 0 });

  const receiverIsOnTheLeft = startPoint.x > endPoint.x;
  // NOTE: Receiver card is in front of sender card, so no workaround required
  if (!receiverIsOnTheLeft) {
    return [start, shiftedStart, shiftedEnd, end];
  }

  // NOTE: At first, go around the sender bbox
  // TODO: Should we add offsets.around.advance(this.senderId) to padding ?
  const points1 = rounding.goAroundTheBox(
    sender,
    shiftedStart,
    shiftedEnd,
    sizes.aroundCardPadX,
    sizes.aroundCardPadY,
  );

  // NOTE: Take the point that is placed after the sender bbox. From this
  // NOTE: point when going towards the receiver, it's impossible to face
  // NOTE: the sender bbox. This point is always defined.
  const senderPoint = points1.at(-1) ?? shiftedStart;
  const aroundOffset = offsets.around.advance('receiver');

  // NOTE: Now we are going around the second box
  const points2 = rounding.goAroundTheBox(
    receiver,
    senderPoint,
    shiftedEnd,
    sizes.aroundCardPadX + aroundOffset,
    sizes.aroundCardPadY + aroundOffset,
  );

  // NOTE: We incremented an around offset for receiver before trying to go
  // NOTE: around, but in case when there was no need to go around, we must
  // NOTE: rewind (rollback) the offset counter.
  if (points2.length === 0) offsets.around.rewind('receiver');

  // NOTE: If we went around the receiver box, then the last "around" point
  // NOTE: and shiftedEnd point is not vertically aligned, let's fix it:
  if (points2.length > 0) {
    const lastAroundPoint = points2.at(-1)!;
    const offsetAdvancer = lastAroundPoint.y > senderPoint.y ? offsets.bottom : offsets.top;

    const offset = offsetAdvancer.advance('receiver');

    // TODO: This is not fair offset in case when receiver != sender, thus
    // TODO: vector prolongation should be used.
    // NOTE: Align those two points vertically for better view
    lastAroundPoint.x = end.x - offset;
    shiftedEnd.x = end.x - offset;
  }

  // NOTE: Just put those shifted points + around points from first walk
  // NOTE: in the middle of the start and end.

  return removeSharpAngleAtConnector([
    start,
    shiftedStart,
    ...points1,
    ...points2,
    shiftedEnd,
    end,
  ]);
}

function removeSharpAngleAtConnector(points: XY[]) {
  // Check angle between last two segments of arrow to avoid sharp angle
  // on connector
  const [a, b, c] = points.slice(points.length - 3);
  const angleThreshold = Math.PI / 9;
  const angle = gutils.angleBetweenSegments(a, b, c);

  if (angle > angleThreshold) return points;

  points.splice(points.length - 2, 1);

  return points;
}
