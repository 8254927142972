import { differenceInMilliseconds } from 'date-fns';
import * as mobx from 'mobx';

import { Flow } from '~/domain/flows';
import { IPEndpoint, PartialConnections } from '~/domain/interactions/new-connections';
import { setupDebugProp } from '~/domain/misc';
import { ConnectEvent, Kind, ProcessEvent } from '~/domain/process-events';
import { logger } from '~/utils/logger';

import { ClusterNode } from './cluster-node';
import { DestinationGroup, DestinationGroups } from './destination-group';
import { NamespaceNode } from './namespace-node';
import { PodNode } from './pod-node';

export { Processes } from './processes';

export { DestinationGroup, DestinationGroups, NamespaceNode, PodNode };

export type PodHandleID = string;
export type StartTimeProps = {
  isLongTimeDiff: boolean;
  timeDiff: number;
};

export class Tree {
  private events: ProcessEvent[] = [];
  private flows: Flow[] = [];

  // NOTE: this is where you should start inspecting process tree
  public nodes: Map<string, ClusterNode> = new Map();

  public static podHandleID(nodeName: string, nsName: string, podName: string): PodHandleID {
    return `pod-handle/${nodeName}/${nsName}/${podName}`;
  }

  public static fromEvents(evts: ProcessEvent[]): Tree {
    return new Tree(evts);
  }

  constructor(evts: ProcessEvent[] = []) {
    this.saveEvents(evts);

    setupDebugProp({
      printProcessEvents: () => {
        logger.log(JSON.stringify(this.events, null, 2));
        logger.log(`Total number of events in tree: ${this.events.length}`);
      },
      getDomainTree: () => {
        logger.log(this);
        logger.log(mobx.toJS(this));
      },
    });

    mobx.makeAutoObservable(this);
  }

  // NOTE: this is just an aggregation of each pod egressGroups
  // public get destinationGroups(): DestinationGroups {
  //   const allGroups: DestinationGroups = new Map();

  //   this.nodes.forEach(clusterNode => {
  //     clusterNode.namespaces.forEach(nsNode => {
  //       nsNode.pods.forEach(podNode => {
  //         DestinationGroup.merge(allGroups, podNode.destinationGroups);
  //       });
  //     });
  //   });

  //   return allGroups;
  // }

  public clear() {
    this.events.splice(0, this.events.length);
    this.flows.splice(0, this.flows.length);
    this.nodes.clear();
  }

  public get destinationGroups(): DestinationGroups {
    const groups: DestinationGroups = new Map();
    const groupFlows: Map<string, Flow[][]> = new Map();

    this.forEachPodNode((_clusterNode, _nsNode, podNode, handleId) => {
      podNode.processes.events.forEach(kindEvents => {
        const pidConnectEvents = kindEvents.get(Kind.Connect);
        if (pidConnectEvents == null) return;

        pidConnectEvents.forEach(evt => {
          const cnctEvt = evt.process_connect;
          const exec_id = evt.exec_id;
          if (cnctEvt == null || exec_id == null) return;

          const { destinationNames, destinationIp, destinationPort } = cnctEvt;
          const flows = podNode.flowsToIp.get(destinationIp);

          // NOTE: be careful here: group name can change in future when
          // NOTE: flow/process events appears with better name alternative
          // const groupName = DestinationGroup.getName(destinationNames);
          const groupName = DestinationGroup.getNameFromRelated(destinationNames, flows);

          if (!groups.has(groupName)) {
            groups.set(groupName, DestinationGroup.new(groupName));
          }

          const group = groups.get(groupName);

          // NOTE: we want to show endpoints from suspicious process events
          // NOTE: as dedicated ones
          const isSeparated = this.isSuspiciousExecId(handleId, exec_id);
          group?.upsertPair(destinationIp, destinationPort, isSeparated);

          if (!groupFlows.has(groupName)) {
            groupFlows.set(groupName, []);
          }

          // group?.addFlows(flows);
          if (!flows?.length) return;
          groupFlows.get(groupName)?.push(flows);
        });
      });
    });

    // NOTE: now when we have all flows collected, need to extract unique ones
    groupFlows.forEach((flowArrays, groupName) => {
      const group = groups.get(groupName);
      if (group == null) return;

      const uniqueGroupFlows: Flow[] = [];
      const existing: Set<string> = new Set();

      flowArrays.forEach(flows => {
        flows.forEach(flow => {
          if (existing.has(flow.id)) return;
          existing.add(flow.id);

          uniqueGroupFlows.push(flow);
        });
      });

      group.addFlows(uniqueGroupFlows);
    });

    return groups;
  }

  public get ipToDestinationGroup(): DestinationGroups {
    const m = new Map();

    this.destinationGroups.forEach(group => {
      group.destinations.forEach((_, ipAddress) => {
        m.set(ipAddress, group);
      });

      group.separatedDestinations.forEach((_, ipAddress) => {
        m.set(ipAddress, group);
      });
    });

    return m;
  }

  public get connections(): PartialConnections<IPEndpoint> {
    const conns = new PartialConnections<IPEndpoint>();

    this.nodes.forEach(clusterNode => {
      clusterNode.namespaces.forEach(nsNode => {
        nsNode.pods.forEach(podNode => {
          podNode.connections.forEach((receivers, senderExecId) => {
            if (conns.has(senderExecId)) {
              logger.warn('ProcessTree.connections encountered duplicated senderExecId');
            }

            conns.upsertMap(senderExecId, receivers);
          });
        });
      });
    });

    return conns;
  }

  public get flowIds(): Set<string> {
    return new Set(this.flows.map(f => f.id));
  }

  public get handleIdToPodNode(): Map<string, PodNode> {
    const m: Map<string, PodNode> = new Map();

    this.nodes.forEach(clusterNode => {
      clusterNode.namespaces.forEach(nsNode => {
        nsNode.pods.forEach(podNode => {
          const handleId = Tree.podHandleID(clusterNode.name, nsNode.name, podNode.name);

          m.set(handleId, podNode);
        });
      });
    });

    return m;
  }

  public getDestinationGroupByConnectEvent(cnct: ConnectEvent): DestinationGroup | null {
    const { destinationIp } = cnct;

    return this.ipToDestinationGroup.get(destinationIp) ?? null;
  }

  public replaceEvents(evts: ProcessEvent[]) {
    this.reset();
    this.saveEvents(evts);
  }

  public replaceFlows(flows: Flow[]) {
    this.flows.splice(0, this.flows.length);
    this.saveFlows(flows);
  }

  public saveEvent(evt: ProcessEvent) {
    const node = this.ensureNode(evt.node_name);

    this.events.push(evt);
    return node.saveEvent(evt);
  }

  public saveFlow(flow: Flow, dontTouchArray = false) {
    const node = this.ensureNode(flow.ref.nodeName);

    if (!dontTouchArray) this.flows.push(flow);
    return node.saveFlow(flow);
  }

  public saveEvents(evts: ProcessEvent[]) {
    evts.forEach(evt => {
      this.saveEvent(evt);
    });
  }

  // TODO: would it be ok if podHandleId be a prop of PodNode ?
  public forEachPodNode(
    cb: (cn: ClusterNode, nsn: NamespaceNode, pn: PodNode, podHandleId: PodHandleID) => void,
  ) {
    this.nodes.forEach(clusterNode => {
      clusterNode.namespaces.forEach(nsNode => {
        nsNode.pods.forEach(podNode => {
          const handleId = Tree.podHandleID(clusterNode.name, nsNode.name, podNode.name);

          cb(clusterNode, nsNode, podNode, handleId);
        });
      });
    });
  }

  public saveFlows(flows: Flow[]) {
    const nonExistingFlows: Flow[] = [];
    const existingFlowIds = new Set(this.flowIds);

    flows.forEach(f => {
      if (existingFlowIds.has(f.id)) return;
      existingFlowIds.add(f.id);

      this.saveFlow(f, true);
      nonExistingFlows.push(f);
    });

    this.flows = this.flows.concat(nonExistingFlows);
  }

  public isSuspiciousExecId(handleId: PodHandleID, execId: string): boolean {
    const startTimeProps = this.execIdStartTimeDiff(handleId, execId);

    return !!startTimeProps?.isLongTimeDiff;
  }

  public isInitExecId(handleId: PodHandleID, execId: string): boolean {
    const podNode = this.handleIdToPodNode.get(handleId);
    if (podNode == null) return false;

    const nsPid = podNode.processes.namespacePids.get(execId);
    return nsPid === 1;
  }

  public execIdStartTimeDiff(handleId: PodHandleID, execId: string): StartTimeProps | null {
    const podNode = this.handleIdToPodNode.get(handleId);
    if (podNode == null) return null;
    const { processes: pss } = podNode;

    const entry = pss.processes.get(execId);
    if (entry == null) return null;
    let ps = entry;

    // NOTE: we need to find the farthest parent of execId process entry
    // NOTE: because it's him from whom time diff is being calculated
    while (ps != null) {
      const parentExecId = pss.parents.get(ps.exec_id);
      if (parentExecId == null) break;

      const parent = pss.processes.get(parentExecId);
      if (parent?.pod?.container == null) break;

      ps = parent;
    }

    if (ps == null) return null;
    if (ps.pod?.container == null) return null;

    const diffInMs = differenceInMilliseconds(entry.start_time, ps.pod.container.start_time);

    return {
      timeDiff: diffInMs,
      isLongTimeDiff: diffInMs >= 3 * 1000 * 60,
    };
  }

  public reset() {
    this.events.splice(0, this.events.length);
    this.nodes.clear();
  }

  private ensureNode(nodeName: string): ClusterNode {
    if (this.nodes.has(nodeName)) return this.nodes.get(nodeName)!;

    const node = ClusterNode.new(nodeName);
    this.nodes.set(nodeName, node);

    return node;
  }
}
