import { Options as BaseOptions, CustomProtocolAPI } from '~/api/customprotocol-core';
import { EventParams, EventParamsSet } from '~/api/general/event-stream';
import { Paginated } from '~/api/general/pagination';
import { Filters } from '~/domain/filtering';
import { PodInfo, TimescapeData, TimescapeDataFilter } from '~/domain/timescape';

import { ConnectionsMapOneshot, ConnectionsMapParams } from './cluster-links-oneshot';
import { ControlStream } from './control-stream';
import { FullFlowOneshot } from './full-flow-oneshot';
import { ProcessEventsStream } from './process-events-stream';
import { ServiceMapFromLogsOneshot } from './service-map-from-logs-oneshot';
import { ServiceMapStream } from './service-map-stream';
import { TimescapeFlowsPager } from './timescape-flows-pager';
import {
  EventKind,
  TimescapeK8SEventsOneshot,
  TimescapeK8SEventsParams,
} from './timescape-k8s-events-oneshot';
import { TimescapePodsOneshot } from './timescape-pods-oneshot';
import { TimescapeServiceMapOneshot } from './timescape-service-map';

export type Options = BaseOptions & {};

export class BackendAPI extends CustomProtocolAPI {
  constructor(opts: Options) {
    super(opts);
  }

  public controlStream(): ControlStream {
    return new ControlStream(
      this.streamOpts({
        route: 'control-stream',
      }),
    );
  }

  public serviceMapStream(
    namespace: string,
    filters?: Filters,
    eventFlags?: EventParams | null,
  ): ServiceMapStream {
    return new ServiceMapStream(
      this.streamOpts({
        route: 'service-map-stream',
        namespace,
        filters,
        eventParams: Object.assign({}, EventParamsSet.EventStream, eventFlags),
      }),
    );
  }

  public processEventsStream(pod: PodInfo): ProcessEventsStream {
    return new ProcessEventsStream(
      this.streamOpts({
        route: 'process-events-stream',
        pod,
      }),
    );
  }

  public getTimescapeFlows(df: TimescapeDataFilter): TimescapeServiceMapOneshot {
    return new TimescapeServiceMapOneshot(
      this.streamOpts({
        route: 'get-timescape-flows',
        dataFilters: df,
      }),
    );
  }

  public getTimescapeFlowStats(df: TimescapeDataFilter): TimescapeServiceMapOneshot {
    return new TimescapeServiceMapOneshot(
      this.streamOpts({
        route: 'get-timescape-flow-stats',
        dataFilters: df,
      }),
    );
  }

  public getTimescapeK8SPolicyEvents(opts: TimescapeK8SEventsParams): TimescapeK8SEventsOneshot {
    const baseOpts = this.streamOpts({ route: 'get-timescape-k8s-events' });
    return new TimescapeK8SEventsOneshot({
      ...baseOpts,
      ...opts,
      eventKind: EventKind.PolicyEvents,
    });
  }

  public getConnectionsMap(opts: ConnectionsMapParams): ConnectionsMapOneshot {
    const baseOpts = this.streamOpts({ route: 'get-connections-map' });
    return new ConnectionsMapOneshot({
      ...baseOpts,
      ...opts,
    });
  }

  public getTimescapeFlowsPager(df: TimescapeDataFilter): Paginated<TimescapeData> {
    return new TimescapeFlowsPager()
      .setDataFilters(df)
      .setOneshotConstructor(df => this.getTimescapeFlows(df));
  }

  public getFullFlow(flowSummaryId: string): FullFlowOneshot {
    return new FullFlowOneshot(
      this.streamOpts({
        route: 'get-full-flow',
        flowSummaryId,
      }),
    );
  }

  public getTimescapePods(namespace: string | null, df: TimescapeDataFilter): TimescapePodsOneshot {
    return new TimescapePodsOneshot(
      this.streamOpts({
        route: 'get-timescape-pods',
        namespace,
        dataFilter: df,
      }),
    );
  }

  public getServiceMapFromLogs(logs: ArrayBuffer, filters?: Filters): ServiceMapFromLogsOneshot {
    return new ServiceMapFromLogsOneshot(
      this.streamOpts({
        route: 'get-service-map-from-logs',
        logs,
        filters,
      }),
    );
  }
}

export {
  ControlStream,
  ProcessEventsStream,
  ServiceMapFromLogsOneshot,
  ServiceMapStream,
  TimescapePodsOneshot,
  TimescapeServiceMapOneshot,
};
