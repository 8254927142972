import React from 'react';

import classNames from 'classnames';
import { observer } from 'mobx-react';

import { useRefsCollector } from '~/components/ProcessTree/RefsCollectorContext';
import { TrafficIcon } from '~/components/ProcessTree/TrafficIcon';
import { Intent } from '~/components/widgets/common';
import { Kind as EventKind } from '~/domain/process-events';
import { ProcessChildGroup } from '~/domain/process-tree/common';
import { getBinaryName } from '~/domain/process-tree/helpers';
import { Processes } from '~/domain/process-tree/processes';
import { applyPrefix } from '~/utils';

import css from './styles.scss';

export interface Props {
  group: ProcessChildGroup;
  processes: Processes;
  podHandleId: string;

  insideContainer?: boolean;
  containerHeadlineRendered?: boolean;
  containerStartTime?: Date;
  iconsPrefix?: string;
  isDarkThemeEnabled: boolean;

  // NOTE: I tried to pass just boolean props, but it doesnt work ><
  childGroupStates?: Map<string, boolean>;
  children?: React.ReactNode;
  suspicious?: boolean;

  onToggle?: () => void;
}

export const SimilarProcessesSubTree = observer(function SimilarProcessesSubTree(props: Props) {
  const { group, processes: ps } = props;

  const collector = useRefsCollector();

  const binaryName = getBinaryName(group.binary);
  const groupHasConnectEvents = ps.childGroupHasEventsOfKind(group, EventKind.Connect);

  const opened = !!props.childGroupStates?.get(group.key);
  const amount = group.execIds.size;

  const classes = classNames(css.similarProcessesGroup, {
    [css.insideContainer]: !!props.insideContainer,
    [css.suspicious]: !!props.suspicious,
    [css.opened]: opened,
    [css.dark]: !!props.isDarkThemeEnabled,
  });

  return (
    <div className={classes}>
      <div className={css.rootHandle} title={group.binary}>
        <div
          className={css.connectorIcon}
          ref={collector.similarChildsConnectorRef(props.podHandleId, group.key)}
        >
          <img src={applyPrefix('/icons/processes/process-expand-icon.png', props.iconsPrefix)} />
        </div>

        <div className={css.amount} title={`${amount} processes in this group`}>
          {amount}
        </div>

        <div className={css.stateArrow} onClick={props.onToggle}>
          <img src={applyPrefix('/icons/processes/triangle-right.png', props.iconsPrefix)} />
        </div>

        <div className={css.executable} title={group.binary}>
          {binaryName}
        </div>

        {opened && groupHasConnectEvents && (
          <div className={css.trafficConnector}>
            <TrafficIcon
              containerRef={collector.similarChildsEgressConnectorRef(props.podHandleId, group.key)}
              className={css.icon}
              iconsPrefix={props.iconsPrefix}
              intent={props.suspicious ? Intent.Danger : Intent.None}
              isDarkThemeEnabled={props.isDarkThemeEnabled}
            />
          </div>
        )}
      </div>

      {opened && <div className={css.subTree}>{props.children}</div>}
    </div>
  );
});
