import { EndpointSelector, LabelSelectorRequirement } from '~/domain/cilium/cnp/types.generated';
import { KV } from '~/domain/labels';

export function filterPolicySpecs(selectors: Array<null | EndpointSelector>, filterLabels: KV[]) {
  return selectors.filter(selector => {
    const matchLabels = selector?.matchLabels;
    const matchExpressions = selector?.matchExpressions;

    return (
      Object.entries(matchLabels || {}).every(([labelKey, labelValue]) =>
        matchLabel(labelKey, labelValue, filterLabels || []),
      ) &&
      (matchExpressions || []).every(expression =>
        evaluateExpression(expression, filterLabels || []),
      )
    );
  });
}

const ANY_SOURCE_PREFIX = 'any:';

enum CiliumLabelSource {
  Container = 'container',
  K8s = 'k8s',
  Mesos = 'mesos',
  Reserved = 'reserved',
  Unspec = 'unspec',
}

function removeCiliumSourcePrefix(labelKey: string): string {
  return Object.values(CiliumLabelSource).some(prefix => labelKey.startsWith(`${prefix}:`))
    ? labelKey.substr(labelKey.indexOf(':') + 1)
    : labelKey;
}

function compareLabelKeys(labelKeyFromCNP: string, labelKeyFromQuery: string) {
  const normalizedKey =
    !labelKeyFromCNP.startsWith(ANY_SOURCE_PREFIX) &&
    Object.values(CiliumLabelSource).every(prefix => !labelKeyFromCNP.startsWith(`${prefix}:`))
      ? `${ANY_SOURCE_PREFIX}${labelKeyFromCNP}`
      : labelKeyFromCNP;
  return normalizedKey.startsWith(ANY_SOURCE_PREFIX)
    ? normalizedKey.substr(ANY_SOURCE_PREFIX.length) === removeCiliumSourcePrefix(labelKeyFromQuery)
    : normalizedKey === labelKeyFromQuery;
}

function matchLabel(labelKey: string, labelValue: string, labels: KV[]) {
  return labels
    .filter(label => compareLabelKeys(labelKey, label.key))
    .some(label => label.value === labelValue);
}

function evaluateExpression(expression: LabelSelectorRequirement, labels: KV[]): boolean {
  const filteredLabels = labels.filter(label => compareLabelKeys(expression.key, label.key));
  switch (expression.operator) {
    case 'Exists':
      return filteredLabels.length > 0;
    case 'DoesNotExist':
      return filteredLabels.length === 0;
    case 'In':
      return filteredLabels.some(label => expression.values?.includes(label.value));
    case 'NotIn':
      return filteredLabels.every(label => !expression.values?.includes(label.value));
    default:
      throw new Error(`Unknown expression: ${expression}`);
  }
}
