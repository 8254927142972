import React, { useMemo, useState } from 'react';

import { AbsoluteElement } from '~/components/AbsoluteElement';
import { Teleport } from '~/components/Teleport';
import { ElemCoords } from '~/ui/hooks/useElemCoords';

import { RenderableElement } from './common';
import css from './TooltipWindow.scss';

export type Props = {
  forwardRef?: React.MutableRefObject<HTMLDivElement | null>;
  isVisible: boolean;
  handleCoords: ElemCoords | null;
  portalContainer: Element | React.MutableRefObject<Element | null>;
  content: RenderableElement;
  isSVGContext: boolean;
};

// TODO: It would be cool to have fadeIn/fadeOut animation
export const TooltipWindow = function TooltipWindow(props: Props) {
  const [windowCoords, setWindowCoords] = useState<ElemCoords | null>(null);

  const container = useMemo(() => {
    if (props.portalContainer instanceof Element) return props.portalContainer;

    return props.portalContainer.current;
  }, [props.portalContainer]);

  // NOTE: Here we just watch over windowCoords and align top left corner of
  // NOTE: tooltip window postiion to make it's bottom origin pointer pointing
  // NOTE: the handleCoords center.
  //       ______________
  //      |              |
  //      |   content    |
  //      |____  ________|
  //           \/  <- origin pointer which coords we need to control
  //          [*] <- tooltip handle (handleCoords gives it's position)
  const topLeftCornerCoords = useMemo(() => {
    if (props.handleCoords == null) return { x: -100500, y: -100500 };
    if (windowCoords == null) return props.handleCoords.center;

    const { dimensions, scale } = windowCoords;

    // NOTE: We must divide to scale.W/scale.H to account possible
    // NOTE: scaling on the outermost svg parent element
    return {
      x: props.handleCoords.center.x - dimensions.w / 2 / scale.w,
      y: props.handleCoords.center.y - dimensions.h / 1 / scale.h - 5,
    };
  }, [props.handleCoords, windowCoords]);

  const content = useMemo(() => {
    if (props.content instanceof Function) return props.content();

    return props.content;
  }, [props.content]);

  // NOTE: Be careful: html part of this component is not tested and may require
  // NOTE: some calibration. Partially, it may require to transform coords into
  // NOTE: page-relative coords (use ~/ui/utils/.ts toPageCoords for this).
  // NOTE: It's better to perform such conversion on this level, not inside of
  // NOTE: AbsoluteElement.
  return (
    <Teleport to={container}>
      {!!props.isVisible && (
        <AbsoluteElement
          coords={topLeftCornerCoords}
          onCoords={setWindowCoords}
          forwardRef={props.forwardRef}
          isSVGContext={!!props.isSVGContext}
        >
          <div className={css.tooltipBody}>
            <div className={css.tooltipContent}>{content}</div>

            <div className={css.tooltipArrow}></div>
          </div>
        </AbsoluteElement>
      )}
    </Teleport>
  );
};
