import { useEffect } from 'react';

import { useApplication } from '~/application';
import { DataLayer } from '~/data-layer';
import { FilterDirection, FilterEntry } from '~/domain/filtering';
import { FilterGroup, Kind } from '~/domain/filtering/filter-entry';
import { Labels } from '~/domain/labels';
import { Store } from '~/store';
import { UILayer } from '~/ui-layer';

export function useFlowFilterGroups() {
  const { store, ui, dataLayer } = useApplication();
  useEffect(() => {
    if (dataLayer.transferState.isDisabled) {
      return;
    }
    normalizeFilters(store, ui, dataLayer);
  }, [store.controls.currApp, dataLayer.transferState.dataMode, store.controls.flowFilterGroups]);
}

// This function tries to seamlessly keep global
//cluster/namespace selection with flow filter groups
function normalizeFilters(store: Store, ui: UILayer, dataLayer: DataLayer) {
  try {
    const currGroups = store.controls.flowFilterGroups;
    const currCluster = store.clusterNamespaces.currCluster;
    const currNamespace = store.clusterNamespaces.currNamespace;

    // Put default filter groups
    if (currGroups.length === 0) {
      if (dataLayer.transferState.isCiliumStreaming && currNamespace) {
        ui.controls.setFlowFilterGroups(dataLayer.controls.defaultFlowFilterGroups, {
          commitRouter: false,
        });
      } else if (dataLayer.transferState.isWatchingHistory && (currCluster || currNamespace)) {
        ui.controls.setFlowFilterGroups(dataLayer.controls.defaultFlowFilterGroups, {
          commitRouter: false,
        });
      }
      return;
    }

    const { clusters, namespaces } = dataLayer.controls.uniqClustersNamespaces;

    let nextCluster = currCluster;
    if (clusters.size === 1) {
      const clusterFromFilters = [...clusters][0];
      if (dataLayer.transferState.isWatchingHistory && currCluster !== clusterFromFilters) {
        nextCluster = clusterFromFilters;
      }
    }

    let nextNamespace = currNamespace;
    if (namespaces.size === 1) {
      const namespaceFromFilters = [...namespaces][0];
      if (currNamespace !== namespaceFromFilters) {
        nextNamespace = namespaceFromFilters;
      }
    }

    if (nextCluster !== currCluster || nextNamespace !== currNamespace) {
      ui.controls.clusterNamespaceChanged(nextCluster, nextNamespace);
      return;
    }

    let groupsWereChanged = false;

    const nextGroups = currGroups.reduce<FilterGroup[]>((groups, group) => {
      let groupWasChanged = false;
      let hasClusterEntry = false;
      let hasNamespaceEntry = false;
      let isReserved = false;

      let nextEntries = group.entries.reduce<FilterEntry[]>((entries, entry) => {
        // No cluster entries in filters for live view mode
        if (dataLayer.transferState.isCiliumStreaming) {
          if (entry.isCluster) {
            return entries;
          }
        }

        // Update filter group with globally selected cluster
        if (!dataLayer.transferState.isCiliumStreaming) {
          if (entry.isCluster && currCluster) {
            hasClusterEntry = true;
          }
        }

        // Update filter group with globally selected namespace
        if (entry.isNamespace && currNamespace) {
          hasNamespaceEntry = true;
        }

        if (entry.isLabel) {
          isReserved ||= Labels.isReserved(Labels.toKV(entry.query, true));
        }

        entries.push(entry);

        return entries;
      }, []);

      groupWasChanged ||= nextEntries.length !== group.entries.length;

      // We only update filter group for specific kind of filters.
      // We don't add new entries in case if filter group contains
      // some reserved label filter.
      const shouldAttemptNormalization =
        !isReserved &&
        nextEntries.some(
          e => e.isWorkload || e.isIdentity || e.isLabel || e.isPod || e.isNamespace,
        );
      if (shouldAttemptNormalization) {
        // If no namespace filter - add it
        if (!hasNamespaceEntry && currNamespace) {
          const namespaceEntry = new FilterEntry({
            kind: Kind.Namespace,
            direction: FilterDirection.Either,
            query: currNamespace,
          });
          nextEntries = [namespaceEntry, ...nextEntries];
          groupWasChanged = true;
        }
        // If no cluster filter - add it
        if (!hasClusterEntry && currCluster && !dataLayer.transferState.isCiliumStreaming) {
          const clusterEntry = new FilterEntry({
            kind: Kind.Cluster,
            direction: FilterDirection.Either,
            query: currCluster,
          });
          nextEntries = [clusterEntry, ...nextEntries];
          groupWasChanged = true;
        }
      }

      // Finally if we know that something actually changed in filter group we modify stored data
      if (groupWasChanged) {
        groupsWereChanged = true;
        if (nextEntries.length > 0) groups.push(new FilterGroup(nextEntries));
      } else {
        groups.push(group);
      }

      return groups;
    }, []);

    // Finally if we know that something actually changed in some filter group we modify stored data
    if (groupsWereChanged) {
      ui.controls.setFlowFilterGroups(nextGroups, { commitRouter: false });
    }
  } finally {
    ui.router.commit();
  }
}
