import type { DataFrame, Field, PanelData } from '@grafana/data';

import { dateToTime, parseTimeFromObject, Time, timeToDate } from '~/domain/helpers/time';
import {
  Ancestor,
  ConnectEvent,
  Container,
  ExecEvent,
  Image,
  Pod,
  Process,
  ProcessEvent,
} from '~/domain/process-events';
import { logger } from '~/utils/logger';
import * as fgspb from '~backend/proto/tetragon/events_pb';

export interface SplunkResultEntry {
  'E.process_exec.process.exec_id': string;
  _raw: string;
  _time: string;
  'P.count': string;
  'P.destination': string;
  'P.process_connect.destination_port': string;
  'P.process_connect.process.exec_id': string;
  'P.process_connect.source_ip': string;
}

export function parseProcessEventsFromSplunkResult(
  splunkResultEntries: SplunkResultEntry[],
): ProcessEvent[] {
  const processEvents: ProcessEvent[] = [];
  splunkResultEntries.forEach(splunkResultEntry => {
    const parsedEvent = JSON.parse(splunkResultEntry._raw);

    const time = dateToTime(new Date(parsedEvent.time));
    const node_name = parsedEvent.node_name;

    const updateTime = (process?: Process | Ancestor | null | undefined) => {
      if (!process) return;
      if (typeof process.start_time === 'string') {
        process.start_time = new Date(process.start_time);
      }
      if (typeof process.pod?.container?.start_time === 'string') {
        process.pod.container.start_time = new Date(process.pod.container.start_time);
      }
      return process;
    };
    const processExecRaw = parsedEvent.process_exec as ExecEvent;
    updateTime(processExecRaw.process);
    updateTime(processExecRaw.parent);
    processExecRaw.ancestors =
      processExecRaw.ancestors?.map(ancestor => {
        updateTime(ancestor);
        return ancestor;
      }) ?? [];

    const processConnectRaw: ConnectEvent = {
      sourceIp: splunkResultEntry['P.process_connect.source_ip'],
      sourcePort: 0,
      destinationIp: splunkResultEntry['P.destination'] === 'IP Address' ? 'IP Address' : '',
      destinationNames: splunkResultEntry['P.destination']?.startsWith('dns=')
        ? splunkResultEntry['P.destination'].slice('dns='.length).split(',')
        : [],
      destinationPort: +splunkResultEntry['P.process_connect.destination_port'],
      parent: processExecRaw.parent!,
      process: processExecRaw.process,
    };

    const processExecEvent = new ProcessEvent();
    processExecEvent.node_name = node_name;
    processExecEvent.time = time;
    processExecEvent.process_exec = processExecRaw;

    const processConnectEvent = new ProcessEvent();
    processConnectEvent.node_name = node_name;
    processConnectEvent.time = time;
    processConnectEvent.process_connect = processConnectRaw;

    processEvents.push(processExecEvent);
    processEvents.push(processConnectEvent);
  });
  return processEvents;
}

export const parseProcessEventsFromPanelData = (panelData: PanelData): ProcessEvent[] => {
  logger.log('parseProcessEventsFromPanelData', panelData);

  const events: ProcessEvent[] = [];
  panelData.series.forEach(dataFrame => {
    const processEvent = parseProcessEventFromDataFrame(dataFrame);
    if (processEvent == null) {
      logger.warn('failed to parse process event from data frame', dataFrame);
      return;
    }

    events.push(processEvent);
  });

  logger.log('parsed events: ', events);

  return events;
};

const parseProcessEventFromDataFrame = (frame: DataFrame): ProcessEvent | null => {
  const psEvent = new ProcessEvent();

  for (const field of frame.fields) {
    const { name, values } = field;
    if (values.length === 0) {
      return null;
    }

    const value = values.get(0);

    if (name === 'node_name') {
      psEvent.node_name = value;
    } else if (name === 'time') {
      const time = parseTimeFromObject(value);
      if (time == null) {
        return null;
      }

      psEvent.time = time;
    } else if (name === 'kind') {
      const kind: fgspb.EventType = fgspb.EventType[value] as any as fgspb.EventType;

      if (kind === fgspb.EventType.PROCESS_EXEC) {
        const processExec = parseProcessExecFromDataFrame(frame);
        if (processExec == null) {
          return null;
        }

        psEvent.process_exec = processExec;
      } else if (kind === fgspb.EventType.PROCESS_CONNECT) {
        const processConnect = parseProcessConnectFromDataFrame(frame);
        if (processConnect == null) {
          return null;
        }

        psEvent.process_connect = processConnect;
      }
    }
  }

  return psEvent;
};

const parseProcessConnectFromDataFrame = (frame: DataFrame): ConnectEvent | null => {
  const proc = parseProcessFromDataFrame(frame, 'process/');
  if (proc == null) {
    return null;
  }

  const parent = parseAncestorFromDataFrame(frame, 'parent/');
  if (parent == null) {
    return null;
  }

  let sourceIp = '';
  let sourcePort = -1;
  let destinationIp = '';
  let destinationPort = -1;
  let destinationNames = [] as string[];
  let destinationPod = null as Pod | null;

  // NOTE: Keep this number equal to number of fields in the `Ancestor``
  let nFieldsLeft = 6;

  for (const field of frame.fields) {
    const { values, name } = field;
    if (values.length === 0) {
      return null;
    }

    const value = values.get(0);
    nFieldsLeft -= 1;

    if (name === 'source_ip') {
      sourceIp = value;
    } else if (name === 'source_port') {
      sourcePort = value;
    } else if (name === 'destination_ip') {
      destinationIp = value;
    } else if (name === 'destination_port') {
      destinationPort = value;
    } else if (name === 'destination_names') {
      destinationNames = value.split(',');
    } else if (name.startsWith('destination_pod/') && destinationPod == null) {
      destinationPod = parsePodFromDataFrame(frame, 'destination_pod/');
    } else {
      nFieldsLeft += 1;
    }

    // NOTE: This is an optimization for not iterating through unrelated fields
    if (nFieldsLeft === 0) {
      break;
    }
  }

  if (!sourceIp || sourcePort === -1 || !destinationIp || destinationPort === -1) {
    return null;
  }

  return {
    process: proc,
    parent,
    sourceIp,
    sourcePort,
    destinationIp,
    destinationPort,
    destinationNames,
    destinationPod,
  };
};

const parseProcessExecFromDataFrame = (frame: DataFrame): ExecEvent | null => {
  const execProcess = parseProcessFromDataFrame(frame, 'process/');
  if (execProcess == null) {
    return null;
  }

  const parent = parseAncestorFromDataFrame(frame, 'parent/');

  const ancestorsFields = frame.fields.filter(field => {
    return field.name === 'n_ancestors' || field.name.startsWith('ancestor/');
  });

  const ancestors = ancestorsFields.length === 0 ? [] : parseAncestorsFromFields(ancestorsFields);

  return {
    process: execProcess,
    parent,
    ancestors,
  };
};

const parseAncestorsFromFields = (fields: Field[]): Ancestor[] => {
  const ancestorsMap = new Map<number, Ancestor>();

  for (const field of fields) {
    // NOTE: name has form `ancestor/<index>/other/fields/here`

    if (!field.name.startsWith('ancestor/')) {
      continue;
    }
    const idxName = field.name.slice('ancestor/'.length);

    const nextSlashIdx = idxName.indexOf('/');
    const idxStr = idxName.slice(0, nextSlashIdx);
    const idx = parseInt(idxStr, 10);

    if (Number.isNaN(idx)) {
      logger.warn('failed to parse ancestor idx', idxStr);
      continue;
    }

    if (ancestorsMap.has(idx)) {
      continue;
    }

    const ancestor = parseAncestorFromDataFrame(
      {
        fields,
        length: fields.length,
      },
      `ancestor/${idx}/`,
    );

    if (ancestor == null) {
      logger.warn('failed to parse ancestor', fields);
      continue;
    }

    ancestorsMap.set(idx, ancestor);
  }

  return Array.from(ancestorsMap.values());
};

const parseProcessFromDataFrame = (frame: DataFrame, prefix = ''): Process | null => {
  const anc = parseAncestorFromDataFrame(frame, prefix);
  if (anc?.pod == null) {
    return null;
  }

  return anc as Process;
};

const parseAncestorFromDataFrame = (frame: DataFrame, prefix = ''): Ancestor | null => {
  let exec_id = '';
  let pid = -1;
  let uid = -1;
  let cwd = '';
  let binary = '';
  let args = '';
  let flags = '';
  let start_time = parseTimeFromObject(new Date());
  let auid = -1;
  let pod = null;
  let docker = '';
  let parent_exec_id = void 0;

  // NOTE: Keep this number equal to number of fields in the `Ancestor``
  let nFieldsLeft = 12;

  for (const field of frame.fields) {
    const { values } = field;

    if (!field.name.startsWith(prefix)) {
      continue;
    }
    if (values.length === 0) {
      return null;
    }

    const value = values.get(0);
    const name = field.name.slice(prefix.length);
    nFieldsLeft -= 1;

    if (name === 'exec_id') {
      exec_id = value;
    } else if (name === 'pid') {
      pid = value;
    } else if (name === 'uid') {
      uid = value;
    } else if (name === 'cwd') {
      cwd = value;
    } else if (name === 'binary') {
      binary = value;
    } else if (name === 'arguments') {
      args = value;
    } else if (name === 'flags') {
      flags = value;
    } else if (name === 'start_time') {
      const time = parseTimeFromObject(value);
      if (time == null) {
        return null;
      }

      start_time = time;
    } else if (name === 'auid') {
      auid = value;
    } else if (name === 'docker') {
      docker = value;
    } else if (name === 'parent_exec_id') {
      parent_exec_id = value;
    } else if (name.startsWith('pod') && pod == null) {
      pod = parsePodFromDataFrame(frame, prefix + 'pod/');
    } else {
      nFieldsLeft += 1;
    }

    // NOTE: This is an optimization for not iterating through unrelated fields
    if (nFieldsLeft === 0) {
      break;
    }
  }

  if (start_time == null) {
    return null;
  }
  if (exec_id.length === 0 || pid === -1 || uid === -1 || auid === -1) {
    return null;
  }

  return {
    exec_id,
    pid,
    uid,
    cwd,
    binary,
    arguments: args,
    flags,
    start_time: timeToDate(start_time),
    auid,
    pod: pod ?? void 0,
    docker,
    parent_exec_id,
  };
};

const parsePodFromDataFrame = (frame: DataFrame, prefix = ''): Pod | null => {
  let namespace = '';
  let podName = '';
  let labels = [] as string[];
  let container = null as Container | null;

  // NOTE: Keep this number equal to number of fields in the `Ancestor``
  let nFieldsLeft = 4;

  for (const field of frame.fields) {
    const { values } = field;

    if (!field.name.startsWith(prefix)) {
      continue;
    }
    if (values.length === 0) {
      return null;
    }

    const value = values.get(0);
    const name = field.name.slice(prefix.length);
    nFieldsLeft -= 1;

    if (name === 'namespace') {
      namespace = value;
    } else if (name === 'name') {
      podName = value;
    } else if (name === 'labels') {
      labels = value.split(',');
    } else if (name.startsWith('container') && container == null) {
      container = parseContainerFromDataFrame(frame, prefix + 'container/');
    } else {
      nFieldsLeft += 1;
    }

    // NOTE: This is an optimization for not iterating through unrelated fields
    if (nFieldsLeft === 0) {
      break;
    }
  }

  if (!podName || container == null) {
    return null;
  }

  return {
    name: podName,
    namespace,
    labels,
    container,
  };
};

const parseContainerFromDataFrame = (frame: DataFrame, prefix = ''): Container | null => {
  let id = '';
  let containerName = '';
  let image = null as Image | null;
  let start_time = null as Time | null;
  let pid = void 0 as undefined | number;
  let maybe_exec_probe = false;

  // NOTE: Keep this number equal to number of fields in the `Ancestor``
  let nFieldsLeft = 6;

  for (const field of frame.fields) {
    const { values } = field;

    if (!field.name.startsWith(prefix)) {
      continue;
    }
    if (values.length === 0) {
      return null;
    }

    const value = values.get(0);
    const name = field.name.slice(prefix.length);
    nFieldsLeft -= 1;

    if (name === 'id') {
      id = value;
    } else if (name === 'name') {
      containerName = value;
    } else if (name === 'start_time') {
      const time = parseTimeFromObject(value);
      if (time == null) {
        return null;
      }

      start_time = time;
    } else if (name.startsWith('image') && image == null) {
      image = parseImageFromDataFrame(frame, prefix + 'image/');
    } else if (name === 'pid') {
      pid = value;
    } else if (name === 'maybe_exec_probe') {
      maybe_exec_probe = value;
    } else {
      nFieldsLeft += 1;
    }

    // NOTE: This is an optimization for not iterating through unrelated fields
    if (nFieldsLeft === 0) {
      break;
    }
  }

  if (image == null || start_time == null) {
    return null;
  }

  return {
    id,
    name: containerName,
    image,
    start_time: timeToDate(start_time),
    pid,
    maybe_exec_probe,
  };
};

const parseImageFromDataFrame = (frame: DataFrame, prefix = ''): Image | null => {
  let id = '';
  let imageName = '';

  // NOTE: Keep this number equal to number of fields in the `Ancestor``
  let nFieldsLeft = 2;

  for (const field of frame.fields) {
    const { values } = field;

    if (!field.name.startsWith(prefix)) {
      continue;
    }
    if (values.length === 0) {
      return null;
    }

    const value = values.get(0);
    const name = field.name.slice(prefix.length);
    nFieldsLeft -= 1;

    if (name === 'id') {
      id = value;
    } else if (name === 'name') {
      imageName = value;
    } else {
      nFieldsLeft += 1;
    }

    // NOTE: This is an optimization for not iterating through unrelated fields
    if (nFieldsLeft === 0) {
      break;
    }
  }

  return { id, name: imageName };
};
