import React from 'react';

import { Button, MenuItem } from '@blueprintjs/core';
import { ItemPredicate, ItemRenderer, Select } from '@blueprintjs/select';
import { observer } from 'mobx-react-lite';

import { GrafanaDashboardsEntry } from '~/domain/features';

const filterEntry: ItemPredicate<GrafanaDashboardsEntry> = (query, entry, _index, exactMatch) => {
  const normalizedTitle = entry.title.toLowerCase();
  const normalizedQuery = query.toLowerCase();

  if (exactMatch) {
    return normalizedTitle === normalizedQuery;
  } else {
    return normalizedTitle.indexOf(normalizedQuery) >= 0;
  }
};

const renderEntry: ItemRenderer<GrafanaDashboardsEntry> = (
  entry,
  { handleClick, handleFocus, modifiers },
) => {
  if (!modifiers.matchesPredicate) {
    return null;
  }
  return (
    <MenuItem
      active={modifiers.active}
      disabled={modifiers.disabled}
      key={entry.title + entry.dashboard.uid}
      onClick={handleClick}
      onFocus={handleFocus}
      roleStructure="listoption"
      text={entry.title}
    />
  );
};

export interface Props {
  entries: GrafanaDashboardsEntry[];
  current: GrafanaDashboardsEntry;
  onSelect: (entry: GrafanaDashboardsEntry) => void;
}

export const GrafanaChartSelector = observer(function GrafanaChartSelector(props: Props) {
  return (
    <Select<GrafanaDashboardsEntry>
      items={props.entries}
      itemPredicate={filterEntry}
      itemRenderer={renderEntry}
      noResults={<MenuItem disabled={true} text="No results." roleStructure="listoption" />}
      popoverProps={{ placement: 'right', hasBackdrop: true }}
      onItemSelect={props.onSelect}
    >
      <Button
        text={props.current.title ? props.current.title : 'Select dashboard'}
        icon="grouped-bar-chart"
        rightIcon="double-caret-vertical"
      />
    </Select>
  );
});
