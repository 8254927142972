import React from 'react';

import { useApplication } from '~/application';
import { buildBackendUrl } from '~/domain/env';
import { GrafanaDashboardKind, GrafanaDashboardsEntry } from '~/domain/features';
import { TimeRange } from '~/domain/time';
import { useStore } from '~/store';

import { DashboardVariant } from '../Dashboard/types';

export type DashboardVariableKind = 'namespace' | 'workload';
export type DashboardVariables = {
  [key in DashboardVariableKind]?: string;
};

export function useDashboardVariables(kind: GrafanaDashboardKind): DashboardVariables {
  const { store, ui } = useApplication();

  return React.useMemo((): { [key: string]: string } => {
    if (kind === GrafanaDashboardKind.Cluster) {
      return {};
    }
    const variables: DashboardVariables = {
      namespace: 'All',
    };
    if (store.clusterNamespaces?.currNamespace) {
      variables.namespace = store.clusterNamespaces.currNamespace;
    }
    if (kind === GrafanaDashboardKind.Namespace) {
      return variables;
    }

    const activeServices = ui.getActiveServices();
    const workload = activeServices.find(svc => svc.workload != null)?.workload;

    if (workload != null) {
      variables.workload = workload.name;
    }
    return variables;
  }, [kind, store.controls.flowFilterGroups]);
}

export function useAutoDashboardKind() {
  const store = useStore();

  return React.useMemo((): GrafanaDashboardKind => {
    if (
      store.controls.flowFilterGroups.filter(g => g.entries.some(e => e.isWorkload)).length === 1
    ) {
      return GrafanaDashboardKind.Service;
    }

    return GrafanaDashboardKind.Namespace;
  }, [store.controls.flowFilterGroups]);
}

export function useGrafanaDashboard(
  variant: DashboardVariant,
  kind: GrafanaDashboardKind,
  timeRange: TimeRange,
) {
  const store = useStore();

  const bgColor = React.useMemo(() => {
    return getComputedStyle(document.documentElement).getPropertyValue('--hubble-ui-background');
  }, [store.themes.isDarkTheme]);

  const variables = useDashboardVariables(kind);

  const entry = store.uiSettings.grafana.enabled
    ? store.uiSettings.grafana.dashboards[variant]
    : null;

  const [current, setCurrent] = React.useState(entry?.dashboards[0] ?? null);

  React.useEffect(() => {
    setCurrent(entry?.dashboards[0] ?? null);
  }, [entry]);

  const context = React.useMemo(() => {
    if (!entry || !current) return null;

    const url = buildChartUrl(
      current,
      `/d/${current.dashboard.uid}`,
      timeRange,
      variables,
      store.themes.isDarkTheme,
    );

    return {
      variant,
      kind,
      entry,
      url,
      select: setCurrent,
      current,
      bgColor,
      variables,
      timeRange,
      isDarkTheme: store.themes.isDarkTheme,
    };
  }, [variant, kind, entry, current, bgColor, store.themes.isDarkTheme, variables, timeRange]);

  return { context };
}

function buildChartUrl(
  current: GrafanaDashboardsEntry,
  path: string,
  tr: TimeRange,
  variables: DashboardVariables,
  isDarkTheme: boolean,
): string {
  const url = new URL(path, buildBackendUrl());
  url.searchParams.set('from', tr.startDate.valueOf().toString());
  url.searchParams.set('to', tr.endDate.valueOf().toString());
  url.searchParams.set('proxy', 'grafana');
  url.searchParams.set('theme', isDarkTheme ? 'dark' : 'light');
  url.searchParams.delete('tz');
  url.searchParams.delete('refresh');
  Object.entries(variables ?? {}).forEach(([key, value]) => {
    if (key === 'namespace') {
      url.searchParams.set(
        'var-source_namespace',
        mapModalNamespaceVariable(current, value, 'All'),
      );
      url.searchParams.set(
        'var-destination_namespace',
        mapModalNamespaceVariable(current, 'All', value),
      );
    }
    if (key === 'workload') {
      url.searchParams.set('var-source_workload', 'All');
      url.searchParams.set('var-destination_workload', value);
    }
  });
  return url.toString();
}

function mapModalNamespaceVariable(
  current: GrafanaDashboardsEntry,
  aValue: string,
  bValue: string,
) {
  if (
    current.dashboard.uid === 'hubble-ui-hubble-dns-overview' ||
    current.dashboard.uid === 'hubble-ui-hubble-network-overview'
  ) {
    return aValue;
  }

  if (
    current.dashboard.uid === 'hubble-ui-hubble-dns-overview' ||
    current.dashboard.uid === 'hubble-ui-hubble-network-overview'
  ) {
    return aValue;
  }

  return bValue;
}

export type NonNullableFields<T> = {
  [P in keyof T]: NonNullable<T[P]>;
};

export type GrafanaDashboardContext = NonNullable<
  NonNullableFields<ReturnType<typeof useGrafanaDashboard>['context']>
>;
