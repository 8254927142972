import React from 'react';

import classNames from 'classnames';
import { observer } from 'mobx-react';

import { Button, Props as ButtonProps } from '~/components/widgets/Button';
import buttonCss from '~/components/widgets/Button/styles.scss';

import css from './styles.scss';

type MaybeButton = React.ReactElement<ButtonProps> | null | undefined | false;

export interface Props {
  className?: string;
  children?: MaybeButton[];
}

export const ButtonGroup = observer(function ButtonGroup(props: Props) {
  const classes = classNames(css.buttonGroup, props.className);

  const childButtons = (props.children || []).filter(e => {
    return e != null && !!e;
  }) as React.ReactElement<ButtonProps>[];

  const stackedButtons = childButtons.map((child, idx, all) => {
    if (!child || child == null) return null;

    const n = all.length;
    const nextButton = all[idx + 1];

    const classes = classNames(child.props.className, {
      [buttonCss.noRightRounding]: n > 1 && idx !== n - 1,
      [buttonCss.noLeftRounding]: n > 1 && idx !== 0,
      [buttonCss.noRightBorder]: idx !== n - 1 && !!nextButton?.props?.active,
    });

    return (
      <Button key={child.key ?? idx} {...child.props} className={classes}>
        {child.props.children}
      </Button>
    );
  });

  return <div className={classes}>{stackedButtons}</div>;
});
