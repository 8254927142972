import * as mobx from 'mobx';

import { DataLayer } from '~/data-layer';
import { Application } from '~/domain/common';
import * as helpers from '~/domain/helpers';
import { ProcessEvent } from '~/domain/process-events';
import { PodInfo } from '~/domain/timescape/pod-info';
import { Store } from '~/store';
import { ProcessTreeArrowsLayout } from '~/ui/process-tree/arrows';
import { RefsCollector } from '~/ui/process-tree/collector';
import { TreeLayout } from '~/ui/process-tree/layout';
import { WidgetControl } from '~/ui/widgets/control';
import { Options } from '~/ui-layer/common';
import { logger } from '~/utils/logger';
import { Timer } from '~/utils/timer';

export class ProcessTree {
  private readonly store: Store;
  private readonly dataLayer: DataLayer;

  public collector: RefsCollector;
  public layout: TreeLayout;
  public arrows: ProcessTreeArrowsLayout;

  @mobx.observable
  public isTimescapePodsLoading = false;

  @mobx.observable
  public isPodEventsLoading = false;

  @mobx.observable
  public isPodEventsLoadingTakesTooLong = false;

  @mobx.observable
  public amountOfPodEventsLoaded = 0;

  public readonly podSelectionControl: WidgetControl = new WidgetControl();

  constructor(opts: Options) {
    this.store = opts.store;
    this.dataLayer = opts.dataLayer;

    this.collector = new RefsCollector();
    this.layout = new TreeLayout();
    this.arrows = new ProcessTreeArrowsLayout(
      this.collector,
      this.layout,
      this.store.processTree.tree,
    );

    mobx.makeObservable(this);

    this.setupEventHandlers();
  }

  public get prefilteredPods(): string[] {
    const prefilter = this.getInitialPodQuery();

    const allPods = this.store.processTree.availablePods;
    if (!prefilter) return allPods;

    return allPods.filter(podName => podName.toLowerCase().includes(prefilter));
  }

  public async namespaceChanged(ns: string): Promise<boolean> {
    return await this.dataLayer.processTree.namespaceChanged(ns);
  }

  public getInitialPodQuery(): string | undefined {
    const query = this.store.processTree.podPrefilter;

    return query || void 0;
  }

  public async dropLongLoading() {
    if (!this.isPodEventsLoading || !this.isPodEventsLoadingTakesTooLong) return;

    await this.dataLayer.processTree.dropProcessEventsStream();
  }

  public async refetchProcessEvents() {
    if (this.store.processTree.currentPodName == null) return;
    if (this.isPodEventsLoading) return;

    const podName = this.store.processTree.currentPodName;
    await this.dataLayer.processTree.recreateProcessEventsStream(podName);
  }

  public async refetchPodList() {
    if (this.store.processTree.currentNamespace == null) return;
    if (this.isPodEventsLoading) return;

    await this.dataLayer.processTree.ensurePodsForNamespace();
  }

  public async podSelected(podName: string) {
    const isLocalMode = this.store.processTree.isLocalMode;
    const isChanged = this.store.processTree.selectPod(podName);

    if (isChanged) {
      this.layout.reset();
      this.collector.reset();
      this.arrows.reset();

      this.store.processTree.tree.clear();
    }

    if (!isLocalMode) {
      await this.dataLayer.processTree.ensureProcessEventsStream(podName);
      return;
    }

    const events = this.store.processTree.currentEvents;
    logger.log(`podSelected events: `, events);
    this.store.processTree.replaceEvents(events);
  }

  public async appToggled(next: Application, isChanged: boolean) {
    if (!isChanged) return;

    switch (next) {
      case Application.ProcessTree: {
        await this.dataLayer.processTree.appOpened();
        break;
      }
    }
  }

  public replaceEventsWithLogs(psEventsLogs: string) {
    const { processEvents } = helpers.processEvents.parse(psEventsLogs);
    logger.log('process events parsed from logs: ', processEvents);

    this.replaceEvents(processEvents);
  }

  public replaceEvents(events: ProcessEvent[], pod?: PodInfo) {
    this.layout.reset();
    this.collector.reset();
    this.arrows.reset();

    this.store.processTree.replaceEvents(events, pod?.namespace, pod?.name);
  }

  private async handlePrefiltering() {
    const prefilter = this.getInitialPodQuery();
    if (!prefilter) return;

    const pods = this.prefilteredPods;

    if (pods.length === 1) {
      await this.podSelected(pods[0]);
    } else {
      this.podSelectionControl.open();
    }

    // WARN: this assignment goes around the data layer logic
    this.store.processTree.podPrefilter = null;
  }

  private setupEventHandlers() {
    const podsLoadingTimer = Timer.new(400).onTimeout(() => {
      this.isTimescapePodsLoading = false;
    });

    this.dataLayer.processTree.onTimescapePodsLoadingStarted(() => {
      this.isTimescapePodsLoading = true;
    });

    this.dataLayer.processTree.onTimescapePodsLoadingFinished(() => {
      podsLoadingTimer.reset();
    });

    this.dataLayer.processTree.onTimescapePodsLoadingSuccess(() => {
      this.handlePrefiltering();
    });

    this.setupEventChunksLoadingHandlers();

    this.collector.onCoordsUpdated(bboxes => {
      this.layout.setCollectedCoords(bboxes);
      logger.log(`updated bboxes: `, bboxes);
      const onlyDestinationArrows = !this.layout.isHierarchyArrowsUpdateNeeded(bboxes);
      this.arrows.release({ onlyDestinationArrows });
    });
  }

  private setupEventChunksLoadingHandlers() {
    const podEventsLoadingTimer = Timer.new(400).onTimeout(() => {
      this.isPodEventsLoading = false;
    });

    const tooLongTimer = Timer.new(5000).onTimeout(() => {
      this.isPodEventsLoadingTakesTooLong = true;
    });

    this.dataLayer.processTree.onTimescapePodEventsLoadingStarted(() => {
      // NOTE: This check prevents timer from being triggered when event stream
      // is recreated (e g when pod is changed)
      if (podEventsLoadingTimer.isSet) podEventsLoadingTimer.stop();

      this.isPodEventsLoading = true;
    });

    let accumulator: ProcessEvent[] = [];
    this.dataLayer.processTree.onTimescapePodEventsChunkFetched(evts => {
      accumulator = accumulator.concat(evts);
      this.amountOfPodEventsLoaded = accumulator.length;
    });

    this.dataLayer.processTree.onTimescapePodEventsLoadingSuccess(pod => {
      this.replaceEvents(accumulator, pod);
    });

    this.dataLayer.processTree.onTimescapePodEventsLoadingFinished(() => {
      tooLongTimer.stop();
      podEventsLoadingTimer.reset();
      accumulator = [];

      this.isPodEventsLoading = false;
      this.amountOfPodEventsLoaded = 0;
      this.isPodEventsLoadingTakesTooLong = false;
    });

    this.dataLayer.processTree.onTimescapePodEventsLoadingStarted(() => {
      this.amountOfPodEventsLoaded = 0;
      tooLongTimer.reset();
    });
  }
}
