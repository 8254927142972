/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter add_pb_suffix,eslint_disable,ts_nocheck,generate_dependencies,long_type_number,use_proto_field_name
// @generated from protobuf file "ui/process-events.proto" (package "ui", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { GetEventsResponse } from "../tetragon/events_pb";
import { EventsContainerInfo } from "../timescape/v1/container_pb";
/**
 * @generated from protobuf message ui.GetTimescapeProcessEventsRequest
 */
export interface GetTimescapeProcessEventsRequest {
    /**
     * @generated from protobuf field: timescape.v1.EventsContainerInfo container = 1;
     */
    container?: EventsContainerInfo;
}
/**
 * @generated from protobuf message ui.GetTimescapeProcessEventsResponse
 */
export interface GetTimescapeProcessEventsResponse {
    /**
     * @generated from protobuf field: repeated tetragon.GetEventsResponse events = 1;
     */
    events: GetEventsResponse[];
}
// @generated message type with reflection information, may provide speed optimized methods
class GetTimescapeProcessEventsRequest$Type extends MessageType<GetTimescapeProcessEventsRequest> {
    constructor() {
        super("ui.GetTimescapeProcessEventsRequest", [
            { no: 1, name: "container", kind: "message", T: () => EventsContainerInfo }
        ]);
    }
    create(value?: PartialMessage<GetTimescapeProcessEventsRequest>): GetTimescapeProcessEventsRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<GetTimescapeProcessEventsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetTimescapeProcessEventsRequest): GetTimescapeProcessEventsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* timescape.v1.EventsContainerInfo container */ 1:
                    message.container = EventsContainerInfo.internalBinaryRead(reader, reader.uint32(), options, message.container);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetTimescapeProcessEventsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* timescape.v1.EventsContainerInfo container = 1; */
        if (message.container)
            EventsContainerInfo.internalBinaryWrite(message.container, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ui.GetTimescapeProcessEventsRequest
 */
export const GetTimescapeProcessEventsRequest = new GetTimescapeProcessEventsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetTimescapeProcessEventsResponse$Type extends MessageType<GetTimescapeProcessEventsResponse> {
    constructor() {
        super("ui.GetTimescapeProcessEventsResponse", [
            { no: 1, name: "events", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => GetEventsResponse }
        ]);
    }
    create(value?: PartialMessage<GetTimescapeProcessEventsResponse>): GetTimescapeProcessEventsResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.events = [];
        if (value !== undefined)
            reflectionMergePartial<GetTimescapeProcessEventsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetTimescapeProcessEventsResponse): GetTimescapeProcessEventsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated tetragon.GetEventsResponse events */ 1:
                    message.events.push(GetEventsResponse.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetTimescapeProcessEventsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated tetragon.GetEventsResponse events = 1; */
        for (let i = 0; i < message.events.length; i++)
            GetEventsResponse.internalBinaryWrite(message.events[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ui.GetTimescapeProcessEventsResponse
 */
export const GetTimescapeProcessEventsResponse = new GetTimescapeProcessEventsResponse$Type();
