import { LastSeen, Order } from '~/domain/common';
import { Filters } from '~/domain/filtering/filters';

export class TimescapeDataFilter {
  public static readonly defaultLimit = 256;

  public limit: number = TimescapeDataFilter.defaultLimit;
  public order: Order = Order.Ascending;
  public filters: Filters;
  public lastSeen?: LastSeen | null;

  public constructor(filters: Filters) {
    this.filters = filters;
  }

  public static ensureLimit(limit?: number | null): number {
    return Math.max(1, limit || TimescapeDataFilter.defaultLimit);
  }

  public clone() {
    return new TimescapeDataFilter(this.filters.clone(true))
      .setLimit(this.limit)
      .setLastSeen(this.lastSeen)
      .setOrder(this.order);
  }

  public equals(rhs: TimescapeDataFilter): boolean {
    const fieldsEqual =
      this.limit === rhs.limit &&
      this.order === rhs.order &&
      this.lastSeen?.id === rhs.lastSeen?.id &&
      this.lastSeen?.timestamp === rhs.lastSeen?.timestamp;

    const filtersEqual =
      !!this.filters === !!rhs.filters &&
      (this.filters != null && rhs.filters != null ? this.filters.equals(rhs.filters) : true);

    return fieldsEqual && filtersEqual;
  }

  public setLimit(limit?: number | null): this {
    this.limit = TimescapeDataFilter.ensureLimit(limit);
    return this;
  }

  public setOrder(order?: Order | null): this {
    this.order = order ?? Order.Descending;
    return this;
  }

  public setLastSeen(lastSeen?: LastSeen | null): this {
    this.lastSeen = lastSeen;
    return this;
  }

  public setFilters(filters: Filters): this {
    this.filters = filters.clone(true);
    return this;
  }
}
