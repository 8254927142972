import React, { useCallback, useMemo } from 'react';

import { Button, Classes, Menu, MenuItem, Popover } from '@blueprintjs/core';
import classnames from 'classnames';
import { observer } from 'mobx-react';

import allIcon from '~/assets/icons/rule-kind-all-icon.png';
import cidrIcon from '~/assets/icons/rule-kind-cidr-icon.png';
import fqdnIcon from '~/assets/icons/rule-kind-fqdn-icon.png';
import hostIcon from '~/assets/icons/rule-kind-host-icon.png';
import namespaceIcon from '~/assets/icons/rule-kind-namespace-icon.png';
import podIcon from '~/assets/icons/rule-kind-pod-selector-icon.png';
import remoteNodeIcon from '~/assets/icons/rule-kind-remote-node-icon.png';
import serviceIcon from '~/assets/icons/rule-kind-service-icon.png';
import { PolicyCard } from '~/domain/cimulator/cards';
import { PolicyKind } from '~/domain/cimulator/types';
import { useStore } from '~/store/stores/cimulator';
import { usePopover } from '~/ui/hooks/usePopover';

import { CNPRuleKindsByCard, KNPRuleKindsByCard, RuleKind, RuleKindLabels } from './general';
import css from './RuleKindSelector.scss';

const icons: Partial<{ [key in RuleKind]: string }> = {
  [RuleKind.All]: allIcon,
  [RuleKind.Cidr]: cidrIcon,
  [RuleKind.Fqdn]: fqdnIcon,
  [RuleKind.NamespaceSelector]: namespaceIcon,
  [RuleKind.PodSelector]: podIcon,
  [RuleKind.Host]: hostIcon,
  [RuleKind.KubeApiserver]: hostIcon,
  [RuleKind.RemoteNode]: remoteNodeIcon,
  [RuleKind.Service]: serviceIcon,
};

export interface Props {
  card: PolicyCard;
  value?: RuleKind | null;
  disabled?: boolean;
  expanded?: boolean;
  onChange?: (kind: RuleKind | null) => void;
}

export const RuleKindSelector = observer(function RuleKindSelector(props: Props) {
  const store = useStore();
  const popover = usePopover();

  const items = useMemo(() => {
    if (store.policy.policyKind === PolicyKind.CNP) {
      return CNPRuleKindsByCard;
    } else {
      return KNPRuleKindsByCard;
    }
  }, [store.policy.policyKind]);

  const onChange = useCallback(
    (kind: RuleKind) => {
      props.onChange?.(kind);
      popover.close();
    },
    [props.onChange, popover],
  );

  const onPopoverClick = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();
      popover.toggle();
    },
    [popover],
  );

  const menuClassName = classnames(css.menu, {
    [css.fill]: !props.expanded,
    [Classes.ELEVATION_1]: !props.expanded,
  });

  const menu = (
    <Menu className={menuClassName}>
      {items[props.card.side][props.card.kind].map(kind => {
        return (
          <KindMenuItem
            key={kind}
            kind={kind}
            card={props.card}
            active={kind === props.value}
            onClick={onChange}
          />
        );
      })}
    </Menu>
  );

  if (props.expanded) {
    return props.disabled ? null : menu;
  }

  return (
    <Popover {...popover.props} minimal content={menu} targetTagName="div" position="bottom" fill>
      <Button
        fill={true}
        disabled={props.disabled}
        text={getKindTitle(props.card, props.value)}
        alignText="left"
        rightIcon={props.disabled ? null : 'double-caret-vertical'}
        onClick={onPopoverClick}
      />
    </Popover>
  );
});

interface KindMenuItemProps {
  kind: RuleKind;
  card: PolicyCard;
  active?: boolean;
  onClick?: (kind: RuleKind) => void;
}

const KindMenuItem = observer(function KindMenuItem(props: KindMenuItemProps) {
  const store = useStore();

  const onClick = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      event.stopPropagation();
      event.preventDefault();
      props.onClick?.(props.kind);
    },
    [props.onClick, props.kind],
  );

  const example = getKindExample(store.policy.policyKind ?? PolicyKind.KNP, props.kind);
  const text = (
    <div className={css.itemContent}>
      <div className={css.itemTitle}>{getKindTitle(props.card, props.kind)}</div>
      {example && <div className={css.itemExample}>{example}</div>}
    </div>
  );

  return (
    <MenuItem
      multiline
      key={props.kind}
      active={props.active}
      className={css.item}
      text={text}
      onClick={onClick}
      shouldDismissPopover={true}
      icon={<img src={icons[props.kind]} className={css.icon} />}
    />
  );
});

const getKindTitle = (card: PolicyCard, kind?: RuleKind | null) => {
  if (!kind) return 'Select rule type...';

  const dirLabel = card.isIngress ? 'From' : 'To';
  const kindLabel = kind === RuleKind.All ? RuleKindLabels[kind][card.kind] : RuleKindLabels[kind];

  return `${dirLabel} ${kindLabel}`;
};

const getKindExample = (policyKind: PolicyKind, ruleKind: RuleKind | null) => {
  switch (ruleKind) {
    case RuleKind.All:
      return 'With or without specific ports';
    case RuleKind.Cidr:
      return 'Ex.: 10.2.1.0/28';
    case RuleKind.Fqdn:
      return 'Ex.: *.cilium.io';
    case RuleKind.NamespaceSelector:
      return policyKind === PolicyKind.CNP ? `Ex.: namespace, env=prod` : `Ex.: team=one, env=prod`;
    case RuleKind.PodSelector:
      return policyKind === PolicyKind.CNP
        ? `Ex.: env=prod, app=frontend`
        : `Ex.: app=frontend, version=2`;
    case RuleKind.Service:
      return `Ex.: namespace, app=frontend`;
    case RuleKind.RemoteNode:
      return 'Any node (except local host) and their containers in host networking mode in any of the connected clusters';
    case RuleKind.Host:
      return 'The local host and containers running in host networking mode on the local host';
    case RuleKind.KubeApiserver:
      return 'Canonical way to match k8s API server';
    default:
      return null;
  }
};
