import { Application } from '~/domain/common';
import { Router } from '~/router';
import { Store } from '~/store';

import { Options } from './common';

export class ErrorPage {
  private router: Router;
  private store: Store;

  constructor(opts: Options) {
    this.router = opts.router;
    this.store = opts.store;

    this.setupEventHandlers();
  }

  private setupEventHandlers() {
    this.router.onLocationUpdated(() => {
      if (this.router.getCurrentApplication() !== Application.ErrorPage) return;
      if (this.store.uiSettings.ssrError != null) return;

      this.router.openApplication(Application.Dashboard).commit();
    });
  }
}
