import { DataLayer } from '~/data-layer';
import { Environment } from '~/environment';
import { Router } from '~/router';
import { Store } from '~/store';
import { UILayer } from '~/ui-layer';

import { Application } from './application';

// NOTE: This fn type will be like (env: Environment, s: Store) => T;
export type BuilderFn<T, D extends Array<any> = []> = (...args: D) => T;
export type RenderFn<R> = (e: Element, app: Application) => R;

export class ApplicationBuilder<R = any> {
  private dataLayer?: BuilderFn<DataLayer, [Environment, Store]>;
  private uiLayer?: BuilderFn<UILayer, [Store, Router, DataLayer]>;
  private renderFn?: RenderFn<R>;
  private store?: BuilderFn<Store>;
  private router?: BuilderFn<Router, [Environment, DataLayer]>;

  public static new() {
    return new ApplicationBuilder();
  }

  public withStore(fn?: BuilderFn<Store>): this {
    this.store = fn;
    return this;
  }

  public withRouter(fn?: BuilderFn<Router, [Environment, DataLayer]>): this {
    this.router = fn;
    return this;
  }

  public withDataLayer(fn?: BuilderFn<DataLayer, [Environment, Store]>): this {
    this.dataLayer = fn;
    return this;
  }

  public withUILayer(fn?: BuilderFn<UILayer, [Store, Router, DataLayer]>): this {
    this.uiLayer = fn;
    return this;
  }

  // NOTE: This typing is needed to properly call testing `render` function
  public withRenderFunction<RR>(fn?: RenderFn<RR>): ApplicationBuilder<RR> {
    this.renderFn = fn as any;
    return this as any as ApplicationBuilder<RR>;
  }

  public build() {
    const env = Environment.new();

    const store = this.store?.();
    if (store == null) throw this.err('Store');

    const dataLayer = this.dataLayer?.(env, store);
    if (dataLayer == null) throw this.err('DataLayer');

    const router = this.router?.(env, dataLayer);
    if (router == null) throw this.err('Router');

    const uiLayer = this.uiLayer?.(store, router, dataLayer);
    if (uiLayer == null) throw this.err('UILayer');

    if (this.renderFn == null) throw this.err('RenderFn');

    return new Application(env, router, store, dataLayer, uiLayer, this.renderFn);
  }

  private err(comp: string): Error {
    return new Error(`Failed to build Application: ${comp} is not set`);
  }
}
