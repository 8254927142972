import { makeAutoObservable, reaction } from 'mobx';
import { customAlphabet } from 'nanoid';

import { PolicyCard } from '~/domain/cimulator/cards';
import { PolicyEndpoint } from '~/domain/cimulator/endpoint';
import { PolicyKind } from '~/domain/cimulator/types';
import { QueryParams, URLUtils } from '~/domain/url';
import * as storage from '~/storage/local';

export default class ControlStore {
  policyKind: PolicyKind = PolicyKind.CNP;
  tutorial = 'main';
  selectedCardId: string | null = null;
  selectedEndpointId: string | null = null;
  showMultiPolicy = false;
  policyUuid: string | null = null;
  policyVersionShortId: string | null = null;
  skipOnboardingDialog = false;
  skipDownloadPolicyDialog = false;
  skipOnBeforeUnload = false;
  policySharingInProgress = false;

  static policyIdGenerator = customAlphabet(
    '1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ',
    16,
  );

  constructor() {
    makeAutoObservable(this);

    this.skipOnboardingDialog = storage.getSkipOnboardingDialog();
    reaction(() => this.skipOnboardingDialog, storage.saveSkipOnboardingDialog);

    this.skipDownloadPolicyDialog = storage.getSkipDownloadPolicyDialog();
    reaction(() => this.skipDownloadPolicyDialog, storage.saveSkipDownloadPolicyDialog);

    this.skipOnBeforeUnload = storage.getSkipOnBeforeUnload();
    reaction(() => this.skipOnBeforeUnload, storage.saveSkipOnBeforeUnload);

    this.policyUuid = URLUtils.getQueryParam(QueryParams.PolicyUuid);

    this.initTutorial();
  }

  setPolicySharingInProgress = (state: boolean) => {
    this.policySharingInProgress = state;
  };

  setPolicyKind = (kind: PolicyKind) => {
    this.policyKind = kind;
  };

  setTutorial = (tutorial: string) => {
    this.tutorial = tutorial;
  };

  selectCardId = (cardId: string | null) => {
    this.selectedCardId = cardId;
  };

  selectEndpointId = (endpointId: string | null) => {
    this.selectedEndpointId = endpointId;
  };

  toggleShowMultiPolicy = () => {
    this.showMultiPolicy = !this.showMultiPolicy;
  };

  selectElement = (card?: PolicyCard, endpoint?: PolicyEndpoint) => {
    if (!card) {
      return this.selectCardId(null), this.selectEndpointId(null);
    }
    if (card.isSelector) {
      return this.selectCardId(card.id), this.selectEndpointId(null);
    }
    if (endpoint) {
      return this.selectCardId(card.id), this.selectEndpointId(endpoint.id);
    }
    return this.selectCardId(null), this.selectEndpointId(null);
  };

  setPolicyUuid = (uuid: string | null) => {
    this.policyUuid = uuid;
  };

  setPolicyVersionShortId = (id: string | null) => {
    this.policyVersionShortId = id;
  };

  reset = () => {
    this.tutorial = 'main';
    this.policyKind = PolicyKind.CNP;
    this.selectedCardId = null;
    this.selectedEndpointId = null;
  };

  private initTutorial() {
    const url = URLUtils.getQueryParam(QueryParams.PolicyTutorial);
    if (typeof url === 'string') {
      this.tutorial = url;
    }
  }
}
