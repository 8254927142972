import React from 'react';

import classNames from 'classnames';
import { observer } from 'mobx-react';

import { Table } from '~/components/Table';
import { ProcessEvent } from '~/domain/process-events';
import { sizes } from '~/ui';

import { ProcessEventRow } from './ProcessEventRow';
import css from './styles.scss';

export interface Props {
  processEvents: ProcessEvent[];

  children?: React.ReactNode;
  className?: string;
  headerClassName?: string;
  listHeight?: number;

  onRowClick?: (evt: ProcessEvent, idx: number) => void;
}

export const ProcessEventsTable = observer(function ProcessEventsTable(props: Props) {
  return (
    <div className={classNames(css.processEventsTable, props.className)}>
      <Table
        data={props.processEvents}
        visibleColumns={['nodeName', 'time']}
        itemSize={sizes.processEventRowHeight}
        itemKey={idx => `${idx}`}
        listHeight={props.listHeight}
        headerClassName={props.headerClassName}
        headerHeight={sizes.processEventRowHeight}
        headerRenderer={() => {
          return <ProcessEventRow isHeader={true} />;
        }}
        itemRenderer={({ index, style, isScrolling }) => {
          const evt = props.processEvents[index];

          return (
            <ProcessEventRow
              processEvent={evt}
              style={style}
              isScrolling={isScrolling}
              onClick={() => props.onRowClick?.(evt, index)}
            />
          );
        }}
      />

      {props.children}
    </div>
  );
});
