import * as mobx from 'mobx';

import { BackendAPI } from '~/api/customprotocol';
import { Status, User } from '~/domain/authorization';
import { FeatureFlags } from '~/domain/features';
import { FiltersDiff } from '~/domain/filtering';
import { AsFlowDigest } from '~/domain/flows/common';
import { ConnectionState, DataMode, TransferState } from '~/domain/interactions';
import { setupDebugProp } from '~/domain/misc';
import * as storage from '~/storage/local';
import { Store } from '~/store';
import { SSRError } from '~/ui/ssr';
import { EventEmitter } from '~/utils/emitter';
import { logger } from '~/utils/logger';

import { Cimulator } from './cimulator';
import { Options, StorageParameters } from './common';
import { Controls } from './controls';
import { InjectionReader, InjectionReaderBuilder } from './injected';
import { ProcessTree } from './process-tree';
import { ServiceMap } from './service-map';

export enum Event {
  Unauthorized = 'unauthorized',
  FeatureFlagsSet = 'feature-flags-set',
  SSRErrorSet = 'ssr-error-set',
}

export type Handlers = {
  [Event.Unauthorized]: (u: User | null | undefined) => void;
  [Event.FeatureFlagsSet]: (ff: FeatureFlags) => void;
  [Event.SSRErrorSet]: (err: SSRError | null) => void;
};

export class DataLayer extends EventEmitter<Handlers> {
  public readonly controls: Controls;
  public readonly serviceMap: ServiceMap;
  public readonly processTree: ProcessTree;
  public readonly cimulator: Cimulator;
  public readonly transferState: TransferState;

  constructor(
    private store: Store,
    private ffTemplateSelector: string,
    private authzTemplateSelector: string,
    private ssrErrorsTemplateSelector: string,
    private backendAPI: BackendAPI,
  ) {
    super(true);

    this.transferState = new TransferState();
    this.controls = new Controls(this.commonOpts);
    this.serviceMap = new ServiceMap(this.commonOpts);
    this.processTree = new ProcessTree(this.commonOpts);
    this.cimulator = new Cimulator(this.commonOpts);

    this.setupEventHandlers();
    this.setupDebugProps();

    mobx.makeObservable(this);
  }

  private get commonOpts(): Options {
    return {
      store: this.store,
      backendAPI: this.backendAPI,
      transferState: this.transferState,
      dataLayerControls: this.controls,
    };
  }

  @mobx.computed
  public get flowDigests(): AsFlowDigest[] {
    if (this.transferState.isCiliumStreaming) {
      return this.store.currentFrame.interactions.flows;
    } else if (this.transferState.isWatchingHistory) {
      if (this.store.controls.aggregation == null) {
        return this.store.currentFrame.interactions.flowSummaries;
      } else {
        return this.store.currentFrame.interactions.flows;
      }
    }

    return [];
  }

  public async dropDataFetch() {
    await this.serviceMap.dropDataFetch();
  }

  public async filtersChanged(f: FiltersDiff) {
    await Promise.all([this.serviceMap.filtersChanged(f), this.processTree.filtersChanged(f)]);
  }

  public readLocalStorageParams(): StorageParameters {
    return {
      isHostShown: storage.getShowHost(),
      isKubeDNSShown: storage.getShowKubeDns(),
      isAggregationOff: storage.getIsAggregationOff(),
    };
  }

  public setFeatureFlags(ff: FeatureFlags) {
    this.store.setFeatures(ff);
    this.emit(Event.FeatureFlagsSet, ff);
  }

  public setSSRErrors(err: SSRError | null) {
    this.store.uiSettings.setSSRError(err);
    this.emit(Event.SSRErrorSet, err);
  }

  public injectionReader(): InjectionReader {
    return InjectionReaderBuilder.new()
      .withFeatureFlagsSelector(this.ffTemplateSelector)
      .withAuthorizationSelector(this.authzTemplateSelector)
      .withSSRErrorsSelector(this.ssrErrorsTemplateSelector)
      .build()
      .onFeatureFlags(ff => this.setFeatureFlags(ff))
      .onSSRErrors(err => this.setSSRErrors(err))
      .onAuthorization(authz => {
        logger.log('authz', authz);
        if (authz == null) {
          logger.log('injected authorization is null');
          return;
        }

        this.store.authz.setStatus(authz.isAuthorized ? Status.Authorized : Status.Unauthorized);

        if (authz.isRequired && (!authz.isAuthorized || authz.user == null)) {
          this.emit(Event.Unauthorized, authz.user);
        }

        if (authz.user != null) {
          this.store.authz.setUser(authz.user);
        }
      });
  }

  public onFeatureFlagsSet(fn: Handlers[Event.FeatureFlagsSet]): this {
    this.on(Event.FeatureFlagsSet, fn);
    return this;
  }

  public onUnauthorized(fn: Handlers[Event.Unauthorized]): this {
    this.on(Event.Unauthorized, fn);
    return this;
  }

  public onSSRErrorSet(fn: Handlers[Event.SSRErrorSet]): this {
    this.on(Event.SSRErrorSet, fn);
    return this;
  }

  public async switchToDataMode(dm: DataMode, cs?: ConnectionState) {
    this.transferState.setDataMode(dm);
    if (cs != null) {
      this.transferState.setConnectionState(cs);
      return;
    }

    if (this.transferState.isWatchingHistory) {
      this.transferState.setIdle();
    } else if (this.transferState.isCiliumStreaming) {
      this.transferState.setReceiving();
    }

    await this.serviceMap.switchToDataMode(dm);
  }

  private setupEventHandlers() {
    // NOTE: This event is thrown by ServiceMap data layer when for example
    // card is selected and we need to update flow filters in search bar
    this.serviceMap.onFlowFiltersShouldBeChanged(group => {
      this.controls.setFlowFilterGroups(group);
    });
  }

  private setupDebugProps() {
    setupDebugProp({
      stopFetches: async () => {
        await this.controls.stopFetches();
        await this.serviceMap.dropDataFetch();
      },
    });
  }
}
