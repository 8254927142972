import React, { ReactNode, useMemo, useRef } from 'react';

import * as mobx from 'mobx';
import { computed } from 'mobx';
import { observer } from 'mobx-react';

import { useApplication } from '~/application';
import { ServiceMapArrowsRenderer } from '~/components/ServiceMapArrowsRenderer';
import { ServiceMapArrowDuckFeet } from '~/components/ServiceMapArrowsRenderer/ServiceMapArrowDuckFeet';
import { ServiceMapCard } from '~/components/ServiceMapCard';
import { ServiceCard } from '~/domain/service-map';
import { useMapZoom } from '~/ui/hooks/useMapZoom';
import { useMutationObserver } from '~/ui/hooks/useMutationObserver';
import { sizes } from '~/ui/vars';
import { addMargin } from '~/ui-layer/service-map/coordinates/add-margin';
import { AccessPointArrow } from '~/ui-layer/service-map/coordinates/arrow';
import { getBoundingBox } from '~/ui-layer/service-map/coordinates/get-bounding-box';
import { PlacementEntry } from '~/ui-layer/service-map/coordinates/get-placement';
import { Arrow, XYWH } from '~/ui-layer/service-map/coordinates/types';
import { MapUtils } from '~/utils/iter-tools/map';

import { NamespaceBackplate } from './NamespaceBackplate';
import css from './styles.scss';

export interface MapProps {
  cardsPlacement: PlacementEntry<ServiceCard>[];
  namespace?: string | null;
  namespaceBBox?: XYWH | null;
  visibleHeight: number;
  arrows: Arrow[];
  combinedAccessPointArrows: Map<string, AccessPointArrow[]>;
}

export enum E2E {
  visibleContainer = 'visible-container',
  arrowsForeground = 'arrows-foreground',
}

export const MapElements = observer(function MapElements(props: MapProps) {
  const { store, ui } = useApplication();
  const underlayRef = useRef<SVGGElement | null>(null);
  const overlayRef = useRef<SVGGElement | null>(null);
  const cardsRef = useRef<SVGGElement | null>(null);
  const backgroundsRef = useRef<SVGGElement | null>(null);
  const l7endpoints = store.currentFrame.interactions.l7endpoints;

  const cards = computed(() => {
    const cards: ReactNode[] = [];

    props.cardsPlacement.forEach(cardPlacement => {
      const coords = cardPlacement.geometry;
      const showInfo = store.currentFrame.services.hasOtherCardsWithTheSameCaption(
        cardPlacement.card,
      );

      if (coords != null) {
        cards.push(
          <ServiceMapCard
            card={cardPlacement.card}
            overlayRef={overlayRef}
            underlayRef={underlayRef}
            backgroundsRef={backgroundsRef}
            key={cardPlacement.card.id}
            active={ui.serviceMap.isCardActive(cardPlacement.card)}
            collector={ui.serviceMap.collector}
            className={'map-card'}
            l7endpoints={l7endpoints.forReceiver(cardPlacement.card.id)}
            maxHttpEndpointsVisible={5}
            isClusterMeshed={store.currentFrame.services.isClusterMeshed}
            onHeaderClick={() => {
              ui.serviceMap.onCardSelect(cardPlacement.card);
            }}
            coords={coords}
            onGotoProcessTree={card => ui.serviceMap.openProcessTreeForCard(card)}
            showAdditionalInfo={showInfo}
          />,
        );
      }
    });

    return cards;
  }).get();

  // NOTE: We use only one mutation observer to watch over cards changes and
  // react on that with arrows rebuilding in the end
  useMutationObserver({ ref: cardsRef, options: { all: true } }, () => {
    ui.serviceMap.cardsMutationsObserved();
  });

  return (
    <>
      {props.namespaceBBox && props.namespace && (
        <NamespaceBackplate
          namespace={props.namespace}
          xywh={addMargin(props.namespaceBBox, sizes.namespaceBackplatePadding)}
        />
      )}

      <g className="underlay" ref={underlayRef}></g>
      {props.arrows && (
        <ServiceMapArrowsRenderer arrows={props.arrows} key={'arrows'} overlay={overlayRef} />
      )}

      <g className="backgrounds" ref={backgroundsRef}></g>
      <g className="arrows-foreground" data-testid={E2E.arrowsForeground}>
        {props.combinedAccessPointArrows &&
          MapUtils.new(props.combinedAccessPointArrows).map((connectorId, combinedArrows) => {
            return (
              <ServiceMapArrowDuckFeet
                key={connectorId}
                connectorId={connectorId}
                arrows={[...combinedArrows.values()] as any}
              />
            );
          })}
      </g>
      <g ref={cardsRef} className="visible-cards" data-testid={E2E.visibleContainer}>
        {cards}
      </g>

      <g className="overlay" ref={overlayRef}></g>
    </>
  );
});

const Map = observer(function Map(props: MapProps) {
  const { ui } = useApplication();
  const mapBBox = useMemo(
    () => getBoundingBox([...ui.serviceMap.placement.cardsPlacement.values()].map(e => e.geometry)),
    [ui.serviceMap.placement.cardsPlacement],
  );
  const zoom = useMapZoom({
    mapBBox,
  });

  return (
    <svg ref={zoom.ref} className={css.wrapper} id="service-map">
      <g transform={zoom.transformStr}>
        <MapElements {...props} />
      </g>
    </svg>
  );
});

export interface Props {
  mapVisibleHeight: number;
}

export const LiveMap = observer(function LiveMap(props: Props) {
  const { store, ui } = useApplication();

  const serviceMapCardPlacements = mobx
    .computed(() => [...ui.serviceMap.placement.cardsPlacement.values()])
    .get();

  return (
    <Map
      namespace={store.clusterNamespaces.currNamespace}
      namespaceBBox={ui.serviceMap.placement.namespaceBBox}
      cardsPlacement={serviceMapCardPlacements}
      arrows={[...ui.serviceMap.arrows.arrows.values()]}
      combinedAccessPointArrows={ui.serviceMap.arrows.combinedAccessPointArrows}
      visibleHeight={props.mapVisibleHeight ?? 0}
    />
  );
});
