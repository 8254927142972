import React from 'react';

import classNames from 'classnames';
import { observer } from 'mobx-react';

import { Method, sortMethods } from '~/domain/http';
import { Verdict } from '~/domain/hubble';
import * as trafficIcons from '~/ui/icons/traffic';
import { RefsCollector } from '~/ui/service-map/collector';

import { HTTPEndpointGroup } from './http-groups';
import css from './HttpEndpoint.scss';

export interface Props {
  group: HTTPEndpointGroup;
  collector?: RefsCollector;
}

export const HttpEndpoint = observer(function HttpEndpoint(props: Props) {
  const methods = sortMethods(props.group.methods);
  if (methods.size === 0) return null;

  const methodsVerdicts = props.group.getMethodsVerdicts();

  return (
    <div className={css.httpEndpoint}>
      <div className={css.header}>
        <span className={css.pathname}>{props.group.url.pathname}</span>
      </div>

      <div className={css.connectors}>
        {[...methods].map(method => {
          return (
            <MethodConnector
              key={method}
              method={method}
              verdicts={methodsVerdicts.get(method)}
              connectorRef={props.collector?.httpMethodConnector(props.group.url.pathname, method)}
            />
          );
        })}
      </div>
    </div>
  );
});

interface MethodConnectorProps {
  method: Method;
  verdicts?: Set<Verdict>;
  connectorRef?: React.MutableRefObject<HTMLDivElement | null>;
}

const MethodConnector = function MethodConnector(props: MethodConnectorProps) {
  const v = props.verdicts;
  const isSuccess = v == null || v.size === 0 || (v.size === 1 && v.has(Verdict.Forwarded));
  const isFail = !!v?.has(Verdict.Dropped);

  const classes = classNames(css.connector, {
    [css.success]: isSuccess,
    [css.fail]: isFail,
  });

  return (
    <div className={classes}>
      <div className={css.mockConnector} ref={props.connectorRef}></div>
      <div className={css.connectorIcon}>
        <img src={trafficIcons.iconByVerdicts(props.verdicts)} />
      </div>
      <div className={css.method}>{props.method}</div>
    </div>
  );
};
