export interface PolicyData {
  policyNamespace: string;
  policyName: string;
  type: PolicyDataType;
  yaml: string;
  uid: string;
}

export enum PolicyDataType {
  CiliumNetworkPolicy = 0,
  KubernetesNetworkPolicy = 1,
  CiliumClusterwideNetworkPolicy = 2,
}
