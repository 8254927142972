import React, { useMemo } from 'react';

import { Icon, Tooltip } from '@blueprintjs/core';
import classNames from 'classnames';

import * as authTypeHelpers from '~/domain/helpers/auth-type';
import { AuthType } from '~/domain/hubble';

import css from './AuthTypeEntry.scss';

export interface Props {
  authTypes: Set<AuthType>;
}

export function AuthTypeEntry(props: Props) {
  const firstAuthType = useMemo(() => {
    for (const authType of props.authTypes) return authType;
    return null;
  }, [props.authTypes]);

  const tooltip = useMemo(() => {
    switch (firstAuthType) {
      case AuthType.Disbaled:
      case null:
        return '';
      case AuthType.TestAlwaysFail:
        return 'Failed to test for mutual auth';
      default:
        return `Mutual Auth via ${authTypeHelpers.toString(firstAuthType)}`;
    }
  }, [firstAuthType]);

  const cls = classNames(css.icon, {
    [css.danger]: firstAuthType === AuthType.TestAlwaysFail,
  });

  if (!firstAuthType) return null;

  return (
    <Tooltip content={tooltip} position="top" minimal>
      <Icon icon="lock" size={21} className={cls} />
    </Tooltip>
  );
}
