import React from 'react';

import classNames from 'classnames';
import { observer } from 'mobx-react';

import { useRefsCollector } from '~/components/ProcessTree/RefsCollectorContext';
import { SimilarProcessesSubTree } from '~/components/ProcessTree/SimilarProcessesSubTree';
import { TrafficIcon } from '~/components/ProcessTree/TrafficIcon';
import * as psUtils from '~/components/ProcessTree/utils';
import { Intent } from '~/components/widgets/common';
import { XY } from '~/domain/geometry';
import { Kind as EventKind } from '~/domain/process-events';
import { getBinaryName } from '~/domain/process-tree/helpers';
import { Processes } from '~/domain/process-tree/processes';
import { applyPrefix } from '~/utils';

import css from './styles.scss';

export interface Props {
  rootExecId: string | null;
  podHandleId: string;
  processes: Processes;
  isDarkThemeEnabled: boolean;

  insideContainer?: boolean;
  containerHeadlineRendered?: boolean;
  containerStartTime?: Date;
  childGroupStates?: Map<string, boolean>;
  iconsPrefix?: string;

  onEgressConnectorCoordsEmit?: (execId: string, xy: XY) => void;
  onChildGroupStateToggle?: (groupKey: string) => void;
}

export const ProcessesSubTree = observer(function ProcessesSubTree(props: Props) {
  const { rootExecId, processes: ps, childGroupStates } = props;
  const isEmptyTree = rootExecId == null || !ps.processes.has(rootExecId);

  const rootProcess = ps.processes.get(rootExecId!)!;
  const childGroups = ps.childrenGroups.get(rootExecId!);

  const binaryName = rootProcess ? getBinaryName(rootProcess.binary) : '';
  const flagsValue = rootProcess?.arguments ?? '';

  const namespacedPid = ps.namespacePids.get(rootExecId!);
  const isNamespacedInit = namespacedPid === 1;
  const isContainerStart = !props.containerHeadlineRendered && rootProcess?.pod?.container != null;

  const containerName = rootProcess?.pod?.container.name;

  let [startTimeDiff, isLongTimeDiff] = ['', false];
  if (
    props.containerStartTime != null &&
    props.insideContainer &&
    rootProcess?.start_time != null
  ) {
    [startTimeDiff, isLongTimeDiff] = psUtils.startTimeDiff(
      rootProcess?.start_time,
      props.containerStartTime,
    );
  }

  const [firstPidTitle, secondPidTitle] = (() => {
    let firstPidTitle = `${rootProcess?.pid}`;
    let secondPidTitle = '';

    if (namespacedPid != null) {
      firstPidTitle = `Namespace PID: ${namespacedPid}`;
      secondPidTitle = `Host PID: ${rootProcess?.pid}`;
    }

    return [firstPidTitle, secondPidTitle];
  })();

  const collector = useRefsCollector();

  const classes = classNames(css.processesSubTree, {
    [css.insideContainer]: !!props.insideContainer,
    [css.namespacedInit]: !!isNamespacedInit,
    [css.suspicious]: isLongTimeDiff,
  });

  return isEmptyTree ? null : (
    <div className={classes}>
      {!isContainerStart && (
        <div className={css.rootHandle} title={`${rootProcess.pid}`}>
          <div
            className={css.connectorCircle}
            ref={collector.processLineRootRef(props.podHandleId, rootExecId)}
          />

          <div className={css.processData}>
            {props.containerStartTime != null && (
              <span className={css.timeDiff}>{startTimeDiff}</span>
            )}
            <span className={css.pids}>
              <span className={css.pid} title={firstPidTitle}>
                {namespacedPid ?? rootProcess.pid}
              </span>
              {namespacedPid != null && (
                <span className={css.hostPid} title={secondPidTitle}>
                  ({rootProcess.pid})
                </span>
              )}
            </span>
            <div className={css.executable} title={rootProcess.binary}>
              {binaryName}
            </div>
            <div className={css.flags} title={flagsValue}>
              {flagsValue}
            </div>

            {ps.hasEventsOfKind(rootExecId, EventKind.Connect) && (
              <div className={css.trafficConnector}>
                <TrafficIcon
                  containerRef={collector.egressConnectorRef(props.podHandleId, rootExecId)}
                  className={css.icon}
                  iconsPrefix={props.iconsPrefix}
                  isDarkThemeEnabled={props.isDarkThemeEnabled}
                  intent={
                    isLongTimeDiff ? Intent.Danger : isNamespacedInit ? Intent.Primary : Intent.None
                  }
                />
              </div>
            )}

            {!props.insideContainer && (
              <div className={css.hostIcon} title="This process is running not in container">
                <img src={applyPrefix('/icons/processes/host-icon.png', props.iconsPrefix)} />
              </div>
            )}
          </div>
        </div>
      )}

      {isContainerStart && (
        <div className={css.rootHandle} title={containerName}>
          <div
            className={css.containerIcon}
            ref={collector.containerRootRef(props.podHandleId, rootExecId)}
          >
            <img src={applyPrefix('/icons/processes/container-icon.png', props.iconsPrefix)} />
          </div>

          <div className={css.containerData}>
            <div className={css.startTime}>{psUtils.startTimeFmt(rootProcess.start_time)}</div>

            <div className={css.containerName} title={containerName}>
              {containerName}
            </div>
          </div>
        </div>
      )}

      {childGroups != null && !isContainerStart && (
        <div className={css.subTree}>
          {[...childGroups].map(([groupKey, group]) => {
            if (group.execIds.size === 1) {
              const execId = [...group.execIds][0];
              if (execId === props.rootExecId) return null;

              return (
                <ProcessesSubTree
                  key={execId}
                  rootExecId={execId}
                  podHandleId={props.podHandleId}
                  processes={props.processes}
                  iconsPrefix={props.iconsPrefix}
                  childGroupStates={childGroupStates}
                  insideContainer={props.insideContainer}
                  containerHeadlineRendered={props.containerHeadlineRendered}
                  containerStartTime={rootProcess.pod?.container?.start_time}
                  onChildGroupStateToggle={props.onChildGroupStateToggle}
                  isDarkThemeEnabled={props.isDarkThemeEnabled}
                />
              );
            }

            return (
              <SimilarProcessesSubTree
                iconsPrefix={props.iconsPrefix}
                key={groupKey}
                group={group}
                processes={props.processes}
                podHandleId={props.podHandleId}
                insideContainer={props.insideContainer}
                childGroupStates={childGroupStates}
                suspicious={!!isLongTimeDiff}
                containerHeadlineRendered={props.containerHeadlineRendered}
                containerStartTime={rootProcess.pod?.container?.start_time}
                isDarkThemeEnabled={props.isDarkThemeEnabled}
                onToggle={() => {
                  props.onChildGroupStateToggle?.(groupKey);
                }}
              >
                {[...group.execIds].sort().map(execId => {
                  return (
                    <ProcessesSubTree
                      iconsPrefix={props.iconsPrefix}
                      key={execId}
                      rootExecId={execId}
                      podHandleId={props.podHandleId}
                      processes={props.processes}
                      childGroupStates={childGroupStates}
                      insideContainer={props.insideContainer}
                      containerHeadlineRendered={props.containerHeadlineRendered}
                      containerStartTime={rootProcess.pod?.container?.start_time}
                      onChildGroupStateToggle={props.onChildGroupStateToggle}
                      isDarkThemeEnabled={props.isDarkThemeEnabled}
                    />
                  );
                })}
              </SimilarProcessesSubTree>
            );
          })}
        </div>
      )}

      {isContainerStart && (
        <div className={css.subTree}>
          <ProcessesSubTree
            key={rootExecId}
            rootExecId={rootExecId}
            podHandleId={props.podHandleId}
            iconsPrefix={props.iconsPrefix}
            processes={props.processes}
            childGroupStates={childGroupStates}
            containerHeadlineRendered={true}
            insideContainer={true}
            containerStartTime={rootProcess.pod?.container?.start_time}
            onChildGroupStateToggle={props.onChildGroupStateToggle}
            isDarkThemeEnabled={props.isDarkThemeEnabled}
          />
        </div>
      )}
    </div>
  );
});
