import * as dhelpers from '~/domain/helpers';
import { Labels } from '~/domain/labels';
import { Service, ServiceCard } from '~/domain/service-map';

import { FilterGroup } from './filter-entry';
import { Filters } from './filters';

export const filterService = (svc: ServiceCard, filters: Filters): boolean => {
  if (filters.skipHost && svc.isHost) return false;
  if (filters.skipRemoteNode && svc.isRemoteNode) return false;
  if (filters.skipPrometheusApp && svc.isPrometheusApp) return false;
  return true;
};

export const filterServiceByGroup = (service: Service, group: FilterGroup): boolean => {
  return group.entries.every(e => {
    if (e.isIdentity) return service.identity.toString() === e.query;

    if (e.isIp || e.isPort || e.isTCPFlag || e.isProtocol) {
      return e.negative;
    }

    let pass = true;

    if (e.isLabel) {
      pass = !!Labels.findKVByString(service.labels, e.query);
    }

    if (e.isDNS) {
      pass = service.dnsNames.includes(e.query) || service.id === e.query;
    }

    if (e.isWorkload) {
      const workload = e.asWorkload();
      if (workload != null) {
        pass = dhelpers.workload.includes(service.workloads, workload);
      }
    }

    return e.negative ? e.negative !== pass : pass;
  });
};
