import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Icon, Popover, Tag } from '@blueprintjs/core';
import classnames from 'classnames';
import { observer } from 'mobx-react';

import mapcss from '~/components/CimulatorMap/styles.scss';
import { PolicyCard } from '~/domain/cimulator/cards';
import { PolicyEndpoint } from '~/domain/cimulator/endpoint';
import { PolicyKind } from '~/domain/cimulator/types';
import { XY } from '~/domain/geometry';
import { AssistantEmitterActions, emitter } from '~/store/stores/assistant/emitter';
import { useStore } from '~/store/stores/cimulator';
import { usePopover } from '~/ui/hooks/usePopover';
import { AnalyticsTrackKind, track } from '~/utils/analytics';

import { CrudPopover } from './CrudPopover';
import css from './styles.scss';

interface Props {
  card: PolicyCard;
  endpoint: PolicyEndpoint;
  active?: boolean;
  onArrowPoint?: (pt: XY) => void;
  onSelectElement?: (card?: PolicyCard, endpoint?: PolicyEndpoint) => void;
}

export const EndpointItem = observer(function EndpointItem(props: Props) {
  const divRef = useRef<HTMLDivElement>(null);

  const store = useStore();

  const [isPopoverHoverDisabled, setIsPopoverHoverDisabled] = useState(false);
  const popover = usePopover({
    popoverProps: {
      onOpened: useCallback(() => {
        track(AnalyticsTrackKind.PolicyRuleCRUD, {
          action: 'open-endpoint',
          cardSide: props.card.side,
          cardKind: props.card.kind,
          endpointKind: props.endpoint.kind,
        });
      }, [props.card.side, props.card.kind, props.endpoint.kind]),
    },
  });

  useEffect(() => {
    // reset endpoint on map click
    const listener = (event: MouseEvent) => {
      const element = event.target as HTMLElement;
      if (!('tagName' in element) || !('className' in element)) return;
      if (element.tagName.toLowerCase() !== 'svg' || !element.classList.contains(mapcss.wrapper)) {
        return;
      }
      setIsPopoverHoverDisabled(false);
      props.onSelectElement?.();
    };
    document.addEventListener('click', listener);
    return () => document.removeEventListener('click', listener);
  }, [props.onSelectElement]);

  useEffect(() => {
    return emitter.on(
      AssistantEmitterActions.OpenCardEndpointPopup,
      (card: PolicyCard, endpoint: PolicyEndpoint) => {
        if (card !== props.card || endpoint !== props.endpoint) {
          return popover.close();
        }
        return popover.open();
      },
    );
  }, [props.card, props.endpoint, popover]);

  useEffect(() => {
    return emitter.on(AssistantEmitterActions.CloseCardEndpointPopup, () => {
      return popover.close();
    });
  }, [props.card, props.endpoint, popover]);

  const isUnsupported = useMemo(() => {
    if (!store.controls.policyKind) return false;
    return props.card.getUnsupportedReasonInfo(store.controls.policyKind, props.endpoint);
  }, [props.card, props.endpoint, store.controls.policyKind]);

  const onClick = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      popover.open();
      props.onSelectElement?.(props.card, props.endpoint);
      setIsPopoverHoverDisabled(true);
    },
    [props.onSelectElement, props.card, props.endpoint],
  );

  const onPopoverInteraction = useCallback(
    (nextOpenState: boolean) => {
      if (isPopoverHoverDisabled) return;
      nextOpenState ? popover.open() : popover.close();
      props.onSelectElement?.(
        nextOpenState ? props.card : undefined,
        nextOpenState ? props.endpoint : undefined,
      );
    },
    [isPopoverHoverDisabled, props.onSelectElement, props.card, props.endpoint],
  );

  const onCrudInteraction = useCallback(() => {
    popover.open();
    setIsPopoverHoverDisabled(true);
  }, []);

  const showDnsProxy = useMemo(() => {
    if (!props.endpoint.isKubeDns) return false;
    if (!store.policy.contextual.isKubeDnsAllowed) return false;
    if (store.controls.policyKind === PolicyKind.KNP) return false;
    if (!store.policy.contextual.hasEgressFqdnOutsideCluster) return false;
    return true;
  }, [
    props.endpoint.isKubeDns,
    store.policy.contextual.isKubeDnsAllowed,
    store.controls.policyKind,
    store.policy.contextual.hasEgressFqdnOutsideCluster,
  ]);

  useEffect(() => {
    if (!divRef.current || !props.onArrowPoint) return;
    const bbox = divRef.current.getBoundingClientRect();
    props.onArrowPoint({ x: bbox.x, y: bbox.y + bbox.height / 2 });
  }, [props.onArrowPoint, divRef.current]);

  const className = classnames(css.endpointItem, {
    [css.active]: props.active,
    [css.notsupported]: isUnsupported,
  });

  const onCrudDone = useCallback(
    (card?: PolicyCard, endpoint?: PolicyEndpoint) => {
      setIsPopoverHoverDisabled(true);
      props.onSelectElement?.(card, endpoint);
      popover.close();
    },
    [props.onSelectElement],
  );

  const onPopoverClosed = useCallback(() => {
    setIsPopoverHoverDisabled(false);
  }, []);

  return (
    <Popover
      {...popover.props}
      onInteraction={onPopoverInteraction}
      interactionKind={isPopoverHoverDisabled ? 'click' : 'hover'}
      onClosed={onPopoverClosed}
      position={props.card.isEgress ? 'left' : 'right'}
      minimal
      content={
        <CrudPopover
          card={props.card}
          endpoint={props.endpoint}
          onDone={onCrudDone}
          onInteraction={onCrudInteraction}
        />
      }
      targetTagName="div"
      hoverCloseDelay={100}
      hoverOpenDelay={100}
      hasBackdrop={isPopoverHoverDisabled}
    >
      <div className={className} ref={divRef} onClick={onClick}>
        <div className={css.endpointItemContent}>
          {props.endpoint.getCaption({ cardInfo: props.card.generalInfo })}
          {props.endpoint.exceptCidrsStr && (
            <Icon icon="remove" className={css.cidrExceptIcon} iconSize={14} />
          )}
        </div>
        {props.endpoint.portsStr.length > 0 && !props.endpoint.isKubeDns && (
          <Ports card={props.card} endpoint={props.endpoint} />
        )}
        {props.endpoint.isKubeDns &&
          !showDnsProxy &&
          (store.policy.contextual.defaultDenyEgress || store.policy.contextual.hasSomeEgress) &&
          !store.policy.contextual.isKubeDnsAllowed && <AllowDNSLabel />}
        {showDnsProxy && (
          <DNSProxyLabel
            isDNSProxyEnabled={store.policy.contextual.isDNSProxyEnabled}
            policyKind={store.controls.policyKind ?? PolicyKind.KNP}
          />
        )}
      </div>
    </Popover>
  );
});

const AllowDNSLabel = memo(function AllowDNSLabel() {
  return (
    <Tag intent="warning" className={css.l7label}>
      Allow DNS
    </Tag>
  );
});

const DNSProxyLabel = memo<{
  isDNSProxyEnabled: boolean;
  policyKind: PolicyKind;
}>(function DNSProxyLabel(props) {
  const enabled = props.policyKind === PolicyKind.CNP && props.isDNSProxyEnabled;
  const intent = enabled ? 'primary' : 'warning';

  return (
    <Tag intent={intent} className={css.l7label}>
      {enabled ? 'DNS proxy on' : 'DNS proxy off'}
    </Tag>
  );
});

const Ports = memo<{ card: PolicyCard; endpoint: PolicyEndpoint }>(function Ports(props) {
  return props.card.isIngress ? (
    <Tag minimal className={css.port}>
      → {props.endpoint.portsStrWithColons}
    </Tag>
  ) : (
    <Tag minimal className={css.port}>
      {props.endpoint.portsStrWithColons}
    </Tag>
  );
});
