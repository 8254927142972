import React, { useCallback } from 'react';

import { Button, Checkbox, Classes, Dialog, Icon } from '@blueprintjs/core';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';

import minikubeLogo from '~/assets/icons/minikube-logo.png';
import { useStore as usePrimaryStore } from '~/store';
import { useStore } from '~/store/stores/cimulator';

import css from './styles.scss';

export interface Props {
  isOpen: boolean;
  onClose?: () => void;
  containerRef?: HTMLElement;
}

export const DownloadDialog = observer(function DownloadDialog(props: Props) {
  const store = useStore();

  // TODO: Improve
  const primaryStore = usePrimaryStore();
  const themeStore = primaryStore.themes;
  const isDarkThemeActive = themeStore.isDarkTheme;
  const bpThemeClassName = isDarkThemeActive ? 'bp5-dark' : '';

  const toggleSkip = useCallback(() => {
    runInAction(() => {
      store.controls.skipDownloadPolicyDialog = !store.controls.skipDownloadPolicyDialog;
    });
  }, [store.controls.skipDownloadPolicyDialog]);

  const policyName = store.policy.policyName || 'policy';

  const getStartedLink = (
    <a
      href="https://docs.cilium.io/en/v1.14/gettingstarted/k8s-install-default/"
      target="_blank"
      rel="noreferrer"
    >
      Getting Started with Minikube
    </a>
  );

  const minikubeImg = (
    <img
      src={minikubeLogo}
      style={{
        width: '27.73px',
        height: '27px',
        marginRight: '8.27px',
      }}
    />
  );

  return (
    <Dialog
      canEscapeKeyClose={true}
      canOutsideClickClose={true}
      isOpen={props.isOpen}
      onClose={props.onClose}
      portalContainer={props.containerRef}
      className={bpThemeClassName}
    >
      <div className={`${Classes.DIALOG_BODY} ${css.downloadDialog}`}>
        <div style={{ textAlign: 'center', marginBottom: '25px' }}>
          <Icon icon="tick-circle" iconSize={70} intent="success" />
        </div>
        <h1 className={css.title}>The policy has been downloaded</h1>
        <div>
          <h3 className={css.tryItTitle}>
            {minikubeImg} <span>Try it with Minikube</span>
          </h3>
          <div>
            Using Minikube, try the network policy. See {getStartedLink} for setup guidance.
          </div>
          <pre className={css.code}>
            {`# requires minikube >= v1.12.1. \nminikube start --cni=cilium --memory=4096\nkubectl apply -f ${policyName}.yaml`}
          </pre>
        </div>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS} style={{ alignItems: 'center' }}>
          <Checkbox
            label="Do not show me this again"
            checked={store.controls.skipDownloadPolicyDialog}
            onChange={toggleSkip}
          />
          <Button onClick={props.onClose}>Close</Button>
        </div>
      </div>
    </Dialog>
  );
});
