import React, { useMemo, useRef } from 'react';

import { XY } from '~/domain/geometry';
import { ElemCoords, useElemCoords } from '~/ui/hooks';
import { unionRef } from '~/ui/react';

export type Props = {
  coords: XY;
  className?: string;
  children: React.ReactNode;
  forwardRef: React.MutableRefObject<HTMLDivElement | null>;

  onCoords?: (_: ElemCoords) => void;
};

export const AbsoluteDiv = function AbsoluteDiv(props: Props) {
  const rootDiv = useRef<HTMLDivElement | null>(null);
  const ref = unionRef(rootDiv, props.forwardRef);

  useElemCoords(rootDiv, false, coords => {
    props.onCoords?.(coords);
  });

  const divStyle = useMemo((): React.CSSProperties => {
    return {
      position: 'absolute',
      left: `${props.coords.x}px`,
      top: `${props.coords.y}px`,
    };
  }, [props.coords]);

  return (
    <div ref={ref} style={divStyle}>
      {props.children}
    </div>
  );
};
