import React from 'react';

import css from './styles.scss';

export type Props = {
  caption?: string;
};

export const LoadingDots = function LoadingDots(props: Props) {
  return (
    <div className={css.loadingDots}>
      {props.caption != null && <span>{props.caption}</span>}

      <span className={css.animatedDots}>
        <span>.</span>
        <span>.</span>
        <span>.</span>
      </span>
    </div>
  );
};
