import { MutableRefObject as MutRef } from 'react';

import _ from 'lodash';

import css from '~/components/ProcessTree/styles.scss';
import { XYWH } from '~/domain/geometry/xywh';
import { getIntersectBBoxAsync } from '~/ui/hooks/useIntersectCoords';
import { reactionRef } from '~/ui/react/refs';
import { EventEmitter } from '~/utils/emitter';
import { logger } from '~/utils/logger';

type IdRefMap<E = HTMLDivElement> = Map<string, MutRef<E | null>>;

// NOTE: { podHandleId -> { execId -> Ref }}
type IdRefMap2<E = HTMLDivElement> = Map<string, IdRefMap<E>>;

// NOtE: { groupName -> { port -> { ip -> Ref }}
type IdRefMap3<E = HTMLDivElement> = Map<string, IdRefMap2<E>>;

type ReactionRefCb<E = HTMLDivElement> = Parameters<typeof reactionRef<E>>[1];

type PodHandleConnectorCoords = {
  bbox: XYWH;
  podHandleId: string;
};

type ProcessLineConnectorCoords = {
  bbox: XYWH;
  podHandleId: string;
  execId: string;
};

type SimilarChildsConnectorCoords = {
  bbox: XYWH;
  podHandleId: string;
  groupKey: string;
};

type DestinationGroupConnectorCoords = {
  bbox: XYWH;
  groupName: string;
};

type DestinationPortConnectorCoords = DestinationGroupConnectorCoords & {
  port: string;
  ip?: string;
};

export type ProcessTreeConnectorCoords = {
  enclosingBBox: XYWH;
  podConnectorBBoxes: PodHandleConnectorCoords[];
  containerConnectorBBoxes: ProcessLineConnectorCoords[];
  processLineConnectorBBoxes: ProcessLineConnectorCoords[];
  egressConnectorBBoxes: ProcessLineConnectorCoords[];
  similarChildsConnectorBBoxes: SimilarChildsConnectorCoords[];
  similarChildsEgressConnectorBBoxes: SimilarChildsConnectorCoords[];
  destinationGroupConnectorBBoxes: DestinationGroupConnectorCoords[];
  destinationPortConnectorBBoxes: DestinationPortConnectorCoords[];
};

export enum Event {
  CoordsUpdated = 'coords-updated',
}

type Handlers = {
  [Event.CoordsUpdated]: (bboxes: ProcessTreeConnectorCoords) => void;
};

export class RefsCollector extends EventEmitter<Handlers> {
  private svgRoot: MutRef<SVGSVGElement | null> | null = null;
  // NOTE: Handles reponsible for emitting entire PodHandle size
  // private _podHandles: Set<ElemCoordsHandle> = new Set();

  // NOTE: Refs to pod handle headline element
  // { podHandleId -> Ref }
  private podHandleConnectorRefs: IdRefMap<HTMLDivElement> = new Map();

  // NOTE: Refs to process/container lines
  // { podHandleId -> { execId -> Ref }}
  private containerRootRefs: IdRefMap2 = new Map();
  private processLineRootRefs: IdRefMap2 = new Map();

  // NOTE: Refs to processes egress connectors, i e those connectors where
  // arrows start
  // { podHandleId -> { execId -> Ref }}
  private egressConnectorRefs: IdRefMap2 = new Map();

  // NOTE: Refs to SimilarProcessSubTrees connectorIcon (expand icon)
  // { podHandleId -> { groupName -> Ref }}
  private similarChildsConnectorRefs: IdRefMap2 = new Map();

  // NOTE: Refs to SimilarProcessSubTree egress connectors (currently not in use)
  private similarChildsEgressConnectorRefs: IdRefMap2 = new Map();

  // NOTE: Refs to EndpointGroup connectors
  private destinationGroupConnectorRefs: IdRefMap = new Map();
  private destinationPortConnectorRefs: IdRefMap3 = new Map();

  private throttledRefsUpdated?: () => void;

  constructor() {
    super(false);

    this.throttledRefsUpdated = _.debounce(async () => {
      await this.refsUpdated();
    }, 0);
  }

  public reset() {
    this.svgRoot = null;

    this.podHandleConnectorRefs.clear();
    this.containerRootRefs.clear();
    this.processLineRootRefs.clear();
    this.egressConnectorRefs.clear();
    this.similarChildsConnectorRefs.clear();
    this.similarChildsEgressConnectorRefs.clear();
    this.destinationGroupConnectorRefs.clear();
    this.destinationPortConnectorRefs.clear();
  }

  public onCoordsUpdated(fn: Handlers[Event.CoordsUpdated]): this {
    this.on(Event.CoordsUpdated, fn);
    return this;
  }

  public isProcessLineRefAssigned(handleId: string, execId: string): boolean {
    const ref = this.processLineRootRefs.get(handleId)?.get(execId);
    return ref?.current != null;
  }

  public async refsUpdated() {
    const svgRoot = this.svgRoot?.current;
    if (svgRoot == null) return;

    // NOTE: Take an element where all the pod handles and egress groups
    // are enclosed
    const sourcesAndDestinationsElem = svgRoot.querySelector(`.${css.sourcesAndDestinations}`);
    if (sourcesAndDestinationsElem == null) return;

    const podHandleConnectorPromises = this.getPodHandleConnectorPromises();
    const containerConnectorPromises = this.getContainerConnectorPromises();
    const processLineConnectorPromises = this.getProcessLineConnectorPromises();
    const egressConnectorPromises = this.getEgressConnectorPromises();
    const similarChildsConnectorPromises = this.getSimilarChildsConnectorPromises();
    const similarChildsEConnectorPromises = this.getSimilarChildsEConnectorPromises();

    const destinationGroupConnectorPromises = this.getDestinationGroupConnectorPromises();
    const destinationPortConnectorPromises = this.getDestinationPortConnectorPromises();

    // NOTE: Tree BBox is determinated by pod handles and destination groups
    // but not the arrows, so the arrows cannot expand enclosing bbox in this
    // code.
    const [
      treeRootBBox,
      podConnectorBBoxes,
      containerConnectorBBoxes,
      processLineConnectorBBoxes,
      egressConnectorBBoxes,
      similarChildsConnectorBBoxes,
      similarChildsEgressConnectorBBoxes,
      destinationGroupConnectorBBoxes,
      destinationPortConnectorBBoxes,
    ] = await Promise.all([
      getIntersectBBoxAsync({ elem: sourcesAndDestinationsElem, oneshot: true }),
      Promise.all(podHandleConnectorPromises),
      Promise.all(containerConnectorPromises),
      Promise.all(processLineConnectorPromises),
      Promise.all(egressConnectorPromises),
      Promise.all(similarChildsConnectorPromises),
      Promise.all(similarChildsEConnectorPromises),
      Promise.all(destinationGroupConnectorPromises),
      Promise.all(destinationPortConnectorPromises),
    ]);

    this.emit(Event.CoordsUpdated, {
      enclosingBBox: treeRootBBox,
      podConnectorBBoxes,
      containerConnectorBBoxes,
      processLineConnectorBBoxes,
      egressConnectorBBoxes,
      similarChildsConnectorBBoxes,
      similarChildsEgressConnectorBBoxes,
      destinationGroupConnectorBBoxes,
      destinationPortConnectorBBoxes,
    });
  }

  private getDestinationGroupConnectorPromises(): Promise<DestinationGroupConnectorCoords>[] {
    return this.mapBBoxPromises(this.destinationGroupConnectorRefs, async (groupName, p) => {
      return p.then(bbox => ({ bbox, groupName }));
    });
  }

  private getDestinationPortConnectorPromises(): Promise<DestinationPortConnectorCoords>[] {
    return this.mapBBoxPromises3(
      this.destinationPortConnectorRefs,
      async (groupName, port, ip, p) => {
        return p.then(bbox => ({ bbox, groupName, port, ip }));
      },
    );
  }

  private getSimilarChildsConnectorPromises(): Promise<SimilarChildsConnectorCoords>[] {
    return this.mapBBoxPromises2(
      this.similarChildsConnectorRefs,
      async (podHandleId, groupKey, p) => {
        return p.then(bbox => ({ podHandleId, groupKey, bbox }));
      },
    );
  }

  private getSimilarChildsEConnectorPromises(): Promise<SimilarChildsConnectorCoords>[] {
    return this.mapBBoxPromises2(
      this.similarChildsEgressConnectorRefs,
      async (podHandleId, groupKey, p) => {
        return p.then(bbox => ({ podHandleId, groupKey, bbox }));
      },
    );
  }

  private getEgressConnectorPromises(): Promise<ProcessLineConnectorCoords>[] {
    return this.mapBBoxPromises2(this.egressConnectorRefs, async (podHandleId, execId, p) => {
      return p.then(bbox => ({ podHandleId, execId, bbox }));
    });
  }

  private getPodHandleConnectorPromises(): Promise<PodHandleConnectorCoords>[] {
    return this.mapBBoxPromises(this.podHandleConnectorRefs, async (podHandleId, p) => {
      return p.then(bbox => ({ bbox, podHandleId }));
    });
  }

  private getContainerConnectorPromises(): Promise<ProcessLineConnectorCoords>[] {
    return this.mapBBoxPromises2(this.containerRootRefs, async (podHandleId, execId, p) => {
      return p.then(bbox => ({ podHandleId, execId, bbox }));
    });
  }

  private getProcessLineConnectorPromises(): Promise<ProcessLineConnectorCoords>[] {
    return this.mapBBoxPromises2(this.processLineRootRefs, async (podHandleId, execId, p) => {
      return p.then(bbox => ({ podHandleId, execId, bbox }));
    });
  }

  public processTreeSvgRoot(): MutRef<SVGSVGElement | null> {
    if (this.svgRoot != null) return this.svgRoot;

    const newRef = reactionRef(null, svgRoot => {
      logger.log(`svg root is updated: `, svgRoot);
      this.throttledRefsUpdated?.();
    });

    this.svgRoot = newRef;
    return newRef;
  }

  public containerRootRef(podHandleId: string, execId: string): MutRef<HTMLDivElement | null> {
    return this.ensureIdRef2(this.containerRootRefs, podHandleId, execId, elem => {
      logger.log(`${podHandleId} -> ${execId} container root ref assigned`, elem);
      this.throttledRefsUpdated?.();
    });
  }

  public processLineRootRef(podHandleId: string, execId: string): MutRef<HTMLDivElement | null> {
    return this.ensureIdRef2(this.processLineRootRefs, podHandleId, execId, elem => {
      logger.log(`${podHandleId} -> ${execId} process line root ref asssigned: `, elem);
      this.throttledRefsUpdated?.();
    });
  }

  public egressConnectorRef(podHandleId: string, execId: string): MutRef<HTMLDivElement | null> {
    return this.ensureIdRef2(this.egressConnectorRefs, podHandleId, execId, elem => {
      logger.log(`${podHandleId} -> ${execId} egress connector ref is assigned: `, elem);
      this.throttledRefsUpdated?.();
    });
  }

  public podHandleConnectorRef(podHandleId: string): MutRef<HTMLDivElement | null> {
    return this.ensureIdRef(this.podHandleConnectorRefs, podHandleId, elem => {
      logger.log(`pod handle "${podHandleId}" connector assigned`, elem);
      this.throttledRefsUpdated?.();
    });
  }

  public destinationGroupConnectorRef(groupName: string): MutRef<HTMLDivElement | null> {
    return this.ensureIdRef(this.destinationGroupConnectorRefs, groupName, elem => {
      logger.log(`destination group ${groupName} connector ref assigned`, elem);
      this.throttledRefsUpdated?.();
    });
  }

  public destinationPortConnectorRef(
    groupName: string,
    port: number,
    ip?: string,
  ): MutRef<HTMLDivElement | null> {
    return this.ensureIdRef3(
      this.destinationPortConnectorRefs,
      groupName,
      port.toString(),
      ip || '',
      elem => {
        logger.log(
          `${groupName} -> ${port} -> ${ip || '<Empty IP>'} connector ref assigned: `,
          elem,
        );
        this.throttledRefsUpdated?.();
      },
    );
  }

  public similarChildsConnectorRef(
    podHandleId: string,
    groupKey: string,
  ): MutRef<HTMLDivElement | null> {
    return this.ensureIdRef2(this.similarChildsConnectorRefs, podHandleId, groupKey, elem => {
      logger.log(`${podHandleId} -> ${groupKey} similar childs connector ref assigned`, elem);
      this.throttledRefsUpdated?.();
    });
  }

  public similarChildsEgressConnectorRef(
    podHandleId: string,
    groupKey: string,
  ): MutRef<HTMLDivElement | null> {
    return this.ensureIdRef2(this.similarChildsEgressConnectorRefs, podHandleId, groupKey, elem => {
      logger.log(`${podHandleId} -> ${groupKey} similar childs egress connector ref: `, elem);
      this.throttledRefsUpdated?.();
    });
  }

  private ensureIdRef3<E>(
    refsMap: IdRefMap3<E>,
    id1: string,
    id2: string,
    id3: string,
    refCb: ReactionRefCb<E | null>,
  ) {
    const existing = refsMap.get(id1) || new Map();
    if (!refsMap.has(id1)) refsMap.set(id1, existing);

    return this.ensureIdRef2(existing, id2, id3, refCb);
  }

  private ensureIdRef2<E>(
    refsMap: IdRefMap2<E>,
    id1: string,
    id2: string,
    refCb: ReactionRefCb<E | null>,
  ) {
    const existing = refsMap.get(id1) || new Map();
    if (!refsMap.has(id1)) refsMap.set(id1, existing);

    return this.ensureIdRef(existing, id2, refCb);
  }

  private ensureIdRef<E>(refsMap: IdRefMap<E>, id: string, refCb: ReactionRefCb<E | null>) {
    const existing = refsMap.get(id);
    if (existing != null) return existing;

    const newRef = reactionRef(null, refCb);

    refsMap.set(id, newRef);
    return newRef;
  }

  private mapBBoxPromises<E extends Element, P>(
    refsMap: IdRefMap<E>,
    cb: (id: string, p: Promise<XYWH>) => Promise<P>,
  ): Promise<P>[] {
    const promises: Promise<P>[] = [];

    for (const [id, ref] of refsMap) {
      if (ref.current == null) continue;
      const p = getIntersectBBoxAsync({ elem: ref.current, oneshot: true });

      promises.push(cb(id, p));
    }

    return promises;
  }

  private mapBBoxPromises2<E extends Element, P>(
    refsMap: IdRefMap2<E>,
    cb: (id1: string, id2: string, p: Promise<XYWH>) => Promise<P>,
  ): Promise<P>[] {
    const promises: Promise<P>[] = [];

    for (const [id1, refMap] of refsMap) {
      for (const [id2, ref] of refMap) {
        if (ref.current == null) continue;
        const p = getIntersectBBoxAsync({ elem: ref.current, oneshot: true });

        promises.push(cb(id1, id2, p));
      }
    }

    return promises;
  }

  private mapBBoxPromises3<E extends Element, P>(
    refsMap: IdRefMap3<E>,
    cb: (id1: string, id2: string, id3: string, p: Promise<XYWH>) => Promise<P>,
  ): Promise<P>[] {
    const promises: Promise<P>[] = [];

    for (const [id1, refMap2] of refsMap) {
      for (const [id2, refMap] of refMap2) {
        for (const [id3, ref] of refMap) {
          if (ref.current == null) continue;
          const p = getIntersectBBoxAsync({ elem: ref.current, oneshot: true });

          promises.push(cb(id1, id2, id3, p));
        }
      }
    }

    return promises;
  }
}
