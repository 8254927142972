import { InjectionReader } from './reader';

export class InjectionReaderBuilder {
  private ffSelector?: string;
  private authzSelector?: string;
  private ssrErrorsSelector?: string;

  public static new() {
    return new InjectionReaderBuilder();
  }

  public withFeatureFlagsSelector(sel?: string): this {
    this.ffSelector = sel;
    return this;
  }

  public withAuthorizationSelector(sel?: string): this {
    this.authzSelector = sel;
    return this;
  }

  public withSSRErrorsSelector(sel?: string): this {
    this.ssrErrorsSelector = sel;
    return this;
  }

  public build() {
    if (!this.ffSelector) throw this.err('ffSelector');
    if (!this.authzSelector) throw this.err('authzSelector');
    if (!this.ssrErrorsSelector) throw this.err('ssrErrorsSelector');

    return new InjectionReader(this.ffSelector, this.authzSelector, this.ssrErrorsSelector);
  }

  private err(what: string): Error {
    return new Error(`failed to build InjectionReader: ${what} is not set`);
  }
}
