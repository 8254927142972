import { Message, Oneshot, OneshotOptions } from '~/api/customprotocol-core';
import { TimeRange } from '~/domain/time';
import { TimescapeK8SEvent } from '~/domain/timescape/k8s-events';
import { ProtoFactory } from '~/factories/proto';
import * as tsk8seventspb from '~backend/proto/timescape/v1/k8s_events_pb';
import * as uipb from '~backend/proto/ui/ui_pb';

export type TimescapeK8SEventsParams = {
  cluster?: string | null | undefined;
  namespace?: string | null | undefined;
  timeRange?: TimeRange | null | undefined;
  resourceUuid?: string | null | undefined;
};

export type Options = OneshotOptions & TimescapeK8SEventsParams & { eventKind: EventKind };

export enum EventKind {
  PolicyEvents = 'policy-events',
}

export type Handlers = {
  [EventKind.PolicyEvents]: (events: TimescapeK8SEvent[]) => void;
};

export class TimescapeK8SEventsOneshot extends Oneshot<Handlers> {
  private eventKind: EventKind;
  private params: TimescapeK8SEventsParams;

  constructor(opts: Options) {
    super(opts);
    this.eventKind = opts.eventKind;
    this.params = {
      cluster: opts.cluster,
      namespace: opts.namespace,
      timeRange: opts.timeRange,
      resourceUuid: opts.resourceUuid,
    };

    this.setupEventHandlers();
  }

  public onPolicyEvents(fn: Handlers[EventKind.PolicyEvents]): this {
    this.on(EventKind.PolicyEvents, fn);
    return this;
  }

  private setupEventHandlers() {
    this.onMessage(msg => {
      const resp = uipb.GetTimescapeK8SEventsResponse.fromBinary(msg.body);
      if (resp.events == null) return;

      this.emit(EventKind.PolicyEvents, resp.events);
    });
  }

  protected messageBuilder(msg: Message, isFirst: boolean): Message {
    if (!isFirst) return msg;

    let request: tsk8seventspb.GetK8sEventsRequest;
    switch (this.eventKind) {
      case EventKind.PolicyEvents: {
        request = ProtoFactory.timescapeK8SPolicyEventsRequest(
          this.params.cluster ?? null,
          this.params.namespace ?? null,
          this.params.timeRange ?? null,
          this.params.resourceUuid ?? null,
        );
        break;
      }
      default: {
        throw new Error(`unhandled timescape k8s event kind: ${this.eventKind}`);
      }
    }

    msg.setBodyBytes(tsk8seventspb.GetK8sEventsRequest.toBinary(request));
    return msg;
  }
}
