import find from 'lodash/find';
import moment from 'moment';

import { TimeDuration, TimeDurationRange } from '~/domain/time';

export type AxisDomain = string | number;

export interface TimeCountTuple {
  time: Date;
  count: number | null;
}

export interface TimeRangeOption {
  label: string;
  value: string;
}

export const timeRangeOptions: TimeRangeOption[] = [
  {
    label: 'Last hour',
    value: moment().utc().subtract(1, 'hour').format(),
  },
  {
    label: 'Last 24 hours',
    value: moment().utc().subtract(24, 'hour').format(),
  },
  {
    label: 'Last week',
    value: moment().utc().subtract(7, 'days').format(),
  },
  {
    label: 'Last month',
    value: moment().utc().subtract(1, 'month').format(),
  },
];

export const getXAxisDomain = (
  tickDurationMs: number,
  chartPivotTime: string,
  chartEndTime?: string | null,
): [AxisDomain, AxisDomain] => {
  const now = moment.utc();
  let domain: [AxisDomain, AxisDomain] = ['auto', 'auto'];

  const endTime = (chartEndTime ? moment(chartEndTime) : now).add(tickDurationMs, 'ms').valueOf();
  if (chartPivotTime === 'last hour') {
    domain = [moment(now).subtract(1, 'h').subtract(tickDurationMs, 'ms').valueOf(), endTime];
  } else if (chartPivotTime === 'last 24 hours') {
    domain = [moment(now).subtract(1, 'd').subtract(tickDurationMs, 'ms').valueOf(), endTime];
  } else if (chartPivotTime === 'last week') {
    domain = [moment(now).subtract(7, 'd').subtract(tickDurationMs, 'ms').valueOf(), endTime];
  } else if (chartPivotTime === 'last month') {
    domain = [moment(now).subtract(1, 'month').subtract(tickDurationMs, 'ms').valueOf(), endTime];
  } else if (chartEndTime) {
    domain = [moment(chartPivotTime).subtract(tickDurationMs, 'ms').valueOf(), endTime];
  }
  return domain;
};

export const getXAxisTicks = (domain: [AxisDomain, AxisDomain]): number[] => {
  const fromTime = moment.utc(domain[0] as number);
  const toTime = moment.utc(domain[1] as number);
  const ticks: number[] = [];
  if (toTime.diff(fromTime, 'minutes') <= 60) {
    // tick every 10 minutes
    for (
      let tick = moment(fromTime).startOf('hour');
      tick.isBefore(toTime);
      tick.add(10, 'minute')
    ) {
      if (tick.isAfter(fromTime)) {
        ticks.push(tick.valueOf());
      }
    }
  } else if (toTime.diff(fromTime, 'weeks') <= 1) {
    // tick every hour
    for (let tick = moment(fromTime).startOf('hour'); tick.isBefore(toTime); tick.add(1, 'hour')) {
      if (tick.isAfter(fromTime)) {
        ticks.push(tick.valueOf());
      }
    }
  } else if (toTime.diff(fromTime, 'weeks') <= 3) {
    // tick every day
    for (let tick = moment(fromTime).startOf('day'); tick.isBefore(toTime); tick.add(1, 'day')) {
      if (tick.isAfter(fromTime)) {
        ticks.push(tick.valueOf());
      }
    }
  } else {
    // tick every week
    for (let tick = moment(fromTime).startOf('day'); tick.isBefore(toTime); tick.add(1, 'week')) {
      if (tick.isAfter(fromTime)) {
        ticks.push(tick.valueOf());
      }
    }
  }
  return ticks;
};

export const getChartPivotTime = (chartPivotTime: string | null | undefined) => {
  if (!chartPivotTime) {
    return timeRangeOptions[0].label.toLowerCase();
  }
  if (isTimeRangeOption(chartPivotTime)) {
    return chartPivotTime.toLowerCase();
  }
  return chartPivotTime;
};

export const formatTick = (tick: number, chartPivotTime: string): string => {
  return chartPivotTime === 'last hour' || chartPivotTime === 'last 24 hours'
    ? moment(tick).format('HH:mm')
    : moment(tick).format('lll');
};

export const getTimeAfter = (chartPivotTime: string | null | undefined) => {
  const timeRangeOption = find(timeRangeOptions, o => o.label === chartPivotTime);
  if (chartPivotTime && !timeRangeOption) {
    return chartPivotTime;
  }
  return timeRangeOption ? timeRangeOption.value : timeRangeOptions[0].value;
};

export const getTimeBefore = () => moment().toISOString();

export const isTimeRangeOption = (dateTime: string | null | undefined) => {
  return Boolean(find(timeRangeOptions, o => o.label === dateTime));
};

export const durations = {
  ZeroToDay: new TimeDurationRange(new TimeDuration(0), new TimeDuration(86400000)),
  DayToWeek: new TimeDurationRange(new TimeDuration(86400000), new TimeDuration(604800000)),
  WeekToMonth: new TimeDurationRange(new TimeDuration(604800000), new TimeDuration(2592000000)),
  MonthPlus: new TimeDurationRange(
    new TimeDuration(2592000000),
    new TimeDuration(Number.MAX_VALUE),
  ),
};

export function mapDuration<R>(
  duration: TimeDuration,
  callback: (key: keyof typeof durations) => R,
): R {
  if (durations.DayToWeek.covered(duration, { includeMin: false })) {
    return callback('DayToWeek');
  } else if (durations.WeekToMonth.covered(duration, { includeMin: false })) {
    return callback('WeekToMonth');
  } else if (durations.MonthPlus.covered(duration, { includeMin: false })) {
    return callback('MonthPlus');
  }
  return callback('ZeroToDay');
}
