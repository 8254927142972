import React from 'react';

import { Button, ButtonGroup } from '@blueprintjs/core';
import classnames from 'classnames';

import { DetailsPanelMode } from '~/domain/common';
import { DataMode } from '~/domain/interactions';
import { TimeRange } from '~/domain/time';

import css from './center.scss';

export interface Props {
  dataMode: DataMode;
  timeRange: TimeRange;
  isLoading?: boolean;
  panelMode: DetailsPanelMode;
  showPanelModeSwitcher: boolean;
  showGrafanaNodesGraph: boolean;
  onPanelModeChange?: (mode: DetailsPanelMode) => void;
  onTimeRangeChange?: (timeRange: TimeRange) => void;
  isHidden: boolean;
}

export const Center = (props: Props) => {
  return (
    <div className={classnames(css.layout, { [css.hidden]: props.isHidden })}>
      {props.showPanelModeSwitcher && (
        <ButtonGroup>
          <Button
            small
            active={props.panelMode === DetailsPanelMode.Flows}
            onClick={() => props.onPanelModeChange?.(DetailsPanelMode.Flows)}
          >
            Flows
          </Button>
          <Button
            small
            active={props.panelMode === DetailsPanelMode.Dashboards}
            onClick={() => props.onPanelModeChange?.(DetailsPanelMode.Dashboards)}
          >
            Metrics
          </Button>
          {props.showGrafanaNodesGraph && (
            <Button
              small
              active={props.panelMode === DetailsPanelMode.NodeGraph}
              onClick={() => props.onPanelModeChange?.(DetailsPanelMode.NodeGraph)}
            >
              Services
            </Button>
          )}
        </ButtonGroup>
      )}
    </div>
  );
};
