/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter add_pb_suffix,eslint_disable,ts_nocheck,generate_dependencies,long_type_number,use_proto_field_name
// @generated from protobuf file "ui/notifications.proto" (package "ui", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { GetStatusResponse } from "./status_pb";
/**
 * @generated from protobuf message ui.Notification
 */
export interface Notification {
    /**
     * @generated from protobuf oneof: notification
     */
    notification: {
        oneofKind: "conn_state";
        /**
         * @generated from protobuf field: ui.ConnectionState conn_state = 1;
         */
        conn_state: ConnectionState;
    } | {
        oneofKind: "data_state";
        /**
         * @generated from protobuf field: ui.DataState data_state = 2;
         */
        data_state: DataState;
    } | {
        oneofKind: "status";
        /**
         * @generated from protobuf field: ui.GetStatusResponse status = 3;
         */
        status: GetStatusResponse;
    } | {
        oneofKind: "no_permission";
        /**
         * @generated from protobuf field: ui.NoPermission no_permission = 4;
         */
        no_permission: NoPermission;
    } | {
        oneofKind: "auth_state";
        /**
         * @generated from protobuf field: ui.AuthorizationState auth_state = 5;
         */
        auth_state: AuthorizationState;
    } | {
        oneofKind: undefined;
    };
}
/**
 * @generated from protobuf message ui.ConnectionState
 */
export interface ConnectionState {
    /**
     * Backend is successfully connected to hubble-relay
     *
     * @generated from protobuf field: bool relay_connected = 1;
     */
    relay_connected: boolean;
    /**
     * Backend has lost the connection to hubble-relay and is reconnecting now
     *
     * @generated from protobuf field: bool relay_reconnecting = 2;
     */
    relay_reconnecting: boolean;
    /**
     * Backend has lost the connection to kubernetes and is reconnecting
     *
     * @generated from protobuf field: bool k8s_unavailable = 3 [json_name = "k8sUnavailable"];
     */
    k8s_unavailable: boolean;
    /**
     * Backend has established connection to k8s
     *
     * @generated from protobuf field: bool k8s_connected = 4 [json_name = "k8sConnected"];
     */
    k8s_connected: boolean;
    /**
     * Backend is successfully connected to hubble-timescape
     *
     * @generated from protobuf field: bool timescape_connected = 5;
     */
    timescape_connected: boolean;
    /**
     * Backend has lost the connection to hubble-timescape and is reconnecting now
     *
     * @generated from protobuf field: bool timescape_reconnecting = 6;
     */
    timescape_reconnecting: boolean;
}
/**
 * @generated from protobuf message ui.DataState
 */
export interface DataState {
    /**
     * No pods in selected namespace
     *
     * @generated from protobuf field: bool no_activity = 1;
     */
    no_activity: boolean;
}
/**
 * @generated from protobuf message ui.NoPermission
 */
export interface NoPermission {
    /**
     * @generated from protobuf field: string resource = 1;
     */
    resource: string;
    /**
     * @generated from protobuf field: string error = 2;
     */
    error: string;
}
/**
 * @generated from protobuf message ui.AuthorizationState
 */
export interface AuthorizationState {
    /**
     * @generated from protobuf field: bool invalid = 1;
     */
    invalid: boolean;
}
// @generated message type with reflection information, may provide speed optimized methods
class Notification$Type extends MessageType<Notification> {
    constructor() {
        super("ui.Notification", [
            { no: 1, name: "conn_state", kind: "message", localName: "conn_state", oneof: "notification", T: () => ConnectionState },
            { no: 2, name: "data_state", kind: "message", localName: "data_state", oneof: "notification", T: () => DataState },
            { no: 3, name: "status", kind: "message", oneof: "notification", T: () => GetStatusResponse },
            { no: 4, name: "no_permission", kind: "message", localName: "no_permission", oneof: "notification", T: () => NoPermission },
            { no: 5, name: "auth_state", kind: "message", localName: "auth_state", oneof: "notification", T: () => AuthorizationState }
        ]);
    }
    create(value?: PartialMessage<Notification>): Notification {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.notification = { oneofKind: undefined };
        if (value !== undefined)
            reflectionMergePartial<Notification>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Notification): Notification {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* ui.ConnectionState conn_state */ 1:
                    message.notification = {
                        oneofKind: "conn_state",
                        conn_state: ConnectionState.internalBinaryRead(reader, reader.uint32(), options, (message.notification as any).conn_state)
                    };
                    break;
                case /* ui.DataState data_state */ 2:
                    message.notification = {
                        oneofKind: "data_state",
                        data_state: DataState.internalBinaryRead(reader, reader.uint32(), options, (message.notification as any).data_state)
                    };
                    break;
                case /* ui.GetStatusResponse status */ 3:
                    message.notification = {
                        oneofKind: "status",
                        status: GetStatusResponse.internalBinaryRead(reader, reader.uint32(), options, (message.notification as any).status)
                    };
                    break;
                case /* ui.NoPermission no_permission */ 4:
                    message.notification = {
                        oneofKind: "no_permission",
                        no_permission: NoPermission.internalBinaryRead(reader, reader.uint32(), options, (message.notification as any).no_permission)
                    };
                    break;
                case /* ui.AuthorizationState auth_state */ 5:
                    message.notification = {
                        oneofKind: "auth_state",
                        auth_state: AuthorizationState.internalBinaryRead(reader, reader.uint32(), options, (message.notification as any).auth_state)
                    };
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Notification, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* ui.ConnectionState conn_state = 1; */
        if (message.notification.oneofKind === "conn_state")
            ConnectionState.internalBinaryWrite(message.notification.conn_state, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* ui.DataState data_state = 2; */
        if (message.notification.oneofKind === "data_state")
            DataState.internalBinaryWrite(message.notification.data_state, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* ui.GetStatusResponse status = 3; */
        if (message.notification.oneofKind === "status")
            GetStatusResponse.internalBinaryWrite(message.notification.status, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* ui.NoPermission no_permission = 4; */
        if (message.notification.oneofKind === "no_permission")
            NoPermission.internalBinaryWrite(message.notification.no_permission, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* ui.AuthorizationState auth_state = 5; */
        if (message.notification.oneofKind === "auth_state")
            AuthorizationState.internalBinaryWrite(message.notification.auth_state, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ui.Notification
 */
export const Notification = new Notification$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ConnectionState$Type extends MessageType<ConnectionState> {
    constructor() {
        super("ui.ConnectionState", [
            { no: 1, name: "relay_connected", kind: "scalar", localName: "relay_connected", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "relay_reconnecting", kind: "scalar", localName: "relay_reconnecting", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "k8s_unavailable", kind: "scalar", localName: "k8s_unavailable", jsonName: "k8sUnavailable", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "k8s_connected", kind: "scalar", localName: "k8s_connected", jsonName: "k8sConnected", T: 8 /*ScalarType.BOOL*/ },
            { no: 5, name: "timescape_connected", kind: "scalar", localName: "timescape_connected", T: 8 /*ScalarType.BOOL*/ },
            { no: 6, name: "timescape_reconnecting", kind: "scalar", localName: "timescape_reconnecting", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<ConnectionState>): ConnectionState {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.relay_connected = false;
        message.relay_reconnecting = false;
        message.k8s_unavailable = false;
        message.k8s_connected = false;
        message.timescape_connected = false;
        message.timescape_reconnecting = false;
        if (value !== undefined)
            reflectionMergePartial<ConnectionState>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ConnectionState): ConnectionState {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool relay_connected */ 1:
                    message.relay_connected = reader.bool();
                    break;
                case /* bool relay_reconnecting */ 2:
                    message.relay_reconnecting = reader.bool();
                    break;
                case /* bool k8s_unavailable = 3 [json_name = "k8sUnavailable"];*/ 3:
                    message.k8s_unavailable = reader.bool();
                    break;
                case /* bool k8s_connected = 4 [json_name = "k8sConnected"];*/ 4:
                    message.k8s_connected = reader.bool();
                    break;
                case /* bool timescape_connected */ 5:
                    message.timescape_connected = reader.bool();
                    break;
                case /* bool timescape_reconnecting */ 6:
                    message.timescape_reconnecting = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ConnectionState, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool relay_connected = 1; */
        if (message.relay_connected !== false)
            writer.tag(1, WireType.Varint).bool(message.relay_connected);
        /* bool relay_reconnecting = 2; */
        if (message.relay_reconnecting !== false)
            writer.tag(2, WireType.Varint).bool(message.relay_reconnecting);
        /* bool k8s_unavailable = 3 [json_name = "k8sUnavailable"]; */
        if (message.k8s_unavailable !== false)
            writer.tag(3, WireType.Varint).bool(message.k8s_unavailable);
        /* bool k8s_connected = 4 [json_name = "k8sConnected"]; */
        if (message.k8s_connected !== false)
            writer.tag(4, WireType.Varint).bool(message.k8s_connected);
        /* bool timescape_connected = 5; */
        if (message.timescape_connected !== false)
            writer.tag(5, WireType.Varint).bool(message.timescape_connected);
        /* bool timescape_reconnecting = 6; */
        if (message.timescape_reconnecting !== false)
            writer.tag(6, WireType.Varint).bool(message.timescape_reconnecting);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ui.ConnectionState
 */
export const ConnectionState = new ConnectionState$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DataState$Type extends MessageType<DataState> {
    constructor() {
        super("ui.DataState", [
            { no: 1, name: "no_activity", kind: "scalar", localName: "no_activity", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<DataState>): DataState {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.no_activity = false;
        if (value !== undefined)
            reflectionMergePartial<DataState>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DataState): DataState {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool no_activity */ 1:
                    message.no_activity = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DataState, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool no_activity = 1; */
        if (message.no_activity !== false)
            writer.tag(1, WireType.Varint).bool(message.no_activity);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ui.DataState
 */
export const DataState = new DataState$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NoPermission$Type extends MessageType<NoPermission> {
    constructor() {
        super("ui.NoPermission", [
            { no: 1, name: "resource", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "error", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<NoPermission>): NoPermission {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.resource = "";
        message.error = "";
        if (value !== undefined)
            reflectionMergePartial<NoPermission>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: NoPermission): NoPermission {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string resource */ 1:
                    message.resource = reader.string();
                    break;
                case /* string error */ 2:
                    message.error = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: NoPermission, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string resource = 1; */
        if (message.resource !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.resource);
        /* string error = 2; */
        if (message.error !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.error);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ui.NoPermission
 */
export const NoPermission = new NoPermission$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AuthorizationState$Type extends MessageType<AuthorizationState> {
    constructor() {
        super("ui.AuthorizationState", [
            { no: 1, name: "invalid", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<AuthorizationState>): AuthorizationState {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.invalid = false;
        if (value !== undefined)
            reflectionMergePartial<AuthorizationState>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AuthorizationState): AuthorizationState {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool invalid */ 1:
                    message.invalid = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AuthorizationState, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool invalid = 1; */
        if (message.invalid !== false)
            writer.tag(1, WireType.Varint).bool(message.invalid);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ui.AuthorizationState
 */
export const AuthorizationState = new AuthorizationState$Type();
