import React from 'react';

import classnames from 'classnames';
import { motion } from 'framer-motion';
import * as mobx from 'mobx';
import { observer } from 'mobx-react-lite';

import { Tooltip } from '~/components/Tooltip';
import { link } from '~/domain/helpers';
import { colors, sizes } from '~/ui/vars';
import { Arrow } from '~/ui-layer/service-map/coordinates/types';

import * as FlowsInfo from './FlowsInfo';
import * as helpers from './helpers';
import css from './styles.scss';

export type Props = {
  arrow: Arrow;
  overlay?: React.MutableRefObject<Element | null>;
  isFlowStatsHidden?: boolean;
  strokeWidth?: number | null;
  strokeColor?: string | null;
  decorationColor?: string | null;
  arrowDecorationDuration?: number;
  arrowDelay?: number;
  arrowDuration?: number;
  className?: string;
};

export const ServiceMapArrowBody = observer(function ServiceMapArrowBody(props: Props) {
  const {
    strokeWidth,
    strokeColor,
    decorationColor,
    arrowDecorationDuration,
    arrowDelay,
    arrowDuration,
  } = props;
  // TODO: consider moving to inside of ServiceMapArrow
  const linkThroughput = mobx
    .computed(() => {
      if (props.arrow == null) return null;

      const combined = link.reduceLinkThroughputs(props.arrow.linkThroughputs || []);
      if (link.isDumbLinkThroughput(combined)) return null;

      return combined;
    })
    .get();

  if (props.arrow.points.length === 0) return null;

  const linkStatsCanBeRendered =
    !!props.arrow.flowsInfoIndicatorCoords && linkThroughput && !props.isFlowStatsHidden;

  const classes = classnames('arrow-body', props.arrow?.id, props.className);
  const handles = helpers.collectHandles(props.arrow.points);

  return (
    <g className={classes} key={props.arrow.id}>
      <g className="trajectory">
        <motion.path
          initial={{ pathLength: 0, opacity: 0 }}
          animate={{ pathLength: 1, opacity: 1 }}
          transition={{
            delay: arrowDelay || helpers.animationDelay.arrowDelay,
            duration: arrowDuration || helpers.animationDelay.arrowDuration,
          }}
          className={'line'}
          fill="none"
          stroke={strokeColor || colors.arrowStroke}
          strokeWidth={strokeWidth || sizes.linkWidth}
          d={helpers.svg.arrowLinePath(props.arrow.points)}
        />
        <motion.path
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            delay: arrowDelay || helpers.animationDelay.arrowDelay,
            duration: arrowDuration || helpers.animationDelay.arrowDecorationDuration,
          }}
          className={'start-plate'}
          fill={strokeColor || colors.startPlateFill}
          stroke={strokeColor || colors.startPlateStroke}
          strokeWidth={sizes.linkWidth}
          d={helpers.svg.startPlatePath(props.arrow.points[0])}
        />
      </g>
      <g className="triangle-handles">
        {handles.map((arrowHandle, i) => {
          return (
            <motion.path
              key={i}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{
                delay:
                  arrowDelay ||
                  helpers.animationDelay.arrowDelay +
                    i * ((arrowDuration || helpers.animationDelay.arrowDuration) / handles.length),
                duration: arrowDecorationDuration || helpers.animationDelay.arrowDecorationDuration,
              }}
              fill={decorationColor || colors.arrowHandle}
              className={'handle ' + css.arrowHandle}
              stroke="none"
              d={helpers.svg.arrowHandlePath(arrowHandle)}
            />
          );
        })}
      </g>

      {linkStatsCanBeRendered && (
        <Tooltip
          isSVGContext={true}
          content={
            <FlowsInfo.TooltipContent
              coords={props.arrow.flowsInfoIndicatorCoords!}
              linkThroughput={linkThroughput}
            />
          }
          portalContainer={props.overlay}
          target={() => {
            return <FlowsInfo.TooltipHandle arrow={props.arrow} linkThroughput={linkThroughput} />;
          }}
        />
      )}
    </g>
  );
});
