import React from 'react';

import classnames from 'classnames';
import formatDate from 'date-fns/format';
import { observer } from 'mobx-react';
import parseUrl from 'url-parse';

import { formatWidth } from '~/components/hooks/useFlowTableColumns';
import { Verdict } from '~/domain/flows';
import { FlowDigest } from '~/domain/flows/common';
import * as helpers from '~/domain/helpers';
import { httpStatus } from '~/domain/http';
import { AuthType, L7FlowType, Layer7 } from '~/domain/hubble';

import { Column } from './general';
import css from './styles.scss';

export interface CellProps {
  flow: FlowDigest;
  kind: Column;
  width?: number;
}

export const Cell = observer(function FlowsTableCell(props: CellProps) {
  const verdictLabel = helpers.verdict.toString(props.flow.verdict);
  const authTypeLabel = helpers.authType.toString(props.flow.authType);

  switch (props.kind) {
    case Column.SrcCluster: {
      return (
        <div className={css.cell} style={{ width: formatWidth(props.width) }}>
          {props.flow.sourceCluster || <span className={css.emptyValue}>—</span>}
        </div>
      );
    }
    case Column.SrcNamespace: {
      return (
        <div className={css.cell} style={{ width: formatWidth(props.width) }}>
          {props.flow.sourceNamespace || <span className={css.emptyValue}>—</span>}
        </div>
      );
    }
    case Column.SrcPod: {
      return (
        <div
          className={css.cell}
          data-testid={Column.SrcPod}
          style={{ width: formatWidth(props.width) }}
        >
          {props.flow.sourcePodName || <span className={css.emptyValue}>—</span>}
        </div>
      );
    }
    case Column.SrcIp: {
      return (
        <div
          className={css.cell}
          data-testid={Column.SrcIp}
          style={{ width: formatWidth(props.width) }}
        >
          {props.flow.sourceIp}
        </div>
      );
    }
    case Column.SrcService: {
      const appName = props.flow.sourceService ?? 'No app name';
      return (
        <div
          className={css.cell}
          data-testid={Column.SrcService}
          style={{ width: formatWidth(props.width) }}
        >
          {appName}
        </div>
      );
    }
    case Column.DstCluster: {
      return (
        <div className={css.cell} style={{ width: formatWidth(props.width) }}>
          {props.flow.destinationCluster || <span className={css.emptyValue}>—</span>}
        </div>
      );
    }
    case Column.DstNamespace: {
      return (
        <div className={css.cell} style={{ width: formatWidth(props.width) }}>
          {props.flow.destinationNamespace || <span className={css.emptyValue}>—</span>}
        </div>
      );
    }
    case Column.DstPod: {
      return (
        <div
          className={css.cell}
          data-testid={Column.DstPod}
          style={{ width: formatWidth(props.width) }}
        >
          {props.flow.destinationPodName || <span className={css.emptyValue}>—</span>}
        </div>
      );
    }
    case Column.DstIp: {
      return (
        <div
          className={css.cell}
          data-testid={Column.DstIp}
          style={{ width: formatWidth(props.width) }}
        >
          {props.flow.destinationIp}
        </div>
      );
    }
    case Column.DstService: {
      const appName = props.flow.destinationDns
        ? props.flow.destinationDns
        : (props.flow.destinationService ?? '—');
      return (
        <div
          className={css.cell}
          data-testid={Column.DstService}
          style={{ width: formatWidth(props.width) }}
        >
          {appName}
        </div>
      );
    }
    case Column.DstPort: {
      const className = classnames(css.cell, css.dstPort);
      const port = props.flow.destinationPort || '-';
      return (
        <div
          className={className}
          data-testid={Column.DstPort}
          style={{ width: formatWidth(props.width) }}
        >
          {port}
        </div>
      );
    }
    case Column.L7Info: {
      const className = classnames(css.cell, css.l7info);

      if (props.flow.l7 == null)
        return (
          <div
            className={className}
            data-testid={Column.L7Info}
            style={{ width: formatWidth(props.width) }}
          >
            —
          </div>
        );

      const l7 = props.flow.l7!;
      const isRequest = l7.type === L7FlowType.Request;
      const isResponse = l7.type === L7FlowType.Response;

      return (
        <div
          className={className}
          data-testid={Column.L7Info}
          style={{ width: formatWidth(props.width) }}
        >
          <span
            className={css.direction}
            title={isRequest ? 'Request' : isResponse ? 'Response' : void 0}
          >
            {isRequest && <>&rarr;</>}
            {isResponse && <>&larr;</>}
          </span>

          {l7.dns != null && <>DNS</>}
          {l7.kafka != null && <>Kafka</>}
          {l7.http != null && (
            <span className={css.http} title={l7title(l7)}>
              <span className={css.method}>{l7.http.method}</span>
              {httpStatus.has(l7.http.code) && (
                <span className={css.status}>
                  {l7.http.code} {httpStatus.get(l7.http.code)}
                </span>
              )}
              <span className={css.path}>{parseUrl(l7.http.url).pathname}</span>
              <span className={css.latency}>{(l7.latencyNs / 1e6).toFixed(0)}ms</span>
            </span>
          )}
        </div>
      );
    }
    case Column.TrafficDirection: {
      return (
        <div
          className={classnames(css.cell, css.trafficDirection)}
          data-testid={Column.TrafficDirection}
          style={{ width: formatWidth(props.width) }}
        >
          {props.flow.trafficDirection}
        </div>
      );
    }
    case Column.Verdict: {
      const className = classnames(css.cell, css.verdict, {
        [css.forwardedVerdict]: props.flow.verdict === Verdict.Forwarded,
        [css.droppedVerdict]: props.flow.verdict === Verdict.Dropped,
        [css.auditVerdict]: props.flow.verdict === Verdict.Audit,
      });
      return (
        <div
          className={className}
          data-testid={Column.Verdict}
          style={{ width: formatWidth(props.width) }}
        >
          {verdictLabel}
        </div>
      );
    }
    case Column.TcpFlags: {
      return (
        <div
          className={classnames(css.cell, css.tcpFlags)}
          data-testid={Column.TcpFlags}
          style={{ width: formatWidth(props.width) }}
        >
          {helpers.tcpFlags.toString(props.flow.tcpFlags ?? null)}
        </div>
      );
    }
    case Column.AuthType: {
      const className = classnames(css.cell, css.authType, {
        [css.authTypeDisabled]: props.flow.authType === AuthType.Disbaled,
        [css.authTypeSpire]: props.flow.authType === AuthType.Spire,
        [css.authTypeFailed]: props.flow.authType === AuthType.TestAlwaysFail,
      });
      return (
        <div
          className={className}
          data-testid={Column.AuthType}
          style={{ width: formatWidth(props.width) }}
        >
          {authTypeLabel}
        </div>
      );
    }
    case Column.Timestamp: {
      const date = helpers.time.timeToDate(props.flow.time);
      const title = date.toISOString();

      const year = formatDate(date, 'yyyy/MM/dd');
      const hour = formatDate(date, 'HH:mm:ss (x)');
      const className = classnames(css.cell, css.timestamp);

      return (
        <div
          className={className}
          data-testid={Column.Timestamp}
          title={title}
          style={{ width: formatWidth(props.width) }}
        >
          <span className={css.cellLabel}>
            <span className={css.year}>{year}</span> {hour}
          </span>
        </div>
      );
    }
    default: {
      return null;
    }
  }
});

const l7title = (l7: Layer7): string => {
  if (l7.dns != null) return 'DNS';
  if (l7.kafka != null) return 'Kafka';
  if (l7.http == null) return '';

  let str = l7.http.method;

  if (httpStatus.has(l7.http.code)) {
    str += `${l7.http.code} ${httpStatus.get(l7.http.code)}`;
  }

  const pathname = parseUrl(l7.http.url).pathname;
  const latency = (l7.latencyNs / 1e6).toFixed(0);

  return `${str} ${pathname} ${latency}ms`;
};
