import { makeAutoObservable } from 'mobx';

import * as tsmappb from '~backend/proto/timescape/map/v1/map_pb';

import ControlStore from './controls';

export class ConnectionsMapStore {
  kind: tsmappb.ConnectionKind = tsmappb.ConnectionKind.CLUSTERS;

  private _map: tsmappb.Connections | null = null;

  constructor(private _controls: ControlStore) {
    makeAutoObservable(this, void 0, { autoBind: true });
  }

  get map(): tsmappb.Connections {
    const map: tsmappb.Connections = {
      clusters: {},
      connections: [],
    };

    if (!this._map) {
      return map;
    }

    map.clusters = { ...this._map.clusters };
    map.connections = [];

    this._map.connections.forEach(conn => {
      if (
        conn.kind === tsmappb.ConnectionKind.KUBEAPI ||
        conn.kind === tsmappb.ConnectionKind.NODE
      ) {
        if (!conn.src_cluster && !conn.src_namespace) {
          map.connections.push({
            ...conn,
            kind: tsmappb.ConnectionKind.CLUSTERS,
            src_cluster: 'reserved:host',
          });
          map.clusters['reserved:host'] = { name: 'reserved:host', namespaces: {} };
        } else if (!conn.dst_cluster && !conn.dst_namespace) {
          map.connections.push({
            ...conn,
            kind: tsmappb.ConnectionKind.CLUSTERS,
            dst_cluster: 'reserved:host',
          });
          map.clusters['reserved:host'] = { name: 'reserved:host', namespaces: {} };
        }
      } else if (conn.kind === tsmappb.ConnectionKind.WORLD) {
        map.connections.push({
          ...conn,
          kind: tsmappb.ConnectionKind.CLUSTERS,
          dst_cluster: 'reserved:world',
        });
        map.clusters['reserved:world'] = { name: 'reserved:world', namespaces: {} };
      } else {
        map.connections.push(conn);
      }
    });

    return map;
  }

  replaceWith(kind: tsmappb.ConnectionKind, map: tsmappb.Connections | null) {
    this.kind = kind;
    this._map = map;
  }

  clear() {
    this._map = null;
  }
}
