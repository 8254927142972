import React, { useMemo } from 'react';

import classNames from 'classnames';
import { FixedSizeList, VariableSizeList } from 'react-window';

import { Dictionary } from '~/domain/misc';

import css from './styles.scss';

export interface Props<T> {
  data: T[];
  itemRenderer: React.FC<ItemProps>;
  itemKey: (idx: number, data: T) => string;

  className?: string;
  headerClassName?: string;
  visibleColumns?: string[]; // NOTE: using array here to know the order of cols
  captions?: Map<string, string> | Dictionary<string>;
  itemSize?: number;
  listHeight?: number;
  headerHeight?: number;

  headerRenderer?: (captions?: Map<string, string>, visibleColumns?: string[]) => JSX.Element;

  getItemSize?: (idx: number) => number;
  // NOTE: rowIdx is -1 when click is on header
  onCellClick?: (rowIdx: number, columnKey: string) => void;
}

export interface ItemProps {
  index: number;
  style: React.CSSProperties;
  isScrolling?: boolean;
}

export const Table = function Table<T>(props: Props<T>) {
  const captions = getCaptions(props);
  const visibleColumns = getVisibleColumns(props);

  const tableClasses = classNames(css.table, props.className);

  const noVisibleData = captions == null || visibleColumns == null;
  const noWayToGetItemSize = props.getItemSize == null && props.itemSize == null;

  if (noVisibleData || noWayToGetItemSize) {
    return <div className={tableClasses}></div>;
  }

  const ItemRenderer = props.itemRenderer;

  const listHeight = useMemo(() => {
    if (props.listHeight == null) {
      const base = (props.itemSize ?? 0) * props.data.length;

      if (props.itemSize != null) return base - (props.headerHeight ?? 0);

      return '100%';
    }

    return props.listHeight! - (props.headerHeight ?? 0);
  }, [props.listHeight, props.itemSize, props.getItemSize, props.headerHeight]);

  // TODO: currently full table is rendered. This is for development speed purpose
  // TOOD: need a mechanism to properly calculate amount of rows to render
  return (
    <div className={tableClasses}>
      <div className={classNames(css.header, props.headerClassName)}>
        {renderHeader(props, captions!, visibleColumns!)}
      </div>

      <div className={css.rows}>
        {props.getItemSize != null && (
          <VariableSizeList
            useIsScrolling={true}
            itemCount={props.data.length}
            height={listHeight}
            width="100%"
            itemSize={props.getItemSize}
            itemKey={props.itemKey}
          >
            {ItemRenderer}
          </VariableSizeList>
        )}

        {props.getItemSize == null && props.itemSize != null && (
          <FixedSizeList
            useIsScrolling={true}
            itemCount={props.data.length}
            height={listHeight}
            width="100%"
            itemSize={props.itemSize}
            itemKey={props.itemKey}
          >
            {ItemRenderer}
          </FixedSizeList>
        )}
      </div>
    </div>
  );
};

const renderHeader = (
  props: Props<any>,
  captions: Map<string, string>,
  visibleColumns: string[],
): React.ReactNode => {
  if (props.headerRenderer != null) {
    return props.headerRenderer(captions, visibleColumns);
  }

  return (
    <>
      {visibleColumns.map(colKey => {
        const colCaption = captions.get(colKey)!;

        return (
          <div
            className={classNames(css.cell)}
            key={colKey}
            onClick={() => props.onCellClick?.(-1, colKey)}
          >
            {colCaption}
          </div>
        );
      })}
    </>
  );
};

const getVisibleColumns = (props: Props<any>): string[] | null => {
  if (props.visibleColumns != null) return props.visibleColumns;

  if (props.data.length === 0) return null;
  return Object.keys(props.data[0]).sort();
};

const getCaptions = (props: Props<any>): Map<string, string> | null => {
  const { captions } = props;
  if (captions instanceof Map) return captions;

  const mapCaptions = new Map();
  if (captions != null) {
    Object.keys(captions).forEach(key => {
      mapCaptions.set(key, key);
    });

    return mapCaptions;
  }

  if (props.data.length === 0) return null;
  Object.keys(props.data[0]).forEach(key => {
    mapCaptions.set(key, key);
  });

  return mapCaptions;
};
