import * as React from 'react';

import { Checkbox } from '@blueprintjs/core';
import { observer } from 'mobx-react';

import css from './VisualsFiltersList.scss';

export interface Props {
  showHost?: boolean;
  onShowHostToggle?: () => void;
  showKubeDns?: boolean;
  onShowKubeDnsToggle?: () => void;
  showRemoteNode?: boolean;
  onShowRemoteNodeToggle?: () => void;
  showPrometheusApp?: boolean;
  onShowPrometheusAppToggle?: () => void;
}

export const VisualsFiltersList = observer(function VisualsFiltersList(props: Props) {
  return (
    <div className={css.wrapper}>
      <Checkbox checked={props.showHost} label="Host service" onChange={props.onShowHostToggle} />
      <Checkbox
        checked={props.showKubeDns}
        label="Kube-DNS:53 pod"
        onChange={props.onShowKubeDnsToggle}
      />
      <Checkbox
        checked={props.showRemoteNode}
        label="Remote node"
        onChange={props.onShowRemoteNodeToggle}
      />
      <Checkbox
        checked={props.showPrometheusApp}
        label="Prometheus app"
        onChange={props.onShowPrometheusAppToggle}
      />
    </div>
  );
});
