import * as mobx from 'mobx';

import { XY } from '~/domain/geometry';
import { Kind as EventKind } from '~/domain/process-events';
import { PodNode, Processes, Tree as TreeData } from '~/domain/process-tree';
import { RefsCollector } from '~/ui/process-tree/collector';
import { TreeLayout } from '~/ui/process-tree/layout';

import {
  DestinationArrows,
  ExecIDToExecIDArrows,
  PidToChildGroupArrows,
  ProcessArrows,
} from './helpers';

export type CalculatedArrows = {
  node: Map<string, Map<string, XY[]>>;
  ps: Map<string, ExecIDToExecIDArrows>;
  container: Map<string, ExecIDToExecIDArrows>;
  destination: Map<string, DestinationArrows>;
  childGroups: Map<string, PidToChildGroupArrows>;
};

export class ProcessTreeArrowsLayout {
  public calculated: CalculatedArrows = {
    node: new Map(),
    ps: new Map(),
    container: new Map(),
    destination: new Map(),
    childGroups: new Map(),
  };

  constructor(
    private collector: RefsCollector,
    private layout: TreeLayout,
    private treeData: TreeData,
  ) {
    mobx.makeAutoObservable(this);
  }

  public release(partialUpdateFlags?: { onlyDestinationArrows?: boolean }) {
    const onlyDestinationArrows = !!partialUpdateFlags?.onlyDestinationArrows;
    const nodeArrows: Map<string, Map<string, XY[]>> = new Map();

    // NOTE: map { handleId -> { parentExecId -> { childExecId -> ConnectorCoords }}}
    const psArrows: Map<string, ExecIDToExecIDArrows> = new Map();
    const containerArrows: Map<string, ExecIDToExecIDArrows> = new Map();
    const allChildGroupsArrows: Map<string, PidToChildGroupArrows> = new Map();
    const destinationArrows: Map<string, DestinationArrows> = new Map();

    this.treeData.forEachPodNode((_node, _nsNode, pod, handleId) => {
      const nodeConnectorCoords = this.layout.nodeConnectorCoords.get(handleId);
      if (nodeConnectorCoords == null) return;

      const podConnectors = this.layout.podHandleConnectorCoords.get(handleId);
      if (podConnectors == null) return;

      const containerCoords = this.layout.containerConnectorCoords.get(handleId);

      if (!nodeArrows.has(handleId)) {
        nodeArrows.set(handleId, new Map());
      }

      const podNodeArrows = nodeArrows.get(handleId)!;
      const [orphans] = pod.processes.rootExecIds;
      const processArrows = new ProcessArrows();
      const toDestinationArrows = new DestinationArrows(this.layout);

      orphans.forEach((_, rootExecId) => {
        const rootConnectorCoords = podConnectors?.get(rootExecId);
        const rootContainerCoords = containerCoords?.get(rootExecId);
        const topCoords = rootContainerCoords ?? rootConnectorCoords;
        if (topCoords == null) return;

        // NOTE: if topCoords is container coords, we need an additional
        // arrow from that root container to its first process... (1)
        podNodeArrows.set(rootExecId, [nodeConnectorCoords, topCoords]);

        this.buildDestinationArrows(handleId, pod, rootExecId, toDestinationArrows);
        if (onlyDestinationArrows) return;

        const { toContainerArrows } = this.buildProcessArrows(
          handleId,
          pod.processes,
          rootExecId,
          processArrows,
        );

        if (rootContainerCoords == null) return;

        // NOTE: (1) ...and here is where we fix that
        if (!toContainerArrows.has(rootExecId)) {
          toContainerArrows.set(rootExecId, new Map());
        }

        const psContainerArrows = toContainerArrows.get(rootExecId)!;
        if (psContainerArrows.has(rootExecId)) return;

        psContainerArrows.set(rootExecId, [rootContainerCoords, rootConnectorCoords!]);
      });

      psArrows.set(handleId, processArrows.toProcessArrows);
      containerArrows.set(handleId, processArrows.toContainerArrows);
      allChildGroupsArrows.set(handleId, processArrows.toChildGroupsArrows);
      destinationArrows.set(handleId, toDestinationArrows);
    });

    const newCalculated = onlyDestinationArrows
      ? {
          destination: destinationArrows,
        }
      : {
          destination: destinationArrows,
          node: nodeArrows,
          ps: psArrows,
          container: containerArrows,
          childGroups: allChildGroupsArrows,
        };

    this.copyIntoCalculated(newCalculated);
  }

  private copyIntoCalculated(arrows: Partial<CalculatedArrows>) {
    if (arrows.destination != null) {
      this.calculated.destination.clear();

      arrows.destination.forEach((d, key) => {
        this.calculated.destination.set(key, d);
      });
    }

    if (arrows.node != null) {
      this.calculated.node.clear();

      arrows.node.forEach((d, key) => {
        this.calculated.node.set(key, d);
      });
    }

    if (arrows.ps != null) {
      this.calculated.ps.clear();

      arrows.ps.forEach((d, key) => {
        this.calculated.ps.set(key, d);
      });
    }

    if (arrows.container != null) {
      this.calculated.container.clear();

      arrows.container.forEach((d, key) => {
        this.calculated.container.set(key, d);
      });
    }

    if (arrows.childGroups != null) {
      this.calculated.childGroups.clear();

      arrows.childGroups.forEach((d, key) => {
        this.calculated.childGroups.set(key, d);
      });
    }
  }

  public reset() {
    this.calculated.node.clear();
    this.calculated.ps.clear();
    this.calculated.container.clear();
    this.calculated.destination.clear();
    this.calculated.childGroups.clear();
  }

  private buildProcessArrows(
    handleId: string,
    pss: Processes,
    rootExecId: string | null,
    arrows: ProcessArrows,
  ): ProcessArrows {
    if (rootExecId == null) return arrows;

    const childGroups = pss.childrenGroups.get(rootExecId);
    if (childGroups == null || childGroups.size === 0) return arrows;

    const processConnectors = this.layout.podHandleConnectorCoords.get(handleId);
    const containerConnectors = this.layout.containerConnectorCoords.get(handleId);
    const childGroupConnectors = this.layout.childGroupConnectorCoords.get(handleId);

    const groupStates = this.layout.childGroupStates.get(handleId);
    // NOTE: rootCoords is always below container coords
    const rootCoords = processConnectors?.get(rootExecId);
    if (rootCoords == null) return arrows;

    childGroups.forEach((group, groupKey) => {
      const groupCoords = childGroupConnectors?.get(groupKey);
      const groupExpanded = !!groupStates?.get(groupKey);

      // NOTE: no child group case
      if (groupCoords == null) {
        const execId = [...group.execIds][0];
        const containerCoords = containerConnectors?.get(execId);
        const connectorCoords = processConnectors?.get(execId);

        if (containerCoords != null && connectorCoords != null) {
          // NOTE: arrow to container handle and from container to connector
          arrows.toContainer(rootExecId, execId, [rootCoords, containerCoords]);
          arrows.toProcess(execId, execId, [containerCoords, connectorCoords]);
        } else if (connectorCoords != null) {
          // NOTE: arrow directly to connector (no container)
          arrows.toProcess(rootExecId, execId, [rootCoords, connectorCoords]);
        }

        this.buildProcessArrows(handleId, pss, execId, arrows);
      } else {
        // NOTE: here we have many child execIds and there is a group for them
        arrows.toChildGroup(rootExecId, groupKey, [rootCoords, groupCoords]);

        if (groupExpanded) {
          group.execIds.forEach(childExecId => {
            // TODO: Is it possible to have another child group right inside of this child?
            const childContainerCoords = containerConnectors?.get(childExecId);
            const childConnectorCoords = processConnectors?.get(childExecId);
            if (childConnectorCoords == null) return;

            if (childContainerCoords != null) {
              // NOTE: Arrow from group handle to container handle
              arrows.toContainer(childExecId, childExecId, [groupCoords, childContainerCoords]);

              // NOTE: And one more arrow from container handle to process connector
              arrows.toProcess(childExecId, childExecId, [
                childContainerCoords,
                childConnectorCoords,
              ]);
            } else {
              arrows.toProcess(childExecId, childExecId, [groupCoords, childConnectorCoords]);
            }

            this.buildProcessArrows(handleId, pss, childExecId, arrows);
            // rootProcessArrows.set(execId, [groupCoords, childConnectorCoords]);
            // arrows.childGroupToConnector()
          });
        }
      }
    });

    return arrows;
  }

  private isProcessLineVisible(handleId: string, execId: string): boolean {
    return this.collector.isProcessLineRefAssigned(handleId, execId);
  }

  private buildDestinationArrows(
    handleId: string,
    pod: PodNode,
    rootExecId: string | null,
    existingArrows?: DestinationArrows,
  ): DestinationArrows {
    const allArrows = existingArrows ?? new DestinationArrows(this.layout);
    if (rootExecId == null) return allArrows;

    // NOTE: This is a reliable way to understand if arrow should be rendered
    if (!this.isProcessLineVisible(handleId, rootExecId)) {
      return allArrows;
    }

    const pss = pod.processes;
    const connectEvents = pss.events.get(rootExecId)?.get(EventKind.Connect);

    // NOTE: use connectEvents to build destination arrows to appropriate
    // NOTE: destination groups
    connectEvents?.forEach(evt => {
      const cnct = evt.process_connect;
      if (cnct == null) return;

      const { destinationIp: ip, destinationPort: port } = cnct;
      const egressGroup = this.treeData.getDestinationGroupByConnectEvent(cnct);
      if (!egressGroup) return;

      allArrows.arrow(handleId, rootExecId, egressGroup, ip, port);
    });

    // NOTE: continue building destinationArrows from child processes
    const firstLevel = pss.children.get(rootExecId);
    firstLevel?.forEach(execId => {
      if (execId === rootExecId) return;

      // TODO: transform this into iterative algorithm
      this.buildDestinationArrows(handleId, pod, execId, allArrows);
    });

    return allArrows;
  }
}
