import * as mobx from 'mobx';
import { when } from 'mobx';

import { DataLayer } from '~/data-layer';
import { Application, Direction } from '~/domain/common';
import { FilterGroup } from '~/domain/filtering';
import { Flow } from '~/domain/flows';
import { ServiceCard } from '~/domain/service-map';
import { Router } from '~/router';
import { getHideDropFiltersMessage, saveHideDropFiltersMessage } from '~/storage/local';
import { Store } from '~/store';
import { RefsCollector } from '~/ui/service-map/collector';
import { Options } from '~/ui-layer/common';
import { ServiceMapArrowStrategy } from '~/ui-layer/service-map/coordinates/arrows';
import { ServiceMapPlacementStrategy } from '~/ui-layer/service-map/coordinates/placement';
import { StatusCenter } from '~/ui-layer/status-center';
import { EventEmitter } from '~/utils/emitter';
import { logger } from '~/utils/logger';
import { Timer } from '~/utils/timer';

export enum Event {
  ArrowsDropped = 'arrows-dropped',
}

export type Handlers = {
  [Event.ArrowsDropped]: () => void;
};

export class ServiceMap extends EventEmitter<Handlers> {
  private readonly store: Store;
  private readonly dataLayer: DataLayer;
  private readonly statusCenter: StatusCenter;
  private readonly router: Router;

  public readonly collector: RefsCollector;
  public readonly placement: ServiceMapPlacementStrategy;
  public readonly arrows: ServiceMapArrowStrategy;

  @mobx.observable
  showFilterDropWarning = false;

  public acceptToDropFilter = false;

  @mobx.observable
  public shouldHideDropFIltersMessagePermanently = false;

  @mobx.observable
  public isTimescapeFlowsPageLoading = false;

  @mobx.observable
  public isTimescapeFlowStatsLoading = false;

  @mobx.observable
  public isFullFlowLoading = false;

  @mobx.observable
  public isServiceMapLogsUploading = false;

  constructor(opts: Options) {
    super();

    this.store = opts.store;
    this.dataLayer = opts.dataLayer;
    this.statusCenter = opts.statusCenter;
    this.router = opts.router;

    this.collector = new RefsCollector(this.store.currentFrame);
    this.placement = new ServiceMapPlacementStrategy(this.store.currentFrame);
    this.arrows = new ServiceMapArrowStrategy(
      this.dataLayer,
      this.store.currentFrame,
      this.placement,
    );

    mobx.makeObservable(this);

    this.setupEventHandlers();
  }

  public onArrowsDrop(fn: Handlers[Event.ArrowsDropped]): this {
    this.on(Event.ArrowsDropped, fn);
    return this;
  }

  @mobx.action
  public async advanceTimescapeFlowsPager() {
    if (this.isTimescapeFlowsPageLoading) return;

    await this.dataLayer.serviceMap.fetchTimescapeFlowsNextPage();
  }

  public async uploadServiceMapLogs(logs: ArrayBuffer) {
    return this.dataLayer.serviceMap.uploadServiceMapLogs(logs);
  }

  @mobx.action
  public setFlowStatsLoading(state: boolean) {
    this.isTimescapeFlowStatsLoading = state;
  }

  @mobx.action
  public setFullFlowLoading(state: boolean) {
    this.isFullFlowLoading = state;
  }

  @mobx.action
  public setFlowsPageLoading(state: boolean) {
    this.isTimescapeFlowsPageLoading = state;
  }

  @mobx.action
  public setServiceMapLogsUploading(state: boolean) {
    this.isServiceMapLogsUploading = state;
  }

  public onCardSelect(card: { filterGroups: FilterGroup[] }) {
    this.dataLayer.serviceMap.toggleActiveCardFilterGroup(card.filterGroups);
  }

  public openProcessTreeForCard(card: { appLabel?: string | null }) {
    this.dataLayer.processTree.setPodPrefilterFromServiceCard(card);
    this.router.openApplication(Application.ProcessTree).commit();
  }

  public openProcessTreeForFlow(f: Flow, dir: Direction) {
    logger.log(`openProcessTreeForFlow`, f, dir);
    this.dataLayer.processTree.setPodPrefilterFromFlow(f, dir);
    this.router.openApplication(Application.ProcessTree).commit();
  }

  public cardsMutationsObserved() {
    this.collector.cardsMutationsObserved();
  }

  public toggleDetached() {
    logger.log(`toggleDetached: dropping layout / collector for service map`);
    this.clearCoordinates();
  }

  public clearCoordinates() {
    this.collector.clear();
    this.placement.reset();
    this.arrows.reset();
  }

  public isCardActive(card: ServiceCard) {
    return this.dataLayer.controls.areSomeFilterGroupsEnabled(card.filterGroups);
  }

  public async appToggled(next: Application, isChanged: boolean) {
    if (!isChanged) return;

    switch (next) {
      case Application.ConnectionsMap: {
        // NOTE: This drop is needed to fix incorrect cards sizing after app switch
        mobx.runInAction(() => {
          this.clearCoordinates();
        });

        await this.dataLayer.serviceMap.appOpened();
        break;
      }
      case Application.Cimulator: {
        await this.dataLayer.serviceMap.enablePoliciesFetch();
        break;
      }
    }
  }

  private setupEventHandlers() {
    this.collector.onCoordsUpdated(coords => {
      // NOTE: This runInAction wrapping ensures that no reactions will be
      // triggered in between of those `set` calls. They will be called only
      // once, after arrows rebuild procedure.
      this.emit(Event.ArrowsDropped);
      mobx.runInAction(() => {
        // NOTE: We only set card dimensions here, so they are valid even if
        // card was rendered in invisible area with -100500 coords.
        this.placement.setCardHeights(coords.cards, 0.5);

        // NOTE: Access points are different, we don't need their dimensions
        // and store exact position of its center, even from invisible area with
        // -100500 coords. Thus we need to check if card was correctly placed
        // and if it wasn't, skip and wait for another coords.
        coords.accessPoints.forEach(apCoords => {
          const isCardPositioned = !!this.placement.cardsCoords.get(apCoords.cardId);
          if (!isCardPositioned) return;

          this.placement.setAccessPointCoords(apCoords.id, apCoords.bbox.leftEdgeCenter);
        });

        coords.httpEndpoints.forEach(apCoords => {
          const isCardPositioned = !!this.placement.cardsCoords.get(apCoords.cardId);
          if (!isCardPositioned) return;
          this.placement.setHttpEndpointCoords(
            apCoords.cardId,
            apCoords.urlPath,
            apCoords.method,
            apCoords.bbox.center,
          );
        });

        this.arrows.rebuild();
      });
    });

    const flowsPageTimer = Timer.new(300).onTimeout(() => {
      this.setFlowsPageLoading(true);
    });

    this.dataLayer.serviceMap.onTimescapeFlowsPageLoadingStarted(() => {
      flowsPageTimer.reset();
    });

    this.dataLayer.serviceMap.onTimescapeFlowsPageLoadingFinished(() => {
      flowsPageTimer.stop();
      this.setFlowsPageLoading(false);
    });

    this.dataLayer.serviceMap.onTimescapeFlowsPageLoadingFailed(err => {
      this.setFlowsPageLoading(false);
      this.statusCenter.pushTimescapeError(
        err,
        'Failed to fetch page of flows from hubble-timescape',
      );
    });

    this.dataLayer.serviceMap.onTimescapeFlowStatsLoadingStarted(() => {
      this.setFlowStatsLoading(true);
    });

    this.dataLayer.serviceMap.onTimescapeFlowStatsLoadingFinished(() => {
      this.setFlowStatsLoading(false);
    });

    this.dataLayer.serviceMap.onTimescapeFlowStatsLoadingFailed(err => {
      this.setFlowStatsLoading(false);
      this.statusCenter.pushTimescapeError(
        err,
        'Failed to fetch flows stats from hubble-timescape',
      );
    });

    const fullFlowTimer = Timer.new(100).onTimeout(() => {
      this.setFullFlowLoading(true);
    });

    this.dataLayer.serviceMap.onFullFlowLoadingStarted(() => {
      fullFlowTimer.reset();
    });

    this.dataLayer.serviceMap.onFullFlowLoadingFinished(() => {
      fullFlowTimer.stop();
      this.setFullFlowLoading(false);
    });

    this.dataLayer.serviceMap.onFullFlowLoadingFailed(err => {
      this.statusCenter.pushTimescapeError(err, 'Failed to fetch flow details from timescape');
    });

    const logsUploadingTimer = Timer.new(300).onTimeout(() => {
      this.setServiceMapLogsUploading(true);
    });

    this.dataLayer.serviceMap.onServiceMapLogsUploadingStarted(() => {
      logsUploadingTimer.reset();
    });

    this.dataLayer.serviceMap.onServiceMapLogsUploadingFinished(() => {
      logsUploadingTimer.stop();
      this.setServiceMapLogsUploading(false);
    });

    this.dataLayer.serviceMap.onServiceMapLogsUploadingFailed(err => {
      // TODO: Show some real user notification
      logger.error('service map uploading failed: ', err);
      this.statusCenter.pushError(err, 'Parsing service map from logs failed');
    });

    this.store.currentFrame.onFlushed(() => {
      this.clearCoordinates();
    });
  }

  @mobx.action
  public toggleHideDropFiltersMessage() {
    this.shouldHideDropFIltersMessagePermanently = !this.shouldHideDropFIltersMessagePermanently;
  }

  @mobx.action
  public cancelFilterDrop() {
    this.acceptToDropFilter = false;
    this.showFilterDropWarning = false;
  }

  @mobx.action
  public confirmFilterDrop() {
    if (this.shouldHideDropFIltersMessagePermanently) {
      saveHideDropFiltersMessage();
    }
    this.acceptToDropFilter = true;
    this.showFilterDropWarning = false;
  }

  @mobx.action
  public async askUserFiltersDrop(): Promise<boolean> {
    if (getHideDropFiltersMessage() != null) {
      return Promise.resolve(true);
    }

    this.showFilterDropWarning = true;
    this.acceptToDropFilter = false;
    await when(() => !this.showFilterDropWarning);

    return this.acceptToDropFilter;
  }
}
