import { makeAutoObservable } from 'mobx';

import { Flow } from '~/domain/flows';
import { PodWithoutContainer, ProcessEvent } from '~/domain/process-events';

import { PodNode } from './pod-node';

export class NamespaceNode {
  public name: string;
  public pods: Map<string, PodNode> = new Map();

  public static new(name: string) {
    return new NamespaceNode(name);
  }

  constructor(name: string) {
    this.name = name;

    makeAutoObservable(this);
  }

  public saveEvent(evt: ProcessEvent): boolean {
    const pod = evt.pod;
    if (pod == null) return false;

    const podNode = this.ensureNode(pod);
    return podNode.saveEvent(evt);
  }

  public saveFlow(flow: Flow): boolean {
    const sourcePod = flow.sourceProcessEventPod;
    if (sourcePod == null) return false;

    const podNode = this.ensureNode(sourcePod);
    return podNode.saveFlow(flow);
  }

  private ensureNode(pod: PodWithoutContainer): PodNode {
    if (this.pods.has(pod.name)) return this.pods.get(pod.name)!;

    const node = PodNode.new(pod);
    this.pods.set(pod.name, node);

    return node;
  }
}
