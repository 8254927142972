import React, { useCallback, useState } from 'react';

import { Popover, Tag } from '@blueprintjs/core';
import classnames from 'classnames';
import { observer } from 'mobx-react';

import { useApplication } from '~/application';
import dashboardIconDark from '~/assets/icons/navigation-icon-dashboard-dark.png';
import dashboardIcon from '~/assets/icons/navigation-icon-dashboard.png';
import metricsIconDark from '~/assets/icons/navigation-icon-metrics-dark.png';
import metricsIcon from '~/assets/icons/navigation-icon-metrics.png';
import networkPoliciesIconDark from '~/assets/icons/navigation-icon-network-policies-dark.png';
import networkPoliciesIcon from '~/assets/icons/navigation-icon-network-policies.png';
import processTreeIconDark from '~/assets/icons/navigation-icon-process-tree-dark.png';
import processTreeIcon from '~/assets/icons/navigation-icon-process-tree.png';
import serviceMapIconDark from '~/assets/icons/navigation-icon-service-map-dark.png';
import serviceMapIcon from '~/assets/icons/navigation-icon-service-map.png';
import { UserDropdown } from '~/components/Authorization/UserDropdown';
import { DashboardVariant } from '~/components/Dashboard/types';
import { FileUploader, ReadKind } from '~/components/FileUploader';
import { PoliciesList } from '~/components/PoliciesPanel/PoliciesSidebar';
import { LogsUpload } from '~/components/ProcessTreeApp/LogsUpload';
import { PodSelection } from '~/components/ProcessTreeApp/PodSelection';
import { StatusCenterWindow } from '~/components/StatusCenterWindow';
import { TimeRangePickerButton } from '~/components/TimeRangePicker';
import { Aggregation } from '~/domain/aggregation';
import { Application } from '~/domain/common';
import { Verdict } from '~/domain/hubble';
import { DataMode, TransferState } from '~/domain/interactions';
import { TimeRange } from '~/domain/time';
import { useStore } from '~/store';
import { Keys } from '~/ui/status-center';
import { WidgetControl } from '~/ui/widgets/control';

import { AggregationFilter } from './AggregationFilter';
import { ClusterSelectorDropdown } from './ClusterSelectorDropdown';
import { ConnectionIndicator } from './ConnectionIndicator';
import { DataModeSwitcher } from './DataModeSwitcher';
import { MainNavigationItem } from './MainNavigationItem';
import { NamespaceSelectorDropdown } from './NamespaceSelectorDropdown';
import css from './NavigationSidebar.scss';
import { StatusCenterHandle } from './StatusCenterHandle';
import { ThemeSwitcher } from './ThemeSwitcher';
import { VerdictsSelectorDropdown } from './VerdictsSelectorDropdown';
import { VisualsFiltersList } from './VisualFiltersList';

export enum E2E {
  root = `nav-sidebar`,
  mainMenu = `main-menu`,
}

export interface Props {
  transferState: TransferState;
  clustersList?: string[];
  currCluster?: string | null;
  namespacesList?: string[];
  currNamespace?: string | null;
  selectedVerdicts?: Set<Verdict>;
  showHost?: boolean;
  showKubeDns?: boolean;
  showRemoteNode?: boolean;
  showPrometheusApp?: boolean;
  hideNamespaceSelector?: boolean;
  aggregation?: Aggregation;
  isProcessTreeLogsUploading?: boolean;
  processTreeAvailablePods?: string[];
  processTreeSelectedPod?: string;
  podSelectionControl?: WidgetControl;
  initialPodQuery?: string;
  timeRange?: TimeRange;
  isServiceMapLogsUploading?: boolean;
  isPodsSpinnerVisible?: boolean;
  isFlowsPageLoading?: boolean;
  processTreeSlotRef?: React.MutableRefObject<HTMLDivElement | null>;
  onAggregationChange?: (_: boolean) => void;
  onAppSelect?: (app: Application, dashboardVariant?: DashboardVariant) => void;
  onClusterNamespaceChange?: (cluster: string | null, namespace: string | null) => void;
  onVerdictToggle?: (verdict: Verdict) => void;
  onShowHostToggle?: () => void;
  onShowKubeDnsToggle?: () => void;
  onShowRemoteNodeToggle?: () => void;
  onShowPrometheusAppToggle?: () => void;
  onOpenPolicy?: (id: string) => void;
  onProcessTreeLogsUploaded?: (logs: string) => void;
  onProcessTreeLogsUploadError?: (err: DOMException | null) => void;
  setProcessTreeLogsIsUploading?: (state: boolean) => void;
  onProcessTreeReadingStart?: () => void;
  onProcessTreePodSelect?: (pod: string) => void;
  onTimeRangeChange?: (_: TimeRange) => void;
  onServiceMapLogs?: (logs: ArrayBuffer) => void;
  onLoginAgainClick?: () => void;
}

export const NavigationSidebar = observer(function NavigationSidebar(props: Props) {
  const store = useStore();
  const { ui } = useApplication();
  const dataMode = props.transferState.dataMode;
  const statusCenter = ui.statusCenter;

  const themeStore = store.themes;
  const isDarkThemeActive = themeStore.isDarkTheme;

  const onClusterChange = useCallback(
    (cluster: string | null) => {
      props.onClusterNamespaceChange?.(cluster, null);
    },
    [props.onClusterNamespaceChange],
  );

  const onNamespaceChange = useCallback(
    (namespace: string | null) => {
      props.onClusterNamespaceChange?.(props.currCluster ?? '', namespace);
    },
    [props.onClusterNamespaceChange, props.currCluster],
  );

  const onConnectionsMapSelect = useCallback(() => {
    props.onAppSelect?.(Application.ConnectionsMap);
  }, [props.onAppSelect]);

  const onProcessTreeSelect = useCallback(() => {
    props.onAppSelect?.(Application.ProcessTree);
  }, [props.onAppSelect]);

  const onCimulatorSelect = useCallback(() => {
    props.onAppSelect?.(Application.Cimulator);
  }, [props.onAppSelect]);

  const [isStatusCenterOpen, setStatusCenterOpen] = useState(false);
  const toggleStatusCenterWindow = useCallback((nextState: boolean) => {
    setStatusCenterOpen(nextState);
    statusCenter.setSeen();
  }, []);

  const namespaceDropdownDisabled =
    store.controls.isRbacNoRoles ||
    (store.controls.app.isProcessTree &&
      store.processTree.isLocalMode &&
      store.processTree.namespaces.length === 0);

  const showVisualFilters = store.controls.app.isConnectionsMap;

  const showPoliciesList = store.controls.app.isCimulator && props.currNamespace;

  const isDataModeSwitcherVisible = !(
    store.uiSettings.isTimescapeOnlyEnabled || store.uiSettings.isTetragonOnlyEnabled
  );
  const dataModeSwitcher = isDataModeSwitcherVisible ? (
    <div className={`${css.section}`} id="data-mode-switcher">
      <DataModeSwitcher />
    </div>
  ) : null;

  const isClusterSelectorVisible =
    store.uiSettings.isTimescapeEnabled && props.clustersList?.length;
  const isClusterSelectorDisabled = dataMode === DataMode.CiliumStreaming;
  const clusterSection = isClusterSelectorVisible ? (
    <div className={`${css.clusterWrapper} ${css.section}`}>
      <div className={css.title}>Cluster</div>
      <ClusterSelectorDropdown
        clusters={props.clustersList ?? []}
        cluster={props.currCluster ?? null}
        disabled={isClusterSelectorDisabled || namespaceDropdownDisabled}
        onChange={onClusterChange}
        placeholderText={'Choose cluster'}
      />
    </div>
  ) : null;

  const namespaceSection = (
    <div className={`${css.namespaceWrapper} ${css.section}`}>
      <div className={css.title}>Namespace</div>
      <NamespaceSelectorDropdown
        namespacesList={props.namespacesList ?? []}
        currNamespace={props.currNamespace ?? null}
        disabled={namespaceDropdownDisabled}
        onChange={onNamespaceChange}
        placeholderText={'Choose namespace'}
      />
    </div>
  );

  const timeRangeSection = (
    <div className={`${css.timeRangePicker} ${css.section}`}>
      <div className={css.title}>Time range</div>
      <TimeRangePickerButton
        className={css.pickerButton}
        timeRange={props.timeRange}
        onTimeRangeChange={props.onTimeRangeChange}
        isMinimal={false}
        isVertical={true}
        isLoading={props.isFlowsPageLoading}
      />
    </div>
  );

  const filtersSection = (
    <div className={`filters-wrapper ${css.section}`}>
      <div className={css.title}>Flows verdict</div>
      <VerdictsSelectorDropdown
        selectedVerdicts={props.selectedVerdicts ?? new Set()}
        onVerdictToggle={props.onVerdictToggle}
      />
      <div style={{ marginTop: '24px', marginBottom: '24px' }}>
        <AggregationFilter
          enabled={!!props.aggregation}
          onAggregationChange={props.onAggregationChange}
        />
      </div>
      {showVisualFilters && (
        <div className={css.title} style={{ marginTop: '16px' }}>
          Visual filters
        </div>
      )}
      {showVisualFilters && (
        <VisualsFiltersList
          showHost={!!props.showHost}
          showKubeDns={!!props.showKubeDns}
          showRemoteNode={!!props.showRemoteNode}
          showPrometheusApp={!!props.showPrometheusApp}
          onShowHostToggle={props.onShowHostToggle}
          onShowKubeDnsToggle={props.onShowKubeDnsToggle}
          onShowRemoteNodeToggle={props.onShowRemoteNodeToggle}
          onShowPrometheusAppToggle={props.onShowPrometheusAppToggle}
        />
      )}
      {!!store.clusterNamespaces.currDescriptor &&
        store.uiSettings.isServiceMapUploadEnabled &&
        props.transferState.isWatchingHistory && (
          <FileUploader
            className={css.serviceMapLogsUploader}
            caption="Upload events"
            dataKind={ReadKind.ArrayBuffer}
            onArrayBuffer={props.onServiceMapLogs}
            isUploading={!!props.isServiceMapLogsUploading}
          />
        )}
    </div>
  );

  return (
    <div className={css.wrapper} data-testid={E2E.root}>
      <div className={css.section} data-testid={E2E.mainMenu}>
        <MainNavigationItem
          app={Application.Dashboard}
          text="Overview"
          icon={<NavigationIcon icon={isDarkThemeActive ? dashboardIconDark : dashboardIcon} />}
          isActive={store.controls.currentDashboardVariant === DashboardVariant.Overview}
          onSelect={() => {
            ui.applicationChanged(Application.Dashboard);
            store.controls.setCurrentDashboardVariant(DashboardVariant.Overview);
          }}
        />
        <div className={css.groupTitle}>Network</div>
        {ui.controls.isConnectionsMapAvailable && (
          <MainNavigationItem
            app={Application.ConnectionsMap}
            text="Connections"
            icon={<NavigationIcon icon={isDarkThemeActive ? serviceMapIconDark : serviceMapIcon} />}
            isActive={store.controls.app.isConnectionsMap}
            onSelect={onConnectionsMapSelect}
          />
        )}
        {store.uiSettings.grafana.enabled && (
          <MainNavigationItem
            app={Application.Dashboard}
            text="Metrics"
            icon={<NavigationIcon icon={isDarkThemeActive ? metricsIconDark : metricsIcon} />}
            isActive={store.controls.currentDashboardVariant === DashboardVariant.Network}
            onSelect={() => {
              ui.applicationChanged(Application.Dashboard);
              store.controls.setCurrentDashboardVariant(DashboardVariant.Network);
            }}
          />
        )}
        <MainNavigationItem
          app={Application.Cimulator}
          text="Policies"
          icon={
            <NavigationIcon
              icon={isDarkThemeActive ? networkPoliciesIconDark : networkPoliciesIcon}
            />
          }
          isActive={store.controls.app.isCimulator}
          onSelect={onCimulatorSelect}
        />
        <div className={css.groupTitle}>Runtime</div>
        {false && (
          <MainNavigationItem
            app={Application.Dashboard}
            text="Metrics"
            icon={<NavigationIcon icon={isDarkThemeActive ? metricsIconDark : metricsIcon} />}
            isActive={store.controls.currentDashboardVariant === DashboardVariant.Runtime}
            onSelect={() => {
              ui.applicationChanged(Application.Dashboard);
              store.controls.setCurrentDashboardVariant(DashboardVariant.Runtime);
            }}
          />
        )}
        <MainNavigationItem
          app={Application.ProcessTree}
          text={
            <>
              Processes
              <Tag minimal style={{ fontSize: '9px', marginInlineStart: '5px' }}>
                BETA
              </Tag>
            </>
          }
          icon={<NavigationIcon icon={isDarkThemeActive ? processTreeIconDark : processTreeIcon} />}
          isActive={store.controls.app.isProcessTree}
          onSelect={onProcessTreeSelect}
        />
      </div>
      <div className={css.separator} style={{ margin: '16px 0 12px 0' }}></div>
      {store.controls.isRbacNoRoles && (
        <div className={`${css.namespaceWrapper} ${css.section}`}>
          <div className={css.errorCallout}>
            <b>No RBAC role specified</b>. Accessing data such as network flows or the list of
            namespaces is denied.
          </div>
        </div>
      )}
      {store.controls.app.isDashboard && (
        <div className={css.expandSection}>
          {!props.hideNamespaceSelector && namespaceSection}
          {store.uiSettings.grafana.enabled && timeRangeSection}
        </div>
      )}
      {(store.controls.app.isConnectionsMap || store.controls.app.isCimulator) && (
        <div
          className={classnames(css.expandSection, {
            [css.connectionsMapSection]: store.controls.app.isConnectionsMap,
            [css.cimulatorSection]: store.controls.app.isCimulator,
          })}
        >
          {dataModeSwitcher}
          {clusterSection}
          {namespaceSection}
          {dataMode === DataMode.WatchingHistory && timeRangeSection}
          {filtersSection}
          {showPoliciesList && (
            <div style={{ marginTop: '16px' }} className={css.section}>
              <PoliciesList
                currNamespace={props.currNamespace ?? null}
                onOpenPolicy={props.onOpenPolicy}
              />
            </div>
          )}
        </div>
      )}
      {store.controls.app.isProcessTree && (
        <div className={`${css.processTreeSection} ${css.expandSection}`}>
          {!store.uiSettings.isTimescapeEnabled && (
            <>
              <LogsUpload
                isUploading={props.isProcessTreeLogsUploading}
                onLogs={props.onProcessTreeLogsUploaded}
                onLogsError={props.onProcessTreeLogsUploadError}
                onReadingStart={props.onProcessTreeReadingStart}
              />
              {namespaceSection}
              <div className={css.section}>
                <PodSelection
                  pods={props.processTreeAvailablePods ?? []}
                  selectedPod={props.processTreeSelectedPod ?? void 0}
                  initialQuery={props.initialPodQuery}
                  disabled={store.processTree.currentNamespace == null}
                  onPodSelect={props.onProcessTreePodSelect}
                  isSpinnerVisible={!!props.isPodsSpinnerVisible}
                  widgetControl={props.podSelectionControl}
                />
              </div>
              {props.processTreeSlotRef && (
                <div className={css.section} ref={props.processTreeSlotRef}></div>
              )}
            </>
          )}

          {store.uiSettings.isTimescapeEnabled && (
            <>
              {namespaceSection}
              {timeRangeSection}
              <div className={css.section}>
                <PodSelection
                  pods={props.processTreeAvailablePods ?? []}
                  selectedPod={props.processTreeSelectedPod ?? void 0}
                  initialQuery={props.initialPodQuery}
                  onPodSelect={props.onProcessTreePodSelect}
                  disabled={store.processTree.currentNamespace == null}
                  isSpinnerVisible={!!props.isPodsSpinnerVisible}
                  widgetControl={props.podSelectionControl}
                />
              </div>

              {props.processTreeSlotRef && (
                <div className={css.section} ref={props.processTreeSlotRef}></div>
              )}
            </>
          )}
        </div>
      )}
      <div className={css.section}>
        <ThemeSwitcher />

        <Popover
          isOpen={isStatusCenterOpen}
          autoFocus
          interactionKind="click"
          targetTagName="div"
          placement="right-end"
          popoverClassName={css.statusCenterPopover}
          onInteraction={toggleStatusCenterWindow}
          inheritDarkTheme={true} // Note
          content={
            <StatusCenterWindow
              statusCenter={statusCenter}
              onClose={() => toggleStatusCenterWindow(false)}
              detailsTarget={entry => {
                if (entry.key === Keys.RBACSessionExpired) {
                  return (
                    <div className={css.sessionExpired}>
                      Your session has expired. Please&nbsp;
                      <span onClick={props.onLoginAgainClick}>login again</span>.
                    </div>
                  );
                }

                return null;
              }}
            />
          }
        >
          <StatusCenterHandle
            hasNotifications={!isStatusCenterOpen && statusCenter.hasUnread}
            criticalPreview={statusCenter.criticalPreview}
          />
        </Popover>

        {store.uiSettings.isFeaturesSet && (
          <ConnectionIndicator transferState={props.transferState} />
        )}

        {store.authz.email && (
          <>
            <div className={css.separator} style={{ margin: '10px 0px 16px' }}></div>
            <UserDropdown inlineEmail />
          </>
        )}
      </div>
    </div>
  );
});

function NavigationIcon(props: { icon: string }) {
  return <img src={props.icon} className={css.navigationIcon} />;
}
