import React, { memo, useCallback } from 'react';

import { Button, MenuItem } from '@blueprintjs/core';
import { ItemPredicate, ItemRenderer, Select } from '@blueprintjs/select';

import css from './NamespaceSelectorDropdown.scss';

export enum E2E {
  currentNamespace = `current-namespace`,
  namespaceItem = `namespace-item`,
}

const renderItem: ItemRenderer<string> = (namespace, itemProps) => {
  const { handleClick, modifiers } = itemProps;

  if (!modifiers.matchesPredicate) {
    return null;
  }

  return (
    <MenuItem
      data-testid={E2E.namespaceItem}
      key={namespace}
      onClick={handleClick}
      text={namespace}
    />
  );
};

const filterItem: ItemPredicate<string> = (query, namespace, idx, exactMatch) => {
  const normalizedTitle = namespace.toLowerCase();
  const normalizedQuery = query.toLowerCase();

  if (exactMatch) {
    return normalizedTitle === normalizedQuery;
  } else {
    return namespace.indexOf(normalizedQuery) >= 0;
  }
};

export interface Props {
  namespacesList?: string[];
  currNamespace?: string | null;
  disabled?: boolean;
  onChange?: (namespace: string | null) => void;
  placeholderText?: string;
  large?: boolean;
}

export const NamespaceSelectorDropdown = memo<Props>(function NamespaceSelectorDropdown(props) {
  const { namespacesList, currNamespace, placeholderText = 'Choose namespace', large } = props;

  const onChange = useCallback(
    (namespace: string) => !props.disabled && props.onChange?.(namespace),
    [props.onChange, props.disabled],
  );

  const dropdownText = currNamespace ?? placeholderText;

  return (
    <Select<string>
      resetOnQuery
      resetOnClose
      resetOnSelect
      itemPredicate={filterItem}
      itemRenderer={renderItem}
      items={namespacesList || []}
      noResults={<MenuItem disabled={true} text="No matches" />}
      onItemSelect={onChange}
      inputProps={{ placeholder: 'Filter namespaces…' }}
      popoverContentProps={{ className: 'namespace-selector-dropdown' }}
      popoverProps={{ hasBackdrop: true }}
    >
      <Button
        data-testid={E2E.currentNamespace}
        id="namespace-selector-dropdown-button"
        rightIcon="caret-down"
        text={dropdownText}
        disabled={props.disabled}
        className={css.wrapper}
        alignText="left"
        large={large ?? false}
      />
    </Select>
  );
});
