/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter add_pb_suffix,eslint_disable,ts_nocheck,generate_dependencies,long_type_number,use_proto_field_name
// @generated from protobuf file "tetragon/capabilities.proto" (package "tetragon", syntax proto3)
// tslint:disable
// @ts-nocheck
//
// This file is copied from OSS, DO NOT EDIT
//
//
// SPDX-License-Identifier: Apache-2.0
// Copyright Authors of Hubble
//
/**
 * @generated from protobuf enum tetragon.CapabilitiesType
 */
export enum CapabilitiesType {
    /**
     * In a system with the [_POSIX_CHOWN_RESTRICTED] option defined, this
     * overrides the restriction of changing file ownership and group
     * ownership.
     *
     * @generated from protobuf enum value: CAP_CHOWN = 0;
     */
    CAP_CHOWN = 0,
    /**
     * Override all DAC access, including ACL execute access if
     * [_POSIX_ACL] is defined. Excluding DAC access covered by
     * CAP_LINUX_IMMUTABLE.
     *
     * @generated from protobuf enum value: DAC_OVERRIDE = 1;
     */
    DAC_OVERRIDE = 1,
    /**
     * Overrides all DAC restrictions regarding read and search on files
     * and directories, including ACL restrictions if [_POSIX_ACL] is
     * defined. Excluding DAC access covered by "$1"_LINUX_IMMUTABLE.
     *
     * @generated from protobuf enum value: CAP_DAC_READ_SEARCH = 2;
     */
    CAP_DAC_READ_SEARCH = 2,
    /**
     * Overrides all restrictions about allowed operations on files, where
     * file owner ID must be equal to the user ID, except where CAP_FSETID
     * is applicable. It doesn't override MAC and DAC restrictions.
     *
     * @generated from protobuf enum value: CAP_FOWNER = 3;
     */
    CAP_FOWNER = 3,
    /**
     * Overrides the following restrictions that the effective user ID
     * shall match the file owner ID when setting the S_ISUID and S_ISGID
     * bits on that file; that the effective group ID (or one of the
     * supplementary group IDs) shall match the file owner ID when setting
     * the S_ISGID bit on that file; that the S_ISUID and S_ISGID bits are
     * cleared on successful return from chown(2) (not implemented).
     *
     * @generated from protobuf enum value: CAP_FSETID = 4;
     */
    CAP_FSETID = 4,
    /**
     * Overrides the restriction that the real or effective user ID of a
     * process sending a signal must match the real or effective user ID
     * of the process receiving the signal.
     *
     * @generated from protobuf enum value: CAP_KILL = 5;
     */
    CAP_KILL = 5,
    /**
     * Allows forged gids on socket credentials passing.
     *
     * @generated from protobuf enum value: CAP_SETGID = 6;
     */
    CAP_SETGID = 6,
    /**
     * Allows forged pids on socket credentials passing.
     *
     * @generated from protobuf enum value: CAP_SETUID = 7;
     */
    CAP_SETUID = 7,
    /**
     * Without VFS support for capabilities:
     *   Transfer any capability in your permitted set to any pid,
     *   remove any capability in your permitted set from any pid
     * With VFS support for capabilities (neither of above, but)
     *   Add any capability from current's capability bounding set
     *       to the current process' inheritable set
     *   Allow taking bits out of capability bounding set
     *   Allow modification of the securebits for a process
     *
     * @generated from protobuf enum value: CAP_SETPCAP = 8;
     */
    CAP_SETPCAP = 8,
    /**
     * Allow modification of S_IMMUTABLE and S_APPEND file attributes
     *
     * @generated from protobuf enum value: CAP_LINUX_IMMUTABLE = 9;
     */
    CAP_LINUX_IMMUTABLE = 9,
    /**
     * Allows binding to ATM VCIs below 32
     *
     * @generated from protobuf enum value: CAP_NET_BIND_SERVICE = 10;
     */
    CAP_NET_BIND_SERVICE = 10,
    /**
     * Allow broadcasting, listen to multicast
     *
     * @generated from protobuf enum value: CAP_NET_BROADCAST = 11;
     */
    CAP_NET_BROADCAST = 11,
    /**
     * Allow activation of ATM control sockets
     *
     * @generated from protobuf enum value: CAP_NET_ADMIN = 12;
     */
    CAP_NET_ADMIN = 12,
    /**
     * Allow binding to any address for transparent proxying (also via NET_ADMIN)
     *
     * @generated from protobuf enum value: CAP_NET_RAW = 13;
     */
    CAP_NET_RAW = 13,
    /**
     * Allow mlock and mlockall (which doesn't really have anything to do
     * with IPC)
     *
     * @generated from protobuf enum value: CAP_IPC_LOCK = 14;
     */
    CAP_IPC_LOCK = 14,
    /**
     * Override IPC ownership checks
     *
     * @generated from protobuf enum value: CAP_IPC_OWNER = 15;
     */
    CAP_IPC_OWNER = 15,
    /**
     * Insert and remove kernel modules - modify kernel without limit
     *
     * @generated from protobuf enum value: CAP_SYS_MODULE = 16;
     */
    CAP_SYS_MODULE = 16,
    /**
     * Allow sending USB messages to any device via /dev/bus/usb
     *
     * @generated from protobuf enum value: CAP_SYS_RAWIO = 17;
     */
    CAP_SYS_RAWIO = 17,
    /**
     * Allow use of chroot()
     *
     * @generated from protobuf enum value: CAP_SYS_CHROOT = 18;
     */
    CAP_SYS_CHROOT = 18,
    /**
     * Allow ptrace() of any process
     *
     * @generated from protobuf enum value: CAP_SYS_PTRACE = 19;
     */
    CAP_SYS_PTRACE = 19,
    /**
     * Allow configuration of process accounting
     *
     * @generated from protobuf enum value: CAP_SYS_PACCT = 20;
     */
    CAP_SYS_PACCT = 20,
    /**
     * Allow everything under CAP_BPF and CAP_PERFMON for backward compatibility
     *
     * @generated from protobuf enum value: CAP_SYS_ADMIN = 21;
     */
    CAP_SYS_ADMIN = 21,
    /**
     * Allow use of reboot()
     *
     * @generated from protobuf enum value: CAP_SYS_BOOT = 22;
     */
    CAP_SYS_BOOT = 22,
    /**
     * Allow setting cpu affinity on other processes
     *
     * @generated from protobuf enum value: CAP_SYS_NICE = 23;
     */
    CAP_SYS_NICE = 23,
    /**
     * Control memory reclaim behavior
     *
     * @generated from protobuf enum value: CAP_SYS_RESOURCE = 24;
     */
    CAP_SYS_RESOURCE = 24,
    /**
     * Allow setting the real-time clock
     *
     * @generated from protobuf enum value: CAP_SYS_TIME = 25;
     */
    CAP_SYS_TIME = 25,
    /**
     * Allow vhangup() of tty
     *
     * @generated from protobuf enum value: CAP_SYS_TTY_CONFIG = 26;
     */
    CAP_SYS_TTY_CONFIG = 26,
    /**
     * Allow the privileged aspects of mknod()
     *
     * @generated from protobuf enum value: CAP_MKNOD = 27;
     */
    CAP_MKNOD = 27,
    /**
     * Allow taking of leases on files
     *
     * @generated from protobuf enum value: CAP_LEASE = 28;
     */
    CAP_LEASE = 28,
    /**
     * Allow writing the audit log via unicast netlink socket
     *
     * @generated from protobuf enum value: CAP_AUDIT_WRITE = 29;
     */
    CAP_AUDIT_WRITE = 29,
    /**
     * Allow configuration of audit via unicast netlink socket
     *
     * @generated from protobuf enum value: CAP_AUDIT_CONTROL = 30;
     */
    CAP_AUDIT_CONTROL = 30,
    /**
     * Set or remove capabilities on files
     *
     * @generated from protobuf enum value: CAP_SETFCAP = 31;
     */
    CAP_SETFCAP = 31,
    /**
     * Override MAC access.
     * The base kernel enforces no MAC policy.
     * An LSM may enforce a MAC policy, and if it does and it chooses
     * to implement capability based overrides of that policy, this is
     * the capability it should use to do so.
     *
     * @generated from protobuf enum value: CAP_MAC_OVERRIDE = 32;
     */
    CAP_MAC_OVERRIDE = 32,
    /**
     * Allow MAC configuration or state changes.
     * The base kernel requires no MAC configuration.
     * An LSM may enforce a MAC policy, and if it does and it chooses
     * to implement capability based checks on modifications to that
     * policy or the data required to maintain it, this is the
     * capability it should use to do so.
     *
     * @generated from protobuf enum value: CAP_MAC_ADMIN = 33;
     */
    CAP_MAC_ADMIN = 33,
    /**
     * Allow configuring the kernel's syslog (printk behaviour)
     *
     * @generated from protobuf enum value: CAP_SYSLOG = 34;
     */
    CAP_SYSLOG = 34,
    /**
     * Allow triggering something that will wake the system
     *
     * @generated from protobuf enum value: CAP_WAKE_ALARM = 35;
     */
    CAP_WAKE_ALARM = 35,
    /**
     * Allow preventing system suspends
     *
     * @generated from protobuf enum value: CAP_BLOCK_SUSPEND = 36;
     */
    CAP_BLOCK_SUSPEND = 36,
    /**
     * Allow reading the audit log via multicast netlink socket
     *
     * @generated from protobuf enum value: CAP_AUDIT_READ = 37;
     */
    CAP_AUDIT_READ = 37,
    /**
     *
     * Allow system performance and observability privileged operations
     * using perf_events, i915_perf and other kernel subsystems
     *
     * @generated from protobuf enum value: CAP_PERFMON = 38;
     */
    CAP_PERFMON = 38,
    /**
     *
     * CAP_BPF allows the following BPF operations:
     * - Creating all types of BPF maps
     * - Advanced verifier features
     *   - Indirect variable access
     *   - Bounded loops
     *   - BPF to BPF function calls
     *   - Scalar precision tracking
     *   - Larger complexity limits
     *   - Dead code elimination
     *   - And potentially other features
     * - Loading BPF Type Format (BTF) data
     * - Retrieve xlated and JITed code of BPF programs
     * - Use bpf_spin_lock() helper
     * CAP_PERFMON relaxes the verifier checks further:
     * - BPF progs can use of pointer-to-integer conversions
     * - speculation attack hardening measures are bypassed
     * - bpf_probe_read to read arbitrary kernel memory is allowed
     * - bpf_trace_printk to print kernel memory is allowed
     * CAP_SYS_ADMIN is required to use bpf_probe_write_user.
     * CAP_SYS_ADMIN is required to iterate system wide loaded
     * programs, maps, links, BTFs and convert their IDs to file descriptors.
     * CAP_PERFMON and CAP_BPF are required to load tracing programs.
     * CAP_NET_ADMIN and CAP_BPF are required to load networking programs.
     *
     * @generated from protobuf enum value: CAP_BPF = 39;
     */
    CAP_BPF = 39,
    /**
     * Allow writing to ns_last_pid
     *
     * @generated from protobuf enum value: CAP_CHECKPOINT_RESTORE = 40;
     */
    CAP_CHECKPOINT_RESTORE = 40
}
/**
 * @generated from protobuf enum tetragon.SecureBitsType
 */
export enum SecureBitsType {
    /**
     * @generated from protobuf enum value: SecBitNotSet = 0;
     */
    SecBitNotSet = 0,
    /**
     * When set UID 0 has no special privileges. When unset, inheritance
     * of root-permissions and suid-root executable under compatibility mode
     * is supported. If the effective uid of the new process is 0 then
     * the effective and inheritable bitmasks of the executable file is raised.
     * If the real uid is 0, the effective (legacy) bit of the executable file
     * is raised.
     *
     * @generated from protobuf enum value: SecBitNoRoot = 1;
     */
    SecBitNoRoot = 1,
    /**
     * Make bit-0 SecBitNoRoot immutable
     *
     * @generated from protobuf enum value: SecBitNoRootLocked = 2;
     */
    SecBitNoRootLocked = 2,
    /**
     * When set, setuid to/from uid 0 does not trigger capability-"fixup".
     * When unset, to provide compatiblility with old programs relying on
     * set*uid to gain/lose privilege, transitions to/from uid 0 cause
     * capabilities to be gained/lost.
     *
     * @generated from protobuf enum value: SecBitNoSetUidFixup = 4;
     */
    SecBitNoSetUidFixup = 4,
    /**
     * Make bit-2 SecBitNoSetUidFixup immutable
     *
     * @generated from protobuf enum value: SecBitNoSetUidFixupLocked = 8;
     */
    SecBitNoSetUidFixupLocked = 8,
    /**
     * When set, a process can retain its capabilities even after
     * transitioning to a non-root user (the set-uid fixup suppressed by
     * bit 2). Bit-4 is cleared when a process calls exec(); setting both
     * bit 4 and 5 will create a barrier through exec that no exec()'d
     * child can use this feature again.
     *
     * @generated from protobuf enum value: SecBitKeepCaps = 16;
     */
    SecBitKeepCaps = 16,
    /**
     * Make bit-4 SecBitKeepCaps immutable
     *
     * @generated from protobuf enum value: SecBitKeepCapsLocked = 32;
     */
    SecBitKeepCapsLocked = 32,
    /**
     * When set, a process cannot add new capabilities to its ambient set.
     *
     * @generated from protobuf enum value: SecBitNoCapAmbientRaise = 64;
     */
    SecBitNoCapAmbientRaise = 64,
    /**
     * Make bit-6 SecBitNoCapAmbientRaise immutable
     *
     * @generated from protobuf enum value: SecBitNoCapAmbientRaiseLocked = 128;
     */
    SecBitNoCapAmbientRaiseLocked = 128
}
/**
 * Reasons of why the process privileges changed.
 *
 * @generated from protobuf enum tetragon.ProcessPrivilegesChanged
 */
export enum ProcessPrivilegesChanged {
    /**
     * @generated from protobuf enum value: PRIVILEGES_CHANGED_UNSET = 0;
     */
    PRIVILEGES_CHANGED_UNSET = 0,
    /**
     * A privilege elevation happened due to the execution of a binary with file capability sets.
     * The kernel supports associating capability sets with an executable file using `setcap` command.
     * The file capability sets are stored in an extended attribute (see https://man7.org/linux/man-pages/man7/xattr.7.html)
     * named `security.capability`. The file capability sets, in conjunction with the capability sets
     * of the process, determine the process capabilities and privileges after the `execve` system call.
     * For further reference, please check sections `File capability extended attribute versioning` and
     * `Namespaced file capabilities` of the capabilities man pages: https://man7.org/linux/man-pages/man7/capabilities.7.html.
     * The new granted capabilities can be listed inside the `process` object.
     *
     * @generated from protobuf enum value: PRIVILEGES_RAISED_EXEC_FILE_CAP = 1;
     */
    PRIVILEGES_RAISED_EXEC_FILE_CAP = 1,
    /**
     * A privilege elevation happened due to the execution of a binary with set-user-ID to root.
     * When a process with nonzero UIDs executes a binary with a set-user-ID to root also
     * known as suid-root executable, then the kernel switches the effective user ID to 0 (root) which
     * is a privilege elevation operation since it grants access to resources owned by the root user.
     * The effective user ID is listed inside the `process_credentials` part of the `process` object.
     * For further reading, section `Capabilities and execution of programs by root` of https://man7.org/linux/man-pages/man7/capabilities.7.html.
     * Afterward the kernel recalculates the capability sets of the process and grants all capabilities
     * in the permitted and effective capability sets, except those masked out by the capability bounding set.
     * If the binary also have file capability sets then these bits are honored and the process gains just
     * the capabilities granted by the file capability sets (i.e., not all capabilities, as it would occur
     * when executing a set-user-ID to root binary that does not have any associated file capabilities). This
     * is described in section `Set-user-ID-root programs that have file capabilities` of https://man7.org/linux/man-pages/man7/capabilities.7.html.
     * The new granted capabilities can be listed inside the `process` object.
     * There is one exception for the special treatments of set-user-ID to root execution receiving all
     * capabilities, if the `SecBitNoRoot` bit of the Secure bits is set, then the kernel does not grant
     * any capability. Please check section: `The securebits flags: establishing a capabilities-only environment`
     * of the capabilities man pages: https://man7.org/linux/man-pages/man7/capabilities.7.html
     *
     * @generated from protobuf enum value: PRIVILEGES_RAISED_EXEC_FILE_SETUID = 2;
     */
    PRIVILEGES_RAISED_EXEC_FILE_SETUID = 2,
    /**
     * A privilege elevation happened due to the execution of a binary with set-group-ID to root.
     * When a process with nonzero GIDs executes a binary with a set-group-ID to root, the kernel switches
     * the effective group ID to 0 (root) which is a privilege elevation operation since it grants access to
     * resources owned by the root group.
     * The effective group ID is listed inside the `process_credentials` part of the `process` object.
     *
     * @generated from protobuf enum value: PRIVILEGES_RAISED_EXEC_FILE_SETGID = 3;
     */
    PRIVILEGES_RAISED_EXEC_FILE_SETGID = 3
}
