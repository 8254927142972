/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter add_pb_suffix,eslint_disable,ts_nocheck,generate_dependencies,long_type_number,use_proto_field_name
// @generated from protobuf file "timescape/v1/k8s_events.proto" (package "timescape.v1", syntax proto3)
// tslint:disable
// @ts-nocheck
//
// Copyright (C) Isovalent, Inc. - All Rights Reserved.
//
// NOTICE: All information contained herein is, and remains the property of
// Isovalent Inc and its suppliers, if any. The intellectual and technical
// concepts contained herein are proprietary to Isovalent Inc and its suppliers
// and may be covered by U.S. and Foreign Patents, patents in process, and are
// protected by trade secret or copyright law.  Dissemination of this
// information or reproduction of this material is strictly forbidden unless
// prior written permission is obtained from Isovalent Inc.
//
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Event } from "../../k8sevent/v1/event_pb";
import { Kind } from "../../k8sevent/v1/event_pb";
import { EventType } from "../../k8sevent/v1/event_pb";
import { FieldMask } from "../../google/protobuf/field_mask_pb";
import { TimeFilter } from "./time_filter_pb";
/**
 * GetK8sEventsRequest for the GetEvents rpc call.
 *
 * @generated from protobuf message timescape.v1.GetK8sEventsRequest
 */
export interface GetK8sEventsRequest {
    /**
     * TimeFilter is a filter that allows to specify a time interval.
     *
     * @generated from protobuf field: timescape.v1.TimeFilter time_filter = 1;
     */
    time_filter?: TimeFilter;
    /**
     * Include are filters that events must match.
     * If multiple include filters are specified, only one of them has to
     * match for a flow to be included.
     *
     * @generated from protobuf field: repeated timescape.v1.K8sEventFilter include = 2;
     */
    include: K8sEventFilter[];
    /**
     * Exclude are filters that events must not match.
     * If multiple excluded filters are specified, only one of them has to
     * match for a flow to be excluded.
     *
     * @generated from protobuf field: repeated timescape.v1.K8sEventFilter exclude = 3;
     */
    exclude: K8sEventFilter[];
    /**
     * FieldMask allows clients to limit event fields that will be returned.
     *
     * @generated from protobuf field: google.protobuf.FieldMask field_mask = 4;
     */
    field_mask?: FieldMask;
    /**
     * Limit is the maximum number of events to return
     *
     * @generated from protobuf field: uint64 limit = 5;
     */
    limit: number;
    /**
     * Event fields to distinct by
     *
     * @generated from protobuf field: repeated timescape.v1.EventField distinct_on = 6;
     */
    distinct_on: EventField[];
}
/**
 * EventFilter is a filter to be used in the GetEventsRequest include/exclude fields.
 * All fields are optional. If multiple fields are set, then all fields must
 * match for the filter to match.
 *
 * @generated from protobuf message timescape.v1.K8sEventFilter
 */
export interface K8sEventFilter {
    /**
     * Filter by the name of the k8s event.
     *
     * @generated from protobuf field: repeated string name = 1;
     */
    name: string[];
    /**
     * Filter by the k8s resource namespace.
     *
     * @generated from protobuf field: repeated string namespace = 2;
     */
    namespace: string[];
    /**
     * Filter by the k8s resource uuid.
     *
     * @generated from protobuf field: repeated string resource_uuid = 3;
     */
    resource_uuid: string[];
    /**
     * Filter by the event type.
     *
     * @generated from protobuf field: repeated k8sevent.v1.EventType event_type = 4;
     */
    event_type: EventType[];
    /**
     * Filter by the k8s resource kind.
     *
     * @generated from protobuf field: repeated k8sevent.v1.Kind kind = 5;
     */
    kind: Kind[];
    /**
     * Filter by the k8s resource cluster.
     *
     * @generated from protobuf field: repeated string cluster = 6;
     */
    cluster: string[];
}
/**
 * GetK8sEventsResponse for the GetEvents rpc call.
 *
 * @generated from protobuf message timescape.v1.GetK8sEventsResponse
 */
export interface GetK8sEventsResponse {
    /**
     * Event is the event information.
     *
     * @generated from protobuf field: k8sevent.v1.Event event = 1;
     */
    event?: Event;
}
/**
 * Subset of the fields from k8sevent.v1.Event
 *
 * Some indices are skipped to make sure there match the indexes on message
 * Event upstream.
 *
 * @generated from protobuf enum timescape.v1.EventField
 */
export enum EventField {
    /**
     * Unknown field
     *
     * @generated from protobuf enum value: EVENT_FIELD_UNSPECIFIED = 0;
     */
    UNSPECIFIED = 0,
    /**
     * Event type
     *
     * @generated from protobuf enum value: EVENT_FIELD_EVENT_TYPE = 2;
     */
    EVENT_TYPE = 2,
    /**
     * Kubernetes resource version.
     *
     * @generated from protobuf enum value: EVENT_FIELD_RESOURCE_VERSION = 3;
     */
    RESOURCE_VERSION = 3,
    /**
     * Resource UUID;
     *
     * @generated from protobuf enum value: EVENT_FIELD_RESOURCE_UUID = 4;
     */
    RESOURCE_UUID = 4,
    /**
     * API version of the resource.
     *
     * @generated from protobuf enum value: EVENT_FIELD_API_VERSION = 5;
     */
    API_VERSION = 5,
    /**
     * Kubernetes kind of the resource.
     *
     * @generated from protobuf enum value: EVENT_FIELD_KIND = 6;
     */
    KIND = 6,
    /**
     * Name is the name of the Kubernetes resource resource the event was about.
     *
     * @generated from protobuf enum value: EVENT_FIELD_NAME = 7;
     */
    NAME = 7,
    /**
     * Namespace of the Kubernetes resource resource the event
     * was about.
     *
     * @generated from protobuf enum value: EVENT_FIELD_NAMESPACE = 8;
     */
    NAMESPACE = 8,
    /**
     * Cluster name where Kubernetes event occurred.
     *
     * @generated from protobuf enum value: EVENT_FIELD_CLUSTER = 12;
     */
    CLUSTER = 12
}
// @generated message type with reflection information, may provide speed optimized methods
class GetK8sEventsRequest$Type extends MessageType<GetK8sEventsRequest> {
    constructor() {
        super("timescape.v1.GetK8sEventsRequest", [
            { no: 1, name: "time_filter", kind: "message", localName: "time_filter", T: () => TimeFilter },
            { no: 2, name: "include", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => K8sEventFilter },
            { no: 3, name: "exclude", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => K8sEventFilter },
            { no: 4, name: "field_mask", kind: "message", localName: "field_mask", T: () => FieldMask },
            { no: 5, name: "limit", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 6, name: "distinct_on", kind: "enum", localName: "distinct_on", repeat: 1 /*RepeatType.PACKED*/, T: () => ["timescape.v1.EventField", EventField, "EVENT_FIELD_"] }
        ]);
    }
    create(value?: PartialMessage<GetK8sEventsRequest>): GetK8sEventsRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.include = [];
        message.exclude = [];
        message.limit = 0;
        message.distinct_on = [];
        if (value !== undefined)
            reflectionMergePartial<GetK8sEventsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetK8sEventsRequest): GetK8sEventsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* timescape.v1.TimeFilter time_filter */ 1:
                    message.time_filter = TimeFilter.internalBinaryRead(reader, reader.uint32(), options, message.time_filter);
                    break;
                case /* repeated timescape.v1.K8sEventFilter include */ 2:
                    message.include.push(K8sEventFilter.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated timescape.v1.K8sEventFilter exclude */ 3:
                    message.exclude.push(K8sEventFilter.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* google.protobuf.FieldMask field_mask */ 4:
                    message.field_mask = FieldMask.internalBinaryRead(reader, reader.uint32(), options, message.field_mask);
                    break;
                case /* uint64 limit */ 5:
                    message.limit = reader.uint64().toNumber();
                    break;
                case /* repeated timescape.v1.EventField distinct_on */ 6:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.distinct_on.push(reader.int32());
                    else
                        message.distinct_on.push(reader.int32());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetK8sEventsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* timescape.v1.TimeFilter time_filter = 1; */
        if (message.time_filter)
            TimeFilter.internalBinaryWrite(message.time_filter, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* repeated timescape.v1.K8sEventFilter include = 2; */
        for (let i = 0; i < message.include.length; i++)
            K8sEventFilter.internalBinaryWrite(message.include[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* repeated timescape.v1.K8sEventFilter exclude = 3; */
        for (let i = 0; i < message.exclude.length; i++)
            K8sEventFilter.internalBinaryWrite(message.exclude[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.FieldMask field_mask = 4; */
        if (message.field_mask)
            FieldMask.internalBinaryWrite(message.field_mask, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* uint64 limit = 5; */
        if (message.limit !== 0)
            writer.tag(5, WireType.Varint).uint64(message.limit);
        /* repeated timescape.v1.EventField distinct_on = 6; */
        if (message.distinct_on.length) {
            writer.tag(6, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.distinct_on.length; i++)
                writer.int32(message.distinct_on[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message timescape.v1.GetK8sEventsRequest
 */
export const GetK8sEventsRequest = new GetK8sEventsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class K8sEventFilter$Type extends MessageType<K8sEventFilter> {
    constructor() {
        super("timescape.v1.K8sEventFilter", [
            { no: 1, name: "name", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "namespace", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "resource_uuid", kind: "scalar", localName: "resource_uuid", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "event_type", kind: "enum", localName: "event_type", repeat: 1 /*RepeatType.PACKED*/, T: () => ["k8sevent.v1.EventType", EventType, "EVENT_TYPE_"] },
            { no: 5, name: "kind", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["k8sevent.v1.Kind", Kind, "KIND_"] },
            { no: 6, name: "cluster", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<K8sEventFilter>): K8sEventFilter {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.name = [];
        message.namespace = [];
        message.resource_uuid = [];
        message.event_type = [];
        message.kind = [];
        message.cluster = [];
        if (value !== undefined)
            reflectionMergePartial<K8sEventFilter>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: K8sEventFilter): K8sEventFilter {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string name */ 1:
                    message.name.push(reader.string());
                    break;
                case /* repeated string namespace */ 2:
                    message.namespace.push(reader.string());
                    break;
                case /* repeated string resource_uuid */ 3:
                    message.resource_uuid.push(reader.string());
                    break;
                case /* repeated k8sevent.v1.EventType event_type */ 4:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.event_type.push(reader.int32());
                    else
                        message.event_type.push(reader.int32());
                    break;
                case /* repeated k8sevent.v1.Kind kind */ 5:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.kind.push(reader.int32());
                    else
                        message.kind.push(reader.int32());
                    break;
                case /* repeated string cluster */ 6:
                    message.cluster.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: K8sEventFilter, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string name = 1; */
        for (let i = 0; i < message.name.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.name[i]);
        /* repeated string namespace = 2; */
        for (let i = 0; i < message.namespace.length; i++)
            writer.tag(2, WireType.LengthDelimited).string(message.namespace[i]);
        /* repeated string resource_uuid = 3; */
        for (let i = 0; i < message.resource_uuid.length; i++)
            writer.tag(3, WireType.LengthDelimited).string(message.resource_uuid[i]);
        /* repeated k8sevent.v1.EventType event_type = 4; */
        if (message.event_type.length) {
            writer.tag(4, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.event_type.length; i++)
                writer.int32(message.event_type[i]);
            writer.join();
        }
        /* repeated k8sevent.v1.Kind kind = 5; */
        if (message.kind.length) {
            writer.tag(5, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.kind.length; i++)
                writer.int32(message.kind[i]);
            writer.join();
        }
        /* repeated string cluster = 6; */
        for (let i = 0; i < message.cluster.length; i++)
            writer.tag(6, WireType.LengthDelimited).string(message.cluster[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message timescape.v1.K8sEventFilter
 */
export const K8sEventFilter = new K8sEventFilter$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetK8sEventsResponse$Type extends MessageType<GetK8sEventsResponse> {
    constructor() {
        super("timescape.v1.GetK8sEventsResponse", [
            { no: 1, name: "event", kind: "message", T: () => Event }
        ]);
    }
    create(value?: PartialMessage<GetK8sEventsResponse>): GetK8sEventsResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<GetK8sEventsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetK8sEventsResponse): GetK8sEventsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* k8sevent.v1.Event event */ 1:
                    message.event = Event.internalBinaryRead(reader, reader.uint32(), options, message.event);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetK8sEventsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* k8sevent.v1.Event event = 1; */
        if (message.event)
            Event.internalBinaryWrite(message.event, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message timescape.v1.GetK8sEventsResponse
 */
export const GetK8sEventsResponse = new GetK8sEventsResponse$Type();
/**
 * @generated ServiceType for protobuf service timescape.v1.K8sEventService
 */
export const K8sEventService = new ServiceType("timescape.v1.K8sEventService", [
    { name: "GetK8sEvents", serverStreaming: true, options: {}, I: GetK8sEventsRequest, O: GetK8sEventsResponse }
]);
