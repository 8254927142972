import React, { useEffect } from 'react';

import { NonIdealState, NonIdealStateIconSize, Spinner } from '@blueprintjs/core';
import { observer } from 'mobx-react';

import { TimeRange } from '~/domain/time';
import { logger } from '~/utils/logger';

import css from './GrafanaChart.scss';
import { GrafanaDashboardContext } from './misc';

const DO_CLEANUP = process.env.NODE_ENV === 'production' || true;

export interface Props {
  context: GrafanaDashboardContext;
  currentNamespace: string | null;
}

export const GrafanaChart = observer(function GrafanaChart(props: Props) {
  const chart = useGrafanaChart(props.context);

  return (
    <div className={css.wrapper}>
      {(chart.status === 'loading' || chart.status === 'pending') && (
        <div className={css.overlay}>
          <NonIdealState title="Loading metrics..." icon={<Spinner size={80} />} />
        </div>
      )}
      {chart.status === 'error' && (
        <div className={css.overlay}>
          <NonIdealState
            title="Something went wrong"
            icon="error"
            iconSize={NonIdealStateIconSize.STANDARD}
          />
        </div>
      )}
      <iframe
        key={props.context.url}
        ref={chart.ref}
        className={css.iframe}
        src={props.context.url}
        frameBorder={0}
      ></iframe>
    </div>
  );
});

function waitElement<T>(
  getWin: () => Window | null | undefined,
  selector: string,
  maxRetries = 10,
): Promise<[Window, T]> {
  return new Promise<[Window, T]>((resolve, reject) => {
    const rec = (retry = 1) => {
      if (retry === maxRetries) {
        reject('Max retries exceed for selector: ' + selector);
        return;
      }
      const win = getWin();
      const node = win?.document.querySelector(selector);
      if (win && node) {
        resolve([win, node as T]);
        return;
      }
      setTimeout(() => rec(retry + 1), retry * 100);
    };
    rec();
  });
}

function cleanupUi(doc: Document, context: GrafanaDashboardContext) {
  if (!DO_CLEANUP) return;
  doc.body.setAttribute('style', `background-color: ${context.bgColor}`);
  const sidemenu = doc.querySelector('.sidemenu')?.parentElement;
  if (sidemenu) {
    const parent = sidemenu.parentElement;
    if (parent) parent.removeChild(sidemenu);
  }

  // Here we want to try eliminate top margin on some dashboards
  const firstChartWidget = doc.querySelector(`.react-grid-layout .react-grid-item`) as
    | HTMLElement
    | undefined;
  if (firstChartWidget) {
    const match = /translate\(0px, (\d{1,}px)\)/.exec(firstChartWidget.style.transform);
    if (match) {
      doc.querySelector(`.react-grid-layout`)?.setAttribute('style', `margin-top: -${match[1]};`);
    }
  }

  doc.querySelector('html')?.setAttribute('style', 'overflow: hidden;');

  [`#pageContent`, `main`].forEach(selector => {
    doc.querySelectorAll(selector).forEach(element => {
      element.setAttribute('style', 'padding-top: 0;');
    });
  });

  doc.querySelectorAll(`[data-testid*="Panel"]`).forEach(element => {
    element.setAttribute('style', `background-color: ${context.bgColor}`);
  });

  (doc.querySelector(`[class$="-page-wrapper"]`) as HTMLElement)?.parentElement?.setAttribute(
    'style',
    'padding-top: 0 !important',
  );

  [
    '[class$="viewControls"]',
    '[data-testid="header-container"] .show-on-hover',
    '[data-testid*="mega-menu"]',
    '[data-testid="title-items-container"]',
  ].forEach(selector => {
    doc.querySelectorAll(selector).forEach(element => {
      element.setAttribute('style', 'display: none');
    });
  });

  [`[data-testid="header-container"]`].forEach(selector => {
    doc.querySelectorAll(selector).forEach(element => {
      element.setAttribute('style', `pointer-events: none;`);
    });
  });

  [
    '[data-testid$="Dashboard navigation"]',
    '[aria-label="Dashboard submenu"]',
    '[aria-label="Explore toolbar"]',
    '[class$="queryContainer"]',
    '[class$="collapse__header--collapsed"]',
  ].forEach(selector => {
    doc.querySelectorAll(selector).forEach(element => {
      element.setAttribute('style', 'position: absolute; top: -999999px');
    });
  });

  (doc.querySelector(`[data-testid*="Nav toolbar"]`) as HTMLElement)?.parentElement?.setAttribute(
    'style',
    'position: absolute; top: -999999px',
  );
}

function cleanupInteractivity(doc: Document) {
  if (!DO_CLEANUP) return;
  doc.querySelectorAll('.dashboard-row__actions').forEach(element => {
    const parent = element.parentElement;
    if (parent) parent.removeChild(element);
  });
  doc.querySelectorAll('.panel-header').forEach(element => {
    element.setAttribute('style', 'pointer-events: none');
  });
  doc.querySelectorAll('.dashboard-row').forEach(element => {
    const parent = element.parentElement;
    const parentParent = parent?.parentElement;
    if (parentParent) parentParent.removeChild(parent);
  });
  doc.querySelectorAll('.panel-title').forEach(title => {
    title.querySelectorAll('[data-testid="panel-dropdown"]').forEach(dropdown => {
      title.removeChild(dropdown);
    });
  });
  doc.querySelectorAll('.react-resizable-handle').forEach(el => {
    const parent = el.parentElement;
    if (parent) parent.removeChild(el);
  });

  doc.querySelectorAll(`[aria-label^="Node: "]`).forEach(el => {
    el.setAttribute('style', 'pointer-events: none');
  });

  doc.querySelectorAll(`[aria-label^="Edge "]`).forEach(el => {
    el.setAttribute('style', 'pointer-events: none');
  });
}

function mutationsChecker(mutations: MutationRecord[]) {
  let needCleanup = false;
  mutations.forEach(mutation => {
    if (needCleanup) return;
    if (mutation.addedNodes.length > 0) needCleanup = true;
  });
  return needCleanup;
}

function useGrafanaChart(context: GrafanaDashboardContext) {
  const ref = React.useRef<HTMLIFrameElement>(null);

  const [status, setStatus] = React.useState<'pending' | 'loading' | 'ok' | 'error'>('pending');

  useEffect(() => {
    setStatus('loading');

    const getWin = () => {
      return ref.current?.contentWindow;
    };

    const cleanup = () => {
      const win = getWin();
      if (!win) return;
      cleanupUi(win.document, context);
      cleanupInteractivity(win.document);
    };

    const observer = new MutationObserver(mutations => {
      if (mutationsChecker(mutations)) {
        cleanup();
      }
    });

    waitElement<Node>(getWin, '[data-testid*="Dashboard navigation"]', 20)
      .then(([win]) => {
        observer.observe(win.document.body, { childList: true, subtree: true });
        cleanup();
        setStatus('ok');
      })
      .catch(error => logger.error(error));

    return () => observer.disconnect();
  }, [context.url]);

  useEffect(() => {
    if (status === 'pending' || status === 'loading') return;
    setTimeRange(() => ref.current?.contentWindow, context.timeRange)
      .then(() => {
        setStatus('ok');
      })
      .catch(error => {
        setStatus('error');
        logger.error(error);
      });
  }, [context.timeRange.startStr, context.timeRange.endStr]);

  useEffect(() => {
    const win = ref.current?.contentWindow;
    if (!win) return;
    cleanupUi(win.document, context);
    cleanupInteractivity(win.document);
  }, [status]);

  return { ref, status };
}

function inputValue(input: HTMLInputElement, value: string) {
  const prototype = Object.getPrototypeOf(input);
  const valueDescriptor = Object.getOwnPropertyDescriptor(prototype, 'value');
  if (valueDescriptor && typeof valueDescriptor.set === 'function') {
    valueDescriptor.set.call(input, value);
  }
  input.dispatchEvent(new Event('change', { bubbles: true }));
}

async function setTimeRange(getWin: () => Window | null | undefined, timeRange: TimeRange) {
  return waitElement<HTMLButtonElement>(getWin, `[data-testid*="TimePicker Open Button"]`)
    .then(async ([win, button]) => {
      if (!win.document.getElementById('TimePickerContent')) button.click();
      const [, fromInput] = await waitElement<HTMLInputElement>(
        getWin,
        `[data-testid*="Time Range from field"]`,
      );
      const [, toInput] = await waitElement<HTMLInputElement>(
        getWin,
        `[data-testid*="Time Range to field"]`,
      );
      return { fromInput, toInput };
    })
    .then(async ({ fromInput, toInput }) => {
      const startTime = timeRange.startDateTimezoneOffset.toISOString();
      const endTime = timeRange.endDateTimezoneOffset.toISOString();
      inputValue(fromInput, startTime);
      inputValue(toInput, endTime);
      return waitElement<HTMLButtonElement>(getWin, `[data-testid*="TimePicker submit button"]`);
    })
    .then(([, button]) => button.click())
    .catch(error => logger.error(error));
}
