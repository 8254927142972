import React from 'react';

import { observer } from 'mobx-react';

import { useStore } from '~/store/stores/cimulator';

import { PolicyAssistantCard } from './PolicyAssistantCard';
import css from './styles.scss';

export const PolicyAssistant = observer(function PolicyAssistant() {
  const store = useStore();
  return (
    <div className={css.wrapper}>
      <div className={css.tutorialContent}>
        {store.assistant.currentTutorial
          ? store.assistant.currentTutorial.entries.map(entry => {
              return <PolicyAssistantCard key={entry.id} entry={entry} />;
            })
          : 'Tutorial not found'}
      </div>
    </div>
  );
});
