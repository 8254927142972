import React from 'react';

import { Button, NonIdealState, Popover } from '@blueprintjs/core';
import { observer } from 'mobx-react';

import { FlowsTable } from '~/components/FlowsTable';
import { cimulatorVisibleColumns } from '~/components/FlowsTable/general';
import { FlowsTableSidebar, Props as SidebarProps } from '~/components/FlowsTable/Sidebar';
import { getDefaultWidths, useFlowsTableColumns } from '~/components/hooks/useFlowTableColumns';
import { Filters } from '~/domain/filtering';
import { Flow } from '~/domain/flows';
import { AsFlowDigest, FlowDigest } from '~/domain/flows/common';
import { TimeRange } from '~/domain/time';
import { useStore } from '~/store';
import { usePopover } from '~/ui/hooks/usePopover';

import { FlowsSettings } from './FlowsSettings';
import css from './HubblePanel.scss';

export interface Props {
  flows: AsFlowDigest[];
  filters: Filters;
  kind?: PanelKind;
  selectedFlow: Flow | null;
  selectedFlowIsLoading: boolean;
  flowsPageIsLoading: boolean;

  timeRange: TimeRange;
  onTimeRangeChange?: (timeRange: TimeRange) => void;

  onSidebarVerdictClick?: SidebarProps['onVerdictClick'];
  onSidebarTCPFlagClick?: SidebarProps['onTcpFlagClick'];
  onSidebarLabelClick?: SidebarProps['onLabelClick'];
  onSidebarPodClick?: SidebarProps['onPodClick'];
  onSidebarIdentityClick?: SidebarProps['onIdentityClick'];
  onSidebarIpClick?: SidebarProps['onIpClick'];
  onSidebarDnsClick?: SidebarProps['onDnsClick'];
  onSidebarWorkloadClick?: SidebarProps['onWorkloadClick'];
  onSidebarPortClick?: SidebarProps['onPortClick'];
  onSidebarProtocolClick?: SidebarProps['onProtocolClick'];
  onSidebarOpenProcessTreeForFlowClick?: SidebarProps['onOpenProcessTreeForFlow'];

  onSelectFlow?: (flow: FlowDigest | null) => void;
  onUploadFlows?: (
    event: React.SyntheticEvent,
    onSuccess?: (flows: Flow[]) => void,
    onError?: (error: Error) => void,
  ) => void;
  onCloseFlowsTableSidebar?: () => void;
  onSetPanelKind?: (kind: PanelKind) => void;
  onFlowsScrolledToBottom?: () => void;
}

export enum PanelKind {
  Table = 'table',
}

const flowTableUniqueId = 'cimulator';

export const HubblePanel = observer(function HubblePanel(props: Props) {
  const store = useStore();
  const flowsTableColumns = useFlowsTableColumns(
    flowTableUniqueId,
    getDefaultWidths(cimulatorVisibleColumns),
  );

  const settingsPopover = usePopover({
    stopPropagation: true,
    preventDefault: true,
  });

  const showFlowSidebar = !!(props.selectedFlow || props.selectedFlowIsLoading);

  return (
    <div className={`${css.wrapper} ${css.flows}`}>
      <div className={css.tableCaption}>
        {store.uiSettings.isTimescapeOnlyEnabled && <div />}
        <Popover {...settingsPopover.props} hasBackdrop={true} content={<FlowsSettings />}>
          <Button icon="cog" minimal onClick={settingsPopover.toggle}>
            Preferences
          </Button>
        </Popover>
      </div>
      <div className={css.table}>
        {props.flows.length > 0 && (
          <FlowsTable
            flows={props.flows}
            isLoading={props.flowsPageIsLoading}
            visibleColumns={flowsTableColumns.visible}
            columnWidths={flowsTableColumns.widths}
            selectedFlow={props.selectedFlow}
            onSelectFlow={props.onSelectFlow}
            onScrolledToBottom={props.onFlowsScrolledToBottom}
            onHeaderResize={flowsTableColumns.setWidths}
          />
        )}
        {props.flows.length === 0 && (
          <NonIdealState icon="disable" title="No flows convertable to policy rules" />
        )}
        {showFlowSidebar && (
          <FlowsTableSidebar
            flow={props.selectedFlow}
            isLoading={props.selectedFlowIsLoading}
            filters={props.filters}
            showReviewPolicyButton={false}
            showAddToPolicyAction={true}
            onVerdictClick={props.onSidebarVerdictClick}
            onTcpFlagClick={props.onSidebarTCPFlagClick}
            onLabelClick={props.onSidebarLabelClick}
            onPodClick={props.onSidebarPodClick}
            onIdentityClick={props.onSidebarIdentityClick}
            onIpClick={props.onSidebarIpClick}
            onDnsClick={props.onSidebarDnsClick}
            onWorkloadClick={props.onSidebarWorkloadClick}
            onPortClick={props.onSidebarPortClick}
            onProtocolClick={props.onSidebarProtocolClick}
            onClose={props.onCloseFlowsTableSidebar}
            onOpenProcessTreeForFlow={props.onSidebarOpenProcessTreeForFlowClick}
          />
        )}
      </div>
    </div>
  );
});
