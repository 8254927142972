/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter add_pb_suffix,eslint_disable,ts_nocheck,generate_dependencies,long_type_number,use_proto_field_name
// @generated from protobuf file "timescape/v1/container.proto" (package "timescape.v1", syntax proto3)
// tslint:disable
// @ts-nocheck
//
// Copyright (C) Isovalent, Inc. - All Rights Reserved.
//
// NOTICE: All information contained herein is, and remains the property of
// Isovalent Inc and its suppliers, if any. The intellectual and technical
// concepts contained herein are proprietary to Isovalent Inc and its suppliers
// and may be covered by U.S. and Foreign Patents, patents in process, and are
// protected by trade secret or copyright law.  Dissemination of this
// information or reproduction of this material is strictly forbidden unless
// prior written permission is obtained from Isovalent Inc.
//
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { EventType } from "../../tetragon/events_pb";
import { Timestamp } from "../../google/protobuf/timestamp_pb";
import { Process } from "../../tetragon/tetragon_pb";
import { GetEventsResponse } from "../../tetragon/events_pb";
import { TimeFilter } from "./time_filter_pb";
/**
 * GetContainersFromEventsRequest is the request parameter for the
 * FromEvents RPC endpoint.
 *
 * @generated from protobuf message timescape.v1.GetContainersFromEventsRequest
 */
export interface GetContainersFromEventsRequest {
    /**
     * time_filter allows to filter by time.
     *
     * @generated from protobuf field: timescape.v1.TimeFilter time_filter = 1;
     */
    time_filter?: TimeFilter;
    /**
     * results_limit limits the number of containers to return from the call.
     * NB: This request will return a number of containers (rather than a
     * stream), so we need to bound the results. Note that ResultsOrder will
     * determine what results will be dropped if we have more results than the
     * limit.
     *
     * @generated from protobuf field: uint32 results_limit = 2;
     */
    results_limit: number;
    /**
     * results_order specifies the expected order of the results.
     *
     * @generated from protobuf field: timescape.v1.GetContainersFromEventsRequest.ResultsOrder results_order = 3;
     */
    results_order: GetContainersFromEventsRequest_ResultsOrder;
    /**
     * allow_list is a set of filters that containers must match.
     *
     * @generated from protobuf field: repeated timescape.v1.EventsContainersFilter allow_list = 4;
     */
    allow_list: EventsContainersFilter[]; // TODO: deny_list
}
/**
 * ResultsOrder is an enum that defines results ordering.
 *
 * @generated from protobuf enum timescape.v1.GetContainersFromEventsRequest.ResultsOrder
 */
export enum GetContainersFromEventsRequest_ResultsOrder {
    /**
     * RESULTS_ORDER_UNSPECIFIED means that no particular order should be
     * assumed.
     *
     * @generated from protobuf enum value: RESULTS_ORDER_UNSPECIFIED = 0;
     */
    UNSPECIFIED = 0,
    /**
     * RESULTS_ORDER_CONTAINER_START_TIME_ASCENDING means that results are
     * sorted by container start time in ascending order.
     *
     * @generated from protobuf enum value: RESULTS_ORDER_CONTAINER_START_TIME_ASCENDING = 1;
     */
    CONTAINER_START_TIME_ASCENDING = 1,
    /**
     * RESULTS_ORDER_CONTAINER_START_TIME_DESCENDING means that results are
     * sorted by container start time in descending order.
     *
     * @generated from protobuf enum value: RESULTS_ORDER_CONTAINER_START_TIME_DESCENDING = 2;
     */
    CONTAINER_START_TIME_DESCENDING = 2
}
/**
 * GetContainersFromEventsResponse is the response to the
 * GetContainersFromEvents RPC endpoint.
 *
 * @generated from protobuf message timescape.v1.GetContainersFromEventsResponse
 */
export interface GetContainersFromEventsResponse {
    /**
     * containers is a list of containers that match the request.
     *
     * @generated from protobuf field: repeated timescape.v1.EventsContainerInfo containers = 1;
     */
    containers: EventsContainerInfo[];
}
/**
 * GetContainerEventsRequest is the request parameter for the
 * GetContainerEvents RPC endpoint.
 *
 * @generated from protobuf message timescape.v1.GetContainerEventsRequest
 */
export interface GetContainerEventsRequest {
    /**
     * EventsContainerInfo specifies information to identify a container from
     * which to see the list of related events.
     *
     * @generated from protobuf field: timescape.v1.EventsContainerInfo container = 1;
     */
    container?: EventsContainerInfo;
    /**
     * allow_list is a list of filters that must be matched by the target
     * container.
     *
     * @generated from protobuf field: repeated timescape.v1.EventFilter allow_list = 2;
     */
    allow_list: EventFilter[]; // TODO: deny_list, reserved = 3;
    /**
     * Reduce allows for additional control over the amount of the
     * returned data.
     *
     * @generated from protobuf field: timescape.v1.ContainerEventsReduce reduce = 4;
     */
    reduce?: ContainerEventsReduce;
}
/**
 * ContainerEventsReduce allows for additional control over the amount of the
 * returned data.
 *
 * @generated from protobuf message timescape.v1.ContainerEventsReduce
 */
export interface ContainerEventsReduce {
    /**
     * Mode controls the number of returned container events.
     *
     * @generated from protobuf field: timescape.v1.ContainerEventsMode mode = 1;
     */
    mode: ContainerEventsMode;
}
/**
 * GetContainerEventsResponse is the respponse returned from the
 * GetContainerEvents RPC call.
 *
 * @generated from protobuf message timescape.v1.GetContainerEventsResponse
 */
export interface GetContainerEventsResponse {
    /**
     * event is typically a TLS or process event.
     *
     * @generated from protobuf field: tetragon.GetEventsResponse event = 1;
     */
    event?: GetEventsResponse;
}
/**
 * GetProcessAncestorsRequest is the request parameter for the
 * GetProcessAncestors RPC endpoint.
 *
 * @generated from protobuf message timescape.v1.GetProcessAncestorsRequest
 */
export interface GetProcessAncestorsRequest {
    /**
     * exec_id is the execution ID of the process from which to get the list of
     * ancestors.
     *
     * @generated from protobuf field: string exec_id = 1;
     */
    exec_id: string;
    /**
     * namespace is the namespace in which the container runs (or ran). If empty,
     * the request will be satisfied only with the exec_id.
     *
     * @generated from protobuf field: string namespace = 2;
     */
    namespace: string;
}
/**
 * GetProcessAncestorsResponse is the response of the GetProcessAncestors RPC
 * endpoint.
 *
 * @generated from protobuf message timescape.v1.GetProcessAncestorsResponse
 */
export interface GetProcessAncestorsResponse {
    /**
     * ancestors is a list of ancestor processes.
     *
     * @generated from protobuf field: repeated tetragon.Process ancestors = 1;
     */
    ancestors: Process[];
}
/**
 * EventsContainersFilter is a filter for container information as can be found
 * in FGS events.
 *
 * @generated from protobuf message timescape.v1.EventsContainersFilter
 */
export interface EventsContainersFilter {
    /**
     * namespaces is a list of namespaces that can be used for filtering
     * containers.
     *
     * @generated from protobuf field: repeated string namespaces = 1;
     */
    namespaces: string[];
}
/**
 * EventsContainerInfo contains container information retreived from FGS event
 * data.
 *
 * Fields below serve two purposes: provide basic container information, but
 * also uniquely identify a container in FGS data. What exists now should be
 * OK, but we might want to revisit this.
 *
 * @generated from protobuf message timescape.v1.EventsContainerInfo
 */
export interface EventsContainerInfo {
    /**
     * namespace is the namespace in which the container runs (or ran).
     *
     * @generated from protobuf field: string namespace = 1;
     */
    namespace: string;
    /**
     * pod_name is the name of the pod in which the container runs (or ran).
     *
     * @generated from protobuf field: string pod_name = 2;
     */
    pod_name: string;
    /**
     * node_name is the name of the node on which the pod, and thus container,
     * runs (or ran).
     *
     * @generated from protobuf field: string node_name = 3;
     */
    node_name: string;
    /**
     * image_id identifies a container image.
     *
     * @generated from protobuf field: string image_id = 4;
     */
    image_id: string;
    /**
     * start_time corresponds to the time at which the container was starter.
     *
     * @generated from protobuf field: google.protobuf.Timestamp start_time = 5;
     */
    start_time?: Timestamp;
}
/**
 * EventFilter is a filter for events.
 *
 * @generated from protobuf message timescape.v1.EventFilter
 */
export interface EventFilter {
    /**
     * event_set specifiies a list of events that must (or must not) match the
     * filter.
     *
     * @generated from protobuf field: repeated tetragon.EventType event_set = 1;
     */
    event_set: EventType[]; // TODO: more filters (e.g., implement tetragon.Filter)
}
/**
 * ContainerEventsMode
 *
 * @generated from protobuf enum timescape.v1.ContainerEventsMode
 */
export enum ContainerEventsMode {
    /**
     * If not specified, same as ALL.
     *
     * @generated from protobuf enum value: CONTAINER_EVENTS_MODE_UNSPECIFIED = 0;
     */
    UNSPECIFIED = 0,
    /**
     * All found events are going to be returned
     *
     * @generated from protobuf enum value: CONTAINER_EVENTS_MODE_ALL = 1;
     */
    ALL = 1,
    /**
     * Only distinct events are going to be preset in the returned set.
     *
     * Particularly useful to reduce the number of CONNECT event types which may
     * use a repeat destination a lot.
     *
     * @generated from protobuf enum value: CONTAINER_EVENTS_MODE_DESTINATION = 2;
     */
    DESTINATION = 2
}
// @generated message type with reflection information, may provide speed optimized methods
class GetContainersFromEventsRequest$Type extends MessageType<GetContainersFromEventsRequest> {
    constructor() {
        super("timescape.v1.GetContainersFromEventsRequest", [
            { no: 1, name: "time_filter", kind: "message", localName: "time_filter", T: () => TimeFilter },
            { no: 2, name: "results_limit", kind: "scalar", localName: "results_limit", T: 13 /*ScalarType.UINT32*/ },
            { no: 3, name: "results_order", kind: "enum", localName: "results_order", T: () => ["timescape.v1.GetContainersFromEventsRequest.ResultsOrder", GetContainersFromEventsRequest_ResultsOrder, "RESULTS_ORDER_"] },
            { no: 4, name: "allow_list", kind: "message", localName: "allow_list", repeat: 1 /*RepeatType.PACKED*/, T: () => EventsContainersFilter }
        ]);
    }
    create(value?: PartialMessage<GetContainersFromEventsRequest>): GetContainersFromEventsRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.results_limit = 0;
        message.results_order = 0;
        message.allow_list = [];
        if (value !== undefined)
            reflectionMergePartial<GetContainersFromEventsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetContainersFromEventsRequest): GetContainersFromEventsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* timescape.v1.TimeFilter time_filter */ 1:
                    message.time_filter = TimeFilter.internalBinaryRead(reader, reader.uint32(), options, message.time_filter);
                    break;
                case /* uint32 results_limit */ 2:
                    message.results_limit = reader.uint32();
                    break;
                case /* timescape.v1.GetContainersFromEventsRequest.ResultsOrder results_order */ 3:
                    message.results_order = reader.int32();
                    break;
                case /* repeated timescape.v1.EventsContainersFilter allow_list */ 4:
                    message.allow_list.push(EventsContainersFilter.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetContainersFromEventsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* timescape.v1.TimeFilter time_filter = 1; */
        if (message.time_filter)
            TimeFilter.internalBinaryWrite(message.time_filter, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* uint32 results_limit = 2; */
        if (message.results_limit !== 0)
            writer.tag(2, WireType.Varint).uint32(message.results_limit);
        /* timescape.v1.GetContainersFromEventsRequest.ResultsOrder results_order = 3; */
        if (message.results_order !== 0)
            writer.tag(3, WireType.Varint).int32(message.results_order);
        /* repeated timescape.v1.EventsContainersFilter allow_list = 4; */
        for (let i = 0; i < message.allow_list.length; i++)
            EventsContainersFilter.internalBinaryWrite(message.allow_list[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message timescape.v1.GetContainersFromEventsRequest
 */
export const GetContainersFromEventsRequest = new GetContainersFromEventsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetContainersFromEventsResponse$Type extends MessageType<GetContainersFromEventsResponse> {
    constructor() {
        super("timescape.v1.GetContainersFromEventsResponse", [
            { no: 1, name: "containers", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => EventsContainerInfo }
        ]);
    }
    create(value?: PartialMessage<GetContainersFromEventsResponse>): GetContainersFromEventsResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.containers = [];
        if (value !== undefined)
            reflectionMergePartial<GetContainersFromEventsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetContainersFromEventsResponse): GetContainersFromEventsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated timescape.v1.EventsContainerInfo containers */ 1:
                    message.containers.push(EventsContainerInfo.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetContainersFromEventsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated timescape.v1.EventsContainerInfo containers = 1; */
        for (let i = 0; i < message.containers.length; i++)
            EventsContainerInfo.internalBinaryWrite(message.containers[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message timescape.v1.GetContainersFromEventsResponse
 */
export const GetContainersFromEventsResponse = new GetContainersFromEventsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetContainerEventsRequest$Type extends MessageType<GetContainerEventsRequest> {
    constructor() {
        super("timescape.v1.GetContainerEventsRequest", [
            { no: 1, name: "container", kind: "message", T: () => EventsContainerInfo },
            { no: 2, name: "allow_list", kind: "message", localName: "allow_list", repeat: 1 /*RepeatType.PACKED*/, T: () => EventFilter },
            { no: 4, name: "reduce", kind: "message", T: () => ContainerEventsReduce }
        ]);
    }
    create(value?: PartialMessage<GetContainerEventsRequest>): GetContainerEventsRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.allow_list = [];
        if (value !== undefined)
            reflectionMergePartial<GetContainerEventsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetContainerEventsRequest): GetContainerEventsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* timescape.v1.EventsContainerInfo container */ 1:
                    message.container = EventsContainerInfo.internalBinaryRead(reader, reader.uint32(), options, message.container);
                    break;
                case /* repeated timescape.v1.EventFilter allow_list */ 2:
                    message.allow_list.push(EventFilter.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* timescape.v1.ContainerEventsReduce reduce */ 4:
                    message.reduce = ContainerEventsReduce.internalBinaryRead(reader, reader.uint32(), options, message.reduce);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetContainerEventsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* timescape.v1.EventsContainerInfo container = 1; */
        if (message.container)
            EventsContainerInfo.internalBinaryWrite(message.container, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* repeated timescape.v1.EventFilter allow_list = 2; */
        for (let i = 0; i < message.allow_list.length; i++)
            EventFilter.internalBinaryWrite(message.allow_list[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* timescape.v1.ContainerEventsReduce reduce = 4; */
        if (message.reduce)
            ContainerEventsReduce.internalBinaryWrite(message.reduce, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message timescape.v1.GetContainerEventsRequest
 */
export const GetContainerEventsRequest = new GetContainerEventsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ContainerEventsReduce$Type extends MessageType<ContainerEventsReduce> {
    constructor() {
        super("timescape.v1.ContainerEventsReduce", [
            { no: 1, name: "mode", kind: "enum", T: () => ["timescape.v1.ContainerEventsMode", ContainerEventsMode, "CONTAINER_EVENTS_MODE_"] }
        ]);
    }
    create(value?: PartialMessage<ContainerEventsReduce>): ContainerEventsReduce {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.mode = 0;
        if (value !== undefined)
            reflectionMergePartial<ContainerEventsReduce>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ContainerEventsReduce): ContainerEventsReduce {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* timescape.v1.ContainerEventsMode mode */ 1:
                    message.mode = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ContainerEventsReduce, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* timescape.v1.ContainerEventsMode mode = 1; */
        if (message.mode !== 0)
            writer.tag(1, WireType.Varint).int32(message.mode);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message timescape.v1.ContainerEventsReduce
 */
export const ContainerEventsReduce = new ContainerEventsReduce$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetContainerEventsResponse$Type extends MessageType<GetContainerEventsResponse> {
    constructor() {
        super("timescape.v1.GetContainerEventsResponse", [
            { no: 1, name: "event", kind: "message", T: () => GetEventsResponse }
        ]);
    }
    create(value?: PartialMessage<GetContainerEventsResponse>): GetContainerEventsResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<GetContainerEventsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetContainerEventsResponse): GetContainerEventsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* tetragon.GetEventsResponse event */ 1:
                    message.event = GetEventsResponse.internalBinaryRead(reader, reader.uint32(), options, message.event);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetContainerEventsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* tetragon.GetEventsResponse event = 1; */
        if (message.event)
            GetEventsResponse.internalBinaryWrite(message.event, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message timescape.v1.GetContainerEventsResponse
 */
export const GetContainerEventsResponse = new GetContainerEventsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetProcessAncestorsRequest$Type extends MessageType<GetProcessAncestorsRequest> {
    constructor() {
        super("timescape.v1.GetProcessAncestorsRequest", [
            { no: 1, name: "exec_id", kind: "scalar", localName: "exec_id", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "namespace", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetProcessAncestorsRequest>): GetProcessAncestorsRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.exec_id = "";
        message.namespace = "";
        if (value !== undefined)
            reflectionMergePartial<GetProcessAncestorsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetProcessAncestorsRequest): GetProcessAncestorsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string exec_id */ 1:
                    message.exec_id = reader.string();
                    break;
                case /* string namespace */ 2:
                    message.namespace = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetProcessAncestorsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string exec_id = 1; */
        if (message.exec_id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.exec_id);
        /* string namespace = 2; */
        if (message.namespace !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.namespace);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message timescape.v1.GetProcessAncestorsRequest
 */
export const GetProcessAncestorsRequest = new GetProcessAncestorsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetProcessAncestorsResponse$Type extends MessageType<GetProcessAncestorsResponse> {
    constructor() {
        super("timescape.v1.GetProcessAncestorsResponse", [
            { no: 1, name: "ancestors", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Process }
        ]);
    }
    create(value?: PartialMessage<GetProcessAncestorsResponse>): GetProcessAncestorsResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.ancestors = [];
        if (value !== undefined)
            reflectionMergePartial<GetProcessAncestorsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetProcessAncestorsResponse): GetProcessAncestorsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated tetragon.Process ancestors */ 1:
                    message.ancestors.push(Process.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetProcessAncestorsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated tetragon.Process ancestors = 1; */
        for (let i = 0; i < message.ancestors.length; i++)
            Process.internalBinaryWrite(message.ancestors[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message timescape.v1.GetProcessAncestorsResponse
 */
export const GetProcessAncestorsResponse = new GetProcessAncestorsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EventsContainersFilter$Type extends MessageType<EventsContainersFilter> {
    constructor() {
        super("timescape.v1.EventsContainersFilter", [
            { no: 1, name: "namespaces", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<EventsContainersFilter>): EventsContainersFilter {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.namespaces = [];
        if (value !== undefined)
            reflectionMergePartial<EventsContainersFilter>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EventsContainersFilter): EventsContainersFilter {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string namespaces */ 1:
                    message.namespaces.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EventsContainersFilter, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string namespaces = 1; */
        for (let i = 0; i < message.namespaces.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.namespaces[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message timescape.v1.EventsContainersFilter
 */
export const EventsContainersFilter = new EventsContainersFilter$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EventsContainerInfo$Type extends MessageType<EventsContainerInfo> {
    constructor() {
        super("timescape.v1.EventsContainerInfo", [
            { no: 1, name: "namespace", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "pod_name", kind: "scalar", localName: "pod_name", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "node_name", kind: "scalar", localName: "node_name", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "image_id", kind: "scalar", localName: "image_id", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "start_time", kind: "message", localName: "start_time", T: () => Timestamp }
        ]);
    }
    create(value?: PartialMessage<EventsContainerInfo>): EventsContainerInfo {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.namespace = "";
        message.pod_name = "";
        message.node_name = "";
        message.image_id = "";
        if (value !== undefined)
            reflectionMergePartial<EventsContainerInfo>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EventsContainerInfo): EventsContainerInfo {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string namespace */ 1:
                    message.namespace = reader.string();
                    break;
                case /* string pod_name */ 2:
                    message.pod_name = reader.string();
                    break;
                case /* string node_name */ 3:
                    message.node_name = reader.string();
                    break;
                case /* string image_id */ 4:
                    message.image_id = reader.string();
                    break;
                case /* google.protobuf.Timestamp start_time */ 5:
                    message.start_time = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.start_time);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EventsContainerInfo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string namespace = 1; */
        if (message.namespace !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.namespace);
        /* string pod_name = 2; */
        if (message.pod_name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.pod_name);
        /* string node_name = 3; */
        if (message.node_name !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.node_name);
        /* string image_id = 4; */
        if (message.image_id !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.image_id);
        /* google.protobuf.Timestamp start_time = 5; */
        if (message.start_time)
            Timestamp.internalBinaryWrite(message.start_time, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message timescape.v1.EventsContainerInfo
 */
export const EventsContainerInfo = new EventsContainerInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EventFilter$Type extends MessageType<EventFilter> {
    constructor() {
        super("timescape.v1.EventFilter", [
            { no: 1, name: "event_set", kind: "enum", localName: "event_set", repeat: 1 /*RepeatType.PACKED*/, T: () => ["tetragon.EventType", EventType] }
        ]);
    }
    create(value?: PartialMessage<EventFilter>): EventFilter {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.event_set = [];
        if (value !== undefined)
            reflectionMergePartial<EventFilter>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EventFilter): EventFilter {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated tetragon.EventType event_set */ 1:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.event_set.push(reader.int32());
                    else
                        message.event_set.push(reader.int32());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EventFilter, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated tetragon.EventType event_set = 1; */
        if (message.event_set.length) {
            writer.tag(1, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.event_set.length; i++)
                writer.int32(message.event_set[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message timescape.v1.EventFilter
 */
export const EventFilter = new EventFilter$Type();
/**
 * @generated ServiceType for protobuf service timescape.v1.ContainerService
 */
export const ContainerService = new ServiceType("timescape.v1.ContainerService", [
    { name: "GetContainersFromEvents", options: {}, I: GetContainersFromEventsRequest, O: GetContainersFromEventsResponse },
    { name: "GetContainerEvents", serverStreaming: true, options: {}, I: GetContainerEventsRequest, O: GetContainerEventsResponse },
    { name: "GetProcessAncestors", options: {}, I: GetProcessAncestorsRequest, O: GetProcessAncestorsResponse }
]);
