import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';

import classNames from 'classnames';
import { observer } from 'mobx-react';

import { WH, XY } from '~/domain/geometry';
import { whComparator } from '~/domain/geometry/general';
import { ElemCoords, useElemCoords } from '~/ui/hooks';
import { applyScaleToWH } from '~/ui/hooks/useElemCoords';

import css from './styles.scss';

export { ElemCoords };

export interface Props {
  dimensions?: WH | null;
  coords?: XY | null;
  children?: React.ReactNode;
  className?: string;
  forwardRef?: React.MutableRefObject<SVGForeignObjectElement | null>;
  style?: React.CSSProperties;

  onCoords?: (c: ElemCoords) => void;
  onClick?: () => void;
}

export const DimensionalWrapper = observer(function DimensionalWrapper(props: Props) {
  const divRef = useRef<HTMLDivElement | null>(null);
  const [wh, setDimensions] = useState<WH | null>(props.dimensions ?? null);

  const handle = useElemCoords(divRef, false, coords => {
    // NOTE: Here is what's going on here: it's possible that somewhere in the
    // NOTE: DOM tree above this node, transform: scale(...) has been applied,
    // NOTE: so we need to account it, not to get cut dimensions
    if (props.dimensions == null) {
      const nextDimensions = applyScaleToWH(coords.dimensions, coords.scale);
      if (wh != null && whComparator(wh, nextDimensions)) return;

      setDimensions(nextDimensions);
    }

    props.onCoords?.(coords);
  });

  useEffect(() => {
    if (props.dimensions == null || whComparator(wh, props.dimensions)) return;

    setDimensions(props.dimensions);
  }, [wh, props.dimensions]);

  // NOTE: there are some cases when children is updated, but coords are not
  // NOTE: emited by unknowable reason ><
  useLayoutEffect(() => {
    handle.emit();
  }, [props]);

  const divClasses = classNames(css.dimensionalWrapper, props.className);

  return (
    <foreignObject
      onClick={() => props.onClick?.()}
      width={wh?.w ?? 1}
      height={wh?.h ?? 1}
      x={props.coords?.x}
      y={props.coords?.y}
      className={css.foreignObject}
      ref={props.forwardRef}
    >
      <div ref={divRef} className={divClasses} style={props.style}>
        {props.children}
      </div>
    </foreignObject>
  );
});
